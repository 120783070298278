import React from 'react'
import UsersLayout from 'components/UsersLayout'
import { DrawerEventEmitter } from 'helpers/drawer'

const AdminsLayout = () => {
  const handleGroupClick = (groupId: string) => {
    DrawerEventEmitter.emit('openDrawer', 'groupInformation', true, {
      id: groupId,
    })
  }

  return (
    <div>
      <UsersLayout
        role="ADMIN"
        addText="Create Admin"
        addUserDrawer="addAdmin"
        editUserDrawer="editAdmin"

        onGroupClick={handleGroupClick}

      />
    </div>
  )
}

export default AdminsLayout
