import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import SyncIcon from '@material-ui/icons/Sync'

import { useFetchNonSectionCourses } from 'hooks/sections/useSections';

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'

import { DrawerEventEmitter } from 'helpers/drawer'

import styled from 'styled-components'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

import {
  ActionButton,
  GridLayoutForm,
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'

import Search from 'components/common/Search'
import TablePagination from 'components/common/Pagination/TablePagination'
import Grid from 'components/common/Grid'

import { columnConfigForNonSectionCourses, actionConfigForNonSectionCourses } from '../gridConfig';
import { Button } from 'components/common/Button'

interface SelectedCourse {
  id: string
  name: string
  imgLink?: string
}

interface Props {
  title: string
  drawerName?: string
  selectedCourses: SelectedCourse[]
  onUpdateSelectedCourses: (courses: SelectedCourse[]) => void
  onOrganizeSectionDrawerClose: () => void
}

const PER_PAGE = 10;

const OrganizeSectionDrawer = ({ title, selectedCourses, onUpdateSelectedCourses, drawerName, onOrganizeSectionDrawerClose }: Props) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const theme = useTheme()
  const history = useHistory()

  const [filterData, setFilterData] = useState<any>({})
  const [searchText, setSearchText] = useState<string>('')

  const [selectedItem, setSelectedItem] = useState<any>({})
  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE);
  const [selectAll, setSelectAll] = useState<any>({})

  const selectedCoursesIds: string[] = selectedCourses?.map((item: any) => item.id) || [];
  const { courses, loading, error, refetch, fetchMore } = useFetchNonSectionCourses(selectedCoursesIds, currentPage, perPage);

  const handleCloseDrawer = () => {
    onOrganizeSectionDrawerClose();
    DrawerEventEmitter.emit('openDrawer', 'createSection', false)
  }

  // const disabledButton = !!(
  //   (progress > 0 && progress < 100) ||
  //   imageLoading ||
  //   addModuleLoading
  // )


  const resetFilter = () => { }

  const filterDrawer = () => {
    setFilterIsOpen(!filterIsOpen)
  }

  const onSearchSubmit = (e: React.FormEvent) => { };


  const value = {
    $or: [
      { originalId: { $exists: false } },
      { originalId: { $eq: null } },
    ],
    isPublished: { $eq: true },
    status: { $eq: 'ACTIVE' },
    state: { $eq: 'published' },
    ...(selectedCoursesIds.length > 0 ? { _id: { $nin: [...selectedCoursesIds] } } : {})
  }

  const query = {

    query: { type: "eq", value: "" }, companyId: { type: "exact", value: null }
  }

  const handlePaginationClick = (event: any, value: number) => {

    // console.log('desired event', event);
    // console.log('desired value', value);

    refetch({
      filter: query,
      currentPage,
      perPage: 100
    })

    if (value === 0) {
      setCurrentPage(1)
    } else if (value < currentPage) {

      setCurrentPage(cp => cp - 1);
    } else if (value === currentPage) {

      setCurrentPage((cp) => cp + 1)
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    // console.log("Change rows per page!")
    // setPerPage(parseInt(event.target.value, 10))
    // setCurrentPage(1)
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      // const newArr: string[] = modules.data.map((n: any) => n.id)
      const newArr: SelectedCourse[] = courses?.data || [];
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  };

  const actionHandler = (event: string, field: any, action: string) => {
    switch (event) {
      case 'view':
        window.open(`/courses/${field}`, '_blank');
        break
      default:
        return () => console.log('')
    }
  };

  const handleClick = (courseId: string) => {
    history.push(`/courses/${courseId}`)
  }


  const selectItem = (selectedCourse: SelectedCourse) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.findIndex((item: SelectedCourse) => item.id === selectedCourse.id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, { ...selectedCourse })
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const isStudent = state.currentUser.role === 'STUDENT'

  // const isCompanySuperAdmin =
  //   state?.currentUser?.role === 'SUPER_ADMIN' &&
  //   !!state?.currentUser?.companyId?.id

  // const isCompanyAdmin =
  //   state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  const isSuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !state?.currentUser?.companyId?.id

  // const isAdmin =
  //   state?.currentUser?.role === 'ADMIN' && !state?.currentUser?.companyId?.id


  // const canModify =
  //   (((isCompanySuperAdmin || isCompanyAdmin) && modules?.data[0]?.companyId === state?.currentUser?.companyId?.id) || isSuperAdmin || isAdmin)
  //   && !isStudent

  const canModify = isSuperAdmin && !isStudent;

  const actions = actionConfigForNonSectionCourses(
    actionHandler,
    state.userPermission,
    t,
    canModify,
  )

  const config = columnConfigForNonSectionCourses(handleClick, selectItem, t, theme);


  const onFinishSelecting = () => {
    let finalCourses: SelectedCourse[] = [];
    let newlySelectedCoursesIds: string[] = [];

    for (const key in selectedItem) {
      if (selectedItem[key]) {
        finalCourses = finalCourses.concat(selectedItem[key])
        const courseUniqueId = selectedItem[key].map((i: SelectedCourse) => i.id);
        newlySelectedCoursesIds = newlySelectedCoursesIds.concat(courseUniqueId);
      }
    }

    const uniqueSelectedCourses: SelectedCourse[] =
      selectedCourses.filter((c: SelectedCourse) => !newlySelectedCoursesIds.includes(c.id));

    finalCourses = [...finalCourses, ...uniqueSelectedCourses];

    onUpdateSelectedCourses(finalCourses);
    onOrganizeSectionDrawerClose()
  }
  return (
    <>
      {/* {addSectionLoading && <Loader />} */}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{title}</DrawerTitle>

        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent headerHeight={100} margin={'20px'}>
        <Container theme={theme} style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <GridStyledToolbar theme={theme}>
            <GridLayoutForm>
              <ActionButton
                disabled={_.isEmpty(filterData) && !searchText}
                onClick={resetFilter}
                theme={theme}
              >
                <SyncIcon fontSize="small" />
              </ActionButton>
              <MultipleAction theme={theme}>
                <ActionButton onClick={filterDrawer} theme={theme}>
                  <Icon filename={'filter'} fill={'#080A0AD6'} />
                </ActionButton>
                <MultipleActionTooltip>
                  {t('actions.filters')}
                </MultipleActionTooltip>
              </MultipleAction>
              <Search
                inputProps={{
                  value: searchText,
                  onChange: (e: any) => {
                    setSearchText(e.target.value)
                    setCurrentPage(1)
                  },
                }}
                onClick={onSearchSubmit}
              />
            </GridLayoutForm>
          </GridStyledToolbar>

          {courses?.data ? (
            <>
              <Grid
                title={t('courses_layout.title')}
                config={config}
                data={courses.data}
                actionConfig={actions}
                selected={_.size(selectedItem[currentPage])}
                selectAllItem={selectAllItem}
                selectedItems={selectedItem[currentPage]?.map((item: SelectedCourse) => item.id) || []}
              />
              <div style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: "flex-end" }}>
                <Button
                  onClick={onFinishSelecting}
                  text={t('actions.save')}
                  type="large"
                  btnType="button"
                  // isDisabled={disabledButton}
                  // but do we need disable here?
                  background={'#317BF4'}
                  buttonStyles={{
                    borderRadius: '8px',
                  }}
                />
              </div>
            </>
          ) : null}

          {courses ? (
            <TablePagination
              currentPage={courses ? courses.currentPage : 0}
              rowsPerPage={perPage}
              count={courses ? courses.courseCount : 0}
              handleChangePage={handlePaginationClick}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : null}
        </Container>
      </DrawerContent>
    </>
  )
}

export default OrganizeSectionDrawer;

const CloseIcon = styled(Icon)`
  position: absolute;
  top: 30px;
  right: 50px;
  cursor: 'pointer';
`

const Info = styled.div`
  position: absolute;
  bottom: -15px;
  left: 75px;
  width: 35px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 100%;
`

const Container = styled.div`
  overflow: hidden;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  height: 100%;
`

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #317bf4;
  padding: 18px 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    background: #3165f4;
  }
`


const GridStyledToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
`
