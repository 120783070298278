import { useMutation, useQuery } from "@apollo/react-hooks";
import { Box, Switch, useMediaQuery, useTheme } from "@material-ui/core";
// import { useTheme } from "@material-ui/styles";
import { useParams } from "react-router-dom";
import { Button } from "components/common/Button";
import DatePicker from "components/common/DatePicker";
import Drawer from "components/common/Drawer";
import SwitchesGroup from "components/common/Switcher/Switcher";
import { Input } from "components/common/TextInput";
import { CloseIcon, DrawerContent, DrawerHeader, DrawerTitle, Info } from "components/DrawerLayout/styled-components";
import { useUserValue } from "context/UserContext";
import { useFormik } from "formik";
import { ADD_REMOVE_UPDATE_EDUCATION, ADD_REMOVE_UPDATE_EXPERIENCE, GET_USER } from "gql/users.query";
import { Text } from "pages/courses/StudentCourse/styled";
import React, { useEffect, useState } from "react";
import { string } from "yup";
import { useTranslation } from 'react-i18next'

import * as S from "./styled";

interface IinitialValues {
  company: string,
  onGoing: Boolean,
  position: string,
  startDate: Date | null,
  endDate: Date | null,
}


const AddUpdateEducation = (props: any) => {
  const { t } = useTranslation()


  const [now, setNow] = useState(false);
  const initValues: IinitialValues = {
    company: "",
    onGoing: false,
    position: "",
    startDate: null,
    endDate: null,
  };

  const [state] = useUserValue();
  const { id: userId } = useParams<{ id: string }>()
  const { data } = useQuery(GET_USER, {
    variables: {
      id: userId,
    }
  })




  const [addOrUpdateEducation, { loading }] = useMutation(ADD_REMOVE_UPDATE_EDUCATION)

  const formik = useFormik({
    initialValues: initValues,
    onSubmit: values => {
      if (values.company !== "" && values.position !== "") {
        const endDate = now ? " " : values.endDate?.toLocaleDateString()

        if (!props.isEdit) {
          addOrUpdateEducation({
            variables: {
              action: 'add',
              userId,
              input: {
                onGoing: now,
                title: values.company,
                position: values.position,
                date: values.startDate?.toLocaleDateString() + " - " + endDate,
              },
            }
          }).then(() => props.refetch())
        }
        else {
          addOrUpdateEducation({
            variables: {
              action: 'update',
              userId,
              input: {
                onGoing: now,
                title: values.company,
                position: values.position,
                date: values.startDate?.toLocaleDateString() + " - " + endDate,
              },
              educationId: props.isEdit,
            }
          }).then(() => props.refetch())
        }

        props.onClose();
      }
    }
  });

  useEffect(() => {
    if (props.isEdit) {
      const exp = data.user.education?.find((exp: any) => exp.id === props.isEdit);
      setNow(exp.onGoing)

      if (exp) {
        const date = exp.date.split(" - ");
        const start = new Date(date[0]);
        const end = new Date(date[1]);


        formik.setValues({
          onGoing: now,
          company: exp.title,
          position: exp.position,
          startDate: start,
          endDate: end,
        })
      }
    }
  }, [])

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Drawer
      totalWidth={isSmallScreen ? "930px" : "100%"}
      // responsiveTotalWidth='500px'
      opened={props.open}
      toggleDrawer={() => props.onClose()}
    >
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{t('general.education')}</DrawerTitle>
        <CloseIcon
          onClick={() => props.onClose()}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent
        headerHeight={68}
      // marginResponsive="145px"
      >

        <Box style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          rowGap: '20px',
        }}>
          <Input
            label={t('general.schoolName')}
            placeholder={'general.companyName'}
            value={formik.values.company}
            onChange={formik.handleChange}
            name={'company'}
          />
          <Input
            label={t('general.courseName')}
            placeholder={t('general.courseName')}
            value={formik.values.position}
            onChange={formik.handleChange}
            name={'position'}
          />
        </Box>

        <S.BoxContainer>
          <DatePicker
            label={t('general.startDate')}
            name={'startDate'}
            onChange={(date) => {
              formik.setFieldValue('startDate', date);
            }}
            value={formik.values.startDate}
          />
          {!now && <DatePicker
            label={t('general.endDate')}
            name={'endDate'}
            onChange={(date) => {
              formik.setFieldValue('endDate', date);
            }}
            value={formik.values.endDate}
          />}
        </S.BoxContainer>

        <Box style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <Text style={{
            color: 'rgb(8 10 10 / 54%)',
          }}>{t("general.stillLearning")}</Text>
          <Switch
            checked={now}
            color={'primary'}
            size={'small'}
            onChange={() => setNow(!now)}
          />
        </Box>

        <Box style={{
          width: '100%',
          height: '1px',
          backgroundColor: '#EBEBEB',
          margin: '20px 0',
        }}></Box>

        <Box style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <Button
            text={t('actions.save')}
            type="medium"
            color="secondary"
            btnType="submit"
            onClick={formik.handleSubmit}
          />
        </Box>

      </DrawerContent>
    </Drawer>
  );
}

export default AddUpdateEducation;
