import { useContext, useEffect, useState } from 'react';
import { Sun } from '../../../assets/images/sun';
// import ThemeContext from '../../context/theme';
// import useLocalStorage from '../../hooks/useLocalStorage';
import React from 'react';

const LightSwitch = () => {
  // const [isDark, setIsDak] = useLocalStorage("isDark");
  const [darkMode, setDarkMode] = useState(true);
  // const theme = useContext(ThemeContext)

  // useEffect(() => {

  //   if (darkMode) {
  //     setIsDak(true)
  //     theme.changeTheme(theme.themes.dark)
  //     return
  //   }

  //   theme.changeTheme(theme.themes.light)
  //   setIsDak(false)

  // }, [darkMode])


  return (
    <div
      className='flex flex-col h-full align-center justify-center mx-2 cursor-pointer'
      onClick={() => setDarkMode(!darkMode)}
    >
      <span className='w-[60px] h-12 sm:w-20 sm:h-12 rounded-full border-2 relative'>
        <span
          className={`w-9 h-9 rounded-full flex ${darkMode ? 'right-1' : 'left-1'
            } top-1 border-2 absolute p-1`}
        >
          <Sun color={darkMode ? '#000' : '#fff'} />
        </span>
      </span>
    </div>
  );
};

export default LightSwitch;
