import React, { useState, useEffect } from 'react'
import { gql } from 'graphql.macro'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useQuery } from '@apollo/react-hooks'
import { useFormik } from 'formik'
import { useData } from 'context/DataContext'
import { useAction } from 'store/actions'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useEditQuestion } from 'hooks/questions/useEditQuestion'
import { useCreateQuestion } from 'hooks/questions/useCreateQuestion'
import { SET_FORMDATA } from 'store/types'
import HelpIcon from '@material-ui/icons/Help'
import { QuestionSchema } from 'helpers/validationSchemas'
import { useTranslation } from 'react-i18next'
import { GET_QUESTION } from 'gql/questions.query'
import { useUploadImage } from 'hooks/helpers/useHelpersService'

import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import Tooltip from '@material-ui/core/Tooltip'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  CloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import {
  ModuleItemWrapper,
  ModuleItem,
} from 'pages/questions/styled-components'
import { Wrapper, Modules } from '../styled-components'

import Loader from 'components/common/Loader'
import Drawer from 'components/common/Drawer'
import TextInput from 'components/common/TextInput/TextInput'
import QuestionItem from '../CreateQuestionDrawer/QuestionItem'
import AddModuleToQuestionDrawer from '../CreateQuestionDrawer/AddModuleToQuestionDrawer'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import UploadContent from '../CreateQuestionDrawer/UploadContent'
import { useUserValue } from 'context/UserContext'
import { Button } from 'components/common/Button'
import { QuestionTypes } from '../staticData'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import SelectField from 'components/common/SelectField/SelectField'
import { useTheme } from '@material-ui/core'

interface Params {
  title: string
  currentModule: any
  questionId: string
}

const GET_TAGS = gql`
  query {
    getAllTags {
      id
      label
    }
  }
`

const EditQuestionDrawer = ({ title, currentModule, questionId }: Params) => {
  const { t } = useTranslation()
  const [userState] = useUserValue()

  const { data, loading } = useQuery(GET_QUESTION, {
    variables: {
      questionId,
      type: currentModule.moduleId ? 'module' : '',
    },
  })

  const { data: tags } = useQuery(GET_TAGS)

  const { editQuestion, editQuestionLoading } = useEditQuestion({
    questionId,
    moduleId: currentModule ? currentModule.moduleId : '',
  })
  const { createQuestion, createQuestionLoading }: any = useCreateQuestion({
    type: currentModule.moduleId ? 'module' : '',
    id: currentModule.moduleId ? currentModule.moduleId : '',
    filter: {
      companyId: {
        type: 'exact',
        value: currentModule.manualCompanyId || userState.selectedCompanyId,
      },
    },
  })

  const tagList =
    (tags &&
      tags.getAllTags.map((i: any) => ({ label: i.label, value: i.label }))) ||
    []

  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()

  const [linkModuleDrawerOpened, setLinkModuleDrawerOpened] = useState<boolean>(
    false,
  )
  const [boolAnswer, setBoolAnswer] = useState<boolean>(true)
  const [choiceAnswers, setChoiceAnswers] = useState<any>([])
  const [multiAnswers, setMultiAnswers] = useState<any>([])
  const [selectedChoiceAnswer, setSelectedChoiceAnswer] = useState<any>(null)
  const [selectedMultiAnswers, setSelectedMultiAnswers] = useState<any>([])
  const [selectedModules, setSelectedModules] = useState<any>([])
  const [uploadedFiles, setUploadedFiles] = useState<any>([])
  const [cropperOpen, setCropperOpen] = useState<boolean>(false)
  const [cropperImage, setCropperImage] = useState<any>('')
  const { uploadImage, imageLoading } = useUploadImage()
  const [currentQuestion, setcurrentQuestion] = useState<any>('')

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      question: '',
      type: {
        label: 'True/False',
        value: 'boolean',
      },
      tags: [],
      reason: '',
    },
    validationSchema: QuestionSchema,
    onSubmit(values) {
      const {
        choiceAnswers: choiceAnswersFromValues,
        multiAnswers: multiAnswersFromValues,
        ...data
      }: any = values
      let formValues: any = data
      // if (selectedModules.length > 0) {
      //   formValues.modules = selectedModules
      // }

      if (values.type.value === 'boolean') {
        formValues.boolAnswer = boolAnswer
      }

      if (values.type.value === 'single') {
        formValues.answers = choiceAnswers.map((answer: any, index: number) => {
          return {
            isCorrect: selectedChoiceAnswer == index ? true : false,
            value: answer.value,
          }
        })
      }

      if (values.type.value === 'multiple') {
        formValues.answers = multiAnswers.map((answer: any, index: number) => {
          return {
            isCorrect: !!selectedMultiAnswers.includes(index),
            value: answer.value,
          }
        })
      }

      formValues.tags = values.tags.map((tag: any) => {
        return { label: tag.label }
      })

      // formValues.images = uploadedFiles.reduce((arr: any, file: any) => {
      //   if (file.type === 'image') {
      //     const { link } = file
      //     arr.push(link)
      //   }

      //   return arr
      // }, [])

      // formValues.documents = uploadedFiles.reduce((arr: any, file: any) => {
      //   if (file.type === 'document') {
      //     const { type, uploading, __typename, ...props } = file
      //     arr.push(props)
      //   }

      //   return arr
      // }, [])
      // formValues.videos = uploadedFiles.reduce((arr: any, file: any) => {
      //   if (file.type === 'video') {
      //     const { type, uploading, __typename, ...props } = file
      //     const updatedProps: any = { ...props }
      //     const links: any = props.links.map((item: any) => {
      //       const { __typename, ...props } = item
      //       return props
      //     })
      //     updatedProps.links = links
      //     arr.push(updatedProps)
      //   }

      //   return arr
      // }, [])
      // formValues.audios = uploadedFiles.reduce((arr: any, file: any) => {
      //   if (file.type === 'audio') {
      //     const { type, uploading, __typename, ...props } = file

      //     arr.push(props)
      //   }

      //   return arr
      // }, [])

      let obj: any = { documents: [], videos: [], audios: [], images: [] }
      if (uploadedFiles.length > 0) {
        uploadedFiles.map((item: any) => {
          const { type, ...args } = item

          if (type === 'videos') {
            obj[type] = [
              ...obj[type],
              {
                ...args,
                links: args.links.map(({ __typename, ...n }: any) => n),
              },
            ]
          } else {
            obj[type] = [...obj[type], args]
          }
        })

        formValues = {
          ...formValues,
          ...obj,
        }
      } else {
        formValues = {
          ...formValues,
          ...obj,
        }
      }

      const moduleId: string = currentModule ? currentModule.moduleId : ''
      const duplicate: boolean = currentModule.duplicate ? true : false

      formValues.videos = formValues.videos
        ? formValues.videos.map((video: any) => {
          return { id: video.id, title: video.title }
        })
        : []
      if (duplicate) {
        if (currentModule.moduleId) {
          formValues.modules = [
            {
              moduleId: currentModule.moduleId,
              name: currentModule.name,
            },
          ]
        } else {
          // formValues.modules = []
        }
        createQuestion(
          formValues,
          currentModule.manualCompanyId || userState.selectedCompanyId,
          closeDrawer,
        )
      } else {
        editQuestion(moduleId, questionId, formValues, closeDrawer)
      }
      toggleDrawerConfirm(false, '')
    },
  })

  const closeDrawer = () => {
    DrawerEventEmitter.emit('openDrawer', 'createQuestion', false)
  }

  const handleSelectChange = (field: string, e: any) => {
    setFieldValue(field, e)
  }

  const checkAnswers = (type: string) => {
    if (type === 'boolean') {
      return true
    }

    if (type === 'single') {
      const emptyValues = choiceAnswers.filter(
        (answer: any) => answer.value === '',
      )
      if (choiceAnswers.length > 0 && emptyValues.length === 0) {
        return true
      } else {
        return false
      }
    }

    if (type === 'multiple') {
      const emptyValues = multiAnswers.filter(
        (answer: any) => answer.value === '',
      )
      if (multiAnswers.length > 0 && emptyValues.length === 0) {
        return true
      } else {
        return false
      }
    }
  }

  const checkSelectedAnswers = (type: string) => {
    if (type === 'boolean') {
      return true
    }

    if (type === 'single') {
      if (selectedChoiceAnswer === null) {
        return false
      } else {
        return true
      }
    }

    if (type === 'multiple') {
      if (selectedMultiAnswers.length < 2) {
        return false
      } else {
        return true
      }
    }
  }

  const handleBoolChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const convertedValue = value === 'true' ? true : false
    setBoolAnswer(convertedValue)
  }

  const handleAddAnswerClick = (type: string) => {
    if (type === 'single') {
      const updatedChoiceAnswers: any = [...choiceAnswers, { value: '' }]
      setChoiceAnswers(updatedChoiceAnswers)
    }

    if (type === 'multiple') {
      const updatedMultiAnswers: any = [...multiAnswers, { value: '' }]
      setMultiAnswers(updatedMultiAnswers)
    }
  }

  const handleRemoveAnswerClick = (type: string, index: number) => {
    if (type === 'single') {
      const updatedChoiceAnswers: any = [...choiceAnswers]
      updatedChoiceAnswers.splice(index, 1)
      setChoiceAnswers(updatedChoiceAnswers)

      if (index == selectedChoiceAnswer) {
        setSelectedChoiceAnswer(null)
      }
    }

    if (type === 'multiple') {
      const updatedMultiAnswers: any = [...multiAnswers]
      updatedMultiAnswers.splice(index, 1)
      setMultiAnswers(updatedMultiAnswers)

      if (selectedMultiAnswers.includes(index.toString())) {
        const ind = selectedMultiAnswers.indexOf(index.toString())
        const updatedMultiAnswers = [...selectedMultiAnswers]
        updatedMultiAnswers.splice(ind, 1)
        setSelectedMultiAnswers(updatedMultiAnswers)
      }
    }
  }

  const handleAnswerChange = (e: any, type: string, index: number) => {
    const { name, value } = e.target

    if (type === 'single') {
      const updatedChoiceAnswers: any = [...choiceAnswers]
      updatedChoiceAnswers[index].value = value
      setChoiceAnswers(updatedChoiceAnswers)
    }

    if (type === 'multiple') {
      const updatedMultiAnswers: any = [...multiAnswers]
      updatedMultiAnswers[index].value = value
      setMultiAnswers(updatedMultiAnswers)
    }

    // const isAnswersEmpty = checkAnswers(type)
    // if (isAnswersEmpty) {
    //   setIsValid(false)
    // } else {
    //   setIsValid(true)
    // }
  }

  const handleRemoveModuleClick = (moduleId: string) => {
    const updatedSelectedModules = selectedModules.filter(
      (selectedModule: any) => selectedModule.moduleId !== moduleId,
    )

    setSelectedModules(updatedSelectedModules)
  }

  const handleSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    if (type === 'single') {
      const { value } = e.target
      setSelectedChoiceAnswer(value)
    }

    if (type === 'multiple') {
      const value = e
      const index = selectedMultiAnswers.indexOf(value)
      if (index > -1) {
        const updatedMultiAnswers = [...selectedMultiAnswers]
        updatedMultiAnswers.splice(index, 1)
        setSelectedMultiAnswers(updatedMultiAnswers)
      } else {
        setSelectedMultiAnswers([...selectedMultiAnswers, value])
      }
    }
  }

  const cropperModalToggle = () => {
    setCropperOpen(!cropperOpen)
  }

  const handleCropSave = (field: string, croppedFile: string) => {
    setCropperImage('')
    const index = uploadedFiles.length
    setUploadedFiles([
      ...uploadedFiles,
      {
        type: 'image',
        link: '',
        uploading: true,
      },
    ])
    uploadImage(croppedFile, (field = 'questions/images'), (link: string) => {
      const updatedFiles = [...uploadedFiles]
      updatedFiles[index] = {
        type: 'image',
        link,
        uploading: false,
      }
      setUploadedFiles(updatedFiles)
    })
  }

  useEffect(() => {
    if (data) {
      const {
        question,
        type,
        tags,
        reason,
        boolAnswer,
        answers,
        modules,
      } = data.question
      const formData: any = {
        question,
        type: { label: type.label, value: type.value },
        tags:
          tags.map((tag: any) => ({
            label: tag.label,
            value: tag.label,
          })) || [],
        reason,
      }
      setValues(formData)

      if (type.value === 'boolean') {
        setBoolAnswer(boolAnswer)
        formData.boolAnswer = boolAnswer
      }

      if (type.value === 'single') {
        const choiceAnswers = answers.map((answer: any) => ({
          value: answer.value,
        }))
        const correct = answers.findIndex((answer: any) => answer.isCorrect)
        setChoiceAnswers(choiceAnswers)
        setSelectedChoiceAnswer(correct)
        formData.choiceAnswers = choiceAnswers
      }

      if (type.value === 'multiple') {
        const multiAnswers = answers.map((answer: any) => ({
          value: answer.value,
        }))

        const correctAnswers = answers.reduce(
          (ids: any, answer: any, index: number) => {
            if (answer.isCorrect) {
              ids.push(index)
            }

            return ids
          },
          [],
        )

        setMultiAnswers(multiAnswers)
        setSelectedMultiAnswers(correctAnswers)
        formData.multiAnswers = multiAnswers
      }

      setSelectedModules(
        modules.map((moduleItem: any) => ({
          moduleId: moduleItem.moduleId,
          name: moduleItem.name,
        })),
      )

      setcurrentQuestion(formData)

      let files: any = []
      // question.images.map((image: string) =>
      //   files.push({ type: 'image', link: image, uploading: false }),
      // )
      // question.documents.map((document: any) =>
      //   files.push({ type: 'document', ...document, uploading: false }),
      // )
      // question.videos.map((video: any) =>
      //   files.push({ type: 'video', ...video, uploading: false }),
      // )
      // question.audios.map((audio: any) =>
      //   files.push({ type: 'audio', ...audio, uploading: false }),
      // )

      for (const key in data.question) {
        if (
          ['videos', 'audios', 'images', 'documents'].includes(key) &&
          data.question[key].length > 0
        ) {
          files = [
            ...files,
            ...data.question[key].map(({ __typename, ...args }: any) => ({
              ...args,
              type: key,
            })),
          ]
        }
      }

      setUploadedFiles(files)
    }
  }, [data])

  const handleCloseDrawer = () => {
    const { type, ...formData }: any = values
    const { type: questionType, ...currentQuestionData }: any = currentQuestion
    if (multiAnswers.length > 0) {
      formData.multiAnswers = multiAnswers
    }
    if (choiceAnswers.length > 0) {
      formData.choiceAnswers = choiceAnswers
    }
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editQuestion',
        values: formData,
        compareTo: currentQuestionData,
      },
    })
  }
  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  const fieldsValid = checkAnswers(values.type.value)
  const selectedValid = checkSelectedAnswers(values.type.value)

  // const submitBtnDisabled =
  //   !values.question || !fieldsValid || !selectedValid || !changed

  // console.log('FILES', uploadedFiles)
  const theme = useTheme()
  return (
    <>
      {(loading || editQuestionLoading || createQuestionLoading) && (
        <Loader withBackground={loading ? true : false} />
      )}
      <Drawer
        opened={linkModuleDrawerOpened}
        toggleDrawer={() => setLinkModuleDrawerOpened(false)}
        totalWidth="650px"
      >
        <AddModuleToQuestionDrawer
          title="Link Modules to the Question"
          closeDrawer={() => setLinkModuleDrawerOpened(false)}
          selectedModules={selectedModules}
          setSelectedModules={setSelectedModules}
        />
      </Drawer>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{title}</DrawerTitle>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent>
        <FormContainer>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <label>{t('general.type')}*</label>
                <SelectField
                  onChange={(e: any) => handleSelectChange('type', e)}
                  options={QuestionTypes}
                  value={values.type}
                  placeholder={t('form_fields.select')}
                />
              </FormField>
              <FormField>
                <SelectField
                  isMulti
                  onChange={(e: any) => {
                    handleSelectChange('tags', e)
                  }}
                  options={tagList}
                  value={values.tags}
                  placeholder={t('general.tags')}
                />
              </FormField>
              <FormField style={{ display: 'flex' }}>
                <UploadContent
                  setUploadedFiles={setUploadedFiles}
                  uploadedFiles={uploadedFiles}
                />
              </FormField>
              <FormField>
                <TextInput
                  error={errors.question ? true : false}
                  errorMessage={errors.question ? errors.question : ''}
                  touched={touched.question}
                  label={`${t('general.question')}*`}
                  size="medium"
                  name="question"
                  type="text"
                  value={values.question}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows="2"
                />
              </FormField>
              <FormField>
                <QuestionItem
                  type={values.type.value}
                  boolAnswer={boolAnswer}
                  choiceAnswers={choiceAnswers}
                  multiAnswers={multiAnswers}
                  selectedChoiceAnswer={selectedChoiceAnswer}
                  selectedMultiAnswers={selectedMultiAnswers}
                  handleSelect={handleSelect}
                  onBoolChange={handleBoolChange}
                  handleAddAnswerClick={handleAddAnswerClick}
                  handleRemoveAnswerClick={handleRemoveAnswerClick}
                  handleAnswerChange={handleAnswerChange}
                  fieldsValid={fieldsValid}
                  selectedValid={selectedValid}
                />
              </FormField>
              <FormField>
                <TextInput
                  label={`${t('question_details.reason')}*`}
                  size="medium"
                  name="reason"
                  type="text"
                  value={values.reason}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows="3"
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            {!currentModule.duplicate && (
              <ModuleWrapper>
                <Wrapper>
                  <article style={{ marginRight: 5 }}>
                    {t('question_details.linked_to_modules')}:
                  </article>
                  <Modules>
                    {selectedModules.map((selectedModule: any) => (
                      <ModuleItemWrapper key={selectedModule.moduleId}>
                        <ModuleItem>{selectedModule.name}</ModuleItem>
                      </ModuleItemWrapper>
                    ))}
                  </Modules>
                </Wrapper>
              </ModuleWrapper>
            )}

            {currentModule.duplicate && (
              <ModuleWrapper>
                <Wrapper>
                  <article style={{ marginRight: 5 }}>
                    {t('question_details.linked_to_modules')}: :
                  </article>
                  {currentModule.moduleId && (
                    <Modules>
                      <ModuleItemWrapper>
                        <ModuleItem>{currentModule.name}</ModuleItem>
                      </ModuleItemWrapper>
                    </Modules>
                  )}
                </Wrapper>
              </ModuleWrapper>
            )}
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.save')}
                type="medium"
                color="secondary"
                onClick={handleSubmit}
                background={'#317BF4'}
                buttonStyles={{
                  borderRadius: '8px',
                }}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
      <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
        <ImageCropper
          file={cropperImage}
          hideCropper={cropperModalToggle}
          getCroppedFile={handleCropSave}
        />
      </Modal>
    </>
  )
}

export default EditQuestionDrawer

const ModuleWrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  svg {
    color: #d2364b;
    cursor: pointer;
  }
`

const HelpButtonContainer = styled.div`
  width: 43px;
  display: flex;
  align-items: center;
  padding: 0 0px 0 14px;
  svg {
    cursor: pointer;
  }
`
