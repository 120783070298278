import { useMutation } from '@apollo/react-hooks'
import { SET_REJECTION_STATE } from 'gql/rejections/rejections.query'

export const setRejectionStateService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [setRejectionStateMutation, { loading, error }] = useMutation(
    SET_REJECTION_STATE,
  )

  return {
    setRejectionStateMutation,
    loading,
    error,
  }
}
