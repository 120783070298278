import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Box, useTheme, useMediaQuery } from "@material-ui/core";


import Drawer from "components/common/Drawer";
import { useQuery } from '@apollo/react-hooks'
import TextEditor from "components/common/TextEditor/TextEditor";
import { CloseIcon, DrawerContent, DrawerHeader, DrawerTitle, Info } from "components/DrawerLayout/styled-components";
import { useUserValue } from "context/UserContext";
import { UPDATE_USER } from "gql/users.query";
import { useParams } from "react-router-dom"
import React, { useEffect, useState } from "react";
import { GET_USER } from 'gql/users.query'
import { useTranslation } from 'react-i18next'


const BioDrawer = (props: any) => {

  const { t } = useTranslation()
  // const [userData, setUserData] = useState()

  const params: { id: string } = useParams()
  let { id } = params
  const [state] = useUserValue();

  const [updateUserBio, { loading }] = useMutation(UPDATE_USER)

  const [getUser, { data: userData }] = useLazyQuery(GET_USER, {
    variables: {
      id: id
    }
  })
  useEffect(() => {
    getUser()
  }, [params])



  const handleBioUpdate = async (editorText: any) => {



    const { data } = await updateUserBio({
      variables: {
        id: id,
        input: {
          firstName: userData?.user.firstName,
          lastName: userData?.user.lastName,
          email: userData?.user.email,
          biography: editorText
        }
      }
    })
  }
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Drawer
      totalWidth={isSmallScreen ? "960px" : "100%"}
      opened={props.open}
      toggleDrawer={() => props.onClose()}
    >
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{t('general.biography')}</DrawerTitle>
        <CloseIcon
          onClick={() => props.onClose()}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <Box style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <TextEditor
            biography
            text={userData?.user.biography}
            hasControls={false}
            onSave={(editorText: any, plainText: any) => {
              handleBioUpdate(editorText)
              props.onClose()
            }}
            canEdit={true}
            style={{
              width: '100%',
              '.DraftEditor-root': {
                height: '600px!important',
              },
            }}
          />
        </Box>
      </DrawerContent>
    </Drawer>
  );
}

export default BioDrawer;
