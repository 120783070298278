import React, { useState, useEffect, useCallback } from 'react'
import Stepper from 'components/common/Stepper'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

import { useFormik } from 'formik'
import styled, { css } from 'styled-components'
import { Button } from 'components/common/Button'
import * as Yup from 'yup'
import { useAction } from 'store/actions'
import { useData } from 'context/DataContext'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'
import { QuizSchema, IQTestSchema } from 'helpers/validationSchemas'
import { formData } from 'pages/quizzes/staticData'

import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import { DrawerContent } from 'components/DrawerLayout/styled-components'
import { GET_QUESTIONS } from 'gql/questions.query'

import useCreateQuizService from 'hooks/quizzes/useCreateQuizService'

import Step1 from './Step1'
import Step2 from './Step2'
import { useLocation } from 'react-router-dom'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { CloseIcon, Info } from 'components/common/Modal/Modal'

const GET_QUESTIONS_BY_COURSE = gql`
  query($courseIds: [String]) {
    questionsByCourseIds(courseIds: $courseIds) {
      id
      edited
      type {
        label
        value
      }
      question
      tags {
        id
        label
      }
      answers {
        isCorrect
        value
      }
      boolAnswer
      modules {
        moduleId
        name
      }
      images {
        name
        link
        fileType
      }
      videos {
        name
      }
      documents {
        name
      }
      audios {
        name
      }
    }
  }
`

const AddQuizDrawer = (props: any) => {
  const { t } = useTranslation()
  const validationSchema = props.data.IQTest ? IQTestSchema : QuizSchema
  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()
  const [step, setStep] = useState<number>(0)
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [validation, setValidation] = useState<any>(validationSchema)
  const currentLocation = useLocation()
  const isOnQuestionsPage = currentLocation.pathname.indexOf('questions') > -1

  const { createQuiz, loading: createQuizLoading } = useCreateQuizService({
    type: props.type,
    actionId: props.id,
  })

  const [fetchModuleQuestions, { data: moduleQuestions }] = useLazyQuery(
    GET_QUESTIONS,
  )
  const [fetchCourseQuestions, { data: courseQuestions }] = useLazyQuery(
    GET_QUESTIONS_BY_COURSE,
  )

  useEffect(() => {
    if (props.type === 'course') {
      fetchCourseQuestions({
        variables: {
          courseIds: [props.id],
        },
      })
    } else {
      fetchModuleQuestions({
        variables: {
          type: props.type,
          id: props.id,
          filter: {},
          currentPage: 1,
          perPage: 12,
        },
      })
    }
  }, [props.id, props.type])

  const questionList =
    props.type === 'course'
      ? (courseQuestions && courseQuestions.questionsByCourseIds) || []
      : (moduleQuestions && moduleQuestions.getQuestions.data) || []

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: Yup.object(validation),
    onSubmit: (values: any) => {
      if (step === 0) {
        setStep(1)
        return
      }

      if (createQuizLoading) return

      const data = { ...values }
      data.questions = selectedItem

      if (!data.time || data.time === 0) {
        data.time = 0
        delete data.timeType
      }

      if (data.quizParticipationType === 'optional') delete data.passRate

      if (data.tags && data.tags.length > 0) {
        data.tags = data.tags.map((i: any) => ({ label: i.label }))
      }

      if (!props.data.IQTest) {
        data.position = values.position.value
      } else {
        delete data.position
      }

      if (!data.randomization) data.randomizationNumber = 0

      if (props.data.IQTest) {
        data.IQTestType = 'initial'
      }

      data.parentName = props.data.name
      createQuiz(data, props.type, props.id, props.data.companyId, onSuccess)
    },
  })

  const onSuccess = useCallback(() => {
    !isOnQuestionsPage && props.data.refetch()
    props.onClose()
    dispatch({ type: CLEAR_FORMDATA })
    toggleDrawerConfirm(false, '')
  }, [props.data, props.onClose, isOnQuestionsPage])

  const handleCloseDrawer = () => {
    const { quizParticipationType, position, ...formData } = values

    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'addQuiz',
        values: formData,
        compareTo: {},
      },
    })
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  const { time, quizParticipationType, randomization } = values

  useEffect(() => {
    if (time) {
      setValidation({
        ...validation,
        timeType: Yup.string().required(
          `${t('quiz_details.pass_rate')} ${t('validations.required')}`,
        ),
      })
    } else {
      setValidation(validationSchema)
    }
  }, [time])

  useEffect(() => {
    if (randomization) {
      setValidation({
        ...validation,
        randomizationNumber: Yup.number()
          .max(
            questionList.length,
            `${t('validations.number_of_questions')} ${questionList.length}`,
          )
          .required(
            `${t('quiz_details.number_of_questions')} ${t(
              'validations.required',
            )}`,
          ),
      })
    } else {
      setValidation(validationSchema)
      delete errors.randomizationNumber
      values.randomizationNumber = ''
      delete touched.randomizationNumber
    }
  }, [randomization])

  useEffect(() => {
    if (quizParticipationType === 'mandatory') {
      setValidation({
        ...validation,
        passRate: Yup.number().required('Pass Rate is Required'),
      })
    } else {
      setValidation(validationSchema)
    }
  }, [quizParticipationType])

  let addedIQTestType = ''

  if (props.type === 'course') {
    addedIQTestType =
      props.data.quizzes.length > 0 ? props.data.quizzes[0].IQTestType : ''
  }

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            errors={errors}
            values={values}
            touched={touched}
            handleChange={handleChange}
            IQTest={props.data.IQTest}
            setFieldValue={setFieldValue}
            type={props.type}
            addedIQTestType={addedIQTestType}
            id={props.id}
            questionListSize={questionList.length}
            t={t}
          />
        )
      case 1:
        return (
          <Step2
            id={props.id}
            type={props.type}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            questionList={questionList}
            randomization={randomization}
            randomizationNumber={values.randomizationNumber}
            t={t}
          />
        )
      default:
        return 'Error'
    }
  }

  const firstTab: string =
    props.type === 'course'
      ? t('quiz_details.iq_test_details')
      : t('quiz_details.quiz_details')

  const disabledButton = randomization
    ? !!(
      ((values.randomizationNumber && values.randomizationNumber) || 0) >
      selectedItem.length
    )
    : false

  return (
    <Container>
      <Header>
        <Title>
          {props.data.IQTest
            ? t('quizzes_layout.create_iq_test')
            : t('quizzes_layout.create_quiz')}
        </Title>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </Header>

      <StepperContainer>
        <Stepper
          active={step}
          steps={[firstTab, t('general.questions')]}
          ColorlibConnector={() => <></>}
          StepIconComponent={(props: any) => {
            const icons: { [index: string]: React.ReactElement } = {
              1: <>1</>,
              2: <>2</>,
            }

            return (
              <SWizardStepper active={props.active as boolean}>
                {icons[String(props.icon)]}
              </SWizardStepper>
            )
          }}
        />
      </StepperContainer>

      <DrawerContent
        style={{
          height: 'calc(100% - 168px)',
          margin: `50px ${step === 1 ? '30' : '150'}px`,
        }}
      >
        <FormContainer onSubmit={(e: any) => e.preventDefault()}>
          <FormGroupWrapper>{getStepContent(step)}</FormGroupWrapper>

          <FormFooter>
            <FormButtons multiCols={true}>
              <div />
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />

              <Button
                text={step === 0 ? t('actions.next') : t('actions.save')}
                type="medium"
                color="secondary"
                onClick={handleSubmit}
                isDisabled={step === 1 ? disabledButton : false}
                background={'#317BF4'}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </Container>
  )
}

export default AddQuizDrawer

const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
`
const StepperContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiPaper-root {
    background: transparent !important;
  }
`

const Header = styled.header`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 2;
  background: #fff;
  height: 166px;
  border-bottom: 1px solid #ebebeb;
`
const Title = styled.div`
  font-size: 2em;
  font-weight: normal;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 10px;
`

const SWizardStepper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 42px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #080a0a14;
  box-shadow: none;
  color: #707070;
  font-size: 20px;
  transition: 0.3s;
  border-radius: 100%;

  ${({ active }) =>
    active &&
    css`
      width: 54px;
      height: 54px;
      background: #317bf4;
      color: #ffffff;
      font-size: 28px;
      &::after {
        content: '';
        position: absolute;
        border: 1px solid #707070;
        border-collapse: separate;
        border-radius: 100%;
        width: 60px;
        height: 60px;
      }
    `}
`
