import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styled, { ThemeConsumer } from 'styled-components'

import CompanyReport from './CompanyReport/CompanyReport'
// import StudentsReport from './StudentsReport/StudentsReport'
// import CoachesReport from './CoachesReport/CoachesReport'
// import CoursesReport from './CoursesReport/CoursesReport'

import FilterBar from './FilterBar/FilterBar'
import TotalStatistics from './TotalStatistics/TotalStatistics'
import NewCompanies from './CompanyReport/NewCompanies'

// import UsersStatistics from './UsersStatistics/UsersStatistics'
// import TopCoaches from './UsersStatistics/TopCoaches'
// import TopStudents from './UsersStatistics/TopStudents'
import { useUserValue } from 'context/UserContext'
import ChartLayout from './ChartLayout/ChartLayout'
import { useData } from 'context/DataContext'
import { makeStyles, useTheme } from '@material-ui/core'


const DashboardLayout = () => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const history = useHistory()
  const theme = useTheme();
  const [companyFilter, setCompanyFilter] = useState({
    value: 'All',
    label: 'all',
  })
  const [date, setDate] = useState({
    from: null,
    to: null,
  })

  const [dateRange, setDateRange] = useState({ start: null, end: null })


  useEffect(() => {
    if (state.currentUser.companyId) {
      history.push(`Companies/${state.currentUser.companyId.id}`)
    }
  }, [state.currentUser])

  const handleDateSelect = (value: any, states: any) => setDateRange(value)

  return (
    <Container>
      <StatisticsContainer>
        <FilterContainer theme={theme}>
          <FilterBar
            setCompanyFilter={setCompanyFilter}
            companyFilter={companyFilter}
            date={date}
            setDate={setDate}
            handleDateSelect={handleDateSelect}
            dateRange={dateRange}
            t={t}
          />
        </FilterContainer>

        {/* <TileContainer>
          <TotalStatistics
            companyFilter={companyFilter}
            dateRange={dateRange}
            t={t}
          />
        </TileContainer> */}

        <ChartContainer theme={theme}>
          {/* <CompanyReportContainer>
            <CompanyReport />
          </CompanyReportContainer>

          <CompanyReportContainer>
            <NewCompanies />
          </CompanyReportContainer> */}

          <ChartLayout ></ChartLayout>

        </ChartContainer>

      </StatisticsContainer>



      {/* <RatingContainer>

        <RatingContainerWrapper>

          <TopUserContainer>
            <UsersStatistics
              companyFilter={companyFilter}
              dateRange={dateRange}

            />
          </TopUserContainer>

          <TopUserContainer>
            <TopCoaches
              companyFilter={companyFilter}
              dateRange={dateRange}
            />
          </TopUserContainer>
        </RatingContainerWrapper>

        <TopUserContainer style={{ marginTop: '20px' }}>
          <TopStudents
            companyFilter={companyFilter}
            dateRange={dateRange}
          />
        </TopUserContainer>

      </RatingContainer> */}
    </Container>
  )
}

export default DashboardLayout

const Container = styled.div`
  /* display: grid;
  grid-template-columns: 1.1fr 0.9fr;
  grid-column-gap: 25px; */
`

const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const FilterContainer = styled.div`
  padding: 22px 55px;
  background: ${props => props.theme.palette.background.default};
  border-radius: 12px;
`

// const RatingContainer = styled.div`
//   display: flex;
//   flex-direction: column;
// `

const TileContainer = styled.div`
  margin-top: 20px;
`
const CompanyReportContainer = styled.div`
  width: 50%;
  margin-top: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  padding: 13px 0;
  border-radius: 3px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`

// const RatingContainerWrapper = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   grid-column-gap: 25px;
// `

// const TopUserContainer = styled.div`
//   background: #fff;
//   box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
//     0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
//   /* height: 500px; */
// `

const ChartContainer = styled.div`
  width: 100%;
  background: ${props => props.theme.palette.background.default};
  height: calc(100vh - 420px);
  margin-top: 20px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  display: flex;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
