import React from 'react'

import styled from 'styled-components'

import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'

export const actionConfig = (
  actionHandler: any,
  mode?: boolean,
  canModify?: boolean,
  t?: any,
  isCompanySuperAdmin?: any,
  isCompanyAdmin?: any,
) => {
  const view = {
    hide: false,
    render: (item: any) => (
      <Action onClick={() => actionHandler('view', item)}>
        <ViewCompactIcon />
        <span>{t('actions.view')}</span>
      </Action>
    ),
  }

  let action = [
    {
      hide: !canModify || isCompanySuperAdmin || isCompanyAdmin,
      render: (item: any) => (
        <Action onClick={() => actionHandler('edit', item)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify || isCompanySuperAdmin || isCompanyAdmin,
      render: (item: any) => (
        <Action onClick={() => actionHandler('delete', item)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
  ].filter((i: any) => !i.hide)

  if (!mode) {
    action = [view, ...action]
  }

  return action
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`
