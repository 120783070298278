import React from 'react'

import {
  useFetchRejection,
  useSetRejectionState,
} from 'hooks/rejections/useRejections'
import { useSwal } from 'hooks/useSwal'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import {
  CapitalizeWrapper,
  RejectionContent,
  RejectionStateWrapper,
} from './styled-components'
import Loader from 'components/common/Loader'
import RejectionDetailContent from './RejectionDetailContent'
import { mainColor } from 'components/common/Colors/CommonColors'
import { useTheme } from '@material-ui/core'

interface Params {
  onClose: any
  data: any
  notificationView?: boolean
}

const RejectionDetailsDrawer = ({ onClose, data, notificationView }: Params) => {
  const { fireSwal } = useSwal()
  const { rejection, error: fetchRejectionError, refetch } = useFetchRejection(
    data.actionId,
  )


  const { setRejectionState, error, loading } = useSetRejectionState()

  const handleApproveRejectRejectionClick = (action: string) => {

    let title = ''
    let confirmText = ''

    if (action === 'APPROVE') {
      title = 'Are You Sure you want to Approve ?'
      confirmText = 'Approve'
    }

    if (action === 'REJECT') {
      title = 'Are You Sure you want to Reject?'
      confirmText = 'Reject'
    }

    fireSwal({
      title,
      text: 'text',
      confirmText: `Yes, ${confirmText}`,
      onConfirm: () => {
        setRejectionState(rejection.id, action, refetch)
      },
    })
  }

  const rejectionType = data.message.split(' ').slice(-1)[0]

  const renderTitle = (actionName: 'Post' | 'User') => {
    let title: string = ''

    if (actionName === 'Post') {
      title = `${data.actionName} Marked as ${rejectionType}`
    }
    if (actionName === 'User') {
      title = `User Reported`
    }

    return title
  }

  const theme = useTheme();

  return (
    <>
      {loading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{!notificationView ? renderTitle(data.actionName) : data.actionName + " " + data.message}</DrawerTitle>
        <IconButton onClick={onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        {rejection && (
          <FormContainer>
            <FormGroupWrapper>
              <RejectionContent>
                <RejectionDetailContent data={rejection} />
              </RejectionContent>
            </FormGroupWrapper>
            <FormFooter>
              <FormButtons>
                {rejection.state === 'PENDING' ? (
                  <>
                    <Button
                      variant="outlined"
                      size="small"
                      style={{
                        color: mainColor,
                        border: '1px solid #317BF4',
                        marginRight: '10px',
                      }}
                      onClick={() =>
                        handleApproveRejectRejectionClick('APPROVE')
                      }
                    >
                      Accept
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() =>
                        handleApproveRejectRejectionClick('REJECT')
                      }
                    >
                      Reject
                    </Button>
                  </>
                ) : (
                  <RejectionStateWrapper
                    color={
                      rejection.state === 'APPROVED' ? mainColor : '#D2364B'
                    }
                  >
                    This Report is Marked as{' '}
                    <CapitalizeWrapper>
                      {rejection.state.toLowerCase()}
                    </CapitalizeWrapper>
                  </RejectionStateWrapper>
                )}
              </FormButtons>
            </FormFooter>
          </FormContainer>
        )}

        {notificationView && (
          <FormContainer>
            <FormGroupWrapper>
              <RejectionContent>
                <RejectionDetailContent data={data} notificationView={true} />
              </RejectionContent>
            </FormGroupWrapper>

          </FormContainer>
        )}
        {fetchRejectionError && !notificationView && (
          <div style={{ padding: 10 }}>{fetchRejectionError}</div>
        )}
      </DrawerContent>
    </>
  )
}

export default RejectionDetailsDrawer
