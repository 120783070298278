import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useAddCourseModules } from 'hooks/course/useEditCourse'
import { useFetchModules } from 'hooks/modules/useModules'
import { SET_FORMDATA, TOGGLE_CLOSE_DRAWER } from 'store/types'
import FilterDrawer from 'pages/modules/FilterDrawer/FilterDrawer'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import DashboardIcon from '@material-ui/icons/Dashboard'
import FilterListIcon from '@material-ui/icons/FilterList'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import SyncIcon from '@material-ui/icons/Sync'
import ViewListIcon from '@material-ui/icons/ViewList'
import { Button } from 'components/common/Button'
import { CardsLayout } from 'components/common/Cards'
import { GridSwitchButtons } from 'components/common/Cards/styled-components'
import Drawer from 'components/common/Drawer'
import {
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import Modal from 'components/common/Modal'
import Pagination from 'components/common/Pagination'
import TextInput from 'components/common/TextInput/TextInput'
import VideoPlayer from 'components/common/VideoPlayer'
import {
  CloseIcon,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Info,
} from 'components/DrawerLayout/styled-components'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import Grid from 'components/common/Grid'
import { columnConfig } from 'pages/modules/gridConfig'
import { useTheme } from '@material-ui/core'

interface Props {
  title: string
  drawerName?: string
  closeDrawer?: () => void
  data: any
}

const filterOption: any = {
  name: '',
  // duration: '',
  // courses: [],
  coverImage: { label: 'All', id: 0, value: 'all' },
  video: { label: 'All', id: 0, value: 'all' },
  attachment: { label: 'All', id: 0, value: 'all' },
  range: [0, 5000],
}

const AddCourseModuleDrawer = ({ title, drawerName, data }: Props) => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()

  let company: any = {
    companyId: {
      type: 'exact',
      value: data.manualCompanyId
        ? data.manualCompanyId
        : state.selectedCompanyId,
    },
  }

  // if (state.selectedCompanyId) {
  //   company.companyId = {
  //     type: 'exact',
  //     value: data.manualCompanyId
  //       ? data.manualCompanyId
  //       : state.selectedCompanyId,
  //   }
  // }
  const id = data.courseId
  const { modules, loading, refetch }: any = useFetchModules({
    name: { type: 'match', value: '' },
    course: { type: 'nestedArrayNotIn', value: id },
    ...company,
  })

  const {
    addCourseModules,
    addCourseModulesLoading,
    newModule,
  } = useAddCourseModules({
    filter: {
      name: {
        type: 'match',
        value: '',
      },
      course: {
        type: 'nestedArrayIn',
        value: id,
      },
      ...company,
    },
  })

  const [dataState, dispatchData] = useData()
  const [filterData, setFilterData] = useState<any>(filterOption)
  const [filtered, setFiltered] = useState<boolean>(false)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [gridStyle, setGridStyle] = useState<string>('card')
  const [videoModal, setVideoModal] = useState({ modal: false, data: '' })
  const [selectedItem, setSelectedItem] = useState<any>([])
  const [selectAll, setSelectAll] = useState(false)
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage] = useState<number>(12)

  const selectItem = (id: string, event: boolean) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    if (newSelected.length > 0) {
      dispatchData({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
    } else {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: '',
          confirm: false,
        },
      })
    }

    setSelectedItem(newSelected)
  }

  const selectAllItem = () => {
    if (!selectAll) {
      const newArr: string[] = modules.data.map((n: any) => n.id)
      setSelectedItem(newArr)
      setSelectAll(true)
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
      return
    }
    dispatch({
      type: TOGGLE_CLOSE_DRAWER,
      payload: {
        text: '',
        confirm: false,
      },
    })
    setSelectedItem([])
    setSelectAll(false)
  }

  const handleCloseDrawer = () => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'addCourseModule',
        values: selectedItem,
        compareTo: {},
      },
    })
    // if (selectedItem.length === 0) {
    // DrawerEventEmitter.emit('openDrawer', { drawerName }, false)
    // } else {
    //   fireSwal({
    //     title:
    //       t('popups.close_popup'),
    //     text: t('popups.sure'),
    //     confirmText: 'Yes, Cancel!',
    //     onConfirm: () =>
    //       DrawerEventEmitter.emit('openDrawer', { drawerName }, false),
    //   })
    // }
  }

  const handleSubmit = () => {
    const moduleIds = selectedItem
    addCourseModules(
      { courseId: data.courseId, name: data.name },
      moduleIds,
      () => {
        DrawerEventEmitter.emit('openDrawer', 'addCourseModule', false)
        data.refetchCourse()
      },
    )
  }

  const handleSearchChange = (e: any) => {
    setSearchValue(e.target.value)

    if (e.target.value === '') {
      refetch({
        filter: {
          name: {
            type: 'match',
            value: '',
          },
          course: {
            type: 'nestedArrayNotIn',
            value: id,
          },
          ...company,
        },
      })
    }
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    refetch({
      filter: {
        name: {
          type: 'match',
          value: searchValue,
        },
        course: {
          type: 'nestedArrayNotIn',
          value: id,
        },
        ...company,
      },
    })
  }

  const handleResetClick = () => {
    refetch({
      filter: {
        name: { type: 'match', value: '' },
        course: { type: 'nestedArrayNotIn', value: id },
        ...company,
      },
      currentPage: 1,
      perPage,
    })
    setFiltered(false)
    setSearchValue('')
    setFilterQuery({})
  }
  const openPdf = (item: any) => {
    item.attachment && window.open(item.attachment, '_blank')
  }

  const handleVideoModal = (item: any) => {
    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? item.video[0].links[2].url : '',
    })
  }

  const handlePaginationClick = (event: any, value: number) => {
    if (value !== currentPage) {
      refetch({
        filter: {
          course: { type: 'nestedArrayNotIn', value: id },
          query: {
            type: 'query',
            value: JSON.stringify(filterQuery),
          },
          ...company,
        },
        currentPage: value,
        perPage,
      })
      setCurrentPage(value)
    }
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (newModule) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: '',
          confirm: false,
        },
      })
      refetch({
        filter: {
          name: {
            type: 'match',
            value: '',
          },
          course: {
            type: 'nestedArrayNotIn',
            value: id,
          },
          ...company,
        },
      })
      setSelectedItem([])
    }
  }, [newModule])
  const config = columnConfig(selectItem, selectItem, t)

  const theme = useTheme()

  return (
    <>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => setFilterDrawerOpened(false)}
        totalWidth="500px"
      >
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterQuery={setFilterQuery}
          perPage={perPage}
          refetchData={refetch}
          onClose={() => setFilterDrawerOpened(false)}
          setFiltered={setFiltered}
          courseId={id}
          type="nestedArrayNotIn"
          t={t}
        />
      </Drawer>
      <DrawerHeader theme={theme} style={{ position: 'relative' }}>
        <DrawerTitle theme={theme}>{title}</DrawerTitle>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent>
        <ModulesList>
          <FormFieldsWrapper>
            <Header
              style={{
                marginTop: 20,
                paddingBottom: 8,
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title>{t('modules_layout.title')}</Title>
                <SelectedWrapper
                  className={selectedItem.length === 0 ? 'disabled' : ''}
                >
                  <strong>{selectedItem.length} </strong>
                  {t('general.items_selected')}
                </SelectedWrapper>
              </div>
              {/* <Toolbar>
                <div style={{ display: 'flex' }}>
                  <MultipleAction>
                    <IconButton
                      disabled={filtered || searchValue ? false : true}
                      onClick={handleResetClick}
                    >
                      <SyncIcon fontSize="small" />
                    </IconButton>
                    <MultipleActionTooltip>
                      {t('actions.reset')}
                    </MultipleActionTooltip>
                  </MultipleAction>
                  <MultipleAction>
                    <IconButton onClick={() => setFilterDrawerOpened(true)}>
                      <FilterListIcon fontSize="small" />
                    </IconButton>
                    <MultipleActionTooltip>
                      {t('actions.filter')}
                    </MultipleActionTooltip>
                  </MultipleAction>
                  <form
                    onSubmit={handleSearchSubmit}
                    style={{ marginRight: 10 }}
                  >
                    <TextInput
                      label={t('general.search_placeholder')}
                      type="text"
                      size="small"
                      value={searchValue}
                      onChange={handleSearchChange}
                      icon={
                        <IconButton type="submit">
                          <SearchRoundedIcon />
                        </IconButton>
                      }
                    />
                  </form>
                  <GridSwitchButtons>
                    <button
                      className={`${gridStyle === 'card' ? 'active' : ''}`}
                      onClick={() => setGridStyle('card')}
                    >
                      <DashboardIcon />
                    </button>
                    <button
                      className={`${gridStyle === 'list' ? 'active' : ''}`}
                      onClick={() => setGridStyle('list')}
                    >
                      <ViewListIcon />
                    </button>
                  </GridSwitchButtons>
                </div>
              </Toolbar> */}
            </Header>
            {loading && <div>Loading...</div>}
            {modules && (
              <div style={{ padding: 10 }}>
                <Grid
                  title={t('courses_layout.title')}
                  data={modules.data}
                  selectItem={selectItem}
                  selectedItems={selectedItem || []}
                  config={config}
                  actionConfig={[]}
                  selectAllItem={() => { }}
                />
              </div>
            )}
            {modules && modules.data.length > 0 && (
              <div style={{ margin: '30px auto', width: 'max-content' }}>
                <Pagination
                  currentPage={modules ? modules.currentPage : 1}
                  totalPages={modules ? modules.totalPages : 0}
                  handleChange={handlePaginationClick}
                />
              </div>
            )}
          </FormFieldsWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              ></Button>
              <Button
                text={t('actions.add')}
                type="medium"
                onClick={handleSubmit}
                isDisabled={selectedItem.length === 0}
                background={'#317BF4'}
                buttonStyles={{
                  borderRadius: '8px',
                }}
              ></Button>
            </FormButtons>
          </FormFooter>
        </ModulesList>
      </DrawerContent>
      <Modal isOpened={videoModal.modal} onClose={handleVideoModal}>
        <VideoPlayer url={videoModal.data} />
      </Modal>
    </>
  )
}

export default AddCourseModuleDrawer

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 10px;
  position: sticky;
  z-index: 9999;
  top: 0;
  background: #fff;
`

export const Title = styled.h2`
  font-size: 22px;
  font-weight: 500;
`

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SelectedWrapper = styled.div`
  margin-left: 10px;
  padding-left: 10px;
  &.disabled {
    opacity: 0.5;
  }
`

const FormTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`

const FormFooter = styled.footer`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const FormFieldsWrapper = styled.div`
  flex: 2;
  overflow-y: scroll;
`

const ModulesList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const FormButtons = styled.div`
  align-self: flex-end;
  display: flex;

  div:not(:last-child) {
    margin-right: 10px;
  }
`
