import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { DrawerEventEmitter } from 'helpers/drawer'
import _ from 'lodash'
import { actionConfig, columnConfig } from './gridConfig'
import { makeStyles } from '@material-ui/core/styles'
import { useFetchModules, PER_PAGE } from 'hooks/modules/useModules'
import { useDeleteModuleService } from 'hooks/modules/useDeleteModuleService'
import { useSwal } from 'hooks/useSwal'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import FilterListIcon from '@material-ui/icons/FilterList'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTheme } from '@material-ui/core'

import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import SyncIcon from '@material-ui/icons/Sync'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ViewListIcon from '@material-ui/icons/ViewList'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Player from 'components/common/VideoPlayer/Player'
import FilterDrawer from './FilterDrawer/FilterDrawer'
import Drawer from 'components/common/Drawer'
import { GridSwitchButtons } from 'components/common/Cards/styled-components'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import ModulesList from './ModulesList'
import Pagination from 'components/common/Pagination'
import { mainColor } from 'components/common/Colors/CommonColors'
import {
  ActionButton,
  GridLayoutActions,
  GridLayoutForm,
  GridToolbar,
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import Icon from 'components/Icon'
import Search from 'components/common/Search'
import TablePagination from 'components/common/Pagination/TablePagination'
import Grid from 'components/common/Grid'

const toolbarStyle = {
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const ModulesLayout = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [state] = useUserValue()
  const classes = useStyles()
  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const [videoModal, setVideoModal] = useState({
    modal: false,
    data: '',
    subtitle: '',
  })
  const [filterData, setFilterData] = useState<any>({})
  const [gridStyle, setGridStyle] = useState('card')
  const [searchText, setSearchText] = useState<string>('')
  const [filtered, setFiltered] = useState(false)
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})
  const [fetchAllPagination, setFetchAllPagination] = useState([0, 10])
  const [page, setPage] = useState(1)
  const [fetchAll, setFetchAll] = useState(false)


  const { deleteModule, deletedModule } = useDeleteModuleService()
  const { fireSwal } = useSwal()

  const { modules, error, loading, refetch }: any = useFetchModules({
    companyId: {
      type: 'exact',
      value: state.selectedCompanyId,
    },
  })


  const { modules: allModules }: any = useFetchModules({
    companyId: {
      type: 'exact',
      value: state.selectedCompanyId,
    },
  }, 1, 1, true)


  const selectItem = (id: string) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const handleVideoModal = (data: any) => {
    if (!data.video) return

    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      subtitle: !videoModal.modal ? data.subtitle : '',
      data: !videoModal.modal ? data.video.links[0].url : '',
    })
  }

  const resetFilter = () => {
    // setFiltered(false)
    setFilterData({})
    refetch({
      filter: {
        companyId: {
          type: 'exact',
          value: state.selectedCompanyId,
        },
      },
      currentPage: 1,
      perPage,
    })
    setFilterIsOpen(false)
    setFilterQuery({})
    setSearchText('')
  }

  const filterDrawer = () => {
    setFilterIsOpen(!filterIsOpen)
  }

  const onSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    const searchValue = { type: 'search', value: searchText, fields: ["name"] }
    setCurrentPage(1)
    refetch({
      filter: {
        name: searchValue,
        // companyId: {
        //   type: 'exact',
        //   value: state.selectedCompanyId,
        // },
      },
      currentPage: 1,
      perPage,
    })
  }

  const downloadPdf = (e: any) => {
    if (!e.attachment) return
    window.open(e.attachment, '_blank')
  }

  const openDrawer = (id: string) => {
    DrawerEventEmitter.emit('openDrawer', 'editModuleDrawer', true, { id })
  }

  const handelOpenEditDrawer = (data: any) => {
    if (data.courses && data.courses.length > 0) {
      fireSwal({
        title: t('popups.edit_module'),
        confirmText: t('popups.ok'),
        onConfirm: () => openDrawer(data.id),
      })
      return
    }

    openDrawer(data.id)
  }

  const handleDeleteClick = (field: any, event?: any) => {
    if (event) {
      fireSwal({
        title:
          selectedItem['1'].length === 1
            ? t('popups.delete_module_single')
            : t('popups.delete_module_many'),
        confirmText: t('popups.confirm_delete'),
        onConfirm: () => {
          deleteModule(field)
          setSelectedItem({})
          setSelectAll({})
        },
      })

      return
    }

    fireSwal({
      title: t('popups.delete_module_single_from_action'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => {
        deleteModule([field.id])
        setSelectedItem({})
        setSelectAll({})
      },
    })
  }

  const handlePaginationClick = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {

    if (((page - newPage) === 1 || (page - newPage) === 0) && fetchAll) {
      setFetchAllPagination(prevState => [prevState[0] + 10, prevState[1] + 10])
      setPage(page + 1)
      return
    } else if (((page > newPage)) && fetchAll) {
      setFetchAllPagination(prevState => [prevState[0] - 10, prevState[1] - 10])
      setPage(page - 1)
      return
    }

    refetch({
      filter: {
        companyId: {
          type: 'exact',
          value: state.selectedCompanyId,
        },
        query: {
          type: 'query',
          value: JSON.stringify(filterQuery),
        },
      },
      currentPage: newPage + 1,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setPerPage(parseInt(event.target.value, 10))
    setCurrentPage(1)
  }

  useEffect(() => {
    refetch({
      filter: {
        companyId: {
          type: 'exact',
          value: state.selectedCompanyId,
        },
      },
      currentPage,
      perPage,
    })
  }, [perPage])

  useEffect(() => {
    if (deletedModule) {
      refetch()
    }
  }, [deletedModule])

  const actionHandler = (event: string, field: any, action: string) => {
    switch (event) {
      case 'view':
        history.push(`/modules/${field}`)
        break
      case 'edit':
        return handelOpenEditDrawer(field)
        break
      case 'delete':
        handleDeleteClick(field)
        break
      case 'pdf':
        return downloadPdf(field)
      default:
        return () => console.log('null')
    }
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = allModules?.data.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const isStudent = state.currentUser.role === 'STUDENT'

  const isCompanySuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !!state?.currentUser?.companyId?.id

  const isCompanyAdmin =
    state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  const isSuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !state?.currentUser?.companyId?.id

  const isAdmin =
    state?.currentUser?.role === 'ADMIN' && !state?.currentUser?.companyId?.id

  const canModify =
    (((isCompanySuperAdmin || isCompanyAdmin) &&
      modules?.data[0]?.companyId === state?.currentUser?.companyId?.id) ||
      isSuperAdmin ||
      isAdmin) &&
    !isStudent

  const handleClick = (moduleid: string) => {
    history.push(`/modules/${moduleid}`)
  }

  const theme = useTheme()


  const config = columnConfig(handleClick, selectItem, t, theme)
  const actions = actionConfig(
    actionHandler,
    state.userPermission,
    t,
    canModify,
  )
  const selectedItemsOnPage = selectedItem[currentPage] || []


  return (
    <Container theme={theme}>
      {/* <Toolbar styles={toolbarStyle}>
        <ToolbarItemWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {state.userPermission.createModule && (
              <Button
                text={t('modules_layout.create_module')}
                color="secondary"
                onClick={() =>
                  DrawerEventEmitter.emit('openDrawer', 'addModule', true, {
                    data: {
                      courseId: '',
                      name: '',
                      onSuccess: () => {
                        refetch({
                          filter: {
                            companyId: {
                              type: 'exact',
                              value: state.selectedCompanyId,
                            },
                            query: {
                              type: 'query',
                              value: JSON.stringify(filterQuery),
                            },
                          },
                          currentPage: 1,
                          perPage,
                        })
                        setSelectedItem({})
                        setSelectAll({})
                      },
                    },
                  })
                }
                icon={<AddCircleRoundedIcon />}
                background={mainColor}
              />
            )}

            <GridMultipleActions
              selectedItems={selectedItemsOnPage.length}
              actions={[
                {
                  id: 0,
                  color: 'secondary',
                  hide: !state.userPermission.deleteModule,
                  tooltipText: `${t('actions.delete')}`,
                  disabled: selectedItemsOnPage.length < 1,
                  onClick: () =>
                    handleDeleteClick(selectedItem[currentPage], true),
                  component: <DeleteIcon fontSize="small" />,
                },
              ].filter((i: any) => !i.hide)}
            />
          </div>

          <div className="wrapper">
            <ToolbarItem>
              <IconButton
                disabled={_.isEmpty(filterData) && !searchText}
                onClick={resetFilter}
              >
                <SyncIcon fontSize="small" />
              </IconButton>
            </ToolbarItem>

            <ToolbarItem>
              <IconButton onClick={filterDrawer}>
                <FilterListIcon
                  fontSize="default"
                  style={{ color: mainColor }}
                />
              </IconButton>
            </ToolbarItem>

            <ToolbarItem>
              <form onSubmit={onSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type="text"
                  size="small"
                  value={searchText}
                  onChange={(e: any) => setSearchText(e.target.value)}
                  icon={
                    <IconButton onClick={onSearchSubmit}>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                />
              </form>
            </ToolbarItem>
          </div>
        </ToolbarItemWrapper>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ToolbarItem>
            <GridSwitchButtons>
              <button
                className={`${gridStyle === 'card' ? 'active' : ''}`}
                onClick={() => setGridStyle('card')}
              >
                <DashboardIcon />
              </button>
              <button
                className={`${gridStyle === 'list' ? 'active' : ''}`}
                onClick={() => setGridStyle('list')}
              >
                <ViewListIcon />
              </button>
            </GridSwitchButtons>
          </ToolbarItem>
        </div>
      </Toolbar> */}

      <GridToolbar theme={theme}>
        <GridLayoutActions>
          <div>
            {state.userPermission.createModule && (
              <AddButton
                onClick={() =>
                  DrawerEventEmitter.emit('openDrawer', 'addModule', true, {
                    data: {
                      courseId: '',
                      name: '',
                      onSuccess: () => {
                        refetch({
                          filter: {
                            companyId: {
                              type: 'exact',
                              value: state.selectedCompanyId,
                            },
                            query: {
                              type: 'query',
                              value: JSON.stringify(filterQuery),
                            },
                          },
                          currentPage: 1,
                          perPage,
                        })
                        setSelectedItem({})
                        setSelectAll({})
                      },
                    },
                  })
                }
              >
                <Icon filename={'add'} fill={'#ffffff'} />
                {t('modules_layout.create_module')}
              </AddButton>
            )}
          </div>
        </GridLayoutActions>
        <GridLayoutForm>
          <ActionButton
            disabled={_.isEmpty(filterData) && !searchText}
            onClick={resetFilter}
            theme={theme}
          >
            <SyncIcon fontSize="small" />
          </ActionButton>
          <MultipleAction theme={theme}>
            <ActionButton onClick={filterDrawer} theme={theme}>
              <Icon filename={'filter'} fill={'#080A0AD6'} />
            </ActionButton>
            <MultipleActionTooltip>
              {t('actions.filters')}
            </MultipleActionTooltip>
          </MultipleAction>
          <Search
            inputProps={{
              value: searchText,
              onChange: (e: any) => {
                setSearchText(e.target.value)
                setCurrentPage(1)
              },
            }}
            onClick={onSearchSubmit}
          />
        </GridLayoutForm>
      </GridToolbar>

      {/* <ContentWrapper>
        <Grid className={gridStyle}>
          {modules && modules.data.length <= 0 && <div>No records found</div>}

          {modules && (
            <ModulesList
              data={modules.data}
              // isDraggable={!searchText && _.isEmpty(filterData) ? true : false}
              gridStyle={gridStyle}
              selectedItem={selectedItem[currentPage] || []}
              selectItem={selectItem}
              handleVideoModal={handleVideoModal}
              actions={actions}
            />
          )}
        </Grid>
        {modules && modules.data.length > 0 && (
          <div
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Pagination
              currentPage={modules ? modules.currentPage : 1}
              totalPages={modules ? modules.totalPages : 0}
              handleChange={handlePaginationClick}
            />
          </div>
        )}
      </ContentWrapper> */}
      {modules && (
        <>
          <Grid
            title={t('modules_layout.title')}

            data={fetchAll ? allModules?.data?.slice(...fetchAllPagination) : modules.data}
            config={config}
            fetchAll={fetchAll}
            setFetchAll={setFetchAll}
            actionConfig={actions}
            selected={_.size(selectedItem[currentPage])}
            selectAllItem={selectAllItem}
            selectedItems={selectedItem[currentPage] || []}
            actions={() => [
              {
                id: 0,
                color: 'secondary',
                tooltipText: `${t('actions.delete')}`,
                hide: !state.userPermission.deleteGroup,
                disabled: selectedItem[currentPage]
                  ? selectedItem[currentPage].length < 1
                  : true,
                onClick: () =>
                  handleDeleteClick(selectedItem[currentPage], true),
                component: (
                  <ActionButton
                    disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
                    fill={'#CC3131'}
                    disabledFill={'#080A0AD6'}
                    theme={theme}
                  >
                    <Icon filename={'delete'} />
                  </ActionButton>
                ),
              },
              {
                id: 0,
                color: 'secondary',
                tooltipText: `${t('actions.export')}`,
                hide: !state.userPermission.deleteGroup,
                disabled: selectedItem[currentPage]
                  ? selectedItem[currentPage].length < 1
                  : true,
                onClick: () => alert('function'),
                component: (
                  <ActionButton
                    disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
                    fill={'#269F5A'}
                    disabledFill={'#080A0AD6'}
                    theme={theme}
                  >
                    <Icon filename={'download'} />
                  </ActionButton>
                ),
              },
            ]}
          // actions={multiActions}
          // allSelected={allSelected}
          />
        </>
      )}
      {modules && modules.data && (
        <TablePagination
          currentPage={modules && !fetchAll ? modules.currentPage : allModules && fetchAll ? page : 0}

          rowsPerPage={perPage}
          count={modules ? modules.totalCount : 0}
          handleChangePage={handlePaginationClick}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={videoModal.modal}
        onClose={() => setVideoModal({ data: '', modal: false, subtitle: '' })}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Player url={videoModal.data} subtitle={videoModal.subtitle} />
      </Modal>

      <Drawer opened={filterIsOpen} toggleDrawer={() => { }} totalWidth="970px">
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterQuery={setFilterQuery}
          perPage={perPage}
          refetchData={refetch}
          resetFilter={resetFilter}
          onClose={filterDrawer}
          setFiltered={setFiltered}
          companyId={state.selectedCompanyId}
          t={t}
        />
      </Drawer>
    </Container>
  )
}

export default ModulesLayout

const Container = styled.div`
  overflow: hidden;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  height: 100%;
`
const ToolbarItemWrapper = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-column-gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

const Title = styled.div`
  font-size: 23px;
  font-weight: 500;
`

const ContentWrapper = styled.div`
  margin-top: 30px;
`

const defs = (args: any) => {
  const styles: any = {}
  if (args.switched) {
    styles.top = '0px'
    styles.left = '0px'
    styles.gridTemplateColumns = '1fr 35px'
  } else {
    styles.bottom = '0px'
    styles.right = '0px'
    styles.justifyContent = 'end'
    styles.gridTemplateColumns = '35px 35px'
  }
  return styles
}

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #317bf4;
  padding: 18px 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    background: #3165f4;
  }
`
