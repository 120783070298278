
import { useMutation } from '@apollo/react-hooks';
import { updateCache } from 'services/updateCache';
import { GET_QUIZ_BY_ID, RETAKE_IQ_QUIZZ } from '../../gql/quiz/quiz.query';

const retakeQuizService = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [retakeQuizMutation, { loading, error }] = useMutation(RETAKE_IQ_QUIZZ, {
      update(cache, { data: { createQuiz: document } }) {
        updateCache({
          objectName: 'getQuizzesByType',
          query: GET_QUIZ_BY_ID,
          cache,
          document,
          action: 'add',
        })
      },
    })
  
    return {
      retakeQuizMutation,
      loading,
      error,
    }
  }

export default retakeQuizService