import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import _ from 'lodash'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'

import { makeStyles } from '@material-ui/core/styles'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import SyncIcon from '@material-ui/icons/Sync'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import FilterListIcon from '@material-ui/icons/FilterList'

import { DrawerEventEmitter } from 'helpers/drawer'
import { useUserValue } from 'context/UserContext'
import { GET_GROUP } from 'gql/group/group.query'
import { useSwal } from 'hooks/useSwal'
import { actionConfig, purchasedCourseActionConfig } from './config'
import { useFetchCoursesWithArgumets } from 'hooks/course/useCourses'
import useDeleteCourse from 'hooks/course/useDeleteCourse'
import { usePublishedCourse } from 'hooks/course/useEditCourse'
import { Grid } from 'components/common/Cards/styled-components'
import { Wrapper } from '../styled-components'
import CourseList from 'pages/courses/CourseList'
import { Button } from 'components/common/Button'
import { Input } from 'components/common/TextInput'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import Pagination from 'components/common/Pagination'
import Drawer from 'components/common/Drawer'
import Player from 'components/common/VideoPlayer/Player'
import { SET_CREATED_USER } from 'store/types'
import { useData } from 'context/DataContext'
import AddPurchasedCourseDrawer from './AddPurchasedCourseDrawer/AddPurchasedCourseDrawer'
import { mainColor } from 'components/common/Colors/CommonColors'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { FilterDrawer as WenrollCoursesFilterDrawer } from 'pages/courses/WenrollCoursesFilterDrawerCompanyAdminView/FilterDrawer'
import { FilterDrawer as CompanyCoursesFilterDrawer } from 'pages/courses/CompanyCoursesFilterDrawerCompanyAdminView/FilterDrawer'
import Loader from 'components/common/Loader'
import {
  GET_ALL_COURSE,
  GET_ALL_EXPLORE_COURSES_FOR_COMPANY,
} from 'gql/course/course.query'
import {
  GridToolbar,
  GridLayoutActions,
  AddButton,
  GridLayoutForm,
  ActionButton,
} from 'components/common/Grid/styled-components'
import Search from 'components/common/Search'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

interface Params {
  variables: any
  createBtn: boolean
  addCoachIds: any
  defCurrentPage: number
  currentTab: number
  company: any
  t: any
  addCourseButton?: boolean
  title: string
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const toolbarStyle = {
  justifyContent: 'space-between',

  '&.noBorder': {
    borderBottom: '1px solid transparent',
  },
}

const CompanyCourses = ({
  variables,
  createBtn,
  addCoachIds,
  defCurrentPage,
  currentTab,
  company,
  t,
  addCourseButton,
  title,
}: Params) => {
  const history = useHistory()
  const classes = useStyles()
  const [state] = useUserValue()
  const [exploreWenrollCoursesMode, setExploreWenrollCoursesMode] = useState<
    boolean
  >(true)
  const [filter, setFilter] = useState({ ...variables.filter })
  const { deleteCourse } = useDeleteCourse()
  const { publishedCourse } = usePublishedCourse()
  const [dataState, dispatch] = useData()
  const { fireSwal } = useSwal()
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [showAll, setShowAll] = useState<boolean>(false)
  const [perPage] = useState<number>(12)
  const [searchText, setSearchText] = useState<string>('')
  const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false)
  const [filterQuery, setFilterQuery] = useState<any>('')
  const [videoModal, setVideoModal] = useState<any>({
    modal: false,
    data: '',
    subtitle: '',
  })
  const [addPurchasedCourse, setAddPurchasedCourse] = useState(false)
  const [courseEditMode, setCourseEditMode] = useState('')
  const [companyId, setCompanyId] = useState(state?.currentUser?.companyId?.id)

  useEffect(() => {
    if (searchText) {
      setFilter({
        ...filter,
        name: {
          type: 'match',
          value: searchText,
        },
      })
    }
  }, [searchText])

  useEffect(() => {
    setCompanyId(state?.currentUser?.companyId?.id)
  }, [state])

  useEffect(() => {
    setFilter({ ...variables.filter })
  }, [variables])

  useEffect(() => {
    const isompanySuperAdmin =
      state?.currentUser?.role === 'SUPER_ADMIN' &&
      !!state?.currentUser?.companyId?.id

    const isComapanyAdmin =
      state?.currentUser?.role === 'ADMIN' &&
      !!state?.currentUser?.companyId?.id

    const exploreWenrollCoursesMode =
      currentTab === 0 && (isompanySuperAdmin || isComapanyAdmin)

    setExploreWenrollCoursesMode(exploreWenrollCoursesMode)
  }, [currentTab, state.currentUser.role, state.currentUser.companyId?.id])

  const [filterData, setFilterData] = useState<any>(filter)

  useEffect(() => {
    setFilterData(filter)
  }, [filter])

  const generateQuery = () => {
    const where: any = {
      query: { value: {}, type: 'query' },
    }
    where.companyId = {
      type: 'exact',
      value: companyId,
    }

    return where
  }

  const query = generateQuery()

  query.query = {
    type: 'query',
    value: JSON.stringify({ ...query.query.value, ...filterQuery }),
  }

  if (searchText) {
    query.name = {
      type: 'match',
      value: searchText,
    }
  }

  query.companyId = {
    type: 'exact',
    value: companyId,
  }

  const {
    courses,
    loading,
    refetch,
    error,
    fetchMore,
  }: any = useFetchCoursesWithArgumets(
    exploreWenrollCoursesMode
      ? 'getAllExploreCourseForCompany'
      : 'getAllCourse',
    exploreWenrollCoursesMode
      ? GET_ALL_EXPLORE_COURSES_FOR_COMPANY
      : GET_ALL_COURSE,
    companyId ? query : filter,
    currentPage,
    perPage,
  )



  const { data: groupsData } = useQuery(GET_GROUP, {
    variables: {
      filter: {
        company: {
          type: 'exact',
          value: variables.filter?.companyId?.value,
        },
      },
      perPage: 0,
    },
  })

  const isSuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !state?.currentUser?.companyId?.id

  const isAdmin =
    state?.currentUser?.role === 'ADMIN' && !state?.currentUser?.companyId?.id

  const selectItem = (id: string) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }
  const boughtCourses = courses?.data.map((i: any) => i.originalId)

  const handleViewClick = (id: string) => {
    if (isSuperAdmin || addCourseButton) {
      history.push(`/courses/${id}`)
    } else {
      history.push(`/course/${id}`)
    }
  }

  const handleDeleteCourse = (ids: string[]) => {
    if (ids.length <= 0) return

    const params = {
      title: `${ids.length > 1
        ? t('actions.remove_company_courses')
        : t('actions.remove_company_course')
        }`,
      onConfirm: () => {
        deleteCourse(ids, refetch)
        setSelectedItem([])
        setSelectAll({})
      },
      confirmText: t('actions.yes'),
    }

    fireSwal(params)
  }

  const resetFilter = () => {
    setFilterQuery({})
    setFilterData({})
    setFilterIsOpen(false)
    setSearchText('')
  }

  const onSearchSubmit = (e: any) => {
    e.preventDefault()
    setFilterQuery({})
    setCurrentPage(1)
    refetch()
  }

  const handlePublishedCourse = (courseId: string[], action: string) => {
    let title: string = ''
    let confirmText: string = ''

    if (action === 'unpublished') {
      title = `<span style="color:red">${courseId.length === 1
        ? t('popups.unpublish_course')
        : t('popups.unpublish_course')
        }</span>`
      confirmText = t('general.yes')
    } else {
      title = `<span style="color: ${mainColor}">${courseId.length === 1
        ? t('actions.publish_course')
        : t('actions.publish_course')
        }</span>`
      confirmText = t('actions.yes_publish')
    }

    fireSwal({
      title,
      onConfirm: () => publishedCourse(courseId, action),
      confirmText,
    })
  }

  const handleEditClick = (field: any) => {
    if (currentTab === 0) {
      setCourseEditMode(field)
      setAddPurchasedCourse(true)
      return
    }
    DrawerEventEmitter.emit('openDrawer', 'editCourse', true, { id: field })
  }

  const handleClosePurchasedCourseDrawer = () => {
    setCourseEditMode('')
    setAddPurchasedCourse(false)
  }

  const handleVideoModal = (data: any) => {
    if (!data.video) return

    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? data.video.links[0].url : '',
      subtitle: !videoModal.modal ? data.subtitle : '',
    })
  }

  const filterDrawer = () => {
    setFilterIsOpen(!filterIsOpen)
  }

  const handlePaginationClick = (event: any, value: number) => {
    if (value !== courses.currentPage) {
      refetch({
        filter: {
          query: {
            type: 'query',
            value: JSON.stringify(filterQuery),
          },
          ...variables.filter,
        },
        currentPage: value,
        perPage,
      })
      setCurrentPage(value)
    }
  }

  const handleCreateCourse = () => {
    dispatch({
      type: SET_CREATED_USER,
      payload: { obj: null, type: '' },
    })

    DrawerEventEmitter.emit('openDrawer', 'addCourses', true, {
      data: {
        companyId: variables.filter.companyId.value,
        onSuccess: () => {
          refetch(variables)
        },
      },
    })
  }

  const actionHandler = (event: string, field: any, action: string) => {
    switch (event) {
      case 'viewCourse':
        return handleViewClick(field)
      case 'deleteCourse':
        return handleDeleteCourse([field])
      case 'publishedUnPublished':
        return handlePublishedCourse([field], action)
      case 'editCourse':
        return handleEditClick(field)
      case 'palyIntro':
        return handleVideoModal(field)
      default:
        return () => console.log('')
    }
  }

  useEffect(() => {
    if (groupsData && groupsData.getAllGroups) {
      const coachIds = groupsData.getAllGroups.data.reduce(
        (ids: string[], group: any) => {
          const courseCoachesIds = group.courses.map(
            (course: any) => course.coach && course.coach.coachId,
          )
          ids = [...courseCoachesIds, ...ids]
          return ids
        },
        [],
      )
      addCoachIds(coachIds)
    }
  }, [groupsData])

  useEffect(() => {
    setCurrentPage(defCurrentPage)
  }, [])

  const selectedItemsOnPage = selectedItem[currentPage] || []

  const actions = actionConfig(
    actionHandler,
    state.userPermission,
    state.currentUser,
    createBtn,
    currentTab,
    t,
    isSuperAdmin,
    isAdmin,
    exploreWenrollCoursesMode,
  )

  const actionConfigForPurchasedCourse = purchasedCourseActionConfig(
    actionHandler,
    state.currentUser,
    t,
  )

  const theme = useTheme();

  return (
    <div style={{ paddingTop: 20 }}>
      {/* <div style={{ marginBottom: 20 }}>
        <Wrapper justify="between" align="center">
          <Wrapper align="center">
            {createBtn && !!company.platformService && (
              <Button
                text={t('courses_layout.create_course')}
                color="secondary"
                icon={<AddCircleRoundedIcon />}
                onClick={handleCreateCourse}
                isDisabled={!createBtn}
                background={mainColor}
                buttonStyles={{ padding: '10px 20px' }}
              />
            )}

            {currentTab === 0 && !state.currentUser.companyId && (
              <Button
                text={t('courses_layout.add_purchased_courses')}
                color="secondary"
                icon={<AddCircleRoundedIcon />}
                onClick={() => setAddPurchasedCourse(true)}
                background={mainColor}
                buttonStyles={{ padding: '10px 20px' }}
              />
            )}
            {!exploreWenrollCoursesMode && (
              <GridMultipleActions
                selectedItems={selectedItemsOnPage.length}
                actions={[
                  {
                    id: 0,
                    color: 'secondary',
                    tooltipText: t('actions.delete'),
                    disabled: selectedItemsOnPage.length < 1,
                    hide: !state.userPermission.deleteCourse,
                    onClick: () =>
                      handleDeleteCourse(selectedItem[currentPage]),
                    component: <DeleteIcon fontSize="small" />,
                  },
                ].filter((i: any) => !i.hide)}
              />
            )}
          </Wrapper>

          <Toolbar styles={toolbarStyle} l>
            <ToolbarItemWrapper>
              <div style={{ display: 'flex', alignItems: 'center' }}></div>

              <div className="wrapper">
                <ToolbarItem>
                  <IconButton
                    disabled={_.isEmpty(filterData) && !searchText}
                    onClick={resetFilter}
                  >
                    <SyncIcon fontSize="small" />
                  </IconButton>
                </ToolbarItem>

                {state.currentUser.companyId?.id && (
                  <ToolbarItem>
                    <IconButton onClick={filterDrawer}>
                      <FilterListIcon
                        fontSize="default"
                        style={{ color: mainColor }}
                      />
                    </IconButton>
                  </ToolbarItem>
                )}

                <ToolbarItem>
                  <form onSubmit={onSearchSubmit}>
                    <Input
                      label={t('general.search_placeholder')}
                      type="text"
                      size="small"
                      value={searchText}
                      onChange={(e: any) => {
                        setSearchText(e.target.value)
                        setCurrentPage(1)
                      }}
                      icon={
                        <IconButton>
                          <SearchRoundedIcon />
                        </IconButton>
                      }
                    />
                  </form>
                </ToolbarItem>
              </div>
            </ToolbarItemWrapper>
          </Toolbar>
        </Wrapper>
      </div> */}
      {/* <GridToolbar>
        <GridLayoutActions>
          <div>
            {createBtn && !!company.platformService && (
              // state.userPermission.createSuperAdmin &&
              <AddButton onClick={handleCreateCourse}>
                <Icon filename={'add'} fill={'#ffffff'} />
                {t('courses_layout.create_course')}
              </AddButton>
            )}
            {currentTab === 0 && !state.currentUser.companyId && (
              // state.userPermission.createSuperAdmin &&
              <AddButton onClick={() => setAddPurchasedCourse(true)}>
                <Icon filename={'add'} fill={'#ffffff'} />
                {t('courses_layout.add_purchased_courses')}
              </AddButton>
            )}
          </div>
        </GridLayoutActions>
        <GridLayoutForm>
          {`${selectedItem?.[currentPage]?.length || 0} ${t(
            'general.items_selected',
          )}`}
          <ActionButton
            onClick={() => handleDeleteCourse(selectedItem[currentPage])}
            disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
            fill={'#CC3131'}
            disabledFill={'#080A0AD6'}
          >
            <Icon filename={'delete'} />
          </ActionButton>
          <Search
            inputProps={{
              value: searchText,
              onChange: e => {
                setSearchText(e.target.value)
                setCurrentPage(1)
              },
            }}
          />
        </GridLayoutForm>
      </GridToolbar> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          {addCourseButton && createBtn && !!company.platformService && (
            <AddButton onClick={() => handleCreateCourse()}>
              <Icon filename={'add'} fill={'#ffffff'} />
              {t('courses_layout.create_course')}
            </AddButton>
          )}
          <span
            style={{ fontSize: '24px', fontWeight: 600, color: theme.palette.text.primary }}
          >
            {title}
          </span>
        </div>
        <span onClick={() => setShowAll(true)} style={{ fontSize: '13px', color: theme.palette.text.primary, cursor: "pointer" }}>
          {t('general.show_all')}
        </span>
      </div>

      <Grid className="card" style={{ position: 'relative' }} cols={3}>
        {error && error}
        {loading && <Loader withBackground />}
        {courses && (
          <CourseList
            data={showAll ? courses?.data || [] : courses?.data?.slice(0, 3) || []}
            gridStyle="card"
            selectItem={selectItem}
            selectedItem={selectedItem[currentPage] || []}
            handleVideoModal={handleVideoModal}
            actions={actions}
            actionConfigForPurchasedCourse={actionConfigForPurchasedCourse}
            exploreWenrollCoursesMode={exploreWenrollCoursesMode}
            redirectToExplore={true}
            addCourseButton={addCourseButton}
          />
        )}
      </Grid>
      {courses?.data.length === 0 && (
        <div>{t('companies_layout.no_courses_found')}</div>
      )}

      {!addCourseButton && currentTab === 0 && !state.currentUser.companyId && (
        <AddButton
          onClick={() => setAddPurchasedCourse(true)}
          style={{ margin: '40px auto' }}
        >
          <Icon filename={'add'} fill={'#ffffff'} />
          {t('course_drawer_layout.add_course')}
        </AddButton>
      )}
      {/* {courses && courses.totalPages > 1 && (
        <div
          style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}
        >
          <Pagination
            currentPage={courses ? courses.currentPage : 1}
            totalPages={courses ? courses.totalPages : 0}
            handleChange={handlePaginationClick}
          />
        </div>
      )} */}
      <Drawer opened={filterIsOpen} toggleDrawer={() => { }} totalWidth="970px">
        {currentTab === 0 ? (
          <WenrollCoursesFilterDrawer
            filterData={filterData}
            setFilterData={setFilterData}
            setFilterQuery={setFilterQuery}
            perPage={perPage}
            generateQuery={generateQuery}
            refetchCourse={refetch}
            resetFilter={resetFilter}
            onClose={() => setFilterIsOpen(false)}
            tabValue={currentTab}
            isCoursePrivacyHidden={true}
            t={t}
          />
        ) : (
          <CompanyCoursesFilterDrawer
            filterData={filterData}
            setFilterData={setFilterData}
            setFilterQuery={setFilterQuery}
            perPage={perPage}
            generateQuery={generateQuery}
            refetchCourse={refetch}
            resetFilter={resetFilter}
            onClose={() => setFilterIsOpen(false)}
            tabValue={currentTab}
            isCoursePrivacyHidden={true}
            t={t}
          />
        )}
      </Drawer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={videoModal.modal}
        onClose={() => {
          setVideoModal({
            ...videoModal,
            modal: false,
          })
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Player url={videoModal.data} subtitle={videoModal.subtitle} />
      </Modal>

      <Drawer
        opened={addPurchasedCourse}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <AddPurchasedCourseDrawer
          boughtCourses={boughtCourses}
          handleCloseDrawer={handleClosePurchasedCourseDrawer}
          isEditMode={courseEditMode}
          refetchCourses={refetch}
        />
      </Drawer>
    </div>
  )
}

export default CompanyCourses

const ToolbarItemWrapper = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`
