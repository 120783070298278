import React, { useEffect } from 'react'
import { Skeleton } from '@material-ui/lab'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import platform from 'platform'
import { CHECK_COURSE_COMPLETION, GET_COURSE, GET_COURSE_BY_ID_FOR_COACH, GET_LAST_WATCHED_LESSON, TRACK_PROGRESS } from 'gql/course/course.query'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { GetCourseById, lastWatchedIndex } from './types'
import Header from './Header'
import * as S from './styled'
import Content from './Content'
import { useUserValue } from 'context/UserContext'
import { useData } from 'context/DataContext'
import { clearCourseId, getCourseId } from 'helpers/localStorage'
import LastWatchedProvider from './Module/StudentCourseContext'

const StudentCourse: React.FC<RouteComponentProps<{ id: string }>> = props => {
  const [state] = useUserValue()
  const history = useHistory();


  const [course, setCourse] = React.useState<GetCourseById | null>(null)
  const [disabled, setDisabled] = React.useState(true)
  const [lastWatched, setLastWatched] = React.useState<lastWatchedIndex>({ moduleIndex: 0, topicIndex: 0, lessonIndex: 0, show: false })
  const [dataCtx, dispatch] = useData();
  const [checkCourseCompletion, { error }] = useMutation(CHECK_COURSE_COMPLETION)


  const [getCourseDataForStudent, { loading, refetch, startPolling, stopPolling }] = useLazyQuery<{ getCourseById: GetCourseById }>(GET_COURSE, {
    variables: { courseId: props.match.params.id },
    onCompleted: data => {

      setCourse(data.getCourseById)
      if (data.getCourseById.progress === 100) {
        checkCourseCompletion({
          variables: {
            courseId: data.getCourseById.originalId || props.match.params.id
          }
        })
      }
    },
  })

  const [loadCourseForCoach, { loading: coachCourseDataLoading, data: coachCourseData }] = useLazyQuery(
    GET_COURSE_BY_ID_FOR_COACH,
    {
      variables: { courseId: props.match.params.id },
      onCompleted: (data) => {

        setCourse(data.getCourseByIdForCoach)
      }
    }
  );


  useEffect(() => {
    setCourse(null)
    if (state.currentUser.role === "COACH") {
      loadCourseForCoach();
    } else if (state.currentUser.role === "STUDENT") {
      getCourseDataForStudent()
    }
  }, [state?.currentUser.role, props.match.params.id])


  useEffect(() => {
    const courseIdInLocalStorage = getCourseId()
    if (courseIdInLocalStorage) {
      // check if user agent is ios or android.
      const { family: deviceType = "" } = platform.os ?? {}

      const mobileTypes = ['iOS', 'Android'];
      const isMobile = mobileTypes.includes(deviceType);

      if (!isMobile) {
        clearCourseId()
      }


    }
  }, [course])


  React.useEffect(() => {
    if ((course?.id, state?.currentUser.group)) {
      if (state.currentUser.role === 'COACH') return setDisabled(false)
      const userGroupIds: string[] | undefined = course?.groups?.map(
        (group: any) => group.groupId,
      )
      const courseGroupsIds:
        | string[]
        | undefined = state.currentUser?.group?.map(
          (group: any) => group.groupId,
        )
      const test = userGroupIds?.filter(id => courseGroupsIds?.includes(id))
      if ((test?.length || 0) > 0) {
        setDisabled(false)

        setCourse(prev => (prev ? { ...prev, isBought: true } : null))
        return
      }
      setDisabled(true)
    }
  }, [course?.id, state?.currentUser.group])

  const studentUserId = state?.currentUser?.role === "STUDENT" && state.currentUser.id;

  if (!course?.id) return <Skeleton />

  return (
    <LastWatchedProvider>

      <S.Container>
        <Header course={course} setLastWatched={setLastWatched}
          refetchCourse={refetch}
          lastWatched={lastWatched}
          startPolling={startPolling}
          stopPolling={stopPolling}
        />
        <div style={{ marginTop: '80px' }}>
          <Content
            disabled={disabled}
            course={course}
            userId={studentUserId}
            refetchCourseStatiscs={refetch}
          />
        </div>
      </S.Container>
    </LastWatchedProvider>
  )
}

export default StudentCourse
