import React from 'react'
import Rating from '@material-ui/lab/Rating'
import Typography from '@material-ui/core/Typography'

interface RatingProps {
  isControled: boolean
  text?: string
  value: number | null
  onChange?: () => void
  size?: 'small' | 'medium' | 'large' | undefined
}

export default function SimpleRating({
  isControled,
  text,
  value = 0,
  onChange = () => {},
  size = 'medium',
}: RatingProps) {
  return (
    <div>
      {text && <Typography>{text}</Typography>}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Rating
          name={isControled ? 'simple-controlled' : 'read-only'}
          value={value}
          readOnly
          onChange={onChange}
          size={size}
        />
        <span style={{ marginLeft: 10 }}>
          <strong>{value}</strong>
        </span>
      </div>
    </div>
  )
}
