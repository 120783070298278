import React, { useState } from 'react'
import { gql } from 'graphql.macro'
import { useQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router'
import { DrawerEventEmitter } from 'helpers/drawer'
import { GET_TOPIC } from 'gql/topics.query'
import { useTranslation } from 'react-i18next'

import { actionConfig } from './actionConfig'
import { useSwal } from 'hooks/useSwal'
import { useDeleteTopic } from 'hooks/topics/useDeleteTopic'
import { useUserValue } from 'context/UserContext'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {
  Row,
  DetailsContentLeft,
  DetailsContentRight,
  DetailsContentHeader,
  DetailsContentHeaderSection,
  DetailsTitle,
  Column,
} from 'components/common/DetailsLayout'
import Thumbnail from 'components/common/Thumbnail/Thumbnail'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { Button } from 'components/common/Button'
import LessonLayout from 'pages/lessons/LessonLayout'
import {
  TabNavigation,
  TabPanel,
  activeTabClassName,
} from 'components/common/Tabs/Tabs'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { QuizLayout } from 'pages/quizzes'
import Breadcrumb from 'components/common/Breadcrumb'
import { formatDuration } from 'helpers/formatDuration'

import {
  ModuleContentDetailsContainer,
  ModuleContentDetailsHeader,
  ModuleContentDetails,
  ModuleContentDetail,
} from 'pages/modules/ModuleDetail/ModuleDetailContent/styled-components'
import styled from 'styled-components'
import Icon from 'components/Icon'
import { ActionButton } from 'components/common/Grid/styled-components'
import { useTheme } from '@material-ui/core'

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid #EBEBEB',
  },
  btn: {
    color: '#000',
  },
}

const GET_MODULE_IMAGE = gql`
  query($moduleId: String!) {
    getModule(moduleId: $moduleId) {
      name
      coverImage {
        link
      }
      companyId
    }
  }
`

interface RouterParams {
  match: MatchProps
}

interface MatchProps {
  path: string
  url: string
  isExact: boolean
  params: { id: string; topicId: string }
}

const TopicDetails = ({ match }: RouterParams) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = useState(0)
  const [state] = useUserValue()
  const topicId = match.params.topicId
  const { data, refetch } = useQuery(GET_TOPIC, { variables: { topicId } })
  const moduleId = (data && data.topic.currentModule.moduleId) || null

  const { data: moduleData } = useQuery(GET_MODULE_IMAGE, {
    variables: { moduleId },
  })
  const { deleteTopic, deletedTopic } = useDeleteTopic({
    moduleId,
    filter: {},
  })

  const { fireSwal } = useSwal()

  const confirmDelete = (ids: string[]) => {
    deleteTopic(moduleId, ids, () => history.push(`/modules/${moduleId}`))
  }

  const handleAddLesonClick = (id: string, name: string) => {
    DrawerEventEmitter.emit('openDrawer', 'addLesson', true, {
      id: moduleId,
      data: { name, id },
    })
  }

  const handleEditClick = (id: string) => {
    DrawerEventEmitter.emit('openDrawer', 'editTopic', true, {
      id,
    })
  }

  const handleDeleteClick = (id: string) => {
    fireSwal({
      title: t('popups.delete_topic_single'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => confirmDelete([id]),
    })
  }
  const openAttachment = (attachment: string) => {
    window.open(attachment, '_blank')
  }

  const topic = (data && data.topic) || {}
  const moduleImage = moduleData && moduleData.getModule.coverImage
  const actions = actionConfig(
    handleAddLesonClick,
    handleEditClick,
    handleDeleteClick,
    openAttachment,
    state.userPermission,
    t,
  )

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  let breadCrumbData: any = ''

  if (moduleData && data) {
    breadCrumbData = [
      { label: t('general.modules'), link: '/modules' },
      {
        label: moduleData.getModule.name,
        link: `/modules/${moduleId}`,
      },
      { label: data.topic.name, link: '' },
    ]
  }

  const duration = topic.lessons
    ? topic.lessons.reduce((duration: number, lesson: any) => {
        duration += lesson.duration

        return duration
      }, 0)
    : 0

  const currentModule = (moduleData && moduleData.getModule) || {}

  const isStudent = state.currentUser.role === 'STUDENT'

  const isCompanySuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !!state?.currentUser?.companyId?.id

  const isCompanyAdmin =
    state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  const isSuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !state?.currentUser?.companyId?.id

  const isAdmin =
    state?.currentUser?.role === 'ADMIN' && !state?.currentUser?.companyId?.id

  const canModify =
    (((isCompanySuperAdmin || isCompanyAdmin) &&
      currentModule?.companyId === state?.currentUser?.companyId?.id) ||
      isSuperAdmin ||
      isAdmin) &&
    !isStudent

  const theme = useTheme()

  return (
    <Container>
      {/* {topic && (
        <Row>
          <DetailsContentLeft>
            <DetailsContentHeader>
              <Thumbnail
                width={100}
                height={100}
                imageSrc={moduleImage && moduleImage.link}
              />
              <DetailsContentHeaderSection>
                <DetailsTitle>{topic.name}</DetailsTitle>
              </DetailsContentHeaderSection>
            </DetailsContentHeader>
          </DetailsContentLeft>
          <DetailsContentRight className="noVideo">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginBottom: '20px',
              }}
            >
              {canModify && (
                <GridActionMenu
                  actionConfig={actions}
                  row={topic}
                  icon={
                    <Button
                      text={t('general.actions')}
                      size="small"
                      icon={<ArrowDropDownIcon />}
                      iconPosition="right"
                    />
                  }
                />
              )}
            </div>
          </DetailsContentRight>
        </Row>
      )}
      <ModuleContentDetailsContainer>
        <ModuleContentDetailsHeader>
          {topic.description || t('topic_details.topic_details_placeholder')}
        </ModuleContentDetailsHeader>
        <Row>
          <Column>
            <ModuleContentDetails>
              <ModuleContentDetail>
                <strong>{t('general.duration')}:</strong>{' '}
                {formatDuration(duration)}
              </ModuleContentDetail>

              <ModuleContentDetail>
                <strong>{t('general.lessons')}:</strong>{' '}
                {(topic.lessons && topic.lessons.length) || 0}
              </ModuleContentDetail>
            </ModuleContentDetails>
          </Column>
        </Row>
      </ModuleContentDetailsContainer> */}
      <Header>
        <div
          style={{
            position: 'absolute',
            top: '40px',
            right: '30px',
          }}
        >
          {canModify && (
            <GridActionMenu
              actionConfig={actions}
              row={topic}
              CIcon={
                <ActionButton theme={theme}>
                  <Icon filename="edit" />
                </ActionButton>
              }
            />
          )}
        </div>
        <div
          style={{
            color: '#080A0AD6',
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '20px',
          }}
        >
          {topic.name}
        </div>
        <div
          style={{
            color: '#080A0A8A',
            fontSize: '16px',
            marginBottom: '10px',
          }}
        >
          {t('general.details')}
        </div>
        <ListItem>
          <Icon filename={'time'} />
          <div>{t('general.duration')}:</div>
          <span>{formatDuration(duration)}</span>
        </ListItem>
        <ListItem>
          <Icon filename={'modules'} />
          <div>{t('general.numberOfLessons')}:</div>
          <span>{(topic.lessons && topic.lessons.length) || 0}</span>
        </ListItem>
      </Header>
      <TabContainer>
        <TabNavigation>
          <AppBar position="static" style={styles.bar}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              <Tab
                label={t('general.lessons')}
                {...activeTabClassName(0)}
                style={styles.btn}
              />
              {!isStudent && (
                <Tab
                  label={t('general.quizzes')}
                  {...activeTabClassName(1)}
                  style={styles.btn}
                />
              )}
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <>
              {topic.lessons && (
                <LessonLayout
                  topic={{ name: topic.name, id: topic.id }}
                  moduleId={topic.module}
                  refetch={refetch}
                  lessons={topic.lessons}
                  isDraggable
                  canModify={canModify}
                />
              )}
            </>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <QuizLayout
              type="topic"
              id={topic.id}
              canCreate={topic.numberOfModuleQuestions}
              name={topic.name}
              isDraggable
              companyId={topic.companyId}
              canModify={canModify}
            />
          </TabPanel>
        </TabNavigation>
      </TabContainer>
    </Container>
  )
}

export default TopicDetails

const Container = styled.div`
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  height: 100%;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 43px 30px;
`

const TabContainer = styled.div`
  .MuiTabs {
    &-flexContainer {
      justify-content: center;
    }
  }

  .MuiTab {
    &-wrapper {
      color: #080a0ad6;
      font-size: 20px;
    }
  }
`

const ListItem = styled.div`
  display: flex;
  gap: 5px;
  font-size: 16px;
  margin-bottom: 25px;
  min-width: 280px;

  svg {
    width: 20px;
    height: 20px;
    fill: #080a0ad6;
  }

  div {
    color: #080a0ad6;
  }
  span {
    color: #080a0a8a;
  }
`
