import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Backdrop, IconButton, makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import SyncIcon from '@material-ui/icons/Sync'
import { Grid } from 'components/common/Cards/styled-components'
import Drawer from 'components/common/Drawer'
import Pagination from 'components/common/Pagination'
import { Input } from 'components/common/TextInput'
import { ToolbarItem } from 'components/common/Toolbar'
import Player from 'components/common/VideoPlayer/Player'
import { useCourseByUserId } from 'hooks/course/useCourseByUserId'
import CourseList from 'pages/courses/CourseList'
import FilterDrawer from 'pages/courses/FilterDrawer/FilterDrawer'
import { Wrapper } from 'pages/companies/CompanyDetails/styled-components'
import { PersonalInformationHeader } from '../AdminProfile/PersonalInformation'
import FilterListIcon from '@material-ui/icons/FilterList'
import { mainColor } from 'components/common/Colors/CommonColors'
import _ from 'lodash'
import { useFetchSuggestedCourses } from 'hooks/course/useSuggestedCourses'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export const UserExploreCourses: React.FC<{
  userId: string
  groups: string[]
  redirectToCoursePage?: boolean
  isTitleHidden?: boolean
  companyId?: string | null
}> = ({ userId, groups, redirectToCoursePage, isTitleHidden, companyId }) => {

  const { t } = useTranslation()
  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const [filterData, setFilterData] = useState<any>({})
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(12);
  const [searchText, setSearchText] = useState<string>('');

  const [hideCourses, setHideCourses] = useState(false);
  const [hasMoreCourses, setHasMoreCourses] = useState(true);

  const observer = useRef<IntersectionObserver | null>(null);

  const initialVariables = {}

  const generateQuery = () => {
    const where: any = {
      query: { value: {}, type: 'query' },
    }

    where.query = {
      type: 'query',
      value: {
        ...where.query.value,
      },
    }

    const searchedGroupIds = filterData?.group
      ? filterData.group.map(
        (group: { value: string; label: string }) => group.value,
      )
      : []
    const groupsIds = searchedGroupIds
      ? [...searchedGroupIds, ...groups]
      : [...groups]

    return where
  }

  const query = generateQuery()

  query.query = {
    type: 'query',
    value: JSON.stringify({ ...query.query.value, ...filterQuery }),
  }

  if (searchText) {
    query.name = {
      type: 'match',
      value: searchText,
    }
  }

  const {
    courses,
    loading,
    refetch,
    error,
    fetchMore,
  }: any = useFetchSuggestedCourses(query, currentPage, perPage)

  const onObserve = (entries: any[]) => {
    if (entries[0].isIntersecting && hasMoreCourses && !loading) {
      if (currentPage >= courses?.totalPages) {
        setHasMoreCourses(false);
      } else {
        setCurrentPage(cp => cp + 1);
      }
    }
  };

  const lastDivElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(onObserve, { threshold: 1 });
    if (node) {
      observer.current.observe(node)
    }
  }, [loading, hasMoreCourses]);

  useEffect(() => {
    if (currentPage !== 1 && currentPage <= courses?.totalPages && hasMoreCourses) {
      handleFetchMoreCourses();
    }
  }, [currentPage]);

  useEffect(() => {
    return () => {
      if (observer.current) observer.current.disconnect();
    }
  }, [])

  function handleFetchMoreCourses() {
    if (!hasMoreCourses) return;
    // console.log('Start fetching', currentPage);

    fetchMore({
      variables: {
        currentPage
      },
      updateQuery: (prev: any, newData: any) => {
        const { fetchMoreResult } = newData;
        if (!fetchMoreResult) return prev;

        let cacheExists;

        // Can't figure out why, but prev and newData are always the same. So it needed a solution depended on current data.

        // NEEDS OPTIMIZATION
        if (fetchMoreResult.getSuggestedCourses?.data?.length > 0) {
          const courseExistsInCache = courses?.data.find((item: any) => item.id === fetchMoreResult.getSuggestedCourses.data[0].id) || null;
          if (courseExistsInCache) {
            cacheExists = true;
          }
        }

        return {
          getSuggestedCourses: {
            __typename: "GetSuggestedCoursesResponse",
            totalPages: fetchMoreResult?.getSuggestedCourses?.totalPages,
            currentPage: fetchMoreResult?.getSuggestedCourses?.currentPage,
            data: [...courses.data, ...(cacheExists ? [] : fetchMoreResult?.getSuggestedCourses?.data)],
            // data: [
            //   ...(prev?.getSuggestedCourses?.data || []),
            //   ...(fetchMoreResult?.getSuggestedCourses?.data || [])
            // ]
          }
        }
      }
    })
  };


  const handleResetClick = () => {
    setSearchText('')
    setFilterQuery({})
    setFilterData({})
    setFilterIsOpen(false)
    refetch({ filter: { ...initialVariables }, currentPage: 1, perPage })
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()

    setCurrentPage(1)
    const searchValue = { type: 'match', value: searchText }
    const vars: any = {}
    vars.filter = {
      ...initialVariables,
      name: searchValue,
    }
    vars.currentPage = 1
    vars.perPage = perPage
    refetch(vars)
  }

  const filterDrawer = () => {
    setFilterIsOpen(!filterIsOpen)
  }

  const resetFilter = () => {
    setFilterQuery({})
    setFilterData({})
    setFilterIsOpen(false)
    setSearchText('')
  }

  const [isCoursesVisible, setCoursesVisible] = useState(true)

  const classes = useStyles()

  const [videoModal, setVideoModal] = useState<any>({
    modal: false,
    data: '',
    subtitle: '',
  })

  const handleVideoModal = (data: any) => {
    if (!data.video) return

    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? data.video.links[0].url : '',
      subtitle: !videoModal.modal ? data.subtitle : '',
    })
  }

  const handlePaginationClick = (event: any, value: number) => {
    if (value !== courses.currentPage) {
      refetch({
        filter: {
          query: {
            type: 'query',
            value: JSON.stringify(filterQuery),
          },
          ...filterQuery,
        },
        currentPage: value,
        perPage,
      })
      setCurrentPage(value)
    }
  }

  useEffect(() => {
    const query = generateQuery()
    query.query = { type: 'query', value: JSON.stringify(query.query.value) }
    refetch({
      filter: {
        ...query,
      },
    })
  }, [])


  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <div>
      {!isTitleHidden && (
        <PersonalInformationHeader theme={theme}>
          {/* {!isCoursesVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} */}
          <h1>
            {t('general.discover_courses')}
          </h1>


          {courses?.data && courses?.data.length >= 4 &&
            <div
              style={{
                fontSize: '13px',
                marginLeft: 'auto',
                fontWeight: 500,
              }}
              // onClick={() => setPerPage(courses.totalPages * perPage)}
              onClick={() => setHideCourses((oldState) => oldState ? false : true)}
            >
              {hideCourses ? t('general.show_all') : t('general.show_less')}
            </div>
          }


        </PersonalInformationHeader>
      )}

      {isCoursesVisible && hideCourses && (
        <Grid className="card" style={{ position: 'relative' }} cols={isSmallScreen ? 3 : 2}>
          {courses?.data && courses?.totalPages >= 1 && (
            <>
              <CourseList
                data={[courses.data[0], courses.data[1], courses.data[2]]}
                gridStyle="card"
                selectedItem={[]}
                handleVideoModal={handleVideoModal}
                selectItem={() => { }}
                redirectToDashboard={!redirectToCoursePage}
                userId={userId}
              />
            </>
          )}
        </Grid>
      )}

      {isCoursesVisible && !hideCourses && (
        <>

          <Grid className="card" style={{ position: 'relative' }} cols={isSmallScreen ? 3 : 2}>
            {courses?.data && courses?.totalPages >= 1 && (
              <>
                <CourseList
                  data={courses.data}
                  gridStyle="card"
                  selectedItem={[]}
                  handleVideoModal={handleVideoModal}
                  selectItem={() => { }}
                  redirectToDashboard={!redirectToCoursePage}
                  userId={userId}
                />
                <div ref={lastDivElementRef} />
              </>
            )}
          </Grid>
        </>
      )}
      <Drawer opened={filterIsOpen} toggleDrawer={() => { }} totalWidth="700px">
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterQuery={setFilterQuery}
          perPage={perPage}
          generateQuery={generateQuery}
          refetchCourse={refetch}
          resetFilter={resetFilter}
          onClose={() => setFilterIsOpen(false)}
          t={t}
          companyId={companyId}
          isOnStudentProfile={true}
          isCoursePrivacyHidden={true}

        />
      </Drawer>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={videoModal.modal}
        onClose={() => {
          setVideoModal({
            ...videoModal,
            modal: false,
          })
        }}
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Player url={videoModal.data} subtitle={videoModal.subtitle} />
      </Modal>
    </div>
  )
}
