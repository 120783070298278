import Radio from '@material-ui/core/Radio'
import { black, mainColor, red, successGreen } from 'components/common/Colors/CommonColors'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { AnswerType } from './MultipleAnswer'

const SingleAnswer: React.FC<{
  answers: AnswerType[]
  setHasAnswerSelected: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedAnswers: React.Dispatch<React.SetStateAction<string[]>>
  isAnswersButtonDisabled: boolean
  correctAnswer: string
}> = ({
  answers,
  setHasAnswerSelected,
  setSelectedAnswers,
  isAnswersButtonDisabled,
  correctAnswer,
}) => {
    const [selectedAnswer, setSelectedAnswer] = useState<AnswerType>(
      {} as AnswerType,
    )

    const onRadioChange = useCallback((answer: AnswerType) => {
      setSelectedAnswer(answer)
      setHasAnswerSelected(true)
      setSelectedAnswers([answer.id])
    }, [])

    const SingleChoiceAnswer: React.FC<{ answer: AnswerType }> = ({ answer }) => {
      const [color, setColor] = useState(black)
      const [bulletColor, setBulletColor] = useState(mainColor)

      useEffect(() => {
        if (selectedAnswer?.id && correctAnswer && isAnswersButtonDisabled) {
          if (
            (selectedAnswer.id === answer.id && correctAnswer === answer.id) ||
            (correctAnswer === answer.id && selectedAnswer.id !== answer.id)
          ) {
            setColor(successGreen)
          } else if (
            selectedAnswer.id === answer.id &&
            correctAnswer !== answer.id
          ) {
            setColor(red)
          }
        }
      }, [selectedAnswer, correctAnswer, isAnswersButtonDisabled])

      return (
        <AnswerContainer
          key={answer.id}
          onClick={() => !isAnswersButtonDisabled && onRadioChange(answer)}
        >
          <Radio
            name="single"
            value={answer.id}
            checked={selectedAnswer.id == answer.id}
            style={{ color: '#080A0A' }}
          />
          <span
            style={{
              color: color,
            }}
          >
            {answer.value}
          </span>
        </AnswerContainer>
      )
    }

    return (
      <div>
        {answers &&
          answers.map(answer => {
            return <SingleChoiceAnswer key={answer.id} answer={answer} />
          })}
      </div>
    )
  }

export default SingleAnswer

export const AnswerContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
`
