import i18n from 'i18n/index'

export let filterOptions: any = []

function translate() {
  filterOptions = [
    { label: i18n.t('general.all'), value: 'all' },
    {
      label: `${i18n.t('general.all')} ${i18n.t('navigation.companies')}`,
      value: 'all companies',
    },
    { label: i18n.t('general.wenroll'), value: 'wenroll' },
  ]
}

translate()

i18n.on('languageChanged init', () => {
  translate()
})
