import React, { useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import useAddStudentsInGroup from 'hooks/group/useAddStudentsInGroup'
import { PER_PAGE, useFetchUsers } from 'hooks/users/useUsers'
import { useSwal } from 'hooks/useSwal'
import { columnConfig } from './studentsGridconfig'

import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import { Button } from 'components/common/Button'
import {
  FormButtons,
  FormContainer,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form'
import Grid from 'components/common/Grid'
import TablePagination from 'components/common/Pagination/TablePagination'
import TextInput from 'components/common/TextInput/TextInput'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/DrawerLayout/styled-components'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { useTheme } from '@material-ui/core'

const AddStudentsInGroupDrawer = (props: any) => {
  const { fireSwal } = useSwal()
  const [selectedItem, setSelectedItem] = useState<any>([])
  const [selectAll, setSelectAll] = useState<any>({})
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchValue, setSearchValue] = useState<string>('')

  let filter: any = {
    group: {
      type: 'nestedArrayNotIn',
      value: [props.groupId],
      nestedField: 'groupId',
    },
  }
  if (props.companyId !== undefined) {
    filter.companyId = {
      type: 'exact',
      value: props.companyId,
    }
  }

  const { addStudentsInGroup } = useAddStudentsInGroup({
    role: 'STUDENT',
    filter: { ...filter },
  })

  const { users, loading, error, refetch }: any = useFetchUsers('STUDENT', {
    ...filter,
  })

  const selectItem = (id: never) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
    props.setSelectedStudents(newSelected)
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = users.data.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }
  const handleSearchChange = (e: any) => {
    setSearchValue(e.currentTarget.value)
  }

  const onFormSubmit = () => {
    if (setSelectedItem.length <= 0) return

    addStudentsInGroup(
      props.groupId,
      props.groupName,
      selectedItem[currentPage],
      refetch,
      props.studentInGroupRefetch,
    )
  }

  const handleDrawerClose = () => {
    if (selectedItem[currentPage] && selectedItem[currentPage].length > 0) {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        onConfirm: () => {
          props.onClose()
          props.setSelectedStudents([])
        },

        confirmText: t('actions.yes'),
        cancelText: t('general.no'),
      })
    } else {
      props.onClose()
      props.setSelectedStudents([])
    }
  }

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    refetch({
      role: 'STUDENT',
      filter: { ...filter },
      currentPage: newPage + 1,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    refetch({
      role: 'STUDENT',
      filter: { ...filter },
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    const formValue = {
      search: {
        type: 'search',
        value: searchValue,
        fields: ['firstName', 'lastName', 'email', 'phone'],
      },
    }
    let filterData = { ...filter }
    if (searchValue !== '') {
      filterData = { ...filter, ...formValue }
    } else {
      const { search, ...filters } = filter
      filterData = { ...filters }
    }

    refetch({
      role: 'STUDENT',
      filter: filterData,
      currentPage: 1,
      perPage,
    })
  }

  const { t } = props
  const config = columnConfig(selectItem, false, t)
  const theme = useTheme();
  return (
    <>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{t('actions.add_student')}</DrawerTitle>
        <IconButton onClick={handleDrawerClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>

      <DrawerContent>
        <FormContainer>
          <FormGroupWrapper>
            <Container>
              <Toolbar styles={{ justifyContent: 'space-between' }}>
                <div />
                <ToolbarItem>
                  <form onSubmit={handleSearchSubmit}>
                    <TextInput
                      label={t('general.search_placeholder')}
                      type="text"
                      size="small"
                      value={searchValue}
                      onChange={handleSearchChange}
                      disabled={loading}
                      icon={
                        <IconButton type="submit">
                          <SearchRoundedIcon />
                        </IconButton>
                      }
                    />
                  </form>
                </ToolbarItem>
              </Toolbar>

              <Grid
                data={(users && users.data) || []}
                config={config}
                selectAllItem={selectAllItem}
                selected={_.size(selectedItem[currentPage])}
                selectedItems={selectedItem[currentPage] || []}
                openInExternalTab={true}
              />
              {users && users.totalCount !== 0 && (
                <TablePagination
                  currentPage={users ? users.currentPage : 0}
                  rowsPerPage={perPage}
                  count={users ? users.totalCount : 0}
                  handleChangePage={handlePaginationClick}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Container>
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleDrawerClose}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />

              <Button
                text={t('actions.save')}
                color="secondary"
                type="small"
                onClick={onFormSubmit}
                background={mainColor}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddStudentsInGroupDrawer

const Container = styled.div`
  padding: 0 20px;
`
