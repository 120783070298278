import { useMutation } from '@apollo/react-hooks'
import { CREATE_LESSON } from 'gql/lesson/lesson.query'

const createLessonService = (params: any) => {
  // console.log('params', params)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [createLessonMutation, { loading }] = useMutation(CREATE_LESSON, {
    // update(cache, { data: { createLesson: document } }) {
    //   updateCache({
    //     objectName: 'topic',
    //     query: GET_TOPIC,
    //     cache,
    //     document,
    //     action: 'update',
    //     params,
    //   })
    // },
  })
  //    {
  //   update(cache, { data: { addGroup: document } }) {
  //     updateCache({
  //       objectName: 'getAllGroups',
  //       query: GET_GROUP,
  //       cache,
  //       document,
  //       action: 'add',
  //     })
  //   }
  // })

  return {
    createLessonMutation,
    loading,
  }
}

export default createLessonService
