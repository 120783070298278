import React, { FC, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom"
import { Rating } from "@material-ui/lab";
import { Button } from "components/common/Button";
import { Input } from "components/common/TextInput";
import { CloseIcon, DrawerContent, DrawerHeader, DrawerTitle, Info } from "components/DrawerLayout/styled-components";
import styled from "styled-components";
import Switcher from '../../../../components/common/Switcher/CustomSwitcher'
import { useTheme } from "@material-ui/core";
import { ADD_COURSE_REVIEW, EDIT_REVIEW } from "gql/skillTest/skilltest.query";
import { UPDATE_ASK_REVIEW } from "gql/review/review.query"
import { GET_PROGRESS_BY_USER_ID } from 'gql/progress/progress.query'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { useUserValue } from 'context/UserContext'

interface ReviewProps {
  onModalClose: () => void;
  refetch?: () => void;
  coachName?: string;
  courseName?: string;
  drawerTitle: string;
  ratedId?: string;
  model?: string;
  isRatedByMe?: Boolean;
  demand?: Boolean;
  setDemand?: any
}

const Review: FC<ReviewProps> = ({
  onModalClose,
  refetch,
  coachName,
  courseName,
  drawerTitle,
  ratedId,
  model,
  isRatedByMe,
  demand,
  setDemand
}) => {
  const [anonym, setAnonym] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState('')
  const [starsRate, setStarsRate] = useState(0)
  const [addReviewFunc, { data }] = useMutation(ADD_COURSE_REVIEW);
  const [editReviewFunc] = useMutation(EDIT_REVIEW);
  const [starWarning, setStarWarning] = useState(false)
  const { t } = useTranslation();
  let history = useHistory()
  const [state] = useUserValue();
  let params: { id: string } = useParams()


  // const { data: progress } = useQuery(GET_PROGRESS_BY_USER_ID, {
  //   variables: {
  //     userId: props.userId,
  //   }
  // });

  const [updateAskReview, { data: review }] = useMutation(UPDATE_ASK_REVIEW, {
    refetchQueries: () => [{
      query: GET_PROGRESS_BY_USER_ID,
      variables: {
        userId: state.currentUser.id
      }
    }]
  })

  const addReview = () => {

    addReviewFunc({ variables: { ratedId: ratedId, input: { rate: starsRate, description: descriptionValue }, model: model, isAnonymous: anonym } }).then(
      () => {
        refetch && refetch()
      }
    )

    updateAskReview({ variables: { courseId: params.id } }).then(res => {
      setTimeout(() => {
        onModalClose()
        history.goBack()
      }, 300)
    })
  }



  const reviewLater = () => {
    updateAskReview({ variables: { courseId: params.id } }).then(res => {
      setTimeout(() => {
        onModalClose()
        history.goBack()
      }, 300)
    })
  }


  const editReview = () => {
    editReviewFunc({ variables: { id: ratedId, input: { rate: starsRate, description: descriptionValue }, isAnonymous: anonym } }).then(
      () => refetch && refetch()
    )
  }

  useEffect(() => {
    starWarning === true && setStarWarning(false)
  }, [starsRate])



  const theme = useTheme()

  return (
    <>
      <DrawerHeader theme={theme} style={{ display: 'flex', flexDirection: 'column' }}>
        <DrawerTitle theme={theme}>{drawerTitle}</DrawerTitle>
        <CloseIcon
          onClick={onModalClose}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent margin="100px">
        <DrawerTitle style={{
          fontSize: '28px',
        }}>{coachName || courseName}</DrawerTitle>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', margin: '20px 0px' }}>
            <Rating
              value={starsRate}
              max={5}
              onChange={(e: any) => setStarsRate(Number(e.target.value))}
            />
            <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#d32f2f', marginTop: 20 }}>
              {starWarning && t('general.pleaseWriteStars')}
            </p>
          </div>
          <div style={{ margin: '20px 0px' }}>
            <DrawerSubtitle><p style={{ width: '100%' }}>{t('general.reviewAnonymously')} </p> <Switcher value={anonym} onChange={(e: any) => setAnonym(!anonym)} /></DrawerSubtitle>
          </div>
        </div>

        <Input
          label={`${t('general.yourReview')}`}
          name="location"
          size="medium"
          type="text"
          fullWidth
          multiline
          rows={10}
          value={descriptionValue}
          onChange={(e: any) => setDescriptionValue(e.target.value)}
        />
        <div style={{
          marginTop: '20px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <Button
            text={t('general.save')}
            isDisabled={starsRate === 0}
            onClick={() => starsRate === 0 ? setStarWarning(true) : (isRatedByMe ? editReview() : addReview())}
            size={'medium'}
            buttonStyles={{
              borderRadius: '16px',
              // color: 'white',
              // opacity: starsRate === 0 ? '0.7' : '1'
            }}
          />
          {demand &&
            <div style={{ marginLeft: "15px" }}> <Button
              text={t('general.reviewLater')}
              // isDisabled={starsRate === 0}
              onClick={reviewLater}
              size={'medium'}
              buttonStyles={{
                borderRadius: '16px',
                // color: 'white',
                // opacity: starsRate === 0 ? '0.7' : '1'
              }}
            />
            </div>
          }
        </div>
        <div style={{ height: '20px' }}></div>
      </DrawerContent>
    </>
  );
}

export default Review;

const DrawerSubtitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: #080A0A86;
  margin-bottom: 20px;
`
