import { useMutation } from '@apollo/react-hooks'
import { updateCache } from 'services/updateCache'

import { DELETE_QUIZ_BY_IDS, GET_QUIZ_BY_TYPE } from 'gql/quiz/quiz.query'

const deleteQuizService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteQuizMutation, { loading, error }] = useMutation(DELETE_QUIZ_BY_IDS, {
    update(cache, { data: { deleteQuiz: document } }) {
      updateCache({
        objectName: 'getQuizzesByType',
        query: GET_QUIZ_BY_TYPE,
        cache,
        document,
        action: 'delete',
        params
      })
    },
  })

  return {
    deleteQuizMutation,
    loading,
    error,
  }
}

export default deleteQuizService
