import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Input } from 'components/common/TextInput'
import React, { useState } from 'react'
import { Props } from './TextInput'

export const PasswordInput: React.FC<Props> = ({
  eyeColor,
  error,
  errorMessage,
  textColor,
  touched,
  label,
  name,
  size,
  value,
  onChange,
  inputStyle,
  placeholder,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <Input
      placeholder={placeholder}
      textColor={textColor}
      error={error}
      errorMessage={errorMessage}
      touched={touched}
      label={label}
      name={name}
      size={size}
      type={showPassword ? 'text' : 'password'}
      fullWidth
      value={value}
      onChange={onChange}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            onClick={() => setShowPassword(x => !x)}
            style={{ top: 'auto' }}
          >
            {showPassword ? <Visibility htmlColor={eyeColor} /> : <VisibilityOff htmlColor={eyeColor} />}
          </IconButton>
        </InputAdornment>
      }
      inputStyle={inputStyle}
    />
  )
}
