import { useMutation } from '@apollo/react-hooks'

import { GET_COMPANIES, DELETE_COMPANY } from 'gql/companies.query'
import { updateCache } from 'services/updateCache'

const deleteCompanyService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteCompanyMutation, { loading, error }] = useMutation(
    DELETE_COMPANY,
    {
      update(cache, { data: { deleteCompany: document } }) {
        updateCache({
          objectName: 'companies',
          query: GET_COMPANIES,
          cache,
          document,
          action: 'update',
          params,
        })
      },
    },
  )

  return {
    deleteCompanyMutation,
    loading,
    error,
  }
}

export default deleteCompanyService
