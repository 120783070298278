import React from 'react'
import MultipleAnswers, { AnswerType } from './answers/MultipleAnswer'
import styled from 'styled-components'
import SingleAnswer from './answers/SingleAnswer'
import BoolAnswer from './answers/BoolAnswer'

const question = {
  MULTIPLE: 'multiple',
  SINGLE: 'single',
  BOOLEAN: 'boolean',
}

const Answers: React.FC<{
  setHasAnswerSelected: React.Dispatch<React.SetStateAction<boolean>>
  questionId: string
  type: {
    value: string
    label: string
  }
  answers: AnswerType[]
  setBoolAnswer: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedAnswers: React.Dispatch<React.SetStateAction<string[]>>
  isAnswersButtonDisabled: boolean
  answerData: any
}> = ({
  setHasAnswerSelected,
  questionId,
  type,
  answers,
  setBoolAnswer,
  setSelectedAnswers,
  isAnswersButtonDisabled,
  answerData,
}) => {


    return (
      <AnswersWrapper>
        {type?.value === question.MULTIPLE && (
          <MultipleAnswers
            answers={answers}
            setHasAnswerSelected={setHasAnswerSelected}
            setSelectedAnswers={setSelectedAnswers}
            isAnswersButtonDisabled={isAnswersButtonDisabled}
            correctAnswers={answerData?.getAnswer?.correctAnswers}
          />
        )}
        {type?.value === question.SINGLE && (
          <SingleAnswer
            answers={answers}
            setHasAnswerSelected={setHasAnswerSelected}
            setSelectedAnswers={setSelectedAnswers}
            isAnswersButtonDisabled={isAnswersButtonDisabled}
            correctAnswer={answerData?.getAnswer?.correctAnswers[0]}
          />
        )}
        {type?.value === question.BOOLEAN && (
          <BoolAnswer
            setHasAnswerSelected={setHasAnswerSelected}
            questionId={questionId}
            setBoolAnswer={setBoolAnswer}
            isAnswersButtonDisabled={isAnswersButtonDisabled}
            correctAnswer={answerData?.getAnswer?.correctBoolAnswer}
          />
        )}
      </AnswersWrapper>
    )
  }

export default Answers

const AnswersWrapper = styled.div`
  width: 70%;
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
`
