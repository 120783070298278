import { FormGroupItem } from 'components/common/Form'
import { PasswordInput } from 'components/common/TextInput/PasswordInput'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { PasswordWithValidations } from './PasswordWithValidations'

interface IFormValues {
  oldPassword?: string | undefined
  newPassword?: string | undefined
  confirmPassword?: string | undefined
}

interface IFormTouched {
  oldPassword?: boolean | undefined
  newPassword?: boolean | undefined
  confirmPassword?: boolean | undefined
}

interface IEditProfileFieldsProps {
  values: IFormValues
  touched: IFormTouched
  handleChange: (eventOrTextValue: string | React.FormEvent<any>) => void
  currentPasswordError: string
  t: any
}

export const ChangePasswordFields: React.FC<IEditProfileFieldsProps> = ({
  values,
  touched,
  handleChange,
  currentPasswordError,
  t,
}) => {
  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  const onChangeConfirmPassword = useCallback(
    (e: any) => {
      handleChange(e)
      if (values.confirmPassword !== e.target.value) {
        setConfirmPasswordError(t('actions.passwords_do_not_match'))
      } else setConfirmPasswordError('')
    },
    [values.confirmPassword, confirmPasswordError],
  )

  useEffect(() => {
    if (
      values.newPassword !== values.confirmPassword &&
      values.confirmPassword &&
      values.confirmPassword.length > 0
    ) {
      setConfirmPasswordError(t('actions.passwords_do_not_match'))
    } else setConfirmPasswordError('')
  }, [values.newPassword, values.confirmPassword])

  return (
    <InputsContainer>
      <FormGroupItem>
        <PasswordInput
          touched={touched.oldPassword}
          label={t('form_fields.current_password')}
          name="oldPassword"
          value={values.oldPassword}
          onChange={handleChange}
          error={currentPasswordError.length > 1}
          size="medium"
        />
        <ErrorText>
          {currentPasswordError === 'Old password do not match'
            ? t('actions.old_password_do_not_match')
            : currentPasswordError}
        </ErrorText>
      </FormGroupItem>
      <PasswordWithValidations label={t('form_fields.new_password')} handleChange={handleChange} />
      <FormGroupItem>
        <PasswordInput
          touched={touched.confirmPassword}
          label={t('form_fields.confirm_password')}
          name="confirmPassword"
          size="medium"
          onChange={onChangeConfirmPassword}
          value={values.confirmPassword}
          error={confirmPasswordError.length > 1}
        />
        <ErrorText>{confirmPasswordError}</ErrorText>
      </FormGroupItem>
    </InputsContainer>
  )
}

const InputsContainer = styled.div`
  width: 90%;
  margin: 0 auto;
`
const ErrorText = styled.div`
  color: #f44336;
`
