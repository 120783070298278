import React, { useEffect, useState } from 'react'
import CustomDrawer from 'components/common/Drawer/CustomDrawer'
import * as S from './styled'
import { Button } from 'components/common/Button'
import platform from 'platform'
import Icon from 'components/Icon'
import { clearCourseId, getCourseId } from 'helpers/localStorage'


const RedirectMobileDrawer = () => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(true)
  const [isMobile, setIsMobile] = useState(false)
  const handleRedirect = () => {
    const courseId = getCourseId()
    const query = courseId ? "?courseInfo=" + courseId : ""
    clearCourseId()
    const appUri = `https://wenrol.page.link/redirect/${query}`;
    let fallbackUrl = "";

    const { family: deviceType = "" } = platform.os ?? {}

    const mobileTypes = ['iOS', 'Android'];
    const isMobile = mobileTypes.includes(deviceType);

    if (deviceType === 'iOS') {
      fallbackUrl = "https://appstore.com"
    }

    if (deviceType === 'Android') {
      fallbackUrl = "https://wenrol.page.link/redirect?courseInfo=62eb7bed25e13120104fdca1"
    }

    if (true) {
      window.location.replace(appUri)

      // fallback
      // window.setTimeout(() => {
      //   //redirect if app is not installed
      //   !document.hidden && window.location.replace(fallbackUrl)
      // }, 2000)
    }

  }

  useEffect(() => {

    const { family: deviceType = "" } = platform.os ?? {}

    const mobileTypes = ['iOS', 'Android'];
    const isMobile = mobileTypes.includes(deviceType);

    setIsMobile(isMobile)
    if (isMobile) {
      sessionStorage.setItem("isDrawerOpened", "true");
    }
    // sessionStorage.setItem("isDrawerOpened", "true");
  }, [])


  const closeDrawer = () => {
    setIsDrawerOpened(false)
    clearCourseId()
    sessionStorage.setItem("isDrawerOpened", "false");
  }


  return (
    isMobile ? <CustomDrawer
      totalWidth="100%"
      anchor="bottom"
      borderRadius="20px 20px 0px 0px"
      opened={isDrawerOpened}
      toggleDrawer={() => closeDrawer()}
    >
      <div>
        <S.Title>See Wenroll in...</S.Title>
        <S.Box>
          <S.IconTextBox>
            <Icon filename="favicon" width="50px" height="50px" fill='black' />
            <S.Typography>Wenroll App</S.Typography>
          </S.IconTextBox>
          <div>
            <Button width={100} height={40} text="Open" onClick={() => {
              closeDrawer()
              handleRedirect()
            }
            }
            />
          </div>
        </S.Box>
        <S.Box>
          <S.IconTextBox>
            <Icon filename="Chrome" width="50px" height="50px" />
            <S.Typography>Chrome</S.Typography>
          </S.IconTextBox>
          <div>
            <Button width={100} height={40} text="Continue" onClick={() => closeDrawer()} />
          </div>
        </S.Box>
      </div>
    </CustomDrawer> : null
  )
}

export default RedirectMobileDrawer