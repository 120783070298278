import React, { ReactElement } from 'react'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

import { makeStyles, createStyles } from '@material-ui/core/styles'
import Icon from 'components/Icon'
import styled from 'styled-components'

interface ModalParams {
  isOpened: boolean
  onClose: any
  children: ReactElement
}

const useStyles = makeStyles(theme =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
)

const CustomModal = ({ isOpened, onClose, children }: ModalParams) => {
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpened}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpened}>{children}</Fade>
    </Modal>
  )
}

export default CustomModal

export const CloseIcon = styled(Icon)`
  position: absolute;
  top: 30px;
  right: 50px;
  cursor: 'pointer';
`

export const Info = styled.div`
  position: absolute;
  bottom: -15px;
  left: 75px;
  width: 35px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 100%;
`
