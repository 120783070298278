import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import CancelIcon from '@material-ui/icons/Cancel'

import { FlashCardSchema } from 'helpers/validationSchemas'
import { useData } from 'context/DataContext'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'
import { images } from 'pages/modules/FlashCards/images'

import {
  useUploadImage,
  useUploadAudioService,
} from 'hooks/helpers/useHelpersService'
import useAddFlashCardService from 'hooks/modules/useAddFlashCardService'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  CloseIcon as MCloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import Uploader from 'components/common/FileUploader/Uploader'
import Modal from 'components/common/Modal'
import ColorPicker from 'components/common/ColorPicker'
import ImagePicker from 'components/common/ImagePicker'
import Preview from '../Preview'
import { FormData, Params, Images } from 'pages/modules/FlashCards/interfaces'
import { FlashCardsIconsTabs } from '../staticData'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { useTheme } from '@material-ui/core'

const formData: FormData = {
  question: '',
  answer: '',
  image: '',
  audio: '',
  color: '',
}

const AddFlashCardDrawer = ({ onClose, data }: Params) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: FlashCardSchema,
    onSubmit(values) {
      const formValues: any = { ...values }
      formValues.audio = values.audio || {}
      addFlashCard(data.module.id, formValues, () => onSuccess())
    },
  })

  const onSuccess = () => {
    onClose()
    dispatch({ type: CLEAR_FORMDATA })
  }

  const [state, dispatch] = useData()
  const [openedModal, setOpenedModal] = useState<string>('')
  const [color, setColor] = useState<string>('')
  const [image, setImage] = useState<string>('')
  const [uploadedImage, setUploadedImage] = useState<string>('')
  const [audioProgress, setAudioProgress] = useState<number | null>(null)
  const { uploadImage, imageLoading } = useUploadImage()
  const { uploadAudio, audioLoading } = useUploadAudioService()
  const [currentTab, setCurrentTab] = useState<keyof Images>('bussiness')
  const { addFlashCard, addFlashCardLoading } = useAddFlashCardService({
    moduleId: data.module.id,
    skip: 0,
    limit: 0,
  })

  const uploadVideoService = (url: string, file: any) => {
    return axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: ({ total, loaded }) => {
        setAudioProgress((loaded / total) * 100)
      },
    })
  }

  const handleImageChange = (e: any) => {
    const { files, name } = e.target
    const reader: any = new FileReader()

    reader.onload = () => {
      const img: any = new Image()
      img.src = reader.result

      uploadImage(
        reader.result,
        `flashcards/${files[0].name}`,
        (link: string) => {
          setFieldValue('image', link)
          setUploadedImage(link)
        },
      )
    }
    reader.readAsDataURL(files[0])
  }

  const handleAudioChange = (e: any) => {
    const file = e.target.files[0]
    const audioFile: any = { fileName: file.name, type: file.type }
    uploadAudio(
      audioFile,
      (arg: any) => uploadVideoService(arg, file),
      (link: string) => {
        setFieldValue('audio', {
          name: file.name,
          fileType: file.name.split('.').pop(),
          link,
        })

        setAudioProgress(0)
      },
    )
  }

  const handleCloseDrawer = () => {
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'addFlashCard',
        values,
        compareTo: '',
      },
    })
  }

  const handleModalClose = () => {
    setOpenedModal('')
    if (openedModal === 'color') {
      setColor('')
    }

    if (openedModal === 'image') {
      setImage('')
    }
  }

  const handleModalSubmit = () => {
    if (openedModal === 'color') {
      setFieldValue('color', color)
      handleModalClose()
    }

    if (openedModal === 'image') {
      setFieldValue('image', image)
      handleModalClose()
    }
  }

  const handleTabChange = (event: any, newValue: keyof Images) => {
    setCurrentTab(newValue)
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick === true) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  const theme = useTheme()

  return (
    <>
      {addFlashCardLoading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{t('flashcards_layout.create_flashcard')}</DrawerTitle>
        <MCloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <TextInput
                  error={errors.question ? true : false}
                  errorMessage={errors.question ? errors.question : ''}
                  touched={touched.question}
                  label={`${t('general.question')}*`}
                  size="medium"
                  name="question"
                  type="text"
                  value={values.question}
                  onChange={handleChange}
                  multiline
                  rows={2}
                />
              </FormField>
              <FormField>
                <TextInput
                  error={errors.answer ? true : false}
                  errorMessage={errors.answer ? errors.answer : ''}
                  touched={touched.answer}
                  label={`${t('question_details.answer')}*`}
                  size="medium"
                  name="answer"
                  type="text"
                  value={values.answer}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
              </FormField>
              <FormField width="100%">
                <ColorInputContainer
                  className={`${errors.image && touched.image ? 'error' : ''}`}
                >
                  <Label
                    className={`${errors.image && touched.image ? 'error' : ''
                      }`}
                  >
                    {t('actions.upload_cover_image')}*
                  </Label>
                  <Wrapper>
                    {values.image && !uploadedImage ? (
                      <div style={{ position: 'relative', width: '50% ' }}>
                        <ImagePlaceholder>
                          <img src={values.image} alt="" />
                          <CloseIcon
                            onClick={() => {
                              setFieldValue('image', '')
                            }}
                          >
                            <CancelIcon color="secondary" />
                          </CloseIcon>
                        </ImagePlaceholder>
                      </div>
                    ) : (
                      <Button
                        text={t('actions.pick')}
                        size="medium"
                        onClick={() => setOpenedModal('image')}
                        isDisabled={
                          imageLoading || uploadedImage ? true : false
                        }
                        background={mainColor}
                      />
                    )}
                    {/* <div style={{ position: 'relative', width: '50% ' }}>
                      {imageLoading && <Loader width={20} height={20} />}
                      {uploadedImage ? (
                        <ImagePlaceholder>
                          <img src={uploadedImage} alt="" />
                          <CloseIcon
                            onClick={() => {
                              setImage('')
                              setUploadedImage('')
                              setFieldValue('image', '')
                            }}
                          >
                            <CancelIcon
                              color="secondary"
                              style={{ color: mainColor }}
                            />
                          </CloseIcon>
                        </ImagePlaceholder>
                      ) : (
                        <>
                          <UploadInput
                            type="file"
                            id="image"
                            onChange={(e: any) => handleImageChange(e)}
                            disabled={
                              values.image && !uploadedImage ? true : false
                            }
                            accept=".svg"
                          />
                          <InputLabel
                            htmlFor="image"
                            className={`${
                              values.image && !uploadedImage ? 'disabled' : ''
                            }`}
                          >
                            {t('general.upload')}
                          </InputLabel>
                        </>
                      )}
                    </div> */}
                  </Wrapper>
                  {errors.image && touched.image && (
                    <ErrorMessage>{errors.image}</ErrorMessage>
                  )}
                </ColorInputContainer>
              </FormField>
              <FormField width="100%">
                <AudioUploader>
                  <Uploader
                    label={t('actions.upload_audio_file')}
                    accept="audio/*"
                    onFileChange={(e: any) => handleAudioChange(e)}
                    deleteFile={() => {
                      setFieldValue('audio', '')
                      setAudioProgress(0)
                    }}
                    type="audio"
                    inputValue={values.audio ? values.audio.name : ''}
                    onInputChange={(e: any) => { }}
                    disabled
                    uploadedFile={values.audio ? values.audio.link : ''}
                    fileExtension={values.audio ? values.audio.fileType : ''}
                    loading={audioProgress ? audioProgress : null}
                    id="audio"
                  />
                </AudioUploader>
              </FormField>
              <FormField width="100%">
                <ColorInputContainer
                  className={`${errors.color && touched.color ? 'error' : ''}`}
                >
                  <Label
                    className={`${errors.color && touched.color ? 'error' : ''
                      }`}
                  >
                    {t('actions.pick_color')}*
                  </Label>
                  {values.color ? (
                    <ColorPlaceholder
                      background={values.color}
                      onClick={() => setOpenedModal('color')}
                    />
                  ) : (
                    <Button
                      text={t('actions.pick')}
                      size="medium"
                      onClick={() => setOpenedModal('color')}
                      background={mainColor}
                    />
                  )}
                  {errors.color && touched.color && (
                    <ErrorMessage>{errors.color}</ErrorMessage>
                  )}
                </ColorInputContainer>
              </FormField>
            </FormFields>
            <Preview
              backgroundColor={values.color}
              question={values.question}
              image={values.image}
              audio={values.audio}
              answer={values.answer}
              t={t}
            />
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.save')}
                type="medium"
                color="secondary"
                btnType="submit"
                background={mainColor}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
      <Modal isOpened={openedModal ? true : false} onClose={handleModalClose}>
        <ModalContainer>
          <ModalContent>
            {openedModal === 'color' && (
              <ColorPicker
                selectedColor={color ? color : values.color}
                setSelectedColor={setColor}
              />
            )}
            {openedModal === 'image' && (
              <ImagePicker
                images={images[currentTab]}
                selectedImage={image}
                setSelectedImage={setImage}
                tabs={{
                  withTabs: true,
                  currentTab,
                  handleTabChange,
                  tabsList: FlashCardsIconsTabs,
                }}
              />
            )}
          </ModalContent>
          <ModalButtons>
            <Button
              text={t('actions.cancel')}
              size="medium"
              color="secondary"
              onClick={handleModalClose}
              background="#E0E1E2"
              textColor={validationRed}
              outline={`1px solid ${red}`}
            />
            <Button
              text={t('form_fields.select')}
              size="medium"
              isDisabled={openedModal === 'image' ? !image : !color}
              onClick={handleModalSubmit}
              background={mainColor}
            />
          </ModalButtons>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default AddFlashCardDrawer

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const ColorInputContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 14px 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.error {
    border: 1px solid red;
  }

  .btn-wrapper {
    margin-right: 5px;
  }

  button {
    width: 80px !important;
    height: 38px;
    padding: 0 !important;
  }
`

const ErrorMessage = styled.article`
  position: absolute;
  color: red;
  bottom: -25px;
  left: 0;
`

const ModalContainer = styled.div`
  background: #fff;
  width: 1000px;
  height: 80%;
  padding: 40px;
  border-radius: 5px;
`

const ModalContent = styled.div`
  border: 1px solid #73787a;
  border-radius: 5px;
  height: 90%;
  overflow-y: scroll;
  display: flex;
  align-items: center;
`
const ModalButtons = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;

  .btn-wrapper {
    margin-right: 5px;
  }
`

const UploadInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  overflow: hidden;
`

const Label = styled.div`
  position: absolute;
  left: 11px;
  top: -9px;
  background: #fff;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);

  &.error {
    color: red;
  }
`
const InputLabel = styled.label`
  cursor: pointer;
  color: #fff;
  background: ${mainColor};
  border-radius: 4px;
  width: 80px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    color: rgba(0, 0, 0, 0.26);
    pointer-events: none;
    background: rgba(0, 0, 0, 0.3);
  }
`

const ImagePlaceholder = styled.figure`
  position: relative;
  margin-top: 2px;
  width: 36px;
  img {
    width: 36px;
    height: 36px;
  }
`
const CloseIcon = styled.span`
  position: absolute;
  cursor: pointer;
  top: -10px;
  right: -10px;
`

const ColorPlaceholder = styled.div<{ background: string }>`
  cursor: pointer;
  width: 36px;
  height: 36px;
  background: ${({ background }) => background};
`

const AudioUploader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  label {
    display: block;
    width: 80px;
    height: 38px;
  }
`
