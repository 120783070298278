import React, { useState, useEffect, useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import update from 'immutability-helper'
import { GET_ALL_QUIZ } from 'gql/quiz/quiz.query'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { gql } from 'graphql.macro'
import { useMutation } from '@apollo/react-hooks'

import { DrawerEventEmitter } from 'helpers/drawer'
import { actionConfig, questionActionsConfig } from './gridConfig'
import { useSwal } from 'hooks/useSwal'
import { useUserValue } from 'context/UserContext'
import { useDeleteQuestion } from 'hooks/questions/useDeleteQuestion'
import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import { useFetchQuestions, PER_PAGE } from 'hooks/questions/useQuestions'

import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'

import Drawer from 'components/common/Drawer'
import LinkQuestionsToModuleDrawer from './LinkQuestionsToModuleDrawer'
import { Button } from 'components/common/Button'
import Toolbar from './Toolbar'
import Grid from './Grid'
import FilterDrawer from './FilterDrawer'
import Pagination from 'components/common/Pagination'
import { mainColor } from 'components/common/Colors/CommonColors'
import {
  ActionButton,
  GridLayoutActions,
  GridLayoutForm,
  GridToolbar,
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import Icon from 'components/Icon'
import { FormControlLabel, useTheme } from '@material-ui/core'
import Checkbox from 'components/common/Checkbox'
import Search from 'components/common/Search'
import SyncIcon from '@material-ui/icons/Sync'
import SelectBtn from 'components/common/SelectBtn'

interface CurrentModuleParams {
  moduleId: string
  name: string
}

interface Params {
  fromModule?: boolean
  topics: any
  currentModule?: CurrentModuleParams | undefined
  cols?: number
  isDraggable?: boolean
  refetchModule?: any
  manualCompanyId?: string
  canModify?: boolean
  handleTabChange?: any
  setOpenCreateQuizDrawer?: (open: boolean) => void
}

const CHANGE_ORDER = gql`
  mutation($draggedId: String!, $droppedId: String!) {
    changeQuestionsOrder(draggedId: $draggedId, droppedId: $droppedId)
  }
`

const QuestionsLayout = ({
  fromModule = false,
  topics,
  currentModule,
  cols,
  isDraggable = false,
  refetchModule,
  manualCompanyId,
  canModify,
  handleTabChange,
  setOpenCreateQuizDrawer = () => { },
}: Params) => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const [tabValue, setTabValue] = useState(0)

  const defaultFilters = {
    companyId: {
      type: 'exact',
      value: manualCompanyId || state.selectedCompanyId,
    },
  }
  const { questions, loading, refetch, error }: any = useFetchQuestions(
    currentModule ? 'module' : '',
    currentModule ? currentModule.moduleId : '',
    defaultFilters,
  )

  const moduleId = currentModule ? currentModule.moduleId : null
  let topicIds: string[] = []
  let lessonIds: string[] = []

  if (topics) {
    topics.forEach((topic: any) => {
      topicIds.push(topic.id)
      topic.lessons.forEach((lesson: any) => {
        lessonIds.push(lesson.id)
      })
    })
  }

  const ids = [moduleId, ...topicIds, ...lessonIds]

  const { data: quizList } = useQuery(GET_ALL_QUIZ, {
    variables: {
      query: JSON.stringify({ actionId: { $in: ids } }),
    },
  })

  const [cards, setCards] = useState([])
  const moveCard = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage] = useState<number>(PER_PAGE)
  const [changeQuestionsOrder] = useMutation(CHANGE_ORDER)
  const history = useHistory()
  const { deleteQuestion } = useDeleteQuestion()

  const [searchValue, setSearchValue] = useState<string>('')
  const [gridStyle, setGridStyle] = useState<string>('card')
  const [selectedItem, setSelectedItem] = useState<any>([])
  const [selectAll, setSelectAll] = useState<any>({})
  const [LinkQuestionDrawerOpened, setLinkQuestionDrawerOpened] = useState<
    boolean
  >(false)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<any>({})
  const [filterOptions, setFilterOptions] = useState<any>({})
  const [filtered, setFiltered] = useState<boolean>(false)
  const [filteredQuestionList, setFilteredQuestionList] = useState<any>([])
  const [maxQuestionsCountInQuiz, setMaxQuestionsCountInQuiz] = useState<
    number
  >(0)
  const { fireSwal } = useSwal()
  const [resetVisible, setResetVisible] = useState(true)

  const selectItem = (id: never, event: boolean) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const questionList =
        filteredQuestionList.length > 0 ? filteredQuestionList : questions.data
      const newArr: string[] = questionList.map((n: any) => n.id)
      setSelectedItem({ ...selectedItem, [currentPage]: newArr })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({ ...selectedItem, [currentPage]: [] })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const fireNotDeleteQuestionPopup = (isMultiple: boolean) => {
    const questionText = isMultiple
      ? t('general.questions')
      : t('general.question')
    fireSwal({
      title: `${t('popups.can_not_delete_questions')} ${questionText}`,
      confirmText: t('actions.close'),
      onConfirm: () => { },
    })
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    if (!currentModule) {
      refetch({
        type: '',
        id: '',
        filter: {
          ...defaultFilters,
          question: { type: 'match', value: searchValue },
          ...filterOptions,
        },
        currentPage: 1,
      })
      setFiltered(true)
    }
    setResetVisible(false)
  }

  const handleSearchChange = (e: any) => {
    const { value } = e.target

    setSearchValue(value)
    if (!currentModule) {
      if (e.target.value === '') {
        refetch({
          type: '',
          id: '',
          filter: { ...defaultFilters, ...filterOptions },
        })
      }
    }
  }

  const handleResetClick = () => {
    setFilterData({})
    setFiltered(false)
    setSearchValue('')
    setCurrentPage(1)
    setFilterOptions({})
    if (currentModule) {
      setFilteredQuestionList(cards)
    } else {
      refetch({
        type: '',
        id: '',
        filter: { ...defaultFilters },
      })
    }
    setResetVisible(true)
  }

  const handleChangeRequest = async (draggedId: string, droppedId: string) => {
    const { data } = await changeQuestionsOrder({
      variables: { draggedId, droppedId },
    })
  }

  const handleCreateQuestionClick = (moduleId: string, name: string) => {

    DrawerEventEmitter.emit('openDrawer', 'createQuestion', true, {
      data: {
        moduleId,
        name,
        manualCompanyId,
        refetch: () => {
          if (refetchModule) {
            refetchModule()
          }
          setSelectAll({})
          setSelectedItem({})
          refetch()
        },
      },
    })
  }

  const handleLinkQuestionToModuleClick = (moduleId: string, name: string) => {
    setLinkQuestionDrawerOpened(true)
  }

  const handleViewClick = (questionId: string) => {
    history.push(`/questions/${questionId}`)
  }

  const handleEditClick = (id: string) => {
    const title: string = currentModule
      ? t('popups.edit_question_in_module')
      : t('popups.edit_question_general')
    fireSwal({
      title,
      confirmText: t('popups.confirm_edit'),
      onConfirm: () => {
        DrawerEventEmitter.emit('openDrawer', 'editQuestion', true, {
          id,
          data: {
            moduleId: currentModule ? currentModule.moduleId : '',
            name: currentModule ? currentModule.name : '',
          },
        })
      },
    })
  }

  const handleDuplicateClick = (id: string) => {
    fireSwal({
      title: t('popups.edit_duplicate_question'),
      confirmText: t('popups.confirm_edit_duplicate'),
      onConfirm: () => {
        DrawerEventEmitter.emit('openDrawer', 'editQuestion', true, {
          id,
          data: {
            moduleId: currentModule ? currentModule.moduleId : '',
            name: currentModule ? currentModule.name : '',
            duplicate: true,
            manualCompanyId: manualCompanyId,
          },
        })
      },
    })
  }

  const handleDeleteClick = (ids: string[], edited: boolean) => {
    const isMultiple = ids.length > 1 ? true : false
    if (
      currentModule &&
      filteredQuestionList.length - ids.length < maxQuestionsCountInQuiz
    ) {
      fireNotDeleteQuestionPopup(isMultiple)
    } else {
      const moduleId: string = currentModule ? currentModule.moduleId : ''
      let mainText: string = ''
      if (!moduleId) {
        mainText = isMultiple
          ? t('popups.delete_question_many')
          : t('popups.delete_question_single')
      } else {
        mainText = edited
          ? t('popups.delete_question_edited')
          : t('popups.delete_question_many_in_module')
      }

      fireSwal({
        title: `${mainText}`,
        confirmText: t('popups.confirm_remove'),
        onConfirm: () => {
          deleteQuestion(moduleId, ids, () => {
            if (refetchModule) {
              refetchModule()
            }
            refetch()
          })
          setSelectedItem([])
        },
      })
    }
  }

  const questionActions = questionActionsConfig(
    handleCreateQuestionClick,
    handleLinkQuestionToModuleClick,
    true,
    t,
  )
  const actionHandler = (event: string, field: any) => {
    switch (event) {
      case 'view':
        return handleViewClick(field.id)
      case 'edit':
        return handleEditClick(field.id)
      case 'duplicate':
        return handleDuplicateClick(field.id)
      case 'delete':
        return handleDeleteClick([field.id], field.edited)
      case 'pdf':
        return () => { }
      default:
        return () => console.log('null')
    }
  }

  const handleCloseDrawer = () => {
    const { text, confirm } = state.closeDrawer
    // console.log(text, confirm)
    if (!text && !confirm) {
      setLinkQuestionDrawerOpened(false)
    } else {
      fireSwal({
        title: text,
        text: t('popups.sure'),
        onConfirm: () => {
          setLinkQuestionDrawerOpened(false)
          dispatch({
            type: TOGGLE_CLOSE_DRAWER,
            payload: {
              text: '',
              confirm: false,
            },
          })
        },
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }

  const handlePaginationClick = useCallback(
    (event: any, value: number) => {
      if (value !== currentPage) {
        refetch({
          type: '',
          id: '',
          filter: {
            ...defaultFilters,
            ...filterOptions,
            companyId: {
              type: 'exact',
              value: manualCompanyId || state.selectedCompanyId,
            },
            question: {
              type: 'match',
              value:
                searchValue.length > 0
                  ? searchValue
                  : filterOptions?.question?.value || '',
            },
          },
          currentPage: value,
          perPage,
        })
        setCurrentPage(value)
      }
    },
    [currentPage, filterOptions, defaultFilters],
  )

  const handleFilterClick = (filterOptions: any) => {
    setSelectedItem({})
    setSelectAll({})
    refetch({
      filter: {
        ...defaultFilters,
        ...filterOptions,
      },
      currentPage: 1,
    })
    setFilterOptions(filterOptions)
    setResetVisible(false)
  }

  const handleLinkQuestion = (e: any) => {
    setLinkQuestionDrawerOpened(true)
  }

  // const actions = actionConfig(currentModule, actionHandler, canModify, t)
  const actions = actionConfig(currentModule, actionHandler, true, t)

  // const questionList = (data && data.questions) || []

  useEffect(() => {
    if (cards && currentModule) {
      if (filtered) {
        const ids = filteredQuestionList.map((item: any) => item.id)
        const filteredCards = cards.filter((item: any) => ids.includes(item.id))
        // setFilteredQuestionList(filteredCards)
      } else {
        const questions = cards.filter((item: any) =>
          item.question.toLowerCase().includes(searchValue.toLowerCase()),
        )
        setFilteredQuestionList(questions)
      }
    }
  }, [cards, searchValue])

  useEffect(() => {

    if (questions) {
      // console.log('data', data)
      setCards(questions.data)

    }
  }, [questions])

  useEffect(() => {
    if (quizList) {
      const quizzes = quizList.getAllQuizzes
      const quizRandomizationNumbers = quizzes.map(
        (quiz: any) => quiz.randomizationNumber,
      )
      const maxQuestionsCount =
        quizRandomizationNumbers.length > 0
          ? Math.max.apply(null, quizRandomizationNumbers)
          : 0
      setMaxQuestionsCountInQuiz(maxQuestionsCount)
    }
  }, [quizList])

  const isStudent = state.currentUser.role === 'STUDENT'
  const isCompanySuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !!state?.currentUser?.companyId?.id

  const isCompanyAdmin =
    state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  const theme = useTheme();

  return (
    <Container theme={theme}>
      <Drawer
        opened={LinkQuestionDrawerOpened}
        toggleDrawer={() => { }}
        totalWidth="900px"
      >
        <LinkQuestionsToModuleDrawer
          title={t('questions_layout.link_questions_to_module')}
          handleCloseDrawer={handleCloseDrawer}
          closeDrawer={() => setLinkQuestionDrawerOpened(false)}
          currentModule={currentModule}
          refetch={() => {
            refetch()
            refetchModule()
          }}
          currentModuleQuestionsLength={cards.length}
          manualCompanyId={manualCompanyId}
        />
      </Drawer>

      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => { }}
        totalWidth="900px"
      >
        <FilterDrawer
          closeDrawer={() => setFilterDrawerOpened(false)}
          handleFilterClick={handleFilterClick}
          filterData={filterData}
          quizModel={currentModule ? 'module' : 'general'}
          setFilterData={setFilterData}
          currentModule={currentModule}
          refetch={refetch}
          setFiltered={() => setFiltered(true)}
          questions={cards}
          setFilteredQuestionList={setFilteredQuestionList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          t={t}
        />
      </Drawer>

      {!fromModule && canModify && (
        <div style={{ marginBottom: 10 }}>
          <Button
            text={t('questions_layout.create_question')}
            color="secondary"
            onClick={() => handleCreateQuestionClick('', '')}
            icon={<AddCircleRoundedIcon />}
            background={mainColor}
          />
        </div>
      )}
      {/* <Toolbar
        fromModule={fromModule}
        currentModule={currentModule}
        actions={questionActions}
        searchValue={searchValue}
        gridStyle={gridStyle}
        setGridStyle={setGridStyle}
        dataLength={filteredQuestionList.length || cards.length}
        selectedItem={selectedItem[currentPage] || []}
        selectAllItem={selectAllItem}
        handleDeleteClick={handleDeleteClick}
        handleSearchChange={handleSearchChange}
        handleSearchSubmit={handleSearchSubmit}
        openFilterDrawer={() => setFilterDrawerOpened(true)}
        handleResetClick={handleResetClick}
        filtered={filtered}
        canModify={canModify}
        handleCreateQuizClick={handleCreateQuizClick}
        t={t}
        isQuizButtonInVisible={isStudent}
      /> */}
      {/* <GridToolbar style={{ borderBottom: 'none' }}>
        <GridLayoutActions>
          <div>
            {canModify && (
              <AddButton onClick={handleCreateQuizClick}>
                <Icon filename={'add'} fill={'#ffffff'} />
                {t('quizzes_layout.create_quiz')}
              </AddButton>
            )}
          </div>
          {!isCompanySuperAdmin && !isCompanyAdmin && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    (filteredQuestionList.length || cards.length) > 0 &&
                    (filteredQuestionList.length || cards.length) ===
                      selectedItem.length
                      ? true
                      : false
                  }
                  disabled={(filteredQuestionList.length || cards.length) === 0}
                  onChange={selectAllItem}
                />
              }
              label={
                (filteredQuestionList.length || cards.length) ===
                selectedItem.length
                  ? t('general.diselect_all')
                  : t('general.select_all')
              }
            />
          )}
        </GridLayoutActions>
        <GridLayoutForm>
          {`${selectedItem?.[currentPage]?.length || 0} ${t(
            'general.items_selected',
          )}`}
          <ActionButton
            onClick={() =>
              handleDeleteClick(selectedItem[currentPage] || [], false)
            }
            disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
            fill={'#CC3131'}
            disabledFill={'#080A0AD6'}
          >
            <Icon filename={'delete'} />
          </ActionButton>
        </GridLayoutForm>
      </GridToolbar> */}
      {/* {canModify && (
        <Text>{t('questions_layout.questions_recomendation')}</Text>
      )} */}
      <GridToolbar
        style={{ borderBottom: '1px solid #EBEBEB', marginBottom: '40px' }}
        theme={theme}
      >
        <GridLayoutActions>
          {canModify && (
            <SelectBtn
              active={tabValue}
              onChange={setTabValue}
              options={[
                {
                  label: t('questions_layout.create_question'),
                  value: 0,
                  onClick: () => handleCreateQuestionClick(currentModule ? currentModule.moduleId : '', currentModule ? currentModule.name : ''),
                },
                {
                  label: t('questions_layout.link_question_to_module'),
                  value: 1,
                  onClick: e => handleLinkQuestion(e),
                },
              ]}
            />
          )}
          {/* {!isCompanySuperAdmin && !isCompanyAdmin && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={(selectedItem?.[currentPage]?.length || 0) > 0}
                  onChange={selectAllItem}
                />
              }
              label={t('general.select_all')}
            />
          )} */}
        </GridLayoutActions>
        <GridLayoutForm>
          <ActionButton
            disabled={resetVisible}
            onClick={handleResetClick}
            theme={theme}
          >
            <SyncIcon fontSize="small" />
          </ActionButton>
          <MultipleAction theme={theme}>
            <ActionButton theme={theme} onClick={() => setFilterDrawerOpened(true)}>
              <Icon filename={'filter'} fill={'#080A0AD6'} />
            </ActionButton>
            <MultipleActionTooltip>
              {t('actions.filters')}
            </MultipleActionTooltip>
          </MultipleAction>
          <Search
            inputProps={{
              value: searchValue,
              onChange: e => setSearchValue(e.target.value),
            }}
            onClick={handleSearchSubmit}
          />
        </GridLayoutForm>
      </GridToolbar>
      {loading && <div>{t('general.loading')}...</div>}
      <div style={{ padding: '0px 30px' }}>
        <QuestionHeader theme={theme}>
          <div className="title">{t('questions_layout.title').toUpperCase()}</div>
          <GridLayoutForm>
            {`${selectedItem?.[currentPage]?.length || 0} ${t(
              'general.items_selected',
            )}`}
            <ActionButton
              onClick={() =>
                handleDeleteClick(selectedItem[currentPage] || [], false)
              }
              disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
              fill={'#CC3131'}
              disabledFill={'#080A0AD6'}
              theme={theme}
            >
              <Icon filename={'delete'} />
            </ActionButton>
          </GridLayoutForm>
        </QuestionHeader>
        <Grid
          gridStyle={gridStyle}
          cols={cols || 3}
          actions={actions}
          data={currentModule ? filteredQuestionList : cards}
          currentModuleId={currentModule ? currentModule.moduleId : ''}
          selectItem={selectItem}
          selectedItems={selectedItem[currentPage] || []}
          isDraggable={isDraggable && !searchValue && !filtered ? true : false}
          moveCard={moveCard}
          handleChangeRequest={handleChangeRequest}
          t={t}
          canModify={canModify}
        />
        {questions && questions.totalPages > 1 && (
          <div
            style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}
          >
            <Pagination
              currentPage={questions ? questions.currentPage : 1}
              totalPages={questions ? questions.totalPages : 0}
              handleChange={handlePaginationClick}
            />
          </div>
        )}
      </div>
    </Container>
  )
}

export default QuestionsLayout

const Text = styled.div`
  width: 100%;
  text-align: right;
  font-size: 14px;
  color: #ff3a52;
  margin-bottom: 10px;
`

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #317bf4;
  padding: 18px 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 30px;

  &:hover {
    transition: 0.3s;
    background: #3165f4;
  }
`

const Container = styled.div`
  overflow: hidden;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  height: 100%;
`

const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;

  .title {
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 600;
    font-size: 24px;
  }
`
