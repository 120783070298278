import React, { useState, useEffect } from 'react'
import { Button } from 'components/common/Button'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { useFormik } from 'formik'
import {
  TabNavigation,
  TabPanel,
  activeTabClassName,
} from 'components/common/Tabs/Tabs'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { GET_GROUP } from 'gql/group/group.query'
import { useHistory } from 'react-router-dom'

import useAddGroup from 'hooks/group/useAddGroup'
import useCompaniesService from 'hooks/useCompaniesService'
import { GroupSchema } from 'helpers/validationSchemas'

import {
  Container,
  Wrapper,
  FooterWrapper,
  ButtonWrapper,
} from '../styled-components'

import CreateGroup from './CreateGroup'
import GroupList from './GroupList'
import { mainColor } from 'components/common/Colors/CommonColors'
import DropdownButton from 'components/common/DropdownButton'
import { useTheme } from '@material-ui/core'

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

const formData: any = {
  name: '',
  location: '',
  admin: null,
  courses: null,
  note: '',
}

const CompanyGroup = (props: any) => {
  const [tabValue, setTabValue] = useState(0)
  const [createGroup, setCreateGroup] = useState('')
  const { companyData, setData } = props
  const history = useHistory()
  const { setCompanyState } = useCompaniesService()

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const { data, refetch } = useQuery(GET_GROUP, {
    variables: {
      filter: {
        company: {
          type: 'exact',
          value: companyData.companyData.id,
        },
      },
      perPage: 0,
    },
  })

  useEffect(() => {
    if (data && data.getAllGroups) {
      setData({
        ...companyData,
        groups: data.getAllGroups.data,
      })
    }
  }, [data])

  const { loading, addGroup, group } = useAddGroup({
    filter: {
      company: {
        type: 'exact',
        value: companyData.companyData.id,
      },
    },
  })

  // useEffect(() => {
  //   if (group && group.id) {
  //     setData({
  //       ...companyData,
  //       groups: [group, ...companyData.groups]
  //     })
  //   }
  // }, group)

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: formData,
    validationSchema: GroupSchema,
    onSubmit(values) {
      if (loading) return

      const data = { ...values }

      data.courses = []
      if (data.admin) {
        data.admin = data.admin.value
      }

      if (values.courses) {
        data.courses = values.courses.map((i: any) => ({
          name: i.label,
          courseId: i.value,
          state: i.state,
        }))
      }

      data.company = companyData.companyData.id
      // delete data.courses

      addGroup(data, [], () => {
        refetch()
        if (createGroup === 'reset') {
          resetForm()
        } else {
          resetForm()
          setTabValue(1)
        }
      })
    },
  })

  const onFormSubmit = (action: string) => {
    setCreateGroup(action)
    handleSubmit()
  }

  const nextButtonDisabled = !(companyData.groups.length > 0)

  const { t } = props

  const createGroupButtons = () => {
    return [
      {
        title: `${t('actions.create_group')}`, onClick: () => {
          setTabValue(0);
        }
      },
      {
        title: `${t('general.groupList')}`, onClick: () => {
          setTabValue(1);
        }
      },
    ]
  }

  const theme = useTheme();

  return (
    <>
      <Container>
        <Wrapper theme={theme} width="100%">
          <ContentWrapper>
            <ActionButtonContainer>
              <DropdownButton title={t('general.groups')} buttons={createGroupButtons()} />
            </ActionButtonContainer>
            <TabNavigation withBorder={true}>
              <TabPanel value={tabValue} index={0}>
                <Wrapper theme={theme} width="70%" style={{ margin: 'auto' }}>
                  <CreateGroup
                    handleSubmit={onFormSubmit}
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    adminList={companyData.admins}
                    companyId={companyData.companyData.id}
                    addGroupLoading={loading}
                    t={t}
                  />
                </Wrapper>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <GroupList
                  groupList={companyData.groups}
                  adminList={companyData.admins}
                  companyId={companyData.companyData.id}
                  refetch={refetch}
                  setTabValue={setTabValue}
                  t={t}
                />
              </TabPanel>
            </TabNavigation>
          </ContentWrapper>
        </Wrapper>
      </Container>

      <FooterWrapper>
        <ButtonWrapper>
          <Button
            text={t('actions.save_as_draft')}
            type="medium"
            color="secondary"
            onClick={() => {
              setCompanyState(companyData.companyData.id, 4, 'Success', () =>
                history.push('/Companies'),
              )
            }}
            background="#ffffff"
            textColor="#080A0A8A"
            outline="1px solid #080A0A14"
          />

          <Button
            text={t('actions.save_and_next')}
            type="medium"
            onClick={() => {
              setCompanyState(companyData.companyData.id, 5, 'Success', () =>
                props.changeStep(5),
              )
            }}
            isDisabled={nextButtonDisabled}
            color="secondary"
            background={'#317BF4'}
          />
        </ButtonWrapper>
      </FooterWrapper>
    </>
  )
}

export default CompanyGroup

const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 0px 50px;
`
const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
`

const ActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`