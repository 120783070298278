import { gql } from 'graphql.macro'

export const GET_MODULES = gql`
  query($filter: CourseFilter, $currentPage: Int, $perPage: Int, $fetchAll: Boolean) {
    allModules(filter: $filter, currentPage: $currentPage, perPage: $perPage, fetchAll: $fetchAll) {
      currentPage
      totalPages
      totalCount
      data {
        id
        orderIndex
        name
        coverImage {
          name
          fileType
          link
        }
        description
        editorText
        createDate
        numberOfTopics
        numberOfLessons
        duration
        quizList {
          id
          name
          IQTestType
          questions
          questionList {
            id
            type {
              label
              value
            }
            question
            answers {
              id
              isCorrect
              value
            }
            boolAnswer
          }
        }
        subtitle {
          name
          fileType
          link
        }
        courses {
          courseId
          name
        }
        attachment {
          name
          fileType
          link
        }
        video {
          name
          thumbnail
          title
          links {
            url
            fileType
            converted
          }
        }
        companyId

      }
    }
  }
`


export const GET_MODULE = gql`
  query($moduleId: String!, $courseId: String) {
    getModule(moduleId: $moduleId, courseId: $courseId) {
      id
      name
      coverImage {
        name
        fileType
        link
      }
      description
      editorText
      createDate
      numberOfLessons
      subtitle {
        name
        fileType
        link
      }
      courses {
        courseId
        name
      }
      attachment {
        name
        fileType
        link
      }
      companyId
      # video {
      #   name
      #   thumbnail
      #   title
      #   links {
      #     url
      #     fileType
      #     converted
      #   }
      # }
      certificateImage {
        name
        fileType
        link
      }
      topics {
        id
        quizzes{
          id
          name
          time
          questionsCount
          blocked
          score
          quizParticipationType
        }
        name
        description
        attachment {
          name
          fileType
          size
          link
        }
        lessons {
          name
          id
          pdfLessonDuration
          isLocked
          quizzes{
            id
            name
            time
            questionsCount
            blocked
            score
            quizParticipationType
          }
          subtitle {
            name
            fileType
            link
          }
          video {
            id
            name
            thumbnail
            title
            duration
            links {
              url
              fileType
              converted
            }
          }
          attachment {
            name
            fileType
            link
          }
          duration
          progress
          audio {
            name
            duration
            link
            fileType
          }
          contentDocument {
            name
            fileType
            link
          }
          transcriptFile {
            name
            fileType
            link
          }
        }
      }
      numberOfQuestions
      numberOfTopics
      duration
      companyId
      skillTestVideoEnabled
      isLocked
      quizList{
        id
        name
        time
        questionsCount
        blocked
        score
        quizParticipationType
      }
    }
  }
`

export const GET_COURSE_MODULES = gql`
  query($courseId: String!, $type: String, $filter: CourseFilter) {
    getModulesByCourseId(courseId: $courseId, type: $type, filter: $filter) {
      id
      orderIndex
      name
      coverImage {
        name
        fileType
        link
      }
      description
      editorText
      createDate
      subtitle {
        name
        fileType
        link
      }
      topics {
        id
        name
        # lessons {
        #   id
        #   name
        # }
      }
      attachment {
        name
        fileType
        link
      }
      video {
        name
        thumbnail
        title
        links {
          url
          fileType
          converted
        }
      }
    }
  }
`

export const ADD_MODULE = gql`
  mutation($input: ModuleInput, $courseData: CourseData) {
    addModule(input: $input, courseData: $courseData) {
      id
      orderIndex
      name
      description
      editorText
      createDate
      numberOfTopics
      numberOfLessons
      duration
      skillTestVideoEnabled
      coverImage {
        name
        fileType
        link
      }
      subtitle {
        name
        fileType
        link
      }
      courses {
        courseId
        name
      }
      video {
        name
        thumbnail
        title
        links {
          url
          fileType
          converted
        }
      }
      attachment {
        name
        fileType
        link
      }
      certificateImage {
        name
        fileType
        link
      }
    }
  }
`

export const ADD_COURSE_MODULE = gql`
  mutation($courseData: CourseData, $moduleIds: [String!]) {
    addModulesToCourse(courseData: $courseData, moduleIds: $moduleIds) {
      id
      name
      coverImage {
        name
        fileType
        link
      }
      description
      editorText
      numberOfTopics
      numberOfLessons
      duration
      createDate
      subtitle {
        name
        fileType
        link
      }
      courses {
        courseId
        name
      }
      topics {
        id
        name
        # lessons {
        #   id
        #   name
        # }
      }
      attachment {
        name
        fileType
        link
      }
      video {
        name
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
    }
  }
`

export const EDIT_MODULE = gql`
  mutation($moduleId: String!, $input: ModuleInput) {
    editModule(moduleId: $moduleId, input: $input) {
      id
      name
      description
      editorText
      createDate
      numberOfTopics
      numberOfLessons
      duration
      skillTestVideoEnabled
      coverImage {
        name
        fileType
        link
      }
      subtitle {
        name
        fileType
        link
      }
      video {
        name
        thumbnail
        title
        links {
          url
          fileType
          converted
        }
      }
      attachment {
        name
        fileType
        link
      }
      certificateImage {
        name
        fileType
        link
      }
    }
  }
`

export const DELETE_MODULE = gql`
  mutation($ids: [String]) {
    deleteModule(ids: $ids) {
      id
      name
      description
      createDate
      numberOfTopics
      numberOfLessons
      duration
      subtitle {
        name
        fileType
        link
      }
      coverImage {
        name
        fileType
        link
      }
      video {
        name
        thumbnail
        title
        links {
          url
          fileType
          converted
        }
      }
      attachment {
        name
        fileType
        link
      }
      certificateImage {
        name
        fileType
        link
      }
    }
  }
`
