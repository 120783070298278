import i18n from 'i18n/index'
export let FlashCardsIconsTabs: any = []

function translate() {
  FlashCardsIconsTabs = [
    {
      label: i18n.t('flashcards_layout.bussiness'),
      value: 'bussiness',
    },
    {
      label: i18n.t('flashcards_layout.everyday'),
      value: 'everyday',
    },
    {
      label: i18n.t('flashcards_layout.influence'),
      value: 'influence',
    },
    {
      label: i18n.t('flashcards_layout.social_media'),
      value: 'social',
    },
  ]
}

translate()

i18n.on('languageChanged init', () => {
  translate()
})
