import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useSwal } from 'hooks/useSwal'
import { FormContainer } from 'components/common/Form'
import {
  DrawerHeaderSteps,
  DrawerStepsTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import Stepper from 'components/common/Stepper'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/react-hooks'
import { GET_COURSE_BY_ORIGINAL_ID } from 'gql/course/course.query'
import useDeleteCourse from 'hooks/course/useDeleteCourse'
import { useData } from 'context/DataContext'
import { SET_CREATED_USER } from 'store/types'
import { useTranslation } from 'react-i18next'

import Step1 from './Step1'
import Step2 from 'pages/courses/EditCourseDrawer/Tab2'
import AssignCoach from 'pages/courses/AddCourseDrawer/AssignCoach'
import { generateFullName } from 'utils/utils'
import { useAddRemoveCourseFromCompany } from 'hooks/companies/useCompanySettings'
import { useEditCourse, useChangeCourseStep } from 'hooks/course/useEditCourse'

interface Props {
  boughtCourses: string[]
  handleCloseDrawer: any
  isEditMode?: string
  refetchCourses: any
}

const formData = {
  name: '',
  description: '',
  editorText: '',
  level: null,
  coursePrivacy: '',
  coachType: '',
  coaches: [],
  skills: [],
  groups: [],
  avatar: null,
  certificate: null,
  price: '',
  currency: '',
  video: null,
  subtitle: null,
}

const AddPurchasedCourseDrawer = (props: Props) => {
  const { t } = useTranslation()
  const [state, dispatch] = useData()

  const [step, setStep] = useState<number>(0)
  const [selectedItem, setSelectedItem] = useState('')
  const [selectedGroup, setSelectedGroup] = useState<any>([])
  const params: any = useParams()
  const { deleteCourse } = useDeleteCourse()
  const { fireSwal } = useSwal()

  const { addRemoveCourseFromCompany } = useAddRemoveCourseFromCompany()
  const { changeCourseStep } = useChangeCourseStep()
  const [
    fetchCourse,
    { data: courseData, loading: courseLoading },
  ] = useLazyQuery(GET_COURSE_BY_ORIGINAL_ID)

  useEffect(() => {
    if (props.isEditMode) {
      fetchCourse({
        variables: {
          courseId: props.isEditMode,
          companyId: params.id,
        },
      })
      // setStep(1)
    }
  }, [props.isEditMode])

  const course = courseData && courseData.getCourseByOriginalId

  useEffect(() => {
    if (props.isEditMode && course) {
      setStep(1)
    }
  }, [course])

  useEffect(() => {
    if (courseData && courseData.getCourseByOriginalId) {
      const groups = course.groups || []

      const courseGroups = groups.map((group: any) => ({
        groupId: group.groupId,
        name: group.name,
      }))
      setSelectedGroup(courseGroups)
    }
  }, [courseData])

  const { editCourse, editCourseLoading, updatedCourse } = useEditCourse({
    courseId: course && course.id,
  })

  const selectGroup = (field: string[], event?: string) => {
    setSelectedGroup([...selectedGroup, ...field])
  }

  const handleDeleteGroup = (ids: string[]) => {
    const groups = selectedGroup.filter(
      (i: any) => ids.findIndex((e: any) => i.groupId === e) === -1,
    )
    setSelectedGroup(groups)
  }

  const handleChangeStep = (activeStep: number) => {
    setStep(activeStep)
  }

  const editGroupFuc = (callback: any) => {
    const data: any = {}
    const { skills, ...args } = formData
    for (const key in args) {
      data[key] = course[key]
    }

    if (data.avatar) delete data.avatar.__typename

    if (data.certificate) delete data.certificate.__typename

    if (data.subtitle) delete data.subtitle.__typename

    if (data.video) {
      const { __typename, ...args } = data.video
      data.video = {
        id: args.id,
        title: args.title,
      }
    }

    if (!data.price) {
      data.price = null
      data.currency = ''
    } else {
      data.price = parseFloat(data.price)
    }

    data.groups = selectedGroup
    data.coaches = course.coaches.map((i: any) => i.userId)

    const groupIds = selectedGroup.map((i: any) => i.groupId)
    editCourse(course.id, data, groupIds, callback)
  }

  useEffect(() => {
    if (state.createdObject.obj && state.createdObject.type === 'group') {
      setSelectedGroup([
        {
          name: state.createdObject.obj.name,
          groupId: state.createdObject.obj.id,
        },
        ...selectedGroup,
      ])

      setTimeout(() => {
        dispatch({
          type: SET_CREATED_USER,
          payload: { obj: null, type: '' },
        })
      }, 100)
    }
  }, [state.createdObject.obj])

  const handleButtonClick = (e: string) => {
    // console.log('selectedItem::', selectedItem, 'props::', props.boughtCourses)

    if (step === 0 && (e === 'next' || e === 'draft') && selectedItem) {
      addRemoveCourseFromCompany(
        params.id,
        // [selectedItem],
        [selectedItem, ...props.boughtCourses],
        () => {
          if (e === 'next') {
            handleChangeStep(1)
          }

          fetchCourse({
            variables: {
              courseId: selectedItem,
              companyId: params.id,
            },
          })

          if (e === 'draft') {
            props.refetchCourses()
            // changeCourseStep(course.id, step, () => { })
            props.handleCloseDrawer()
          }
        },
      )
      return
    }

    if (step === 1 && (e === 'next' || e === 'draft')) {
      changeCourseStep(course?.id, e === 'draft' ? 1 : 2, () => {
        if (e === 'draft') {
          editGroupFuc(() => {
            props.handleCloseDrawer()
            props.refetchCourses()
          })
        } else {
          editGroupFuc(() => {
            setStep(2)
          })
        }
      })
      return
    }

    if (step === 2 && (e === 'next' || e === 'draft')) {
      changeCourseStep(course?.id, e === 'draft' ? 2 : 3, () => {
        props.refetchCourses()
        props.handleCloseDrawer()
      })
      return
    }
  }

  const selectItem = (id: string) => {
    setSelectedItem(id)
  }

  const handleDeleteCourse = () => {
    if (!course || props.isEditMode) {
      return props.handleCloseDrawer()
    }

    const params = {
      title: t('popups.close_popup'),
      onConfirm: () => {
        if (course && course.id) {
          deleteCourse([course.id], () => {
            props.refetchCourses()
            props.handleCloseDrawer()
          })
        } else {
          props.handleCloseDrawer()
        }
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const coaches =
    (course &&
      course.coaches.map((i: any) => ({
        label: generateFullName(i),
        value: i.userId,
      }))) ||
    []

  const courseWithCoach = { ...course, ...{ coaches: coaches } }

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            boughtCourses={props.boughtCourses}
            selectItem={selectItem}
            selectedItem={selectedItem}
            handleDeleteCourse={handleDeleteCourse}
            handleButtonClick={handleButtonClick}
            t={t}
          />
        )
      case 1:
        return (
          <Step2
            selectedGroup={selectedGroup}
            selectGroup={selectGroup}
            deleteGroup={handleDeleteGroup}
            handleDeleteCourse={handleDeleteCourse}
            handleButtonClick={handleButtonClick}
            step={courseWithCoach.finished || 0}
            editMode={props.isEditMode}
            handleChangeStep={(num: number) => handleChangeStep(step - num)}
            isCoursePurchased
            t={t}
          />
        )

      case 2:
        return (
          <AssignCoach
            values={courseWithCoach}
            selectedGroup={selectedGroup}
            courseId={courseWithCoach.id}
            handleDeleteCourse={handleDeleteCourse}
            handleButtonClick={handleButtonClick}
            handleChangeStep={(num: number) => handleChangeStep(step - num)}
          />
        )
      default:
        return 'Error'
    }
  }

  const stepTitles = [
    t('general.wenroll_courses'),
    t('general.groups'),
    t('general.assign'),
  ]

  return (
    <Container>
      <DrawerHeaderSteps>
        <DrawerStepsTitle>
          {props.isEditMode
            ? t('companies_layout.edit_purchased_course')
            : t('companies_layout.add_purchased_course')}
        </DrawerStepsTitle>

        <StepperContainer>
          <Stepper
            active={step}
            steps={stepTitles}
            setStep={setStep}
            ColorlibConnector={() => <></>}
            StepIconComponent={(props: any) => {
              const icons: { [index: string]: React.ReactElement } = {
                1: <>1</>,
                2: <>2</>,
                3: <>3</>,
              }

              return (
                <SWizardStepper active={props.active as boolean}>
                  {icons[String(props.icon)]}
                </SWizardStepper>
              )
            }}
          />
        </StepperContainer>
      </DrawerHeaderSteps>

      <DrawerContent
        headerHeight={168}
        style={{
          margin: '100px 20px',
        }}
      >
        <FormContainer>{getStepContent(step)}</FormContainer>
      </DrawerContent>
    </Container>
  )
}

export default AddPurchasedCourseDrawer

const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
`

const StepperContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiPaper-root {
    background: transparent !important;
  }
`
const SWizardStepper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 42px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #080a0a14;
  box-shadow: none;
  color: #707070;
  font-size: 20px;
  transition: 0.3s;
  border-radius: 100%;

  ${({ active }) =>
    active &&
    css`
      width: 54px;
      height: 54px;
      background: #317bf4;
      color: #ffffff;
      font-size: 28px;
      &::after {
        content: '';
        position: absolute;
        border: 1px solid #707070;
        border-collapse: separate;
        border-radius: 100%;
        width: 60px;
        height: 60px;
      }
    `}
`
