import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  DetailsContentSkillsWrapper,
  DetailsContentSkilsTitle,
} from 'components/common/DetailsLayout'
import styled from 'styled-components'
import { black } from 'components/common/Colors/CommonColors'

const Categories: React.FC<{
  categories: {
    id: string
    name: string
  }[]
}> = ({ categories }) => {
  const { t } = useTranslation()
  return (
    <>
      {categories && categories.length > 0 && (
        <DetailsContentSkillsWrapper>
          <DetailsContentSkilsTitle>
            {t('general.categories')}
          </DetailsContentSkilsTitle>
          <CategoriesWrapper>
            {categories &&
              categories.map((category: any) => (
                <Category key={category.id}>{category.name}</Category>
              ))}
          </CategoriesWrapper>
        </DetailsContentSkillsWrapper>
      )}
    </>
  )
}
export default Categories

const Category = styled.div`
  display: inline-block;
  margin-right: 8px;
  font-size: 14px;
  color: ${black};
  padding: 8px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  white-space: nowrap;
`

const CategoriesWrapper = styled.div`
  display: inline-block;
  margin-top: 10px;
`
