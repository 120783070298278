import gql from 'graphql-tag'

export const CREATE_QUIZ = gql`
  mutation(
    $type: String
    $actionId: String
    $input: QuizInput
    $companyId: String
  ) {
    createQuiz(
      type: $type
      actionId: $actionId
      input: $input
      companyId: $companyId
    ) {
      name
      id
      description
      timeType
      time
      position
      quizParticipationType
      passRate
      quizType
      IQTestType
      feedback
      tags {
        label
        id
      }
      questions
      parentName
      randomization
      randomizationNumber
    }
  }
`

export const GET_QUIZ_BY_TYPE = gql`
  query(
    $type: String
    $actionId: String
    $currentPage: Int
    $perPage: Int
    $filter: QuizesFilter
  ) {
    getQuizzesByType(
      type: $type
      actionId: $actionId
      currentPage: $currentPage
      perPage: $perPage
      filter: $filter
    ) {
      currentPage
      totalPages
      data {
        name
        description
        timeType
        time
        IQTestType
        position
        quizParticipationType
        passRate
        feedback
        id
        quizType
        tags {
          label
          id
        }
        questions
        numberOfQuestions
        parentName
        randomization
        randomizationNumber
        score
      }
    }
  }
`

export const GET_QUIZ_BY_ID = gql`
  query($quizId: String) {
    getQuizById(quizId: $quizId) {
      name
      description
      timeType
      time
      quizParticipationType
      passRate
      feedback
      IQTestType
      position
      id
      quizType
      tags {
        label
        id
      }
      hasEverStarted
      actionId
      questions
      parentName
      randomization
      randomizationNumber
      score
      questionsCount
      questionList {
        id
        edited
        type {
          value
          label
        }
        reason
        question
        videos {
          name
          title
          thumbnail
          duration
          id
          links {
            url
            fileType
            converted
          }
        }
        audios {
          name
          fileType
          duration
          link
          id
        }
        documents {
          name
          size
          fileType
          link
        }
        images {
          name
          fileType
          link
        }
        answers {
          id,
          isCorrect,
          value
        }
        boolAnswer
        currentModule {
          moduleId
          name
        }
      }
    }
  }
`

export const GET_ALL_QUIZ = gql`
  query($query: String) {
    getAllQuizzes(query: $query) {
      name
      description
      timeType
      time
      quizParticipationType
      passRate
      IQTestType
      position
      feedback
      id
      quizType
      tags {
        label
        id
      }
      questions
      parentName
      randomization
      randomizationNumber
    }
  }
`

export const DELETE_QUIZ_BY_IDS = gql`
  mutation($ids: [String]) {
    deleteQuiz(ids: $ids) {
      ids
    }
  }
`

export const EDIT_QUIZ = gql`
  mutation($quizId: String, $input: QuizInput) {
    editQuiz(quizId: $quizId, input: $input) {
      name
      description
      timeType
      time
      position
      quizParticipationType
      passRate
      feedback
      id
      quizType
      IQTestType
      tags {
        label
        id
      }
      questions
      parentName
      randomization
      randomizationNumber
    }
  }
`
export const ADD_REMOVE_QUESTIONS = gql`
  mutation($action: String, $questionIds: [String], $quizId: String) {
    addRemoveQuestion(
      action: $action
      questionIds: $questionIds
      quizId: $quizId
    ) {
      name
      description
      timeType
      time
      quizParticipationType
      passRate
      feedback
      id
      quizType
      tags {
        label
        id
      }
      questions
      parentName
    }
  }
`

export const GET_QUIZ_ANSWER = gql`
  query(
    $quizId: String!
    $questionId: String!
    $boolAnswer: Boolean
    $answers: [String!]
  ) {
    getAnswer(
      quizId: $quizId
      questionId: $questionId
      boolAnswer: $boolAnswer
      answers: $answers
    ) {
      isCorrect
      correctBoolAnswer
      correctAnswers
    }
  }
`

export const GET_QUIZ_RESULT = gql`
  query($quizId: String!, $userId: String, $courseId: String) {
    getQuizResult(quizId: $quizId, userId: $userId, courseId: $courseId) {
      score
      hasEverStarted
      passed
      passRate
    }
  }
`

export const GET_IQ_TEST_RESULTS = gql`
  query($quizId: String!, $userId: String, $courseId: String) {
    getIQTestResults(quizId: $quizId, userId: $userId, courseId: $courseId) {
      initial {
        totalQuestions
        totalCorrectAnswers
        data {
          #id
          name
          correctAnswersPercent
          correctAnswersCount
          totalQuestions
        }
      }
      final {
        totalQuestions
        totalCorrectAnswers
        data {
          #id
          name
          correctAnswersPercent
          correctAnswersCount
          totalQuestions
        }
      }
    }
  }
`

export const GET_IQ_TEST_RESULT = gql`
  query($quizId: String!, $userId: String) {
    getIQTestResult(quizId: $quizId, userId: $userId) {
      totalQuestions
      totalCorrectAnswers
      data {
        id
        name
        correctAnswersPercent
        correctAnswersCount
        totalQuestions
      }
    }
  }
`

export const RETAKE_IQ_QUIZZ = gql`
  mutation($userId: String, $courseId: String, $quizId: String) {
    retakeIQTestResults(userId: $userId, courseId: $courseId, quizId: $quizId)
  }
`
