import styled from 'styled-components'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

export const Container = styled.div`
  width: ${({ totalWidth }) => totalWidth || '100%'};
  height: ${({ small }) => small ? '32px' : '42px'};
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  &:hover{
    border-color: rgba(0, 0, 0, 0.87);
  }
`

const defs = ({ active, small }) => {
  const styles = {
    fontSize: '14px',
  }

  if (active) styles.fontSize = '12px'

  if (small) styles.fontSize = '12px'


  return styles
}

export const DropDownValue = styled.div`
  width: 100%;
  color: black;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.55);
  font-size: ${({ small }) => small ? '12px' : '14px'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledLabel = styled.div`
  position: absolute;
  left: 14px;
  transition: .2s;
  background: #fff;
  top: ${({ active }) => active ? '-2%' : '50%'};
  transform: translateY(-50%);
  color: rgba(0,0,0,0.55);
  ${defs};
`
export const DropDownContainer = styled.div`
  position: absolute;
  bottom: -8px;
  transform: translateY(100%);
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e3e6eb;
  background-color: #ffffff;
  border-radius: 4px;
  width: 100%;
  left: 0;
  z-index: 11;
  box-sizing: border-box;
  padding: 7px 0;
  max-height: 250px;
  overflow-y: scroll;
`

export const DropDownItem = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 3px 15px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  /* color: ${({ active }) => active ? '#2368a1' : '#354052'}; */
  color: #354052;
  transition: 0.4s;
  margin: 5px 0;
  cursor: pointer;
  &:hover {
    background: #eaeaea;;
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledIcon = styled(ArrowDropDownIcon)`
  position: absolute;
  right: 6px;
  color: #757575;
  top: 50%;
  transform: translateY(-50%);
`
