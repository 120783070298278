import { FormikErrors, FormikTouched } from 'formik'
import React from 'react'
import CreatableSelect from 'react-select/creatable'
import styled from 'styled-components'
interface ICreatableSelect {
  placeholder?: string
  options: { value: string; label: string }[]
  errorMessage?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
  value?: { value: string; label: string }
  onChange: (e: any) => void
  isLoading?: boolean
  isDisabled?: boolean
}
const CreatableSelectWithError: React.FC<ICreatableSelect> = ({
  placeholder,
  options,
  errorMessage,
  touched,
  value,
  onChange,
  isLoading,
  isDisabled,
}) => {
  const customStyles: any = {
    control: (provided: any, state: any) => ({
      ...provided,
      border:
        !!errorMessage && touched
          ? '1px solid #f4493c'
          : '1px solid rgba(0, 0, 0, 0.23)',
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: !!errorMessage && touched ? '#f4493c' : '#757575',
    }),
  }
  return (
    <div>
      <CreatableSelect
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={onChange}
        isLoading={isLoading}
        isDisabled={isDisabled}
        styles={customStyles}
        isClearable
      />
      {!!errorMessage && touched && (
        <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
      )}
    </div>
  )
}
export default CreatableSelectWithError
const ErrorMessageContainer = styled.div`
  color: #f44336;
  font-size: 0.75rem;
  margin-left: 4px;
  margin-top: 6px;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`
