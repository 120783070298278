import { makeStyles, Popover } from '@material-ui/core'
import React from 'react'
import * as S from './styled'

export type SelectBtnProps = {
  active: number
  onChange: (active: number) => void
  options: Array<{
    label: string
    value: number
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  }>
}

const SelectBtn: React.FC<SelectBtnProps> = props => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <S.Container>
      <S.Button aria-describedby={id} onClick={handleClick}>
        {props.options[props.active].label}
        <S.Icon />
      </S.Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{
          top: '15px',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <S.ItemContainer>
          {props.options.map(o => (
            <S.Item
              onClick={e => {
                props.onChange(o.value)
                o?.onClick?.(e)
              }}
              active={o.value === props.active}
            >
              {o.label}
            </S.Item>
          ))}
        </S.ItemContainer>
      </Popover>
    </S.Container>
  )
}

export default SelectBtn
