import { ActionButton } from 'components/common/Grid/styled-components'
import Icon from 'components/Icon'
import React from 'react'
import styled from 'styled-components'

export type EducationItemProps = {
  id: string
  title: React.ReactNode
  status?: React.ReactNode
  date?: React.ReactNode
  onEdit?: (id: string) => void
  onDelete?: (id: string) => void
}

const EducationItem: React.FC<EducationItemProps> = props => {

  return (
    <Container>
      <div className="logo">
        <Icon filename={'work'} />
      </div>
      <div className="content">
        <div className="title">{props.title}</div>
        <div className="status">{props.status}</div>
        <div className="date">{props.date}</div>
      </div>
      {(props.onEdit || props.onDelete) && (
        <div className="actions">
          {props.onEdit && (
            <Icon
              onClick={() => props.onEdit?.(props.id)}
              filename={'edit_border'}
              width={20}
              height={20}
            />
          )}
          {props.onDelete && (
            <Icon
              onClick={() => props.onDelete?.(props.id)}
              filename={'delete'}
              fill={'#CC3131'}
              width={20}
              height={20}
            />
          )}
        </div>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 30px;
  padding-bottom: 30px;
  background: transparent;
  gap: 16px;
  border-radius: 12px;
  transition: 0.3s;
  cursor: pointer;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: #f5f5f5;
    border-radius: 12px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    word-break: break-all;
    .title {
      color: #4c4d4d;
      font-size: 20px;
    }
    .status {
      color: #080a0ad6;
      font-size: 16px;
    }
    .date {
      color: #989999;
      font-size: 14px;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: auto;
    transition: 0.3s;
    opacity: 0;
  }

  &:hover {
    background: #ffffff;
    box-shadow: 0px 0px 30px #0000001a;

    .logo {
      svg {
        fill: #317bf4;
      }
    }
    .content {
      .title {
        color: #317bf4;
      }
    }
    .actions {
      opacity: 1;
    }
  }
`

export default EducationItem
