import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useLazyQuery } from '@apollo/react-hooks'
import { useParams, useHistory } from 'react-router-dom'
import WizardStepper from './WizardStepper/WizardStepper'
import CompanyDetails from './CompanyDetails/CompanyDetails'
import PurchasedCourses from './PurchasedCourses/PurchasedCourses'
import CompanySuperAdmins from './CompanySuperAdmins/CompanySuperAdmins'
import GroupAdmins from './GroupAdmins/GroupAdmins'
import CompanyGroup from './CompanyGroup/CompanyGroup'
import CompanyStudents from './CompanyStudents/CompanyStudents'
import AssignCoach from './AssignCoach/AssignCoach'
import { GET_USERS } from 'gql/users.query'
import { GET_COMPANY } from 'gql/companies.query'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import { GET_GROUP } from 'gql/group/group.query'
import { useSwal } from 'hooks/useSwal'
import useDeleteCompanyService from 'hooks/companies/useCompanyDeleteService'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Icon from 'components/Icon'
import { Tooltip, styled as muiStyled, useTheme } from '@material-ui/core'
import CIcon from 'components/Icon'

export const stepDescriptions = (t: TFunction): Record<number, string> => ({
  0: t('company_wizard_layout.company_details_description'),
  1: t('company_wizard_layout.invite_company_super_admins_description'),
  2: t('company_wizard_layout.purchased_courses_description'),
  3: t('company_wizard_layout.group_admins_description'),
  4: t('company_wizard_layout.group_of_students_description'),
  5: t('company_wizard_layout.students_description'),
  6: t('company_wizard_layout.assign_coaches_description'),
})

const dummedata = {
  companyData: {
    address: 'location',
    avatar: '',
    city: 'Tbilisi',
    companyActivity: [
      {
        id: '61a229e8ce3d74170310568b',
        label: 'ფინანსები სატესტო',
      },
    ],
    companyEmail: 'dato@gmail.com',
    country: 'Georgia',
    id: '61a229e8ce3d74170310568a',
    keyContactName: 'dato',
    name: 'tech',
    note: 'description',
    numberOfUsers: '5',
    phone: '454548526',
    phoneFields: { code: '+1 684', dialCode: 'AS' },
    platformService: true,
    postalCode: '22516',
    status: 'DRAFT',
    __typename: 'Company',
  },
  managers: [
    {
      age: null,
      avatar: null,
      biography: null,
      birthDate: null,
      companyId: {
        name: 'tech',
        id: '61a229e8ce3d74170310568a',
        platformService: true,
      },
      courses: [],
      createDate: 1638017670286,
      email: 'tiko@gmail.com',
      firstName: 'tiko',
      gender: null,
      group: [],
      id: '61a22a86ce3d741703105696',
      isChecked: false,
      isQualified: null,
      jobTitle: '',
      lastName: 'lomidze',
      location: '',
      note: null,
      phone: '454582656',
      phoneFields: { code: '+244', dialCode: 'AO' },
      role: 'SUPER_ADMIN',
      status: 'DRAFT',
    },
  ],
  selectedCourses: ['6140961fdde4d85d86c4bba2', '6118f731b9118c73e41af7d3'],
  admins: [
    {
      age: null,
      avatar: '',
      biography: null,
      birthDate: null,
      companyId: {
        name: 'tech',
        id: '61a229e8ce3d74170310568a',
        platformService: true,
      },
      courses: [],
      createDate: 1638017841900,
      email: 'tata@gmail.com',
      firstName: 'tata',
      gender: null,
      group: [],
      id: '61a22b31ce3d7417031056b1',
      isChecked: false,
      isQualified: null,
      jobTitle: '',
      lastName: 'lolia',
      location: '',
      note: '',
      phone: '4545',
      phoneFields: { code: '+355', dialCode: 'AL', __typename: 'PhoneFields' },
      role: 'ADMIN',
      status: 'DRAFT',
    },
  ],
  groups: [
    {
      admin: {
        firstName: 'tata',
        lastName: 'lolia',
        email: 'tata@gmail.com',
        id: '61a22b31ce3d7417031056b1',
      },
      coach: null,
      company: { name: 'tech', id: '61a229e8ce3d74170310568a' },
      courses: [
        {
          coach: null,
          courseId: '61a22ae7ce3d74170310569a',
          name: 'ვნკ',
        },
      ],
      createDate: 1638017920789,
      id: '61a22b80ce3d7417031056bc',
      isChecked: false,
      location: 'Tbilisi',
      name: 'bgt',
      note: '',
      numberOfStudents: '0',
      status: 'DRAFT',
    },
  ],
  students: [
    {
      age: null,
      avatar: '',
      biography: '',
      birthDate: null,
      companyId: {
        name: 'tech',
        id: '61a229e8ce3d74170310568a',
        platformService: true,
        __typename: 'Company',
      },
      courses: [
        {
          name: 'ვნკ',
          courseId: '6140961fdde4d85d86c4bba2',
          __typename: 'UserCourses',
        },
      ],
      createDate: 1638018045391,
      email: 'gio@gmail.com',
      firstName: 'gio',
      gender: null,
      group: [
        {
          groupId: '61a22b80ce3d7417031056bc',
          name: 'bgt',
        },
      ],
      id: '61a22bfdce3d7417031056c9',
      isChecked: false,
      isQualified: null,
      jobTitle: 'sagijetis gamge',
      lastName: 'barbaqadze',
      location: '',
      note: '',
      phone: '',
      phoneFields: { code: null, dialCode: null, __typename: 'PhoneFields' },
      role: 'STUDENT',
      status: 'DRAFT',
    },
  ],
}

const CompanyWizardLayout = () => {
  const { t } = useTranslation()
  const [step, setStep] = useState<number>(0)
  const [completedSteps, setCompletedSteps] = useState<number[]>([])
  const { fireSwal } = useSwal()
  const params: any = useParams()
  const history = useHistory()
  const [data, setData] = useState<any>({
    companyData: null,
    managers: [],
    selectedCourses: [],
    admins: [],
    groups: [],
    students: [],
  })
  const theme = useTheme();

  const [fetchAdmins, { data: admins }] = useLazyQuery(GET_USERS)
  const [fetchStudent, { data: students }] = useLazyQuery(GET_USERS)
  const [fetchCompany, { data: companyDetails, loading }] = useLazyQuery(
    GET_COMPANY,
  )
  const [fetchCourses, { data: courses }] = useLazyQuery(GET_ALL_COURSE)
  const [fetchGroups, { data: groups }] = useLazyQuery(GET_GROUP)
  const { deleteCompany, deleteCompanyLoading } = useDeleteCompanyService({
    filter: {},
  })



  // useEffect(() => {
  //   const step = localStorage.getItem('companyStep')
  //   if (step) {
  //     setStep(0)
  //   }
  // }, [])

  useEffect(() => {
    return () => {
      localStorage.removeItem('companyStep')
    }
  }, [])

  const fetchCompanyData = (companyId: string) => {
    fetchCompany({
      variables: {
        id: companyId,
      },
    })

    fetchAdmins({
      variables: {
        role: 'ADMIN',
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
        currentPage: 1,
        perPage: 10,
      },
    })

    fetchStudent({
      variables: {
        role: 'STUDENT',
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
        currentPage: 1,
        perPage: 10,
      },
    })

    fetchCourses({
      variables: {
        filter: {
          isBought: {
            type: 'bool',
            value: 'true',
          },
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
      },
    })

    fetchGroups({
      variables: {
        filter: {
          company: {
            type: 'exact',
            value: companyId,
          },
        },
        currentPage: 1,
        perPage: 0,
      },
    })
  }

  useEffect(() => {
    if (params.id) {
      fetchCompanyData(params.id)
    }
  }, [])

  useEffect(() => {
    if (admins && admins.usersByRole) {
      setData({
        ...data,
        admins: admins.usersByRole.data,
      })
    }
  }, [admins])

  useEffect(() => {
    if (students && students.usersByRole) {
      setData({
        ...data,
        students: students.usersByRole.data,
      })
    }
  }, [students])

  useEffect(() => {
    if (companyDetails && companyDetails.company) {
      setData({
        ...data,
        companyData: companyDetails.company,
      })

      const step = localStorage.getItem('companyStep')
      setStep(step ? 0 : companyDetails.company.finished)
      setCompletedSteps((x: any) => {
        return step
          ? [0]
          : [...Array(companyDetails.company.finished)].map((_, i) => i)
      })
    }
  }, [companyDetails])

  useEffect(() => {
    setCompletedSteps((x: number[]) => {
      const newArr = [...Array(step)].map((_, i) => i)
      if (newArr.indexOf(Math.max(...x)) > -1 || x.length === 0)
        return [...[...Array(step)].map((_, i) => i)]
      return [...x]
    })
  }, [step])

  useEffect(() => {
    if (courses && courses.getAllCourse) {
      setData({
        ...data,
        selectedCourses: courses.getAllCourse.data.map(
          (i: any) => i.originalId,
        ),
      })
    }
  }, [courses])

  useEffect(() => {
    if (groups && groups.getAllGroups) {
      setData({
        ...data,
        groups: groups.getAllGroups.data,
      })
    }
  }, [groups])

  const handleCancelCompany = () => {
    const company: any = { ...data.companyData }
    if (company && company.id) {
      fireSwal({
        title: t('popups.cancel_company_create'),
        onConfirm: () =>
          deleteCompany([company.id], () => history.push('/Companies')),
        confirmText: t('general.yes'),
      })
    } else {
      fireSwal({
        title: t('popups.cancel_company_create'),
        onConfirm: () => history.push('/Companies'),
        confirmText: t('general.yes'),
      })
    }
  }

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <CompanyDetails
            changeStep={setStep}
            companyData={data}
            setData={setData}
            onCancel={handleCancelCompany}
            t={t}
          />
        )

      case 1:
        return (
          <CompanySuperAdmins
            changeStep={setStep}
            companyData={data}
            setData={setData}
            onCancel={handleCancelCompany}
            t={t}
          />
        )
      case 2:
        return (
          <PurchasedCourses
            changeStep={setStep}
            companyData={data}
            setData={setData}
            onCancel={handleCancelCompany}
            t={t}
          />
        )
      case 3:
        return (
          <GroupAdmins
            changeStep={setStep}
            companyData={data}
            setData={setData}
            onCancel={handleCancelCompany}
            t={t}
          />
        )
      case 4:
        return (
          <CompanyGroup
            changeStep={setStep}
            companyData={data}
            setData={setData}
            onCancel={handleCancelCompany}
            t={t}
          />
        )
      case 5:
        return (
          <CompanyStudents
            changeStep={setStep}
            companyData={data}
            setData={setData}
            onCancel={handleCancelCompany}
            t={t}
          />
        )
      case 6:
        return (
          <AssignCoach
            changeStep={setStep}
            companyData={data}
            setData={setData}
            onCancel={handleCancelCompany}
            t={t}
          />
        )

      default:
        history.push('/Companies')
        return
    }
  }

  return (
    <PageContainer theme={theme}>
      <PageHeader theme={theme}>
        <BackButton
          theme={theme}
          onClick={() =>
            step === 0 ? history.push('/Companies') : setStep(step - 1)
          }
        >
          <IconContainer>
            <Icon filename={'left_arrow'} />
          </IconContainer>
          {step === 0 ? t('actions.cancel') : t('actions.previous')}
        </BackButton>
        <WizardStepper
          step={step}
          setStep={setStep}
          completedSteps={completedSteps}
          t={t}
        />
        <SToolTip title={stepDescriptions(t)[step]} placement="top">
          <PageInformation theme={theme}>
            <CIcon filename='info' fill="#303131" style={{
              position: 'relative',
              zIndex: 2,
            }}></CIcon>
          </PageInformation>
        </SToolTip>
      </PageHeader>
      <Container>{!loading ? getStepContent(step) : 'Loading...'}</Container>
    </PageContainer>
  )
}

export default CompanyWizardLayout

const PageContainer = styled.div`
  position: relative;
  background: ${({ theme }) => theme.palette.background.default};
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  // padding: 0 !important;
`

const PageHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 30px;
  padding-bottom: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.text.primary};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

const SToolTip = muiStyled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  ['& .MuiTooltip-tooltip']: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 3px 6px #0000001A',
    fontSize: 16,
    color: '#080A0AD6',
    maxWidth: '970px',
    padding: '30px 50px',
  },
}))

const PageInformation = styled.div`
  position: absolute;
  right: 70px;
  bottom: -15px;
  width: 33px;
  height: 33px;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.text.primary};
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;


  &::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background: #ebebeb;
    border: 1px solid #ebebeb;
    border-radius: 100%;
    z-index: 0;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const BackButton = styled.button`
  position: absolute;
  top: 27px;
  left: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background: transparent;
  border: none;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.text.primary};
  cursor: pointer;
`

const IconContainer = styled.div`
  width: 36px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #080a0a14;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 6px;
    height: 10px;
    fill: #797a7a;
  }
`
