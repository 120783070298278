import { useMutation } from '@apollo/react-hooks'

import { ADD_MODULE } from 'gql/modules.query'

const addModuleService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addModuleMutation, { loading, error }] = useMutation(ADD_MODULE, {
    // update(cache, { data: { addModule: document } }) {
    //   updateCache({
    //     objectName: 'allModules',
    //     query: GET_MODULES,
    //     cache,
    //     document,
    //     action: 'add',
    //     params,
    //   })
    // },
  })

  return {
    addModuleMutation,
    loading,
    error,
  }
}

export default addModuleService
