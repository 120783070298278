import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import update from 'immutability-helper'
import CourseHeader from 'pages/courses/StudentCourse/Header'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DeleteIcon from '@material-ui/icons/Delete'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import FilterListIcon from '@material-ui/icons/FilterList'
import HistoryIcon from '@material-ui/icons/History'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import ScheduleIcon from '@material-ui/icons/Schedule'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import SpellcheckIcon from '@material-ui/icons/Spellcheck'
import SyncIcon from '@material-ui/icons/Sync'
import ViewListIcon from '@material-ui/icons/ViewList'
import Breadcrumb from 'components/common/Breadcrumb'
import { Button } from 'components/common/Button'
import { GridSwitchButtons } from 'components/common/Cards/styled-components'
import {
  Column,
  DetailsList,
  DetailsListContainer,
  DetailsListItem,
  Row,
} from 'components/common/DetailsLayout'
import Drawer from 'components/common/Drawer'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import {
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import TextEditor from 'components/common/TextEditor/TextEditor'
import TextInput from 'components/common/TextInput/TextInput'
import {
  CourseContentDescription,
  CourseContentHeader,
  CourseContentHeaderSection,
  CourseContentLeft,
  CourseContentRight,
  CourseContentWrapper,
  CourseTitle,
  TextWrapper,
} from 'components/courseDetail/styled-component'
import { GET_QUESTIONS_BY_IDS } from 'gql/questions.query'
import { GET_QUIZ_BY_ID } from 'gql/quiz/quiz.query'
import { gql } from 'graphql.macro'
import { DrawerEventEmitter } from 'helpers/drawer'
import useDeleteQuizService from 'hooks/quizzes/useDeleteQuizService'
import {
  useAddRemoveQuestions,
  useEditQuizService,
} from 'hooks/quizzes/useEditQuizService'
import FilterDrawer from 'pages/questions/FilterDrawer'
import QuestionGrid from 'pages/questions/Grid'
import { quizQuestionActionsConfig as questionActionsConfig } from 'pages/questions/gridConfig'
import { mainColor } from 'components/common/Colors/CommonColors'
import { GET_COURSE } from 'gql/course/course.query'
import { GetCourseById } from 'pages/courses/StudentCourse/types'
import Icon from 'components/Icon'

const CHANGE_ORDER = gql`
  mutation($draggedId: String!, $droppedId: String!) {
    changeQuestionsOrder(draggedId: $draggedId, droppedId: $droppedId)
  }
`

const QuizDetails = () => {
  const { t } = useTranslation()
  const [cards, setCards] = useState([])

  const moveCard = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )
  const [changeQuestionsOrder] = useMutation(CHANGE_ORDER)

  const params: any = useParams()
  const history = useHistory()
  const [state] = useUserValue()

  const [gridStyle, setGridStyle] = useState<string>('card')
  const [searchValue, setSearchValue] = useState<string>('')
  const [editMode, setEditMode] = useState(false)
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<any>({})
  const [filtered, setFiltered] = useState<boolean>(false)
  const [filteredQuestionList, setFilteredQuestionList] = useState<any>(null)
  const { fireSwal } = useSwal()

  const { data } = useQuery(GET_QUIZ_BY_ID, {
    variables: {
      quizId: params.id,
    },
  })
  const courseId = params?.courseId
  const { deleteQuiz } = useDeleteQuizService('s')
  const { addRemoveQuestions } = useAddRemoveQuestions()
  const [fetchQuestions, { data: questionData }] = useLazyQuery(
    GET_QUESTIONS_BY_IDS,
  )
  const { editQuiz } = useEditQuizService('1')
  const quizData = (data && data.getQuizById) || {}

  const { data: courseData } = useQuery<{ getCourseById: GetCourseById }>(
    GET_COURSE,
    {
      variables: { courseId: courseId },
    },
  )

  useEffect(() => {
    if (quizData.questionList && quizData.questionList.length > 0) {
      fetchQuestions({
        variables: {
          ids: quizData.questions,
        },
      })
    }
  }, [quizData, quizData.questions])

  const handleChangeRequest = async (draggedId: string, droppedId: string) => {
    const { data } = await changeQuestionsOrder({
      variables: { draggedId, droppedId },
    })
  }

  const handleResetClick = () => {
    setSearchValue('')
    setFilteredQuestionList(null)
    setFiltered(false)
    setFilterData({})
  }

  const handleSearchChange = (e: any) => {
    const { value } = e.target

    setSearchValue(value)
  }

  const editQuizDescription = (editorText: any) => {
    const newQuiz: any = {}
    newQuiz.description = editorText
    editQuiz(quizData.id, newQuiz, () => { })
    setEditMode(false)
  }

  const handleEditDrawer = (val: any) => {
    DrawerEventEmitter.emit('openDrawer', 'editQuiz', true, {
      type: quizData.quizType,
      id: quizData.actionId,
      data: { quizId: val.id },
    })
  }

  const selectItem = (id: never, event: boolean) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
    }

    setSelectedItem(newSelected)
  }

  const fireNotDeleteQuestionPopup = (isMultiple: boolean) => {
    const questionText = isMultiple
      ? t('general.questions')
      : t('general.question')
    fireSwal({
      title: `${t('popups.can_not_delete_questions')} ${questionText}`,
      confirmText: t('actions.close'),
      onConfirm: () => { },
    })
  }

  const handleDeleteQuiz = (id: string) => {
    const ids = [id]

    fireSwal({
      title: t('popups.delete_quiz_single'),
      confirmText: 'Yes, Delete',
      onConfirm: () => deleteQuiz(ids, () => history.goBack()),
    })
  }

  const handleViewQuestion = (id: string) => {
    history.push(`/questions/${id}`)
  }

  const removeQuestions = (ids: string[]) => {
    if (ids.length > 0)
      addRemoveQuestions('remove', ids, quizData.id, () => setSelectedItem([]))
  }

  const handleDeleteQuestions = (ids: string[]) => {
    const { questions, randomizationNumber } = quizData
    const remainedQuestionsCount = questions.length - ids.length

    const isMultiple = ids.length > 1 ? true : false
    if (randomizationNumber && remainedQuestionsCount < randomizationNumber) {
      fireNotDeleteQuestionPopup(isMultiple)
    } else {
      const title: string = isMultiple
        ? 'If you remove Selected Questions this Questions will no longer exist in this Quiz but remains in Module Details View. Remove Questions?'
        : 'If you remove the Question this Question will no longer exist in this Quiz but remains in Module Details View. Remove the Question?'

      fireSwal({
        title,
        confirmText: 'Yes, Remove',
        onConfirm: () => removeQuestions(ids),
      })
    }
  }

  const actionHandler = (action: string, value: any) => {
    switch (action) {
      case 'edit':
        return handleEditDrawer(value)
        break
      case 'delete':
        return handleDeleteQuiz(value.id)
        break
      case 'view':
        return handleViewQuestion(value.id)
        break
      case 'deleteQuestion':
        return handleDeleteQuestions([value.id])
      default:
        break
    }
  }

  useEffect(() => {
    if (questionData) {
      const questions =
        questionData.questionsByIds.filter((item: any) => {
          return item.question.toLowerCase().includes(searchValue)
        }) || []
      setCards(questions)
    }
  }, [questionData, searchValue])

  const onStartQuiz = useCallback(() => {
    if (courseId && params?.id) {
      history.push(`/course/${courseId}/quizz/${params.id}`)
    }
  }, [courseId, params])

  return (
    <div>
      {courseData?.getCourseById && (
        <CourseHeader course={courseData.getCourseById} />
      )}
      <QuizDetailsContainer>
        <div className="title">{courseData?.getCourseById.name}</div>
        <QuizDetailsList>
          <div className="title">{t('general.details')}</div>

          <div className="item">
            <Icon filename={'time'} />
            <div className="name">{t('general.duration')}:</div>
            <div className="value">{quizData.time}</div>
          </div>
          <div className="item">
            <Icon filename={'questions'} />
            <div className="name">{t('general.numberOfQuestions')}:</div>
            <div className="value">{quizData?.questionsCount || 0}</div>
          </div>
          <div className="item">
            <Icon filename={'safe'} />
            <div className="name">{t('general.mandatoryParticipation')}</div>
            <div className="value">
              {quizData.quizParticipationType === 'optional' ? t('general.yes') : t('general.no')}
            </div>
          </div>
          <div className="item">
            <Icon filename={'divide'} />
            <div className="name">{t('quiz_details.pass_rate')}:</div>
            <div className="value">{quizData.passRate}%</div>
          </div>

          <div className="item">
            <Icon filename={'random'} />
            <div className="name">{t('quiz_details.randomize')}:</div>
            <div className="value">{t('general.yes')}</div>
          </div>
        </QuizDetailsList>
        <StartQuizButton onClick={onStartQuiz}>
          {t('quiz_details.start')}
        </StartQuizButton>
      </QuizDetailsContainer>
    </div>
  )
}
export default QuizDetails

export const QuizDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 770px;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 10px 30px #0000001a;
  border: 1px solid #080a0a0a;
  border-radius: 16px;
  margin: auto;
  margin-top: 150px;

  & > .title {
    color: #080a0ad6;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
`

export const QuizDetailsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > .title {
    color: #080a0a8a;
    font-size: 16px;
    margin: auto;
  }
  .item {
    display: flex;
    align-items: center;
    gap: 7px;
    .name {
      color: #080a0ad6;
      font-size: 16px;
      font-weight: 600;
    }
    .value {
      color: #080a0a8a;
      font-size: 16px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
`

export const StartQuizButton = styled.button`
  margin-top: 24px;
  color: #ffffff;
  background: #317bf4;
  border-radius: 16px;
  padding: 16px 24px;
  border: none;
  outline: none;
  cursor: pointer;
`
