import React from 'react'
import SVG, { Props as SVGProps } from 'react-inlinesvg'

type Props = {
  filename?: string
  src?: string
} & Omit<SVGProps, 'src'>

const CIcon: React.FC<Props> = ({ filename, ...rest }) => {

  return <SVG {...rest} src={rest.src || `/svg/${filename}.svg`} />
}

export default CIcon
