import { black, mainColor } from 'components/common/Colors/CommonColors'
import styled from 'styled-components'

export const Wrapper = styled.div<{ align?: string; justify?: string }>`
  display: flex;
  align-items: ${({ align }) => (align ? align : 'initial')};
  justify-content: ${({ justify }) =>
    justify ? `space-${justify}` : 'initial'};
`

export const NoValueWrapper = styled.span`
  opacity: 0.5;
`

export const Header = styled.div<{ color: string }>`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: ${({ color }) => color || mainColor};
    width: 100%;
    height: 130px;
    z-index: -1;
    opacity: 0.4;
  }
`

export const CompanyImage = styled.div`
  position: relative;
  width: 170px;
  height: 170px;
  border-radius: 16px;
  background: transparent;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 100%;
    height: 100%;
  }
`
export const CompanyContent = styled.section`
  margin-left: 30px;
  margin-top: 80px;
`

export const CompanyTitle = styled.h1`
  font-size: 40px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-top: 20px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`

export const ContinueBtn = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #d2364b;
  color: #fff;
  cursor: pointer;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 600;
  transition: background 0.3s ease, border 0.3s ease;
  box-shadow: 1px 1px 1px rgb(0 0 0 / 38%);

  /* &:hover {
    background: #ff3a52;
    border: 1px solid transparent;
  } */
`
export const PlatformServiceTitle = styled.h4`
  font-size: 17px;
  margin-left: 10px;
`

export const PlatformServiceSwitcher = styled.div`
  margin-top: 10px;
  font-size: 17px;

  span.disabled {
    color: red;
  }

  span.enabled {
    color: ${mainColor};
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${mainColor};
  }
`
export const Container = styled.div`
  margin-top: 30px;
`
export const ContainerHeader = styled.header`
  font-size: 24px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 600;
  display: flex;
  align-items: center;
`
export const ContainerContent = styled.div`
  padding: 30px 20px;
`
export const ContenGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
`
export const ContenGridItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
`

// Chart Section

export const ChartContainer = styled.div`
  width: 100%;
  background: #fff;
  padding: 30px 20px;
  border-radius: 5px;
`

export const NoteContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 15px 30px #0000001a;
  border-radius: 16px;
  width: 100%;
  padding: 30px 100px;
  padding-bottom: 50px;

  .noteTitle {
    font-size: 24px;
    color: #080a0ad6;
    font-weight: 600;
    margin: auto;
    text-align: center;
    margin-bottom: 20px;
  }

  .noteText {
    font-size: 16px;
    color: #080a0a8a;
  }
`
