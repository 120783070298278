import React, { useCallback, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { Row } from 'components/common/DetailsLayout'
import { Button } from 'components/common/Button'
import { mainColor } from './../../../components/common/Colors/CommonColors'
import { useHistory, useParams } from 'react-router-dom'
import FinalAndInitialResultsInChart from './RadarChart'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

function answerrBar(correctAnswers: number, totalQuestions: number) {
  const correctAnswersPercent = (100 / totalQuestions) * correctAnswers

  let width = ''
  if (correctAnswers === 0) {
    width = `10px`
  } else {
    let num = 3 * correctAnswersPercent
    width = `${num}px`
  }

  let color = ''
  if (correctAnswersPercent >= 0 && correctAnswersPercent <= 29) {
    color = '#D2364B'
  } else if (correctAnswersPercent >= 30 && correctAnswersPercent <= 69) {
    color = '#FFA502'
  } else if (correctAnswersPercent >= 70 && correctAnswersPercent <= 100) {
    color = mainColor
  }

  return (
    <div
      style={{ width: `${width}`, height: '15px', backgroundColor: `${color}` }}
    ></div>
  )
}

const boxStyles: React.CSSProperties = {
  width: '8rem',
  height: '5rem',
  borderRadius: '10%',
  backgroundColor: '#f2f2f2',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '30px',
  fontWeight: 'bold',
}

const wrapperBoxStyles: React.CSSProperties = {
  width: '100%',
  margin: 'auto',
  height: 'calc(100vh - 140px)',
  padding: '0px 35px',
  overflowY: 'scroll',
}

type quizType = {
  totalCorrectAnswers: number
  totalQuestions: number
  data: {
    id: string
    name: string
    correctAnswersPercent: number
    correctAnswersCount: number
    totalQuestions: number
  }[]
}

type iqTestsResultsType = {
  initial: quizType
  final: quizType | null
}

const IQTestsResults: React.FC<{
  quizResults: iqTestsResultsType
}> = ({ quizResults }) => {
  const { t } = useTranslation()
  const { courseId } = useParams<{ courseId: string }>()
  const history = useHistory()

  const [quizResult, setQuizResult] = useState<quizType>({} as quizType)

  useEffect(() => {
    if (quizResults?.final && quizResults.final !== null)
      setQuizResult(quizResults.final)
    else setQuizResult(quizResults.initial)
  }, [quizResults])

  const redirectToCoursePage = useCallback(() => {
    if (courseId) history.push(`/courses/${courseId}`)
  }, [courseId])

  return (
    <>
      <Box boxShadow={3} m={1} p={1} style={wrapperBoxStyles}>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingBottom: '32px',
          }}
        >
          <p style={{ fontSize: '18px' }}>{t('iq_quizz.result')}</p>
          {quizResults?.initial?.data &&
            quizResults.initial.data.length >= 3 && (
              <FinalAndInitialResultsInChart testResult={quizResults} />
            )}
          <Box boxShadow={3} m={1} p={1} style={boxStyles}>
            <div>
              {quizResult?.totalCorrectAnswers} / {quizResult?.totalQuestions}
            </div>
          </Box>
        </Row>
        <Row style={{ paddingLeft: '32px' }}>
          <div>
            {quizResult?.data &&
              quizResult.data.map((mod: any, index: number) => (
                <div key={index} style={{ paddingBottom: '16px' }}>
                  <div style={{ fontSize: '18px' }}>{mod.name} </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    {answerrBar(mod.correctAnswersCount, mod.totalQuestions)}
                    <span
                      style={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        paddingLeft: '16px',
                      }}
                    >
                      {mod.correctAnswersCount} / {mod.totalQuestions}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </Row>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '32px',
          }}
        >
          <Button
            text={t('actions.close')}
            onClick={redirectToCoursePage}
            background={mainColor}
            width={100}
            height={36}
          />
        </Row>
      </Box>
    </>
  )
}

export default IQTestsResults
