import i18n from 'i18n/index'
export let existData: any = []

export let filterOption: any = {}

function translate() {
  existData = [
    { label: i18n.t('general.all'), value: 'all' },
    { label: i18n.t('general.yes'), value: 'yes' },
    { label: i18n.t('general.no'), value: 'no' },
  ]

  filterOption = {
    name: '',
    courses: [],
    coverImage: { label: i18n.t('general.all'), value: 'all' },
    video: { label: i18n.t('general.all'), value: 'all' },
    attachment: { label: i18n.t('general.all'), value: 'all' },
  }
}

translate()

i18n.on('languageChanged init', () => {
  translate()
})
