import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  blockquote::before {
    content: open-quote;
  }

  blockquote::after {
    content: close-quote;
  }
  
  blockquote {
    div {
      display: inline;
    }
    quotes: "“" "”" "‘" "’";
  }

  ul {
    list-style-type: disc;
    margin: 0px 10px;
    padding: 5px;
  }

  ol {
    list-style-type: space-counter;
    margin: 0px 10px;
    padding: 5px;
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

  h3 { 
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}


  .DraftEditor-root {
    // border: 1px solid transparent;
    padding: 10px;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    transition: border 0.2s ease;
  }

  // &.show .DraftEditor-root {
  //   border: 1px solid rgba(0, 0, 0, 0.2);
  // }

  .anchor_input {
    padding: 10px;
    outline: none;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);

    &--invalid {
      border: 1px solid red;
    }
  }
`

export const ContainerHeader = styled.header`
  display: flex;
  align-items: center;
`

export const EditorContainer = styled.div`
  &.show {
    background: #fff;
    border-left: ${props => props.biography ? '0px' : '1px solid rgba(0, 0, 0, 0.2)'};
    border-right:  ${props => props.biography ? '0px' : '1px solid rgba(0, 0, 0, 0.2)'};
    border-bottom:  ${props => props.biography ? '0px' : '1px solid rgba(0, 0, 0, 0.2)'};
  }
`

export const EditorActions = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;

  > div:not(:last-child) {
    margin-right: 12px;
  }
`

export const EditorActionsEditButton = styled.div`
  position: relative;

  &:hover > div {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
`

export const EditorActionsEditText = styled.div`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  text-align: center;
  padding: 5px 8px;
  bottom: -35px;
  left: 50%;
  color: #333;
  font-size: 12px;
  transform: translateX(-50%) scale(0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.5px 12px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease, transform 0.2s ease;
`
export const EditorEditActions = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
  > div:not(:last-child) {
    margin-right: 6px;
  }

  &.show {
    opacity: 1;
    pointer-events: auto;
  }
`

export const ScrollView = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0) 10%,
    rgb(250, 250, 250)
  );
  color: #333;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  p {
    margin: 0 0 5px 0 !important;
  }
`

export const ToolbarControls = styled.div`
  // border-top: 1px solid rgba(0, 0, 0, 0.2);
  // border-right: 1px solid rgba(0, 0, 0, 0.2);
  // border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  padding: 10px;
  display: flex;
  align-items: center;

  > div:not(:last-child) {
    margin-right: 10px;
  }

  button {
    cursor: pointer;
    display: inline-block;
    background: transparent;
    border: 1px solid #dedede;
    border-radius: 5px;
    font-style: normal;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: background 0.2s ease, border 0.2s ease;

    &:hover {
      background: #f4f4f4;
    }

    &.draftJsToolbar__active__3qcpF {
      background: #f4f4f4;
    }
  }
`
