import React from 'react'
import styled from 'styled-components'

interface Props {
  imgSource?: string
  width: number
  shadow?: boolean
  height: number
}

const ProfilePicture = ({ imgSource, shadow, width, height }: Props) => (
  <ProfilePictureWrapper shadow={shadow} width={width} height={height}>
    <img src={imgSource} alt={imgSource} />
  </ProfilePictureWrapper>
)

export default ProfilePicture

const ProfilePictureWrapper = styled.figure<Props>`
  overflow: hidden;
  border-radius: 50%;
  width: ${({ width }) => `${width}px;`}
  height: ${({ height }) => `${height}px;`}
  box-shadow: ${({ shadow }) =>
    shadow ? `0px 10px 10px rgba(0,0,0,0.2);` : `none`}

`
