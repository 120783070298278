import React, { useCallback, useEffect, useRef } from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import styled from 'styled-components'
import { mainColor } from '../Colors/CommonColors'

interface Props {
  active: number | undefined
  steps: string[]
  StepIconComponent?: any
  ColorlibConnector?: any
  setStep?: React.Dispatch<React.SetStateAction<number>>
  completedSteps?: number[]
}

const UiStepper = ({
  active,
  steps,
  StepIconComponent,
  ColorlibConnector,
  setStep,
  completedSteps,
}: Props) => {
  const currentSteps = useRef(completedSteps)
  const onStepClick = useCallback(
    (index: number) => {
      if (
        completedSteps &&
        currentSteps?.current &&
        Math.max(...currentSteps.current) > -1 &&
        Math.max(...currentSteps.current) >= index - 1
      ) {
        if (
          currentSteps?.current &&
          (currentSteps.current.indexOf(index) > -1 ||
            Math.max(...currentSteps.current) < index)
        ) {
          currentSteps.current = [...currentSteps.current]
        } else if (!!currentSteps?.current) {
          currentSteps.current = [...currentSteps.current, index]
        }

        if (setStep) setStep(index)
      }
    },
    [completedSteps, currentSteps?.current],
  )

  useEffect(() => {
    if (currentSteps?.current) currentSteps.current = completedSteps
  }, [completedSteps])

  return (
    <Container>
      <Stepper
        className="test"
        activeStep={active}
        connector={ColorlibConnector ? <ColorlibConnector /> : undefined}
        alternativeLabel={false}
        nonLinear={false}
      >
        {steps.map((label: string, index: number) => (
          <Step key={label} onClick={() => onStepClick(index)}>
            <StepLabel StepIconComponent={StepIconComponent}>
              {active === index && label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Container>
  )
}

export default UiStepper

const Container = styled.div`
  .MuiStepLabel-labelContainer {
    .MuiStepLabel-label {
      color: #317bf4;
      font-size: 24px;
    }
  }
`
