import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'

import { Button } from 'components/common/Button'
import styled from 'styled-components'
import {
  TabNavigation,
  TabPanel,
  activeTabClassName,
} from 'components/common/Tabs/Tabs'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { UserSchema } from 'helpers/validationSchemas'
import useCompaniesService from 'hooks/useCompaniesService'
import { useFetchUsers } from 'hooks/users/useUsers'
import { useHistory } from 'react-router-dom'
import CreateCompanySuperAdmin from './CreateCompanySuperAdmin'
import CompanySuperAdminList from './CompanySuperAdminList'
import {
  Container,
  Wrapper,
  FooterWrapper,
  ButtonWrapper,
} from '../styled-components'
import { mainColor } from 'components/common/Colors/CommonColors'
import { DropDownMenu } from 'material-ui'
import { useTranslation } from 'react-i18next'
import DropdownButton from 'components/common/DropdownButton'
import { useTheme } from '@material-ui/core'

interface Manager {
  id?: string
  firstName: string
  lastName: string
  email: string
  phone: string
  location: string
  gender: any
  jobTitle: string
  phoneFields: any
  phoneFieldValue: any
}

const formData: Manager = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  location: '',
  gender: null,
  jobTitle: '',
  phoneFields: null,
  phoneFieldValue: null,
}
const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

const CompanySuperAdmins = (props: any) => {
  const { companyData, setData } = props
  const history = useHistory()
  const [tabValue, setTabValue] = useState(0)
  const [createAdmin, setCreateAdmin] = useState('')
  const { t } = useTranslation()


  const { useAddManager, setCompanyState } = useCompaniesService()
  const { users, refetch }: any = useFetchUsers('SUPER_ADMIN', {
    companyId: { type: 'exact', value: companyData.companyData.id },
  })

  useEffect(() => {
    if (users && users.data) {
      setData({
        ...companyData,
        managers: users.data || [],
      })
    }
  }, [users])

  const companyManagers = (companyData && companyData.managers) || []

  const {
    handleSubmit,
    handleChange,
    resetForm,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: UserSchema,
    onSubmit(values) {
      const data = { ...values }

      delete data.phoneFieldValue

      if (data.gender) data.gender = data.gender.value
      if (data.phoneFields && data.phoneFields.label) {
        data.phoneFields = {
          dialCode: data.phoneFields.value,
          code: data.phoneFields.label,
        }
      } else {
        data.phoneFields = null
      }

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useAddManager(data, companyData.companyData.id, () => {
        refetch()
        if (createAdmin === 'reset') {
          resetForm()
        } else {
          resetForm()
          setTabValue(1)
        }
      })
    },
  })

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const onFormSubmit = (action: string) => {
    setCreateAdmin(action)
    handleSubmit()
  }

  const createActionButtons = () => {
    return [
      {
        title: t('users_layout.create_super_admin'), onClick: () => {
          setTabValue(0);
        }
      },
      {
        title: t(`company_wizard_layout.super_admin_list`), onClick: () => {
          setTabValue(1);
        }
      },
    ]
  }

  const theme = useTheme()

  return (
    <>
      <Container>
        <Wrapper theme={theme} width="100%">
          <ContentWrapper>
            <ActionButtonContainer>
              <DropdownButton title={t('general.super_admin')} buttons={createActionButtons()} />
            </ActionButtonContainer>
            <TabNavigation>
              <TabPanel value={tabValue} index={0}>
                <Wrapper theme={theme} width="70%" style={{ margin: 'auto' }}>
                  <CreateCompanySuperAdmin
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleSubmit={onFormSubmit}
                    addUserLoading={false}
                    setFieldValue={setFieldValue}
                    t={props.t}
                  />
                </Wrapper>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <CompanySuperAdminList
                  superAdminList={companyManagers}
                  companyId={companyData.companyData.id}
                  refetch={refetch}
                  setTabValue={setTabValue}
                  t={props.t}
                />
              </TabPanel>
            </TabNavigation>
          </ContentWrapper>
        </Wrapper>
      </Container>

      <FooterWrapper>
        <ButtonWrapper>
          <Button
            text={props.t('actions.save_as_draft')}
            type="medium"
            onClick={() => {
              setCompanyState(companyData.companyData.id, 1, 'Success', () =>
                history.push('/Companies'),
              )
            }}
            background="#ffffff"
            textColor="#080A0A8A"
            outline="1px solid #080A0A14"
          />

          <Button
            text={props.t('actions.save_and_next')}
            onClick={() => {
              setCompanyState(companyData.companyData.id, 2, 'Success', () =>
                props.changeStep(2),
              )
            }}
            type="medium"
            color="secondary"
            background={'#317BF4'}
          />
        </ButtonWrapper>
      </FooterWrapper>
    </>
  )
}

export default CompanySuperAdmins

const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 0px 50px;
`
const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
`

const ActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

