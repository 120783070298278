import React, { memo } from 'react'

import ProtectedRoute from 'hoc/ProtectedRoute'
import { DashboardLayout } from 'pages/dashboard'
import Users from 'pages/main/Users'
import Settings from 'pages/main/Settings'

import { CompaniesLayout, CompanyDetails } from 'pages/companies'
import VideosCommunity from 'pages/main/VideosCommunity'
import VideosInnapropriate from 'pages/main/VideosInnapropriate'
import { ModeratorsLayout } from 'pages/moderators'
import { AdminsLayout } from 'pages/admins'
import { CoachesLayout } from 'pages/coaches'
import { StudentsLayout } from 'pages/students'
import ChangePassword from 'pages/auth/ChangePassword'
import { useUserValue } from 'context/UserContext'
import { GroupLayout } from 'pages/group'
import { ModulesLayout } from 'pages/modules'
import { SectionsLayout } from 'pages/sections'
import EditProfile from 'pages/profile'
import { CourseLayout, CourseDetail } from 'pages/courses'
import StudentCourse from 'pages/courses/StudentCourse'
import ModuleDetail from 'pages/modules/ModuleDetail'
import { TopicDetails } from 'pages/topics'
import { LessonDetails } from 'pages/lessons'
import { QuestionsLayout, QuestionDetails } from 'pages/questions'
import { QuizDetails, QuizLayout } from 'pages/quizzes'
import StudentQuizDetails from 'pages/quizzes/QuizDetails/StudentQuizDetails'
import { CompanyWizardLayout } from 'pages/companyWizard'
import { AddCourseWizard } from 'pages/courses'
import AllUserProfile from './../pages/profile/AllUserProfile/AllUserProfile'
import UserStatistics from 'pages/profile/AllUserProfile/UserStatistics'
import CourseLayoutForStudent from 'pages/courses/students-courses'
import WriteIQQuizz from '../pages/quizzes/IQQuizz/WriteQuizz'
import CourseLayoutViewFromCompanyUsers from 'pages/courses/CourseLayoutViewFromCompanyUsers'
import StudentStatistics from 'pages/profile/AllUserProfile/StudentStatistics'
import GroupStudents from 'pages/group/GroupStudents/GroupStudents'
import CourseReview from 'pages/companies/CourseReview/CourseReview'
import ProfileReview from 'pages/ProfileReview/ProfileReview'
import PaymentPage from 'pages/payment'
import TransactionsPage from 'pages/payment/transactions'
import { Switch } from 'react-router-dom'
import SubscriptionsPage from 'pages/subscriptions'
import PurchaseCourse from 'components/purchaseCourse/PurchaseCourse'





const MainRoutes = () => {
  const [state] = useUserValue()
  const isStudent =
    state.currentUser.role === 'STUDENT' || state.currentUser.role === 'COACH'
  const isCompanyUser = state.currentUser.companyId !== null

  return (
    <>
      <ProtectedRoute
        exact
        path="/change-password"
        component={ChangePassword}
      />

      <ProtectedRoute exact path="/user-profile/:id" component={EditProfile} />
      <ProtectedRoute exact path="/settings" component={Settings} />
      <ProtectedRoute exact path="/topics/:topicId" component={TopicDetails} />

      <ProtectedRoute
        exact
        path="/modules/:id"
        component={ModuleDetail}
        roleAccess={['super_admin']}
      />

      <ProtectedRoute
        exact
        roleAccess={[
          'user',
          'super_admin',
          'admin',
          'companySuper_admin',
          'companyAdmin',
        ]}
        path="/companies/:id"
        component={CompanyDetails}
      />
      <ProtectedRoute
        exact
        roleAccess={['user', 'super_admin', 'admin', 'companySuper_admin']}
        path="/create-company"
        component={CompanyWizardLayout}
      />
      <ProtectedRoute
        exact
        roleAccess={['user', 'super_admin', 'admin', 'companySuper_admin']}
        path="/create-company/:id"
        component={CompanyWizardLayout}
      />
      <ProtectedRoute
        exact
        roleAccess={['user', 'super_admin', 'admin', 'companySuper_admin']}
        path="/create-course"
        component={AddCourseWizard}
      />
      <ProtectedRoute
        exact
        roleAccess={['user', 'super_admin', 'admin', 'companySuper_admin']}
        path="/create-course/:id"
        component={AddCourseWizard}
      />
      <ProtectedRoute
        exact
        path="/users/students"
        component={StudentsLayout}
        roleAccess={[
          'user',
          'super_admin',
          'admin',
          'companySuper_admin',
          'companyAdmin',
        ]}
      />
      <ProtectedRoute
        exact
        path="/users/coaches"
        component={CoachesLayout}
        roleAccess={[
          'user',
          'super_admin',
          'admin',
          'companySuper_admin',
          'companyAdmin',
        ]}
      />
      <ProtectedRoute
        exact
        roleAccess={[
          'super_admin',
          'moderator',
          'companySuper_admin',
          'companyModerator',
          'admin',
          'companyAdmin',
          'companyCoach',
        ]}
        path="/users/admins"
        component={AdminsLayout}
      />
      <ProtectedRoute
        exact
        roleAccess={[
          'user',
          'super_admin',
          'admin',
          'companyAdmin',
          'companySuper_admin',
        ]}
        path="/users/super-admins"
        component={ModeratorsLayout}
      />
      <ProtectedRoute
        exact
        path="/videos/community"
        component={VideosCommunity}
      />
      <ProtectedRoute
        exact
        path="/videos/innapropriate"
        component={VideosInnapropriate}
      />
      <ProtectedRoute exact path="/courses/:id" component={CourseDetail} />

      <ProtectedRoute
        path="/course/:courseId/quizzdetails/:id"
        exact
        roleAccess={['student', 'coach']}
        component={StudentQuizDetails}
      />
      <Switch>
        <ProtectedRoute
          path="/course/:id/stat/:userId"
          exact
          roleAccess={[
            'student',
            'coach',
            'companyAdmin',
            'super_admin',
            'admin',
            'companySuper_admin',
          ]}
          component={StudentStatistics}
        />
        <ProtectedRoute
          path="/course/:id"
          exact
          roleAccess={['student', 'coach']}
          component={StudentCourse}
        />
        <ProtectedRoute
          exact
          path="/course/:courseId/lesson/:id"
          component={LessonDetails}
        />
        <ProtectedRoute
          path="/course/:id/:assignment?/:skillId?"
          exact
          roleAccess={['student', 'coach']}
          component={StudentCourse}
        />

        <ProtectedRoute
          exact
          roleAccess={['user', 'super_admin', 'admin']}
          path="/companies"
          component={CompaniesLayout}
        />
        <ProtectedRoute exact path="/groups" component={GroupLayout} />
        <ProtectedRoute exact path="/courses" component={CourseLayout} />

        <ProtectedRoute exact path="/questions" component={QuestionsLayout} />
        <ProtectedRoute exact path="/quizzes" component={QuizLayout} />

        <ProtectedRoute exact path="/modules" component={ModulesLayout} />
        <ProtectedRoute
          exact
          roleAccess={['super_admin']}
          path="/transactions"
          component={TransactionsPage}
        />
        <ProtectedRoute
          exact
          roleAccess={['super_admin']}
          path="/subscriptions"
          component={SubscriptionsPage}
        />
        {/* test */}
        <ProtectedRoute
          exact
          roleAccess={['super_admin']}
          path="/sections"
          component={SectionsLayout}
        />
        <ProtectedRoute
          exact
          path="/"
          component={isStudent ? CourseLayoutForStudent : DashboardLayout}
        />
      </Switch>

      <ProtectedRoute
        exact
        path="/explore-wenroll-courses/:id"
        component={CourseDetail}
      />
      <ProtectedRoute
        exact
        path="/explore-course/:id"
        component={CourseDetail}
      />

      {/* <ProtectedRoute exact path="/courses/add" component={CoursesAdd} /> */}
      <ProtectedRoute exact path="/users" component={Users} />
      <ProtectedRoute exact path="/groups/:id" component={GroupLayout} />
      <ProtectedRoute exact path="/group/:id" component={GroupStudents} />

      <ProtectedRoute
        exact
        roleAccess={['user', 'coach', 'admin', 'student']}
        path="/course/:id/review/:demand?"
        component={CourseReview}
      />
      <ProtectedRoute
        exact
        roleAccess={['user', 'coach', 'admin', 'student']}
        path="/profile/:id/review/"
        component={ProfileReview}
      />

      <ProtectedRoute exact path="/questions/:id" component={QuestionDetails} />
      <ProtectedRoute exact path="/profile/:id" component={AllUserProfile} />
      <ProtectedRoute
        exact
        path="/course/:courseId/user/:userId"
        component={UserStatistics}
      />
      <ProtectedRoute
        exact
        roleAccess={['student', 'coach']}
        path="/course/:courseId/quizz/:id"
        component={WriteIQQuizz}
      />
      <ProtectedRoute
        exact
        path="/course/:courseId/quizz-details/:id"
        roleAccess={['student']}
        component={QuizDetails}
      />
      {/* <ProtectedRoute exact path="/subscribe" component={PurchaseCourse}  */}
      {/* <ProtectedRoute exact path="/usersubscribe" component={PurchaseCourse} /> */}
      <ProtectedRoute
        exact
        roleAccess={['student', 'coach', 'admin', 'super_admin']}
        path="/payment/:orderId"
        component={PaymentPage}
      />
    </>
  )
}

export default memo(MainRoutes)
