import React, { useState } from 'react'
import { PER_PAGE, useFetchCourses } from 'hooks/course/useCourses'
import { Button } from 'components/common/Button'
// import { Grid } from 'components/common/Cards/styled-components'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import {
  FormButtons,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form'
import CourseList from 'pages/courses/CourseList'
import Grid from 'components/common/Grid'
import { columnConfig } from './gridConfig'
import Search from 'components/common/Search'
import TablePagination from 'components/common/Pagination/TablePagination'

interface Props {
  boughtCourses: string[]
  selectItem: any
  selectedItem: string
  handleDeleteCourse: any
  handleButtonClick: any
  t: any
}

const Step1 = (props: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [searchText, setSearchText] = useState('')
  const [gridStyle] = useState('card')

  const query: any = {
    state: {
      type: 'exact',
      value: 'published',
    },
    companyId: {
      type: 'exact',
      value: null,
    },
    query: {
      type: 'query',
      value: JSON.stringify({ _id: { $nin: props.boughtCourses } }),
    },
  }

  const { courses, loading, refetch, error, fetchMore } = useFetchCourses(
    query,
    currentPage,
    perPage,

  )
  const fetchAll = false
  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    refetch({
      filter: query,
      currentPage: newPage + 1,
      perPage,
      fetchAll
    })
    setCurrentPage(newPage + 1)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    refetch({
      filter: query,
      currentPage,
      perPage: parseInt(event.target.value),
      fetchAll
    })
    setPerPage(parseInt(event.target.value, 10))
  }
  const courseList = (courses && courses.data) || []

  const filtered = courseList.filter((i: any) =>
    i.name.toLowerCase().includes(searchText.toLowerCase()),
  )

  const { t } = props

  const config = columnConfig(() => { }, props.selectItem, t)

  return (
    <>
      <FormGroupWrapper padding={10}>
        {/* <Grid className={gridStyle} style={{ position: 'relative' }} cols={3}>
          {courses && (
            <CourseList
              data={filtered}
              gridStyle={gridStyle}
              selectItem={props.selectItem}
              selectedItem={[props.selectedItem]}
              actions={[]}
              handleVideoModal={() => {}}
              fromWizard
              openInExternalTab={true}
              checkbox={true}
            />
          )}
        </Grid> */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <div
            style={{
              color: '#080A0AD6',
              fontSize: '24px',
              fontWeight: 600,
            }}
          >
            {t('courses_layout.title')}
          </div>
          <Search
            inputProps={{
              value: searchText,
              onChange: e => setSearchText(e.currentTarget.value),
            }}
          />
        </div>

        <Grid
          data={filtered}
          selectItem={props.selectItem}
          selectedItems={props.selectedItem || []}
          config={config}
          actionConfig={[]}
          selectAllItem={() => { }}
        />
      </FormGroupWrapper>

      <FormFooter>
        <FormButtons multiCols={true}>
          <Button
            text={t('actions.cancel')}
            type="medium"
            onClick={props.handleDeleteCourse}
            background="#E0E1E2"
            textColor={validationRed}
            outline={`1px solid ${red}`}
          />

          <Button
            text={t('actions.save_as_draft')}
            type="medium"
            isDisabled={props.selectedItem.length === 0}
            color="secondary"
            onClick={() => props.handleButtonClick('draft')}
            textColor={mainColor}
            background="#f5f5f5"
            outline={`1px solid ${mainColor}`}
          />

          <Button
            text={t('actions.next')}
            type="medium"
            isDisabled={props.selectedItem.length === 0}
            color="secondary"
            onClick={() => props.handleButtonClick('next')}
            background={mainColor}
          />
        </FormButtons>
      </FormFooter>
      <TablePagination
        currentPage={courses?.currentPage ? courses.currentPage : 0}
        rowsPerPage={perPage}
        count={courses?.courseCount ? courses.courseCount : 0}
        handleChangePage={handlePaginationClick}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}

export default Step1
