import React, { useEffect } from 'react'
import { Route, Redirect, Switch, useLocation } from 'react-router-dom'

import { getToken } from 'helpers/localStorage'
import { useUserValue } from 'context/UserContext'
import ProtectedRoute from 'hoc/ProtectedRoute'

import Activate from '../pages/auth/Activate'
import ErrorPage from 'pages/ErrorPage/ErrorPage'
import RedurectPage from 'pages/redirect/Redirect'
import Login from 'pages/auth/Login'
import ForgotPassword from 'pages/auth/ForgotPassword'
import ResetPassword from 'pages/auth/ResetPassword'
import RegisterSuccess from 'pages/auth/RegisterSuccess'
// import Subscribe from 'pages/auth/Subscribe'
import Main from 'pages'
import SignUpPage from 'pages/auth/signup'
import SendMail from 'pages/auth/sendMail'
import PurchaseCourse from 'components/purchaseCourse/PurchaseCourse'
// import UserSubscribe from 'components/purchaseCourse/UserSubscribe'

const AuthRoutes = () => {
  const token = getToken()
  const isAuthenticated = token ? true : false
  const [state] = useUserValue()
  const location = useLocation()

  useEffect(() => { }, [state.isAuthenticated])

  return (
    <>
      <Route
        path="(/forgot-password|/login|/reset-password|/subscribe|/signup|/reset|/success)"
        render={() => {
          if (isAuthenticated) return <Redirect to="/" />
          return (
            <>
              <Route exact path='/reset' component={SendMail} />


              <Route
                exact
                path="/reset-password/:token"
                component={ResetPassword}
              />

              {/* <Route exact path="/subscribe" component={Subscribe} /> */}
            </>
          )
        }}
      />
      <ProtectedRoute exact path="/activate/:token" component={Activate} />
      <Switch>
        <Route exact path="/login/:lang?" component={Login} />
        <Route exact path="/success" component={RegisterSuccess} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route
          exact
          path="/signup/:lang?"
          component={SignUpPage}
        />

        <Route exact path="/usersubscribe/:id" component={PurchaseCourse} />
        <Route exact path="/purchase" component={PurchaseCourse} />
        <ProtectedRoute exact={!isAuthenticated} path="/:lang?" component={Main} />
      </Switch>

      <Route exact path="/wenroll-course/:courseId" component={RedurectPage} />
      <Route exact path="/error" component={ErrorPage} />

    </>
  )
}

export default AuthRoutes
