import i18n from 'i18n/index'

export let courseState: any = []
export let courseLevels: any = []
export let currencyList: any = []
export let coursePrivacy: any = []
export let selectOptions: any = []
export let filterOptions: any = {}

function translate() {
  courseState = [
    { label: i18n.t('general.all'), value: 'all' },
    { id: 1, value: 'published', label: i18n.t('courses_layout.published') },
    {
      id: 2,
      value: 'unpublished',
      label: i18n.t('courses_layout.unpublished'),
    },
  ]

  courseLevels = [
    { value: '', label: i18n.t('course_details.level') },
    { value: 'beginner', label: i18n.t('courses_layout.beginner') },
    { value: 'intermediate', label: i18n.t('courses_layout.intermediate') },
    { value: 'advanced', label: i18n.t('courses_layout.advanced') },
  ]

  currencyList = [
    { id: 1, value: 'USD', label: i18n.t('currency.usd') },
    { id: 2, value: 'EUR', label: i18n.t('currency.eur') },
    { id: 3, value: 'GBP', label: i18n.t('currency.gbp') },
    { id: 4, value: 'GEL', label: i18n.t('currency.gel') },
  ]

  coursePrivacy = [
    { label: i18n.t('general.all'), value: 'all' },
    { id: 1, value: 'private', label: i18n.t('course_details.private') },
    { id: 2, value: 'public', label: i18n.t('course_details.public') },
  ]

  selectOptions = [
    { label: i18n.t('general.all'), value: 'all' },
    { label: i18n.t('general.yes'), value: 'yes' },
    { label: i18n.t('general.no'), value: 'no' },
  ]

  filterOptions = {
    name: '',
    state: { label: i18n.t('general.all'), value: 'all' },
    level: { label: i18n.t('general.all'), value: 'all' },
    coursePrivacy: { label: i18n.t('general.all'), value: 'all' },
    coaches: [],
    group: [],
    skills: [],
    video: { label: i18n.t('general.all'), value: 'all' },
    company: [],
  }
}

translate()

i18n.on('languageChanged init', () => {
  translate()
})
