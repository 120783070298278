import { Check } from '@material-ui/icons'
import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { PasswordInput } from 'components/common/TextInput/PasswordInput'
import { isValidPassword } from './ChangePassword'
import { useTranslation } from 'react-i18next'
import {
  validations,
  ValidationObject,
} from 'components/UsersLayout/staticData'
import { mainColor, validationRed } from 'components/common/Colors/CommonColors'

import '../../../fonts/font.css'
import { FormGroupItem } from 'components/common/Form'

const FormWrapper = ({ children, language }: any) => {
  return language === 'en' ? (
    <>{children}</>
  ) : (
    <>
      <FormGroupItem>{children}</FormGroupItem>
    </>
  )
}

export const PasswordWithValidations: React.FC<{
  handleChange: (eventOrTextValue: string | React.FormEvent<any>) => void
  language?: string
  currentLng?: string
  labelFirstColor?: string
  labelSecondColor?: string
  textColor?: string
  eyeColor?: string
  label?: string
  placeholder?: string
}> = ({ handleChange, language, currentLng, labelFirstColor, labelSecondColor, eyeColor, label, textColor, placeholder }) => {
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<ValidationObject[]>([])

  const chengeError = useCallback((key: string, err: boolean) => {
    setErrors((x: any) => {
      const foundIndex = x.findIndex((it: ValidationObject) => it.value === key)
      const before = x.slice(0, foundIndex)
      const after = x.slice(foundIndex + 1)
      const current = [
        ...before,
        {
          label: x[foundIndex].label,
          value: x[foundIndex].value,
          error: err,
        },
        ...after,
      ]

      return current
    })
  }, [])

  const onPasswordChange = useCallback(
    (e: any) => {
      const currentValue = e.target.value
      handleChange(e)
      setPassword(currentValue)
      if (currentValue.length >= 8) {
        chengeError('length', false)
      } else chengeError('length', true)

      if (hasLowerCase(currentValue)) {
        chengeError('lowercase', false)
      } else chengeError('lowercase', true)

      if (hasUpperCase(currentValue)) {
        chengeError('uppercase', false)
      } else chengeError('uppercase', true)

      if (hasDigit(currentValue)) {
        chengeError('digit', false)
      } else chengeError('digit', true)

      // if (hasSpecial(currentValue)) {
      //   chengeError('special', false)
      // } else chengeError('special', true)
    },
    [password],
  )

  useEffect(() => {
    if (validations) {
      setErrors(validations)
    }
  }, [validations])

  return (
    <FormWrapper language={language}>
      <PasswordInput
        placeholder={placeholder && t('form_fields.new_password')}
        name="newPassword"
        eyeColor={eyeColor}
        label={label}
        size="medium"
        type="password"
        textColor={textColor}
        fullWidth
        onChange={onPasswordChange}
        value={password}
        error={isValidPassword(password)}
        inputStyle={
          language === 'en'
            ? {
              borderRadius: '12px',
              background: '#333333',
              // height: '60px',

              fontSize: currentLng === 'en' ? '16px' : '14px',
            }
            : undefined
        }
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {errors.map((error: ValidationObject, i: number) => (
          <Label
            labelFirstColor={labelFirstColor}
            labelSecondColor={labelSecondColor}
            key={i}
            text={error.label}
            hasError={error.error}
            currentLng={currentLng}
          />
        ))}
      </div>
    </FormWrapper>
  )
}

export const Label: React.FC<{
  text: string
  hasError?: boolean
  currentLng?: string
  labelFirstColor?: string
  labelSecondColor?: string
}> = ({ text, hasError, currentLng, labelFirstColor, labelSecondColor }) => {
  return (
    <LabelContainer
      style={
        hasError
          ? {
            color: `${labelFirstColor || '#c9caca'}`
          }
          : { color: `${labelSecondColor || '#363737'}` }
      }
    >
      {/* {!hasError && <Check />} */}
      <div>{text}</div>
    </LabelContainer >
  )
}

const LabelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 5px 0px;

  font-size: 14px;
`

const hasLowerCase = (str: string) => {
  return /[a-z]/.test(str)
}

const hasUpperCase = (str: string) => {
  return /[A-Z]/.test(str)
}

const hasDigit = (str: string) => {
  return /\d/.test(str)
}

const hasSpecial = (str: string) => {
  return /[.,@#$%^&*]/.test(str)
}
