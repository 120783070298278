import styled from 'styled-components'

export const Container = styled.div``

export const ActionsContent = styled.div`
  padding: 5px;
  & > div {
    width: 100%;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 12px;
      font-size: 16px;
      width: 100%;
      text-align: center;

      svg {
        display: none;
      }

      &:hover {
        color: #317bf4 !important;
      }
    }
  }
`
