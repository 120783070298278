import { useMutation } from '@apollo/react-hooks'
import { updateCache } from 'services/updateCache'
import { GET_MODULE } from 'gql/modules.query'
import { DELETE_TOPIC } from 'gql/topics.query'

const deleteTopicService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteTopicMutation, { loading, error }] = useMutation(DELETE_TOPIC, {
    update(cache, { data: { deleteTopics: document } }) {
      updateCache({
        objectName: 'getModule',
        query: GET_MODULE,
        cache,
        document,
        action: 'update',
        params,
      })
    },
  })

  return {
    deleteTopicMutation,
    loading,
    error,
  }
}

export default deleteTopicService
