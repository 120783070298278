import React, { useState, useEffect, useRef } from 'react'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { useFormik } from 'formik'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useData } from 'context/DataContext'
import { SET_FORMDATA } from 'store/types'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import { useTranslation } from 'react-i18next'
import { genderOptions } from 'components/UsersLayout/staticData'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  CloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'

import Loader from 'components/common/Loader'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import { SelectField, AsyncSelect } from 'components/common/SelectField'
import DatePicker from 'components/common/DatePicker/DatePicker'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'

import { GET_USER } from 'gql/users.query'
import { CoachSchema } from 'helpers/validationSchemas'
import { useUserValue } from 'context/UserContext'
import useEditUserService from 'hooks/users/useEditUserService'
import moment from 'moment'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import GenderPicker from 'components/common/GenderPicker'
import { useTheme } from '@material-ui/core'

interface IEditCoach {
  id: string
  role: string
}

interface FormData {
  firstName: string
  lastName: string
  email: string
  phone: string
  gender: any
  birthDate: any
  biography: string
  note: string
  requestPasswordChange: boolean
  role: string
  phoneFields: any
  courses: any
  jobTitle: any
  location: any
  phoneFieldValue: any
  avatar: any
}

const formData: FormData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  gender: '',
  birthDate: '',
  biography: '',
  note: '',
  requestPasswordChange: false,
  role: '',
  courses: null,
  jobTitle: '',
  location: '',
  phoneFields: null,
  phoneFieldValue: null,
  avatar: '',
}

const EditCoach = ({ id, role }: IEditCoach) => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const [dataState, dispatchData] = useData()
  const ref: any = useRef()
  const { data, loading } = useQuery(GET_USER, {
    variables: { id: id },
  })

  const query: any = {}

  query.companyId = {
    type: 'exact',
    value: state.selectedCompanyId,
  }

  if (state.currentUser.companyId || state.selectedCompanyId) {
    query.isBought = {
      type: 'bool',
      value: 'true',
    }
  }

  const [fetchCourse, { data: courses, loading: courseLoading }] = useLazyQuery(
    GET_ALL_COURSE,
  )

  const { updateUser, user, error, editUserLoading } = useEditUserService({
    role: role,
    companyId: state.selectedCompanyId,
    filter: {},
  })
  const [generalError, setGeneralError] = useState('')
  const [currentCoach, setCurrentCoach] = useState<any>('')

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: CoachSchema,
    onSubmit(values) {
      const data: any = { ...values }
      data.group = null
      delete data.phoneFieldValue
      delete data.courses

      if (data.phoneFields && data.phoneFields.label) {
        data.phoneFields = {
          dialCode: data.phoneFields.value,
          code: data.phoneFields.label,
        }
      } else {
        data.phoneFields = null
      }

      updateUser(id, data)
    },
  })

  const closeDrawer = () => {
    DrawerEventEmitter.emit('openDrawer', 'editCoach', false)
  }

  const handleCloseDrawer = () => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editCoach',
        values,
        compareTo: currentCoach,
      },
    })
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (user) {
      closeDrawer()
    }
  }, [user])

  useEffect(() => {
    if (error) {
      setGeneralError(error)
    }
  }, [error])

  useEffect(() => {
    if (data && data.user) {
      const dataClone: any = {}
      const { birthDate, gender, courses, phoneFields, ...args } = formData
      for (const key in args) {
        dataClone[key] = data.user[key]
      }

      if (data.user.birthDate)
        dataClone.birthDate = moment(parseInt(data.user.birthDate)).format()

      if (data.user.gender) {
        dataClone.gender = data.user.gender
      }

      if (data.user.phoneFields && data.user.phoneFields.dialCode) {
        dataClone.phoneFields = {
          label: data.user.phoneFields.code,
          value: data.user.phoneFields.dialCode,
        }
      } else dataClone.phoneFields = null

      if (data.user.courses) {
        dataClone.courses = data.user.courses.map((i: any) => ({
          label: i.name,
          value: i.courseId,
        }))
      }

      setValues(dataClone)
      setCurrentCoach(dataClone)
    }
  }, [data])

  const loadDataOptions = (e: any) =>
    setTimeout(() => {
      const value = e
      fetchCourse({
        variables: {
          filter: {
            name: {
              type: 'match',
              value,
            },
            ...query,
          },
        },
      })
    }, 200)

  const onDateChange = (date: Date | null) => {
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  const handleSelectChange = (e: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e)
    }
  }

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  const theme = useTheme()

  return (
    <>
      {editUserLoading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>
          {t('actions.edit')} {t('general.coach')}
        </DrawerTitle>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width="48%">
                <TextInput
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  label={`${t('user_details.first_name')}*`}
                  size="medium"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  label={`${t('user_details.last_name')}*`}
                  size="medium"
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.email || generalError ? true : false}
                  errorMessage={
                    errors.email || generalError
                      ? errors.email || generalError
                      : ''
                  }
                  touched={touched.email}
                  label={`${t('user_details.email')}*`}
                  size="medium"
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <PhoneCountryCode
                  label={`${t('user_details.phone')}`}
                  selectLabel={t('form_fields.dial_code')}
                  selectValue={values.phoneFields}
                  selectChange={(e: any) => setFieldValue('phoneFields', e)}
                  inputLabel="Number"
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={
                    errors.phone || errors.phoneFields || generalError
                      ? true
                      : false
                  }
                  errorMessage={
                    errors.phone || errors.phoneFields || generalError
                      ? errors.phone || errors.phoneFields || generalError
                      : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>
              <FormField width="100%">
                <GenderPicker
                  checked={values?.gender}
                  onChange={(e: any) => setFieldValue('gender', e)}
                />
              </FormField>
              <FormField width="48%">
                <DatePicker
                  label={`${t('user_details.birthday')}`}
                  name="birthDate"
                  touched={touched.birthDate}
                  error={errors.birthDate ? true : false}
                  errorMessage={errors.birthDate ? errors.birthDate : ''}
                  value={values.birthDate}
                  onChange={onDateChange}
                />
              </FormField>
              <FormField width="48%">
                <AsyncSelect
                  isMulti
                  onInputChange={handleSelectChange}
                  onChange={(e: any) => setFieldValue('courses', e)}
                  data={(courses && courses.getAllCourse.data) || []}
                  value={values.courses}
                  label={t('form_fields.type_to_search_courses')}
                  loading={courseLoading}
                  labelDataKey="name"
                  valueDataKey="id"
                  disabled
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  label={t('form_fields.job_title')}
                  size="medium"
                  name="jobTitle"
                  type="text"
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  label={t('form_fields.location')}
                  size="medium"
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <TextInput
                  error={errors.biography ? true : false}
                  errorMessage={errors.biography ? errors.biography : ''}
                  touched={touched.biography}
                  label={t('user_details.biography')}
                  size="medium"
                  name="biography"
                  type="text"
                  multiline
                  rows="3"
                  value={values.biography}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
              <FormField>
                <TextInput
                  error={errors.note ? true : false}
                  errorMessage={errors.note ? errors.note : ''}
                  touched={touched.note}
                  label={t('user_details.note')}
                  size="medium"
                  name="note"
                  type="text"
                  multiline
                  rows="3"
                  value={values.note}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isQualified}
                    onChange={handleCheckboxChange}
                  />
                }
                label={
                  <QualifiedWrapper
                    className={`${values.isQualified ? '' : 'not'}`}
                  >
                    is qualified
                  </QualifiedWrapper>
                }
              /> */}
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.save')}
                type="medium"
                color="secondary"
                btnType="submit"
                background={mainColor}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditCoach
