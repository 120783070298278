import axios from 'axios';
import config from '../../config/index';

export const triggerVideoConverter = (videoKey: string): Promise<any> => {
  return axios.post(`${config.APIURL}/videos/convert`, {
    videoKey: videoKey
  }, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    }
  })
}