import React from 'react'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import Grid from 'components/common/Grid'
import { Button } from 'components/common/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'
import styled from 'styled-components'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import DeleteIcon from '@material-ui/icons/Delete'

import { columnConfig } from './gridConfig'
import { mainColor } from 'components/common/Colors/CommonColors'

const Step2 = (props: any) => {
  const {
    importedStudents,
    setSelectedStudents,
    selectedStudents,
    imported,
    handleFileChange,
    selectAll,
    setSelectAll,
    deleteUsers,
  } = props

  const selectItem = (id: string) => {
    const selectedIndex = selectedStudents.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedStudents, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedStudents.slice(1))
      // setSelectAll(false)
    } else if (selectedIndex === selectedStudents.length - 1) {
      newSelected = newSelected.concat(selectedStudents.slice(0, -1))
      // setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedStudents.slice(0, selectedIndex),
        selectedStudents.slice(selectedIndex + 1),
      )
      // setSelectAll(false)
    }

    setSelectedStudents(newSelected)
  }

  const studentList = importedStudents || []

  const selectAllItem = () => {
    if (!selectAll) {
      const newArr: string[] = studentList.map((n: any) => n.id)
      setSelectedStudents(newArr)
      setSelectAll(true)
      return
    }
    setSelectedStudents([])
    setSelectAll(false)
  }

  let toolbarAction = [
    {
      id: 0,
      color: 'secondary',
      tooltipText: 'Delete',
      disabled: !(selectedStudents.length > 0),
      onClick: () => deleteUsers(),
      component: <DeleteIcon fontSize="small" />,
    },
  ]

  const errorList = studentList.some((n: any) => n.error === true)
  const { t } = props
  const config = columnConfig(selectItem, imported, t)

  return (
    <>
      <Toolbar styles={{ justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ToolbarItem>
            <Button
              text={t('actions.download_template')}
              color="primary"
              type="medium"
              icon={<GetAppIcon fontSize="small" />}
              onClick={() =>
                (window.location.href =
                  'https://ib-assets-public.s3.eu-west-2.amazonaws.com/Template.xlsx')
              }
              background={mainColor}
            />
          </ToolbarItem>

          <ToolbarItem style={{ marginLeft: '10px' }}>
            <Button
              text={t('actions.upload_file')}
              color="primary"
              type="medium"
              icon={<PublishIcon fontSize="small" />}
              component={
                <StyledInput type="file" onChange={handleFileChange} value="" />
              }
              background={mainColor}
            />
          </ToolbarItem>

          <div style={{ marginLeft: '10px' }}>
            <GridMultipleActions
              selectedItems={selectedStudents.length}
              actions={toolbarAction}
            />
          </div>
        </div>
      </Toolbar>

      {imported && studentList.find((i: any) => i.error) && (
        <div
          style={{
            color: mainColor,
            width: '90%',
            padding: '10px 0 20px 0',
          }}
        >
          The row indicated in #317BF4 is already uploaded, as for the row which
          is indicated in red contains incorrect information. You need to
          correct them in Excel file and upload again.
        </div>
      )}

      <Grid
        data={studentList}
        config={config}
        paging
        pageSize={1}
        totalCount={0}
        onSwitchPage={() => console.log('1')}
        currentPage={1}
        onPageSizeChange={() => console.log('1')}
        selectAllItem={selectAllItem}
        selected={selectedStudents.length}
        selectedItems={selectedStudents}
        error={
          imported
            ? {
                error: 'error',
                errorColor: 'rgba(255, 0, 0, 0.13)',
                successColor: 'rgba(0, 128, 0, 0.13)',
              }
            : false
        }
      />
    </>
  )
}

export default Step2

const StyledInput = styled.input`
  cursor: pointer;
  height: 100%;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
`
