import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { useUserValue } from 'context/UserContext'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { Button } from 'components/common/Button'
import Pagination from 'components/common/Pagination'
import Loader from 'components/common/Loader'
import { Input } from 'components/common/TextInput'
import Drawer from 'components/common/Drawer'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import { TabNavigation, activeTabClassName } from 'components/common/Tabs/Tabs'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import FilterDrawer from './FilterDrawer/FilterDrawer'
import Player from 'components/common/VideoPlayer/Player'

import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'

import styled from 'styled-components'

import SyncIcon from '@material-ui/icons/Sync'
import { useFetchCourses, PER_PAGE } from 'hooks/course/useCourses'
import useDeleteCourse from 'hooks/course/useDeleteCourse'
import { usePublishedCourse } from 'hooks/course/useEditCourse'
import { columnConfig, actionConfig } from './gridConfig'

import { mainColor } from 'components/common/Colors/CommonColors'
import { useData } from 'context/DataContext'
import { SET_CREATED_USER } from 'store/types'
import {
  ActionButton,
  GridLayoutActions,
  GridLayoutForm,
  GridToolbar,
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import Icon from 'components/Icon'
import Search from 'components/common/Search'
import TablePagination from 'components/common/Pagination/TablePagination'
import Grid from 'components/common/Grid'
import { useTheme } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const toolbarStyle = {
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',

  '&.noBorder': {
    borderBottom: '1px solid transparent',
  },
}

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

const CourseLayout = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const [state] = useUserValue()
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})
  const [videoModal, setVideoModal] = useState({
    modal: false,
    data: '',
    subtitle: '',
  })
  const [gridStyle, setGridStyle] = useState('card')

  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const { deleteCourse } = useDeleteCourse()
  const { fireSwal } = useSwal()
  const [searchText, setSearchText] = useState<string>('')
  const [submitSearch, setSubmitSearch] = useState<boolean>(false)
  const [filterData, setFilterData] = useState({})
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [value, setValue] = useState(0)
  const [, dispatch] = useData()
  const [tab, setTab] = useState('wenrollCourse')
  const [userId, setUserId] = useState('')
  const [fetchAllPagination, setFetchAllPagination] = useState([0, 10])
  const [page, setPage] = useState(1)
  const [fetchAll, setFetchAll] = useState(false)

  const [companyId, setCompanyId] = useState(state?.currentUser?.companyId?.id)
  useEffect(() => {
    setCompanyId(state?.currentUser?.companyId?.id)
  }, [state])

  let initialVariabledForCompanyUser = {
    filter: {
      companyId: {
        type: 'exact',
        value: companyId,
      },
    },
  }

  const [variablesForCompanyUsers, setVariablesForCompanyUsers] = useState<any>(
    initialVariabledForCompanyUser,
  )

  const generateQuery = () => {
    const companyMode = state.selectedCompanyId || state.currentUser.companyId
    const where: any = {
      query: { value: {}, type: 'query' },
    }

    if (companyMode) {
      const val = value === 0 ? 'true' : 'false'
      where.companyId = {
        type: 'exact',
        value: state.selectedCompanyId,
      }
      where.isBought = {
        type: 'bool',
        value: val,
      }
      where.query = {
        type: 'query',
        value: { ...where.query.value, ...filterQuery },
      }
    } else {
      if (value === 0) {
        where.companyId = {
          type: 'exact',
          value: null,
        }
      } else {
        where.query = {
          type: 'query',
          value: {
            ...where.query.value,
            ...{ companyId: { $ne: null }, isBought: false },
          },
        }
      }
    }




    return where
  }

  const query = generateQuery()

  query.query = {
    type: 'query',
    value: JSON.stringify({ ...query.query.value, ...filterQuery }),
  }

  useEffect(() => {
    if (searchText) {
      query.name = {
        type: 'match',
        value: searchText,
      }
    }
    setSubmitSearch(false)
  }, [submitSearch])

  const { courses, courseCount, loading, refetch, error, fetchMore, }: any = useFetchCourses(
    query,
    currentPage,
    perPage,
  )

  useEffect(() => {

  },)
  const { courses: allCourses, }: any = useFetchCourses(
    query,
    currentPage,
    perPage,
    true
  )

  const { publishedCourse } = usePublishedCourse()

  const handleClick = (courseId: string) => {
    history.push(`/courses/${courseId}`)
  }

  const handleEditClick = (field: any) => {
    let lastIndex = allCourses.data[allCourses.data.length - 1].index

    DrawerEventEmitter.emit('openDrawer', 'editCourse', true, {
      maxIndex: courseCount,
      refetch: refetch,
      id: field,
      defaultStep: state.currentUser.companyId && value === 0 ? 1 : undefined,
    })
  }


  const handleNewCourse = () => {
    // history.push('/create-course/')
    const isBought = state.selectedCompanyId
      ? {
        type: 'bool',
        value: value === 0 ? 'true' : 'false',
      }
      : {}
    DrawerEventEmitter.emit('openDrawer', 'addCourses', true, {
      data: {
        companyId: state.selectedCompanyId || state.currentUser.companyId,
        onSuccess: () => {
          refetch({
            filter: {
              companyId: {
                type: 'exact',
                value: state.selectedCompanyId || state.currentUser.companyId,
              },
              isBought,
            },
            currentPage: 1,
            perPage,
          })
          setSelectedItem({})
          setSelectAll({})
        },
      },
    })
  }

  const handleCreateCompanyCourse = () => {
    dispatch({
      type: SET_CREATED_USER,
      payload: { obj: null, type: '' },
    })
    // setAddCourses(true)

    DrawerEventEmitter.emit('openDrawer', 'addCourses', true, {
      data: {
        companyId: variablesForCompanyUsers.filter.companyId.value,
        onSuccess: () => {
          refetch(variablesForCompanyUsers)
        },
      },
    })
  }

  const selectItem = (id: string) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  // const selectAllItem = () => {
  //   if (!selectAll[currentPage]) {
  //     const newArr: string[] = allCourses.data.map((n: any) => n.id)
  //     setSelectedItem({
  //       ...selectedItem,
  //       [currentPage]: newArr,
  //     })
  //     setSelectAll({
  //       ...selectAll,
  //       [currentPage]: true,
  //     })
  //     return
  //   }
  //   setSelectedItem({
  //     ...selectedItem,
  //     [currentPage]: [],
  //   })
  //   setSelectAll({
  //     ...selectAll,
  //     [currentPage]: false,
  //   })
  // }

  const handleDeleteCourse = (ids: string[]) => {
    if (ids.length <= 0) return

    const params = {
      title: `${ids.length > 1
        ? t('actions.remove_company_courses')
        : t('actions.remove_company_course')
        }`,
      onConfirm: () => {
        deleteCourse(ids, refetch)
        setSelectedItem([])
        setSelectAll({})
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const filterDrawer = () => {
    setFilterIsOpen(!filterIsOpen)
  }

  const resetFilter = () => {
    setFilterQuery({})
    setFilterData({})
    setFilterIsOpen(false)
    setSearchText('')
    // setTimeout(() => {
    //   runQuery()
    // }, 100)
    // refetch({ filter: {}, currentPage, perPage })
  }

  const onSearchSubmit = (e: React.FormEvent) => {
    //

    // searchText = { $regex: searchText, $options: 'i' }
    e.preventDefault()
    setSubmitSearch(true)
    setFilterQuery({ name: { $regex: searchText, $options: 'i' } })
    setCurrentPage(1)

    // const curPage = searchText !== undefined ? currentPage : 1
    // setCurrentPage(curPage)
    // const searchValue = { type: 'match', value: searchText }
    refetch()
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    refetch({
      // role,
      filter: filterQuery,
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
    setSelectedItem({})
  }

  const handlePublishedCourse = (courseId: string[], action: string) => {
    let title: string = ''
    let confirmText: string = ''

    if (action === 'unpublished') {
      title = `<span style="color:red">${t('popups.unpublish_course')}</span>`
      confirmText = t('popups.confirm_unpublish')
    } else {
      title = `<span style="color: ${mainColor}">${t(
        'popups.publish_course',
      )}</span>`
      confirmText = t('popups.confirm_publish')
    }

    setSelectedItem([])

    fireSwal({
      title,
      onConfirm: () => publishedCourse(courseId, action, refetch),
      confirmText,
    })
    //
  }

  const handleVideoModal = (data: any) => {
    if (!data.video) return

    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? data.video.links[0].url : '',
      subtitle: !videoModal.modal ? data.subtitle : '',
    })
  }

  const handlePaginationClick = (event: any, value: number) => {
    if (((page - value) === 1 || (page - value) === 0) && fetchAll) {
      setFetchAllPagination(prevState => [prevState[0] + 10, prevState[1] + 10])
      setPage(page + 1)
      return
    } else if (((page > value)) && fetchAll) {
      setFetchAllPagination(prevState => [prevState[0] - 10, prevState[1] - 10])
      setPage(page - 1)
      return
    }
    refetch({
      filter: {
        query: {
          type: 'query',
          value: JSON.stringify(filterQuery),
        },
        isBought: {
          type: 'bool',
          value,
        },
      },
      currentPage: value + 1,
      perPage,
    })
    setCurrentPage(value + 1)
  }

  useEffect(() => {
    const query = generateQuery()
    query.query = { type: 'query', value: JSON.stringify(query.query.value) }
    refetch({
      filter: {
        ...query,
      },
    })
  }, [value])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setFilterData({})
    setValue(newValue)
    setSelectedItem([])
  }

  const actionHandler = (event: string, field: any, action: string) => {
    switch (event) {
      case 'viewCourse':
        return handleClick(field)
      case 'deleteCourse':
        return handleDeleteCourse([field])
      case 'publishedUnPublished':
        return handlePublishedCourse([field], action)
      case 'editCourse':
        return handleEditClick(field)
      case 'palyIntro':
        return handleVideoModal(field)
      default:
        return () => console.log('')
    }
  }

  const isStudent = state.currentUser.role === 'STUDENT'

  const isCompanySuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !!state?.currentUser?.companyId?.id

  const isCompanyAdmin =
    state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  const isSuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !state?.currentUser?.companyId?.id

  const isAdmin =
    state?.currentUser?.role === 'ADMIN' && !state?.currentUser?.companyId?.id

  const canModify =
    (((isCompanySuperAdmin || isCompanyAdmin) &&
      courses?.data[0]?.companyId?.id === state?.currentUser?.companyId?.id) ||
      isSuperAdmin ||
      isAdmin) &&
    !isStudent

  const theme = useTheme()


  const config = columnConfig(handleClick, selectItem, t, theme)
  const actions = actionConfig(
    actionHandler,
    state.userPermission,
    state.currentUser,
    canModify,
    value,
    t,
    isSuperAdmin,
    isAdmin,
  )
  const selectedItemsOnPage = selectedItem[currentPage] || []
  const { currentCompany, currentUser } = state

  const handleOnTabChange = (event: any, value: string) => {
    setTab(value)
    setCurrentPage(1)

    if (value === "companyCourse")
      refetch({
        filter: {
          companyId: {
            type: "notEqual",
            value: null,
          },
          isBought: {
            type: "bool",
            value: "false"
          }
        }
      })
    else
      refetch({
        filter: {
          companyId: {
            type: "exact",
            value: null,
          }
        }
      })



  }


  return (
    <Container theme={theme}>

      <GridToolbar>
        <GridLayoutActions>
          <div>
            {state.currentUser.role === 'SUPER_ADMIN' && (
              <AddButton
                onClick={
                  !currentUser.companyId
                    ? handleNewCourse
                    : handleCreateCompanyCourse
                }
                disabled={
                  currentUser.companyId && !currentCompany.platformService
                    ? true
                    : false
                }
              >
                <Icon filename={'add'} fill={'#ffffff'} />
                {!currentUser.companyId
                  ? t('courses_layout.create_wenroll_course')
                  : t('courses_layout.create_course')}
              </AddButton>
            )}
          </div>
        </GridLayoutActions>
        <GridLayoutForm>
          <ActionButton
            disabled={_.isEmpty(filterData) && !searchText}
            onClick={resetFilter}
            theme={theme}
          >
            <SyncIcon fontSize="small" />
          </ActionButton>
          <MultipleAction theme={theme}>
            <ActionButton theme={theme} onClick={filterDrawer}>
              <Icon filename={'filter'} fill={'#080A0AD6'} />
            </ActionButton>
            <MultipleActionTooltip>
              {t('actions.filters')}
            </MultipleActionTooltip>
          </MultipleAction>
          <Search
            inputProps={{
              value: searchText,
              onChange: (e: any) => {
                setSearchText(e.target.value)
                setCurrentPage(1)
              },
            }}
            onClick={onSearchSubmit}
          />
        </GridLayoutForm>
      </GridToolbar>

      {courses && (
        <Grid
          title={t('courses_layout.title')}
          fetchAll={fetchAll}
          setFetchAll={setFetchAll}
          tabGrid={true}
          tabValue={tab}
          tabValueChange={handleOnTabChange}
          tabs={[
            { label: `${t('general.wenroll_courses')}`, value: 'wenrollCourse' },
            { label: `${t('general.company_courses')}`, value: 'companyCourse' },
          ]}
          data={fetchAll ? allCourses?.data?.slice(...fetchAllPagination) : courses.data}
          config={config}
          actionConfig={actions}
          selected={_.size(selectedItem[currentPage])}
          // selectAllItem={selectAllItem}
          selectedItems={selectedItem[currentPage] || []}
          actions={() => [
            {
              id: 0,
              color: 'secondary',
              tooltipText: `${t('actions.delete')}`,
              hide: !state.userPermission.deleteGroup,
              disabled: selectedItem[currentPage]
                ? selectedItem[currentPage].length < 1
                : true,
              onClick: () => handleDeleteCourse(selectedItem[currentPage]),
              component: (
                <ActionButton
                  disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
                  fill={'#CC3131'}
                  disabledFill={'#080A0AD6'}
                  theme={theme}
                >
                  <Icon filename={'delete'} />
                </ActionButton>
              ),
            },
            {
              id: 0,
              color: 'secondary',
              tooltipText: `${t('actions.export')}`,
              hide: !state.userPermission.deleteGroup,
              disabled: selectedItem[currentPage]
                ? selectedItem[currentPage].length < 1
                : true,
              onClick: () => alert('function'),
              component: (
                <ActionButton
                  theme={theme}
                  disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
                  fill={'#269F5A'}
                  disabledFill={'#080A0AD6'}
                >
                  <Icon filename={'download'} />
                </ActionButton>
              ),
            },
          ]}
        // actions={multiActions}
        // allSelected={allSelected}

        />
      )}
      {courses && courses.data && (
        <TablePagination
          currentPage={courses ? courses.currentPage : 0}
          rowsPerPage={perPage}
          count={courses?.courseCount ? courses.courseCount : 0}
          handleChangePage={handlePaginationClick}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      <Drawer opened={filterIsOpen} toggleDrawer={() => { }} totalWidth="900px">
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterQuery={setFilterQuery}
          perPage={perPage}
          generateQuery={generateQuery}
          refetchCourse={refetch}
          resetFilter={resetFilter}
          onClose={() => {
            setFilterIsOpen(false);
            refetch()
          }}
          tabValue={value}
          isCoursePrivacyHidden={true}
          t={t}
        />
      </Drawer>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={videoModal.modal}
        onClose={() => {
          setVideoModal({
            ...videoModal,
            modal: false,
          })
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Player url={videoModal.data} subtitle={videoModal.subtitle} />
      </Modal>
    </Container>
  )
}

export default CourseLayout

const Container = styled.div`
  overflow: hidden;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  height: 100%;
`

const Title = styled.div`
  font-size: 23px;
  font-weight: 500;
`
const ToolbarItemWrapper = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`
const ContentWrapper = styled.div`
  margin-top: 30px;
`

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #317bf4;
  padding: 18px 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    background: #3165f4;
  }
`
