import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { GET_LESSON_BY_ID } from 'gql/lesson/lesson.query'
import { useQuery } from '@apollo/react-hooks'
import { useSwal } from 'hooks/useSwal'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'

import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import Thumbnail from 'components/common/Thumbnail/Thumbnail'
import { Button } from 'components/common/Button'
import Modal from 'components/common/Modal'
import Player from 'components/common/VideoPlayer/Player'
import TextEditor from 'components/common/TextEditor/TextEditor.jsx'
import { DrawerEventEmitter } from 'helpers/drawer'
import {
  DetailsContentVideoCover,
  VideoProcessingWrapper,
} from 'components/common/DetailsLayout'
import {
  CourseContentWrapper,
  CourseContentLeft,
  CourseContentRight,
  CourseContentHeader,
  CourseContentHeaderSection,
  CourseTitle,
  CourseContentDescription,
  CourseContentReview,
  CourseContentPlayIcon,
  CourseContentDetailsList,
} from 'components/courseDetail/styled-component'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import AudioPlayer from 'components/common/AudioPlayer'
import HeadsetIcon from '@material-ui/icons/Headset'
import useEditLessonService from 'hooks/lessons/useEditLessonService'
import useDeleteLessonService from 'hooks/lessons/useDeleteLessonService'

import {
  TabNavigation,
  TabPanel,
  activeTabClassName,
} from 'components/common/Tabs/Tabs'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { QuizLayout } from 'pages/quizzes'
import Breadcrumb from 'components/common/Breadcrumb'

import { actionConfig, downloadActionConfig } from '../gridConfig'
import styled from 'styled-components'
import { CourseVideo } from 'pages/courses/StudentCourse/styled'
import {
  ActionButton,
  AddButton,
} from 'components/common/Grid/styled-components'
import Icon from 'components/Icon'
import { useTrackCourse } from 'hooks/course/useTrackCourse'
import { useTheme } from '@material-ui/core'
import useDownloadFile from 'hooks/useDownloadFile'

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

// const modal = { modal: false, url: '', event: '' }

const LessonDetails = (props: any) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(0)
  const params = useParams<any>()
  const [state] = useUserValue()
  const history = useHistory()
  const { fireSwal } = useSwal()
  const [mediaModal, setMediaModal] = useState(false)
  const [descriptionEdit, setDescriptionEdit] = useState(false)
  const [transcriptEdit, setTranscriptEdit] = useState(false)

  const { data, loading } = useQuery(GET_LESSON_BY_ID, {
    variables: {
      lessonId: params.id,
    },
  })

  const [downloadFile] = useDownloadFile()


  // const { data: moduleData } = useQuery(GET_MODULE_IMAGE, {
  //   variables: {
  //     moduleId: null,
  //   },
  // })

  const lessonData = (data && data.getLessonById) || {}

  const { editLesson } = useEditLessonService({
    lessonId: params.id,
  })
  const { deleteLesson } = useDeleteLessonService({
    topicId: lessonData.topicId,
  })

  const editEditorText = (editorText: any, event: string) => {
    const newData: any = {}
    newData[event] = editorText

    editLesson(lessonData.topicId, params.id, newData, () => console.log(''))

    if (descriptionEdit && event === 'description') setDescriptionEdit(false)
    if (transcriptEdit && event === 'transcript') setTranscriptEdit(false)
  }

  const video = (lessonData.video && lessonData.video) || ''
  const audio = (lessonData.audio && lessonData.audio.link) || ''
  const document =
    (lessonData.contentDocument && lessonData.contentDocument.link) || ''

  const handleLessonDelete = (ids: string[]) => {
    let title: string =
      ids.length === 1
        ? t('popups.delete_lesson_single')
        : t('popups.delete_lesson_many')
    const params = {
      title,
      onConfirm: () => deleteLesson(ids, () => history.goBack()),
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const editLessonDrawer = (lessonId: string) => {
    DrawerEventEmitter.emit('openDrawer', 'editLesson', true, {
      id: null,
      data: {
        id: lessonId,
        fromTopic: true,
      },
    })
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const action = actionConfig(
    editLessonDrawer,
    handleLessonDelete,
    '',
    state.userPermission,
  )
  const downloadActions = downloadActionConfig(() => console.log(''), t)

  // აერორებდა, დროებით იყოს სტატიკურად და მერე moduleId შეგვილია Url-დან ამოვიღოთ
  // const moduleImage = moduleData && moduleData.getModule.coverImage

  let breadCrumbData: any = ''

  if (data && lessonData) {
    breadCrumbData = [
      { label: t('general.modules'), link: '/modules' },
      {
        label: lessonData.moduleName,
        link: `/modules/${lessonData.moduleId}`,
      },
      {
        label: lessonData.topicName,
        link: `/topics/${lessonData.topicId}`,
      },
      { label: lessonData.name, link: '' },
    ]
  }

  const isStudent = state.currentUser.role === 'STUDENT'

  const isCompanySuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !!state?.currentUser?.companyId?.id

  const isCompanyAdmin =
    state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  const isSuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !state?.currentUser?.companyId?.id

  const isAdmin =
    state?.currentUser?.role === 'ADMIN' && !state?.currentUser?.companyId?.id

  const canModify =
    (((isCompanySuperAdmin || isCompanyAdmin) &&
      lessonData?.companyId === state?.currentUser?.companyId?.id) ||
      isSuperAdmin ||
      isAdmin) &&
    !isStudent

  const hasConverted = video?.links && video.links[0].converted

  const openAddCoach = () => {
    DrawerEventEmitter.emit('openDrawer', 'addQuiz', true, {
      id: null,
      data: () => { },
    })
  }

  const theme = useTheme()
  return (
    <Container>
      {/* <CourseContentWrapper>
        <CourseContentLeft>
          <CourseContentHeader>
            <Thumbnail width={100} height={100} imageSrc={''} />

            <CourseContentHeaderSection>
              <CourseTitle>{lessonData.name}</CourseTitle>
            </CourseContentHeaderSection>
          </CourseContentHeader>

          <CourseContentDescription>
            {/* {editModuleLoading && <Loader withBackground={true} />}
            {lessonData.description && (
              <TextEditor
                text={lessonData.description}
                isVisible={descriptionEdit}
                hasControls={true}
                editorTitle={t('lesson_details.lesson_overview')}
                closeEditMode={() => setDescriptionEdit(false)}
                openEditMode={() => setDescriptionEdit(true)}
                onSave={(e: any) => editEditorText(e, 'description')}
                canEdit={state.userPermission.editLesson && canModify}
              />
            )}
          </CourseContentDescription>
          <CourseContentReview></CourseContentReview>
        </CourseContentLeft>

        <CourseContentRight
          style={{ textAlign: 'right' }}
          // className={!video ? 'noVideo' : ''}
        >
          <div
            style={{
              display: 'inline-flex',
              marginBottom: '20px',
              justifyContent: 'flex-end',
            }}
          >
            <GridActionMenu
              actionConfig={downloadActions}
              row={lessonData}
              icon={
                <Button
                  text={t('actions.download')}
                  size="small"
                  icon={<ArrowDropDownIcon />}
                  iconPosition="right"
                />
              }
            />
          </div>
          <div style={{ display: 'inline-flex', marginBottom: '20px' }}>
            {!(
              !state.userPermission.editLesson &&
              !state.userPermission.deleteLesson
            ) && (
              <GridActionMenu
                actionConfig={action}
                row={lessonData}
                icon={
                  <Button
                    text={t('general.actions')}
                    size="small"
                    icon={<ArrowDropDownIcon />}
                    iconPosition="right"
                  />
                }
              />
            )}
          </div>
          {video || audio ? (
            <DetailsContentVideoCover
              noBefore={video && !hasConverted ? true : false}
            >
              {video && hasConverted ? (
                <img src={video.thumbnail} alt="" />
              ) : null}
              <CourseContentPlayIcon onClick={() => setMediaModal(true)}>
                {video && hasConverted ? (
                  <PlayCircleOutlineIcon fontSize="large" />
                ) : (
                  <VideoProcessingWrapper>
                    <CircularProgress />
                    <div> {t('general.video_processing_title')}</div>
                    <div> {t('general.video_processing_description')}</div>
                  </VideoProcessingWrapper>
                )}
                {audio && <HeadsetIcon fontSize="large" />}
              </CourseContentPlayIcon>
            </DetailsContentVideoCover>
          ) : null}
          {document && (
            <div
              style={{
                height: '100px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                src="https://img.icons8.com/ios/500/document.png"
                alt=""
                style={{ width: '100px', cursor: 'pointer' }}
                onClick={() => window.open(document, '_blank')}
              />
            </div>
          )}
          <CourseContentDetailsList>
            {(video || audio) && (
              <div style={{ fontSize: '13px' }}>
                {t('general.duration')}: {lessonData.duration}
              </div>
            )}
          </CourseContentDetailsList>
        </CourseContentRight>
      </CourseContentWrapper> */}
      <Header>
        <CourseVideo
          image={video.thumbnail}
          hasVideo={true}
          onClick={() => setMediaModal(true)}
        />
        <div>
          <LessonName>{lessonData.name}</LessonName>
          <ModuleName>{lessonData.moduleName}</ModuleName>
        </div>

        <Box>
          {
            lessonData?.attachment?.link &&
            <Button
              onClick={() => downloadFile(
                lessonData?.attachment?.link,
                lessonData?.attachment?.name
              )}
              icon={<Icon fill="#ffffff" filename='download' />}
              text={t('actions.download')}
              width={170}
              height={45}
              buttonStyles={{ borderRadius: '16px' }}
            />
          }
          <Link style={{ cursor: 'pointer', marginLeft: '30px' }} to={{
            pathname: `/course/${params.courseId}/`,
            state: { moduleId: lessonData.moduleId || "" }
          }}>
            <Icon filename="close" height="36px" width="36px" fill='#707070' />
          </Link>
        </Box>


        {state.userPermission.editLesson && canModify && (
          <ActionButton
            style={{
              marginLeft: 'auto',
            }}
            onClick={() => editLessonDrawer(params.id)}
            theme={theme}
          >
            <Icon filename={'edit'} fill={'#080A0A'} />
          </ActionButton>
        )}
      </Header>
      <div style={{ padding: '0px 30px' }}>
        <CourseContentDescription>
          {lessonData.description && (
            <TextEditor
              textEditorStyle={{ height: '100px' }}
              text={lessonData.description}
              isVisible={descriptionEdit}
              hasControls={true}
              editorTitle={t('lesson_details.lesson_overview')}
              closeEditMode={() => setDescriptionEdit(false)}
              openEditMode={() => setDescriptionEdit(true)}
              onSave={(e: any) => editEditorText(e, 'description')}
              canEdit={state.userPermission.editLesson && canModify}
            />
          )}
        </CourseContentDescription>
      </div>
      <SSection>
        <CourseContentDescription>
          {lessonData.transcript && (
            <TextEditor
              textEditorStyle={{ height: '100px' }}
              text={lessonData.transcript}
              isVisible={transcriptEdit}
              hasControls={true}
              editorTitle={t('lesson_details.transcript')}
              closeEditMode={() => setTranscriptEdit(false)}
              openEditMode={() => setTranscriptEdit(true)}
              onSave={(e: any) => editEditorText(e, 'transcript')}
              canEdit={state.userPermission.editLesson && canModify}
            />
          )}
        </CourseContentDescription>
      </SSection>
      {/* {canModify && (
        <SSection>
          <AddButton style={{ margin: 'auto' }} onClick={() => openAddCoach()}>
            <Icon filename={'add'} fill={'#ffffff'} />
            {t('quizzes_layout.create_quiz')}
          </AddButton>
        </SSection>
      )} */}

      {/* <QuizLayout
        type="lesson"
        id={lessonData.id}
        canCreate={lessonData.numberOfModuleQuestions}
        name={lessonData.name}
        isDraggable
        companyId={lessonData.companyId}
        canModify={canModify}
      /> */}

      <Modal isOpened={mediaModal} onClose={() => setMediaModal(false)}>
        <div>
          {audio && <AudioPlayer url={audio} />}
          {video && video.links && (
            <Player url={video.links[0].url} subtitle={lessonData.subtitle} />
          )}
        </div>
      </Modal>
    </Container>
  )
}

export default LessonDetails

const Header = styled.div`
  display: flex;
  gap: 30px;
  padding: 30px;
`

const Container = styled.div`
  position: relative;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  height: 100%;
`

const SSection = styled.section`
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 30px;
`

const LessonName = styled.h1`
  font-size: 24px;
  color: '#080A0AD6';
  font-weight: 600;
`

const ModuleName = styled.p`
  padding: 5px 10px;
  margin-bottom: 30px;
  border-radius: 8px;
  border: 1px solid #080A0A14;
  color: #080A0A8A;
`
const Box = styled.div`
  position: absolute;
  right: 50px;
  display: flex;
`
const QuizLayoutWrapper = styled.div``
