import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AnswerType } from './MultipleAnswer'
import Radio from '@material-ui/core/Radio'
import { black, mainColor, red, successGreen } from 'components/common/Colors/CommonColors'
import { AnswerContainer } from './SingleAnswer'
import { useTranslation } from 'react-i18next'

const BoolAnswer: React.FC<{
  setHasAnswerSelected: React.Dispatch<React.SetStateAction<boolean>>
  questionId: string
  setBoolAnswer: React.Dispatch<React.SetStateAction<boolean>>
  isAnswersButtonDisabled: boolean
  correctAnswer: boolean
}> = ({
  setHasAnswerSelected,
  questionId,
  setBoolAnswer,
  isAnswersButtonDisabled,
  correctAnswer,
}) => {
    const { t } = useTranslation()
    const answers = useMemo(() => {
      return [
        {
          id: 'true',
          value: t('general.true'),
        },
        {
          id: 'false',
          value: t('general.false'),
        },
      ]
    }, [questionId])
    const [selectedAnswer, setSelectedAnswer] = useState<AnswerType>(
      {} as AnswerType,
    )

    useEffect(() => {
      setSelectedAnswer({} as AnswerType)
    }, [questionId])

    const onRadioChange = useCallback((answer: AnswerType) => {
      setSelectedAnswer(answer)
      setHasAnswerSelected(true)
      setBoolAnswer(answer.id === 'true')
    }, [])

    const BooleanChoiceAnswer: React.FC<{ answer: AnswerType }> = ({
      answer,
    }) => {
      const [color, setColor] = useState(black)
      const [bulletColor, setBulletColor] = useState(mainColor)

      useEffect(() => {
        if (
          selectedAnswer?.id &&
          correctAnswer !== undefined &&
          correctAnswer !== null &&
          isAnswersButtonDisabled
        ) {
          if (`${correctAnswer}` === answer.id) {
            setColor(successGreen)
          } else if (
            selectedAnswer.id === answer.id &&
            `${correctAnswer}` !== answer.id
          ) {
            setColor(red)
            setBulletColor(red)
          }
        }
      }, [selectedAnswer, correctAnswer, isAnswersButtonDisabled])

      return (
        <AnswerContainer
          key={answer.id}
          onClick={() => !isAnswersButtonDisabled && onRadioChange(answer)}
        >
          <Radio
            name="single"
            value={answer.id}
            checked={selectedAnswer.id == answer.id}
            style={{ color: '#080A0A' }}
          />
          <span
            style={{
              color: color,
            }}
          >
            {answer.value}
          </span>
        </AnswerContainer>
      )
    }

    return (
      <div>
        {answers &&
          answers.map(answer => {
            return <BooleanChoiceAnswer key={answer.id} answer={answer} />
          })}
      </div>
    )
  }

export default BoolAnswer
