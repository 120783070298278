import styled, { css } from 'styled-components'
import IconButton from '@material-ui/core/IconButton'

export const Wrapper = styled.div`
  display: flex;
`

export const SelectedItemsWrapper = styled.div`
  transition: opacity 0.2s ease;
  margin: 0 10px 0 20px;
  &.disabled {
    opacity: 0.5;
  }
`

export const MultipleAction = styled.div`
  position: relative;
  background: ${({ theme }) => theme.palette.background.paper};

  &.disabled {
    pointer-events: none;
  }

  &:hover > div {
    opacity: 1;
    transform: translate(-50%) scale(1);
  }
`

export const MultipleActionTooltip = styled.div`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  text-align: center;
  padding: 5px 8px;
  bottom: -35px;
  left: 50%;
  color: #333;
  font-size: 12px;
  transform: translateX(-50%) scale(0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.5px 12px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease, transform 0.2s ease;
`

export const NoValueWrapper = styled.div`
  opacity: 0.5;
  font-size: 0.75rem;
`

export const GridContainer = styled.div`
  margin: 0 30px;
  box-shadow: 0 0 10px -8px;

  table,
  th,
  td {
    padding: 0;
    margin: 0;
    vertical-align: middle;
  }
  .table_row_background {
    background: #fcfcfc;
  }
  min-width: 1300px;
  /* width: -webkit-fill-available; */
`

export const GridToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 30px;
`

export const GridLayoutActions = styled.div`
  display: flex;
  align-items: center;
`

export const GridLayoutForm = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  & > div {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
`

export const TableHeader = styled.thead`
  background: #fcfcfc;
`

export const TableRow = styled.tr`
  border-bottom: solid 1px rgba(216, 216, 216, 0.21);
  .table_header {
    height: 50px;
  }
  .table_column_checkbox {
    width: 80px;
    .table_row {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  transition: background 0.3s ease;

  &:nth-child(even) {
    background: rgba(238, 238, 238, 1);
  }
`
export const TableRowHeader = styled.th`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
  background: #2f2834;
`

export const TableBody = styled.tbody`
  .table_row_tr {
    padding: 10px 0;
  }
`

export const TableColumn = styled.td`
  padding: 10px 0 !important;
  .table_row_container {
    width: 100%;
    height: 100%;
    padding: 0px 10px 0 0;
  }
  .column_column_item {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4e4e4e;
  }
`

export const GridHeader = styled.thead`
  display: flex;
  width: 100%;
  background: #fcfcfc;
`

export const ColumnContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4e4e4e;
`

export const ActionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
`
export const ActionContainerItem = styled.div`
  box-sizing: border-box;
  padding: 0px 4px;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
`

export const ActionIcon = styled.img`
  cursor: pointer;
`

export const GridImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 2px;
  border: solid 1px rgba(170, 170, 177, 0.57);
  background-color: #ffffff;
  img {
    width: 60px;
    height: 60px;
  }
`

export const ActionButton = styled(IconButton) <any>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background: ${({ theme }) => theme.palette.background.paper};
  border: 1px solid #080a0a1f;
  border-radius: 8px;
  cursor: pointer;

  ${({ fill, disabledFill }) =>
    css`
      svg {
        fill: ${fill};
      }
      &:disabled {
        svg {
          fill: ${disabledFill};
        }
      }
    `}

  ${({ disabledStyle }) =>
    disabledStyle &&
    css`
      &:disabled {
        ${disabledStyle}
      }
    `}
`

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #317bf4;
  padding: 18px 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 30px;

  &:hover {
    transition: 0.3s;
    background: #3165f4;
  }
`
