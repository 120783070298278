import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import BackspaceRoundedIcon from '@material-ui/icons/BackspaceRounded'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import LockIcon from '@material-ui/icons/Lock'
import SchoolIcon from '@material-ui/icons/School'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import Checkbox from 'components/common/Checkbox'
import { mainColor } from 'components/common/Colors/CommonColors'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import { CapitalPlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'
import moment from 'moment'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'

import React from 'react'
import styled from 'styled-components'
import strCutter from 'utils/strCutter'
import AvatarIcon from '../../components/common/AvatarInput/profile.svg'
import SendIcon from '@material-ui/icons/Send'
import { GlobalUserDefaultImage } from 'utils/utils'
import { GET_ALL_SUBSCRIPTIONS } from 'gql/subscriptions/subscription.query'
import { Item } from 'components/common/SelectBtn/styled'
import { GET_SUCCESSFUL_TRANSACTION } from 'gql/transaction/transaction.query'

interface itemProps {
  id: string
  isChecked: boolean
  firstName: string
  lastName: string
  email: string
  status: string
  mobileNumber: string
  phone: string
  phoneFields: {
    dialCode: string
    code: string
  }
  gender: string
  birthDate: string
  age: string
  biography: string
  note: string
  group: any
  isQualified: boolean
  companyId: any
  courses: any
  avatar: any
  createDate: any
  subscription?: any
  canceledSub: any
}

export const columnConfig = (
  role: string,
  handelClick: any,
  selectItem: any,
  t?: any,
  theme?: any
) => {
  let grid = [

    {
      id: 'isChecked',
      render: (item: itemProps) => (

        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={item.isChecked}
            onChange={() => selectItem(item.id)}
          />
          <img src={item.avatar || GlobalUserDefaultImage} alt="Avatar" />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: `${t('user_details.first_name')}`,
      render: (item: itemProps) => (
        <div onClick={() => handelClick(item.id)} style={{ cursor: 'pointer' }}>
          {item.firstName}
        </div>
      ),
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.last_name')}`,
      render: (item: itemProps) => <div>{item.lastName}</div>,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.email')}`,
      render: (item: itemProps) => <div>{item.email}</div>,
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.phone')}`,
      render: (item: itemProps) => {

        return (
          <div>
            {item.mobileNumber != "0" ? item.mobileNumber : null}
          </div>
        )
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item: itemProps) => (
        <StatusWrapper className={`${item.status.toLowerCase()}`}>
          {item.status}
        </StatusWrapper>
      ),
    },
  ]

  if (role === 'STUDENT' || role === 'ADMIN') {

    // grid = [
    //   ...grid,
    //   {
    //     id: 'subscription',
    //     numeric: false,
    //     disablePadding: false,
    //     label: `პაკეტი`,
    //     render: (item: itemProps) => {


    //       return (
    //         <GroupsWrapper>
    //           <GroupItem>
    //             <CountContainer theme={theme}>{item.subscription?.productId?.price || 0} ₾  </CountContainer>
    //             {/* <GroupTitle className="group-item-title">
    //               {item.group.map((g: any) => (
    //                 <div key={g.name}>{g.name}</div>
    //               ))}
    //             </GroupTitle> */}
    //           </GroupItem>
    //         </GroupsWrapper>
    //       )
    //     },
    //   },
    // ]
  }

  if (role === 'COACH' || role === 'STUDENT') {
    grid = [
      ...grid,
      {
        id: 'courses',
        numeric: false,
        disablePadding: false,
        label: `${t('general.courses')}`,
        render: (item: itemProps) => {
          return (
            <GroupsWrapper>
              <GroupItem>
                <CountContainer theme={theme}>{item.courses.length}</CountContainer>
                <GroupTitle className="group-item-title">
                  {item.courses.map((g: any) => (
                    <div key={g.name}>{g.name}</div>
                  ))}
                </GroupTitle>
              </GroupItem>
            </GroupsWrapper>
          )
        },
      },
      {
        id: 'paymentCount',
        numeric: false,
        disablePadding: false,
        label: `ჯერადობა`,
        render: (item: itemProps) => (

          <div>
            {item.subscription?.paymentCount || item.canceledSub?.paymentCount}
          </div>
        ),
      },
      {
        id: 'subscription',
        numeric: false,
        disablePadding: false,
        label: `პაკეტი`,
        render: (item: itemProps) => {


          return (
            <GroupsWrapper>
              <GroupItem>
                <CountContainer theme={theme}>{item.subscription?.productId?.price || item.canceledSub?.productId?.price || 0} ₾  </CountContainer>
                {/* <GroupTitle className="group-item-title">
                  {item.group.map((g: any) => (
                    <div key={g.name}>{g.name}</div>
                  ))}
                </GroupTitle> */}
              </GroupItem>
            </GroupsWrapper>
          )
        },
      },
      {
        id: 'subscriptionDate',
        numeric: false,
        disablePadding: false,
        label: `გამოწერის თარიღი`,
        render: (item: itemProps) => {


          return (
            <StatusWrapper>
              {item?.subscription?.createDate && moment(parseInt(item?.subscription?.createDate)).format('DD.MM.YYYY')}
              {item?.canceledSub?.createDate && moment(parseInt(item?.canceledSub?.createDate)).format('DD.MM.YYYY')}

            </StatusWrapper>
          )
        },
      },
      {
        id: 'subscriptionCancelDate',
        numeric: false,
        disablePadding: false,
        label: `გაუქმების თარიღი`,
        render: (item: itemProps) => {


          return (
            <StatusWrapper>
              {item.canceledSub?.canceledAt && moment(parseInt(item.canceledSub.canceledAt)).format('DD.MM.YYYY')}
              {item?.subscription?.canceledAt && moment(parseInt(item.subscription.canceledAt)).format('DD.MM.YYYY')}
              {item.canceledSub && !item.canceledSub?.canceledAt && "გაუქმებულია"}
            </StatusWrapper>
          )
        },
      },
    ]
  }

  // if (role === 'COACH') {
  //   grid = [
  //     ...grid,
  //     {
  //       id: 'isQualified',
  //       numeric: false,
  //       disablePadding: false,
  //       label: 'Is Qualified',
  //       render: (item: itemProps) => (
  //         <div>{item.isQualified ? 'Yes' : 'No'}</div>
  //       ),
  //     },
  //   ]
  // }

  // grid = [
  //   ...grid,
  //   {
  //     id: 'paymentCount',
  //     numeric: false,
  //     disablePadding: false,
  //     label: `ჯერადობა`,
  //     render: (item: itemProps) => (

  //       <div>
  //         {item.subscription?.paymentCount || 0}
  //       </div>
  //     ),
  //   },
  // ]
  grid = [
    ...grid,
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: itemProps) => {
        return (
          <StatusWrapper>
            {moment(parseInt(item.createDate)).format('DD.MM.YYYY')}
          </StatusWrapper>
        )
      },
    },
  ]

  return grid
}

export const actionConfig = (
  handleEditClick: any,
  handleDeleteClick: any,
  handleResendPasswordClick: any,
  handleChangeStatusClick: any,

  // handleChangeQualificationClick?: any,
  permissions?: any,
  field?: any,
  userRole?: string,
  t?: any,
  setResetPasswordOpen?: React.Dispatch<React.SetStateAction<boolean>>,
  currentUserId?: string,
  setUserId?: React.Dispatch<React.SetStateAction<string>>,
  handleEditSubmission?: any
) => {
  let action = [
    {
      hide: !permissions[`edit${field}`],
      render: (item: any) => (
        <Action onClick={() => handleEditClick(item.id)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },

    {
      hide: !permissions[`delete${field}`],
      render: (item: any) => (
        <>
          {item.id !== currentUserId && (
            <Action onClick={() => handleDeleteClick(item)}>
              <DeleteForeverRoundedIcon />
              <span>{t('actions.delete')}</span>
            </Action>
          )}
        </>
      ),
    }, {

      render: (item: any) => (
        <>
          {item.id !== currentUserId && (

            <Action onClick={() => handleEditSubmission(item.id)}>
              <EditRoundedIcon />
              <span>გამოწერა</span>
            </Action>
          )}
        </>
      ),
    },
    {
      hide: !permissions[`resendUserPassword${field}`],
      render: (item: any) => (
        <Action onClick={() => handleResendPasswordClick(item.id)}>
          <SendIcon />
          <span>{t('actions.resend_password')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.resetPassword,
      render: (item: any) => (
        <Action
          onClick={() => {
            if (setResetPasswordOpen) {
              setResetPasswordOpen(true)
            }
            if (setUserId) setUserId(item.id)
          }}
        >
          <LockIcon />
          <span>{t('actions.reset_password')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions[`suspend${field}`],
      render: (item: any) => {
        return (
          <>
            {item.id !== currentUserId && (
              <Action
                onClick={() => handleChangeStatusClick(item.id, item.status)}
              >
                {item.status === 'ACTIVE' ? (
                  <>
                    <BackspaceRoundedIcon />
                    <span>{t('actions.suspend')} </span>

                  </>
                ) : (
                  <>
                    <CheckCircleIcon />
                    <span>{t('actions.activate')}</span>
                  </>
                )}
              </Action>
            )}
          </>
        )
      },
    },
  ].filter((i: any) => !i.admin)

  return action.filter((i: any) => !i.hide)
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
`

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  width: max-content;
  height: max-content;
  padding: 5px 10px;
  padding-bottom: 2px;
  border-radius: 4px;

  &.active {
    color: #269f5a;
    background: #269f5a1f;
  }

  &.suspended {
    color: #cc3131;
    background: #cc31311f;
  }

  &.pending {
    color: #ffa502;
    background: #ffa5021f;
  }

  &.draft {
    color: rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.151);
  }
`

const StatusCircle = styled.div`
  margin-right: 5px;
  margin-top: -3px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
`

const CheckBoxAndAvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  & > img {
    margin: 10px;
    width: 60px;
    height: 60px;
    border: 1px solid #080a0a0a;
    border-radius: 8px;
    cursor: pointer;
  }
`

export const GroupsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const GroupItem = styled.div`
  position: relative;
  &:hover > span {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
`

export const GroupTitle = styled.span`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  text-align: center;
  padding: 5px 8px;
  bottom: -35px;
  left: 50%;
  color: #333;
  font-size: 12px;
  transform: translateX(-50%) scale(0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.5px 12px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease, transform 0.2s ease;
`

const CountContainer = styled.div`

  height: 36px;
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color:  ${({ theme }) => theme.palette.text.primary};;
`
