import styled from 'styled-components'
// import { Select } from '@material-ui/core'

export const Container = styled.div`
  position: relative;
  width: 95px;
  @media (max-width: 600px){
    width: 65px;
  }
`
export const Select = styled.div`
  border-radius: 25px;
  padding: 8px 15px;
  border: 1px solid #262A31;
  color: rgba(0,0,0,0.87);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px){
    padding:3px 8px;
  }
`

export const CurrentLanguage = styled.span`
  margin-right: 5px;

`

export const OptionsBox = styled.div`
  position: absolute;
  width: 100%;
  padding: 8px;
  text-align: center;
  background-color: #bcd1f2;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border: 1px solid #262A31;
  border-top: none;
`

export const LanguageOption = styled.div`
  margin: 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LanguageText = styled.div`
  width: 40px;
  border-radius: 6px;
  color: rgba(0,0,0,0.87);
  cursor: pointer;

  padding: 4px 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: #E1E1E1;
    color: rgba(0,0,0,0.87) !important;
  }
`
