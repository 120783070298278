import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useDeleteStudentsFromGroup from 'hooks/group/useDeleteStudentsFromGroup'
import useImportStudents from 'hooks/users/useImportStudents'
import { PER_PAGE, useFetchUsers } from 'hooks/users/useUsers'
import { useSwal } from 'hooks/useSwal'
import { AddStudent, FilterStudents } from 'pages/students'
import { CLEAR_FORMDATA, SET_CREATED_USER } from 'store/types'
import { useData } from 'context/DataContext'
import IconButton from '@material-ui/core/IconButton'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import { Button } from 'components/common/Button'
import { mainColor } from 'components/common/Colors/CommonColors'
import Drawer from 'components/common/Drawer'
import { FormContainer } from 'components/common/Form/Form'
import Grid from 'components/common/Grid'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import TablePagination from 'components/common/Pagination/TablePagination'
import { Input } from 'components/common/TextInput'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'

import ImportStudentsDrawer from '../ImportStudents/ImportStudentsDrawer'
import AddStudentsInGroupDrawer from './AddStudentsInGroupDrawer'
import { actionConfig, columnConfig, nonAdminColumnConfig } from './gridConfig'
import MoveToAnotherGroup from './MoveToAnotherGroup'
import Search from 'components/common/Search'
import SelectBtn from 'components/common/SelectBtn'
import { useUserValue } from 'context/UserContext'
import { useHistory, useParams } from 'react-router-dom'
import { Text } from 'pages/courses/StudentCourse/styled'
import Icon from 'components/Icon'
import { useLazyQuery } from '@apollo/react-hooks'
import { GET_GROUP_BY_ID } from 'gql/group/group.query'
import { CourseParams } from 'components/courseDetail/CourseDetailContent'


const studentActionConfig = (handleClick: any, t: any) => {
  let action = [
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('create')}>
          <AddCircleRoundedIcon />
          <span>{t('actions.add_student')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('addFromDb')}>
          <PlaylistAddIcon />
          <span>{t('actions.add_students_to_group')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('import')}>
          <GetAppIcon />
          <span>{t('actions.import_students')}</span>
        </Action>
      ),
    },
  ]

  return action
}

const GroupStudents = (props: any) => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()
  const [dataState, dispatch] = useData()
  const [selectedStudents, setSelectedStudents] = useState<any>([])
  const params = useParams<{ id: string }>();

  const [selectedItem, setSelectedItem] = useState<any>([])
  const [selectAll, setSelectAll] = useState<any>({})
  const [moveToGroupDialog, setMovetoGroupDialog] = useState(false)
  const [studentId, setStudentId] = useState('')
  const [studentGroups, setStudentGroups] = useState<string[]>([])
  const [formData, setFormData] = useState<any>('')
  const [searchText, setSearchText] = useState('')
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [state] = useUserValue();
  const history = useHistory();
  const [getGroupById, { data }] = useLazyQuery(GET_GROUP_BY_ID, {
    variables: {
      id: params.id,
    }
  })




  useEffect(() => {
    if (state.currentUser.role === "COACH" ||
      state.currentUser.role === "STUDENT") {
      getGroupById()
    }
  }, [state])



  const [drawer, setStudentsDrawer] = useState({
    import: false,
    addFromDb: false,
    create: false,
  })

  let filter = {}




  if (state.currentUser.role === "COACH") {

    filter = {
      group: {
        type: 'nestedArrayIn',
        value: [params.id],
        nestedField: 'groupId',
      },
    }
  }
  else {
    filter = {
      group: {
        type: 'nestedArrayIn',
        value: [props.groupId],
        nestedField: 'groupId',
      },
    }
  }

  const { users, loading, error, refetch }: any = useFetchUsers('STUDENT', {
    ...filter,
  })

  const { importStudents } = useImportStudents()

  const { deleteStudentsFromGroup } = useDeleteStudentsFromGroup()

  const selectItem = (id: never) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = users.data.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const deleteStudents = (id?: string) => {
    fireSwal({
      title:
        selectedItem.length === 1
          ? t('popups.remove_student_from_group_del_button')
          : t('popups.remove_students_from_group_del_button'),
      onConfirm: () => {
        let ids: string[] = []
        id ? (ids = [id]) : (ids = [...selectedItem[currentPage]])
        if (ids.length <= 0) return false
        deleteStudentsFromGroup(props.groupId, ids, refetch)
        setSelectedItem([])
      },
      confirmText: t('popups.confirm_remove'),
    })
  }

  const toggleDialog = () => {
    setMovetoGroupDialog(!moveToGroupDialog)
  }

  const handleActionClick = (id: string, action: string, groups: string[]) => {
    if (action === 'delete') {
      fireSwal({
        title: t('popups.remove_student_from_group_action'),
        onConfirm: () => {
          let ids: string[] = []
          id ? (ids = [id]) : (ids = [...selectedItem[currentPage]])
          if (ids.length <= 0) return false
          deleteStudentsFromGroup(props.groupId, ids, refetch)
          setSelectedItem([])
        },
        confirmText: t('popups.confirm_delete'),
      })

      // deleteStudentsFromGroup(props.groupId, [id], refetch)
      return
    }

    setStudentId(id)
    setStudentGroups(groups)

    toggleDialog()
  }

  const toggleDrawer = (field: string, value: boolean) => {
    setStudentsDrawer({
      ...drawer,
      [field]: value,
    })
  }

  const handleDrawerClose = (field: string, value: boolean) => {
    if (field === 'create') {
      dispatch({
        type: 'SET_CLOSE_DRAWER',
        payload: true,
      })
    } else if (field === 'addFromDb') {
      if (selectedStudents.length > 0) {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            setStudentsDrawer({
              ...drawer,
              ['addFromDb']: false,
            })
            setSelectedStudents([])
          },
          confirmText: t('popups.confirm_cancel'),
          cancelText: t('general.no'),
        })
      } else {
        setStudentsDrawer({
          ...drawer,
          ['addFromDb']: false,
        })
        setSelectedStudents([])
      }
    } else {
      setStudentsDrawer({
        ...drawer,
        [field]: value,
      })
    }
  }

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    refetch({
      role: 'STUDENT',
      filter: { ...filter },
      currentPage: newPage + 1,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    refetch({
      role: 'STUDENT',
      filter: { ...filter },
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
  }

  useEffect(() => {
    if (formData) {
      const isEmpty = _.values(formData).every(_.isEmpty)
      if (isEmpty) {
        setStudentsDrawer({
          ...drawer,
          ['create']: false,
        })
        dispatch({
          type: CLEAR_FORMDATA,
          payload: true,
        })
      } else {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            setStudentsDrawer({
              ...drawer,
              ['create']: false,
            })
            dispatch({
              type: CLEAR_FORMDATA,
              payload: true,
            })
          },
          onClose: () => {
            setFormData('')
            dispatch({
              type: CLEAR_FORMDATA,
              payload: true,
            })
          },
          confirmText: t('popups.confirm_cancel'),
          cancelText: t('general.no'),
        })
      }
    }
  }, [formData])

  useEffect(() => {
    if (
      dataState.createdObject.obj &&
      dataState.createdObject.type === 'student'
    ) {
      importStudents(
        [
          {
            firstName: dataState.createdObject.obj.firstName,
            lastName: dataState.createdObject.obj.lastName,
            email: dataState.createdObject.obj.email,
            phone: dataState.createdObject.obj.phone,
          },
        ],
        props.groupId,
        props.groupName,
        null,
        () => refetch(),
      )

      dispatch({
        type: SET_CREATED_USER,
        payload: { obj: null, type: '' },
      })
    }
  }, [dataState.createdObject.obj])



  const config = state.currentUser.role === "COACH" || state.currentUser.role === "STUDENT" ? nonAdminColumnConfig(selectItem, t) : columnConfig(selectItem, t)
  const actions = actionConfig(handleActionClick, t)
  const buttonActions = studentActionConfig((field: string) => {
    setStudentsDrawer({
      ...drawer,
      [field]: true,
    })
  }, t)

  const studentList = (users && users.data) || []

  const filteredStudents = studentList.filter(
    (i: any) =>
      i.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
      i.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
      i.email.toLowerCase().includes(searchText.toLowerCase()) ||
      i?.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
      i?.phoneFields?.code?.toLowerCase().includes(searchText.toLowerCase()) ||
      i?.phoneFields?.dialCode
        ?.toLowerCase()
        .includes(searchText.toLowerCase()),
  )

  const [filterDrawerOpened, setFilterDrawerOpened] = useState(false)

  const handleFiltersClick = () => {
    setFilterDrawerOpened(true)
  }

  const [filterOptions, setFilterOptions] = useState<any>(null)

  const handleResetClick = () => {
    refetch({
      role: 'STUDENT',
      filter,
      currentPage,
      perPage,
    })
    setSearchText('')
    setFilterOptions(null)
  }

  const filterData = (filters: any) => {
    const searchedGroupIds = filters.group.value
    const groups = searchedGroupIds
      ? [...searchedGroupIds, props.groupId]
      : [props.groupId]

    filters.group = {
      nestedField: 'groupId',
      type: 'nestedArrayAll',
      value: groups,
    }

    setFilterOptions(filters)
    // refetch({
    //   role: 'STUDENT',
    //   filter: filters,
    //   currentPage: 1,
    //   perPage,
    // })
  }

  const handleSuccess = () => {
    refetch()
    setStudentsDrawer({
      ...drawer,
      ['addFromDb']: false,
    })
  }

  return (
    <Container>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => { }}
        totalWidth="600px"
      >
        <FilterStudents
          role="STUDENT"
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filterData}
          filterLoading={loading}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          hasSearchByCompany={false}
          defaultFilter={filter}
          refetchStudents={refetch}
          useCompanyId
          manualCompanyId={props.companyId}
        />
      </Drawer>
      <FormContainer onSubmit={(e: any) => e.preventDefault()}>
        {state.currentUser.role !== 'COACH' && state.currentUser.role !== 'STUDENT' && (
          <Toolbar styles={{ justifyContent: 'space-between' }}>
            <ToolbarItem>
              <div style={{ display: 'flex' }}>
                <SelectBtn
                  active={0}
                  onChange={() => { }}
                  options={[
                    {
                      label: t('actions.add_student'),
                      value: 0,
                      onClick: () =>
                        setStudentsDrawer({
                          ...drawer,
                          create: true,
                        }),
                    },
                    {
                      label: t('actions.add_students_to_group'),
                      value: 1,
                      onClick: () =>
                        setStudentsDrawer({
                          ...drawer,
                          addFromDb: true,
                        }),
                    },
                    {
                      label: t('actions.import_students'),
                      value: 2,
                      onClick: () =>
                        setStudentsDrawer({
                          ...drawer,
                          import: true,
                        }),
                    },
                  ]}
                />

                <GridMultipleActions
                  selectedItems={
                    selectedItem[currentPage]
                      ? selectedItem[currentPage].length
                      : 0
                  }
                  actions={[
                    {
                      id: 0,
                      color: 'secondary',
                      tooltipText: t('actions.delete'),
                      disabled: selectedItem[currentPage]
                        ? selectedItem[currentPage].length < 1
                        : true,
                      onClick: () => deleteStudents(),
                      component: <DeleteIcon fontSize="small" />,
                    },
                  ]}
                />
              </div>
            </ToolbarItem>

            <ToolbarItem>
              <ItemWrapper>
                <Search
                  inputProps={{
                    value: searchText,
                    onChange: e => setSearchText(e.target.value),
                  }}
                />
              </ItemWrapper>
            </ToolbarItem>
          </Toolbar>
        )}

        {(state.currentUser.role === "COACH" || state.currentUser.role === "STUDENT") && (
          <>
            <NonAdminWrapper>
              <NonAdminHeader>
                <Button
                  text={t('actions.back')}
                  size={'medium'}
                  buttonStyles={{
                    borderRadius: '16px',
                  }}
                  icon={
                    <Icon filename={'arrowLeft'}></Icon>
                  }
                  background={'#080A0A04'}
                  textColor={'#080A0A'}
                  onClick={history.goBack}
                />
                <Text
                  fs={'32px'}
                  color={'#080A0A'}
                  weight={'bold'}
                >
                  {t('navigation.list_of_students')}
                </Text>
              </NonAdminHeader>
              <NonAdminGroupName>
                <Text fs={'24px'} color={'#080A0A54'}>{data?.getGroupById?.name}</Text>
              </NonAdminGroupName>
              <NonAdminGroupItems>
                <NonAdminGroupItem>
                  <Text fs={"16px"} color={'#080A0A54'}>{t('navigation.group_admins')}: {data?.getGroupById?.admin?.firstName} {data?.getGroupById?.admin?.lastName}</Text>
                </NonAdminGroupItem>
                <NonAdminGroupItem>
                  <Text fs={"16px"} color={'#080A0A54'}>{t('navigation.group_coach')}: {data?.getGroupById?.coach?.name}</Text>
                </NonAdminGroupItem>
              </NonAdminGroupItems>
            </NonAdminWrapper>
          </>
        )}

        <Grid
          data={filteredStudents}
          config={config}
          actionConfig={state.currentUser.role !== "COACH" && state.currentUser.role !== "STUDENT" ? actions : []}
          selectAllItem={selectAllItem}
          selected={_.size(selectedItem[currentPage])}
          selectedItems={selectedItem[currentPage] || []}
          onFiltersClick={handleFiltersClick}
          resetFilters={handleResetClick}
          resetVisible={!!searchText || !!filterOptions}
          // title={t('general.students')}
          openInExternalTab={true}
        />
        {users && (
          <TablePagination
            currentPage={users ? users.currentPage : 0}
            rowsPerPage={perPage}
            count={users ? users.totalCount : 0}
            handleChangePage={handlePaginationClick}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}

        <Drawer
          opened={drawer.addFromDb}
          toggleDrawer={() => { }}
          totalWidth="970px"
        >
          <AddStudentsInGroupDrawer
            groupId={props.groupId}
            groupName={props.groupName}
            studentInGroupRefetch={handleSuccess}
            onClose={() =>
              setStudentsDrawer({
                ...drawer,
                ['addFromDb']: false,
              })
            }
            setSelectedStudents={setSelectedStudents}
            companyId={props.companyId}
            t={t}
          />
        </Drawer>

        <Drawer
          opened={drawer.import}
          toggleDrawer={() => { }}
          totalWidth="970px"
        >
          <ImportStudentsDrawer
            onClose={() => toggleDrawer('import', false)}
            groupData={{
              groupId: props.groupId,
              groupName: props.groupName,
            }}
            refetch={refetch}
          />
        </Drawer>

        <Drawer opened={moveToGroupDialog} toggleDrawer={toggleDialog}>
          <MoveToAnotherGroup
            toggleDialog={toggleDialog}
            studentId={studentId}
            studentGroups={studentGroups}
            studentListRefetch={() => {
              refetch()
              toggleDialog()
            }}
            companyId={props.companyId}
          />
        </Drawer>

        <Drawer
          opened={drawer.create}
          toggleDrawer={() => { }}
          totalWidth="970px"
        >
          <AddStudent
            title={t('actions.add_student')}
            role="STUDENT"
            drawerName="addStudent"
            drawerData={{
              onSuccess: () => { },
              groupField: true,
            }}
            onClose={() => toggleDrawer('create', false)}
            setFormData={setFormData}
            group={{
              groupId: props.groupId,
              name: props.groupName,
              status: props.groupStatus,
            }}
            setSelectedStudents={setSelectedStudents}
          />
        </Drawer>
      </FormContainer>
    </Container>
  )
}
export default GroupStudents

const Container = styled.div`
  box-sizing: border-box;
  padding: 10px;
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 350px;
`
const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`
const NonAdminWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
`

const NonAdminHeader = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;

  .btn-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 92px;
    height: 50px;
  }
`

const NonAdminGroupName = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const NonAdminGroupItems = styled.div`
  margin-top: 10%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`

const NonAdminGroupItem = styled.div`
  width: 470px;
  height: 60px;
  background: #FFFFFF;
  border: 1px solid #70707008;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
`
