import deleteUserService from 'services/users/deleteUserService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useDeleteUserService = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const { deleteUserMutation, loading, error } = deleteUserService(params)

  const deleteUser = async (
    userIds: string[],
    role: string,
    onSuccess: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    const { data, errors } = await deleteUserMutation({
      variables: {
        ids: userIds,
        role,
      },
    })

    if (errors) {
      console.log(errors)
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      onSuccess()
      const capitalizedRole: string = `${role.charAt(0)}${role
        .slice(1)
        .toLowerCase()}`

      if (userIds.length > 1) {
        message = `${capitalizedRole}s ${t('messages.deleted')}`
      }

      if (userIds.length > 1 && role === 'COACH') {
        message = `${capitalizedRole}es ${t('messages.deleted')}`
      }

      if (userIds.length === 1) {
        message = `${capitalizedRole} ${t('messages.deleted')}`
      }

      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteUser,
    deleteUserLoading: loading,
    error,
  }
}

export default useDeleteUserService
