import React, { useState } from 'react'
import styled from 'styled-components'
import { useSwal } from 'hooks/useSwal'
import { generateFullName } from 'utils/utils'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import { Button } from 'components/common/Button'
import Grid from 'components/common/Grid'
import { Input } from 'components/common/TextInput'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import useUserDeleteService from 'hooks/users/useDeleteUserService'
import EditSuperAdmin from './EditSuperAdmin'
import { actionConfig, columnConfig } from './gridConfig'
import { mainColor } from 'components/common/Colors/CommonColors'
import Icon from 'components/Icon'
import Search from 'components/common/Search'
import { useTheme } from '@material-ui/core'

const toolbarStyle = {
  justifyContent: 'space-between',
}

const CompanySuperAdminList = ({
  superAdminList,
  companyId,
  refetch,
  t,
  setTabValue,
}: any) => {
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchTextClick, setsearchTextClick] = useState('')

  const { fireSwal } = useSwal()
  const [checkbox, setCheckbox] = useState(true)
  const [editMode, setEditMode] = useState(false)
  const [editField, setEditField] = useState<any>(null)

  const { deleteUser, deleteUserLoading } = useUserDeleteService({
    role: 'SUPER_ADMIN',
    companyId: companyId,
  })

  const handleSelect = (id: never, event: boolean) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    setSelectedItem(newSelected)
  }

  const selectAllItem = () => {
    if (!selectAll) {
      const newArr: string[] = superAdminList.map((n: any) => n.id)
      setSelectedItem(newArr)
      setSelectAll(true)
      return
    }
    setSelectedItem([])
    setSelectAll(false)
  }

  const handleDelete = (ids: string[]) => {
    if (ids.length <= 0) return
    let title: string =
      ids.length === 1
        ? `${t('popups.delete_single')} ${t('general.super_admin')} ?`
        : `${t('popups.delete_many')} ${t('general.super_admins')} ?`

    const params = {
      title,
      onConfirm: () => {
        deleteUser(ids, 'SUPER_ADMIN', () => {
          refetch()
          setSelectedItem([])
          setCheckbox(false)
        })
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const handleEdit = (field: any) => {
    setEditMode(true)
    setEditField({
      ...field,
      phoneFields: {
        label: field.phoneFields.code,
        value: field.phoneFields.dialCode,
      },
    })
  }

  const actionClick = (e: string, field: any) => {
    switch (e) {
      case 'edit':
        return handleEdit(field)

      case 'delete':
        return handleDelete([field])

      default:
        break
    }
  }

  const config = columnConfig(handleSelect, checkbox, t)
  const actions = actionConfig(actionClick, t)

  const filtered = superAdminList.filter((i: any) =>
    generateFullName(i)
      .toLowerCase()
      .includes(searchText.toLowerCase()),
  )


  const theme = useTheme();

  return (
    <>
      {editMode && (
        <EditSuperAdmin
          onCancel={() => {
            setEditField(null)
            setEditMode(false)
          }}
          item={editField}
          t={t}
        />
      )}
      {/* <AddButton onClick={() => setTabValue(0)}>
        <Icon filename={'add'} />
        {'სუპერ ადმინის შექმნა'}
      </AddButton> */}
      <Toolbar styles={toolbarStyle}>
        <ToolbarItemWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title theme={theme}>{t('company_wizard_layout.super_admin_list')}</Title>
          </div>

          <div className="wrapper">
            {selectedItem.length > 0 && (
              <SelectedItemCounter theme={theme}>
                {selectedItem.length} {t('general.items_selected')}
              </SelectedItemCounter>
            )}
            <div style={{ margin: '0px 30px' }}>
              <Tooltip title={t('actions.delete')}>
                <DeleteIconContainer
                  disabled={selectedItem.length === 0}
                  onClick={() => handleDelete(selectedItem)}
                >
                  <Icon filename={'delete'} />
                </DeleteIconContainer>
              </Tooltip>
            </div>
            <ToolbarItem>
              <Search
                inputProps={{
                  value: searchTextClick,
                  onChange: e => setsearchTextClick(e.target.value),
                }}
                onClick={e => setSearchText(searchTextClick)}
                onEnter={e => setSearchText(searchTextClick)}
              />
            </ToolbarItem>
          </div>
        </ToolbarItemWrapper>
      </Toolbar>
      <GridWrapper>
        <Grid
          config={config}
          data={filtered}
          actionConfig={actions}
          selected={selectedItem.length}
          selectAllItem={selectAllItem}
          selectedItems={selectedItem}
          disabledSelect={!checkbox}
          openInExternalTab={true}
        />
      </GridWrapper>
    </>
  )
}

export default CompanySuperAdminList

const ToolbarItemWrapper = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`
const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text.primary};
`

const GridWrapper = styled.div`
  .MuiPaper-root {
    box-shadow: none;
  }
`

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #317bf4;
  padding: 18px 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin: auto;

  svg {
    fill: #ffffff;
  }

  &:hover {
    transition: 0.3s;
    background: #3165f4;
  }
`

const SelectedItemCounter = styled.div`
  min-width: max-content;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.text.primary};
`

const DeleteIconContainer = styled.div<{ disabled: boolean }>`
  width: 56px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #080a0a0a;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: 20px;
    height: 22px;
    fill: ${({ disabled }) => (disabled ? '#080a0aa2' : '#cc3131')};
  }
`
