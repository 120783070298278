import React, { useState } from 'react'

import {
  Wrapper,
  DocumentContainer,
  Details,
  DetailsTitle,
  DetailsList,
  DetailsListItem,
  TagItem,
  NoValueWrapper,
} from './styled-components'
import { ModuleItemWrapper } from '../styled-components'
import HeadsetIcon from '@material-ui/icons/Headset'

import FileWrapper from 'components/common/FileWrapper'
import Modal from 'components/common/Modal'
import AudioPlayer from 'components/common/AudioPlayer'

interface Tags {
  id: string
  label: string
}

interface Module {
  moduleId: string
  name: string
}

interface Document {
  name: string
  fileType: string
  size: string
  link: string
}

interface Audio {
  name: string
  fileType: string
  link: string
}

interface Params {
  type: string
  tags: Tags[]
  modules: Module[]
  documents: Document[]
  audios: Audio[]
  t: any
}

const QuestionInfo = ({
  type,
  tags,
  modules,
  documents,
  audios,
  t,
}: Params) => {
  const [audioModalOpened, setAudioModalOpened] = useState<boolean>(false)
  const [currentAudio, setCurrentAudio] = useState<string>('')

  const handleDocumentClick = (link: string) => window.open(link)

  const handleAudioClick = (link: string) => {
    setAudioModalOpened(true)
    setCurrentAudio(link)
  }

  const renderDocuments = () => {
    return (
      <Wrapper>
        {documents.map((document: Document) => (
          <DocumentContainer>
            <FileWrapper file={document} onClick={handleDocumentClick} />
          </DocumentContainer>
        ))}
      </Wrapper>
    )
  }

  const renderAudios = () => {
    return (
      <Wrapper>
        {audios.map((audio: Audio) => (
          <DocumentContainer>
            <FileWrapper
              icon={<HeadsetIcon color="secondary" />}
              file={audio}
              onClick={handleAudioClick}
            />
          </DocumentContainer>
        ))}
        <Modal
          isOpened={audioModalOpened}
          onClose={() => setAudioModalOpened(false)}
        >
          <AudioPlayer url={currentAudio && currentAudio} />
        </Modal>
      </Wrapper>
    )
  }

  return (
    <Details>
      <DetailsTitle>{t('question_details.question_details')}</DetailsTitle>
      <DetailsList>
        <DetailsListItem>
          <strong>{t('general.type')}:</strong>
          {type}
        </DetailsListItem>
        <DetailsListItem>
          <strong>{t('general.tags')}:</strong>
          {tags.map((tag: any) => tag.label)}
          {tags.length === 0 && t('general.no_tags')}
        </DetailsListItem>
        <DetailsListItem>
          <strong>{t('question_details.linked_to_modules')}:</strong>
          {modules.map((moduleItem: any) => moduleItem.name)}
          {modules.length === 0 && (
            <NoValueWrapper>{t('general.no_modules')}</NoValueWrapper>
          )}
        </DetailsListItem>
        <DetailsListItem>
          <strong>{t('general.attached_documents')}:</strong>
          {documents && renderDocuments()}
        </DetailsListItem>
        <DetailsListItem>
          <strong>{t('general.uploaded_audios')}:</strong>
          {audios && renderAudios()}
        </DetailsListItem>
      </DetailsList>
    </Details>
  )
}

export default QuestionInfo
