import { useMutation } from '@apollo/react-hooks'
import { CREATE_COURSE } from 'gql/course/course.query'

const createCourseService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [createCourse, { loading }] = useMutation(CREATE_COURSE, {
    // update(cache, { data: { createCourse: document } }) {
    //   updateCache({
    //     objectName: 'getAllCourse',
    //     query: GET_ALL_COURSE,
    //     cache,
    //     document,
    //     action: 'add',
    //     params,
    //   })
    // },
  })

  return {
    createCourse,
    loading,
  }
}

export default createCourseService
