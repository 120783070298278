import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import GroupIcon from '@material-ui/icons/Group'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import Checkbox from 'components/common/Checkbox'
import { mainColor } from 'components/common/Colors/CommonColors'
import { GroupsWrapper } from 'components/common/DetailsLayout'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import { CapitalPlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import strCutter from 'utils/strCutter'
import AvatarIcon from '../../../components/common/AvatarInput/profile.svg'
import {
  CheckBoxAndAvatarContainer,
  GroupItem,
  GroupTitle,
  StatusCircle,
  StatusWrapper,
} from '../CompanyStudents/gridConfig'

interface itemProps {
  id: string
  isChecked: boolean
  firstName: string
  lastName: string
  email: string
  status: string
  phone: string
  phoneFields: {
    dialCode: string
    code: string
  }
  gender: string
  birthDate: string
  age: string
  biography: string
  note: string
  group: any
  isQualified: boolean
  avatar: string
  createDate: any
  companyId: any
}

export const columnConfig = (selectItem: any, checkbox?: boolean, t?: any) => {
  let grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <CheckBoxAndAvatarContainer>
          <div style={{ visibility: !checkbox ? 'hidden' : 'visible' }}>
            <Checkbox
              checked={item.isChecked}
              onChange={() => selectItem(item.id)}
            />
          </div>
          <img src={item.avatar || AvatarIcon} alt="Avatar" style={{ objectFit: item.avatar ? 'cover' : 'contain' }} />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: t('user_details.first_name'),
      render: (item: itemProps) => (
        <div style={{ cursor: 'pointer' }}>{item.firstName}</div>
      ),
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: t('user_details.last_name'),
      render: (item: itemProps) => <div>{item.lastName}</div>,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('user_details.email'),
      render: (item: itemProps) => <div>{item.email}</div>,
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: t('user_details.phone'),
      render: (item: itemProps) => {
        return (
          <div>
            {item.phoneFields &&
              item.phoneFields.code &&
              `(${item.phoneFields.code})`}
            {item.phone}
          </div>
        )
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: t('general.status'),
      render: (item: itemProps) => {
        return (
          <StatusWrapper className={`${item.status.toLowerCase()}`}>
            <StatusCircle />
            {item.status}
          </StatusWrapper>
        )
      },
    },
    {
      id: 'group',
      numeric: false,
      disablePadding: false,
      label: t('general.groups'),
      render: (item: itemProps) => {
        const firstTwoGroups = item.group.slice(0, 2)
        const exGroups = item.group.slice(2, item.group.length)
        const groups = item.group
        return (
          <div>
            {firstTwoGroups.length === 0 && (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
            <GroupsWrapper>
              {firstTwoGroups.map(
                (group: any, index: number) =>
                  index < 3 && (
                    <GroupItem key={group.groupId}>
                      <IconButton>
                        <GroupIcon fontSize="small" />
                      </IconButton>
                      <GroupTitle className="group-item-title">
                        {group.name}
                      </GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exGroups.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exGroups.map((exGroup: any) => (
                        <div>{exGroup.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: itemProps) => (
        <div>
          {item.companyId ? (
            strCutter(item.companyId.name, 25)
          ) : (
            <>
              {CapitalPlatformTitle}
              <StarsRoundedIcon
                style={{
                  color: mainColor,
                  fontSize: 10,
                  verticalAlign: 'super',
                }}
              />
            </>
          )}
        </div>
      ),
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: itemProps) => {
        return (
          <StatusWrapper>
            <StatusCircle />
            {moment(parseInt(item.createDate)).format('DD.MM.YYYY')}
          </StatusWrapper>
        )
      },
    },
  ]

  return grid
}

export const actionConfig = (handleClick: any, t: any) => {
  const actions: any = [
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('edit', item)}>
          <EditRoundedIcon />
          <Typography>{t('actions.edit')}</Typography>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('delete', item.id)}>
          <DeleteForeverRoundedIcon />
          <Typography>{t('actions.delete')}</Typography>
        </Action>
      ),
    },
  ]
  return actions
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
`
