import React, { useState, useEffect } from 'react'
import { useFetchCourses, PER_PAGE } from 'hooks/course/useCourses'
import { useUserValue } from 'context/UserContext'
import Loader from 'components/common/Loader'
import styled from 'styled-components'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import IconButton from '@material-ui/core/IconButton'
import {
  GridSwitchButtons,
  Grid,
} from 'components/common/Cards/styled-components'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ViewListIcon from '@material-ui/icons/ViewList'
import { Input } from 'components/common/TextInput'

import CourseList from 'pages/courses/CourseList'

const toolbarStyle = {
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',
}

const Step2 = ({ selectedCourses, setSelectedCourses }: any) => {
  const [currentPage] = useState<number>(1)
  const [perPage] = useState<number>(PER_PAGE)
  const [state] = useUserValue()
  const [gridStyle, setGridStyle] = useState('card')
  const [videoModal, setVideoModal] = useState({
    modal: false,
    data: '',
    subtitle: '',
  })
  const [selectedItem, setSelectedItem] = useState<any>({})

  const [selectAll, setSelectAll] = useState<any>({})

  useEffect(() => {
    setSelectedItem(selectedCourses)
  }, [])

  const query: any = {}

  if (state.selectedCompanyId) {
    query.companyId = {
      type: 'exact',
      value: state.selectedCompanyId,
    }
  }

  const { courses, loading, refetch, error, fetchMore } = useFetchCourses(
    query,
    currentPage,
    perPage,
  )

  const handleVideoModal = (data: any) => {
    if (!data.video) return

    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? data.video.links[0].url : '',
      subtitle: !videoModal.modal ? data.subtitle : '',
    })
  }

  const selectItem = (id: string) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  useEffect(() => {
    return () => {
      setSelectedCourses(selectedItem)
    }
  })

  const selectedItemsOnPage = selectedItem[currentPage] || []

  return (
    <>
      <ContentWrapper>
        <Toolbar styles={toolbarStyle}>
          <ToolbarItemWrapper>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* {state.userPermission.createCourse && (
              <Button
                text="Create Course"
                color="secondary"
                icon={<AddCircleRoundedIcon />}
                onClick={handleNewCourse}
              />
            )} */}
              <Title>Courses list</Title>

              <GridMultipleActions
                selectedItems={selectedItemsOnPage.length}
                actions={[]}
              />
            </div>

            <div className="wrapper">
              {/* <ToolbarItem>
                <IconButton
                disabled={_.isEmpty(filterData)}
                onClick={resetFilter}
                >
                  <SyncIcon fontSize="small" />
                </IconButton>
              </ToolbarItem> */}

              {/* <ToolbarItem>
                <IconButton
                onClick={filterDrawer}
                >
                  <FilterListIcon
                    fontSize="default"
                  color={filtered ? 'secondary' : 'primary'}
                  />
                </IconButton>
              </ToolbarItem> */}

              <ToolbarItem>
                <form onSubmit={(e: any) => e.preventDefault()}>
                  <Input
                    label="Search"
                    type="text"
                    size="small"
                    value={''}
                    // onChange={(e: any) => setSearchText(e.target.value)}
                    icon={
                      <IconButton>
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                  />
                </form>
              </ToolbarItem>
            </div>
          </ToolbarItemWrapper>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ToolbarItem>
              <GridSwitchButtons>
                <button
                  className={`${gridStyle === 'card' ? 'active' : ''}`}
                  onClick={() => setGridStyle('card')}
                >
                  <DashboardIcon />
                </button>
                <button
                  className={`${gridStyle === 'list' ? 'active' : ''}`}
                  onClick={() => setGridStyle('list')}
                >
                  <ViewListIcon />
                </button>
              </GridSwitchButtons>
            </ToolbarItem>
          </div>
        </Toolbar>

        <br />

        <Grid className={gridStyle} style={{ position: 'relative' }} cols={3}>
          {error && error}
          {loading && <Loader withBackground />}
          {courses && (
            <CourseList
              data={courses.data}
              // isDraggable={!searchText && !filtered ? true : false}
              gridStyle={gridStyle}
              selectItem={selectItem}
              selectedItem={selectedItem[currentPage] || []}
              actions={[]}
              handleVideoModal={handleVideoModal}
            />
          )}
        </Grid>
      </ContentWrapper>
    </>
  )
}

export default Step2

const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 15px 20px;
`
const ToolbarItemWrapper = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`
const Title = styled.h2`
  font-size: 22px;
  font-weight: 500;
`
