import React from 'react'
import AddStudentsInNewGroup from './AddStudentsInNewGroup'

const Step2 = (props: any) => (
  <AddStudentsInNewGroup
    students={props.students}
    handleSetStudents={props.handleSetStudents}
    companyId={props.companyId}
  />
)

export default Step2
