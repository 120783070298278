import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import axios from 'axios'

import { GET_TOPICS } from 'gql/topics.query'
import { GET_LESSON_BY_ID } from 'gql/lesson/lesson.query'
import { useData } from 'context/DataContext'
import { useAction } from 'store/actions'
import { SET_FORMDATA } from 'store/types'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
  FormGroupItem,
  InputGroup,
} from 'components/common/Form'
import { Button } from 'components/common/Button'
import { Input } from 'components/common/TextInput'
import { LessonSchema } from 'helpers/validationSchemas'
import {
  DrawerHeader,
  DrawerContent,
  CloseIcon,
  DrawerTitle,
  Info,
} from 'components/DrawerLayout/styled-components'
import { FormTitle } from 'components/common/Form/styled-component'
import SelectAsync from 'components/common/Select/SelectAsync'
import useEditLessonService from 'hooks/lessons/useEditLessonService'
import {
  useGenerateUploadVideoLinks,
  useUploadImage,
  useUploadFile,
  useUploadAudioService,
  useGenerateCustumUploadVideoLinks,
} from 'hooks/helpers/useHelpersService'
import Uploader from 'components/common/FileUploader/Uploader'
import {
  mainColor,
  validationRed,
  red,
} from 'components/common/Colors/CommonColors'
import { useTheme } from '@material-ui/core'
import { triggerVideoConverter } from 'services/mediaEncoding'
import useSnackbarAlert from 'hooks/useSnackbar'

const formData: any = {
  name: '',
  topic: null,
  video: null,
  attachment: null,
  contentDocument: null,
  audio: null,
  duration: null,
  transcriptFile: null,
  subtitle: null,
}

const constants = {
  video:
    'video/x-msvideo|video/mpeg|video/ogg|video/mp4|video/x-flv|video/quicktime|video/x-msvideo|video/x-ms-wmv',
  audio: 'audio/mpeg|audio/mpeg|audio/aac|audio/mp4a-latm',
  file:
    'application/pdf|application/msword|application/plain|text/plain|image/png|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel',
}

const EditLessonDrawer = (props: any) => {
  const { t } = useTranslation()

  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()
  const ref = useRef<any>(null)
  const [file, setFile] = useState<any>('')
  const [videoDuration, setVideoDuration] = useState(0)
  const [uploadVideoProgress, setUploadVideoProgress] = useState(0)
  const [attachment, setAttachment] = useState(null)
  const [subtitles, setSubtitles] = useState(null)
  const [transcript, setTranscript] = useState(null)
  const [fileTypes, setFileTypes] = useState('video')
  const { setSnackbar } = useSnackbarAlert()

  const { editLesson } = useEditLessonService({
    lessonId: props.lesson.id,
  })

  const { generateCustumUploadVideoLinks } = useGenerateCustumUploadVideoLinks()
  const { uploadFile, fileLoading } = useUploadFile()
  const { uploadImage, imageLoading } = useUploadImage()
  const { uploadAudio } = useUploadAudioService()
  const [
    loadTopics,
    { called, loading: getTopicsLoading, data: topicsList },
  ] = useLazyQuery(GET_TOPICS)
  const [currentLesson, setCurrentLesson] = useState<any>('')
  const { data: lessonData, loading } = useQuery(GET_LESSON_BY_ID, {
    variables: {
      lessonId: props.lesson.id,
    },
  })


  const uploadVideoService = (url: string, key?: string) => {
    const formData = new FormData()

    formData.append('file', file)

    return axios
      .put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: ({ total, loaded }) => {
          setUploadVideoProgress((loaded / total) * 100)
          if ((loaded / total) * 100 === 100) {
            setUploadVideoProgress(0)
          }
        },
      })
      .then(res => {
        const message = `Upload success, conversion ${res.data}`
        const variant = 'success'

        setSnackbar({ message, variant })
      })
      .catch(err => {
        const message = `${err}`
        const variant = 'error'
        console.log('err', err)
        setSnackbar({ message, variant })
      })
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: formData,
    // validationSchema: Yup.object(LessonSchema),
    onSubmit: (values: any) => {
      const data = { ...values }

      if (videoDuration) {
        data.duration = videoDuration
      } else {
        delete data.duration
      }

      const topicId = props.lesson.topic
        ? props.lesson.topic.id
        : data.topic.value

      delete data.topic

      if (data.video) {
        const { __typename, ...args } = data.video
        data.video = {
          id: args.id,
          title: args.title,
        }
      }

      if (data.thumbnail) {
        data.thumbnail = data.thumbnail.link
      }

      if (data.attachment) delete data.attachment.__typename

      if (data.audio) delete data.audio.__typename

      if (data.contentDocument) delete data.contentDocument.__typename

      if (data.subtitle) delete data.subtitle.__typename

      if (data.transcriptFile) delete data.transcriptFile.__typename

      editLesson(topicId, props.lesson.id, data, onSuccess)
    },
  })

  useEffect(() => {
    if (lessonData && lessonData.getLessonById) {
      const lesson = lessonData.getLessonById
      const dataClone: any = {}

      const { topic, ...args } = lesson

      for (const key in formData) {
        if (['video', 'audio', 'contentDocument'].includes(key) && args[key]) {
          setFileTypes(key)
        }
        dataClone[key] = args[key]
      }

      dataClone.topic = {
        label: lesson.topicName || props.lesson.topic.name,
        value: lesson.topicId || props.lesson.topic.id,
      }
      // for (let [key] of Object.entries(formData)) {
      //   if (key === 'topic') {
      //     dataClone.topic = {
      //       label:
      //         lesson.topicName || props.lesson.topic.name,
      //       value: lesson.topicId || props.lesson.topic.id,
      //     }
      //   } else {
      //     dataClone[key] = lesson[key]
      //   }
      // }
      if (dataClone.video) {
        dataClone.thumbnail = {
          name: '',
          fileType: '',
          link: dataClone.video.thumbnail,
        }
      }
      setValues(dataClone)
      setCurrentLesson(dataClone)
    }
  }, [lessonData])

  const handleGenerateUploadLink = () => {
    const videos = [{ fileName: file.name, type: file.type }]

    generateCustumUploadVideoLinks(
      videos,
      'lesson',
      (arg: any, key?: string) => uploadVideoService(arg, key),
      (args: any) => {
        setFieldValue('video', args.video)
        setFieldValue('video.title', args.video.name)
        setUploadVideoProgress(0)
      },
    )
  }

  const generateUploadAudioLink = () => {
    const audioFile: any = { fileName: file.name, type: file.type }


    uploadAudio(
      audioFile,
      (arg: any) => uploadVideoService(arg),
      (link: string) => {
        setFieldValue('audio', {
          name: file.name,
          fileType: file.name.split('.').pop(),
          link,
        })

        setUploadVideoProgress(0)
      },
    )
  }

  useEffect(() => {
    if (file && file.type && constants.audio.includes(file.type)) {
      generateUploadAudioLink()
      setFileTypes('audio')
    }

    if (file && file.type && constants.video.includes(file.type)) {
      handleGenerateUploadLink()
      setFileTypes('video')
    }

    if (file && file.type && constants.file.includes(file.type)) {
      uploadFile(file, 'lesson/content', (link: string, fileId: string) =>
        setFieldValue('contentDocument', {
          name: file.name,
          fileType: file.name.split('.').slice(-1)[0],
          link,
        }),
      )
      setFileTypes('contentDocument')
    }
  }, [file])

  const handleContentChange = (e: any) => {
    const file = e.target.files[0]
    if (constants.file.includes(file.type)) {
      setFile(file)
      return
    }

    setUploadVideoProgress(1)
    setFile(file)
    // const reader: any = new FileReader()
    // reader.onload = function() {
    //   const media = new Audio(reader.result)
    //   media.onloadedmetadata = function() {
    setVideoDuration(0)
    //     console.log('media.duration::', media.duration)
    //   }
    // }
    // reader.readAsDataURL(file)
  }

  const handleFileChange = (e: any, field: string) => {
    const { files } = e.target
    const file = files[0]
    file.fileSize = file.size
    const fileType = file.name.split('.').slice(-1)[0]
    uploadFile(file, `lesson/${field}`, (link: string, fileId: String) =>
      setFieldValue(field, {
        name: file.name,
        fileType,
        link,
      }),
    )

    if (field === 'attachment') setAttachment(file)

    if (field === 'transcript') setTranscript(file)

    if (field === 'subtitles') setSubtitles(file)
  }

  const deleteContentFile = () => {
    setFile('')
    setUploadVideoProgress(0)
    setFieldValue('video', null)
    setFieldValue('audio', null)
    setFieldValue('contentDocument', null)
    setFieldValue('subtitle', null)
    setVideoDuration(0)
  }

  const loadDataOptions = (e: any) =>
    setTimeout(() => {
      const value = e
      loadTopics({
        variables: {
          moduleId: props.moduleId,
          filter: {
            name: {
              type: 'match',
              value,
            },
          },
        },
      })
    }, 300)

  const handleSelectChange = (e: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e)
    }
  }
  // const submitDisabled = !(values.videos.length > 0 && (uploadVideoProgress <= 0 || uploadVideoProgress >= 100))

  const onSuccess = () => {
    props.onClose()
    toggleDrawerConfirm(false, '')
  }

  const handleCloseDrawer = () => {
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editLesson',
        values,
        compareTo: currentLesson,
      },
    })
  }

  const handleImageChange = (e: any, field: string) => {
    const { files } = e.target
    const file = files[0]
    const reader: any = new FileReader()
    reader.onload = () => {
      const img: any = new Image()
      img.src = reader.result
      uploadImage(reader.result, `lesson/${field}`, (link: string) =>
        setFieldValue(field, {
          name: file.name,
          link,
          fileType: file.name.split('.').slice(-1)[0],
        }),
      )
    }
    reader.readAsDataURL(files[0])
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  const submitDisabled = !(
    (values.video || values.audio || values.contentDocument) &&
    !fileLoading
  )

  const topicDisabled = !(props.data && props.data.fromTopic)

  const fileExtension =
    fileTypes === 'video'
      ? (values.video && `(${values.video.links[2]?.type})`) || ''
      : (values[fileTypes] && `(${values[fileTypes]?.fileType})`) || ''

  const theme = useTheme()

  return (
    <>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>
          {t('lessons_layout.edit_lesson')}
        </DrawerTitle>

        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent>
        <FormContainer>
          <FormGroupWrapper>
            <FormGroupItem>
              <Input
                error={!!errors.name}
                errorMessage={errors.name ? errors.name : ''}
                touched={touched.name}
                label={`${t('general.name')}*`}
                name="name"
                size="medium"
                type="text"
                fullWidth
                value={values.name}
                onChange={handleChange}
              />
            </FormGroupItem>

            <FormGroupItem>
              <SelectAsync
                error={!!errors.topic}
                errorMessage={errors.topic ? errors.topic : ''}
                touched={touched.topic}
                onInputChange={handleSelectChange}
                onChange={(e: any) => setFieldValue('topic', e)}
                data={(topicsList && topicsList.getTopics) || []}
                value={values.topic}
                label={t('general.topic')}
                loading={getTopicsLoading}
                isClearable
                disabled={topicDisabled}
              />
            </FormGroupItem>

            <FormGroupItem>
              <StyledFromGroupContainer>
                <CourseDetailsContainer>
                  <Uploader
                    label={`${t('actions.upload_content')}*`}
                    onFileChange={handleContentChange}
                    deleteFile={deleteContentFile}
                    type={fileTypes}
                    inputValue={
                      fileTypes === 'video'
                        ? (values[fileTypes] && values[fileTypes].title) || ''
                        : (values[fileTypes] && values[fileTypes].name) || ''
                    }
                    onInputChange={(e: any) => {

                      setFieldValue(
                        fileTypes === 'video'
                          ? 'video.title'
                          : `${fileTypes}.name`,
                        e.target.value,
                      )
                    }
                    }
                    disabled={!values[fileTypes]}
                    uploadedFile={values[fileTypes]}
                    fileExtension={fileExtension}
                    loading={
                      ['video', 'audio'].includes(fileTypes)
                        ? uploadVideoProgress > 0
                          ? uploadVideoProgress
                          : false
                        : fileLoading === 'content'
                    }
                    id="content"
                    accept="audio/*,video/*,.pdf"
                  />
                </CourseDetailsContainer>

                <CourseDetailsContainer>
                  <Uploader
                    label={t('actions.add_transcript')}
                    onFileChange={(e: any) =>
                      handleFileChange(e, 'transcriptFile')
                    }
                    deleteFile={() => {
                      setFieldValue('transcriptFile', null)
                      setTranscript(null)
                    }}
                    type="file"
                    inputValue={
                      (values.transcriptFile && values.transcriptFile.name) ||
                      ''
                    }
                    onInputChange={(e: any) =>
                      setFieldValue('transcriptFile.name', e.target.value)
                    }
                    disabled={!values.transcriptFile}
                    uploadedFile={values.transcriptFile}
                    fileExtension={
                      (values.transcriptFile &&
                        values.transcriptFile.fileType) ||
                      ''
                    }
                    loading={fileLoading === 'transcriptFile'}
                    id="transcriptFile"
                    accept=".pdf"
                  />
                </CourseDetailsContainer>
              </StyledFromGroupContainer>
            </FormGroupItem>

            <FormGroupItem>
              <InputGroup>
                {values.video && (
                  <Uploader
                    label={t('actions.add_thumbnail')}
                    onFileChange={(e: any) => handleImageChange(e, 'thumbnail')}
                    deleteFile={() => {
                      setFieldValue('thumbnail', null)
                    }}
                    type="image"
                    inputValue={
                      (values.thumbnail && values.thumbnail.name) || ''
                    }
                    onInputChange={(e: any) =>
                      setFieldValue('thumbnail', e.target.value)
                    }
                    disabled={!values.thumbnail}
                    uploadedFile={values.thumbnail}
                    fileExtension={
                      (values.thumbnail && values.thumbnail.fileType) || ''
                    }
                    loading={imageLoading}
                    id="thumbnail"
                    accept="image/*"
                  />
                )}

                {/* <Uploader
                  label="Add subtitle to video"
                  accept=".vtt"
                  onFileChange={(e: any) => handleFileChange(e, 'subtitle')}
                  deleteFile={() => {
                    setFieldValue('attachment', null)
                    setAttachment(null)
                  }}
                  type="file"
                  inputValue={
                    (values.attachment && values.attachment.name) || ''
                  }
                  onInputChange={(e: any) =>
                    setFieldValue('attachment.name', e.target.value)
                  }
                  disabled={!values.attachment}
                  uploadedFile={values.attachment}
                  fileExtension={
                    (values.attachment && values.attachment.fileType) || ''
                  }
                  loading={fileLoading === 'attachment'}
                  id="attachment"
                /> */}
              </InputGroup>
            </FormGroupItem>
            <FormGroupItem>
              <Uploader
                label={t('actions.add_attachment')}
                onFileChange={(e: any) => handleFileChange(e, 'attachment')}
                deleteFile={() => {
                  setFieldValue('attachment', null)
                  setAttachment(null)
                }}
                type="file"
                inputValue={(values.attachment && values.attachment.name) || ''}
                onInputChange={(e: any) =>
                  setFieldValue('attachment.name', e.target.value)
                }
                disabled={!values.attachment}
                uploadedFile={values.attachment}
                fileExtension={
                  (values.attachment && values.attachment.fileType) || ''
                }
                loading={fileLoading === 'attachment'}
                id="attachment"
                accept=".pdf"
              />
            </FormGroupItem>
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#f5f5f5"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.save')}
                type="medium"
                color="secondary"
                onClick={handleSubmit}
                isDisabled={submitDisabled}
                background={'#317BF4'}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditLessonDrawer

const StyledFromGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const CourseDetailsContainer = styled.div`
  width: 47%;
  box-sizing: border-box;
`
