import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

import { Container, Wrapper, Content, QuestionTitle } from './styled-components'

import { GET_QUESTION } from 'gql/questions.query'

import QuestionInfo from './QuestionInfo'
import QuestionFiles from './QuestionFiles'
import QuestionAnswers from './QuestionAnswers'
import Breadcrumb from 'components/common/Breadcrumb'

interface RouterParams {
  match: MatchProps
}

interface MatchProps {
  path: string
  url: string
  isExact: boolean
  params: any
}

const QuestionDetails = ({ match }: RouterParams) => {
  const { t } = useTranslation()
  const id = match.params.id || ''
  const hasModule = id.includes('module')

  const { data, loading, refetch, error } = useQuery(GET_QUESTION, {
    variables: {
      questionId: id,
      type: hasModule ? 'module' : '',
    },
  })

  if (loading) return <div>Loading...</div>

  if (error) return <div>Something went wrong</div>

  const question = (data && data.question) || {}

  let breadCrumbData: any = []
  if (data && question) {
    if (question.currentModule) {
      breadCrumbData = [
        { label: t('general.modules'), link: '/modules' },
        {
          label: question.currentModule.name,
          link: `/modules/${question.currentModule.moduleId}`,
        },
        { label: t(`question_details.${question.type.value}`), link: '' },
      ]
    } else {
      breadCrumbData = [
        { label: t('general.questions'), link: '/questions' },
        { label: t(`question_details.${question.type.value}`), link: '' },
      ]
    }
  }

  return (
    <div>
      <Container>
        <Wrapper>
          <QuestionInfo
            type={question.type.label}
            tags={question.tags}
            modules={question.modules}
            documents={question.documents}
            audios={question.audios}
            t={t}
          />
          <Content>
            <QuestionFiles
              images={question.images}
              documents={question.documents}
              videos={question.videos}
              audios={question.audios}
              t={t}
            />
            <QuestionTitle>{question.question}</QuestionTitle>
            <QuestionAnswers
              type={question.type.value}
              boolAnswer={
                question.boolAnswer !== null
                  ? question.boolAnswer.toString()
                  : ''
              }
              answers={question.answers}
              t={t}
            />
          </Content>
        </Wrapper>
      </Container>
    </div>
  )
}

export default QuestionDetails
