import { useMutation } from '@apollo/react-hooks'

import { DELETE_MODULE } from 'gql/modules.query'

const addModuleService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteModuleMutation, { loading, error }] = useMutation(DELETE_MODULE)

  return {
    deleteModuleMutation,
    loading,
    error,
  }
}

export default addModuleService
