import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import DndItem from 'components/common/Grid/DndGrid/DndItem'
import { useHistory } from 'react-router-dom'

import { gql } from 'graphql.macro'

import Checkbox from 'components/common/Checkbox'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'

import ListItem from '@material-ui/core/ListItem'
import Loader from 'components/common/Loader'
import { IconButton } from 'material-ui'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/styles'


interface Props {
  title: string
  config: any
  navigateToTopicDetails: any
  nestedConfig: any
  actions: any
  data: any
  nestedAction: any
  selectAll?: any
  selectedItems?: any
  loading: boolean
  changeTopicsOrder: any
  moveCard?: any
  canModify?: boolean
  t?: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    collapse: {
      background: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 0,
      paddingBottom: 0,
      //   paddingLeft: theme.spacing(6),
      display: 'flex',
      '&:not(:last-child)': {
        borderBottom: '1px solid #080A0A04',
      },
    },
    isVideo: {
      width: 'unset',
      padding: 0,
    },
  }),
)

const CollapseComponent = (props: any) => {
  // const { data, loading } = useQuery(GET_LESSONS, {
  //   variables: {
  //     filter: {
  //       topic: {
  //         type: 'exact',
  //         value: props.topicId
  //       }
  //     }
  //   }
  // })

  const item = props.item || []
  const history = useHistory()

  // if (loading && item.length <= 0) {
  //   return (
  //     <List className={props.classes.nested}>
  //       <ListItem>
  //         <div>
  //           Loading...
  //         </div>
  //       </ListItem>
  //     </List>
  //   )
  // }

  if (item.length <= 0) {
    return (
      <List className={props.classes.nested}>
        <ListItem>
          <div>No Lessons</div>
        </ListItem>
      </List>
    )
  }

  return (
    <>
      {item.map((lesson: any) => (
        <List className={props.classes.nested}>
          {props.nestedConfig.map((nested: any) => (
            <ListItem
              style={{ margin: '10px 0' }}
              onClick={() => history.push(`/lessons/${lesson.id}`)}
              className={nested.id === 'video' ? props.classes.isVideo : ''}
            >

              {nested.render(lesson)}
            </ListItem>
          ))}

          <GridActionMenu actionConfig={props.nestedAction} row={lesson} />
        </List>
      ))}
    </>
  )
}

const CHANGE_ORDER = gql`
  mutation($draggedId: String!, $position: Int!) {
    changeTopicsOrder(draggedId: $draggedId, position: $position)
  }
`

export const NestedGrid = ({
  title,
  config,
  navigateToTopicDetails,
  data,
  actions,
  nestedConfig,
  nestedAction,
  selectAll,
  selectedItems,
  loading,
  changeTopicsOrder,
  moveCard,
  canModify,
  t,
}: // changeTopicsOrder,
  Props) => {
  const classes = useStyles()
  // const [changeTopicsOrder] = useMutation(CHANGE_ORDER)

  const [collapsedItems, setCollapsedItems] = useState<string[]>([])
  const theme = useTheme()

  // const [cards, setCards] = useState(data)
  // const moveCard = useCallback(
  //   async (dragIndex: number, hoverIndex: number) => {
  //     const dragCard = cards[dragIndex]
  //     console.log('dragCard', dragCard)

  //     setCards(
  //       update(cards, {
  //         $splice: [
  //           [dragIndex, 1],
  //           [hoverIndex, 0, dragCard],
  //         ],
  //       }),
  //     )
  //   },
  //   [cards],
  // )

  // useEffect(() => {
  //   if (data) {
  //     if (cards.length === 0 || cards.length !== data.length) {
  //       setCards(data)
  //     }
  //   }
  // }, [data])

  const onColappseAllClick = () => {
    const isOpened = collapsedItems.length === data.length
    if (isOpened) {
      setCollapsedItems([])
    } else {
      const ids = data.map((item: any) => item.id)
      setCollapsedItems(ids)
    }
  }

  const onCollapseClick = (id: string) => {
    let updatedCollapsedItems: string[] = []

    const index = collapsedItems.indexOf(id)

    if (index < 0) {
      updatedCollapsedItems = [...collapsedItems, id]
    } else {
      updatedCollapsedItems = collapsedItems.filter(
        (collapsedItem: string) => collapsedItem !== id,
      )
    }

    setCollapsedItems(updatedCollapsedItems)
  }

  const renderHeadColumn = (item: any) => {
    if (item.id === 'emptyBox') {
      return <div key={item.id} style={{ width: 200 }}></div>
    }

    if (item.id === 'isChecked') {
      return (
        <div key={item.id}>
          <Checkbox
            checked={
              data.length !== 0 && selectedItems.length === data.length
                ? true
                : false
            }
            disabled={data.length === 0}
            onChange={() => selectAll()}
          />
        </div>
      )
    }

    if (item.id === 'name') {
      return (
        <div key={item.id} className="item">
          {/* <CollapseIcon
            style={{
              cursor: 'pointer',
            }}
            onClick={() => onColappseAllClick()}
            className={`${data.length > 0 &&
              collapsedItems.length === data.length &&
              'collapsed'}
              ${data.length === 0 && 'disabled'}
              `}
          >
            <ChevronRightIcon fontSize="small" />
          </CollapseIcon> */}

          {item.label}
        </div>
      )
    }

    return (
      <div key={item.id} className="item">
        {item.label}

      </div>
    )
  }

  const isSelected = (id: string) => selectedItems.includes(id)

  const renderBody = (item: any, index: number) => {
    const isItemSelected = isSelected(item.id)
    item.isChecked = isItemSelected
    return (
      <DndItem
        key={item.id}
        id={item.id}
        index={index}
        moveCard={moveCard}
        cardIds={data.map((card: any) => card.id)}
        onEnd={changeTopicsOrder}
        isNested
      >

        <Row
          onClick={() => navigateToTopicDetails(item.id)}
          style={{ cursor: 'pointer' }}
          theme={theme}
        >

          {config.map((column: any) => {
            return (
              <div
                key={column.id}
                className={`${column.id !== 'isChecked' && column.id !== 'emptyBox'
                  ? 'item'
                  : ''
                  }`}
              >

                {column.id === 'isChecked' && (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row-reverse' }}>

                    <ExpandTopicLessons
                      style={{ cursor: 'pointer' }}
                      onClick={e => {
                        e.stopPropagation()
                        onCollapseClick(item.id)
                      }}
                    >

                      <Icon filename={'dropdownIndicator'} fill={'#5290F6'} className={`${collapsedItems.includes(item.id) ? 'collapsed' : ''}`} />
                    </ExpandTopicLessons>
                    {column.render(item)}
                  </div>
                )}
                {column.id !== 'isChecked' && column.render(item)}
              </div>
            )
          })}

          <GridActionMenu actionConfig={actions} row={item} />
        </Row>

        <Collapse
          in={collapsedItems.includes(item.id) ? true : false}
          className={classes.collapse}
        >

          {collapsedItems.includes(item.id) && (
            <CollapseComponent
              item={item.lessons}
              classes={classes}
              nestedConfig={nestedConfig}
              nestedAction={nestedAction}
              topicId={item.id}
            />
          )}
        </Collapse>
      </DndItem>
    )
  }

  return (
    <Container theme={theme}>
      <List className={classes.root}>
        <h2 style={{ padding: 10 }}>{title}</h2>
        <Header theme={theme}>
          <Row theme={theme}>
            {config.map((item: any) => renderHeadColumn(item))}
            <div style={{ paddingRight: 10 }}>{t('general.actions')}</div>
          </Row>
        </Header>
        <Body theme={theme}>
          {loading && <Loader withBackground={true} />}
          {data &&
            data.map((item: any, index: number) =>
              renderBody(item, index),
            )}{' '}
          {data.length === 0 && (
            <NoRecords>{t('general.no_records')}</NoRecords>
          )}{' '}
        </Body>
      </List>
    </Container>
  )
}

export default NestedGrid

const Container = styled.div`
  color: rgba(0, 0, 0, 0.87);
  margin-top: 20px;
  background-color: ${({ theme }) => theme.palette.background.paper};
`

const Header = styled.header`
  > div {
    padding: 20px;
    font-weight: bold!important;
    background: ${({ theme }) => theme.palette.background.paper};
    border-bottom: 1px solid #080A0A04;
  }
`

const Body = styled.div`
  background: ${({ theme }) => theme.palette.background.paper};
  position: relative;

  > div:not(:last-child) {
    border-bottom: 1px solid #080A0A04;
  }

`

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  background-color: ${({ theme }) => theme.palette.background.paper};

  .item {
    flex: 1;
    padding: 0 20px;
  }
`

const ExpandTopicLessons = styled.div`
    width: 60px;
    height: 60px;
    background-color: #EFF5FE;
    border: 1px solid #080A0A04;
    border-radius: 8px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      transition: transform 0.2s ease;
    }

    svg.collapsed {
      transform: rotate(-180deg);
    }
`
const CollapseIcon = styled.div`
  cursor: pointer;
  position: absolute;
  left: 48px;
  top: 55%;
  transform: translateY(-50%) rotate(0);
  transition: transform 0.2s ease;

  &svg.collapsed {
    transform: translateY(-50%) rotate(90deg);
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`

const NoRecords = styled.div`
  text-align: center;
  padding: 10px 0;
  opacity: 0.5;
`
