import { useTranslation } from 'react-i18next'

const useDownloadFile = () => {
  const { t } = useTranslation()

  const downloadFile = (url: string, fileName: string) => {
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `${fileName}.pdf`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(() => alert(t('general.fileDownloadError')))
  }

  return [downloadFile]
}

export default useDownloadFile