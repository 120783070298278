import { useMutation } from '@apollo/react-hooks'

import { EDIT_MODULE, GET_MODULES } from 'gql/modules.query'
import { updateCache } from 'services/updateCache'

const editModuleService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editModuleMutation, { loading }] = useMutation(EDIT_MODULE, {
    update(cache, { data: { editModule: document } }) {
      updateCache({
        objectName: 'allModules',
        query: GET_MODULES,
        cache,
        document,
        action: 'update',
        params
      })
    },
  })

  return {
    editModuleMutation,
    loading,
  }
}

export default editModuleService
