import React from 'react'
import styled from 'styled-components'

import IconButton from '@material-ui/core/IconButton'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ViewListIcon from '@material-ui/icons/ViewList'
import DeleteIcon from '@material-ui/icons/Delete'
import SyncIcon from '@material-ui/icons/Sync'
import FilterListIcon from '@material-ui/icons/FilterList'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from 'components/common/Checkbox'

import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { Button } from 'components/common/Button'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import TextInput from 'components/common/TextInput/TextInput'
import { GridSwitchButtons } from 'components/common/Cards/styled-components'
import {
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import { mainColor } from 'components/common/Colors/CommonColors'
import { useUserValue } from 'context/UserContext'
import { useTheme } from '@material-ui/core'

interface CurrentModuleParams {
  moduleId: string
  name: string
}

interface Params {
  fromModule?: boolean
  actions?: any
  currentModule?: CurrentModuleParams | undefined
  searchValue: string
  gridStyle: string
  selectedItem?: any
  selectedItemsLength?: number
  handleDeleteClick?: any
  dataLength: number
  selectAllItem?: any
  setGridStyle: (gridStyle: string) => void
  handleSearchChange: (e: any) => void
  handleSearchSubmit?: (e: any) => void
  openFilterDrawer?: () => void
  handleResetClick?: () => void
  filtered?: boolean
  canModify?: boolean
  handleCreateQuizClick?: any
  resetVisible?: boolean
  t?: any
  isQuizButtonInVisible?: boolean
}

const Toolbar = ({
  fromModule = false,
  currentModule,
  actions,
  searchValue,
  gridStyle,
  setGridStyle,
  dataLength,
  selectedItem,
  selectedItemsLength,
  selectAllItem,
  handleDeleteClick,
  handleSearchChange,
  handleSearchSubmit,
  openFilterDrawer,
  handleResetClick,
  filtered,
  canModify,
  handleCreateQuizClick,
  isQuizButtonInVisible,
  resetVisible,
  t,
}: Params) => {
  const [state] = useUserValue()
  const isCompanySuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !!state?.currentUser?.companyId?.id

  const isCompanyAdmin =
    state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  const theme = useTheme()

  return (
    <Header className={currentModule ? '' : `withBorder noMarginBottom`}>
      <Wrapper>
        {fromModule ? (
          <Wrapper>
            {currentModule && canModify ? (
              <GridActionMenu
                actionConfig={actions}
                row={currentModule}
                icon={
                  <Button
                    text={t('general.questions')}
                    size="small"
                    color="secondary"
                    icon={<ArrowDropDownIcon />}
                    iconPosition="right"
                    background={mainColor}
                  />
                }
              />
            ) : (
              <Title style={{ marginRight: 10 }}>
                {t('questions_layout.title')}
              </Title>
            )}
            {canModify && (
              <div style={{ marginLeft: 10, lineHeight: 0 }}>
                <Button
                  text={t('quizzes_layout.create_quiz')}
                  size="small"
                  color="secondary"
                  background={mainColor}
                  onClick={handleCreateQuizClick}
                  isDisabled={dataLength === 0 ? true : false}
                />
              </div>
            )}
            {canModify && (
              <GridMultipleActions
                selectedItems={selectedItem.length}
                actions={[
                  {
                    id: 0,
                    color: 'secondary',
                    tooltipText: t('actions.delete'),
                    disabled: selectedItem.length < 1,
                    onClick: () => handleDeleteClick(selectedItem),
                    component: <DeleteIcon fontSize="small" />,
                  },
                ]}
              />
            )}
            {!isCompanySuperAdmin && !isCompanyAdmin && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      dataLength > 0 && dataLength === selectedItem.length
                        ? true
                        : false
                    }
                    disabled={dataLength === 0}
                    onChange={selectAllItem}
                  />
                }
                label={
                  dataLength === selectedItem.length
                    ? t('general.diselect_all')
                    : t('general.select_all')
                }
              />
            )}
          </Wrapper>
        ) : (
          <Wrapper>
            <Title>{t('questions_layout.title')}</Title>
            <GridMultipleActions
              selectedItems={selectedItem.length}
              actions={[
                {
                  id: 0,
                  color: 'secondary',
                  tooltipText: t('actions.delete'),
                  disabled: selectedItem.length < 1,
                  onClick: () => handleDeleteClick(selectedItem),
                  component: <DeleteIcon fontSize="small" />,
                },
              ]}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dataLength === selectedItem.length ? true : false}
                  disabled={dataLength === 0}
                  onChange={selectAllItem}
                />
              }
              label={
                dataLength === selectedItem.length
                  ? t('general.diselect_all')
                  : t('general.select_all')
              }
            />
          </Wrapper>
        )}

        <Wrapper>
          <MultipleAction theme={theme}>
            <IconButton
              disabled={resetVisible || (!filtered && !searchValue)}
              onClick={handleResetClick}
            >
              <SyncIcon fontSize="small" />
            </IconButton>
            <MultipleActionTooltip>{t('actions.reset')}</MultipleActionTooltip>
          </MultipleAction>
          <MultipleAction theme={theme}>
            <IconButton onClick={openFilterDrawer}>
              <FilterListIcon fontSize="small" style={{ color: mainColor }} />
            </IconButton>
            <MultipleActionTooltip>
              {t('actions.filters')}
            </MultipleActionTooltip>
          </MultipleAction>
          <form
            style={{ marginLeft: 10, marginRight: 10 }}
            onSubmit={handleSearchSubmit}
          >
            <TextInput
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              value={searchValue}
              onChange={handleSearchChange}
              icon={
                <IconButton type="submit">
                  <SearchRoundedIcon />
                </IconButton>
              }
            />
          </form>
          <GridSwitchButtons>
            <button
              className={`${gridStyle === 'card' ? 'active' : ''}`}
              onClick={() => setGridStyle('card')}
            >
              <DashboardIcon />
            </button>
            <button
              className={`${gridStyle === 'list' ? 'active' : ''}`}
              onClick={() => setGridStyle('list')}
            >
              <ViewListIcon />
            </button>
          </GridSwitchButtons>
        </Wrapper>
      </Wrapper>
    </Header>
  )
}

export default Toolbar

const Header = styled.header`
  margin-bottom: 20px;

  &.withBorder {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
    padding-bottom: 10px;
  }

  &.noMarginBottom {
    margin-bottom: 0;
  }

  button.makeStyles-button-247 {
    padding: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
`
