import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import companiesService from '../services/companies/companiesService'
import useSnackbarAlert from '../hooks/useSnackbar'

const useCompaniesService = (params?: any) => {
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [company, setCompany] = useState('')
  const { setSnackbar } = useSnackbarAlert()
  const {
    addCompanyMutation,
    changeCompanyPlatformServiceMutation,
    changeCompanyStatusMutation,
    updateCompanyMutation,
    deleteCompanyMutation,
    addManagerMutation,
    editManagerMutation,
    setCompanyStateMutation,
  } = companiesService(params)

  const addCompany = async (input: any) => {
    let message: string = ''
    let variant:
      | 'default'
      | 'error'
      | 'warning'
      | 'success'
      | 'info'
      | undefined
    const { data, errors } = await addCompanyMutation({
      variables: { input },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.company')} ${t('messages.added')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  const changeCompanyStatus = async (companyId: string, action: string) => {
    let message: string = ''
    let variant:
      | 'default'
      | 'error'
      | 'warning'
      | 'success'
      | 'info'
      | undefined
    const { data, errors } = await changeCompanyStatusMutation({
      variables: { id: companyId, action },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.company')} ${t('general.status')} ${t(
        'messages.changed',
      )}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  const changeCompanyPlatformService = async (
    id: string,
    platformService: boolean,
  ) => {
    let message: string = ''
    let variant:
      | 'default'
      | 'error'
      | 'warning'
      | 'success'
      | 'info'
      | undefined
    const { data, errors } = await changeCompanyPlatformServiceMutation({
      variables: { id, platformService },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.company')} ${t(
        'companies_layout.platform_service',
      )} ${t('messages.changed')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  const updateCompany = async (
    companyId: string,
    company: any,
    callback?: any,
  ) => {
    let message: string = ''
    let variant:
      | 'default'
      | 'error'
      | 'warning'
      | 'success'
      | 'info'
      | undefined
    const { data, errors } = await updateCompanyMutation({
      variables: { id: companyId, input: company },
    })
    if (errors) {
      message = errors[0].message
      // if (message.includes("already registered")) {
      //   setError(message)
      //   return false
      // }
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      setCompany(data.updateCompany)
      message = `${t('general.company')} ${t('messages.edited')}`
      variant = 'success'
      if (callback) callback(data.updateCompany)
    }

    setSnackbar({ message, variant })
  }

  const deleteCompany = async (ids: string[]) => {
    let message: string = ''
    let variant:
      | 'default'
      | 'error'
      | 'warning'
      | 'success'
      | 'info'
      | undefined
    const { data, errors } = await deleteCompanyMutation({
      variables: { ids },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.company')} ${t('messages.deleted')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  const useAddManager = async (
    input: any,
    companyId: string,
    callback: any,
  ) => {
    let message: string = ''
    let variant:
      | 'default'
      | 'error'
      | 'warning'
      | 'success'
      | 'info'
      | undefined

    const { data, errors }: any = await addManagerMutation({
      variables: {
        input,
        companyId,
      },
    })

    if (errors) {
      message = errors[0].message.message
      variant = 'error'
    } else {
      message = t('messages.success')
      variant = 'success'
      callback()
    }

    setSnackbar({ message, variant })
  }

  const useEditManager = async (
    companyId: string,
    userId: string,
    input: any,
    callback: any,
  ) => {
    let message: string = ''
    let variant:
      | 'default'
      | 'error'
      | 'warning'
      | 'success'
      | 'info'
      | undefined

    setError('')

    const { data, errors }: any = await editManagerMutation({
      variables: {
        companyId,
        userId,
        input,
      },
    })

    if (errors) {
      message = errors[0].message.message
      variant = 'error'
      // setError(errors[0].message.message)
    } else {
      message = t('messages.success')
      variant = 'success'
      callback()
    }

    setSnackbar({ message, variant })
  }

  const setCompanyState = async (
    companyId: string,
    state: number,
    successMessage?: any,
    callBack?: any,
  ) => {
    let message: string = ''
    let variant:
      | 'default'
      | 'error'
      | 'warning'
      | 'success'
      | 'info'
      | undefined

    setError('')

    const { errors } = await setCompanyStateMutation({
      variables: {
        companyId,
        state,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
      setSnackbar({ message, variant })
    } else {
      if (successMessage) {
        message = successMessage
        variant = 'success'
        setSnackbar({ message, variant })
      }
      if (callBack) callBack()
    }
  }

  const updateCompanyAvatar =  async (
    companyId: string,
    userId: string,
    input: any,
    callback: any,
  ) => {
    let message: string = ''
    let variant:
      | 'default'
      | 'error'
      | 'warning'
      | 'success'
      | 'info'
      | undefined

    setError('')

    const { data, errors }: any = await editManagerMutation({
      variables: {
        companyId,
        userId,
        input,
      },
    })

    if (errors) {
      message = errors[0].message.message
      variant = 'error'
      // setError(errors[0].message.message)
    } else {
      message = t('messages.success')
      variant = 'success'
      callback()
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteCompany,
    addCompany,
    changeCompanyStatus,
    changeCompanyPlatformService,
    updateCompany,
    company,
    error,
    useAddManager,
    useEditManager,
    setCompanyState,
  }
}

export default useCompaniesService
