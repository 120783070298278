import Icon from "components/Icon";
import React, { FC, useState } from "react";
import * as S from './styled'
import { Text } from '../styled'
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import { Button } from "components/common/Button";
import { mainColor } from "components/common/Colors/CommonColors";
import Modal from "components/common/Modal";
import AudioPlayer from "components/common/AudioPlayer";
import { useTranslation } from 'react-i18next'



interface FlashCardProps {
  flashcards: any
}

const FlashCard: FC<FlashCardProps> = ({ flashcards }) => {

  const [currentFlashCard, setCurrentFlashCard] = useState(0)
  const [isAudio, setIsAudio] = useState(false)
  const [isAnswerVisible, setIsAnswerVisible] = useState(false)
  const { t } = useTranslation()




  const handleChangeFlashCard = () => {
    if (currentFlashCard < flashcards.length - 1) {
      setIsAnswerVisible(false)
      setCurrentFlashCard(currentFlashCard + 1)
    }
  }

  const handleAudioChange = () => {
    setIsAudio(false)
  }


  return (
    <S.SectionItem>
      <FlashCardWrapper>
        <FlashCardCounter>
          <Text fs={'25px'} color={'#080A0A'} weight={'bold'}>{currentFlashCard + 1}/{flashcards.length}</Text>
        </FlashCardCounter>
        <FlashCardContainer bgColor={flashcards[currentFlashCard].color}>
          <FlashCardActions>
            <IconButton onClick={() => setIsAudio(true)}>
              <Icon filename={'flashcard_audio'} />
            </IconButton>
            {/* <IconButton>
              <Icon filename={'flashcard_settings'} />
            </IconButton> */}
          </FlashCardActions>
          <FlashCardContent>
            <Text fs={16} weight={'bold'}>{flashcards[currentFlashCard].question}</Text>
            {isAnswerVisible &&
              <div style={{ marginTop: 20 }}>
                <Text fs={16} weight={'bold'}>
                  {flashcards[currentFlashCard].answer}
                </Text>
              </div>}
          </FlashCardContent>
          <FlashCardAnswer>
            <Button text={t('question_details.answer')}
              textColor={'#fff'}
              size={'medium'}
              background={mainColor}
              buttonStyles={{
                padding: '10px 20px',
                borderRadius: '16px',
              }}
              onClick={() => setIsAnswerVisible(true)}
            />
          </FlashCardAnswer>
        </FlashCardContainer>

        <Button text={t('actions.next')}
          textColor={mainColor}
          size={'medium'}
          background={'#fff'}
          width={116}
          buttonStyles={{
            padding: '10px 20px',
            borderRadius: '16px',
            margin: 20,
          }}
          outline={`1px solid ${mainColor}`}
          onClick={handleChangeFlashCard}
        />

      </FlashCardWrapper>
      <Modal isOpened={isAudio} onClose={handleAudioChange}>
        <div>
          <AudioPlayer url={flashcards[currentFlashCard]?.audio?.link} />
        </div>
      </Modal>

    </S.SectionItem>
  );
}

interface Props {
  bgColor: string;
}

const FlashCardCounter = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
`


const FlashCardAnswer = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`


const FlashCardContent = styled.div`
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

`

const FlashCardContainer = styled.div<Props>`
  width: 370px;
  height: 370px;
  background-color: ${(props) => props.bgColor};
  border: 1px solid #EBEBEB;
  border-radius: 16px;
`

const FlashCardActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const FlashCardWrapper = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  position: relative;
`

export default FlashCard;
