import React, { useEffect } from 'react'
import moment from 'moment'
import {
  useLazyQuery,
  useSubscription,
  useMutation,
  useQuery,
} from '@apollo/react-hooks'
import { DrawerEventEmitter } from 'helpers/drawer'
import styled from 'styled-components'
import {
  GET_NOTIFICATIONS,
  NOTIFICATION_LISTENER,
} from 'gql/notifications/notifications.query'
import {
  useSetNotificationStats,
  userReadNotification,
  useDeleteNotifications,
} from 'hooks/notifications/useNotifications'
import { generateFullName, formatDateAgo, getUserRole } from 'utils/utils'
import strCutter from 'utils/strCutter'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import { useTranslation } from 'react-i18next'
import { GET_REVIEWS } from 'gql/review/review.query'

import { mainColor } from 'components/common/Colors/CommonColors'
import ProfileIcon from 'components/common/AvatarInput/profile.svg'
import { PlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'
import { useTheme } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const NotificationMenu = (props: any) => {
  const { t } = useTranslation()
  let history = useHistory()
  const [
    fetchNotificationData,
    { loading, error, data: getNotificationsResponse },
  ] = useLazyQuery(GET_NOTIFICATIONS)
  const { data: notificationListener } = useSubscription(NOTIFICATION_LISTENER)
  const { setNotificationViewStatus } = useSetNotificationStats()
  const { readNotification } = userReadNotification()
  const { deleteNotifications } = useDeleteNotifications()

  useEffect(() => {
    fetchNotificationData({ variables: { skip: 0, count: 10 } })
  }, [notificationListener])

  useEffect(() => {
    if (
      getNotificationsResponse &&
      getNotificationsResponse.getNotifications.data
    ) {
      const unViewNotifications = getNotificationsResponse.getNotifications.data.filter(
        (i: any) => !i.view,
      )

      if (unViewNotifications.length > 0) {
        setNotificationViewStatus(
          unViewNotifications.map((i: any) => i.id),
          props.notificationBadgeRefetch,
        )
      }
    }
  }, [getNotificationsResponse])

  const handleOpenNotification = (id: any) => {
    DrawerEventEmitter.emit('openDrawer', 'notification', true, id)
  }

  const renderTitle = (notification: any) => {
    if (notification.objectName === 'Rejection') {
      return notification.actionName === 'Post'
        ? `${notification.actionName} By`
        : `${notification.actionName}`
    } else {
      const owner = notification.owner
        ? notification.owner.companyId
          ? 'Company'
          : PlatformTitle
        : ''
      return `${owner} ${getUserRole(
        notification.owner ? notification.owner.role : '',
      )}`
    }
  }

  const notificationList =
    (getNotificationsResponse &&
      getNotificationsResponse.getNotifications.data) ||
    []

  // const { data } = useQuery(GET_REVIEWS, {
  //   variables: {
  //     ratedId: "test",
  //   },
  // })

  // const [fetchReviews, { data }] = useLazyQuery(
  //   GET_REVIEWS,
  //   {
  //     onCompleted: (data: any) => {
  //       if (data !== null && data !== undefined) {
  //         console.log(data, "----------------")
  //       }
  //     }
  //   }

  // )
  const theme = useTheme()
  const handleRedirect = (e: any) => {
    if (
      props.user.role === 'STUDENT' &&
      (e.actionType === 'addCourseToGroup' ||
        e.actionType === 'courseCompleted' ||
        e.actionType === 'publishedCourse')
    ) {
      history.push(`/course/${e.actionId}`)
    } else if (
      props.user.role === 'STUDENT' &&
      e.actionType === 'reviewSkillTestVideoCoach'
    ) {
      history.push(`/course/${e.courseId}/assignment`)
    }
    if (props.user.role === 'COACH' && e.actionType === 'addSkillTestVideo') {
      history.push(`/course/${e.courseId}/assignment/${e.actionId}`)
    }
  }

  return (
    <Container>
      <HeaderContainer>
        <ItemContainer
          theme={theme}
          onClick={() => readNotification([], 'all', fetchNotificationData)}
          className={notificationList.length === 0 ? 'disabled' : ''}
        >
          {t('notifications.mark_all_as_read')}
        </ItemContainer>

        <ItemContainer
          theme={theme}
          onClick={() => deleteNotifications([], 'all', fetchNotificationData)}
          className={notificationList.length === 0 ? 'disabled' : ''}
        >
          {t('notifications.clear_all')}
        </ItemContainer>
      </HeaderContainer>

      <ContentContainer>
        <NotificationList>
          {notificationList.map((i: any) => (
            <NotificationListItem
              key={i.id}
              onClick={() => handleOpenNotification(i.id)}
              bcColor={i.state === 'read' ? '#fff' : 'rgba(236,235,235,0.7)'}
            >
              <ItemListContainer align="center">
                <AvatarContainer>
                  {i.objectName === 'Rejection' ? (
                    <ReportProblemIcon />
                  ) : (
                    <img src={i?.owner?.avatar || ProfileIcon} alt="" />
                  )}
                </AvatarContainer>

                <ItemContent>
                  <TitleContainer>
                    <UserRole>{renderTitle(i)}</UserRole>

                    <NameContainer>
                      {strCutter(generateFullName(i.owner), 15)}
                    </NameContainer>
                  </TitleContainer>
                  <EventContainer>
                    <span>{strCutter(i.message, 28)}</span>
                    {i.objectName !== 'Rejection' && (
                      <NameContainer>
                        {strCutter(i.actionName, 10)}
                      </NameContainer>
                    )}

                    {i.additionalText && <span>{i.additionalText}</span>}
                  </EventContainer>
                </ItemContent>

                <DateContainer>
                  {formatDateAgo(
                    moment(parseInt(i.createDate)).format('DD MMM YYYY h:mm A'),
                  )}
                </DateContainer>
              </ItemListContainer>
            </NotificationListItem>
          ))}
        </NotificationList>
        {notificationList.length === 0 && (
          <NoDataWrapper>No Notifications...</NoDataWrapper>
        )}
      </ContentContainer>

      <FooterContainer theme={theme}>
        <ItemContainer
          theme={theme}
          onClick={handleOpenNotification}
          className={notificationList.length === 0 ? 'disabled' : ''}
        >
          {t('notifications.view_all')}
        </ItemContainer>
      </FooterContainer>
    </Container>
  )
}

export default NotificationMenu

const Container = styled.div`
  width: 370px;
  height: 497px;
  display: grid;
  grid-template-rows: 70px auto 60px;
`

const HeaderContainer = styled.div`
  border-bottom: solid 1px #0000000a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 20px;
  height: max-content;
`
const ItemContainer = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
`

const ContentContainer = styled.div`
  overflow-y: scroll;
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.background.default};
`

const NotificationList = styled.ul`
  margin: 0;
  padding: 0;
`
const NotificationListItem = styled.li<{ bcColor: string }>`
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  background: ${({ bcColor }) => bcColor};
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background: #fff;
  }
`

const AvatarContainer = styled.div`
  img {
    border-radius: 50%;
    width: 37px;
    height: 37px;
  }
`

const ItemListContainer = styled.div<{ align?: string }>`
  width: 100%;
  height: 68px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: ${({ align }) => (align ? align : 'stretch')};
  box-sizing: border-box;
  padding: 10px 0;
  position: relative;
`

const ItemContent = styled.div`
  box-sizing: border-box;
  padding-left: 15px;
`
const NameContainer = styled.div`
  color: ${mainColor};
  font-size: 13px;
  margin-left: 5px;
  white-space: nowrap;
`
const EventContainer = styled.div`
  color: #333;
  font-size: 12px;
  margin-top: 1px;
  display: flex;
  align-items: center;
  span {
    white-space: nowrap;
  }
`

const DateContainer = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`
const UserRole = styled.div`
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.87);
`
const NoDataWrapper = styled.div`
  padding: 10px;
  opacity: 0.6;
`
