

import { useMutation, useQuery } from '@apollo/react-hooks'
import { Paper, useTheme } from '@material-ui/core'
import Grid from 'components/common/Grid'
import { GET_ALL_SUBSCRIPTIONS, UPDATE_SUBSCRIPTION_PRODUCT } from 'gql/subscriptions/subscription.query'
import { DELETE_SUBSCRIPTION } from 'gql/subscriptions/subscription.query'
import React, { useEffect, useState } from 'react'
import { columnConfig } from './GridConfig'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'components/common/Grid/styled-components'
import { useUserValue } from 'context/UserContext'
import Icon from 'components/Icon'

import { DrawerEventEmitter } from 'helpers/drawer'

const TransactionsPage = () => {
  const [state, dispatch] = useUserValue()
  const [selectedItem, setSelectedItem] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [selectAll, setSelectAll] = useState<any[]>([])
  // const { loading, data } = useQuery(GET_ALL_TRANSACTIONS)
  const { loading, data, refetch } = useQuery(GET_ALL_SUBSCRIPTIONS)

  const [transactions, setTransactions] = useState([])
  const { t } = useTranslation()

  const [deleteSubscriptionProduct] = useMutation(DELETE_SUBSCRIPTION)
  const [updateSubscriptionProduct] = useMutation(UPDATE_SUBSCRIPTION_PRODUCT)

  useEffect(() => {
    if (!loading) setTransactions(data?.getSubscriptionProducts.data)
  }, [loading, data])


  const updateProduct = (id: string, input: any) => {

    const filterProduct = data.getSubscriptionProducts.data.find((item: any) => item.id === id)

    filterProduct.activePromo = filterProduct.activePromo

    updateSubscriptionProduct({
      variables: {
        id,
        input: { activePromo: !filterProduct.activePromo }
      }

    }).then(() => {
      setSelectedItem([])
      refetch()
    })

  }
  const selectItem = (id: any) => {
    const selectedItemsOnPage: any[] = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }
  // console.log(selectedItem[1][0], 'selectedItem')

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: any[] = transactions.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const theme = useTheme()
  let config = columnConfig(
    // state.currentUser.role,
    // handleClick,

    selectItem,
    theme,
    t,
  )
  const handleDeleteClick = (id: string) => {
    deleteSubscriptionProduct({
      variables: {
        id,
      },
    }).then(() => {
      setSelectedItem([])
      refetch()
    })
  }

  const handleAddClick = () => {
    DrawerEventEmitter.emit('openDrawer', 'addSubscription', true, {
      title: `${t('general.addSubscription')}`,
      data: {
        sectionInfo: 'test',
        type: 'add',
        onSuccess: () => {
          setSelectedItem([])
          refetch()
        },
      },
    })
  }

  return (
    <Paper
      style={{
        padding: '20px',
      }}
    >
      {!loading && data?.getSubscriptionProducts.data && (
        <Grid
          title={t('general.subscriptions')}
          config={config}
          data={transactions}
          actionConfig={[]}
          transactions={true}
          selectAllItem={selectAllItem}
          selectedItems={selectedItem[currentPage] || []}
          actions={() => [
            {
              id: 0,
              color: 'secondary',
              tooltipText: `${t('actions.delete')}`,
              hide: !state.userPermission.deleteGroup,
              disabled: selectedItem[currentPage]
                ? selectedItem[currentPage].length < 1
                : true,
              onClick: () => handleDeleteClick(selectedItem[1][0]),
              component: (
                <ActionButton
                  // disabled={(selectedItem?.length || 0) === 0}
                  disabled={
                    selectedItem[currentPage]
                      ? selectedItem[currentPage].length < 1
                      : true
                  }
                  fill={'#269F5A'}
                  disabledFill={'#080A0AD6'}
                  theme={theme}
                >
                  <Icon filename={'delete'} />
                </ActionButton>
              ),
            },
            {
              id: 1,
              color: 'secondary',
              tooltipText: `${t('actions.addSubscription')}`,
              hide: !state.userPermission.deleteGroup,

              onClick: () => handleAddClick(),
              component: (
                <ActionButton
                  disabled={
                    selectedItem[currentPage] &&
                    !!selectedItem[currentPage].length
                  }
                  fill={'#269F5A'}
                  disabledFill={'#080A0AD6'}
                  theme={theme}
                >
                  <Icon filename={'add'} />
                </ActionButton>
              ),
            },
          ]}
        />
      )}
    </Paper>
  )
}

export default TransactionsPage
