import { GET_COMMON_COURSES } from '../../gql/user.profile.query';
import { useQuery } from '@apollo/react-hooks'

export const useFetchCommonCourses = (userId: string, currentPage: null | number = 1, perPage: null | number = 3, isDone: boolean) => {
  const { data, loading, error, refetch, fetchMore, updateQuery } = useQuery(GET_COMMON_COURSES, {
    variables: {
      userId,
      currentPage,
      perPage,
      isDone
    },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    courses: data && data.getCommonCourses.data,
    courseCount: data && data.getCommonCourses.courseCount,
    loading,
    error: errorMessage,
    refetch,
    fetchMore,
    updateQuery
  }
}
