import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SelectField } from 'components/common/SelectField'
import useEditGroup from 'hooks/group/useEditGroup'
import { useQuery } from '@apollo/react-hooks'
import { GET_GROUP } from 'gql/group/group.query'
import {
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import { Button } from 'components/common/Button'
import { useTranslation } from 'react-i18next'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { FormField } from 'components/common/Form'
import { Label } from 'components/common/Form/Form'
import { GET_COURSE } from 'gql/course/course.query'
import { ActionMeta } from 'react-select'

interface Props {
  values: any
  selectedGroup: any
  courseId?: string
  handleDeleteCourse?: any
  handleButtonClick?: any
  handleChangeStep?: any
}

const AssignComponent = ({ item, coaches, assignCoach, courseId }: any) => {
  const { t } = useTranslation()
  const [coach, setCoach] = useState<object[]>([])

  const { data, loading, refetch } = useQuery(GET_COURSE, {
    variables: {
      courseId,
    },
  })

  const group = useQuery(GET_GROUP, {
    variables: {
      filter: {
        query: {
          type: 'query',
          value: JSON.stringify({
            'courses.courseId': { $in: [courseId] },
          }),
        },
      },
      perPage: 0,
    },
  })




  const course = data?.getCourseById;


  useEffect(() => {

    if (!loading && !group.loading) {


      // if (course && course.coaches) {
      //   setCoach([{ value: course.coaches[0].id, label: `${course.coaches[0].firstName} ${course.coaches[0].lastName}` }])
      // }

    }
  }, [data, group.data])

  return (
    <>
      <Container>
        <Wrapper width="70%" style={{ paddingBottom: '80px' }}>
          {!loading && !group.loading && (
            <ContentContainer>
              <Card>
                {/* <div className="title">{item.name}</div> */}

                <FormField
                  style={{ position: 'relative', marginBottom: '0px' }}
                  width="100%"
                >
                  <Label>{`${t('general.group')}*`}</Label>

                  <div
                    style={{
                      pointerEvents: 'none',
                    }}
                  >
                    <SelectField
                      placeholder={t('general.coach')}
                      options={{ value: item.name, label: item.name }}
                      name="group"
                      value={{ value: item.name, label: item.name }}
                      isClearable={false}
                      disabled={true}
                    />
                  </div>
                </FormField>

                <FormField style={{ position: 'relative' }} width="100%">
                  <Label>{`${t('general.coaches')}*`}</Label>

                  <SelectField
                    placeholder={`${t('general.coaches')}*`}
                    name="coaches"
                    options={coaches}
                    value={coach}
                    // onChange={(e: any) => setFieldValue('group', e)}
                    isClearable={false}
                    onChange={(e: any, action: ActionMeta<any>) => {
                      setCoach(e)


                      if (action.action === 'select-option')
                        assignCoach(
                          item.id,
                          e
                            ? {
                              name: e.label,
                              coachId: e.value,
                              avatar: null,
                            }
                            : null,
                          courseId,
                          () => { },
                          t,
                        )
                      refetch();
                    }}
                  />
                </FormField>
              </Card>
            </ContentContainer>
          )}
        </Wrapper>
      </Container>
    </>
  )
}

const AssignCoach = (props: Props) => {
  const { t } = useTranslation()
  const { assignCoach } = useEditGroup()


  const { data } = useQuery(GET_GROUP, {
    variables: {
      filter: {
        query: {
          type: 'query',
          value: JSON.stringify({
            'courses.courseId': { $in: [props.courseId] },
          }),
        },
      },
      perPage: 0,
    },
  })
  // const groups = (data && data.getAllGroups.data) || []

  const groups = props.selectedGroup || []


  const coaches = props.values.coaches || []



  return (
    <>
      <FormGroupWrapper padding={10}>
        <StyledItemContainer>
          {/* {_.uniqBy(groups, (obj: any) => obj.groupId).map((i: any) => ( */}
          {data?.getAllGroups?.data?.map((i: any) => (
            <AssignComponent
              item={i}
              coaches={coaches}
              assignCoach={assignCoach}
              courseId={props.courseId}
            />
          ))}
        </StyledItemContainer>
      </FormGroupWrapper>

      <FormFooter>
        <FormButtons multiCols={true}>
          <Button
            text={t('actions.cancel')}
            type="medium"
            onClick={props.handleDeleteCourse}
            background="#F5F5F5"
            textColor={validationRed}
            outline={`1px solid ${red}`}
            buttonStyles={{
              borderRadius: '8px',
            }}
          />

          <Button
            text={t('actions.previous')}
            type="medium"
            color="secondary"
            onClick={() => props.handleChangeStep(1)}
            background="#E0E1E2"
            textColor="#414141"
            buttonStyles={{
              borderRadius: '8px',
            }}
          />

          {props.values.finished === 2 && (
            <Button
              text={t('actions.save_as_draft')}
              type="medium"
              color="secondary"
              onClick={() => props.handleButtonClick('draft')}
              background="#ffffff"
              textColor="#080A0A8A"
              outline="1px solid #080A0A14"
              buttonStyles={{
                borderRadius: '8px',
              }}
            />
          )}

          <Button
            text={
              props.values.finished === 3
                ? t('actions.save')
                : t('actions.finish')
            }
            type="medium"
            color="secondary"
            onClick={() => props.handleButtonClick('next')}
            background={'#317BF4'}
            buttonStyles={{
              borderRadius: '8px',
            }}
          />
        </FormButtons>
      </FormFooter>
    </>
  )
}

export default AssignCoach

const StyledItemContainer = styled.ul`
  margin-top: 20px;
  padding: 0;
`
const StyledItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 70px;
  margin-top: 20px;
`

const StyledTitle = styled.div``

const ItemContainer = styled.div`
  width: 210px;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  & > div:first-child {
    box-shadow: 0px 0px 30px #0000001a;
  }
`

const Card = styled.div`
  width: 670px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 1px solid #080a0a29;
  border-radius: 8px;
  padding: 30px;
  gap: 30px;

  .title {
    color: #080a0ad6;
    font-size: 20px;
    font-weight: 600;
  }
`

export const Container = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #ebebeb;
  border-top: none;
`
export const Wrapper = styled.div<{ width: string; height?: string }>`
  width: ${({ width }) => width};
  background: #fff;
  ${({ height }) => (height ? `height: ${height}` : undefined)};
`
export const FooterWrapper = styled.div`
  width: 100%;
  margin: auto;
  padding: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    border-radius: 8px !important;
  }
`
