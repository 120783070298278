import React, { useState } from 'react'
import { useUserValue } from 'context/UserContext'
import { useFetchCompanies, PER_PAGE } from 'hooks/companies/useCompanies'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'

import useDeleteCompanyService from 'hooks/companies/useCompanyDeleteService'
import useChangeCompanyStatusService from 'hooks/companies/useChangeCompanyStatusService'

import { DrawerEventEmitter } from 'helpers/drawer'

import { SET_SELECTED_COMPANY_ID } from 'store/types'
import { useHistory } from 'react-router-dom'
import { actionConfig, columnConfig } from './gridConfig'

import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import Loader from 'components/common/Loader'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import { Button } from 'components/common/Button'
import Drawer from 'components/common/Drawer'
import FilterCompanies from './FilterCompanies/FilterCompanies'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import ArchiveIcon from '@material-ui/icons/Archive'
import CompanyList from './companyList'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import styled from 'styled-components'
import SyncIcon from '@material-ui/icons/Sync'
import FilterListIcon from '@material-ui/icons/FilterList'
import { Input } from 'components/common/TextInput'
import Pagination from 'components/common/Pagination'
import { mainColor } from 'components/common/Colors/CommonColors'
import Grid from 'components/common/Grid'
import _ from 'lodash'
import TablePagination from 'components/common/Pagination/TablePagination'
import {
  GridToolbar,
  GridLayoutActions,
  GridLayoutForm,
  MultipleAction,
  MultipleActionTooltip,
  ActionButton,
} from 'components/common/Grid/styled-components'
import Search from 'components/common/Search'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

const toolbarStyle = {
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',
}

const CompaniesLayout = () => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [filterOptions, setFilterOptions] = useState(null)
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [fetchAll, setFetchAll] = useState(false)
  const [fetchAllPagination, setFetchAllPagination] = useState([0, 10])
  const [page, setPage] = useState(1)
  const {
    companies,
    loading,
    refetch,
    error,
    fetchMore,
  }: any = useFetchCompanies(currentPage, perPage, filterQuery)


  const {
    companies: allComapanies, }: any = useFetchCompanies(currentPage, perPage, filterQuery, true)

  const { deleteCompany, deleteCompanyLoading } = useDeleteCompanyService({
    filter: {},
  })
  const { exportData } = useExportDataService()

  const {
    changeCompanyStatus,
    changeCompanyStatusLoading,
  } = useChangeCompanyStatusService()

  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>([])
  const [filterDrawerOpened, setFilterDrawerOpened] = useState(false)
  const [resetVisible, setResetVisible] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const { fireSwal } = useSwal()

  const selectItem = (id: never) => {


    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = allComapanies.data.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formValue: any = {
      name: { type: 'search', value: searchValue, fields: ["name"] },
    }
    setCurrentPage(1)
    setFilterQuery({ ...filterQuery, ...formValue })
    refetch({
      currentPage: 1,
      perPage,
      filter: filterQuery,
    })
    setResetVisible(true)
  }

  const handleSearchChange = (e: any) => {
    setSearchValue(e.currentTarget.value)
  }

  const handleDeleteAllClick = () => {
    fireSwal({
      title:
        selectedItem?.length === 1
          ? `${t('popups.delete_many')} ${t('navigation.company')}?`
          : `${t('popups.delete_many')} ${t('navigation.companies')}?`,
      onConfirm: () => confirmDelete(selectedItem),
      confirmText: t('popups.confirm_delete'),
    })
  }

  const handleEditClick = (companyId: string, status: any) => {
    if (status === 'DRAFT') {
      history.push(`/create-company/${companyId}`)
      return
    }
    DrawerEventEmitter.emit('openDrawer', 'companiesEdit', true, {
      id: companyId,
      data: () => { },
    })
  }

  const confirmChangeStatus = async (ids: string[], action: string) => {
    changeCompanyStatus(ids, action)
  }

  const handleChangeStatusClick = (companyId: string, status: string) => {
    let action: string = ''
    if (status === 'ACTIVE') {
      action = 'suspend'
      fireSwal({
        title: 'Are you sure you want to deactivate this company?',
        onConfirm: () => {
          confirmChangeStatus([companyId], action)
        },
        confirmText: t('popups.confirm_suspend'),
      })
    } else {
      action = 'active'
      fireSwal({
        title: 'Are you sure you want to activate this company?',
        onConfirm: () => {
          confirmChangeStatus([companyId], action)
        },
        confirmText: t('popups.confirm_activate'),
      })
    }
  }

  const confirmDelete = async (ids: string[]) => {
    deleteCompany(ids, refetch)
    setSelectedItem([])
    dispatch({
      type: SET_SELECTED_COMPANY_ID,
      payload: null,
    })
  }

  const handleDeleteClick = (id: string) => {
    const params = {
      title: `${t('popups.delete_single')}   ${t('general.company')}?`,
      onConfirm: () => {
        confirmDelete([id])
      },
      confirmText: t('popups.confirm_delete'),
    }
    fireSwal(params)
  }

  const handleSwitchCompanyClick = (name: string, id: string) => {
    history.push(`/companies/${id}`)
  }

  const handleFiltersClick = () => {
    setFilterDrawerOpened(true)
  }

  const filter = (filters: any) => {
    refetch({
      currentPage: 1,
      perPage,
      filter: filters,
    })
    setFilterQuery(filters)
    if (companies) {
      setFilterDrawerOpened(false)
      setResetVisible(true)
    }
  }

  const handleResetClick = () => {
    refetch({
      currentPage: 1,
      perPage,
      filter: {},
    })
    setCurrentPage(1)
    setFilterQuery({})
    setFilterOptions(null)
    setSearchValue('')
    setResetVisible(false)
  }

  const handleExportData = () => {

    if (selectedItem[currentPage].length > 0) {
      exportData('company', selectedItem[currentPage], (link: string) => {
        window.open(link, '_blank')
      })
    }
  }

  const handleCloseFilterDrawer = (action: string) => {
    if (action === 'reset') {
      handleResetClick()
      setFilterDrawerOpened(false)
    } else {
      setFilterDrawerOpened(false)
    }
  }

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {

    if (((page - newPage) === 1 || (page - newPage) === 0) && fetchAll) {
      setFetchAllPagination(prevState => [prevState[0] + 10, prevState[1] + 10])
      setPage(page + 1)
      return
    } else if (((page > newPage)) && fetchAll) {
      setFetchAllPagination(prevState => [prevState[0] - 10, prevState[1] - 10])
      setPage(page - 1)
      return
    }

    refetch({
      filter: filterQuery,
      currentPage: newPage + 1,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    refetch({
      // role,
      filter: filterQuery,
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
    setSelectedItem({})
  }

  const actions = actionConfig(
    handleEditClick,
    handleDeleteClick,
    handleChangeStatusClick,
    handleSwitchCompanyClick,
    state.userPermission,
    t,
  )

  const handleClick = (name: string, id: string, status: string) => {
    status === 'ACTIVE' && history.push(`/companies/${id}`)
    status === 'DRAFT' && history.push(`/create-company/${id}`)
  }
  const theme = useTheme();

  const config = columnConfig(
    state.currentUser.role,
    handleClick,
    selectItem,
    t,
    theme
  )

  let toolbarAction = [
    {
      id: 0,
      color: 'secondary',
      tooltipText: `${t('actions.delete')}`,
      disabled: selectedItem.length < 1,
      onClick: () => handleDeleteAllClick(),
      component: <DeleteIcon fontSize="small" />,
    },
    // {
    //   id: 1,
    //   color: 'secondary',
    //   tooltipText: 'Suspend',
    //   disabled: selectedItem.length < 1,
    //   onClick: () => handleSuspendAllClick(),
    //   component: <BackspaceRoundedIcon fontSize="small" />,
    // },
    // {
    //   id: 2,
    //   color: 'primary',
    //   tooltipText: 'Activate',
    //   disabled: selectedItem.length < 1,
    //   onClick: () => handleActivateAllClick(),
    //   component: <CheckCircleIcon fontSize="small" />,
    // },
    {
      id: 2,
      color: 'primary',
      tooltipText: `${t('actions.export')}`,
      disabled: selectedItem.length < 1,
      onClick: () => handleExportData(),
      component: (
        <ArchiveIcon
          fontSize="small"
          style={{ color: selectedItem.length < 1 ? '' : mainColor }}
        />
      ),
    },
  ]

  const handleDeleteCompany = (id?: string) => {
    let ids = selectedItem[currentPage]

    if (id) ids = [id]

    if (ids.length <= 0) return

    const params = {
      title:
        ids.length > 1
          ? `${t('popups.delete_many')} ${t('general.groups')}?`
          : `${t('popups.delete_single')} ${t('general.group')}?`,
      onConfirm: () => {
        deleteCompany(ids, refetch)
        setSelectedItem([])
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }


  if (state.currentUser.role === 'MODERATOR') {
    toolbarAction = []
  }
  return (
    <Container theme={theme}>

      <GridToolbar>
        <GridLayoutActions>
          <div>
            {state.currentUser.role === 'SUPER_ADMIN' && (
              <AddButton onClick={() => history.push('/create-company')}>
                <Icon filename={'add'} fill={'#ffffff'} />
                {t('companies_layout.create_company')}
              </AddButton>
            )}
          </div>
        </GridLayoutActions>
        <GridLayoutForm>
          <ActionButton
            onClick={handleResetClick}
            disabled={resetVisible ? false : true}
            theme={theme}
          >
            <SyncIcon fontSize="small" />
          </ActionButton>
          <MultipleAction theme={theme}>
            <ActionButton theme={theme} onClick={handleFiltersClick}>
              <Icon filename={'filter'} fill={theme.palette.text.primary} />
            </ActionButton>
            <MultipleActionTooltip>
              {t('actions.filters')}
            </MultipleActionTooltip>
          </MultipleAction>
          <Search
            inputProps={{
              value: searchValue,
              onChange: handleSearchChange,
            }}
            onClick={handleSearchSubmit}
          />
        </GridLayoutForm>
      </GridToolbar>
      {(changeCompanyStatusLoading || deleteCompanyLoading) && <Loader />}
      {companies && (
        <Grid
          fetchAll={fetchAll}
          setFetchAll={setFetchAll}
          title={t('companies_layout.title')}
          onFiltersClick={handleFiltersClick}
          resetVisible={resetVisible}
          resetFilters={handleResetClick}
          config={config}
          data={fetchAll ? allComapanies?.data?.slice(...fetchAllPagination) : companies.data}

          actionConfig={actions}
          selected={_.size(selectedItem[currentPage])}
          selectAllItem={selectAllItem}
          selectedItems={selectedItem[currentPage] || []}
          actions={() => [
            {
              id: 0,
              color: 'secondary',
              tooltipText: `${t('actions.delete')}`,
              hide: !state.userPermission.deleteGroup,
              disabled: selectedItem[currentPage]
                ? selectedItem[currentPage].length < 1
                : true,
              onClick: () => handleDeleteCompany(),
              component: (
                <ActionButton
                  disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
                  fill={'#CC3131'}
                  disabledFill={'#080A0AD6'}
                  theme={theme}
                >
                  <Icon filename={'delete'} />
                </ActionButton>
              ),
            },
            {
              id: 0,
              color: 'secondary',
              tooltipText: `${t('actions.export')}`,
              hide: !state.userPermission.deleteGroup,
              disabled: selectedItem[currentPage]
                ? selectedItem[currentPage].length < 1
                : true,
              onClick: () => handleExportData(),
              component: (
                <ActionButton
                  disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
                  fill={'#269F5A'}
                  disabledFill={'#080A0AD6'}
                  theme={theme}
                >
                  <Icon filename={'download'} />
                </ActionButton>
              ),
            },
          ]}
        // actions={multiActions}
        // allSelected={allSelected}
        />
      )}
      {companies && companies.data && (
        <TablePagination
          currentPage={companies && !fetchAll ? companies.currentPage : allComapanies && fetchAll ? page : 0}

          // currentPage={page}
          rowsPerPage={perPage}
          count={companies ? companies.totalCount : 0}
          handleChangePage={handlePaginationClick}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <FilterCompanies
          filter={filter}
          filterLoading={loading}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          onClose={handleCloseFilterDrawer}
          setCurrentPage={setCurrentPage}
        />
      </Drawer>
    </Container>
  )
}

export default CompaniesLayout

const Title = styled.div`
  font-size: 23px;
  font-weight: 500;
`
const ToolbarItemWrapper = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

const Container = styled.div`
  overflow: hidden;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  height: 100%;
`

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #317bf4;
  padding: 18px 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    background: #3165f4;
  }
`
