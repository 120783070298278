import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import {
  CloseIcon,
  DrawerHeader,
  DrawerTitle,
  Info,
} from 'components/DrawerLayout/styled-components'
import { useLazyQuery, useSubscription } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

import {
  GET_NOTIFICATIONS,
  NOTIFICATION_LISTENER,
} from 'gql/notifications/notifications.query'
import {
  userReadNotification,
  useDeleteNotifications,
} from 'hooks/notifications/useNotifications'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import NotificationList from './NotificationList'
import { mainColor } from 'components/common/Colors/CommonColors'
import { ActionButton } from 'components/common/Grid/styled-components'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

const NotificationLayout = (props: any) => {
  const { t } = useTranslation()
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [skip, setSkip] = useState<number>(0)
  const [count] = useState<number>(10)
  console.log(props, 'notprops')
  const { data: notificationListener } = useSubscription(NOTIFICATION_LISTENER)
  const [
    fetchNotificationData,
    { loading, error, data: getNotificationsResponse, fetchMore },
  ] = useLazyQuery(GET_NOTIFICATIONS)
  const { readNotification } = userReadNotification()
  const { deleteNotifications } = useDeleteNotifications()

  useEffect(() => {
    fetchNotificationData({ variables: { skip: 0, count: 10 } })
  }, [notificationListener])

  const selectItem = (id: string) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    setSelectedItem(newSelected)
  }

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        skip: skip + count,
        count,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newSkip = fetchMoreResult.getNotifications.data.length + skip
        setSkip(newSkip)
        if (!fetchMoreResult.getNotifications.data) return prev
        return Object.assign({}, prev, {
          getNotifications: {
            ...prev.getNotifications,
            totalCount: fetchMoreResult.getNotifications.totalCount,
            hasMore: fetchMoreResult.getNotifications.hasMore,
            data: [
              ...prev.getNotifications.data,
              ...fetchMoreResult.getNotifications.data,
            ],
          },
        })
      },
    })
  }

  const notificationList =
    (getNotificationsResponse &&
      getNotificationsResponse.getNotifications.data) ||
    []
  const hasMore =
    (getNotificationsResponse &&
      getNotificationsResponse.getNotifications.hasMore) ||
    false

  const theme = useTheme()

  return (
    <Container>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{t('notifications.title')}</DrawerTitle>
        <CloseIcon
          onClick={props.onClose}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
      </DrawerHeader>

      <ContentWrapper>
        <Toolbar styles={{ justifyContent: 'space-between' }}>
          <ToolbarItem>
            <GridMultipleActions
              selectedItems={selectedItem.length}
              actions={[]}
            />
          </ToolbarItem>

          <ToolbarItemContainer>
            <ToolbarItem>
              <div>{t('notifications.clear_all')}</div>
              <ActionButton
                fill={'#CC3131'}
                style={{ marginLeft: '10px' }}
                onClick={() =>
                  deleteNotifications([], 'all', () =>
                    fetchNotificationData({
                      variables: { skip: 0, count: skip + count },
                    }),
                  )
                }
                theme={theme}
              >
                <Icon filename={'delete'} />
              </ActionButton>
            </ToolbarItem>
            <ToolbarItem style={{ marginLeft: '30px', cursor: 'pointer' }}>
              <div
                onClick={() =>
                  readNotification(selectedItem, 'all', () =>
                    fetchNotificationData({
                      variables: { skip: 0, count: skip + count },
                    }),
                  )
                }
              >
                {t('notifications.mark_all_as_read')}
              </div>
            </ToolbarItem>
          </ToolbarItemContainer>
        </Toolbar>

        <NotificationList
          notificationList={notificationList}
          selectedItem={selectedItem}
          selectItem={selectItem}
          selectAll={selectAll}
          id={props.id}
        />
        {notificationList.length === 0 && <div>No Notifications...</div>}
        {hasMore && (
          <LoadMoreWrapper>
            {!loading && (
              <Button
                variant="outlined"
                style={{
                  color: mainColor,
                  border: '1px solid #317BF4',
                }}
                onClick={handleLoadMore}
              >
                {t('general.load_more')}
              </Button>
            )}
            {loading && <CircularProgress />}
          </LoadMoreWrapper>
        )}
      </ContentWrapper>
    </Container>
  )
}

export default NotificationLayout

const Container = styled.div``
const ToolbarItemContainer = styled.div`
  display: flex;
  align-items: center;
`

const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px 20px 20px 15px;
`

const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  .MuiCircularProgress-root {
    width: 30px !important;
    height: 30px !important;
  }
`
