import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useTranslation } from 'react-i18next'
import { useSwal } from 'hooks/useSwal'
import { useData } from 'context/DataContext'
import { useAction } from 'store/actions'
import Drawer from 'components/common/Drawer'
import { EditGroupDrawer } from 'pages/group'
import { CompaniesWizard, CompaniesEdit } from 'pages/companies'
import { AddStudent, EditStudent } from 'pages/students'
import { AddCoach, EditCoach } from 'pages/coaches'
import { AddAdmin, EditAdmin } from 'pages/admins'
import { AddModerator, EditModerator } from 'pages/moderators'
import { AddModule } from 'components/modules'
import {
  AddCourseDrawer,
  EditCourseDrawer,
  AddCourseModuleDrawer,
} from 'pages/courses'
import { AddTopicDrawer } from 'pages/modules'
import { EditTopicDrawer } from 'pages/topics'
import { EditModuleDrawer } from 'pages/modules'
import { AddLessonDrawer, EditLessonDrawer } from 'pages/lessons'
import {
  CreateQuestionDrawer,
  EditQuestionDrawer,
  LinkQuestionsToModuleDrawer,
} from 'pages/questions'
import {
  AddQuizDrawer,
  EditQuizDrawer,
  AddQuestionsToQuiz,
} from 'pages/quizzes'
import { NotificationLayout } from 'pages/notifications'
import {
  AddFlashCardDrawer,
  EditFlashCardDrawer,
} from 'pages/modules/FlashCards'
import PopulateSectionDrawer from 'pages/sections/PopulateSectionDrawer/PopulateSectionDrawer'

import SubscriptionDrawer from 'pages/subscriptions/SubscriptionDrawer'
import EditUserSubmission from 'pages/students/EditStudent/editUserSubmission'

const DrawerLayout = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()
  const { fireSwal } = useSwal()
  const [args, setArgs] = useState<any>({
    id: '',
    role: '',
    data: {},
    type: '',
  })

  const [drawer, setDrawer] = useState({
    groupInformation: false,
    companyAdd: false,
    companiesEdit: false,
    addModerator: false,
    addAdmin: false,
    addCoach: false,
    addStudent: false,
    editStudent: false,
    editCoach: false,
    editAdmin: false,
    editModerator: false,
    filterCoach: false,
    addCourses: false,
    editCourse: false,
    addModule: false,
    addCourseModule: false,
    editModuleDrawer: false,
    addTopic: false,
    editTopic: false,
    addLesson: false,
    editLesson: false,
    createQuestion: false,
    editQuestion: false,
    linkQuestionsToModule: false,
    addQuiz: false,
    editQuiz: false,
    AddQuestionsToQuiz: false,
    notification: false,
    addFlashCard: false,
    editFlashCard: false,
    rejectionDetails: false,
    addSubscription: false,
    populateSection: false,
    editSection: false,
    editUserSubmission: false
  })

  const handleDrawerEvent = (field: string, event: boolean, args?: any) => {
    if (args) {
      setArgs(args)
    }

    setDrawer({
      ...drawer,
      [field]: event,
    })
  }

  useEffect(() => {

    const isEmpty = _.values(state.formData).every(_.isEmpty)
    if (!isEmpty) {
      const { type, values, compareTo, drawer } = state.formData
      const isEqual = _.isEqual(values, compareTo)
      const isEmpty = _.values(values).every(_.isEmpty)
      if (type === 'add' && isEmpty) {
        dispatch({
          type: 'CLEAR_FORMDATA',
          payload: true,
        })
        handleDrawerEvent(drawer, false)
      }

      if (type === 'add' && !isEmpty) {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            handleDrawerEvent(drawer, false)
            toggleDrawerConfirm(false, '')
            dispatch({
              type: 'CLEAR_FORMDATA',
              payload: true,
            })
          },
          onClose: () => {
            dispatch({
              type: 'CLEAR_FORMDATA',
              payload: true,
            })
          },
          confirmText: t('popups.confirm_cancel'),
          cancelText: t('general.no'),
        })
      }

      if (type === 'edit' && isEqual) {
        dispatch({
          type: 'CLEAR_FORMDATA',
          payload: true,
        })
        handleDrawerEvent(drawer, false)
      }

      if (type === 'edit' && !isEqual) {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            handleDrawerEvent(drawer, false)
            toggleDrawerConfirm(false, '')
            dispatch({
              type: 'CLEAR_FORMDATA',
              payload: true,
            })
          },
          onClose: () => {
            dispatch({
              type: 'CLEAR_FORMDATA',
              payload: true,
            })
          },
          confirmText: t('popups.confirm_cancel'),
          cancelText: t('general.no'),
        })
      }
    }
  }, [state.formData])

  useEffect(() => {

    DrawerEventEmitter.addListener('openDrawer', handleDrawerEvent)
  }, [])

  return (
    <>
      {/* {drawers.map((drawerItem: Props) => (
        <Drawer
          opened={drawer[drawerItem.drawer]}
          toggleDrawer={() => handleDrawerEvent(drawerItem.name, false)}
          totalWidth={drawerItem.width}
        >
          {drawerItem.component}
        </Drawer>
      ))} */}
      <Drawer
        opened={drawer.groupInformation}
        toggleDrawer={() => { }}
        totalWidth="50%"
      >
        <EditGroupDrawer
          id={args.id}
          closeDrawer={() => handleDrawerEvent('groupInformation', false)}
          drawerData={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.companyAdd}
        toggleDrawer={() => { }}
        totalWidth="840px"
      >
        <CompaniesWizard
          closeDrawer={() => handleDrawerEvent('companyAdd', false)}
          refetchCompany={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.companiesEdit}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <CompaniesEdit companyId={args.id} refetchCompany={args.data} />
      </Drawer>
      <Drawer
        opened={drawer.addModerator}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <AddModerator
          title={t('users_layout.add_super_admin')}
          role="SUPER_ADMIN"
          drawerName="addModerator"
          drawerData={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.addAdmin}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <AddAdmin
          title="Add Admin"
          role="ADMIN"
          drawerName="addAdmin"
          drawerData={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.addStudent}
        toggleDrawer={() => { }}
        totalWidth="44%"
      >
        <AddStudent
          title="Add Student"
          role="STUDENT"
          drawerName="addStudent"
          drawerData={args.data}
          onClose={() => handleDrawerEvent('addStudent', false)}
        />
      </Drawer>
      <Drawer
        opened={drawer.addCoach}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <AddCoach
          title={t('actions.create_coach')}
          role="COACH"
          drawerName="addCoach"
          drawerData={args.data}
          onClose={() => handleDrawerEvent('addCoach', false)}
        />
      </Drawer>
      <Drawer
        opened={drawer.editStudent}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <EditStudent id={args.id} role={args.role} />
      </Drawer>
      <Drawer
        opened={drawer.editUserSubmission}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <EditUserSubmission id={args.id} role={args.role} />
      </Drawer>
      <Drawer
        opened={drawer.editCoach}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <EditCoach id={args.id} role={args.role} />
      </Drawer>
      <Drawer
        opened={drawer.editAdmin}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <EditAdmin id={args.id} role={args.role} />
      </Drawer>
      <Drawer
        opened={drawer.editModerator}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <EditModerator
          id={args.id}
          role={args.role}
          drawerName="editModerator"
        />
      </Drawer>
      {/* <Drawer
        opened={drawer.filterCoach}
        toggleDrawer={() => handleDrawerEvent('filterCoach', false)}
        totalWidth="600px"
      >
        <FilterCoaches id={args.id} role={args.role} />
      </Drawer> */}
      <Drawer
        opened={drawer.addCourses}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <AddCourseDrawer
          onClose={() => handleDrawerEvent('addCourses', false)}
          data={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.editCourse}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <EditCourseDrawer
          title={`${t('actions.edit')} ${t('general.course')}`}
          id={args.id}
          maxIndex={args.maxIndex}
          refetchCourses={args.refetch}
          drawerName="editCourse"
          defaultStep={args?.defaultStep}
        />
      </Drawer>
      <Drawer
        opened={drawer.addModule}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <AddModule
          title={t('modules_layout.create_module')}
          data={args.data}
          drawerName="addModule"
        />
      </Drawer>
      <Drawer
        opened={drawer.addCourseModule}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <AddCourseModuleDrawer
          title={t('actions.add_modules_to_course')}
          drawerName="addCourseModule"
          data={args.data}
        />
      </Drawer>

      <Drawer
        opened={drawer.editModuleDrawer}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <EditModuleDrawer
          onClose={() => handleDrawerEvent('editModuleDrawer', false)}
          id={args.id}
        />
      </Drawer>

      <Drawer
        opened={drawer.addTopic}
        toggleDrawer={() => { }}
        totalWidth="600px"
      >
        <AddTopicDrawer
          title="Add topic to module"
          id={args.id}
          data={args.data}
        />
      </Drawer>

      <Drawer
        opened={drawer.editTopic}
        toggleDrawer={() => { }}
        totalWidth="600px"
      >
        <EditTopicDrawer
          title="Edit topic"
          drawerName="editTopic"
          id={args.id}
        />
      </Drawer>

      <Drawer
        opened={drawer.addLesson}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <AddLessonDrawer
          onClose={() => handleDrawerEvent('addLesson', false)}
          id={args.id}
          data={args.data}
        />
      </Drawer>

      <Drawer
        opened={drawer.editLesson}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <EditLessonDrawer
          onClose={() => handleDrawerEvent('editLesson', false)}
          moduleId={args.id}
          lesson={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.createQuestion}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <CreateQuestionDrawer
          title={t('questions_layout.create_question')}
          currentModule={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.editQuestion}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <EditQuestionDrawer
          title={t('questions_layout.edit_question')}
          currentModule={args.data}
          questionId={args.id}
        />
      </Drawer>
      {/* <Drawer
        opened={drawer.linkQuestionsToModule}
        toggleDrawer={() => {}}
        totalWidth="900px"
      >
        <LinkQuestionsToModuleDrawer
          title="Link Questions to the Module"
          handleCloseDrawer={() => handleDrawerEvent('linkQuestionsToModule', false)}
          currentModule={args.data}
          closeDrawer={() => handleDrawerEvent('linkQuestionsToModule', false)}
        />
      </Drawer> */}

      <Drawer
        opened={drawer.addQuiz}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <AddQuizDrawer
          onClose={() => handleDrawerEvent('addQuiz', false)}
          id={args.id}
          type={args.type}
          data={args.data}
        />
      </Drawer>

      <Drawer
        opened={drawer.editQuiz}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <EditQuizDrawer
          onClose={() => handleDrawerEvent('editQuiz', false)}
          id={args.id}
          type={args.type}
          data={args.data}
        />
      </Drawer>

      <Drawer
        opened={drawer.AddQuestionsToQuiz}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <AddQuestionsToQuiz
          onClose={() => handleDrawerEvent('AddQuestionsToQuiz', false)}
          id={args.id}
          type={args.type}
          data={args.data}
        />
      </Drawer>

      <Drawer
        opened={drawer.notification}
        toggleDrawer={() => handleDrawerEvent('notification', false)}
        totalWidth="970px"
      >
        <NotificationLayout
          id={args}
          onClose={() => handleDrawerEvent('notification', false)}
        />
      </Drawer>
      <Drawer
        opened={drawer.addFlashCard}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <AddFlashCardDrawer
          onClose={() => handleDrawerEvent('addFlashCard', false)}
          data={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.editFlashCard}
        toggleDrawer={() => { }}
        totalWidth="700px"
      >
        <EditFlashCardDrawer
          onClose={() => handleDrawerEvent('editFlashCard', false)}
          data={args.data}
        />
      </Drawer>

      <Drawer
        opened={drawer.populateSection}
        toggleDrawer={() => { }}
        totalWidth="50%"
      >
        <PopulateSectionDrawer
          title={args.title}
          drawerName="populateSection"
          closeDrawer={() => handleDrawerEvent('populateSection', false)}
          data={args.data}
        />
      </Drawer>
      <Drawer
        opened={drawer.addSubscription}
        toggleDrawer={() => { }}
        totalWidth="50%"
      >
        <SubscriptionDrawer
          title={args.title}
          drawerName="addSubscription"
          closeDrawer={() => handleDrawerEvent('addSubscription', false)}
          data={args.data}
        />
      </Drawer>
    </>
  )
}

export default DrawerLayout
