import React, { useState } from 'react'
import moment from 'moment'
import { GlobalUserDefaultImage, formatDateAgo } from 'utils/utils'

import IconButton from '@material-ui/core/IconButton'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'

import {
  UserProfileWrapper,
  UsernameWrapper,
  PostContentWrapper,
  RejectionListWrapper,
  RejectionListTitle,
  PostContentContainer,
  PostMediaContainer,
  PostMediaImage,
  DateWrapper,
  UserDetails,
} from './styled-components'

import Thumbnail from 'components/common/Thumbnail/Thumbnail'
import Player from 'components/common/VideoPlayer/Player'
import Modal from 'components/common/Modal'

const RejectionDetailContent = ({ data, notificationView }: any) => {
  const [videoModalOpened, setVideoModalOpened] = useState<boolean>(false)
  const { rejectedPost, rejectedUser }: any = data



  const renderUserContent = (userData: any) => {
    return (
      <div style={{ display: 'flex' }}>
        <Thumbnail width={100} height={100} imageSrc={userData.avatar} />
        <UserDetails>
          <UsernameWrapper>
            {userData.firstName} {userData.lastName}
          </UsernameWrapper>
          {userData.email}
        </UserDetails>
      </div>
    )
  }

  const renderPostContent = (postData: any) => {

    return (
      <>
        <UserProfileWrapper>
          <Thumbnail width={50} height={50} imageSrc={postData.author.avatar} />
          <UsernameWrapper>
            {postData.author.firstName} {postData.author.lastName}
          </UsernameWrapper>
        </UserProfileWrapper>
        <PostContentWrapper>
          <PostContentContainer>

            {postData.description && postData.description}

            {postData.media &&
              postData.media.map((media: any) => (
                <PostMediaContainer key={media.id}>
                  {media.fileType === 'video' && (
                    <PostMediaImage>
                      <img src={media.thumbnail} alt="" />
                      <IconButton onClick={() => setVideoModalOpened(true)}>
                        <PlayCircleOutlineIcon
                          fontSize="small"
                          color="secondary"
                        />
                      </IconButton>
                      <Modal
                        isOpened={videoModalOpened}
                        onClose={() => setVideoModalOpened(false)}
                      >
                        <Player url={media && media.links[0].url} />
                      </Modal>
                    </PostMediaImage>
                  )}
                  {media.fileType === 'image' && (
                    <PostMediaImage>
                      <img src={media.link} alt="" />
                    </PostMediaImage>
                  )}
                </PostMediaContainer>
              ))}
            {!postData.description && postData.media.length === 0 && (
              <div>No Content</div>
            )}
          </PostContentContainer>
        </PostContentWrapper>
      </>
    )
  }


  const renderNotificationContent = (data: any) => {

    return (
      <>
        <UserProfileWrapper>
          <Thumbnail width={50} height={50} imageSrc={data.owner.avatar || GlobalUserDefaultImage} />
          <UsernameWrapper>
            {data.owner.role}:  {data.owner.firstName} {data.owner.lastName}
          </UsernameWrapper>

        </UserProfileWrapper>
        <PostContentWrapper>
          <PostContentContainer>
            <p style={{ margin: "20px 0" }}>Email: {data.owner.email}</p>
            <p>{data.actionName}  {data.message}

              {formatDateAgo(
                moment(+data.createDate).format('DD MMM YYYY h:mm A'),
              )}

            </p>
            {/* {postData.media &&
              postData.media.map((media: any) => (
                <PostMediaContainer key={media.id}>
                  {media.fileType === 'video' && (
                    <PostMediaImage>
                      <img src={media.thumbnail} alt="" />
                      <IconButton onClick={() => setVideoModalOpened(true)}>
                        <PlayCircleOutlineIcon
                          fontSize="small"
                          color="secondary"
                        />
                      </IconButton>
                      <Modal
                        isOpened={videoModalOpened}
                        onClose={() => setVideoModalOpened(false)}
                      >
                        <Player url={media && media.links[0].url} />
                      </Modal>
                    </PostMediaImage>
                  )}
                  {media.fileType === 'image' && (
                    <PostMediaImage>
                      <img src={media.link} alt="" />
                    </PostMediaImage>
                  )}
                </PostMediaContainer>
              ))}
            {!postData.description && postData.media.length === 0 && (
              <div>No Content</div>
            )} */}
          </PostContentContainer>
        </PostContentWrapper>
      </>
    )
  }


  const renderUsers = (userList: any) =>
    userList.map((user: any) => (
      <div key={user.id}>
        <UserProfileWrapper>
          <Thumbnail width={50} height={50} imageSrc={user.avatar} />
          <UsernameWrapper>
            {user.firstName} {user.lastName}
          </UsernameWrapper>
          <DateWrapper>
            {formatDateAgo(
              moment(user.createDate).format('DD MMM YYYY h:mm A'),
            )}
          </DateWrapper>
        </UserProfileWrapper>
      </div>
    ))

  return (
    <>
      {rejectedPost && renderPostContent(rejectedPost)}
      {notificationView && renderNotificationContent(data)}
      {rejectedUser && renderUserContent(rejectedUser)}
      <RejectionListWrapper>
        {!notificationView && <RejectionListTitle>
          {' '}
          {rejectedPost ? `marked as ${data.type} by` : `reported by`}
        </RejectionListTitle>}
        {data.allRejections && renderUsers(data.allRejections)}
      </RejectionListWrapper>
    </>
  )
}

export default RejectionDetailContent
