import { useQuery } from '@apollo/react-hooks'
import { GET_QUESTIONS } from 'gql/questions.query'

export const PER_PAGE = 12
export const useFetchQuestions = (
  type: 'module' | '' = '',
  id: string = '',
  filter: any = {},
  currentPage: number = 1,
  perPage: number = PER_PAGE,
) => {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_QUESTIONS, {
    variables: { type, id, filter, currentPage, perPage },
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''
  

  return {
    questions: data && data.getQuestions,
    loading,
    error: errorMessage,
    refetch,
    fetchMore,
  }
}
