export default [
  'rgba(6, 198, 143, .3)',
  'rgba(17, 161, 242, .3)',
  'rgba(210, 54, 75, .3)',
  'rgba(249, 147, 29, .3)',
  'rgba(251, 222, 75, .3)',
  'rgba(223, 119, 239, .3)',
  'rgba(112, 112, 112, .3)',
  'rgba(0, 18, 255, .3)',
  'rgba(175, 255, 14, .3)',
  'rgba(14, 255, 247, .3)',
  'rgba(255, 27, 170, .3)',
  'rgba(144, 7, 7, .3)',
]
