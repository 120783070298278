import React, { useState } from 'react'
import styled from 'styled-components'
import { useSwal } from 'hooks/useSwal'
import { generateFullName } from 'utils/utils'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import { Button } from 'components/common/Button'
import Grid from 'components/common/Grid'
import { Input } from 'components/common/TextInput'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import useUserDeleteService from 'hooks/users/useDeleteUserService'
import EditStudent from './EditStudent'
import { actionConfig, columnConfig } from './gridConfig'
import { StudentListParams } from './interfaces'
import { mainColor } from 'components/common/Colors/CommonColors'

const toolbarStyle = {
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',
}

const StudentList = ({
  studentList,
  groupList,
  companyId,
  refetch,
  t,
}: StudentListParams) => {
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [searchText, setSearchText] = useState('')

  const { fireSwal } = useSwal()
  const [checkbox, setCheckbox] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [editField, setEditField] = useState<any>(null)

  const { deleteUser, deleteUserLoading } = useUserDeleteService({
    role: 'STUDENT',
  })

  const handleSelect = (id: never, event: boolean) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    setSelectedItem(newSelected)
  }

  const selectAllItem = () => {
    if (!selectAll) {
      const newArr: string[] = studentList.map((n: any) => n.id)
      setSelectedItem(newArr)
      setSelectAll(true)
      return
    }
    setSelectedItem([])
    setSelectAll(false)
  }

  const handleDelete = (ids: string[]) => {
    if (ids.length <= 0) return
    let title: string =
      ids.length === 1
        ? `${t('popups.delete_single')} ${t('general.student')}`
        : `${t('popups.delete_many')} ${t('general.students')}`
    const params = {
      title,
      onConfirm: () => {
        deleteUser(ids, 'STUDENT', () => {
          refetch({
            role: 'STUDENT',
            filter: {
              companyId: {
                type: 'exact',
                value: companyId,
              },
            },
          })

          setSelectedItem([])
          setCheckbox(false)
        })
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const handleEdit = (field: any) => {
    setEditMode(true)
    setEditField(field)
  }

  const actionClick = (e: string, field: any) => {
    switch (e) {
      case 'edit':
        return handleEdit(field)

      case 'delete':
        return handleDelete([field])

      default:
        break
    }
  }

  const config = columnConfig(handleSelect, checkbox, t)
  const actions = actionConfig(actionClick, t)

  const filtered = studentList.filter((i: any) =>
    generateFullName(i)
      .toLowerCase()
      .includes(searchText.toLowerCase()),
  )

  return (
    <>
      {editMode && (
        <EditStudent
          groupList={groupList}
          item={editField}
          onCancel={() => {
            setEditMode(false)
            setEditField(null)
          }}
          t={t}
        />
      )}

      <GridWrapper>
        <Grid
          config={config}
          data={filtered || []}
          actionConfig={actions}
          selectAllItem={selectAllItem}
          selectedItems={selectedItem}
          selected={selectedItem.length}
          disabledSelect={!checkbox}
          openInExternalTab={true}
        />
      </GridWrapper>
    </>
  )
}

export default StudentList

const ToolbarItemWrapper = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`
const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
`

const GridWrapper = styled.div`
  .MuiPaper-root {
    box-shadow: none;
  }
`
