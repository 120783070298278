import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { FormGroup, FormGroupItem } from 'components/common/Form'
import { Button } from 'components/common/Button'

import { useChangePasswordServiceWithErrorMEssage } from 'hooks/users/useEditProfileService'
import { PasswordWithValidations } from 'pages/profile/AdminProfile/PasswordWithValidations'
import { isValidPassword } from 'pages/profile/AdminProfile/ChangePassword'
import { PasswordInput } from 'components/common/TextInput/PasswordInput'
import { useTranslation } from 'react-i18next'

const formData = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}

const FirstLogin = (props: any) => {
  const { t } = useTranslation()
  const {
    useChangePassword,
    message,
  } = useChangePasswordServiceWithErrorMEssage()

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: formData,
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .required(
          `${t('form_fields.current_password')} ${t('validations.required')}`,
        )
        .min(4, '4'),
      newPassword: Yup.string()
        .required(
          `${t('form_fields.new_password')} ${t('validations.required')}`,
        )
        .min(4, '4'),
      confirmPassword: Yup.string()
        .required(
          `${t('form_fields.confirm_password')} ${t('validations.required')}`,
        )
        .min(4, '4'),
    }),
    onSubmit: (values: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useChangePassword(values)
    },
  })

  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  const onChangeConfirmPassword = useCallback(
    (e: any) => {
      handleChange(e)
      if (values.confirmPassword !== e.target.value) {
        setConfirmPasswordError(t('validaitons.passwords_dont_match'))
      } else setConfirmPasswordError('')
    },
    [values.confirmPassword, confirmPasswordError],
  )

  useEffect(() => {
    if (
      values.newPassword !== values.confirmPassword &&
      values.confirmPassword &&
      values.confirmPassword.length > 0
    ) {
      setConfirmPasswordError(t('validations.passwords_dont_match'))
    } else setConfirmPasswordError('')
  }, [values.newPassword, values.confirmPassword])

  const onSave = () => {
    const isValid = isValidPassword(values.newPassword)

    if (isValid && values.confirmPassword === values.newPassword) {
      handleSubmit()
      props.setDisplayChangePasswordModel(false)
    }
  }

  return (
    <Container>
      <FormGroup>
        <FormGroupItem>
          <PasswordInput
            touched={touched.oldPassword}
            label={t('form_fields.current_password')}
            name="oldPassword"
            value={values.oldPassword}
            onChange={handleChange}
            error={message.length > 1}
            size="medium"
            inputStyle={{ color: '#363737 !important' }}
            eyeColor="#363737"
          />
          <div style={{ color: '#f44336' }}>{message}</div>
        </FormGroupItem>
        <PasswordWithValidations
          eyeColor="#363737"
          label={t('form_fields.new_password')}

          handleChange={handleChange}
        />
        <FormGroupItem>
          <PasswordInput
            touched={touched.confirmPassword}
            label={t('form_fields.confirm_password')}
            name="confirmPassword"
            size="medium"
            onChange={onChangeConfirmPassword}
            value={values.confirmPassword}
            error={confirmPasswordError.length > 1}
            inputStyle={{ color: '#363737 !important' }}
            eyeColor="#363737"
          />
          <div style={{ color: '#f44336' }}>{confirmPasswordError}</div>
        </FormGroupItem>
        <FormGroupItem>
          <Button
            text={t('actions.save')}
            color="secondary"
            type="small"
            onClick={onSave}
          />
        </FormGroupItem>
      </FormGroup>
    </Container>
  )
}

export default FirstLogin

const Container = styled.div`
  width: 500px;
  box-sizing: border-box;
  padding: 30px;
  background: #fff;
`
