import { AvatarInput } from 'components/common/AvatarInput/AvatarInput'
import { Button } from 'components/common/Button'
import { FormField, FormFields, Label } from 'components/common/Form/Form'
import DatePicker from 'components/common/DatePicker/DatePicker'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import { genderOptions } from 'components/UsersLayout/staticData'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { mainColor } from 'components/common/Colors/CommonColors'
import GenderPicker from 'components/common/GenderPicker'

const CreateStudent = ({
  handleSubmit,
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
  groupList,
  addUserLoading,
  t,
}: any) => {
  const groups = groupList.map((i: any) => ({ value: i.id, label: i.name }))

  const onDateChange = (date: Date | null) => {
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  return (
    <Container>
      <FormFields>
        <FormField>
          <AvatarInput
            setFieldValue={setFieldValue}
            initialAvatar={values.avatar}
          />
        </FormField>

        <FormField width="48%">
          <TextInput
            error={!!errors.firstName}
            errorMessage={errors.firstName}
            touched={touched.firstName}
            label={`${t('user_details.first_name')}*`}
            name="firstName"
            size="medium"
            type="text"
            value={values.firstName}
            onChange={handleChange}
          />
        </FormField>
        <FormField width="48%">
          <TextInput
            error={!!errors.lastName}
            errorMessage={errors.lastName}
            touched={touched.lastName}
            label={`${t('user_details.last_name')}*`}
            name="lastName"
            size="medium"
            type="text"
            value={values.lastName}
            onChange={handleChange}
          />
        </FormField>

        <FormField width="48%">
          <DatePicker
            label={t('user_details.birthday')}
            touched={touched.birthDate}
            error={errors.birthDate ? true : false}
            errorMessage={errors.birthDate ? errors.birthDate : ''}
            name="birthDate"
            value={values.birthDate}
            onChange={onDateChange}
          />
        </FormField>

        <FormField width="48%">
          <TextInput
            label={t('form_fields.job_title')}
            size="medium"
            name="jobTitle"
            type="text"
            value={values.jobTitle}
            onChange={handleChange}
          />
        </FormField>

        <FormField width="100%">
          <GenderPicker
            checked={values.gender}
            onChange={e => setFieldValue('gender', e)}
          />
        </FormField>

        <FormField width="48%">
          <PhoneCountryCode
            label={t('user_details.phone')}
            selectLabel={t('form_fields.dial_code')}
            selectValue={null}
            selectChange={(e: any) => setFieldValue('phoneFields', e)}
            inputLabel={t('user_details.phone')}
            inputValue={values.phone}
            inputChange={handleChange}
            error={errors.phone || errors.phoneFields ? true : false}
            errorMessage={
              errors.phone || errors.phoneFields
                ? errors.phone || errors.phoneFields
                : ''
            }
            touched={touched.phone || touched.phoneFields}
          />
        </FormField>

        <FormField width="48%">
          <TextInput
            error={!!errors.email}
            errorMessage={errors.email}
            touched={touched.email}
            label={`${t('form_fields.email')}*`}
            name="email"
            size="medium"
            type="text"
            value={values.email}
            onChange={handleChange}
          />
        </FormField>

        <FormField width="48%">
          <TextInput
            label={t('company_details.city')}
            size="medium"
            name="city"
            type="text"
            value={values.city}
            onChange={handleChange}
          />
        </FormField>

        <FormField width="48%">
          <TextInput
            label={t('form_fields.location')}
            size="medium"
            name="location"
            type="text"
            value={values.location}
            onChange={handleChange}
          />
        </FormField>

        <FormField style={{ position: 'relative' }} width="100%">
          {values.group && values.group.length > 0 && (
            <Label>{`${t('general.groups')}*`}</Label>
          )}
          <SelectField
            error={!!errors.group}
            errorMessage={errors.group}
            touched={touched.group}
            placeholder={`${t('general.groups')}*`}
            name="group"
            options={groups}
            value={values.group}
            onChange={(e: any) => setFieldValue('group', e)}
            isClearable
            isMulti
          />
        </FormField>

        <FormField>
          <TextInput
            error={!!errors.biography}
            errorMessage={errors.biography ? errors.biography : ''}
            touched={touched.biography}
            label={t('user_details.biography')}
            size="medium"
            name="biography"
            type="text"
            multiline
            rows="3"
            value={values.biography}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField>
          <TextInput
            error={!!errors.note}
            errorMessage={errors.note}
            touched={touched.note}
            label={t('user_details.note')}
            size="medium"
            name="note"
            type="text"
            multiline
            rows="3"
            value={values.note}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField>
          <ButtonContainer>
            <ButtonItem style={{ marginRight: '10px' }}>
              <Button
                text={t('company_wizard_layout.save_and_create_another')}
                type="medium"
                onClick={() => handleSubmit('reset')}
                isDisabled={addUserLoading}
                background="#ffffff"
                textColor="#080A0A8A"
                outline="1px solid #080A0A14"
              />
            </ButtonItem>

            <ButtonItem>
              <Button
                text={t('company_wizard_layout.save_and_review')}
                type="medium"
                onClick={() => handleSubmit('review')}
                isDisabled={addUserLoading}
                color="secondary"
                background={'#317BF4'}
              />
            </ButtonItem>
          </ButtonContainer>
        </FormField>
      </FormFields>
    </Container>
  )
}

export default CreateStudent

const Container = styled.div`
  box-sizing: border-box;
  padding: 0px 30px;
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 30px;
`

const ButtonItem = styled.div`
  width: 100%;
  div {
    width: 100%;

    button {
      border-radius: 8px !important;
    }
  }
`
