import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { useFormik } from 'formik'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  CloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
  Label,
} from 'components/common/Form/Form'
import { generateFullName } from 'utils/utils'
import Loader from 'components/common/Loader'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import RangeSlider from 'components/common/RangeSlider'
import { SelectField, AsyncSelect } from 'components/common/SelectField'
import { GET_COMPANIES } from 'gql/companies.query'
import { GET_USERS } from 'gql/users.query'
import { FilterGroupsSchema } from 'helpers/validationSchemas'
import { statuses, filterOptions } from 'pages/group/staticData'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import DateRangePicker from 'components/common/DateRangePicker/DateRangePicker'
import { useTranslation } from 'react-i18next'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { CapitalPlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'
import { useUserValue } from 'context/UserContext'
import { useTheme } from '@material-ui/core'

const FilterDrawer = (props: any) => {
  const { t } = useTranslation()
  const ref: any = useRef()
  const [state] = useUserValue()
  const companyIdForFilter: string | null =
    state.selectedCompanyId || state?.currentUser?.companyId?.id
  let variables: any = { role: 'ADMIN' }
  if (props.companyId) {
    variables = {
      ...variables,
      filter: {
        companyId: {
          type: 'exact',
          value: props.companyId,
        },
      },
    }
  }

  const [
    fetchCompany,
    { data: companyData, loading: companyLoading },
  ] = useLazyQuery(GET_COMPANIES)

  const [fetchAdmin, { data: adminData, loading: adminLoading }] = useLazyQuery(
    GET_USERS,
  )

  const [
    fetchCourse,
    { data: courseData, loading: courseLoading },
  ] = useLazyQuery(GET_ALL_COURSE)

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: filterOptions,
    validationSchema: FilterGroupsSchema,
    onSubmit(values) {
      const formValues: any = {}
      formValues.name = {
        type: 'search',
        value: values.name,
        fields: ["name"]
      }

      formValues.note = {
        type: 'match',
        value: values.note,
      }

      formValues.location = {
        type: 'match',
        value: values.location,
      }

      if (values.status.value !== 'all') {
        formValues.status = {
          type: 'exact',
          value: values.status.value,
        }
      }

      if (values.numberOfStudents[1] > 0) {
        formValues.numberOfStudents = {
          type: 'range',
          value: `${values.numberOfStudents[0]}-${values.numberOfStudents[1]}`,
        }
      }

      if (values.company) {
        const companyIds = values.company.map((i: any) =>
          i.value === '-1' ? (i.value = null) : i.value,
        )
        const query = { company: { $in: companyIds } }

        formValues.query = {
          type: 'query',
          value: JSON.stringify(query),
        }
      }

      if (values.admin) {
        const adminIds = values.admin.map((i: any) => i.value)
        const query = { admin: { $in: adminIds } }
        // formValues.query = {
        //   type: 'query',
        //   value: formValues.query
        //     ? { ...formValues.query.value, ...query }
        //     : query,
        // }
        formValues.admin = { type: 'arrayIn', value: adminIds[0] }
      }

      // if (formValues.query) {
      //   formValues.query = {
      //     type: 'query',
      //     value: JSON.stringify(formValues.query.value),
      //   }
      // }

      if (values.course) {
        formValues.courses = {
          type: 'nestedArrayIn',
          value: values.course.map((i: any) => i.value),
          nestedField: 'courseId',
        }
      }

      values.createDate[0] =
        values.createDate[0] && new Date(values.createDate[0])
      values.createDate[1] =
        values.createDate[1] && new Date(values.createDate[1])

      const isSameDate =
        values.createDate[0] === values.createDate[1] &&
        values.createDate[0] !== null

      if (!values.createDate[1] && values.createDate[0] !== null)
        values.createDate[1] = new Date()

      if (!values.createDate[0] && values.createDate[1] !== null)
        values.createDate[0] = ''

      const formatedData = isSameDate
        ? values.createDate[0]
        : `${values.createDate[0] ? values.createDate[0].toString() : ''
        }${'-'}${values.createDate[1] ? values.createDate[1].toString() : ''}`

      formValues.createDate = {
        type: isSameDate ? 'exact' : 'range',
        value: formatedData,
      }

      if (companyIdForFilter) {
        formValues.company = {
          type: 'exact',
          value: companyIdForFilter,
        }
      }

      props.refetchGroup({
        filter: {
          ...props.defaultFilter,
          ...formValues,
        },
      })

      const defaultDrawer = props.defaultFilter || null

      props.setFilterOptions({
        ...props.filterOptions,
        filterOptions: { ...defaultDrawer, ...formValues },
        filterValues: values,
        opened: false,
      })

      props.setCurrentPage(1)
    },
  })

  useEffect(() => {
    if (props.filterOptions.filterValues) {
      setValues(props.filterOptions.filterValues)
    }
  }, [])

  const handleRangeChange = (range: any) => {
    setFieldValue('numberOfStudents', range)
  }

  const loadDataOptions = (e: any, field: string) =>
    setTimeout(() => {
      const value = e
      if (field === 'admin') {
        let adminFilter: any = {}
        if (companyIdForFilter) {
          adminFilter = {
            ...adminFilter,
            companyId: {
              type: 'exact',
              value: companyIdForFilter,
            },
            search: {
              type: 'search',
              value,
              fields: ['firstName', 'lastName'],
            },
          }
        } else
          adminFilter = {
            ...adminFilter,
            search: {
              type: 'search',
              value,
              fields: ['firstName', 'lastName'],
            },
          }
        fetchAdmin({
          variables: {
            ...variables,
            filter: { ...adminFilter },
            ...variables.filter,
          },
        })
      }

      if (field === 'company') {
        fetchCompany({
          variables: {
            filter: {
              name: {
                type: 'match',
                value,
              },
            },
          },
        })
      }

      if (field === 'course') {
        let filter: any = {
          originalId: { type: 'exact', value: null },
        }
        if (companyIdForFilter) {
          filter = {
            ...filter,
            companyId: {
              type: 'exact',
              value: companyIdForFilter,
            },
          }
          if (props.isCoursePurchased && companyIdForFilter) {
            filter = {
              companyId: {
                type: 'exact',
                value: companyIdForFilter,
              },
              // originalId: { type: 'notEqual', value: null },
            }
          }

          if (
            props.isCoursePurchased !== undefined &&
            !props.isCoursePurchased &&
            companyIdForFilter
          ) {
            filter = {
              companyId: {
                type: 'exact',
                value: companyIdForFilter,
              },
            }
            // filter = {
            //   ...filter,
            //   isBought: { type: 'exact', value: 'false' },
            //   originalId: { type: 'exact', value: null },
            // }
          }
        }
        fetchCourse({
          variables: {
            filter,
            perPage: 0,
          },
        })
      }
    }, 200)

  const handleSelectChange = (e: string, field: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e, field)
    }
  }

  const [companyOptions, setCompanyOptions] = useState([] as any)

  useEffect(() => {
    if (companyData) {
      setCompanyOptions([
        {
          id: '-1',
          name: CapitalPlatformTitle,
          icon: (
            <StarsRoundedIcon
              style={{
                color: mainColor,
                fontSize: 10,
                verticalAlign: 'super',
              }}
            />
          ),
        },
        ...companyData.companies.data,
      ])
    }
  }, [companyData])

  const onCancelClick = useCallback(() => {
    if (props.defaultFilter) {
      props.setFilterOptions({
        ...props.filterOptions,
        filterOptions: props.defaultFilter,
        filterValues: null,
        opened: false,
      })
    } else {
      props.setFilterOptions({
        filterOptions: null,
        filterValues: null,
        opened: false,
      })
    }
  }, [props.defaultFilter, props.setFilterOptions, props.filterOptions])

  const theme = useTheme();
  return (
    <>
      {props.filterLoading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{`${t('general.advanced_filters')} - ${t(
          'general.group_filter',
        )}`}</DrawerTitle>
        <CloseIcon
          onClick={props.closeDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent>
        <FormContainer
          onSubmit={(e: any) => {
            e.preventDefault()
          }}
        >
          <FormGroupWrapper>
            <FormFields>
              <FormField width="48%">
                <TextInput
                  error={errors.name ? true : false}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  label={t('form_fields.group_name')}
                  size="medium"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="48%" style={{ position: 'relative' }}>
                <Label>{t('general.status')}</Label>
                <SelectField
                  label="Status"
                  name="status"
                  options={statuses}
                  value={values.status}
                  onChange={(e: any) => setFieldValue('status', e)}
                />
              </FormField>
              <FormField width="100%">
                <RangeSlider
                  label={t('form_fields.number_of_students')}
                  value={values.numberOfStudents}
                  onRangeChange={handleRangeChange}
                />
              </FormField>
              <FormField width="48%" style={{ position: 'relative' }}>
                {values?.admin?.length > 0 ? (
                  <Label>{t('general.admins')}</Label>
                ) : null}
                <AsyncSelect
                  isMulti
                  onInputChange={(e: any) => handleSelectChange(e, 'admin')}
                  onChange={(e: any) => setFieldValue('admin', e)}
                  data={(adminData && adminData.usersByRole.data) || []}
                  value={values.admin}
                  label={t('form_fields.type_to_search_admin')}
                  loading={adminLoading}
                  labelDataName={generateFullName}
                  valueDataKey="id"
                  isClearable
                />
              </FormField>
              <FormField width="48%" style={{ position: 'relative' }}>
                {values.course && values.course.length > 0 ? (
                  <Label>{t('general.courses')}</Label>
                ) : null}
                <AsyncSelect
                  isMulti
                  onInputChange={(e: any) => handleSelectChange(e, 'course')}
                  onChange={(e: any) => setFieldValue('course', e)}
                  data={courseData?.getAllCourse?.data || []}
                  value={values.course}
                  label={t('form_fields.type_to_search_courses')}
                  loading={courseLoading}
                  labelDataKey="name"
                  valueDataKey="id"
                />
              </FormField>
              {props.hasSearchByCompany && (
                <FormField width="100%" style={{ position: 'relative' }}>
                  {values.company && values.company.length > 0 ? (
                    <Label>{t('general.company')}</Label>
                  ) : null}
                  <AsyncSelect
                    isMulti
                    onInputChange={(e: any) => handleSelectChange(e, 'company')}
                    onChange={(e: any) => setFieldValue('company', e)}
                    data={companyOptions}
                    value={values.company}
                    label={t('form_fields.type_to_search_companies')}
                    loading={companyLoading}
                    labelDataKey="name"
                    valueDataKey="id"
                    iconDataKey="icon"
                  />
                </FormField>
              )}
              <FormField width="48%">
                <TextInput
                  label={t('form_fields.location')}
                  size="medium"
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  label={t('user_details.note')}
                  size="medium"
                  name="note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="100%">
                <DateRangePicker
                  placeholder={t('general.create_date')}
                  values={values.createDate}
                  onChange={(e: any) => {
                    setFieldValue('createDate', e)
                  }}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={onCancelClick}
                background="#F5F5F5"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.filter_verb')}
                type="medium"
                color="secondary"
                btnType="submit"
                background={'#317BF4'}
                onClick={handleSubmit}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterDrawer
