import gql from 'graphql-tag'

export const GET_CATEGOTIES = gql`
  query {
    getCategories {
      id
      status
      name
      createDate
    }
  }
`
export const GET_USED_CATEGORIES = gql`
 query{
  getUsedCategories{
   	id
    status
    name
    createDate
	}
}
`
export const ADD_CATEGORY = gql`
  mutation($input: CategoryInput!) {
    addCategory(input: $input) {
      id
      name
    }
  }
`

export const DELETE_CATEGORIES = gql`
mutation($ids: [String!]!) {
  deleteCategories(ids: $ids)
}
`
