import React, { useState, useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import { useLazyQuery } from '@apollo/react-hooks'
import { useData } from 'context/DataContext'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'
import { useTranslation } from 'react-i18next'
import { genderOptions } from 'components/UsersLayout/staticData'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  CloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'

import { GET_GROUP } from 'gql/group/group.query'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'

import Loader from 'components/common/Loader'
import { UserSchema } from 'helpers/validationSchemas'
import { useUserValue } from 'context/UserContext'
import useAddUserServie from 'hooks/users/useAddUserService'
import TextInput from 'components/common/TextInput/TextInput'
import { SelectField, AsyncSelect } from 'components/common/SelectField'
import DatePicker from 'components/common/DatePicker'
import { Button } from 'components/common/Button'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { AvatarInput } from 'components/common/AvatarInput/AvatarInput'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import GenderPicker from 'components/common/GenderPicker'
import { useTheme } from '@material-ui/core'

interface Props {
  title: string
  role: string
  drawerName?: string
  closeDrawer?: () => void
  drawerData: any
  onClose?: any
  setFormData?: any
  group?: {
    groupId: string
    name: string
    status: string
  }
  setSelectedStudents?: any
}

const AddStudent = ({
  title,
  role,
  drawerName,
  drawerData,
  onClose,
  setFormData,
  group,
  setSelectedStudents,
}: Props) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const ref: any = useRef()
  const [dataState, dispatchData] = useData()
  const [generalError, setGeneralError] = useState('')

  const { addUser, user, error, addUserLoading } = useAddUserServie({
    role: 'STUDENT',
    companyId: state.selectedCompanyId,
    filter: {},
  })
  const [fetchGroup, { data: groupData, loading: groupLoading }] = useLazyQuery(
    GET_GROUP,
  )

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender: null,
      birthDate: '',
      biography: '',
      requestPasswordChange: state.currentUser.settings.askPassword,
      note: '',
      group: [],
      role: '',
      jobTitle: '',
      location: '',
      phoneFields: null as any,
      phoneFieldValue: null as any,
      avatar: '',
    },
    validationSchema: UserSchema,
    onSubmit(values) {
      const formValues: any = { ...values }

      delete formValues.phoneFieldValue

      if (addUserLoading) return

      formValues.role = role
      formValues.group = []

      if (values.group.length !== 0) {
        formValues.group = values.group.map((group: any) => {
          return { name: group.label, groupId: group.value }
        })
      }

      if (values.group.length === 0 && group) {
        formValues.group = group
      }

      if (formValues.phoneFields && formValues.phoneFields.value) {
        formValues.phoneFields = {
          dialCode: formValues.phoneFields.value,
          code: formValues.phoneFields.label,
        }
      } else {
        formValues.phoneFields = null
      }

      formValues.gender = formValues.gender ? formValues.gender : null
      addUser(
        formValues,
        role,
        drawerData.companyId || state.selectedCompanyId,
        onSuccess,
      )
    },
  })

  const onSuccess = (student: any) => {
    if (setSelectedStudents) {
      setSelectedStudents(student)
    }
    closeDrawer()
    dispatchData({ type: CLEAR_FORMDATA })
    drawerData.onSuccess()
  }

  const handleCloseDrawer = () => {
    if (setFormData) {
      setFormData(values)
    } else {
      dispatchData({
        type: SET_FORMDATA,
        payload: {
          type: 'add',
          drawer: 'addStudent',
          values,
          compareTo: {},
        },
      })
    }
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (error) {
      setGeneralError(error)
    }
  }, [error])

  const handleDateChange = (date: Date | null) => {
    setFieldValue('birthDate', date)
  }

  const loadDataOptions = (e: any) =>
    setTimeout(() => {
      const value = e
      fetchGroup({
        variables: {
          filter: {
            name: {
              type: 'match',
              value,
            },
            company: {
              type: 'exact',
              value: state.selectedCompanyId,
            },
          },
        },
      })
    }, 200)

  const handleSelectChange = (e: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e)
    }
  }

  const onDateChange = (date: Date | null) => {
    setFieldValue('birthDate', date)
  }

  const closeDrawer = () => onClose()

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  const theme = useTheme()

  return (
    <>
      {addUserLoading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{title}</DrawerTitle>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <AvatarInput
                  setFieldValue={setFieldValue}
                  initialAvatar={values.avatar}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  label={`${t('user_details.first_name')}*`}
                  size="medium"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  label={`${t('user_details.last_name')}*`}
                  size="medium"
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.email || generalError ? true : false}
                  errorMessage={
                    errors.email || generalError
                      ? errors.email || generalError
                      : ''
                  }
                  touched={touched.email}
                  label={`${t('user_details.email')}*`}
                  size="medium"
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <PhoneCountryCode
                  label={`${t('user_details.phone')}`}
                  selectLabel={t('form_fields.dial_code')}
                  selectValue={null}
                  selectChange={(e: any) => setFieldValue('phoneFields', e)}
                  inputLabel="Number"
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={
                    errors.phone || errors.phoneFields || generalError
                      ? true
                      : false
                  }
                  errorMessage={
                    errors.phone || errors.phoneFields || generalError
                      ? errors.phone || errors.phoneFields || generalError
                      : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>

              <FormField width="100%">
                <GenderPicker
                  checked={values.gender || undefined}
                  onChange={e => setFieldValue('gender', e)}
                />
              </FormField>

              <FormField width="48%">
                <TextInput
                  label={t('form_fields.job_title')}
                  size="medium"
                  name="jobTitle"
                  type="text"
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  label={t('form_fields.location')}
                  size="medium"
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <DatePicker
                  touched={touched.birthDate}
                  error={errors.birthDate ? true : false}
                  errorMessage={errors.birthDate ? errors.birthDate : ''}
                  name="birthDate"
                  label={t('user_details.birthday')}
                  value={values.birthDate}
                  onChange={onDateChange}
                />
              </FormField>

              {!drawerData.groupField && (
                <FormField width="48%">
                  <AsyncSelect
                    isMulti
                    onInputChange={handleSelectChange}
                    onChange={(e: any) => setFieldValue('group', e)}
                    data={(groupData && groupData.getAllGroups) || []}
                    value={values.group}
                    label={t('form_fields.type_to_search_group')}
                    loading={groupLoading}
                    labelDataKey="name"
                    valueDataKey="id"
                  />
                </FormField>
              )}

              <FormField>
                <TextInput
                  error={errors.biography ? true : false}
                  errorMessage={errors.biography ? errors.biography : ''}
                  touched={touched.biography}
                  label={t('user_details.biography')}
                  size="medium"
                  name="biography"
                  type="text"
                  multiline
                  rows="3"
                  value={values.biography}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
              <FormField>
                <TextInput
                  error={errors.note ? true : false}
                  errorMessage={errors.note ? errors.note : ''}
                  touched={touched.note}
                  label={t('user_details.note')}
                  size="medium"
                  name="note"
                  type="text"
                  multiline
                  rows="3"
                  value={values.note}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.save')}
                type="medium"
                color="secondary"
                btnType="submit"
                background={'#317BF4'}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddStudent
