import { FC } from "react";
import * as React from "react";
import './ChartToolTip.scss'

interface ChartToolTipProps {

}

const ChartToolTip: FC<ChartToolTipProps> = ({ children }) => {
  return (
    <div className="tooltip">
      <div className="inner-box">
        {children}
      </div>
    </div>
  );
}

export default ChartToolTip;