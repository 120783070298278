import React from 'react'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import {
  Wrapper,
  BoolIcon,
  AnswersWrapper,
  AnswerContainer,
  CorrectAnswerTitle,
  CorrectBoolAnswer,
} from './styled-components'

interface Answer {
  isCorrect: boolean
  value: string
}

interface Params {
  type: string
  answers?: Answer[]
  boolAnswer?: boolean
  t?: any
}

const QuestionAnswers = ({ type, answers, boolAnswer, t }: Params) => {
  const renderBool = () => (
    <Wrapper>
      <BoolIcon className="true">
        <CheckCircleOutlineIcon />
      </BoolIcon>
      <BoolIcon className="false">
        <HighlightOffIcon />
      </BoolIcon>
    </Wrapper>
  )

  const renderSingle = (title?: string, correctMode: boolean = false) => (
    <>
      {correctMode && <CorrectAnswerTitle>{title}</CorrectAnswerTitle>}
      <AnswersWrapper>
        {answers &&
          !correctMode &&
          answers.map((answer: Answer) => (
            <FormControlLabel
              value={answer.value}
              control={
                <Radio
                  disabled
                  checked={correctMode ? answer.isCorrect : false}
                />
              }
              label={answer.value}
            />
          ))}

        {answers &&
          correctMode &&
          answers.map((answer: Answer) => {
            return (
              answer.isCorrect && (
                <FormControlLabel
                  value={answer.value}
                  control={
                    <Radio
                      disabled
                      checked={correctMode ? answer.isCorrect : false}
                    />
                  }
                  label={answer.value}
                />
              )
            )
          })}
      </AnswersWrapper>
    </>
  )

  const renderMultiple = (title?: string, correctMode: boolean = false) => (
    <>
      {correctMode && <CorrectAnswerTitle>{title}</CorrectAnswerTitle>}
      <AnswersWrapper>
        {answers &&
          answers.map((answer: Answer) => (
            <FormControlLabel
              value={answer.value}
              control={
                <Checkbox
                  disabled
                  checked={correctMode ? answer.isCorrect : false}
                  icon={<div className={'icon'} />}
                  checkedIcon={<div className={'icon icon-checked'} />}
                />
              }
              label={answer.value}
            />
          ))}
      </AnswersWrapper>
    </>
  )

  return (
    <>
      {/* {type === 'boolean' && renderBool()} */}
      {type === 'single' && renderSingle()}
      {type === 'multiple' && renderMultiple(undefined, true)}
      <AnswerContainer>
        {type === 'boolean' && (
          <>
            <CorrectAnswerTitle>
              {t('question_details.correct')} {t('question_details.answer')}:
              <CorrectBoolAnswer>
                {!!boolAnswer ? t('general.true') : t('general.false')}
              </CorrectBoolAnswer>
            </CorrectAnswerTitle>
          </>
        )}

        {type === 'single' &&
          renderSingle(
            `${t('question_details.correct')} ${t('question_details.answer')}`,
            true,
          )}
        {type === 'multiple' &&
          answers &&
          `${t('question_details.correct')} ${t(
            'question_details.answer',
          )}: ${answers.map((answer: Answer) => answer.value)}`}
      </AnswerContainer>
    </>
  )
}

export default QuestionAnswers
