import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { GET_COACHES_BY_COMPANYID, GET_USERS } from 'gql/users.query'
import { useUserValue } from 'context/UserContext'
import { GET_ALL_SKILLS } from 'gql/skilss.query'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { Input } from 'components/common/TextInput'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import { GET_COMPANIES } from 'gql/companies.query'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'

import {
  FormContainer,
  FormGroupWrapper,
  FormGroup,
  FormFooter,
  FormButtons,
  FormGroupItem,
} from 'components/common/Form'
import { generateFullName } from 'utils/utils'

import { Button } from 'components/common/Button'
import { AsyncSelect, SelectField } from 'components/common/SelectField'
import {
  courseState,
  courseLevels,
  coursePrivacy,
  selectOptions,
  filterOptions,
} from 'pages/courses/staticData'

import { GET_CATEGOTIES } from './../../../gql/categories/categories.query'
import { mainColor } from 'components/common/Colors/CommonColors'
import { GET_GROUPS } from 'gql/group/group.query'
import { useTheme } from '@material-ui/core'

const FilterDrawer = (props: any) => {
  const [state] = useUserValue()
  const ref: any = useRef()
  const [studentRange, setStudentRange] = useState([0, 300])
  const [filterData, setFilterData] = useState<any>(filterOptions)

  // const { users, loading, error } = useFetchUsers(
  //   'COACH',
  //   state.selectedCompanyId,
  //   {},
  //   1,
  //   0,
  // )

  const [fetchCoach, { data: coachData, loading: userLoading }] = useLazyQuery(
    GET_USERS,
  )

  const [
    fetchCoachesByCompanyId,
    { data: coachesByCompanyIdData, loading: coachingLoading },
  ] = useLazyQuery(GET_COACHES_BY_COMPANYID)

  const [
    fetchCompany,
    { data: companyData, loading: companyLoading },
  ] = useLazyQuery(GET_COMPANIES)
  const [fetchGroup, { data: groupData, loading: groupLoading }] = useLazyQuery(
    GET_GROUPS,
  )

  // const { data: groupData } = useQuery(GET_GROUPS, {
  //   variables: {
  //     filter: {
  //       company: {
  //         type: 'exact',
  //         value: state.selectedCompanyId,
  //       },
  //     },
  //   },
  // })

  const { data: skillsData } = useQuery(GET_ALL_SKILLS)
  const { data: categoriesData } = useQuery(GET_CATEGOTIES)

  const handleFieldChange = (field: string) => {
    return (e: any) => {
      let item = field === 'name' ? e.target.value : e

      setFilterData({
        ...filterData,
        [field]: item,
      })
    }
  }

  useEffect(() => {
    if (!_.isEmpty(props.filterData)) {
      const { numberOfStudents, ...args } = props.filterData
      setFilterData(args)

      const students = numberOfStudents || [0, 300]
      setStudentRange(students)
    }
  }, [])

  const onFormSubmit = () => {
    const {
      name,
      coaches,
      group,
      video,
      skills,
      categories,
      company,
      ...args
    } = filterData
    props.setFilterData({
      ...filterData,
      numberOfStudents: studentRange,
    })

    const filterQuery: any = {}

    for (const key in args) {
      if (args[key].value && args[key].value !== 'all') {
        filterQuery[key] = args[key].value
      }
    }

    if (video.value !== 'all') {
      filterQuery.video = video.value === 'no' ? null : { $ne: null }
    }

    if (name) filterQuery.name = { $regex: name, $options: 'i' }

    if (coaches && coaches.length > 0) {
      const selectedCoachesIds = coaches.map((i: any) => i.value)
      filterQuery['coaches.userId'] = { $in: selectedCoachesIds }
    }

    if (group && group.length > 0) {
      filterQuery['groups.groupId'] = { $in: group.map((i: any) => i.value) }
    }

    if (skills && skills.length > 0) {
      filterQuery['skills.label'] = { $in: skills.map((i: any) => i.label) }
    }

    if (categories && categories.length > 0) {
      filterQuery['categories.name'] = {
        $in: categories.map((i: any) => i.label),
      }
    }

    if (company && company.length > 0) {
      filterQuery.companyId = { $in: company.map((i: any) => i.value) }
    }

    if (studentRange[0] > 0 || studentRange[1] < 300) {
      filterQuery.numberOfStudents = {
        $gte: studentRange[0],
        $lte: studentRange[1],
      }
    }

    if (props.defaultVariables) {
      filterQuery['companyId'] = props.defaultVariables.filter.companyId.value
    }

    props.setFilterQuery(filterQuery)
    props.setCurrentPage && props.setCurrentPage(1)

    props.refetchCourse()

    props.onClose()
  }

  // const generateFullName = (e: any) => {
  //   if (!e.firstName && !e.lastName) return e.email
  //   return `${e.firstName} ${e.lastName}`
  // }

  // const coachList =
  //   users &&
  //   users.data.map((i: any) => ({
  //     id: i.id,
  //     label: generateFullName(i),
  //     value: i.id,
  //   }))

  // const groupList =
  //   groupData &&
  //   groupData.getAllGroups.map((i: any) => ({
  //     label: i.name,
  //     id: i.id,
  //     value: i.id,
  // }))

  // const companyList = companyData && companyData.companies.map((i: any) =>
  //   ({ value: i.id, label: i.name })) || []

  const loadDataOptions = (e: any, field: string) =>
    setTimeout(() => {
      const value = e

      if (field === 'coach') {
        let filter: any = {
          search: {
            type: 'search',
            value,
            fields: ['firstName', 'lastName'],
          },
        }
        if (props.isOnPurchasedCourses && props.coachIds.length > 0) {
          filter = {
            ...filter,
            _id: {
              type: 'arrayIn',
              value: props.coachIds,
            },
            companyId: {
              type: 'exact',
              value: null,
            },
          }
        } else
          filter = {
            ...filter,
            companyId: {
              type: props.tabValue == 1 ? 'notEqual' : 'exact',
              value:
                props.isOnStudentProfile && props.companyId
                  ? props.companyId
                  : props.tabValue == 1
                    ? null
                    : state.selectedCompanyId,
            },
          }
        if (props.companyId && props.isOnStudentProfile)
          fetchCoachesByCompanyId({ variables: { companyId: props.companyId } })
        else
          fetchCoach({
            variables: {
              role: 'COACH',
              filter: {
                ...filter,
              },
            },
          })
        // fetchCoach({
        //   variables: {
        //     role: 'COACH',
        //     filter: {
        //       search: {
        //         type: 'search',
        //         value,
        //         fields: ['firstName', 'lastName'],
        //       },
        //       companyId: {
        //         type: props.tabValue == 1 ? 'notEqual' : 'exact',
        //         value:
        //           props.tabValue == 1
        //             ? null
        //             : props.companyId || state.selectedCompanyId,
        //       },
        //     },
        //   },
        // })
      }

      if (field === 'company') {
        fetchCompany({
          variables: {
            filter: {
              name: {
                type: 'match',
                value,
              },
            },
          },
        })
      }

      if (field === 'group') {
        let filter = {
          name: {
            type: 'match',
            value,
          },
          company: {
            type:
              !!props.companyId && !props.isOnStudentProfile
                ? 'notEqual'
                : props.tabValue == 1
                  ? 'notEqual'
                  : 'exact',
            value:
              props.companyId ||
              (props.tabValue == 1 ? null : state.selectedCompanyId),
          },
        }

        fetchGroup({
          variables: {
            filter: {
              ...filter,
            },
            perPage: 0,
          },
        })
      }
      // fetchGroup({
      //   variables: {
      //     filter: {
      //       name: {
      //         type: 'match',
      //         value,
      //       },
      //       company: {
      //         type: props.tabValue == 1 ? 'notEqual' : 'exact',
      //         value:
      //           props.tabValue == 1
      //             ? null
      //             : props.companyId || state.selectedCompanyId,
      //       },
      //     },
      //     perPage: 0,
      //   },
      // })
    }, 200)

  const handleSelectChange = (e: string, field: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e, field)
    }
  }

  const skillList =
    skillsData &&
    skillsData.getAllSkills.map((i: any) => ({
      label: i.label,
      id: i.id,
      value: i.id,
    }))

  const categoriesList =
    categoriesData &&
    categoriesData.getCategories.map((category: any) => ({
      label: category.name,
      value: category.id,
      id: category.id,
    }))

  const { t } = props

  const theme = useTheme();

  return (
    <>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>
          {t('general.advanced_filters')} - {t('actions.to_filter')}{' '}
          {t('general.courses')}
        </DrawerTitle>
        <IconButton onClick={props.onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent margin='100px'>
        <FormContainer>
          <FormGroupWrapper>
            <FormGroup onSubmit={(e: any) => e.preventDefault()}>
              <FormGroupItem>
                <Input
                  label={t('form_fields.course_name')}
                  name="name"
                  size="small"
                  type="text"
                  fullWidth
                  value={filterData.name}
                  onChange={handleFieldChange('name')}
                />
              </FormGroupItem>

              <FormGroupItem>
                <FormItemContainer>
                  <Label>{t('general.categories')}</Label>
                  <SelectField
                    isMulti
                    label="categories"
                    name="categories"
                    options={categoriesList}
                    value={filterData.categories}
                    onChange={handleFieldChange('categories')}
                    placeholder={t('form_fields.select')}
                  />
                </FormItemContainer>
              </FormGroupItem>

              <FormGroupItem>
                <FormItemContainer>
                  <Label>
                    {t('courses_layout.published')}/
                    {t('courses_layout.unpublished')}
                  </Label>
                  <SelectField
                    label={t('form_fields.select')}
                    name="state"
                    options={courseState}
                    value={filterData.state}
                    onChange={handleFieldChange('state')}
                  />
                </FormItemContainer>
              </FormGroupItem>

              <FormGroupItem>
                <FormItemContainer>
                  <Label>{t('form_fields.course_level')}</Label>
                  <SelectField
                    label="Level"
                    name="level"
                    options={courseLevels}
                    value={filterData.level}
                    onChange={handleFieldChange('level')}
                  />
                </FormItemContainer>
              </FormGroupItem>

              <FormGroupItem>
                <FormItemContainer>
                  <Label>{t('form_fields.intro_video')}</Label>
                  <SelectField
                    onChange={handleFieldChange('video')}
                    options={selectOptions}
                    value={filterData.video}
                  />
                </FormItemContainer>
              </FormGroupItem>

              <FormGroupItem>
                <FormItemContainer>
                  <Label>{t('general.coach')}</Label>
                  <AsyncSelect
                    isMulti
                    onInputChange={(e: string) => handleSelectChange(e, 'coach')}
                    onChange={handleFieldChange('coaches')}
                    value={filterData.coaches}
                    label={t('form_fields.type_to_search_coaches')}
                    data={
                      props.isOnStudentProfile &&
                        coachesByCompanyIdData?.getCoachesByCompany
                        ? coachesByCompanyIdData.getCoachesByCompany
                        : (coachData && coachData.usersByRole.data) || []
                    }
                    loading={
                      props.isOnStudentProfile ? coachingLoading : userLoading
                    }
                    labelDataName={generateFullName}
                    valueDataKey="id"
                  />
                </FormItemContainer>
              </FormGroupItem>

              <FormGroupItem>
                <FormItemContainer>
                  <Label>{t('general.skills')}</Label>
                  <SelectField
                    isMulti
                    value={filterData.skills}
                    placeholder={t('form_fields.select')}
                    options={skillList}
                    onChange={handleFieldChange('skills')}
                  />
                </FormItemContainer>
              </FormGroupItem>

              {!props.isCoursePrivacyHidden && (
                <FormGroupItem>
                  <FormItemContainer>
                    <Label>
                      {t('general.course')} {t('course_details.privacy')}
                    </Label>
                    <SelectField
                      label={t('course_details.privacy')}
                      name="coursePrivacy"
                      options={coursePrivacy}
                      value={filterData.coursePrivacy}
                      onChange={handleFieldChange('coursePrivacy')}
                    />
                  </FormItemContainer>
                </FormGroupItem>
              )}
              {props.tabValue === 1 && (
                <FormGroupItem>
                  <FormItemContainer>
                    <Label>{t('general.company')}</Label>
                    <AsyncSelect
                      isMulti
                      onInputChange={(e: string) =>
                        handleSelectChange(e, 'company')
                      }
                      onChange={handleFieldChange('company')}
                      data={(companyData && companyData.companies.data) || []}
                      value={filterData.company}
                      label={t('form_fields.type_to_search_companies')}
                      loading={companyLoading}
                      labelDataKey="name"
                      valueDataKey="id"
                    />
                  </FormItemContainer>
                </FormGroupItem>
              )}

              <FormGroupItem>
                <FormItemContainer>
                  <Label>{t('general.groups')}</Label>
                  <AsyncSelect
                    isMulti
                    onInputChange={(e: string) => handleSelectChange(e, 'group')}
                    onChange={handleFieldChange('group')}
                    data={(groupData && groupData.getAllGroups.data) || []}
                    value={filterData.group}
                    label={t('form_fields.type_to_search_groups')}
                    loading={groupLoading}
                    labelDataKey="name"
                    valueDataKey="id"
                  />
                </FormItemContainer>
              </FormGroupItem>

              <FormGroupItem>
                <Typography id="range-slider" gutterBottom>
                  {t('course_details.number_of_students')}
                </Typography>
                <Slider
                  value={studentRange}
                  onChange={(e: any, i: any) => setStudentRange(i)}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  min={0}
                  max={300}
                />
              </FormGroupItem>
            </FormGroup>
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.clear')}
                type="medium"
                onClick={() => {
                  props.resetFilter()
                  props.onClose()
                }}
                background="#E0E1E2"
                textColor="#414141"
              />

              <Button
                text={t('actions.filter')}
                type="medium"
                color="secondary"
                onClick={onFormSubmit}
                background={mainColor}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent >
    </>
  )
}

export default FilterDrawer

// const Container = styled.div`
//   box-sizing: border-box;
//   height: 100%;
//   overflow-y: scroll;
//   padding-bottom: 70px;
//   padding-top: 100px;
//   position: relative;
// `

const FormItemContainer = styled.div`
  position: relative;
`
const Label = styled.div`
  position: absolute;
  background: #fff;
  color: rgba(0, 0, 0, 0.54);
  z-index: 1;
  line-height: 1;
  top: -7px;
  font-size: 13px;
  left: 10px;
`
