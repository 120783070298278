import StepConnector from '@material-ui/core/StepConnector'
import { StepIconProps } from '@material-ui/core/StepIcon'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import DomainIcon from '@material-ui/icons/Domain'
import GroupIcon from '@material-ui/icons/Group'
import PersonIcon from '@material-ui/icons/Person'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import WidgetsIcon from '@material-ui/icons/Widgets'
import clsx from 'clsx'
import { mainColor } from 'components/common/Colors/CommonColors'
import Stepper from 'components/common/Stepper'
import React from 'react'
import styled, { css } from 'styled-components'

interface Props {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  completedSteps: number[]
  t: any
}

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    background: mainColor,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    background: mainColor,
    cursor: 'pointer',
  },
})

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      background: mainColor,
    },
  },
  completed: {
    '& $line': {
      background: mainColor,
    },
  },
  line: {
    height: 4,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector)

const WizardStepper = ({ step, completedSteps, setStep, t }: Props) => {
  function ColorlibStepIcon(props: StepIconProps) {
    const classes = useColorlibStepIconStyles()
    const { active, completed } = props

    const icons: { [index: string]: React.ReactElement } = {
      1: <>1</>,
      2: <>2</>,
      3: <>3</>,
      4: <>4</>,
      5: <>5</>,
      6: <>6</>,
      7: <>7</>,
    }

    return (
      <SWizardStepper active={active as boolean}>
        {icons[String(props.icon)]}
      </SWizardStepper>
    )
  }
  const stepTitles = [
    t('company_wizard_layout.company_details'),
    t('company_wizard_layout.invite_company_super_admins'),
    t('general.purchased_courses'),
    t('company_wizard_layout.group_admins'),
    t('company_wizard_layout.group_of_students'),
    t('general.students'),
    t('company_wizard_layout.assign_coaches'),
  ]
  return (
    <Container>
      <Stepper
        active={step}
        steps={stepTitles}
        setStep={setStep}
        completedSteps={completedSteps}
        StepIconComponent={ColorlibStepIcon}
        ColorlibConnector={() => <></>}
      />
    </Container>
  )
}

export default WizardStepper

const Container = styled.div`
  position: relative;
  .MuiPaper-root {
    background: transparent !important;
  }
`

const SWizardStepper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 42px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #080a0a14;
  box-shadow: none;
  color: #707070;
  font-size: 20px;
  transition: 0.3s;
  border-radius: 100%;

  ${({ active }) =>
    active &&
    css`
      width: 54px;
      height: 54px;
      background: #317bf4;
      color: #ffffff;
      font-size: 28px;
      &::after {
        content: '';
        position: absolute;
        border: 1px solid #707070;
        border-collapse: separate;
        border-radius: 100%;
        width: 60px;
        height: 60px;
      }
    `}
`
