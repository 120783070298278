import React, { useEffect, useState } from 'react'
import axios from 'axios'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useQuery } from '@apollo/react-hooks'
import { DrawerEventEmitter } from 'helpers/drawer'
import { SET_FORMDATA } from 'store/types'
import { CourseSchema } from 'helpers/validationSchemas'
import { useTranslation } from 'react-i18next'

import 'cropperjs/dist/cropper.css'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  CloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'
import { FormContainer } from 'components/common/Form'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { useUserValue } from 'context/UserContext'

import { useData } from 'context/DataContext'

import {
  useUploadImage,
  useGenerateUploadVideoLinks,
  useUploadFile,
  useGenerateCustumUploadVideoLinks,
} from 'hooks/helpers/useHelpersService'
import { useEditCourse } from 'hooks/course/useEditCourse'
import { SET_CREATED_USER } from 'store/types'
import { GET_COURSE } from 'gql/course/course.query'
import { GET_ALL_SKILLS } from 'gql/skilss.query'
import Stepper from 'components/common/Stepper'

import Tab1 from './Tab1'
import Tab2 from './Tab2'
import Loader from 'components/common/Loader'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import AssignCoach from '../AddCourseDrawer/AssignCoach'
import styled, { css } from 'styled-components'
import { useTheme } from '@material-ui/core'
import { triggerVideoConverter } from 'services/mediaEncoding'
import useSnackbarAlert from 'hooks/useSnackbar'

interface Props {
  title: string
  id: string
  drawerName: string
  defaultStep?: number
  refetchCourses?: any
  maxIndex?: number
}

const formData = {
  name: '',
  description: '',
  editorText: '',
  level: null,
  coursePrivacy: '',
  coachType: '',
  coaches: [],
  skills: [],
  groups: [],
  avatar: null,
  certificate: null,
  price: '',
  currency: '',
  video: null,
  subtitle: null,
  categories: [],
  contentLocked: false,
  certificateIncluded: false,
  index: ''
}

const validationSchema = {
  name: Yup.string().required('Name is Required'),
  avatar: Yup.object()
    .required('Cover Image is Required')
    .nullable(),
}

const EditCourseDrawer = ({ title, id, drawerName, defaultStep, refetchCourses, maxIndex }: Props) => {

  const { t } = useTranslation()
  const [state, dispatch] = useData()
  const [contextState] = useUserValue()
  const [currentCourse, setCurrentCourse] = useState<any>('')
  const [step, setStep] = useState<number>(1)
  const [buttonEvent, setButtonEvent] = useState('')
  const [file, setFile] = useState<any>('')
  const [introVideo, setIntroVideo] = useState<any>('')
  const [progress, setProgress] = useState<number>(0)
  const [videoLinks, setVideoLinks] = useState<any>('')
  const [cropperOpen, setCropperOpen] = useState(false)
  const [certificateIncluded, setCertificateIncluded] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState<any>([])
  const [firstFetch, setFirstFetch] = useState(true)

  const { generateCustumUploadVideoLinks } = useGenerateCustumUploadVideoLinks()
  const { uploadImage, imageLoading } = useUploadImage()
  const { uploadFile, fileLoading } = useUploadFile()
  const { setSnackbar } = useSnackbarAlert()

  const { data: course, loading, refetch } = useQuery(GET_COURSE, {
    variables: { courseId: id },
  })

  const { data: skillsData } = useQuery(GET_ALL_SKILLS)

  const { editCourse, editCourseLoading, updatedCourse } = useEditCourse({
    courseId: id,
  })

  const [validationData, setValidationData] = useState<any>(validationSchema)

  const courseData = course && course.getCourseById
  useEffect(() => {
    if (!!defaultStep) {
      setStep(1)
    }
  }, [defaultStep])

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: Yup.object(CourseSchema),
    onSubmit: (values) => {


      if (step === 0 && buttonEvent === 'next') {

        handleChangeStep(1)
        return
      }

      if (step === 1 && buttonEvent === 'previous') {
        handleChangeStep(0)
        return
      }



      const data: any = { ...values }


      if (course && course.getCourseById.finished !== 3) {
        data.finished = step

        if (step === 2 && buttonEvent === 'next') {
          data.finished = 3
        }
      }

      data.skills = data.skills.map((skill: any) => {
        return { label: skill.label }
      })

      let categoriesValues: any = []

      data.categories.map((e: any) => {
        categoriesValues.push({ name: e.label })
      })
      data.categories = categoriesValues

      if (data.avatar) delete data.avatar.__typename

      if (data.certificate) delete data.certificate.__typename

      if (data.subtitle) delete data.subtitle.__typename

      if (data.video) {
        const { __typename, ...args } = data.video
        data.video = {
          id: args.id,
          title: args.title,
        }
      }

      if (!data.price) {
        data.price = null
      } else {
        data.price = parseFloat(data.price)
      }

      if (data.level) data.level = data.level.value

      data.coaches =
        data.coaches && data.coaches.length > 0
          ? data.coaches.map((item: any) => item.value)
          : []

      data.groups = selectedGroup
      data.description = values.description



      const groupIds = selectedGroup.map((i: any) => i.groupId)
      editCourse(id, data, groupIds, () => {


        if (step === 1 && buttonEvent === 'next') {
          handleChangeStep(2)
        }

        if (step === 2) {
          closeDrawer()
        }
      })
    },
  })

  useEffect(() => {
    if (values.price) {
      setValidationData({
        ...validationData,
        currency: Yup.string().required(
          `${t('form_fields.currency')} ${t('validations.required')}`,
        ),
      })
    } else {
      setValidationData(validationSchema)
    }
  }, [values.price])

  const uploadVideoService = (url: string, videoKey: string) => {
    const formData = new FormData();

    formData.append("file", introVideo);

    return axios.put(url, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: ({ total, loaded }) => {
        setProgress((loaded / total) * 100)
      },
    }).then((res) => {
      const message = `Video upload success, conversion ${res.data}`
      const variant = 'success'

      setSnackbar({ message, variant })
    }).catch((err) => {
      const message = `${err}`
      const variant = 'error'
      console.log("err", err)
      setSnackbar({ message, variant })
    })
  }

  useEffect(() => {
    if (state.createdObject.obj && state.createdObject.type === 'group') {
      setSelectedGroup([
        {
          name: state.createdObject.obj.name,
          groupId: state.createdObject.obj.id,
        },
        ...selectedGroup,
      ])

      setTimeout(() => {
        dispatch({
          type: SET_CREATED_USER,
          payload: { obj: null, type: '' },
        })
      }, 100)
    }
  }, [state.createdObject.obj])

  const cropperModalToggle = () => {
    setCropperOpen(!cropperOpen)
  }

  const handleCropSave = (field: string, croppedFile: string) => {
    uploadImage(
      croppedFile,
      field === 'avatar' ? 'course/avatar' : 'course/certificate',
      (link: string) => setFieldValue(field, link),
    )
  }

  const selectGroup = (field: string[], event?: string) => {
    setSelectedGroup([...selectedGroup, ...field])
  }

  const handleDeleteGroup = (ids: string[]) => {
    const groups = selectedGroup.filter(
      (i: any) => ids.findIndex((e: any) => i.groupId === e) === -1,
    )
    setSelectedGroup(groups)
  }

  const closeDrawer = () => {
    DrawerEventEmitter.emit('openDrawer', 'editCourse', false)

    refetchCourses()
  }

  const handleCloseDrawer = () => {


    values.groups = selectedGroup
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editCourse',
        values,
        compareTo: currentCourse,
      },
    })
    // refetchCourses()
  }

  const handleGenerateUploadLink = () => {
    const videos = [{ fileName: introVideo.name, type: introVideo.type }]
    generateCustumUploadVideoLinks(
      videos,
      'course',
      uploadVideoService,
      (args: any) => {
        setFieldValue('video', args.video)
        setFieldValue('video.title', args.video.name)
        setProgress(0)
      },
    )
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  useEffect(() => {
    if (introVideo) {
      handleGenerateUploadLink()
    }
  }, [introVideo])

  // useEffect(() => {
  //   if (updatedCourse) {
  //     refetch()
  //     closeDrawer()
  //     toggleDrawerConfirm(false, '')
  //   }
  // }, [updatedCourse])

  useEffect(() => {

    if (course && course.getCourseById && firstFetch) {
      const courseData = course.getCourseById
      const dataClone: any = {}

      for (const key in formData) {
        if (courseData[key]) {
          if (key === 'skills' && courseData[key]) {
            dataClone[key] = courseData[key].map((i: any) => ({
              label: i.label,
              value: i.label,
            }))
          }

          if (key === 'coaches' && courseData[key]) {
            dataClone[key] = courseData[key].map((i: any) => ({
              label: `${i.firstName} ${i.lastName}`,
              value: i.userId,
            }))
          }

          if (!['skills', 'coaches'].includes(key)) {
            dataClone[key] = courseData[key]
          }
        }

        if (courseData.certificateIncluded) setCertificateIncluded(true)

        dataClone.coursePrivacy = state.selectedCompanyId
          ? dataClone.coursePrivacy
          : 'public'
      }

      if (dataClone.level) {
        dataClone.level = {
          label: t(`courses_layout.${dataClone.level}`),
          value: dataClone.level,
        }
      }

      if (dataClone.groups && dataClone.groups.length > 0) {
        const groups = dataClone.groups.map((group: any) => ({
          groupId: group.groupId,
          name: group.name,
        }))
        setSelectedGroup(groups)
      }
      dataClone.categories = []
      courseData.categories.map((category: any) =>
        dataClone.categories.push({
          value: category.id,
          label: category.name,
        }),
      )




      setValues(dataClone)
      setCurrentCourse(dataClone)

      setStep(courseData.finished < 3 ? courseData.finished : defaultStep || 0)
      setFirstFetch(false)
    }
  }, [course, defaultStep])

  const handleImageChange = (file: any, field: string, fileName: string) => {
    if (!file) {
      setFieldValue(field, null)
      return
    }
    uploadImage(file, `course/${field}`, (link: string) =>
      setFieldValue(field, {
        name: Date.now().toString(),
        link: link,
        fileType: fileName.split('.').pop(),
      }),
    )
  }

  const handleVideoChange = (e: any) => {
    setIntroVideo(e ? e.target.files[0] : '')
    if (e) {
      // const file = e.target.files[0]
      // const reader: any = new FileReader()
      // reader.onload = function() {
      //   const media = new Audio(reader.result)
      //   media.onloadedmetadata = function() {
      // setVideoDuration(0)
      //   }
      // }
      // reader.readAsDataURL(file)
    } else {
      setIntroVideo(null)
      setFieldValue('video', null)
      setProgress(0)
    }
  }

  const handleUploadFile = (e: any, field: string) => {
    const { files } = e.target
    const file = files[0]

    uploadFile(file, `course/${field}`, (link: string) => {
      setFieldValue(field, {
        name: file.name,
        link: link,
        fileType: file.name.split('.').pop(),
      })
    })
  }

  const skillList =
    skillsData &&
    skillsData.getAllSkills.map((i: any) => ({
      label: i.label,
      value: i.label,
    }))

  const currentUser = contextState.currentUser

  const handleChangeStep = (activeStep: number) => {

    setStep(activeStep)
  }

  const handleButtonClick = (e: string) => {
    setButtonEvent(e)
    handleSubmit()
  }

  const courseWithCoach = { ...courseData, ...{ coaches: values.coaches } }

  const courseCompanyId =
    (courseData && courseData.companyId && courseData.companyId.id) || null
  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <Tab1
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleVideoChange={handleVideoChange}
            videoLinks={videoLinks}
            setVideoLinks={setVideoLinks}
            videoUploadProgress={progress}
            setProgress={setProgress}
            introVideo={introVideo}
            setIntroVideo={setIntroVideo}
            setFieldValue={setFieldValue}
            isCompanyMode={state.selectedCompanyId ? true : false}
            skillList={skillList}
            handleImageChange={handleImageChange}
            uploadImage={uploadImage}
            imageLoading={imageLoading}
            certificateIncluded={certificateIncluded}
            setCertificateIncluded={setCertificateIncluded}
            handleUploadFile={handleUploadFile}
            fileLoading={fileLoading}
            courseData={courseData}
            handleButtonClick={handleButtonClick}
            handleDeleteCourse={handleCloseDrawer}
            manualCompanyId={courseCompanyId}
            t={t}
            maxIndex={maxIndex}
            handleCloseDrawer={closeDrawer}
          />
        )
      case 1:
        return (
          <Tab2
            selectedGroup={selectedGroup}
            selectGroup={selectGroup}
            deleteGroup={handleDeleteGroup}
            handleImageChange={handleImageChange}
            handleDeleteCourse={handleCloseDrawer}
            handleButtonClick={handleButtonClick}
            step={(courseData && courseData.finished) || 0}
            handleChangeStep={(num: number) => handleChangeStep(step - num)}
            manualCompanyId={courseCompanyId}
            editMode={!!defaultStep}
            isCoursePurchased={courseData?.isBought}
            t={t}
          />
        )

      case 2:
        return (
          <AssignCoach
            values={courseWithCoach}
            selectedGroup={selectedGroup}
            courseId={id}
            handleDeleteCourse={handleCloseDrawer}
            handleButtonClick={handleButtonClick}
            handleChangeStep={(num: number) => handleChangeStep(step - num)}
          />
        )
      default:
        return 'Error...!'
    }
  }

  const stepTitles = [
    t('course_details.course_details'),
    t('general.groups'),
    t('general.assign'),
  ]

  const theme = useTheme()

  return (
    <>
      {(loading || editCourseLoading) && (
        <Loader withBackground={loading ? true : false} />
      )}
      <div>
        <DrawerHeader theme={theme}>
          <DrawerTitle theme={theme}>{title}</DrawerTitle>
          <CloseIcon
            onClick={handleCloseDrawer}
            filename={'close'}
            fill={'#707070'}
            stroke={'transparent'}
            width={36}
            height={36}
            cursor={'pointer'}
          />
          <Info />
        </DrawerHeader>

        <StepperContainer>
          <Stepper
            active={step}
            steps={stepTitles}
            setStep={setStep}
            ColorlibConnector={() => <></>}
            StepIconComponent={(props: any) => {
              const icons: { [index: string]: React.ReactElement } = {
                1: <>1</>,
                2: <>2</>,
                3: <>3</>,
              }

              return (
                <SWizardStepper active={props.active as boolean}>
                  {icons[String(props.icon)]}
                </SWizardStepper>
              )
            }}
          />
        </StepperContainer>
      </div>
      <div>
        <FormContainer onSubmit={(e: any) => e.preventDefault()}>
          {getStepContent(step)}
        </FormContainer>
      </div>
      <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
        <ImageCropper
          file={file}
          hideCropper={cropperModalToggle}
          getCroppedFile={handleCropSave}
        />
      </Modal>
    </>
  )
}

export default EditCourseDrawer

const StepperContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiPaper-root {
    background: transparent !important;
  }
`

const SWizardStepper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 42px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #080a0a14;
  box-shadow: none;
  color: #707070;
  font-size: 20px;
  transition: 0.3s;
  border-radius: 100%;

  ${({ active }) =>
    active &&
    css`
      width: 54px;
      height: 54px;
      background: #317bf4;
      color: #ffffff;
      font-size: 28px;
      &::after {
        content: '';
        position: absolute;
        border: 1px solid #707070;
        border-collapse: separate;
        border-radius: 100%;
        width: 60px;
        height: 60px;
      }
    `}
`
