import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import DashboardIcon from '@material-ui/icons/Dashboard'
import GavelIcon from '@material-ui/icons/Gavel'
import GroupIcon from '@material-ui/icons/Group'
import LibraryBooksRoundedIcon from '@material-ui/icons/LibraryBooksRounded'
import PersonIcon from '@material-ui/icons/Person'
import PersonRoundedIcon from '@material-ui/icons/PersonRounded'
import PhonelinkIcon from '@material-ui/icons/Phonelink'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import WidgetsIcon from '@material-ui/icons/Widgets'
import SchoolIcon from '@material-ui/icons/School'
import styled from 'styled-components'
import Icon from 'components/Icon'

const CoursesIcon = withStyles({
  root: {
    // marginTop: "-2px",
    // marginRight: "20px",
    // fontSize: "24px"
  },
})(LibraryBooksRoundedIcon)

const getAllNavigation = (permission, currentUser) => [
  {
    title: 'dashboard',
    path: '',
    icon: (
      <NavIcon>
        <Icon filename={'dashboard'} />
      </NavIcon>
    ),
    canView: permission.viewDashboardPage,
  },
  {
    title: currentUser.companyId ? 'company' : 'companies',
    path: currentUser.companyId
      ? `/Companies/${currentUser.companyId.id}`
      : '/Companies',
    icon: (
      <NavIcon>
        <Icon filename={'companies'} />
      </NavIcon>
    ),
    canView: permission.viewCompanyPage,
  },
  {
    title: 'users',
    icon: (
      <NavIcon>
        <Icon filename={'users'} />
      </NavIcon>
    ),
    canView: true,
    children: [
      {
        title: 'super_admins',
        path: '/users/super-admins',
        icon: (
          <NavIcon>
            <Icon filename={'superAdmin'} />
          </NavIcon>
        ),
        canView: permission.viewSuperAdminPage,
      },
      {
        title: 'admins',
        path: '/users/admins',
        icon: (
          <NavIcon>
            <Icon filename={'admins'} />
          </NavIcon>
        ),
        canView: permission.viewAdmin,
      },
      {
        title: 'coaches',
        path: '/users/coaches',
        icon: (
          <NavIcon>
            <Icon filename={'coaches'} />
          </NavIcon>
        ),
        canView: permission.viewCoach,
      },
      {
        title: 'students',
        path: '/users/students',
        icon: (
          <NavIcon>
            <Icon filename={'students'} />
          </NavIcon>
        ),
        canView: permission.viewStudent,
      },
    ],
  },
  {
    title: 'groups',
    path: '/groups',
    icon: (
      <NavIcon>
        <Icon filename={'groups2'} />
      </NavIcon>
    ),
    canView: true,
  },
  {
    title: 'learning',
    icon: (
      <NavIcon>
        <Icon filename={'learning'} />
      </NavIcon>
    ),
    canView: true,
    children: [
      {
        title: 'view_all_courses',
        path: '/courses',
        icon: (
          <NavIcon>
            <Icon filename={'courses'} />
          </NavIcon>
        ),
        canView: permission.viewCourse,
      },
      {
        title: 'modules',
        path: '/modules',
        icon: (
          <NavIcon>
            <Icon filename={'modules'} />
          </NavIcon>
        ),
        canView: permission.viewModule,
      },
    ],
  },
  {
    title: 'evaluation',
    icon: (
      <NavIcon>
        <Icon filename={'testing'} />
      </NavIcon>
    ),
    canView: permission.viewQuestion,
    children: [
      {
        title: 'questions',
        path: '/questions',
        icon: (
          <NavIcon>
            <Icon filename={'questions'} />
          </NavIcon>
        ),
        canView: permission.viewQuestion,
      },
      {
        title: 'quizzes',
        path: '/quizzes',
        icon: (
          <NavIcon>
            <Icon filename={'quizzes'} />
          </NavIcon>
        ),
        canView: permission.viewQuiz,
      },
    ],
  },
  {
    title: 'settings',
    path: '/Settings',
    icon: (
      <NavIcon>
        <Icon filename={'settings'} />
      </NavIcon>
    ),
    canView: permission.viewSettingPage,
    children: [
      {
        title: 'transactions',
        path: '/transactions',
        icon: (
          <NavIcon>
            <Icon filename={'settings'} />
          </NavIcon>
        ),
        canView: permission.viewSettingPage,
      },
      {
        title: 'add_section',
        path: '/sections',
        icon: (
          <NavIcon>
            <Icon filename={'add'} />
          </NavIcon>
        ),
        canView: permission.viewSettingPage && permission.createNewSection,
      },
      {
        title: 'subscription',
        path: '/subscriptions',
        icon: (
          <NavIcon>
            <Icon filename={'add'} />
          </NavIcon>
        ),
        canView: permission.viewSettingPage && permission.createNewSection,
      },
    ],
  },
]

const getNavigationForCompanySuperAdminAndAdmin = (permission, currentUser) => [
  {
    title: 'users',
    icon: (
      <NavIcon>
        <Icon filename={'users'} />
      </NavIcon>
    ),
    canView: true,
    children: [
      {
        title: 'coaches',
        path: '/users/coaches',
        icon: (
          <NavIcon>
            <Icon filename={'coaches'} />
          </NavIcon>
        ),
        canView: permission.viewCoach,
      },
      {
        title: 'students',
        path: '/users/students',
        icon: (
          <NavIcon>
            <Icon filename={'students'} />
          </NavIcon>
        ),
        canView: permission.viewStudent,
      },
      {
        title: 'groups',
        path: '/groups',
        icon: (
          <NavIcon>
            <Icon filename={'groups'} />
          </NavIcon>
        ),
        canView: true,
      },
      {
        title: 'group_admins',
        path: '/users/admins',
        icon: (
          <NavIcon>
            <Icon filename={'groups'} />
          </NavIcon>
        ),
        canView: permission.viewAdmin,
      },
      {
        title: 'super_admins',
        path: '/users/super-admins',
        icon: (
          <NavIcon>
            <Icon filename={'superAdmin'} />
          </NavIcon>
        ),
        canView: permission.viewSuperAdminPage,
      },
    ],
  },
  {
    title: 'learning',
    icon: (
      <NavIcon>
        <Icon filename={'learning'} />
      </NavIcon>
    ),
    canView: true,
    children: [
      {
        title: 'view_all_courses',
        path: '/courses',
        icon: (
          <NavIcon>
            <Icon filename={'courses'} />
          </NavIcon>
        ),
        canView: permission.viewCourse,
      },
      {
        title: 'modules',
        path: '/modules',
        icon: (
          <NavIcon>
            <Icon filename={'modules'} />
          </NavIcon>
        ),
        canView: permission.viewModule,
      },
      {
        title: 'questions',
        path: '/questions',
        icon: (
          <NavIcon>
            <Icon filename={'questions'} />
          </NavIcon>
        ),
        canView: permission.viewQuestion,
      },
      {
        title: 'quizzes',
        path: '/quizzes',
        icon: (
          <NavIcon>
            <Icon filename={'quizzes'} />
          </NavIcon>
        ),
        canView: permission.viewQuiz,
      },
    ],
  },
]

export const getNavigation = (permission, currentUser) =>
  !(currentUser?.role === 'SUPER_ADMIN' && !!currentUser?.companyId?.id) &&
  !(currentUser?.role === 'ADMIN' && !!currentUser?.companyId?.id)
    ? getAllNavigation(permission, currentUser)
    : getNavigationForCompanySuperAdminAndAdmin(permission, currentUser)

export const NavIcon = styled.div`
  width: 36px;
  height: 36px;
  background: #ffffff0a;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  svg {
    fill: #ffffff;
    transition: 0.3s;
  }
`
