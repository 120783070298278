import { useMutation } from '@apollo/react-hooks'
import { DELETE_USER } from 'gql/users.query'

const deleteUserService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteUserMutation, { loading, error }] = useMutation(DELETE_USER, {
    // update(cache, { data: { deleteUser: document } }) {
    //   updateCache({
    //     objectName: 'usersByRole',
    //     query: GET_USERS,
    //     cache,
    //     document,
    //     action: 'delete',
    //     params
    //   })
    // }
  })

  return {
    deleteUserMutation,
    loading,
    error,
  }
}

export default deleteUserService
