import { useMutation } from '@apollo/react-hooks'

import { EDIT_QUIZ, ADD_REMOVE_QUESTIONS } from 'gql/quiz/quiz.query'

export const editQuizService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editQuizMutation, { loading, error }] = useMutation(EDIT_QUIZ)

  return {
    editQuizMutation,
    loading,
    error,
  }
}

export const addRemoveQuestionsService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [questionsActionMutation, { loading, error }] = useMutation(ADD_REMOVE_QUESTIONS)

  return {
    questionsActionMutation,
    loading,
    error
  }
}
