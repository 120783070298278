import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useQuery } from '@apollo/react-hooks'
import { GET_GROUP } from 'gql/group/group.query'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import { columnConfig, actionConfig } from 'pages/group/gridConfig'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import useDeleteGroup from 'hooks/group/useDeleteGroup'

import IconButton from '@material-ui/core/IconButton'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import DeleteIcon from '@material-ui/icons/Delete'
import ArchiveIcon from '@material-ui/icons/Archive'
import { Wrapper } from '../styled-components'
import FilterDrawer from 'pages/group/FilterDrawer/FilterDrawer'
import Grid from 'components/common/Grid'
import { Input } from 'components/common/TextInput'
import Drawer from 'components/common/Drawer'
import AddGroupDrawer from 'pages/group/AddGroupDrawer/AddGroupDrawer'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import {
  GridToolbar,
  GridLayoutActions,
  AddButton,
  GridLayoutForm,
  ActionButton,
} from 'components/common/Grid/styled-components'
import Search from 'components/common/Search'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

interface Params {
  variables: any
  t: any
  companyId: string
}

const CompanyGroups = ({ variables, t, companyId }: Params) => {
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const { data, loading, error, refetch } = useQuery(GET_GROUP, {
    variables,
  })
  const { exportData } = useExportDataService()
  const { deleteGroupServ } = useDeleteGroup()
  const [searchValue, setSearchValue] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})
  const [closeDrawerClicked, setCloseDrawerClicked] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>('')
  const [filterDrawer, setFilterDrawer] = useState({
    filterOptions: {
      company: { type: 'exact', value: state?.currentUser?.companyId?.id },
    },
    filterValues: null,
    opened: false,
  })

  const selectItem = (id: never, event: boolean) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = groups.data.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const handleDeleteGroup = (id?: string) => {
    let ids = selectedItem

    if (id) ids = [id]

    if (ids.length <= 0) return

    const params = {
      title: `${selectAll?.['1'] ||
        (!!selectedItem?.['1'] && selectedItem?.['1'].length > 1)
        ? t('actions.remove_selected_groups')
        : !!selectedItem?.['1'] && selectedItem?.['1'].length > 0
          ? t('actions.remove_selected_group')
          : t('actions.remove_group_from_actions')
        }`,
      onConfirm: () => {
        deleteGroupServ(ids, refetch)
        setSelectedItem([])
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const handleExportData = () => {
    if (selectedItem.length > 0) {
      exportData('group', selectedItem, (link: string) => {
        window.open(link, '_blank')
      })
    }
  }

  const closeDrawer = () => setDrawerOpened(false)

  const handleCloseDrawer = () => setCloseDrawerClicked(true)

  const handleClick = (e: any, field: string) => {
    if (field === 'delete') {
      handleDeleteGroup(e)
      return
    }
    DrawerEventEmitter.emit('openDrawer', 'groupInformation', true, {
      id: e.id,
    })
  }

  const handleSearchChange = (e: any) => {
    if (e.currentTarget.value === '') {
      refetch({
        ...variables,
      })
    }
    setSearchValue(e.currentTarget.value)
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    let filterOptions = {}
    if (searchValue !== '') {
      filterOptions = {
        ...variables.filter,
        name: { type: 'match', value: searchValue },
      }
    } else {
      filterOptions = { ...variables.filter }
    }

    refetch({
      ...variables,
      filter: filterOptions,
    })
  }

  useEffect(() => {
    if (formData) {
      const isEmpty = _.values(formData).every(_.isEmpty)
      if (!isEmpty) {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            closeDrawer()
            setFormData('')
            setCloseDrawerClicked(false)
          },
          onClose: () => {
            setFormData('')
            setCloseDrawerClicked(false)
          },
          confirmText: 'Yes, Cancel!',
          cancelText: 'No',
        })
      }

      if (isEmpty) {
        closeDrawer()
        setFormData('')
        setCloseDrawerClicked(false)
      }
    }
  }, [formData])
  const theme = useTheme();

  const config = columnConfig(selectItem, selectedItem, true, t, theme)
  const actions = actionConfig(handleClick, state.userPermission, true, t)
  const groups = (data && data.getAllGroups) || {}

  if (loading) return <div>Loading...</div>
  return (
    <div style={{ paddingTop: 20 }}>
      {/* <div style={{ marginBottom: 20 }}>
        <Wrapper justify="between" align="center">
          <Wrapper align="center">
            {/* {state.userPermission.createGroup && (
              <Button
                text="Create Group"
                color="secondary"
                onClick={() => setDrawerOpened(true)}
                icon={<AddCircleRoundedIcon />}
              />
            )} 
            
            <GridMultipleActions
              selectedItems={selectedItem.length}
              actions={[
                {
                  id: 0,
                  color: 'secondary',
                  tooltipText: t('actions.delete'),
                  hide: !state.userPermission.deleteGroup,
                  disabled: selectedItem[currentPage]
                    ? selectedItem[currentPage].length < 1
                    : true,
                  onClick: () => handleDeleteGroup(),
                  component: <DeleteIcon fontSize="small" />,
                },
                {
                  id: 0,
                  color: 'secondary',
                  tooltipText: t('actions.export'),
                  hide: !state.userPermission.deleteGroup,
                  disabled: selectedItem[currentPage]
                    ? selectedItem[currentPage].length < 1
                    : true,
                  onClick: () => handleExportData(),
                  component: <ArchiveIcon fontSize="small" />,
                },
              ].filter((i: any) => !i.hide)}
            />
          </Wrapper>
          <form onSubmit={handleSearchSubmit}>
            <Input
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              icon={
                <IconButton>
                  <SearchRoundedIcon />
                </IconButton>
              }
              value={searchValue}
              onChange={handleSearchChange}
            />
          </form>
        </Wrapper>
      </div> */}
      <GridToolbar theme={theme}>
        <GridLayoutActions></GridLayoutActions>
        <GridLayoutForm>
          {`${selectedItem?.[currentPage]?.length || 0} ${t(
            'general.items_selected',
          )}`}
          <ActionButton
            onClick={() => handleDeleteGroup()}
            disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
            fill={'#CC3131'}
            disabledFill={'#080A0AD6'}
            theme={theme}
          >
            <Icon filename={'delete'} />
          </ActionButton>
          <Search
            inputProps={{
              value: searchValue,
              onChange: handleSearchChange,
            }}
            onClick={handleSearchSubmit}
          />
        </GridLayoutForm>
      </GridToolbar>
      <Grid
        title={t('general.groups')}
        data={groups?.data || []}
        config={config}
        redirectToGroupeDetails={handleClick}
        actionConfig={actions}
        selectAllItem={selectAllItem}
        selected={_.size(selectedItem[currentPage] || [])}
        selectedItems={selectedItem[currentPage] || []}
        onFiltersClick={() => {
          setFilterDrawer({ ...filterDrawer, opened: !filterDrawer.opened })
          setSelectedItem({})
          setSelectAll({})
        }}
        resetVisible={!!searchValue || !!filterDrawer?.filterValues}
        resetFilters={() => {
          setFilterDrawer({
            filterOptions: {
              company: {
                type: 'exact',
                value: state?.currentUser?.companyId?.id,
              },
            },
            filterValues: null,
            opened: false,
          })
          setCurrentPage(1)
          setSelectedItem({})
          setSelectAll({})
          setSearchValue('')
          refetch({ filter: { ...variables.filter } })
        }}
      />
      <Drawer
        opened={drawerOpened}
        toggleDrawer={handleCloseDrawer}
        totalWidth="44%"
      >
        <AddGroupDrawer
          refetch={() => {
            // refetch()
            setDrawerOpened(false)
          }}
          onCancel={() => setDrawerOpened(false)}
          setFormData={setFormData}
          closeDrawerClicked={closeDrawerClicked}
          data={{ companyId: variables?.filter?.company?.value || null }}
          companyId={variables?.filter?.company?.value || null}
        />
      </Drawer>

      <Drawer
        opened={filterDrawer.opened}
        toggleDrawer={() =>
          setFilterDrawer({ ...filterDrawer, opened: !filterDrawer.opened })
        }
        totalWidth="600px"
      >
        <FilterDrawer
          // hasSearchByCompany
          closeDrawer={() =>
            setFilterDrawer({ ...filterDrawer, opened: false })
          }
          filterOptions={filterDrawer}
          setFilterOptions={setFilterDrawer}
          refetchGroup={(args: any) => {
            refetch({
              filter: {
                ...variables.filter,
                ...args.filter,
              },
            })
          }}
          companyId={companyId}
          useCompanyId
          isCoursePurchased
        />
      </Drawer>
    </div>
  )
}

export default CompanyGroups
