import CIcon from "components/Icon";
import React, { FunctionComponent } from "react";
import TextInput from "../TextInput/TextInput";
import { useTranslation } from 'react-i18next'

import './TimeInput.scss'

interface TimeInputProps {
  time: number,
  label: string,
  value: string,
  onChange: (e: any) => void;
}

const TimeInput: FunctionComponent<TimeInputProps> = (props) => {
  const { t } = useTranslation()

  function timeConvert(duration: number) {

    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;


    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }


  return (
    <div className="time-input-wrapper">
      <div className="time" >
        <CIcon filename="timer" />
        <div></div>
        {timeConvert(props.time)}
      </div>
      <input value={props.value} onChange={props.onChange} className="input" type="text" placeholder={t('form_fields.title')} />
    </div>
  );
}

export default TimeInput;
