import { useParams, Redirect } from 'react-router-dom';
import React, { useLayoutEffect } from 'react'
import { getRole, getToken } from 'helpers/localStorage';

const RedirectComp = () => {

  const token = getToken()
  const role = getRole()
  let { courseId } = useParams<{ courseId: string }>();


  if (token) {

    return role === "STUDENT" ? <Redirect to={`/course/${courseId}`} /> : <Redirect to="/" />
  } else {
    localStorage.setItem('courseId', courseId);
    return <Redirect to="/login" />
  }

}

export default RedirectComp