import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useDeleteReview } from 'hooks/reviews/useDeleteReview';
import { Rating } from '@material-ui/lab'
import { CircularProgress } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton'
import Icon from 'components/Icon'
import styles from './SkillTestReviewCard.module.css';

import AudioPlayer from '../../../../../../components/common/AudioPlayer/AudioPlayer';
import VideoPlayer from 'components/common/VideoPlayer';
import { useTranslation } from 'react-i18next'
import { ISkillTestReview } from '../../personalhomework';
import config from '../../../../../../config/index'
import { dateConvertToLocaleDate } from 'utils/utils';

interface Props {
  profilePictureUrl: string
  isMyReview: boolean
  refetch: () => void
  review: ISkillTestReview
  // skillTestId: string
}

const SkillTestReviewCard: FC<Props> = ({ review, refetch, profilePictureUrl, isMyReview }) => {
  const { handleDeleteReview } = useDeleteReview(refetch);
  const { t } = useTranslation();
  const [isSkillVideoConverted, setIsSkillVideoConverted] = useState<boolean>(false);

  const { author, rate, description, createDate, id, attachedFile } = review;

  const handleDeleteConfirm = () => {
    if (!id) return;
    handleDeleteReview([id]);
  };

  let attachedFileElement;

  if (attachedFile && attachedFile.type && attachedFile.link) {
    const { type, link } = attachedFile;
    if (type === "pdf") {
      attachedFileElement = (
        <div style={{
          marginTop: '20px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}>
          <IconButton style={{
            border: '1px solid #F1F1F1',
            borderRadius: 15,
          }}
            onClick={() => window.open(link)}
          >
            <Icon filename='pdf-file' />
          </IconButton>
        </div>
      )
    } else if (type === "mp3") {
      attachedFileElement = (
        <div style={{
          marginTop: '20px',
        }}>
          <AudioPlayer url={link} needsBlackBackground={false} />
        </div>
      )
    } else if (type === "mp4") {
      if (!isSkillVideoConverted) {
        attachedFileElement = (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '50px 0 60px' }}>
            <CircularProgress size={50} color="primary" />
          </div>
        )
      } else {
        attachedFileElement = (
          <div className='w-full mt-5'>
            <VideoPlayer url={attachedFile.link} width={1000} isInDrawer={true} />
          </div>
        )
      }
    }
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    if (attachedFile?.type !== 'mp4' || !attachedFile?.link) return;

    const checkVideoConversionStatus = async (videoLink: string): Promise<any> => {
      try {
        const response = await axios.post(`${config.APIURL}/videos/check-video-conversion-status`, {
          videoLink
        }, {
          cancelToken: source.token,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        if (response.data) {
          setIsSkillVideoConverted(true);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('caught error')
        } else {
          console.log('error')
        }
      }
    };

    checkVideoConversionStatus(attachedFile.link);

    return () => {
      source.cancel()
    }
  }, [])

  const reviewCreationDate = dateConvertToLocaleDate(createDate);

  return (
    <div className={styles.skillTestReviewContainer}>
      <div className={styles.reviewDetails}>
        {profilePictureUrl
          ? <img className={styles.profilePictuire} src={profilePictureUrl} width="100%" alt="profile-picture" />
          : <IconButton
            className={styles.avatarIconButton}>
            <Icon className={styles.avatarIcon} filename={'profile'} fill={'#FFD700'} />
          </IconButton>}
        <div className={styles.reviewData}>
          <div className={styles.reviewDataSection}>
            <h5 className={styles.username}>{`${author.firstName} ${author.lastName}`}</h5>
            {isMyReview ? (
              <IconButton
                onClick={handleDeleteConfirm}
                className={styles.iconButton}
              >
                <Icon filename="delete" fill="red" />
              </IconButton>
            ) : ""}
          </div>
          <p style={{ marginBottom: 10 }}>{author.role === "COACH" ? `${t('general.coach')}` : `${t('general.course_student')}`}</p>
          <div className={styles.reviewDataSection}>
            <div>
              <Rating value={rate} />
              {rate <= 3 ? <span className={styles.lowRating}>{t('general.lowRating')}</span> : ""}
            </div>
            <div>
              <Icon filename="calendar" />
              {" "}
              <span style={{ marginLeft: 10 }}>{reviewCreationDate}</span>
            </div>
          </div>
        </div>
      </div>

      {attachedFileElement ? attachedFileElement : ""}
      <div className={styles.description}>{description}</div>
    </div>
  )
}

export default SkillTestReviewCard;
