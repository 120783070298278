import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_GROUP } from 'gql/group/group.query'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'

import Dropdown from 'components/common/Dropdown'
import { Button } from 'components/common/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { FormFooter, FormButtons } from 'components/common/Form'
import useAddStudentsInGroup from 'hooks/group/useAddStudentsInGroup'
import { validationRed, red } from 'components/common/Colors/CommonColors'

const MoveToAnotherGroup = (props: any) => {
  const { t } = useTranslation()
  const [state] = useUserValue()

  const [group, setGroup] = useState({
    name: '',
    id: '',
  })

  const { addStudentsInGroup } = useAddStudentsInGroup({
    role: 'STUDENT',
    companyId: props.companyId,
    filter: {},
  })

  const userGroups = state.currentUser?.group
  const { data, loading, error, refetch } = useQuery(GET_GROUP, {
    variables: {
      filter: {
        _id: {
          type: 'arrayNotIn',
          value: props.studentGroups,
        },
        company: {
          type: 'exact',
          value: props.companyId,
        },
      },
      perPage: 0,
    },
  })

  const handleSelect = (e: any) => {
    setGroup(e)
  }

  const onFormSubmit = () => {
    if (!group.id) return
    addStudentsInGroup(
      group.id,
      group.name,
      [props.studentId],
      refetch,
      props.studentListRefetch,
    )
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">
        {t('actions.move_to_another_group')}
      </DialogTitle>

      <DialogContent>
        {loading ? (
          t('general.loading')
        ) : (
          <Dropdown
            value={group.name}
            label={`${t('form_fields.select')} ${t('general.group')}`}
            list={data.getAllGroups.data}
            dataKey="name"
            handelSelect={handleSelect}
          />
        )}
      </DialogContent>

      <FormFooter>
        <FormButtons>
          <Button
            text={t('actions.cancel')}
            color="primary"
            type="medium"
            onClick={props.toggleDialog}
            background="#E0E1E2"
            textColor={validationRed}
            outline={`1px solid ${red}`}
          />
          <Button
            text={t('actions.save')}
            color="secondary"
            type="medium"
            onClick={onFormSubmit}
          />
        </FormButtons>
      </FormFooter>
    </>
  )
}

export default MoveToAnotherGroup
