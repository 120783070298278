import React, { useState, useEffect } from 'react'
import { Button } from 'components/common/Button'
import styled from 'styled-components'
import { useFormik } from 'formik'
import {
  TabNavigation,
  TabPanel,
  activeTabClassName,
} from 'components/common/Tabs/Tabs'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useHistory } from 'react-router-dom'

import {
  Container,
  Wrapper,
  FooterWrapper,
  ButtonWrapper,
} from '../styled-components'
import CreateAdmin from './CreateAdmin'
import AdminList from './AdminList'

import useAddUserServie from 'hooks/users/useAddUserService'
import { useFetchUsers } from 'hooks/users/useUsers'
import useCompaniesService from 'hooks/useCompaniesService'
import { useUserValue } from 'context/UserContext'
import { UserSchema } from 'helpers/validationSchemas'
import { mainColor } from 'components/common/Colors/CommonColors'
import DropdownButton from 'components/common/DropdownButton'
import { useTheme } from '@material-ui/core'
const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

const formData: any = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  gender: null,
  birthDate: '',
  note: '',
  role: 'ADMIN',
  avatar: '',
  jobTitle: '',
  location: '',
  phoneFields: null,
  phoneFieldValue: null,
}

const GroupAdmins = (props: any) => {
  const [state] = useUserValue()
  const [createAdmin, setCreateAdmin] = useState('')
  const [tabValue, setTabValue] = useState(0)
  const history = useHistory()
  const { setCompanyState } = useCompaniesService()

  const { companyData, setData } = props

  const { addUser, user, error, addUserLoading } = useAddUserServie({
    role: 'ADMIN',
    filter: {},
  })

  const { users, loading, refetch }: any = useFetchUsers('ADMIN', {
    companyId: { type: 'exact', value: companyData.companyData.id },
  })

  useEffect(() => {
    if (users && users.data) {
      setData({
        ...companyData,
        admins: users.data,
      })
    }
  }, [users])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: formData,
    validationSchema: UserSchema,
    onSubmit(values) {
      if (addUserLoading) return

      const data = { ...values }

      delete data.phoneFieldValue

      if (data.gender) data.gender = data.gender.value

      data.group = []

      if (data.phoneFields && data.phoneFields.label) {
        data.phoneFields = {
          dialCode: data.phoneFields.value,
          code: data.phoneFields.label,
        }
      } else {
        data.phoneFields = null
      }

      data.requestPasswordChange = state.currentUser.settings.askPassword

      addUser(data, 'ADMIN', companyData.companyData.id, () => {
        refetch()
        if (createAdmin === 'reset') {
          resetForm()
        } else {
          resetForm()
          setTabValue(1)
        }
      })
    },
  })

  const onFormSubmit = (action: string) => {
    setCreateAdmin(action)
    handleSubmit()
  }

  const nextButtonDisabled = !(companyData.admins.length > 0)

  const { t } = props

  const createGroupButtons = () => {
    return [
      {
        title: `${t('general.createGroupAdmin')}`, onClick: () => {
          setTabValue(0);
        }
      },
      {
        title: `${t('general.groupAdminList')}`, onClick: () => {
          setTabValue(1);
        }
      },
    ]
  }

  const theme = useTheme();

  return (
    <>
      <Container>
        <Wrapper theme={theme} width="100%">
          <ContentWrapper>
            <ActionButtonContainer>
              <DropdownButton title={t('company_wizard_layout.group_admin')} buttons={createGroupButtons()} />
            </ActionButtonContainer>
            <TabNavigation>
              <Wrapper theme={theme} width="70%" style={{ margin: 'auto' }}>
                <TabPanel value={tabValue} index={0}>
                  <CreateAdmin
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleSubmit={onFormSubmit}
                    addUserLoading={addUserLoading}
                    t={t}
                  />
                </TabPanel>
              </Wrapper>
              <TabPanel value={tabValue} index={1}>
                <AdminList
                  adminList={companyData.admins}
                  refetch={refetch}
                  companyId={companyData.companyData.id}
                  setTabValue={setTabValue}
                  t={t}
                />
              </TabPanel>
            </TabNavigation>
          </ContentWrapper>
        </Wrapper>
      </Container>

      <FooterWrapper>
        <ButtonWrapper>
          <Button
            text={t('actions.save_as_draft')}
            type="medium"
            color="secondary"
            onClick={() => {
              setCompanyState(companyData.companyData.id, 3, 'Success', () =>
                history.push('/Companies'),
              )
            }}
            background="#ffffff"
            textColor="#080A0A8A"
            outline="1px solid #080A0A14"
          />

          <Button
            text={t('actions.save_and_next')}
            type="medium"
            onClick={() => {
              setCompanyState(companyData.companyData.id, 4, 'Success', () =>
                props.changeStep(4),
              )
            }}
            isDisabled={nextButtonDisabled}
            color="secondary"
            background={'#317BF4'}
          />
        </ButtonWrapper>
      </FooterWrapper>
    </>
  )
}

export default GroupAdmins

const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 0px 50px;
`
const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
`
const ActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`
