
import CustomDrawer from "components/common/Drawer/CustomDrawer";
import { FormField, FormFields, FormGroupItem, FormGroupWrapper } from "components/common/Form";
import TimeInput from "components/common/TimeInput/TimeInput";
import VideoCommentGrid, { IComment } from "components/common/VideoCommentGrid/VideoCommentGrid";
import { CloseIcon, DrawerContent, DrawerHeader, DrawerTitle, Info } from "components/DrawerLayout/styled-components";
import React, { FunctionComponent, useEffect, useState } from "react";
import { JsxEmit } from "typescript";
import { CutomTextArea, SaveCommentButton, CancelEditButton } from "../styled-components";
import { useTheme } from "@material-ui/core";
import { useTranslation } from 'react-i18next'
import { getUserId } from "helpers/localStorage";
import { module } from "pages/courses/StudentCourse/types";

import { useCreateNote, CreateNote } from "hooks/notes/useCreateNote";
import { useNote, NoteQuery } from 'hooks/notes/useNote';
import { useEditNote, EditNote } from "hooks/notes/useEditNote";
import { timeConvert } from 'utils/utils'

interface PlayerDrawerProps {
  opened: boolean,
  toggleDrawer: () => void,
  totalWidth: string,
  onClose?: () => void,
  time: number,
  url: string,
  lesson: module.Lesson,
  courseId: string,
  model: 'LESSON' | 'SKILLTESTVIDEO',
  refetchGetUser: () => any
}

const PlayerDrawer: FunctionComponent<PlayerDrawerProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [editedNote, setEditedNote] = useState<IComment | null>(null);

  const getNotesData: NoteQuery = {
    actionId: props.lesson?.id,
    model: "LESSON",
    skip: 0,
    limit: 8
  };

  const {
    comments,
    loadingFetchingNotes,
    errorFetchingNotes,
    refetchGetNotes,
  } = useNote(getNotesData);


  const { addNoteFunction, loading, error } = useCreateNote(handleRefetchNotes);
  const { editNoteFunction, editNoteLoading, editNoteError } = useEditNote(handleRefetchNotes);


  function clearFields() {
    setTitle('')
    setDescription('')
  }

  function handleRefetchNotes() {
    props.refetchGetUser();
    refetchGetNotes();
    clearFields();
    setEditedNote(null);
  }

  function onNoteEditHandler(e: React.MouseEvent<HTMLElement>, id: string) {
    e.stopPropagation();
    const foundNote = comments.find(item => item.id === id);
    if (foundNote) {
      setEditedNote(foundNote);
      setTitle(foundNote.title);
      setDescription(foundNote.description);
    }
  }

  const saveNoteClickHandler = async () => {
    const validTitle = title.trim();
    const validDescription = description.trim();

    if (validTitle && validDescription) {
      if (!editedNote) {
        const newNoteData: CreateNote = {
          actionId: props.lesson.id,
          model: props.model,
          courseId: props.courseId,
          newNote: {
            title,
            description,
            moment: Math.round(props.time),
            thumbnail: props.lesson.video?.thumbnail
          }
        };
        await addNoteFunction({
          variables: newNoteData
        });
        return;
      } else {
        const updatedNoteData: EditNote = {
          noteId: editedNote.id,
          input: {
            description,
            moment: editedNote.seconds,
            title,
            thumbnail: props.lesson.video?.thumbnail
          },
        };
        await editNoteFunction({
          variables: updatedNoteData
        });
      }
    }
  };

  const handleCancelNoteEditing = () => {
    setEditedNote(null)
    clearFields();
  };

  return (
    <CustomDrawer
      opened={props.opened}
      toggleDrawer={props.toggleDrawer}
      totalWidth={props.totalWidth}>
      <>
        <DrawerHeader theme={theme}>
          <DrawerTitle theme={theme}>
            {t('general.noteComment')}
          </DrawerTitle>
          <CloseIcon filename='close' onClick={props.onClose} />
          <Info />
        </DrawerHeader>
        <DrawerContent margin='100px'>
          <FormGroupWrapper>
            <FormGroupItem>
              <FormFields >
                <FormField width={"100%"} color={"black"}>
                  <TimeInput label={t('general.time')} time={props.time} value={title} onChange={(e) => setTitle(e.target.value)} />
                </FormField>
                <FormField width={"100%"} color={"black"}>
                  <CutomTextArea placeholder={t('form_fields.description')} value={description} onChange={(e) => setDescription(e.target.value)} />
                </FormField>
                <FormField width={"100%"}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {editedNote && (
                      <CancelEditButton onClick={handleCancelNoteEditing}>
                        {t('actions.cancel')}
                      </CancelEditButton>
                    )}
                    <SaveCommentButton disabled={loading || editNoteLoading || loadingFetchingNotes} onClick={saveNoteClickHandler}>
                      {t('general.save')}
                    </SaveCommentButton>
                  </div>
                </FormField>
              </FormFields>
            </FormGroupItem>
          </FormGroupWrapper>
          <VideoCommentGrid
            comments={comments}
            onDelete={handleRefetchNotes}
            onEdit={onNoteEditHandler}
          />
        </DrawerContent>
      </>
    </CustomDrawer>
  );
}

export default PlayerDrawer;
