import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { Button } from 'components/common/Button'
import { mainColor, red, successGreen } from 'components/common/Colors/CommonColors'
import CustomLinearProgressBar from 'components/common/LineProgress/CustomLinearProgressBar'
import { useUserValue } from 'context/UserContext'
import {
  GET_IQ_TEST_RESULTS,
  GET_QUIZ_ANSWER,
  GET_QUIZ_BY_ID,
  EDIT_QUIZ,
  GET_QUIZ_RESULT,
} from 'gql/quiz/quiz.query'
import { useMutation } from '@apollo/react-hooks'

import useRetakeQuizService from 'hooks/quizzes/useRetakeQuizService'
import { TextProps } from 'pages/courses/StudentCourse/styled'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Answers from './Answer'
import IQTestsResults from './IQTestResults'
import Question, { IQuestion } from './Question'
import QuizResults from './QuizResults'

interface WriteQuizzProps {
  quizId: string,
  courseId: string,
  originalId: string,
  onQuizzFinish: () => void
}

const WriteQuizz: React.FC<WriteQuizzProps> = (props) => {
  const [answered, setAnswered] = useState(false)
  const { t } = useTranslation()
  const [displayResults, setDisplayResults] = useState(false)
  const { data, refetch: refechQuiz } = useQuery(GET_QUIZ_BY_ID, {
    variables: {
      quizId: props.quizId,
    },
  })

  const quizType = data?.getQuizById?.IQTestType
  const isQuiz =
    data?.getQuizById &&
    data.getQuizById != null &&
    (quizType == null || !quizType)

  const [state, dispatch] = useUserValue()


  const [
    getQuizResult,
    { loading: resultsLoading, data: quizResult, refetch, },
  ] = useLazyQuery(isQuiz ? GET_QUIZ_RESULT : GET_IQ_TEST_RESULTS)
  const [getAnswer, { loading, data: answerData, }] = useLazyQuery(
    GET_QUIZ_ANSWER,
    {
      onCompleted: () => {
        if (
          QuizQuestions?.length &&
          currentIndexRef?.current > QuizQuestions.length - 1
        ) {
          getQuizResult({
            variables: {
              quizId: props.quizId,
              userId: state?.currentUser?.id,
              courseId: props.originalId,
            },
          })
          setDisplayQuizResults(true)
        }

        if (!loading) {
          if (answerData?.getAnswer.isCorrect)
            setBorderColor(successGreen)
          else
            setBorderColor(red)
        }
        else {
          setBorderColor("transparent")
        }
      }
    },
  )

  const [QuizQuestions, setQuizQuestions] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState<IQuestion>(
    {} as IQuestion,
  )
  const [hasAnswerSelected, setHasAnswerSelected] = useState(false)
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([])
  const [boolAnswer, setBoolAnswer] = useState<boolean>(false)
  const currentIndexRef = useRef(0)

  useEffect(() => {
    setQuizQuestions(data?.getQuizById?.questionList)
  }, [data])


  useEffect(() => {
    QuizQuestions && setCurrentQuestion(QuizQuestions[0])
  }, [QuizQuestions])

  const onNextClick = useCallback(() => {
    setBorderColor("transparent");

    if (!isQuiz) {
      getAnswer({
        variables: {
          quizId: props.quizId,
          questionId: currentQuestion.id,
          boolAnswer:
            currentQuestion?.type?.value === 'boolean' ? boolAnswer : null,
          answers:
            currentQuestion?.type?.value === 'boolean' ? [] : selectedAnswers,
        },
      })
    }

    currentIndexRef.current++
    if (
      isQuiz &&
      QuizQuestions?.length &&
      currentIndexRef?.current > QuizQuestions.length - 1
    ) {
      getQuizResult({
        variables: {
          quizId: props.quizId,
          userId: state?.currentUser?.id,
          courseId: props.originalId,
        },
      })
      setDisplayQuizResults(true)
    }
    if (QuizQuestions) {
      setCurrentQuestion(QuizQuestions[currentIndexRef.current])
    }
    setHasAnswerSelected(false)
    setAnswered(false)
  }, [
    currentIndexRef,
    QuizQuestions,
    currentQuestion,
    selectedAnswers,
    boolAnswer,
    isQuiz,
  ])

  const [borderColor, setBorderColor] = useState<string>("transparent")

  const answerIsGiven = useCallback(() => {
    getAnswer({
      variables: {
        quizId: props.quizId,
        questionId: currentQuestion.id,
        boolAnswer:
          currentQuestion?.type?.value === 'boolean' ? boolAnswer : null,
        answers:
          currentQuestion?.type?.value === 'boolean' ? [] : selectedAnswers,
      },
    })


    setAnswered(true)
    setBoolAnswer(false)
    setSelectedAnswers([])
    setHasAnswerSelected(false)
  }, [currentQuestion, boolAnswer, selectedAnswers])

  useEffect(() => {
    if (data?.getQuizById === null) {
      getQuizResult({
        variables: {
          quizId: props.quizId,
          userId: state?.currentUser?.id,
          courseId: props.originalId,
        },
      })
    }
  }, [data, props.quizId, state, displayResults])

  const [displayQuizResults, setDisplayQuizResults] = useState(false)

  const { retakeQuiz, loading: retakeresultsLoading } = useRetakeQuizService()

  const restartQuiz = useCallback(() => {
    setCurrentQuestion({} as IQuestion)
    refechQuiz()
    setAnswered(false)

    retakeQuiz(state?.currentUser?.id, props.originalId ?? props.courseId, props.quizId)
    setBorderColor("transparent")
    // setBoolAnswer(false)
    if (!!QuizQuestions) {
      setCurrentQuestion(QuizQuestions[0])
    }
    currentIndexRef.current = 0
    setSelectedAnswers([])
    setBoolAnswer(false)
    setDisplayResults(false)
    setDisplayQuizResults(false)
  }, [state, props.courseId, props.quizId, QuizQuestions, displayResults])
  const [countdown, setCountdown] = useState(true)

  const CountingDown = useMemo(() => {
    const time = data?.getQuizById?.time ? data?.getQuizById?.time * 60 * 1000 : 15 * 60 * 1000
    const quizParticipationType = data?.getQuizById?.quizParticipationType
    return <Countdown autoStart={true} date={Date.now() + time} onComplete={
      () => {
        getQuizResult({
          variables: {
            quizId: props.quizId,
            userId: state?.currentUser?.id,
            courseId: props.originalId,
          },
        })
        if (quizParticipationType == "mandatory") {
          setTimeout(() => {
            setDisplayResults(true)
          }
            , 1000)
        }
      }
    }
      renderer={({ hours, minutes, seconds }) => {
        return <h4>{hours}:{minutes}:{seconds}</h4>
      }}


    />
  }, [displayResults, data?.getQuizById?.time, countdown])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      {countdown && CountingDown}
      {!displayResults && (<QuizContainer>
        {currentQuestion?.id &&
          (!quizResult?.getIQTestResults || !displayQuizResults) && (
            <div>
              {/* <div>
              <CustomLinearProgressBar
                currentNumber={currentIndexRef?.current}
                maximumNumber={QuizQuestions?.length}
              />
              <CurrenIndexIndicator>
                {currentIndexRef?.current + 1} / {QuizQuestions?.length}
              </CurrenIndexIndicator>
            </div> */}
              {/* <QuestionType>
              {t('quiz_details.quiz_type')}: {currentQuestion?.type?.label}
            </QuestionType> */}
              <QuestionWiThAnswersContainer style={{
                border: `1px solid ${borderColor}`
              }}>
                <QuestionsAnsAnswersOnly>
                  <Question question={currentQuestion} />
                  <Answers
                    answers={currentQuestion.answers}
                    questionId={currentQuestion.id}
                    setHasAnswerSelected={setHasAnswerSelected}
                    type={currentQuestion.type}
                    setSelectedAnswers={setSelectedAnswers}
                    setBoolAnswer={setBoolAnswer}
                    isAnswersButtonDisabled={answered}
                    answerData={answerData}
                  />
                </QuestionsAnsAnswersOnly>
                {currentQuestion.reason && answered && !loading && answerData?.getAnswer.isCorrect && (
                  <QuestionDescription style={{
                    background: '#EEF8F2',
                    borderColor: '#49AF75',
                  }}>
                    <Text>{currentQuestion.reason}</Text>
                  </QuestionDescription>
                )}
                {currentQuestion.reason && answered && !loading && !answerData?.getAnswer.isCorrect && (
                  <QuestionDescription style={{
                    background: '#FFF2F2',
                    borderColor: '#CC3131',
                  }}>
                    <Text>{currentQuestion.reason}</Text>
                  </QuestionDescription>
                )}
              </QuestionWiThAnswersContainer>

            </div>
          )}


      </QuizContainer>)}
      {!!quizResult?.getIQTestResults && !isQuiz && (
        <IQTestsResults quizResults={quizResult?.getIQTestResults} />
      )}
      {(displayResults || displayQuizResults) && isQuiz && (
        <QuizResults
          quizResults={quizResult?.getQuizResult}
          resultsLoading={resultsLoading}
          getQuizResult={() => {
            getQuizResult({
              variables: {
                quizId: props.quizId,
                userId: state?.currentUser?.id,
                courseId: props.originalId,
              },
            })
          }}
          setCountdown={setCountdown}
          restartQuiz={restartQuiz}
          courseId={props.courseId}
          onFinish={() => props.onQuizzFinish()}
        />
      )}

      {(!displayResults && !displayQuizResults) && isQuiz &&
        (<div style={{ display: 'flex', }}>
          <Button
            onClick={answerIsGiven}
            text={t('actions.give_answer')}
            isDisabled={!hasAnswerSelected}
            width={200}
            height={40}
            textColor={"#fff"}
            background={'#317BF4'}
            buttonStyles={{ marginRight: 10, borderRadius: 16 }}
          />
          <Button
            onClick={onNextClick}
            text={t('actions.next')}
            isDisabled={!answered}
            width={140}
            height={40}
            background={'transparent'}
            textColor={`${mainColor}`}
            outline={`1px solid ${mainColor}`}
            buttonStyles={
              {
                borderRadius: '16px'
              }
            }
          />
        </div>)}



    </div>
  )
}

export default WriteQuizz

const QuizContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 17px 34px;

  color: #414141;
`
const CurrenIndexIndicator = styled.div`
  color: ${mainColor};
  width: 100%;
  text-align: right;
  font-weight: bold;
  font-size: 20px;
  padding-right: 17px;
  margin-top: 7px;
`
export const QuestionWiThAnswersContainer = styled.div`
  width: 770px!important;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 10px 30px #00000029;
  border-radius: 16px;
  width: 100%;
  align-items: flex-end;
  padding-top: 34px;
  overflow-y: scroll;
  @media (max-width: 992px){
   width: 70vw !important;
  }
`
const QuestionType = styled.div`
  font-size: 16px;
  text-align: left;
  padding: 5px 0;
`
const QuestionsAnsAnswersOnly = styled.div`
  //  padding: 10px 320px 0px 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: calc(100% - 42px);
  overflow-y: scroll;
`
const QuestionDescription = styled.div`
  width: 100%;
  border-top: 1px solid black;
  padding: 17px;
`

const Text = styled.div<TextProps>(props => {
  return css`
    font-size: ${props.fs || '14px'};
    color: ${props.color || '#000000'};
    font-weight: ${props.weight || 'normal'};
    margin: ${props.margin || '0'};
    margin-top: ${props.mt || '0'};
    margin-right: ${props.mr || '0'};
    margin-bottom: ${props.mb || '0'};
    margin-left: ${props.ml || '0'};
    ${() => {
      switch (props.variant) {
        case 'title':
          return css`
            font-size: 24px;
            color: #080a0ad6;
            font-weight: 600;
          `
      }
    }}
  `
})
