import { useMutation } from '@apollo/react-hooks'
import { GET_COMPANY, UPDATE_COMPANY_COVER_IMAGE } from 'gql/companies.query'
import { updateCache } from 'services/updateCache'

export const updateCompanyCoverImageService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [updateCompanyCoverImage] = useMutation(UPDATE_COMPANY_COVER_IMAGE, {
    update(cache, { data: { updateCompanyCoverImage: document } }) {
      updateCache({
        objectName: 'company',
        query: GET_COMPANY,
        cache,
        document,
        action: 'update',
      })
    },
  })

  return {
    updateCompanyCoverImage,
  }
}
