import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import * as Yup from 'yup'
import { FormContainer } from 'components/common/Form'

import Loader from 'components/common/Loader'
import Stepper from 'components/common/Stepper'
import {
  DrawerContent,
  DrawerHeaderSteps,
  DrawerStepsTitle,
} from 'components/DrawerLayout/styled-components'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { useFormik } from 'formik'
import { CourseSchema } from 'helpers/validationSchemas'
import useCreateCourse from 'hooks/course/useCreateCourse'
import { useEditCourse } from 'hooks/course/useEditCourse'
import {
  useGenerateCustumUploadVideoLinks,
  useGenerateUploadVideoLinks,
  useUploadFile,
  useUploadImage,
} from 'hooks/helpers/useHelpersService'

import { CLEAR_FORMDATA, SET_FORMDATA } from 'store/types'
import styled, { css } from 'styled-components'
import AssignCoach from './AssignCoach'
import Step1 from './Step1'
import Step2 from './Step2'
import { useHistory } from 'react-router-dom'
import { triggerVideoConverter } from 'services/mediaEncoding'
import useSnackbarAlert from 'hooks/useSnackbar'

const formData = {
  name: '',
  description: '',
  level: null,
  coursePrivacy: 'private',
  coachType: '',
  coaches: [],
  skills: [],
  certificateIncluded: false,
  price: '',
  currency: '',
  avatar: null,
  certificate: null,
  video: null,
  categories: [],
  contentLocked: false,
}

const AddCourseWizard = (props: any) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [userData] = useUserValue()
  const [state, dispatch] = useData()
  const [step, setStep] = useState<number>(0)
  const [fileImage, setFileImage] = useState({
    certificate: '',
    avatar: '',
  })
  const [buttonEvent, setButtonEvent] = useState('')
  const [videoLinks, setVideoLinks] = useState<any>('')
  const [courseVideo, setCourseVideo] = useState<any>('')
  const [progress, setProgress] = useState<any>(0)
  const [selectedGroup, setSelectedGroup] = useState<string[]>([])
  const [validationData, setValidationData] = useState<any>(CourseSchema)
  const [courseData, setCourseData] = useState<any>(null)
  const { setSnackbar } = useSnackbarAlert()

  const query: any = {}

  if (userData.selectedCompanyId) {
    query.companyId = {
      type: 'exact',
      value: userData.selectedCompanyId,
    }
  }

  const { useCreateCourseService, createCourseLoading } = useCreateCourse({
    filter: { ...query },
    perPage: 2,
    currentPage: 1,
  })
  const { editCourse, editCourseLoading, updatedCourse } = useEditCourse({
    courseId: '',
  })

  const { generateCustumUploadVideoLinks } = useGenerateCustumUploadVideoLinks()
  const { uploadImage, imageLoading } = useUploadImage()
  const { uploadFile, fileLoading } = useUploadFile()

  const handleChangeStep = (activeStep: number) => {
    setStep(activeStep)
  }

  const uploadVideoService = (url: string, videoKey: string) => {

    const formData = new FormData();

    formData.append("file", courseVideo);

    return axios.put(url, formData, {
      headers: {
        'Content-Type': "multipart/form-data",
      },
      onUploadProgress: ({ total, loaded }) => {
        setProgress((loaded / total) * 100)
      },
    }).then((res) => {
      const message = `Video upload success, conversion ${res.data}`
      const variant = 'success'

      setSnackbar({ message, variant })
    }).catch((err) => {
      const message = `${err}`
      const variant = 'error'
      console.log("err", err)
      setSnackbar({ message, variant })
    })
  }

  const selectGroup = (field: string[], event?: string) => {
    setSelectedGroup([...selectedGroup, ...field])
  }

  const handleDeleteGroup = (ids: string[]) => {
    const groups = selectedGroup.filter(
      (i: any) => ids.findIndex((e: any) => i.groupId === e) === -1,
    )
    setSelectedGroup(groups)
  }

  const onCloseDrawer = () => {
    props.data.onSuccess()
    props.onClose()
    dispatch({ type: CLEAR_FORMDATA })
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: Yup.object(validationData),
    onSubmit: (values: any) => {
      if ((step === 0 || step === 1) && buttonEvent === 'next') {
        handleChangeStep(step + 1)
        return
      }

      if (step === 2 && buttonEvent === 'draft') {
        onCloseDrawer()
        handleCloseDrawer()
        return
      }

      if (createCourseLoading) return

      let data = { ...values, finished: step + 1 }

      let skillsValue: any = []

      data.skills.map((e: any) => {
        skillsValue.push({ label: e.label })
      })
      data.skills = skillsValue

      let categoriesValues: any = []

      data.categories.map((e: any) => {
        categoriesValues.push({ name: e.label })
      })

      data.categories = categoriesValues

      delete data.certificateIncluded

      if (data.coaches && data.coaches.length > 0) {
        data.coaches = data.coaches.map((i: any) => i.value)
      }

      if (props.data.companyId) data.companyId = props.data.companyId

      if (data.level) {
        data.level = data.level.value
      }

      if (!data.price) {
        data.price = null
        data.currency = ''
      }

      if (selectedGroup.length > 0) data.groups = selectedGroup
      const groupIds = selectedGroup.map((i: any) => i.groupId)

      if (data.video) {
        data.video = {
          id: data.video.id,
          title: data.video.title,
        }
      }

      if (courseData && courseData.id) {
        if (step === 2 && buttonEvent === 'next') {
          data.finished = 3
        }

        editCourse(courseData.id, data, groupIds, () => {
          if (data.finished === 3 && buttonEvent === 'next') {
            onCloseDrawer()
            return
          }
          if (buttonEvent === 'draft') {
            onCloseDrawer()
          } else {
            handleChangeStep(2)
          }
        })
        return
      }

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useCreateCourseService(data, groupIds, (data: any) => {
        setCourseData(data.createCourse)
        if (buttonEvent === 'draft') {
          onCloseDrawer()
        } else {
          handleChangeStep(2)
        }
      })
    },
  })

  useEffect(() => {
    if (values.price) {
      setValidationData({
        ...validationData,
        currency: Yup.string().required(
          `${t('form_fields.currency')} ${t('validations.required')}`,
        ),
      })
    } else {
      setValidationData(validationData)
    }
  }, [values.price])

  const handleGenerateUploadLink = () => {
    const videos = [{ fileName: courseVideo.name, type: courseVideo.type }]

    generateCustumUploadVideoLinks(
      videos,
      'course',
      uploadVideoService,
      (args: any) => {
        setFieldValue('video', args.video)
        setFieldValue('video.title', args.video.name)
        setProgress(0)
      },
    )
  }

  const handleCloseDrawer = () => {
    // const { coursePrivacy, ...formData } = values
    // dispatch({
    //   type: SET_FORMDATA,
    //   payload: {
    //     type: 'add',
    //     drawer: 'addCourses',
    //     values: formData,
    //     compareTo: {},
    //   },
    // })
    history.push('/courses')
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  useEffect(() => {
    if (courseVideo && courseVideo.type) {
      handleGenerateUploadLink()
    }
  }, [courseVideo])

  const handleVideoChange = (e: any) => {
    setCourseVideo(e ? e.target.files[0] : '')
    if (e) {
      // const file = e.target.files[0]
      // const reader: any = new FileReader()
      // reader.onload = function() {
      //   const media = new Audio(reader.result)
      //   media.onloadedmetadata = function() {
      //   }
      // }
      // reader.readAsDataURL(file)
    } else {
      setCourseVideo(null)
      setFieldValue('video', null)
      setProgress(0)
    }
  }

  const handleImageChange = (file: any, field: string, fileName: string) => {
    if (!file) {
      setFieldValue(field, null)
      return
    }
    uploadImage(file, `course/${field}`, (link: string) =>
      setFieldValue(field, {
        name: Date.now().toString(),
        link: link,
        fileType: fileName.split('.').pop(),
      }),
    )
  }

  const handleUploadFile = (e: any, field: string) => {
    const { files } = e.target
    const file = files[0]

    uploadFile(file, `course/${field}`, (link: string) => {
      setFieldValue(field, {
        name: file.name,
        link: link,
        fileType: file.name.split('.').pop(),
      })
    })
  }

  const handleButtonClick = (e: string) => {
    setButtonEvent(e)
    handleSubmit()
  }

  const courseWithCoach = { ...courseData, ...{ coaches: values.coaches } }

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleImageChange={handleImageChange}
            fileImage={fileImage}
            setFieldValue={setFieldValue}
            videoUploadProgress={progress}
            imageUploading={imageLoading}
            handleVideoChange={handleVideoChange}
            courseVideo={courseVideo}
            handleUploadFile={handleUploadFile}
            fileLoading={fileLoading}
            videoLinks={videoLinks}
            company={props.data}
            handleCloseDrawer={handleCloseDrawer}
            handleChangeStep={handleChangeStep}
            handleButtonClick={handleButtonClick}
            step={step}
            t={t}
          />
        )
      case 1:
        return (
          <Step2
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            selectedGroup={selectedGroup}
            selectGroup={selectGroup}
            deleteGroup={handleDeleteGroup}
            company={props.data}
            handleCloseDrawer={handleCloseDrawer}
            handleChangeStep={handleChangeStep}
            handleButtonClick={handleButtonClick}
            step={step}
            t={t}
          />
        )

      case 2:
        return (
          <AssignCoach
            values={courseWithCoach}
            selectedGroup={selectedGroup}
            courseId={courseData && courseData.id}
            handleDeleteCourse={handleCloseDrawer}
            handleButtonClick={handleButtonClick}
            handleChangeStep={(num: number) => handleChangeStep(step - num)}
          />
        )
      default:
        return 'Error'
    }
  }

  const stepTitles = [
    t('course_details.course_details'),
    t('general.groups'),
    t('general.assign'),
  ]

  return (
    <Container>
      <Header>
        {createCourseLoading && <Loader />}

        {/* <Stepper active={step} steps={stepTitles} /> */}
        <StepperContainer>
          <Stepper
            active={step}
            steps={stepTitles}
            setStep={setStep}
            ColorlibConnector={() => <></>}
            StepIconComponent={(props: any) => {
              const icons: { [index: string]: React.ReactElement } = {
                1: <>1</>,
                2: <>2</>,
                3: <>3</>,
              }

              return (
                <SWizardStepper active={props.active as boolean}>
                  {icons[String(props.icon)]}
                </SWizardStepper>
              )
            }}
          />
        </StepperContainer>
      </Header>

      <DrawerContent headerHeight={168}>
        <FormContainer>{getStepContent(step)}</FormContainer>
      </DrawerContent>
    </Container>
  )
}

export default AddCourseWizard

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 291px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  overflow: hidden;
`

const StepperContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiPaper-root {
    background: transparent !important;
  }
`
const SWizardStepper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 42px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #080a0a14;
  box-shadow: none;
  color: #707070;
  font-size: 20px;
  transition: 0.3s;
  border-radius: 100%;

  ${({ active }) =>
    active &&
    css`
      width: 54px;
      height: 54px;
      background: #317bf4;
      color: #ffffff;
      font-size: 28px;
      &::after {
        content: '';
        position: absolute;
        border: 1px solid #707070;
        border-collapse: separate;
        border-radius: 100%;
        width: 60px;
        height: 60px;
      }
    `}
`
