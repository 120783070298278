import React from 'react'
import strCutter from 'utils/strCutter'
import Checkbox from 'components/common/Checkbox'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'

import EditRoundedIcon from '@material-ui/icons/EditRounded'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import { generateFullName } from 'utils/utils'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import IconButton from '@material-ui/core/IconButton'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import SchoolIcon from '@material-ui/icons/School'
import { mainColor } from 'components/common/Colors/CommonColors'
import { CapitalPlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'
import Icon from 'components/Icon'

interface itemProps {
  name: string
  description: string
  location: string
  isChecked?: boolean
  id: string
  last?: string
  numberOfStudents?: number | string
  admin?: any
  createDate: string
  company: any
  courses: any
  status: string
  companyId: any
}

export const columnConfig = (
  selectItem: any,
  checkbox?: any,
  company?: boolean,
  t?: any,
  theme?: any,
) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <div style={{ visibility: !checkbox ? 'hidden' : 'visible' }}>
          <Checkbox
            checked={item.isChecked}
            onChange={() => selectItem(item.id)}
          />
        </div>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: `${t('general.name')}`,
      render: (item: itemProps) => (
        <Text theme={theme} style={{ height: '80px', display: 'flex', alignItems: 'center' }}>
          {strCutter(item.name, 30)}
        </Text>
      ),
    },
    {
      id: 'admin',
      numeric: true,
      disablePadding: false,
      label: `${t('general.admin')}`,
      render: (item: itemProps) => {
        return <Text theme={theme}>{strCutter(generateFullName(item.admin), 30)}</Text>
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item: itemProps) => {
        return (
          <StatusWrapper className={`${item.status?.toLowerCase()}`}>
            {item.status}
          </StatusWrapper>
        )
      },
    },
    {
      id: 'numberOfStudents',
      numeric: true,
      disablePadding: false,
      label: `${t('general.students')}`,
      render: (item: itemProps) => (
        <Text theme={theme} style={{ margin: 'auto' }}>{item.numberOfStudents || 0}</Text>
      ),
    },
    {
      id: 'courses',
      numeric: false,
      disablePadding: false,
      label: `${t('general.courses')}`,
      render: (item: itemProps) => {
        return (
          <GroupsWrapper>
            <GroupItem>
              <CountContainer theme={theme}>{item.courses.length}</CountContainer>
              <GroupTitle className="group-item-title">
                {item.courses.map((g: any) => (
                  <div key={g.name}>{g.name}</div>
                ))}
              </GroupTitle>
            </GroupItem>
          </GroupsWrapper>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: itemProps) => (
        <Text theme={theme}>
          {item.company && item.company.name ? (
            strCutter(item.company.name, 25)
          ) : (
            <>
              {CapitalPlatformTitle}
              <StarsRoundedIcon
                style={{
                  color: mainColor,
                  fontSize: 10,
                  verticalAlign: 'super',
                }}
              />
            </>
          )}
        </Text>
      ),
    },

    {
      id: 'createDate',
      numeric: true,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: itemProps) => (
        <Text theme={theme}>{moment(item.createDate).format('DD.MM.YYYY')}</Text>
      ),
    },
  ]

  return grid.filter((i: any) => !i.hide)
}

export const actionConfig = (
  handleClick: any,
  permission?: any,
  company?: boolean,
  t?: any,
) => {
  const action = [
    {
      render: (item: any) => (
        <Action onClick={() => handleClick(item, 'info')}>
          <EditRoundedIcon />
          <Typography>{t('actions.edit')} </Typography>
        </Action>
      ),
    },
    // {
    //   hide: !permission.editGroup,
    //   render: (item: any) => (
    //     <Action onClick={() => handleClick(item.id, 'info')}>
    //       <EditRoundedIcon />
    //       <Typography>Edit</Typography>
    //     </Action>
    //   ),
    // },
    {
      hide: !permission.deleteGroup,
      render: (item: any) => (
        <Action onClick={() => handleClick(item.id, 'delete')}>
          <DeleteForeverRoundedIcon />
          <Typography>{t('actions.delete')}</Typography>
        </Action>
      ),
    },
  ]
  return action.filter((i: any) => !i.hide)
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`

const Text = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  font-weight: 600;
`

const GroupsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const GroupItem = styled.div`
  position: relative;
  &:hover > span {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
`

const GroupTitle = styled.span`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  text-align: center;
  padding: 5px 8px;
  bottom: -35px;
  left: 50%;
  color: #333;
  font-size: 12px;
  transform: translateX(-50%) scale(0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.5px 12px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease, transform 0.2s ease;
`

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  width: max-content;
  height: max-content;
  padding: 5px 10px;
  padding-bottom: 2px;
  border-radius: 4px;

  &.active {
    color: #269f5a;
    background: #269f5a1f;
  }

  &.suspended {
    color: #cc3131;
    background: #cc31311f;
  }

  &.pending {
    color: #ffa502;
    background: #ffa5021f;
  }

  &.draft {
    color: rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.151);
  }
`

const StatusCircle = styled.div`
  margin-right: 5px;
  margin-top: -3px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
`

const CountContainer = styled.div`
  width: 36px;
  height: 36px;
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color:  ${({ theme }) => theme.palette.text.primary};
`
