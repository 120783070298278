import React, { useState } from 'react'
import styled from 'styled-components'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import { mainColor } from 'components/common/Colors/CommonColors'
import Modal from 'components/common/Modal'
import Player from 'components/common/VideoPlayer/Player'
import { DocumentContainer } from 'pages/questions/QuestionDetails/styled-components'
import HeadsetIcon from '@material-ui/icons/Headset'
import FileWrapper from 'components/common/FileWrapper'
import AudioPlayer from 'components/common/AudioPlayer'

interface IImageType {
  name: string
  fileType: string
  link: string
}
interface IDocumentType extends IImageType {
  size: string
}
interface IAudios extends IImageType {
  duration: string
  link: string
}

interface IVideo {
  name: string
  title: string
  thumbnail: string
  duration: string
  id: string
  links: {
    url: string
    fileType: string
    converted: string
  }[]
}

export interface IQuestion {
  id: string
  type: {
    value: string
    label: string
  }
  question: string
  answers: {
    id: string,
    isCorrect: boolean,
    value: string
  }[],
  reason: string,
  boolAnswer: boolean
  images: IImageType[]
  documents: IDocumentType[]
  audios: IAudios[]
  videos: IVideo[]
}

const Question: React.FC<{ question: IQuestion }> = ({ question }) => {
  const [videoModal, setVideoModal] = useState(false)
  const [currentVideo, setCurrentVideo] = useState<IVideo>({} as IVideo)
  const [audioModalOpened, setAudioModalOpened] = useState<boolean>(false)
  const [currentAudio, setCurrentAudio] = useState<string>('')

  const handleAudioClick = (link: string) => {
    setAudioModalOpened(true)
    setCurrentAudio(link)
  }

  return (
    <QuestionWrapper>
      {question.audios &&
        question.audios.map((audio: IAudios, i) => (
          <DocumentContainer key={i}>
            <FileWrapper
              icon={<HeadsetIcon color="secondary" />}
              file={audio}
              onClick={handleAudioClick}
            />
          </DocumentContainer>
        ))}
      {question?.videos &&
        question?.videos.map((video, i) => (
          <VideosContainer key={i}>
            <div>
              <div
                style={{ backgroundImage: `URL(${video?.thumbnail})` }}
                onClick={() => {
                  setCurrentVideo(video)
                  setVideoModal(true)
                }}
              >
                <PlayCircleOutlineIcon style={{ color: mainColor }} />
              </div>
            </div>
          </VideosContainer>
        ))}
      <ImagesContaner>
        {question?.images &&
          question.images.map((image, i) => (
            <div key={i}>
              <img src={image.link} alt="" />
            </div>
          ))}
      </ImagesContaner>
      <QuestionText>{question.question}</QuestionText>
      <div style={{
        width: '80%',
        height: '1px',
        background: '#F5F5F5',
        margin: '20px auto',
      }}></div>
      <Modal isOpened={videoModal} onClose={() => setVideoModal(false)}>
        <Player
          url={currentVideo?.links && currentVideo.links[0].url}
          subtitle={'subtitle'}
        />
      </Modal>
      <Modal
        isOpened={audioModalOpened}
        onClose={() => setAudioModalOpened(false)}
      >
        <AudioPlayer url={currentAudio && currentAudio} />
      </Modal>
    </QuestionWrapper>
  )
}

export default Question

const ImagesContaner = styled.div`
  display: inline-block;
  > div {
    display: inline-block;
    > img {
      border: 0.5px solid #707070;
      border-radius: 5px;
      box-shadow: 0px 3px 6px #00000029;
      width: 254px;
      height: 161px;
      margin-right: 15px;
    }
  }
`

const VideosContainer = styled.div`
  display: inline-block;
  > div {
    display: inline-block;
    margin-right: 15px;
    > div {
      border: 0.5px solid #707070;
      border-radius: 5px;
      box-shadow: 0px 3px 6px #00000029;
      width: 254px;
      height: 161px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-top: 10px;
    }
  }
`

const QuestionWrapper = styled.div`
  width: 100%;
  text-align: center;
`
const QuestionText = styled.div`
  margin: 0 auto;
  width: 95%;
  font-size: 20px;
  font-weight: bold;
  color: #317BF4;
`
