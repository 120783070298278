import { useMutation } from '@apollo/react-hooks'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import { Grid } from 'components/common/Cards'
import CheckBox from 'components/common/Checkbox'
import DndItem from 'components/common/Grid/DndGrid/DndItem'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { gql } from 'graphql.macro'
import { formatDuration } from 'helpers/formatDuration'
import update from 'immutability-helper'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { mainColor } from '../Colors/CommonColors'
import ModuleDropdown from './ModuleDropdown/ModuleDropdown'
import {
  Container,
  GridItem,
  GridItemContent,
  GridItemContentHeader,
  GridItemImage,
  GridItemLessons,
  GridItemTitle,
} from './styled-components'

const CHANGE_ORDER = gql`
  mutation($draggedId: String!, $droppedId: String!) {
    changeModulesOrder(draggedId: $draggedId, droppedId: $droppedId)
  }
`

interface CardsLayoutParams {
  permissions: any
  gridStyle?: string
  addText?: string
  addAction?: () => void
  data: any
  noRecordsText?: string
  actions?: any
  handleVideoModal: (item: any) => void
  selectItem?: (id: string, e: any) => void
  selectedModuleIds?: [string]
  selectedItem?: [string]
  cols?: number
  refetch?: any
  isDraggable?: boolean
  t?: any
  FirstItem?: JSX.Element
  lastItem?: JSX.Element
  openInExternalTab?: boolean
  isStudent?: boolean
  wenrollExploreCourse?: boolean
  canModify?: boolean
}

const CardsLayout = ({
  permissions,
  gridStyle = 'card',
  addAction,
  addText = '',
  data,
  noRecordsText = 'No records',
  actions,
  handleVideoModal,
  selectItem = (id: string, e: any) => { },
  selectedModuleIds = [''],
  selectedItem = [''],
  cols = 4,
  refetch,
  isDraggable = false,
  t,
  lastItem,
  FirstItem,
  openInExternalTab,
  isStudent,
  wenrollExploreCourse,
  canModify,
}: CardsLayoutParams) => {
  const [changeModulesOrder, { loading }] = useMutation(CHANGE_ORDER)

  const history = useHistory()

  const [cards, setCards] = useState(data)
  const moveCard = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )

  const handleChangeRequest = async (
    draggedId: string,
    droppedId: string | undefined,
  ) => {
    const { data } = await changeModulesOrder({
      variables: { draggedId, droppedId },
    })

    if (data) {
      refetch()
    }
  }

  useEffect(() => {
    setCards(data)
  }, [data])

  const redirectToModuleDetails = (moduleId: string): void => {
    if (openInExternalTab) {
      window.open(`/modules/${moduleId}`, '_blank')
    } else {
      history.push(`/modules/${moduleId}`)
    }
  }

  return (
    <Container>
      <Grid className={gridStyle} cols={cols}>
        {data.length === 0 && <div>{noRecordsText}</div>}
        {FirstItem || ''}
        {cards.map((item: any, index: number) => {
          return isDraggable && !isStudent ? (
            <DndItem
              key={item.id}
              id={item.id}
              index={index}
              moveCard={moveCard}
              onEnd={handleChangeRequest}
              cardIds={cards.map((card: any) => card.id)}
            >
              {!permissions.activateAdmin || wenrollExploreCourse ? (
                <ModuleDropdown
                  item={item}
                  gridStyle={gridStyle}
                  wenrollExploreCourse={wenrollExploreCourse}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  {canModify && (
                    <CheckBox
                      onChange={(e: React.SyntheticEvent<EventTarget>) =>
                        selectItem(item.id, e)
                      }
                      checked={
                        selectedItem.includes(item.id) ||
                        selectedModuleIds.includes(item.id)
                      }
                      disabled={selectedModuleIds.includes(item.id)}
                    />
                  )}
                  <StylesCardWrapper
                    className="wrapper"
                    key={item.id}
                    onClick={() => redirectToModuleDetails(item.id)}
                  >
                    <CardActionBar switched={!!(gridStyle === 'card')}>
                      <div className="action_block">
                        <IconButton
                          onClick={e => {
                            e.stopPropagation()
                            if (!item.attachment) return
                            window.open(item.attachment, '_blank')
                          }}
                        >
                          <FileCopyIcon
                            color={item.attachment ? 'secondary' : 'disabled'}
                            style={{ color: item.attachment ? mainColor : '' }}
                          />
                        </IconButton>
                      </div>
                      {actions && (
                        <div className="action_block">
                          <GridActionMenu actionConfig={actions} row={item} />
                        </div>
                      )}
                    </CardActionBar>
                    <GridItem>
                      <GridItemImage>
                        <img
                          src={
                            item.coverImage
                              ? item.coverImage.link
                              : '/img/moduleAvatar.jpeg'
                          }
                          alt="Module Image"
                        />
                      </GridItemImage>
                      <GridItemContent>
                        <GridItemContentHeader>
                          <GridItemTitle>
                            <Link to={`/modules/${item.id}`}>{item.name}</Link>
                          </GridItemTitle>
                          <span
                            onClick={e => {
                              e.stopPropagation()
                              item.video && handleVideoModal(item)
                            }}
                            style={item.video ? { pointerEvents: 'none' } : {}}
                          >
                            <PlayCircleOutlineIcon
                              style={{ cursor: 'pointer', fontSize: '1.7rem' }}
                              color={item.video ? 'secondary' : 'disabled'}
                            />
                          </span>
                        </GridItemContentHeader>
                        <GridItemLessons>
                          <div>
                            {item.numberOfTopics} {t('general.topic')}
                          </div>
                          <div>
                            {item.numberOfLessons} {t('general.lesson')}
                          </div>
                          <div>{formatDuration(item.duration)}</div>
                        </GridItemLessons>
                        {item.description && (
                          <article>
                            {gridStyle === 'card'
                              ? item.description.length > 80
                                ? item.description.substring(0, 80) + '...'
                                : item.description
                              : item.description.length > 300
                                ? item.description.substring(0, 300) + '...'
                                : item.description}
                          </article>
                        )}
                      </GridItemContent>
                    </GridItem>
                  </StylesCardWrapper>
                </div>
              )}
            </DndItem>
          ) : !permissions.activateAdmin || wenrollExploreCourse ? (
            <ModuleDropdown
              item={item}
              gridStyle={gridStyle}
              wenrollExploreCourse={wenrollExploreCourse}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                cursor: 'pointer',
              }}
              onClick={() => redirectToModuleDetails(item.id)}
            >
              {permissions.deleteModule && (
                <CheckBox
                  onChange={(e: React.SyntheticEvent<EventTarget>) =>
                    selectItem(item.id, e)
                  }
                  checked={
                    selectedItem.includes(item.id) ||
                    selectedModuleIds.includes(item.id)
                  }
                  disabled={selectedModuleIds.includes(item.id)}
                />
              )}
              <StylesCardWrapper className="wrapper" key={item.id}>
                <CardActionBar switched={!!(gridStyle === 'card')}>
                  <div className="action_block">
                    <IconButton
                      onClick={e => {
                        e.stopPropagation()
                        if (!item.attachment) return
                        window.open(item.attachment, '_blank')
                      }}
                    >
                      <FileCopyIcon
                        color={item.attachment ? 'secondary' : 'disabled'}
                      />
                    </IconButton>
                  </div>
                  {actions && (
                    <div className="action_block">
                      <GridActionMenu actionConfig={actions} row={item} />
                    </div>
                  )}
                </CardActionBar>
                <GridItem>
                  <GridItemImage>
                    <img
                      src={
                        item.coverImage
                          ? item.coverImage.link
                          : '/img/moduleAvatar.jpeg'
                      }
                      alt="Module Image"
                    />
                  </GridItemImage>
                  <GridItemContent>
                    <GridItemContentHeader>
                      <GridItemTitle>
                        <Link to={`/modules/${item.id}`}>{item.name}</Link>
                      </GridItemTitle>
                      <span
                        onClick={() => handleVideoModal(item)}
                        style={item.video ? { pointerEvents: 'none' } : {}}
                      >
                        <PlayCircleOutlineIcon
                          style={{ cursor: 'pointer', fontSize: '1.7rem' }}
                          color={item.video ? 'secondary' : 'disabled'}
                        />
                      </span>
                    </GridItemContentHeader>
                    <GridItemLessons>
                      <div>
                        {item.numberOfTopics} {t('general.topics')}
                      </div>
                      <div>
                        {item.numberOfLessons} {t('general.lessons')}
                      </div>
                      <div>{formatDuration(item.duration)}</div>
                    </GridItemLessons>
                    {item.description && (
                      <article>
                        {gridStyle === 'card'
                          ? item.description.length > 80
                            ? item.description.substring(0, 80) + '...'
                            : item.description
                          : item.description.length > 300
                            ? item.description.substring(0, 300) + '...'
                            : item.description}
                      </article>
                    )}
                  </GridItemContent>
                </GridItem>
              </StylesCardWrapper>
            </div>
          )
        })}
        {lastItem || ''}
      </Grid>
    </Container>
  )
}

export default CardsLayout

const defs = (args: any) => {
  const styles: any = {}
  if (args.switched) {
    styles.top = '0px'
    styles.left = '0px'
    styles.gridTemplateColumns = '1fr 35px'
  } else {
    styles.bottom = '0px'
    styles.right = '0px'
    styles.justifyContent = 'end'
    styles.gridTemplateColumns = '35px 35px'
  }
  return styles
}

const CardActionBar = styled.div<{ switched: boolean }>`
  width: 100%;
  position: absolute;
  z-index: 99;
  box-sizing: border-box;
  padding: 6px;
  display: grid;
  ${defs}
  .action_block {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`
const StylesCardWrapper = styled.div`
  position: relative;
  cursor: pointer;
  &:hover {
    .action_block {
      background: rgba(255, 255, 255, 0.59) !important;
    }
  }
`
