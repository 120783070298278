import React, { useState, FC } from 'react'
import Popover from '@material-ui/core/Popover'
import IconButton from '@material-ui/core/IconButton'
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded'
import { makeStyles } from '@material-ui/core'
import { ManualVerifyType } from './ManualVerifyType'
import { ActionsContent, Container } from './StyledComponents'
import Icon from 'components/Icon'
import { borderRadius } from 'react-select/src/theme'

const useStyles = makeStyles({
  button: {
    postion: 'absolute',
    // top: '-10px',
    // right: '-10px',
    borderRadius: '12px',
    padding: '8px',
    background: 'rgba(255, 255, 255, 60%)',
    // '&:hover': {
    //   backgroundColor: 'transparent',
    // },
  },
  popover: {
    border: '1px solid #EBEBEB',
    borderRadius: '8px',
    padding: '12px 15px',
  },
})

const GridActionMenu: FC<ManualVerifyType> = ({
  actionConfig,
  row,
  button,
  icon,
  CIcon,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: React.BaseSyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      {actionConfig?.length > 0 && (
        <Container onClick={e => e.stopPropagation()}>
          <div onClick={handleClick}>
            {button ? (
              button
            ) : (
              <>
                {CIcon ? (
                  CIcon
                ) : (
                  <IconButton className={classes.button}>
                    <Icon
                      filename={'dots'}
                      style={{ transform: 'rotate(90deg)' }}
                    />
                  </IconButton>
                )}
              </>
            )}
          </div>

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{
              paper: classes.popover,
            }}
          >
            <ActionsContent>
              {actionConfig.map(
                (i: any, index: any) =>
                  !i.hide && (
                    <div key={index} onClick={() => setAnchorEl(null)}>
                      {i.render(row)}
                    </div>
                  ),
              )}
            </ActionsContent>
          </Popover>
        </Container>
      )}
    </>
  )
}

export default GridActionMenu
