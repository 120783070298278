import React from 'react'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { mainColor } from '../Colors/CommonColors'

const CustomSwitch = withStyles({
  switchBase: {
    color: mainColor,
    '&$checked': {
      color: mainColor,
    },
    '&$checked + $track': {
      backgroundColor: mainColor,
    },
  },
  checked: {},
  track: {},
})(Switch)

const CustomSwitcher: React.FC<{
  value: boolean
  onChange: any
  name?: string
  label?: String
  off?: String
  on?: string
}> = ({ value, onChange, name, label, on, off }) => {
  return (
    <FormControlLabel
      style={{
        width: 'min-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: 0,
      }}
      label={label}
      labelPlacement="start"
      control={
        <div>
          {off}
          <CustomSwitch
            checked={value}
            onChange={onChange}
            name={name}
            value={value}
          />
          {on}
        </div>
      }
    />
  )
}

export default CustomSwitcher
