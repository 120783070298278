import { useMutation } from "@apollo/react-hooks"
import Axios from "axios"
import { useUserValue } from "context/UserContext"
import { CREATE_TRANSACTION } from "gql/transaction/transaction.query"
import { useEffect } from "react"
import { v1 } from 'uuid'
import config from '../config/index'

const redirectDomain = config.ENV;
export const usePayment = () => {

  const [state] = useUserValue()


  const [createTransaction] = useMutation(CREATE_TRANSACTION);

  const orderUrl = 'https://payment.wenroll.com/api/order-checkout'

  const checkoutOrder = async (item: {
    "amount": number,
    "description": string,
    "quantity": number,
    "product_id": string
  }) => {

    const shopOrder = v1() + '-' + item.product_id
    const response = await Axios.post(orderUrl, {
      "courseId": item.product_id,
      "userId": state?.currentUser?.id,
      "redirectDomain": `https://${redirectDomain}.wenroll.com`,
      // "intent": "CAPTURE",
      // "items": [item],
      // "locale": "ka",
      // "shop_order_id": shopOrder,
      // "capture_method": "AUTOMATIC",
      // "purchase_units": [
      //   {
      //     "amount": {
      //       "currency_code": "GEL",
      //       "value": item.amount
      //     }
      //   }
      // ]

    })


    // await createTransaction({
    //   variables: {
    //     input: {
    //       orderId: response.data.orderId,
    //       userId: state.currentUser.id,
    //       shopOrderId: shopOrder,
    //     }
    //   }
    // })

    window.location.href = response.data.url
  }

  return [
    checkoutOrder
  ]
}