import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import format from 'format-duration'
import styled from 'styled-components'
import { gql } from 'graphql.macro'
import update from 'immutability-helper'
import strCutter from 'utils/strCutter'
import { useTranslation } from 'react-i18next'


import CheckBox from 'components/common/Checkbox'
import DndItem from 'components/common/Grid/DndGrid/DndItem'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { useMutation } from '@apollo/react-hooks'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import HistoryIcon from '@material-ui/icons/History'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import ShuffleIcon from '@material-ui/icons/Shuffle'
import SpellcheckIcon from '@material-ui/icons/Spellcheck'
import { mainColor } from 'components/common/Colors/CommonColors'
import { useUserValue } from 'context/UserContext'
import Checkbox from 'components/common/Checkbox'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

const ItemList = (props: any) => {
  const [state] = useUserValue()
  const location = useLocation()
  const { t } = useTranslation()
  const onCourseDetal_QuizzesTab = location.pathname.includes('courses')
  const isCompanySuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !!state?.currentUser?.companyId?.id

  const isCompanyAdmin =
    state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  const quizOrigin = (quizOrigin: string) => {
    let formatedQuizOrigin = ''
    if (quizOrigin === 'course') {
      formatedQuizOrigin = props.t('general.course')
    } else if (quizOrigin === 'module') {
      formatedQuizOrigin = props.t('general.module')
    } else if (quizOrigin === 'topic') {
      formatedQuizOrigin = props.t('general.topic')
    } else if (quizOrigin === 'lesson') {
      formatedQuizOrigin = props.t('general.lesson')
    }
    return formatedQuizOrigin
  }
  const theme = useTheme()

  return (
    <ItemContainer
      onClick={() => props.history.push(`/quizzes/${props.item.id}`)}
    >
      <HeaderContainer>
        {/* <Title>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {(isCompanySuperAdmin || isCompanyAdmin) && props.companyCourse && (
              <CheckBox
                onChange={() => props.selectItem(props.item.id)}
                checked={props.selectedItems.includes(props.item.id)}
              />
            )}
            <LocationOnIcon style={{ color: mainColor }} fontSize="small" />
            <span
              onClick={() => props.history.push(`/quizzes/${props.item.id}`)}
              style={{ textTransform: 'capitalize' }}
            >
              {strCutter(
                `${quizOrigin(props.item.quizType)}: ${props.item.parentName}`,
                20,
              )}
            </span>
          </div>
        </Title> */}
        <CheckBox
          onChange={() => props.selectItem(props.item.id)}
          checked={props.selectedItems.includes(props.item.id)}
        />
        <HeaderItem theme={theme}>
          <div className="count">
            {(props.item.numberOfQuestions && props.item.numberOfQuestions) ||
              0}
          </div>
          <div className="title">{props.t('quiz_card.question')}</div>
        </HeaderItem>
        <HeaderItem theme={theme}>
          <div className="count">{props.item.passRate || 0}%</div>
          <div className="title">{t('quiz_details.rate')}</div>
        </HeaderItem>
        <HeaderItem theme={theme}>
          <div className="count">{format(props.item.time * 1000 * 60)}</div>
          <div className="title">{t('general.time')}</div>
        </HeaderItem>

        <GridActionMenu actionConfig={props.actions} row={props.item} />
      </HeaderContainer>

      <ContentContainer>
        {/* <LibraryBooksIcon color="disabled" style={{ fontSize: '5rem' }} />

        <div className="content_block">
          <div className="quizParticipationType" style={{ color: mainColor }}>
            {props.item.IQTestType && props.t('general.iq_test')}
            {props.item.quizType === 'course' &&
              !props.item.IQTestType &&
              props.item.position &&
              props.t(`quiz_details.${props.item.position}`)}
            {props.item.quizType === 'module' &&
              props.t('quiz_details.module_quiz')}
            {['topic', 'lesson'].includes(props.item.quizType) &&
              props.t('quiz_details.knowledge_check')}
          </div>

          <span style={{ textTransform: 'capitalize' }}>
            {props.t(`general.${props.item.quizParticipationType}`)}
          </span>

          <span className="time">
            {props.item.time || 0} {props.t('general.minutes')}
          </span>
        </div> */}
        <MandatoryContainer>
          <Icon filename={'warn'} width={16} height={16} fill={'#317BF4'} />
          {props.t(`general.${props.item.quizParticipationType}`)}
        </MandatoryContainer>
      </ContentContainer>

      <StyledWrapper>
        <div className="wrapper">
          <div className="title_block">{props.item.name}</div>
          <div className="module_block">
            {props.item.tags &&
              props.item.tags.map((i: any, inedx: number) => (
                <span key={inedx}>{i.label}</span>
              ))}
          </div>
        </div>
      </StyledWrapper>

      <FooterContainer>
        {/* <FooterItem>
          <ContactSupportIcon style={{ color: mainColor }} />
          <span>
            <span>{props.t('quiz_card.question')} </span>
            <span>
              {(props.item.numberOfQuestions && props.item.numberOfQuestions) ||
                0}
            </span>
          </span>
        </FooterItem>

        <FooterItem>
          <SpellcheckIcon style={{ color: mainColor }} />
          <span>
            <span>{props.t('quiz_details.pass_rate')}</span>
            <span>{props.item.passRate || 0}%</span>
          </span>
        </FooterItem>

        <FooterItem>
          <HistoryIcon style={{ color: mainColor }} />
          <span>
            <span>{props.t('general.type')}</span>
            <span>
              {props.item.timeType === 'limited'
                ? props.t('general.limited')
                : props.item.timeType === 'estimated'
                ? props.t('general.estimated_time')
                : props.item.timeType}
            </span>
          </span>
        </FooterItem>

        <FooterItem>
          <ShuffleIcon
            color={props.item.randomization ? 'secondary' : 'disabled'}
            style={{ color: props.item.randomization ? mainColor : '' }}
          />
          <span>
            <span>{props.t('quiz_details.randomize')} </span>
            <span>
              {props.item.randomization
                ? props.t('general.yes')
                : props.t('general.no')}
            </span>
          </span>
        </FooterItem> */}
        {strCutter(
          `${quizOrigin(props.item.quizType)}: ${props.item.parentName}`,
          20,
        )}
      </FooterContainer>
    </ItemContainer>
  )
}

const CHANGE_ORDER = gql`
  mutation($draggedId: String!, $droppedId: String!) {
    changeQuizzesOrder(draggedId: $draggedId, droppedId: $droppedId)
  }
`

const QuizList = (props: any) => {
  const history = useHistory()
  const [changeQuizzesOrder] = useMutation(CHANGE_ORDER)
  const [cards, setCards] = useState(props.data)
  const moveCard = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )

  const handleChangeRequest = (draggedId: string, droppedId: number) => {
    changeQuizzesOrder({
      variables: { draggedId, droppedId },
    })
  }

  useEffect(() => {
    setCards(props.data)
  }, [props.data])

  return (
    <Container items={props.gridStyle}>
      {props.data.length <= 0 && (
        <div>
          {props.IQTest
            ? props.t('general.no_iq_test')
            : props.t('general.no_quizzes')}
        </div>
      )}

      {cards.map((i: any, index: number) =>
        props.isDraggable ? (
          <DndItem
            key={i.id}
            id={i.id}
            index={index}
            moveCard={moveCard}
            onEnd={handleChangeRequest}
            cardIds={cards.map((card: any) => card.id)}
            isNested
          >
            <ItemList
              actions={props.actions}
              item={i}
              index={index}
              history={history}
              selectItem={props.selectItem}
              selectedItems={props.selectedItems}
              isDraggable={props.isDraggable}
              moveCard={moveCard}
              t={props.t}
              companyCourse={props.companyCourse}
            />
          </DndItem>
        ) : (
          <ItemList
            key={i.id}
            actions={props.actions}
            item={i}
            index={index}
            history={history}
            selectItem={props.selectItem}
            selectedItems={props.selectedItems}
            isDraggable={props.isDraggable}
            moveCard={moveCard}
            t={props.t}
            companyCourse={props.companyCourse}
          />
        ),
      )}
    </Container>
  )
}

export default QuizList

const Container = styled.div<{ items: any }>`
  cursor: pointer;
  margin-top: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: ${({ items }) =>
    items === 'list' ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'};
`

const ItemContainer = styled.div`
  background: #fff;
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  border-radius: 16px;
  min-width: 370px;
`
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  padding-bottom: 10px;
`

const HeaderItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .count {
    font-size: 16px;
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 600;
    margin-bottom: -5px;
  }

  .title {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 16px;
  }
`

const MandatoryContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  gap: 6px;
  padding: 0px 8px;
  border: 1px solid #317bf4;
  border-radius: 8px;
  font-size: 14px;
  color: #317bf4;
  margin: auto;
`

const ContentContainer = styled.div`
  display: flex;
  position: relative;
  .content_block {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-left: 19px;
    .quizParticipationType {
      font-size: 15px;
      color: #d2364b;
      text-transform: capitalize;
    }
    .time {
      margin-top: 3px;
    }
  }
  .type_block {
    position: absolute;
    right: 0;
    bottom: 0;
    text-transform: capitalize;
  }
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  overflow: auto;
  font-size: 16px;
  font-weight: 600;
  border-top: 1px solid #ebebeb;
  padding: 5px 0px;
`
const FooterItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 11px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
`

const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const StyledWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #080a0a8a;
  font-size: 16px;
  text-align: center;
  min-height: 60px;
  .title_block {
    font-weight: 500;
    font-size: 17px;
  }
  .module_block {
    font-size: 12px;
    margin-top: 2px;
    display: flex;
    flex-wrap: wrap;
    span {
      padding: 2px 4px;
      background: #fff;
      box-sizing: border-box;
      margin-left: 3px;
      border-radius: 3px;
      margin-top: 3px;
    }
  }
`
