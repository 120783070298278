import { useQuery } from '@apollo/react-hooks'
import { GET_ALL_SUGGESTED_COURSE, SEARCH_COURSES } from './../../gql/course/course.query'

export const PER_PAGE = 10

export const useFetchSuggestedCourses = (
  filter: any, currentPage: number = 1,
  perPage: number = PER_PAGE,
  includePurchasedCourses?: boolean,
  searchText?: string,
) => {

  const { data, loading, error, refetch, fetchMore } = useQuery(
    GET_ALL_SUGGESTED_COURSE,
    {
      variables: { filter, currentPage, perPage, includePurchasedCourses },
      notifyOnNetworkStatusChange: true
    }
  )
  const errorMessage = error ? error?.message.split(':')[1] : ''

  return {
    courses: data && data.getSuggestedCourses,
    loading,
    error: errorMessage,
    refetch,
    fetchMore,
  }
}
