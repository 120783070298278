import styled from 'styled-components'

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  cursor: pointer;
`

export const FileTitle = styled.h3`
  color: #0645ad;
`

export const FileIcon = styled.figure`
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  margin-right: 5px;
`
