import { useMutation } from '@apollo/react-hooks'

import { GET_COMPANIES, ADD_COMPANY } from 'gql/companies.query'
import { updateCache } from 'services/updateCache'

const addCompanyService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addCompanyMutation, { loading, error }] = useMutation(ADD_COMPANY, {
    update(cache, { data: { addCompany: document } }) {
      updateCache({
        objectName: 'companies',
        query: GET_COMPANIES,
        cache,
        document,
        action: 'add',
        params
      })
    }
  })

  return {
    addCompanyMutation,
    loading,
    error
  }
}

export default addCompanyService
