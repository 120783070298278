import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import { useTheme } from '@material-ui/core'

interface Props {
  opened: boolean
  toggleDrawer?: (e: KeyboardEvent) => void
  children: React.ReactElement
  totalWidth?: number | string
  responsiveTotalWidth?: number | string
  borderRadius?: string
  anchor?: "bottom" | "left" | "right" | "top" | undefined
  drawerHeight?: string
}

const CustomDrawer = ({
  opened,
  children,
  toggleDrawer,
  totalWidth,
  responsiveTotalWidth,
  borderRadius,
  anchor,
  drawerHeight
}: Props) => {


  const theme = useTheme()

  const useStyles = makeStyles({
    drawer: {
      height: drawerHeight,
      borderRadius: borderRadius || '0px',
      width: totalWidth || '630px',
      background: theme.palette.background.default,
      ['@media (max-width: 480px)']: {
        width: responsiveTotalWidth || totalWidth || '630px',
      }
    },
  })

  const classes = useStyles()


  return (
    <div>

      <Drawer
        anchor={anchor || "right"}
        open={opened}
        onClose={(e: KeyboardEvent) => toggleDrawer?.(e)}
        classes={{ paper: classes.drawer }}
      >
        {children}
      </Drawer>
    </div>
  )
}

export default CustomDrawer
