import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'
import { useQuery } from '@apollo/react-hooks'
import { useUserValue } from 'context/UserContext'
import { GET_CATEGOTIES } from 'gql/categories/categories.query'
import SelectField, { Container, ErrorMessage } from './SelectField'

const SelectCategories: React.FC<{
  categories: { value: string; label: string }[]
  setFieldValue: any
  touched?: boolean
  error?: boolean
  errorMessage?: string
}> = ({ categories, setFieldValue, touched, error, errorMessage }) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const { data: categoriesData } = useQuery(GET_CATEGOTIES)
  const [selectedCategories, setSelectedCategories] = useState<
    { label: string; value: string }[]
  >([])
  const [categoriesList, setCategoriesList] = useState<
    { label: string; value: string }[]
  >([])

  useEffect(() => {
    categoriesData?.getCategories &&
      setCategoriesList(
        categoriesData.getCategories.map((i: any) => ({
          label: i.name,
          value: i.id,
        })),
      )
  }, [categoriesData])

  useEffect(() => {
    setSelectedCategories(categories)
  }, [categories])

  return (
    <>
      {state.userPermission.createNewCategory ? (
        <Container error={error && touched ? true : false}>
          <CreatableSelect
            isMulti
            onChange={(e: any) => {
              if ((e && e.length <= 3) || !e) {
                setSelectedCategories(e)
                setFieldValue('categories', e)
              }
            }}
            options={categoriesList || []}
            value={selectedCategories}
            placeholder={`${t('general.categories')}*`}
            classNamePrefix={'creatableselect'}
          />
          {touched && error && errorMessage && (
            <ErrorMessage>{errorMessage}</ErrorMessage>
          )}
        </Container>
      ) : (
        <SelectField
          isMulti
          onChange={(e: any) => {
            if ((e && e.length <= 3) || !e) {
              setFieldValue('categories', e)
            }
          }}
          options={categoriesList || []}
          value={selectedCategories}
          placeholder={`${t('general.categories')}*`}
          error={!!error}
          errorMessage={error || ''}
          touched={touched}
        />
      )}
    </>
  )
}

export default SelectCategories
