import { useLazyQuery } from '@apollo/react-hooks'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { Button } from 'components/common/Button'
import DateRangePicker from 'components/common/DateRangePicker/DateRangePicker'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
  Label,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import RangeSlider from 'components/common/RangeSlider'
import { AsyncSelect, SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import {
  CloseIcon,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Info,
} from 'components/DrawerLayout/styled-components'
import {
  filterOptions,
  genderOptionsForFilters,
  statuses,
} from 'components/UsersLayout/staticData'
import { useFormik } from 'formik'
import { GET_COMPANIES } from 'gql/companies.query'
import { FilterSuperAdminSchema } from 'helpers/validationSchemas'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { CapitalPlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'
import GenderPicker from 'components/common/GenderPicker'
import { useTheme } from '@material-ui/core'

const FilterModerators = (props: any) => {
  const { t } = useTranslation()
  const ref: any = useRef()

  const [
    fetchCompany,
    { data: companyData, loading: companyLoading },
  ] = useLazyQuery(GET_COMPANIES)

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: filterOptions,
    validationSchema: FilterSuperAdminSchema,
    onSubmit: (values: any) => {
      const data: any = { ...values }
      const formValues: any = {}

      const { status, ...args } = filterOptions

      formValues.firstName = {
        type: 'search',
        value: values.firstName,
        fields: ["firstName"]
      }

      formValues.lastName = {
        type: 'search',
        value: values.lastName,
        fields: ["lastName"]
      }

      formValues.note = {
        type: 'match',
        value: values.note,
      }

      formValues.location = {
        type: 'match',
        value: values.location,
      }

      if (data.status.value !== 'all') {
        formValues.status = {
          type: 'exact',
          value: data.status.value,
        }
      }

      if (values.company) {
        const companyIds = values.company.map((i: any) =>
          i.value === '-1' ? (i.value = null) : i.value,
        )
        const query = { companyId: { $in: companyIds } }
        // formValues.companyId = {
        //   type: 'arrayIn',
        //   value: companyIds,
        // }
        formValues.query = {
          type: 'query',
          value: JSON.stringify(query),
        }
      }

      const isAllZero = values.age.every((item: any) => item === 0)

      if (!isAllZero) {
        formValues.age = {
          type: 'range',
          value: `${values.age[0]}-${values.age[1]}`,
        }
      }




      if (values.gender !== 'all') {
        formValues.gender = {
          type: 'match',
          value: values.gender,
        }
      }

      values.createDate[0] =
        values.createDate[0] && new Date(values.createDate[0])
      values.createDate[1] =
        values.createDate[1] && new Date(values.createDate[1])

      const isSameDate =
        values.createDate[0] === values.createDate[1] &&
        values.createDate[0] !== null

      if (!values.createDate[1] && values.createDate[0] !== null)
        values.createDate[1] = new Date()

      if (!values.createDate[0] && values.createDate[1] !== null)
        values.createDate[0] = ''

      const formatedData = isSameDate
        ? values.createDate[0]
        : `${values.createDate[0] ? values.createDate[0].toString() : ''
        }${'-'}${values.createDate[1] ? values.createDate[1].toString() : ''}`

      formValues.createDate = {
        type: isSameDate ? 'exact' : 'range',
        value: formatedData,
      }

      if (props.companyId) {
        formValues.companyId = {
          type: 'exact',
          value: props.companyId,
        }
      }

      props.filter(formValues)
      props.setFilterOptions(values)
    },
  })




  useEffect(() => {
    if (props.filterOptions) {
      setValues(props.filterOptions)
    }
  }, [])

  const loadDataOptions = (e: any, action: string) =>
    setTimeout(() => {
      const value = e

      if (action === 'company') {
        fetchCompany({
          variables: {
            filter: {
              name: {
                type: 'match',
                value,
              },
            },
          },
        })
      }
    }, 200)

  const handleSelectChange = (e: string, action: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e, action)
    }
  }

  const handleRangeChange = (range: any) => {
    setFieldValue('age', range)
  }

  const [companyOptions, setCompanyOptions] = useState([] as any)

  useEffect(() => {
    if (companyData) {
      setCompanyOptions([
        {
          id: '-1',
          name: CapitalPlatformTitle,
          icon: (
            <StarsRoundedIcon
              style={{
                color: mainColor,
                fontSize: 10,
                verticalAlign: 'super',
              }}
            />
          ),
        },
        ...companyData.companies.data,
      ])
    }
  }, [companyData])

  const theme = useTheme()

  return (
    <>
      {props.filterLoading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>
          {t('general.advanced_filters')} - {t('actions.filter')}{' '}
          {t('general.super_admins')}
        </DrawerTitle>
        <CloseIcon
          onClick={props.closeDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width="48%">
                <TextInput
                  label={t('user_details.first_name')}
                  size="medium"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  label={t('user_details.last_name')}
                  size="medium"
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>

              <FormField width="100%">
                <GenderPicker
                  checked={values.gender}
                  onChange={e => setFieldValue('gender', e)}
                />
              </FormField>

              <FormField width="100%">
                <RangeSlider
                  label={t('form_fields.age_range')}
                  value={values.age}
                  onRangeChange={handleRangeChange}
                />
              </FormField>
              <FormField style={{ position: 'relative' }} width="48%">
                <Label>{t('general.status')}</Label>
                <SelectField
                  placeholder={t('general.status')}
                  options={statuses}
                  value={values.status}
                  onChange={(e: any) => setFieldValue('status', e)}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  label={t('form_fields.location')}
                  size="medium"
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>

              {props.hasSearchByCompany && (
                <FormField style={{ position: 'relative' }} width="100%">
                  {values.company && values.company.length > 0 ? (
                    <Label>{t('form_fields.search_companies')}</Label>
                  ) : null}
                  <AsyncSelect
                    isMulti
                    onInputChange={(e: any) => handleSelectChange(e, 'company')}
                    onChange={(e: any) => setFieldValue('company', e)}
                    data={companyOptions}
                    value={values.company}
                    label={t('form_fields.type_to_search_companies')}
                    loading={companyLoading}
                    labelDataKey="name"
                    valueDataKey="id"
                    iconDataKey="icon"
                  />
                </FormField>
              )}

              <FormField width="100%">
                <TextInput
                  label={t('user_details.note')}
                  size="medium"
                  name="note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="100%">
                <DateRangePicker
                  placeholder={t('general.create_date')}
                  values={values.createDate}
                  onChange={(e: any) => {
                    setFieldValue('createDate', e)
                  }}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={props.closeDrawer}
                background="#F5F5F5"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.filter_verb')}
                type="medium"
                color="secondary"
                btnType="submit"
                background={'#317BF4'}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterModerators
