import React, { useState, useEffect, memo } from 'react'
import _ from 'lodash'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import { useFetchGroups } from 'hooks/group/useGroups'
import SyncIcon from '@material-ui/icons/Sync'
import Search from 'components/common/Search'

import FilterListIcon from '@material-ui/icons/FilterList'

import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  GridToolbar,
  GridLayoutActions,
  GridLayoutForm,
  MultipleAction,
  MultipleActionTooltip,
  ActionButton,
} from 'components/common/Grid/styled-components'

import Grid from 'components/common/Grid'
import { Input } from 'components/common/TextInput'
import Drawer from 'components/common/Drawer'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import AddGroupDrawer from './AddGroupDrawer/AddGroupDrawer'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import { useUserValue } from 'context/UserContext'

import { columnConfig, actionConfig } from './gridConfig'
import useDeleteGroup from 'hooks/group/useDeleteGroup'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import ArchiveIcon from '@material-ui/icons/Archive'
import FilterDrawer from './FilterDrawer/FilterDrawer'
import TablePagination from 'components/common/Pagination/TablePagination'
import { mainColor } from 'components/common/Colors/CommonColors'
import styled from 'styled-components'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

const GroupLayout = (props: any) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [drawerOpened, setDrawerOpened] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [formData, setFormData] = useState<any>('')
  const [closeDrawerClicked, setCloseDrawerClicked] = useState<boolean>(false)
  const { fireSwal } = useSwal()
  const [reset, setReset] = useState(true)
  const [fetchAll, setFetchAll] = useState(false)
  const [fetchAllPagination, setFetchAllPagination] = useState([0, 10])
  const [page, setPage] = useState(1)
  const [filterDrawer, setFilterDrawer] = useState<any>({
    filterOptions: null,
    filterValues: null,
    opened: false,
  })
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    let deffilter =
      state?.currentUser?.companyId && state.currentUser.companyId !== null
        ? {
          company: {
            type: 'exact',
            value: state?.currentUser?.companyId?.id,
          },
        }
        : null
    setFilterDrawer({
      filterOptions: deffilter,
      filterValues: null,
      opened: false,
    })
  }, [state])

  const { groups, loading, error, refetch }: any = useFetchGroups(
    currentPage,
    perPage,
    filterDrawer.filterOptions,
  )

  const { groups: allGroups }: any = useFetchGroups(
    currentPage,
    perPage,
    filterDrawer.filterOptions,
    true
  )


  const { exportData } = useExportDataService()

  const { deleteGroupServ } = useDeleteGroup()

  const selectItem = (id: never, event: boolean) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = allGroups.data.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const handleDeleteGroup = (id?: string) => {
    let ids = selectedItem[currentPage]

    if (id) ids = [id]

    if (ids.length <= 0) return

    const params = {
      title:
        ids.length > 1
          ? `${t('popups.delete_many')} ${t('general.groups')}?`
          : `${t('popups.delete_single')} ${t('general.group')}?`,
      onConfirm: () => {
        deleteGroupServ(ids, refetch)
        setSelectedItem([])
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const handelClick = (e: any, field?: string) => {
    if (field === 'delete') {
      handleDeleteGroup(e)
      return
    }
    DrawerEventEmitter.emit('openDrawer', 'groupInformation', true, {
      id: e.id,
      data: { companyId: e.company ? e.company.id : null },
    })
  }

  const closeDrawer = () => setDrawerOpened(false)

  const handleCloseDrawer = () => setCloseDrawerClicked(true)

  const handleExportData = () => {
    if (selectedItem[currentPage].length > 0) {
      exportData('GROUP', selectedItem[currentPage], (link: string) => {
        window.open(link, '_blank')
      })
    }
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    let filterOptions = {
      ...filterDrawer.filterOptions,
      name: { type: 'match', value: searchText },
    }
    refetch({
      filter: {
        ...filterOptions,
      },
      currentPage,
      perPage,
    })

    setCurrentPage(1)

    setFilterDrawer({ ...filterDrawer, filterOptions })
    setSelectedItem({})
    setSelectAll({})
    setReset(false)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    setSearchText(value)
  }

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    if (((page - newPage) === 1 || (page - newPage) === 0) && fetchAll) {
      setFetchAllPagination(prevState => [prevState[0] + 10, prevState[1] + 10])
      setPage(page + 1)
      return
    } else if (((page > newPage)) && fetchAll) {
      setFetchAllPagination(prevState => [prevState[0] - 10, prevState[1] - 10])
      setPage(page - 1)
      return
    }
    refetch({
      filter: filterDrawer.filterOptions,
      currentPage,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    refetch({
      filter: {},
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
  }

  const handleResetClick = () => {
    refetch({
      filter: {},
      currentPage,
      perPage,
    })
    setSearchText('')
    setReset(true)
  }

  const handleFiltersClick = () => {
    setFilterDrawer({ ...filterDrawer, opened: true })
    setReset(false)
  }

  useEffect(() => {
    if (formData) {
      const isEmpty = _.values(formData).every(_.isEmpty)
      if (!isEmpty) {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            closeDrawer()
            setFormData('')
            setCloseDrawerClicked(false)
          },
          onClose: () => {
            setFormData('')
            setCloseDrawerClicked(false)
          },
          confirmText: 'Yes, Cancel!',
          cancelText: 'No',
        })
      }

      if (isEmpty) {
        closeDrawer()
        setFormData('')
        setCloseDrawerClicked(false)
      }
    }
  }, [formData])
  const theme = useTheme()

  const config = columnConfig(selectItem, true, false, t, theme)
  const actions = actionConfig(handelClick, state.userPermission, false, t)

  return (
    <Container theme={theme}>
      {/* <Toolbar styles={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <GridMultipleActions
            selectedItems={
              selectedItem[currentPage] ? selectedItem[currentPage].length : 0
            }
            actions={[
              {
                id: 0,
                color: 'secondary',
                tooltipText: `${t('actions.delete')}`,
                hide: !state.userPermission.deleteGroup,
                disabled: selectedItem[currentPage]
                  ? selectedItem[currentPage].length < 1
                  : true,
                onClick: () => handleDeleteGroup(),
                component: <DeleteIcon fontSize="small" />,
              },
              {
                id: 0,
                color: 'secondary',
                tooltipText: `${t('actions.export')}`,
                hide: !state.userPermission.deleteGroup,
                disabled: selectedItem[currentPage]
                  ? selectedItem[currentPage].length < 1
                  : true,
                onClick: () => handleExportData(),
                component: (
                  <ArchiveIcon
                    fontSize="small"
                    style={{ color: selectedItem.length < 1 ? '' : mainColor }}
                  />
                ),
              },
            ].filter((i: any) => !i.hide)}
          />
        </div>

        <ToolbarItem>
          <form onSubmit={handleSearchSubmit}>
            <Input
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              value={searchText}
              onChange={handleSearchChange}
              icon={
                <IconButton>
                  <SearchRoundedIcon />
                </IconButton>
              }
            />
          </form>
        </ToolbarItem>
      </Toolbar> */}
      <GridToolbar theme={theme}>
        <GridLayoutActions>
          {/* <div>
            {role === 'SUPER_ADMIN' && (
              // state.userPermission.createSuperAdmin &&
              <AddButton onClick={openAddDrawer}>
                <AddCircleRoundedIcon />
                {addText}
              </AddButton>
            )}
          </div> */}
        </GridLayoutActions>
        <GridLayoutForm>
          <ActionButton onClick={handleResetClick} disabled={reset} theme={theme}>
            <SyncIcon fontSize="small" />
          </ActionButton>
          <MultipleAction theme={theme}>
            <ActionButton onClick={handleFiltersClick} theme={theme}>
              <Icon filename={'filter'} fill={'#080A0AD6'} />
            </ActionButton>
            <MultipleActionTooltip>
              {t('actions.filters')}
            </MultipleActionTooltip>
          </MultipleAction>
          <Search
            inputProps={{
              value: searchText,
              onChange: handleSearchChange,
            }}
            onClick={handleSearchSubmit}
          />
        </GridLayoutForm>
      </GridToolbar>
      {loading ? (
        'Loading...'
      ) : (
        <Grid
          fetchAll={fetchAll}
          setFetchAll={setFetchAll}
          title={t('general.groups')}
          data={fetchAll ? allGroups?.data?.slice(...fetchAllPagination) : groups?.data}
          config={config}
          actions={() => [
            {
              id: 0,
              color: 'secondary',
              tooltipText: `${t('actions.delete')}`,
              hide: !state.userPermission.deleteGroup,
              disabled: selectedItem[currentPage]
                ? selectedItem[currentPage].length < 1
                : true,
              onClick: () => handleDeleteGroup(),
              component: (
                <ActionButton
                  disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
                  fill={'#CC3131'}
                  disabledFill={'#080A0AD6'}
                  theme={theme}
                >
                  <Icon filename={'delete'} />
                </ActionButton>
              ),
            },
            {
              id: 0,
              color: 'secondary',
              tooltipText: `${t('actions.export')}`,
              hide: !state.userPermission.deleteGroup,
              disabled: selectedItem[currentPage]
                ? selectedItem[currentPage].length < 1
                : true,
              onClick: () => handleExportData(),
              component: (
                <ActionButton
                  disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
                  fill={'#269F5A'}
                  disabledFill={'#080A0AD6'}
                  theme={theme}
                >
                  <Icon filename={'download'} />
                </ActionButton>
              ),
            },
          ]}
          redirectToGroupeDetails={handelClick}
          actionConfig={actions}
          selectAllItem={selectAllItem}
          selected={_.size(selectedItem[currentPage] || [])}
          selectedItems={selectedItem[currentPage] || []}
          onFiltersClick={() => {
            setFilterDrawer({ ...filterDrawer, opened: !filterDrawer.opened })
            setSelectedItem({})
            setSelectAll({})
          }}
          resetVisible={!!searchText || !!filterDrawer?.filterValues}
          resetFilters={() => {
            setFilterDrawer({
              filterOptions:
                state?.currentUser?.companyId &&
                  state.currentUser.companyId !== null
                  ? {
                    company: {
                      type: 'exact',
                      value: state?.currentUser?.companyId?.id,
                    },
                  }
                  : null,
              filterValues: null,
              opened: false,
            })
            setCurrentPage(1)
            setSelectedItem({})
            setSelectAll({})
            setSearchText('')
            refetch({
              filter: {},
            })
          }}
        />
      )}
      {groups && groups.data && (
        <TablePagination
          currentPage={groups && !fetchAll ? groups.currentPage : fetchAll && allGroups ? page : 0}
          // currentPage={page}
          rowsPerPage={perPage}
          count={groups ? groups.totalCount : 0}
          handleChangePage={handlePaginationClick}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <Drawer
        opened={drawerOpened}
        toggleDrawer={handleCloseDrawer}
        totalWidth="750px"
      >
        <AddGroupDrawer
          refetch={() => {
            refetch()
            setDrawerOpened(false)
          }}
          companyId={state.currentUser.companyId}
          onCancel={() => setDrawerOpened(false)}
          setFormData={setFormData}
          closeDrawerClicked={closeDrawerClicked}
        />
      </Drawer>
      <Drawer
        opened={filterDrawer.opened}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        <FilterDrawer
          hasSearchByCompany={!state?.currentUser?.companyId?.id}
          closeDrawer={() =>
            setFilterDrawer({ ...filterDrawer, opened: false })
          }
          filterOptions={filterDrawer}
          setFilterOptions={setFilterDrawer}
          refetchGroup={refetch}
          setCurrentPage={setCurrentPage}
          companyIdForFilter={state?.currentUser?.companyId?.id}
          isCoursePurchased={state?.currentUser?.companyId?.id !== null}
        />
      </Drawer>
    </Container>
  )
}

export default memo(GroupLayout)

const Container = styled.div`
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  height: 100%;
`
