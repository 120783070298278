import { useMutation } from "@apollo/react-hooks";
import { CREATE_NOTE } from "gql/notes/notes.query";

export type NewNote = {
  title: string
  description: string
  moment: number
  thumbnail?: string
}

export type CreateNote = {
  actionId: string
  model: "LESSON" | "SKILLTESTVIDEO"
  courseId: string
  newNote: NewNote
}

export const useCreateNote = (onCompleted: () => void) => {
  const [addNoteFunction, { data, loading, error }] = useMutation(CREATE_NOTE, {
    onCompleted: (data) => {
      if (data) {
        onCompleted();
      }
    }
  })

  return {
    addNoteFunction,
    loading,
    error,
    newNote: data && data.addNote
  }
};
