import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { Button } from 'components/common/Button'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Tooltip from '@material-ui/core/Tooltip'
import { useUserValue } from 'context/UserContext'

import Modal from 'components/common/Modal'
import Player from 'components/common/VideoPlayer/Player'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import FileWrapper from 'components/common/FileWrapper'

import { actionConfig } from './actionConfig'
import { DrawerEventEmitter } from 'helpers/drawer'
import strCutter from 'utils/strCutter'
import { formatDuration } from 'helpers/formatDuration'

import { useSwal } from 'hooks/useSwal'
import { useDeleteModuleService } from 'hooks/modules/useDeleteModuleService'
import useEditModuleService from 'hooks/modules/useEditModuleService'
import {
  CourseContentWrapper,
  CourseContentLeft,
  CourseContentRight,
  CourseContentHeader,
  CourseContentHeaderSection,
  CourseTitle,
  CourseContentDescription,
  CourseContentReview,
  CourseContentVideoCover,
  CourseContentPlayIcon,
} from 'components/courseDetail/styled-component'

import {
  ModuleContentDetailsContainer,
  ModuleContentDetailsHeader,
  CourseList,
  CourseItem,
  ModuleContentDetails,
  ModuleContentDetail,
} from './styled-components'
import { Row, Column } from 'components/common/DetailsLayout'
import Thumbnail from 'components/common/Thumbnail/Thumbnail'
import TextEditor from 'components/common/TextEditor/TextEditor.jsx'
import Loader from 'components/common/Loader'
import { mainColor } from 'components/common/Colors/CommonColors'
import { ActionButton } from 'components/common/Grid/styled-components'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

interface CourseParams {
  id: string
  name: string
  description: string
  level: string
  coursePrivacy: string
  coachType: string
  coach: any // for time
  certificateIncluded: Boolean
  certificatePhoto: string
  avatar: string
  skills: [
    {
      id: string
      label: string
    },
  ]
  status: string
  numberOfStudents: number
}

interface Props {
  course: CourseParams
  refetch: () => void
  topicsLength?: number
}

const ModuleDetailsContent = ({
  moduleItem,
  refetch,
  topicsLength,
  t,
  canModify,
}: any) => {
  const history = useHistory()
  const { editModule, loading: editModuleLoading } = useEditModuleService({
    moduleId: moduleItem.id,
  })
  const [state] = useUserValue()
  const { deleteModule, deletedModule } = useDeleteModuleService()
  const [editMode, setEditMode] = useState(false)
  const [videoModal, setVideoModal] = useState(false)
  const { fireSwal } = useSwal()

  const editModuleEditorText = (editorText: any, plainText: string) => {
    const newModule: any = {}
    newModule.editorText = editorText
    newModule.description = plainText
    editModule(moduleItem.id, newModule)
    setEditMode(false)
  }

  const handleEditClick = (id: string) => {
    DrawerEventEmitter.emit('openDrawer', 'editModuleDrawer', true, { id })
  }

  const handleDeleteClick = (field: any) => {
    if (field.courses.length !== 0) {
      fireSwal({
        title: t('popups.delete_module_warning'),
        confirmText: t('general.ok'),
        onConfirm: () => deleteModule([field.id]),
      })
    } else {
      fireSwal({
        title: t('popups.delete_module_single'),
        confirmText: t('popups.confirm_delete'),
        onConfirm: () => deleteModule([field.id]),
      })
    }
  }

  useEffect(() => {
    if (deletedModule.length !== 0) {
      history.push('/modules')
    }
  }, [deletedModule])

  const openAttachment = (attachment: string) => {
    window.open(attachment, '_blank')
  }

  const video = moduleItem.video

  const actions = actionConfig(
    handleEditClick,
    handleDeleteClick,
    openAttachment,
    state.userPermission,
    t,
  )

  const theme = useTheme()

  return (
    <div style={{ marginBottom: 20 }}>
      <CourseContentWrapper>
        <CourseContentLeft>
          <CourseContentHeader>
            <MainImageHolder>
              <img
                style={{
                  width: '470px',
                  height: '269px',
                  borderRadius: '16px',
                  marginRight: '30px',
                }}
                src={moduleItem.coverImage && moduleItem.coverImage.link}
                alt=""
              />
            </MainImageHolder>

            <CourseContentHeaderSection style={{ marginLeft: 30 }}>
              <CourseTitle theme={theme}>{moduleItem.name}</CourseTitle>
              <CourseList>
                {moduleItem.courses &&
                  moduleItem.courses.map((course: any) => (
                    <Tooltip title={course.name} key={course.courseId}>
                      <CourseItem theme={theme}>
                        <Link to={`/courses/${course.courseId}`}>
                          {strCutter(course.name, 20)}
                        </Link>
                      </CourseItem>
                    </Tooltip>
                  ))}
              </CourseList>
              <ModuleContentDetailsContainer>
                <ModuleContentDetailsHeader theme={theme}>
                  {t('module_details.module_details')}
                </ModuleContentDetailsHeader>

                <ModuleContentDetails>
                  <ModuleContentDetail theme={theme}>
                    <strong>{t('course_details.duration')}:</strong>{' '}
                    <span>{formatDuration(moduleItem.duration)}</span>
                  </ModuleContentDetail>
                  <ModuleContentDetail theme={theme}>
                    <strong>{t('general.topics')}:</strong>{' '}
                    <span>{moduleItem.numberOfTopics}</span>
                  </ModuleContentDetail>
                  <ModuleContentDetail
                    theme={theme}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <strong>{t('general.attachment')}:</strong>
                    <div
                      style={{ marginLeft: 5 }}
                    // onClick={() => openAttachment(moduleItem.attachment)}
                    >
                      {moduleItem.attachment ? (
                        <FileWrapper
                          file={moduleItem.attachment}
                          onClick={openAttachment}
                        />
                      ) : (
                        <NoValueWrapper>
                          {t('general.no_attachment')}
                        </NoValueWrapper>
                      )}
                    </div>
                  </ModuleContentDetail>
                  <ModuleContentDetail theme={theme}>
                    <strong>{t('general.lessons')}:</strong>{' '}
                    <span>{moduleItem.numberOfLessons}</span>
                  </ModuleContentDetail>
                </ModuleContentDetails>
              </ModuleContentDetailsContainer>
            </CourseContentHeaderSection>
          </CourseContentHeader>
          <CourseContentDescription>
            {editModuleLoading && <Loader withBackground={true} />}
            <TextEditor
              style={{ top: 60 }}
              text={moduleItem.editorText}
              isVisible={editMode}
              hasControls={true}
              editorTitle={t('module_details.module_overview')}
              closeEditMode={() => setEditMode(false)}
              openEditMode={() => setEditMode(true)}
              onSave={editModuleEditorText}
              canEdit={
                state.userPermission.editModule &&
                !(!moduleItem.companyId && state.currentUser.companyId)
              }
            />
          </CourseContentDescription>
          <CourseContentReview></CourseContentReview>
        </CourseContentLeft>
        <CourseContentRight
          style={{ textAlign: 'right' }}
          className={!video ? 'noVideo' : ''}
        >
          <div style={{ display: 'flex' }}>
            <Button
              text={t('actions.download')}
              icon={<Icon filename={'download'}
                fill={'#fff'} />}
              buttonStyles={{ borderRadius: '15px', padding: '10px 20px' }}
              onClick={() => {

              }}
              size={'large'}
            ></Button>
            <EditIconContainer onClick={() => handleEditClick(moduleItem.id)}>
              <Icon filename={'edit'} fill={theme.palette.text.primary}></Icon>
            </EditIconContainer>
          </div>
          {video ? (
            <CourseContentVideoCover>
              <img src={video.thumbnail} alt="" />
              <CourseContentPlayIcon onClick={() => setVideoModal(true)}>
                <PlayCircleOutlineIcon
                  fontSize="large"
                  style={{ color: mainColor }}
                />
              </CourseContentPlayIcon>
            </CourseContentVideoCover>
          ) : null}
        </CourseContentRight>
      </CourseContentWrapper>

      <Modal isOpened={videoModal} onClose={() => setVideoModal(false)}>
        <Player
          url={video && video.links[0].url}
          subtitle={moduleItem.subtitle}
        />
      </Modal>
    </div>
  )
}

export default ModuleDetailsContent

const NoValueWrapper = styled.div`
  opacity: 0.5;
  font-style: italic;
`

const MainImageHolder = styled.div`
  position: relative;

  .btn-wrapper
  {
    position: absolute!important;
    bottom: 20px;
    left: 20px;
  }
`

const EditIconContainer = styled.button`
  border: 1px solid #080A0A12;
  border-radius: 8px;
  outline: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 15px;
  cursor: pointer;
  margin-left: 15px;
`
