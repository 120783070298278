import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Backdrop, IconButton, makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import SyncIcon from '@material-ui/icons/Sync'
import { Grid } from 'components/common/Cards/styled-components'
import Drawer from 'components/common/Drawer'
import Pagination from 'components/common/Pagination'
import { Input } from 'components/common/TextInput'
import { ToolbarItem } from 'components/common/Toolbar'
import Player from 'components/common/VideoPlayer/Player'
import { useCourseByUserId } from 'hooks/course/useCourseByUserId'
import CourseList from 'pages/courses/CourseList'
import FilterDrawer from 'pages/courses/FilterDrawer/FilterDrawer'
import { Wrapper } from 'pages/companies/CompanyDetails/styled-components'
import { PersonalInformationHeader } from '../AdminProfile/PersonalInformation'
import FilterListIcon from '@material-ui/icons/FilterList'
import { mainColor } from 'components/common/Colors/CommonColors'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { GET_CURRENT_USER } from 'gql/users.query'
import { useFetchCommonCourses } from 'hooks/users/useCommonCourses'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export const UserCourses: React.FC<{
  userId: string
  groups: string[]
  redirectToCoursePage?: boolean
  isTitleHidden?: boolean
  companyId?: string | null
  stat?: boolean
  isDone: boolean
  section?: string
}> = ({
  userId,
  groups,
  redirectToCoursePage,
  isTitleHidden,
  companyId,
  stat,
  isDone
}) => {
    const { t } = useTranslation()
    const [filterIsOpen, setFilterIsOpen] = useState(false)
    const [filterData, setFilterData] = useState<any>({})
    const [filterQuery, setFilterQuery] = useState<any>({})
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(3)
    const [searchText, setSearchText] = useState<string>('')
    const [hideCourses, setHideCourses] = useState(false);

    const initialVariables = {
      groups: {
        type: 'nestedArrayIn',
        value: groups,
        nestedField: 'groupId',
      },
    }

    const generateQuery = () => {
      const where: any = {
        query: { value: {}, type: 'query' },
      }

      where.query = {
        type: 'query',
        value: {
          ...where.query.value,
        },
      }

      const searchedGroupIds = filterData?.group
        ? filterData.group.map(
          (group: { value: string; label: string }) => group.value,
        )
        : []
      const groupsIds = searchedGroupIds
        ? [...searchedGroupIds, ...groups]
        : [...groups]

      where.groups = {
        type: searchedGroupIds.length < 1 ? 'nestedArrayIn' : 'nestedArrayAll',
        value: groupsIds,
        nestedField: 'groupId',
      }

      return where
    }

    const query = generateQuery()

    query.query = {
      type: 'query',
      value: JSON.stringify({ ...query.query.value, ...filterQuery }),
    }

    if (searchText) {
      query.name = {
        type: 'match',
        value: searchText,
      }
    }



    const { courses, courseCount, refetch } = useFetchCommonCourses(userId, null, null, isDone)
    const { data: user } = useQuery(GET_CURRENT_USER)




    const filterDrawer = () => {
      setFilterIsOpen(!filterIsOpen)
    }

    const resetFilter = () => {
      setFilterQuery({})
      setFilterData({})
      setFilterIsOpen(false)
      setSearchText('')
    }

    const [isCoursesVisible, setCoursesVisible] = useState(true)

    const classes = useStyles()

    const [videoModal, setVideoModal] = useState<any>({
      modal: false,
      data: '',
      subtitle: '',
    })

    const handleVideoModal = (data: any) => {
      if (!data.video) return

      setVideoModal({
        ...videoModal,
        modal: !videoModal.modal,
        data: !videoModal.modal ? data.video.links[0].url : '',
        subtitle: !videoModal.modal ? data.subtitle : '',
      })
    }




    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

    if (courses?.length === 0) {

      return null
    } else {
      const doneCourses = courses?.filter((course: any) => {
        if (course.completed == true) {

          return { ...course }
        }
      })

      const myCourses = courses?.filter((course: any) => {
        if (!course.completed) {

          return { ...course }
        }

      })

      if (isDone && !(doneCourses?.length > 0)) {
        return null
      }
      if (!isDone && !(myCourses?.length > 0)) {

        return null
      }
    }
    return (

      <div>
        {!isTitleHidden && (
          <PersonalInformationHeader theme={theme}>

            <h1>
              {!isDone ? `${user?.currentUser?.role === 'STUDENT' ? t('general.myCourses') : t('general.currentCourses')}` : t('general.courses_done')}
            </h1>
            {/* <div
              style={{
                color: theme.palette.text.primary,
                fontSize: '13px',
                marginLeft: 'auto',
                fontWeight: 500,
              }}
              onClick={() => {
                refetch({
                  userId: userId,
                  currentPage: 1,
                  perPage: courseCount,
                  isDone
                })
                setHideCourses((oldState) => oldState ? false : true)
              }}
            >
              {t('general.show_all')}
            </div> */}
            {courses && courses?.length >= 4 &&
              <div
                style={{
                  fontSize: '13px',
                  marginLeft: 'auto',
                  fontWeight: 500,
                }}

                onClick={() => {
                  refetch({
                    userId: userId,
                    currentPage: null,
                    perPage: null,
                    isDone
                  })
                  setHideCourses((oldState) => oldState ? false : true)
                }}
              >
                {hideCourses ? t('general.show_all') : t('general.show_less')}
              </div>
            }
          </PersonalInformationHeader>
        )
        }

        {
          isCoursesVisible && !hideCourses && (
            <>
              <Grid className="card" style={{ position: 'relative' }} cols={isSmallScreen ? 3 : 2}>
                {courses && (
                  <CourseList
                    sectionName="myCourse"
                    isDone={isDone}
                    data={courses}
                    gridStyle="card"
                    selectedItem={[]}
                    handleVideoModal={handleVideoModal}
                    selectItem={() => { }}
                    redirectToDashboard={!redirectToCoursePage}
                    userId={userId}
                    stat={stat}
                  />
                )}
              </Grid>
            </>
          )
        }

        {
          isCoursesVisible && hideCourses && (
            <>
              <Grid className="card" style={{ position: 'relative' }} cols={isSmallScreen ? 3 : 2}>
                {courses && (
                  <CourseList
                    isDone={isDone}
                    data={[courses[0], courses[1], courses[2]]}
                    gridStyle="card"
                    selectedItem={[]}
                    handleVideoModal={handleVideoModal}
                    selectItem={() => { }}
                    redirectToDashboard={!redirectToCoursePage}
                    userId={userId}
                    stat={stat}
                  />
                )}
              </Grid>
            </>
          )
        }

        <Drawer opened={filterIsOpen} toggleDrawer={() => { }} totalWidth="700px">
          <FilterDrawer
            filterData={filterData}
            setFilterData={setFilterData}
            setFilterQuery={setFilterQuery}
            perPage={perPage}
            generateQuery={generateQuery}
            // refetchCourse={refetch}
            resetFilter={resetFilter}
            onClose={() => setFilterIsOpen(false)}
            t={t}
            companyId={companyId}
            isOnStudentProfile={true}
            isCoursePrivacyHidden={true}
          // setCurrentPage={setCurrentPage}
          />
        </Drawer>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={videoModal.modal}
          onClose={() => {
            setVideoModal({
              ...videoModal,
              modal: false,
            })
          }}
          className={classes.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Player url={videoModal.data} subtitle={videoModal.subtitle} />
        </Modal>
      </div>
    )
  }

