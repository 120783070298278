import { useMutation } from '@apollo/react-hooks'

import { ADD_GROUP, GET_GROUP } from 'gql/group/group.query'
import { updateCache } from 'services/updateCache'

const addGroupService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addGroupMutation] = useMutation(ADD_GROUP, {
    update(cache, { data: { addGroup: document } }) {
      updateCache({
        objectName: 'getAllGroups',
        query: GET_GROUP,
        cache,
        document,
        action: 'add',
        params,
      })
    },
  })

  return {
    addGroupMutation,
  }
}

export default addGroupService
