import { useMutation } from "@apollo/react-hooks"
import { updateCache } from 'services/updateCache'

import { EDIT_GROUP, GET_GROUP, ASSIGN_COACH } from "gql/group/group.query"

const editGroupService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editGroupMutation] = useMutation(EDIT_GROUP, {
    update(cache, { data: { editGroup: document } }) {
      updateCache({
        objectName: 'getAllGroups',
        query: GET_GROUP,
        cache,
        document,
        action: 'update',
      })

      // updateCache({
      //   objectName: 'getGroupById',
      //   query: GET_GROUP_BY_ID,
      //   cache,
      //   document,
      //   action: 'update',
      // })
    }
  })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [assignCoachMutation] = useMutation(ASSIGN_COACH)

  return {
    editGroupMutation,
    assignCoachMutation
  }
}

export default editGroupService
