import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import styled from 'styled-components'

import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'

import { GET_COACHES_BY_COMPANYID } from 'gql/users.query'
import { useUserValue } from 'context/UserContext'
import { GET_ALL_SKILLS } from 'gql/skilss.query'
import { Input } from 'components/common/TextInput'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormGroup,
  FormFooter,
  FormButtons,
  FormGroupItem,
} from 'components/common/Form'
import { generateFullName } from 'utils/utils'
import { Button } from 'components/common/Button'
import { AsyncSelect, SelectField } from 'components/common/SelectField'
import {
  courseState,
  courseLevels,
  selectOptions,
  filterOptions,
} from 'pages/courses/staticData'
import { GET_CATEGOTIES } from './../../../gql/categories/categories.query'
import { mainColor } from 'components/common/Colors/CommonColors'
import { GET_GROUPS } from 'gql/group/group.query'
import { useTheme } from '@material-ui/core'

export const FilterDrawer = (props: any) => {
  const { t } = props
  const [state] = useUserValue()
  const ref: any = useRef()
  const [studentRange, setStudentRange] = useState([0, 300])
  const [filterData, setFilterData] = useState<any>(filterOptions)
  const [
    fetchCoach,
    { data: coachData, loading: coachingLoading },
  ] = useLazyQuery(GET_COACHES_BY_COMPANYID)
  const [fetchGroup, { data: groupData, loading: groupLoading }] = useLazyQuery(
    GET_GROUPS,
  )

  const { data: skillsData } = useQuery(GET_ALL_SKILLS)
  const { data: categoriesData } = useQuery(GET_CATEGOTIES)

  const author: { label: string; value: string }[] = [
    { label: t('general.wenroll'), value: null },
    {
      label: state.currentCompany?.name,
      value: state.currentUser.companyId?.id,
    },
  ]

  const handleFieldChange = (field: string) => {
    return (e: any) => {
      let item = field === 'name' ? e.target.value : e

      setFilterData({
        ...filterData,
        [field]: item,
      })
    }
  }

  useEffect(() => {
    if (!_.isEmpty(props.filterData)) {
      const { numberOfStudents, ...args } = props.filterData
      setFilterData(args)

      const students = numberOfStudents || [0, 300]
      setStudentRange(students)
    }
  }, [])

  const onFormSubmit = () => {
    const {
      name,
      coaches,
      group,
      video,
      skills,
      categories,
      company,
      ...args
    } = filterData
    props.setFilterData({
      ...filterData,
      numberOfStudents: studentRange,
    })

    const filterQuery: any = {}

    if (props.defaultVariables) {
      filterQuery['companyId'] = props.defaultVariables.filter.companyId.value
    }

    for (const key in args) {
      if (args[key].value && args[key].value !== 'all') {
        filterQuery[key] = args[key].value
      }
    }

    if (video?.value === 'no' || video?.value === 'yes') {
      filterQuery.video = video.value === 'no' ? null : { $ne: null }
    }

    if (name) filterQuery.name = { $regex: name, $options: 'i' }

    if (coaches && coaches.length > 0) {
      const selectedCoachesIds = coaches.map((i: any) => i.value)
      filterQuery['coaches.userId'] = { $in: selectedCoachesIds }
    }

    if (group && group.length > 0) {
      filterQuery['groups.groupId'] = { $in: group.map((i: any) => i.value) }
    }

    if (skills && skills.length > 0) {
      filterQuery['skills.label'] = { $in: skills.map((i: any) => i.label) }
    }

    if (categories && categories.length > 0) {
      filterQuery['categories.name'] = {
        $in: categories.map((i: any) => i.label),
      }
    }

    if (company?.value === null || !!company?.value) {
      filterQuery.isBought = !company.value
    }

    if (studentRange[0] > 0 || studentRange[1] < 300) {
      filterQuery.numberOfStudents = {
        $gte: studentRange[0],
        $lte: studentRange[1],
      }
    }

    props.setFilterQuery(filterQuery)
    props.setCurrentPage && props.setCurrentPage(1)
    props.refetchCourse()
    props.onClose()
  }

  const loadDataOptions = (e: any, field: string) =>
    setTimeout(() => {
      const value = e

      if (field === 'coach') {
        fetchCoach({
          variables: { companyId: state.selectedCompanyId },
        })
      }

      if (field === 'group') {
        let filter = {
          name: {
            type: 'match',
            value,
          },
          company: {
            type: 'exact',
            value: state.selectedCompanyId,
          },
        }

        fetchGroup({
          variables: {
            filter: {
              ...filter,
            },
            perPage: 0,
          },
        })
      }
    }, 200)

  const handleSelectChange = (e: string, field: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e, field)
    }
  }

  const skillList =
    skillsData &&
    skillsData.getAllSkills.map((i: any) => ({
      label: i.label,
      id: i.id,
      value: i.id,
    }))

  const categoriesList =
    categoriesData &&
    categoriesData.getCategories.map((category: any) => ({
      label: category.name,
      value: category.id,
      id: category.id,
    }))

  const theme = useTheme();
  return (
    <DrawerContent>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>
          {t('general.advanced_filters')} - {t('actions.to_filter')}{' '}
          {t('general.courses')}
        </DrawerTitle>
        <IconButton onClick={props.onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <FormContainer>
        <FormGroupWrapper>
          <FormGroup onSubmit={(e: any) => e.preventDefault()}>
            <FormGroupItem>
              <Input
                label={t('form_fields.course_name')}
                name="name"
                size="small"
                type="text"
                fullWidth
                value={filterData.name}
                onChange={handleFieldChange('name')}
              />
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.categories')}</Label>
                <SelectField
                  isMulti
                  label="categories"
                  name="categories"
                  options={categoriesList}
                  value={filterData.categories}
                  onChange={handleFieldChange('categories')}
                  placeholder={t('form_fields.select')}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>
                  {t('courses_layout.published')}/
                  {t('courses_layout.unpublished')}
                </Label>
                <SelectField
                  label={t('form_fields.select')}
                  name="state"
                  options={courseState}
                  value={filterData.state}
                  onChange={handleFieldChange('state')}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('form_fields.course_level')}</Label>
                <SelectField
                  label="Level"
                  name="level"
                  options={courseLevels}
                  value={filterData.level}
                  onChange={handleFieldChange('level')}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('form_fields.intro_video')}</Label>
                <SelectField
                  onChange={handleFieldChange('video')}
                  options={selectOptions}
                  value={filterData.video}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.coach')}</Label>
                <AsyncSelect
                  isMulti
                  onInputChange={(e: string) => handleSelectChange(e, 'coach')}
                  onChange={handleFieldChange('coaches')}
                  value={filterData.coaches}
                  label={t('form_fields.type_to_search_coaches')}
                  data={coachData?.getCoachesByCompany || []}
                  loading={coachingLoading}
                  labelDataName={generateFullName}
                  valueDataKey="id"
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.skills')}</Label>
                <SelectField
                  isMulti
                  value={filterData.skills}
                  placeholder={t('form_fields.select')}
                  options={skillList}
                  onChange={handleFieldChange('skills')}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.groups')}</Label>
                <AsyncSelect
                  isMulti
                  onInputChange={(e: string) => handleSelectChange(e, 'group')}
                  onChange={handleFieldChange('group')}
                  data={(groupData && groupData.getAllGroups.data) || []}
                  value={filterData.group}
                  label={t('form_fields.type_to_search_groups')}
                  loading={groupLoading}
                  labelDataKey="name"
                  valueDataKey="id"
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.author')}</Label>
                <SelectField
                  value={filterData.company}
                  placeholder={t('form_fields.select')}
                  options={author}
                  onChange={handleFieldChange('company')}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <Typography id="range-slider" gutterBottom>
                {t('course_details.number_of_students')}
              </Typography>
              <Slider
                value={studentRange}
                onChange={(e: any, i: any) => setStudentRange(i)}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={0}
                max={300}
              />
            </FormGroupItem>
          </FormGroup>
        </FormGroupWrapper>

        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.clear')}
              type="small"
              onClick={() => {
                props.resetFilter()
                props.onClose()
              }}
              background="#E0E1E2"
              textColor="#414141"
            />

            <Button
              text={t('actions.filter')}
              type="small"
              color="secondary"
              onClick={onFormSubmit}
              background={mainColor}
            />
          </FormButtons>
        </FormFooter>
      </FormContainer>
    </DrawerContent>
  )
}

const FormItemContainer = styled.div`
  position: relative;
`
const Label = styled.div`
  position: absolute;
  background: #fff;
  color: rgba(0, 0, 0, 0.54);
  z-index: 1;
  line-height: 1;
  top: -7px;
  font-size: 13px;
  left: 10px;
`
