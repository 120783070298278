import React from 'react'
import styled from 'styled-components'
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'
import Icon from 'components/Icon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    badge: {
      '& > span': {
        background: '#CC3131',
        fontWeight: 'bold',
        width: '19px',
        height: '19px',
      },
    },
  }),
)

const NotificationBadge = (props: any) => {
  const classes = useStyles()
  const theme = useTheme();

  return (
    <Container className={classes.root}>
      <Badge
        badgeContent={props.numberOfNewNotifications}
        // color="secondary"
        onClick={props.open}
        className={classes.badge}
      >
        <Icon fill={theme.palette.text.primary} width={20} height={20} filename={'notification'} />
      </Badge>
    </Container>
  )
}

export default NotificationBadge

const Container = styled.div`
  cursor: pointer;

`
