import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { GET_SUBSCRIPTION_PRODUCTS } from 'gql/subscriptions/subscription.query'
import { useQuery } from '@apollo/react-hooks';
import axios from "axios"
import styled from 'styled-components'
import logo from "../../assets/logo/blackLogo.svg"
import check from "../../assets/check.svg"

import { AppBar, Switch, useMediaQuery, useTheme } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar'
import useSnackbarAlert from 'hooks/useSnackbar'

interface PurchaseCourseProps {
  userId: string;
  subscription: any
}
const PurchaseCourse: React.FC<PurchaseCourseProps> = ({ userId, subscription }) => {
  const { setSnackbar } = useSnackbarAlert()

  const theme = useTheme();
  const textDate = ["გამოწერის შემდეგ გაგიაქტიურდება წვდომა ყველა კურსზე",
    "წვდომა გექნება გამოწერის პერიოდში დამატებულ ყველა კურსზე ",
    "ყოველი თვის დასაწყისში აპლიკაციაში ემატება მინ. 5 კურსი",
    "ვადის გასვლის შემდეგ არჩეული პაკეტი ავტომატურად განახლდება",
    "გამოწერის გაუქმება შეგიძლია ნებისმიერ დროს პროფილიდან",
    "გამოწერის გაუქმებით შეგეზღუდება წვდომა კურსებზე"
  ]
  const { id } = useParams()
  const { data, loading } = useQuery(GET_SUBSCRIPTION_PRODUCTS)




  const usePayment = (
    userId: string,

    price?: string
  ) => {
    const orderUrl = "https://payment.wenroll.com/api/subscribe";

    const checkoutOrder = async (productId: string) => {
      let link
      try {
        const response = await axios.post(orderUrl, {
          userId,
          productId,
          // Your DeepLink Goes Here
          // TODO: Remove Test when going to production
          redirect_url: "wenroll://coursesList",
          // redirect_url: "wenroll://coursesListTest",
        });

        // await window.open(response.data.redirectUrl, "_blank");
        link = await response.data.redirectUrl
        // analytics().logEvent("purchase", {
        //   type: "Subscription",
        //   price,
        // });
      } catch (error) {
        const message = `${error}`
        const variant = 'error'
        // Alert.alert("Error", "Something went wrong, please try again later");
        setSnackbar({ message, variant })
        console.log("error", error);
      }
      return link
    };

    return [checkoutOrder];
  };
  const [checkoutOrder] = usePayment(id || userId);

  const handleClick = async (productId: string) => {

    let link = await checkoutOrder(productId)
    window.location.href = link;

  }
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const ThemeSwitch = styled(Switch)(({ theme }) => ({
    width: isSmallScreen ? 61 : 41,
    height: isSmallScreen ? 37 : 25,
    padding: isSmallScreen ? 7 : 13,
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: '25px',
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(3px) translateY(3px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(27px) translateY(3px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url(../svg/themedark.svg)`,
        },
        '& + .MuiSwitch-track': {
          opacity: 0,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: '100%',
      width: isSmallScreen ? 27 : 18,
      height: isSmallScreen ? 27 : 18,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(../svg/themelight.svg)`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 0
    },
  }));
  return (
    <MainContainer >
      <>
        {/* <div className='makeStyles-content-4'> */}
        <div className=" purchase-container">
          {/* <StyledHeader theme={theme}> */}
          {/* <AppBar
            // position="fixed"
            style={{ width: '100%', background: 'none' }}

          > */}
          <Toolbar style={{ padding: "27px 0" }}>
            <Wrapper >

            </Wrapper>
          </Toolbar>
          {/* </AppBar> */}
          {/* </StyledHeader > */}

          {/* </div> */}
        </div>
        <ContentContainer className='purchase-container' >
          <LeftContainer>
            <ContentTitle>
              <BlackWord>აირჩიე</BlackWord>  შენზე მორგებული პაკეტი
            </ContentTitle>
            <>
              {textDate.map(item => (
                <Content >
                  <div>
                    <img src={check} alt="check" />
                  </div>
                  <p>{item}</p>
                </Content>
              )
              )}
            </>
          </LeftContainer>
          <RightContainer>
            {data?.getSubscriptionProducts?.data.map((item: any) => (
              <>
                <ProductContainer key={item.id}>
                  <ProductInfo>
                    <SmallContainer>
                      <Duration>
                        <Month> {item.occurrenceNumber} თვიანი</Month>
                        <Package>პაკეტი</Package>
                      </Duration>
                      <Price>
                        {item.price} ₾
                      </Price>
                    </SmallContainer>
                    <Points>
                      <div>
                        <img src={check} alt="check" />
                      </div>
                      <p>დაგერიცხება 100 ქულა</p>
                    </Points>
                    {/* <Button
                      disabled={

                        subscription ? true : false
                      }
                      onClick={() => handleClick(item.id)}
                    >
                      არჩევა პატარა
                    </Button> */}
                  </ProductInfo>

                  <div style={{ "zIndex": 1000 }}>
                    <Button disabled={
                      subscription ? true : false
                    } onClick={() => handleClick(item.id)}>არჩევა</Button>
                  </div>
                </ProductContainer>

                <ProductContainerLarger>
                  <SmallContainer>
                    <Duration>
                      <Month>{item.occurrenceNumber} თვიანი</Month>
                      <Package>პაკეტი</Package>
                    </Duration>
                    <Price>
                      {item.price} ₾
                    </Price>
                  </SmallContainer>
                  <Points>
                    <div>
                      <img src={check} alt="check" />
                    </div>
                    <p>დაგერიცხება {item.occurrenceNumber == "1" && 100}{item.occurrenceNumber == "6" && 700}{item.occurrenceNumber == "12" && 1500} ქულა</p>
                  </Points>
                  <div style={{ "zIndex": 1000 }}>
                    <Button disabled={
                      subscription ? true : false
                    } onClick={() => handleClick(item.id)}>არჩევა </Button>
                  </div>
                </ProductContainerLarger>
              </>
            ))}
          </RightContainer>

        </ContentContainer>
        <Footer className='purchase-container'>
          <LeftSection>
            © 2021 WENROLL. All Rights Reserved.
          </LeftSection>
          <RightSection>
            <a style={{ marginRight: "10px" }} href="https://www.wenroll.com/privacy"><p>Privacy Policy  |  Terms of Service</p></a>

            <a href="https://www.wenroll.com/terms"><p>support@wenroll.com</p></a>
          </RightSection>
        </Footer>
      </>
    </MainContainer >
  )
}

const StyledHeader = styled.header`
  .MuiPaper-elevation4 {
    box-shadow: none !important;
    // background: ${({ theme }) => theme.palette.secondary.main};
    background: black;
  }
  // &::before {
  //   content: '';
  //   position: fixed;
  //   top: 0;
  //   width: 100%;
  //   height: 462px;
  //   // background: transparent
  //   //   linear-gradient(180deg, #317bf45c 0%, #317bf400 100%) 0% 0% no-repeat
  //   //   padding-box;
  // }
  .MuiToolbar-root {
    padding: 27px 24px;
    @media (max-width:450px ){
      padding: 27px 0;
    }
  }
`
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background:  #FFFF;

  @media (min-width: 540px ){
    background: #F1F3F8;
  }
`
const Footer = styled.div`
margin-bottom: 20px;
// padding: 0 20px;
display: none;
margin-top: 100px;
// left: 50px;
// position: fixed;
// bottom: 0; /* Position the div at the bottom */

justify-content: space-between;
@media (min-width: 760px) {
  display: flex;
}
@media (min-width: 1100px) {
margin-top: 150px;

padding-right: 65px;
}
`
const LeftSection = styled.p`

`
const RightSection = styled.p`
display: flex;
width: 50%;
justify-content: space-between;

`
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Logo = styled.img`
width: 250px;
@media (max-width: 640px) {
  width: 160px;
}
`


const ContentContainer = styled.div`
margin-top: 0;
display: flex;
flex-direction: column-reverse;
justify-content: space-between;
margin-top: 80px;
@media (min-width: 500px) {
  // margin-top: 30px;
}
@media (min-width: 540px) {
  margin-top: 30px;
  background: #F1F3F8;
  flex-direction: column;

}

@media (min-width: 700px) {
  margin-top: 60px;
}
@media (min-width: 1100px) {
  flex-direction: row;
  margin-top: 100px;
}
@media (max-width: 640px) {
  // flex-direction: column;
  // margin-top: 100px;
}
`
const LeftContainer = styled.div`
@media (min-width: 700px) {

}

`
const RightContainer = styled.div`
display: flex;
justify-content: space-between;
flex-direction: column;
align-items: center;
@media (min-width: 540px) {
flex-direction: row;
}
@media (min-width: 1100px) {
  flex-direction: column;
  width: 70%;
  }
`
const Content = styled.div`
display: flex;
margin-bottom: 8px;
color: #000000;
@media (min-width: 760px) {
  justify-content: center
    }
@media (min-width: 1100px) {
  justify-content: unset;
margin-bottom: 24px;
font-size: 16px;
  }
`
const ContentTitle = styled.p`
font-size: 14px;
font-weight: 700;
position: absolute;
top: 100px;
// margin-bottom: 24px;
left: 50%;
width: 250px;
  transform: translateX(-50%);
// margin: auto;
color: #056EC4;
@media (min-width: 390px) {

  left: unset;
  width: 250px;
    transform: unset;
    }
@media (min-width: 540px) {
  position: unset;
  top: unset;
  margin-bottom: 24px;
  left: unset;
  width: unset;
    transform: unset;
    }
@media (min-width: 760px) {
  text-align: center;
    }
@media (min-width: 1100px) {
  font-size: 24px;
  text-align: unset;
    }
`
const ProductContainer = styled.div`
border-radius: 24px;
background: #F1F3F8;
padding-top: 10px;
display: flex;
padding-right: 10px;
// padding: 10px 20px 0 20px;
margin-bottom: 28px;
align-items: center;
justify-content: space-between;
width: 85%;
flex-direction: column;
@media (min-width: 390px) {
  width: 100%;
  flex-direction: row;
    // padding: 10px 20px 0 20px;
  }
  @media (min-width: 428px) {
    padding-top: unset;
    }
@media (min-width: 540px) {
 width: 32%;
 flex-direction: column;
 padding: 10px 10px 0px;
 background: #FFFF;
}
@media (min-width: 1100px) {
display: none;
 }
}
`

const ProductContainerLarger = styled.div`
border-radius: 24px;
background: #FFF;
display: none;
 padding: 10px 20px 0 20px;
margin-bottom: 28px;
align-items: center;
justify-content: space-between;
width: 85%;
flex-direction: column;
@media (min-width: 1100px) {
  display: flex;
  flex-direction: row;

height: 126px;
   }
`
const ProductInfo = styled.div`
padding: 5px;
@media (min-width: 1100px) {
  // display: none;
//   width: 100%;
//  display: flex;
//  background: red;
//  flex-direction: row;
 }
`

const Duration = styled.div`
margin-right: 5px;
display: flex;

flex-direction: column;
@media (min-width: 390px) {

  flex-direction: row;

  }
@media (min-width: 428px) {
  font-size: unset;
  }
@media (min-width: 540px) {
 flex-direction: column;
 }
 @media (min-width: 1100px) {
  flex-direction: row;
  }
`
const Price = styled.p`
font-size: 16px;
font-weight: 700;
margin: 0;
text-align: center;
@media (min-width: 390px) {
  font-size: 20px;
  text-align: unset;
  }
@media (min-width: 428px) {
  margin: 10px 0;
  }
@media (min-width: 540px) {
  text-align: center;
  }
@media (min-width: 1100px) {
  font-size: 28px;
  }
`
const Points = styled.div`
display: flex;
color: #000000;
`
const BlackWord = styled.span`
@media (min-width: 540px) {
  color: #000000;
  }

`
const Month = styled.p`
margin-right: 5px;
font-size: 16px;
font-weight: 700;
text-align: center;
@media (min-width: 390px) {
  text-align: unset;
  font-size: 20px;
  }
@media (min-width: 428px) {
  // font-size: 14px;
  }
@media (min-width: 540px) {
  text-align: center;
  font-size: 16px;
  }
`
const Package = styled.p`
display: none;
font-size: 20px;
font-weight: 700;
text-align: center;
@media (min-width: 390px) {
  text-align: unset;
  }
@media (min-width: 428px) {
  display: unset;
  }
@media (min-width: 540px) {
  text-align: center;
  font-size: 16px;
  }
`
const Button = styled.button.attrs((props) => ({
  disabled: props.disabled,
}))`
  border-radius: 48px;
  background: ${(props) => (props.disabled ? "#999999" : "#056ec4")};
  width: 132px;
  height: 28px;
  color: ${(props) => (props.disabled ? "#666666" : "#f0f0f0")};
  margin-bottom: 15px;
  &:hover {
    background: ${(props) => (props.disabled ? "#999999" : "#ebf1ff")};
    color: ${(props) => (props.disabled ? "#666666" : "#056ec4")};
  }
`;
const SmallContainer = styled.div`
display: flex;
align-items: center;
text-align: center;
 padding-left: 0;
 justify-content: center;
 @media (min-width: 390px) {
  text-align: unset;
  padding-left: 10px;
  justify-content: unset;
  }
@media (min-width: 540px) {
  text-align: center;
  padding-left: 0;
  flex-direction: column;
  }
`

export default PurchaseCourse
