import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation($input: LoginInput) {
    auth(input: $input) {
      accessToken
      user {
        id
        firstName
        lastName
        email
        role
        # isFirstLogin
        # requestPasswordChange
      }
    }
  }
`
