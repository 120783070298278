import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import CreatableSelect from 'react-select/creatable'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import HelpIcon from '@material-ui/icons/Help'
import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Cancel'

import { CHECK_COMPANY_EMAIL } from 'gql/companies.query'
import { GET_ALL_INDUSTRY } from 'gql/skilss.query'
import { CompaniesWizardPage1Schema } from 'helpers/validationSchemas'
import Checkbox from 'components/common/Checkbox'
import { FormFields, FormField, } from 'components/common/Form/Form'
import TextInput from 'components/common/TextInput/TextInput'
import { SelectField } from 'components/common/SelectField'
import { Button } from 'components/common/Button'
import countries from 'countries'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { Container, Wrapper } from '../styled-components'
import { useUploadImage } from 'hooks/helpers/useHelpersService'
import useAddCompanyService from 'hooks/companies/useAddCompanyService'
import useCompaniesService from 'hooks/useCompaniesService'
import { mainColor } from 'components/common/Colors/CommonColors'
import { SSwitch } from 'pages/companies/CompanyDetails/CompanyServiceToggle'
import Icon from 'components/Icon'
import CIcon from 'components/Icon'

import { Tooltip, styled as mstyled, useTheme } from '@material-ui/core'

interface Props {
  changeStep: any
  companyData: any
  setData: any
  onCancel: any
  t: any
}

interface IformData {
  name: string
  companyEmail: string
  phone: string
  city: string
  companyActivity: any
  numberOfUsers: string
  country: any
  postalCode: string
  address: string
  platformService: boolean
  keyContactName: string
  note: string
  avatar: string
  phoneFields: any
  phoneFieldValue: any
}

const formData: IformData = {
  name: '',
  companyEmail: '',
  phone: '',
  city: '',
  companyActivity: null,
  numberOfUsers: '',
  country: null,
  postalCode: '',
  address: '',
  platformService: true,
  keyContactName: '',
  note: '',
  avatar: '',
  phoneFields: null,
  phoneFieldValue: null,
}

const CompanyDetails = ({
  changeStep,
  companyData,
  setData,
  onCancel,
  t,
}: Props) => {
  const [cropperOpen, setCropperOpen] = useState(false)
  const [avatar, setAvatar] = useState(null)
  const history = useHistory()
  const [save, setSave] = useState('saveAsDraft')

  const { data } = useQuery(GET_ALL_INDUSTRY)
  const { uploadImage, imageLoading } = useUploadImage()
  const { addCompany, loading } = useAddCompanyService({ filter: {} })
  const [checkEmail] = useMutation(CHECK_COMPANY_EMAIL)
  const { updateCompany, setCompanyState } = useCompaniesService()



  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: formData,
    validationSchema: CompaniesWizardPage1Schema,
    async onSubmit(values) {
      try {
        const data: any = { ...values }

        delete data.phoneFieldValue

        if (data.companyActivity) {
          data.companyActivity = [
            {
              label: data.companyActivity.label,
            },
          ]
        }

        if (data.numberOfUsers)
          data.numberOfUsers = data.numberOfUsers.toString()

        if (data.country) data.country = data.country.value

        if (data.phoneFields && data.phoneFields.value) {
          data.phoneFields = {
            dialCode: data.phoneFields.value,
            code: data.phoneFields.label,
          }
        } else {
          data.phoneFields = null
        }

        if (companyData.companyData && companyData.companyData.id) {
          delete data.managers
          updateCompany(companyData.companyData.id, data, (data: any) => {
            const { managers, ...args } = data

            setData({
              ...companyData,
              companyData: args,
              managers,
            })
            if (save === 'next') {
              setCompanyState(args.id, 1)
              changeStep(1)
            } else {
              setCompanyState(args.id, 0, 'Success', () =>
                history.push('/Companies'),
              )
            }
          })

          return
        }

        const res = await checkEmail({
          variables: { email: values.companyEmail },
        })

        if (res.errors) {
          errors.companyEmail = res.errors[0].message
          return false
        }

        // const managers = companyData.managers.map((i: any) => {
        //   const { id, __typename, ...args } = i
        //   return args
        // })

        delete data.managers

        addCompany(data, [], (data: any) => {
          const { managers, ...args } = data
          setData({
            ...companyData,
            companyData: args,
            managers,
          })
          setCompanyState(args.id, 0)
          if (save === 'next') changeStep(1)
          else history.push('/Companies')
        })
      } catch (err) {
        console.log('Error from checkEmail request', err)
      }
    },
  })

  useEffect(() => {
    if (companyData.companyData) {
      const { companyData: company } = companyData
      const data: any = {}
      const { country, companyActivity, ...args } = formData

      for (const key in args) {
        data[key] = company[key]
      }

      data.companyActivity =
        company.companyActivity && company.companyActivity.length > 0
          ? {
            value: company.companyActivity[0].label,
            label: company.companyActivity[0].label,
          }
          : null

      data.country = company.country
        ? {
          value: company.country,
          label: company.country,
        }
        : null

      setValues(data)
    }
  }, [companyData])

  const handleFileChange = (e: any) => {
    const { files } = e.target
    const reader: any = new FileReader()
    reader.onload = () => {
      const img: any = new Image()
      img.src = reader.result
      setAvatar(reader.result)
    }
    reader.readAsDataURL(files[0])

    setCropperOpen(true)
    e.target.value = ''
  }

  const handleUploadAvatar = (field: string, croppedFile: string) => {
    uploadImage(croppedFile, `company/${field}`, (link: string) => {
      setFieldValue(field, link)
      setAvatar(null)
    })
  }

  const onSubmit = (state: string) => {
    setSave(state)
    handleSubmit()
  }

  const activityList =
    (data &&
      data.getAllIndustry.map((i: any) => ({ label: i.label, value: i.id }))) ||
    []

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  const deleteImage = (field: string) => {
    setFieldValue(field, '')
    setAvatar(null)
  }

  const theme = useTheme();

  return (
    <>
      <Container theme={theme}>
        <Wrapper width="70%" theme={theme}>
          <ContentWrapper>
            <FormFields>
              <FormField>
                <AvatarContainer>
                  <div style={{ position: 'relative' }}>
                    {values.avatar && (
                      <DeleteIconContainer
                        onClick={() => deleteImage('avatar')}
                      >
                        <CancelIcon
                          style={{ fontSize: 18, color: '#989898' }}
                        />
                      </DeleteIconContainer>
                    )}
                    {
                      <img
                        src={values.avatar || '/svg/companyAvatar.svg'}
                        alt="Company Image"
                      />
                    }
                    <label htmlFor="avatar">
                      <EditIconContainer>
                        <Icon filename={'edit'} />
                      </EditIconContainer>
                    </label>
                  </div>
                  <div className="input-button">
                    <input
                      type="file"
                      onChange={handleFileChange}
                      accept="image/*"
                      id="avatar"
                    />
                  </div>
                </AvatarContainer>
              </FormField>
              <FormField width="100%">
                <TextInput
                  textColor={(theme: any) => theme.palette.text.secondary}
                  error={errors.name ? true : false}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  label={`${t('general.company')} ${t('form_fields.title')}*`}
                  name="name"
                  size="medium"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                // onBlur={handleBlur}
                />
              </FormField>

              <FormField width="100%">
                <TextInput
                  textColor={(theme: any) => theme.palette.text.secondary}
                  error={errors.keyContactName ? true : false}
                  errorMessage={
                    errors.keyContactName ? errors.keyContactName : ''
                  }
                  touched={touched.keyContactName}
                  label={`${t('company_details.key_contact_name')}*`}
                  name="keyContactName"
                  size="medium"
                  type="text"
                  value={values.keyContactName}
                  onChange={handleChange}
                // onBlur={handleBlur}
                />
              </FormField>

              <FormField width="48%">
                {/* <TextInput
                  textColor={(theme: any) => theme.palette.text.secondary}
                  error={errors.phone ? true : false}
                  errorMessage={errors.phone ? errors.phone : ''}
                  touched={touched.phone}
                  label={`${t('company_details.key_contact')} ${t(
                    'form_fields.phone',
                  )}*`}
                  name="phone"
                  size="medium"
                  type="text"
                  value={values.phone}
                  onChange={handleChange}
                // onBlur={handleBlur}
                /> */}
                <PhoneCountryCode
                  label={t('user_details.phone')}
                  selectLabel={t('form_fields.dial_code')}
                  selectValue={null}
                  selectChange={(e: any) => setFieldValue('phoneFields', e)}
                  inputLabel={t('user_details.phone')}
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={errors.phone || errors.phoneFields ? true : false}
                  errorMessage={
                    errors.phone || errors.phoneFields
                      ? errors.phone || errors.phoneFields
                      : ''
                  }

                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>

              <FormField width="48%">
                <TextInput
                  textColor={(theme: any) => theme.palette.text.secondary}
                  error={errors.companyEmail ? true : false}
                  errorMessage={errors.companyEmail ? errors.companyEmail : ''}
                  touched={touched.companyEmail}
                  label={`${t('company_details.key_contact_email')}*`}
                  name="companyEmail"
                  size="medium"
                  type="text"
                  value={values.companyEmail}
                  onChange={handleChange}
                // onBlur={handleBlur}
                />
              </FormField>

              <FormField width="48%" style={{ position: 'relative' }}>
                {values.companyActivity && <Label theme={theme}>Industry</Label>}
                <SelectField
                  onChange={(e: any) => setFieldValue('companyActivity', e)}
                  options={activityList}
                  value={values.companyActivity}
                  placeholder={t('company_details.industry')}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  textColor={(theme: any) => theme.palette.text.secondary}
                  // error={errors.numberOfUsers ? true : false}
                  // errorMessage={errors.numberOfUsers ? errors.numberOfUsers : ''}
                  // touched={touched.numberOfUsers}
                  label={t('company_details.number_of_users')}
                  name="numberOfUsers"
                  size="medium"
                  type="number"
                  value={values.numberOfUsers}
                  onChange={handleChange}
                // onBlur={handleBlur}
                />
              </FormField>
              <FormField width="48%" style={{ position: 'relative' }}>
                {values.country && (
                  <Label theme={theme}>{t('company_details.country')}</Label>
                )}
                <SelectField

                  placeholder={t('company_details.country')}
                  options={countries}
                  value={values.country}
                  onChange={(e: any) => setFieldValue('country', e)}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  textColor={(theme: any) => theme.palette.text.secondary}
                  // error={errors.city ? true : false}
                  // errorMessage={errors.city ? errors.city : ''}
                  // touched={touched.city}
                  label={t('company_details.city')}
                  name="city"
                  size="medium"
                  type="text"
                  value={values.city}
                  onChange={handleChange}
                // onBlur={handleBlur}
                />
              </FormField>

              <FormField width="68%">
                <TextInput
                  textColor={(theme: any) => theme.palette.text.secondary}
                  // error={errors.address ? true : false}
                  // errorMessage={errors.address ? errors.address : ''}
                  // touched={touched.address}
                  label={t('company_details.address')}
                  name="address"
                  size="medium"
                  type="text"
                  fullWidth={true}
                  value={values.address}
                  onChange={handleChange}
                />
              </FormField>

              <FormField width="28%">
                <TextInput
                  textColor={(theme: any) => theme.palette.text.secondary}
                  label={t('company_details.postal_code')}
                  name="postalCode"
                  size="medium"
                  type="text"
                  value={values.postalCode}
                  onChange={handleChange}
                />
              </FormField>

              <FormField>
                <TextInput
                  textColor={(theme: any) => theme.palette.text.secondary}
                  // error={errors.note ? true : false}
                  // errorMessage={errors.note ? errors.note : ''}
                  // touched={touched.note}
                  label={t('form_fields.note')}
                  name="note"
                  size="medium"
                  type="text"
                  fullWidth
                  multiline
                  rows="3"
                  value={values.note}
                  onChange={handleChange}
                />
              </FormField>

              <FormField width="100%">
                <ItemWrapper theme={theme}>
                  {t('companies_layout.platform_service')}

                  <SSwitch
                    checked={values.platformService}
                    onChange={handleChange}
                    name="platformService"
                    style={{ color: mainColor }}
                  />
                  <SToolTip title={t('company_details.platform_service_helper')} placement="top">
                    <PageInformation theme={theme}>
                      <Icon filename='info' fill={theme.palette.text.primary} style={{ position: 'relative', zIndex: 2 }}></Icon>
                    </PageInformation>
                  </SToolTip>


                  {/* <Tooltip
                    title={
                      <span style={{ fontSize: '14px' }}>
                        {t('company_details.platform_service_helper')}
                      </span>
                    }
                    arrow
                  >
                    <HelpIcon color="primary" style={{ color: mainColor }} />
                  </Tooltip> */}
                  {/* <HelpButtonContainer>
                  </HelpButtonContainer> */}
                </ItemWrapper>
              </FormField>
            </FormFields>
          </ContentWrapper>
        </Wrapper>
      </Container>

      <FooterWrapper>
        <ButtonWrapper>
          <Button
            text={t('actions.save_as_draft')}
            type="medium"
            onClick={() => onSubmit('saveAsDraft')}
            background="#ffffff"
            textColor="#080A0A8A"
            outline="1px solid #080A0A14"
            isDisabled={loading}
          />

          <Button
            text={t('actions.save_and_next')}
            type="medium"
            color="secondary"
            onClick={() => onSubmit('next')}
            background={'#317BF4'}
            isDisabled={loading}
          />
        </ButtonWrapper>
      </FooterWrapper>

      <Modal
        isOpened={cropperOpen}
        onClose={() => setCropperOpen(!cropperOpen)}
      >
        <ImageCropper
          file={{ file: avatar }}
          hideCropper={() => setCropperOpen(!cropperOpen)}
          getCroppedFile={(_: any, file: any) =>
            handleUploadAvatar('avatar', file)
          }
        />
      </Modal>
    </>
  )
}

export default CompanyDetails

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;

  .MuiSwitch-root {
    height: auto !important;
  }
`

const SToolTip = mstyled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  ['& .MuiTooltip-tooltip']: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 3px 6px #0000001A',
    fontSize: 16,
    color: '#080A0AD6',
    maxWidth: '970px',
    padding: '30px 50px',
  },
}))

const PageInformation = styled.div`
  width: 33px;
  height: 33px;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid #ebebeb;
  color: ${({ theme }) => theme.palette.text.primary};
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Label = styled.label`
  position: absolute;
  z-index: 1;

  background: ${({ theme }) => theme.palette.background.default};

  font-size: 12px;
  top: -9px;
  left: 9px;

  color: ${({ theme }) => theme.palette.background.primary};
`

const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 0px 50px;
`

const AvatarContainer = styled.div`
  width: 100%;
  // outline: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 12px;
  .input-button {
    position: absolute;
    top: 10px;
    left: 9px;
    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      overflow: hidden;
    }
  }
  img {
    border: 1px solid #080a0a14;
    border-radius: 8px;
    width: 130px;
    height: 130px;
    margin: 14px;
  }
`

const EditIconContainer = styled.div`
  width: 34px;
  height: 34px;
  background: #ffffff;
  border-radius: 100%;
  border: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5px;
  right: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 17px;
    height: 16px;
    fill: #080a0ad6;
  }
`

const DeleteIconContainer = styled.div`
  width: 28px;
  height: 28px;
  background: #f0f0f0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`

const FooterWrapper = styled.div`
  width: 100%;
  margin: auto;
  padding: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    border-radius: 8px !important;
  }
`
const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
`
function muiStyled(arg0: ({ className, ...props }: { [x: string]: any; className: any }) => JSX.Element) {
  throw new Error('Function not implemented.')
}

