import { mainColor } from 'components/common/Colors/CommonColors'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
  Tooltip,
} from 'recharts'

interface IChartData {
  moduleName: string
  I: number
  F: number
  fullMark: number
}

type testType = {
  totalCorrectAnswers: number
  totalQuestions: number
  data: {
    id: string
    name: string
    correctAnswersPercent: number
    correctAnswersCount: number
    totalQuestions: number
  }[]
}

type testResultType = {
  initial: testType
  final: testType | null
}

const FinalAndInitialResultsInChart: React.FC<{
  testResult: testResultType
}> = ({ testResult }) => {
  const { t } = useTranslation()
  const [chartData, setChartData] = useState<IChartData[]>([])
  useEffect(() => {
    const dataforchart: IChartData[] = []
    testResult.initial.data.map(result => {
      dataforchart.push({
        moduleName: result.name,
        I: result.correctAnswersPercent,
        F: 0,
        fullMark: 100,
      })
    })

    if (testResult?.final && testResult.final !== null) {
      testResult.final.data.map((result, index) => {
        dataforchart[index].F = result.correctAnswersPercent
      })
    }

    setChartData(dataforchart)
  }, [testResult])

  const renderFinalTestResultsRadar = (name: string) => {
    if (testResult?.final && testResult?.final !== null) {
      return (
        <Radar
          name={name}
          dataKey="F"
          stroke="#FFA502"
          fill="#FFA502"
          fillOpacity={0.3}
        />
      )
    }
  }

  return (
    <RadarChart outerRadius={165} width={1200} height={450} data={chartData}>
      <PolarGrid />
      <PolarAngleAxis dataKey="moduleName" />

      <PolarRadiusAxis
        angle={90}
        domain={[0, 100]}
        tickCount={11}
        orientation="middle"
      />
      <Legend iconSize={28} verticalAlign="top" />
      {renderFinalTestResultsRadar(t('iq_quizz.final_result'))}
      <Radar
        name={t('iq_quizz.initial_result')}
        dataKey="I"
        stroke={mainColor}
        fill={mainColor}
        fillOpacity={0.3}
      />
      <Tooltip
        cursor={{ stroke: 'red', stroeWidth: 1 }}
        formatter={value => `${value}%`}
      />
    </RadarChart>
  )
}

export default FinalAndInitialResultsInChart
