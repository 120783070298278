export default {
  CARD: 'data',
}

export interface DndItemProps {
  id: string
  index: number
  moveCard: (dragIndex: number, hoverIndex: number) => void
  cardIds?: string[]
  isNested?: boolean
  isTable?: boolean
  className?: string
  onEnd?: any
}

export interface DragItem {
  index: number
  hoverIndex: number
  id: string
  type: string
}
