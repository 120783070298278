import { Box, useTheme } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { Button } from 'components/common/Button'
import { mainColor } from 'components/common/Colors/CommonColors'
import { EditableAvatar } from 'components/common/EditableAvatar/EditableAvatar'
import Icon from 'components/Icon'
import { useUserValue } from 'context/UserContext'
import 'cropperjs/dist/cropper.css'
import { useUpdateProfileAvatar } from 'hooks/users/useEditProfileService'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { getRole } from 'utils/user-role'
import { UserCourses } from '../AllUserProfile/UserCourses'
import { ChangePassword } from './ChangePassword'
import EducationListDrawer from './drawers/EducationListDrawer'
import ExperienceListDrawer from './drawers/ExperienceListDrawer'
import EditAdminProfile from './EditProfile'
import { Rating } from '@material-ui/lab'
import EditProfileDescription from './EditProfileDescription'
import PersonalInformation, {
  IPersonalInformation,
} from './PersonalInformation'
import { Text } from 'pages/courses/StudentCourse/styled'
import strCutter from 'utils/strCutter'
import { generateFullName } from 'utils/utils'


const AdminProfile: React.FC<{}> = ({ }) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [isClickedOnEdit, setIsClickedOnEdit] = useState(false)
  const [isClickedOnEditDescription, setIsClickedOnEditDescription] = useState(
    false,
  )
  const [educationListOpen, setEducationListOpen] = useState(false)
  const [experienceListOpen, setExperienceListOpen] = useState(false)

  const { id: userId } = useParams<{ id: string }>()
  const [isClickedOnChangePassword, setIsClickedOnChangePassword] = useState(
    false,
  )

  const {
    firstName,
    lastName,
    email,
    avatar,
    id,
    role,
    gender,
    birthDate,
    location,
    jobTitle,
    companyId,
    phone,
    createDate,
    biography,
    note,
    status,
    age,
    phoneFields,
    isCompanyAdmin,
    isSuperAdmin,
    group,
  } = state.currentUser
  const [profileData, setProfileData] = useState<IPersonalInformation>({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    companyId: {
      id: '',
      name: '',
    },
    createDate: new Date(),
    birthDate: new Date(),
    gender: 'prefer not to say',
    location: '',
    phone: '',
    jobTitle: '',
    biography: '',
    note: '',
    status: '',
    id: '',
    age: 0,
    phoneFields: null,
    isCompanyAdmin: false,
    isSuperAdmin: false,
    phoneFieldValue: null,
    avatar: '',
    group: [],
  })

  useEffect(() => {
    const data = {
      firstName,
      lastName,
      email,
      role,
      companyId,
      createDate,
      birthDate,
      gender,
      location,
      phone,
      jobTitle,
      biography,
      note,
      status,
      id,
      age,
      phoneFields,
      isCompanyAdmin,
      isSuperAdmin,
      phoneFieldValue: phoneFields ? phoneFields.code : null,
      avatar,
      group: group ? group.map((g: any) => g.groupId) : [],
    }
    setProfileData(data)
  }, [
    firstName,
    lastName,
    email,
    role,
    companyId,
    createDate,
    birthDate,
    gender,
    location,
    phone,
    jobTitle,
    biography,
    note,
    status,
    id,
    age,
    phoneFields,
    isCompanyAdmin,
    isSuperAdmin,
    avatar,
    group,
  ])

  const iscompany = companyId && companyId.id && companyId.name

  const { updateAvatar, hasResponseReceived } = useUpdateProfileAvatar()

  const finishCrop = (
    action: any,
    crudFunction: (isFinished: boolean) => void,
    cropper: any,
  ) => {
    let cropdFile = null
    if (action === 'update') {
      cropdFile = cropper.current.getCroppedCanvas().toDataURL()
    }
    updateAvatar(cropdFile, userId, action, crudFunction)
  }

  const theme = useTheme()

  return (
    <div>
      <>
        <UserInfoWrapper>
          <EditableAvatar
            img={avatar}
            finishCrop={finishCrop}
            hasResponseReceived={hasResponseReceived}
          />
          <UserInfoWithButtons>
            <UserShortInfo>
              <UserNameText theme={theme}>
                {strCutter(generateFullName(profileData), 40)}
              </UserNameText>
              <UserRoleText theme={theme}>
                {getRole(profileData.role || 'N/A', iscompany)}
              </UserRoleText>
              {profileData?.role === 'COACH' && (
                <Box display={'flex'} alignItems={'center'}>
                  <Text fs={'28px'} weight={600} color={'#080A0AD6'} mr={'5px'}>
                    2.5
                  </Text>
                  <Rating defaultValue={2.5} precision={0.1} disabled={true} />
                  <Text fs={'16px'} color={'#080A0A8A'} ml={'20px'}>
                    212 {t('general.review_')}
                  </Text>
                </Box>
              )}
              {/* <Status>
                <Circle />
                {profileData.status &&
                  t(`general.${profileData.status.toLowerCase()}`)}
              </Status> */}
              <ChangePasswordContainer
                style={{ marginTop: '30px' }}
                onClick={() => {
                  setIsClickedOnChangePassword(true)
                }}
                theme={theme}
              >
                <Icon fill={theme.palette.text.primary} width={13} height={16} filename={'lock'} />
                {t('actions.change_password')}
              </ChangePasswordContainer>
            </UserShortInfo>
          </UserInfoWithButtons>
        </UserInfoWrapper>
        <PersonalInformation
          user={profileData}
          updateProfile={setIsClickedOnEdit}
          updateDescription={setIsClickedOnEditDescription}
          setEducationListOpen={setEducationListOpen}
          setExperienceListOpen={setExperienceListOpen}
          t={t}
        />
        {isClickedOnEdit && (
          <EditAdminProfile
            setIsClickedOnEdit={setIsClickedOnEdit}
            initialValues={profileData}
          />
        )}
        {isClickedOnEditDescription && (
          <EditProfileDescription
            setIsClickedOnEdit={setIsClickedOnEditDescription}
            initialValues={profileData}
          />
        )}
        {isClickedOnChangePassword && (
          <ChangePassword
            setIsClickedOnChangePassword={setIsClickedOnChangePassword}
          />
        )}
        {/* {profileData?.role &&
          profileData.role === 'STUDENT' &&
          profileData?.group && (
            <div style={{ margin: '50px 0px' }}>
              <UserCourses
                userId={profileData?.id}
                groups={profileData?.group}
                stat={true}
              />
            </div>
          )} */}

        {
          profileData?.role === 'STUDENT' &&
          profileData?.group && (<>
            <div style={{ margin: '50px 0px' }}>
              <UserCourses stat userId={profileData?.id} groups={profileData?.group} isDone={false} />
            </div>
            <div style={{ margin: '50px 0px' }}>
              <UserCourses stat userId={profileData?.id} groups={profileData?.group} isDone={true} />
            </div>
          </>
          )}

        {educationListOpen && (
          <EducationListDrawer
            open={educationListOpen}
            id={id}
            onClose={() => setEducationListOpen(false)}
          />
        )}
        {experienceListOpen && (
          <ExperienceListDrawer
            id={id}
            open={experienceListOpen}
            onClose={() => setExperienceListOpen(false)}
          />
        )}
      </>
    </div>
  )
}

export const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`
export const UserNameText = styled.h1`
  font-size: 32px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 600;
`
export const UserShortInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 30px;
`

export const UserRoleText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.primary};
`
export const UserInfoWithButtons = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(100% - 32px);
  width: 100%;
`

export const EditAndPasswordButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`
export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${mainColor};
  font-size: 16px;
  margin-top: 15px;
  font-weight: 500;
`
export const Circle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: ${mainColor};
  margin-right: 5px;
`

export const ChangePasswordContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  gap: 10px;
  cursor: pointer;
`

export default AdminProfile
