import { useState } from "react";
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { useMutation } from "@apollo/react-hooks";

import {
  ADD_SECTION_COURSES
} from "gql/sections/sections.query";

const useAddSectionCourses = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [updatedSection, setUpdatedSection] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const [addSectionCoursesMutation, { loading: addSectionCoursesLoading, error: addSectionCoursesError }] = useMutation(ADD_SECTION_COURSES);

  const addSectionCourses = async (
    { sectionId, coursesIncluded }: { sectionId: string, coursesIncluded: string[] }
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await addSectionCoursesMutation({
      variables: {
        id: sectionId,
        input: {
          courses: coursesIncluded,
        }
      },
    });

    if (errors) {
      message = errors[0].message
      variant = 'error'
      setError(message)
    } else {
      setUpdatedSection(data.addSectionCourses)
      message = t('messages.success')
      variant = 'success'
    }
    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    addSectionCourses,
    addSectionCoursesLoading: loading,
    updatedSection,
    error,
  }
};

export default useAddSectionCourses;
