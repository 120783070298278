import React, { useState, useEffect } from 'react'
import { useFetchCourses, PER_PAGE } from 'hooks/course/useCourses'
import { useQuery } from '@apollo/react-hooks'
import { GET_GROUP } from 'gql/group/group.query'
import { Button } from 'components/common/Button'
import styled from 'styled-components'
import {
  Container,
  Wrapper,
  FooterWrapper,
  ButtonWrapper,
} from '../styled-components'
import { generateFullName } from 'utils/utils'
import ReactSelect from 'react-select'
import { useHistory } from 'react-router-dom'
import useEditGroup from 'hooks/group/useEditGroup'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import useCompaniesService from 'hooks/useCompaniesService'
import { mainColor } from 'components/common/Colors/CommonColors'
import { FormField } from 'components/common/Form'
import { Label } from 'components/common/Form/Form'
import { SelectField } from 'components/common/SelectField'
import { useTheme } from '@material-ui/core'

const Expandable = (props: any) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <ExpandableContainer>
      <ExpandableHeader onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        <div style={{ marginLeft: '7px' }}>{props.title}</div>
      </ExpandableHeader>

      {isOpen && <ExpandableContent>{props.children}</ExpandableContent>}
    </ExpandableContainer>
  )
}

const ExpandableContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const ExpandableHeader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`
const ExpandableContent = styled.div``

const GroupItem = (props: any) => {
  const [firstFetch, setFirstFetch] = useState(true)
  const { item, coaches, courseId } = props

  const [coach, setCoach] = useState<any>(null)
  const course = item.courses.find((i: any) => i.courseId === courseId)
  useEffect(() => {
    if (course.coach) {
      setCoach({
        label: course.coach.name,
        value: course.coach.coachId,
        avatar: course.coach.avatar,
      })
    }
  }, [])

  useEffect(() => {
    if (!firstFetch) {
      const assign = coach
        ? {
          name: coach.label,
          coachId: coach.value,
          avatar: coach.avatar,
        }
        : null

      props.assignCoach(item.id, assign, courseId, () => { }, props.t)
    }
  }, [coach])

  return (
    <ItemFieldContainer>
      <div
        key={item.id}
        style={{
          border: '1px solid #ccc',
          padding: '8px 7px 0px',
          boxSizing: 'border-box',
          width: '260px',
        }}
      >
        {item.name}
      </div>

      <div style={{ width: '260px' }}>
        <ReactSelect
          placeholder={props.t('general.coach')}
          name="coach"
          options={coaches.map((c: any) => ({
            label: generateFullName(c),
            value: c.userId,
            avatar: c.avatar,
          }))}
          value={coach}
          onChange={(e: any) => {

            setCoach(e)
            setFirstFetch(false)
          }}
          isClearable
        />
      </div>
    </ItemFieldContainer>
  )
}

const AssignCoach = (props: any) => {
  const { updateCompany, setCompanyState } = useCompaniesService()
  const [currentPage] = useState<number>(1)
  const [perPage] = useState<number>(PER_PAGE)
  const history = useHistory()
  const { assignCoach } = useEditGroup()

  const { companyData } = props

  const query: any = {
    companyId: {
      type: 'exact',
      value: companyData.companyData.id,
    },
  }

  const { data } = useQuery(GET_GROUP, {
    variables: {
      filter: {
        company: {
          type: 'exact',
          value: companyData.companyData.id,
        },
      },
      perPage: 0,
    },
  })

  const { courses, loading, refetch, error, fetchMore } = useFetchCourses(
    query,
    currentPage,
    perPage,
  )

  const filterData = (arr: any, field: string) => {
    const data = arr.filter((i: any) => {
      return i.courses.find((n: any) => n.courseId === field)
    })
    return data
  }

  const courseList = (courses && courses.data) || []
  const groupList = (data && data.getAllGroups.data) || []
  const groups = groupList.map((i: any) => ({ value: i.id, label: i.name }))
  const coaches = courseList?.coaches?.map((c: any) => ({
    value: c.id,
    label: c.firstName,
  }))
  const { t } = props


  const theme = useTheme()

  return (
    <>
      <Container>
        <Wrapper theme={theme} width="70%" style={{ paddingBottom: '80px' }}>
          <ContentContainer>
            {courseList.map((course: any) => (
              <Card theme={theme}>
                <div className="title" style={{
                  color: theme.palette.text.primary,
                }}>{course.name}</div>

                <FormField
                  style={{ position: 'relative', marginBottom: '0px' }}
                  width="100%"
                >
                  <Label>{`${t('general.groups')}*`}</Label>

                  <SelectField
                    placeholder={`${t('general.groups')}*`}
                    name="group"
                    options={groups}
                    value={groups[0]}
                    // onChange={(e: any) => setFieldValue('group', e)}
                    isClearable={false}
                  />
                </FormField>

                <FormField style={{ position: 'relative' }} width="100%">
                  <Label>{`${t('general.coaches')}*`}</Label>

                  <SelectField
                    placeholder={`${t('general.coaches')}*`}
                    name="coaches"
                    options={course?.coaches?.map((coach: any) => ({
                      value: coach.id,
                      label: coach.firstName,
                    })
                    )}
                    value={course?.coaches?.map((coach: any) => ({
                      value: coach.id,
                      label: coach.firstName,
                    })
                    )}
                    // onChange={(e: any) => setFieldValue('group', e)}
                    isClearable={false}
                  />
                </FormField>
                {/* <Expandable
                  title={`${t('general.course')} -- ${course.name}`}
                  key={course.id}
                >
                  <>
                    <br />
                    <TitleContainer>
                      <Title>{t('general.groups')}</Title>
                      <Title>{t('general.coaches')}</Title>
                    </TitleContainer>
                    <br />
                    {filterData(groupList, course.id).length > 0 ? (
                      filterData(groupList, course.id).map((i: any) => (
                        <>
                          <GroupItem
                            item={i}
                            coaches={course.coaches}
                            courseId={course.id}
                            assignCoach={assignCoach}
                            t={t}
                          />
                          <br />
                        </>
                      ))
                    ) : (
                      <div>{t('general.no_data')}</div>
                    )}
                  </>
                </Expandable> */}
              </Card>
            ))}
          </ContentContainer>
        </Wrapper>
      </Container>

      <FooterWrapper>
        <ButtonWrapper>
          <Button
            text={t('actions.save_as_draft')}
            type="medium"
            color="secondary"
            onClick={() => {
              setCompanyState(companyData.companyData.id, 6, 'Success', () =>
                history.push('/Companies'),
              )
            }}
            background="#ffffff"
            textColor="#080A0A8A"
            outline="1px solid #080A0A14"
          />

          <Button
            text={t('actions.finish')}
            onClick={() => {
              setCompanyState(companyData.companyData.id, 7, 'Success', () =>
                history.push('/Companies'),
              )
            }}
            type="medium"
            color="secondary"
            background={'#317BF4'}
          />
        </ButtonWrapper>
      </FooterWrapper>
    </>
  )
}

export default AssignCoach

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  & > div:first-child {
    box-shadow: 0px 0px 30px #0000001a;
  }
`

const ItemFieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 1px solid #ccc; */
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const Title = styled.div``

const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
`

const Card = styled.div`
  width: 670px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.palette.background.paper};
  border: 1px solid #080a0a29;
  border-radius: 8px;
  padding: 30px;
  gap: 30px;

  .title {
    color: #080a0ad6;
    font-size: 20px;
    // font-weight: 600;
  }
`
