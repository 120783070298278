import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { useData } from 'context/DataContext'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'

import { useDeleteSectionCourses } from 'hooks/sections/useSections';
import useAddSectionService from 'hooks/sections/useAddSectionService'
import useAddSectionCourses from 'hooks/sections/useAddSectionCoursesService'
import useEditSection from 'hooks/sections/useEditSectionService'

import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import Drawer from 'components/common/Drawer'
import { useSwal } from 'hooks/useSwal'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'

import Loader from 'components/common/Loader'
import { DrawerEventEmitter } from 'helpers/drawer'
import { ModuleSchema, SectionSchema } from 'helpers/validationSchemas'

import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'

import styled from 'styled-components'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

import OrganizeSectionDrawer from '../OrganizeSectionDrawer/OrganizeSectionDrawer'

import {
  ActionButton,
  GridLayoutActions,
  GridLayoutForm,
  GridToolbar,
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'

import { useHistory } from 'react-router'


import _ from 'lodash'
import SyncIcon from '@material-ui/icons/Sync'
import Search from 'components/common/Search'
import TablePagination from 'components/common/Pagination/TablePagination'
import Grid from 'components/common/Grid'

import { columnConfigForSectionCourses, actionConfigForSectionCourses } from '../gridConfig';

enum SectionPopulatingActions {
  CREATE = 'createSection',
  EDIT = 'editSection'
}

interface SelectedCourse {
  id: string
  name: string
  savedInDb?: boolean
  avatar?: {
    link: string
    __typename?: string
  }
  __typename?: string;
}

interface ISectionInfo {
  courses: SelectedCourse[]
  name: string
  // forStartup: boolean
  id: string
}

interface Props {
  // title: string
  // data: ISectionInfo
  // drawerName?: string
  // closeDrawer: () => void
  title: string;
  data: {
    sectionInfo: ISectionInfo | null;
    type: string;
    onSuccess: () => void
  }
  drawerName?: string
  closeDrawer: () => void
}

interface formDataInterface {
  name: string
  // forStartup: boolean
}

const PER_PAGE = 10;

const PopulateSectionDrawer = ({ title, data, drawerName, closeDrawer }: Props) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [dataState, dispatchData] = useData()
  const theme = useTheme()
  const history = useHistory()
  const { fireSwal } = useSwal()

  // const courseId: any = data.courseId || ''

  const [filterData, setFilterData] = useState<any>({})
  const [searchText, setSearchText] = useState<string>('')
  const [filterIsOpen, setFilterIsOpen] = useState(false)

  // const [startup, setStartup] = useState(data?.sectionInfo?.forStartup || false)

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE);

  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})

  const [organizeSectionDrawerIsOpen, setOrganizeSectionDrawerIsOpen] = useState(false);

  const [selectedCourses, setSelectedCourses] = useState<SelectedCourse[]>(data?.sectionInfo?.courses || []);
  const [isChecked, setIsChecked] = useState(false);

  // const handleCheckboxChange = () => {
  //   setStartup(!startup);
  // };


  const formData: formDataInterface = {
    name: data?.sectionInfo?.name || "",
    // forStartup: data?.sectionInfo?.forStartup || false
  }

  const skip: number = (currentPage - 1) * perPage;
  const lastRenderedCourseIndex: number = ((currentPage - 1) * perPage) + perPage;

  const gridData: SelectedCourse[] = selectedCourses.slice(skip, lastRenderedCourseIndex)

  const { addSection, newSection, addSectionLoading } = useAddSectionService();
  const {
    deleteSectionCourses,
    deletedSectionCourses,
    deleteSectionCoursesLoading,
    setDeletedSectionCourses,
    setSnackbar
  } = useDeleteSectionCourses();


  const { addSectionCourses, addSectionCoursesLoading, updatedSection } = useAddSectionCourses();
  const { editSection, editSectionLoading, editedSection } = useEditSection();

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isValid,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: SectionSchema,

    onSubmit(values) {

      if (addSectionLoading || deleteSectionCoursesLoading || addSectionCoursesLoading || editSectionLoading) return;

      const sectionCoursesIds = selectedCourses.map(c => c.id);
      // courseData.courses =
      //   courseData.courses.map((course: any) => course.value) || []

      // if (state.selectedCompanyId)
      //   courseData.companyId = state.selectedCompanyId

      // if (!state.selectedCompanyId && data.manualCompanyId) {
      //   courseData.companyId = data.manualCompanyId
      // }

      // const { manualCompanyId, ...dataParams } = data
      const variables: any = {
        coursesIncluded: sectionCoursesIds
      }

      if (data?.type === SectionPopulatingActions.CREATE) {
        variables.sectionName = values.name;
        // variables.forStartup = startup
        addSection(variables);
      } else if (data?.type === SectionPopulatingActions.EDIT) {
        if (data?.sectionInfo?.id && data?.sectionInfo?.name) {
          if (data.sectionInfo.name === values.name
            // && data.sectionInfo.forStartup === startup
          ) {

            variables.sectionId = data.sectionInfo.id
            // variables.forStartup = startup
            addSectionCourses(variables)
          } else {
            // variables.forStartup = startup
            const editedSectionName = values.name.trim();
            if (!editedSectionName) {
              return;
            } else {

              variables.sectionName = editedSectionName;
              // variables.forStartup = startup
              variables.sectionId = data.sectionInfo.id;
              editSection(variables);
            }
          }
        }
      }
    },
  })

  // const closeDrawer = () => {
  //   DrawerEventEmitter.emit('openDrawer', { drawerName }, false)
  // }

  // const handleCloseDrawer = () => {
  //   dispatchData({
  //     type: SET_FORMDATA,
  //     payload: {
  //       type: 'add',
  //       drawer: 'addModule',
  //       values,
  //       compareTo: {},
  //     },
  //   })
  // }

  // useEffect(() => {
  //   if (dataState.formData.closeDrawerClick) {
  //     handleCloseDrawer()
  //   }
  // }, [dataState.formData.closeDrawerClick])




  useEffect(() => {
    if (newSection || updatedSection || editedSection) {
      closeDrawer()
      dispatchData({ type: CLEAR_FORMDATA })
      data.onSuccess()
    }
  }, [newSection, updatedSection, editedSection])

  // useEffect(() => {
  //   if (state.formData.closeDrawerClick) {
  //     handleCloseDrawer()
  //   }
  // }, [state.formData.closeDrawerClick])



  // Uncomment and modify! Save button dissability !!

  // const disabledButton = !!(
  //   (progress > 0 && progress < 100) ||
  //   imageLoading ||
  //   addModuleLoading
  // )


  const resetFilter = () => {
  }

  const filterDrawer = () => {
    setFilterIsOpen(!filterIsOpen)
  }

  const onSearchSubmit = (e: React.FormEvent) => {
  };


  const handleUpdateSelectedCourses = (markedCoursesIds: string[]) => {
    const successMessage = t('messages.success')
    const successVariant = 'success';

    setSelectedCourses((previousSelectedCourses) => {
      return previousSelectedCourses.filter(item => !markedCoursesIds.includes(item.id))
    })
    setSelectedItem({})
    setSelectAll({})
    setSnackbar({ message: successMessage, variant: successVariant });
  };

  const onDeleteConfirm = (markedCoursesIds: string[]) => {
    if (data?.sectionInfo) {
      const savedCoursesIds = gridData
        .filter((i: SelectedCourse) => markedCoursesIds?.includes(i.id) && i.savedInDb)
        .map((i: SelectedCourse) => i.id);

      if (savedCoursesIds.length > 0) {
        deleteSectionCourses(data.sectionInfo?.id, savedCoursesIds)
      } else {
        handleUpdateSelectedCourses(markedCoursesIds);
      }
    } else {
      handleUpdateSelectedCourses(markedCoursesIds);
    }
  };

  const deleteSingleCourseFromDotsHandler = (courseToBeDeletedId: string) => {
    if (data?.sectionInfo) {
      const savedCourse = gridData.find(item => courseToBeDeletedId === item.id && item.savedInDb);
      if (savedCourse) {
        deleteSectionCourses(data.sectionInfo?.id, [courseToBeDeletedId], true)
      } else {
        handleUpdateSelectedCourses([courseToBeDeletedId])
      }
    } else {
      handleUpdateSelectedCourses([courseToBeDeletedId])
    }
  };


  const handleDeleteClick = (field: any, event?: any) => {
    // console.log('desired event on deleteclick', event)
    // console.log('desired field on deleteclick', field)

    if (event) {
      fireSwal({
        title:
          selectedItem[currentPage].length === 1
            ? t('popups.delete_section_course_single')
            : t('popups.delete_section_courses_many'),
        confirmText: t('popups.confirm_delete'),
        onConfirm: () => {
          onDeleteConfirm(field);
        },
      })
      return
    }

    fireSwal({
      title: t('popups.delete_section_course_single'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => {
        deleteSingleCourseFromDotsHandler(field.id)
      },
    })
  }


  useEffect(() => {
    if (deletedSectionCourses) {
      if (deletedSectionCourses?.deletingFromDots && deletedSectionCourses?.deletedCourseId) {
        handleUpdateSelectedCourses([deletedSectionCourses.deletedCourseId])
      } else {
        handleUpdateSelectedCourses(selectedItem[currentPage]);
      }
    }
  }, [deletedSectionCourses])


  const handlePaginationClick = (event: any, value: number) => {
    if (value === 0) {
      setCurrentPage(1)
    } else if (value === currentPage) {
      setCurrentPage((cp) => cp + 1)
    } else if (value < currentPage) {
      setCurrentPage(cp => cp - 1);
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setPerPage(parseInt(event.target.value, 10))
    setCurrentPage(1)
  }


  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      // const newArr: string[] = modules.data.map((n: any) => n.id)
      const newArr: string[] = selectedCourses.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  };


  const actionHandler = (event: string, field: any, action: string) => {
    switch (event) {
      case 'view':
        // history.push(`/courses/${field}`)
        window.open(`/courses/${field}`, '_blank');
        break
      case 'delete':
        handleDeleteClick(field)
        break
      default:
        return () => console.log('')
    }
  };


  const handleClick = (courseId: string) => {
    // history.push(`/courses/${courseId}`)
    window.open(`/courses/${courseId}`, '_blank');
  }

  const selectItem = (id: string) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const isStudent = state.currentUser.role === 'STUDENT'

  // const isCompanySuperAdmin =
  //   state?.currentUser?.role === 'SUPER_ADMIN' &&
  //   !!state?.currentUser?.companyId?.id

  // const isCompanyAdmin =
  //   state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  const isSuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !state?.currentUser?.companyId?.id

  // const isAdmin =
  //   state?.currentUser?.role === 'ADMIN' && !state?.currentUser?.companyId?.id


  // const canModify =
  //   (((isCompanySuperAdmin || isCompanyAdmin) && modules?.data[0]?.companyId === state?.currentUser?.companyId?.id) || isSuperAdmin || isAdmin)
  //   && !isStudent

  const canModify = isSuperAdmin && !isStudent;

  const config = columnConfigForSectionCourses(handleClick, selectItem, t, theme);
  const actions = actionConfigForSectionCourses(
    actionHandler,
    state.userPermission,
    t,
    canModify,
  )

  const handleOrganizeSectionDrawerOpen = () => {
    let removedCoursesIds: string[] = [];
    for (const key in selectedItem) {
      removedCoursesIds = removedCoursesIds.concat(selectedItem[key].map((i: SelectedCourse) => i.id))
    }

    const desiredCourses = selectedCourses.filter((sc: SelectedCourse) => !removedCoursesIds.includes(sc.id));

    setSelectedCourses(desiredCourses)
    setSelectedItem({})
    setSelectAll({})
    setOrganizeSectionDrawerIsOpen(true)
  };

  const handleSetSelectedCourses = (courses: SelectedCourse[]) => setSelectedCourses(courses);

  return (
    <>
      {addSectionLoading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{title}</DrawerTitle>

        <CloseIcon
          // onClick={handleCloseDrawer}
          onClick={closeDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent headerHeight={500} margin={'10px'}>
        <FormContainer onSubmit={handleSubmit}>
          {/* <FormGroupWrapper> */}
          <div>
            <FormFields>
              {/* {data.name && (
                <FormField>
                  <TextInput
                    label={t('general.course')}
                    size="medium"
                    name="course"
                    type="text"
                    value={data.name}
                    disabled
                  />
                </FormField>
              )} */}
              <FormField>
                <TextInput
                  error={errors.name ? true : false}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  label={`${t('form_fields.name')}*`}
                  size="medium"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                />
                {/* <div style={{ marginTop: "20px" }}>
                  <input type="checkbox" id="forStartups" name="forStartups"
                    checked={startup}
                    onChange={handleCheckboxChange} />
                  <label >სტარტაპისთვის</label><br></br>


                </div> */}
              </FormField>


              {/* <FormField>
                <TextInput
                  error={errors.description ? true : false}
                  errorMessage={errors.description ? errors.description : ''}
                  touched={touched.description}
                  label={t('form_fields.module_overview')}
                  size="medium"
                  name="description"
                  type="text"
                  value={values.description}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows="4"
                  component={
                    <UploaderButton
                      onChange={handleExtractText}
                      label={t('general.upload')}
                      loading={fileLoading === 'extract' || extractTextLoading}
                    />
                  }
                />
              </FormField> */}
            </FormFields>
          </div>
          {/* </FormGroupWrapper> */}



          {/* <Container theme={theme}> */}


          <GridStyledToolbar theme={theme}>
            <GridLayoutActions>
              <div>
                {state.userPermission.createNewSection && (
                  <AddButton
                    onClick={handleOrganizeSectionDrawerOpen}
                  >
                    <Icon filename={'add'} fill={'#ffffff'} />
                    {t('course_drawer_layout.add_course')}

                  </AddButton>
                )}
              </div>
            </GridLayoutActions>
            <GridLayoutForm>
              <ActionButton
                disabled={_.isEmpty(filterData) && !searchText}
                onClick={resetFilter}
                theme={theme}
              >
                <SyncIcon fontSize="small" />
              </ActionButton>
              <MultipleAction theme={theme}>
                <ActionButton onClick={filterDrawer} theme={theme}>
                  <Icon filename={'filter'} fill={'#080A0AD6'} />
                </ActionButton>
                <MultipleActionTooltip>
                  {t('actions.filters')}
                </MultipleActionTooltip>
              </MultipleAction>

              {/* Uncomment and modify !! */}

              {/* <Search
                inputProps={{
                  value: searchText,
                  onChange: (e: any) => {
                    setSearchText(e.target.value)
                    setCurrentPage(1)
                  },
                }}
                onClick={onSearchSubmit}
              /> */}
            </GridLayoutForm>
          </GridStyledToolbar>

          {gridData.length > 0 && (
            <>
              <Grid
                title={t('courses_layout.title')}
                config={config}
                data={gridData}
                actionConfig={actions}
                selected={_.size(selectedItem[currentPage])}
                selectAllItem={selectAllItem}
                selectedItems={selectedItem[currentPage] || []}
                actions={() => [
                  {
                    id: 0,
                    color: 'secondary',
                    tooltipText: `${t('actions.delete')}`,
                    hide: !state.userPermission.createNewSection,
                    disabled: selectedItem[currentPage]
                      ? selectedItem[currentPage].length < 1
                      : true,
                    onClick: () =>
                      handleDeleteClick(selectedItem[currentPage], true),
                    component: (
                      <ActionButton
                        disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
                        fill={'#CC3131'}
                        disabledFill={'#080A0AD6'}
                        theme={theme}
                      >
                        <Icon filename={'delete'} />
                      </ActionButton>
                    ),
                  },
                ]}
              />
            </>
          )}

          {selectedCourses.length > 0 && (
            <TablePagination
              currentPage={currentPage}
              rowsPerPage={perPage}
              count={selectedCourses.length}
              handleChangePage={handlePaginationClick}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
          <Drawer opened={organizeSectionDrawerIsOpen} toggleDrawer={() => { }} totalWidth="48%">
            <OrganizeSectionDrawer
              title={t('sections_layout.organize_section')}
              drawerName='organizeSection'
              onOrganizeSectionDrawerClose={() => setOrganizeSectionDrawerIsOpen(false)}
              selectedCourses={selectedCourses}
              onUpdateSelectedCourses={handleSetSelectedCourses}
            />
          </Drawer>
          {/* </Container> */}
          <FormFooter>
            <FormButtons>
              {/* <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              /> */}
              <Button
                onClick={() => console.log("clicked")}
                text={t('actions.save')}
                type="medium"
                btnType="submit"

                // uncomment below:
                // isDisabled={data?.type === SectionPopulatingActions.EDIT}

                background={'#317BF4'}
                buttonStyles={{
                  borderRadius: '8px',
                }}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default PopulateSectionDrawer;

const CloseIcon = styled(Icon)`
              position: absolute;
              top: 30px;
              right: 50px;
              cursor: 'pointer';
              `

const Info = styled.div`
              position: absolute;
              bottom: -15px;
              left: 75px;
              width: 35px;
              height: 35px;
              background: #ffffff;
              border: 1px solid #ebebeb;
              border-radius: 100%;
              `

const Container = styled.div`
              overflow: hidden;
              background: ${({ theme }) => theme.palette.background.default};
              border: 1px solid ${({ theme }) => theme.palette.background.default};
              border-radius: 8px;
              height: 100%;
              `

const AddButton = styled.div`
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              background: #317bf4;
              padding: 18px 30px;
              color: #ffffff;
              font-size: 16px;
              font-weight: 600;
              border: none;
              border-radius: 8px;
              cursor: pointer;

              &:hover {
                transition: 0.3s;
              background: #3165f4;
  }
              `

const GridStyledToolbar = styled.div`
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 20px 20px;
              `
