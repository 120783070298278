import React from 'react'
import { gql } from 'graphql.macro'
import ReactSelect from 'react-select'
import { useQuery } from '@apollo/react-hooks'

import { filterQuestions } from './filterQuestions'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form'

import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import { useUserValue } from 'context/UserContext'
import { useFetchModules } from 'hooks/modules/useModules'
import { QuestionTypes, editedOptions } from 'pages/questions/staticData'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { useTheme } from '@material-ui/core'

const GET_TAGS = gql`
  query {
    getAllTags {
      id
      label
    }
  }
`

interface Params {
  courseId?: string | null
  closeDrawer: () => void
  handleFilterClick?: any
  filterData: any
  quizModel: 'general' | 'course' | 'module' | 'topic' | 'lesson'
  setFilterData: any
  currentModule: any
  currentPage: number
  refetch?: any
  setFiltered: () => void
  questions: any
  setFilteredQuestionList?: any
  setCurrentPage?: any
  setCards?: any
  t?: any
}

const FilterDrawer = ({
  courseId,
  closeDrawer,
  handleFilterClick,
  filterData,
  quizModel,
  setFilterData,
  currentPage,
  currentModule,
  refetch,
  setFiltered,
  questions,
  setFilteredQuestionList,
  setCurrentPage,
  t,
}: Params) => {
  const { data } = useQuery(GET_TAGS)
  const [state] = useUserValue()

  let filters: any = {
    companyId: {
      type: 'exact',
      value: state.selectedCompanyId,
    },
  }

  if (quizModel === 'course' && courseId) {
    filters.course = { type: 'nestedArrayIn', value: courseId }
  }

  const { modules }: any = useFetchModules(filters, 1, 0)

  const tagList =
    (data &&
      data.getAllTags.map((i: any) => ({ label: i.label, value: i.id }))) ||
    []

  const moduleList: any =
    (modules &&
      modules.data.map((i: any) => ({ label: i.name, value: i.id }))) ||
    []

  const handleChange = (field: string, e: any) => {
    if (e === null || !e.target) {
      setFilterData({
        ...filterData,
        [field]: e,
      })
    } else {
      setFilterData({
        ...filterData,
        [field]: e.target.value,
      })
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const filterOptions: any = {}
    if (filterData.type && filterData.type.value !== 'all') {
      filterOptions.type = {
        type: 'exact',
        value: filterData.type.value,
        nestedField: 'value',
      }
    }

    if (filterData.tags) {
      filterOptions.tags = {
        type: 'nestedArrayIn',
        value: filterData.tags.map((tag: any) => tag.label),
        nestedField: 'label',
      }
    }

    if (filterData.question) {
      filterOptions.question = {
        type: 'match',
        value: filterData.question,
      }
    }

    if (filterData.modules) {
      filterOptions.modules = {
        type: 'nestedArrayIn',
        value: filterData.modules.map((moduleItem: any) => moduleItem.value),
        nestedField: 'moduleId',
      }
    }

    if (filterData.edited && filterData.edited.value !== 'all') {
      filterOptions.edited = {
        type: 'bool',
        value: filterData.edited.value,
      }
    }

    if (currentModule) {
      const filteredQuestions = filterQuestions(questions, filterOptions)
      setFilteredQuestionList(filteredQuestions)
    } else {
      handleFilterClick(filterOptions)
      // refetch({
      //   filter: {
      //     ...defaultFilters,
      //     ...filterOptions,
      //     question: { type: 'match', value: searchValue },
      //   },
      //   currentPage: 1,
      // })
    }

    closeDrawer()

    setCurrentPage && setCurrentPage(1)

    setFiltered()
  }
  const theme = useTheme()

  return (
    <>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>
          {t('questions_layout.filter_questions')} - {t('actions.to_filter')}{' '}
          {t('general.questions')}
        </DrawerTitle>
        <IconButton onClick={closeDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent margin={'100px'} >
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <label>{t('general.type')}</label>
                <ReactSelect
                  onChange={(e: any) => handleChange('type', e)}
                  options={QuestionTypes}
                  value={filterData.type}
                  placeholder={t('form_fields.select')}
                />
              </FormField>
              <FormField>
                <label>{t('general.tags')}</label>
                <ReactSelect
                  onChange={(e: any) => handleChange('tags', e)}
                  options={tagList}
                  value={filterData.tags}
                  isMulti
                  placeholder={t('form_fields.select')}
                />
              </FormField>
              <FormField>
                <TextInput
                  label={t('general.question')}
                  size="small"
                  name="question"
                  type="text"
                  value={filterData.question}
                  onChange={(e: any) => handleChange('question', e)}
                  multiline
                  rows="2"
                />
              </FormField>
              {(quizModel === 'general' || quizModel === 'course') && (
                <FormField>
                  <label>{t('general.modules')}</label>
                  <ReactSelect
                    onChange={(e: any) => handleChange('modules', e)}
                    options={moduleList}
                    value={filterData.modules}
                    isMulti
                    placeholder={t('general.module')}
                  />
                </FormField>
              )}
              <FormField>
                <label>{t('general.edited')}</label>
                <ReactSelect
                  onChange={(e: any) => handleChange('edited', e)}
                  options={editedOptions}
                  placeholder={t('form_fields.select')}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={closeDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.filter')}
                type="medium"
                color="secondary"
                btnType="submit"
                background={mainColor}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterDrawer
