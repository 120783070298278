

import { useQuery } from '@apollo/react-hooks'
import { CloseIcon, DrawerContent, DrawerHeader, DrawerTitle } from 'components/DrawerLayout/styled-components'
import Loader from 'components/common/Loader/Loader'
import { GET_USER } from 'gql/users.query'
import { useTheme } from '@material-ui/styles'
import { useData } from 'context/DataContext'
import { useTranslation } from 'react-i18next'



import React from 'react'
import { DrawerEventEmitter } from 'helpers/drawer'
import { Info } from '@material-ui/icons'
import { FormFields, FormGroupWrapper, FormField } from 'components/common/Form'
import TextInput from 'components/common/TextInput/TextInput'
import { useFormik } from 'formik'

function EditUserSubmission(id: any, role: any) {
  const [dataState, dispatchData] = useData()
  const { data, loading } = useQuery(GET_USER, {
    variables: { id: id },
  })
  const { t } = useTranslation()
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender: '',
      birthDate: null as any,
      biography: '',
      group: null,
      note: '',
      jobTitle: '',
      location: '',
      phoneFields: null as any,
      phoneFieldValue: null as any,
      avatar: '',
    },

    onSubmit(values) {

    },
  })

  const theme = useTheme();

  const handleCloseDrawer = () => {
    DrawerEventEmitter.emit('openDrawer', 'editUserSubmission', false)
  }

  return (
    <>
      {(loading) && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>
          {/* {t('actions.edit')} {t('general.student')} */}
          გამოწერის ედიტი
        </DrawerTitle>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent>
        <FormGroupWrapper>
          <FormFields>
            <FormField width="48%">
              <TextInput
                error={errors.firstName ? true : false}
                errorMessage={errors.firstName ? errors.firstName : ''}
                touched={touched.firstName}
                label={`${t('user_details.last_name')}*`}
                size="medium"
                name="firstName"
                type="number"
                value={values.firstName}
                onChange={handleChange}
              />
            </FormField>
          </FormFields>
        </FormGroupWrapper>
      </DrawerContent>
    </>
  )
}

export default EditUserSubmission
