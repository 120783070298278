import i18n from 'i18n/index'

interface FilterOptions {
  firstName: string
  lastName: string
  status: any
  company: any
  age: number[]
  gender: string
  location: string
  note: string
  createDate: any
}

interface AdminsFilterOptions extends FilterOptions {
  group: any
}

interface CoachesFilterOptions extends FilterOptions {
  group: any
  course: any
}

interface StudentsFilterOptions extends FilterOptions {
  group: any
  course: any
}

export interface ValidationObject {
  label: string
  value: string
  error: boolean
}

export let genderOptions: any = []

export let genderOptionsForFilters: any = []

export let statuses: any = []
export let filterOptions: FilterOptions = {
  firstName: '',
  lastName: '',
  status: { value: 'all', label: 'All' },
  gender: '',
  age: [0, 0],
  createDate: [null, null],
  company: null,
  location: '',
  note: ''
}

export let adminsFilterOptions: AdminsFilterOptions = {
  ...filterOptions,
  group: null,
}

export let coachesFilterOptions: CoachesFilterOptions = {
  ...filterOptions,
  group: null,
  course: null,
}

export let studentsFilterOptions: StudentsFilterOptions = {
  ...filterOptions,
  group: null,
  course: null,
}

export let validations: ValidationObject[] = []

function translate() {
  genderOptions = [
    { value: 'male', label: i18n.t('user_details.male') },
    { value: 'female', label: i18n.t('user_details.female') },
    { value: 'other', label: i18n.t('user_details.not_say') },
  ]

  genderOptionsForFilters = [
    { value: 'male', label: i18n.t('user_details.male') },
    { value: 'female', label: i18n.t('user_details.female') },
    { value: 'prefer not to say', label: i18n.t('user_details.not_say') },
    { value: null, label: i18n.t('user_details.gender_is_not_set') },
  ]

  statuses = [
    { value: 'all', label: i18n.t('general.all') },
    { value: 'DRAFT', label: i18n.t('general.draft') },
    { value: 'PENDING', label: i18n.t('general.pending') },
    { value: 'ACTIVE', label: i18n.t('general.active') },
    { value: 'SUSPENDED', label: i18n.t('general.suspended') },
  ]

  filterOptions = {
    firstName: '',
    lastName: '',
    status: { value: 'all', label: i18n.t('general.all') },
    gender: '',
    age: [0, 0],
    createDate: [null, null],
    company: null,
    location: '',
    note: ''
  }

  adminsFilterOptions = { ...adminsFilterOptions, ...filterOptions }
  coachesFilterOptions = {
    ...coachesFilterOptions,
    ...filterOptions,
  }
  studentsFilterOptions = {
    ...studentsFilterOptions,
    ...filterOptions,
    gender: '',
  }

  validations = [
    {
      label: `${i18n.t('validations.use_at_least')} 8 ${i18n.t(
        'validations.character',
      )}`,
      value: 'length',
      error: true,
    },
    {
      label: `${i18n.t('validations.use_at_least')} 1 ${i18n.t(
        'validations.uppercase',
      )} ${i18n.t('validations.character')}`,
      value: 'uppercase',
      error: true,
    },
    {
      label: `${i18n.t('validations.use_at_least')} 1 ${i18n.t(
        'validations.lowercase',
      )} ${i18n.t('validations.character')}`,
      value: 'lowercase',
      error: true,
    },
    {
      label: `${i18n.t('validations.use_at_least')} 1 ${i18n.t(
        'validations.number',
      )}`,
      value: 'digit',
      error: true,
    },
    // {
    //   label: `${i18n.t('validations.use_at_least')} 1 ${i18n.t(
    //     'validations.special',
    //   )} ${i18n.t('validations.character')}(., @ # $ % ^ & *)`,
    //   value: 'special',
    //   error: true,
    // },
  ]
}

translate()

i18n.on('languageChanged init', () => {
  translate()
})
