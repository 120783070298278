import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { default as MUIPagination } from '@material-ui/lab/Pagination'

interface PaginationProps {
  currentPage: number
  totalPages: number
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
)

const Pagination = ({
  currentPage,
  totalPages,
  handleChange,
}: PaginationProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <MUIPagination
        count={totalPages}
        variant="outlined"
        page={currentPage}
        onChange={handleChange}
      />
    </div>
  )
}

export default Pagination
