import React from "react";
const VideosCommunity = () => {
  return (
    <div>
      <h1>Community videos page</h1>
    </div>
  );
};

export default VideosCommunity;
