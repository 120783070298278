import changeCompanyStatusService from 'services/companies/changeCompanyStatusService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useChangeCompanyStatusService = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const {
    changeCompanyStatusMutation,
    loading,
    error,
  } = changeCompanyStatusService()

  const changeCompanyStatus = async (ids: string[], action: string) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    const { data, errors } = await changeCompanyStatusMutation({
      variables: { ids, action },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.company')} ${t('general.status')} ${t(
        'messages.changed',
      )}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    changeCompanyStatus,
    changeCompanyStatusLoading: loading,
    error,
  }
}

export default useChangeCompanyStatusService
