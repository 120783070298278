import changeCoachQualificationService from 'services/users/changeCoachQualificationService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useChangeCoachQualification = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const {
    changeCoachQualificationMutation,
    loading,
  } = changeCoachQualificationService(params)

  const changeCoachQualification = async (
    ids: string[],
    isQualified: boolean,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    const { data, errors } = await changeCoachQualificationMutation({
      variables: {
        ids,
        isQualified,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      const updatedCoaches = data.changeCoachQualification
      if (updatedCoaches.length === 1) {
        const { isQualified } = updatedCoaches[0]
        message = isQualified
          ? `${t('general.coach')} ${t('messages.qualified')}`
          : `${t('general.coach')} ${t('unqialified')}`
      } else {
        const { isQualified } = updatedCoaches[0]
        message = isQualified
          ? `${t('general.coaches')} ${t('messages.qualified')}`
          : `${t('general.coaches')} ${t('unqialified')}`
      }
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    changeCoachQualification,
    user: '',
    changeCoachQualificationLoading: loading,
    error: '',
  }
}

export default useChangeCoachQualification
