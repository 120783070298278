import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import { useLazyQuery } from '@apollo/react-hooks'
import { GET_USERS } from 'gql/users.query'
import { CLEAR_FORMDATA } from 'store/types'
import { useSwal } from 'hooks/useSwal'

import { FormGroup, FormGroupItem, InputGroup } from 'components/common/Form'
import { Input } from 'components/common/TextInput'
import { useUserValue } from 'context/UserContext'
import { Button } from 'components/common/Button'
import { useData } from 'context/DataContext'
import { SET_CREATED_USER } from 'store/types'
import { AddAdmin } from 'pages/admins'
import Drawer from 'components/common/Drawer'
import { AsyncSelect } from 'components/common/SelectField'
import { generateFullName } from 'utils/utils'
import { mainColor } from 'components/common/Colors/CommonColors'

const Step1 = (props: any) => {
  const [state] = useUserValue()
  const ref: any = useRef()
  const [dataState, dispatch] = useData()
  const [formData, setFormData] = useState<any>('')
  const [drawer, setDrawer] = useState({
    addAdmin: false,
    args: {},
  })
  const { fireSwal } = useSwal()

  const variables = { role: 'ADMIN' }
  const [fetchAdmin, { data: adminData, loading: adminLoading }] = useLazyQuery(
    GET_USERS,
  )

  useEffect(() => {
    if (
      dataState.createdObject.obj &&
      dataState.createdObject.type === 'admin'
    ) {
      props.setFieldValue('admin', {
        value: dataState.createdObject.obj.id,
        label: `${dataState.createdObject.obj.firstName} ${dataState.createdObject.obj.lastName}`,
      })

      dispatch({
        type: SET_CREATED_USER,
        payload: { obj: null, type: '' },
      })
    }
  }, [dataState.createdObject.obj])

  const handleDrawerClose = () => {
    dispatch({
      type: 'SET_CLOSE_DRAWER',
      payload: true,
    })
  }

  useEffect(() => {
    if (formData) {
      const isEmpty = _.values(formData).every(_.isEmpty)
      if (isEmpty) {
        setDrawer({ addAdmin: false, args: {} })
        dispatch({
          type: CLEAR_FORMDATA,
          payload: true,
        })
      } else {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            setDrawer({ addAdmin: false, args: {} })
            dispatch({
              type: CLEAR_FORMDATA,
              payload: true,
            })
          },
          onClose: () => {
            setFormData('')
            dispatch({
              type: CLEAR_FORMDATA,
              payload: true,
            })
          },
          confirmText: t('popup.confirm_cancel'),
          cancelText: t('general.no'),
        })
      }
    }
  }, [formData])
  const loadDataOptions = (e: any) =>
    setTimeout(() => {
      const value = e
      fetchAdmin({
        variables: {
          ...variables,
          filter: {
            search: {
              type: 'search',
              value,
              fields: ['firstName', 'lastName'],
            },
            companyId: {
              type: 'exact',
              value: props.companyId,
            },
          },
        },
      })
    }, 200)

  const handleSelectChange = (e: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e)
    }
  }

  const { t } = props

  return (
    <FormGroup>
      <FormGroupItem>
        <InputGroup>
          <Input
            error={!!props.errors.name}
            errorMessage={props.errors.name ? props.errors.name : ''}
            touched={props.touched.name}
            label={t('form_fields.name')}
            name="name"
            size="medium"
            type="text"
            fullWidth
            value={props.values.name}
            onChange={props.handleChange}
          />

          <Input
            label={t('form_fields.location')}
            name="location"
            size="medium"
            type="text"
            fullWidth
            value={props.values.location}
            onChange={props.handleChange}
          />
        </InputGroup>
      </FormGroupItem>

      <FormGroupItem style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '100%' }}>
          <AsyncSelect
            onInputChange={handleSelectChange}
            onChange={(e: any) => props.setFieldValue('admin', e)}
            data={(adminData && adminData.usersByRole.data) || []}
            value={props.values.admin}
            label={t('form_fields.type_to_search_admin')}
            loading={adminLoading}
            labelDataName={generateFullName}
            valueDataKey="id"
            error={!!props.errors.admin}
            touched={props.touched.admin}
            errorMessage={props.errors.admin ? props.errors.admin : ''}
          />
        </div>
        <div style={{ paddingLeft: '15px' }}>
          <Button
            text={t('actions.create')}
            type="medium"
            onClick={() => {
              setDrawer({
                ...drawer,
                addAdmin: true,
                args: {
                  companyId: props.companyId,
                  onSuccess: () => {},
                },
              })
            }}
            background={mainColor}
          />
        </div>
      </FormGroupItem>

      <FormGroupItem>
        <Input
          label={t('form_fields.note')}
          name="note"
          size="medium"
          type="text"
          fullWidth
          multiline
          rows="4"
          value={props.values.note}
          onChange={props.handleChange}
        />
      </FormGroupItem>

      <Drawer
        opened={drawer.addAdmin}
        toggleDrawer={handleDrawerClose}
        totalWidth="950px"
      >
        <AddAdmin
          title={t('admin_drawer_layout.create_admin')}
          role="ADMIN"
          drawerName="addAdmin"
          drawerData={drawer.args}
          onClose={() => setDrawer({ addAdmin: false, args: {} })}
          setFormData={setFormData}
        />
      </Drawer>
    </FormGroup>
  )
}

export default Step1
