import React, { useState } from 'react'
import strCutter from 'utils/strCutter'
import config from 'config'
import {
  FilesContainer,
  ImageContainer,
  VideoContainer,
  MediaContainer,
  MediaHeader,
  MediaContent,
  MediaContentWrapper,
  MediaTitle,
} from './styled-components'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import VideoCallIcon from '@material-ui/icons/VideoCall'
import CircularProgress from '@material-ui/core/CircularProgress'

import Modal from 'components/common/Modal'
import Player from 'components/common/VideoPlayer/Player'
import ImageGallery from 'components/common/ImageGallery'
import Tooltip from '@material-ui/core/Tooltip'

interface VideoLinks {
  url: string
  type: string
  converted: boolean
}

interface Video {
  name: string
  thumbnail: string
  links: VideoLinks[]
  title: string
}

interface Audio {
  name: string
  thumbnail: string
  fileType: string
}

interface Document {
  name: string
  fileType: string
  size: string
  link: string
}

interface Image {
  name: string
  link: string
  fileType: String
}

interface Image {
  currentImage: string
  images: string[]
}

interface Params {
  images: Image[]
  documents: Document[]
  videos: Video[]
  audios: Audio[]
  t: any
}

const QuestionFiles = ({ images, documents, videos, audios, t }: Params) => {
  const [mediaModalOpened, setMediaModalOpened] = useState<boolean>(false)
  const [currentMedia, setCurrentMedia] = useState<any>(null)

  const handlePlayVideoClick = (video: Video) => {
    setMediaModalOpened(true)
    setCurrentMedia(video)
  }

  const handleOpenImageClick = (image: string) => {
    setMediaModalOpened(true)
    setCurrentMedia({
      currentImage: image,
      images,
    })
  }

  const renderImages = () => {
    return (
      <>
        {images.map((image: Image) => (
          <MediaContentWrapper>
            <ImageContainer>
              <ZoomInIcon onClick={() => handleOpenImageClick(image.link)} />
              <img src={image.link} alt="Question Image" />
            </ImageContainer>
            {image.name &&
              (image.name.length > 80 ? (
                <Tooltip title={image.name}>
                  <MediaTitle>{strCutter(image.name, 80)}</MediaTitle>
                </Tooltip>
              ) : (
                <MediaTitle>{image.name}</MediaTitle>
              ))}
          </MediaContentWrapper>
        ))}
      </>
    )
  }

  const renderVideos = () => {
    return (
      <>
        {videos.map((video: Video) => {
          const hasConverted = video.links[0].converted
          return (
            <MediaContentWrapper>
              <VideoContainer>
                {hasConverted ||
                (!hasConverted && config.ENV === 'development') ? (
                  <img src={video.thumbnail} alt={video.name} />
                ) : null}
                {hasConverted ||
                (!hasConverted && config.ENV === 'development') ? (
                  <PlayCircleOutlineIcon
                    onClick={() => handlePlayVideoClick(video)}
                  />
                ) : null}
                {!hasConverted && config.ENV === 'production' && (
                  <CircularProgress size={20} />
                )}
              </VideoContainer>
              {video.title && (
                <Tooltip title={video.title}>
                  <>{strCutter(video.title, 20)}</>
                </Tooltip>
              )}
            </MediaContentWrapper>
          )
        })}
      </>
    )
  }

  return (
    <FilesContainer>
      {images.length !== 0 && (
        <MediaContainer>
          <MediaHeader>
            <PermMediaIcon />
            {t('general.images')}
          </MediaHeader>
          <MediaContent>{renderImages()}</MediaContent>
        </MediaContainer>
      )}
      {videos.length !== 0 && (
        <MediaContainer>
          <MediaHeader>
            <VideoCallIcon />
            {t('general.videos')}
          </MediaHeader>
          <MediaContent>{renderVideos()}</MediaContent>
        </MediaContainer>
      )}
      <Modal
        isOpened={mediaModalOpened}
        onClose={() => setMediaModalOpened(false)}
      >
        <>
          {currentMedia && currentMedia.images ? (
            <ImageGallery
              images={currentMedia.images}
              currentImage={currentMedia.currentImage}
            />
          ) : (
            <Player url={currentMedia && currentMedia.links[0].url} />
          )}
        </>
      </Modal>
    </FilesContainer>
  )
}

export default QuestionFiles
