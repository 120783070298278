import { useTheme } from '@material-ui/core'
import Icon from 'components/Icon'
import React from 'react'

import ReactSelect from 'react-select'
import { DropdownIndicator } from 'react-select/src/components/indicators'
import styled from 'styled-components'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { validationRed } from '../Colors/CommonColors'

const CustomDropDownIndicator = (props: any) => {
  const theme = useTheme()
  return (
    <Icon
      filename="dropdownIndicator"
      fill={theme.palette.text.primary}
      style={{
        marginRight: '10px',
      }}
    >
      {props.children}
    </Icon>
  )
}

const SelectField = (props: any) => {
  const useStyles = makeStyles((theme: Theme) => createStyles({}))
  const theme = useTheme()

  return (
    <Container error={props.error && props.touched} theme={theme}>
      <ReactSelect
        classNamePrefix="react-select"
        isMulti={props.isMulti}
        onChange={props.onChange}
        options={props.options}
        value={props.value}
        placeholder={props.placeholder}
        styles={
          props.styles || {
            control: (provided: any, state: any) => ({
              ...provided,
              // color: theme.palette.text.primary,
              background: theme.palette.background.default,
            }),
            menu: (provided: any, state: any) => ({
              ...provided,
              zIndex: '99',
              background: theme.palette.background.default,
            }),
            singleValue: (provided: any, state: any) => ({
              ...provided,
              color: theme.palette.text.primary,
            }),
            option: (provided: any, state: any) => ({
              ...provided,
              background: theme.palette.background.default,
              color: theme.palette.text.primary,
            }),
            placeholder: () => ({
              color: theme.palette.text.primary,
              background: theme.palette.background.default,
            }),
          }
        }
        isClearable={props.isClearable}
        openMenuOnClick={props.openMenuOnClick}
        isSearchable={props.isSearchable}
        isDisabled={props.isDisabled || false}
        components={{
          DropdownIndicator: props.value ? null : CustomDropDownIndicator,
          ...props.components,
        }}
      />

      {props.touched && props.error && props.errorMessage && (
        <ErrorMessage>{props.errorMessage}</ErrorMessage>
      )}
    </Container>
  )
}

export default SelectField

export const Container = styled.div<{ error: boolean }>`
  width: 100%;
  position: relative;
  input {
    color: ${({ theme }) => theme.palette?.text?.primary} !important;
  }
  .react-select {
    &__control {
      border-color: ${({ error }) => (error ? '#f44336' : '#080A0A14')};
      outline: none;
      height: 56px;
      border-radius: 8px;
    }

    .__single-value {
      color: #080a0a;
      font-size: 16px;
      font-weight: 500;
    }

    &__indicator-separator {
      display: none;
    }
  }

  .css-1wa3eu0-placeholder {
    color: ${({ error }) => (error ? '#f44336' : '#080A0A8A')};
  }

  .creatableselect {
    &__control {
      height: 56px;
      border: 1px solid #080a0a14;
      border-radius: 8px;
    }

    &__indicator {
      &-separator {
        display: none;
      }
    }
  }
`

export const ErrorMessage = styled.div`
  position: absolute;
  color: #f44336;
  bottom: -20px;
  left: 6px;
  font-size: 0.75rem;
`
