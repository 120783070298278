import { gql } from 'graphql.macro'

export const GET_QUESTIONS = gql`
  query(
    $type: String!
    $id: String!
    $filter: QuestionFilter
    $currentPage: Int!
    $perPage: Int!
  ) {
    getQuestions(
      type: $type
      id: $id
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
    ) {
      currentPage
      totalPages
      data {
        id
        edited
        type {
          label
          value
        }
        question
        tags {
          id
          label
        }
        answers {
          isCorrect
          value
        }
        boolAnswer
        modules {
          moduleId
          name
        }
        images {
          name
        }
        videos {
          id
          name
          title
        }
        documents {
          name
        }
        audios {
          name
        }
      }
    }
  }
`

export const GET_QUESTION = gql`
  query($questionId: String!, $type: String!) {
    question(questionId: $questionId, type: $type) {
      id
      edited
      type {
        label
        value
      }
      question
      tags {
        id
        label
      }
      answers {
        isCorrect
        value
      }
      boolAnswer
      reason
      modules {
        moduleId
        name
      }
      images {
        name
        fileType
        link
      }
      documents {
        name
        fileType
        link
        size
      }
      videos {
        id
        name
        thumbnail
        title
        links {
          fileType
          url
          converted
        }
      }
      audios {
        name
        fileType
        link
      }
      currentModule {
        moduleId
        name
      }
    }
  }
`

export const CREATE_QUESTION = gql`
  mutation($input: QuestionInput, $companyId: String) {
    createQuestion(input: $input, companyId: $companyId) {
      id
      edited
      type {
        label
        value
      }
      question
      tags {
        id
        label
      }
      answers {
        isCorrect
        value
      }
      boolAnswer
      modules {
        moduleId
        name
      }
      images {
        name
        fileType
        link
      }
      videos {
        id
        name
        title
      }
      documents {
        name
      }
      audios {
        name
      }
    }
  }
`

export const ADD_QUESTIONS_TO_MODULE = gql`
  mutation($module: QuestionModuleInput, $questionIds: [String]) {
    addQuestionsToModule(module: $module, questionIds: $questionIds)
  }
`

export const EDIT_QUESTION = gql`
  mutation($moduleId: String, $questionId: String, $input: QuestionInput) {
    editQuestion(moduleId: $moduleId, questionId: $questionId, input: $input) {
      id
      edited
      type {
        label
        value
      }
      question
      tags {
        id
        label
      }
      answers {
        isCorrect
        value
      }
      boolAnswer
      reason
      modules {
        moduleId
        name
      }
      images {
        name
        fileType
        link
      }
      videos {
        id
        name
        title
      }
      documents {
        name
      }
      audios {
        name
      }
    }
  }
`
export const DELETE_QUESTIONS = gql`
  mutation($moduleId: String, $questionIds: [String]) {
    deleteQuestions(moduleId: $moduleId, questionIds: $questionIds)
  }
`

export const GET_QUESTIONS_BY_IDS = gql`
  query($ids: [String]) {
    questionsByIds(ids: $ids) {
      id
      edited
      type {
        label
        value
      }
      question
      tags {
        id
        label
      }
      answers {
        isCorrect
        value
      }
      boolAnswer
      modules {
        moduleId
        name
      }
      images {
        name
        fileType
        link
      }
      videos {
        id
        name
        title
      }
      documents {
        name
      }
      audios {
        name
      }
    }
  }
`

export const CHANGE_QUESTION_ORDER = gql`
  mutation($draggedId: String!, $position: Int!) {
    changeQuestionsOrder(draggedId: $draggedId, position: $position) {
      id
      edited
      type {
        label
        value
      }
      question
      tags {
        id
        label
      }
      answers {
        isCorrect
        value
      }
      boolAnswer
      modules {
        moduleId
        name
      }
      images
      videos {
        id
        name
        title
      }
      documents {
        name
      }
      audios {
        name
      }
    }
  }
`
