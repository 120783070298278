import React from 'react'
import Switch from '@material-ui/core/Switch'
import { mainColor } from 'components/common/Colors/CommonColors'
import 'cropperjs/dist/cropper.css'
import useCompaniesService from 'hooks/useCompaniesService'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface ICompanyServiceToggle {
  id: string
  platformService: boolean
  name: string
}

const CompanyServiceToggle = ({
  id,
  platformService,
  name,
}: ICompanyServiceToggle) => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()

  const { changeCompanyPlatformService } = useCompaniesService({
    id: id,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    fireSwal({
      text: t('actions.change_platform_service'),
      title: t('actions.change_of_status'),
      confirmText: t('actions.change_status'),
      width: '40%',
      onConfirm: () => {
        fireSwal({
          title: platformService
            ? t('actions.disable_platform_service')
            : t('actions.enable_platform_service'),
          text: platformService
            ? t('actions.disable_platform_service_description')
            : t('actions.enable_platform_service_description'),
          input: 'text',
          inputConfirmValue: name,
          inputPlaceholder: t('actions.change_platform_service_placeholder'),
          confirmText: t('actions.change'),
          inputValidationMessage: t('actions.company_name_validation'),
          onConfirm: () => changeCompanyPlatformService(id, !platformService),
        })
      },
    })
  }

  return (
    <span
      onClick={(e: any) => {
        e.stopPropagation()
      }}
    >
      <SSwitch
        checked={platformService}
        onChange={handleChange}
        name="platformServiceOn"
        style={{ color: mainColor }}
      />
    </span>
  )
}
export default CompanyServiceToggle

export const SSwitch = styled(Switch)`
  .MuiSwitch {
    &-track {
      width: 32px !important;
      height: 18px !important;
      background-color: #ffffff !important;
      border: 1px solid #080a0a1f;
      border-radius: 100px !important;
      opacity: 1 !important;
    }
    &-thumb {
      top: 50%;
      transform: translate(7px, 50%);
      width: 12px;
      height: 12px;
      color: #269f5a;
    }
  }

  .Mui-checked {
    .MuiSwitch {
      &-thumb {
        transform: translate(-1px, 50%);
      }
    }
  }
`
