import { useState } from 'react'
import editUserService from 'services/users/editUserService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useEditUserService = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [user, setUser] = useState('')
  const [error, setError] = useState('')
  const { updateUserMutation, loading } = editUserService(params)

  const updateUser = async (id: string, input: any, callback?: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    
    const { data, errors } = await updateUserMutation({
      variables: {
        id,
        input,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      // message = errors[0].message

      // if (message === 'User already registered') {
      //   setError(message)
      //   return false
      // }
      variant = 'error'
    } else {
      setUser(data.updateUser)
      const role = data.updateUser.role.toLowerCase()
      message = `${t(`general.${role}`)} ${t('messages.edited')}`
      variant = 'success'
      if (callback) {
        callback()
      }
    }

    setSnackbar({ message, variant })
  }

  return {
    updateUser,
    user,
    editUserLoading: loading,
    error,
  }
}

export default useEditUserService
