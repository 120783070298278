import { useQuery } from '@apollo/react-hooks'
import { Button } from 'components/common/Button'
import DatePicker from 'components/common/DatePicker/DatePicker'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import {
  CloseIcon,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Info,
} from 'components/DrawerLayout/styled-components'
import { genderOptions } from 'components/UsersLayout/staticData'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { useFormik } from 'formik'
import { GET_USER } from 'gql/users.query'
import { DrawerEventEmitter } from 'helpers/drawer'
import { UserSchema } from 'helpers/validationSchemas'
import useEditUserService from 'hooks/users/useEditUserService'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SET_FORMDATA } from 'store/types'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import GenderPicker from 'components/common/GenderPicker'
import { useTheme } from '@material-ui/styles'

interface IEditStudent {
  id: string
  role: string
}

const EditStudent = ({ id, role }: IEditStudent) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [dataState, dispatchData] = useData()
  const [currentStudent, setCurrentStudent] = useState<any>('')
  const { data, loading } = useQuery(GET_USER, {
    variables: { id: id },
  })

  const [bio, setBio] = useState()
  const { updateUser, user, error, editUserLoading } = useEditUserService({
    role: role,
    companyId: state.selectedCompanyId,
    filter: {},
  })
  const [generalError, setGeneralError] = useState('')

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender: '',
      birthDate: null as any,
      biography: '',
      group: null,
      note: '',
      jobTitle: '',
      location: '',
      phoneFields: null as any,
      phoneFieldValue: null as any,
      avatar: '',
    },
    validationSchema: UserSchema,
    onSubmit(values) {
      const formValues: any = { ...values }

      delete formValues.phoneFieldValue

      formValues.group = formValues.group
        ? formValues.group.map((group: any) => {
          return { name: group.label, groupId: group.value }
        })
        : []

      if (formValues.phoneFields && formValues.phoneFields.value) {
        formValues.phoneFields = {
          dialCode: formValues.phoneFields.value,
          code: formValues.phoneFields.label,
        }
      } else {
        formValues.phoneFields = null
      }

      let blankObj = { "blocks": [{ "key": "6bovk", "text": "დდ", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }], "entityMap": {} }

      let bioObj = JSON.parse(data.user.biography) ?? blankObj

      bioObj.blocks[0].text = values.biography
      formValues.biography = JSON.stringify(bioObj)
      formValues.role = data.user.role
      formValues.gender = formValues.gender ? formValues.gender : ''

      updateUser(id, formValues)
    },
  })

  const closeDrawer = () => {
    DrawerEventEmitter.emit('openDrawer', 'editStudent', false)
  }

  const handleCloseDrawer = () => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editStudent',
        values,
        compareTo: currentStudent,
      },
    })
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (user) {
      closeDrawer()
    }
  }, [user])

  useEffect(() => {
    if (error) {
      setGeneralError(error)
    }
  }, [error])

  useEffect(() => {
    if (data) {
      const {
        firstName,
        lastName,
        email,
        phone,
        gender,
        birthDate,

        group,
        note,
        jobTitle,
        location,
        phoneFields,
        avatar,
      } = data.user

      setBio(data.user.biography)
      let biography;
      try {
        biography = JSON.parse(data.user.biography).blocks[0].text
      } catch (err) {
        console.log(err)

        biography = ""
      }
      const formData: any = {
        firstName,
        lastName,
        email,
        phone,
        gender,
        birthDate: birthDate ? moment(parseInt(birthDate)).format() : null,
        biography,
        jobTitle,
        location,
        group: group.map((group: any) => {
          return { label: group.name, value: group.groupId }
        }),
        note,
        phoneFields: phoneFields.dialCode
          ? { label: phoneFields.code, value: phoneFields.dialCode }
          : null,
        phoneFieldValue: phoneFields?.dialCode || null,
        avatar,
      }
      setValues({
        ...formData,
      })
      setCurrentStudent(formData)
    }
  }, [data])

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  const onDateChange = (date: Date | null) => {
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  const theme = useTheme();

  return (
    <>
      {(loading || editUserLoading) && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>
          {t('actions.edit')} {t('general.student')}
        </DrawerTitle>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width="48%">
                <TextInput
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  label={`${t('user_details.last_name')}*`}
                  size="medium"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  label={`${t('user_details.last_name')}*`}
                  size="medium"
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.email || generalError ? true : false}
                  errorMessage={
                    errors.email || generalError
                      ? errors.email || generalError
                      : ''
                  }
                  touched={touched.email}
                  label={`${t('user_details.email')}*`}
                  size="medium"
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <PhoneCountryCode
                  label={t('user_details.phone')}
                  selectLabel={t('form_fields.dial_code')}
                  selectValue={values.phoneFields}
                  selectChange={(e: any) => setFieldValue('phoneFields', e)}
                  inputLabel="Number"
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={
                    errors.phone || errors.phoneFields || generalError
                      ? true
                      : false
                  }
                  errorMessage={
                    errors.phone || errors.phoneFields || generalError
                      ? errors.phone || errors.phoneFields || generalError
                      : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>
              <FormField width="100%">
                <GenderPicker
                  checked={values?.gender}
                  onChange={e => setFieldValue('gender', e)}
                />
              </FormField>
              <FormField width="48%">
                <DatePicker
                  label={t('user_details.birthday')}
                  name="birthDate"
                  value={values.birthDate}
                  onChange={onDateChange}
                  error={errors.birthDate && touched.birthDate ? true : false}
                  touched={touched.birthDate}
                  errorMessage={errors.birthDate ? errors.birthDate : ''}
                />
              </FormField>

              {/* <FormField style={{ position: 'relative' }}>
                <Label>{t('general.groups')}</Label>
                <AsyncSelect
                  isMulti
                  onInputChange={handleSelectChange}
                  onChange={(e: any) => setFieldValue('group', e)}
                  data={(groupData && groupData.getAllGroups) || []}
                  value={values.group}
                  label={t('form_fields.type_to_search_group')}
                  loading={groupLoading}
                  labelDataKey="name"
                  valueDataKey="id"
                  disabled
                />
              </FormField> */}

              <FormField width="48%">
                <TextInput
                  label={t('form_fields.job_title')}
                  size="medium"
                  name="jobTitle"
                  type="text"
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>

              <FormField width="100%">
                <TextInput
                  label={t('form_fields.location')}
                  size="medium"
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>

              <FormField>
                <TextInput
                  error={errors.biography ? true : false}
                  errorMessage={errors.biography ? errors.biography : ''}
                  touched={touched.biography}
                  label={t('user_details.biography')}
                  size="medium"
                  name="biography"
                  type="text"
                  multiline
                  rows="3"
                  value={values.biography}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
              <FormField>
                <TextInput
                  error={errors.note ? true : false}
                  errorMessage={errors.note ? errors.note : ''}
                  touched={touched.note}
                  label={t('user_details.note')}
                  size="medium"
                  name="note"
                  type="text"
                  multiline
                  rows="3"
                  value={values.note}
                  onChange={handleChange}
                  fullWidth={true}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.save')}
                type="medium"
                color="secondary"
                btnType="submit"
                background={mainColor}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditStudent
