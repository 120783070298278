type token = string

export const setToken = (token: token) => localStorage.setItem('token', token)
export const setSelectedCompanyId = (selectedCompanyId: string) =>
  localStorage.setItem('selectedCompanyId', selectedCompanyId)
export const setRole = (role: string) => localStorage.setItem('role', role)
export const getRole = () => localStorage.getItem('role')
export const getToken = () => localStorage.getItem('token')
export const getCompanyMode = () => localStorage.getItem('selectedCompanyId')
export const setUserId = (userId: string) => localStorage.setItem('userId', userId)
export const getUserId = () => localStorage.getItem('userId') || ''
export const clearToken = () => localStorage.removeItem('token')
export const setCourseId = (courseId: string) => localStorage.setItem('courseId', courseId)
export const getCourseId = () => localStorage.getItem('courseId') || ''
export const clearCourseId = () => localStorage.removeItem('courseId')
export const clearCompanyModee = () =>
  localStorage.removeItem('selectedCompanyId')

export const setCurrentLanguage = (language: string) =>
  localStorage.setItem('currentLanguage', language)

export const getCurrentLanguage = () => localStorage.getItem('currentLanguage')
