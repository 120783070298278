import {
  Backdrop,
  IconButton,
  LinearProgress,
  makeStyles,
} from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import SyncIcon from '@material-ui/icons/Sync'
import { Grid } from 'components/common/Cards/styled-components'
import { mainColor } from 'components/common/Colors/CommonColors'
import Drawer from 'components/common/Drawer'
import Pagination from 'components/common/Pagination'
import { Input } from 'components/common/TextInput'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import Player from 'components/common/VideoPlayer/Player'
import { useFetchSuggestedCourses } from 'hooks/course/useSuggestedCourses'
import _ from 'lodash'
import CourseList from 'pages/courses/CourseList'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FilterDrawer from '../FilterDrawer/FilterDrawer'
import { SelectField } from 'components/common/SelectField'
import { useQuery } from '@apollo/react-hooks'
import { GET_CATEGOTIES } from 'gql/categories/categories.query'
import Icon from 'components/Icon'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const toolbarStyle = {
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',

  '&.noBorder': {
    borderBottom: '1px solid transparent',
  },
}
export const ExploreCourses: React.FC<{
  groups: string[]
  courses: any
  loading: any
  refetch: any
  error: any
  userId: any
  fetchMore: any
}> = ({ groups, courses, loading, refetch, error, userId, fetchMore }) => {
  const { t } = useTranslation()
  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [filterData, setFilterData] = useState<any>({})
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage] = useState<number>(12)


  const generateQuery = () => {
    const where: any = {
      query: { value: {}, type: 'query' },
    }

    where.query = {
      type: 'query',
      value: {
        ...where.query.value,
      },
    }
    return where
  }

  const query = generateQuery()
  const { data: categoriesData } = useQuery(GET_CATEGOTIES)

  const categoriesList =
    categoriesData &&
    categoriesData.getCategories.map((category: any) => ({
      label: category.name,
      value: category.id,
      id: category.id,
    }))

  query.query = {
    type: 'query',
    value: JSON.stringify({ ...query.query.value, ...filterQuery }),
  }

  if (searchText) {
    query.name = {
      type: 'match',
      value: searchText,
    }
  }

  const filterDrawer = () => {
    setFilterIsOpen(!filterIsOpen)
  }

  const resetFilter = () => {
    setFilterQuery({})
    setFilterData({})
    setFilterIsOpen(false)
    setSearchText('')
  }

  const onSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setCurrentPage(1)
    setFilterQuery({})
    refetch()
  }

  const [isCoursesVisible, setCoursesVisible] = useState(true)

  const classes = useStyles()

  const [videoModal, setVideoModal] = useState<any>({
    modal: false,
    data: '',
    subtitle: '',
  })

  const handleVideoModal = (data: any) => {
    if (!data.video) return

    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? data.video.links[0].url : '',
      subtitle: !videoModal.modal ? data.subtitle : '',
    })
  }

  // const handlePaginationClick = (event: any, value: number) => {
  //   if (value !== courses.currentPage) {
  //     refetch({
  //       filter: {},
  //       currentPage: value,
  //       perPage,
  //     })
  //     setCurrentPage(value)
  //   }
  // }

  const handleFieldChange = (field: string) => {
    return (e: any) => {
      let item = field === 'name' ? e.target.value : e

      setFilterData({
        ...filterData,
        [field]: item,
      })
    }
  }

  const onFormSubmit = () => {
    const { categories, ...args } = filterData

    const filterQuery: any = {}

    for (const key in args) {
      if (args[key].value && args[key].value !== 'all') {
        filterQuery[key] = args[key].value
      }
    }

    if (categories) {
      filterQuery['categories.name'] = categories.label
    }

    setFilterQuery(filterQuery)
    setCurrentPage(1)
    refetch()
  }

  React.useEffect(() => onFormSubmit(), [filterData])

  useEffect(() => {
    const query = generateQuery()
    query.query = { type: 'query', value: JSON.stringify(query.query.value) }
    refetch({
      filter: {
        ...query,
      },
    })
  }, [])

  return (
    <div> {isCoursesVisible && (
      <Grid className="card" style={{ position: 'relative' }} cols={3}>
        {courses && (
          <CourseList
            data={courses}
            gridStyle="card"
            userId={userId}
            selectedItem={[]}
            handleVideoModal={handleVideoModal}
            selectItem={() => { }}
            redirectToDashboard={true}
          />
        )}
        {/* {courses?.data && (
          <CourseList
            data={courses.data}
            gridStyle="card"
            userId={userId}
            selectedItem={[]}
            handleVideoModal={handleVideoModal}
            selectItem={() => { }}
            redirectToDashboard={true}
          />
        )} */}
      </Grid>
    )}
      {/* {courses && courses.totalPages > 1 && (
        <div
          style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}
        >
          <Pagination
            currentPage={courses ? courses.currentPage : 1}
            totalPages={courses ? courses.totalPages : 0}
            handleChange={handlePaginationClick}
          />
        </div>
      )} */}

      <Drawer opened={filterIsOpen} toggleDrawer={() => { }} totalWidth="700px">
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterQuery={setFilterQuery}
          perPage={perPage}
          generateQuery={generateQuery}
          refetchCourse={refetch}
          resetFilter={resetFilter}
          onClose={() => setFilterIsOpen(false)}
          t={t}
        // setCurrentPage={setCurrentPage}
        />
      </Drawer>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={videoModal.modal}
        onClose={() => {
          setVideoModal({
            ...videoModal,
            modal: false,
          })
        }}
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Player url={videoModal.data} subtitle={videoModal.subtitle} />
      </Modal>
    </div>
  )
}

const ToolbarItemWrapper = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  justify-content: flex-end;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 50px 0px;

  .title {
    color: #080a0ad6;
    font-size: 36px;
    font-weight: 600;
  }

  .secondary-title {
    color: #080a0a8a;
    font-size: 16px;
  }
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 90px;
  width: 100%;
`

const SelectCategoryWrapper = styled.div`
  max-width: 237px;
  width: 100%;
  .react-select__control {
    border-color: #080a0a29;
  }
`

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 357px;
  width: 100%;
  height: 56px;
  background: #ffffff;
  border: 1px solid #080a0a29;
  border-radius: 8px;
  padding: 0px 17px;
  gap: 10px;

  input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
  }
`

const SearchButton = styled.button`
  background: #317bf4;
  border-radius: 16px;
  color: #ffffff;
  font-size: 14px;
  padding: 20px 35px;
  border: none;
  outline: none;
  cursor: pointer;
`
