import styled from 'styled-components'

export const Container = styled.div`
  width: 700px;
  position: relative;
  overflow: hidden;
  .video-react-big-play-button{
    overflow: hidden !important;
  }
`

export const Tracker = styled.track`
  background: rgba(204, 204, 204, 0.17);
`


export const StyledVideo = styled.video`
  width: 660px;
  height: 380px;
  outline: none;
`
export const SubtitlesMenuContainer = styled.div`
  width: 45px;
  position: absolute;
  top: 0;
  left: -10px;
  transform: translateY(-100%);
  background: rgba(43, 51, 63, 0.7);
  height: 80px;
  display: flex;
  flex-direction: column;
  border-radius: 1px;
`
export const QualityButtonWrapper = styled.div`
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
`
export const StyledQualityMenu = styled.div`
  width: 44px;
  position: absolute;
  top: 0;
  left: -10px;
  transform: translateY(-100%);
  background: rgba(43, 51, 63, 0.7);
  height: 80px;
  display: flex;
  flex-direction: column;
  border-radius: 1px;
`

export const StyledQualityMenuItem = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
  font-size: 13px;
  color: ${({ color }) => color ? '#ff3a52' : '#fff'};
  cursor: pointer;
  margin-top: 3px;
`

export const ButtonWrapper = styled.div`
  margin-top: 5px;
  cursor: pointer;
`

export const CommentMenu = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;


  &:hover {
    background-color: #317BF4;
    border-radius: 4px;
  }

  &:hover:after, &:hover:before {
    display: block;
  }

  &::after{
    content: '${props => props.title ? props.title : ''}';
    display: none;
    width: 150px;
    height: 30px;
    background-color: #317BF4;
    position: absolute;
    top: -40px;
    left: -60px;
    line-height: 30px;
    border-radius: 8px;
  }

  &::before {
    content: '';
    display: none;
    position: absolute;
    top: -13px;

    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #317BF4;
  }
`

export const CutomTextArea = styled.textarea`
  resize: none;
  width: 100%;
  height: 130px;
  border: 1px solid #080A0A08;
  outline: none;
  padding: 20px;
  border-radius: 8px;
 `

export const SaveCommentButton = styled.button`
  width: 166px;
  height: 51px;
  background-color: #317BF4;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 16px;
  cursor: pointer;
`

export const CancelEditButton = styled.button`
  width: 166px;
  height: 51px;
  background-color: #fff;
  color: #797A7A;
  border: none;
  outline: none;
  border-radius: 16px;
  margin-right: 10px;
  border: 1px solid #EBEBEB;
  cursor: pointer;
  font-weight: medium;
`