import { useEffect, useState } from 'react';
import React from 'react'


const LangSelect = ({ languages, defaultValue, onChange }) => {
  const [currentLanguage, setCurrentLanguage] = useState(defaultValue);
  const [isVisible, setIsVisible] = useState(false)


  return (
    <div className={`flex flex-col justify-center items-center border-2   border-solid border-[#fff] cursor-pointer mx-3 text-white relative ${!isVisible ? 'rounded-full' : 'rounded-tl-3xl rounded-tr-3xl'}`} onClick={() => {
      setIsVisible(!isVisible)
    }}>
      <div className='px-8 py-2'>{defaultValue.title}</div>
      {isVisible && (<div className='flex flex-col justify-center items-center bg-black/70 absolute left-0 right-0 border-l-2 border-r-2 border-b-2 border-[#fff] rounded-bl-3xl rounded-br-3xl p-2' style={{
        bottom: '-76px',
      }}>
        {languages.map(lang => {
          return <div className='hover:bg-[#E1E1E1] hover:text-[#000] px-2 py-1 rounded-md' onClick={() => {
            setCurrentLanguage(lang)
            onChange(lang)
          }}>{lang.title}</div>
        })}
      </div>)}
    </div>
  );
}

export default LangSelect;
