import { updateCompanyCoverImageService } from 'services/companies/editCompanyCoverImage'
import { useTranslation } from 'react-i18next'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'

export const useUpdateCompanyCoverImage = () => {
  const { t } = useTranslation()
  const { updateCompanyCoverImage } = updateCompanyCoverImageService()
  const { setSnackbar } = useSnackbarAlert()

  const updateCoverImage = async (
    id: string,
    action: string,
    coverImage: any,
    crudFunction: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    let hasResponseReceived = false

    const { errors } = await updateCompanyCoverImage({
      variables: {
        id,
        action,
        coverImage,
      },
    })

    if (errors) {
      hasResponseReceived = true
      message = errors[0].message
      variant = 'error'
    } else {
      hasResponseReceived = true
      if (action === 'ADD') {
        message = t('messages.changes_saved')
      } else {
        message = `${t('general.avatar')} ${t('messages.successfully')} ${t(
          'general.deleted',
        )}`
      }

      variant = 'success'
    }

    setSnackbar({ message, variant })

    crudFunction(hasResponseReceived)
  }

  return {
    updateCoverImage,
    hasResponseReceived_Cover: false,
  }
}
