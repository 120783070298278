import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import EditIcon from '@material-ui/icons/Edit'
import { Button } from 'components/common/Button'
import { useUserValue } from 'context/UserContext'
import 'cropperjs/dist/cropper.css'
import useOutsideClick from 'helpers/useOutsideClick'
import Cropper from 'react-cropper'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CancelIcon from '@material-ui/icons/Cancel'
import { mainColor, mainGrey, validationRed, red } from '../Colors/CommonColors'
import Loader from '../Loader'
import { useSwal } from 'hooks/useSwal'

interface ICoverImage {
  finishCrop: (
    action: any,
    crudFunction: (isFinished: boolean) => void,
    cropper: any,
  ) => void
  img?: string
  isRectangular?: boolean
  hasResponseReceived?: boolean
}

const useStyles = makeStyles(theme =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
)

const cropper: any = React.createRef() as any

const CoverImage = ({ finishCrop, img, hasResponseReceived }: ICoverImage) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [avatarMenu, setAvatarMenu] = useState(false)
  const [cropperOpen, setCropperOpen] = useState(false)
  const [file, setFile] = useState<any>(null)
  const ref: React.RefObject<HTMLDivElement> = useRef() as React.RefObject<
    HTMLDivElement
  >
  const { fireSwal } = useSwal()
  const [loading, setLoading] = useState(false)
  const [loadingForDelete, setLoadingForDelete] = useState(false)
  const [canEditCompanyCoverImage, setCanEditCompanyCoverImage] = useState<
    boolean
  >(false)
  const [state] = useUserValue()

  useEffect(() => {
    loadingForDelete && setLoadingForDelete(!hasResponseReceived)
  }, [hasResponseReceived, loading, loadingForDelete])

  useOutsideClick(ref, () => {
    if (avatarMenu) setAvatarMenu(false)
  })

  const handleAvatarChange = (e: any) => {
    const { files } = e.target
    const reader = new FileReader()
    setLoading(false)

    reader.onload = () => {
      const img: HTMLImageElement = new Image() as HTMLImageElement
      if (typeof reader.result === 'string') {
        img.src = reader.result
        setFile(reader.result)
      }
      setCropperOpen(true)
      setAvatarMenu(false)
    }
    reader.readAsDataURL(files[0])
  }

  const cropperModalToggle = () => {
    setCropperOpen(!cropperOpen)
  }

  const crudFunction = (isFinished: boolean) => {
    setCropperOpen(false)
    setAvatarMenu(false)
  }

  const onSave = useCallback(() => {
    setLoading(true)
    finishCrop('update', crudFunction, cropper)
    setFile(null)
  }, [])

  useEffect(() => {
    if (
      state?.currentUser?.role === 'SUPER_ADMIN' ||
      state?.currentUser?.role === 'ADMIN'
    ) {
      setCanEditCompanyCoverImage(true)
    }
  }, [state.currentUser.role])

  const handleDeleteClick = () => {
    const params = {
      title: t('popups.remove_image'),
      onConfirm: () => {
        setLoadingForDelete(true)
        finishCrop('delete', crudFunction, cropper)
        setFile(null)
      },
      confirmText: t('popups.confirm_delete'),
    }
    fireSwal(params)
  }

  const CompanySuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !!state?.currentUser?.companyId?.id

  const CompanyAdmin =
    state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  return (
    <Wrapper
      img={img}
      CompanySuperAdmin={CompanySuperAdmin}
      CompanyAdmin={CompanyAdmin}
    >
      {canEditCompanyCoverImage && (
        <AvatarSettingContainer ref={ref}>
          <EditIconContainer
            CompanySuperAdmin={CompanySuperAdmin}
            CompanyAdmin={CompanyAdmin}
            onClick={() => setAvatarMenu(!avatarMenu)}
          >
            <EditIcon
              onClick={() => setAvatarMenu(!avatarMenu)}
              style={{ fontSize: 18, color: '#989898' }}
            />
          </EditIconContainer>

          {avatarMenu && (
            <AvatarMenu>
              <AvatarMenuItem>
                <EditIcon
                  onClick={() => setAvatarMenu(true)}
                  style={{ fontSize: 18, color: '#989898', marginRight: '8px' }}
                />
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={handleAvatarChange}
                  multiple
                />
                <label htmlFor="file">{t('actions.uplode_cover_image')}</label>
              </AvatarMenuItem>

              {(!!file || (!file && !!img)) && (
                <AvatarMenuItem onClick={handleDeleteClick}>
                  <CancelIcon
                    style={{
                      fontSize: 18,
                      color: '#989898',
                      marginRight: '8px',
                    }}
                  />
                  {t('actions.delete_cover_image')}
                </AvatarMenuItem>
              )}
            </AvatarMenu>
          )}
        </AvatarSettingContainer>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={cropperOpen}
        onClose={cropperModalToggle}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={cropperOpen}>
          <ImageCropperContainer>
            {loading && <Loader />}
            <Cropper
              ref={cropper}
              src={file}
              style={{ height: 500, width: 600 }}
              aspectRatio={16 / 4}
              guides={false}
            />
            <div className="actions">
              <Button
                onClick={cropperModalToggle}
                background="#C7C7C7"
                textColor={validationRed}
                outline={`1px solid ${red}`}
                text={t('actions')}
                type="small"
              />
              <Button
                color="primary"
                onClick={onSave}
                background={mainColor}
                text={t('actions.save')}
                type="small"
              />
            </div>
          </ImageCropperContainer>
        </Fade>
      </Modal>
    </Wrapper>
  )
}

export default CoverImage

const Wrapper = styled.div<{
  img: any
  CompanySuperAdmin: boolean
  CompanyAdmin: boolean
}>`
  position: relative;
  width: 100%;
  height: 350px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: url(${({ img }) => !!img && img});
    background-color: ${({ img }) => !img && '#944949'};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    border-radius: 8px;

    z-index: -1;
    opacity: 1;
  }
`

const AvatarSettingContainer = styled.div`
  position: relative;
`

const AvatarMenuItem = styled.div`
  padding: 5px 3px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  label {
    cursor: pointer;
  }
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
`
const AvatarMenu = styled.div`
  position: absolute;
  top: 60px;
  left: 20px;
  width: 200px;
  box-sizing: border-box;
  padding: 5px 7px;
  background: #fff;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.22);
  border-radius: 3px;
  z-index: 1;
`

const ImageCropperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .actions {
    /* width: 500px; */
    /* background: #fff; */
    display: flex;
    button {
      margin: 7px 5px;
    }
  }
`

const EditIconContainer = styled.div<{
  CompanySuperAdmin: boolean
  CompanyAdmin: boolean
}>`
  width: 28px;
  height: 28px;
  background: #f0f0f0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
`
