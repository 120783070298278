import React, { memo } from 'react'
import clsx from 'clsx'
import { makeStyles, } from '@material-ui/core/styles'
import { useTheme, useMediaQuery } from '@material-ui/core'
import largeLogo from 'assets/logo.png'

import smallLogo from "assets/smallLogo.svg"
import Drawer from '@material-ui/core/Drawer'
import _ from 'lodash'
import NavigationBar from 'components/ExpansionMenu/NavigationBar'
import styled from 'styled-components'
import Icon from './Icon'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  drawer: {
    backgroundColor: theme.palette.secondary.dark,
    color: '#fff',
  },
  drawerOpen: {
    width: 370,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: '.2s',
    }),
  },
  drawerClose: {
    display: 'flex',
    alignItems: 'center',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: '.2s',
    }),
    overflowX: 'hidden',
    width: 187,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '30px',
    marginTop: '30px',
    marginBottom: '70px',
    padding: '0px 30px',
    width: '100%',
  },
}))

interface Props {
  currentUser: any
  open: boolean
  setSideBarOpen: (open: boolean) => void
}

const Sidebar = ({ currentUser, open, setSideBarOpen }: Props) => {
  const classes = useStyles()
  const theme = useTheme()

  const isSmallScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const history = useHistory()
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <Logo open={!open} onClick={() => history.push('/')}>
          {/* <Icon filename={open ? 'logo' : 'wenroll_logo'} /> */}
          <img src={open ? largeLogo : smallLogo} alt="logo" />
        </Logo>
        <CloseBtn open={!open} onClick={() => setSideBarOpen(!open)}>
          <Icon filename={'left_arrow'} />
        </CloseBtn>

      </div>
      {/* {currentUser && <WelcomeUser isOpened={open} user={currentUser} />} */}
      <NavigationBar isOpened={open} currentUser={currentUser} />
    </Drawer>
  )
}

export default memo(Sidebar, (prev, next) => {
  if (!_.isEqual(prev.currentUser, next.currentUser)) return false
  if (prev.open !== next.open) return false
  return true
})

const CloseBtn = styled.div<{ open: boolean }>`
  width: 36px;
  height: 36px;
  background: #ffffff0a;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: #ffffff;
    transition: 0.3s;
    ${({ open }) => open && 'transform: rotate(180deg);'}
  }
`

const Logo = styled.div<{ open: boolean }>`
  width: 177px;

  overflow: hidden;
  cursor: pointer;
  ${({ open }) => open && 'width: 50px;'}
  svg {
    width: 100%;
    height: 100%;
    /* path {
      fill: #ffffff;
    } */
  }
`
