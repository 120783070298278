import React from "react"
import { createContext, ReactChild, ReactNode, useState } from "react"
import { lastWatchedIndex } from "../types"



interface ILastWatched {

  lastWatched: lastWatchedIndex;
  setLastWatched: React.Dispatch<React.SetStateAction<lastWatchedIndex>>;
}



export const LastWatchedContext = createContext<ILastWatched>({} as ILastWatched)

const LastWatchedProvider = ({ children }: { children: ReactNode }) => {

  const [lastWatched, setLastWatched] = useState<lastWatchedIndex>({ moduleIndex: 0, topicIndex: 0, lessonIndex: 0, show: false })

  const value = { lastWatched, setLastWatched }
  return <LastWatchedContext.Provider value={value}>{children}</LastWatchedContext.Provider>
}


export default LastWatchedProvider;