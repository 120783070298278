import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { CircularProgress, useTheme } from '@material-ui/core'
import { Text } from '../styled'
import * as S from './styled'
import { GET_MODULE } from 'gql/modules.query'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { lastWatchedIndex, module, ModulesData } from '../types'
import { secondsToHms } from 'utils/utils'
import { useTranslation } from 'react-i18next'
import LessionTypeIcon from './atoms/LessionTypeIcon'
import Icon from 'components/Icon'
import LessionTypeAction from './atoms/LessionTypeAction'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from 'components/common/Button'
import { useUserValue } from 'context/UserContext'
import Quizz from './quizz'
import { GET_FLASHCARDS } from 'gql/flashcards.query'
import FlashCard from './flashcard'
import { useTrackCourse } from 'hooks/course/useTrackCourse'
import styled from 'styled-components'
import PersonalHomework from './personalhomework'
import CreatePersonalHomework from './PersonalHomework/createPersonalHomework'
import { GET_SKILL_TEST_VIDEO, GET_SKILL_TEST_VIDEO_BY_MODULE_ID } from 'gql/skillTest/skilltest.query'
import { CircularProgressBarWithLabel } from 'components/common/CircularProgressBar/CircularProgressBar'

import Notes, { Note } from './Drawer/Notes';
import { LastWatchedContext } from './StudentCourseContext'
import LessonName from './atoms/LessonName'

const lessionTypeName = (t: any, lesson: module.Lesson) => {
  if (lesson.audio) return t('general.audio') + " ·"
  if (lesson.contentDocument) return t('general.attached_documents')
  if (lesson.video) return t('general.video') + " ·"
}

export type ModuleProps = {
  courseId: string
  moduleId: string,
  originalId: string,
  disabled?: boolean
  onQuizzSelect: (quizId: string) => void,
  courseName: string,
  groupId: string,
  isStat?: boolean,
  isOwnCourse: boolean,
  moduleIndex: number
  modules: ModulesData[]
  refetchCourseStatiscs?: () => any
  refetchGetUser: () => any
  userTotalNotes: Note[]
}

// export interface ModulesData {
//   id: string
//   orderIndex: number
//   name: string
//   coverImage: CoverImage
//   description: string
//   editorText: string
//   createDate: string
//   numberOfTopics: number
//   numberOfLessons: number
//   duration: number
//   quizList?: any
//   subtitle?: any
//   courses: Cours[]
//   attachment: Attachment
//   video?: any
//   companyId?: any
// }

const Module: React.FC<ModuleProps> = props => {
  const { t } = useTranslation()
  const theme = useTheme();
  const history = useHistory()
  const [state, setState] = useUserValue();
  const [flashcardVisible, setFlashCardVisible] = useState(false)
  const [personalHomeworkVisible, setPersonalHomeworkVisible] = useState(false)
  const [quizVisible, setQuizVisible] = useState(false)
  const [iqTestVisible, setIqTestVisible] = useState(false)

  const [lastModule, setLastModuleData] = React.useState<module.root | null>(null)
  const [flashCards, setFlashCards] = useState([]);
  const [skillTests, setSkillTests] = useState<any[]>();
  const { lastWatched, setLastWatched } = useContext(LastWatchedContext)
  const [isModalOpenen, setIsModalOpened] = useState(false);
  const [refetchDone, setRefetchDone] = useState(false)
  const [moduleData, setModuleData] = useState<any>({});

  const [refs, setRefs] = React.useState<{ [key: string]: React.RefObject<HTMLDivElement> }>({})


  const params: { id: string, userId: string, assignment: string } = useParams()

  const lastModuleId = props.modules[props.moduleIndex - 1]?.id || props.moduleId
  const currentUserNotes: Note[] = props.userTotalNotes ? props.userTotalNotes : state.currentUser.notes || [];

  const bought = state.currentUser.courses.some((el: any) => el.courseId === params.id.toString())


  const { refetch: refetchSkillTestVideos } = useQuery(GET_SKILL_TEST_VIDEO, {
    variables: {
      courseId: props.isStat ? params.id : props.originalId,
      userId: props.isStat ? params.userId : state.currentUser.id,
    },
    onCompleted: data => setSkillTests(data.getSkillTestVideos)
  })

  const [getSkillTestForCoach, { refetch: refetchSkillTestForCoach }] = useLazyQuery(GET_SKILL_TEST_VIDEO_BY_MODULE_ID, {
    variables: {
      moduleId: props.moduleId
    },
    onCompleted: (data) => setSkillTests(data.getSkillTestVideosByModuleId)
  })

  // const { data: { getModule: moduleData } = {}, refetch: refetchModule } = useQuery<{ getModule: module.root }>(GET_MODULE, {
  //   variables: {
  //     courseId: props.courseId,
  //     moduleId: props.moduleId,
  //   },
  // })


  const [getModule, { refetch: refetchModule }]
    = useLazyQuery(GET_MODULE, {
      variables: {
        courseId: props.courseId,
        moduleId: props.moduleId,
      },
      onCompleted: (data) => { setModuleData(data.getModule) }
    })

  useEffect(() => {
    getModule()
  }, [])

  const { trackProgress } = useTrackCourse({ moduleId: moduleData?.id, courseId: props.originalId || props.courseId, userId: state.currentUser.id });
  const { refetch: refetchData, loading: loadingModule } = useQuery<{ getModule: module.root }>(GET_MODULE, {
    variables: {
      courseId: props.courseId,
      moduleId: lastModuleId,
    },
    onCompleted: data => setLastModuleData(data.getModule),
  })
  useQuery(GET_FLASHCARDS, {
    variables: {
      moduleId: props.moduleId,
      skip: 0,
      limit: 50,
    },
    onCompleted: data => setFlashCards(data.flashCards)
  })

  const openLesson = React.useCallback((lesson: module.Lesson) => {
    if (lesson.video || lesson.audio) {
      history.push(`lesson/${lesson.id}`)
    }
    if (lesson.contentDocument) {
      window.open(lesson.contentDocument.link, '_blank')
    }
  }, [])

  useEffect(() => {
    if (params.assignment === "assignment") {
      setPersonalHomeworkVisible(true)
      setIsModalOpened(true)
    } else {
      setPersonalHomeworkVisible(false)
      setIsModalOpened(false)
    }

  }, [params.assignment])


  useEffect(() => {
    if (state.currentUser.role === "COACH" && !props.isStat) {
      getSkillTestForCoach();
    }
  }, [state.currentUser, state.currentUser.role])

  useEffect(() => {
    const itemRefs = {}
    //@ts-ignore
    moduleData?.topics?.forEach((topic, topicIndex) => {
      //@ts-ignore
      topic.lessons?.forEach((lesson, lessonIndex) => {
        //@ts-ignore
        itemRefs[topicIndex + "-" + lessonIndex] = React.createRef();
      })
    })
    setRefs(itemRefs)
    lastWatched && handleClick(lastWatched?.topicIndex + "-" + lastWatched?.lessonIndex)
  }, [lastWatched, moduleData, loadingModule])

  const handleClick = (id: string) => {
    if (lastWatched.show) {
      //@ts-ignore
      refs[id]?.current?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      });
    }
  }

  // check is last lessoion's progress is 100
  function isDisabled(topicIndex: number, lessonIndex: number) {
    // First video should be open && props.moduleIndex == 0
    if (topicIndex == 0 && lessonIndex == 0 && props.moduleIndex == 0) {
      return false
    }
    //check last module's last lessons progress
    if (props.moduleIndex > 0 && topicIndex == 0 && lessonIndex == 0) {
      if (lastModule?.topics.length) {
        const lastModuleLastLessons = lastModule?.topics[lastModule?.topics.length - 1]?.lessons
        if (lastModuleLastLessons?.length) {
          const lastLesson = lastModuleLastLessons[lastModuleLastLessons.length - 1]
          const value = lastLesson.progress < 100 ? true : false
          return value
        }
      } else {
        // here should check othere modules if there is one with lessons and not 100% return true
        return false
      }
    }
    // check if last lesson has 100% progress
    if (topicIndex >= 0 && lessonIndex >= 0) {
      if (lessonIndex == 0 && !(topicIndex == 0)) {
        let lastTopicLessonLength = (moduleData?.topics[topicIndex - 1].lessons.length || 0)
        if (lastTopicLessonLength) {
          const lastTopicsLastLesson = moduleData?.topics[topicIndex - 1].lessons[lastTopicLessonLength - 1]
          return lastTopicsLastLesson!.progress < 100 ? true : false
        }
      } else {
        const lastLesson = moduleData?.topics[topicIndex].lessons[lessonIndex - 1]
        if (!lastLesson) {
          return false
        }
        return lastLesson!.progress < 100 ? true : false
      }
    }
    return true
  }


  function isLocked(topicIndex: number, lessonIndex: number) {
    if (state.currentUser.role !== "STUDENT") return false;
    if (props.isOwnCourse && moduleData?.isLocked) {
      return isDisabled(topicIndex, lessonIndex)
    } else if (props.isOwnCourse && !moduleData?.isLocked) {
      return false
    } else if (!props.isOwnCourse) {
      return true
    } else {
      return true
    }
  }

  const progressTracker = (duration: number, lessonId: string) => {
    trackProgress({
      variables: {
        courseId: props.originalId || props.courseId,
        lessonId,
        duration
      }
    }).then((x) => {

      getModule()
      !props.refetchCourseStatiscs && setRefetchDone(true)
      props.refetchCourseStatiscs && props.refetchCourseStatiscs().then(() => {
        setRefetchDone(true)
        getModule()

      }).catch(() => {
        console.log("refeched error")
      }
      )
    }).catch(() => {
      console.log("track progress error")
    })
  }

  if (!moduleData) return <CircularProgress />

  const renderedTopicLessons = (topic: module.Topic, topicIndex: number) => {
    return (
      <>
        {topic.lessons?.map((lesson, lessonIndex) => {
          const currentLessonNotes = currentUserNotes.filter((n: any) => n.actionId === lesson.id);
          const isLockedLesson = isLocked(topicIndex, lessonIndex)

          const lessonTypeIcon = (
            <LessionTypeIcon
              getModule={getModule}
              refetchDone={refetchDone}
              setRefetchDone={setRefetchDone}
              refetchGetUser={props.refetchGetUser}
              allLesson={topic.lessons}
              lessonIndex={lessonIndex}
              onVideoProggress={(duration: number, lessonId?: string) => progressTracker(duration, lessonId ? lessonId : lesson.id)}
              pdfDuration={lesson?.pdfLessonDuration}
              disabled={props.disabled}
              lesson={lesson}
              indexes={[topicIndex, lessonIndex]}
              isLessonDisabled={isLockedLesson}
              courseId={props.courseId}
              moduleId={moduleData.id}
              userId={state.currentUser.id}
            />
          )

          return (
            <S.SectionItem
              key={lesson.id}
              id={'stdSection'}
              onClick={(e: any) => {
                e.target?.id === 'stdSection' &&
                  !props.disabled &&
                  openLesson(lesson)
              }}
              //@ts-ignore
              ref={refs[topicIndex + "-" + lessonIndex]}
            >
              <S.SectionIcon>
                {lesson?.progress < 100 ?
                  <CircularProgressBarWithLabel
                    strokeWidth={1.5}
                    progressStrokeWidth={3}
                    size={35}
                    progress={lesson?.progress || 0}
                    innerFontSize={14}
                  >

                    {lessonTypeIcon}

                  </CircularProgressBarWithLabel>
                  : lessonTypeIcon
                }
              </S.SectionIcon>
              <S.SectionContent>
                <LessonName lessonId={lesson.id} color={theme.palette.text.primary} lessonName={lesson.name} isOwn={props.isOwnCourse} />
                <div className="content">
                  <Text fs={'12px'} color={theme.palette.text.primary}>
                    {`${lessionTypeName(t, lesson)} ${secondsToHms(
                      lesson.duration,
                    )}`}
                  </Text>
                  <Notes
                    currentLessonNotesCount={currentLessonNotes.length}
                    color={theme.palette.text.primary}
                    lesson={lesson}
                    model="LESSON"
                    courseId={props.courseId}
                    refetchGetUser={props.refetchGetUser}
                  />
                </div>
              </S.SectionContent>

              {
                props.isOwnCourse && moduleData.isLocked && !lesson.isLocked && lesson.progress < 100 && (
                  <S.SectionAction>
                    <LessionTypeAction lesson={lesson} icon="openLock" />
                  </S.SectionAction>
                )
              }
              {
                props.isOwnCourse && moduleData.isLocked && lesson.isLocked && (
                  <S.SectionAction>
                    <LessionTypeAction lesson={lesson} icon="lock" />
                  </S.SectionAction>
                )
              }
              {
                !props.isOwnCourse && moduleData.isLocked && (
                  <S.SectionAction>
                    <LessionTypeAction lesson={lesson} icon="lock" />
                  </S.SectionAction>
                )
              }
            </S.SectionItem>
          )
        })}
      </>
    )
  }

  return (
    <S.Container>
      <Text color={theme.palette.text.primary + ` !important`} variant={'title'} mb={'30px'}>
        {moduleData.name}
      </Text>

      {!props.isStat && moduleData.topics?.map((topic: module.Topic, topicIndex: number) => {
        return (
          <S.Section key={topic.id}>
            <div
              className="title"
              style={{ color: theme.palette.text.primary }}
            >
              {topic.name}
            </div>
            {renderedTopicLessons(topic, topicIndex)}
            {topic.quizzes ? topic.quizzes.map((quiz: any) => <Quizz isOwn={props.isOwnCourse} courseId={props.originalId} quiz={quiz} onQuizSelect={props.onQuizzSelect} />) : ""}
          </S.Section>
        )
      })}

      {
        flashCards && !props.isStat && flashCards.length > 0 && (
          <S.Section>

            <Text color={theme.palette.text.primary} fs={16} weight={'bold'} >{t('general.remindCoveredLesson')}</Text>

            <S.SectionContent>
              <div className='content'>
                <S.SectionIcon>
                  <Icon filename='flashcard_icon' width={64} height={64} />
                </S.SectionIcon>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  marginTop: '13px',
                  width: '100%',
                }}>
                  <div>
                    <Text fs={16}>{t('general.remindCards')}</Text>
                    <Text fs={12} color={theme.palette.text.primary}>{flashCards.length} {t('general.card')}</Text>
                  </div>

                  <Button text={t('general.view')}

                    textColor={theme.palette.text.primary}
                    background='#080A0A08'
                    height={47}
                    size={'large'}
                    buttonStyles={{
                      padding: 20,
                      borderRadius: '16px',
                    }}
                    onClick={() => setFlashCardVisible(!flashcardVisible)}
                    isDisabled={bought ? false : true}
                  />
                </div>
              </div>

            </S.SectionContent>
            {flashcardVisible && (<FlashCard flashcards={flashCards} />)}
          </S.Section>
        )
      }


      <PersonalHomeworkContent>
        {moduleData?.quizList && moduleData?.quizList.map((quiz: any) => {
          return <Quizz isOwn={props.isOwnCourse} courseId={props.originalId} quiz={quiz} isStat={props.isStat} onQuizSelect={props.onQuizzSelect} userId={params.userId} />
        })}
      </PersonalHomeworkContent>

      {
        props.isStat && <>
          <div style={{ width: '100%', height: '1px', background: '#080A0A12' }}></div>

          <PersonalHomeworkContainer>
            <PersonalHomeworkHeader>
              <Text fs={20} weight={'bold'} color={theme.palette.text.primary}>{t('general.quizResults')}</Text>
              <div style={{
                width: '36px',
                height: '36px',
                background: '#080A0A04',
                borderRadius: '12px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }} onClick={() => setQuizVisible(!quizVisible)}>
                <Icon filename={'down_arrow'}></Icon>
              </div>
            </PersonalHomeworkHeader>
          </PersonalHomeworkContainer>
        </>
      }

      {
        props.isStat && <>
          <div style={{ width: '100%', height: '1px', background: '#080A0A12' }}></div>

          <PersonalHomeworkContainer>
            <PersonalHomeworkHeader>
              <Text fs={20} weight={'bold'} color={theme.palette.text.primary}>{t('general.testResults')}</Text>
              <div style={{
                width: '36px',
                height: '36px',
                background: '#080A0A04',
                borderRadius: '12px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }} onClick={() => setIqTestVisible(!iqTestVisible)}>
                <Icon filename={'down_arrow'}></Icon>
              </div>
            </PersonalHomeworkHeader>

            {iqTestVisible &&
              <PersonalHomeworkContent>

              </PersonalHomeworkContent>
            }
          </PersonalHomeworkContainer>
        </>
      }

      <div style={{ width: '100%', height: '1px', background: '#080A0A12' }}></div>

      {
        moduleData.skillTestVideoEnabled && (
          <PersonalHomeworkContainer>
            <PersonalHomeworkHeader>
              <Text fs={20} weight={'bold'} color={theme.palette.text.primary}>{t("general.personal_assignments")}</Text>
              <div style={{
                width: '36px',
                height: '36px',
                background: '#080A0A04',
                borderRadius: '12px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }} onClick={() => setPersonalHomeworkVisible((prevState) => !prevState)}>
                <Icon filename={'down_arrow'}></Icon>
              </div>
            </PersonalHomeworkHeader>

            {personalHomeworkVisible && (
              <PersonalHomeworkContent>
                {state.currentUser.role === "STUDENT" && props?.isOwnCourse && (
                  <CreatePersonalHomework refetch={refetchSkillTestVideos} module={moduleData} groupId={props.groupId} />
                )}

                {
                  skillTests && skillTests.length > 0 && state.currentUser.role === "STUDENT" && props?.isOwnCourse &&
                  <PersonalHomework
                    moduleName={moduleData.name}
                    courseName={props.courseName}
                    moduleId={moduleData.id}
                    assignment={params.assignment}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpenen={isModalOpenen}
                    skillTest={skillTests}
                    refetch={refetchSkillTestVideos}
                  />
                }

                {
                  !props.isStat && skillTests && skillTests.length > 0 && state.currentUser.role === "COACH" &&
                  skillTests.map(skill => {
                    return (
                      <PersonalHomework
                        key={skill.createdBy}
                        moduleName={moduleData.name}
                        courseName={props.courseName}
                        assignment={params.assignment}
                        setIsModalOpened={setIsModalOpened}
                        isModalOpenen={isModalOpenen}
                        skillTest={skill.skillTestVideos}
                        refetch={refetchSkillTestForCoach}>
                      </PersonalHomework>
                    )
                  })
                }

                {/* refetch below needs clarification: refetchSkillTestVideos or refetchSkillTestForCoach */}
                {props.isStat && skillTests &&
                  <PersonalHomework
                    moduleName={moduleData.name}
                    courseName={props.courseName}
                    moduleId={moduleData.id}
                    assignment={params.assignment}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpenen={isModalOpenen}
                    skillTest={skillTests}
                    refetch={refetchSkillTestVideos}>
                  </PersonalHomework>
                }

              </PersonalHomeworkContent>
            )}
          </PersonalHomeworkContainer>
        )
      }
    </S.Container >
  )
}

export default Module


const PersonalHomeworkContainer = styled.div`
  width: 100%;
`

const PersonalHomeworkHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`

const PersonalHomeworkContent = styled.div`
  width: 100%;
  padding: 0 20px;
`
