import React from "react";

const ErrorPage = () => {
  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        background: "#fff",
        top: 0,
        left: 0,
        zIndex: 9999
      }}
    >
      <h1>Error page</h1>
    </div>
  );
};

export default ErrorPage;
