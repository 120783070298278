import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import BackspaceRoundedIcon from '@material-ui/icons/BackspaceRounded'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import GetAppIcon from '@material-ui/icons/GetApp'
import LockIcon from '@material-ui/icons/Lock'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import PublicIcon from '@material-ui/icons/Public'
import SchoolIcon from '@material-ui/icons/School'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import VpnLockIcon from '@material-ui/icons/VpnLock'
import Checkbox from 'components/common/Checkbox'
import { mainColor } from 'components/common/Colors/CommonColors'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import { CapitalPlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'
import moment from 'moment'
import { CheckBoxAndAvatarContainer } from 'pages/companyWizard/CompanyStudents/gridConfig'
import React from 'react'
import styled from 'styled-components'
import strCutter from 'utils/strCutter'
import AvatarIcon from '../../../../components/common/AvatarInput/profile.svg'
import SendIcon from '@material-ui/icons/Send'
import { GlobalUserDefaultImage } from 'utils/utils'

interface itemProps {
  id: string
  isChecked: boolean
  firstName: string
  lastName: string
  email: string
  status: string
  phone: string
  gender: string
  birthDate: string
  age: string
  biography: string
  note: string
  group: any
  isQualified: boolean
  companyId: any
  courses: any
  phoneFields: any
  createDate: any
  avatar: string
}

export const actionConfig = (
  handleClick: any,
  permission: any,
  currentUser: any,
  createBtn?: boolean,
  currentTab?: number,
  t?: any,
  isSuperAdmin?: boolean,
  isAdmin?: boolean,
  exploreWenrollCoursesMode?: boolean,
) => {
  const viewCourse = {
    render: (item: any) => (
      <Action
        onClick={() =>
          handleClick(
            'viewCourse',
            item.isBought && !currentUser.companyId ? item.originalId : item.id,
          )
        }
      >
        <ViewCompactIcon />
        <Typography>{t('actions.view')}</Typography>
      </Action>
    ),
  }

  const mediaActions = [
    {
      render: (item: any) => {
        return (
          <Action onClick={() => handleClick('palyIntro', item)}>
            <PlayCircleFilledIcon
              color={item.video ? 'secondary' : 'disabled'}
            />
            <Typography>{t('actions.play_intro')}</Typography>
          </Action>
        )
      },
    },
  ]

  const editCourse = {
    hide: !permission.editCourse,
    render: (item: any) => (
      <Action
        onClick={() =>
          handleClick('editCourse', item.isBought ? item : item.id)
        }
      >
        <EditRoundedIcon />
        <Typography>{t('actions.edit')}</Typography>
      </Action>
    ),
  }

  const deleteCourse = {
    hide: !permission.deleteCourse,
    render: (item: any) => (
      <Action onClick={() => handleClick('deleteCourse', item.id)}>
        <DeleteForeverRoundedIcon />
        <Typography>{t('actions.delete')}</Typography>
      </Action>
    ),
  }

  const remove = {
    hide: exploreWenrollCoursesMode || !permission.deleteCourse,
    render: (item: any) => (
      <Action onClick={() => handleClick('deleteCourse', item.id)}>
        <DeleteForeverRoundedIcon />
        <Typography>{t('actions.delete')}</Typography>
      </Action>
    ),
  }

  let publishUnpublish = {
    hide: !permission.publishCourse,
    render: (item: any) => {
      return (
        <Action
          disabled={item.finished !== 3}
          onClick={() => {
            if (item.finished === 3) {
              handleClick(
                'publishedUnPublished',
                item.id,
                item.state === 'unpublished' ? 'published' : 'unpublished',
              )
            }
          }}
        >
          {item.state === 'unpublished' ? <PublicIcon /> : <VpnLockIcon />}
          {/* <PublicIcon /> */}
          <Typography>
            {item.state === 'unpublished'
              ? t('actions.publish')
              : t('actions.unpublish')}
          </Typography>
          {/* <Typography>Publish / Unpublish</Typography> */}
        </Action>
      )
    },
  }

  const menage = {
    hide: exploreWenrollCoursesMode,
    render: (item: any) => (
      <Action onClick={() => handleClick('editCourse', item.originalId)}>
        <EditRoundedIcon />
        <Typography>{t('actions.edit')}</Typography>
      </Action>
    ),
  }

  if ((isSuperAdmin || isAdmin) && currentTab === 0) {
    return [
      viewCourse,
      menage,
      publishUnpublish,
      remove,
      ...mediaActions,
    ].filter((i: any) => !i.hide)
  }

  if (currentTab === 0) {
    return [viewCourse, menage, ...mediaActions, remove].filter(
      (i: any) => !i.hide,
    )
  }

  return [
    viewCourse,
    editCourse,
    publishUnpublish,
    ...mediaActions,
    deleteCourse,
  ].filter((i: any) => !i.hide)
}

export const purchasedCourseActionConfig = (
  handleClick: any,
  currentUser: any,
  t?: any,
) => {
  const viewCourse = {
    render: (item: any) => (
      <Action
        onClick={() =>
          handleClick(
            'viewCourse',
            item.isBought && !currentUser.companyId ? item.originalId : item.id,
          )
        }
      >
        <ViewCompactIcon />
        <Typography>{t('actions.view')}</Typography>
      </Action>
    ),
  }

  const playIntro = {
    render: (item: any) => {
      return (
        <Action onClick={() => handleClick('palyIntro', item)}>
          <PlayCircleFilledIcon color={item.video ? 'secondary' : 'disabled'} />
          <Typography>{t('actions.play_intro')}</Typography>
        </Action>
      )
    },
  }

  const remove = {
    render: (item: any) => (
      <Action onClick={() => handleClick('deleteCourse', item.id)}>
        <DeleteForeverRoundedIcon />
        <Typography>{t('actions.delete')}</Typography>
      </Action>
    ),
  }

  return [viewCourse, playIntro, remove]
}

export const userActionConfig = (
  handleEditClick: any,
  handleDeleteClick: any,
  handleResendPasswordClick: any,
  handleChangeStatusClick: any,
  handleChangeQualificationClick?: any,
  permissions?: any,
  field?: any,
  userRole?: string,
  t?: any,
  setResetPasswordOpen?: React.Dispatch<React.SetStateAction<boolean>>,
  setUserId?: React.Dispatch<React.SetStateAction<string>>,
  currentUserId?: string,
) => {
  let action = [
    {
      // hide: !permissions[`edit${field}`],
      render: (item: any) => (
        <Action onClick={() => handleEditClick(item.id)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      // hide: !permissions[`delete${field}`],
      render: (item: any) => (
        <>
          {item.id !== currentUserId && (
            <Action onClick={() => handleDeleteClick(item)}>
              <DeleteForeverRoundedIcon />
              <span>{t('actions.delete')}</span>
            </Action>
          )}
        </>
      ),
    },
    {
      // hide: !permissions.resendUserPassword,
      admin: !!(field === 'Admin' && userRole === 'ADMIN'),
      render: (item: any) => (
        <Action onClick={() => handleResendPasswordClick(item.id)}>
          <SendIcon />
          <span>{t('actions.resend_password')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.resetPassword,
      render: (item: any) => (
        <Action
          onClick={() => {
            if (setResetPasswordOpen) {
              setResetPasswordOpen(true)
            }
            if (setUserId) setUserId(item.id)
          }}
        >
          <LockIcon />
          <span>{t('actions.reset_password')}</span>
        </Action>
      ),
    },
    {
      // hide: !permissions.activateUser,
      render: (item: any) => (
        <>
          {item.id !== currentUserId && (
            <Action
              onClick={() =>
                handleChangeStatusClick(
                  [item.id],
                  item.status === 'ACTIVE' ? 'suspend' : 'active',
                )
              }
            >
              {item.status === 'ACTIVE' ? (
                <>
                  <BackspaceRoundedIcon />
                  <span>{t('actions.suspend')}</span>
                </>
              ) : (
                <>
                  <CheckCircleIcon />
                  <span>{t('actions.activate')}</span>
                </>
              )}
            </Action>
          )}
        </>
      ),
    },
  ].filter((i: any) => !i.admin)

  return action.filter((i: any) => !i.hide)
}

export const columnConfig = (
  role: string,
  selectItem: any,
  onGroupClick?: any,
  t?: any,
  assignCoaches?: boolean,
  isSuperAdmin?: boolean,
  isAdmin?: boolean,
) => {
  let grid = [
    !(assignCoaches && (isSuperAdmin || isAdmin))
      ? {
        id: 'isChecked',
        render: (item: itemProps) => (
          <CheckBoxAndAvatarContainer>
            <Checkbox
              checked={item.isChecked}
              onChange={() => selectItem(item.id)}
            />
            <img src={item.avatar || GlobalUserDefaultImage} alt="Avatar" />
          </CheckBoxAndAvatarContainer>
        ),
        numeric: false,
        disablePadding: true,
      }
      : {
        render: (item: itemProps) => (
          <CheckBoxAndAvatarContainer>
            <img
              src={item.avatar || GlobalUserDefaultImage}
              style={{ marginLeft: '16px' }}
              alt="Avatar"
            />
          </CheckBoxAndAvatarContainer>
        ),
      },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: t('user_details.first_name'),
      render: (item: itemProps) => <div>{item.firstName}</div>,
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: t('user_details.last_name'),
      render: (item: itemProps) => <div>{item.lastName}</div>,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('user_details.email'),
      render: (item: itemProps) => <div>{item.email}</div>,
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: t('user_details.phone'),
      render: (item: itemProps) => (
        <div>
          {item.phoneFields &&
            item.phoneFields.code && //code is something like +995
            `(${item.phoneFields.code})`}
          {item.phone}
        </div>
      ),
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: t('general.status'),
      render: (item: itemProps) => (
        <StatusWrapper className={`${item.status.toLowerCase()}`}>
          <StatusCircle />
          {item.status}
        </StatusWrapper>
      ),
    },
  ]

  if (role === 'STUDENT' || role === 'ADMIN') {
    grid = [
      ...grid,
      {
        id: 'group',
        numeric: false,
        disablePadding: false,
        label: t('general.groups'),
        render: (item: itemProps) => {
          const firstThreeGroups = item.group.slice(0, 3)
          const exGroups = item.group.slice(3, item.group.length)
          return (
            <div>
              {firstThreeGroups.length === 0 && (
                <NoValueWrapper>N/A</NoValueWrapper>
              )}
              <GroupsWrapper>
                {firstThreeGroups.map(
                  (group: any, index: number) =>
                    index < 4 && (
                      <GroupItem key={group.groupId}>
                        <IconButton onClick={() => onGroupClick(group.groupId)}>
                          <img src="/img/threegreyhead.svg" alt="" />
                        </IconButton>
                        <GroupTitle className="group-item-title">
                          {group.name}
                        </GroupTitle>
                      </GroupItem>
                    ),
                )}

                {exGroups.length > 0 && (
                  <Tooltip
                    title={
                      <>
                        {exGroups.map((exGroup: any) => (
                          <div>{exGroup.name}</div>
                        ))}
                      </>
                    }
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        cursor: 'pointer',
                        marginLeft: 5,
                      }}
                    >
                      ...
                    </span>
                  </Tooltip>
                )}
              </GroupsWrapper>
            </div>
          )
        },
      },
    ]
  }

  if (role === 'COACH' || role === 'STUDENT') {
    grid = [
      ...grid,
      {
        id: 'courses',
        numeric: false,
        disablePadding: false,
        label: t('general.courses'),
        render: (item: itemProps) => {
          const courses = (item.courses && item.courses) || []
          const firstThreeCourses = courses.slice(0, 3)
          const exCourse = courses.slice(3, courses.length)
          return (
            <div>
              {firstThreeCourses.length === 0 && (
                <NoValueWrapper>N/A</NoValueWrapper>
              )}
              <GroupsWrapper>
                {firstThreeCourses.map(
                  (course: any, index: number) =>
                    index < 4 && (
                      <GroupItem key={course.courseId}>
                        <IconButton
                          onClick={() => onGroupClick(course.courseId)}
                        >
                          <SchoolIcon fontSize="small" />
                        </IconButton>
                        <GroupTitle className="group-item-title">
                          {course.name}
                        </GroupTitle>
                      </GroupItem>
                    ),
                )}

                {exCourse.length > 0 && (
                  <Tooltip
                    title={
                      <>
                        {exCourse.map((exGroup: any) => (
                          <div>{exGroup.name}</div>
                        ))}
                      </>
                    }
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        cursor: 'pointer',
                        marginLeft: 5,
                      }}
                    >
                      ...
                    </span>
                  </Tooltip>
                )}
              </GroupsWrapper>
            </div>
          )
        },
      },
    ]
  }

  grid = [
    ...grid,
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: itemProps) => (
        <div>
          {item.companyId ? (
            strCutter(item.companyId.name, 25)
          ) : (
            <>
              {CapitalPlatformTitle}
              <StarsRoundedIcon
                style={{
                  color: mainColor,
                  fontSize: 10,
                  verticalAlign: 'super',
                }}
              />
            </>
          )}
        </div>
      ),
    },
  ]
  grid = [
    ...grid,
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: itemProps) => {
        return (
          <StatusWrapper>
            <StatusCircle />
            {moment(parseInt(item.createDate)).format('DD.MM.YYYY')}
          </StatusWrapper>
        )
      },
    },
  ]

  return grid
}

const Action = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  opacity: ${({ disabled }: any) => (disabled ? '0.5' : '1')};
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`
const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 0 20px 0 0;
  text-transform: capitalize;
`

const StatusCircle = styled.div`
  margin-right: 5px;
  margin-top: -3px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
`

const GroupsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const GroupItem = styled.div`
  position: relative;
  &:hover > span {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
`

const GroupTitle = styled.span`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  text-align: center;
  padding: 5px 8px;
  bottom: -35px;
  left: 50%;
  color: #333;
  font-size: 12px;
  transform: translateX(-50%) scale(0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.5px 12px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease, transform 0.2s ease;
`

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  &.active {
    color: ${mainColor};

    div {
      background: ${mainColor};
    }
  }

  &.suspended {
    color: red;

    div {
      background: red;
    }
  }

  &.pending {
    color: rgb(207, 207, 9);

    div {
      background: rgb(207, 207, 9);
    }
  }

  &.draft {
    color: rgba(0, 0, 0, 0.5);

    div {
      background: rgba(0, 0, 0, 0.5);
    }
  }
`