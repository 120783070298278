import Typography from '@material-ui/core/Typography'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import Checkbox from 'components/common/Checkbox'
import { mainColor } from 'components/common/Colors/CommonColors'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import strCutter from 'utils/strCutter'
import AvatarIcon from '../../../components/common/AvatarInput/profile.svg'
import {
  CheckBoxAndAvatarContainer,
  StatusCircle,
  StatusWrapper,
} from '../CompanyStudents/gridConfig'
import { CapitalPlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'

interface itemProps {
  id: string
  isChecked: boolean
  firstName: string
  lastName: string
  email: string
  phone: string
  phoneFields: {
    dialCode: string
    code: string
  }
  location: string
  gender: string
  jobTitle: string
  createDate: any
  avatar: string
  companyId: any
  status: any
}

export const columnConfig = (selectItem: any, checkbox?: boolean, t?: any) => {
  let grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <CheckBoxAndAvatarContainer>
          <div style={{ visibility: !checkbox ? 'hidden' : 'visible' }}>
            <Checkbox
              checked={item.isChecked}
              onChange={() => selectItem(item.id)}
            />
          </div>
          <img src={item.avatar || AvatarIcon} alt="" style={{ objectFit: item.avatar ? 'cover' : 'contain' }} />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: t('user_details.first_name'),
      render: (item: itemProps) => (
        <div style={{ cursor: 'pointer' }}>{item.firstName}</div>
      ),
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: t('user_details.last_name'),
      render: (item: itemProps) => <div>{item.lastName}</div>,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('user_details.email'),
      render: (item: itemProps) => <div>{item.email}</div>,
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: t('user_details.phone'),
      render: (item: itemProps) => {
        return (
          <div>
            {item.phoneFields &&
              item.phoneFields.code &&
              `(${item.phoneFields.code})`}
            {item.phone}
          </div>
        )
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: t('general.status'),
      render: (item: itemProps) => {
        return (
          <StatusWrapper className={`${item.status.toLowerCase()}`}>
            <StatusCircle />
            {item.status}
          </StatusWrapper>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: itemProps) => (
        <div>
          {item.companyId ? (
            strCutter(item.companyId.name, 25)
          ) : (
            <>
              {CapitalPlatformTitle}
              <StarsRoundedIcon
                style={{
                  color: mainColor,
                  fontSize: 10,
                  verticalAlign: 'super',
                }}
              />
            </>
          )}
        </div>
      ),
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: itemProps) => {
        return (
          <StatusWrapper>
            <StatusCircle />
            {moment(parseInt(item.createDate)).format('DD.MM.YYYY')}
          </StatusWrapper>
        )
      },
    },
  ]

  return grid
}

export const actionConfig = (handleClick: any, t: any) => {
  const actions: any = [
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('edit', item)}>
          <EditRoundedIcon />
          <Typography>{t('actions.edit')}</Typography>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('delete', item.id)}>
          <DeleteForeverRoundedIcon />
          <Typography>{t('actions.delete')}</Typography>
        </Action>
      ),
    },
  ]
  return actions
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
`
