import React, { useState } from 'react'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useFetchFlashCards } from 'hooks/modules/useModules'
import useDeleteFlashCardsService from 'hooks/modules/useDeleteFlashCardsService'
import { FlashCard } from './types'
import { actionConfig } from './actionConfig'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'

import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import IconButton from '@material-ui/core/IconButton'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckBox from 'components/common/Checkbox'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import FlashCardItem from './FlashCardItem'
import Modal from 'components/common/Modal'
import AudioPlayer from 'components/common/AudioPlayer'

import { Wrapper, FlashCarGrid } from './styled-components'
import { mainColor } from 'components/common/Colors/CommonColors'
import {
  ActionButton,
  GridLayoutActions,
  GridLayoutForm,
  GridToolbar,
} from 'components/common/Grid/styled-components'
import { FormControlLabel, useTheme } from '@material-ui/core'
import Checkbox from 'components/common/Checkbox'
import Icon from 'components/Icon'
import styled from 'styled-components'

interface Params {
  module: any
  companyCourse?: boolean
}

const FlashCardsLayout = ({ module, companyCourse }: Params) => {
  const { t } = useTranslation()
  const { flashCards, error, loading, refetch }: any = useFetchFlashCards(
    module.id,
  )

  const {
    deleteFlashCards,
    deleteFlashCardsLoading,
  }: any = useDeleteFlashCardsService()
  const { fireSwal } = useSwal()

  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<any>([])
  const [selectAll, setSelectAll] = useState<any>(false)
  const [audioModalOpened, setAudioModalOpened] = useState<boolean>(false)
  const [currentAudio, setCurrentAudio] = useState<any>('')
  const theme = useTheme()


  const selectItem = (id: never, event: boolean) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    if (selectedItem.length === flashCards.length) {
      setSelectAll(true)
    }

    setSelectedItem(newSelected)
  }

  const selectAllItem = () => {
    if (!selectAll) {
      if (selectedItem.length === filteredFlashCards.length) {
        setSelectedItem([])
        setSelectAll(false)
      } else {
        const newArr: string[] = filteredFlashCards.map((n: any) => n.id)
        setSelectedItem(newArr)
        setSelectAll(true)
      }

      return
    }

    setSelectedItem([])
    setSelectAll(false)
  }

  const handleSearchChange = (e: any) => setSearchValue(e.target.value)

  const handleCreateFlashCardClick = () =>
    DrawerEventEmitter.emit('openDrawer', 'addFlashCard', true, {
      data: {
        module,
      },
    })

  const handelOpenEditDrawer = (id: string) => {
    DrawerEventEmitter.emit('openDrawer', 'editFlashCard', true, {
      data: {
        id,
        moduleId: module.id,
        refetch: () => refetch(),
      },
    })
  }

  const handleDeleteClick = (ids: string[]) => {
    let title: string =
      ids.length > 1
        ? t('popups.delete_flashcard_many')
        : t('popups.delete_flashcard_single')

    fireSwal({
      title,
      onConfirm: () => {
        deleteFlashCards(ids, () => {
          setSelectedItem([])
          setSelectAll(false)
          refetch()
        })
      },
      confirmText: t('popups.confirm_delete'),
    })
  }

  const handleAudioClick = (audio: any) => {
    setAudioModalOpened(true)
    setCurrentAudio(audio)
  }

  const actionHandler = (event: string, field: any, action: string) => {
    switch (event) {
      case 'edit':
        handelOpenEditDrawer(field.id)
        break
      case 'delete':
        handleDeleteClick([field.id])
        break
      default:
        return () => console.log('null')
    }
  }

  const actions = actionConfig(actionHandler, t)

  if (loading) {
    return <div>{t('general.loading')}...</div>
  }

  const filteredFlashCards = flashCards.filter((flashCard: FlashCard) =>
    flashCard.question.includes(searchValue),
  )



  return (
    <div>
      {/* <Wrapper>
        <Wrapper>
          {companyCourse ? null : (
            <Button
              text={t('flashcards_layout.create_flashcard')}
              size="small"
              color="secondary"
              onClick={() => handleCreateFlashCardClick()}
              icon={<AddCircleRoundedIcon />}
              background={mainColor}
            />
          )}
          <Wrapper>
            <GridMultipleActions
              selectedItems={selectedItem.length}
              actions={
                companyCourse
                  ? []
                  : [
                      {
                        id: 0,
                        color: 'secondary',
                        tooltipText: t('actions.delete'),
                        disabled: selectedItem.length < 1,
                        onClick: () => handleDeleteClick(selectedItem),
                        component: <DeleteIcon fontSize="small" />,
                      },
                    ].filter((i: any) => !i.hide)
              }
              isNumberOfSelectedItemsHidden={companyCourse}
            />
            {!companyCourse && (
              <Wrapper>
                <CheckBox
                  onChange={selectAllItem}
                  checked={
                    filteredFlashCards.length > 0 &&
                    filteredFlashCards.length === selectedItem.length
                  }
                />
                {filteredFlashCards.length === selectedItem.length
                  ? t('general.diselect_all')
                  : t('general.select_all')}
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
        <form style={{ marginLeft: 10 }}>
          <TextInput
            label={t('general.search_placeholder')}
            type="text"
            size="small"
            value={searchValue}
            onChange={handleSearchChange}
            icon={
              <IconButton type="submit">
                <SearchRoundedIcon />
              </IconButton>
            }
          />
        </form>
      </Wrapper> */}
      <GridToolbar style={{ borderBottom: 'none' }} theme={theme}>
        <GridLayoutActions>
          <div>
            {!companyCourse && (
              <AddButton onClick={() => handleCreateFlashCardClick()}>
                <Icon filename={'add'} fill={'#ffffff'} />
                {t('flashcards_layout.create_flashcard')}
              </AddButton>
            )}
          </div>
          {!companyCourse && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedItem.length > 0}
                  disabled={selectedItem.length === 0}
                  onChange={selectAllItem}
                />
              }
              label={
                filteredFlashCards.length === selectedItem.length
                  ? t('general.diselect_all')
                  : t('general.select_all')
              }
            />
          )}
        </GridLayoutActions>
        <GridLayoutForm>
          {`${selectedItem.length} ${t('general.items_selected')}`}
          <ActionButton
            onClick={() => handleDeleteClick(selectedItem)}
            disabled={selectedItem.length === 0}
            fill={'#CC3131'}
            disabledFill={'#080A0AD6'}
            theme={theme}
          >
            <Icon filename={'delete'} />
          </ActionButton>
        </GridLayoutForm>
      </GridToolbar>
      <FlashCarGrid>
        {filteredFlashCards.map((flashCard: FlashCard) => (
          <FlashCardItem
            key={flashCard.id}
            flashCard={flashCard}
            actions={companyCourse ? [] : actions}
            selectedItems={selectedItem}
            selectItem={selectItem}
            handleAudioClick={handleAudioClick}
            companyCourse={companyCourse}
          />
        ))}
      </FlashCarGrid>
      <Modal
        isOpened={audioModalOpened}
        onClose={() => setAudioModalOpened(false)}
      >
        <AudioPlayer url={currentAudio && currentAudio} />
      </Modal>
    </div>
  )
}

export default FlashCardsLayout

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #317bf4;
  padding: 18px 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 30px;

  &:hover {
    transition: 0.3s;
    background: #3165f4;
  }
`
