import styled from 'styled-components'

export const ToolbarBox = styled.div`
  border-top: 1px solid #5290F6;
  border-left: 1px solid #5290F6;
  border-right: 1px solid #5290F6;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

export const TextEditor = styled.div`
  position: 'relative';

  div.DraftEditor-root {
    border: ${props => props.biography && props.isVisible ? "1px solid #5290F6" : 'null'};
    border-bottom-left-radius:   8px;
    border-bottom-right-radius: 8px;
    height: ${props => props.biography && props.scrollViewVisible ? "477px" : '0px'};
    width: 100%;
    overflow-y: auto;
     color: black ;
  }

  div.public-DraftEditor-content,
  div.DraftEditor-editorContainer1 {
    height: 100%;
  }
`
