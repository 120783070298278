import { useQuery } from '@apollo/react-hooks'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { Button } from 'components/common/Button'
import DatePicker from 'components/common/DatePicker'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import {
  CloseIcon,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Info,
} from 'components/DrawerLayout/styled-components'
import { genderOptions } from 'components/UsersLayout/staticData'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { useFormik } from 'formik'
import { GET_USER } from 'gql/users.query'
import { DrawerEventEmitter } from 'helpers/drawer'
import { UserSchema } from 'helpers/validationSchemas'
import useEditUserService from 'hooks/users/useEditUserService'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SET_FORMDATA } from 'store/types'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import GenderPicker from 'components/common/GenderPicker'
import { useTheme } from '@material-ui/core'

interface IUserEdit {
  id: string
  role: string
  drawerName: string
}

const UserEdit = ({ id, role, drawerName }: IUserEdit) => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const [dataState, dispatchData] = useData()
  const [currentModerator, setCurrentModerator] = useState<any>('')
  const [generalError, setGeneralError] = useState('')
  const { data, loading } = useQuery(GET_USER, {
    variables: { id: id },
  })
  const { updateUser, user, error, editUserLoading } = useEditUserService({
    role: role,
    companyId: state.selectedCompanyId,
  })

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      jobTitle: '',
      location: '',
      gender: '',
      phoneFields: null as any,
      phoneFieldValue: null,
      birthDate: null as any,
      biography: '',
      note: '',
      avatar: '',
    },
    validationSchema: UserSchema,
    onSubmit(values) {
      const formValues: any = { ...values }
      delete formValues.phoneFieldValue
      formValues.gender = formValues.gender ? formValues.gender : ''
      if (formValues.phoneFields && formValues.phoneFields.value) {
        formValues.phoneFields = {
          dialCode: formValues.phoneFields.value,
          code: formValues.phoneFields.label,
        }
      } else {
        formValues.phoneFields = null
      }
      updateUser(id, formValues)
    },
  })

  const closeDrawer = () => {
    DrawerEventEmitter.emit('openDrawer', 'userAdd', false)
  }

  const handleCloseDrawer = () => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editModerator',
        values,
        compareTo: currentModerator,
      },
    })
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    setGeneralError('')
  }, [values.email])

  useEffect(() => {
    if (user) {
      closeDrawer()
    }
  }, [user])

  useEffect(() => {
    if (error) {
      setGeneralError(error)
    }
  }, [error])

  useEffect(() => {

    if (data) {
      const {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        location,
        gender,
        phoneFields,
        birthDate,
        biography,
        note,
        avatar,
      } = data.user
      const formData: any = {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        location,
        gender,
        phoneFields: phoneFields.dialCode
          ? { label: phoneFields.code, value: phoneFields.dialCode }
          : null,
        phoneFieldValue: phoneFields?.dialcode || null,
        biography,
        note,
        birthDate: birthDate ? moment(parseInt(birthDate)).format() : null,
        avatar,
      }
      setValues({
        ...formData,
      })
      setCurrentModerator(formData)
    }
  }, [data])

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  const [currentDate, setCurrentDate] = useState<Date | null>(null)

  const onDateChange = (date: Date | null) => {
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  useEffect(() => {
    if (values.birthDate) {
      setCurrentDate(
        new Date(
          typeof (values.birthDate === 'string')
            ? values.birthDate
            : +values.birthDate,
        ),
      )
    }
  }, [values.birthDate])

  const theme = useTheme()

  return (
    <>
      {editUserLoading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>
          {t('actions.edit')} {t('general.super_admin')}
        </DrawerTitle>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width="48%">
                <TextInput
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  label={`${t('user_details.first_name')}*`}
                  size="medium"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  label={`${t('user_details.last_name')}*`}
                  size="medium"
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.email || generalError ? true : false}
                  errorMessage={
                    errors.email || generalError
                      ? errors.email || generalError
                      : ''
                  }
                  touched={touched.email}
                  label={`${t('user_details.email')}*`}
                  size="medium"
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <PhoneCountryCode
                  label={`${t('user_details.phone')}`}
                  selectLabel={t('form_fields.dial_code')}
                  selectValue={values.phoneFields}
                  selectChange={(e: any) => setFieldValue('phoneFields', e)}
                  inputLabel="Number"
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={
                    errors.phone || errors.phoneFields || generalError
                      ? true
                      : false
                  }
                  errorMessage={
                    errors.phone || errors.phoneFields || generalError
                      ? errors.phone || errors.phoneFields || generalError
                      : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>
              <FormField width="100%">
                <GenderPicker
                  checked={values?.gender}
                  onChange={(e: any) => setFieldValue('gender', e)}
                />
              </FormField>

              <FormField width="48%">
                <DatePicker
                  label={`${t('user_details.birthday')}`}
                  name="birthDate"
                  value={currentDate}
                  onChange={onDateChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  label={t('form_fields.job_title')}
                  size="medium"
                  name="jobTitle"
                  type="text"
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  label={t('form_fields.location')}
                  size="medium"
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  label={t('user_details.biography')}
                  size="medium"
                  name="biography"
                  type="text"
                  value={values.biography}
                  onChange={handleChange}
                  multiline
                  rows="3"
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  label={t('user_details.note')}
                  size="medium"
                  name="note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  multiline
                  rows="3"
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.save')}
                type="medium"
                color="secondary"
                btnType="submit"
                background={mainColor}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default UserEdit
