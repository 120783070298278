import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import ReactSelect from 'react-select'
import styled from 'styled-components'
import { useFormik } from 'formik'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
  FormGroupItem,
} from 'components/common/Form'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { filterDefault, filterOptions } from 'pages/quizzes/staticData'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { useTheme } from '@material-ui/core'

export const ALL_TAGS = gql`
  query {
    getAllTags {
      id
      label
    }
  }
`

type valueType = {
  value: string
  label: string
}

type quizzesFilterType = {
  name: string
  quizType: valueType
  quizParticipationType: valueType
  timeType: valueType
  tags: valueType[]
  range: any
  passRate: any
}

const FilterDrawer = (props: any) => {
  const [filterData, setFilterData] = useState<quizzesFilterType>(filterOptions)
  const [range, setRange] = useState([0, 300])
  const [passRate, setPassRate] = useState([0, 100])
  const { data: tagsData } = useQuery(ALL_TAGS)

  const tagList =
    (tagsData &&
      tagsData.getAllTags.map((i: any) => ({ label: i.label, value: i.id }))) ||
    []

  useEffect(() => {
    if (props?.filterData?.values) {
      setFilterData(props.filterData.values)
      setRange(props.filterData.values.range)
      setPassRate(props.filterData.values.passRate)
    }
  }, [props.filterData])

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: props.filterData.values || filterData,
    onSubmit(values) {
      const formValues: any = {}

      if (values.name)
        formValues.name = {
          type: 'MATCH',
          value: values.name,
        }
      else delete formValues.name

      if (values.quizParticipationType.value.toUpperCase() !== 'ALL') {
        formValues.quizParticipationType = {
          type: 'EXACT',
          value: values.quizParticipationType.value.toUpperCase(),
        }
      } else delete formValues.quizParticipationType

      if (values.timeType.value.toUpperCase() !== 'ALL')
        formValues.timeType = {
          type: 'EXACT',
          value: values.timeType.value.toUpperCase(),
        }
      else delete formValues.timeType

      if (values.quizType.value.toUpperCase() !== 'ALL') {
        formValues.quizType = {
          type: 'EXACT',
          value: values.quizType.value.toUpperCase(),
        }
      } else delete formValues.quizType

      if (values.tags)
        formValues.tags = {
          type: 'NESTED_ARRAY_IN',
          value: values.tags.map((tag: any) => tag.label),
          nestedField: 'LABEL',
        }
      else delete formValues.tags

      if (
        (range[0] === 0 && range[1] === 0) ||
        (range[0] === 0 && range[1] === 300)
      )
        delete formValues.time
      else
        formValues.time = {
          type: 'RANGE',
          min: range[0],
          max: range[1],
        }

      formValues.passRate = {
        type: 'RANGE',
        min: passRate[0],
        max: passRate[1],
      }

      props.setFilterData({
        filterOptions: {
          ...formValues,
        },
        values: { ...values, range, passRate },
      })

      props.setCurrentPage(1)

      props.onClose()
    },
  })

  const { t } = props

  const theme = useTheme()

  return (
    <>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{t('quizzes_layout.filter_quizzes')}</DrawerTitle>
        <IconButton onClick={props.onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>

      <br />

      <DrawerContent margin={'100px'}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormGroupItem>
              <Input
                label={t('form_fields.name')}
                name="name"
                size="small"
                type="text"
                fullWidth
                value={values.name}
                onChange={handleChange}
              />
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('quiz_details.entity_type')}</Label>
                <ReactSelect
                  options={filterDefault.quizType}
                  value={values.quizType}
                  onChange={(e: any) => setFieldValue('quizType', e)}
                  name="quizType"
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>
                  {t('general.quiz')} {t('quiz_details.participation')}{' '}
                  {t('general.type')}
                </Label>
                <ReactSelect
                  name="quizParticipationType"
                  onChange={(e: any) =>
                    setFieldValue('quizParticipationType', e)
                  }
                  options={filterDefault.quizParticipationType}
                  value={values.quizParticipationType}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('quiz_details.time_type')}</Label>
                <ReactSelect
                  name="timeType"
                  onChange={(e: any) => setFieldValue('timeType', e)}
                  options={filterDefault.timeType}
                  value={values.timeType}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Label>{t('general.tags')}</Label>
                <ReactSelect
                  isMulti
                  onChange={(e: any) => setFieldValue('tags', e)}
                  name="tags"
                  options={tagList}
                  value={values.tags}
                  placeholder={t('form_fields.select')}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <Typography id="range-slider" gutterBottom>
                {t('quiz_details.time_in_minutes')}
              </Typography>
              <Slider
                value={range}
                onChange={(e: any, i: any) => setRange(i)}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={0}
                max={300}
              />
            </FormGroupItem>

            <FormGroupItem>
              <Typography id="range-slider" gutterBottom>
                {t('quiz_details.pass_rate')}
              </Typography>
              <Slider
                value={passRate}
                onChange={(e: any, i: any) => setPassRate(i)}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={0}
                max={100}
              />
            </FormGroupItem>

            {/* <FormGroupItem>
              <Typography id="range-slider" gutterBottom>
                Questions
              </Typography>
              <Slider
                value={questions}
                onChange={(e: any, i: any) => setQuestions(i)}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={0}
                max={60}
              />
            </FormGroupItem> */}
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={() => {
                  props.setFilterData({
                    filterOptions: null,
                    values: null,
                  })
                  props.onClose()
                }}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.filter')}
                type="medium"
                color="secondary"
                btnType="submit"
                background={mainColor}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterDrawer

const FormItemContainer = styled.div`
  position: relative;
`
const Label = styled.div`
  position: absolute;
  background: #fff;
  color: rgba(0, 0, 0, 0.54);
  z-index: 1;
  line-height: 1;
  top: -7px;
  font-size: 13px;
  left: 10px;
`
