import React, { useState, useEffect } from 'react'
import ReactCountryFlag from 'react-country-flag'
import styled from 'styled-components'
import countryList from './countryCode'
import { SelectField } from 'components/common/SelectField'
import ReactSelect, { components } from 'react-select'
import { useTheme } from '@material-ui/core'

const PhoneCountryCode = props => {
  const [value, setValue] = useState(null)

  const {
    label,
    selectLabel,
    selectValue,
    selectChange,
    inputLabel,
    inputValue,
    inputChange,
    touched,
    error,
    errorMessage,
  } = props

  const { Option } = components
  const CustomSelectOption = props => (
    <Option {...props}>
      <ReactCountryFlag countryCode={props.data.value} /> {props.data.label}
    </Option>
  )

  const country = countryList().map(i => ({
    label: i.dial_code,
    value: i.code,
  }))

  const Component = ({ dialCode, code }) => {
    return (
      <div>
        <ReactCountryFlag countryCode={code} /> {dialCode}
      </div>
    )
  }

  useEffect(() => {
    if (selectValue && selectValue.value) {
      setValue({
        label: (
          <Component dialCode={selectValue.label} code={selectValue.value} />
        ),
        value: selectValue.value,
      })
    }
  }, [selectValue]) //can't update state in componentdidmount

  const handleChange = e => {
    if (!e) {
      setValue(null)
      selectChange(null)
      return
    }
    setValue({
      label: <Component dialCode={e.label} code={e.value} />,
      value: e.value,
    })
    selectChange(e)
  }

  const errorField = touched && error && errorMessage
  const theme = useTheme()
  return (
    <Container error={errorField} theme={theme}>
      <Label theme={theme} error={errorField}>{label}</Label>
      <SelectContainer theme={theme}>
        <SelectField
          placeholder={selectLabel}
          options={country}
          value={value}
          onChange={handleChange}
          components={{ Option: CustomSelectOption }}
          isClearable={true}
        />
      </SelectContainer>
      <InputContainer>
        <Input
          name="phone"
          theme={theme}
          placeholder={inputLabel}
          onChange={inputChange}
          value={inputValue}
        // type="number"
        />
      </InputContainer>

      {touched && error && errorMessage && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
    </Container>
  )
}

export default PhoneCountryCode

const Container = styled.div`
  height: 56px;
  border: 1px solid ${({ error, theme }) => (error ? 'red' : theme.palette.background.paper)};
  border-radius: 8px;
  display: grid;
  grid-template-columns: 130px auto;
  position: relative;
  .css-1pahdxg-control {
    border-color: transparent !important;
    box-shadow: 0 0 0 1px transparent !important;
  }
  .css-yk16xz-control {
    border-color: transparent !important;
    box-shadow: 0px !important;
    &:hover {
      border-color: transparent !important;
    }
    &:focus {
      border-color: transparent !important;
    }
    &:active {
      border-color: transparent !important;
    }
  }
  input {
    border-left: none;
  }
`
const SelectContainer = styled.div`
  width: 120px;
  background-color: ${({ theme }) => theme.palette.background.default};
  /* border-right: 1px solid #ebebeb; */
  .react-select__control {
    height: 54px;
  }
`

const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #080a0a14;
  border-radius: 8px;
  &:hover {
      border: 1px solid #5290f6 !important;
  }
  .Mui-focused {
      border: 1px solid #5290f6 !important;
  }
`
const Input = styled.input`
  width: 100%;
  outline: none;
  border: none;
  /* border-left: 1px solid hsl(0, 0%, 80%); */
  padding: 2.5px 15px;
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme }) => theme.palette.background.default};
  font-size: 15px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
const Label = styled.div`
  position: absolute;
  left: 12px;
  top: -11px;
  background: ${({ theme }) => theme.palette.background.default};
  z-index: 1;
  font-size: 13px;
  color: ${({ error, theme }) => (error ? 'red' : theme.palette.text.primary)};
`
const ErrorMessage = styled.div`
  position: absolute;
  color: #f44336;
  bottom: -20px;
  left: 6px;
  font-size: 0.75rem;
`
