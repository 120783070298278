import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import backgroundImage from '../../assets/images/background-image.png';
import { Formik, Form, Field } from 'formik';

import React from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_USER } from 'gql/auth/register.query';
import { LoginSchema } from 'helpers/validationSchemas';
import LandingNavbar from './LandingNavbar';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Checkbox from 'components/common/Checkbox';
import i18n from '../../i18n';
import { setCurrentLanguage } from '../../helpers/localStorage';

import * as S from './styled'

const SignUpPage = () => {
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [error, setError] = useState("")
  const [register, { loading }] = useMutation(CREATE_USER);
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation()

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      privacyPolicyCheckbox: false,
    },



    onSubmit: async (values) => {
      if (values.firstname.toLowerCase().includes("enrol") || values.lastname.toLowerCase().includes("enrol")) {
        setError(t('login.enrol_error'))
        return
      }
      if (values.email !== "" && values.firstname !== "" && values.lastname !== "" && values.privacyPolicyCheckbox) {
        const res = await register({
          variables: {
            input: {
              firstName: formik.values.firstname,
              lastName: formik.values.lastname,
              email: formik.values.email,
            },
            privacyPolicyCheckbox: formik.values.privacyPolicyCheckbox
          }
        })
        if (!res.errors) {
          history.push('/success');
        } else {
          setError(res.errors[0].message)
        }
      }
    }

  })
  useEffect(() => {
    if (location.pathname === "/signup/ka" || location.pathname === "/ka") {

      i18n.changeLanguage("ka")
      setCurrentLanguage("ka")

    } else if (location.pathname === "/signup/en" || location.pathname === "/en") {
      i18n.changeLanguage("en")
      setCurrentLanguage("en")
    }
  }, [])


  return (
    <>
      <LandingNavbar />
      <div className='w-full h-full flex flex-col items-center justify-center'>
        <div className='w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 text-white landing'>
          <StyledContainer className=' bg-black/70 flex flex-col items-center justify-center rounded-xl'>
            <div className='h-full w-full'>
              <div className='text-3xl w-full mt-5 flex items-center text-center justify-center georgianFonts capitalLetters' >{t('login.sign_up')}</div>
              <form className='flex flex-col w-full h-1/7 mt-10 items-center justify-center'>
                <input
                  name='firstname'
                  type='text'
                  placeholder={t('user_details.first_name')}
                  className='w-full h-14 px-4 rounded-2xl bg-[#333333] border-[#505050] placeholder-[#ffffff70] registrationInput'
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                />
                <input
                  type='text'
                  name='lastname'
                  placeholder={t('user_details.last_name')}
                  className='w-full h-14 px-4 mt-5 rounded-2xl bg-[#333333] border-[#505050] placeholder-[#ffffff70] registrationInput'
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                />
                <div className='relative w-full'>
                  <input
                    type='email'
                    name='email'
                    placeholder={t('user_details.email')}
                    className='w-full h-14 px-4 rounded-2xl bg-[#333333] border-[#505050] placeholder-[#ffffff70] mt-5 relative registrationInput'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {error.length > 1 && <div style={{ position: "absolute", color: "#f44336", bottom: "-20px", left: "6px", fontSize: "0.75rem" }}>{error}</div>}
                </div>
                <div style={{ display: 'flex', width: '100wv', alignItems: 'center', marginTop: '15px' }}>
                  <Checkbox margin="0px 5px 0px 0px" width={'0px'} checked={formik.values.privacyPolicyCheckbox} onChange={formik.handleChange} name='privacyPolicyCheckbox' />
                  <span className='w-full text-[14px] font-light'>
                    {t('login.agree')}
                    {' '}
                    <a href='https://test.wenroll.com/terms' className='underline cursor-pointer mr-1 text-[#007DFF] ml-1 underline cursor-pointer'>
                      {t('login.terms_of_service')}
                    </a>
                    {' '}
                    {t('login.end')}
                    {' '}
                    <a href='https://test.wenroll.com/privacy' className='underline cursor-pointer text-[#007DFF] ml-1 underline cursor-pointer'>
                      {t('login.privacy_policy')}
                    </a>
                  </span>
                </div>

                <span className='w-full mt-5'>
                  <div className='flex flex-nowrap rounded-2xl text-white cursor-pointer h-14'>
                    <S.SignUpButton
                      disabled={(formik.values.email === "" || formik.values.firstname === "" || formik.values.lastname === "" || !formik.values.privacyPolicyCheckbox) && true}
                      type='button'
                      className='w-full h-full flex items-center justify-center  georgianFonts capitalLetters'
                      onClick={formik.handleSubmit}>
                      {t('login.sign_up')}
                    </S.SignUpButton>
                  </div>
                </span>
                <div style={{ marginBottom: '15px', fontSize: '16px' }} className='w-full flex mt-5 mb-4 text-sm font-light'>
                  {t('messages.registered_already')}?
                  <span className='text-[#007DFF] ml-2 underline cursor-pointer'>
                    <Link to={'/login'} className='underline cursor-pointer'>
                      {t('login.log_in')}
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </StyledContainer>
        </div>
      </div>

    </>
  );
};

export default SignUpPage;

const StyledContainer = styled.div`
padding: 50px 92px 62px 92px;
max-width: 536px;

@media (max-width: 500px) {
  padding: 50px 45px;
}
`
