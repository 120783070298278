import React, { useState } from 'react'
// import Drawer from '@material-ui/core/Drawer'
import {
  DrawerHeader,
  DrawerTitle,
  CloseIcon,
  Info,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, useTheme } from '@material-ui/core'

import { useParams } from "react-router-dom"
import { FormContainer } from 'components/common/Form'
import Drawer from 'components/common/Drawer'
import styled from 'styled-components'
import EducationItem from '../EducationItem'
import { AddButton } from 'components/common/Grid/styled-components'
import Icon from 'components/Icon'

import { ADD_REMOVE_UPDATE_EXPERIENCE, GET_USER } from 'gql/users.query'
import { useUserValue } from 'context/UserContext'
import { useMutation, useQuery } from '@apollo/react-hooks'
import AddExpAndEduDrawer from './AddUpdateExperience'
import AddUpdateExperience from './AddUpdateExperience'

export type ExperienceListDrawerProps = {
  open: boolean
  id: string
  onClose: () => void
}

const ExperienceListDrawer: React.FC<ExperienceListDrawerProps> = props => {
  const { t } = useTranslation()


  const [isOpen, setIsOpen] = useState(false);

  const { data, refetch } = useQuery(GET_USER, {
    variables: {
      id: props.id,
    }
  })

  const [modifyExpirience, { loading }] = useMutation(ADD_REMOVE_UPDATE_EXPERIENCE);

  const [isEdit, setIsEdit] = useState<string | null>(null);
  const { id: userId } = useParams<{ id: string }>()
  const removeExpirience = (id: string) => {

    modifyExpirience({
      variables: {
        action: 'remove',
        userId,
        experienceId: id,
      }
    })

    refetch()
  }

  const editExpirience = (id: string) => {
    setIsOpen(true)
    setIsEdit(id);
  }

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Drawer
      totalWidth={isSmallScreen ? "970px" : "100%"}
      // responsiveTotalWidth='500px'
      opened={props.open}
      toggleDrawer={() => props.onClose()}
    >
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{t('general.experience')}</DrawerTitle>
        <CloseIcon
          onClick={() => props.onClose()}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <AddButton
          onClick={() => setIsOpen(true)}
          style={{
            width: '275px',
            margin: '30px auto',
            marginBottom: '60px',
          }}
        >
          <Icon filename={'add'} fill={'#ffffff'} />
          {t('general.add_experience')}
        </AddButton>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

          {data.user?.experience?.map((exp: any, index: any) => (
            <EducationItem
              id={exp.id}
              title={exp.title}
              status={exp.position}
              date={exp.date}
              onEdit={id => editExpirience(id)}
              onDelete={id => removeExpirience(id)}
            />))}

        </div>

        {isOpen && <AddUpdateExperience
          open={isOpen}
          isEdit={isEdit}
          refetch={refetch}
          onClose={() => {
            setIsOpen(false)
            setIsEdit(null);
            // setTimeout(() => {
            //   refetch()
            // }, 200)
          }}
        />}
      </DrawerContent>
    </Drawer>
  )
}

export default ExperienceListDrawer
