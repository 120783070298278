import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import resendUserService from 'services/users/updateUserPasswordService'
import { useTranslation } from 'react-i18next'

const useUpdateUserPasswordService = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [user, setUser] = useState('')
  const [error, setError] = useState('')

  const { updateUserPasswordMutation, loading } = resendUserService(params)

  const updateUserPassword = async (id: string) => {
    let message: string = ''
    let variant:
      | 'default'
      | 'error'
      | 'warning'
      | 'success'
      | 'info'
      | undefined
    const { data, errors } = await updateUserPasswordMutation({
      variables: {
        id,
      },
    })

    if (errors) {
      // eslint-disable-next-line no-console
      console.log(errors)
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = t('messages.new_password_sent')
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    updateUserPassword,
    user,
    error,
  }
}

export default useUpdateUserPasswordService
