import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUserValue } from 'context/UserContext'
import { useHistory } from 'react-router-dom'
import {
  DetailsContentCoachesWrapper,
  DetailsContentCoachTitle,
  Row,
} from 'components/common/DetailsLayout'
import { useLocation } from 'react-router-dom'
import CoachesItem from './CoachesItem'
import styled from 'styled-components'
import { FakeUserImage } from 'components/common/DetailsLayout/styled-components'

const RenderCoaches: React.FC<{ coaches: any[] }> = ({ coaches }) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const history = useHistory()
  const currentLocation = useLocation()
  const isExploreCourse = currentLocation.pathname.indexOf('explore') > -1

  return (
    <DetailsContentCoachesWrapper>
      <DetailsContentCoachTitle>
        {isExploreCourse
          ? t('course_details.coaches_of_the_course')
          : state.userPermission.activateAdmin
          ? t('course_details.coaches_of_the_course')
          : t('course_details.my_coaches')}
      </DetailsContentCoachTitle>
      <CoachesContainer>
        {coaches[0] && <CoachesItem coach={coaches[0]} />}
        {coaches && (
          <CoachesList>
            {coaches.slice(1).map((coach: any) => (
              <>
                <CoachesImageItem>
                  {coach.avatar ? (
                    <img
                      src={coach.avatar}
                      style={{
                        width: 48,
                        height: 48,
                        cursor: 'pointer',
                        borderRadius: '16px',
                      }}
                      onClick={() => history.push(`/profile/${coach.userId}`)}
                      alt=""
                    />
                  ) : (
                    <FakeUserImage
                      onClick={() => history.push(`/profile/${coach?.userId}`)}
                    />
                  )}
                </CoachesImageItem>
              </>
            ))}
          </CoachesList>
        )}
        {(coaches.length < 1 || !coaches) && (
          <div>
            {isExploreCourse || state.userPermission.activateAdmin
              ? t('general.no_coaches')
              : t('general.student_no_coaches')}{' '}
          </div>
        )}
      </CoachesContainer>
    </DetailsContentCoachesWrapper>
  )
}

export default RenderCoaches

const CoachesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`

const CoachesList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 400px;
`

const CoachesImageItem = styled.div`
  display: flex;
  margin-left: -24px;
  & > img {
    border: 1px solid #ffffff;
  }
  & > div {
    border: 1px solid #ffffff;
  }
`
