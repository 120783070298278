/* eslint-disable import/prefer-default-export */
export const updateCache = ({
  objectName,
  objectNames = [],
  query,
  cache,
  params = {},
  document,
  action,
}) => {
  try {
    const result = cache.readQuery({
      query,
      variables: params,
    })
    const object = result[objectName]
    let documentArray = []
    // if (objectNames.length === 0) {
    //   object = result[objectName]
    // } else {
    //   object = objectNames.reduce((acc, name) => {
    //     return acc[name]
    //   }, result)
    // }

    // console.log('object', object)
    // console.log('document', document)

    if (action === 'addMany' || action === 'deleteMany') {
      documentArray = document
    } else {
      documentArray = [document]
    }

    if (action === 'add') {
      cache.writeQuery({
        query,
        variables: params,
        data: { [objectName]: documentArray.concat(object) },
      })
      return false
    }

    if (action === 'addToLast') {
      cache.writeQuery({
        query,
        variables: params,
        data: { [objectName]: object.concat(documentArray) },
      })
      return false
    }

    if (action === 'addMany') {
      cache.writeQuery({
        query,
        variables: params,
        data: { [objectName]: documentArray.concat(object) },
      })
      return false
    }

    if (action === 'delete') {
      cache.writeQuery({
        query,
        variables: params,
        data: {
          [objectName]: object.filter(item => item.id !== document.id),
        },
      })
      return false
    }

    if (action === 'deleteMany') {
      const filteredDocument = object.filter(item => {
        const arr = document.map(doc => item.id !== doc.id && item)[0]
        return arr
      })
      cache.writeQuery({
        query,
        variables: params,
        data: {
          [objectName]: filteredDocument,
        },
      })
      return false
    }
  } catch (error) {
    console.log('Error from cache update', error) //eslint-disable-line
  }
}
