import * as Yup from 'yup'
import i18n from 'i18n/index'

export let UserSchema = {}

export let AdminSchema = {}

export let UserLongSchema = {}

export let StudentSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .required('First Name is Required'),
  lastName: Yup.string()
    .trim()
    .required('Last Name is Required'),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Email is invalid',
    )
    .required('Email is Required'),
  group: Yup.array()
    .required('Group is Required')
    .nullable(),
  phoneFields: Yup.object()
    .nullable()
    .when('phone', {
      is: (value: any) => !!value,
      then: Yup.object()
        .required('Dial Code is Required')
        .nullable(),
    }),
  phoneFieldValue: Yup.string().nullable(), //for phone validation
  phone: Yup.string()
    .nullable()
    .when('phoneFieldValue', {
      is: (value: any) => !!value,
      then: Yup.string()
        .required('Phone is Required')
        .nullable(),
    }),
})

export let CoachSchema = {}

export let LoginSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Email is invalid',
    )
    .required('Email is Required'),

  password: Yup.string()
    .min(2, 'Minimum Characters - 2')
    .required('Password is Required'),
})

export const ChangePasswordSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Email is invalid',
    )
    .required('Email is Required'),
  password: Yup.string()
    .min(2, 'Minimum Characters - 2')
    .required('Password is Required'),
  repeatPassword: Yup.string()
    .min(2, 'Minimum Characters - 2')
    .required('Password is Required'),
  oldPassword: Yup.string()
    .min(2, 'Minimum Characters - 2')
    .required('Password is Required'),
})

export let CompaniesWizardPage1Schema = Yup.object({
  name: Yup.string().required('Company Title is Required'),
  companyEmail: Yup.string()
    .email('Email is invalid')
    .required('Email is Required'),
  keyContactName: Yup.string().required('Key Contact Name is Required'),
  phoneFields: Yup.object()
    .nullable()
    .when('phone', {
      is: (value: any) => !!value,
      then: Yup.object()
        .required('Dial Code is Required')
        .nullable(),
    }),
  phoneFieldValue: Yup.string().nullable(), //for phone validation
  phone: Yup.string()
    .matches(/[^A-Z]/gi, 'Phone is invalid')
    .nullable()
    .when('phoneFieldValue', {
      is: (value: any) => !!value,
      then: Yup.string()
        .required('Phone is Required')
        .nullable(),
    }),
})

export const CompaniesWizardPage2Schema = Yup.object({
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Email is invalid',
    )
    .required('Email is Required'),
  firstName: Yup.string().required('First Name is Required'),
  lastName: Yup.string().required('Last Name is Required'),
  phoneFields: Yup.object()
    .nullable()
    .when('phone', {
      is: (value: any) => !!value,
      then: Yup.object()
        .required('Dial Code is Required')
        .nullable(),
    }),
  phoneFieldValue: Yup.string().nullable(), //for phone validation
  phone: Yup.string()
    .matches(/[^A-Z]/gi, 'Phone is invalid')
    .nullable()
    .when('phoneFieldValue', {
      is: (value: any) => !!value,
      then: Yup.string()
        .required('Phone is Required')
        .nullable(),
    }),
})

export const CompaniesWizardManagersSchema = Yup.object().shape({
  data: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string(),
      lastName: Yup.string(),
      email: Yup.string()
        .matches(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          'Email is invalid',
        )
        .required('Email is Required'),
      // status: Yup.string(),
    }),
  ),
})

export const FilterAdminsSchema = Yup.object({
  firstName: Yup.string(),
  lastName: Yup.string(),
  age: Yup.array().of(Yup.number()),
  gender: Yup.string(),
  status: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
  note: Yup.string(),
  location: Yup.string(),
  createDate: Yup.mixed().nullable(true),
  company: Yup.mixed().nullable(true),
})

export const FilterSuperAdminSchema = Yup.object({
  firstName: Yup.string(),
  lastName: Yup.string(),
  age: Yup.array().of(Yup.number()),
  gender: Yup.string(),
  status: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
  note: Yup.string(),
  location: Yup.string(),
  createDate: Yup.mixed().nullable(true),
  company: Yup.mixed().nullable(true),
})

export const FilterCoachesSchema = Yup.object({
  firstName: Yup.string(),
  lastName: Yup.string(),
  age: Yup.array().of(Yup.number()),
  gender: Yup.string(),
  status: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
  note: Yup.string(),
  location: Yup.string(),
  createDate: Yup.mixed().nullable(true),
  company: Yup.mixed().nullable(true),
  course: Yup.mixed().nullable(true),
  group: Yup.mixed().nullable(true),
})

export const FilterGroupsSchema = Yup.object({
  name: Yup.string(),
  numberOfStudents: Yup.array().of(Yup.number()),
  status: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
  note: Yup.string(),
  location: Yup.string(),
  createDate: Yup.mixed().nullable(true),
  company: Yup.mixed().nullable(true),
  course: Yup.mixed().nullable(true),
  admin: Yup.mixed().nullable(true),
})

export const FilterStudentsSchema = Yup.object({
  firstName: Yup.string(),
  lastName: Yup.string(),
  age: Yup.array().of(Yup.number()),
  gender: Yup.string(),
  status: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
  note: Yup.string(),
  location: Yup.string(),
  createDate: Yup.mixed().nullable(true),
  company: Yup.mixed().nullable(true),
  course: Yup.mixed().nullable(true),
  group: Yup.mixed().nullable(true),
})

export const FilterCompanySchema = Yup.object({
  name: Yup.string(),
  companyEmail: Yup.string(),
  address: Yup.string(),
  phone: Yup.string().matches(/[^A-Z]/gi, 'Phone is invalid'),
  companyActivity: Yup.string(),
})

export let CourseSchema = {}

export const EditCourseSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  level: Yup.string(),
  coursePrivacy: Yup.string(),
})
export let EmailSchema = {}
export let ModuleSchema = {}
export let SectionSchema = {}
export let QuestionSchema = {}
export let QuizSchema = {}
export let IQTestSchema = {}
export let LessonSchema = {}
export let GroupSchema = {}
export let SubscriptionSchema = {}
// Topic Scheemas
export const AddTopicSchema = Yup.object({
  name: Yup.string().required('Name is required'),
})

export const EditTopicSchema = Yup.object({
  name: Yup.string().required('Name is required'),
})

export const FlashCardSchema = Yup.object({
  question: Yup.string()
    .required('Question is required')
    .max(50, 'Question Must be minimum 50 Characters'),
  answer: Yup.string()
    .required('Answer is required')
    .max(250, 'Question Must be minimum 250 Characters'),
  image: Yup.string().required('Cover Image is required'),
  color: Yup.string().required('Color is required'),
})
function translate() {
  EmailSchema = Yup.object({
    email: Yup.string()
      .email(`${i18n.t('form_fields.email')} ${i18n.t(`validations.invalid`)}`)
      .required(
        `${i18n.t('form_fields.email')} ${i18n.t(`validations.required`)}`,
      ),
  })

  LoginSchema = Yup.object({
    email: Yup.string()
      .email(`${i18n.t('form_fields.email')} ${i18n.t(`validations.invalid`)}`)
      .required(
        `${i18n.t('form_fields.email')} ${i18n.t(`validations.required`)}`,
      ),
    password: Yup.string().required(
      `${i18n.t('form_fields.password')} ${i18n.t(`validations.required`)}`,
    ),
  })

  UserSchema = Yup.object({
    firstName: Yup.string().required(
      `${i18n.t('user_details.first_name')} ${i18n.t(`validations.required`)}`,
    ),
    lastName: Yup.string().required(
      `${i18n.t('user_details.last_name')} ${i18n.t(`validations.required`)}`,
    ),
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid',
      )
      .required(
        `${i18n.t('form_fields.email')} ${i18n.t(`validations.required`)}`,
      ),
    phoneFields: Yup.object()
      .nullable()
      .when('phone', {
        is: (value: any) => {
          return !!value
        },
        then: Yup.object()
          .required(
            `${i18n.t('form_fields.dial_code')} ${i18n.t(
              `validations.required`,
            )}`,
          )
          .nullable(),
      }),
    phoneFieldValue: Yup.string().nullable(), //for phone validation
    phone: Yup.string()
      .matches(
        /[^A-Z]/gi,
        `${i18n.t('form_fields.phone')} ${i18n.t(`validations.invalid`)}`,
      )
      .nullable()
      .when('phoneFieldValue', {
        is: (value: any) => {
          return value && value.length > 1
        },
        then: Yup.string()
          .required(
            `${i18n.t('form_fields.phone')} ${i18n.t(`validations.required`)}`,
          )
          .nullable(),
      }),
    birthDate: Yup.date()
      .typeError(
        `${i18n.t('user_details.birthday')} ${i18n.t(`validations.invalid`)}`,
      )
      .nullable(),
  })

  CoachSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is Required'),
    lastName: Yup.string().required('Last Name is Required'),
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid',
      )
      .required('Email is Required'),
    // phone: Yup.string()
    //   // .required('Phone is Required')
    //   .matches(phoneRegExp, 'Phone is invalid'),

    // gender: Yup.string().required('Gender is Required'),
    // birthDate: Yup.date()
    //   .required('Birth date is required')
    //   .typeError(),
    // biography: Yup.string(),
    // note: Yup.string(),

    phoneFields: Yup.object()
      .nullable()
      .when('phone', {
        is: (value: any) => !!value,
        then: Yup.object()
          .required(
            `${i18n.t('form_fields.dial_code')} ${i18n.t(
              `validations.required`,
            )}`,
          )
          .nullable(),
      }),
    phoneFieldValue: Yup.string().nullable(), //for phone validation
    phone: Yup.string()
      .matches(
        /[^A-Z]/gi,
        `${i18n.t('form_fields.phone')} ${i18n.t(`validations.invalid`)}`,
      )
      .nullable()
      .when('phoneFieldValue', {
        is: (value: any) => !!value,
        then: Yup.string()
          .matches(
            /[^A-Z]/gi,
            `${i18n.t('form_fields.phone')} ${i18n.t(`validations.invalid`)}`,
          )
          .required(
            `${i18n.t('form_fields.phone')} ${i18n.t(`validations.required`)}`,
          )
          .nullable(),
      }),
    birthDate: Yup.date()
      .typeError(
        `${i18n.t('user_details.birthday')} ${i18n.t(`validations.invalid`)}`,
      )
      .nullable(),
  })

  StudentSchema = Yup.object({
    firstName: Yup.string().required(
      `${i18n.t('user_details.first_name')} ${i18n.t(`validations.required`)}`,
    ),
    lastName: Yup.string().required(
      `${i18n.t('user_details.last_name')} ${i18n.t(`validations.required`)}`,
    ),
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid',
      )
      .required('Email is Required'),

    phoneFields: Yup.object()
      .nullable()
      .when('phone', {
        is: (value: any) => !!value,
        then: Yup.object()
          .required(
            `${i18n.t('form_fields.dial_code')} ${i18n.t(
              `validations.required`,
            )}`,
          )
          .nullable(),
      }),
    phoneFieldValue: Yup.string().nullable(), //for phone validation
    phone: Yup.string()
      .matches(
        /[^A-Z]/gi,
        `${i18n.t('form_fields.phone')} ${i18n.t('validations.invalid')}`,
      )
      .nullable()
      .when('phoneFieldValue', {
        is: (value: any) => !!value,
        then: Yup.string()
          .required(
            `${i18n.t('form_fields.phone')} ${i18n.t(`validations.required`)}`,
          )
          .nullable(),
      }),
    group: Yup.array()
      .required(`${i18n.t('general.group')} ${i18n.t(`validations.required`)}`)
      .nullable(),
    birthDate: Yup.date()
      .typeError(
        `${i18n.t('user_details.birthday')} ${i18n.t(`validations.invalid`)}`,
      )
      .nullable(),
  })
  CourseSchema = {
    name: Yup.string().required(
      `${i18n.t('general.name')} ${i18n.t('validations.required')}`,
    ),
    avatar: Yup.object()
      .required(
        `${i18n.t('general.cover_image')} ${i18n.t('validations.required')}`,
      )
      .nullable(),
    categories: Yup.array()
      .required(
        `${i18n.t('general.categories')} ${i18n.t('validations.required')}`,
      )
      .nullable(),
  }

  ModuleSchema = Yup.object({
    name: Yup.string().required(
      `${i18n.t('general.name')} ${i18n.t('validations.required')}`,
    ),
    coverImage: Yup.object()
      .required(
        `${i18n.t('general.cover_image')} ${i18n.t('validations.required')}`,
      )
      .nullable(),
  })

  SectionSchema = Yup.object({
    name: Yup.string().required(
      `${i18n.t('general.name')} ${i18n.t('validations.required')}`,
    ),
  })

  Yup.setLocale({
    mixed: {
      default: 'Invalid value',
      notType: 'This field is required', // Customize the error message here
    },
  })

  SubscriptionSchema = Yup.object().shape({
    price: Yup.number()
      .min(0.1)
      .required(
        `${i18n.t('form_fields.price')} ${i18n.t('validations.required')}`,
      ),
    occurrenceNumber: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.number().required('This field is required'),
    }),
    currency: Yup.object()
      .shape({
        label: Yup.string().required(),
        value: Yup.string().required('Thi field is required'),
      })
      .required('This field is required'),
  })

  QuestionSchema = Yup.object({
    question: Yup.string().required(
      `${i18n.t('general.question')} ${i18n.t('validations.required')}`,
    ),
  })

  QuizSchema = {
    name: Yup.string().required(
      `${i18n.t('general.name')} ${i18n.t('validations.required')}`,
    ),
    quizParticipationType: Yup.string().required(
      `${i18n.t('general.quiz')} ${i18n.t(
        'quiz_details.participation',
      )} ${i18n.t('general.type')} ${i18n.t('validations.required')}`,
    ),
  }

  IQTestSchema = {
    name: Yup.string().required(
      `${i18n.t('general.name')} ${i18n.t('validations.required')}`,
    ),
    // IQTestType: Yup.string().required(
    //   `${i18n.t('general.iq_test')} ${i18n.t('general.type')} ${i18n.t(
    //     'validations.required',
    //   )}`,
    // ),
    quizParticipationType: Yup.string().required(
      `${i18n.t('general.iq_test')} ${i18n.t(
        'quiz_details.participation',
      )} ${i18n.t('general.type')} ${i18n.t('validations.required')}`,
    ),
  }

  LessonSchema = {
    name: Yup.string().required(
      `${i18n.t('general.name')} ${i18n.t('validations.required')}`,
    ),
    pdfDuration: Yup.number()
      .typeError('შეიყვანეთ ციფრი')
      .required(
        `${i18n.t('general.pdfLessonTime')} ${i18n.t('validations.required')}`,
      ),
    topic: Yup.object()
      .nullable()
      .shape({
        value: Yup.string().required(),
      })
      .required(`${i18n.t('general.topic')} ${i18n.t('validations.required')}`),
  }

  AdminSchema = Yup.object({
    firstName: Yup.string().required('Firstname is Required'),
    lastName: Yup.string().required('Lastname is Required'),
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid',
      )
      .required('Email is Required'),
    phoneFields: Yup.object()
      .nullable()
      .when('phone', {
        is: (value: any) => !!value,
        then: Yup.object()
          .required(
            `${i18n.t('form_fields.dial_code')} ${i18n.t(
              `validations.required`,
            )}`,
          )
          .nullable(),
      }),
    phoneFieldValue: Yup.string().nullable(), //for phone validation
    phone: Yup.string()
      .matches(/[^A-Z]/gi, 'Phone is invalid')
      .nullable()
      .when('phoneFieldValue', {
        is: (value: any) => !!value,
        then: Yup.string()
          .required(
            `${i18n.t('form_fields.phone')} ${i18n.t(`validations.required`)}`,
          )
          .nullable(),
      }),
    // group: Yup.string().required('You have to select group')
  })

  UserLongSchema = Yup.object({
    firstName: Yup.string().required('First Name is Required'),
    lastName: Yup.string().required('Last Name is Required'),
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid',
      )
      .required('Email is Required'),
    biography: Yup.string().nullable(),
    note: Yup.string().nullable(),
    group: Yup.array()
      .required('Group is Required')
      .nullable(),
    phoneFields: Yup.object()
      .nullable()
      .when('phone', {
        is: (value: any) => !!value,
        then: Yup.object()
          .required(
            `${i18n.t('form_fields.dial_code')} ${i18n.t(
              `validations.required`,
            )}`,
          )
          .nullable(),
      }),
    phoneFieldValue: Yup.string().nullable(), //for phone validation
    phone: Yup.string()
      .matches(/[^A-Z]/gi, 'Phone is invalid')
      .nullable()
      .when('phoneFieldValue', {
        is: (value: any) => !!value,
        then: Yup.string()
          .required(
            `${i18n.t('form_fields.phone')} ${i18n.t(`validations.required`)}`,
          )
          .nullable(),
      }),
  })

  CompaniesWizardPage1Schema = Yup.object({
    name: Yup.string().required(
      `${i18n.t('company_details.company_title')} ${i18n.t(
        'validations.required',
      )}`,
    ),
    companyEmail: Yup.string()
      .email(`${i18n.t('user_details.email')} ${i18n.t('validations.invalid')}`)
      .required(
        `${i18n.t('user_details.email')} ${i18n.t('validations.required')}`,
      ),
    keyContactName: Yup.string().required(
      `${i18n.t('company_details.key_contact_name')} ${i18n.t(
        'validations.required',
      )}`,
    ),
    phoneFields: Yup.object()
      .nullable()
      .when('phone', {
        is: (value: any) => !!value,
        then: Yup.object()
          .required(
            `${i18n.t('form_fields.dial_code')} ${i18n.t(
              'validations.required',
            )}`,
          )
          .nullable(),
      }),
    phoneFieldValue: Yup.string().nullable(), //for phone validation
    phone: Yup.string()
      .matches(/[^A-Z]/gi, 'Phone is invalid')
      .nullable()
      .when('phoneFieldValue', {
        is: (value: any) => !!value,
        then: Yup.string()
          .required(
            `${i18n.t('form_fields.phone')} ${i18n.t('validations.required')}`,
          )
          .nullable(),
      }),
  })

  GroupSchema = Yup.object({
    name: Yup.string().required(
      `${i18n.t('general.name')} ${i18n.t('validations.required')}`,
    ),
    admin: Yup.object()
      .required(`${i18n.t('general.admin')} ${i18n.t('validations.required')}`)
      .nullable(),
    courses: Yup.array()
      .required(`${i18n.t('general.course')} ${i18n.t('validations.required')}`)
      .nullable(),
  })
}

translate()

i18n.on('languageChanged init', () => {
  translate()
})
