import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_MODULE } from 'gql/modules.query'
import { Column, Row } from '../../DetailsLayout'
import { formatDuration } from 'helpers/formatDuration'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import styled from 'styled-components'
import { GridItem, GridItemImage, GridItemTitle } from '../styled-components'
import { useParams } from 'react-router-dom'
import TopicLessonQuizList from './TopicLessonQuizList/TopicLessonQuizList'

interface ModuleDropdownParams {
  item: any
  gridStyle: string
  wenrollExploreCourse?: boolean
}

const ModuleDropdown = ({
  item,
  gridStyle,
  wenrollExploreCourse,
}: ModuleDropdownParams) => {
  const [isCollapsed, setCollapse] = useState(false)
  const moduleId = item.id
  const { id: courseId } = useParams<{ id: string }>()
  const { data, loading, refetch } = useQuery(GET_MODULE, {
    variables: { moduleId, courseId },
  })

  const moduleData = (data && data.getModule) || {}

  const quizzesQuantity = (moduleData: any) => {
    let quizzesCounter = 0
    quizzesCounter += moduleData.quizList?.length
    for (
      var topicIndex = 0;
      topicIndex < moduleData.topics?.length;
      topicIndex++
    ) {
      quizzesCounter += moduleData.topics[topicIndex].quizzes?.length
      for (
        var lessonIndex = 0;
        lessonIndex < moduleData.topics[topicIndex].lessons.length;
        lessonIndex++
      ) {
        quizzesCounter +=
          moduleData.topics[topicIndex].lessons[lessonIndex].quizzes?.length
      }
    }
    return quizzesCounter
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        cursor: 'pointer',
      }}
      onClick={() => setCollapse((collapsed: boolean) => !collapsed)}
    >
      <Column style={{ paddingLeft: '0', marginLeft: '0' }}>
        <StylesCardWrapper className="wrapper" key={item.id}>
          <GridItem>
            <GridItemImage style={{ width: '190px', height: '120px' }}>
              <img
                src={
                  item.coverImage
                    ? item.coverImage.link
                    : '/img/no_module_avatar.jpeg'
                }
                alt="Module Image"
              />
            </GridItemImage>
            <Column>
              <GridItemTitle>{item.name}</GridItemTitle>
              <Row>
                <GridItemShortInfo>
                  <span style={{ marginLeft: '0' }}>
                    {item.numberOfLessons} Lessons
                  </span>
                  <span>|</span>
                  <span>{formatDuration(item.duration)}</span>
                  <span>|</span>
                  <span>{quizzesQuantity(moduleData)} quizzes</span>
                </GridItemShortInfo>
              </Row>
            </Column>
            <CollapseIcon>
              {isCollapsed ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </CollapseIcon>
          </GridItem>
        </StylesCardWrapper>
        {isCollapsed && gridStyle === 'list' && (
          <TopicLessonQuizList
            moduleData={moduleData}
            wenrollExploreCourse={wenrollExploreCourse}
          />
        )}
      </Column>
    </div>
  )
}

export default ModuleDropdown

const StylesCardWrapper = styled.div`
  position: relative;
  &:hover {
    .action_block {
      background: rgba(255, 255, 255, 0.59) !important;
    }
  }
  height: 120px !important;
`

export const GridItemShortInfo = styled.div`
  font-size: 12px;
  letter-spacing: 0px;
  color: #707070;
  margin-top: 12px;

  > span {
    margin: 0 8px;
  }
`
const CollapseIcon = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
`
