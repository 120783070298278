import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { DrawerEventEmitter } from 'helpers/drawer'
import _ from 'lodash'
import { actionConfigForSections, columnConfigForSections } from './gridConfig'
import { makeStyles } from '@material-ui/core/styles'

import {
  useFetchSections,
  PER_PAGE,
  useDeleteSections,
} from 'hooks/sections/useSections'

import { useSwal } from 'hooks/useSwal'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import { useTheme } from '@material-ui/core'

import SyncIcon from '@material-ui/icons/Sync'

import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Player from 'components/common/VideoPlayer/Player'

// not created yet
// import FilterDrawer from './FilterDrawer/FilterDrawer'

import Drawer from 'components/common/Drawer'

import {
  ActionButton,
  GridLayoutActions,
  GridLayoutForm,
  GridToolbar,
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'

import Icon from 'components/Icon'
import Search from 'components/common/Search'
import TablePagination from 'components/common/Pagination/TablePagination'
import Grid from 'components/common/Grid'

type CourseAvatar = {
  link: string
  __typename?: string
}

type SectionCourse = {
  id: string
  name: string
  avatar?: CourseAvatar
  savedInDb?: boolean
  __typename?: string
}

interface ISectionInfo {
  courses: SectionCourse[]
  name: string
  // forStartup: boolean
  id: string
}

enum SectionPopulatingActions {
  CREATE = 'createSection',
  EDIT = 'editSection',
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const SectionsLayout = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [state] = useUserValue()
  const classes = useStyles()
  const theme = useTheme()
  const { fireSwal } = useSwal()

  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const [videoModal, setVideoModal] = useState({
    modal: false,
    data: '',
    subtitle: '',
  })
  const [filterData, setFilterData] = useState<any>({})
  const [gridStyle, setGridStyle] = useState('card')
  const [searchText, setSearchText] = useState<string>('')
  const [filtered, setFiltered] = useState(false)
  const [filterQuery, setFilterQuery] = useState<any>({})

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)

  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectedAll, setSelectedAll] = useState<any>({})
  const [fetchAllPagination, setFetchAllPagination] = useState([0, 10])
  const [page, setPage] = useState(1)
  const [fetchAll, setFetchAll] = useState(false)

  // const { deleteModule, deletedModule } = useDeleteModuleService()
  const { deleteSections, deletedSections, setDeleted } = useDeleteSections()

  // console.log('desired deletedSections (status)', deletedSections)

  // const { modules, error, loading, refetch }: any = useFetchModules({
  //   companyId: {
  //     type: 'exact',
  //     value: state.selectedCompanyId,
  //   },
  // })

  const { sections, error, loading, refetch } = useFetchSections({
    // query: {
    //   type: 'query',
    //   value: JSON.stringify(filterQuery),
    // },

  }, 1, 10, true)

  const { sections: allSections } = useFetchSections({}, 1, 1, true)

  console.log(allSections, "allSections")
  const selectItem = (id: string) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectedAll({
        ...selectedAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectedAll({
        ...selectedAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectedAll({
        ...selectedAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const resetFilter = () => {
    // setFiltered(false)
    let fetchAll = false
    setFilterData({})
    refetch({
      filter: {
        companyId: {
          type: 'exact',
          value: state.selectedCompanyId,
        },
      },
      currentPage: 1,
      perPage,
      fetchAll,
      // dashBoard: true
    })
    setFilterIsOpen(false)
    setFilterQuery({})
    setSearchText('')
  }

  const filterDrawer = () => {
    setFilterIsOpen(!filterIsOpen)
  }

  const onSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    let fetchAll = false

    const searchValue = { type: 'match', value: searchText }
    setCurrentPage(1)
    refetch({
      filter: {
        name: searchValue,
        companyId: {
          type: 'exact',
          value: state.selectedCompanyId,
        },
      },
      currentPage: 1,
      perPage,
      fetchAll,
      // dashBoard: true,
    })
  }

  const openDrawer = (id: string) => {
    DrawerEventEmitter.emit('openDrawer', 'editModuleDrawer', true, { id })
  }

  const handleDeleteClick = (field: any, event?: any) => {
    // console.log('desired event on deleteclick', event)
    // console.log('desired field on deleteclick', field)

    if (event) {
      fireSwal({
        title:
          selectedItem[currentPage]?.length === 1
            ? t('popups.delete_section_single')
            : t('popups.delete_section_many'),
        confirmText: t('popups.confirm_delete'),
        onConfirm: () => {
          deleteSections(field)
          setSelectedItem({})
          setSelectedAll({})
        },
      })
      return
    }

    fireSwal({
      title: t('popups.delete_section_single'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => {
        deleteSections([field.id])
        setSelectedItem({})
        setSelectedAll({})
      },
    })
  }

  const handlePaginationClick = (event: any, value: number) => {
    if ((page - value === 1 || page - value === 0) && fetchAll) {
      setFetchAllPagination(prevState => [prevState[0] + 10, prevState[1] + 10])

      setPage(page + 1)
      return
    } else if (page > value && fetchAll) {
      setFetchAllPagination(prevState => [prevState[0] - 10, prevState[1] - 10])
      setPage(page - 1)
      return
    }

    if (value + 1 !== currentPage) {
      let fetchAll = false
      refetch({
        filter: {
          // companyId: {
          //   type: 'exact',
          //   value: state.selectedCompanyId,
          // },
          query: {
            type: 'query',
            value: JSON.stringify(filterQuery),
          },
        },
        currentPage: value + 1,
        perPage,
        fetchAll,
        // dashBoard: true
      })
      setCurrentPage(value + 1)
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setPerPage(parseInt(event.target.value, 10))
    setCurrentPage(1)
  }

  useEffect(() => {
    let fetchAll = false
    refetch({
      filter: {},
      currentPage,
      perPage,
      fetchAll,
      // dashBoard: true
    })
  }, [perPage])

  // uncomment for refetching after deletion

  useEffect(() => {
    if (deletedSections) {
      // console.log("desired section deleted")
      setDeleted(false)
      refetch()
    }
  }, [deletedSections])

  const selectAllItem = () => {
    if (!selectedAll[currentPage]) {
      // const newArr: string[] = modules.data.map((n: any) => n.id)
      const newArr: string[] = allSections.data.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectedAll({
        ...selectedAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectedAll({
      ...selectedAll,
      [currentPage]: false,
    })
  }

  const isStudent = state.currentUser.role === 'STUDENT'

  // const isCompanySuperAdmin =
  //   state?.currentUser?.role === 'SUPER_ADMIN' &&
  //   !!state?.currentUser?.companyId?.id

  // const isCompanyAdmin =
  //   state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  const isSuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !state?.currentUser?.companyId?.id

  // const isAdmin =
  //   state?.currentUser?.role === 'ADMIN' && !state?.currentUser?.companyId?.id

  // const canModify =
  //   (((isCompanySuperAdmin || isCompanyAdmin) && modules?.data[0]?.companyId === state?.currentUser?.companyId?.id) || isSuperAdmin || isAdmin)
  //   && !isStudent

  const canModify = isSuperAdmin && !isStudent

  const handleClick = (id: string) => {
    // window.open(`/courses/${id}`, "_blank")
  }

  const handleOpenFirstLevelDrawer = (
    actionType: SectionPopulatingActions,
    sectionData?: any,
  ) => {

    let sectionInfo: ISectionInfo | null = null
    if (actionType === SectionPopulatingActions.EDIT) {
      if (sectionData?.courses && sectionData?.id && sectionData?.name) {
        const savedCourses = sectionData.courses.map((c: SectionCourse) => ({
          ...c,
          savedInDb: true,
        }))
        sectionInfo = {
          id: sectionData.id,
          name: sectionData.name,
          // forStartup: sectionData.forStartup,
          courses: savedCourses,
        }
      } else {
        return
      }
    }

    const drawerTitles = {
      [SectionPopulatingActions.CREATE]: t(
        'sections_layout.create_course_section',
      ),
      [SectionPopulatingActions.EDIT]: t('sections_layout.edit_course_section'),
    }
    let fetchAll = false
    DrawerEventEmitter.emit('openDrawer', 'populateSection', true, {
      title: drawerTitles[actionType],
      data: {
        sectionInfo,
        type: actionType,
        onSuccess: () => {
          refetch({
            filter: {
              query: {
                type: 'query',
                value: JSON.stringify(filterQuery),
              },
            },
            currentPage,
            perPage,
            fetchAll,
            // dashBoard: true
          })
          setSelectedItem({})
          setSelectedAll({})
        },
      },
    })
  }

  const actionHandler = (event: string, field: any, action: string) => {
    switch (event) {
      case 'edit':
        // return handelOpenEditDrawer(field)
        return handleOpenFirstLevelDrawer(SectionPopulatingActions.EDIT, field)
      case 'delete':
        handleDeleteClick(field)
        break
      default:
        return () => console.log('')
    }
  }

  const config = columnConfigForSections(handleClick, selectItem, t, theme)
  const actions = actionConfigForSections(
    actionHandler,
    state.userPermission,
    t,
    canModify,
  )

  return (
    <Container theme={theme}>
      <GridToolbar theme={theme}>
        <GridLayoutActions>

          <div>
            {state.userPermission.createNewSection && (
              <AddButton
                onClick={() =>
                  handleOpenFirstLevelDrawer(SectionPopulatingActions.CREATE)
                }
              >
                <Icon filename={'add'} fill={'#ffffff'} />
                {t('sections_layout.create_section')}
              </AddButton>
            )}
          </div>
        </GridLayoutActions>
        <GridLayoutForm>
          <ActionButton
            disabled={_.isEmpty(filterData) && !searchText}
            onClick={resetFilter}
            theme={theme}
          >
            <SyncIcon fontSize="small" />
          </ActionButton>
          <MultipleAction theme={theme}>
            <ActionButton onClick={filterDrawer} theme={theme}>
              <Icon filename={'filter'} fill={'#080A0AD6'} />
            </ActionButton>
            <MultipleActionTooltip>
              {t('actions.filters')}
            </MultipleActionTooltip>
          </MultipleAction>
          <Search
            inputProps={{
              value: searchText,
              onChange: (e: any) => {
                setSearchText(e.target.value)
                setCurrentPage(1)
              },
            }}
            onClick={onSearchSubmit}
          />
        </GridLayoutForm>
      </GridToolbar>

      {sections?.data && (
        <>
          <Grid
            title={t('sections_layout.sections_list')}
            // onFiltersClick={handleFiltersClick}
            // resetVisible={resetVisible}
            // resetFilters={handleResetClick}
            fetchAll={fetchAll}
            setFetchAll={setFetchAll}
            config={config}
            // data={modules.data}
            data={
              fetchAll
                ? allSections?.data?.slice(...fetchAllPagination)
                : sections.data
            }
            actionConfig={actions}
            selected={_.size(selectedItem[currentPage])}
            selectAllItem={selectAllItem}
            selectedItems={selectedItem[currentPage] || []}
            actions={() => [
              {
                id: 0,
                color: 'secondary',
                tooltipText: `${t('actions.delete')}`,
                hide: !state.userPermission.createNewSection,
                disabled: selectedItem[currentPage]
                  ? selectedItem[currentPage].length < 1
                  : true,
                onClick: () =>
                  handleDeleteClick(selectedItem[currentPage], true),
                component: (
                  <ActionButton
                    disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
                    fill={'#CC3131'}
                    disabledFill={'#080A0AD6'}
                    theme={theme}
                  >
                    <Icon filename={'delete'} />
                  </ActionButton>
                ),
              },
              {
                id: 1,
                color: 'secondary',
                tooltipText: `${t('actions.export')}`,
                hide: !state.userPermission.deleteGroup,
                disabled: selectedItem[currentPage]
                  ? selectedItem[currentPage].length < 1
                  : true,
                onClick: () => alert('function'),
                component: (
                  <ActionButton
                    disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
                    fill={'#269F5A'}
                    disabledFill={'#080A0AD6'}
                    theme={theme}
                  >
                    <Icon filename={'download'} />
                  </ActionButton>
                ),
              },
            ]}
          // actions={multiActions}
          // allSelected={allSelected}
          />
        </>
      )}

      {sections && sections.data && (
        <TablePagination
          // currentPage={sections ? sections.currentPage : 0}
          currentPage={
            sections && !fetchAll
              ? sections.currentPage
              : allSections && fetchAll
                ? page
                : 0
          }
          rowsPerPage={perPage}
          count={sections ? sections.totalCount : 0}
          handleChangePage={handlePaginationClick}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={videoModal.modal}
        onClose={() => setVideoModal({ data: '', modal: false, subtitle: '' })}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Player url={videoModal.data} subtitle={videoModal.subtitle} />
      </Modal>

      {/* <Drawer opened={filterIsOpen} toggleDrawer={() => { }} totalWidth="970px">
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterQuery={setFilterQuery}
          perPage={perPage}
          refetchData={refetch}
          resetFilter={resetFilter}
          onClose={filterDrawer}
          setFiltered={setFiltered}
          companyId={state.selectedCompanyId}
          t={t}
        />
      </Drawer> */}
    </Container>
  )
}

export default SectionsLayout

const Container = styled.div`
  overflow: hidden;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  height: 100%;
`

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #317bf4;
  padding: 18px 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    background: #3165f4;
  }
`
