import { useQuery } from '@apollo/react-hooks'
import IconButton from '@material-ui/core/IconButton'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import { Button } from 'components/common/Button'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import Drawer from 'components/common/Drawer'
import {
  FormButtons,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form'
import Grid from 'components/common/Grid'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import {
  GridToolbar,
  GridLayoutActions,
  AddButton,
  GridLayoutForm,
  ActionButton,
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import Search from 'components/common/Search'
import { Input } from 'components/common/TextInput'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import Icon from 'components/Icon'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { GET_GROUP } from 'gql/group/group.query'
import { AddGroupDrawer, EditGroupDrawer } from 'pages/group'
import FilterDrawer from 'pages/group/FilterDrawer/FilterDrawer'
import React, { useEffect, useState } from 'react'
import SyncIcon from '@material-ui/icons/Sync'
import { useLocation } from 'react-router-dom'
import { SET_CREATED_USER } from 'store/types'
import styled from 'styled-components'
import AddGroupInCourseDrawer from './AddGroupInCourseDrawer'
import {
  actionConfig,
  columnConfig,
  groupActionConfig,
} from './groupGridConfig'
import SelectBtn from 'components/common/SelectBtn'
import { useTheme } from '@material-ui/core'

const Step2 = (props: any) => {
  const { t } = props
  const [state] = useUserValue()
  const [dataState, dispatch] = useData()
  const [drawer, setAddGroupDrawer] = useState<any>({
    addGroup: false,
    createGroup: false,
    editGroup: false,
  })
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [currentGroupId, setCurrentGroupId] = useState('')
  const [tabValue, setTabValue] = useState(0)

  const [filterDrawer, setFilterDrawer] = useState<any>({
    filterOptions: null,
    filterValues: null,
    opened: false,
  })

  const filter: any = {}

  const defaultFilter = {
    company: {
      type: 'exact',
      value: state.selectedCompanyId,
    },
  }

  useEffect(() => {
    if (state.currentUser.role === 'ADMIN') {
      filter.admin = {
        type: 'exact',
        value: state.currentUser.id,
      }
    }
  }, [])

  const { data, loading, error, refetch } = useQuery(GET_GROUP, {
    variables: {
      filter: {
        company: {
          type: 'exact',
          value: state.selectedCompanyId,
        },
        ...filter,
        ...filterDrawer.filterOptions,
      },
      perPage: 0,
    },
  })

  useEffect(() => {
    if (
      dataState.createdObject.obj &&
      dataState.createdObject.type === 'group'
    ) {
      props.selectGroup([
        {
          name: dataState.createdObject.obj.name,
          groupId: dataState.createdObject.obj.id,
        },
      ])

      dispatch({
        type: SET_CREATED_USER,
        payload: { obj: null, type: '' },
      })
    }
  }, [dataState.createdObject.obj])

  const handleClick = () => { }

  const handleSelect = (id: never, event: boolean) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    setSelectedItem(newSelected)
  }

  const selectAllItem = () => {
    if (!selectAll) {
      const newArr: string[] = selectedGroups.map((n: any) => n.id)
      setSelectedItem(newArr)
      setSelectAll(true)
      return
    }
    setSelectedItem([])
    setSelectAll(false)
  }

  const selectedGroups =
    data &&
    data.getAllGroups.data.filter(
      (item: any) =>
        props.selectedGroup.findIndex((val: any) => item.id === val.groupId) !==
        -1,
    )

  const deleteGroup = () => {
    if (selectedItem.length > 0) {
      props.deleteGroup(selectedItem)
      setSelectedItem([])
    }
  }

  const handleActionClick = (e: any, action: string) => {
    if (action === 'edit') {
      setCurrentGroupId(e)
      return setAddGroupDrawer({ ...drawer, editGroup: true })
    }
    props.deleteGroup(e)
  }

  const config = columnConfig(handleClick, handleSelect, props.t)
  const actions = actionConfig(handleActionClick, t)

  const filterFun = (i: any) => {
    return searchText
      ? i.name.toLowerCase().includes(searchText.toLowerCase())
      : i
  }

  const handleOpenDrawer = (action: string) => {
    setAddGroupDrawer({
      ...drawer,
      [action]: true,
    })
  }

  const buttonActions = groupActionConfig(handleOpenDrawer, t)

  const filterData = (filters: any) => {
    refetch({
      filter: { ...defaultFilter, ...filters },
      perPage: 0,
    })
  }

  const currentLocation = useLocation()
  const hasSearchByCompany = currentLocation.pathname.indexOf('course') > -1

  const theme = useTheme()

  return (
    <>
      <FormGroupWrapper padding={10}>
        <Container>
          {/* <Toolbar styles={{ justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <ToolbarItem>
                <GridActionMenu
                  actionConfig={buttonActions}
                  row={{}}
                  icon={
                    <Button
                      text={t('general.groups')}
                      size="small"
                      color="secondary"
                      icon={<ArrowDropDownIcon />}
                      iconPosition="right"
                      background={mainColor}
                    />
                  }
                />
              </ToolbarItem>

              <GridMultipleActions
                selectedItems={selectedItem.length}
                actions={[
                  {
                    id: 0,
                    color: 'secondary',
                    tooltipText: t('actions.delete'),
                    disabled: selectedItem.length < 1,
                    onClick: () => deleteGroup(),
                    component: <DeleteIcon fontSize="small" />,
                  },
                ]}
              />
            </div>

            <ToolbarItem>
              <Input
                label={t('general.search_placeholder')}
                type="text"
                size="small"
                onChange={(e: any) => setSearchText(e.target.value)}
                value={searchText}
                icon={
                  <IconButton>
                    <SearchRoundedIcon />
                  </IconButton>
                }
              />
            </ToolbarItem>
          </Toolbar> */}

          <GridToolbar
            style={{
              borderBottom: 'none',
              marginBottom: '-50px',
            }}
          >
            <SelectBtn
              active={tabValue}
              onChange={setTabValue}
              options={[
                {
                  label: t('actions.create_group'),
                  value: 0,
                  onClick: () => handleOpenDrawer('createGroup'),
                },
                {
                  label: t('actions.add_groups_to_course'),
                  value: 1,
                  onClick: () => handleOpenDrawer('addGroup'),
                },
              ]}
            />
            <GridLayoutForm>
              <ActionButton
                // onClick={handleResetClick}
                // disabled={resetVisible ? false : true}
                theme={theme}
              >
                <SyncIcon fontSize="small" />
              </ActionButton>
              <MultipleAction theme={theme}>
                <ActionButton theme={theme}>
                  <Icon filename={'filter'} fill={'#080A0AD6'} />
                </ActionButton>
                <MultipleActionTooltip>
                  {t('actions.filters')}
                </MultipleActionTooltip>
              </MultipleAction>
              <Search
                inputProps={{
                  value: searchText,
                  onChange: (e: any) => setSearchText(e.target.value),
                }}
                onClick={() => { }}
              />
            </GridLayoutForm>
          </GridToolbar>

          <Grid
            data={(selectedGroups && selectedGroups.filter(filterFun)) || []}
            config={config}
            actionConfig={actions}
            selectAllItem={selectAllItem}
            selected={selectedItem.length}
            selectedItems={selectedItem}
            onFiltersClick={() => {
              setFilterDrawer({ ...filterDrawer, opened: !filterDrawer.opened })
              setSelectAll(false)
            }}
            resetVisible={!!searchText || !!filterDrawer?.filterValues}
            resetFilters={() => {
              setFilterDrawer({
                filterOptions: null,
                filterValues: null,
                opened: false,
              })
              setSelectAll(false)
              setSearchText('')
              refetch({
                filter: { ...defaultFilter },
                perPage: 0,
              })
            }}
          />

          <Drawer
            opened={drawer.addGroup}
            toggleDrawer={() => { }}
            totalWidth="900px"
          >
            {loading ? (
              <div>Loading...</div>
            ) : (
              <AddGroupInCourseDrawer
                onClose={() =>
                  setAddGroupDrawer({ ...drawer, addGroup: false })
                }
                groups={data.getAllGroups}
                selectedGroup={props.selectedGroup}
                selectGroup={props.selectGroup}
                refetch={refetch}
                companyId={state.selectedCompanyId}
              />
            )}
          </Drawer>

          <Drawer
            opened={drawer.createGroup}
            toggleDrawer={() => { }}
            totalWidth="44%"
          >
            <AddGroupDrawer
              refetch={() => {
                refetch()
                setAddGroupDrawer({ ...drawer, createGroup: false })
              }}
              setFormData={() =>
                setAddGroupDrawer({ ...drawer, createGroup: false })
              }
              onCancel={() =>
                setAddGroupDrawer({ ...drawer, createGroup: false })
              }
              companyId={state.selectedCompanyId || state.currentUser.companyId}
            />
          </Drawer>

          <Drawer
            opened={drawer.editGroup}
            toggleDrawer={() => {
              setAddGroupDrawer({ ...drawer, editGroup: false })
              setCurrentGroupId('')
            }}
            totalWidth="44%"
          >
            <EditGroupDrawer
              id={currentGroupId}
              closeDrawer={() => {
                setAddGroupDrawer({ ...drawer, editGroup: false })
                setCurrentGroupId('')
              }}
            />
          </Drawer>
          <Drawer
            opened={filterDrawer.opened}
            toggleDrawer={() => { }}
            totalWidth="600px"
          >
            <FilterDrawer
              closeDrawer={() =>
                setFilterDrawer({ ...filterDrawer, opened: false })
              }
              filterOptions={filterDrawer}
              setFilterOptions={setFilterDrawer}
              refetchGroup={refetch}
              setCurrentPage={() => { }}
              filter={filterData}
              defaultFilter={defaultFilter}
              companyId={state.selectedCompanyId}
              useCompanyId={true}
              hasSearchByCompany={hasSearchByCompany}
            />
          </Drawer>
        </Container>
      </FormGroupWrapper>

      <FormFooter>
        <FormButtons multiCols={true}>
          <Button
            text={t('actions.cancel')}
            type="medium"
            onClick={props.handleCloseDrawer}
            background="#F5F5F5"
            buttonStyles={{
              borderRadius: '8px',
            }}
            textColor={validationRed}
            outline={`1px solid ${red}`}
          />

          <Button
            text={t('actions.previous')}
            type="medium"
            color="secondary"
            onClick={() => props.handleButtonClick('previous')}
            background="#ffffff"
            textColor="#080A0A8A"
            outline="1px solid #080A0A14"
            buttonStyles={{
              borderRadius: '8px',
            }}
          />
          <Button
            text={t('actions.next')}
            type="medium"
            color="secondary"
            onClick={() => props.handleButtonClick('next')}
            background={'#317BF4'}
            buttonStyles={{
              borderRadius: '8px',
            }}
          />
        </FormButtons>
      </FormFooter>
    </>
  )
}

export default Step2

const Container = styled.div`
  padding: 15px;
`
