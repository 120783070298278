import styled from 'styled-components'

export const ModuleContentDetailsContainer = styled.div`
  margin-top: 40px;
`

export const ModuleContentDetailsHeader = styled.div`
  padding-bottom: 6px;
  margin-left: 10px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
`
export const ModuleContentDetails = styled.ul`
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ModuleContentDetail = styled.li`
  strong {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 16px;
  }
  span {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 16px;
  }
`
export const NoVideoContainer = styled.div`
  width: 100%;
  height: auto;
`

export const CourseList = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
`
export const CourseItem = styled.span`
  display: inline-block;
  padding: 9px 15px;
  border: 1px solid #080a0a14;
  border-radius: 8px;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.text.primary};
  &:not(:last-child) {
    margin-right: 5px;
  }
`
