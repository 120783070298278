import { mainColor } from 'components/common/Colors/CommonColors'
import styled from 'styled-components'

export const Card = styled.div`
  cursor: pointer;
  background: #fff;
  padding: 0px;
  border: 1px solid #ebebeb;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url('/svg/questionBG.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
`
export const Header = styled.header`
  padding: 20px;
  padding-bottom: 10px;
`

export const Type = styled.h2`
  font-size: 16px;
  font-weight: 500;
  /* color: #D2364B; */
`

export const Tags = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
`

export const TagItem = styled.div`
  margin-bottom: 5px;
  &:not(:last-child) {
    margin-right: 5px;
  }
`

export const Content = styled.section`
  font-size: 13px;
  padding: 20px 0;
  width: 100% !important;
`

export const Question = styled.article`
  padding-top: 30px;
  color: #080a0ad6;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`

export const FilesWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #d2364b;

  &:not(:last-child) {
    margin-right: 6px;
  }

  svg {
    width: 16px;
    margin-right: 2px;
  }
`
export const Edited = styled.div`
  display: flex;
  align-items: center;
  color: #333;
  opacity: 0.7;
  text-transform: uppercase;
  font-size: 11px;

  svg {
    margin-right: 5px;
  }
`

export const QuestionIcon = styled.span`
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
  svg {
    width: 30px;
    height: 30px;
  }
`

export const Footer = styled.footer`
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #ebebeb;
  text-align: center;
  color: #080a0a8a;
`

export const Modules = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 13px;
  font-style: italic;

  .with-modules {
    svg {
      fill: ${mainColor};
    }
  }
`

export const ModuleItemWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 2px 6px;
  background: #fff;
  color: ${mainColor};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  &:not(:last-child) {
    margin-right: 5px;
  }
  /* transition: color 0.3s ease, background 0.3s ease, border 0.3s ease; */

  &:hover {
    cursor: default;
  }

  &.disabled {
    opacity: 0.7;
    /* pointer-events: none; */
  }

  svg {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    fill: ${mainColor};
    transition: fill 0.2s ease;

    &:hover {
      fill: #d2364b;
    }
  }
`

export const ModuleItem = styled.div``

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`
