import Checkbox from 'components/common/Checkbox'
import { CheckBoxAndAvatarContainer } from 'pages/companyWizard/CompanyStudents/gridConfig'
import React from 'react'
import styled from 'styled-components'
import { dateConvertToLocaleDate } from 'utils/utils'

interface itemProps {
  id: string
  isChecked: boolean
  name: string
  companyEmail: string
  address: string
  phone: string
  companyActivity: any
  status: string
  createDate: string
  coverImage: null
  internalCoursesCount: string
  platformCoursesCount: string
  studentsCount: string
  platformService: any
}

export const columnConfig = (selectItem: any, theme: any, t?: any) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={item.isChecked}
            onChange={() => {
              selectItem(item.id)

            }}
          />
          <img
            // onClick={() => handelClick(item.name, item.id, item.status)}
            src={item.coverImage || '/svg/companyAvatar.svg'}
            alt="Avatar"
          />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'cardType',
      label: `${t('general.cardType')}`,
      render: (item: any) => (

        <Text theme={theme} style={{ cursor: 'pointer' }}>
          {item.cardType ? item.cardType : 'N/A'}
        </Text>
      ),
    },
    {
      id: 'iPayPaymentId',
      label: `${t('general.ipay_identifier')}`,
      render: (item: any) => (
        <Text theme={theme} style={{ cursor: 'pointer' }}>
          {item.iPayPaymentId ? item.iPayPaymentId : 'N/A'}
        </Text>
      ),
    },
    {
      id: 'pan',
      label: `${t('general.cardCode')}`,
      render: (item: any) => (
        <Text theme={theme} style={{ cursor: 'pointer' }}>
          {item.pan ? item.pan : 'N/A'}
        </Text>
      ),
    },
    {
      id: 'paymentMethod',
      label: `${t('general.paymentMethod')}`,
      render: (item: any) => (
        <Text theme={theme} style={{ cursor: 'pointer' }}>
          {item.paymentMethod ? item.paymentMethod : 'N/A'}
        </Text>
      ),
    },
    {
      id: 'status',
      label: `${t('general.status')}`,
      render: (item: any) => (
        <Text
          theme={theme}
          style={{
            cursor: 'pointer',
            color: item.status === 'success' ? '#269f5a' : '#cc3131',
          }}
        >
          {item.status ? item.status : 'N/A'}
        </Text>
      ),
    },
    {
      id: 'statusDescription',
      label: `${t('general.statusDescription')}`,
      render: (item: any) => (
        <Text theme={theme} style={{ cursor: 'pointer' }}>
          {item.statusDescription ? item.statusDescription : 'N/A'}
        </Text>
      ),
    },
    {
      id: 'transactionId',
      label: `${t('general.transaction_identifier')}`,
      render: (item: any) => (
        <Text theme={theme} style={{ cursor: 'pointer' }}>
          {item.transactionId ? item.transactionId : 'N/A'}
        </Text>
      ),
    },
    {
      id: 'userFullName',
      label: `${t('general.user')}`,
      render: (item: any) => (
        <Text theme={theme} style={{ cursor: 'pointer' }}>

          {item.userEmail ? item.userEmail : 'N/A'}
        </Text>
      ),
    },
    {
      id: 'boughtCourseName',
      label: `${t('form_fields.price')}`,
      render: (item: any) => (
        <Text theme={theme} style={{ cursor: 'pointer' }}>
          {item?.subscriptionProduct?.price ? `${item?.subscriptionProduct?.price} ${item?.subscriptionProduct?.currency}` : 'N/A'}
        </Text>
      ),
    },
    {
      id: 'transactionDate',
      label: `${t('general.date')}`,
      render: (item: any) => (
        <Text theme={theme} style={{ cursor: 'pointer' }}>
          {item.createDate ? dateConvertToLocaleDate(item.createDate) : 'N/A'}
        </Text>
      ),
    },
  ]

  return grid
}

const Text = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  font-weight: 900;
  padding: 20px;
`
