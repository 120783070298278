import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { GET_QUIZ_BY_ID } from 'gql/quiz/quiz.query'
import { useData } from 'context/DataContext'
import { useAction } from 'store/actions'
import { SET_FORMDATA } from 'store/types'
import { useTranslation } from 'react-i18next'
import { QuizSchema, IQTestSchema } from 'helpers/validationSchemas'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  CloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import { Button } from 'components/common/Button'
import gql from 'graphql-tag'

import { useEditQuizService } from 'hooks/quizzes/useEditQuizService'

import Step1 from './Step1'
import Step2 from './Step2'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { useTheme } from '@material-ui/core'

const GET_QUESTIONS_BY_COURSE = gql`
  query($courseIds: [String]) {
    questionsByCourseIds(courseIds: $courseIds) {
      id
    }
  }
`
const GET_QUESTIONS = gql`
  query($type: String!, $id: String!, $filter: QuestionFilter) {
    getQuestions(type: $type, id: $id, filter: $filter) {
      data {
        id
      }
    }
  }
`

const formData = {
  name: '',
  timeType: '',
  time: '',
  quizParticipationType: '',
  passRate: '',
  feedback: false,
  tags: [],
  IQTestType: '',
  position: '',
  randomization: false,
  randomizationNumber: '',
}

const EditQuizDrawer = (props: any) => {
  const { t } = useTranslation()
  const validationSchema = props.data.IQTest ? IQTestSchema : QuizSchema
  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()
  const [step, setStep] = useState<number>(0)
  const [validation, setValidation] = useState<any>(validationSchema)
  const [currentQuiz, setCurrentQuiz] = useState<any>('')

  const { data } = useQuery(GET_QUIZ_BY_ID, {
    variables: {
      quizId: props.data.quizId,
    },
  })
  const [fetchModuleQuestions, { data: moduleQuestions }] = useLazyQuery(
    GET_QUESTIONS,
  )
  const [fetchCourseQuestions, { data: courseQuestions }] = useLazyQuery(
    GET_QUESTIONS_BY_COURSE,
  )

  useEffect(() => {
    if (props.type === 'course') {
      fetchCourseQuestions({
        variables: {
          courseIds: [props.id],
        },
      })
    } else {
      fetchModuleQuestions({
        variables: {
          type: props.type,
          id: props.id,
        },
      })
    }
  }, [props.id, props.type])

  const { editQuiz } = useEditQuizService('0')

  const questionList =
    props.type === 'course'
      ? (courseQuestions && courseQuestions.questionsByCourseIds) || []
      : (moduleQuestions && moduleQuestions.getQuestions.data) || []

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: formData,
    validationSchema: Yup.object(validation),
    onSubmit: (values: any) => {
      const data = { ...values }

      if (!data.time || data.time === 0) {
        data.time = 0
        delete data.timeType
      }

      if (data.tags && data.tags.length > 0) {
        data.tags = data.tags.map((i: any) => ({ label: i.label }))
      }
      if (values?.position !== '') {
        data.position = values?.position?.value
      } else {
        delete data.position
      }

      if (!data.randomization) data.randomizationNumber = 0

      if (data.quizParticipationType === 'optional') delete data.passRate

      if (data.IQTestType) data.IQTestType = values.IQTestType.value

      if (props.data.IQTest) {
        data.IQTestType = 'initial'
      }

      editQuiz(props.data.quizId, data, onSuccess)
    },
  })

  const onSuccess = () => {
    props.onClose()
    toggleDrawerConfirm(false, '')
  }

  const handleCloseDrawer = () => {
    const { quizParticipationType, ...formData } = values
    const { quizParticipationType: estra, ...currentQuizData } = currentQuiz
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editQuiz',
        values: formData,
        compareTo: currentQuizData,
      },
    })
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  const { time, quizParticipationType, randomization } = values

  useEffect(() => {
    if (time) {
      setValidation({
        ...validation,
        timeType: Yup.string().required('Time Type is Required'),
      })
    } else {
      setValidation(validationSchema)
    }
  }, [time])

  useEffect(() => {
    if (randomization) {
      setValidation({
        ...validation,
        randomizationNumber: Yup.number()
          .max(
            questionList.length,
            `${t('validations.number_of_questions')} ${questionList.length}`,
          )
          .required(
            `${t('quiz_details.number_of_questions')} ${t(
              'validations.required',
            )}`,
          ),
      })
    } else {
      setValidation(validationSchema)
      delete errors.randomizationNumber
      values.randomizationNumber = ''
      delete touched.randomizationNumber
    }
  }, [randomization, questionList])

  useEffect(() => {
    if (quizParticipationType === 'mandatory') {
      setValidation({
        ...validation,
        passRate: Yup.number().required('Pass Rate is Required'),
      })
    } else {
      setValidation(validationSchema)
    }
  }, [quizParticipationType])

  useEffect(() => {
    if (data && data.getQuizById) {
      const dataClone: any = {}
      for (let [key] of Object.entries(formData)) {
        dataClone[key] =
          key === 'tags' && data.getQuizById[key]
            ? data.getQuizById[key].map((i: any) => ({
              label: i.label,
              value: i.id,
            }))
            : data.getQuizById[key]
      }
      setValues(dataClone)
      setCurrentQuiz(dataClone)
    }
  }, [data])

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            errors={errors}
            values={values}
            touched={touched}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            IQTest={props.data.IQTest}
            type={props.type}
            quizList={props.data.quizzes}
            questionListSize={questionList.length}
            t={t}
          />
        )
      case 1:
        return <Step2 />
      default:
        return 'Error'
    }
  }

  const theme = useTheme();

  return (
    <>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>
          {props.data.IQTest
            ? `${t('quizzes_layout.edit_iq_test')}`
            : `${t('quizzes_layout.edit_quiz')}`}
        </DrawerTitle>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>

      <DrawerContent>
        <FormContainer onFormSubmit={(e: any) => e.preventDefault()}>
          <FormGroupWrapper>{getStepContent(step)}</FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />

              <Button
                text={t('actions.save')}
                type="medium"
                onClick={handleSubmit}
                background={'#317BF4'}
                buttonStyles={{
                  borderRadius: '8px',
                }}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default EditQuizDrawer
