import { Button } from 'components/common/Button'
import { CircularProgressBarWithLabel } from 'components/common/CircularProgressBar/CircularProgressBar'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

interface QuizProps {
  item: any
  quizType: string
  displayStartButton?: boolean
}

const ModuleQuizzes = ({ item, quizType, displayStartButton }: QuizProps) => {
  const { t } = useTranslation()
  const { id: courseId } = useParams<{ id: string }>()
  const history = useHistory()
  const redirectToQuizPage = useCallback(() => {
    if (courseId && item?.id) {
      history.push(`/course/${courseId}/quizz-details/${item.id}`)
    }
  }, [courseId, item])

  return (
    <QuizWrapper>
      <AvatarWrapper>
        <img src="/img/quiz_avatar.svg" alt="" />
      </AvatarWrapper>
      <TitleWrapper>
        {item.name} ({quizType === 'module' && t('quiz_details.module_quiz')}
        {quizType === 'topic' && t('quiz_details.topic_quiz')}
        {quizType === 'lesson' && t('quiz_details.lesson_quiz')})
      </TitleWrapper>
      <QuestionTimeWrapper>
        {item.questionsCount} {t('general.question')} | {item.time}{' '}
        {t('general.minutes')}
      </QuestionTimeWrapper>
      <Wrapper>
        {item.score !== null && (
          <CircularProgressBarWithLabel
            strokeWidth={1}
            progressStrokeWidth={2.6}
            size={40}
            progress={item?.score}
            innerFontSize={11}
          />
        )}
        {displayStartButton && (
          <span style={{ marginBottom: '10px' }}>
            <Button
              text={
                item.score !== null ? t('quiz.retake') : t('quiz_details.start')
              }
              width={127}
              height={17}
              onClick={redirectToQuizPage}
              isDisabled={item.blocked}
            />
          </span>
        )}
      </Wrapper>
    </QuizWrapper>
  )
}

export default ModuleQuizzes

const QuizWrapper = styled.div`
  width: 100%;
  height: 40px;
  background-color: #cce9ff80;
  border: 0.5px solid #7070704d;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AvatarWrapper = styled.div`
  width: 24px;
  height: 27px;
  margin-left: 12px;
`

const TitleWrapper = styled.div`
  margin-left: 12px;
`
const QuestionTimeWrapper = styled.div`
  font-size: 16px;
  letter-spacing: 0px;
  color: #414141;
  position: absolute;
  right: 400px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: auto;
  margin-right: 8px;
`
