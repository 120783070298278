import { useQuery } from '@apollo/react-hooks'
import { SEARCH_COURSES } from './../../gql/course/course.query'

export const PER_PAGE = 10

export const useSearchCourses = (
  filter: any,

) => {
  const { data, loading, error, refetch, fetchMore } = useQuery(
    SEARCH_COURSES,
    {
      variables: { filter },
      notifyOnNetworkStatusChange: true
    }
  )

  const errorMessage = error ? error?.message.split(':')[1] : ''

  return {
    courses: data && data.getCoursesGroupedBySection,
    loading,
    error: errorMessage,
    refetch,
    fetchMore,
  }
}
