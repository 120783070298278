import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { RouteComponentProps } from '@reach/router'

import { getToken } from 'helpers/localStorage'
import { useUserValue } from 'context/UserContext'
import Login from 'pages/auth/Login'

interface PrivateRouteProps extends RouteComponentProps {
  component: React.ComponentType<any>
  exact?: boolean
  filterQuery?: any
  setFilterQuery?: any
  roleAccess?: string[]
}

const PrivateRoute = ({
  component: Component,

  roleAccess = [],
  exact = false,
  ...rest
}: PrivateRouteProps) => {
  const [state] = useUserValue()
  const token = getToken()
  const isAuthenticated = token ? true : false

  return (
    <Route
      exact={exact}
      {...rest}
      render={props => {
        if (isAuthenticated) {
          if (roleAccess.length !== 0) {
            if (state.currentUser.role) {
              if (roleAccess.includes(state.currentUser.role.toLowerCase())) {
                return <Component {...props} />
              } else {
                return <Redirect to="/" />
              }
            }
          }
          return <Component {...props} />
        }

        return <Login />
        // return <Redirect to="/login" />
      }}
    />
  )
}

export default PrivateRoute
