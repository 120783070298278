import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import updateUserStatusService from 'services/users/updateUserStatusService'
import { useTranslation } from 'react-i18next'

const useUpateStatusUserService = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [user, setUser] = useState('')
  const [error, setError] = useState('')

  const { updateUserStatusMutation, loading } = updateUserStatusService(params)

  const updateUserStatus = async (ids: string[], status: string) => {
    let message: string = ''
    let variant:
      | 'default'
      | 'error'
      | 'warning'
      | 'success'
      | 'info'
      | undefined
    const { data, errors } = await updateUserStatusMutation({
      variables: {
        ids,
        status,
      },
    })

    if (errors) {
      // eslint-disable-next-line no-console
      console.log(errors)
      message = t(`error_messages.${errors[0].message}`)
      variant = 'error'
    } else {
      const { role, status } = data.updateUserStatus[0]
      const single = data.updateUserStatus.length === 1
      const sufix =
        !single && role.toLowerCase().slice(-2) === 'ch' ? 'es' : 's'
      message =
        status === 'ACTIVE'
          ? `${role.toLowerCase()}${single ? '' : sufix} ${t(
              'messages.activated',
            )}`
          : `${role.toLowerCase()}${single ? '' : sufix} ${t(
              'messages.suspended',
            )}`
      variant = 'success'
      // message = `User has been ${status === 'ACTIVE' ? 'Activated' : 'Suspended'}`
      // variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    updateUserStatus,
    updateUserStatusLoaing: loading,
    user,
    error,
  }
}

export default useUpateStatusUserService
