import React from 'react'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'

import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import { CheckBoxAndAvatarContainer } from 'pages/companyWizard/CompanyStudents/gridConfig'
import Checkbox from 'components/common/Checkbox'

interface itemProps {
  id: string
  isChecked: boolean
  name: string
  lessons: any
  coverImage: any
  courses: any[]
  // numberOfTopics: any
  // numberOfLessons: any
  numberOfCourses: any
  duration: any
  imgLink: any
}

export const columnConfigForSections = (handelClick: any, selectItem: any, t?: any, theme?: any) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={item.isChecked}
            onChange={() => selectItem(item.id)}
          />
          {item.coverImage?.link
            ? <img
              onClick={() => handelClick(item.id)}
              src={item.coverImage.link || ''}
              alt="Avatar"
            />
            : null
          }
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'sectionName',
      numeric: false,
      disablePadding: true,
      center: true,
      label: `${t('general.sectionName')}`,
      render: (item: itemProps) => (
        <div onClick={() => handelClick(item.id)} style={{ cursor: 'pointer' }}>
          {item.name}
        </div>
      ),
    },
    {
      id: 'coursesNumber',
      label: `${t('general.course')}`,
      render: (item: itemProps) => (
        <CountContainer theme={theme}>{item.courses.length}</CountContainer>
      ),
    },
  ]

  return grid
}

export const actionConfigForSections = (
  actionHandler: any,
  permissions: any,
  t: any,
  canModify?: boolean,
) => {
  let action = [
    // {
    //   render: (item: any) => (
    //     <Action onClick={() => actionHandler('view', item.id)}>
    //       <ViewCompactIcon />
    //       <span>{t('actions.view')}</span>
    //     </Action>
    //   ),
    // },
    {
      hide: !canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('edit', item)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('delete', item)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
  ]

  return action.filter((i: any) => !i.hide)
};

export const columnConfigForSectionCourses = (handelClick: any, selectItem: any, t?: any, theme?: any) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={item.isChecked}
            onChange={() => selectItem(item.id)}
          />
          {item.coverImage?.link
            ? <img
              onClick={() => handelClick(item.id)}
              src={item.coverImage.link || ''}
              alt="Avatar"
            />
            : null
          }
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'courseName',
      numeric: false,
      disablePadding: true,
      center: true,
      label: `${t('general.courseName')}`,
      render: (item: itemProps) => (
        <div onClick={() => handelClick(item.id)} style={{ cursor: 'pointer' }}>
          {item.name}
        </div>
      ),
    },
  ]

  return grid
}

export const columnConfigForNonSectionCourses = (handelClick: any, selectItem: any, t?: any, theme?: any) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={item.isChecked}
            onChange={() => selectItem({ id: item.id, name: item.name, imgLink: item.imgLink })}
          />
          {item.coverImage?.link
            ? <img
              onClick={() => handelClick(item.id)}
              src={item.coverImage.link || ''}
              alt="Avatar"
            />
            : null
          }
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'courseName',
      numeric: false,
      disablePadding: true,
      center: true,
      label: `${t('general.courseName')}`,
      render: (item: itemProps) => (
        <div onClick={() => handelClick(item.id)} style={{ cursor: 'pointer' }}>
          {item.name}
        </div>
      ),
    },
  ]

  return grid
}



export const actionConfigForSectionCourses = (
  actionHandler: any,
  permissions: any,
  t: any,
  canModify?: boolean,
) => {
  let action = [
    {
      render: (item: any) => (
        <Action onClick={() => actionHandler('view', item.id)}>
          <ViewCompactIcon />
          <span>{t('actions.view')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('delete', item)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
  ]

  return action.filter((i: any) => !i.hide)
}

export const actionConfigForNonSectionCourses = (
  actionHandler: any,
  permissions: any,
  t: any,
  canModify?: boolean,
) => {
  let action = [
    {
      render: (item: any) => (
        <Action onClick={() => actionHandler('view', item.id)}>
          <ViewCompactIcon />
          <span>{t('actions.view')}</span>
        </Action>
      ),
    },
  ]

  return action.filter((i: any) => !i.hide)
}






const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

const CountContainer = styled.div`
  width: 36px;
  height: 36px;
  background: #818181;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ffffff
`
