import React from 'react'
import UsersLayout from 'components/UsersLayout'

const StudentsLayout = () => {

  return (
    <div>
      <UsersLayout
        role="STUDENT"
        addText="Create Student"
        addUserDrawer="addStudent"
        editUserDrawer="editStudent"
        editUserSubmission="editUserSubmission"
      />
    </div>
  )
}

export default StudentsLayout
