import { useQuery } from '@apollo/react-hooks'

import { GET_ALL_COURSE } from 'gql/course/course.query'

export const PER_PAGE = 12

export const useFetchCourses = (
  filter: any,
  currentPage: number = 1,
  perPage: number = PER_PAGE,
  fetchAll?: boolean
) => {

  // filter = { companyId: { "type": "exact", "value": null }, name: { "type": "match", value: "gelen" }, query: { type: "query", value: { "name": { "$regex": "gelen", "$options": "i" } } } }
  const { data, loading, error, refetch, fetchMore } = useQuery(
    GET_ALL_COURSE,
    {
      variables: { filter, currentPage, perPage, fetchAll },
    },
  )
  const errorMessage = error ? error?.message.split(':')[1] : ''

  return {
    courses: data && data.getAllCourse,
    loading,
    error: errorMessage,
    refetch,
    fetchMore,
  }
}

export const useFetchCoursesWithArgumets = (
  queryName: any,
  QUERY: any,
  filter: any,
  currentPage: number = 1,
  perPage: number = PER_PAGE,
) => {
  const { data, loading, error, refetch, fetchMore } = useQuery(QUERY, {
    variables: { filter, currentPage, perPage },
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  return {
    courses: data && data?.[queryName],
    loading,
    error: errorMessage,
    refetch,
    fetchMore,
  }
}
