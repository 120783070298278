import i18n from 'i18n/index'

interface FilterOptions {
  name: string
  status: any
  numberOfStudents: number[]
  company: any
  admin: any
  course: any
  location: string
  note: string
  createDate: any

}
export let statuses: any = []

export let filterOptions: FilterOptions = {
  name: '',
  status: { label: i18n.t('general.all'), value: 'all' },
  admin: null,
  numberOfStudents: [0, 0],
  company: null,
  course: null,
  createDate: [null, null],
  location: '',
  note: ''
}

function translate() {
  statuses = [
    { value: 'all', label: i18n.t('general.all') },
    { value: 'DRAFT', label: i18n.t('general.draft') },
    { value: 'ACTIVE', label: i18n.t('general.active') },
    { value: 'SUSPENDED', label: i18n.t('general.suspended') },
  ]

  filterOptions = {
    name: '',
    status: { label: i18n.t('general.all'), value: 'all' },
    admin: null,
    numberOfStudents: [0, 0],
    company: null,
    course: null,
    createDate: [null, null],
    location: '',
    note: ''
  }
}

translate()

i18n.on('languageChanged init', () => {
  translate()
})
