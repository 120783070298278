import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import StudentNotes from "./StudentNotes";
import { module } from "../../types";
import { Text } from '../../styled';
import { useUserValue } from 'context/UserContext';

function getLink(lesson: module.Lesson) {
  if (lesson.audio && lesson.audio.link) return lesson.audio.link
  if (lesson.video && lesson.video.links[0]) return lesson.video.links[0].url
  return ""
}

export type Note = {
  id: string
  title: string
  description: string
  moment: number
  actionId: string
  thumbnail: string
  createDate: Date
};

type NotesProps = {
  color: string
  lesson: module.Lesson
  model: "LESSON" | "SKILLTESTVIDEO"
  courseId: string
  currentLessonNotesCount: number,
  refetchGetUser: () => any
}

const Notes = ({ color, lesson, model, courseId, currentLessonNotesCount, refetchGetUser }: NotesProps) => {
  const [openNotes, setOpenNotes] = useState(false);
  const { t } = useTranslation();
  const [state] = useUserValue();

  const handleNoteClick = () => {
    if (currentLessonNotesCount >= 1) {
      setOpenNotes(true)
    }
  };

  return (
    <>
      {openNotes &&
        <StudentNotes
          refetchGetUser={refetchGetUser}
          opened={openNotes}
          totalWidth={'900px'}
          onClose={() => setOpenNotes(!openNotes)}
          url={getLink(lesson)}
          lesson={lesson}
          courseId={courseId}
          model={model}
        />}
      <Text fs={'12px'} color={color}>
        <button
          onClick={handleNoteClick}
          style={{
            background: 'transparent',
            border: 'none',
            outline: 'none',
            color: '#5290F6',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
        >
          {currentLessonNotesCount} {t("form_fields.note")}
        </button>
      </Text>
    </>
  )
}

export default Notes;
