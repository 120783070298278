import gql from 'graphql-tag'

export const CREATE_COURSE = gql`
  mutation($input: CourseInput, $groupIds: [String]) {
    createCourse(input: $input, groupIds: $groupIds) {
      id
      name
      description
      editorText
      level
      coursePrivacy
      coachType
      price
      currency
      needDefaultGroup
      certificate {
        name
        fileType
        link
      }
      avatar {
        name
        fileType
        link
      }
      state
      subtitle {
        name
        fileType
        link
      }
      skills {
        label
        id
      }
      coaches {
        firstName
        lastName
        email
        avatar
      }
      groups {
        groupId
        name
      }
      numberOfGroups
      numberOfStudents
      numberOfModules
      numberOfLessons
      duration
      video {
        id
        name
        thumbnail
        title
        links {
          url
          fileType
          converted
        }
      }
      companyId {
        id
        name
      }
      finished
      categories {
        id
        name
      }
      contentLocked
    }
  }
`

export const GET_ALL_COURSE = gql`
  query(
    $filter: CourseFilterInput
    $currentPage: Int
    $perPage: Int
    $userId: String
    $fetchAll: Boolean
  ) {
    getAllCourse(
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
      userId: $userId
      fetchAll: $fetchAll
    ) {
      currentPage
      totalPages
      courseCount
      data {
        index
        id
        name
        progress
        completed
        description
        editorText
        level
        coursePrivacy
        coachType
        price
        currency
        originalId
        isBought
        certificate {
          name
          fileType
          link
        }
        avatar {
          name
          fileType
          link
        }
        state
        subtitle {
          name
          fileType
          link
        }
        skills {
          label
          id
        }
        coaches {
          firstName
          lastName
          email
          id
          userId
          avatar
        }
        groups {
          groupId
          name
        }
        numberOfGroups
        numberOfStudents
        numberOfModules
        numberOfLessons
        duration
        video {
          id
          name
          thumbnail
          title
          links {
            url
            fileType
            converted
          }
        }
        companyId {
          id
          name
        }
        finished
        categories {
          id
          name
        }
        contentLocked
      }
    }
  }
`

export const GET_ALL_EXPLORE_COURSES_FOR_COMPANY = gql`
  query(
    $filter: CourseFilterInput
    $currentPage: Int
    $perPage: Int
    $userId: String
  ) {
    getAllExploreCourseForCompany(
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
      userId: $userId
    ) {
      currentPage
      totalPages
      data {
        id
        name
        description
        editorText
        level
        coursePrivacy
        coachType
        price
        currency
        originalId
        isBought
        certificate {
          name
          fileType
          link
        }
        avatar {
          name
          fileType
          link
        }
        state
        subtitle {
          name
          fileType
          link
        }
        skills {
          label
          id
        }
        coaches {
          firstName
          lastName
          email
          id
          userId
          avatar
        }
        groups {
          groupId
          name
        }
        numberOfGroups
        numberOfStudents
        numberOfModules
        numberOfLessons
        duration
        video {
          id
          name
          thumbnail
          title
          links {
            url
            fileType
            converted
          }
        }
        companyId {
          id
          name
        }
        finished
        categories {
          id
          name
        }
        contentLocked
      }
    }
  }
`

export const GET_ALL_SUGGESTED_COURSE = gql`
  query($filter: CourseFilterInput, $currentPage: Int, $perPage: Int, $includePurchasedCourses: Boolean) {
    getSuggestedCourses(
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
      includePurchasedCourses: $includePurchasedCourses
    ) {
      totalPages
      currentPage
      data {
        id
        name
        description
        categories {
          id
          name
        }
        editorText
        level
        coursePrivacy
        coachType
        price
        currency
        originalId
        isBought
        certificate {
          name
          fileType
          link
        }
        avatar {
          name
          fileType
          link
        }
        state
        subtitle {
          name
          fileType
          link
        }
        skills {
          label
          id
        }
        coaches {
          firstName
          lastName
          email
          id
          userId
          avatar
        }
        groups {
          groupId
          name
        }
        numberOfGroups
        numberOfStudents
        numberOfModules
        numberOfLessons
        duration
        video {
          id
          name
          thumbnail
          title
          links {
            url
            fileType
            converted
          }
        }
        companyId {
          id
          name
        }
        finished
      }
    }
  }
`
export const SEARCH_COURSES = gql`
query($filter: CourseFilterInput){
	getCoursesGroupedBySection(
    filter :$filter
  ){
      name
      courses{
        id
        name
        description
        categories {
          id
          name
        }
        editorText
        isOwnCourse
        level
        coursePrivacy
        coachType
        price
        currency
        originalId
        isBought
        certificate {
          name
          fileType
          link
        }
        avatar {
          name
          fileType
          link
        }
        state
        subtitle {
          name
          fileType
          link
        }
        skills {
          label
          id
        }
        coaches {
          firstName
          lastName
          email
          id
          userId
          avatar
        }
        groups {
          groupId
          name
        }
        numberOfGroups
        numberOfStudents
        numberOfModules
        numberOfLessons
        duration
        # video {
        #   id
        #   name
        #   thumbnail
        #   title
        #   links {
        #     url
        #     fileType
        #     converted
        #   }
        # }
        companyId {
          id
          name
        }
        finished
      }
  }
}
`
export const TRACK_PROGRESS = gql`
  mutation($courseId: String!, $lessonId: String!, $duration: Int!) {
    trackProgress(courseId: $courseId, lessonId: $lessonId, duration: $duration)
  }
`

export const GET_COURSE = gql`
  query($courseId: String!) {
    getCourseById(courseId: $courseId) {
      id
      index
      numberOfRating
      averageRating
      isOwnCourse
      certificateIncluded

      lastWatchedLesson {
        moduleIndex
        topicIndex
        lessonIndex
      }
      IQTests {
        id
        parentName
        quizType
        name
        IQTestType
        quizParticipationType
        passRate
        questionsCount
        score
        blocked
        tags {
          label
          id
        }
        timeType
        time
        randomization
      }
      name
      description
      editorText
      level
      progress
      completed
      coursePrivacy
      duration
      coachType
      price
      currency
      isBought
      originalId
      certificate {
        name
        fileType
        link
      }
      price
      currency
      avatar {
        name
        fileType
        link
      }
      state
      subtitle {
        name
        fileType
        link
      }
      skills {
        label
        id
      }
      coaches {
        firstName
        averageRating
        lastName
        email
        id
        userId
        avatar
      }
      groups {
        groupId
        name
      }
      numberOfGroups
      numberOfStudents
      numberOfModules
      numberOfLessons
      duration
      video {
        id
        title
        name
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
      companyId {
        id
        name
      }
      finished
      categories {
        id
        name
      }
      contentLocked
      certificateIncluded
    }
  }
`
export const GET_COURSE_BY_ID_FOR_COACH = gql`
  query($courseId: String!) {
    getCourseByIdForCoach(courseId: $courseId) {
      id
      numberOfRating
      averageRating
      isOwnCourse
      certificateIncluded
      lastWatchedLesson {
        moduleIndex
        topicIndex
        lessonIndex
      }
      IQTests {
        id
        parentName
        quizType
        name
        IQTestType
        quizParticipationType
        passRate
        questionsCount
        score
        blocked
        tags {
          label
          id
        }
        timeType
        time
        randomization
      }
      name
      description
      editorText
      level
      progress
      completed
      coursePrivacy
      duration
      coachType
      price
      currency
      isBought
      originalId
      certificate {
        name
        fileType
        link
      }
      price
      currency
      avatar {
        name
        fileType
        link
      }
      state
      subtitle {
        name
        fileType
        link
      }
      skills {
        label
        id
      }
      coaches {
        firstName
        averageRating
        lastName
        email
        id
        userId
        avatar
      }
      groups {
        groupId
        name
      }
      numberOfGroups
      numberOfStudents
      numberOfModules
      numberOfLessons
      duration
      video {
        id
        title
        name
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
      companyId {
        id
        name
      }
      finished
      categories {
        id
        name
      }
      contentLocked
    }
  }
`


export const GET_COURSES_BY_COACH = gql`
  query($currentPage: Int, $perPage: Int) {
    getCombinedCoursesForCoach(currentPage: $currentPage, perPage: $perPage) {
      totalPages
      currentPage
      totalCount
      data {
        id
        name
        description
        editorText
        level
        coursePrivacy
        price
        currency
        coachType
        coaches {
          userId
          addedDate {
            date
          }
          firstName
          lastName
          email
          avatar
          id
        }
        groups {
          name
          groupId
        }
        avatar {
          name
          size
          fileType
          link
        }
        skills {
          id
          label
        }
        state
        numberOfGroups
        numberOfStudents
        originalId
        isBought
        numberOfModules
        numberOfLessons
        numberOfTopics
        video {
          id
          name
          title
          thumbnail
          duration
          links {
            url
            fileType
            converted
          }
        }
        certificate {
          name
          size
          fileType
          link
        }
        companyId {
          id
          name
        }
        subtitle {
          name
          size
          fileType
          link
        }
        duration
        progress
        lastWatchedLesson {
          moduleIndex
          topicIndex
          lessonIndex
        }
        finished
        categories {
          id
          name
        }
        averageRating
        numberOfRating
        contentLocked
        completed
      }
    }
  }
`

export const EDIT_COURSE = gql`
  mutation($id: String!, $input: CourseInput, $groupIds: [String]) {
    editCourse(id: $id, input: $input, groupIds: $groupIds) {
      id
      index
      name
      description
      editorText
      level
       coursePrivacy
      price
      currency
      coachType
      price
      currency
       certificateIncluded
      certificate {
        name
        fileType
        link
      }
      avatar {
        name
        fileType
        link
      }
      state
      subtitle {
        name
        fileType
        link
      }
      skills {
        label
        id
      }
      coaches {
        firstName
        lastName
        email
        id
        userId
        avatar
      }
      groups {
        groupId
        name
      }
      numberOfGroups
      numberOfStudents
      numberOfModules
      numberOfLessons
      duration
      video {
        id
        name
        title
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
      companyId {
        id
        name
      }
      finished
      categories {
        id
        name
      }
      contentLocked
    }
  }
`

export const DELETE_COURSE = gql`
  mutation($ids: [String]) {
    deleteCourse(ids: $ids) {
      message
    }
  }
`

export const COURSE_PUBLISHED_UN_PUBLISHED = gql`
  mutation($courseId: [String], $action: String) {
    coursePublished(courseId: $courseId, action: $action) {
      id
      name
      description
      level
      coursePrivacy
      coachType
      price
      currency
      certificate {
        name
        fileType
        link
      }
      avatar {
        name
        fileType
        link
      }
      state
      subtitle {
        name
        fileType
        link
      }
      coaches {
        firstName
        lastName
        email
        id
        avatar
      }
      groups {
        groupId
        name
      }
      numberOfGroups
      numberOfStudents
      numberOfModules
      numberOfLessons
      duration
      video {
        name
        title
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
      companyId {
        id
        name
      }
    }
  }
`
export const DELETE_COURSE_MODULE = gql`
  mutation($courseId: String, $moduleIds: [String]) {
    deleteModuleInCourse(courseId: $courseId, moduleIds: $moduleIds) {
      id
      name
      coverImage {
        name
        fileType
        link
      }
      description
      editorText
      duration
      createDate
      numberOfTopics
      numberOfLessons
      duration
      # price
      # currency
      subtitle {
        name
        fileType
        link
      }
      topics {
        id
        name
        # lessons {
        #   id
        #   name
        # }
      }
      attachment {
        name
        fileType
        link
      }
      video {
        name
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
      # companyId
    }
  }
`


export const GET_COURSE_BY_ORIGINAL_ID = gql`
  query($courseId: String, $companyId: String) {
    getCourseByOriginalId(courseId: $courseId, companyId: $companyId) {
      id
      name
      description
      editorText
      level
      coursePrivacy
      duration
      coachType
      price
      currency
      isBought
      originalId
      certificate {
        name
        fileType
        link
      }
      price
      currency
      avatar {
        name
        fileType
        link
      }
      state
      subtitle {
        name
        fileType
        link
      }
      skills {
        label
        id
      }
      coaches {
        firstName
        lastName
        email
        id
        userId
        avatar
      }
      groups {
        groupId
        name
      }
      numberOfGroups
      numberOfStudents
      numberOfModules
      numberOfLessons
      duration
      video {
        id
        title
        name
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
      # companyId {
      #   id
      #   name
      # }
      finished
      contentLocked
    }
  }
`
export const SET_LAST_WATCHED_LESSON = gql`
  mutation(
    $courseId: String
    $moduleId: String
    $topicId: String
    $lessonId: String
  ) {
    setLastWatchedLesson(
      courseId: $courseId
      moduleId: $moduleId
      topicId: $topicId
      lessonId: $lessonId
    ) {
      moduleIndex
      topicIndex
      lessonIndex
    }
  }
`
export const GET_LAST_WATCHED_LESSON = gql`
  query($courseId: String!) {
    getLastWatchedLesson(courseId: $courseId) {
      moduleIndex
      topicIndex
      lessonIndex
    }
  }
`
export const PURCHASE_FREE_COURSE = gql`
  mutation($courseId: String) {
    purchaseFreeCourse(courseId: $courseId) {
      status
    }
  }
`

export const CHECK_COURSE_COMPLETION = gql`
  mutation($courseId: String!) {
    checkCourseCompletion(courseId: $courseId)
  }
`

export const CHANGE_COURSE_STEP = gql`
  mutation($courseId: String, $step: Float) {
    changeCourseStep(courseId: $courseId, step: $step) {
      id
      name
      description
      editorText
      level
      coursePrivacy
      duration
      coachType
      price
      currency
      isBought
      originalId
      certificate {
        name
        fileType
        link
      }
      price
      currency
      avatar {
        name
        fileType
        link
      }
      state
      subtitle {
        name
        fileType
        link
      }
      skills {
        label
        id
      }
      # coaches {
      #   firstName
      #   lastName
      #   email
      #   id
      #   userId
      #   avatar
      # }
      groups {
        groupId
        name
      }
      # numberOfGroups
      # numberOfStudents
      # numberOfModules
      # numberOfLessons
      # duration
      # video {
      #   id
      #   title
      #   name
      #   thumbnail
      #   links {
      #     url
      #     fileType
      #     converted
      #   }
      # }
      # companyId {
      #   id
      #   name
      # }
      finished
    }
  }
`
