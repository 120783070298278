import React from 'react'
import styled from 'styled-components'
import { useUserValue } from 'context/UserContext'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Button } from 'components/common/Button'
import { generateFullName } from 'utils/utils'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

const GroupDetails = (props: any) => {
  const [state] = useUserValue()

  const classes = useStyles()

  const data = (props.data && props.data.getGroupById) || {}

  const { t } = props



  return (
    <Container>
      <Card className={classes.root}>
        {props.loading ? (
          'Loading...'
        ) : (
          <>
            <CardContent>
              <Typography className={classes.pos} color="textSecondary">
                {t('general.name')}: {data.name}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                {t('form_fields.location')}: {data.location}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                {t('general.admin')}: {generateFullName(data.admin)}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                {t('form_fields.note')}: {data.note}
              </Typography>
            </CardContent>

            {state.userPermission.editGroup && (
              <CardActions>
                <Button
                  size="small"
                  onClick={props.drawerToggle}
                  text={t('actions.edit')}
                />
              </CardActions>
            )}
          </>
        )}
      </Card>
    </Container>
  )
}

export default GroupDetails

const Container = styled.div``
