import React from 'react'
import { TabNavigation, activeTabClassName } from 'components/common/Tabs/Tabs'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { Container } from '../styled-components'

import CompanyCourses from './CompanyCourses'
import CompanyGroups from './CompanyGroups'
import CompanyUsers from './CompanyUsers'
import styled from 'styled-components'
import { useTheme } from '@material-ui/core'

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

interface Params {
  currentTab: number
  handleTabChange: any
  variables: any
  addCoachIds: any
  defCurrentPage: number
  company: any
  t: any
}

const CompanyTabs = ({
  currentTab,
  handleTabChange,
  variables,
  addCoachIds,
  defCurrentPage,
  company,
  t,
}: Params) => {

  const theme = useTheme()
  return (
    <Container>
      <TabContainer theme={theme}>
        <TabNavigation transparent>
          <AppBar position="static" style={styles.bar}>
            <Tabs
              value={currentTab}
              onChange={(event: any, value: string) => handleTabChange(value)}
              aria-label="simple tabs example"
              variant="scrollable"
            >
              {/* <Tab
                label={t('general.purchased_courses')}
                {...activeTabClassName(0)}
                style={styles.btn}
              /> */}
              <Tab
                label={t('general.assigned_coaches')}
                {...activeTabClassName(1)}
                style={styles.btn}
              />
              {/* <Tab
                label={t('general.company_courses')}
                {...activeTabClassName(2)}
                style={styles.btn}
              /> */}
              <Tab
                label={t('general.company_coaches')}
                {...activeTabClassName(3)}
                style={styles.btn}
                disabled={!company?.platformService}
              />
              <Tab
                label={t('general.company_super_admins')}
                {...activeTabClassName(4)}
                style={styles.btn}
              />
              <Tab
                label={t('general.groups')}
                {...activeTabClassName(5)}
                style={styles.btn}
              />
              <Tab
                label={t('general.students')}
                {...activeTabClassName(6)}
                style={styles.btn}
              />
              <Tab
                label={t('company_wizard_layout.group_admins')}
                {...activeTabClassName(7)}
                style={styles.btn}
              />
            </Tabs>
          </AppBar>
        </TabNavigation>
      </TabContainer>
      {/* {(currentTab === 0 || currentTab === 2) && (
        <CompanyCourses
          title={t('general.purchased_courses')}
          variables={{
            filter: {
              companyId: {
                type: 'exact',
                value: company.id,
              },
              isBought: {
                type: 'bool',
                value: 'true',
              },
            },
          }}
          createBtn={currentTab === 2 ? company.platformService : false}
          addCoachIds={addCoachIds}
          defCurrentPage={defCurrentPage}
          currentTab={currentTab}
          company={company}
          t={t}
        />
      )} */}
      {currentTab === 3 && (
        <CompanyGroups variables={variables} t={t} companyId={company.id} />
      )}
      {(currentTab === 0 ||
        currentTab === 1 ||
        currentTab === 2 ||
        currentTab === 4 ||
        currentTab === 5) && (
          <CompanyUsers
            variables={variables}
            coaches={!!(currentTab === 0)}
            super_admin={currentTab === 2}
            assignCoaches={currentTab === 0 ? true : false}
            currentTab={currentTab}
            t={t}
          />
        )}
      <CompanyPurchaseCoursesSection style={{ background: '#080a0a0a' }}>
        <CompanyCourses
          title={t('general.purchased_courses')}
          variables={{
            filter: {
              companyId: {
                type: 'exact',
                value: company.id,
              },
              isBought: {
                type: 'bool',
                value: 'true',
              },
            },
          }}
          createBtn={true}
          addCoachIds={addCoachIds}
          defCurrentPage={defCurrentPage}
          currentTab={0}
          company={company}
          t={t}
        />
      </CompanyPurchaseCoursesSection>
      <CompanyCoursesSection style={{ background: '#fffffff' }}>
        <CompanyCourses
          title={t('general.company_courses')}
          variables={variables}
          createBtn={true}
          addCoachIds={addCoachIds}
          defCurrentPage={defCurrentPage}
          currentTab={1}
          company={company}
          addCourseButton={true}
          t={t}
        />
      </CompanyCoursesSection>
    </Container>
  )
}

export default CompanyTabs

const TabContainer = styled.div`

  border-bottom: 1px solid #EBEBEB;
  .MuiTabs {
    &-flexContainer {
      justify-content: center;
    }
  }

  .MuiTab {
    &-wrapper {
      color: ${({ theme }) => theme.palette.text.primary};
      padding-bottom: 15px;
      padding-top: 40px;
      font-size: 20px;
    }
  }
  /* .MuiTabs-flexContainer {
    & > button:first-child {
      margin-left: 300px;
    }
  } */
`

const CompanyPurchaseCoursesSection = styled.section`
  padding: 75px 30px;
  margin-top: 100px;
`

const CompanyCoursesSection = styled.section`
  padding: 75px 30px;
`
