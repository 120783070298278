import { useMutation } from '@apollo/react-hooks';
import { useSwal } from 'hooks/useSwal';
import { DELETE_REVIEWS } from 'gql/skillTest/skilltest.query';
import { useTranslation } from 'react-i18next';

export const useDeleteReview = (refetch: () => void) => {
  const { fireSwal } = useSwal();
  const [deleteReviewsFunc] = useMutation(DELETE_REVIEWS);
  const { t } = useTranslation();

  const handleDeleteReview = (ids: string[]) => {
    if (ids.length <= 0) return

    const params = {
      title: `${ids.length > 1
        ? t('actions.remove_reviews')
        : t('actions.remove_review')
        }`,
      onConfirm: async () => {
        try {
          const { data } = await deleteReviewsFunc({
            variables: { ids },
          })

          refetch();

        } catch (error) {
          console.error(error)
        }
      },
      confirmText: t('actions.yes'),
    }

    fireSwal(params)
  };

  return { handleDeleteReview };
};