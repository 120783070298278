

import React from 'react'
import { Link } from 'react-router-dom'
import { Text } from '../../styled'

type LessonNameProps = {
  lessonId: string
  lessonName: string
  color: string
  isOwn: boolean
}

function LessonName({ lessonId, color, lessonName, isOwn }: LessonNameProps) {

  return (<>
    {isOwn ? <Link to={`lesson/${lessonId}`}>
      <Text fs={'16px'} color={color} weight={600}>
        {lessonName}
      </Text>
    </Link> : <Text fs={'16px'} color={color} weight={600}>
      {lessonName}
    </Text>
    }
  </>)



}

export default LessonName