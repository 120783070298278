import * as React from "react";
import { FC, useState } from "react";
import { Area, Bar, CartesianGrid, ComposedChart, LabelList, Legend, Line, ResponsiveContainer, Scatter, Tooltip, XAxis, YAxis } from "recharts";
import './ChartLayout.scss'
import ChartToolTip from "./ChartTooltip/ChartToolTip";
import CustomLegend from "./CustomLegend/CustomLegend";

interface ChartLayoutProps {

}

interface BargraphState {
  x: number,
  y: number
}

const ChartLayout: FC<ChartLayoutProps> = () => {


  const [barGraph, setBargraph] = useState<BargraphState>({ x: 0, y: 0 });

  const data = [
    {
      name: 'Page A',
      uv: 1000,
      pv: 800,
      amt: 1400,
      cnt: 490,
    },
    {
      name: 'Page B',
      uv: 1800,
      pv: 967,
      amt: 1506,
      cnt: 590,
    },
    {
      name: 'Page C',
      uv: 1397,
      pv: 1098,
      amt: 989,
      cnt: 350,
    },
    {
      name: 'Page D',
      uv: 1480,
      pv: 1200,
      amt: 1228,
      cnt: 480,
    },
    {
      name: 'Page E',
      uv: 1520,
      pv: 1108,
      amt: 1100,
      cnt: 460,
    },
    {
      name: 'Page F',
      uv: 1400,
      pv: 680,
      amt: 1700,
      cnt: 380,
    },
    {
      name: 'Page F',
      uv: 1400,
      pv: 680,
      amt: 700,
      cnt: 380,
    },
    {
      name: 'Page F',
      uv: 400,
      pv: 680,
      amt: 800,
      cnt: 380,
    },
    {
      name: 'Page F',
      uv: 600,
      pv: 680,
      amt: 1500,
      cnt: 380,
    },
    {
      name: 'Page F',
      uv: 1300,
      pv: 680,
      amt: 100,
      cnt: 380,
    },
    {
      name: 'Page F',
      uv: 500,
      pv: 680,
      amt: 1200,
      cnt: 380,
    },
  ];




  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={200}
        height={200}
        data={data}
        margin={{
          top: 90,
          right: 240,
          bottom: 90,
          left: 240,
        }}
      >
        <CartesianGrid stroke="#F3F3F3" />
        {/* <XAxis type="number" /> */}
        {/* <YAxis /> */}
        <Tooltip content={
          <ChartToolTip>
            15000
          </ChartToolTip>
        } cursor={false} position={{ x: barGraph.x - 94, y: barGraph.y - 40 }} />
        <Legend content={<CustomLegend />} />
        <defs>
          <linearGradient id='colorFollower' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='10%' stopColor='#C4C4C4' stopOpacity={0.87} />
            <stop offset='60%' stopColor='#C4C4C4' stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area strokeLinecap="round" type="monotone" dataKey="amt" stroke="#E0E3E7" fill="url(#colorFollower)" strokeWidth={7} dot={false} />
        <Bar dataKey="pv" barSize={24} fill="#5290F6" onMouseOver={data => {
          setBargraph(data)
        }} />
        <Line strokeLinecap="round" type="natural" dataKey="uv" stroke="#FFB42B" strokeWidth={5} dot={false} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}


export default ChartLayout;