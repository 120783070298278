import { useMutation } from "@apollo/react-hooks";
import { updateCache } from "services/updateCache";
import { GET_USERS, UPDATE_USER } from "gql/users.query";

const updateUserService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [updateUserMutation, { loading, error }] = useMutation(UPDATE_USER, {
    update(cache, { data: { updateUser: document } }) {
      updateCache({
        objectName: "usersByRole",
        query: GET_USERS,
        cache,
        document,
        action: "update",
        params
      });
    }
  });

  return {
    updateUserMutation,
    loading,
    error
  };
};

export default updateUserService;
