import React, { useState } from 'react'
import styled from 'styled-components'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import { formatDuration } from 'helpers/formatDuration'
import Modal from 'components/common/Modal'
import Player from 'components/common/VideoPlayer/Player'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import HeadsetIcon from '@material-ui/icons/Headset'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import AudioPlayer from 'components/common/AudioPlayer'
import ModuleQuizzes from '../ModuleQuizzes/ModuleQuizzes'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

interface TopicLessonQuizListParam {
  moduleData: any
  wenrollExploreCourse?: boolean
}

const TopicLessonQuizList = ({
  moduleData,
  wenrollExploreCourse,
}: TopicLessonQuizListParam) => {
  const currentLocation = useLocation()
  const history = useHistory()
  const modalData: any = {
    modal: false,
    url: '',
    field: '',
    subtitle: null,
  }
  const [mediaModal, setMediaModal] = useState(modalData)
  const handleOpenMediaModal = (data?: any, field?: string) => {
    if (mediaModal.modal) {
      setMediaModal(modalData)
      return
    }
    setMediaModal({
      modal: true,
      url: field === 'playAudio' ? data.audio.link : data.video.links[0].url,
      subtitle: field === 'playAudio' ? null : data.subtitle,
      field,
    })
  }
  const Play = (val: any, field: string) => {
    switch (field) {
      case 'playVideo':
        return handleOpenMediaModal(val, field)
      case 'playAudio':
        return handleOpenMediaModal(val, field)
      default:
        return () => console.log('1')
    }
  }
  const isExplorePage = currentLocation.pathname.slice(1, 8) === 'explore'

  const PlayVideAudioIcon = (lesson: any) => {
    const hasConverted = lesson.video && lesson.video.links[0].converted
    if (lesson.video && lesson.video.name && lesson.video.links) {
      return hasConverted || (lesson.video && hasConverted) ? (
        <IconButton
          onClick={e => {
            e.stopPropagation()
            !isExplorePage && Play(lesson, 'playVideo')
          }}
        >
          <PlayCircleOutlineIcon
            color={isExplorePage ? 'secondary' : 'primary'}
          />
        </IconButton>
      ) : (
        <CircularProgress size={20} color="primary" />
      )
    }

    if (lesson.audio && lesson.audio.link) {
      return (
        <IconButton
          onClick={e => {
            e.stopPropagation()
            !isExplorePage && Play(lesson, 'playAudio')
          }}
        >
          <HeadsetIcon color={isExplorePage ? 'secondary' : 'primary'} />
        </IconButton>
      )
    }

    if (lesson.contentDocument && lesson.contentDocument.link) {
      return (
        <IconButton
          onClick={e => {
            e.stopPropagation()
            !isExplorePage && window.open(lesson.contentDocument.link, '_blank')
          }}
        >
          <InsertDriveFileIcon
            color={isExplorePage ? 'secondary' : 'primary'}
          />
        </IconButton>
      )
    }
  }

  return (
    <div style={{ marginTop: '8px' }} onClick={e => e.stopPropagation()}>
      {moduleData?.topics &&
        moduleData.topics.map((topic: any) => (
          <TopicsLessonsWrapper key={topic.id}>
            <TopicWrapper>{topic.name}</TopicWrapper>
            {topic?.lessons &&
              topic.lessons.map((lesson: any) => (
                <div key={lesson.id}>
                  <LessonWrapper
                    onClick={() =>
                      !isExplorePage &&
                      !wenrollExploreCourse &&
                      history.push(`/lessons/${lesson.id}`)
                    }
                  >
                    {PlayVideAudioIcon(lesson)}
                    <span style={{ marginLeft: !isExplorePage ? '' : '12px' }}>
                      {lesson.name}
                    </span>
                    <TimeWrapper>{formatDuration(lesson.duration)}</TimeWrapper>
                  </LessonWrapper>
                  {lesson?.quizzes &&
                    lesson.quizzes?.map((item: any) => (
                      <ModuleQuizzes
                        item={item}
                        quizType="lesson"
                        key={item.id}
                        displayStartButton={!isExplorePage}
                      />
                    ))}
                </div>
              ))}
            {topic?.quizzes &&
              topic.quizzes?.map((item: any) => (
                <ModuleQuizzes
                  item={item}
                  quizType="topic"
                  key={item.id}
                  displayStartButton={!isExplorePage}
                />
              ))}
          </TopicsLessonsWrapper>
        ))}
      {moduleData.quizList?.map((item: any) => (
        <ModuleQuizzes
          item={item}
          quizType="module"
          key={item.id}
          displayStartButton={!isExplorePage}
        />
      ))}
      <Modal isOpened={mediaModal.modal} onClose={handleOpenMediaModal}>
        <div>
          {mediaModal.field === 'playAudio' && (
            <AudioPlayer url={mediaModal.url} />
          )}
          {mediaModal.field === 'playVideo' && (
            <Player url={mediaModal.url} subtitle={mediaModal.subtitle} />
          )}
        </div>
      </Modal>
    </div>
  )
}

export default TopicLessonQuizList

const TopicsLessonsWrapper = styled.div`
  width: 100%;
  border: 0.5px solid #7070704d;
  background-color: #e0e1e299;
`

const TopicWrapper = styled.div`
  width: 100%;
  height: 40px;
  padding: 10px 0 0 10px;
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: bold;
`

const LessonWrapper = styled.div`
  width: 100%;
  height: 40px;
  border: 0.5px solid #7070704d;
  background-color: #ffffff;
  display: flex;
  align-items: center;
`

const TimeWrapper = styled.div`
  margin: auto;
  margin-right: 16px;
`
