import { useMutation } from '@apollo/react-hooks'

import {
  SET_NOTIFICATION_VIEW_STATUS,
  READ_NOTIFICATIONS,
  DELETE_NOTIFICATIONS
} from 'gql/notifications/notifications.query'

export const setNotificationViewStatusService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [setStatusMutation, { loading, error }] = useMutation(SET_NOTIFICATION_VIEW_STATUS)

  return {
    setStatusMutation,
    loading,
    error
  }
}

export const readNotificationService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [readNotificationMutation, { loading, error }] = useMutation(READ_NOTIFICATIONS)

  return {
    readNotificationMutation,
    loading,
    error
  }
}

export const deleteNotificationsService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteNotificationsMutation, { loading, error }] = useMutation(DELETE_NOTIFICATIONS)

  return {
    deleteNotificationsMutation,
    loading,
    error
  }
}