import React, { useContext, useEffect, useState } from 'react'
import { GetCourseById, lastWatchedIndex } from './types'
import * as S from './styled'
import { useTranslation } from 'react-i18next'
import { Box, LinearProgress, useTheme } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import Icon from 'components/Icon'
import { useUserValue } from 'context/UserContext'
import Modal from 'components/common/Modal'
import Player from 'components/common/VideoPlayer/Player'
import { GlobalUserDefaultImage, secondsToHms } from 'utils/utils'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { GET_REVIEWS } from 'gql/review/review.query'
import { useHistory, useParams } from 'react-router-dom'
import Axios from 'axios'
import { usePayment } from 'hooks/usePayment'
import Review from 'pages/companies/CourseReview/Components/Review'
import Drawer from 'components/common/Drawer'
import { GET_LAST_WATCHED_LESSON, PURCHASE_FREE_COURSE } from 'gql/course/course.query'
import { LastWatchedContext } from './Module/StudentCourseContext'
import { useFetchCommonCourses } from 'hooks/users/useCommonCourses'


export type HeaderProps = {
  isStatistics?: boolean
  statcourse?: any
  course: GetCourseById
  setLastWatched?: (lastWatched: lastWatchedIndex) => void
  lastWatched?: lastWatchedIndex
  refetchCourse?: () => void
  startPolling?: (interval: number) => void
  stopPolling?: () => void
}

const Header: React.FC<HeaderProps> = props => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  // const [statCourse, setStatCourse] = useState([])
  const { t } = useTranslation()
  const [state] = useUserValue()
  const params: any = useParams()
  const history = useHistory()
  const [freeState, setFreeState] = useState<{ status: string }>()
  const theme = useTheme()
  const { lastWatched, setLastWatched } = useContext(LastWatchedContext)
  // const { id, userId } = useParams<{ id: string, userId: string }>()


  const [checkoutOrder] = usePayment()


  // courses?.filter((course: any) => {

  //   if (course.id == params.id) {
  //     setStatCourse(course)
  //   }
  // })


  const [videoModalOpen, setVideoModalOpen] = React.useState(false)

  const { data } = useQuery(GET_REVIEWS, {
    variables: {
      ratedId: params.id ? params.id : params.courseId,
    },
  })

  const [purchaseFreeCourse, { data: purchasedData, loading, error }] = useMutation(PURCHASE_FREE_COURSE, {
    onCompleted: data => {
      if (data.purchaseFreeCourse) {
        setFreeState(data.purchaseFreeCourse)
      }
    }
  })


  useEffect(() => {
    if (freeState?.status == "success") {

      props?.startPolling && props.startPolling(500)
    }
    if (props.course.isOwnCourse == true) {
      props?.stopPolling && props.stopPolling()
    }
  }, [props.course.isOwnCourse, freeState])

  const handlePurchaseClicked = async () => {
    if (props.course.price !== null) {
      checkoutOrder({
        amount: props.course.price,
        description: props.course.name,
        quantity: 1,
        product_id: params.id,
      })
    } else {
      purchaseFreeCourse({
        variables: {
          courseId: params.id,
        },
      })
    }

  }

  const handleModalClose = () => {
    setIsModalOpened(false)
  }

  const renderInfoContent = React.useMemo(() => {

    switch (state?.currentUser?.role) {
      default:
      case 'STUDENT':
        if (!props.course.isOwnCourse)
          return (
            <>
              {props.course?.coaches?.[0] && (
                <div style={{ display: 'flex' }}>
                  <S.Author
                    theme={theme}
                    image={
                      props.course.coaches[0].avatar || GlobalUserDefaultImage
                    }
                    name={`${props.course.coaches[0].firstName} ${props.course.coaches[0].lastName}`}
                  />

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <S.Text
                      fs={'28px'}
                      weight={600}
                      color={theme.palette.text.primary}
                      mr={'5px'}
                    >
                      {props.course.coaches[0].averageRating?.toFixed(1) ||
                        '0.0'}
                    </S.Text>
                    <Rating
                      precision={0.1}
                      defaultValue={props.course.coaches[0].averageRating || 0}
                      disabled
                    />

                  </div>
                </div>
              )}

              <Box display={'flex'} gridGap={20}>
                <S.SButton
                  onClick={() => {
                    handlePurchaseClicked()
                  }}>
                  <Icon filename={'wallet'} />
                  {props.course.price
                    ? `${props.course.price} ${props.course.currency === "GBP" ? "£" : props.course.currency === "GEL" ? "₾" : props.course.currency === "USD" ? "$" : props.course.currency === "EUR" ? "€" : ""} `
                    : t('courses_layout.free_start')}
                </S.SButton>

                {!!props.course.video?.links[0].url && (

                  <S.SButton
                    variant={'secondary'}
                    onClick={() =>
                      !!props.course.video?.links[0].url &&
                      setVideoModalOpen(true)
                    }
                  >
                    <Icon
                      filename={'play'}
                      color={theme.palette.text.primary}
                    />
                    <span
                      style={{
                        color: theme.palette.text.primary,
                      }}
                    >
                      {t('general.intro')}
                    </span>
                  </S.SButton>
                )}

              </Box>

            </>
          )
        return (
          <>

            <Box width={'100%'} maxWidth={283} flexDirection={'column'} marginBottom={'15px'}>
              <S.Text
                fs={'16px'}
                color={theme.palette.text.primary}
                mb={'20px'}
              >

                {secondsToHms(props.course.duration)} · {t("general.progress")} {' '}
                {props.course.progress}%
              </S.Text>
              <S.SLinearProgress
                variant="determinate"
                value={props.course.progress}
              />
            </Box>
            <Box display={'flex'} gridGap={20}>
              {!props.isStatistics &&
                <S.SButton
                  onClick={() => {
                    if (props.course.progress == 100) {
                      setLastWatched({
                        moduleIndex: 0,
                        topicIndex: 0,
                        lessonIndex: 0,
                        show: true
                      })
                    } else {
                      const {
                        moduleIndex,
                        lessonIndex,
                        topicIndex,
                      } = props?.course?.lastWatchedLesson
                      setLastWatched({
                        moduleIndex,
                        lessonIndex,
                        topicIndex,
                        show: true,
                      })
                    }
                  }
                  }
                >
                  <Icon filename={'play'} fill={theme.palette.text.primary} />
                  {props.course.progress === 0 ? t('general.start') : t('general.continue')}
                </S.SButton>
              }
              {!!props.course.video?.links[0].url && (
                <S.SButton
                  variant={'secondary'}
                  onClick={() =>
                    !!props.course.video?.links[0].url &&
                    setVideoModalOpen(true)
                  }
                >
                  <Icon filename={'play'} color={theme.palette.text.primary} />
                  <span
                    style={{
                      color: theme.palette.text.primary,
                    }}
                  >
                    {t("general.intro")}
                  </span>
                </S.SButton>
              )}
            </Box>

          </>
        )
      case 'COACH':
        if (!props.course.isOwnCourse)
          return (
            <>
              {props.statcourse?.coaches?.[0] && (
                <div style={{ display: 'flex' }}>
                  <S.Author
                    theme={theme}
                    image={
                      props.statcourse.coaches[0].avatar || GlobalUserDefaultImage
                    }
                    name={`${props.statcourse.coaches[0].firstName} ${props.statcourse.coaches[0].lastName}`}
                  />

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <S.Text
                      fs={'28px'}
                      weight={600}
                      color={theme.palette.text.primary}
                      mr={'5px'}
                    >
                      {props.statcourse.coaches[0].averageRating?.toFixed(1) ||
                        '0.0'}
                    </S.Text>
                    <Rating
                      precision={0.1}
                      defaultValue={props.statcourse.coaches[0].averageRating || 0}
                      disabled
                    />

                  </div>
                </div>
              )}

              <Box display={'flex'} gridGap={20}>
                <S.SButton
                  onClick={() => {
                    if (props.course.progress == 100 || !props?.course?.lastWatchedLesson) {
                      setLastWatched({
                        moduleIndex: 0,
                        topicIndex: 0,
                        lessonIndex: 0,
                        show: true
                      })
                    } else if (props?.course?.lastWatchedLesson) {
                      const {
                        moduleIndex,
                        lessonIndex,
                        topicIndex,
                      } = props?.course?.lastWatchedLesson
                      setLastWatched({
                        moduleIndex,
                        lessonIndex,
                        topicIndex,
                        show: true,
                      })
                    }
                  }
                  }
                >
                  <Icon filename={'play'} fill={theme.palette.text.primary} />

                  {props.course.progress === 0 ? t('general.start') : t('general.continue')}       ასდ
                </S.SButton>

                {!!props.course.video?.links[0].url && (
                  <S.SButton
                    variant={'secondary'}
                    onClick={() => {
                      setVideoModalOpen(true)
                    }}

                  >
                    <Icon
                      filename={'play'}
                      color={theme.palette.text.primary}
                    />
                    <span
                      style={{
                        color: theme.palette.text.primary,
                      }}
                    >
                      {t('general.intro')}
                    </span>
                  </S.SButton>
                )}

              </Box>

            </>
          )
        return (
          <>

            <Box width={'100%'} maxWidth={283} flexDirection={'column'} marginBottom={'15px'}>
              <S.Text
                fs={'16px'}
                color={theme.palette.text.primary}
                mb={'20px'}
              >
                {secondsToHms(props.statcourse.duration)} · {t("general.progress")} {' '}
                {props.statcourse.progress}%
              </S.Text>

              <S.SLinearProgress
                variant="determinate"
                value={props.statcourse.progress}
              />

            </Box>
            <Box display={'flex'} gridGap={20}>
              {!props.isStatistics &&
                <S.SButton
                  onClick={() => {
                    if (props.statcourse.progress == 100) {
                      setLastWatched({
                        moduleIndex: 0,
                        topicIndex: 0,
                        lessonIndex: 0,
                        show: true
                      })
                    } else {
                      const {
                        moduleIndex,
                        lessonIndex,
                        topicIndex,
                      } = props?.course?.lastWatchedLesson
                      setLastWatched({
                        moduleIndex,
                        lessonIndex,
                        topicIndex,
                        show: true,
                      })
                    }
                  }
                  }
                >
                  <Icon filename={'play'} fill={theme.palette.text.primary} />
                  {props.statcourse.progress === 0 ? t('general.start') : t('general.continue')}
                </S.SButton>
              }
              {!!props.course.video?.links[0].url && (
                <S.SButton
                  variant={'secondary'}
                  onClick={() =>
                    !!props.course.video?.links[0].url &&
                    setVideoModalOpen(true)
                  }
                >
                  <Icon filename={'play'} color={theme.palette.text.primary} />
                  <span
                    style={{
                      color: theme.palette.text.primary,
                    }}
                  >
                    {t("general.intro")}
                  </span>
                </S.SButton>
              )}
            </Box>

          </>
        )
        break
    }
  }, [
    state?.currentUser?.role,
    props.course?.isBought,
    props?.course?.isOwnCourse,
    isModalOpened,
    props.course.progress,
    props.course.lastWatchedLesson,
    props.course,
    props.statcourse
  ])

  return (
    <S.Header>

      <S.CourseInfo>
        <S.HeaderText fs={'28px'} weight={600} color={theme.palette.text.primary}>
          {props.course.name}
        </S.HeaderText>
        <Box display={'flex'} alignItems={'center'}>
          <S.Text
            fs={'28px'}
            weight={600}
            color={theme.palette.text.primary}
            mr={'5px'}
          >

            {data?.getReviews?.averageRating
              ? data?.getReviews?.averageRating.toFixed(1)
              : '0.0'}
          </S.Text>


          <button
            onClick={() =>
              history.push(
                `/course/${params.id ? params.id : params.courseId}/review`,
              )
            }
          >
            <Rating
              precision={0.1}
              value={
                props?.course?.averageRating ? props?.course?.averageRating : 0
              }

              disabled
            />

          </button>
          <S.Text
            style={{ cursor: 'pointer' }}
            onClick={() =>
              history.push(
                `/course/${params.id ? params.id : params.courseId}/review`,
              )
            }
            fs={'16px'}
            color={'#080A0A8A'}
            ml={'20px'}
          >
            {data?.getReviews.count} {data?.getReviews.count > 1 ? t('general.reviews') : t('general.review')}
          </S.Text>
        </Box>
        {renderInfoContent}

      </S.CourseInfo>

      <S.CourseVideo
        image={props.course?.avatar?.link}
        hasVideo={!!props.course.video?.links[0].url}
        onClick={() =>
          !!props.course.video?.links[0].url && setVideoModalOpen(true)
        }
      />
      <Modal isOpened={videoModalOpen} onClose={() => setVideoModalOpen(false)} >
        <Player intro={true} url={props.course.video?.links[0].url} disableTimeSave={true} />
      </Modal>
    </S.Header>
  )
}

export default Header
