import { useQuery } from "@apollo/react-hooks";
import { Button } from "components/common/Button";
import Drawer from "components/common/Drawer";
import Modal from "components/common/Modal";
import Icon from "components/Icon";
import { GET_GROUP_BY_ID } from "gql/group/group.query";
import React, { FC, useState } from "react";
import styled from "styled-components";
import { Text } from '../../styled'
import { module } from "../../types";
import { useTheme, useMediaQuery } from '@material-ui/core'

import StudentPersonal from "../Drawer/StudentPersonal";
import { useTranslation } from 'react-i18next'


interface CreatePersonalHomeworkProps {
  module: module.root,
  refetch?: any,
  groupId: string,
}

const CreatePersonalHomework: FC<CreatePersonalHomeworkProps> = (props) => {

  const [isModalOpenen, setIsModalOpened] = useState(false);
  const [group, setGroup] = useState<any[]>()

  const { t } = useTranslation()

  const handleModalClose = () => {
    setIsModalOpened(false);
    props.refetch()
  }

  useQuery(GET_GROUP_BY_ID, {
    variables: {
      id: props.groupId
    },
    onCompleted: data => setGroup(data.getGroupById),
  })



  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <PersonalHomeworkContainer>
      <PersonalHomeworkHeader>
        <Text fs={'16px'} weight={'bold'}>{t("general.personal_assignment")}</Text>
      </PersonalHomeworkHeader>
      <div style={{ display: 'flex' }}>
        <Icon filename={'upload'} />
        <PersonalHomeworkInfo>
          <PersonalHomeworkLeftSide>
            <Text fs={'16px'}>{t("general.personal_assignment_title")}</Text>
            {/* <Text fs={'12px'} color={'#CC3131'}>მოლოდინის რეჟიმში</Text> */}
          </PersonalHomeworkLeftSide>
          <PersonalHomeworkRightSide>
            <Button
              text={t("general.create")}
              background={'#080A0A08'}
              textColor={'#080A0A'}
              size={'medium'}
              buttonStyles={{
                borderRadius: '16px'
              }}
              onClick={() => setIsModalOpened(true)}
            />
          </PersonalHomeworkRightSide>
        </PersonalHomeworkInfo>
      </div>

      <Modal
        isOpened={isModalOpenen}
        onClose={handleModalClose}
      >
        <Drawer
          opened={isModalOpenen}
          toggleDrawer={handleModalClose}
          totalWidth={isSmallScreen ? "970px" : "100%"}

        >
          <StudentPersonal module={props.module} onModalClose={handleModalClose} groupId={props.groupId} />
        </Drawer>
      </Modal>

    </PersonalHomeworkContainer>
  );
}

export default CreatePersonalHomework;

const PersonalHomeworkContainer = styled.div`
  width: 100%;
  height: 172px;
  background: #fff;
  border: 1px solid #080A0A04;
  border-radius: 16px;
  padding: 20px 30px;
  margin: 20px 0;
`

const PersonalHomeworkHeader = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const PersonalHomeworkInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  justify-content: space-between;
`

const PersonalHomeworkLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  div {
    margin-bottom: 10px;
  }
`

const PersonalHomeworkRightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;

  div {
    margin-bottom: 20px;
  }
`
