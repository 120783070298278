import { mainColor } from 'components/common/Colors/CommonColors'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Details = styled.div`
  width: 300px;
  padding: 10px;
`

export const DetailsTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  color: #080a0ad6;
`

export const DetailsList = styled.ul`
  padding-left: 0;
  margin: 15px 0;
`

export const DetailsListItem = styled.li`
  font-size: 16px;
  color: #080a0a8a;

  strong {
    color: #080a0ad6;
    margin-right: 5px;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const TagItem = styled.div`
  display: inline-block;
  font-style: italic;
  &:not(:last-child) {
    margin-right: 8px;
  }
`

export const Content = styled.div`
  /* width: calc(100% - 30px); */
  flex: 2;
  border-left: 1px solid #080a0a8a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
`

export const FilesContainer = styled.div`
  display: flex;
  /* > div {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  } */
`

export const MediaContainer = styled.div`
  background: #fff;
  border-radius: 5px;
  width: 50%;
  flex-grow: 2;
  /* box-shadow: 0 0 18px rgba(0, 0, 0, 0.1); */
  &:not(:last-child) {
    margin-right: 15px;
  }
`

export const MediaHeader = styled.header`
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  color: #d2364b;
  font-weight: 500;
  svg {
    margin-right: 10px;
  }
`

export const MediaContent = styled.section`
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`

export const MediaContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
`

export const MediaTitle = styled.h3`
  padding: 5px;
  display: inline-block;
  max-width: 200px;
  font-size: 14px;
`

export const VideoContainer = styled.div`
  width: 200px;
  height: 120px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    fill: #fff;

    &:hover {
      fill: #d2364b;
    }
  }

  .MuiCircularProgress-colorPrimary {
    color: #dbdbdb;

    svg {
      width: 30px;
      height: 30px;
    }
  }
`

export const ImageContainer = styled.figure`
  position: relative;
  width: 200px;
  height: 120px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.2);
    transition: transform 0.2s ease;
  }

  svg {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    fill: #fff;
    opacity: 0;
    transition: opacity 0.2s ease;

    &:hover {
      fill: #d2364b;
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover {
    svg,
    &:after {
      opacity: 1;
    }

    img {
      transform: scale(1);
    }
  }
`

export const DocumentContainer = styled.div`
  margin-top: 5px;

  > div {
    background: #eee;
    margin-top: 5px;
    border-radius: 5px;
    padding: 10px 15px 10px 10px;
    display: flex;
    align-items: center;
    transition: 0.2s ease color;

    &:hover {
      color: #d2364b;
    }
  }

  > div {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`

export const DocumentName = styled.div`
  margin-left: 5px;
`

export const QuestionTitle = styled.h1`
  margin: 20px 0;
  font-size: 24px;
  max-width: 600px;
  text-align: center;
  color: #080a0ad6;
`
export const BoolIcon = styled.span`
  display: inline-block;

  &:not(:last-child) {
    margin-right: 20px;
  }

  svg {
    width: 100px;
    height: 100px;
  }

  &.true svg {
    fill: ${mainColor};
  }

  &.false svg {
    color: red;
  }
`

export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  label span {
    color: #080a0a8a;
    font-weight: 500;
  }

  label span.Mui-checked {
    color: #080a0a8a;
  }

  .icon {
    width: 19px;
    height: 19px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 100%;
    position: relative;
    &-checked {
      &::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 11px;
        height: 11px;
        background: #707070;
        border-radius: 100%;
      }
    }
  }
`

export const AnswerContainer = styled.div`
  background: #fff;
  border-radius: 5px;
  border: 1px solid #317bf4;
  color: #317bf4;
  font-size: 16px;
  padding: 10px 20px;
  margin-top: 30px;
  max-width: 600px;
  border-radius: 8px;
`

export const CorrectAnswerTitle = styled.h3`
  color: ${mainColor};
  font-weight: 500;
  font-size: 16px;
`
export const CorrectBoolAnswer = styled.span`
  text-transform: capitalize;
  margin-left: 5px;
`
export const AnswerReason = styled.article`
  margin-top: 15px;
  font-size: 16px;
  font-style: italic;
`
export const NoValueWrapper = styled.div`
  display: inline-block;
  font-size: 12px;
  opacity: 0.5;
  font-style: italic;
`
