import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import { useTheme } from '@material-ui/styles'
import { Button } from 'components/common/Button'
import { mainColor } from 'components/common/Colors/CommonColors'
import Drawer from 'components/common/Drawer'
import {
  FormButtons,
  FormContainer,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form'
import Grid from 'components/common/Grid'
import TablePagination from 'components/common/Pagination/TablePagination'
import Search from 'components/common/Search'
import { Input } from 'components/common/TextInput'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import {
  CloseIcon,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Info,
} from 'components/DrawerLayout/styled-components'
import { useFetchGroups } from 'hooks/group/useGroups'
import { useSwal } from 'hooks/useSwal'
import FilterDrawer from 'pages/group/FilterDrawer/FilterDrawer'
import { columnConfig } from 'pages/group/gridConfig'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { columnConfig } from './groupGridConfig'

const AddGroupInCourseDrawer = (props: any) => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [searchText, setSearchText] = useState('')
  let filter = {
    company: {
      type: 'exact',
      value: props.companyId,
    },
    _id: {
      type: 'arrayNotIn',
      value: props.selectedGroup.map((group: any) => group.groupId),
    },
  }
  const [filterOptions, setFilterOptions] = useState<any>(filter)
  const [filterDrawer, setFilterDrawer] = useState<any>({
    filterOptions: filter,
    filterValues: null,
    opened: false,
  })
  const { groups, loading, error, refetch }: any = useFetchGroups(
    currentPage,
    perPage,
    filterDrawer.filterOptions,
  )
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})

  const handleClick = () => { }

  const selectItem = (id: never, event: boolean) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = groups.data.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const onFormSubmit = () => {
    const selectedGroups = groups.data.filter(
      (i: any) =>
        selectedItem[currentPage].findIndex((v: any) => i.id === v) !== -1,
    )

    const updatedGroups = selectedGroups.map((i: any) => ({
      groupId: i.id,
      name: i.name,
    }))

    props.selectGroup(updatedGroups)
    props.onClose()
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    let filters = {
      ...filterOptions,
      name: { type: 'match', value: searchText },
    }
    refetch({
      filter: {
        ...filters,
      },
      currentPage: 1,
      perPage,
    })
    setFilterOptions(filters)
    setCurrentPage(1)
  }

  const handleCloseDrawer = () => {
    if (!selectedItem[currentPage] || selectedItem[currentPage].length === 0) {
      props.onClose()
    } else if (selectedItem[currentPage]) {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        onConfirm: () => {
          props.onClose()
        },
        onClose: () => { },
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }

  const config = columnConfig(handleClick, selectItem, false, t)

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    refetch({
      filter: {
        company: {
          type: 'exact',
          value: props.companyId,
        },
        name: { type: 'match', value: searchText },
      },
      currentPage,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    props.refetch({
      filter: {},
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
  }

  // const selectedGroups =
  //   groups &&
  //   groups.data &&
  //   groups.data.filter(
  //     (item: any) =>
  //       props.selectedGroup.findIndex((val: any) => item.id === val.groupId) ===
  //       -1,
  //   )

  const filterData = (filters: any) => {
    refetch({
      filter: { ...filter, ...filters },
      currentPage,
      perPage: 0,
    })
    if (groups?.groups?.data) {
      setFilterDrawer({ ...filterDrawer, opened: false })
    }
  }

  const theme = useTheme()

  return (
    <>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{t('groups_layout.add_groups')}</DrawerTitle>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent
        style={{
          margin: '100px 20px',
        }}
      >
        <FormContainer>
          <FormGroupWrapper padding={20}>
            <Toolbar styles={{ justifyContent: 'space-between' }}>
              <div />
              <ToolbarItem>
                <form onSubmit={handleSearchSubmit}>
                  <Search
                    inputProps={{
                      value: searchText,
                      onChange: e => setSearchText(e.target.value),
                    }}
                    onClick={(e: any) => setSearchText(e.target.value)}
                  />
                </form>
              </ToolbarItem>
            </Toolbar>

            {groups && groups.data && (
              <Grid
                data={groups.data}
                config={config}
                selectAllItem={selectAllItem}
                selected={
                  selectedItem[currentPage]
                    ? selectedItem[currentPage].length
                    : 0
                }
                selectedItems={selectedItem[currentPage] || []}
                onFiltersClick={() => {
                  setFilterDrawer({
                    ...filterDrawer,
                    opened: !filterDrawer.opened,
                  })
                  setSelectAll(false)
                }}
                resetVisible={!!searchText || !!filterDrawer?.filterValues}
                resetFilters={() => {
                  setFilterDrawer({
                    filterOptions: filterDrawer.filterOptions,
                    filterValues: null,
                    opened: false,
                  })
                  setSelectAll(false)
                  setSearchText('')
                  refetch({
                    filter: { ...filter },
                    perPage: 0,
                  })
                }}
              />
            )}
            {groups && groups.data && (
              <TablePagination
                currentPage={groups ? currentPage : 0}
                rowsPerPage={perPage}
                count={groups ? groups.totalCount : 0}
                handleChangePage={handlePaginationClick}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
            <Drawer
              opened={filterDrawer.opened}
              toggleDrawer={() => { }}
              totalWidth="600px"
            >
              <FilterDrawer
                hasSearchByCompany={props.hasSearchByCompany}
                closeDrawer={() =>
                  setFilterDrawer({ ...filterDrawer, opened: false })
                }
                filterOptions={filterDrawer}
                setFilterOptions={setFilterDrawer}
                refetchGroup={refetch}
                setCurrentPage={() => { }}
                filter={filterData}
                defaultFilter={filter}
                companyId={props.companyId}
                useCompanyId={true}
              />
            </Drawer>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.close')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />

              <Button
                text={t('actions.add')}
                type="medium"
                color="secondary"
                onClick={onFormSubmit}
                background={mainColor}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddGroupInCourseDrawer
