import { useQuery } from '@apollo/react-hooks'
import { useTheme } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { AvatarInput } from 'components/common/AvatarInput/AvatarInput'
import { Button } from 'components/common/Button'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import DatePicker from 'components/common/DatePicker'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import GenderPicker from 'components/common/GenderPicker'
import Loader from 'components/common/Loader'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import {
  CloseIcon,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Info,
} from 'components/DrawerLayout/styled-components'
import { genderOptions } from 'components/UsersLayout/staticData'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { useFormik } from 'formik'
import { GET_GROUP } from 'gql/group/group.query'
import { AdminSchema } from 'helpers/validationSchemas'
import useAddUserServie from 'hooks/users/useAddUserService'
import { useSwal } from 'hooks/useSwal'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CLEAR_FORMDATA } from 'store/types'
import { checkValues } from '../checkAdminValues'

type IValue = {
  firstName: string
  lastName: string
  email: string
  phone: string
  role: string
  group: string
  jobTitle: string
  location: string
  gender: any
  phoneFields: any
  phoneFieldValue: any
  birthDate: any
  biography: string
  note: string
  avatar: string
}

interface Props {
  title: string
  role: string
  drawerName?: string
  closeDrawer?: () => void
  drawerData?: any
  onClose?: any
  setFormData?: any
}

const AddAdmin = ({
  title,
  role,
  drawerName,
  drawerData,
  onClose,
  setFormData,
}: Props) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [dataState, dispatch] = useData()
  const { fireSwal } = useSwal()
  const [selectedGroup, setSelectedGroup] = useState<any>([])
  const [groups, setGroups] = useState<any>([])
  const { data } = useQuery(GET_GROUP, {
    variables: {
      filter: {
        company: {
          type: 'exact',
          value: state.selectedCompanyId,
        },
      },
      perPage: 0,
    },
  })
  const { addUser, user, error, addUserLoading } = useAddUserServie({
    role,
    companyId: state.selectedCompanyId,
    filter: {},
  })
  const [generalError, setGeneralError] = useState('')
  const initialdata: IValue = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',
    group: '',
    jobTitle: '',
    location: '',
    gender: null,
    phoneFields: null,
    phoneFieldValue: null,
    biography: '',
    note: '',
    birthDate: '',
    avatar: '',
  }
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: initialdata,
    validationSchema: AdminSchema,
    onSubmit(values) {
      if (addUserLoading) return

      const formValues: any = {
        ...values,
        requestPasswordChange: state.currentUser.settings.askPassword,
      }
      delete formValues.phoneFieldValue
      formValues.role = drawerData.role ? drawerData.role : role
      formValues.group = selectedGroup
      formValues.gender = formValues.gender ? formValues.gender : null

      if (formValues.phoneFields && formValues.phoneFields.value) {
        formValues.phoneFields = {
          dialCode: formValues.phoneFields.value,
          code: formValues.phoneFields.label,
        }
      } else {
        formValues.phoneFields = null
      }

      addUser(
        formValues,
        role,
        drawerData.companyId || state.selectedCompanyId,
        onSuccess,
      )
    },
  })

  const onSuccess = (adminData: any) => {
    closeDrawer()
    dispatch({ type: CLEAR_FORMDATA })
    drawerData.onSuccess(adminData)
  }

  const closeDrawer = () => (onClose ? onClose() : drawerData.onClose())

  const handleCloseDrawer = () => {
    const isEmpty = checkValues(values)
    if (isEmpty) {
      onClose ? onClose() : drawerData.onClose()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        onConfirm: () => {
          onClose ? onClose() : drawerData.onClose()
        },
        onClose: () => { },
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    setGeneralError('')
  }, [values.email])

  useEffect(() => {
    if (error) {
      setGeneralError(error)
    }
  }, [error])

  useEffect(() => {
    if (data) {
      const allGroups = [...data.getAllGroups.data] || []
      allGroups.forEach(item => {
        item.value = item.id
        item.label = item.name
      })
      setGroups(allGroups)
    }
  }, [data])

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  const [currentDate, setCurrentDate] = useState<Date | null>(null)

  const onDateChange = (date: Date | null) => {
    // setCurrentDate(date)
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  useEffect(() => {
    if (values.birthDate) {
      setCurrentDate(
        new Date(
          typeof (values.birthDate === 'string')
            ? values.birthDate
            : +values.birthDate,
        ),
      )
    }
  }, [values.birthDate])

  const theme = useTheme();

  return (
    <>
      {addUserLoading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{drawerData.title ? drawerData.title : title}</DrawerTitle>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <AvatarInput
                  setFieldValue={setFieldValue}
                  initialAvatar={values.avatar}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  label={`${t('user_details.first_name')}*`}
                  size="medium"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  label={`${t('user_details.last_name')}*`}
                  size="medium"
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.email || generalError ? true : false}
                  errorMessage={
                    errors.email || generalError
                      ? errors.email || generalError
                      : ''
                  }
                  touched={touched.email}
                  label={`${t('user_details.email')}*`}
                  size="medium"
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <PhoneCountryCode
                  label={`${t('user_details.phone')}`}
                  selectLabel={t('form_fields.dial_code')}
                  selectValue={null}
                  selectChange={(e: any) => setFieldValue('phoneFields', e)}
                  inputLabel="Number"
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={
                    errors.phone || errors.phoneFields || generalError
                      ? true
                      : false
                  }
                  errorMessage={
                    errors.phone || errors.phoneFields || generalError
                      ? errors.phone || errors.phoneFields || generalError
                      : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>
              <FormField width="100%">
                <GenderPicker
                  checked={values.gender}
                  onChange={e => setFieldValue('gender', e)}
                />
              </FormField>

              <FormField width="48%">
                <TextInput
                  label={t('form_fields.job_title')}
                  size="medium"
                  name="jobTitle"
                  type="text"
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  label={t('form_fields.location')}
                  size="medium"
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <DatePicker
                  label={`${t('user_details.birthday')}`}
                  name="birthDate"
                  value={currentDate}
                  onChange={onDateChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  label={t('user_details.biography')}
                  size="medium"
                  name="biography"
                  type="text"
                  value={values.biography}
                  onChange={handleChange}
                  multiline
                  rows="3"
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  label={t('user_details.note')}
                  size="medium"
                  name="note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  multiline
                  rows="3"
                />
              </FormField>
              {/* <FormField width="48%">
                <ReactSelect
                  onChange={e => {}}
                  options={groups}
                  // value={values.group}
                />
              </FormField> */}
              {/* <FormField width="48%">
                <FormControlLabel
                  control={
                    <Switch
                      checked={requestPasswordChange}
                      onChange={() => setChecked(!requestPasswordChange)}
                      value={requestPasswordChange}
                      color="primary"
                    />
                  }
                  label="Ask for a password change"
                />
              </FormField> */}
            </FormFields>
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.save')}
                type="medium"
                color="secondary"
                btnType="submit"
                background={'#317BF4'}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddAdmin
