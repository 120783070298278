import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { DrawerEventEmitter } from 'helpers/drawer'
import SyncIcon from '@material-ui/icons/Sync'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import FilterListIcon from '@material-ui/icons/FilterList'
import ReactDOM from 'react-dom';
import { ACTIVATE_SUBSCRIPTION, ATTACH_SUBSCRIPTION, CANCEL_SUB, GET_ACTIVE_SUB } from 'gql/subscriptions/subscription.query'
import { columnConfig, actionConfig } from './gridConfig'
import { ApolloClient, gql, useApolloClient } from '@apollo/client';


import { useFetchUsers, PER_PAGE } from 'hooks/users/useUsers'
import useUserDeleteService from 'hooks/users/useDeleteUserService'
import useResendUserService from 'hooks/users/useUpdateUserPasswordService'
import useUpdateStatusUserService from 'hooks/users/useUpdateStatusUserService'

import {
  GridToolbar,
  GridLayoutActions,
  GridLayoutForm,
  MultipleAction,
  MultipleActionTooltip,
  ActionButton,
} from 'components/common/Grid/styled-components'

import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'


import Loader from 'components/common/Loader'
import Drawer from 'components/common/Drawer'
import TablePagination from 'components/common/Pagination/TablePagination'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import Grid from 'components/common/Grid'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
// import { FilterStudents } from 'pages/students'
import { FilterCoaches } from 'pages/coaches'
import { FilterModerators } from 'pages/moderators'
import { FilterAdmins } from 'pages/admins'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import ArchiveIcon from '@material-ui/icons/Archive'
import { mainColor } from 'components/common/Colors/CommonColors'
import ResetUsersPassword from 'pages/profile/AllUserProfile/ResetPassword'
import styled from 'styled-components'
import Search from 'components/common/Search'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'
import FilterStudents from 'pages/students/FilterStudents/FilterStudents'
import { GET_ALL_SUBSCRIPTIONS } from 'gql/subscriptions/subscription.query'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { client } from "../../apollo"


interface Props {
  role: string
  addText: string
  addUserDrawer: string
  editUserDrawer: string
  editUserSubmission?: string
  changeQualification?: (id: string, isQualified: boolean) => void
  changeCoachQualificationLoading?: boolean
  handleChangeQualificationManyClick?: (
    selectedItems: string[],
    isQualified: boolean,
  ) => void
  onGroupClick?: (groupId: string) => void
}

const UsersLayout = ({
  role,
  addText,
  addUserDrawer,
  editUserDrawer,
  editUserSubmission,
  changeQualification,
  changeCoachQualificationLoading = false,
  handleChangeQualificationManyClick = () => { },
}: Props) => {

  const [selectedValue, setSelectedValue] = useState("")
  const [activeSubs, setActiveSubs] = useState<any[]>([])
  const [isDataFetched, setIsDataFetched] = useState(false);

  const { t } = useTranslation()
  const [state] = useUserValue()
  const handleRadioChange = (event: any) => {

    setSelectedValue(event.target.value);  // Update the state with the selected radio button's value
  }

  const companyId: string | null =
    state.selectedCompanyId || state?.currentUser?.companyId?.id

  let defaultFilter: any = {}

  if (companyId) {
    defaultFilter.companyId = {
      type: 'exact',
      value: companyId,
    }
  }
  const [fetchAll, setFetchAll] = useState(false)

  const { users, loading, error, refetch }: any = useFetchUsers(

    role,
    defaultFilter,
    false
  )

  // const [getActiveSubs, { data: activeSub, loading: subsLoading }] = useLazyQuery(GET_ACTIVE_SUB, {
  //   onCompleted: (data) => {
  //     console.log('Data fetched:', data.getAttachedSubs);
  //     setActiveSubs(prevSubs => [...prevSubs, data.getAttachedSubs]);
  //     setIsDataFetched(false)
  //   },
  //   onError: (error) => {
  //     console.error('Error fetching data:', error);
  //   }
  // });

  // const { users: allUsers, loading: allUserLoading }: any = useFetchUsers(role, defaultFilter, true)

  const { deleteUser, deleteUserLoading } = useUserDeleteService({
    role,
    defaultFilter,
  })
  const { updateUserPassword } = useResendUserService({
    role,
    defaultFilter,
  })
  const {
    updateUserStatus,
    updateUserStatusLoaing,
  } = useUpdateStatusUserService({
    role,
    defaultFilter,
  })
  const { exportData } = useExportDataService()
  const { data: subscriptions } = useQuery(GET_ALL_SUBSCRIPTIONS)
  const [subscribe, { data }] = useMutation(ATTACH_SUBSCRIPTION);
  const [unSubscribe, { data: unsubscribeData }] = useMutation(CANCEL_SUB);
  const [activate, { data: activationData }] = useMutation(ACTIVATE_SUBSCRIPTION);
  const { fireSwal } = useSwal()
  const history = useHistory()
  const selectedValueRef = useRef("");

  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})
  const [filterDrawerOpened, setFilterDrawerOpened] = useState(false)
  const [resetVisible, setResetVisible] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [filterQuery, setFilterQuery] = useState<any>(defaultFilter)
  const [isResetPasswordOpen, setResetPasswordOpen] = useState(false)
  const [userId, setUserId] = useState('')
  const [fetchAllPagination, setFetchAllPagination] = useState([0, 10])
  const [page, setPage] = useState(1)

  const selectItem = (id: never, event: boolean) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  useEffect(() => { }, [])

  // const selectAllItem = () => {
  //   console.log("selectAllItem", "selectAllItem")
  //   if (!selectAll[currentPage]) {
  //     const newArr: string[] = allUsers?.data.map((n: any) => n.id)
  //     console.log(newArr.length, "newArr")
  //     console.log("if")
  //     setSelectedItem({
  //       ...selectedItem,
  //       [currentPage]: newArr,
  //     })
  //     setSelectAll({
  //       ...selectAll,
  //       [currentPage]: true,
  //     })
  //     return
  //   }
  //   setSelectedItem({
  //     ...selectedItem,
  //     [currentPage]: [],
  //   })
  //   console.log("ifssss")
  //   setSelectAll({
  //     ...selectAll,
  //     [currentPage]: false,
  //   })
  // }

  const openAddDrawer = () => {
    DrawerEventEmitter.emit('openDrawer', addUserDrawer, true, {
      data: {
        onSuccess: () => {
          refetch({
            role,
            filter: filterQuery,
            currentPage: 1,
            perPage,
            companyId:
              state.selectedCompanyId ||
              (state.currentUser.companyId && state.currentUser.companyId.id) ||
              null,
          })
          setSelectedItem({})
          setSelectedItem({})
        },
      },
    })

  }

  const handleClick = () => { }

  const confirmDelete = async (userIds: string[]) => {
    deleteUser(userIds, role, () =>
      refetch({
        role,
        filter: filterQuery,
        currentPage,
        perPage,
      }),
    )
  }

  const confirmResendPassword = (id: string) => {
    updateUserPassword(id)
  }

  const confirmChangeStatus = (userId: string, user: any) => {
    updateUserStatus([userId], user)
  }

  const confirmDeleteMany = (role: string) => {
    deleteUser(selectedItem[currentPage], role, () =>
      refetch({
        role,
        filter: filterQuery,
        currentPage,
        perPage,
      }),
    )
    setSelectedItem([])
  }

  const confirmChangeStatusMany = (newStatus: string) => {
    updateUserStatus(selectedItem[currentPage], newStatus)
    setSelectedItem([])
  }


  const handleEditClick = (userId: string) => {
    DrawerEventEmitter.emit('openDrawer', editUserDrawer, true, {
      id: userId,
      role,
    })

  }

  const handleCancelSubscribe = (id: string, userId: string) => {
    unSubscribe({
      variables: {
        id,

        userId
      }
    })
  }
  const handleSubscribe = (userId: string, productId: string) => {
    subscribe({
      variables: {
        userId,
        productId
      }
    }).then(response => {

      activate({
        variables: {
          subscriptionId: response.data.addSubscriptionByAdmin.id,
          orderId: "byadmin"
        }
      })
    }).catch(err => {
      // Handle error
      console.error(err);
    });
  }


  const handleEditSubmission = async (userId: string) => {


    const result = await client.query({
      query: GET_ACTIVE_SUB,
      variables: {
        userId
      },
      fetchPolicy: 'network-only'
    })
    let sub = result.data.getAttachedSubs && result.data.getAttachedSubs
    let renderSubscriptions

    if (result.data.getAttachedSubs) {
      renderSubscriptions = <div>
        {`${t('form_fields.price')}: ${sub.product.price}, ${t('general.occurrenceNumber')}: ${sub.product.occurrenceNumber}`}
      </div>
    } else {
      renderSubscriptions = subscriptions?.getSubscriptionProducts.data.map((sub: any) => {
        return <div key={sub.id}>

          <input checked={selectedValue === sub.id}
            style={{ marginRight: "10px" }} type="radio" id={sub.id} name="fav_language" value={sub.id} ></input>
          <label htmlFor={sub.id}>{`${t('form_fields.price')}: ${sub.price}, ${t('general.occurrenceNumber')}: ${sub.occurrenceNumber}`}</label>
        </div>
      })

    }


    const attachRadioEventListeners = () => {
      const radios = document.querySelectorAll('input[type="radio"]');
      radios.forEach(radio => {
        radio.addEventListener('change', (e: Event) => {

          const newValue = (e.target as HTMLInputElement).value;
          setSelectedValue(newValue);
          selectedValueRef.current = newValue;
        });
      });
    }
    const jsxToHtml = (jsx: any) => {
      const tempDiv = document.createElement('div');
      ReactDOM.render(jsx, tempDiv);
      const htmlString = tempDiv.innerHTML;
      ReactDOM.unmountComponentAtNode(tempDiv);
      return htmlString;
    }
    let htmlContent = jsxToHtml(renderSubscriptions);

    let params: any = {}


    if (role === 'ADMIN') {
      params = {
        title: t('popups.can_not_delete_admin'),
        onConfirm: () => {
          history.push('/groups')
        },
        confirmText: t('popups.go_to_groups'),
      }
    } else {
      params = {
        title: sub ? "მომხმარებლის პაკეტი" : "აირჩიეთ პაკეტი",
        html: htmlContent,
        onConfirm: () => {
          if (sub) {
            handleCancelSubscribe(sub.id, userId)
          } else {
            handleSubscribe(userId, selectedValueRef.current);
            setSelectedValue("");
            selectedValueRef.current = "";
          }

        },
        willOpen: attachRadioEventListeners,
        confirmText: sub ? "გამოწერის წაშლა" : "გამოწერა",
      }
    }
    fireSwal(params)
  }

  const handleDeleteClick = (user: any) => {
    let params: any = {}

    if (role === 'ADMIN' && user.group.length !== 0) {
      params = {
        title: t('popups.can_not_delete_admin'),
        onConfirm: () => {
          history.push('/groups')
        },
        confirmText: t('popups.go_to_groups'),
      }
    } else {
      params = {
        title: `${t('popups.delete_single')} ${role === 'SUPER_ADMIN'
          ? t('general.super_admin')
          : t(`general.${role.toLocaleLowerCase()}`)
          }?`,
        onConfirm: () => {
          confirmDelete([user.id])
        },
        confirmText: t('popups.confirm_delete'),
      }
    }
    fireSwal(params)
  }

  const handleResendPasswordClick = (userId: string) => {
    fireSwal({
      title: t('popups.resend_password'),
      onConfirm: () => {
        confirmResendPassword(userId)
      },
      confirmText: t('popups.confirm_resend_password'),
    })
  }

  const handleChangeStatusClick = (userId: string, status: string) => {
    if (status === 'ACTIVE') {
      fireSwal({
        title: `${t('popups.suspend_single')} ${role === 'SUPER_ADMIN'
          ? t('general.super_admin')
          : t(`general.${role.toLocaleLowerCase()}`)
          }?`,
        onConfirm: () => {
          confirmChangeStatus(userId, 'SUSPENDED')
        },
        confirmText: t('popups.confirm_suspend'),
      })
    } else {
      fireSwal({
        title: `${t('popups.activate_single')} ${role === 'SUPER_ADMIN'
          ? t('general.super_admin')
          : t(`general.${role.toLocaleLowerCase()}`)
          }?`,
        onConfirm: () => {
          confirmChangeStatus(userId, 'ACTIVE')
        },
        confirmText: t('popups.confirm_activate'),
      })
    }
  }

  const userRole = () => {
    let formattedRole
    if (
      (selectAll?.['1'] &&
        !!selectedItem?.['1'] &&
        selectedItem?.['1'].length > 1) ||
      (!!selectedItem?.['1'] && selectedItem?.['1'].length > 1)
    ) {
      if (role === 'SUPER_ADMIN') {
        formattedRole = t('general.super_admins')
      } else if (role === 'ADMIN') {
        formattedRole = t('general.admins')
      } else if (role === 'COACH') {
        formattedRole = t('general.coaches')
      } else if (role === 'STUDENT') {
        formattedRole = t('general.students')
      } else formattedRole = role
    } else {
      if (role === 'SUPER_ADMIN') {
        formattedRole = t('general.super_admin')
      } else if (role === 'ADMIN') {
        formattedRole = t('general.admin')
      } else if (role === 'COACH') {
        formattedRole = t('user_profile.coach')
      } else if (role === 'STUDENT') {
        formattedRole = t('user_profile.student')
      } else formattedRole = role
    }
    return formattedRole
  }

  const handleDeleteAllClick = () => {
    const selectedUsers = selectedItem[currentPage].map(
      (selectedItem: string) => {
        const usersToDelete = users?.data.filter(
          (user: any) => user.id === selectedItem,
        )
        return usersToDelete[0]
      },
    )

    const selectedUsersWithGroup = selectedUsers.filter(
      (user: any) => user.group.length !== 0,
    )

    if (selectedItem[currentPage].includes(state.currentUser.id)) {
      fireSwal({
        title: t('popups.delete_yourself'),
        showConfirmButton: false,
      })
    } else {
      if (selectedUsersWithGroup.length !== 0 && role === 'ADMIN') {
        fireSwal({
          title:
            selectedItem['1'].length === 1
              ? t('popups.can_not_delete_admin_single')
              : t('popups.can_not_delete_admin_many'),
          onConfirm: () => {
            history.push('/groups')
          },
          confirmText: t('popups.go_to_groups'),
        })
      } else {
        fireSwal({
          title: `${t('popups.delete_many')} ${userRole()}?`,
          onConfirm: () => confirmDeleteMany(role),
          confirmText: t('popups.confirm_delete'),
        })
      }
    }
  }

  const handleChangeStatusAllClick = (action: string) => {
    let title: string = ''
    let newStatus: string = ''
    let confirmText: string = ''
    let showConfirmButton: boolean = true

    if (selectedItem[currentPage].includes(state.currentUser.id)) {
      title = `${t('popups.suspend_yourself')}`
      showConfirmButton = false
    } else {
      showConfirmButton = true
      if (action === 'suspend') {
        title = `${selectAll?.['1'] ||
          (!!selectedItem?.['1'] && selectedItem?.['1'].length > 0)
          ? t('actions.deactivate_different_roles_from_checkbox')
          : t('actions.deactivate_different_roles_from_action')
          } ${userRole()}?`
        newStatus = 'SUSPENDED'
        confirmText = t('general.yes')
      } else {
        title = `${selectAll?.['1'] ||
          (!!selectedItem?.['1'] && selectedItem?.['1'].length > 0)
          ? t('actions.activate_different_roles_from_checkbox')
          : t('actions.activate_different_roles_from_action')
          } ${userRole()}?`
        newStatus = 'ACTIVE'
        confirmText = t('general.yes')
      }
    }

    fireSwal({
      title,
      onConfirm: () => confirmChangeStatusMany(newStatus),
      confirmText,
      showConfirmButton,
    })
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    const formValue = {
      search: {
        type: 'search',
        value: searchValue,
        fields: ['firstName', 'lastName', 'email', 'phone'],
      },
    }
    let filterData = { ...filterQuery }
    if (searchValue !== '') {
      filterData = { ...filterQuery, ...formValue }
    } else {
      const { search, ...filters } = filterQuery
      filterData = { ...filters }
    }

    refetch({
      role,
      filter: filterData,
      currentPage: 1,
      perPage,
    })

    setFilterQuery(filterData)
    setResetVisible(true)
  }

  const handleSearchChange = (e: any) => {
    setSearchValue(e.currentTarget.value)
  }

  const handleFiltersClick = () => {
    setFilterDrawerOpened(true)
  }

  const handleExportData = () => {
    if (selectedItem[currentPage] && selectedItem[currentPage].length > 0) {
      exportData(role, selectedItem[currentPage], (link: string) => {
        window.open(link, '_blank')
      })
    } else {
      exportData(role, [], (link: string) => {
        window.open(link, '_blank')
      })
    }
  }

  useEffect(() => {
    refetch()
  }, [state.selectedCompanyId])

  let field = ''

  switch (role) {
    case 'MODERATOR':
      field = 'Moderator'
      break
    case 'SUPER_ADMIN':
      field = 'SuperAdmin'
      break
    case 'STUDENT':
      field = 'Student'
      break
    case 'COACH':
      field = 'Coach'
      break
    case 'ADMIN':
      field = 'Admin'
      break
    default:
      break
  }

  const theme = useTheme()

  const config = columnConfig(role, handleClick, selectItem, t, theme)
  const actions = actionConfig(
    handleEditClick,
    handleDeleteClick,
    handleResendPasswordClick,
    handleChangeStatusClick,

    // handleChangeQualificationClick,
    state.userPermission,
    field,
    state.currentUser.role,
    t,
    setResetPasswordOpen,
    state.currentUser.id,
    setUserId,
    handleEditSubmission,
  )
  const multiActions = () => {
    const disabledAction = selectedItem[currentPage]
      ? selectedItem[currentPage].length < 1
      : true

    let actions: any = [
      {
        id: 0,
        color: 'secondary',
        tooltipText: `${t('actions.delete')}`,
        hide: !state.userPermission[`delete${field}`],
        disabled: disabledAction,
        onClick: () => handleDeleteAllClick(),
        component: (
          <ActionButton
            disabled={disabledAction}
            fill={'#CC3131'}
            disabledFill={'#080A0AD6'}
            theme={theme}
          >
            <Icon filename={'delete'} />
          </ActionButton>
        ),
      },
      {
        id: 1,
        color: 'secondary',
        tooltipText: `${t('actions.suspend')}`,
        hide: !state.userPermission[`suspend${field}`],
        disabled: disabledAction,
        onClick: () => handleChangeStatusAllClick('suspend'),
        component: (
          <ActionButton
            disabled={disabledAction}
            fill={'#CC3131'}
            disabledFill={'#080A0AD6'}
            theme={theme}
          >
            <Icon filename={'cancel'} />
          </ActionButton>
        ),
      },
      {
        id: 2,
        color: 'primary',
        tooltipText: `${t('actions.activate')}`,
        disabled: disabledAction,
        hide: !state.userPermission[`suspend${field}`],
        onClick: () => handleChangeStatusAllClick('activate'),
        component: (
          <ActionButton
            disabled={disabledAction}
            fill={'#269F5A'}
            disabledFill={'#080A0AD6'}
            theme={theme}
          >
            <Icon filename={'testing'} />
          </ActionButton>
        ),
      },
      {
        id: 10,
        color: 'primary',
        tooltipText: `${t('actions.export')}`,
        // disabled: disabledAction,
        onClick: () => handleExportData(),
        component: (
          <ActionButton
            // disabled={disabledAction}
            fill={'#269F5A'}
            // disabledFill={'#080A0AD6'}
            theme={theme}
          >
            <Icon filename={'download'} />
          </ActionButton>
        ),
      },
    ].filter((i: any) => !i.admin)

    if (role === 'COACH') {
      actions = [...actions]
    }

    return actions.filter((i: any) => !i.hide)
  }

  const filter = (filters: any) => {
    refetch({
      role,
      filter: filters,
      currentPage: 1,
      perPage,
    })
    if (users) {
      setFilterDrawerOpened(false)
      setResetVisible(true)
      setFilterQuery(filters)
      setSelectedItem({})
    }
  }

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    if ((page - newPage === 1 || page - newPage === 0) && fetchAll) {
      setFetchAllPagination(prevState => [prevState[0] + 10, prevState[1] + 10])
      setPage(page + 1)
      return
    } else if (page > newPage && fetchAll) {
      setFetchAllPagination(prevState => [prevState[0] - 10, prevState[1] - 10])
      setPage(page - 1)
      return
    }
    refetch({
      role,
      filter: filterQuery,
      currentPage: newPage + 1,
      perPage,
    })
    setCurrentPage(newPage + 1)

    // setFetchAllPagination(prevState => [prevState[0] + 10, prevState[1] + 10])
    // setPage(page + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    refetch({
      role,
      filter: filterQuery,
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
    setSelectedItem({})
  }

  const [filterOptions, setFilterOptions] = useState<any>(null)

  const handleResetClick = () => {
    refetch({
      role,
      filter: defaultFilter,
      currentPage,
      perPage,
    })
    setResetVisible(false)
    setSearchValue('')
    setFilterQuery({})
    setFilterOptions(null)
  }

  const renderFilterComponent = () => {
    if (role === 'STUDENT') {
      return (
        <FilterStudents
          role="STUDENT"
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filter}
          filterLoading={loading}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          hasSearchByCompany={state.selectedCompanyId ? false : true}
          companyId={companyId}
        />
      )
    }

    if (role === 'COACH') {
      return (
        <FilterCoaches
          role="COACH"
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filter}
          filterLoading={loading}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          hasSearchByCompany={state.selectedCompanyId ? false : true}
          companyId={companyId}
          forAssignCoaches={companyId !== null}
        />
      )
    }

    if (role === 'SUPER_ADMIN') {
      return (
        <FilterModerators
          role="SUPER_ADMIN"
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filter}
          filterLoading={loading}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          hasSearchByCompany={state.selectedCompanyId ? false : true}
          companyId={companyId}
        />
      )
    }

    if (role === 'ADMIN') {
      return (
        <FilterAdmins
          role="ADMIN"
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filter}
          filterLoading={loading}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          hasSearchByCompany={state.selectedCompanyId ? false : true}
          companyId={companyId}
        />
      )
    }
  }

  let tableTitle = ''

  switch (role) {
    case 'SUPER_ADMIN':
      tableTitle = t('general.super_admins')
      break
    case 'ADMIN':
      tableTitle = t('general.admins')
      break
    case 'COACH':
      tableTitle = t('general.coaches')
      break
    case 'STUDENT':
      tableTitle = t('general.students')
  }
  const containerStyle: React.CSSProperties = {
    width: '150px',
    height: '30px',
    borderRadius: '15px',
    border: '1px solid #f3f3f3',
    position: 'relative',
    overflow: 'hidden'
  };

  // Styles for the blue element that moves inside the container
  const blueElementStyle: React.CSSProperties = {
    width: '50px',
    height: '30px',
    backgroundColor: 'blue',
    position: 'absolute',
    top: 0,
    left: '-50px', // Start position (hidden on the left)
    animation: 'moveRight 2s linear infinite'
  };

  // Keyframes for moving the blue element from left to right
  const moveRightAnimation = `
    @keyframes moveRight {
      0% { left: -50px; }
      100% { left: 150px; }
    }
  `;


  const createButton = () => (
    <Button
      text={addText}
      color="secondary"
      onClick={openAddDrawer}
      icon={<AddCircleRoundedIcon />}
      background={mainColor}
    />
  )

  return (

    <Container theme={theme}>

      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => { }}
        totalWidth="970px"
      >
        {renderFilterComponent()}
      </Drawer>
      <GridToolbar>
        <GridLayoutActions>
          <div>
            {role === 'SUPER_ADMIN' && (
              // state.userPermission.createSuperAdmin &&
              <AddButton onClick={openAddDrawer}>
                <Icon filename={'add'} fill={'#ffffff'} />
                {addText}
              </AddButton>
            )}
          </div>
        </GridLayoutActions>
        <GridLayoutForm>
          <ActionButton
            theme={theme}
            onClick={handleResetClick}
            disabled={resetVisible ? false : true}
          >
            <SyncIcon fontSize="small" />
          </ActionButton>
          <MultipleAction theme={theme}>
            <ActionButton theme={theme} onClick={handleFiltersClick}>
              <Icon filename={'filter'} fill={'#080A0AD6'} />
            </ActionButton>
            <MultipleActionTooltip>
              {t('actions.filters')}
            </MultipleActionTooltip>
          </MultipleAction>
          <Search
            inputProps={{
              value: searchValue,
              onChange: handleSearchChange,
            }}
            onClick={handleSearchSubmit}
          />
        </GridLayoutForm>
      </GridToolbar>
      {/* {allUserLoading && <div style={{
        position: 'absolute',
        zIndex: 1100,
        top: '428px',
        left: '25px',
        backgroundColor: "white",
        width: "117px",
        height: "50px"
      }}>

      </div>} */}

      <div>
        {(deleteUserLoading ||
          changeCoachQualificationLoading ||
          updateUserStatusLoaing) && <Loader />}
        {users && (
          <Grid
            fetchAll={fetchAll}
            setFetchAll={setFetchAll}
            checked={true}
            title={tableTitle}
            onFiltersClick={handleFiltersClick}
            resetVisible={resetVisible}
            resetFilters={handleResetClick}
            config={config}
            data={
              // fetchAll
              //   ? allUsers?.data?.slice(...fetchAllPagination) :
              users?.data
            }
            actionConfig={actions}
            selected={_.size(selectedItem[currentPage])}
            // selectAllItem={selectAllItem}
            selectedItems={selectedItem[currentPage] || []}
            actions={multiActions}
          />
        )}

        {users && users.data && (
          <TablePagination
            currentPage={
              users ?
                users.currentPage
                : 0
            }
            // currentPage={page}
            rowsPerPage={perPage}
            count={users ? users.totalCount : 0}
            handleChangePage={handlePaginationClick}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
        {isResetPasswordOpen && (
          <ResetUsersPassword
            isModalOpen={isResetPasswordOpen}
            setModalOpen={setResetPasswordOpen}
            userId={userId}
          />
        )}
      </div>
    </Container >
  )
}

export default UsersLayout

const Container = styled.div`
  background: ${props => props.theme.palette.background.default};
  border: 1px solid ${props => props.theme.palette.background.default};
  border-radius: 8px;
  height: 100%;
`

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #317bf4;
  padding: 18px 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    background: #3165f4;
  }
`
