import { useMutation } from '@apollo/react-hooks'
import { GET_COMPANY, UPDATE_COMPANY_AVATAR } from 'gql/companies.query'
import { updateCache } from 'services/updateCache'


export const updateProfileAvatarService = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [updateCompanyAvatar] = useMutation(UPDATE_COMPANY_AVATAR, {
      update(cache, { data: { updateCompanyAvatar: document } }) {
        updateCache({
          objectName: "company",
          query: GET_COMPANY,
          cache,
          document,
          action: "update",
        })
      }
    })
  
    return {
      updateCompanyAvatar
    }
  }