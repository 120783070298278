import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { LOGOUT } from 'gql/auth/logout.query'

import Popover from '@material-ui/core/Popover'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Button } from 'components/common/Button'
import ProfilePicture from '../common/ProfilePicture/ProfilePicture.tsx'
import moment from 'moment'
import useAuth from 'hooks/useAuth'
import AvatarIcon from '../common/AvatarInput/profile.svg'
import { mainColor } from 'components/common/Colors/CommonColors'
import Icon from 'components/Icon'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { GlobalUserDefaultImage } from 'utils/utils'
import { useLazyQuery } from '@apollo/react-hooks'

const useStyles = makeStyles(theme => ({
  notification: {
    borderRadius: '16px',
    overflow: 'hidden',
    boxShadow: '0px 30px 30px #00000026',
  },
}))

const ProfileDropdown = ({ user, t }) => {
  const history = useHistory()
  const classes = useStyles()
  const { logout } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()
  const [logoutDevice, data] = useLazyQuery(LOGOUT, {
    context: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
    onCompleted: data => {
      logout()
    },
  })
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onLogoutClick = () => {
    handleClose()
    logoutDevice()
    // logout()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <Wrapper role="button" aria-describedby={id} onClick={handleClick}>
        {user.avatar ? (
          <ProfilePicture
            imgSource={user.avatar || AvatarIcon}
            width={37}
            height={37}
          />
        ) : (
          <EmptyProfileImage>
            <img
              src="/svg/profileAvatar.png"
              alt=""
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          </EmptyProfileImage>
        )}
        {/* <Title>
          {user.firstName} {user.lastName}{' '}
        </Title> */}
        <Icon
          style={{ marginLeft: '10px' }}
          width={10}
          height={5}
          filename={'down_arrow'}
          fill={theme.palette.text.primary}
        />
      </Wrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{
          marginTop: '30px',
          marginLeft: '14px',
        }}
        classes={{
          paper: classes.notification,
        }}
      >
        <PopoverContent>
          <ProfileInfo>
            <img
              src={user.avatar || GlobalUserDefaultImage}
              style={{
                width: '70px',
                height: '70px',
                borderRadius: '16px',
                border: '1px solid #707070',
              }}
            />
            <ProfileInfoContent>
              <ProfileInfoTitle theme={theme}>
                {user.firstName} {user.lastName}
              </ProfileInfoTitle>
              <span
                style={{
                  color: theme.palette.text.secondary,
                  fontSize: '14px',
                }}
              >
                {user.role}
                {/* {t('user_details.member_since')}
                {' '}
                {moment(new Date(user.createDate)).format('DD.MM.YYYY')} */}
              </span>
            </ProfileInfoContent>
          </ProfileInfo>
          <ProfileActions>
            {user.role !== "STUDENT" && <ProfileActionsItem
              style={{ background: '#080A0A0A' }}
              onClick={() => {
                history.push(`/user-profile/${user.id}`)
                handleClose()
              }}
              theme={theme}
            >
              <Icon
                color={theme.palette.text.primary}
                filename={'profileIcon'}
              />
              {t('general.my_profile')}
            </ProfileActionsItem>}
            <ProfileActionsItem
              theme={theme}
              onClick={onLogoutClick}
              type={'primary'}
            >
              <Icon fill={theme.palette.text.primary} filename={'logout'} />
              {t('actions.log_out')}
            </ProfileActionsItem>
          </ProfileActions>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default ProfileDropdown

const EmptyProfileImage = styled.div`
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #fff;
  overflow: hidden;
  border-radius: 12px;
  font-size: 14px;
  @media (max-width: 600px) {
    width: 33px;
    height: 33px;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Title = styled.h4`
  font-size: 14px;
  color: #fff;
  margin-left: 10px;
`

const PopoverContent = styled.div`
  padding: 35px 45px;
  width: 370px;
  height: 368px;
`

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ProfileInfoContent = styled.section`
  text-align: center;
  margin-top: 10px;
`

const ProfileInfoTitle = styled.div`
  font-size: 20px;
  color: ${props => props.theme.palette.text.primary};
  font-weight: 600;
`

const ProfileActions = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0px;
  bottom: 30px;
  gap: 16px;
  width: 100%;
  padding: 0px 45px;
`

const ProfileActionsItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 20px;
  background: ${props => props.theme.palette.background.default};
  border-radius: 16px;
  color: ${props => props.theme.palette.text.primary};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;

  svg {
    fill: #080a0ad6;
    width: 25px;
    height: 25px;
  }

  ${({ type }) => {
    switch (type) {
      case 'primary':
        return css`
          background: #317bf4;
          color: #ffffff;

          svg {
            fill: #ffffff;
          }
        `
    }
  }}
`
