import React, { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import TextInput from 'components/common/TextInput/TextInput'
import { FormFields, FormField } from 'components/common/Form'
import ReactSelect from 'react-select'
import countries from 'countries'
import { GET_ALL_INDUSTRY } from 'gql/skilss.query'
import CreatableSelect from 'react-select/creatable'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { SelectField } from 'components/common/SelectField'

interface FormValues {
  name?: string | number | undefined
  companyEmail?: string | number | undefined
  address?: string | undefined
  phone?: string | number | undefined
  companyActivity?: any
  keyContactName?: string | undefined
  city?: string | undefined
  note?: string | undefined
  platformService?: boolean | undefined
  postalCode?: string | undefined
  numberOfUsers?: any
  country?: any
  phoneFields?: any
  phoneFieldValue?: string | null | undefined
}

interface FormErrors {
  name?: string | undefined
  companyEmail?: string | undefined
  address?: string | undefined
  phone?: string | undefined
  companyActivity?: string | undefined
  keyContactName?: string | undefined
  city?: string | undefined
  note?: string | undefined
  platformService?: string | undefined
  phoneFields?: any
}

interface FormTouched {
  name?: boolean | undefined
  companyEmail?: boolean | undefined
  address?: boolean | undefined
  phone?: boolean | undefined
  companyActivity?: boolean | undefined
  keyContactName?: boolean | undefined
  city?: boolean | undefined
  note?: boolean | undefined
  platformService?: boolean | undefined
  phoneFields?: any
}

interface Props {
  values: FormValues
  errors: FormErrors
  generalError: string
  touched: FormTouched
  handleChange: (eventOrPath: string | React.ChangeEvent<any>) => void
  setFieldValue: any
  t: any
}

const GeneralInformation = ({
  values,
  errors,
  generalError,
  touched,
  handleChange,
  setFieldValue,
  t,
}: Props) => {
  const { data } = useQuery(GET_ALL_INDUSTRY)

  const activityList =
    (data &&
      data.getAllIndustry.map((i: any) => ({ label: i.label, value: i.id }))) ||
    []

  // useEffect(() => {
  //   const errorMes = `${t('user_details.phone')} ${t('validations.required')}`

  //   if (
  //     values.phone &&
  //     (!values.phoneFields || (values.phoneFields && !values.phoneFields.value))
  //   ) {
  //     errors.phone = errorMes
  //   } else if (!values.phone && values.phoneFields) {
  //     errors.phone = errorMes
  //   } else if (
  //     (values.phone && values.phoneFields) ||
  //     (!values.phone &&
  //       (!values.phoneFields ||
  //         (values.phoneFields && !values.phoneFields.value)))
  //   ) {
  //     delete errors.phone
  //   }
  // }, [values.phone, values.phoneFields, errors.phone])

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  return (
    <FormFields>
      <FormField width="48%">
        <TextInput
          error={errors.name ? true : false}
          errorMessage={errors.name ? errors.name : ''}
          touched={touched.name}
          label={`${t('general.company')} ${t('form_fields.title')}*`}
          name="name"
          size="medium"
          type="text"
          value={values.name}
          onChange={handleChange}
        />
      </FormField>
      <FormField width="48%">
        <TextInput
          error={errors.keyContactName ? true : false}
          errorMessage={errors.keyContactName ? errors.keyContactName : ''}
          touched={touched.keyContactName}
          label={`${t('company_details.key_contact_name')}*`}
          name="keyContactName"
          size="medium"
          type="text"
          value={values.keyContactName}
          onChange={handleChange}
        />
      </FormField>
      <FormField width="48%">
        <TextInput
          error={errors.companyEmail || generalError ? true : false}
          errorMessage={
            errors.companyEmail || generalError
              ? errors.companyEmail || generalError
              : ''
          }
          touched={touched.companyEmail}
          label={`${t('company_details.key_contact_email')}*`}
          name="companyEmail"
          size="medium"
          type="text"
          value={values.companyEmail}
          onChange={handleChange}
        />
      </FormField>
      <FormField width="48%">
        <PhoneCountryCode
          label={t('user_details.phone')}
          selectLabel={t('form_fields.dial_code')}
          selectValue={values.phoneFields}
          selectChange={(e: any) => setFieldValue('phoneFields', e)}
          inputLabel={t('user_details.phone')}
          inputValue={values.phone}
          inputChange={handleChange}
          errorMessage={errors.phone || errors.phoneFields}
          error={!!errors.phone || errors.phoneFields}
          touched={!!touched.phone || touched.phoneFields}
        />
      </FormField>
      {/* <ReactSelect
        placeholder="Industry* "
        name="companyActivity"
        options={CompanyActivityItems}
        value={values.companyActivity}
        onChange={(e: any) => setFieldValue('companyActivity', e)}
      // onBlur={handleBlur}
      /> */}
      <FormField width="48%">
        <SelectField
          // isMulti
          onChange={(e: any) => setFieldValue('companyActivity', e)}
          options={activityList}
          value={values.companyActivity}
          placeholder={t('company_details.industry')}
        />
      </FormField>
      <FormField width="48%">
        <TextInput
          // error={errors.numberOfUsers ? true : false}
          // errorMessage={errors.numberOfUsers ? errors.numberOfUsers : ""}
          // touched={touched.numberOfUsers}
          label={t('company_details.number_of_users')}
          name="numberOfUsers"
          size="medium"
          type="text"
          value={values.numberOfUsers}
          onChange={handleChange}
        />
      </FormField>
      <FormField width="48%">
        <SelectField
          placeholder={t('company_details.country')}
          name="country"
          options={countries}
          value={values.country}
          onChange={(e: any) => setFieldValue('country', e)}
        />
      </FormField>
      <FormField width="48%">
        <TextInput
          label={t('company_details.address')}
          name="address"
          size="medium"
          type="text"
          value={values.address}
          onChange={handleChange}
        />
      </FormField>
      <FormField width="48%">
        <TextInput
          label={t('company_details.city')}
          name="city"
          size="medium"
          type="text"
          value={values.city}
          onChange={handleChange}
          // onBlur={handleBlur}
        />
      </FormField>
      <FormField width="48%">
        <TextInput
          label={t('company_details.postal_code')}
          size="medium"
          type="text"
          name="postalCode"
          value={values.postalCode}
          onChange={handleChange}
        />
      </FormField>
      <FormField width="100%">
        <TextInput
          label={t('form_fields.note')}
          name="note"
          size="medium"
          type="text"
          fullWidth
          multiline
          rows="3"
          value={values.note}
          onChange={handleChange}
        />
      </FormField>
      {/* <FormField width="48%">

        <ItemWrapper>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.platformService}
                onChange={handleChange}
                name="platformService"
                color="primary"
              />
            }
            label="Platform Service"
          />


          <Tooltip
            title={
              <span style={{ fontSize: '14px' }}>
                If this checkbox is ticked,
                Company can create their own Courses.
                If it is not ticked, Company can only view the
                purchased Courses and the ones in the Marketplace
                and purchase them. They can add their Groups,
                Students, Admins and Coaches in these Courses anyway.
              </span>
            }
            arrow
          >
            <HelpIcon color="primary" />
          </Tooltip>

        </ItemWrapper>
      </FormField> */}
    </FormFields>
  )
}

export default GeneralInformation
