import React from 'react'
import styled from 'styled-components'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'

export const actionConfig = (
  handleAddLessonClick?: any,
  handleEditClick?: any,
  handleDeleteClick?: any,
  handleDownloadClick?: any,
  permissions?: any,
  t?: any,
) => {
  let action = [
    {
      hide: !permissions.createLesson,
      render: (item: any) => (
        <Action onClick={() => handleAddLessonClick(item.id, item.name)}>
          <AddCircleIcon />
          <span>{t('actions.add_lesson')}</span>
        </Action>
      ),
    },
    // {
    //   hide: !permissions.editTopic,
    //   render: (item: any) => (
    //     <Action onClick={() => handleEditClick(item.id)}>
    //       <EditRoundedIcon />
    //       <span>Edit</span>
    //     </Action>
    //   ),
    // },
    {
      hide: !permissions.deleteTopic,
      render: (item: any) => (
        <Action onClick={() => handleDeleteClick(item.id)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action
          onClick={() => handleDownloadClick(item.attachment.link)}
          className={item.attachment ? '' : 'disabled'}
        >
          <CloudDownloadIcon />
          <span>{t('actions.download_attachment')}</span>
        </Action>
      ),
    },
  ]

  return action.filter((i: any) => !i.hide)
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`
