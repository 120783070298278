
import gql from 'graphql-tag'


export const GET_COUCH_RATING = gql`
  query($ratedId: String!, $skip: Int, $count: Int, $filter: ReviewsFilter) {
    getReviews(ratedId: $ratedId, skip: $skip, count: $count, filter: $filter) {
      totalReviews
      averageRating
      count
    }
  }
`

export const GET_COACH_BY_COURSEID = gql`
  query($courseId: String!) {
    getCoachByCourseId(courseId: $courseId) {
      id,
      name,
      coachId,
      avatar,
      averageRating
    }
  }
`

