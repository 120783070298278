import { useState } from 'react'
import deleteQuestionsService from 'services/questions/deleteQuestionsService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

export const useDeleteQuestion = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const { deleteQuestionsMutation, loading } = deleteQuestionsService()

  const deleteQuestion = async (
    moduleId: string,
    questionIds: string[],
    successCallback: () => void,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await deleteQuestionsMutation({
      variables: {
        moduleId,
        questionIds,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return false
      }
      variant = 'error'
    } else {
      successCallback()
      const isMultiple = questionIds.length > 1 ? true : false
      message = isMultiple
        ? `${t('general.questions')} ${t('messages.deleted')}`
        : `${t('general.question')} ${t('messages.deleted')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteQuestion,
    deleteQuestionLoading: loading,
    error,
  }
}
