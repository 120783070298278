import { useSnackbar } from 'notistack'
import { snackbarProps } from 'interfaces/snackbar'

const useSnackbarAlert = () => {
  const { enqueueSnackbar } = useSnackbar()

  const setSnackbar = ({ message, variant, anchorOrigin }: snackbarProps) => {
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: 3000,
      anchorOrigin: anchorOrigin,
    })
  }
  return {
    setSnackbar,
  }
}

export default useSnackbarAlert
