import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Checkbox from 'components/common/Checkbox'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import IconButton from '@material-ui/core/IconButton'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import GetAppIcon from '@material-ui/icons/GetApp'
import HeadsetIcon from '@material-ui/icons/Headset'
import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import { formatDuration } from 'helpers/formatDuration'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CheckBoxAndAvatarContainer } from 'pages/companyWizard/CompanyStudents/gridConfig'
import Icon from 'components/Icon'
interface itemProps {
  id: string
  isChecked: boolean
  name: string
  attachment: any
  duration: number
  audio?: any
  video?: any
  contentDocument?: any
  transcriptFile?: any
  subtitle?: any
}

export const columnConfig = (
  permissions: any,
  selectItem?: any,
  handleClick?: any,
  handleOpenMediaModal?: any,
  t?: any,
) => {
  let gridWithoutCheckbox = [
    // {
    //   numeric: false,
    //   disablePadding: true,
    //   id: 'video',
    //   label: t('general.content'),
    //   render: (item: itemProps) => {
    //     if (item.audio && item.audio.link) {
    //       return (
    //         <IconButton onClick={() => handleOpenMediaModal(item, 'audio')}>
    //           <HeadsetIcon fontSize="small" color="secondary" />
    //         </IconButton>
    //       )
    //     }

    //     if (item.video && item.video.links) {
    //       const hasConverted = item.video && item.video.links[0].converted
    //       return hasConverted || (item.video && hasConverted) ? (
    //         <IconButton onClick={() => handleOpenMediaModal(item, 'video')}>
    //           <PlayCircleOutlineIcon fontSize="small" color="secondary" />
    //         </IconButton>
    //       ) : (
    //         <CircularProgress size={20} color="primary" />
    //       )
    //     }

    //     if (item.contentDocument && item.contentDocument.link) {
    //       return (
    //         <IconButton
    //           onClick={() => window.open(item.contentDocument.link, '_blank')}
    //         >
    //           <InsertDriveFileIcon fontSize="small" color="secondary" />
    //         </IconButton>
    //       )
    //     }
    //   },
    // },
    {
      id: 'content',
      label: `${t('general.lessonName')}`,
      render: (item: itemProps) => (
        <div
          style={{
            fontSize: '16px',
            color: '#080A0AD6',
          }}
          onClick={() => handleClick(item)}
        >
          {item.name}
        </div>
      ),
      disablePadding: true,
    },
    {
      id: 'duration',
      numeric: false,
      disablePadding: false,
      label: t('general.duration'),
      render: (item: itemProps) => <div>{formatDuration(item.duration)}</div>,
    },
    // {
    //   id: 'attachment',
    //   numeric: false,
    //   disablePadding: false,
    //   label: t('general.attachment'),
    //   render: (item: itemProps) => {
    //     if (!item.attachment) {
    //       return (
    //         <IconButton>
    //           <InsertDriveFileIcon fontSize="small" color="disabled" />
    //         </IconButton>
    //       )
    //     }

    //     return (
    //       <IconButton
    //         onClick={() => window.open(item.attachment.link, '_blank')}
    //       >
    //         <InsertDriveFileIcon fontSize="small" color="secondary" />
    //       </IconButton>
    //     )
    //   },
    //   // <div>{item.attachment && item.attachment.name || 'No Attachment'}</div>,
    // },
  ]

  let grid = permissions?.deleteLesson
    ? [
      // {
      //   id: 'isChecked',
      //   render: (item: itemProps) => (
      //     <CheckBoxAndAvatarContainer>
      //       <Checkbox
      //         checked={item.isChecked}
      //         onChange={() => selectItem(item.id)}
      //       />
      //     </CheckBoxAndAvatarContainer>
      //   ),
      //   numeric: false,
      //   disablePadding: true,
      // },
      {
        id: 'isChecked',
        render: (item: itemProps) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ColumnIcon
              onClick={() => {
                if (item.audio && item.audio.link) {
                  handleOpenMediaModal(item, 'audio')
                }

                if (item.video && item.video.links) {
                  handleOpenMediaModal(item, 'video')
                }
                if (item.contentDocument && item.contentDocument.link) {
                  window.open(item.contentDocument.link, '_blank')
                }
              }}
            >
              <Icon
                filename={
                  (item.audio && 'voice') ||
                  (item.video && 'videoPlay') ||
                  (item.contentDocument && 'pdf')
                }
              />
            </ColumnIcon>
          </div>
        ),
        numeric: false,
        disablePadding: true,
      },
      ...gridWithoutCheckbox,
    ]
    : [...gridWithoutCheckbox]

  return grid
}

export const downloadActionConfig = (handleClick: any, t: any) => {
  let action = [
    // {
    //   render: (item: any) => (
    //     <Action
    //       onClick={() => handleClick(item)}
    //       className={(item.videos && item.videos.length) > 0 ? '' : 'disabled'}
    //     >
    //       <GetAppIcon />
    //       <span>Video</span>
    //     </Action>
    //   ),
    // },
    // {
    //   render: (item: any) => (
    //     <Action
    //       onClick={() => handleClick(item)}
    //       className={item.audio && item.audio.id ? '' : 'disabled'}
    //     >
    //       <GetAppIcon />
    //       <span>Audio</span>
    //     </Action>
    //   ),
    // },
    // {
    //   render: (item: any) => (
    //     <Action
    //       onClick={() => console.log('item', item)}
    //       className={item.document && item.document.link ? '' : 'disabled'}
    //     >
    //       <GetAppIcon />
    //       <span>Text File</span>
    //     </Action>
    //   ),
    // },
    {
      render: (item: any) => {
        return (
          <Action
            onClick={() =>
              item.transcriptFile &&
              window.open(item.transcriptFile.link, '_blank')
            }
            className={item.transcriptFile ? '' : 'disabled'}
          >
            <GetAppIcon />
            <span>{t('actions.download_transcript')}</span>
          </Action>
        )
      },
    },

    {
      render: (item: any) => (
        <Action
          onClick={() =>
            item.attachment && window.open(item.attachment.link, '_blank')
          }
          className={item.attachment ? '' : 'disabled'}
        >
          <GetAppIcon />
          <span>{t('actions.download_attachment')}</span>
        </Action>
      ),
    },

    {
      render: (item: any) => (
        <Action
          onClick={() =>
            item.subtitle && window.open(item.subtitle.link, '_blank')
          }
          className={item.subtitle ? '' : 'disabled'}
        >
          <GetAppIcon />
          <span>{t('actions.download_subtitle')}</span>
        </Action>
      ),
    },
  ]

  return action
}

export const actionConfig = (
  handleEditClick: any,
  handleDeleteClick: any,
  event?: string,
  permissions?: any,
  canModify?: boolean,
  t?: any,
) => {
  const download: any = downloadActionConfig(handleEditClick, t)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory()

  let action = [
    {
      hide: !permissions.editLesson || !canModify,
      render: (item: any) => (
        <Action onClick={() => handleEditClick(item.id, 'edit')}>
          <EditRoundedIcon />
          {/* {console.log("item", item)} */}
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.deleteLesson || !canModify,
      render: (item: any) => (
        <Action onClick={() => handleDeleteClick([item.id])}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
  ]

  const viewAction = [
    {
      hide: !permissions.viewLesson,
      render: (item: any) => (
        <Action onClick={() => history.push(`/lessons/${item.id}`)}>
          <ViewCompactIcon />
          <span>{t('actions.view')}</span>
        </Action>
      ),
    },
  ]

  if (event) {
    action = [...viewAction, ...action, ...download]
  }

  return action.filter((i: any) => !i.hide)
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

const ColumnIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: #3a7455;
  border: 1px solid #080a0a0a;
  border-radius: 8px;
  margin: 10px 20px;
  svg {
    fill: #ffffffd6;
  }
`
