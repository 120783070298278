import React from 'react'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import styled from 'styled-components'

import EditRoundedIcon from '@material-ui/icons/EditRounded'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import Checkbox from 'components/common/Checkbox'
import {
  CheckBoxAndAvatarContainer,
  StatusWrapper,
} from 'pages/companyWizard/CompanyStudents/gridConfig'
import { formatDuration } from 'helpers/formatDuration'

interface itemProps {
  name: string
  video: string
  status: string
  isChecked?: boolean
  id: string
  level?: string
  coursePrivacy: string
  coachType: any
  coach: any
  duration: string
  studentsEnrolled: string
  rating: string
  numberOfGroups: string
  numberOfStudents: string
}

export const actionConfig = (
  actionHandler: any,
  permissions: any,
  canModify: boolean,
  t: any,
) => {
  let actions = [
    {
      render: (item: any) => (
        <Action onClick={() => actionHandler('view', item.id)}>
          <ViewCompactIcon />
          <span>{t('actions.view')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('edit', item.id)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('delete', item)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.remove_from_course')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify,
      render: (item: any) => (
        <Action
          onClick={() => actionHandler('pdf', item)}
          className={item.attachment ? '' : 'disabled'}
        >
          <CloudDownloadIcon />
          <span>{t('actions.download_attachment')}</span>
        </Action>
      ),
    },
  ]
  return actions.filter((i: any) => !i.hide)
}

export const columnConfig = (handelClick: any, selectItem: any, t?: any) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: any) => (
        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={item.isChecked}
            onChange={() => selectItem(item.id)}
          />
          <img
            onClick={() => handelClick(item.id)}
            src={item.coverImage.link || ''}
            alt="Avatar"
          />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'moduleName',
      numeric: false,
      disablePadding: true,
      center: true,
      label: `${'general.moduleName'}`,
      render: (item: any) => (
        <div onClick={() => handelClick(item.id)} style={{ cursor: 'pointer' }}>
          {item.name}
        </div>
      ),
    },
    {
      id: 'theme',
      label: `${t('general.topic')}`,
      render: (item: any) => (
        <CountContainer>{item.numberOfTopics}</CountContainer>
      ),
    },
    {
      id: 'lessons',
      label: `${t('general.lesson')}`,
      render: (item: any) => (
        <CountContainer>{item.numberOfLessons}</CountContainer>
      ),
    },
    {
      id: 'price',
      label: `${t('general.duration')}`,
      render: (item: any) => formatDuration(item.duration),
    },
  ]

  return grid
}

export const moduleActionsConfig = (
  handleCreateModuleClick?: any,
  handleAddModuleClick?: any,
  t?: any,
) => {
  let action = [
    {
      render: (item: any) => (
        <Action
          onClick={() =>
            handleCreateModuleClick(item.id, item.name, item.companyId)
          }
        >
          <AddCircleRoundedIcon />
          <span>{t('modules_layout.create_module')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action
          onClick={() =>
            handleAddModuleClick(item.id, item.name, item.companyId)
          }
        >
          <PlaylistAddIcon />
          <span>{t('course_details.add_module_to_the_course')}</span>
        </Action>
      ),
    },
  ]

  return action
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

const CountContainer = styled.div`
  width: 36px;
  height: 36px;
  background: #080a0a0a;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #303131;
`
