import deleteCompanyService from 'services/companies/deleteCompanyService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useDeleteCompanyService = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const { deleteCompanyMutation, loading, error } = deleteCompanyService(params)

  const deleteCompany = async (ids: string[], onSuccess: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    const { data, errors } = await deleteCompanyMutation({
      variables: { ids },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      onSuccess()
      message = `${t('general.company')} ${t('messages.deleted')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteCompany,
    deleteCompanyLoading: loading,
    error,
  }
}

export default useDeleteCompanyService
