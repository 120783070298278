import { FC } from "react";
import * as React from "react";
import { useTranslation } from 'react-i18next'

import './CustomLegend.scss'

interface CustomLegendProps {

}

const CustomLegend: FC<CustomLegendProps> = () => {
  const { t } = useTranslation()

  return (
    <div className="legend-holder">
      <div className="legend-bl">
        <div></div> <span>{t('general.activeStudents')}</span>
      </div>
      <div className="legend-or">
        <div></div> <span>{t('general.newStudents')}</span>
      </div>
      <div className="legend-gr">
        <div></div> <span>{t('general.passiveStudents')}</span>
      </div>
    </div>
  );
}

export default CustomLegend;