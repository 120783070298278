import React, { useState, useEffect } from 'react'
import { useFetchCompany } from 'hooks/companies/useCompanies'
import { useUserValue } from 'context/UserContext'
import { SET_SELECTED_COMPANY_ID } from 'store/types'
import { RouterParams } from './types'
import { useTranslation } from 'react-i18next'

import CompanyInformation from './CompanyInformation'
import CompanyTabs from './CompanyTabs'
import CompanySuperAdminCompanyAdminTabs from './CompanyTabs/CompanySuperAdminCompanyAdminTabs'

const CompanyDetails = ({ match }: RouterParams) => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()

  const companyId = match.params.id
  const initialVariables = {
    filter: {
      companyId: { type: 'exact', value: '60f7ff068732327fc9415548' },
      isBought: { type: 'bool', value: 'false' },
    },
  }

  let initialVariabledForCompanyUser = {
    filter: { companyId: { type: 'exact', value: null } },
  }

  const { company, loading, refetch } = useFetchCompany(companyId)

  const [currentTab, setCurrentTab] = useState<number>(0)
  const [variables, setVariables] = useState<any>(null)
  const [variablesForCompanyUsers, setVariablesForCompanyUsers] = useState<any>(
    null,
  )

  const [coachIds, setCoachIds] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)



  useEffect(() => {
    if (company && company.id && !state.selectedCompanyId) {
      dispatch({
        type: SET_SELECTED_COMPANY_ID,
        payload: companyId,
      })
    }
  }, [company])

  useEffect(() => {
    return () => {
      if (!state.currentUser.companyId) {
        dispatch({
          type: SET_SELECTED_COMPANY_ID,
          payload: null,
        })
      }
    }
  }, [])

  // useEffect(() => {
  //   if (company && company.platformService) {
  //     setCurrentTab(1)
  //   }

  // }, [company])

  const handleTabChange = (newValue: number) => {
    setCurrentTab(newValue)
    setCurrentPage(1)
    // if (newValue === 0) {
    //   setVariables({
    //     ...initialVariables,
    //   })
    //   setVariablesForCompanyUsers({
    //     filter: {
    //       companyId: {
    //         type: 'exact',
    //         value: null,
    //       },
    //     },
    //   })
    // }

    if (newValue === 0) {
      setVariables({
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
          isBought: {
            type: 'bool',
            value: 'false',
          },
        },
      })
      setVariablesForCompanyUsers({
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
        role: 'COACH',
      })
    }

    if (newValue === 1) {
      setVariables({
        role: 'COACH',
        filter: {
          _id: {
            type: 'arrayIn',
            value: coachIds,
          },
          companyId: {
            type: 'exact',
            value: null,
          },
        },
      })
      setVariablesForCompanyUsers({
        // აბრუნებს კომპანიის შექმნილ და ვენროლისგან ნაყიდ კურსებს
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
      })
    }

    if (newValue === 2) {
      // 2
      setVariables({
        role: 'SUPER_ADMIN',
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
      })
      setVariablesForCompanyUsers({
        filter: {
          company: {
            type: 'exact',
            value: companyId,
          },
        },
      })
    }

    if (newValue === 3) {
      // 3
      setVariables({
        filter: {
          company: {
            type: 'exact',
            value: companyId,
          },
        },
      })
      setVariablesForCompanyUsers({
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
        role: 'ADMIN',
      })
    }

    if (newValue === 4) {
      // 4
      setVariables({
        role: 'STUDENT',
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
      })
      setVariablesForCompanyUsers({
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
        role: 'SUPER_ADMIN',
      })
    }

    if (newValue === 5) {
      // 5
      setVariables({
        role: 'ADMIN',
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
      })
    }

    if (newValue === 1) {
      // 1
      setVariables({
        role: 'COACH',
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
      })
      setVariablesForCompanyUsers({
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
        role: 'STUDENT',
      })
    }

    // if (newValue === 1) { // კომპანიის კურსები
    //   setVariables({
    //     role: 'COACH',
    //     filter: {
    //       _id: {
    //         type: 'arrayIn',
    //         value: coachIds,
    //       },
    //       companyId: {
    //         type: 'exact',
    //         value: null,
    //       },
    //     },
    //   })
    //   setVariablesForCompanyUsers({
    //     // აბრუნებს კომპანიის შექმნილ და ვენროლისგან ნაყიდ კურსებს
    //     filter: {
    //       companyId: {
    //         type: 'exact',
    //         value: companyId,
    //       },
    //     },
    //   })
    // }
  }

  React.useEffect(() => {
    handleTabChange(0)
  }, [])

  const addCoachIds = (ids: string[]) => setCoachIds([...ids, ...coachIds])

  const isCompanySuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !!state?.currentUser?.companyId?.id

  const isCompanyAdmin =
    state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  if (loading || (!variables && !variablesForCompanyUsers))
    return <div>Loading...</div>

  return (
    <div>
      <CompanyInformation company={company} t={t} refetch={refetch} />

      {isCompanySuperAdmin || isCompanyAdmin ? (
        <CompanySuperAdminCompanyAdminTabs
          currentTab={currentTab}
          handleTabChange={handleTabChange}
          variables={variablesForCompanyUsers}
          addCoachIds={addCoachIds}
          defCurrentPage={currentPage}
          company={company}
          t={t}
        />
      ) : (
        <CompanyTabs
          currentTab={currentTab}
          handleTabChange={handleTabChange}
          variables={variables}
          addCoachIds={addCoachIds}
          defCurrentPage={currentPage}
          company={company}
          t={t}
        />
      )}
    </div>
  )
}
export default CompanyDetails
