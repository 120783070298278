import styled from 'styled-components'

export const Container = styled.div``

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background: #317bf4;
  padding: 16px 24px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  margin: auto;

  svg {
    fill: #ffffff;
  }

  &:hover {
    transition: 0.3s;
    background: #3165f4;
  }
`

export const Icon = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ffffff;
`

export const ItemContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`

export const Item = styled.div<{ active: boolean }>`
  color: #303131;
  font-size: 16px;
  padding: 6px;
  max-width: 230px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: #f2f2f2;
    color: #317bf4;
  }


`
