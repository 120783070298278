import { useParams, useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Rating } from '@material-ui/lab'
import { GET_REVIEWS } from 'gql/review/review.query'
import Drawer from "components/common/Drawer";
import Modal from "components/common/Modal";
import React, { FC, useEffect, useState } from 'react'
import CourseReviewRatings from './CourseReviewRatings'
import { GET_PROGRESS_BY_USER_ID } from 'gql/progress/progress.query'

import { Button } from 'components/common/Button'
import Icon from 'components/Icon'
import Review from './Components/Review';
import { useUserValue } from 'context/UserContext'

import { GET_COURSE } from 'gql/course/course.query';
import { GetCourseById } from 'pages/courses/StudentCourse/types';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'


interface Props {

}

const CourseReview: FC<Props> = () => {
  const [state] = useUserValue()
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [currentUserCoursesIds, setCurrentUserCoursesIds] = useState([''])
  const [demand, setDemand] = useState(false)
  const [course, setCourse] = React.useState<GetCourseById | null>(null)
  const params: any = useParams()


  const { data: progress, } = useQuery(GET_PROGRESS_BY_USER_ID, {
    variables: {
      userId: state.currentUser.id,
    }
  });





  useEffect(() => {
    const filteredCourse = progress?.getProgressByUserId?.courses.filter((item: any) => {
      return item.courseId === params.id
    })

    if (filteredCourse?.length > 0 && filteredCourse[0].askReview) {
      setDemand(true)
      setIsModalOpened(true)
    } else {
      setDemand(false)
      setIsModalOpened(false)
    }

  }, [progress])

  let history = useHistory()


  // useEffect(() => {
  //   demand && setIsModalOpened(true)
  // }, [demand, progress])

  const { data, refetch } = useQuery(GET_REVIEWS, {
    variables: {
      ratedId: params.id,
    },
  })


  useQuery<{ getCourseById: GetCourseById }>(GET_COURSE, {
    variables: { courseId: params.id },
    onCompleted: data => setCourse(data.getCourseById),
  })


  // console.log('reviewsdata', data);

  // const [addReview] = useMutation(ADD_REVIEW)
  // const [addReviewFunc, { data: reviews }] = useMutation(ADD_COURSE_REVIEW);


  const handleModalClose = () => {
    setIsModalOpened(false);
    // history.goBack()
  }

  useEffect(() => {
    setCurrentUserCoursesIds(state?.currentUser?.courses?.map((item: any) => item.courseId))
  }, [state?.currentUser?.courses])

  const { t } = useTranslation();

  return (
    <>
      <Content>
        <ComponentWrapper>
          <Header>
            <Title>{data?.getReviews?.courseName}</Title>
            <TopRating>
              <Title style={{ marginRight: '5px' }}>{data?.getReviews?.averageRating || '0.0'}</Title>
              <Rating value={data?.getReviews?.averageRating || 0} disabled />
              <p style={{ marginLeft: '30px', color: '#080A0A36', fontSize: '14px', display: 'flex', alignItems: 'center' }}>{data?.getReviews?.count} {t('general.review_')} {'>'}</p>
            </TopRating>
          </Header>
          <Wrapper>
            <SmallerTitle>
              <h2 style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: course?.progress === 100 ? '200px' : '0px' }}>
                {t('general.reviews')}
              </h2>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {state.currentUser.role === 'STUDENT' && course?.completed && !data?.getReviews?.isRatedByMe ? (
                  <Button
                    buttonStyles={{ padding: '20px 40px 20px 40px', borderRadius: '16px' }}
                    text={t('general.write_review')}
                    icon={<Icon filename={'edit'} fill="#fff" />}
                    onClick={() => setIsModalOpened(true)}
                  />
                ) : null}
              </div>
            </SmallerTitle>
            {data?.getReviews.data.map((item: any) => {
              return (
                <CourseReviewRatings
                  Appraiser={`${item?.author?.firstName || ''} ${item?.author?.lastName || ''}`}
                  ProfileUrl={item?.author?.userId}
                  ProfilePicture={item?.author?.avatar}
                  Description={item?.description}
                  RatingNum={item?.rate}
                  isReviewedByMe={state.currentUser?.id === item?.author?.userId}
                  reviewId={item?.id}
                  refetch={refetch}
                />
              )
            })}
          </Wrapper>
        </ComponentWrapper>
      </Content>
      <Modal isOpened={isModalOpened} onClose={handleModalClose}>
        <Drawer
          opened={isModalOpened}
          toggleDrawer={handleModalClose}
          totalWidth="970px">
          <Review
            refetch={refetch}
            isRatedByMe={data?.getReviews?.isRatedByMe}
            model='course'
            drawerTitle={t('general.course_review')}
            onModalClose={handleModalClose}
            courseName={data?.getReviews?.courseName}
            ratedId={params?.id}
            demand={demand}
            setDemand={setDemand}

          />
        </Drawer>
      </Modal>
    </>
  )
}

export default CourseReview


const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ComponentWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
`

const TopRating = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SmallerTitle = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 100px; */
  margin-bottom: 20px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 50px 0px 120px 0px;

  .secondary-title {
    color: #080a0a8a;
    font-size: 16px;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
