import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/react-hooks'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { TOOGLE_COMPANY_STATUS } from 'gql/companies.query'

const useDeactivateActivateCompany = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [changeCompanyStatusMutation, { loading, error, data }] = useMutation(
    TOOGLE_COMPANY_STATUS,
  )

  const companyActivationDeactivationToogle = async (companyId: string) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    const { errors } = await changeCompanyStatusMutation({
      variables: { companyId },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = t('messages.status_changed_successfuly')
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    companyActivationDeactivationToogle,
    changeCompanyStatusLoading: loading,
    error,
    data,
  }
}

export default useDeactivateActivateCompany
