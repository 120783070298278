import React, { useEffect, useState } from 'react'
import { CompaniesWizardPage1Schema } from '../../../helpers/validationSchemas'
import { useFormik } from 'formik'
import { SET_FORMDATA } from 'store/types'
import { useData } from 'context/DataContext'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'

import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import {
  CloseIcon,
  DrawerHeader,
  DrawerTitle,
  Info,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'

import Loader from 'components/common/Loader'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { Button } from 'components/common/Button'
import { useFetchCompany } from 'hooks/companies/useCompanies'
import useCompaniesService from 'hooks/useCompaniesService'
import useResendUserService from 'hooks/users/useUpdateUserPasswordService'
import GeneralInformation from './GeneralInformation'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import styled from 'styled-components'
import CompanyServiceToggle from '../CompanyDetails/CompanyServiceToggle'
import { useTheme } from '@material-ui/core'

interface Props {
  companyId: string
  refetchCompany: any
}

const formData: any = {
  name: '',
  companyEmail: '',
  keyContactName: '',
  address: '',
  phone: '',
  companyActivity: null,
  country: null,
  city: '',
  note: '',
  platformService: false,
  numberOfUsers: '',
  phoneFields: null,
  phoneFieldValue: null,
}

const CompaniesEdit = ({ companyId, refetchCompany }: Props) => {
  const { t } = useTranslation()
  const [dataState, dispatchData] = useData()
  const [state, dispatch] = useUserValue()
  const [currentCompany, setCurrentCompany] = useState<any>(null)
  const { updateCompany, error, company } = useCompaniesService()
  const [fetched, setFetched] = useState(false)
  const [generalError, setGeneralError] = useState('')
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: CompaniesWizardPage1Schema,
    onSubmit(values) {
      const data = { ...values }

      // const managersWithoutId = managers.map((manager: any) => {
      //   const { id, __typename, ...managerProps } = manager
      //   return managerProps
      // })
      delete data.phoneFieldValue

      if (data.companyActivity) {
        data.companyActivity = [
          {
            label: data.companyActivity.label,
          },
        ]
      } else {
        data.companyActivity = []
      }

      if (data.phoneFields && data.phoneFields.value) {
        data.phoneFields = {
          dialCode: data.phoneFields.value,
          code: data.phoneFields.label,
        }
      } else {
        data.phoneFields = null //it's optional
      }

      // if (data.numberOfUsers)
      //   data.numberOfUsers = data.numberOfUsers.value

      if (data.country) data.country = data.country.value

      // data.managers = managersWithoutId || []

      delete data.managers

      data.platformService = companyData.platformService

      updateCompany(companyId, data, refetchCompany.refetchCompany)
    },
  })
  const { company: companyData } = useFetchCompany(companyId)

  const { updateUserPassword } = useResendUserService({
    role: 'SUPER_ADMIN',
    companyId,
  })

  const getCompany = async () => {
    const companyValues = {
      name: companyData.name,
      companyEmail: companyData.companyEmail,
      address: companyData.address,
      phone: companyData.phone,
      companyActivity:
        companyData.companyActivity && companyData.companyActivity.length > 0
          ? {
            value: companyData.companyActivity[0].label,
            label: companyData.companyActivity[0].label,
          }
          : null,
      note: companyData.note,
      country: companyData.country
        ? {
          value: companyData.country,
          label: companyData.country,
        }
        : null,
      platformService: companyData.platformService,
      city: companyData.city,
      keyContactName: companyData.keyContactName,
      postalCode: companyData.postalCode,
      numberOfUsers: companyData.numberOfUsers,
      phoneFields:
        companyData.phoneFields && companyData.phoneFields.code
          ? {
            label: companyData.phoneFields.code,
            value: companyData.phoneFields.dialCode,
          }
          : null,
      phoneFieldValue: companyData.phoneFields
        ? companyData.phoneFields.code
        : null,
    }
    setValues(companyValues)
    setCurrentCompany(companyValues)
    setFetched(true)
  }

  const handleCloseDrawer = () => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'companiesEdit',
        values,
        compareTo: currentCompany,
      },
    })
    // const { text } = state.closeDrawer
    // if (!text) {
    //   closeDrawer()
    // } else {
    //   fireSwal({
    //     title: t('popups.close_popup'),
    //     text: t('popups.sure'),
    //     confirmText: t('popups.confirm_cancel'),
    //     cancelText: t('general.no'),
    //     onConfirm: () => {
    //       closeDrawer()
    //       dispatch({
    //         type: TOGGLE_CLOSE_DRAWER,
    //         payload: {
    //           text: '',
    //           confirm: false,
    //         },
    //       })
    //     },
    //   })
    // }
  }
  const handleValueChange = (e: any) => {
    const { confirm } = state.closeDrawer
    if (!confirm) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
    }
    handleChange(e)
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (company) {
      closeDrawer()
    }
  }, [company])

  useEffect(() => {
    if (error) setGeneralError(error)
  }, [error])

  useEffect(() => {
    if (!fetched && companyData) {
      getCompany()
    }
  }, [companyData])

  const closeDrawer = () => {
    DrawerEventEmitter.emit('openDrawer', 'companiesEdit', false)
  }

  const isSuperAdmin = state.currentUser.role === 'SUPER_ADMIN'
  const isAdmin = state.currentUser.role === 'ADMIN'

  const theme = useTheme()

  return (
    <>
      {!fetched && <Loader withBackground={true} />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{t('companies_layout.edit_company')}</DrawerTitle>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      {/* <TabNavigation style={{ flexGrow: 'unset' }}>
        <AppBar position="static" style={styles.bar}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            <Tab
              label="Company Details"
              {...activeTabClassName(0)}
              style={styles.btn}
            />
            <Tab
              label="Purchased Courses"
              {...activeTabClassName(1)}
              style={styles.btn}
            />
          </Tabs>
        </AppBar>
      </TabNavigation> */}
      <FormContainer onSubmit={(e: any) => e.preventDefault()}>
        <FormGroupWrapper>
          <GeneralInformation
            values={values}
            errors={errors}
            generalError={generalError}
            touched={touched}
            handleChange={handleValueChange}
            setFieldValue={setFieldValue}
            t={t}
          />
          {/* <TabPanel value={value} index={0}>
            <GeneralInformation
              values={values}
              errors={errors}
              generalError={generalError}
              touched={touched}
              handleChange={handleValueChange}
              setFieldValue={setFieldValue}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CoursesTab
              setSelectedCourses={() => {}}
              selectedCourses={[]}
              companyId={(companyData && companyData.id) || ''}
            />
          </TabPanel> */}
          {(isSuperAdmin || isAdmin) && (
            <SettingsContainer>
              <SettingsTitle>
                {t('companies_layout.platform_service')}
              </SettingsTitle>

              <CompanyServiceToggle
                id={companyId}
                platformService={companyData?.platformService}
                name={values?.name}
              />
            </SettingsContainer>
          )}
        </FormGroupWrapper>
        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.cancel')}
              type="medium"
              onClick={handleCloseDrawer}
              background="#F5F5F5"
              textColor={validationRed}
              outline={`1px solid ${red}`}
            />
            <Button
              text={t('actions.save')}
              type="medium"
              color="secondary"
              background={'#317BF4'}
              onClick={handleSubmit}
            ></Button>
          </FormButtons>
        </FormFooter>
      </FormContainer>
    </>
  )
}

export default CompaniesEdit

const SettingsContainer = styled.div`
  font-size: 12px;
  padding-top: 8px;
  padding-left: 20px;

  display: flex;
  align-items: center;
`

const SettingsTitle = styled.div`
  font-size: 14px;
`
