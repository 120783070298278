import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { Skeleton } from '@material-ui/lab'
import { GET_COURSE } from 'gql/course/course.query'
import AboutCourse from 'pages/courses/StudentCourse/AboutCourse'
import { useUserValue } from 'context/UserContext'
import Header from 'pages/courses/StudentCourse/Header'
import { GetCourseById } from 'pages/courses/StudentCourse/types'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFetchCommonCourses } from 'hooks/users/useCommonCourses'

import styled from 'styled-components'
import { GET_PORTFOLIO } from 'gql/users.query'

export type StudentStatisticsProps = {}

const StudentStatistics: React.FC<RouteComponentProps<{
  id: string
}>> = props => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const { userId, id } = useParams<{ userId: string, id: string }>()
  const [course, setCourse] = React.useState<GetCourseById | null>(null)
  const [portfolio, setPortfolio] = React.useState<unknown | null>(null)

  const [statCourse, setStatCourse] = useState([])
  useQuery<{ getCourseById: GetCourseById }>(GET_COURSE, {
    variables: { courseId: props.match.params.id },
    onCompleted: data => setCourse(data.getCourseById),
  })

  const [fetchPortfolio] = useLazyQuery(GET_PORTFOLIO, {
    onCompleted: data => setPortfolio(data.getPortfolio),
  })

  React.useEffect(() => {
    if (course?.id && state?.currentUser?.id) {
      fetchPortfolio({
        variables: {
          id:
            course?.isBought && course?.originalId
              ? course.originalId
              : course?.id,
          userId,
        },
      })
    }
  }, [course?.id, state?.currentUser?.id])
  let isDone = true
  const { courses: doneCourse } = useFetchCommonCourses(userId, 1, 3, isDone)
  isDone = false
  const { courses: notDoneCourse } = useFetchCommonCourses(userId, 1, 3, isDone)


  useEffect(() => {

    const filteredDoneCourse = doneCourse?.filter((course: any) => {
      if (course.id == id) {
        return course
      }
    })
    const fileteredNotDoneCourse = notDoneCourse?.filter((course: any) => {
      if (course.id == id) {
        return course
      }
    })

    doneCourse?.length == 1 ? setStatCourse(filteredDoneCourse[0]) : fileteredNotDoneCourse?.length == 1 && setStatCourse(fileteredNotDoneCourse[0])
  }, [doneCourse, notDoneCourse])

  // useEffect(() => {
  //   notDoneCourse.length > 0 && setStatCourse(notDoneCourse)
  // }, [notDoneCourse])



  if (!course?.id) return <Skeleton />
  return (
    <Container>
      <Header course={course} isStatistics statcourse={statCourse} />
      <Content>
        <h1 className="title">{t('user_tabs.statistics')}</h1>
        <AboutCourse userStatistics portfolio={portfolio} course={course} />
      </Content>
    </Container>
  )
}

export default StudentStatistics

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  margin: auto;
  margin-top: 60px;
  width: 100%;
  max-width: 800px;

  .title {
    font-size: 24px;
    color: #080a0ad6;
    font-weight: 600;
    margin-bottom: 40px;
  }
`
