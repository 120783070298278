import React from 'react'
import styled from 'styled-components'
import { GlobalUserDefaultImage } from 'utils/utils'

interface ImageBoxParams {
  children: React.ReactElement
  width?: number
  height?: number
  borderRadius?: number
}

export const Row = (props: any) => (
  <FlexRow {...props}>{props.children}</FlexRow>
)

export const Column = (props: any) => (
  <FlexColumn {...props}>{props.children}</FlexColumn>
)

export const ImageBox = ({
  children,
  width,
  height,
  borderRadius,
}: ImageBoxParams) => (
  <Image width={width} height={height} borderRadius={borderRadius}>
    {children}
  </Image>
)

export const FlexRow = styled.div<{ direction: string }>`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
`
export const FlexColumn = styled.div<{ flex: number; centered: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => (flex ? flex : 1)};
  text-align: ${({ centered }) => (centered ? 'center' : 'inherit')};
  padding-left: 16px;
`

export const Image = styled.figure<{
  width: number | undefined
  height: number | undefined
  borderRadius: number | undefined
}>`
  overflow: hidden;
  width: ${({ width }) => (width ? width + 'px' : '100%')};
  height: ${({ height }) => (height ? height + 'px' : '100%')};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius + 'px' : ''};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const DetailsContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const DetailsContentLeft = styled.div`
  flex: 2;
`

export const DetailsContentRight = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 30px;

  &.noVideo {
    position: relative;
    right: 20px;
  }

  &.textRight {
    text-align: right;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  > div:not(:last-child) {
    margin-right: 10px;
  }
`

export const DetailsContentHeader = styled.header`
  display: flex;
`

export const DetailsContentHeaderSection = styled.section`
  font-size: 16px;
  width: 100%;
  article {
  }
`

export const DetailsTitle = styled.h1`
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 600;
  color: #080a0ad6;

  div > div:not(:last-child) {
    margin-right: 10px;
  }
`
export const DetailsContentDescription = styled.article`
  position: relative;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
`

export const DetailsContentSkillsWrapper = styled.div`
  padding: 30px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
`

export const DetailsContentSkilsTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
`

export const DetailsContentSkilsList = styled.ul`
  display: flex;
  padding-left: 0;
  margin-top: 5px;
`
export const DetailsContentSkillItem = styled.li`
  padding: 8px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 8px;
  }
`
export const DetailsContentReview = styled.div`
  padding: 20px 0 0 20px;
  display: flex;
  align-items: center;
`
export const DetailsContentReviewList = styled.div`
  font-size: 16px;
  color: #080a0a8a;

  a:hover {
    text-decoration: underline;
  }
`

export const FakeUserImage = styled.div`
  width: 48px;
  height: 48px;
  border: 1px solid #080a0a0a;
  border-radius: 16px;
  background: #2f4f4f1f;
  background-image: ${`url(${GlobalUserDefaultImage})`};
  background-size: cover;
`

export const DetailsContentCoachWrapper = styled.div`
  padding: 0 20px;
`

export const DetailsContentCoachesWrapper = styled.div`
  padding: 38px 30px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
`

export const DetailsContentCoachInfo = styled.section`
  font-size: 12px;
`

export const DetailsContentCoachTitle = styled.h4`
  font-size: 24px;
  font-weight: 600;
`

export const DetailsContentCoachFullName = styled.h4`
  font-size: 16px;
  color: #080a0ad6;
  font-weight: 600;
`

export const CertificateContainer = styled.div`
  margin-top: 15px;
  border-radius: 5px;
  /* background: #fff;
  border: 1px solid #ccc; */
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`

export const CertificateContent = styled.div`
  margin-top: 10px;
`

export const DetailsContentVideoCover = styled.figure<{ noBefore?: boolean }>`
  position: relative;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ noBefore }) => (noBefore ? '100%' : 'unset')};
  height: ${({ noBefore }) => (noBefore ? '250px' : 'unset')};
  background: ${({ noBefore }) =>
    noBefore ? 'rgba(8, 82, 143, 0.8);' : 'none'};
  z-index: ${({ noBefore }) => (noBefore ? -1 : 1)};

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    display: ${({ noBefore }) => (noBefore ? 'none' : 'block')};
  }

  img {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }

  .MuiCircularProgress-colorPrimary {
    color: #dbdbdb;
  }
`

export const VideoProcessingWrapper = styled.div`
  text-align: center;
  color: #eee;
  font-size: 16px;

  article {
    margin-top: 5px;
  }
`

export const DetailsContentPlayIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    fill: #fff;
    font-size: 3rem;
    transition: fill 0.2s ease;
  }

  &:hover svg {
    fill: #ff3a52;
  }
`
export const DetailsListContainer = styled.div`
  margin-top: 30px;
`

export const DetailsListHeader = styled.div`
  padding-bottom: 6px;
  border-bottom: 1px solid #ccc;
`
export const DetailsList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  padding-left: 0;
`

export const DetailsListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  strong {
    margin-right: 5px;
  }
  svg {
    color: red;
  }
`
export const GroupsWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const GroupsItem = styled.div`
  display: inline-block;
  padding: 0 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: #333;

  &:not(:last-child) {
    margin-right: 5px;
  }
`
