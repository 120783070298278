import React, { useState } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { useSwal } from 'hooks/useSwal'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'

import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import { GET_MODULES } from 'gql/modules.query'
import IconButton from '@material-ui/core/IconButton'
import SyncIcon from '@material-ui/icons/Sync'
import FilterListIcon from '@material-ui/icons/FilterList'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ViewListIcon from '@material-ui/icons/ViewList'
import { GridSwitchButtons } from 'components/common/Cards/styled-components'
import {
  DrawerHeader,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'

import Drawer from 'components/common/Drawer'
import FilterDrawer from 'pages/modules/FilterDrawer/FilterDrawer'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import { CardsLayout } from 'components/common/Cards'
import Modal from 'components/common/Modal'
import VideoPlayer from 'components/common/VideoPlayer'
import { useTheme } from '@material-ui/core'

interface Props {
  title: string
  closeDrawer: () => void
  selectedModules: any
  setSelectedModules: any
}

const filterOption: any = {
  name: '',
  // duration: '',
  // courses: [],
  coverImage: { label: 'All', id: 0, value: 'all' },
  video: { label: 'All', id: 0, value: 'all' },
  attachment: { label: 'All', id: 0, value: 'all' },
  range: [0, 5000],
}

const AddModuleToQuestionDrawer = ({
  title,
  closeDrawer,
  selectedModules,
  setSelectedModules,
}: Props) => {
  const { t } = useTranslation()
  const { data: modulesData, loading, refetch } = useQuery(GET_MODULES)

  const { fireSwal } = useSwal()
  const [state, dispatch] = useUserValue()
  const [filterData, setFilterData] = useState<any>(filterOption)
  const [filtered, setFiltered] = useState<boolean>(false)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [gridStyle, setGridStyle] = useState<string>('card')
  const [videoModal, setVideoModal] = useState({ modal: false, data: '' })
  const [selectedItem, setSelectedItem] = useState<any>([])

  const selectItem = (id: string, event: boolean) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
    }

    if (newSelected.length > 0) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
    } else {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: '',
          confirm: false,
        },
      })
    }

    setSelectedItem(newSelected)
  }

  const handleCloseDrawer = () => {
    if (selectedItem.length === 0) {
      closeDrawer()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popup.sure'),
        confirmText: t('popup.confirm_cancel'),
        onConfirm: () => closeDrawer(),
      })
    }
  }

  const handleSubmit = () => {
    const moduleList = modulesData.allModules.reduce(
      (modules: any, item: any) => {
        if (selectedItem.includes(item.id)) {
          modules.push({
            moduleId: item.id,
            name: item.name,
          })
        }

        return modules
      },
      [],
    )

    setSelectedModules([...selectedModules, ...moduleList])
    closeDrawer()
  }

  const handleSearchChange = (e: any) => {
    setSearchValue(e.target.value)

    if (e.target.value === '') {
      refetch({
        filter: {
          name: {
            type: 'match',
            value: '',
          },
        },
      })
    }
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    refetch({
      filter: {
        name: {
          type: 'match',
          value: searchValue,
        },
      },
    })
  }

  const handleResetClick = () => {
    refetch({
      filter: {
        name: { type: 'match', value: '' },
      },
    })
    setFiltered(false)
    setSearchValue('')
  }
  const openPdf = (item: any) => {
    item.attachment && window.open(item.attachment, '_blank')
  }

  const handleVideoModal = (item: any) => {
    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? item.video[0].links[2].url : '',
    })
  }

  const selectedModuleIds = selectedModules.map(
    (module: any) => module.moduleId,
  )
  const theme = useTheme();

  return (
    <>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => setFilterDrawerOpened(false)}
        totalWidth="500px"
      >
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          refetchData={refetch}
          onClose={() => setFilterDrawerOpened(false)}
          setFiltered={setFiltered}
          type="nestedArrayNotIn"
        />
      </Drawer>
      <DrawerHeader theme={theme}>
        <FormTitle>{title}</FormTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <ModulesList>
          <FormFieldsWrapper>
            <Header
              style={{
                marginTop: 20,
                borderBottom: '1px solid #ccc',
                paddingBottom: 8,
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title>Module list</Title>
                {/* <SelectedWrapper
                  className={selectedItem.length === 0 ? 'disabled' : ''}
                >
                  <strong>{selectedItem.length}</strong> Selected item
                </SelectedWrapper>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        modulesData &&
                        modulesData.allModules.length === selectedItem.length
                          ? true
                          : false
                      }
                      disabled={
                        modulesData && modulesData.allModules.length === 0
                      }
                      onChange={selectAllItem}
                      style={{ marginLeft: 10 }}
                    />
                  }
                  label={
                    modulesData &&
                    modulesData.allModules.length === selectedItem.length
                      ? 'Diselect all'
                      : 'Select all'
                  }
                /> */}
              </div>
              <Toolbar>
                <div style={{ display: 'flex' }}>
                  <MultipleAction theme={theme}>
                    <IconButton
                      disabled={filtered || searchValue ? false : true}
                      onClick={handleResetClick}
                    >
                      <SyncIcon fontSize="small" />
                    </IconButton>
                    <MultipleActionTooltip>Reset</MultipleActionTooltip>
                  </MultipleAction>
                  <MultipleAction theme={theme}>
                    <IconButton onClick={() => setFilterDrawerOpened(true)}>
                      <FilterListIcon fontSize="small" />
                    </IconButton>
                    <MultipleActionTooltip>Filter</MultipleActionTooltip>
                  </MultipleAction>
                  <form
                    onSubmit={handleSearchSubmit}
                    style={{ marginRight: 10 }}
                  >
                    <TextInput
                      label="Search"
                      type="text"
                      size="small"
                      value={searchValue}
                      onChange={handleSearchChange}
                      icon={
                        <IconButton type="submit">
                          <SearchRoundedIcon />
                        </IconButton>
                      }
                    />
                  </form>
                  <GridSwitchButtons>
                    <button
                      className={`${gridStyle === 'card' ? 'active' : ''}`}
                      onClick={() => setGridStyle('card')}
                    >
                      <DashboardIcon />
                    </button>
                    <button
                      className={`${gridStyle === 'list' ? 'active' : ''}`}
                      onClick={() => setGridStyle('list')}
                    >
                      <ViewListIcon />
                    </button>
                  </GridSwitchButtons>
                </div>
              </Toolbar>
            </Header>
            {loading && <div>Loading...</div>}
            {modulesData && (
              <div style={{ padding: 10 }}>
                <CardsLayout
                  permissions={state.userPermission}
                  gridStyle={gridStyle}
                  data={modulesData.allModules}
                  noRecordsText="No Records found"
                  handleVideoModal={handleVideoModal}
                  selectItem={selectItem}
                  selectedModuleIds={selectedModuleIds}
                  selectedItem={selectedItem}
                  cols={2}
                />
              </div>
            )}
          </FormFieldsWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text="Cancel"
                type="small"
                onClick={handleCloseDrawer}
              ></Button>
              <Button
                text="Add"
                type="small"
                color="secondary"
                onClick={handleSubmit}
                isDisabled={selectedItem.length === 0}
              ></Button>
            </FormButtons>
          </FormFooter>
        </ModulesList>
      </DrawerContent>
      <Modal isOpened={videoModal.modal} onClose={handleVideoModal}>
        <VideoPlayer url={videoModal.data} />
      </Modal>
    </>
  )
}

export default AddModuleToQuestionDrawer

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  position: sticky;
  z-index: 9999;
  top: 0;
  background: #fff;
`

export const Title = styled.h2`
  font-size: 22px;
  font-weight: 500;
`

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SelectedWrapper = styled.div`
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  &.disabled {
    opacity: 0.5;
  }
`

const FormTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`

const FormFooter = styled.footer`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
`

const FormFieldsWrapper = styled.div`
  flex: 2;
  overflow-y: scroll;
`

const ModulesList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const FormButtons = styled.div`
  align-self: flex-end;
  display: flex;

  div:not(:last-child) {
    margin-right: 10px;
  }
`
