import React, { useEffect } from 'react'
import {
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
} from 'draft-js-buttons'
import { ToolbarControls } from '.././styled-components'

const HeadlinesPicker = props => {
  const onWindowClick = () => props.onOverrideContent(undefined)

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('click', onWindowClick)
    })

    return () => {
      window.removeEventListener('click', onWindowClick)
    }
  }, [])

  const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton]
  return (
    <ToolbarControls>
      {buttons.map((
        Button,
        i, // eslint-disable-next-line
      ) => (
        <Button key={i} {...props} />
      ))}
    </ToolbarControls>
  )
}

export default HeadlinesPicker
