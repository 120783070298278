import React, { useEffect, useRef, useState } from 'react'
import Hls from 'hls.js'
import {
  Player,
  ControlBar,
  PlaybackRateMenuButton,
  BigPlayButton,
} from 'video-react'

import HdIcon from '@material-ui/icons/Hd'
import HighQualityIcon from '@material-ui/icons/HighQuality'
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaption'
import 'video-react/dist/video-react.css'

import useOutsideClick from 'helpers/useOutsideClick'
import strCutter from 'utils/strCutter'
import {
  ButtonWrapper,
  CommentMenu,
  Container,
  QualityButtonWrapper,
  StyledQualityMenu,
  StyledQualityMenuItem,
  SubtitlesMenuContainer,
  CutomTextArea,
  SaveCommentButton
} from './styled-components'
import Icon from '../../Icon'
import PlayerDrawer from './PlayerDrawer/PlayerDrawer'
import { getUserId } from "helpers/localStorage"

const SubtitlesButton = ({ subtitles, setSubtitles, list }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef()

  useOutsideClick(ref, () => open && setOpen(false))

  const selectOptions = e => {
    setSubtitles(e)
  }

  return (
    <QualityButtonWrapper ref={ref}>
      <ButtonWrapper onClick={() => setOpen(!open)}>
        <ClosedCaptionIcon fontSize="small" />
      </ButtonWrapper>

      {open && (
        <SubtitlesMenuContainer>
          <StyledQualityMenuItem
            onClick={() => selectOptions('off')}
            color={subtitles === 'off'}
          >
            Off
          </StyledQualityMenuItem>

          <StyledQualityMenuItem
            onClick={() => selectOptions('on')}
            color={subtitles === 'on'}
          >
            {strCutter(list.name, 3)}
          </StyledQualityMenuItem>
        </SubtitlesMenuContainer>
      )}
    </QualityButtonWrapper>
  )
}

const QualityMenu = ({ setQuality, quality }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef()

  const selectOptions = i => {
    setQuality(i)
  }

  useOutsideClick(ref, () => open && setOpen(false))

  return (
    <QualityButtonWrapper ref={ref}>
      <ButtonWrapper onClick={() => setOpen(!open)}>
        {quality === 2 ? (
          <HighQualityIcon fontSize="small" />
        ) : (
          <HdIcon fontSize="small" />
        )}
      </ButtonWrapper>

      {open && (
        <StyledQualityMenu>
          {['480p', '720p', '1080p'].map((item, index) => (
            <StyledQualityMenuItem
              onClick={() => selectOptions(index)}
              color={quality === index}
            >
              {item}
            </StyledQualityMenuItem>
          ))}
        </StyledQualityMenu>
      )}
    </QualityButtonWrapper>
  )
}



const HlsVideoSrc = props => {
  const hlsRef = useRef()

  const { video, url, level, type } = props

  // console.lof(vide0, "video")
  useEffect(() => {
    if (hlsRef.current) {
      hlsRef.current.nextLevel = level
    }
  }, [level])

  useEffect(() => {
    if (Hls.isSupported()) {
      hlsRef.current = new Hls()
      hlsRef.current.loadSource(url)
      hlsRef.current.attachMedia(video)
      // hlsRef.current.loadLevel = 1
      hlsRef.current.on(Hls.Events.MANIFEST_PARSED, () => {
        // video.play();
      })
    }
    return () => {
      hlsRef.current.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return (
    <>
      <source src={url} type={type || 'application/x-mpegURL'} />
    </>
  )
}

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTime: 0,
      quality: 0,
      subtitles: 'off',
      commentDrawerState: false,
      isPause: false,
    }

  }

  componentDidMount() {
    this.player.subscribeToStateChange(this.handleStateChange.bind(this))

    if (this.props.disableTimeSave) return

    if (this.props.lessonProgress) {
      this.setState({ currentTime: +this.props.lessonProgress })
    }
    // this.setState({ quality: 0 })
  }

  componentWillUnmount() {
    if (this.state?.player?.currentTime <= this.state?.player?.duration && !this.props.disableTimeSave) {
      localStorage.setItem(
        `${getUserId() + this.props.url + "video"}`,
        this.state?.player?.currentTime,
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentTime !== prevProps.currentTime) {
      this.player.seek(this.props.currentTime)
      this.player.play()
    }
  }

  handleStateChange(state) {
    // copy player state to this component's state
    this.setState({
      player: state,
      currentTime: state.currentTime === 0 ? this.state.currentTime : state.currentTime,
    })
  }

  setSubtitles = arg => {
    this.setState({ subtitle: arg })
  }

  setQuality = arg => {

    this.setState({ quality: arg })
  }

  handleNextLessonSwitch = () => {
    // console.log("VIDEO HAS JUST ENDED");
    if (this.props.onNextLessonSwitch) {
      this.props.onNextLessonSwitch(Math.ceil(this.state.player.duration))
    }
  }


  render() {
    // console.log("THIS.STATE.CURRENTTIME", this.state.currentTime);

    const { lessonProgress } = this.props;
    let videoPlayerStartPoint;
    if (lessonProgress || lessonProgress === 0) {
      videoPlayerStartPoint = +this.props.lessonProgress
    } else {
      videoPlayerStartPoint = this.props.currentTime || 0;
    }

    // console.log("videoPlayerStartPoint", videoPlayerStartPoint);

    return (
      <Container>
        {this.state.commentDrawerState && <PlayerDrawer
          refetchGetUser={this.props.refetchGetUser}
          opened={this.state.commentDrawerState}
          toggleDrawer={() => {
            this.setState({ commentDrawerState: !this.state.commentDrawerState })
            // this.player.actions.play()
          }}
          onClose={() => {
            // this.player.actions.play()
            this.setState({ commentDrawerState: false })
          }}
          totalWidth={'900px'}
          time={this.state.currentTime}
          url={this.props.url}
          courseId={this.props.courseId}
          model={this.props.model}
          lesson={this.props.lesson}
        />}
        <Player
          ref={player => {
            this.player = player
          }}
          autoPlay={this.props.autoPlay}
          width={this.props.width ? this.props.width : 700}
          height={400}
          fluid={false}
          poster={this.props.thumbnail}
          // preLoad="metadata"
          preload="auto"
          crossOrigin="anonymous"
          startTime={videoPlayerStartPoint}
          onEnded={this.handleNextLessonSwitch}
        >
          <BigPlayButton position="center" />

          <HlsVideoSrc
            isVideoChild
            video={player => {
              this.player = player
            }}
            url={this.props.url}
            level={this.state.quality}
          />

          {/* <track
          label="English"
          kind="subtitles"
          srclang="en"
          src={'https://ib-assets-public.s3.eu-west-2.amazonaws.com/module/subtitle/1d2d455e-2da1-42f8-93d7-9c1d50acfb0a.vtt'}
          default
        /> */}
          {this.props.subtitle && this.state.subtitles === 'on' && (
            <track
              label="English"
              kind="subtitles"
              srclang="en"
              // src={gnida}
              src={this.props.subtitle.link}
              default
            />
          )}

          <ControlBar>
            <PlaybackRateMenuButton
              rates={[3, 2, 1.6, 1.2, 1, 0.5, 0.1]}
              order={7}
            />

            {this.props.subtitle && (
              <SubtitlesButton
                order={7}
                setSubtitles={this.setSubtitles}
                subtitles={this.state.subtitles}
                list={this.props.subtitle}
              />
            )}

            <CommentMenu order={7} title={'კომენტარის ჩანიშვნა'} onClick={() => {
              this.player.actions.pause()
              this.setState({ commentDrawerState: true })
            }}>
              <Icon filename='addComment' fill='#fff'></Icon>
            </CommentMenu>

            {/* <QualityMenu
              order={8}
              setQuality={this.setQuality}
              quality={this.state.quality}
            /> */}
          </ControlBar>

        </Player>
      </Container>
    )
  }
}

export default VideoPlayer
