import { gql } from 'graphql.macro'

export const GET_COMMON_COURSES = gql`
  query($userId: String, $currentPage: Int, $perPage: Int, $isDone: Boolean ) {
    getCommonCourses(userId: $userId, currentPage: $currentPage, perPage: $perPage, isDone: $isDone ) {
      currentPage
      totalPages
      courseCount
      data {
        id
        name
        progress
        completed
        description
        editorText
        level
        coursePrivacy
        coachType
        price
        currency
        originalId
        isBought
        certificate {
          name
          fileType
          link
        }
        avatar {
          name
          fileType
          link
        }
        state
        subtitle {
          name
          fileType
          link
        }
        skills {
          label
          id
        }
        coaches {
          firstName
          lastName
          email
          id
          userId
          avatar
        }
        groups {
          groupId
          name
        }
        numberOfGroups
        numberOfStudents
        numberOfModules
        numberOfLessons
        duration
        companyId {
          id
          name
        }
        finished
        categories {
          id
          name
        }
        contentLocked
    }
  }
}
`
