import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useTheme, useMediaQuery } from '@material-ui/core'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
  padding?: number
  width?: string,
  style?: any
}

export const TabPanel = (props: TabPanelProps) => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const { children, value, index, padding = isSmallScreen ? 3 : 0, width, ...other } = props
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: !!width ? width : '', ...props.style }}
    >
      {value === index && <Box p={padding}>{children}</Box>}
    </Typography>
  )
}

export const activeTabClassName = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const TabNavigation = (props: any) => {
  const theme = useTheme()

  return (
    <TabNavigationContainer theme={theme} isTransparent={props.transparent}>
      {props.children}
    </TabNavigationContainer>
  )
}

const TabNavigationContainer = styled.div<{ isTransparent: boolean }>`
  background: ${({ isTransparent, theme }) =>
    isTransparent ? 'transparent' : theme.palette.background.default};


    background: "blue";

  & > header {
    background-color: ${({ isTransparent }) =>
    isTransparent ? 'transparent !important' : 'inherit'}
`
