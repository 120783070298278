import React, { useState } from 'react'
import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import DashboardIcon from '@material-ui/icons/Dashboard'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import SyncIcon from '@material-ui/icons/Sync'
import ViewListIcon from '@material-ui/icons/ViewList'
import { GridSwitchButtons } from 'components/common/Cards/styled-components'
import Checkbox from 'components/common/Checkbox'
import Drawer from 'components/common/Drawer'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import {
  ActionButton,
  AddButton,
  GridLayoutActions,
  GridLayoutForm,
  GridToolbar,
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import TextInput from 'components/common/TextInput/TextInput'
import FilterDrawer from 'pages/questions/FilterDrawer'
import QuestionGrid from 'pages/questions/Grid'
import { mainColor } from 'components/common/Colors/CommonColors'
import Icon from 'components/Icon'
import Search from 'components/common/Search'
import { useTheme } from '@material-ui/core'

const Step2 = (props: any) => {
  const [gridStyle, setGridStyle] = useState<string>('card')
  const [searchValue, setSearchValue] = useState<string>('')
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<any>({})
  const [filtered, setFiltered] = useState<boolean>(false)
  const [filteredQuestionList, setFilteredQuestionList] = useState<any>(null)

  const selectItem = (id: never, event: boolean) => {
    const selectedIndex = props.selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selectedItem.slice(1))
      props.setSelectAll(false)
    } else if (selectedIndex === props.selectedItem.length - 1) {
      newSelected = newSelected.concat(props.selectedItem.slice(0, -1))
      props.setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selectedItem.slice(0, selectedIndex),
        props.selectedItem.slice(selectedIndex + 1),
      )
      props.setSelectAll(false)
    }

    props.setSelectedItem(newSelected)
  }

  const selectAllItem = () => {
    if (!props.selectAll) {
      const questionList = filteredQuestionList
        ? filteredQuestionList
        : props.questionList
      const newArr: string[] = questionList.map((n: any) => n.id)
      props.setSelectedItem(newArr)
      props.setSelectAll(true)
      return
    }
    props.setSelectedItem([])
    props.setSelectAll(false)
  }

  const handleResetClick = () => {
    setFilteredQuestionList(null)
    setFiltered(false)
    setFilterData({})
  }

  const handleSearchChange = (e: any) => {
    const { value } = e.target

    setSearchValue(value)
  }

  const questionList = filteredQuestionList
    ? filteredQuestionList
    : props.questionList
  const list = questionList.filter((item: any) =>
    item.question.toLowerCase().includes(searchValue),
  )

  const { t } = props

  const theme = useTheme();
  return (
    <div style={{ padding: '15px' }}>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => { }}
        totalWidth="600px"
      >
        <FilterDrawer
          courseId={props.type === 'course' ? props.id : null}
          closeDrawer={() => setFilterDrawerOpened(false)}
          filterData={filterData}
          setFilterData={setFilterData}
          quizModel={props.type}
          currentPage={1}
          currentModule={true}
          setFiltered={() => setFiltered(true)}
          questions={props.questionList}
          setFilteredQuestionList={setFilteredQuestionList}
          t={t}
        />
      </Drawer>
      {/* <Header className={'withBorder'}>
        <Wrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title>{t('questions_layout.title')}</Title>

            <GridMultipleActions
              selectedItems={props.selectedItem.length}
              actions={[]}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    list.length > 0 && list.length === props.selectedItem.length
                      ? true
                      : false
                  }
                  disabled={list.length === 0}
                  onChange={selectAllItem}
                />
              }
              label={
                list.length === props.selectedItem.length
                  ? t('general.diselect_all')
                  : t('general.select_all')
              }
            />
          </div>

          <div style={{ display: 'flex' }}>
            <MultipleAction>
              <IconButton
                disabled={filtered ? false : true}
                onClick={handleResetClick}
              >
                <SyncIcon fontSize="small" />
              </IconButton>
              <MultipleActionTooltip>
                {t('actions.reset')}
              </MultipleActionTooltip>
            </MultipleAction>
            <MultipleAction>
              <IconButton onClick={() => setFilterDrawerOpened(true)}>
                <FilterListIcon fontSize="small" style={{ color: mainColor }} />
              </IconButton>
              <MultipleActionTooltip>
                {t('actions.filter')}
              </MultipleActionTooltip>
            </MultipleAction>
            <div style={{ marginRight: '20px' }}>
              <TextInput
                label={t('general.search_placeholder')}
                type="text"
                size="small"
                value={searchValue}
                onChange={handleSearchChange}
                icon={
                  <IconButton type="submit">
                    <SearchRoundedIcon />
                  </IconButton>
                }
              />
            </div>

            <GridSwitchButtons>
              <button
                className={`${gridStyle === 'card' ? 'active' : ''}`}
                onClick={() => setGridStyle('card')}
              >
                <DashboardIcon />
              </button>
              <button
                className={`${gridStyle === 'list' ? 'active' : ''}`}
                onClick={() => setGridStyle('list')}
              >
                <ViewListIcon />
              </button>
            </GridSwitchButtons>
          </div>
        </Wrapper>
      </Header> */}
      <Header>
        <ActionsHeader>
          <GridLayoutForm>
            <ActionButton
              disabled={filtered ? false : true}
              onClick={handleResetClick}
              theme={theme}
            >
              <SyncIcon fontSize="small" />
            </ActionButton>
            <MultipleAction theme={theme}>
              <ActionButton theme={theme} onClick={() => setFilterDrawerOpened(true)}>
                <Icon filename={'filter'} fill={'#080A0AD6'} />
              </ActionButton>
              <MultipleActionTooltip>
                {t('actions.filters')}
              </MultipleActionTooltip>
            </MultipleAction>
            <Search
              inputProps={{
                value: searchValue,
                onChange: handleSearchChange,
              }}
              onClick={handleSearchChange}
            />
          </GridLayoutForm>
        </ActionsHeader>
        <GridToolbar style={{ borderBottom: 'none', padding: '60px 0px' }} theme={theme}>
          <GridLayoutActions>
            <Title>{t('questions_layout.title')}</Title>
            <div
              style={{
                marginLeft: '30px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      list.length > 0 &&
                        list.length === props.selectedItem.length
                        ? true
                        : false
                    }
                    disabled={list.length === 0}
                    onChange={selectAllItem}
                  />
                }
                label={
                  list.length === props.selectedItem.length
                    ? t('general.diselect_all')
                    : t('general.select_all')
                }
              />
            </div>
            {/* <FormControlLabel
            control={
              <Checkbox
                checked={
                  list.length > 0 && list.length === props.selectedItem.length
                    ? true
                    : false
                }
                disabled={list.length === 0}
                onChange={selectAllItem}
              />
            }
            label={t('general.select_all')}
          /> */}
          </GridLayoutActions>
          <GridLayoutForm>
            {`${props.selectedItem.length || 0} ${t('general.items_selected')}`}
            <ActionButton
              onClick={() => { }}
              disabled={(props.selectedItem.length || 0) === 0}
              fill={'#CC3131'}
              disabledFill={'#080A0AD6'}
              theme={theme}
            >
              <Icon filename={'delete'} />
            </ActionButton>
          </GridLayoutForm>
        </GridToolbar>
      </Header>

      <div style={{ margin: '0 60px' }}>
        <QuestionGrid
          gridStyle={gridStyle}
          cols={2}
          data={list}
          selectItem={selectItem}
          selectedItems={props.selectedItem}
          questionIds={props.filter}
          t={t}
          openInExternalTab={true}
        />
      </div>
    </div>
  )
}

export default Step2

const Header = styled.header`
  margin-bottom: 20px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    margin-right: 0;

    span {
      font-size: 14px;
    }
  }
`
const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
`

const ActionsHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
