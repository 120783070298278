import { useMutation } from "@apollo/react-hooks/lib/useMutation"
import { GET_COURSE, TRACK_PROGRESS } from "gql/course/course.query"
import { GET_MODULE } from "gql/modules.query"
import { GET_PROGRESS_BY_USER_ID } from "gql/progress/progress.query"

type TrackData = {
  moduleId?: string,
  courseId?: string
  userId?: string
}
export const useTrackCourse = ({ moduleId, courseId, userId }: TrackData = {}) => {

  const [trackProgress, { loading, data }] = useMutation(TRACK_PROGRESS, {
    refetchQueries: [{
      query: GET_MODULE, variables: {
        moduleId,
        courseId
      }
    }, {
      query: GET_PROGRESS_BY_USER_ID,
      variables: {
        userId
        //@ts-ignore
      }, onCompleted: (x) => {

      }
    }]
  })

  return {
    trackProgress,
    loading,
    trackData: data && data.trackProgress
  }
}
