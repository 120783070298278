import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { addRemoveCourseFromCompanyService } from 'services/companies/companySettingsService'
import { useTranslation } from 'react-i18next'

export const useAddRemoveCourseFromCompany = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const {
    addRemoveCourseFromCompanyMutation,
    loading,
  } = addRemoveCourseFromCompanyService()

  const addRemoveCourseFromCompany = async (
    companyId: string,
    coursesIds: string[],
    callBack: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await addRemoveCourseFromCompanyMutation({
      variables: {
        companyId,
        coursesIds,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = t('messages.success')
      variant = 'success'

      callBack()
    }

    setSnackbar({ message, variant })
  }

  return {
    addRemoveCourseFromCompany,
    loading,
  }
}
