import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'

import ImageCropper from 'components/common/ImageCropper'
import Modal from 'components/common/Modal'
import { useUploadImage } from 'hooks/helpers/useHelpersService'
import { useSwal } from 'hooks/useSwal'
import Loader from '../Loader'
import { mainColor } from '../Colors/CommonColors'
import ProfileIcon from './profile.svg'
import Icon from 'components/Icon'
import { GlobalUserDefaultImage } from 'utils/utils'

export const AvatarInput: React.FC<{
  setFieldValue: any
  initialAvatar: any
}> = ({ setFieldValue, initialAvatar }) => {
  const [avatar, setAvatar] = useState(null)
  const [cropperOpen, setCropperOpen] = useState(false)
  const [avatarMenu, openAvatarMenu] = useState(false)
  const { uploadImage, imageLoading } = useUploadImage()
  const { t } = useTranslation()
  const { fireSwal } = useSwal()

  const handleFileChange = (e: any) => {
    const { files } = e.target
    const reader: any = new FileReader()
    reader.onload = () => {
      const img: any = new Image()
      img.src = reader.result
      setAvatar(reader.result)
    }
    reader.readAsDataURL(files[0])

    setCropperOpen(true)
    openAvatarMenu(false)
  }

  const handleUploadAvatar = (field: string, croppedFile: string) => {
    uploadImage(croppedFile, `users/${field}`, (link: string) => {
      setFieldValue(field, link)
      setAvatar(null)
    })
  }

  const handleDeleteClick = () => {
    const params = {
      title: t('popups.remove_image'),
      onConfirm: () => {
        setFieldValue('avatar', '')
        setAvatar(null)
        openAvatarMenu(false)
      },
      confirmText: t('popups.confirm_delete'),
    }
    fireSwal(params)
  }

  return (
    <>
      <AvatarContainer>
        <div style={{ position: 'relative' }}>
          {(!!avatar || (!avatar && !!initialAvatar)) && (
            <DeleteIconContainer onClick={handleDeleteClick}>
              <CancelIcon style={{ fontSize: 18, color: '#989898' }} />
            </DeleteIconContainer>
          )}
          <img
            src={initialAvatar || GlobalUserDefaultImage}
            alt="User Profile Picture"
          />
          <label htmlFor="file">
            <EditIconContainer onClick={() => openAvatarMenu(true)}>
              <Icon filename={'edit'} />
            </EditIconContainer>
          </label>
        </div>
        {imageLoading ? (
          <Loader />
        ) : (
          <>
            {avatarMenu && (
              <AvatarMenuItem>
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={handleFileChange}
                  accept="image/*"
                  multiple
                />
              </AvatarMenuItem>
            )}
          </>
        )}
      </AvatarContainer>
      <Modal
        isOpened={cropperOpen}
        onClose={() => setCropperOpen(!cropperOpen)}
      >
        <ImageCropper
          file={{ file: avatar }}
          hideCropper={() => setCropperOpen(!cropperOpen)}
          getCroppedFile={(_: any, file: any) =>
            handleUploadAvatar('avatar', file)
          }
        />
      </Modal>
    </>
  )
}

export const AvatarContainer = styled.div`
  width: 100%;
  // outline: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .input-button {
    position: absolute;
    top: 10px;
    left: 9px;
    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      overflow: hidden;
    }
  }
  img {
    width: 156px;
    height: 156px;
    border: 1px solid #080a0a14;
    border-radius: 8px;
  }
`

const EditIconContainer = styled.div`
  width: 34px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  svg {
    width: 17px;
    height: 16px;
    fill: #080a0ad6;
  }
`

const DeleteIconContainer = styled.div`
  width: 28px;
  height: 28px;
  background: #f0f0f0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  position: absolute;
  top: 4px;
  right: 15px;
  cursor: pointer;
`

export const InputLabel = styled.label`
  cursor: pointer;
  color: #fff;
  background: ${mainColor};
  font-size: 11px;
  padding: 4px 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  span {
    margin-left: 7px;
  }
`

const AvatarMenuItem = styled.div`
  padding: 5px 3px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  label {
    cursor: pointer;
  }
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    overflow: hidden;
  }
`
