export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER'
export const SET_SELECTED_COMPANY_ID = 'SET_SELECTED_COMPANY_ID'
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY'
export const SET_FORMDATA = 'SET_FORMDATA'
export const CLEAR_FORMDATA = 'CLEAR_FORMDATA'
export const SET_CLOSE_DRAWER = 'SET_CLOSE_DRAWER'
export const TOGGLE_CLOSE_DRAWER = 'TOGGLE_CLOSE_DRAWER'
export const TOGGLE_DRAWER_CONFIRM = 'TOGGLE_DRAWER_CONFIRM'
export const SET_CURRENT_TOPIC = 'SET_CURRENT_TOPIC'
export const REMOVE_CURRENT_TOPIC = 'REMOVE_CURRENT_TOPIC'
export const CURRENT_USER_PERMISSION = 'CURRENT_USER_PERMISSION'
export const SET_CREATED_USER = 'SET_CREATED_USER'
export const SET_CURRENT_COURSE = 'SET_CURRENT_COURSE'
export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE'
