import { useTheme, useMediaQuery } from '@material-ui/core'
import React, { useState } from 'react'
import i18n from 'i18next'

import { changeLanguage } from 'helpers/changeLanguage'
import Icon from 'components/Icon'
import * as S from './styled'

const LANGUAGES = [
  { id: "l1", title: "ENG", code: "en" },
  { id: "l2", title: "GEO", code: "ka" },
];

const ChangeLanguage = (props: any) => {
  const [openOptions, setOpenOptions] = useState(false)
  const currentLanguage = React.useMemo(() => i18n.language === 'en' ? "ENG" : i18n.language === 'ka' ? 'GEO' : i18n.language, [i18n.language])
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <S.Container style={{ position: 'relative' }}>
      <S.Select
        onClick={() => setOpenOptions((prevState) => !prevState)}
        style={{ borderRadius: openOptions ? '25px 25px 0px 0px' : '25px', border: props.themeMode && '1px solid #fff' }}
      >
        <S.CurrentLanguage
          style={{ color: props.themeMode && '#fff', fontSize: isSmallScreen ? "16px" : "14px" }}
        >
          {currentLanguage}
        </S.CurrentLanguage>
        {
          openOptions ?
            <Icon
              width={10}
              height={5}
              filename={'arrow_down_bold'}
              fill={theme.palette.text.primary}
            />
            :
            <Icon
              style={{
                transition: '0.3s',
                transform: `rotate(${openOptions ? 0 : 180}deg)`,
              }}
              width={10}
              height={5}
              filename={'arrow_down_bold'}
              fill={theme.palette.text.primary}
            />
        }
      </S.Select>

      {
        openOptions ? <S.OptionsBox style={{ background: props.themeMode && '#000000B3' }}>
          {
            LANGUAGES.map((language) => (
              <S.LanguageOption
                key={language.id}
                style={{ borderColor: props.themeMode && '#fff' }}
                onClick={() => {
                  setOpenOptions(() => false)
                  changeLanguage(language.code)
                }}
              >
                <S.LanguageText style={{ color: props.themeMode && '#fff' }}>
                  {language.title}
                </S.LanguageText>
              </S.LanguageOption>
            ))
          }
        </S.OptionsBox> : null
      }
    </S.Container>
  )
}

export default ChangeLanguage
