import { Select } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
//   @media (max-width: 640px){
//    flex-direction row;
// }
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 36px;
  border-bottom: 1px solid #080a0a0a;
  flex-wrap: wrap;

`

export const CoachContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  @media  (max-width: 600px){
     margin-bottom: 20px;
  }
`

export const CoachItem = styled.div<{ image?: string }>`
  width: 48px;
  height: 48px;
  border: 1px solid #ffffff;
  border-radius: 16px;
  background: #080a0a0a;

  ${({ image }) =>
    image &&
    css`
      background-image: ${`url(${image})`};
      background-repeat: no-repeat;
      background-size: cover;
    `}
`

export const CoachName = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 10px;
`

export const ReviewNumber = styled.p`
  font-size: 24px;
  color: #080a0ad6;
  font-weight: 600;
`

export const RatingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CoachTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  span:first-child {
    font-size: 27px;
    // font-weight: 600;
    color: #080a0ad6;
    margin-bottom: -15px;
  }
  span {
    font-size: 14px;
    color: #080a0a8a;
  }
`

export const SSelect = styled(Select)`
  &.MuiInputBase-root {
    min-width: 158px;
    padding: 0px 24px;
    height: 47px;
    background: #080a0a0a;
    border-radius: 16px;

    &:before {
      display: none;
    }
  }
`

export const MyGroups = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 158px;
  padding: 0px 24px;
  height: 47px;
  background: #080a0a0a;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
`

export const GeneralInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
  border-bottom: 1px solid #080a0a0a;
`

export const GeneralItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #080a0a;
  font-size: 13px;
  font-weight: 600;
  svg {
    width: 20px;
    height: 20px;
    path {
      fill: #317bf4;
    }
  }
`

export const CardItem = styled.div`
  padding: 7px 10px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 13px;
  font-weight: 600;
  border: 1px solid #080a0a14;
  border-radius: 5px;
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  div {
    font-size: 16px;
    color: #080a0ad6;
    font-weight: 600;
  }

  span {
    font-size: 14px;
    color: #080a0ad6;
    // font-weight: 600;
  }
`
