import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getCurrentLanguage, getToken } from '../helpers/localStorage'
import en from "../locales/en/translation.json"
import ka from "../locales/ka/translation.json"
const currentLanguage = getCurrentLanguage()

let language = 'ka'

if (!!currentLanguage) {
  language = currentLanguage
}


export const resources = {
  ka: {
    translations: ka,
  },
  en: {
    translations: en,
  },
};


i18n
  .use(initReactI18next)
  .init({
    fallbackLng: ["ka", "en"],
    debug: false,
    defaultNS: "translations",
    lng: language,
    resources,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false
    }
  })

export default i18n


