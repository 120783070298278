import {} from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import deleteGroupService from 'services/group/deleteGroupService'

const useDeleteGroup = () => {
  const { t } = useTranslation()
  const { deleteGroup } = deleteGroupService()
  const { setSnackbar } = useSnackbarAlert()

  const deleteGroupServ = async (id: string[], refetch: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await deleteGroup({
      variables: { id },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
      return
    }

    message = `${id.length > 1 ? t('general.groups') : t('general.group')} ${t(
      'messages.successfully',
    )} ${t('general.deleted')}`
    variant = 'success'

    setSnackbar({ message, variant })
    refetch()
  }

  return {
    deleteGroupServ,
  }
}

export default useDeleteGroup
