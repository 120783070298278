import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import update from 'immutability-helper'

import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DeleteIcon from '@material-ui/icons/Delete'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import FilterListIcon from '@material-ui/icons/FilterList'
import HistoryIcon from '@material-ui/icons/History'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import ScheduleIcon from '@material-ui/icons/Schedule'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import SpellcheckIcon from '@material-ui/icons/Spellcheck'
import SyncIcon from '@material-ui/icons/Sync'
import ViewListIcon from '@material-ui/icons/ViewList'
import Breadcrumb from 'components/common/Breadcrumb'
import { Button } from 'components/common/Button'
import { GridSwitchButtons } from 'components/common/Cards/styled-components'
import {
  Column,
  DetailsList,
  DetailsListContainer,
  DetailsListItem,
  Row,
} from 'components/common/DetailsLayout'
import Drawer from 'components/common/Drawer'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import {
  ActionButton,
  AddButton,
  GridLayoutForm,
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import TextEditor from 'components/common/TextEditor/TextEditor'
import TextInput from 'components/common/TextInput/TextInput'
import {
  CourseContentDescription,
  CourseContentHeader,
  CourseContentHeaderSection,
  CourseContentLeft,
  CourseContentRight,
  CourseContentWrapper,
  CourseTitle,
  TextWrapper,
} from 'components/courseDetail/styled-component'
import { GET_QUESTIONS_BY_IDS } from 'gql/questions.query'
import { GET_QUIZ_BY_ID } from 'gql/quiz/quiz.query'
import { gql } from 'graphql.macro'
import { DrawerEventEmitter } from 'helpers/drawer'
import useDeleteQuizService from 'hooks/quizzes/useDeleteQuizService'
import {
  useAddRemoveQuestions,
  useEditQuizService,
} from 'hooks/quizzes/useEditQuizService'
import FilterDrawer from 'pages/questions/FilterDrawer'
import QuestionGrid from 'pages/questions/Grid'
import { quizQuestionActionsConfig as questionActionsConfig } from 'pages/questions/gridConfig'
import { mainColor } from 'components/common/Colors/CommonColors'
import Icon from 'components/Icon'
import { getMuiTheme } from 'material-ui/styles'
import { useTheme } from '@material-ui/core'

const CHANGE_ORDER = gql`
  mutation($draggedId: String!, $droppedId: String!) {
    changeQuestionsOrder(draggedId: $draggedId, droppedId: $droppedId)
  }
`

const QuizDetails = () => {
  const { t } = useTranslation()
  const [cards, setCards] = useState([])

  const moveCard = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )
  const [changeQuestionsOrder] = useMutation(CHANGE_ORDER)

  const params: any = useParams()
  const history = useHistory()
  const [state] = useUserValue()

  const [gridStyle, setGridStyle] = useState<string>('card')
  const [searchValue, setSearchValue] = useState<string>('')
  const [editMode, setEditMode] = useState(false)
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<any>({})
  const [filtered, setFiltered] = useState<boolean>(false)
  const [filteredQuestionList, setFilteredQuestionList] = useState<any>(null)
  const { fireSwal } = useSwal()

  const { data } = useQuery(GET_QUIZ_BY_ID, {
    variables: {
      quizId: params.id,
    },
  })
  const courseId = params?.courseId
  const { deleteQuiz } = useDeleteQuizService('s')
  const { addRemoveQuestions } = useAddRemoveQuestions()
  const [fetchQuestions, { data: questionData }] = useLazyQuery(
    GET_QUESTIONS_BY_IDS,
  )
  const { editQuiz } = useEditQuizService('1')
  const quizData = (data && data.getQuizById) || {}

  useEffect(() => {
    if (quizData.questionList && quizData.questionList.length > 0) {
      fetchQuestions({
        variables: {
          ids: quizData.questions,
        },
      })
    }
  }, [quizData, quizData.questions])

  const handleChangeRequest = async (draggedId: string, droppedId: string) => {
    const { data } = await changeQuestionsOrder({
      variables: { draggedId, droppedId },
    })
  }

  const handleResetClick = () => {
    setSearchValue('')
    setFilteredQuestionList(null)
    setFiltered(false)
    setFilterData({})
  }

  const handleSearchChange = (e: any) => {
    const { value } = e.target

    setSearchValue(value)
  }

  const editQuizDescription = (editorText: any) => {
    const newQuiz: any = {}
    newQuiz.description = editorText
    editQuiz(quizData.id, newQuiz, () => { })
    setEditMode(false)
  }

  const handleEditDrawer = (val: any) => {
    DrawerEventEmitter.emit('openDrawer', 'editQuiz', true, {
      type: quizData.quizType,
      id: quizData.actionId,
      data: { quizId: val.id },
    })

  }

  const selectItem = (id: never, event: boolean) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
    }

    setSelectedItem(newSelected)
  }

  const fireNotDeleteQuestionPopup = (isMultiple: boolean) => {
    const questionText = isMultiple
      ? t('general.questions')
      : t('general.question')
    fireSwal({
      title: `${t('popups.can_not_delete_questions')} ${questionText}`,
      confirmText: t('actions.close'),
      onConfirm: () => { },
    })
  }

  const handleDeleteQuiz = (id: string) => {
    const ids = [id]

    fireSwal({
      title: t('popups.delete_quiz_single'),
      confirmText: 'Yes, Delete',
      onConfirm: () => deleteQuiz(ids, () => history.goBack()),
    })
  }

  const handleViewQuestion = (id: string) => {
    history.push(`/questions/${id}`)
  }

  const removeQuestions = (ids: string[]) => {
    if (ids.length > 0)
      addRemoveQuestions('remove', ids, quizData.id, () => setSelectedItem([]))
  }

  const addQuestions = () => {
    const questionIds =
      questionData && questionData.questionsByIds.map((i: any) => i.id)
    const quizType = quizData.quizType
    // id.split('-')[0]

    DrawerEventEmitter.emit('openDrawer', 'AddQuestionsToQuiz', true, {
      type: quizType,
      id: quizData.actionId,
      data: { quizId: quizData.id, questionIds: questionIds },
    })
  }

  const handleDeleteQuestions = (ids: string[]) => {
    const { questions, randomizationNumber } = quizData
    const remainedQuestionsCount = questions.length - ids.length

    const isMultiple = ids.length > 1 ? true : false
    if (randomizationNumber && remainedQuestionsCount < randomizationNumber) {
      fireNotDeleteQuestionPopup(isMultiple)
    } else {
      const title: string = isMultiple
        ? 'If you remove Selected Questions this Questions will no longer exist in this Quiz but remains in Module Details View. Remove Questions?'
        : 'If you remove the Question this Question will no longer exist in this Quiz but remains in Module Details View. Remove the Question?'

      fireSwal({
        title,
        confirmText: 'Yes, Remove',
        onConfirm: () => removeQuestions(ids),
      })
    }
  }

  const actionHandler = (action: string, value: any) => {
    switch (action) {
      case 'edit':
        return handleEditDrawer(value)
        break
      case 'delete':
        return handleDeleteQuiz(value.id)
        break
      case 'view':
        return handleViewQuestion(value.id)
        break
      case 'deleteQuestion':
        return handleDeleteQuestions([value.id])
      default:
        break
    }
  }

  useEffect(() => {
    if (questionData) {
      const questions =
        questionData.questionsByIds.filter((item: any) => {
          return item.question.toLowerCase().includes(searchValue)
        }) || []
      setCards(questions)
    }
  }, [questionData, searchValue])

  const questionActions = questionActionsConfig(
    actionHandler,
    !quizData.companyId && state.currentUser.companyId,
    t,
  )

  let breadCrumbData: any = []
  if (data && quizData) {
    const { quizType } = quizData
    if (quizType === 'course') {
      breadCrumbData = [
        { label: t('general.courses'), link: '/courses' },
        { label: quizData.parentName, link: `/courses/${quizData.actionId}` },
        { label: quizData.name, link: '' },
      ]
    }

    if (quizType === 'module') {
      breadCrumbData = [
        { label: t('general.modules'), link: '/modules' },
        { label: quizData.parentName, link: `/modules/${quizData.actionId}` },
        { label: quizData.name, link: '' },
      ]
    }

    if (quizType === 'topic') {
      breadCrumbData = [
        { label: quizData.parentName, link: `/topics/${quizData.actionId}` },
        { label: quizData.name, link: '' },
      ]
    }

    if (quizType === 'lesson') {
      breadCrumbData = [
        { label: quizData.parentName, link: `/lessons/${quizData.actionId}` },
        { label: quizData.name, link: '' },
      ]
    }
  }

  const onStartQuiz = useCallback(() => {
    if (courseId && params?.id) {
      history.push(`/course/${courseId}/quizz/${params.id}`)
    }
  }, [courseId, params])

  const isStudent = state?.currentUser?.role === 'STUDENT'


  const theme = useTheme()

  return (
    <Container>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => setFilterDrawerOpened(false)}
        totalWidth="600px"
      >
        <FilterDrawer
          closeDrawer={() => setFilterDrawerOpened(false)}
          filterData={filterData}
          setFilterData={setFilterData}
          quizModel={quizData.quizType}
          currentModule={true}
          setFiltered={() => setFiltered(true)}
          questions={cards}
          setFilteredQuestionList={setFilteredQuestionList}
          currentPage={1}
          t={t}
        />
      </Drawer>
      {/* <Row style={{ marginTop: '32px' }}>
        <CourseContentLeft>
          <Box
            boxShadow={3}
            m={1}
            p={1}
            style={{ width: '100%', height: '600px', borderRadius: '3%' }}
          >
            <CourseContentWrapper>
              <Column>
                <CourseContentHeader>
                  <LibraryBooksIcon
                    style={{ fontSize: '5rem', color: mainColor }}
                  />
                  <CourseContentHeaderSection
                    style={{ marginTop: '0px', marginLeft: '15px' }}
                  >
                    <CourseTitle>{quizData.name}</CourseTitle>
                    <div>
                      {quizData.tags &&
                        quizData.tags.map((i: any) => <span>{i.label}, </span>)}
                    </div>
                  </CourseContentHeaderSection>
                </CourseContentHeader>
                <CourseContentDescription>
                  <div style={{ marginTop: '20px' }}>
                    <TextEditor
                      text={quizData.description}
                      isVisible={editMode}
                      hasControls={true}
                      editorTitle={t('quiz_details.quiz_overview')}
                      closeEditMode={() => setEditMode(false)}
                      openEditMode={() => setEditMode(true)}
                      onSave={editQuizDescription}
                      canEdit={
                        state.userPermission.editCourse &&
                        !(!quizData.companyId && state.currentUser.companyId)
                      }
                    />
                  </div>
                </CourseContentDescription>
              </Column>
            </CourseContentWrapper>
          </Box>
        </CourseContentLeft>
        <CourseContentRight>
          <Box
            boxShadow={3}
            m={1}
            p={1}
            style={{
              width: '320px',
              height: '600px',
              borderRadius: '3%',
              marginLeft: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {isStudent && (
              <Button
                text={t('quiz_details.start')}
                size="medium"
                onClick={onStartQuiz}
                width={200}
                height={40}
                background={mainColor}
              />
            )}

            <DetailsListContainer>
              <DetailsList>
                <DetailsListItem>
                  <ContactSupportIcon
                    style={{ fontSize: 40, color: mainColor }}
                  />
                  <Column>
                    <strong style={{ fontSize: '16px' }}>
                      {quizData?.questionsCount || 0}{' '}
                      {t('quiz_details.number_of_questions')}
                    </strong>
                    <TextWrapper>{t('quiz_details.questions')}</TextWrapper>
                  </Column>
                </DetailsListItem>
                <DetailsListItem>
                  <ScheduleIcon style={{ fontSize: 40, color: mainColor }} />
                  <Column>
                    <strong style={{ fontSize: '16px' }}>
                      {quizData.time} {t('quiz_details.time_in_minutes')}
                    </strong>
                    <TextWrapper>{t('quiz_details.duration')}</TextWrapper>
                  </Column>
                </DetailsListItem>
                <DetailsListItem>
                  <HistoryIcon style={{ fontSize: 40, color: mainColor }} />
                  <Column>
                    <strong style={{ fontSize: '16px' }}>
                      {quizData.timeType === 'limited'
                        ? t('general.limited')
                        : quizData.timeType === 'estimated'
                        ? t('general.estimated_time')
                        : quizData.timeType}
                    </strong>
                    <TextWrapper>{t('quiz_details.time_type')}</TextWrapper>
                  </Column>
                </DetailsListItem>
                <DetailsListItem>
                  <ErrorOutlineIcon
                    style={{ fontSize: 40, color: mainColor }}
                  />
                  <Column>
                    <strong style={{ fontSize: '16px' }}>
                      {quizData.quizParticipationType === 'optional'
                        ? t('general.optional')
                        : quizData.quizParticipationType === 'mandatory'
                        ? t('general.mandatory')
                        : quizData.quizParticipationType}
                    </strong>
                    <TextWrapper>{t('quiz_details.participation')}</TextWrapper>
                  </Column>
                </DetailsListItem>
                <DetailsListItem>
                  <SpellcheckIcon style={{ fontSize: 40, color: mainColor }} />
                  <Column>
                    <strong style={{ fontSize: '16px' }}>
                      {quizData.passRate}%
                    </strong>
                    <TextWrapper>{t('quiz_details.pass_rate')}</TextWrapper>
                  </Column>
                </DetailsListItem>
              </DetailsList>
            </DetailsListContainer>
          </Box>
        </CourseContentRight>
      </Row> */}
      <PageHeader>
        <TitleContainer>
          <div className="title">{quizData.name}</div>
          <div className="type">{t('general.module')}:{quizData.parentName}</div>
        </TitleContainer>
        <ActionButton
          style={{ position: 'absolute', top: '33px', right: '30px' }}
          onClick={() => handleEditDrawer({ id: quizData.actionId })}
          theme={theme}
        >
          <Icon filename={'edit'} />
        </ActionButton>
      </PageHeader>
      <QuizContentDetails style={{ padding: '30px' }}>
        <QuizContentDetail>
          <span>{t('general.details')}</span>
        </QuizContentDetail>
        <QuizContentDetail>
          <strong>{t('general.duration')}:</strong>
          <span>{quizData.time}</span>
        </QuizContentDetail>
        <QuizContentDetail>
          <strong>{t('general.questions')}:</strong>
          <span>{quizData?.questionsCount || 0}</span>
        </QuizContentDetail>
        <QuizContentDetail>
          <strong>{t('general.mandatory')}:</strong>
          <span>
            {quizData.quizParticipationType === 'mandatory' ? t('form_fields.yes') : t('general.no')}
          </span>
        </QuizContentDetail>
        <QuizContentDetail>
          <strong>{t('quiz_details.pass_rate')}:</strong>
          <span>{quizData.passRate}%</span>
        </QuizContentDetail>
        <QuizContentDetail>
          <strong>{t('general.randomized')}:</strong>
          <span>{t("general.yes")}</span>
        </QuizContentDetail>
      </QuizContentDetails>

      <Tags>
        {quizData.tags && quizData.tags.map((i: any) => <div>{i.label}</div>)}
      </Tags>

      <CourseContentDescription
        style={{
          padding: '0px 30px',
          borderTop: '1px solid #EBEBEB',
          borderBottom: '1px solid #EBEBEB',
        }}
      >
        <div style={{ marginTop: '20px' }}>
          <TextEditor
            text={quizData.description}
            isVisible={editMode}
            hasControls={true}
            editorTitle={t('quiz_details.quiz_overview')}
            closeEditMode={() => setEditMode(false)}
            openEditMode={() => setEditMode(true)}
            onSave={editQuizDescription}
            canEdit={
              state.userPermission.editCourse &&
              !(!quizData.companyId && state.currentUser.companyId)
            }
          />
        </div>
      </CourseContentDescription>

      {!isStudent && (
        <>
          {/* <Header className={'withBorder'}>
            <Wrapper>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {!quizData.companyId && state.currentUser.companyId ? null : (
                  <Button
                    text={t('quiz_details.add_question')}
                    color="secondary"
                    onClick={addQuestions}
                    icon={<AddCircleRoundedIcon />}
                    background={mainColor}
                  />
                )}

                <GridMultipleActions
                  selectedItems={selectedItem.length}
                  actions={[
                    {
                      hide: !(
                        quizData.companyId && state.currentUser.companyId
                      ),
                      id: 0,
                      color: 'secondary',
                      tooltipText: t('actions.delte'),
                      disabled: selectedItem.length < 1,
                      onClick: () => handleDeleteQuestions(selectedItem),
                      component: <DeleteIcon fontSize="small" />,
                    },
                  ].filter((i: any) => !i.hide)}
                  isNumberOfSelectedItemsHidden={true}
                />
              </div>

              <div style={{ display: 'flex' }}>
                <MultipleAction>
                  <IconButton
                    disabled={!filtered && !searchValue}
                    onClick={handleResetClick}
                  >
                    <SyncIcon fontSize="small" />
                  </IconButton>
                  <MultipleActionTooltip>
                    {t('actions.reset')}
                  </MultipleActionTooltip>
                </MultipleAction>
                <MultipleAction>
                  <IconButton onClick={() => setFilterDrawerOpened(true)}>
                    <FilterListIcon
                      fontSize="small"
                      style={{ color: mainColor }}
                    />
                  </IconButton>
                  <MultipleActionTooltip>
                    {t('actions.filters')}
                  </MultipleActionTooltip>
                </MultipleAction>
                <div style={{ marginRight: '20px' }}>
                  <TextInput
                    label={t('general.search_placeholder')}
                    type="text"
                    size="small"
                    value={searchValue}
                    onChange={handleSearchChange}
                    icon={
                      <IconButton type="submit">
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                  />
                </div>

                <GridSwitchButtons>
                  <button
                    className={`${gridStyle === 'card' ? 'active' : ''}`}
                    onClick={() => setGridStyle('card')}
                  >
                    <DashboardIcon />
                  </button>
                  <button
                    className={`${gridStyle === 'list' ? 'active' : ''}`}
                    onClick={() => setGridStyle('list')}
                  >
                    <ViewListIcon />
                  </button>
                </GridSwitchButtons>
              </div>
            </Wrapper>
          </Header> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '30px',
            }}
          >
            {!quizData.companyId && state.currentUser.companyId ? null : (
              <AddButton onClick={addQuestions}>
                <Icon filename={'add'} fill={'#ffffff'} />
                {t('quiz_details.add_question')}
              </AddButton>
            )}
            <GridLayoutForm>
              {`${selectedItem?.length || 0} ${t('general.items_selected')}`}
              <ActionButton
                onClick={() => handleDeleteQuestions(selectedItem)}
                disabled={selectedItem.length < 1}
                fill={'#CC3131'}
                disabledFill={'#080A0AD6'}
                theme={theme}
              >
                <Icon filename={'delete'} />
              </ActionButton>
            </GridLayoutForm>
          </div>
          <div style={{ padding: '0px 40px' }}>
            <QuestionGrid
              gridStyle={gridStyle}
              cols={4}
              data={filteredQuestionList || cards}
              selectItem={selectItem}
              selectedItems={selectedItem}
              actions={questionActions}
              // isDraggable={!filtered && !searchValue ? true : false}
              fetchQuestions={fetchQuestions}
              questions={quizData.questions}
              moveCard={moveCard}
              handleChangeRequest={handleChangeRequest}
              t={t}
            />
          </div>
        </>
      )}
    </Container>
  )
}
export default QuizDetails

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  overflow: hidden;
`

const PageHeader = styled.div`
  position: relative;
  width: 100%;
  min-height: 80px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: max-content;
  padding: 40px 30px;
  gap: 10px;

  .title {
    font-size: 24px;
    color: #080a0ad6;
    font-weight: 600;
    text-align: center;
  }

  .type {
    font-size: 16px;
    color: #080a0a8a;
    padding: 9px 10px;
    border: 1px solid #080a0a14;
    border-radius: 8px;
  }
`

export const QuizContentDetails = styled.ul`
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const QuizContentDetail = styled.li`
  display: flex;
  gap: 8px;
  margin: 2px 0px;
  strong {
    color: #080a0ad6;
    font-size: 16px;
  }
  span {
    color: #080a0a8a;
    font-size: 16px;
  }
`

const Tags = styled.div`
  display: flex;
  align-items: center;
  padding: 30px;
  padding-top: 0px;
  gap: 10px;

  & > div {
    font-size: 16px;
    color: #080a0a8a;
    padding: 9px 10px;
    border: 1px solid #080a0a14;
    border-radius: 8px;
  }
`

const Header = styled.header`
  margin-bottom: 20px;
  margin-top: 50px;

  &.withBorder {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
  }

  button.makeStyles-button-247 {
    padding: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
