import Checkbox from 'components/common/Checkbox'
import { black, mainColor, red, successGreen } from 'components/common/Colors/CommonColors'
import React, { useCallback, useEffect, useState } from 'react'
import { AnswerContainer } from './SingleAnswer'

export type AnswerType = {
  id: string
  value: string
}

const MultipleAnswers: React.FC<{
  answers: AnswerType[]
  setHasAnswerSelected: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedAnswers: React.Dispatch<React.SetStateAction<string[]>>
  isAnswersButtonDisabled: boolean
  correctAnswers: [string]
}> = ({
  answers,
  setHasAnswerSelected,
  setSelectedAnswers: setSelectedAnswersForParent,
  isAnswersButtonDisabled,
  correctAnswers,
}) => {
    const [selectedAnswers, setSelectedAnswers] = useState<AnswerType[]>(
      [] as AnswerType[],
    )
    const onCheckboxChange = useCallback(
      (answer: AnswerType) => {
        let index = selectedAnswers.findIndex(ans => ans.id === answer.id)
        if (index > -1) {
          setSelectedAnswers((x: AnswerType[]) => [
            ...x.slice(0, index),
            ...x.slice(index + 1),
          ])
          setSelectedAnswersForParent(x => [
            ...x.slice(0, index),
            ...x.slice(index + 1),
          ])
        } else {
          setSelectedAnswers((x: AnswerType[]) => [...x, answer])
          setSelectedAnswersForParent(x => [...x, answer.id])
        }
      },
      [selectedAnswers],
    )

    useEffect(() => {
      if (selectedAnswers && selectedAnswers.length > 1)
        setHasAnswerSelected(true)
      else setHasAnswerSelected(false)
    }, [selectedAnswers])

    const MultipleChoiceAnswer: React.FC<{ answer: AnswerType }> = ({
      answer,
    }) => {
      const [color, setColor] = useState(black)
      const [checkBoxColor, setCheckBoxColor] = useState(mainColor)

      useEffect(() => {
        if (selectedAnswers && correctAnswers && isAnswersButtonDisabled) {
          if (
            selectedAnswers.indexOf(answer) > -1 &&
            correctAnswers.indexOf(answer.id) > -1
          ) {
            setColor(successGreen)
          } else if (
            (selectedAnswers.indexOf(answer) > -1 &&
              correctAnswers.indexOf(answer.id) === -1) ||
            (selectedAnswers.indexOf(answer) === -1 &&
              correctAnswers.indexOf(answer.id) > -1)
          ) {
            setColor(red)
            setCheckBoxColor(red)
          }
        }
      }, [selectedAnswers, correctAnswers, isAnswersButtonDisabled])
      return (
        <AnswerContainer
          onClick={() => {
            !isAnswersButtonDisabled && onCheckboxChange(answer)
          }}
        >
          <Checkbox
            value={answer.id}
            checked={selectedAnswers.findIndex(ans => ans.id === answer.id) > -1}
            onChange={() => {
              !isAnswersButtonDisabled && onCheckboxChange(answer)
            }}
            backgroundColor={'#080A0A'}
          />
          <div style={{ color }}>{answer.value}</div>
        </AnswerContainer>
      )
    }

    return (
      <div>
        {answers &&
          answers.map(answer => {
            return <MultipleChoiceAnswer key={answer.id} answer={answer} />
          })}
      </div>
    )
  }

export default MultipleAnswers
