import { FormControlLabel, Radio, useTheme } from '@material-ui/core'
import React from 'react'
import * as S from './styled'
import { useTranslation } from 'react-i18next'
import { genderOptions } from 'components/UsersLayout/staticData'

export type GenderPickerProps = {
  checked?: string
  onChange: (e: string) => void
}

const GenderPicker: React.FC<GenderPickerProps> = props => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <S.Container theme={theme}>
      <S.Title theme={theme}>{t('user_details.gender')}:</S.Title>
      {genderOptions.map((gender: any) => (
        <FormControlLabel
          key={gender.value}
          value={gender.value}
          control={
            <Radio
              checked={gender.value === props.checked}
              onChange={() => props.onChange(gender.value)}
            />
          }
          label={gender.label}
        />
      ))}
    </S.Container>
  )
}

export default GenderPicker
