import React from 'react'
import styled from 'styled-components'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'

export const actionConfig = (
  handleEditClick?: any,
  handleDeleteClick?: any,
  t?: any,
  isBoughtCourse?: boolean,
) => {
  let action = [
    {
      hide: isBoughtCourse,
      render: (item: any) => (
        <Action onClick={() => handleEditClick(item.id)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={() => handleDeleteClick(item.id)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
  ]

  return action
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
`
