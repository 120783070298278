import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import { FormGroupWrapper } from 'components/common/Form/Form'
import { useTranslation } from 'react-i18next'

import { useAddQuestionsToModule } from 'hooks/questions/useAddQuestionsToModule'
import { GET_QUESTIONS } from 'gql/questions.query'
import { useUserValue } from 'context/UserContext'
import { PER_PAGE } from 'hooks/questions/useQuestions'

import Drawer from 'components/common/Drawer'
import FilterDrawer from '../FilterDrawer'
import { Toolbar, Grid } from 'pages/questions'
import { Button } from 'components/common/Button'
import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import Pagination from 'components/common/Pagination'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { useTheme } from '@material-ui/core'

interface Params {
  title: string
  handleCloseDrawer: () => void
  closeDrawer: () => void
  currentModule: any
  refetch: () => void
  currentModuleQuestionsLength: number
  manualCompanyId?: string
}

const LinkQuestionsToModuleDrawer = ({
  title,
  handleCloseDrawer,
  closeDrawer,
  currentModule,
  refetch,
  currentModuleQuestionsLength,
  manualCompanyId,
}: Params) => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const defaultFilters = {
    companyId: {
      type: 'exact',
      value: manualCompanyId || state.selectedCompanyId,
    },
    modules: {
      type: 'nestedArrayNotIn',
      value: currentModule.moduleId,
      nestedField: 'moduleId',
    },
  }
  const { data, loading, refetch: refetchCurrentQuestions }: any = useQuery(
    GET_QUESTIONS,
    {
      variables: {
        type: '',
        id: currentModule.moduleId,
        filter: defaultFilters,
        currentPage: 1,
        perPage: 12,
      },
    },
  )
  const { addQuestionsToModule } = useAddQuestionsToModule()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage] = useState<number>(PER_PAGE)
  const [searchValue, setSearchValue] = useState<string>('')
  const [gridStyle, setGridStyle] = useState<string>('card')
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<any>({})
  const [filtered, setFiltered] = useState<boolean>(false)

  const selectItem = (id: never, event: boolean) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = data.getQuestions.data.reduce(
        (ids: string[], n: any) => {
          const moduleIds = n.modules.map((item: any) => item.moduleId)
          if (!moduleIds.includes(currentModule.moduleId)) {
            ids.push(n.id)
          }
          return ids
        },
        [],
      )
      setSelectedItem({ ...selectedItem, [currentPage]: newArr })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({ ...selectedItem, [currentPage]: [] })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    refetchCurrentQuestions({
      type: '',
      id: '',
      filter: {
        ...defaultFilters,
        question: { type: 'match', value: searchValue },
      },
    })
    setFiltered(true)
  }

  const handleResetClick = () => {
    setFilterData({})
    setFiltered(false)
    refetchCurrentQuestions({
      type: '',
      id: '',
      filter: defaultFilters,
    })
  }

  const handleSearchChange = (e: any) => {
    const { value } = e.target

    setSearchValue(value)
    if (e.target.value === '') {
      refetchCurrentQuestions({
        type: '',
        id: '',
        filter: defaultFilters,
      })
    }
  }

  const onSuccess = () => {
    refetch()
  }

  const handleSubmit = () => {
    addQuestionsToModule(currentModule, selectedItem[currentPage], onSuccess)
  }

  const handlePaginationClick = (event: any, value: number) => {
    if (value !== currentPage) {
      refetchCurrentQuestions({
        type: '',
        id: '',
        filter: {
          ...defaultFilters,
          question: { type: 'match', value: searchValue },
        },
        currentPage: value,
        perPage,
      })
      setCurrentPage(value)
    }
  }

  const handleFilterClick = (filterOptions: any) => {
    setSelectedItem({})
    setSelectAll({})
    refetchCurrentQuestions({
      filter: {
        ...defaultFilters,
        ...filterOptions,
        question: { type: 'match', value: searchValue },
      },
      currentPage: 1,
    })
  }

  useEffect(() => {
    if (selectedItem.length > 0) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
    } else {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: '',
          confirm: false,
        },
      })
    }
  }, [selectedItem])

  const questionList = (data && data.getQuestions.data) || []


  const theme = useTheme()

  return (
    <>
      {/* {loading && <Loader withBackground={true} />} */}
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => setFilterDrawerOpened(false)}
        totalWidth="600px"
      >
        <FilterDrawer
          closeDrawer={() => setFilterDrawerOpened(false)}
          filterData={filterData}
          setFilterData={setFilterData}
          quizModel="module"
          handleFilterClick={handleFilterClick}
          currentModule=""
          refetch={refetchCurrentQuestions}
          setFiltered={() => setFiltered(true)}
          questions={questionList}
          currentPage={1}
          t={t}
        />
      </Drawer>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{title}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <Container>
          <FormGroupWrapper>
            <Header>
              <Toolbar
                fromModule={true}
                searchValue={searchValue}
                gridStyle={gridStyle}
                setGridStyle={setGridStyle}
                dataLength={questionList.length}
                handleSearchChange={handleSearchChange}
                selectedItem={selectedItem[currentPage] || []}
                selectAllItem={selectAllItem}
                handleSearchSubmit={handleSearchSubmit}
                openFilterDrawer={() => setFilterDrawerOpened(true)}
                handleResetClick={handleResetClick}
                filtered={filtered}
                t={t}
              />
            </Header>
            <div style={{ padding: 10 }}>
              <Grid
                cols={2}
                gridStyle={gridStyle}
                data={questionList}
                selectItem={selectItem}
                selectedItems={selectedItem[currentPage] || []}
                currentModuleId={currentModule.moduleId}
                linkDrawer={true}
                t={t}
              />
            </div>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.save')}
                type="small"
                background={mainColor}
                onClick={handleSubmit}
                isDisabled={
                  selectedItem[currentPage]
                    ? selectedItem[currentPage].length === 0
                    : true
                }
              />
            </FormButtons>
            {data && data.getQuestions.totalPages > 1 && (
              <div>
                <Pagination
                  currentPage={data ? data.getQuestions.currentPage : 1}
                  totalPages={data ? data.getQuestions.totalPages : 0}
                  handleChange={handlePaginationClick}
                />
              </div>
            )}
          </FormFooter>
        </Container>
      </DrawerContent>
    </>
  )
}

export default LinkQuestionsToModuleDrawer

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Header = styled.header`
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  padding-left: 20px;
  padding-top: 10px;
  border-bottom: 1px solid #ccc;
  position: sticky;
  z-index: 9999;
  top: 0;
  background: #fff;
`

const FormFooter = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.3);

  & > div {
    width: 50%;
  }

  & nav {
    margin-top: 0;
    display: flex;
    justify-content: flex-end;
  }
`
const FormButtons = styled.div`
  align-self: flex-end;
  display: flex;
  padding: 20px;

  div:not(:last-child) {
    margin-right: 10px;
  }
`
