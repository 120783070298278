export const filterQuestions = (questions: any, filterOptons: any) => {
  const filteredQuestions: any = questions.filter((question: any) => {
    for (const property in filterOptons) {
      const { type, value, nestedField } = filterOptons[property]
      if (value && type === 'exact') {
        if (nestedField && value !== question[property][nestedField]) {
          return false
        }
      }

      if (value && type === 'nestedArrayIn') {
        const includes = question[property].find((item: any) => {
          return value.includes(item[nestedField]) ? true : false
        })

        if (!includes) {
          return false
        }
      }

      if (value && type === 'match') {
        if (!question[property].toLowerCase().includes(value)) {
          return false
        }
      }

      if (value && type === 'bool') {
        if (question[property].toString() !== value) {
          return false
        }
      }
    }

    return question
  })

  return filteredQuestions
}
