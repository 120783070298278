import React, { useState, useEffect } from 'react'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import styled from 'styled-components'
import Checkbox from 'components/common/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import GetAppIcon from '@material-ui/icons/GetApp'
import { formatDuration } from 'helpers/formatDuration'

import HeadsetIcon from '@material-ui/icons/Headset'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import { Input } from 'components/common/TextInput'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from 'components/Icon'

interface ColumnItem {
  id: string
  isChecked: boolean
  name: string
  lessons: any
  attachment: any
  numberOfLessons: string
  video: any
  audio: any
  contentDocument: any
  duration: number
  subtitle: any
}

// const renderFile = (file: any, openAttachment: any) => {
// const { type } = file

// const fileType = getFileType(type)

//   return (
//     <FileWrapper onClick={() => openAttachment(file.link)}>
// <FileIcon>
//   <img src={`/${fileType}_icon.svg`} alt="File icon" />
// </FileIcon>

// <CustomTooltip title={`${file.name}.${file.type}`}>
//   <FileTitle>
//     {strCutter(file.name, 10)}.{file.type}
//   </FileTitle>
// </CustomTooltip>
//     </FileWrapper>
//   )
// }

const Component = ({ item, onTopicEdit, navigateToTopicDetails }: any) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [fieldName, setFieldName] = useState('')

  useEffect(() => {
    setFieldName(item.name)
  }, [isEditMode, item.name])

  const onClick = () => {
    if (!fieldName) {
      return setIsEditMode(false)
    }

    onTopicEdit(item.id, fieldName, () => setIsEditMode(false))
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ cursor: 'pointer' }} onClick={e => e.stopPropagation()}>
        {!isEditMode ? (
          <div onClick={() => navigateToTopicDetails(item.id)}>{item.name}</div>
        ) : (
          <Input
            // error={!!errors.name}
            // errorMessage={errors.name ? errors.name : ''}
            // touched={touched.name}
            // label="Name*"
            name="name"
            size="small"
            type="text"
            value={fieldName}
            onChange={({ target }: any) => setFieldName(target.value)}
          />
        )}
      </div>

      <div
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={e => e.stopPropagation()}
      >
        {!isEditMode && (
          <IconButton onClick={() => setIsEditMode(true)}>
            <EditRoundedIcon />
          </IconButton>
        )}
        {isEditMode && (
          <>
            <IconButton onClick={onClick}>
              <CheckCircleOutlineIcon />
            </IconButton>
            <IconButton onClick={() => setIsEditMode(false)}>
              <CancelIcon />
            </IconButton>
          </>
        )}
      </div>
    </div>
  )
}

export const columnConfig = (
  permissions: any,
  selectItem?: any,
  navigateToTopicDetails?: any,
  onTopicEdit?: any,
  canModify?: boolean,
  t?: any,
  onExpandClick?: any
) => {
  let gridWithoutCheckbox = [
    // {
    //   id: 'emptyBox',
    //   label: 'ყველა',
    //   render: (item: ColumnItem) => <div style={{ width: 40 }} />,
    //   numeric: false,
    //   disablePadding: true,
    // },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('general.name'),
      render: (item: ColumnItem) =>
        canModify ? (
          <Component
            item={item}
            onTopicEdit={onTopicEdit}
            navigateToTopicDetails={navigateToTopicDetails}
          />
        ) : (


          <div>{item.name}</div>

        ),
    },
    {
      numeric: false,
      disablePadding: true,
      label: t('general.lessons'),
      id: 'lessons',
      render: (item: ColumnItem) => {
        return <div>{item.lessons.length}</div>
      },
    },
    {
      numeric: false,
      disablePadding: true,
      label: t('general.duration'),
      id: 'duration',
      render: (item: ColumnItem) => {
        const duration = item.lessons.reduce(
          (duration: number, lesson: any) => {
            duration += lesson.duration

            return duration
          },
          0,
        )

        return <div>{formatDuration(duration)}</div>
      },
    },
    // {
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'Attachment',
    //   id: 'attachment',
    //   render: (item: ColumnItem) => {
    //     return item.attachment ? (
    //       <FileWrapper file={item.attachment} onClick={openAttachment} />
    //     ) : (
    //         <NoValueWrapper>No Attachment</NoValueWrapper>
    //       )
    //   },
    // },
  ]

  let grid = permissions.deleteTopic
    ? [
      {
        id: 'isChecked',
        render: (item: ColumnItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={item.isChecked}
              onChange={() => selectItem(item.id)}
            />
          </div>
        ),
        numeric: false,
        disablePadding: true,
      },
      ...gridWithoutCheckbox,
    ]
    : [
      {
        id: 'emptyBox',
        render: (item: ColumnItem) => <div style={{ width: 40 }} />,
        numeric: false,
        disablePadding: true,
      },
      ...gridWithoutCheckbox,
    ]

  return grid
}

export const nestedColumConfig = (nestedEvent: any, t: any) => {
  let grid = [
    {
      numeric: false,
      disablePadding: true,
      id: 'video',
      render: (item: ColumnItem) => {
        const hasConverted = item.video && item.video.links[0].converted
        if (item.video && item.video.name && item.video.links) {
          return hasConverted || (item.video && hasConverted) ? (
            <div style={{
              width: 60,
              height: 60,
              background: '#3A7455',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
            }}>
              <IconButton
                onClick={e => {
                  e.stopPropagation()
                  nestedEvent(item, 'playVideo')
                }}
              >
                <Icon filename={'playicon'} />
              </IconButton>
            </div>
          ) : (
            <CircularProgress size={20} color="primary" />
          )
        }

        if (item.audio && item.audio.link) {
          return (
            <div style={{
              width: 60,
              height: 60,
              background: '#3A7455',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
            }}>
              <IconButton onClick={() => nestedEvent(item, 'playAudio')}>
                <Icon filename={'audioicon'} />
              </IconButton>
            </div>
          )
        }

        if (item.contentDocument && item.contentDocument.link) {
          return (
            <div style={{
              width: 60,
              height: 60,
              background: '#3A7455',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
            }}>
              <IconButton
                onClick={() => window.open(item.contentDocument.link, '_blank')}
              >
                <Icon filename={'pdficon'} />
              </IconButton>
            </div>
          )
        }
      },
    },
    {
      numeric: false,
      disablePadding: true,
      label: t('general.name'),
      id: 'name',
      render: (item: ColumnItem) => (
        <div style={{ cursor: 'pointer' }}>{item.name}</div>
      ),
    },
    {
      numeric: false,
      disablePadding: true,
      label: t('general.duration'),
      id: 'duration',
      render: (item: ColumnItem) => (
        <div style={{ cursor: 'pointer' }}>{formatDuration(item.duration)}</div>
      ),
    },
  ]

  return grid
}

export const actionConfig = (
  handleAddLessonClick?: any,
  navigateToTopicDetails?: any,
  handleEditClick?: any,
  handleDeleteClick?: any,
  handleDownloadClick?: any,
  permissions?: any,
  canModify?: boolean,
  t?: any,
) => {
  let action = [
    {
      hide: !permissions.createLesson && !canModify,
      render: (item: any) => (
        // item.lessons && (
        <Action onClick={() => handleAddLessonClick(item)}>
          <AddCircleIcon />
          <span>{t('actions.add_lesson')}</span>
        </Action>
      ),
      // ),
    },
    {
      hide: !permissions.viewTopic,
      render: (item: any) => (
        <Action onClick={() => navigateToTopicDetails(item.id)}>
          <ViewCompactIcon />
          <span>{t('actions.view')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.deleteTopic && !canModify,
      render: (item: any) => (
        <Action onClick={() => handleDeleteClick([item.id])}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
  ]

  return action.filter((i: any) => !i.hide)
}

export const nestedActions = (
  nestedActionClick: any,
  permission: any,
  canModify?: boolean,
  t?: any,

) => {
  let action = [
    {
      hide: !permission.viewLesson && !canModify,
      render: (item: any) => (
        <Action onClick={() => nestedActionClick(item, 'view')}>
          <ViewCompactIcon />
          <span>{t('actions.view')}</span>
        </Action>
      ),
    },
    {
      hide: !permission.editLesson && !canModify,
      render: (item: any) => (
        <Action onClick={() => {
          nestedActionClick(item, 'edit');

        }}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !permission.deleteLesson && !canModify,
      render: (item: any) => (
        <Action onClick={() => nestedActionClick(item.id, 'delete')}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action
          onClick={() => nestedActionClick(item, 'download')}
          className={item.transcriptFile ? '' : 'disabled'}
        >
          <GetAppIcon />
          <span>{t('actions.download_transcript')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action
          onClick={() => nestedActionClick(item, 'download')}
          className={item.attachment ? '' : 'disabled'}
        >
          <GetAppIcon />
          <span>{t('actions.download_attachment')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action
          onClick={() => nestedActionClick(item, 'download')}
          className={item.subtitle ? '' : 'disabled'}
        >
          <GetAppIcon />
          <span>{t('actions.download_subtitle')}</span>
        </Action>
      ),
    },
  ]

  return action.filter((i: any) => !i.hide)
}



const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`
