import { useMutation } from '@apollo/react-hooks'

import {
  GET_COMPANIES,
  GET_COMPANY,
  DELETE_COMPANY,
  ADD_COMPANY,
  UPDATE_COMPANY,
  CHANGE_COMPANY_STATUS,
  CHANGE_COMPANY_PLATFORM_SEVICE,
  ADD_MANAGER,
  EDIT_MANAGER,
  SET_COMPANY_STATE,
} from 'gql/companies.query'
import { updateCache } from 'services/updateCache'

const companiesService = (params?: any) => {
  // // eslint-disable-next-line react-hooks/rules-of-hooks
  // const [getCompanyMutation] = useMutation(GET_COMPANY);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addCompanyMutation] = useMutation(ADD_COMPANY, {
    update(cache, { data: { addCompany: document } }) {
      updateCache({
        objectName: 'companies',
        query: GET_COMPANIES,
        cache,
        document,
        action: 'add',
      })
    },
  })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [changeCompanyStatusMutation] = useMutation(CHANGE_COMPANY_STATUS, {
    update(cache, { data: { changeCompanyStatus: document } }) {
      updateCache({
        objectName: 'companies',
        query: GET_COMPANIES,
        cache,
        document,
        action: 'update',
      })
    },
  })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [changeCompanyPlatformServiceMutation] = useMutation(
    CHANGE_COMPANY_PLATFORM_SEVICE,
    {
      update(cache, { data: { changeCompanyPlatformService: document } }) {
        updateCache({
          objectName: 'company',
          query: GET_COMPANY,
          cache,
          document,
          action: 'update',
          params,
        })
      },
    },
  )

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [updateCompanyMutation] = useMutation(UPDATE_COMPANY, {
    update(cache, { data: { updateCompany: document } }) {
      updateCache({
        objectName: 'companies',
        query: GET_COMPANIES,
        cache,
        document,
        action: 'update',
      })
    },
  })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteCompanyMutation] = useMutation(DELETE_COMPANY, {
    update(cache, { data: { deleteCompany: document } }) {
      updateCache({
        objectName: 'companies',
        query: GET_COMPANIES,
        cache,
        document,
        action: 'delete',
      })
    },
  })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addManagerMutation] = useMutation(ADD_MANAGER)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editManagerMutation] = useMutation(EDIT_MANAGER)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [setCompanyStateMutation] = useMutation(SET_COMPANY_STATE)

  return {
    addCompanyMutation,
    changeCompanyStatusMutation,
    changeCompanyPlatformServiceMutation,
    updateCompanyMutation,
    deleteCompanyMutation,
    addManagerMutation,
    editManagerMutation,
    setCompanyStateMutation
  }
}

export default companiesService
