import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { mainColor } from '../common/Colors/CommonColors'
import { useUserValue } from 'context/UserContext'
import { boolean } from 'yup'

const ExpansionPanel = withStyles({
  root: {
    backgroundColor: 'transparent',
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: '#ffffff',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    color: '#fff',
    // backgroundColor: '#333',
  },
}))(MuiExpansionPanelDetails)

const Typo = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: '16px',
    margin: props => (props.isOpened ? 'unset' : 'auto'),
  },
})(Typography)

const useStyles = makeStyles({
  root: {
    // backgroundColor: '#333',
  },

  navlink: {
    '&:hover': {},
  },

  closed: {
    visibility: 'hidden',
  },
})

const ExpansionNavMenu = props => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState('')
  const classes = useStyles()
  const [state] = useUserValue()

  const isCompanySuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !!state?.currentUser?.companyId?.id

  const isCompanyAdmin =
    state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  useEffect(() => {
    if (isCompanySuperAdmin || isCompanyAdmin) {
      setExpanded(`panel${props.index}`)
    }
  }, [isCompanySuperAdmin, isCompanyAdmin])

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const ExpansionPanelSummary = withStyles({
    root: {
      padding: props => (props.isOpened ? `16px 30px` : '16px 30px'),
      fontSize: '16px',
      backgroundColor: 'transparent',
      // borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      width: '100%',
      color: '#fff',
      transition: 'color .3s ease',

      '&$expanded': {
        backgroundColor: '#080A0A1F !important',
      },

      '&:hover': {
        backgroundColor: '#317BF4',
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
        cursor: !isCompanySuperAdmin && !isCompanyAdmin ? 'pointer' : 'default',
      },
    },
    expandIcon: {
      color: 'inherit',
    },
    expanded: {},
  })(MuiExpansionPanelSummary)

  return (
    <>
      {props.item.canView && (
        <ExpansionPanel
          square
          expanded={expanded === `panel${props.index}`}
          onChange={
            !isCompanySuperAdmin && !isCompanyAdmin
              ? handleChange(`panel${props.index}`)
              : () => {}
          }
          key={props.index}
          className={classes.navlink}
        >
          <ExpansionPanelSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
            isOpened={props.isOpened}
            expandIcon={
              props.isOpened && !isCompanySuperAdmin && !isCompanyAdmin ? (
                <ExpandMoreIcon style={{ color: '#fff' }} />
              ) : (
                false
              )
            }
          >
            <Typo isOpened={props.isOpened}>
              {props.item.icon}
              {props.isOpened && (
                <span className={classes.root}>
                  {t(`navigation.${props.item.title}`)}
                </span>
              )}
            </Typo>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ExpansionMenuItemList navBarOpen={props.isOpened}>
              {props.item.children.map((i, index) => {
                // const canView = i.canView ? i.canView.includes(props.role) : true

                return (
                  <React.Fragment key={index}>
                    {i.canView && (
                      <ExpansionMenuItem key={index} isOpened={props.isOpened}>
                        <NavLink to={i.path} activeClassName="active" exact>
                          {i.icon}
                          {props.isOpened && (
                            <span className={classes.root}>
                              {t(`navigation.${i.title}`)}
                            </span>
                          )}
                        </NavLink>
                      </ExpansionMenuItem>
                    )}
                  </React.Fragment>
                )
              })}
            </ExpansionMenuItemList>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </>
  )
}

export default ExpansionNavMenu

const ExpansionMenuItemList = styled.ul`
  width: 100%;
  padding-left: 0px;
  ${({ navBarOpen }) =>
    navBarOpen &&
    css`
      padding-left: 30px;
    `}
`

const ExpansionMenuItem = styled.li`
  & > a {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 30px;
    margin: 10px 0px;
    gap: 10px;
    /* background: rgba(0, 0, 0, 0.87); */
    font-size: 16px;
    transition: 0.3s ease;

    span {
      margin: 0px;
    }

    &:hover,
    &.active {
      color: #317bf4 !important;
      & > div {
        transition: 0.3s;
        background: #317bf4;
      }
    }

    & > div {
      margin: ${props => (props.isOpened ? 'unset' : 'auto')};
    }
  }

  &:not(:last-child) > a {
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.5); */
  }
`
