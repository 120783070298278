import { Button } from 'components/common/Button'
import React, { forwardRef, useRef } from 'react'
import Cropper from 'react-cropper'
import { useTranslation } from 'react-i18next'
import { ImageCropperContainer } from './styled-components'
import { validationRed, red } from '../Colors/CommonColors'

interface ImageCropperParams {
  file: any
  hideCropper: () => void
  getCroppedFile: (field: string, croppedFile: string) => void
}

const cropper: any = React.createRef() as any

const ImageCropper: React.FC<{
  file: any
  hideCropper: () => void
  getCroppedFile: (field: string, croppedFile: string) => void
}> = forwardRef(({ file, hideCropper, getCroppedFile }: ImageCropperParams) => {
  const { t } = useTranslation()

  const finishCrop = () => {
    let croppedFile = null
    croppedFile = cropper.current.getCroppedCanvas().toDataURL()
    getCroppedFile(file.name, croppedFile)
    hideCropper()
  }

  return (
    <ImageCropperContainer>
      <Cropper
        ref={cropper}
        src={file.file}
        style={{ height: 500, width: 600 }}
        // aspectRatio={16 / 16}
        guides={false}
      />
      <div className="actions">
        <Button
          text={t('actions.cancel')}
          size="small"
          background="#C7C7C7"
          textColor={validationRed}
          outline={`1px solid ${red}`}
          onClick={hideCropper}
        />
        <Button
          text={t('actions.save')}
          color="secondary"
          size="small"
          onClick={() => finishCrop()}
        />
      </div>
    </ImageCropperContainer>
  )
})

export default ImageCropper
