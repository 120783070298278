import React from 'react'
import colors from './colors'

import { Container, Grid, ColorContainer } from './styled.components'

interface Params {
  selectedColor: string
  setSelectedColor: (color: string) => void
}

const ColorPicker = ({ selectedColor, setSelectedColor }: Params) => {
  return (
    <Container>
      <Grid>
        {colors.map(color => (
          <ColorContainer
            key={color}
            color={color}
            onClick={() => setSelectedColor(color)}
            className={`${selectedColor &&
              selectedColor === color &&
              'selected'}`}
          />
        ))}
      </Grid>
    </Container>
  )
}

export default ColorPicker
