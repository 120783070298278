import i18n from 'i18n'

export const getRole = (role: string, iscompany?: boolean) => {
  let formattedRole = ''
  if (role === 'SUPER_ADMIN') {
    if (iscompany) {
      formattedRole = i18n.t('user_profile.company_super_admin')
    } else formattedRole = i18n.t('user_profile.wenroll_super_admin')
  } else if (role === 'ADMIN') {
    if (iscompany) {
      formattedRole = i18n.t('user_profile.company_admin')
    } else formattedRole = i18n.t('user_profile.wenroll_admin')
  } else if (role === 'COACH') {
    formattedRole = i18n.t('user_profile.coach')
  } else if (role === 'STUDENT') {
    formattedRole = i18n.t('user_profile.student')
  } else formattedRole = role
  return formattedRole
}
