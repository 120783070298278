import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import SchoolIcon from '@material-ui/icons/School'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import Checkbox from 'components/common/Checkbox'
import { mainColor } from 'components/common/Colors/CommonColors'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import { CapitalPlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import strCutter from 'utils/strCutter'
import { generateFullName } from 'utils/utils'
import { StatusCircle, StatusWrapper } from '../CompanyStudents/gridConfig'

interface Props {
  handleClick?: () => string | object
}

interface itemProps {
  name: string
  description: string
  location: string
  isChecked?: boolean
  id: string
  last?: string
  numberOfStudents?: number | string
  admin?: any
  created: string
  company: any
  courses: any
  status: any
  companyId: any
}

export const columnConfig = (
  handelClick: any,
  selectItem: any,
  checkbox?: any,
  t?: any,
) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <div style={{ visibility: !checkbox ? 'hidden' : 'visible' }}>
          <Checkbox
            checked={item.isChecked}
            onChange={() => selectItem(item.id)}
          />
        </div>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('general.name'),
      render: (item: itemProps) => (
        <div onClick={() => handelClick(item.id)} style={{ cursor: 'pointer' }}>
          {strCutter(item.name, 30)}
        </div>
      ),
    },
    {
      id: 'admin',
      numeric: true,
      disablePadding: false,
      label: t('general.admin'),
      render: (item: itemProps) => {
        return <div>{strCutter(generateFullName(item.admin), 30)}</div>
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: t('general.status'),
      render: (item: itemProps) => {
        return (
          <StatusWrapper className={`${item.status.toLowerCase()}`}>
            <StatusCircle />
            {item.status}
          </StatusWrapper>
        )
      },
    },
    {
      id: 'numberOfStudents',
      numeric: true,
      disablePadding: false,
      label: t('general.students'),
      render: (item: itemProps) => <div>{item.numberOfStudents || 0}</div>,
    },
    {
      id: 'courses',
      numeric: false,
      disablePadding: false,
      label: t('general.courses'),
      render: (item: itemProps) => {
        const firstTwoCourses = item.courses.slice(0, 2)
        const exCourses = item.courses.slice(2, item.courses.length)
        return (
          <div>
            {firstTwoCourses.length === 0 && (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
            <GroupsWrapper>
              {firstTwoCourses.map(
                (course: any, index: number) =>
                  index < 3 && (
                    <GroupItem key={course.courseId}>
                      <IconButton>
                        <SchoolIcon fontSize="small" />
                      </IconButton>
                      <GroupTitle className="group-item-title">
                        {course.name}
                      </GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exCourses.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exCourses.map((exCourse: any) => (
                        <div>{exCourse.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: itemProps) => (
        <div>
          {item.company && item.company.name ? (
            strCutter(item.company.name, 25)
          ) : (
            <>
              {CapitalPlatformTitle}
              <StarsRoundedIcon
                style={{
                  color: mainColor,
                  fontSize: 10,
                  verticalAlign: 'super',
                }}
              />
            </>
          )}
        </div>
      ),
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: itemProps) => {
        return (
          <StatusWrapper>
            <StatusCircle />
            {item.created ? (
              moment(parseInt(item.created)).format('DD.MM.YYYY')
            ) : (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
          </StatusWrapper>
        )
      },
    },
  ]

  return grid
}

export const actionConfig = (handleClick: any, t: any) => {
  const action = [
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('edit', item)}>
          <EditRoundedIcon />
          <Typography>{t('actions.edit')}</Typography>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('delete', item.id)}>
          <DeleteForeverRoundedIcon />
          <Typography>{t('actions.delete')}</Typography>
        </Action>
      ),
    },
  ]
  return action.filter((i: any) => !i.hide)
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`

const GroupsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const GroupItem = styled.div`
  position: relative;
  &:hover > span {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
`

const GroupTitle = styled.span`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  text-align: center;
  padding: 5px 8px;
  bottom: -35px;
  left: 50%;
  color: #333;
  font-size: 12px;
  transform: translateX(-50%) scale(0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.5px 12px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease, transform 0.2s ease;
`
