import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import axios from 'axios'
import { CircularProgress } from "@material-ui/core";
import VideoPlayer from "components/common/VideoPlayer";
import { ISkillTest } from "../personalhomework";
import config from '../../../../../config/index'

interface ISkillvideofileProps {
  currentSkilltestIndex: number
  chosenSkilltest?: ISkillTest
  onConvertionStatusChange: Dispatch<SetStateAction<true | null>>
  isSkillVideoConverted: true | null
}

const ConvertedVideo = ({ currentSkilltestIndex, chosenSkilltest, onConvertionStatusChange, isSkillVideoConverted }: ISkillvideofileProps) => {
  const [checkedSkilltestsIds, setCheckedSkilltestsIds] = useState<string[]>([]);

  const videoConversionLoader = (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '50px 0 60px' }}>
      <CircularProgress size={50} color="primary" />
    </div>
  )

  const convertedSkilltestVideo = (
    <div className='w-full mt-5'>
      <VideoPlayer url={chosenSkilltest?.file.link} width={1000} isInDrawer={true} />
    </div>
  )

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    if (!chosenSkilltest || chosenSkilltest.file.type !== 'mp4') {
      return
    };

    const isConverted = checkedSkilltestsIds.find((id: string) => id === chosenSkilltest.id) || null;
    if (isConverted) {
      onConvertionStatusChange(true)
      return;
    };

    const checkVideoConversionStatus = async (videoLink: string): Promise<any> => {
      try {
        const response = await axios.post(`${config.APIURL}/videos/check-video-conversion-status`, {
          videoLink
        }, {
          cancelToken: source.token,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })

        if (response.data) {
          setCheckedSkilltestsIds((prevState: string[]) => [...prevState, chosenSkilltest.id])
          onConvertionStatusChange(true)
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('caught error')
        } else {
          console.log('error', error)
        }
      }
    };

    checkVideoConversionStatus(chosenSkilltest.file.link);

    return () => {
      source.cancel()
    };
  }, [currentSkilltestIndex])

  if (!chosenSkilltest || chosenSkilltest?.file.type !== 'mp4') return null
  return isSkillVideoConverted ? convertedSkilltestVideo : videoConversionLoader;
};

export default ConvertedVideo;
