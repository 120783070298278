import React, { useState, useEffect } from 'react'
import { gql } from 'graphql.macro'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useFormik } from 'formik'
import { useCreateQuestion } from 'hooks/questions/useCreateQuestion'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useData } from 'context/DataContext'
import { SET_FORMDATA } from 'store/types'
import { useTranslation } from 'react-i18next'

import { useUploadImage } from 'hooks/helpers/useHelpersService'

import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import Tooltip from '@material-ui/core/Tooltip'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import HelpIcon from '@material-ui/icons/Help'
import { useUserValue } from 'context/UserContext'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  CloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import {
  ModuleItemWrapper,
  ModuleItem,
} from 'pages/questions/styled-components'
import Loader from 'components/common/Loader'
import { Wrapper, Modules } from '../styled-components'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import Drawer from 'components/common/Drawer'
import QuestionItem from './QuestionItem'
import AddModuleToQuestionDrawer from './AddModuleToQuestionDrawer'
import UploadContent from './UploadContent'
import { QuestionSchema } from 'helpers/validationSchemas'
import { QuestionTypes } from '../staticData'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { SelectField } from 'components/common/SelectField'
import { useTheme } from '@material-ui/core'
interface Params {
  title: string
  currentModule: any
}

const GET_TAGS = gql`
  query {
    getAllTags {
      id
      label
    }
  }
`

// const constants = {
//   image: 'image/jpeg|image/jpg|image/png',
//   video:
//     'video/x-msvideo|video/mpeg|video/ogg|video/mp4|video/x-flv|video/quicktime|video/x-msvideo|video/x-ms-wmv',
//   audio: 'audio/mpeg|audio/mpeg|audio/aac|audio/mp4a-latm',
//   file:
//     'application/pdf|application/msword|application/plain|text/plain|image/png|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel|application/vnd.openxmlformats-officedocument.wordprocessingml.document',
// }

const CreateQuestionDrawer = ({ title, currentModule }: Params) => {
  const { t } = useTranslation()
  const { createQuestion, createQuestionLoading } = useCreateQuestion({
    type: currentModule.moduleId ? 'module' : '',
    id: currentModule.moduleId,
    filter: {},
  })



  const { data } = useQuery(GET_TAGS)

  const tagList =
    (data &&
      data.getAllTags.map((i: any) => ({ label: i.label, value: i.id }))) ||
    []
  const [state, dispatch] = useData()
  const [userState] = useUserValue()
  const [linkModuleDrawerOpened, setLinkModuleDrawerOpened] = useState<boolean>(
    false,
  )

  const [boolAnswer, setBoolAnswer] = useState<boolean>(true)
  const [choiceAnswers, setChoiceAnswers] = useState<any>([])
  const [multiAnswers, setMultiAnswers] = useState<any>([])
  const [selectedChoiceAnswer, setSelectedChoiceAnswer] = useState<any>(null)
  const [selectedMultiAnswers, setSelectedMultiAnswers] = useState<any>([])
  const [selectedModules, setSelectedModules] = useState<any>([])
  const [cropperOpen, setCropperOpen] = useState<boolean>(false)
  const [cropperImage, setCropperImage] = useState<any>('')
  const [uploadedFiles, setUploadedFiles] = useState<any>([])
  const [file] = useState<any>(null)

  const { uploadImage, imageLoading } = useUploadImage()

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      question: '',
      type: {
        label: t('question_details.boolean'),
        value: 'boolean',
      },
      tags: [],
      reason: '',
      // images: [],
      // documents: [],
      // videos: [],
      // audios: [],
    },
    validationSchema: QuestionSchema,
    onSubmit(values) {
      let formValues: any = { ...values }

      if (createQuestionLoading) return

      if (selectedModules.length > 0) {
        formValues.modules = selectedModules
      }

      if (values.type.value === 'boolean') {
        formValues.boolAnswer = boolAnswer
      }

      if (values.type.value === 'single') {
        formValues.answers = choiceAnswers.map((answer: any, index: number) => {
          return {
            isCorrect: Number(selectedChoiceAnswer) === index ? true : false,
            value: answer.value,
          }
        })
      }

      if (values.type.value === 'multiple') {
        // console.log('multiAnswers::', multiAnswers)

        formValues.answers = multiAnswers.map((answer: any, index: number) => {
          return {
            isCorrect: !!selectedMultiAnswers.includes(index),
            value: answer.value,
          }
        })

        // console.log('formValues.answers:', formValues.answers)
      }

      formValues.tags = values.tags.map((tag: any) => {
        return { label: tag.label }
      })

      if (uploadedFiles.length > 0) {
        let obj: any = { documents: [], videos: [], audios: [], images: [] }

        uploadedFiles.map((item: any) => {
          const { type, ...args } = item

          if (type) {
            obj[type] = [...obj[type], args]
          }
        })

        formValues = {
          ...formValues,
          ...obj,
        }
      }
      formValues.videos = formValues.videos
        ? formValues.videos.map((video: any) => {
          return { id: video.id, title: video.title }
        })
        : []
      createQuestion(
        formValues,
        currentModule.manualCompanyId || userState.selectedCompanyId,
        () => {
          closeDrawer()
          if (currentModule.refetch) {
            currentModule.refetch()
          }
        },
        currentModule.moduleId,
        currentModule.name,
      )
    },
  })

  const closeDrawer = () => {
    DrawerEventEmitter.emit('openDrawer', 'createQuestion', false)
  }

  const checkAnswers = (type: string) => {
    if (type === 'boolean') {
      return true
    }

    if (type === 'single') {
      const emptyValues = choiceAnswers.filter(
        (answer: any) => answer.value === '',
      )
      if (choiceAnswers.length > 0 && emptyValues.length === 0) {
        return true
      } else {
        return false
      }
    }

    if (type === 'multiple') {
      const emptyValues = multiAnswers.filter(
        (answer: any) => answer.value === '',
      )
      if (multiAnswers.length > 0 && emptyValues.length === 0) {
        return true
      } else {
        return false
      }
    }
  }

  const checkSelectedAnswers = (type: string) => {
    if (type === 'boolean') {
      return true
    }

    if (type === 'single') {
      if (selectedChoiceAnswer === null) {
        return false
      } else {
        return true
      }
    }

    if (type === 'multiple') {
      if (selectedMultiAnswers.length < 2) {
        return false
      } else {
        return true
      }
    }
  }

  const handleTypeChange = (e: any) => {
    setFieldValue('type', e)
  }

  const handleBoolChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const convertedValue = value === 'true' ? true : false
    setBoolAnswer(convertedValue)
  }

  const handleAddAnswerClick = (type: string) => {
    if (type === 'single') {
      const updatedChoiceAnswers: any = [...choiceAnswers, { value: '' }]
      setChoiceAnswers(updatedChoiceAnswers)
    }

    if (type === 'multiple') {
      const updatedMultiAnswers: any = [...multiAnswers, { value: '' }]
      setMultiAnswers(updatedMultiAnswers)
    }
  }

  const handleRemoveAnswerClick = (type: string, index: number) => {
    if (type === 'single') {
      const updatedChoiceAnswers: any = [...choiceAnswers]
      updatedChoiceAnswers.splice(index, 1)
      setChoiceAnswers(updatedChoiceAnswers)

      if (index === Number(selectedChoiceAnswer)) {
        setSelectedChoiceAnswer(null)
      }
    }

    if (type === 'multiple') {
      const updatedMultiAnswers: any = [...multiAnswers]
      updatedMultiAnswers.splice(index, 1)
      setMultiAnswers(updatedMultiAnswers)

      if (selectedMultiAnswers.includes(index.toString())) {
        const ind = selectedMultiAnswers.indexOf(index.toString())
        const updatedMultiAnswers = [...selectedMultiAnswers]
        updatedMultiAnswers.splice(ind, 1)
        setSelectedMultiAnswers(updatedMultiAnswers)
      }
    }
  }

  const handleAnswerChange = (e: any, type: string, index: number) => {
    const { value } = e.target

    if (type === 'single') {
      const updatedChoiceAnswers: any = [...choiceAnswers]
      updatedChoiceAnswers[index].value = value
      setChoiceAnswers(updatedChoiceAnswers)
    }

    if (type === 'multiple') {
      const updatedMultiAnswers: any = [...multiAnswers]
      updatedMultiAnswers[index].value = value
      setMultiAnswers(updatedMultiAnswers)
    }

    // const isAnswersEmpty = checkAnswers(type)
    // if (isAnswersEmpty) {
    //   setIsValid(false)
    // } else {
    //   setIsValid(true)
    // }
  }

  // const handleRemoveModuleClick = (moduleId: string) => {
  //   const updatedSelectedModules = selectedModules.filter(
  //     (selectedModule: any) => selectedModule.moduleId !== moduleId,
  //   )

  //   setSelectedModules(updatedSelectedModules)
  // }

  const handleSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    if (type === 'single') {
      const { value } = e.target
      setSelectedChoiceAnswer(value)
    }

    if (type === 'multiple') {
      const value = e
      const index = selectedMultiAnswers.indexOf(value)
      if (index > -1) {
        const updatedMultiAnswers = [...selectedMultiAnswers]
        updatedMultiAnswers.splice(index, 1)
        setSelectedMultiAnswers(updatedMultiAnswers)
      } else {
        setSelectedMultiAnswers([...selectedMultiAnswers, value])
      }
    }
  }

  const cropperModalToggle = () => {
    setCropperOpen(!cropperOpen)
  }

  const handleCropSave = (field: string, croppedFile: string) => {
    setCropperImage('')
    const index = uploadedFiles.length
    setUploadedFiles([
      ...uploadedFiles,
      {
        type: 'image',
        link: '',
        uploading: true,
      },
    ])
    uploadImage(croppedFile, (field = 'questions/images'), (link: string) => {
      const updatedFiles = [...uploadedFiles]
      updatedFiles[index] = {
        type: 'image',
        link,
        uploading: false,
      }
      setUploadedFiles(updatedFiles)
    })
  }

  useEffect(() => {

    if (currentModule.moduleId) {
      const { manualCompanyId, ...moduleData } = currentModule
      setSelectedModules([moduleData])

    }
  }, [currentModule])

  useEffect(() => {
    if (file) {
      // console.log('file', file)
      setUploadedFiles([...uploadedFiles, file])
    }
  }, [file])

  // const handleContentChange = async (e: any) => {
  //   const { files } = e.target

  //   let resolvedFile: any = []

  //   const promises = Array.from(files).map((item: any, index: any) => {

  //     if (constants.file.includes(item.type)) {
  //       const uploadFiles = uploadFile(item, 'questions/documents', (link: string) => {
  //         const updatedFiles: any = {
  //           type: 'document',
  //           fileType: item.name.split('.').slice(-1)[0],
  //           name: item.name.split('.')[0],
  //           link,
  //           uploading: false,
  //         }

  //         setFile(updatedFiles)
  //       })

  //       return uploadFiles
  //     }

  //   })

  //   await Promise.all(promises)

  // }

  const handleCloseDrawer = () => {
    const { type, ...formData }: any = values
    formData.multiAnswers = multiAnswers
    formData.choiceAnswers = choiceAnswers
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'createQuestion',
        values: formData,
        compareTo: {},
      },
    })
  }
  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  const fieldsValid = checkAnswers(values.type.value)
  const selectedValid = checkSelectedAnswers(values.type.value)

  const submitBtnDisabled = !values.question || !fieldsValid || !selectedValid
  const theme = useTheme()
  return (
    <>
      {createQuestionLoading && <Loader />}
      <Drawer
        opened={linkModuleDrawerOpened}
        toggleDrawer={() => setLinkModuleDrawerOpened(false)}
        totalWidth="650px"
      >
        <AddModuleToQuestionDrawer
          title="Link Modules to the Question"
          closeDrawer={() => setLinkModuleDrawerOpened(false)}
          selectedModules={selectedModules}
          setSelectedModules={setSelectedModules}
        />
      </Drawer>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{title}</DrawerTitle>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent>
        <FormContainer>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                {/* <label onClick={() => console.log('uploadedFiles', file)}>
                  {t('general.type')}*
                </label> */}
                <SelectField
                  onChange={handleTypeChange}
                  options={QuestionTypes}
                  value={values.type}
                  placeholder={t('general.type')}
                />
              </FormField>
              <FormField>
                <CreatableSelect
                  isMulti
                  onChange={(e: any) => {
                    setFieldValue('tags', e)
                  }}
                  options={tagList}
                  value={values.tags}
                  placeholder={t('general.tags')}
                />
              </FormField>
              <FormField style={{ display: 'flex' }}>
                <UploadContent
                  setUploadedFiles={setUploadedFiles}
                  uploadedFiles={uploadedFiles}
                  currentModule={currentModule.moduleId}
                />

                {/* <HelpButtonContainer>
                  <Tooltip
                    title={
                      <span style={{ fontSize: '14px' }}>
                        {t('actions.upload_content_helper')}
                      </span>
                    }
                    arrow
                    color={mainColor}
                  >
                    <HelpIcon color="primary" />
                  </Tooltip>
                </HelpButtonContainer> */}
              </FormField>
              <FormField>
                <TextInput
                  error={errors.question ? true : false}
                  errorMessage={errors.question ? errors.question : ''}
                  touched={touched.question}
                  label={`${t('general.question')}*`}
                  size="medium"
                  name="question"
                  type="text"
                  value={values.question}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows="2"
                />
              </FormField>
              <FormField>
                <QuestionItem
                  type={values.type.value}
                  boolAnswer={boolAnswer}
                  choiceAnswers={choiceAnswers}
                  multiAnswers={multiAnswers}
                  selectedChoiceAnswer={selectedChoiceAnswer}
                  selectedMultiAnswers={selectedMultiAnswers}
                  handleSelect={handleSelect}
                  onBoolChange={handleBoolChange}
                  handleAddAnswerClick={handleAddAnswerClick}
                  handleRemoveAnswerClick={handleRemoveAnswerClick}
                  handleAnswerChange={handleAnswerChange}
                  fieldsValid={fieldsValid}
                  selectedValid={selectedValid}
                />
              </FormField>
              <FormField>
                <TextInput
                  label={t('question_details.reason')}
                  size="medium"
                  name="reason"
                  type="text"
                  value={values.reason}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows="3"
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            {currentModule.moduleId && (
              <ModuleWrapper>
                <Wrapper>
                  <article style={{ marginRight: 5 }}>
                    {' '}
                    {t('question_details.linked_to_modules')}:
                  </article>
                  {currentModule.moduleId && (
                    <Modules>
                      <ModuleItemWrapper>
                        <ModuleItem>{currentModule.name}</ModuleItem>
                      </ModuleItemWrapper>
                    </Modules>
                  )}
                </Wrapper>
              </ModuleWrapper>
            )}
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              ></Button>
              <Button
                text={t('actions.save')}
                type="medium"
                color="secondary"
                onClick={handleSubmit}
                isDisabled={submitBtnDisabled}
                background={'#317BF4'}
                buttonStyles={{
                  borderRadius: '8px',
                }}
              ></Button>
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
      <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
        <ImageCropper
          file={cropperImage}
          hideCropper={cropperModalToggle}
          getCroppedFile={handleCropSave}
        />
      </Modal>
    </>
  )
}

export default CreateQuestionDrawer

const ModuleWrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  svg {
    color: #d2364b;
    cursor: pointer;
  }
`

const HelpButtonContainer = styled.div`
  width: 43px;
  display: flex;
  align-items: center;
  padding: 0 0px 0 14px;
  svg {
    cursor: pointer;
  }
`
