import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Select, { components } from 'react-select'

const customStyles: any = {
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: '99',
  }),
}

const CoursesAsyncSelect = (props: any) => {
  const [options, setOptions] = useState([] as any)
  useEffect(() => {
    const localOptions = props.data.map((i: any) => {
      let customId = i.isBought && !!i.originalId ? 'originalId' : 'id'
      return {
        label: props.labelDataKey
          ? i[props.labelDataKey]
          : props.labelDataName(i),
        value: i[customId],
        icon: i[props.iconDataKey],
      }
    })
    setOptions(localOptions)
  }, [props.data])

  const { Option } = components
  const CustomSelectOption = (props: any) => (
    <Option {...props}>
      {props.data.label}
      {props.data.icon}
    </Option>
  )

  const CustomSelectValue = (props: any) => (
    <div>
      {props.data.label}
      {props.data.icon}
    </div>
  )

  return (
    <Container error={props.error && props.touched}>
      <Select
        classNamePrefix={'react-select'}
        isLoading={props.loading}
        isMulti={props.isMulti}
        options={options}
        value={props.value}
        onChange={props.onChange}
        onInputChange={props.onInputChange}
        placeholder={props.label}
        isDisabled={props.disabled}
        isClearable={props.isClearable}
        styles={customStyles}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
        }}
      />

      {props.touched && props.error && props.errorMessage && (
        <ErrorMessage>{props.errorMessage}</ErrorMessage>
      )}
    </Container>
  )
}

export default CoursesAsyncSelect

const Container = styled.div<{ error: boolean }>`
  width: 100%;
  position: relative;
  .react-select {
    &__control {
      border-color: ${({ error }) => (error ? '#f44336' : '#080A0A14')};
      outline: none;
      height: 56px;
      border-radius: 8px;
    }

    .__single-value {
      color: #080a0a;
      font-size: 16px;
      font-weight: 500;
    }

    &__indicator-separator {
      display: none;
    }
  }
  .css-1wa3eu0-placeholder {
    color: ${({ error }) => (error ? '#f44336' : 'rgba(0, 0, 0, 0.54) ')};
  }
`

const ErrorMessage = styled.div`
  position: absolute;
  color: #f44336;
  bottom: -20px;
  left: 6px;
  font-size: 0.75rem;
`
