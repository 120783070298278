import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { FormFields, FormField } from 'components/common/Form/Form'
import styled from 'styled-components'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import { UserSchema } from 'helpers/validationSchemas'
import { useFormik } from 'formik'
import useEditUserService from 'hooks/users/useEditUserService'
import { SelectField } from 'components/common/SelectField'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import DatePicker from 'components/common/DatePicker'
import { AvatarInput } from 'components/common/AvatarInput/AvatarInput'
import { genderOptions } from 'components/UsersLayout/staticData'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import GenderPicker from 'components/common/GenderPicker'

interface Props {
  item: any
  onCancel: any
  t?: any
}

interface Manager {
  id?: string
  firstName: string
  lastName: string
  email: string
  phone: string
  phoneFields: any
  jobTitle: string
  gender: string
  location: string
  phoneFieldValue?: any
  birthDate?: any
  biography: string
  note: string
  avatar: any
}

const formData: Manager = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  phoneFields: null,
  jobTitle: '',
  gender: '',
  location: '',
  phoneFieldValue: null,
  birthDate: null,
  biography: '',
  note: '',
  avatar: '',
}

const EditSuperAdmin = (props: Props) => {
  const { item, onCancel, t } = props
  const [generalError, setGeneralError] = useState('')

  const { updateUser, user, error, editUserLoading } = useEditUserService({
    role: 'SUPER_ADMIN',
  })

  const {
    handleSubmit,
    handleChange,
    resetForm,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: UserSchema,
    onSubmit(values) {
      const formValues: any = { ...values }
      delete formValues.phoneFieldValue

      if (formValues.gender) {
        formValues.gender = formValues?.gender || null
      }
      if (formValues.phoneFields && formValues.phoneFields.value) {
        formValues.phoneFields = {
          dialCode: formValues.phoneFields.value,
          code: formValues.phoneFields.label,
        }
      } else {
        formValues.phoneFields = null
      }
      updateUser(item.id, formValues, onCancel)
    },
  })

  useEffect(() => {
    setGeneralError('')
  }, [values.email])

  useEffect(() => {
    if (error) {
      setGeneralError(error)
    }
  }, [error])

  useEffect(() => {
    let data: any = {
      firstName: item?.firstName || '',
      lastName: item?.lastName || '',
      email: item?.email || '',
      phone: item?.phone || '',
      phoneFields: item?.phoneFields || null,
      jobTitle: item?.jobTitle || '',
      gender: item?.gender || '',
      location: item?.location || '',
      phoneFieldValue: item?.phoneFieldValue || null,
      birthDate: item?.birthDate || null,
      biography: item?.biography || '',
      note: item?.note || '',
      avatar: item?.avatar || '',
    }
    const { gender, ...args } = formData
    // for (const key in args) {
    //   if (data.hasOwnProperty([key])) {
    //     data[key] = item[key]
    //   }
    // } //TODO: have ts errors

    if (item?.gender) {
      data.gender = item.gender
    }


    if (item?.birthDate) {
      data.birthDate = moment(parseInt(item.birthDate)).format()
    }

    setValues({ ...data })
  }, [item])

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields, values.phoneFieldValue])

  const [currentDate, setCurrentDate] = useState<Date | null>(null)

  const onDateChange = (date: Date | null) => {
    // setCurrentDate(date)
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  useEffect(() => {
    if (values.birthDate) {
      setCurrentDate(
        new Date(
          typeof (values.birthDate === 'string')
            ? values.birthDate
            : +values.birthDate,
        ),
      )
    }
  }, [values.birthDate])

  return (
    <Container>
      <FormFields>
        <FormField>
          <AvatarInput
            setFieldValue={setFieldValue}
            initialAvatar={values.avatar}
          />
        </FormField>

        <FormField width="48%">
          <TextInput
            touched={touched?.firstName}
            errorMessage={errors?.firstName}
            error={!!errors?.firstName}
            label={`${t('user_details.first_name')}*`}
            name="firstName"
            size="small"
            type="text"
            value={values?.firstName}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField width="48%">
          <TextInput
            touched={touched.lastName}
            errorMessage={errors.lastName}
            error={!!errors.lastName}
            label={`${t('user_details.last_name')}*`}
            name="lastName"
            size="small"
            type="text"
            value={values.lastName}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField width="48%">
          <TextInput
            error={!!errors.email}
            errorMessage={errors.email}
            touched={touched.email}
            label={`${t('form_fields.email')}*`}
            name="email"
            size="small"
            type="text"
            value={values.email}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField width="48%">
          <PhoneCountryCode
            label={t('user_details.phone')}
            selectLabel={t('form_fields.dial_code')}
            selectValue={values.phoneFields}
            selectChange={(e: any) => setFieldValue('phoneFields', e)}
            inputLabel={t('user_details.phone')}
            inputValue={values.phone}
            inputChange={handleChange}
            error={
              errors.phone || errors.phoneFields || generalError ? true : false
            }
            errorMessage={
              errors.phone || errors.phoneFields || generalError
                ? errors.phone || errors.phoneFields || generalError
                : ''
            }
            touched={touched.phone || touched.phoneFields}
          />
        </FormField>

        <FormField width="48%">
          {/* <SelectField
            placeholder={t('user_details.gender')}
            options={genderOptions}
            value={
              !!values?.gender?.value &&
              genderOptions?.filter(
                (GenderInGeorgian: any) =>
                  GenderInGeorgian?.value === values?.gender?.value,
              )
            }
            onChange={(e: any) => setFieldValue('gender', e)}
            isClearable
          /> */}
          <GenderPicker
            checked={values.gender}
            onChange={(e) => {
              setFieldValue('gender', e)
            }} />
        </FormField>

        <FormField width="48%">
          <DatePicker
            label={`${t('user_details.birthday')}`}
            name="birthDate"
            value={currentDate}
            onChange={onDateChange}
          />
        </FormField>
        <FormField width="48%">
          <TextInput
            label={t('form_fields.job_title')}
            size="small"
            name="jobTitle"
            type="text"
            value={values.jobTitle}
            onChange={handleChange}
          />
        </FormField>
        <FormField width="48%">
          <TextInput
            label={t('form_fields.location')}
            size="small"
            name="location"
            type="text"
            value={values.location}
            onChange={handleChange}
          />
        </FormField>
        <FormField width="100%">
          <TextInput
            label={t('user_details.biography')}
            size="small"
            name="biography"
            type="text"
            value={values.biography}
            onChange={handleChange}
            multiline
            rows="3"
          />
        </FormField>
        <FormField width="100%">
          <TextInput
            label={t('user_details.note')}
            size="small"
            name="note"
            type="text"
            value={values.note}
            onChange={handleChange}
            multiline
            rows="3"
          />
        </FormField>

        <FormField>
          <ButtonContainer>
            <ButtonItem style={{ marginRight: '10px' }}>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={onCancel}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
            </ButtonItem>

            <ButtonItem>
              <Button
                text={t('actions.save')}
                type="medium"
                color="secondary"
                onClick={handleSubmit}
                isDisabled={editUserLoading}
                background={mainColor}
              />
            </ButtonItem>
          </ButtonContainer>
        </FormField>
      </FormFields>
    </Container>
  )
}

export default EditSuperAdmin

const Container = styled.div`
  box-sizing: border-box;
  padding: 0px 30px;
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const ButtonItem = styled.div``
