import gql from 'graphql-tag'

export const GET_PROGRESS_BY_USER_ID = gql`
  query($userId: String!) {
      getProgressByUserId(userId: $userId) {
      courses {
        askReview
        courseId
        progress
        watchDuration
        lessons {
          watchDuration,
          lessonId,
          progress
        }
        modules {
          watchDuration,
          moduleId,
          progress
        }
      }
    }
    }
`
