import React, { useRef, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import {
  FormGroupItem,
  InputGroup,
  FormGroupWrapper,
  FormContainer,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import { useData } from 'context/DataContext'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import { AsyncSelect } from 'components/common/SelectField'
import { generateFullName } from 'utils/utils'
import { GET_USERS } from 'gql/users.query'
import { useUserValue } from 'context/UserContext'
import { SET_CREATED_USER } from 'store/types'
import { AddAdmin } from 'pages/admins'
import Drawer from 'components/common/Drawer'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'

const Step1 = (props: any) => {
  const [state] = useUserValue()
  const [dataState, dispatch] = useData()
  const [drawer, setDrawer] = useState({
    addAdmin: false,
    args: {},
  })
  const ref: any = useRef()

  const variables = { role: 'ADMIN' }

  const [fetchAdmin, { data: adminData, loading: adminLoading }] = useLazyQuery(
    GET_USERS,
  )

  useEffect(() => {
    if (
      dataState.createdObject.obj &&
      dataState.createdObject.type === 'admin'
    ) {
      props.setFieldValue('admin', {
        value: dataState.createdObject.obj.id,
        label: `${dataState.createdObject.obj.firstName} ${dataState.createdObject.obj.lastName}`,
      })

      dispatch({
        type: SET_CREATED_USER,
        payload: { obj: null, type: '' },
      })
    }
  }, [dataState.createdObject.obj])

  const loadDataOptions = (e: any) =>
    setTimeout(() => {
      const value = e
      fetchAdmin({
        variables: {
          ...variables,
          filter: {
            search: {
              type: 'search',
              value,
              fields: ['firstName', 'lastName'],
            },
            companyId: {
              type: 'exact',
              value: props.manualCompanyId
                ? props.manualCompanyId
                : state.selectedCompanyId,
            },
          },
        },
      })
    }, 200)

  const handleSelectChange = (e: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e)
    }
  }

  return (
    <FormContainer>
      <FormGroupWrapper>
        <FormGroupItem>
          <InputGroup>
            <Input
              error={!!props.errors.name}
              errorMessage={props.errors.name ? props.errors.name : ''}
              touched={props.touched.name}
              label={props.t('form_fields.name')}
              name="name"
              size="medium"
              type="text"
              fullWidth
              value={props.values.name}
              onChange={props.handleChange}
            />

            <Input
              label={props.t('form_fields.location')}
              name="location"
              size="medium"
              type="text"
              fullWidth
              value={props.values.location}
              onChange={props.handleChange}
            />
          </InputGroup>
        </FormGroupItem>

        <FormGroupItem style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '100%' }}>
            <AsyncSelect
              onInputChange={handleSelectChange}
              onChange={(e: any) => props.setFieldValue('admin', e)}
              data={(adminData && adminData.usersByRole.data) || []}
              value={props.values.admin}
              label={props.t('form_fields.type_to_search_admin')}
              loading={adminLoading}
              labelDataName={generateFullName}
              valueDataKey="id"
              error={!!props.errors.admin}
              touched={props.touched.admin}
              errorMessage={props.errors.admin ? props.errors.admin : ''}
            />
          </div>

          <div style={{ paddingLeft: '15px' }}>
            <Button
              text={props.t('actions.create')}
              type="medium"
              background={mainColor}
              onClick={() => {
                setDrawer({
                  ...drawer,
                  addAdmin: true,
                  args: {
                    onSuccess: () => { },
                  },
                })
              }}
            />
          </div>
        </FormGroupItem>

        <FormGroupItem>
          <Input
            label={props.t('form_fields.note')}
            name="note"
            size="medium"
            type="text"
            fullWidth
            multiline
            rows="4"
            value={props.values.note}
            onChange={props.handleChange}
          />
        </FormGroupItem>
      </FormGroupWrapper>
      <FormFooter>
        <FormButtons multiCols={true}>
          <Button
            text={props.t('actions.cancel')}
            type="medium"
            onClick={props.closeDrawer}
            background="#E0E1E2"
            textColor={validationRed}
            outline={`1px solid ${red}`}
          />

          <Button
            text={props.t('actions.next')}
            color="secondary"
            type="medium"
            background={'#317BF4'}
            buttonStyles={{
              borderRadius: '8px',
            }}
            onClick={props.handleSubmit}
          // onClick={() => props.handleChangeStep(1)}
          />
        </FormButtons>
      </FormFooter>

      <Drawer
        opened={drawer.addAdmin}
        toggleDrawer={() => { }}
        totalWidth="940px"
      >
        <AddAdmin
          title={props.t('admin_drawer_layout.create_admin')}
          role="ADMIN"
          drawerName="addAdmin"
          drawerData={drawer.args}
          onClose={() => setDrawer({ addAdmin: false, args: {} })}
        />
      </Drawer>
    </FormContainer>
  )
}

export default Step1
