import React from 'react'
import styled from 'styled-components'
import { mainColor } from '../Colors/CommonColors'

interface UploaderButtonI {
  type?: string
  onChange?: any
  id?: string
  accept?: string
  loading?: any
  label?: string
}

const UploaderButton = ({
  type,
  onChange,
  id,
  accept,
  loading,
  label,
}: UploaderButtonI) => {
  return (
    <FileUploadButton>
      <input type="file" onChange={onChange} id={id || 'id'} accept={accept} />
      {loading ? (
        'Loading...'
      ) : (
        <InputLabel htmlFor={id}>
          {/* <AddAPhotoIcon fontSize="small" /> */}
          <span>{label}</span>
        </InputLabel>
      )}
    </FileUploadButton>
  )
}

export default UploaderButton

const FileUploadButton = styled.div`
  input {
    opacity: 0;
    width: 1px;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    overflow: hidden;
  }
`
const InputLabel = styled.label`
  position: absolute;
  top: 11px;
  right: 11px;
  cursor: pointer;
  color: #fff;
  background: #5290f6;
  font-size: 11px;
  padding: 3px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
`
