import {
  DetailsContentSkillsWrapper,
  DetailsContentSkilsTitle,
  DetailsContentSkilsList,
  DetailsContentSkillItem,
} from 'components/common/DetailsLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Skills: React.FC<{ skills: any }> = ({ skills }) => {
  const { t } = useTranslation()
  return (
    <DetailsContentSkillsWrapper>
      <DetailsContentSkilsTitle>{t('general.skills')}</DetailsContentSkilsTitle>
      <DetailsContentSkilsList>
        {skills &&
          skills.map((skill: any) => (
            <DetailsContentSkillItem id={skill.id} key={skill.id}>
              {skill.label}
            </DetailsContentSkillItem>
          ))}
      </DetailsContentSkilsList>
    </DetailsContentSkillsWrapper>
  )
}
export default Skills
