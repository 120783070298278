import { LinearProgress } from '@material-ui/core'
import Icon from 'components/Icon'
import React from 'react'
import { Tabs, Tab } from '@material-ui/core'
import styled, { css, CSSProperties } from 'styled-components'

export interface TextProps {
  variant?: 'title'
  fs?: CSSProperties['fontSize']
  weight?: CSSProperties['fontWeight']
  color?: CSSProperties['color']
  margin?: CSSProperties['margin']
  mt?: CSSProperties['marginTop']
  mr?: CSSProperties['marginRight']
  mb?: CSSProperties['marginBottom']
  ml?: CSSProperties['marginLeft']
}

export const HeaderText = styled.h1<TextProps>(props => {
  return css`
    font-size: ${props.fs || '14px'};
    color: ${props.color || '#000000'};
    font-weight: ${props.weight || 'normal'};
    margin: ${props.margin || '0'};
    margin-top: ${props.mt || '0'};
    margin-right: ${props.mr || '0'};
    margin-bottom: ${props.mb || '0'};
    margin-left: ${props.ml || '0'};
    ${() => {
      switch (props.variant) {
        case 'title':
          return css`
            font-size: 24px;
            color: #080a0ad6;
            // font-weight: 600;
          `
      }
    }}
  `
})

export const Text = styled.div<TextProps>(props => {
  return css`
    font-size: ${props.fs || '14px'};
    color: ${props.color || '#000000'};
    font-weight: ${props.weight || 'normal'};
    margin: ${props.margin || '0'};
    margin-top: ${props.mt || '0'};
    margin-right: ${props.mr || '0'};
    margin-bottom: ${props.mb || '0'};
    margin-left: ${props.ml || '0'};
    ${() => {
      switch (props.variant) {
        case 'title':
          return css`
            font-size: 24px;
            color: #080a0ad6;
            font-weight: 600;
          `
      }
    }}
  `
})

// Header
export const Container = styled.div`
  display: flex;
  flex-direction: column;

`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px){
    flex-direction : column-reverse;
    align-items: unset;
  }
`

export const CourseInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export interface AuthorProps {
  image: string
  name: string
}
export const Author = styled.div.attrs<AuthorProps>(props => ({
  children: (
    <>
      <div></div>
      <span>{props.name}</span>
    </>
  ),
})) <AuthorProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 10px;

  & > div {
    width: 48px;
    height: 48px;
    border: 1px solid #080a0a0a;
    border-radius: 16px;
    background-color: #080a0a0a;
    cursor: pointer;
    ${({ image }) =>
    image &&
    css`
        background-image: ${`url(${image})`};
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      `}
  }

  & > span {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 16px;
  }
`

export const CourseVideo = styled.div.attrs<{ hasVideo?: boolean }>(props => ({
  children: props?.hasVideo && (
    <Icon
      filename={'play'}
      fill={'#ffffff'}
      pointerEvents={'none'}
      style={{ zIndex: 1 }}
    />
  ),
})) <{ image?: string; hasVideo?: boolean }>`
  position: relative;
  width: 470px;
  height: 264px;
  background-color: #317bf4;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 768px){
      width: 100%
  }

  ${({ image }) =>
    image &&
    css`
      background-image: ${`url(${image})`};
      background-position: center;
      background-size: 100% 100%;
      margin-left: 10px;
      @media (max-width: 768px){
        background-size: cover;
        margin-bottom: 20px;
        margin-left: 0;
    }

    `}

  ${({ hasVideo }) =>
    hasVideo &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;
        background: #080a0a5c;
        border-radius: 16px;
        pointer-events: none;
      }
    `}
`

export const SButton = styled.button<{ variant?: 'primary' | 'secondary' }>`
  padding: 16px 24px;
  font-size: 16px;
  border: none;
  outline: none;
  font-weight: 600;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  ${({ variant }) => {
    switch (variant) {
      case 'secondary':
        return css`
          background: #080a0a0a;
          color: #080a0a;
          svg {
            fill: #080a0a;
          }
        `
      default:
        return css`
          background: #317bf4;
          color: #ffffff;
          svg {
            fill: #ffffff;
          }
        `
    }
  }}
`

export const SLinearProgress = styled(LinearProgress)`
  &.MuiLinearProgress,
  .MuiLinearProgress {
    &-root {
      border-radius: 10px;
      background: #080a0a12;
      height: 6px;
    }
    &-bar {
      border-radius: 10px;
      background: #317bf4;
    }
  }
`

// Content
export const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;

  .MuiTabs-vertical {
    min-width: max-content;
  }

  & > .MuiTypography-root {
    padding-left: 80px;
    width: 100%;
    @media (max-width: 1315px) {
      padding-left: 0;
     }
    @media (max-width: 1030px) {
      padding-left: 0;
      padding-top: 0;

    }
    @media (max-width: 600px) {
    margin-top: 20px;
    }
  }
  @media (max-width: 600px) {
   flex-direction: column;
 }
`

export const QuizButton = styled.button`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 321px;
  height: 60px;
  border: 1px solid #080a0a0a;
  border-radius: 16px;
  padding: 0px 20px;
  background: transparent;
  color: #080a0a;
  font-size: 14px;
  margin: 20px 0px;
  cursor: pointer;
  font-weight: 600;
  svg {
    width: 20px;
    height: 24px;
    path {
      fill: #000000;
    }
  }
`

export const STabs = styled(Tabs)``

export const STab = styled(Tab).attrs<{ label: React.ReactNode }>(props => ({
  label: (
    <>
      {props.label}
      <Icon filename={'right_arrow'} />
    </>
  ),
}))`
  &.MuiTab,
  .MuiTab {
    &-root {
      min-width: 321px;
      padding: 20px;
      border-radius: 16px;
      border-bottom: 1px solid #080a0a0a;
      @media (max-width: 958px) {
        min-width: 230px;
      }
      @media (max-width: 880px) {
        min-width: 170px;
      }
    }
    &-wrapper {
      flex-direction: unset;
      justify-content: space-between;
      font-size: 14px;
      color: #080a0a;
      // font-weight: 600;

      svg {
        width: 8px;
        height: 13px;
        path {
          fill: #080a0a5c;
        }
      }
    }
  }

  &.Mui-selected {
    background: #080a0a0a;
    border-color: transparent;

    .MuiTab {
      &-wrapper {
        color: #080a0a;
        svg {
          path {
            fill: #080a0ad6;
          }
        }
      }
    }
  }
`
