import React from 'react'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'

import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import { CheckBoxAndAvatarContainer } from 'pages/companyWizard/CompanyStudents/gridConfig'
import Checkbox from 'components/common/Checkbox'

interface itemProps {
  id: string
  isChecked: boolean
  name: string
  lessons: any
  coverImage: any
  numberOfTopics: any
  numberOfLessons: any
  duration: any
}

export const columnConfig = (handelClick: any, selectItem: any, t?: any, theme?: any) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={item.isChecked}
            onChange={() => selectItem(item.id)}
          />
          <img
            onClick={() => handelClick(item.id)}
            src={item.coverImage.link || ''}
            alt="Avatar"
          />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'courseName',
      numeric: false,
      disablePadding: true,
      center: true,
      label: `${t('general.courseName')}`,
      render: (item: itemProps) => (
        <div onClick={() => handelClick(item.id)} style={{ cursor: 'pointer' }}>
          {item.name}
        </div>
      ),
    },
    {
      id: 'topic',
      label: `${t('module_card.topic')}`,
      render: (item: itemProps) => (
        <CountContainer theme={theme}>{item.numberOfTopics}</CountContainer>
      ),
    },
    {
      id: 'lessions',
      label: `${t('general.lessons')}`,
      render: (item: itemProps) => (
        <CountContainer theme={theme}>{item.numberOfLessons}</CountContainer>
      ),
    },
    // {
    //   id: 'price',
    //   label: 'ფასი',
    //   render: (item: itemProps) => item.duration,
    // },
    // {
    //   id: 'status',
    //   numeric: false,
    //   disablePadding: false,
    //   label: `${t('general.status')}`,
    //   render: (item: itemProps) => (
    //     <StatusWrapper className={'DRAFT'}>DRAFT</StatusWrapper>
    //   ),
    // },
  ]

  return grid
}

export const nestedColumConfig = () => {
  let grid = [
    {
      numeric: false,
      disablePadding: true,
      id: 'video',
      render: (item: itemProps) => (
        <IconButton>
          <PlayCircleOutlineIcon fontSize="small" />
        </IconButton>
      ),
    },
    {
      numeric: false,
      disablePadding: true,
      label: 'Name',
      id: 'name',
      render: (item: itemProps) => <div>{item.name}</div>,
    },
  ]

  return grid
}

export const actionConfig = (
  actionHandler: any,
  permissions: any,
  t: any,
  canModify?: boolean,
) => {
  let action = [
    {
      render: (item: any) => (
        <Action onClick={() => actionHandler('view', item.id)}>
          <ViewCompactIcon />
          <span>{t('actions.view')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('edit', item)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('delete', item)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify,
      render: (item: any) => (
        <Action
          onClick={() => actionHandler('pdf', item)}
          className={item.attachment ? '' : 'disabled'}
        >
          <CloudDownloadIcon />
          <span>{t('actions.download_attachment')}</span>
        </Action>
      ),
    },
  ]

  return action.filter((i: any) => !i.hide)
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  width: max-content;
  height: max-content;
  padding: 5px 10px;
  padding-bottom: 2px;
  border-radius: 4px;

  &.active {
    color: #269f5a;
    background: #269f5a1f;
  }

  &.suspended {
    color: #cc3131;
    background: #cc31311f;
  }

  &.pending {
    color: #ffa502;
    background: #ffa5021f;
  }

  &.draft {
    color: rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.151);
  }
`

const StatusCircle = styled.div`
  margin-right: 5px;
  margin-top: -3px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
`

const CountContainer = styled.div`
  width: 36px;
  height: 36px;
  background: #818181;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ffffff
`
