import { useState } from 'react'
import addFlashCardService from 'services/modules/addFlashCardService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useAddFlashCardService = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { addFlashCardMutation } = addFlashCardService(params)

  const addFlashCard = async (moduleId: string, input: any, onSuccess: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await addFlashCardMutation({
      variables: {
        moduleId,
        input,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return false
      }
      variant = 'error'
    } else {

      onSuccess()
      message = `${t('general.flash_card')} ${t('messages.added')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    addFlashCard,
    addFlashCardLoading: loading,
    error,
  }
}

export default useAddFlashCardService
