import { useState } from 'react'
import addCompanyService from 'services/companies/addCompanyService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useAddCompanyService = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const { addCompanyMutation, loading, error } = addCompanyService(params)
  const [data, setData] = useState('')

  const addCompany = async (
    input: any,
    coursesIds: string[],
    callBack: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    const { data, errors } = await addCompanyMutation({
      variables: { input, coursesIds },
    })
    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      data && setData(data)
      message = `${t('general.company')} ${t('messages.added')}`
      variant = 'success'
      callBack(data.addCompany)
    }

    setSnackbar({ message, variant })
  }

  return {
    addCompany,
    data,
    loading,
    error,
  }
}

export default useAddCompanyService
