import { useMutation, useQuery } from '@apollo/react-hooks'
import { useTheme } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import BusinessIcon from '@material-ui/icons/Business'
import EmailIcon from '@material-ui/icons/Email'
import EventIcon from '@material-ui/icons/Event'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PersonIcon from '@material-ui/icons/Person'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined'
import { mainColor } from 'components/common/Colors/CommonColors'
import { CapitalPlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'
import TextEditor from 'components/common/TextEditor/TextEditor'
import Icon from 'components/Icon'
import { genderOptions } from 'components/UsersLayout/staticData'
import { useUserValue } from 'context/UserContext'
import { convertFromRaw, convertToRaw } from 'draft-js'
import { GET_USER, UPDATE_USER } from 'gql/users.query'
import moment from 'moment'
import { NoValueWrapper } from 'pages/companies/CompanyDetails/styled-components'
import React, { useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import BioDrawer from './drawers/BioDrawer'
import EducationItem from './EducationItem'
import strCutter from 'utils/strCutter'

export interface IPersonalInformation {
  createDate: Date
  email: string
  phone?: string
  gender?: 'male' | 'female' | 'prefer not to say'
  birthDate?: Date | null
  jobTitle?: string
  location?: string
  firstName?: string
  lastName?: string
  role?: string
  companyId: {
    name: string
    id: string
  }
  biography?: string
  note?: string
  status?: string
  id: string
  age?: number
  phoneFields?: any
  isCompanyAdmin?: boolean
  isSuperAdmin?: boolean
  phoneFieldValue?: string | null | undefined
  avatar: any
  group?: any[]
  course?: any[]
}

const iconStyle = {
  marginRight: 10,
  color: mainColor,
}

const PersonalInformation: React.FC<{
  user: IPersonalInformation
  t: any
  updateProfile?: (a: boolean) => void
  updateDescription?: (a: boolean) => void
  setEducationListOpen?: (open: boolean) => void
  setExperienceListOpen?: (open: boolean) => void
}> = ({
  user,
  t,
  updateProfile,
  updateDescription,
  setEducationListOpen,
  setExperienceListOpen,
}) => {
    const { id } = useParams<{ id: string }>()

    const [isGeneralInformationVisible, setGeneralInformationVisible] = useState(
      true,
    )
    const [isNoteVisible, setNoteVisible] = useState(true)
    const [isBiographyVisible, setIsBiographyVisible] = useState(true)
    const [isExperienceVisible, setIsExperienceVisible] = useState(true)
    const [isEducationVisibile, setIsEducationVisibile] = useState(true)
    const [state] = useUserValue()
    const isStudent = state?.currentUser?.role === 'STUDENT'
    const canEdit =
      state?.currentUser?.id === id || state?.userPermission?.editStudent

    const age = user.age
    const iscompany = user.companyId && user.companyId.id && user.companyId.name

    const [bio, setBio] = useState("")
    const [editMode, setEditMode] = useState(false);





    const { data } = useQuery(GET_USER, {
      variables: {
        id,
      }
    })





    const gender =
      user.gender && user.gender[0].toUpperCase() + user.gender.slice(1)

    const genderInLocalLanguage = genderOptions?.filter(
      (GenderInGeorgian: any) =>
        GenderInGeorgian?.value === gender?.toLowerCase(),
    )
    const phoneCode =
      user?.phoneFields?.code && user.phoneFields.code.indexOf('+') > -1
        ? user.phoneFields.code
        : user?.phoneFields?.dialCode

    const theme = useTheme();


    const grid: {
      id: number
      icon: JSX.Element
      label: string
    }[] = [
        {
          id: 0,
          icon: (
            <IconContainer>
              <Icon color={theme.palette.text.primary} filename={'call'} />
            </IconContainer>
          ),
          label: `${user.phone != "0" ? user.phone : ""}`,
        },
        {
          id: 1,
          icon: (
            <IconContainer>
              <Icon color={theme.palette.text.primary} filename={'message'} />
            </IconContainer>
          ),
          label: `${user.email ? user.email : 'N/A'}`,
        },
        {
          id: 2,
          icon: (
            <IconContainer>
              <Icon color={theme.palette.text.primary} filename={'location'} />
            </IconContainer>
          ),
          label: `${user.location ||
            ReactDOMServer.renderToString(<NoValueWrapper>N/A</NoValueWrapper>)}`,
        },
        {
          id: 3,
          icon: (
            <IconContainer>
              <Icon color={theme.palette.text.primary} filename={'building'} />
            </IconContainer>
          ),
          label: `${!iscompany ? CapitalPlatformTitle : user?.companyId?.name} ${!iscompany
            ? ReactDOMServer.renderToString(
              <StarsRoundedIcon
                style={{
                  color: mainColor,
                  fontSize: 10,
                  verticalAlign: 'super',
                }}
              />,
            )
            : ''
            }`,
        },
        {
          id: 4,
          icon: (
            <IconContainer>
              <Icon color={theme.palette.text.primary} filename={'document'} />
            </IconContainer>
          ),
          label: `${user.jobTitle ||
            ReactDOMServer.renderToString(<NoValueWrapper>N/A</NoValueWrapper>)}`,
        },
        {
          id: 5,
          icon: (
            <IconContainer>
              <Icon color={theme.palette.text.primary} filename={'calendar'} />
            </IconContainer>
          ),
          label: `${user.birthDate
            ? moment(new Date(+user.birthDate)).format('DD.MM.YYYY')
            : ReactDOMServer.renderToString(<NoValueWrapper>N/A</NoValueWrapper>)
            }`,
        },
      ]



    return (
      <PersonalInformationContainer>
        <InfoSection>
          <PersonalInformationHeader theme={theme}>
            <h1>
              {t('user_details.personal_information')}
            </h1>
            {canEdit && (
              <Icon
                filename={'edit'}
                fill={theme.palette.text.primary}
                width={17}
                height={16}
                onClick={() => updateProfile?.(true)}
              />
            )}
          </PersonalInformationHeader>
          {isGeneralInformationVisible && (
            <ContainerContent>
              <ContenGrid>
                {grid.map((i: any, index) => (
                  <ContenGridItem key={i.id}>
                    {i.icon}

                    <div style={{
                      color: theme.palette.text.primary,
                    }} dangerouslySetInnerHTML={{ __html: index === 2 || index === 4 || index === 0 ? strCutter(i.label, 30) : i.label }} />
                  </ContenGridItem>
                ))}

              </ContenGrid>
            </ContainerContent>
          )}
        </InfoSection>

        <InfoSection>
          <PersonalInformationHeader theme={theme}>
            <h1>
              {t('general.experience')}
            </h1>
            {canEdit && (
              <Icon
                filename={'edit'}
                fill={theme.palette.text.primary}
                width={17}
                height={16}
                onClick={() => setExperienceListOpen?.(true)}
              />
            )}
            <div
              style={{ marginLeft: 'auto' }}
              onClick={() => setIsExperienceVisible(!isExperienceVisible)}
            >
              <Icon
                filename={'down_arrow'}
                width={16}
                height={9}
                fill={theme.palette.text.primary}
                cursor={'pointer'}
                style={{
                  marginLeft: 'auto',
                  transition: '0.3s',
                  transform: `rotate(${isExperienceVisible ? 0 : 180}deg)`,
                }}
                onClick={() => setIsExperienceVisible(!isExperienceVisible)}
              />
            </div>
          </PersonalInformationHeader>
          {isExperienceVisible && (
            <ContainerContent style={{ display: 'flex', gap: '10px' }}>
              {data?.user && data.user.experience.map((exp: any, index: number) => {
                return <EducationItem
                  id={index.toString()}
                  title={exp.title}
                  status={exp.position}
                  date={exp.date}
                />
              })}

            </ContainerContent>
          )}
        </InfoSection>

        <InfoSection>
          <PersonalInformationHeader theme={theme}>
            <h1>
              {t('general.education')}
            </h1>
            {canEdit && (
              <Icon
                filename={'edit'}
                fill={theme.palette.text.primary}
                width={17}
                height={16}
                onClick={() => setEducationListOpen?.(true)}
              />
            )}
            <div
              style={{ marginLeft: 'auto' }}
              onClick={() => setIsEducationVisibile(!isEducationVisibile)}
            >
              <Icon
                filename={'down_arrow'}
                width={16}
                height={9}
                fill={theme.palette.text.primary}
                cursor={'pointer'}
                style={{
                  transition: '0.3s',
                  transform: `rotate(${isEducationVisibile ? 0 : 180}deg)`,
                }}
              />
            </div>
          </PersonalInformationHeader >
          {isEducationVisibile && (
            <ContainerContent style={{ display: 'flex', gap: '10px' }}>
              {data?.user && data.user.education.map((edu: any, index: number) => {
                return <EducationItem
                  id={index.toString()}
                  title={edu.title}
                  status={edu.position}
                  date={edu.date}
                />
              })}
            </ContainerContent>
          )}
        </InfoSection>
        {/* <PersonalInformationHeader onClick={() => setBiographyVisible(x => !x)}>
        {!isBiographyVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        {t('user_details.biography')}
      </PersonalInformationHeader>
      {isBiographyVisible && (
        <TextWrapper
          dangerouslySetInnerHTML={{ __html: user.biography || '' }}
        />
      )} */}

        <InfoSection>
          <PersonalInformationHeader theme={theme}>
            <h1>
              {t('user_details.biography')}
            </h1>
            {canEdit && (
              <Icon
                filename={'edit'}
                fill={theme.palette.text.primary}
                width={17}
                height={16}
                onClick={() => setEditMode(true)}
              />
            )}
            <div
              style={{ marginLeft: 'auto' }}
              onClick={() => setIsBiographyVisible(!isBiographyVisible)}
            >
              <Icon
                filename={'down_arrow'}
                width={16}
                height={9}
                fill={theme.palette.text.primary}
                cursor={'pointer'}
                style={{
                  transition: '0.3s',
                  transform: `rotate(${isBiographyVisible ? 0 : 180}deg)`,
                }}
              />
            </div>
          </PersonalInformationHeader >
          {isBiographyVisible && (
            <BiographyContainer>
              <div className="biographyEdit">
              </div>
              {/* <div className="biographyTitle">{t('user_details.biography')}</div> */}
              {/* <div className="biographyText">{user.biography && convertToRaw(JSON.parse(user.biography))}</div> */}
              <TextEditor
                text={user.biography}
                canEdit={false}
                hasControls={false}
                isVisible={false}
              />
            </BiographyContainer>
          )}
          {editMode && (
            <BioDrawer
              open={editMode}
              onClose={() => setEditMode(false)}
            />
          )}
        </InfoSection>

        {/* {!isStudent && (
        <PersonalInformationHeader onClick={() => setNoteVisible(x => !x)}>
          {!isNoteVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          {t('user_details.note')}
        </PersonalInformationHeader>
      )} */}

        {isNoteVisible && !isStudent && (
          <TextWrapper dangerouslySetInnerHTML={{ __html: user.note || '' }} />
        )}
      </PersonalInformationContainer>
    )
  }

export const PersonalInformationHeader = styled.div`
  font-size: 24px;
  color: ${props => props.theme.palette.text.primary};
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  display: flex;
  gap: 14px;
  width: 100%;
`
export const Line = styled.div`
  opacity: 0.72;
  border-bottom: 2px solid #707070;
`

export const InfoSection = styled.section`
  margin: 25px 0px;
`

const PersonalInformationContainer = styled.div`
  color: #989898;
`

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  color: #414141;
`

const GridContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  width: 80%;
  margin-bottom: 60px;
`

const TextWrapper = styled.div`
  text-align: left;
  font-size: 16px;
  color: #414141;
  margin: 20px 0;
`

const GridWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: #080a0a0a;
  border-radius: 12px;

  svg {
    width: 17px;
    height: 17px;
    fill: #080a0ad6;
  }
`

export const ContainerContent = styled.div`
  padding: 30px 20px;
`
export const ContenGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
`
export const ContenGridItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  color: #080a0ad6;
  font-size: 14px;
`

export const BiographyContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 15px 30px #0000001a;
  border-radius: 16px;
  width: 100%;
  padding: 30px 100px 0px 100px;
  /* padding-bottom: 50px; */
  position: relative;

  .DraftEditor-root {
    height: auto !important;
    overflow-y: auto;
  }

  .biographyTitle {
    font-size: 24px;
    color: #080a0ad6;
    font-weight: 600;
    margin: auto;
    text-align: center;
    margin-bottom: 20px;
  }

  .biographyText {
    font-size: 16px;
    color: #080a0a8a;
  }

  .biographyEdit {
    position: absolute;
    top: 37px;
    right: 55px;
    cursor: pointer;
  }
`

const InfoWrapper = styled.div`
  border: 2px solid #ededed;
  border-radius: 10px;
  margin-top: 100px;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 500px) {
    display: none;
  }
`

export default PersonalInformation
