import React, { useState, useEffect, useRef } from 'react'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import createToolbarPlugin from 'draft-js-static-toolbar-plugin'
import { useTranslation } from 'react-i18next'
import * as S from './styled'
import {
  Container,
  ContainerHeader,
  EditorContainer,
  ScrollView,
  EditorActions,
  EditorActionsEditButton,
  EditorActionsEditText,
  EditorEditActions,
} from './styled-components'
import { Button } from 'components/common/Button'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Icon from 'components/Icon'

import Controls from './Controls'
import { linkPlugin, linkifyPlugin } from './Plugins'
import { mainColor, validationRed, red } from '../Colors/CommonColors'
import { Box, useTheme } from '@material-ui/core'




const TextEditor = ({
  textEditorStyle = {},
  text = '',
  isVisible = true,
  biography = false,
  hasControls = false,
  editorTitle = '',
  closeEditMode = () => { },
  openEditMode = () => { },
  onSave = (editorText, plainText) => { },
  canEdit = false,
  style = {},
}) => {
  const { t } = useTranslation()
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  )
  const [scrollViewVisible, setScrollViewVisible] = useState(false)

  const [{ plugins, Toolbar }] = useState(() => {
    const createToolbar = createToolbarPlugin()
    const { Toolbar } = createToolbar
    const plugins = [createToolbar, linkPlugin, linkifyPlugin]
    return {
      plugins,
      Toolbar,
    }
  })


  const editorRef = useRef(null)

  const convertToString = currentState => {
    const convertedRaw = convertToRaw(currentState)
    const stringified = JSON.stringify(convertedRaw)
    return stringified
  }

  const convertToEditorState = jsonState => {
    const rawContent = JSON.parse(jsonState)

    const contentState = convertFromRaw(rawContent)
    const newState = EditorState.createWithContent(contentState)
    return newState
  }

  const scrollToTop = () => {
    const editorDiv = document.querySelector('.DraftEditor-root')
    editorDiv.scrollTo(0, 0)
  }

  const handleCancelClick = () => {
    // const newState = convertToEditorState(text)
    // setEditorState(newState)
    scrollToTop()
    closeEditMode()
  }

  const handleSaveClick = async () => {
    const editorText = convertToString(editorState.getCurrentContent())
    const plainText = editorState.getCurrentContent().getPlainText('\u0001')

    onSave(editorText, plainText)
  }

  const handleEditorScroll = () => {
    const editorDiv = document.querySelector('.DraftEditor-root')
    const editorHeight = editorDiv.scrollHeight
    const extraHeight = editorHeight - 300
    const editorScrollTop = editorDiv.scrollTop
    if (editorScrollTop > extraHeight / 2) {
      setScrollViewVisible(false)
    } else {
      setScrollViewVisible(true)
    }
  }

  useEffect(() => {
    const editorDiv = document.querySelector('.DraftEditor-root')

    const editorHeight = editorDiv.scrollHeight
    const editorScrollTop = editorDiv.scrollTop

    if (editorHeight > 300 && editorScrollTop < 20) {
      setScrollViewVisible(true)
    } else {
      setScrollViewVisible(false)
    }

    editorDiv.addEventListener('scroll', handleEditorScroll)

    return () => editorDiv.removeEventListener('scroll', handleEditorScroll)
  }, [editorState])

  useEffect(() => {
    if (text) {
      setTimeout(() => {
        const newState = convertToEditorState(text)
        setEditorState(newState)
      }, 0)
    }
  }, [text])

  const isEmpty = !editorState.getCurrentContent().hasText()

  const theme = useTheme()

  return (
    <Container style={style}>
      {hasControls && (
        <ContainerHeader>
          <h1 style={{ fontSize: '24px', fontWeight: 'bold' }}>
            {editorTitle && editorTitle}
          </h1>
          <EditorActions>
            {isVisible && (
              <EditorEditActions className={isVisible ? 'show' : ''}>
                <Button
                  text={t('actions.cancel')}
                  size="small"
                  onClick={handleCancelClick}
                  background="#E0E1E2"
                  textColor={validationRed}
                  outline={`1px solid ${red}`}
                />

              </EditorEditActions>
            )}
            {canEdit && (
              <EditorActionsEditButton>
                {/* <IconButton
                  disabled={isVisible}
                  onClick={() => {
                    openEditMode()
                    editorRef.current && editorRef.current.focus()
                  }}
                >
                  <Icon filename={'edit'} fill={theme.palette.text.primary} />
                </IconButton> */}

                <EditorActionsEditText>
                  {t('actions.edit')}
                </EditorActionsEditText>
              </EditorActionsEditButton>
            )}
          </EditorActions>
        </ContainerHeader>
      )}
      <EditorContainer biography className={isVisible ? 'show' : ''}>
        <S.TextEditor style={textEditorStyle} scrollViewVisible biography isVisible={isVisible} onClick={() => editorRef.current.focus()}>
          {isEmpty && !isVisible && (
            <p
              style={{
                opacity: 0.5,
                fontStyle: 'italic',
                position: 'absolute',
                top: style?.top ? style.top : 10,
                margin: 0,
              }}
            >
              {t('general.nothing_display')}
            </p>
          )}
          {isVisible && (
            <S.ToolbarBox>
              <Toolbar>
                {externalProps => {
                  return <Controls externalProps={externalProps} />
                }}
              </Toolbar>
            </S.ToolbarBox>
          )}
          <Editor
            editorState={editorState}
            onChange={setEditorState}
            readOnly={!isVisible}
            plugins={plugins}
            ref={editor => (editorRef.current = editor)}
          />
        </S.TextEditor>

      </EditorContainer>

      <Box style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
      }}>
        {isVisible && <Button
          text={t('actions.save')}
          type="medium"
          color="secondary"
          btnType="submit"
          onClick={handleSaveClick}
          background={mainColor}
        />}
      </Box>
      {scrollViewVisible && !isVisible && (
        <ScrollView>
          <p>{t('general.scroll_down_to_view')}</p>
        </ScrollView>
      )}
    </Container>
  )
}

export default TextEditor
