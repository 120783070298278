import 'date-fns'
import React from 'react'
import styled from 'styled-components'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormikTouched } from 'formik'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datepicker: {
      marginTop: 0,
      width: '100%',
      height: '100%',
    },
  }),
)

interface Props {
  error?: boolean
  errorMessage?: any
  name?: string
  label?: string
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[]
  value?: Date | string | null
  onChange?: (date: Date | null) => void
  styles?: React.CSSProperties
}

const DatePicker = ({
  touched,
  error,
  errorMessage,
  name = '',
  label = '',
  value = '',
  styles,
  onChange = () => { },
}: Props) => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const theme = useTheme();
  // console.log('classes::', classes)
  return (
    <DatePickerWrapper theme={theme} className={`${error && touched ? 'error' : ''}`}>
      <SIcon onClick={() => setOpen(true)}>
        <Icon filename={'calendar'} />
      </SIcon>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.datepicker}
          margin="normal"
          id="date-picker-dialog"
          label={label || 'select'}
          format="dd.MM.yyyy"
          placeholder="DD.MM.YYYY"
          open={open}
          onClose={() => setOpen(false)}
          value={value || null}
          onChange={onChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          style={styles}
          error={error && touched ? true : false}
          invalidDateMessage=""
          keyboardIcon={false}
        />
      </MuiPickersUtilsProvider>
      <p>{error && touched && errorMessage}</p>
    </DatePickerWrapper>
  )
}

export default DatePicker

const DatePickerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 56px;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid #080a0a14;
  border-radius: 8px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &.error label {
    color: #f44336;
  }

  .MuiInput-underline:before {
    border-bottom: none;
  }

  .MuiInput-underline:hover:before {
    border-bottom: none;
  }

  div > p,
  > p {
    position: absolute;
    bottom: -26px;
    color: #f44336;
    font-size: 0.75rem;
  }
`

export const SIcon = styled.div`
  min-width: 18px;
  height: 20px;
  cursor: pointer;
  svg {
    fill: #797a7a;
  }
`

// const ErrorMessage = styled.p`
//   position: absolute;
//   bottom: -26px;
//   color: #f44336;
//   font-size: 0.75rem;
// `
