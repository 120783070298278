import React from 'react'
import styled from 'styled-components'
import BackupIcon from '@material-ui/icons/Backup'

const Step1 = (props: any) => {
  const { t } = props
  return (
    <>
      <TemplateButton>
        <div
          onClick={() =>
            (window.location.href =
              'https://ib-assets-public.s3.eu-west-2.amazonaws.com/Template.xlsx')
          }
        >
          <BackupIcon className="rotate" />
          <span>{t('actions.download_template')}</span>
        </div>
      </TemplateButton>

      <input type="file" onChange={props.handleFileChange} />
    </>
  )
}

export default Step1

const TemplateButton = styled.div`
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-bottom: 20px;
  div {
    color: #555;
    padding: 0;
    line-height: 40px;
    width: 300px;
    margin: auto;
    display: block;
    border: 2px solid #555;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      color: lighten(#555, 20%);
      border-color: lighten(#555, 20%);
    }
    display: flex;
    justify-content: center;
    align-items: center;
    .rotate {
      transform: rotate(180deg);
    }
    span {
      padding: 0 10px;
    }
  }
`
