import { TextField } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { Button } from "components/common/Button";
import Drawer from "components/common/Drawer";
import Uploader from "components/common/FileUploader/Uploader";
import Modal from "components/common/Modal";
import { CloseIcon, Info } from "components/common/Modal/Modal";
import { Input } from "components/common/TextInput";
import VideoPlayer from "components/common/VideoPlayer/Player";
import { DrawerContent, DrawerHeader, DrawerTitle } from "components/DrawerLayout/styled-components";
import Icon from "components/Icon";
import { useUserValue } from "context/UserContext";
import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import { Text } from '../styled'
import CoachPersonal from "./Drawer/CoachPersonal";
import StudentInfoPersonal from "./Drawer/StudentInfoPersonal";
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from "react-router-dom"

export interface ISkillTestReview {
  id: string,
  ratedId: string,
  createDate: string,
  rate: number,
  description: string,
  // attachedAudio: string,
  attachedFile: {
    type: string,
    name: string,
    link: string
  }
  author: {
    userId: string,
    firstName: string,
    lastName: string,
    avatar: string,
    role: string
  }
}
export interface ISkillTest {
  id: string,
  status: string,
  file: {
    name: string,
    type: string,
    link: string,
  },
  description: string,
  reviews: ISkillTestReview[],
  sentForReview: {
    id: string,
    userId: string,
    firstName: string,
    lastName: string,
    role: string,
    rate: number
  }[],
  averageRating: number,
  version: number,
  createDate: Date,
  module: {
    id: string,
    name: string
  },
  course: {
    id: string,
    name: string
  }
}


interface PersonalHomeworkProps {
  skillTest: ISkillTest[],
  moduleName: string,
  moduleId?: string,
  courseName: string,
  assignment?: any,
  isModalOpenen?: any,
  setIsModalOpened?: any,
  refetch: () => void
}

const PersonalHomework: FC<PersonalHomeworkProps> = ({ refetch, skillTest, moduleName, courseName, moduleId, assignment, setIsModalOpened, isModalOpenen }) => {

  // const [isModalOpenen, setIsModalOpened] = useState(false);
  const [state] = useUserValue()
  const { t } = useTranslation()
  const history = useHistory()
  const params: any = useParams()
  const handleModalClose = () => {

    setIsModalOpened(false);
    setShowVersions(false);
    history.push(`/course/${params.id}`)
  }

  // for opening modal, in which we see Skilltests' versions
  const [showVersions, setShowVersions] = useState(false);



  useEffect(() => {
    if (params.assignment === "assignment") {
      setShowVersions(true)
    }
  }, [params.assignment])

  // The value will be Skilltests filtered by module (we should filter them by module for students);
  let renderedSkilltests: ISkillTest[] = skillTest;

  if (moduleId) {
    renderedSkilltests = skillTest.filter(skill => skill.module.id === moduleId);
  }


  const lastSkilltest = renderedSkilltests[renderedSkilltests.length - 1];

  let wasReviewedByCoach: boolean = false;

  // This logic is usefull, because there is only one coach, who can review SkillTestVideo - group's coach;
  if (renderedSkilltests.length > 0) {
    const { reviews } = lastSkilltest;
    const isAuthorCoach = reviews.find(r => r.author.role === 'COACH');
    if (isAuthorCoach) {

      wasReviewedByCoach = true;
    }
  }


  const dateConvert = (timestamp: Date) => {
    const date = new Date(timestamp);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}  ${date.getHours()}:${date.getMinutes()}`
  }


  return (
    <PersonalHomeworkContainer>
      <PersonalHomeworkHeader>
        <Text fs={'16px'} weight={'bold'}>{t("general.personal_assignment")}</Text>
      </PersonalHomeworkHeader>
      {renderedSkilltests.length > 0 && (
        <div style={{ display: 'flex' }}>
          <Icon filename={'personalHW'} />
          <PersonalHomeworkInfo>
            <PersonalHomeworkLeftSide>
              <Text fs={'16px'}>{t('general.titleIndividually')}</Text>
              <Text
                fs={'12px'}
                color={'#080A0A54'}>

                {/* {renderedSkilltests[0]?.sentForReview[1]?.firstName} {renderedSkilltests[0]?.sentForReview[1]?.lastName} */}
              </Text>
              {/* <Text fs={'12px'} color={'#CC3131'}>{lastSkilltest.status === "PENDING" && "მოლოდინის რეჟიმში"}</Text> */}
              <Text fs={'12px'} color={'#CC3131'}>{!wasReviewedByCoach && t('general._loading')}</Text>
            </PersonalHomeworkLeftSide>
            <PersonalHomeworkRightSide>
              <Button
                text={t('general.view')}
                background={'#080A0A08'}
                textColor={'#080A0A'}
                size={'medium'}
                buttonStyles={{
                  borderRadius: '16px'
                }}
                onClick={() => setShowVersions(true)}
              />
              <Text fs={'16px'} color={'#080A0A54'}>{t('general.version')} {lastSkilltest.version}</Text>
              <Text fs={'16px'} color={'#080A0A54'}>{dateConvert(lastSkilltest.createDate)}</Text>
            </PersonalHomeworkRightSide>
          </PersonalHomeworkInfo>
        </div>
      )}

      <Modal isOpened={showVersions} onClose={handleModalClose}>
        <Drawer
          opened={showVersions}
          toggleDrawer={handleModalClose}
          totalWidth="970px">
          {state.currentUser.role === "COACH" ?
            <CoachPersonal
              courseName={courseName}
              moduleName={moduleName}
              skillTests={renderedSkilltests}
              refetch={refetch}
              onModalClose={handleModalClose} />
            :
            <StudentInfoPersonal
              courseName={courseName}
              moduleName={moduleName}
              // renderedSkilltestss={renderedSkilltests}
              skillTests={renderedSkilltests}
              refetch={refetch}
              onModalClose={handleModalClose} />}
        </Drawer>
      </Modal>
    </PersonalHomeworkContainer >
  );
}

export default PersonalHomework;

const PersonalHomeworkContainer = styled.div`
  width: 100%;
  height: 220px;
  background: #fff;
  border: 1px solid #080A0A04;
  border-radius: 16px;
  padding: 20px 30px;
  margin: 20px 0;
`

const PersonalHomeworkHeader = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const PersonalHomeworkInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  justify-content: space-between;
`

const PersonalHomeworkLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  div {
    margin-bottom: 10px;
  }
`

const PersonalHomeworkRightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;

  div {
    margin-bottom: 20px;
  }
`

