import React from "react";
const VideosInnapropriate = () => {
  return (
    <div>
      <h1>Innapropriate videos page</h1>
    </div>
  );
};

export default VideosInnapropriate;
