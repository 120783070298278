import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { CLEAR_FORMDATA } from 'store/types'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'

import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import Grid from 'components/common/Grid'
import StudentListDrawer from './StudentListDrawer'
import Search from 'components/common/Search'

import Drawer from 'components/common/Drawer'
import ImportStudentsDrawer from '../ImportStudents/ImportStudentsDrawer'

import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import IconButton from '@material-ui/core/IconButton'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useData } from 'context/DataContext'
import { SET_CREATED_USER } from 'store/types'
import { AddStudent } from 'pages/students'

import { columnConfig } from '../GroupStudents/gridConfig'
import { mainColor } from 'components/common/Colors/CommonColors'
import SelectBtn from 'components/common/SelectBtn'

const studentActionConfig = (handleClick: any, t: any) => {
  let action = [
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('create')}>
          <AddCircleRoundedIcon />
          <span>{t('actions.add_student')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('addFromDb')}>
          <PlaylistAddIcon />
          <span>{t('actions.add_students_to_group')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('import')}>
          <GetAppIcon />
          <span>{t('actions.import_students')}</span>
        </Action>
      ),
    },
  ]

  return action
}

const AddStudentsInNewGroup = (props: any) => {
  const { t } = useTranslation()
  const [dataState, dispatch] = useData()
  const [formData, setFormData] = useState<any>('')
  const [selectedStudents, setSelectedStudents] = useState<any>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const { fireSwal } = useSwal()

  const [studentsDrawer, SetStudentsDrawer] = useState({
    import: false,
    addFromDb: false,
    create: false,
  })

  const toggleDrawer = (field: string, value: boolean) => {
    SetStudentsDrawer({
      ...studentsDrawer,
      [field]: value,
    })
  }

  const selectItem = (id: string) => {}

  const importedStudents = (items: any) => {
    const arr: any = items.filter((i: any) => !i.error)
    props.handleSetStudents(arr)
  }

  const config = columnConfig(selectItem, t)

  const handleDrawerClose = (field: string, value: boolean) => {
    if (field === 'create') {
      dispatch({
        type: 'SET_CLOSE_DRAWER',
        payload: true,
      })
    } else if (field === 'addFromDb') {
      if (selectedStudents.length > 0) {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            SetStudentsDrawer({
              ...studentsDrawer,
              ['addFromDb']: false,
            })
            setSelectedStudents([])
          },
          confirmText: t('popup.confirm_cancel'),
          cancelText: t('general.no'),
        })
      } else {
        SetStudentsDrawer({
          ...studentsDrawer,
          ['addFromDb']: false,
        })
        setSelectedStudents([])
      }
    } else {
      SetStudentsDrawer({
        ...studentsDrawer,
        [field]: value,
      })
    }
  }

  const handleSearchChange = (e: any) => {
    setSearchValue(e.currentTarget.value)
  }

  useEffect(() => {
    if (formData) {
      const isEmpty = _.values(formData).every(_.isEmpty)
      if (isEmpty) {
        SetStudentsDrawer({
          ...studentsDrawer,
          ['create']: false,
        })
        dispatch({
          type: CLEAR_FORMDATA,
          payload: true,
        })
      } else {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            SetStudentsDrawer({
              ...studentsDrawer,
              ['create']: false,
            })
            dispatch({
              type: CLEAR_FORMDATA,
              payload: true,
            })
          },
          onClose: () => {
            setFormData('')
            dispatch({
              type: CLEAR_FORMDATA,
              payload: true,
            })
          },
          confirmText: t('general.yes'),
          cancelText: t('actions.cancel'),
        })
      }
    }
  }, [formData])

  useEffect(() => {
    if (
      dataState.createdObject.obj &&
      dataState.createdObject.type === 'student'
    ) {
      props.handleSetStudents([dataState.createdObject.obj])

      dispatch({
        type: SET_CREATED_USER,
        payload: { obj: null, type: '' },
      })
    }
  }, [dataState.createdObject.obj])

  const handleActionClick = (action: string) => {
    toggleDrawer(action, true)
  }

  const filteredStudents = props.students.filter(
    (i: any) =>
      i.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
      i.lastName.toLowerCase().includes(searchValue.toLowerCase()),
  )
  const buttonActions = studentActionConfig(handleActionClick, t)

  return (
    <Container>
      <Toolbar styles={{ justifyContent: 'space-between' }}>
        <ToolbarItem>
          <SelectBtn
            active={0}
            onChange={() => {}}
            options={[
              {
                label: t('actions.add_student'),
                value: 0,
                onClick: () => handleActionClick('create'),
              },
              {
                label: t('actions.add_students_to_group'),
                value: 1,
                onClick: () => handleActionClick('addFromDb'),
              },
              {
                label: t('actions.import_students'),
                value: 2,
                onClick: () => handleActionClick('import'),
              },
            ]}
          />
        </ToolbarItem>

        <ToolbarItem>
          <ItemWrapper>
            {/* <div style={{ marginRight: '10px' }}>
              <Button text="Export" color="primary" size="small" />
            </div> */}
            <Search
              inputProps={{
                value: searchValue,
                onChange: handleSearchChange,
              }}
              onClick={handleSearchChange}
            />
          </ItemWrapper>
        </ToolbarItem>
      </Toolbar>

      <Grid
        data={filteredStudents || []}
        config={config}
        selectAllItem={() => console.log('1')}
        selected={0}
        selectedItems={[]}
      />

      <Drawer
        opened={studentsDrawer.addFromDb}
        toggleDrawer={() => handleDrawerClose('addFromDb', false)}
        totalWidth="970px"
      >
        <StudentListDrawer
          selectedStudents={props.students}
          onFormSubmit={props.handleSetStudents}
          onCancel={() => toggleDrawer('addFromDb', false)}
          setSelectedStudents={setSelectedStudents}
          manualCompanyId={props.companyId}
        />
      </Drawer>

      <Drawer
        opened={studentsDrawer.import}
        toggleDrawer={() => toggleDrawer('import', false)}
        totalWidth="900px"
      >
        <ImportStudentsDrawer
          onClose={() => toggleDrawer('import', false)}
          setStudents={importedStudents}
          isNew
        />
      </Drawer>

      <Drawer
        opened={studentsDrawer.create}
        toggleDrawer={() => handleDrawerClose('create', false)}
        totalWidth="970px"
      >
        <AddStudent
          title={t('actions.add_student')}
          role="STUDENT"
          drawerName="addStudent"
          drawerData={{
            onSuccess: () => {},
            groupField: true,
            companyId: props.companyId,
          }}
          onClose={() => toggleDrawer('create', false)}
          setFormData={setFormData}
          setSelectedStudents={setSelectedStudents}
        />
      </Drawer>
    </Container>
  )
}

export default AddStudentsInNewGroup

const Container = styled.div`
  box-sizing: border-box;
  padding: 20px;
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 350px;
`

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`
