import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import Uploader from 'components/common/FileUploader/Uploader'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import {
  useGenerateUploadVideoLinks,
  useUploadImage,
  useUploadFile,
  useUploadAudioService,
  useGenerateCustumUploadVideoLinks,
} from 'hooks/helpers/useHelpersService'
import { triggerVideoConverter } from 'services/mediaEncoding'
import useSnackbarAlert from 'hooks/useSnackbar'

const constants = {
  image: 'image/jpeg|image/jpg|image/png',
  video:
    'video/x-msvideo|video/mpeg|video/ogg|video/mp4|video/x-flv|video/quicktime|video/x-msvideo|video/x-ms-wmv',
  audio: 'audio/mpeg|audio/mpeg|audio/aac|audio/mp4a-latm',
  file:
    'application/pdf|application/msword|application/plain|text/plain|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel|application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

const UploadContent = (props: any) => {
  const { t } = useTranslation()
  // const [props.uploadedFiles, props.setUploadedFiles] = useState<any>([])
  const [uploadingProgress, setUploadingProgress] = useState(0)
  const [mediaDuration, setMediaDuration] = useState<any>(0)
  const [fileType, setFileType] = useState('video')
  const [cropperOpen, setCropperOpen] = useState(false)
  const [cropperImage, setCropperImage] = useState<any>('')

  const { generateCustumUploadVideoLinks } = useGenerateCustumUploadVideoLinks()
  const { uploadFile, fileLoading } = useUploadFile()
  const { uploadAudio } = useUploadAudioService()
  const { uploadImage, imageLoading } = useUploadImage()
  const { setSnackbar } = useSnackbarAlert()

  const uploadVideoService = (url: string, file: any, key?: string) => {
    const formData = new FormData();

    formData.append("file", file);

    return axios.put(url, formData, {
      headers: {
        'Content-Type': "multipart/form-data",
      },
      onUploadProgress: ({ total, loaded }: any) => {
        setUploadingProgress((loaded / total) * 100)
      },
    }).then((res) => {
      const message = `Upload success, conversion ${res.data}`
      const variant = 'success'

      setSnackbar({ message, variant })
    }).catch((err) => {
      const message = `${err}`
      const variant = 'error'
      console.log("err", err)
      setSnackbar({ message, variant })
    })
  }

  const uploadContentFile = (file: any) => {
    uploadFile(file, 'lesson/content', (link: string, fileId: string) => {
      const uploaded = [
        {
          type: 'documents',
          name: file.name,
          fileType: file.name.split('.').slice(-1)[0],
          link,
        },
      ]

      props.setUploadedFiles([...props.uploadedFiles, ...uploaded])
    })
  }

  const uploadVideoFile = (file: any) => {
    const videos = [{ fileName: file.name, type: file.type }]
    const entity = props.currentModule ? 'module_question' : 'question'
    generateCustumUploadVideoLinks(
      videos,
      entity,
      (arg: any, key?: string) => uploadVideoService(arg, file, key),
      (args: any) => {
        const uploaded = [
          {
            ...args.video,
            type: 'videos',
            title: args.video.name,
          },
        ]
        props.setUploadedFiles([...props.uploadedFiles, ...uploaded])
        setUploadingProgress(0)
      },
    )
  }

  const uploadAudioFile = (file: any) => {
    const audioFile: any = { fileName: file.name, type: file.type }

    uploadAudio(
      audioFile,
      (arg: any) => uploadVideoService(arg, file),
      (link: string) => {
        const uploaded = [
          {
            type: 'audios',
            name: file.name,
            fileType: file.name.split('.').pop(),
            link,
          },
        ]

        props.setUploadedFiles([...props.uploadedFiles, ...uploaded])
        setUploadingProgress(0)
      },
    )
  }

  const uploadImageFile = (field: string, croppedFile: any) => {
    uploadImage(croppedFile, `questions/${field}`, (link: string) => {
      const uploaded = [
        {
          type: field,
          name: Date.now().toString(),
          fileType: 'png',
          link,
        },
      ]

      props.setUploadedFiles([...props.uploadedFiles, ...uploaded])
    })
  }

  const extractDuration = (file: any) => {
    // const reader: any = new FileReader()
    // reader.onload = function () {
    //   const media = new Audio(reader.result)
    //   media.onloadedmetadata = function () {
    setMediaDuration(0)
    //   }
    // }
    // reader.readAsDataURL(file)
  }

  const b64Converter = (file: any) => {
    const reader: any = new FileReader()

    reader.onload = () => {
      const img: any = new Image()
      img.src = reader.result
      setCropperImage({ name: 'images', file: reader.result })
    }
    reader.readAsDataURL(file)
  }

  const handleFileChange = (e: any) => {
    const file = e.target.files[0]
    if (constants.file.includes(file.type)) {
      setFileType('document')
      uploadContentFile(file)
      return
    }

    extractDuration(file)

    if (constants.audio.includes(file.type)) {
      setFileType('audio')
      uploadAudioFile(file)
      return
    }

    if (constants.video.includes(file.type)) {
      setFileType('video')
      uploadVideoFile(file)
      return
    }

    if (constants.image.includes(file.type)) {
      setFileType('image')
      b64Converter(file)
      setCropperOpen(true)

      return
    }
  }

  const handleInputChange = (e: any, index: any, fileType: string) => {
    const { value } = e.target

    const field = fileType === 'videos' ? 'title' : 'name'

    const content = [...props.uploadedFiles]
    const item = {
      ...content[index],
    }

    item[field] = value
    content[index] = item

    props.setUploadedFiles([...content])
  }

  const handleDeleteFile = (i: any) => {
    let content = [...props.uploadedFiles]
    content.splice(i, 1)
    props.setUploadedFiles(content)
  }

  const cropperModalToggle = () => {
    setCropperOpen(!cropperOpen)
  }

  const loadings: any = {
    image: imageLoading === 'images',
    video: uploadingProgress > 0 ? uploadingProgress : false,
    document: fileLoading === 'content',
    audio: uploadingProgress > 0 ? uploadingProgress : false,
  }

  return (
    <Container>
      <UploadedContent full={!!(props.uploadedFiles.length > 0)}>
        {props.uploadedFiles.map((item: any, index: any) => {
          const fileExtension =
            item.type === 'videos'
              ? `(${item.links[2].type})`
              : `(${item.fileType})`

          return (
            <>
              <Uploader
                key={index}
                label={t('actions.upload_content')}
                // accept="video/*"
                // onFileChange={handleInputChange}
                deleteFile={() => handleDeleteFile(index)}
                type={item.type.slice(0, item.type.length - 1)}
                inputValue={item.type === 'videos' ? item.title : item.name}
                onInputChange={(e: any) =>
                  handleInputChange(e, index, item.type)
                }
                uploadedFile={item}
                fileExtension={fileExtension}
                accept="audio/*,video/*,image/*"
              />
            </>
          )
        })}
        <Uploader
          label={t('actions.upload_content')}
          onFileChange={handleFileChange}
          disabled={true}
          multiple
          type={fileType}
          // uploadedFile={values[fileTypes]}
          fileExtension={''}
          loading={loadings[fileType]}
          id="content"
          accept="audio/*,video/*,image/*"
        // loading={['video', 'audio'].includes(fileType) ? (uploadingProgress > 0 ? uploadingProgress : false) : fileLoading === 'content'}
        />
      </UploadedContent>

      <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
        <ImageCropper
          file={cropperImage}
          hideCropper={cropperModalToggle}
          getCroppedFile={uploadImageFile}
        />
      </Modal>
    </Container>
  )
}

export default UploadContent

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const defs = ({ full }: any) => {
  const styles: any = {}
  if (full) {
    styles.gridTemplateColumns = 'repeat(2, 1fr)'
    styles.gridGap = '22px'
  } else {
    styles.gridTemplateColumns = 'repeat(1, 1fr)'
  }

  return styles
}

const UploadedContent = styled.div<{ full: any }>`
  display: grid;
  ${defs}
`
