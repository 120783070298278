import React, { useState, useEffect } from 'react'
import { Button } from 'components/common/Button'
import styled from 'styled-components'
import { useFormik } from 'formik'
import {
  TabNavigation,
  TabPanel,
  activeTabClassName,
} from 'components/common/Tabs/Tabs'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useHistory } from 'react-router-dom'
import useAddUserServie from 'hooks/users/useAddUserService'
import { useFetchUsers } from 'hooks/users/useUsers'
import useCompaniesService from 'hooks/useCompaniesService'
import { useUserValue } from 'context/UserContext'

import {
  Container,
  Wrapper,
  FooterWrapper,
  ButtonWrapper,
} from '../styled-components'

import CreateStudent from './CreateStudent'
import StudentList from './StudentList'
import ImportStudents from './ImportStudents'
import { StudentSchema } from 'helpers/validationSchemas'
import { mainColor } from 'components/common/Colors/CommonColors'
import SelectBtn from 'components/common/SelectBtn'
import DropdownButton from 'components/common/DropdownButton'
import { useTheme } from '@material-ui/core'

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

const formData: any = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  gender: null,
  birthDate: '',
  note: '',
  role: 'STUDENT',
  biography: '',
  group: null,
  avatar: '',
  jobTitle: '',
  location: '',
  phoneFields: null,
  phoneFieldValue: null,
}

const CompanyStudents = (props: any) => {
  const [state] = useUserValue()
  const [tabValue, setTabValue] = useState(0)
  const [createStudent, setCreateStudent] = useState('')
  const { companyData, setData } = props
  const history = useHistory()
  const { setCompanyState } = useCompaniesService()

  const { addUser, error, addUserLoading } = useAddUserServie({
    role: 'STUDENT',
    companyId: companyData.companyData.id,
    filter: {},
  })

  const { users, loading, refetch }: any = useFetchUsers('STUDENT', {
    companyId: { type: 'exact', value: companyData.companyData.id },
  })

  // console.log('users::', users)

  useEffect(() => {
    if (users && users.data) {
      setData({
        ...companyData,
        students: users.data,
      })
    }
  }, [users])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: formData,
    validationSchema: StudentSchema,
    onSubmit(values) {
      if (addUserLoading) return

      const data = { ...values }

      delete data.phoneFieldValue

      data.group = []

      if (data.gender) data.gender = data.gender.value

      if (values.group) {
        data.group = values.group.map((i: any) => ({
          name: i.label,
          groupId: i.value,
        }))
      }

      if (data.phoneFields && data.phoneFields.value) {
        data.phoneFields = {
          dialCode: data.phoneFields.value,
          code: data.phoneFields.label,
        }
      } else {
        data.phoneFields = null
      }

      data.requestPasswordChange = state.currentUser.settings.askPassword

      addUser(data, 'STUDENT', companyData.companyData.id, () => {
        refetch()
        if (createStudent === 'reset') {
          resetForm()
        } else {
          resetForm()
          setTabValue(2)
        }
      })
    },
  })

  const onFormSubmit = (action: string) => {
    setCreateStudent(action)
    handleSubmit()
  }

  const nextButtonDisabled = !(companyData.students.length > 0)

  const { t } = props

  const createGroupButtons = () => {
    return [
      {
        title: `${t('company_wizard_layout.create_new_student')}`, onClick: () => {
          setTabValue(0);
        }
      },
      {
        title: `${t('company_wizard_layout.upload_students_in_group')}`, onClick: () => {
          setTabValue(1);
        }
      },
      {
        title: `${t('company_wizard_layout.student_list')}`, onClick: () => {
          setTabValue(2);
        }
      },
    ]
  }

  const theme = useTheme()

  return (
    <>
      <Container>
        <Wrapper theme={theme} width="100%">
          <ContentWrapper>

            <ActionButtonContainer>
              <DropdownButton title={`${t('navigation.students')}`} buttons={createGroupButtons()} />
            </ActionButtonContainer>

            <TabNavigation withBorder={true}>
              {/* <SelectBtn
                active={tabValue}
                onChange={setTabValue}
                options={[
                  {
                    label: t('company_wizard_layout.create_new_student'),
                    value: 0,
                  },
                  {
                    label: t('company_wizard_layout.upload_students_in_group'),
                    value: 1,
                  },
                  {
                    label: t('company_wizard_layout.student_list'),
                    value: 2,
                  },
                ]}
              /> */}

              <TabPanel value={tabValue} index={0}>
                <Wrapper theme={theme} width="70%" style={{ margin: 'auto' }}>
                  <CreateStudent
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleSubmit={onFormSubmit}
                    groupList={companyData.groups}
                    addUserLoading={addUserLoading}
                    t={t}
                  />
                </Wrapper>
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <Wrapper theme={theme} width="70%" style={{ margin: 'auto' }}>
                  <ImportStudents
                    groupList={companyData.groups}
                    companyId={companyData.companyData.id}
                    refetch={refetch}
                    setTabValue={setTabValue}
                    t={t}
                  />
                </Wrapper>
              </TabPanel>

              <TabPanel value={tabValue} index={2}>
                <StudentList
                  studentList={companyData.students}
                  groupList={companyData.groups}
                  companyId={companyData.companyData.id}
                  refetch={refetch}
                  t={t}
                />
              </TabPanel>
            </TabNavigation>
          </ContentWrapper>
        </Wrapper>
      </Container>

      <FooterWrapper>
        <ButtonWrapper>
          <Button
            text={t('actions.save_as_draft')}
            type="medium"
            onClick={() => {
              setCompanyState(companyData.companyData.id, 5, 'Success', () =>
                history.push('/Companies'),
              )
            }}
            background="#ffffff"
            textColor="#080A0A8A"
            outline="1px solid #080A0A14"
          />

          <Button
            text={t('actions.save_and_next')}
            type="medium"
            onClick={() => {
              setCompanyState(companyData.companyData.id, 6, 'Success', () =>
                props.changeStep(6),
              )
            }}
            isDisabled={nextButtonDisabled}
            color="secondary"
            background={'#317BF4'}
          />
        </ButtonWrapper>
      </FooterWrapper>
    </>
  )
}

export default CompanyStudents

const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 0px 50px;
`
const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
`

const ActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`
