import React, { useEffect } from 'react'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useFormik } from 'formik'
import { useUserValue } from 'context/UserContext'
import {
  useUploadFile,
  useExtractTextService,
} from 'hooks/helpers/useHelpersService'
import { useAddTopic } from 'hooks/topics/useAddTopic'
import { useData } from 'context/DataContext'
import { SET_FORMDATA } from 'store/types'

import { AddTopicSchema } from 'helpers/validationSchemas'
import { TOGGLE_CLOSE_DRAWER } from 'store/types'

import Loader from 'components/common/Loader'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import Uploader from 'components/common/FileUploader/Uploader'
import UploaderButton from 'components/common/FileUploader/UploaderButton'
import { useTheme } from '@material-ui/core'

interface Props {
  title: string
  id: string
  data?: any
}

interface formInterface {
  name: string
  description: string
  attachment: any
}

const formData: formInterface = {
  name: '',
  description: '',
  attachment: null,
}

const AddTopicDrawer = ({ title, id, data }: Props) => {
  const { addTopic, newTopic, addTopicLoading } = useAddTopic({
    moduleId: id,
    filter: {},
  })
  const { extractText, loading: extractTextLoading } = useExtractTextService()

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: formData,
    validationSchema: AddTopicSchema,
    onSubmit(values) {
      if (addTopicLoading) return

      addTopic(id, values)
    },
  })
  const [state, dispatch] = useUserValue()
  const [dataState, dispatchData] = useData()
  const { uploadFile, fileLoading } = useUploadFile()

  const closeDrawer = () =>
    DrawerEventEmitter.emit('openDrawer', 'addTopic', false)

  const handleCloseDrawer = () => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'addTopic',
        values,
        compareTo: {},
      },
    })
  }

  const handleAttachmentChange = (e: any) => {
    const { files } = e.target
    const file = files[0]
    uploadFile(file, 'topic/attachment', (link: string) =>
      setFieldValue('attachment', {
        name: file.name.split('.')[0],
        fileType: file.name.split('.').slice(-1)[0],
        // size: file.size.toString(),
        link,
      }),
    )
  }

  const deleteAttachment = (e: any) => {
    e.stopPropagation()
    setFieldValue('attachment', null)
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (newTopic) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: '',
          confirm: false,
        },
      })
      closeDrawer()
      data.refetch()
    }
  }, [newTopic])

  const handleExtractText = (e: any) => {
    if (!e || !e.target) return
    const { files } = e.target
    const file = files[0]
    uploadFile(file, `uploads/extract`, (link: string) => {
      extractText(link, (text: string) => setFieldValue('description', text))
    })
  }

  const theme = useTheme();

  return (
    <>
      {addTopicLoading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{title}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <TextInput
                  error={errors.name ? true : false}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  label="Name*"
                  size="small"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <TextInput
                  label="Topic Overview"
                  size="small"
                  name="description"
                  type="text"
                  value={values.description}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows="4"
                  component={
                    <UploaderButton
                      onChange={handleExtractText}
                      label="Upload"
                      loading={fileLoading === 'extract' || extractTextLoading}
                    />
                  }
                />
              </FormField>
              <FormField>
                <Uploader
                  label="Add attachment"
                  accept="*"
                  onFileChange={handleAttachmentChange}
                  deleteFile={deleteAttachment}
                  type="file"
                  inputValue={values.attachment ? values.attachment.name : ''}
                  onInputChange={(e: any) =>
                    setFieldValue('attachment.name', e.target.value)
                  }
                  disabled={!values.attachment}
                  uploadedFile={values.attachment}
                  fileExtension={
                    values.attachment ? `(${values.attachment.fileType})` : ''
                  }
                  loading={fileLoading === 'attachment'}
                  name="attachment"
                  id="attachment"
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button text="Cancel" type="small" onClick={handleCloseDrawer} />
              <Button
                text="Save"
                type="small"
                color="secondary"
                btnType="submit"
                isDisabled={!!(fileLoading || addTopicLoading)}
              ></Button>
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddTopicDrawer
