import { useMutation } from "@apollo/react-hooks"

import { ADD_STUDENTS_IN_GROUP } from "gql/group/group.query"

const addStudentsService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addStudents] = useMutation(ADD_STUDENTS_IN_GROUP)

  return {
    addStudents
  }
}

export default addStudentsService
