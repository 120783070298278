import { string } from 'yup';
import gql from 'graphql-tag'

export const CREATE_TRANSACTION = gql`
  mutation($input: TransactionInput) {
    createTransaction(input: $input) {
      userId,
      orderId,
      shopOrderId
    }
  }
`


export const GET_TRANSACTIONS = gql`
  query($currentPage: Int, $perPage: Int,  $fetchAll: Boolean) {
    getTransactions (currentPage: $currentPage, perPage: $perPage, fetchAll: $fetchAll){
      currentPage,
      totalPages,
      totalCount,
      data {
        id,
      userId,
      orderId,
      userEmail,
      amount,
      status,
      preAuthStatus,
      iPayPaymentId,
      statusDescription,
      shopOrderId,
      paymentMethod,
      cardType,
      pan,
      transactionId,
      userFullName,
      courseId,
      createDate
      subscriptionProduct {
        price
        currency
      }
      }
    }
  }
`



export const GET_TRANSACTION = gql`
  query($shopOrderId: String!) {
    getTransaction(shopOrderId: $shopOrderId) {
      status
      courseId
    }
  }
`


export const GET_SUCCESSFUL_TRANSACTION = gql`
query findTransactions($subscription: String!,){
    findTransactions(subscription: $subscription){
        id
    }
}
`
