import { gql } from 'graphql.macro'

export const GET_CURRENT_USER = gql`
 query currentUser($deviceType: String,  $deviceId: String) {
  currentUser (deviceType: $deviceType, deviceId: $deviceId)  {
    subscription{
          id
        }
    id
    firstName
    lastName
    isRegistered
    email
    courses {
      name
      courseId
    }
    role
    companyId {
      name
      id
      platformService
    }
    avatar
    requestPasswordChange
    settings {
      askPassword
    }
    jobTitle
    biography
    location
    phone
    gender
    birthDate
    createDate
    note
    notes {
      id
      actionId
      title
      description
      moment
      thumbnail
      createDate
      courseId {
        name
        avatar {
          name
          link
          fileType
        }
      }
      lessonInfo {
        videoLink {
          name
          key
          thumbnail
          duration
          id
          title
          links {
            url
            fileType
          }
        }

        _id
        name
        duration
        topicId
        transcript
        description
        watchDuration
        numberOfModuleQuestions
        topicName
        topicId
        progress
        blocked
        moduleName
        moduleId
        courseId
        courseName
        coaches
        courseCover
        isFavorited
      }
    }
    status
    age
    phoneFields {
      code
      dialCode
    }
    isCompanyAdmin
    isSuperAdmin
    group {
      name
      groupId
    }
  }
}
`

export const GET_USERS = gql`
  query(
    $role: String!
    $filter: UserFilter = {}
    $currentPage: Int
    $perPage: Int
    $fetchAll: Boolean
  ) {
    usersByRole(
      role: $role
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
      fetchAll: $fetchAll
    ) {
      currentPage
      totalPages
      totalCount

      data {
      mobileNumber
        subscription{
          paymentCount
          id
          createDate
          canceledAt
          productId{
            occurrenceNumber
            price
          }
        }
        canceledSub{
          paymentCount
          id
          createDate
          canceledAt
          productId{
            occurrenceNumber
            price
          }
        }
        id
        firstName
        lastName
        email
        phone
        phoneFields {
          code
          dialCode
        }
        role
        gender

        birthDate
        age
        biography
        note
        isQualified
        status
        jobTitle
        location
        group {
          groupId
          name
        }
        courses {
          name
          courseId
        }
        companyId {
          name
          id
          platformService
        }
        avatar
        createDate
        education {
      id,
      onGoing,
      userId,
      title,
      position,
      date
    }
    experience {
      id,
      onGoing,
      userId,
      title
      position,
      date
    }
      }
    }
  }
`

export const GET_USER = gql`
  query($id: String!) {
    user(id: $id) {
      mobileNumber
      id
      firstName
      lastName
      email
      phone
      role
      gender
      age
      averageRating
      birthDate

      isQualified
      biography
      note
      notes {
        id
        actionId
        title
        description
        moment
        thumbnail
        createDate
      }
      group {
        groupId
        name
      }
      companyId {
        name
        id
        platformService
      }
      courses {
        courseId
        name
      }
      jobTitle
      location
      phoneFields {
        code
        dialCode
      }
      avatar
      createDate
      education {
      id,
      onGoing,
      userId,
      title,
      position,
      date
    }
    experience {
      id,
      onGoing,
      userId,
      title
      position,
      date
    }
      status
      age
    }
  }
`

export const CHECK_USER_EMAIL = gql`
  mutation($email: String!) {
    checkUserEmail(email: $email) {
      id
    }
  }
`

export const ADD_USER = gql`
  mutation($input: UserInput, $companyId: String) {
    addUser(input: $input, companyId: $companyId) {
      id
      firstName
      lastName
      email
      phone
      role
      gender
      birthDate
      age
      biography
      note
      isQualified
      status
      avatar
      group {
        groupId
        name
      }
      companyId {
        name
        id
        platformService
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation($id: String, $input: UserInput) {
    updateUser(id: $id, input: $input) {
      id
      firstName
      lastName
      email
      phone
      role
      gender
      birthDate
      age
      biography
      note
      isQualified
      status
      group {
        groupId
        name
      }
      companyId {
        name
        id
        platformService
      }
      jobTitle
      location
      phoneFields {
        code
        dialCode
      }
      avatar
    }
  }
`
export const UPDATE_USER_FIRST_LOGIN = gql`
  mutation($id: String) {
    upadteUserFirstLogin(id: $id) {
      id
    }
  }
`

export const UPDATE_USER_STATUS = gql`
  mutation($ids: [String]!, $status: String) {
    updateUserStatus(ids: $ids, status: $status) {
      id
      firstName
      lastName
      email
      phone
      role
      gender
      birthDate
      age
      biography
      note
      isQualified
      status
      companyId {
        name
        id
        platformService
      }
    }
  }
`

export const DELETE_USER = gql`
  mutation($ids: [String!]!, $role: String!) {
    delete(ids: $ids, role: $role)
  }
`

export const UPDATE_USER_PASSWORD = gql`
  mutation($id: String) {
    updateUserPassword(id: $id) {
      id
      firstName
      lastName
      email
      phone
      role
      gender
      birthDate
      age
      biography
      note
      isQualified
      status
      companyId {
        name
        id
        platformService
      }
    }
  }
`

export const INSERT_STUDENTS = gql`
  mutation(
    $students: [InsertStudentInput]
    $groupId: String
    $groupName: String
    $companyId: String
  ) {
    insertStudents(
      students: $students
      groupId: $groupId
      groupName: $groupName
      companyId: $companyId
    ) {
      firstName
      lastName
      email
      phone
      phoneFields {
        code
        dialCode
      }
      error
      message
      biography
      note
      gender
    }
  }
`

export const UPDATE_PROFILE_AVATAR = gql`
  mutation($avatar: String, $userId: String, $action: String) {
    updateProfileAvatar(avatar: $avatar, userId: $userId, action: $action) {
      id
      firstName
      lastName
      email
      role
      companyId {
        name
        id
        platformService
      }
      avatar
    }
  }
`

export const CHANGE_COACH_QUALIFICATION = gql`
  mutation($ids: [String]!, $isQualified: Boolean!) {
    changeCoachQualification(ids: $ids, isQualified: $isQualified) {
      id
      firstName
      lastName
      email
      phone
      role
      gender
      birthDate
      age
      biography
      note
      isQualified
      status
      avatar
      companyId {
        name
        id
        platformService
      }
    }
  }
`

// Testing filter
export const FILTER_USERS = gql`
  mutation($filters: UserFilter) {
    filter(filters: $filters) {
      id
      firstName
      lastName
      email
      phone
      role
      gender
      birthDate
      age
      biography
      note
      isQualified
      status
      avatar
    }
  }
`

// ---- end Testing filter
export const EDIT_USER_PROFILE = gql`
  mutation($input: EditProfileInput) {
    editUserProfile(input: $input) {
      id
      firstName
      lastName
      email
      role
      companyId {
        name
        id
        platformService
      }
      avatar
      settings {
        askPassword
      }
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation(
    $oldPassword: String!
    $newPassword: String!
    $confirmPassword: String!
    $fromSettings: Boolean
  ) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
      fromSettings:$fromSettings
    ) {
      id
      firstName
      lastName
      email
      role
      # companyId {
      #   name
      #   id
      #   platformService
      # }
      avatar
      requestPasswordChange
    }
  }
`

export const CHECK_RESET_PASSWORD_TOKEN = gql`
  query($token: String!) {
    checkResetPasswordToken(token: $token) {
      isValid
    }
  }
`

export const GET_PORTFOLIO = gql`
  query($id: String!, $userId: String!) {
    getPortfolio(id: $id, userId: $userId) {
      skillTestVideos {
        module {
          id
          name
          skillTestVideoEnabled
        }
        videos {
          id
          file {
            name
            link
            type
          }
          status
          description
          version
          createDate
          coaches
          students
          reviews {
            isAnonymous
            id
            rate
          }
          averageRating
          course {
            id
            name
          }
          module {
            id
            name
          }
        }
      }

      quizzes {
        quizParticipationType
        name
        id
        hasEverStarted
        randomizationNumber
        randomization
        module {
          id
          name
        }
        topic {
          id
          name
        }
        lesson {
          id
          name
        }
        passRate
        score
        questionsCount
      }
      iqTests {
        IQTestType
        id
        name
        score
        questionsCount
      }
    }
  }
`
export const GET_USER_Short_Info = gql`
  query($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      avatar
      group {
        groupId
        name
      }
    }
  }
`

export const GET_SKILL_TEST_PERFORMANCE = gql`
  query($courseId: String!, $userId: String!) {
    getSkillTestVideos(courseId: $courseId, userId: $userId) {
      video {
        name
        id
        title
        thumbnail
        duration
        links {
          url
          fileType
          converted
        }
      }
      module {
        id
        name
      }
      version
      sentForReview {
        id
        firstName
        lastName
        role
        rate
        userId
      }
      averageRating
      status
      createDate
    }
  }
`
export const UPDATE_USER_PASSWORD_BY_ID = gql`
  mutation($id: String, $password: String) {
    updateUserPassword(id: $id, password: $password) {
      id
      firstName
      lastName
      email
      role
    }
  }
`

export const GET_COACHES_BY_COMPANYID = gql`
  query($companyId: String!) {
    getCoachesByCompany(companyId: $companyId) {
      id
      firstName
      lastName
    }
  }
`

export const GET_COMPANY_AND_BOUGHT_COURSES_COACHES = gql`
  query($companyId: String!) {
    getCompanyAndBoughtCoursesCoaches(companyId: $companyId) {
      id
      firstName
      lastName
    }
  }
`

export const ADD_REMOVE_UPDATE_EXPERIENCE = gql`
  mutation($action: String!, $userId:String, $input: ExperienceInput, $experienceId: String) {
    addUserExperience(  action: $action,userId: $userId input: $input, experienceId: $experienceId) {
        experience {
          onGoing,
          id,
          title,
          position,
          date
        }
    }
  }
`

export const ADD_REMOVE_UPDATE_EDUCATION = gql`
  mutation($action: String!, $userId: String, $input: EducationInput, $educationId: String) {
    addUserEducation(  action: $action, userId: $userId, input: $input, educationId: $educationId) {
        education {
          onGoing,
          id,
          title,
          position,
          date
        }
    }
  }
`
