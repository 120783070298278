import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  CloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'
import { DrawerEventEmitter } from 'helpers/drawer'
import React, { useEffect, useState } from 'react'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import IconButton from '@material-ui/core/IconButton'
import Drawer from 'components/common/Drawer'
import { useUserValue } from 'context/UserContext'
import { TOGGLE_CLOSE_DRAWER } from 'store/types'
import { useSwal } from 'hooks/useSwal'
import { EditProfileFields } from './EditProfileFields'
import { UserSchema } from 'helpers/validationSchemas'
import { useFormik } from 'formik'
import { IPersonalInformation, Line } from './PersonalInformation'
import useEditUserService from 'hooks/users/useEditUserService'
import { Button } from 'components/common/Button'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { useTheme } from '@material-ui/core'

const formData: any = {
  firstName: '',
}

const EditAdminProfile: React.FC<{
  setIsClickedOnEdit: React.Dispatch<React.SetStateAction<boolean>>
  initialValues: IPersonalInformation
}> = ({ setIsClickedOnEdit, initialValues }) => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const [generalError, setGeneralError] = useState('')
  const [enrolError, setEnrollError] = useState(false)
  const { updateUser, user, error, editUserLoading } = useEditUserService({
    role: initialValues.role,
    companyId: initialValues?.companyId || 0, //TODO:
  })

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: UserSchema,
    onSubmit(values) {
      const data = { ...values }

      if (data.firstName?.toLowerCase().includes("enrol") || data.lastName?.toLowerCase().includes("enrol")) {
        setEnrollError(true)

        return
      }
      delete data.phoneFieldValue
      if (data.phoneFields && data.phoneFields.value) {
        data.phoneFields = {
          dialCode: data.phoneFields.value,
          code: data.phoneFields.label,
        }
      } else {
        data.phoneFields = null
      }

      if (!data.birthDate) data.birthDate = null

      updateUser(initialValues.id, data)
      handleDrawerClose()
    },
  })

  useEffect(() => {
    if (initialValues) {
      const {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        location,
        gender,
        birthDate,
        biography,
        note,
        phoneFields,
        phoneFieldValue,
        avatar,
      } = initialValues
      const formData: any = {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        location,
        gender,
        birthDate: birthDate
          ? moment(parseInt(birthDate.toString())).format()
          : null,
        biography,
        note,
        phoneFields:
          phoneFields && phoneFields.code
            ? {
              label: phoneFields.code, //label should be something like +995
              value: phoneFields.dialCode, //value should be something like GE
            }
            : null,
        phoneFieldValue: phoneFieldValue || null,
        avatar,
      }
      setValues({
        ...formData,
      })
    }
  }, [formData, initialValues])

  const handleDrawerClose = () => {
    setIsClickedOnEdit(false)
    dispatch({
      type: 'SET_CLOSE_DRAWER',
      payload: true,
    })
  }
  const { fireSwal } = useSwal()

  const handleCloseDrawer = () => {
    const { text } = state.closeDrawer
    if (!text) {
      handleDrawerClose()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
        onConfirm: () => {
          handleDrawerClose()
          dispatch({
            type: TOGGLE_CLOSE_DRAWER,
            payload: {
              text: '',
              confirm: false,
            },
          })
        },
      })
    }
  }

  useEffect(() => {
    DrawerEventEmitter.addListener('openDrawer')
  }, [])

  const handleValueChange = (e: any) => {
    const { confirm } = state.closeDrawer
    if (!confirm) {
      dispatch({
        type: TOGGLE_CLOSE_DRAWER,
        payload: {
          text: t('popups.close_popup'),
          confirm: true,
        },
      })
    }
    handleChange(e)
  }

  const isStudent = state.currentUser.role === 'STUDENT'
  const theme = useTheme()


  return (
    <>
      <Drawer
        opened={true}
        toggleDrawer={() => { }}
        totalWidth={970}
        responsiveTotalWidth='500px'
      >
        <DrawerHeader theme={theme}>
          <DrawerTitle theme={theme} marginLeft={'150px'}>{t('users_layout.edit_profile')}</DrawerTitle>
          <CloseIcon
            onClick={handleDrawerClose}
            filename={'close'}
            fill={'#707070'}
            stroke={'transparent'}
            width={36}
            height={36}
            cursor={'pointer'}
          />
          <Info />


        </DrawerHeader>
        <DrawerContent
          headerHeight={68}
          marginResponsive="100px"
          left="50px"
        >
          <FormContainer
            onSubmit={(e: any) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <FormGroupWrapper>
              {enrolError && <div style={{ position: "absolute", color: "#f44336", top: "90px", left: "30px", fontSize: "0.75rem" }}>{t('login.enrol_error')}</div>}

              <EditProfileFields
                values={values}
                errors={errors}
                generalError={generalError}
                touched={touched}
                handleChange={handleValueChange}
                setFieldValue={setFieldValue}
                setValues={setValues}
                isStudent={isStudent}
              />
            </FormGroupWrapper>

            <FormFooter>
              <FormButtons>
                <Button
                  onClick={handleCloseDrawer}
                  text={t('actions.cancel')}
                  background="#F5F5F5"
                  textColor={validationRed}
                  outline={`1px solid ${red}`}
                  type="medium"
                />
                <Button
                  onClick={() => handleSubmit()}
                  text={t('actions.save')}
                  type="medium"
                  color="secondary"
                  background={'#317BF4'}
                />
              </FormButtons>
            </FormFooter>
          </FormContainer>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default EditAdminProfile
