import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/browser"
import { ApolloProvider } from "@apollo/react-hooks"
import { client } from "./apollo"

import App from "./App"

import 'react-daterange-picker/dist/css/react-calendar.css'
import "./sass/main.scss"
import "./tailwind.css"
import "./index.css"

// let somevar: string = "Dato"

Sentry.init({
  dsn: "https://d8466615f8fb4ca7be3f7f5931d1dea9@sentry.io/influence"
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
)
