import gql from 'graphql-tag'

export const GET_REJECTION = gql`
  query($id: String!) {
    getRejection(id: $id) {
      id
      rejectedBy {
        id
        firstName
        lastName
        avatar
      }
      rejectedPost {
        id
        description
        author {
          firstName
          lastName
          userId
          avatar
        }
        media {
          id
          name
          size
          fileType
          duration
          link
          thumbnail
          links {
            url
            fileType
            converted
          }
        }
      }
      rejectedUser {
        id
        firstName
        lastName
        email
        phone
        role
      }
      type
      description
      createDate
      state
      allRejections {
        id
        firstName
        lastName
        avatar
        createDate
      }
    }
  }
`

export const SET_REJECTION_STATE = gql`
  mutation($id: String!, $action: RejectionAction!) {
    setRejectionState(id: $id, action: $action) {
      id
      rejectedBy {
        id
        firstName
        lastName
        avatar
      }
      rejectedPost {
        id
        description
      }
      rejectedUser {
        id
        firstName
        lastName
      }
      type
      description
      createDate
      state
    }
  }
`
