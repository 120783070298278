import React, { useState, useEffect, memo } from 'react'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useQuery } from '@apollo/react-hooks'
import { GET_GROUP_BY_ID } from 'gql/group/group.query'
import _ from 'lodash'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'

import { DrawerContent } from 'components/DrawerLayout/styled-components'
import {
  TabNavigation,
  TabPanel,
  activeTabClassName,
} from 'components/common/Tabs/Tabs'
import CustomDrawer from 'components/common/Drawer/CustomDrawer'
import GroupDetails from './GroupDetails'
import GroupStudents from '../GroupStudents/GroupStudents'

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
  },
  btn: {
    color: '#000',
  },
}

const GroupInformation = (props: any) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(0)
  const [state] = useUserValue()
  const [editDrawer, setEditDrawer] = useState(false)

  const { data, loading, error, refetch } = useQuery(GET_GROUP_BY_ID, {
    variables: {
      id: props.id,
    },
  })

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  // const closeDrawer = () => {

  //   const isEqual = _.isEqual(currentFormData, formData)
  //   if (isEqual) {
  //     closeDrawer()
  //     setFormData('')
  //     setCloseDrawerClicked(false)
  //   } else {
  //     fireSwal({
  //       title:
  //         t('popups.close_popup'),
  //       text: t('popups.sure'),
  //       onConfirm: () => {
  //         closeDrawer()
  //         setFormData('')
  //         setCloseDrawerClicked(false)
  //       },
  //       onClose: () => {
  //         setFormData('')
  //         setCloseDrawerClicked(false)
  //       },
  //       confirmText: 'Yes, Cancel!',
  //       cancelText: 'No',
  //     })
  //   }
  // }

  // console.log('data::', data)

  // useEffect(() => {
  // }, [formData])

  const groupCompanyId =
    (data && data.getGroupById.company && data.getGroupById.company.id) || null

  return (
    <Container>
      <div>
        {/* <DrawerHeader>
          <DrawerTitle>
            {t('general.group')} {t('group_details.information')}
          </DrawerTitle>
        </DrawerHeader> */}

        <TabNavigation>
          <AppBar position="static" style={styles.bar}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              <Tab
                label={t('group_details.information')}
                {...activeTabClassName(0)}
                style={styles.btn}
              />
              <Tab
                label={`${t('general.group')} ${t('general.students')}`}
                {...activeTabClassName(1)}
                style={styles.btn}
              />
              {/* <Tab label="Admins" {...activeTabClassName(2)} style={styles.btn} /> */}
              {/* <Tab
                label="Timeline"
                {...activeTabClassName(3)}
                style={styles.btn}
              /> */}
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <GroupDetails
              drawerToggle={() => setEditDrawer(true)}
              data={data}
              loading={loading}
              t={t}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <GroupStudents
              groupId={props.id}
              groupName={data && data.getGroupById.name}
              groupStatus={data && data.getGroupById.status}
              companyId={
                state.selectedCompanyId
                  ? state.selectedCompanyId
                  : groupCompanyId
              }
            />
          </TabPanel>

          {/* <TabPanel value={value} index={2}>
          <GroupAdmins />
        </TabPanel> */}

          {/* <TabPanel value={value} index={3}>
            Timeline
          </TabPanel> */}
        </TabNavigation>
      </div>

      {/* <DrawerContent headerHeight={116}>
        <CustomDrawer
          opened={editDrawer}
          toggleDrawer={() => setEditDrawer(false)}
        >
          <EditGroupDrawer
            // onCancel={closeDrawer}
            data={data && data.getGroupById}
            refetch={refetch}
            setFormData={setFormData}
            setCurrentFormData={setCurrentFormData}
            closeDrawer={() => setEditDrawer(false)}
          />
        </CustomDrawer>
      </DrawerContent> */}
    </Container>
  )
}

export default memo(GroupInformation, () => true)

const Container = styled.div``
