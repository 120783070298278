import React, { useState, useEffect } from 'react'
import { useData } from 'context/DataContext'
import { SET_FORMDATA } from 'store/types'
import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  CloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { Button } from 'components/common/Button'
import Step2 from '../AddQuizDrawer/Step2'
import { useAddRemoveQuestions } from 'hooks/quizzes/useEditQuizService'
import { GET_QUESTIONS } from 'gql/questions.query'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { useTheme } from '@material-ui/core'

const GET_QUESTIONS_BY_COURSE = gql`
  query($courseIds: [String]) {
    questionsByCourseIds(courseIds: $courseIds) {
      id
      edited
      type {
        label
        value
      }
      question
      tags {
        id
        label
      }
      answers {
        isCorrect
        value
      }
      boolAnswer
      modules {
        moduleId
        name
      }
      images {
        name
        link
        fileType
      }
      videos {
        name
      }
      documents {
        name
      }
      audios {
        name
      }
    }
  }
`

const AddQuestionsToQuiz = (props: any) => {
  const { t } = useTranslation()
  const [state, dispatch] = useData()
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)

  const [fetchModuleQuestions, { data: moduleQuestions }] = useLazyQuery(
    GET_QUESTIONS,
  )
  const [fetchCourseQuestions, { data: courseQuestions }] = useLazyQuery(
    GET_QUESTIONS_BY_COURSE,
  )

  useEffect(() => {
    if (props.type === 'course') {
      fetchCourseQuestions({
        variables: {
          courseIds: [props.id],
        },
      })
    } else {
      fetchModuleQuestions({
        variables: {
          type: props.type,
          id: props.id,
          filter: {},
          currentPage: 1,
          perPage: 12,
        },
      })
    }
  }, [props.id, props.type])

  const questionList =
    props.type === 'course'
      ? (courseQuestions && courseQuestions.questionsByCourseIds) || []
      : (moduleQuestions && moduleQuestions.getQuestions.data) || []

  const { addRemoveQuestions } = useAddRemoveQuestions()

  const addQuestions = () => {
    if (selectedItem.length > 0)
      addRemoveQuestions('add', selectedItem, props.data.quizId, () => {
        setSelectedItem([])
        props.onClose()
      })
  }

  const handleCloseDrawer = () => {
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'AddQuestionsToQuiz',
        values: selectedItem,
        compareTo: {},
      },
    })
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])


  const theme = useTheme()

  return (
    <>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{t('actions.add_questions')}</DrawerTitle>
        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>

      <DrawerContent>
        <FormContainer onSubmit={(e: any) => e.preventDefault()}>
          <FormGroupWrapper>
            <Step2
              id={props.id}
              type={props.type}
              setSelectedItem={setSelectedItem}
              selectedItem={selectedItem}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              filter={props.data.questionIds}
              questionList={questionList || []}
              t={t}
            />
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />

              <Button
                text={t('actions.save')}
                type="medium"
                color="secondary"
                onClick={addQuestions}
                background={'#317BF4'}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddQuestionsToQuiz
