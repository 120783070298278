import { useMutation } from "@apollo/react-hooks";
import { EDIT_NOTE } from "gql/notes/notes.query";

type UpdatedNote = {
  title: string
  description: string
  moment: number
  thumbnail?: string
}

export type EditNote = {
  noteId: string
  input: UpdatedNote
}

export const useEditNote = (onCompleted: () => void) => {
  const [editNoteFunction, { loading, error }] = useMutation(EDIT_NOTE, {
    onCompleted: (data) => {
      if (data) {
        onCompleted();
      }
    }
  })

  return {
    editNoteFunction,
    editNoteLoading: loading,
    editNoteError: error,
  }
};