import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  CloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import { Button } from 'components/common/Button'

import Step1 from './Step1'
import Step2 from './Step2'

import useUploadFileStream from 'hooks/group/useUploadFileStream'
import useImportStudents from 'hooks/users/useImportStudents'
import { useUserValue } from 'context/UserContext'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { useTheme } from '@material-ui/core'

const ImportStudentsDrawer = (props: any) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [step] = useState(1)
  const [file, setFile] = useState(null)
  const [selectedStudents, setSelectedStudents] = useState([])
  const [importedStudents, setImportedStudents] = useState<any>(null)
  const [imported, setImported] = useState(false)
  const [selectAll, setSelectAll] = useState(false)

  const { uploadFileStream } = useUploadFileStream()
  const { importStudents } = useImportStudents()

  const handleFileChange = (e: any) => {
    const files: any = Array.from(e.target.files)
    setFile(files[0])
    handleUploadFile(files[0])
  }

  const handleUploadFile = (file: any) => {
    if (file) {
      uploadFileStream(file, (data: any) => {
        setImportedStudents(data)
        setImported(false)
        // setStep(1)
      })
    }
  }

  const addStudentsToGroup = (selectedStudents: any, students: any) => {
    if (selectedStudents.length <= 0) return
    const data = students.filter(
      (item: any) =>
        selectedStudents.findIndex((val: any) => item.id === val) !== -1,
    )

    const { groupData, isNew, setStudents } = props

    const studentsData = data.map((i: any) => {
      const { __typename, id, isChecked, ...args } = i

      return args
    })

    importStudents(
      studentsData,
      isNew ? null : groupData.groupId,
      isNew ? null : groupData.groupName,
      state.selectedCompanyId,
      (data?: any) => {
        if (data) {
          setImported(true)
          setImportedStudents(data)
          setSelectedStudents([])
          if (props.refetch) props.refetch()
          if (isNew) {
            setStudents(data)
          }
        }
      },
    )
  }

  const handleFormSubmit = () => {
    if (imported) {
      props.onClose()
    }

    if (step === 0) {
      handleUploadFile(file)
    }

    if (step === 1 && !imported) {
      addStudentsToGroup(selectedStudents, importedStudents)
    }
  }

  const handleDeleteUsers = () => {
    if (selectedStudents.length > 0) {
      const data = importedStudents.filter(
        (i: any) => selectedStudents.findIndex((n: any) => i.id === n) === -1,
      )
      setImportedStudents(data)
      setSelectedStudents([])
    }
  }

  const getStepContent = (key: number) => {
    switch (key) {
      case 0:
        return <Step1 handleFileChange={handleFileChange} t={t} />
      case 1:
        return (
          <Step2
            setSelectedStudents={setSelectedStudents}
            selectedStudents={selectedStudents}
            importedStudents={importedStudents}
            imported={imported}
            handleFileChange={handleFileChange}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            deleteUsers={handleDeleteUsers}
            t={t}
          />
        )
      default:
        return 'Error...!'
    }
  }

  const theme = useTheme();

  return (
    <>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{t('actions.import_students')}</DrawerTitle>

        <CloseIcon
          onClick={props.onClose}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>

      <DrawerContent
        headerHeight={68}
        style={{
          margin: '100px 20px',
        }}
      >
        <FormContainer onSubmit={(e: any) => e.preventDefault()}>
          <FormGroupWrapper style={{ padding: '0 20px' }}>
            {getStepContent(step)}
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons multiCols={true}>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={props.onClose}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />

              <div />

              <Button
                text={step === 0 ? t('actions.import') : t('actions.save')}
                type="medium"
                color="secondary"
                btnType="submit"
                onClick={handleFormSubmit}
                background={mainColor}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default ImportStudentsDrawer
