import {
  SET_CURRENT_TOPIC,
  TOGGLE_DRAWER_CONFIRM,
  SET_CREATED_USER,
  SET_FORMDATA,
  CLEAR_FORMDATA,
  SET_CLOSE_DRAWER,
  SET_CURRENT_COURSE,
  TOGGLE_DARK_MODE,
} from '../types'

interface state {
  currentTopic: any
  formData: any
}

interface action {
  type: string
  payload: any
}
export const dataInitialState: any = {
  currentTopic: null,
  drawerConfirm: {
    confirm: false,
    text: '',
    values: {},
  },
  createdObject: {
    type: '',
    obj: null,
  },
  formData: {
    closeDrawerClick: false,
    type: '',
    drawer: '',
    values: {},
    compareTo: {},
  },
  darkMode: false,
}

export const dataReducer = (state: state, action: action) => {
  switch (action.type) {
    case SET_CURRENT_TOPIC:
      return { ...state, currentTopic: action.payload }

    case SET_FORMDATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          type: action.payload.type,
          drawer: action.payload.drawer,
          values: action.payload.values,
          compareTo: action.payload.compareTo,
        },
      }

    case CLEAR_FORMDATA:
      return {
        ...state,
        formData: {
          type: '',
          drawer: '',
          values: {},
          compareTo: {},
          closeDrawerClick: false,
        },
      }

    case SET_CLOSE_DRAWER:
      return {
        ...state,
        formData: {
          ...state.formData,
          closeDrawerClick: action.payload,
        },
      }

    case TOGGLE_DRAWER_CONFIRM:
      return {
        ...state,
        drawerConfirm: {
          confirm: action.payload.confirm,
          text: action.payload.text,
        },
      }

    case SET_CREATED_USER:
      return {
        ...state,
        createdObject: {
          obj: action.payload.obj,
          type: action.payload.type,
        },
      }
    
    case SET_CURRENT_COURSE: 
      return {...state, currentCourse: action.payload}

    case TOGGLE_DARK_MODE:
      return {...state, darkMode: action.payload}

    default:
      return state
  }
}
