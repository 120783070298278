import { useQuery } from '@apollo/react-hooks'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Button } from 'components/common/Button'
import { EditableAvatar } from 'components/common/EditableAvatar/EditableAvatar'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { actionConfig } from 'components/UsersLayout/gridConfig'
import { useUserValue } from 'context/UserContext'
import 'cropperjs/dist/cropper.css'
import { GET_USER } from 'gql/users.query'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useUpdateProfileAvatar } from 'hooks/users/useEditProfileService'
import useUpdateStatusUserService from 'hooks/users/useUpdateStatusUserService'
import useResendUserService from 'hooks/users/useUpdateUserPasswordService'
import { useSwal } from 'hooks/useSwal'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Text } from 'pages/courses/StudentCourse/styled'
import {
  EditAndPasswordButtons,
  UserInfoWithButtons,
  UserInfoWrapper,
  UserNameText,
  UserRoleText,
  UserShortInfo,
} from '../AdminProfile/AdminProfile'
import PersonalInformation, {
  IPersonalInformation,
} from '../AdminProfile/PersonalInformation'
import strCutter from 'utils/strCutter'

import useUserDeleteService from 'hooks/users/useDeleteUserService'
import { UserCourses } from './UserCourses'
import { mainColor } from 'components/common/Colors/CommonColors'
import ResetUsersPassword from './ResetPassword'
import { getRole } from 'utils/user-role'
import EducationListDrawer from '../AdminProfile/drawers/EducationListDrawer'
import ExperienceListDrawer from '../AdminProfile/drawers/ExperienceListDrawer'
import EditAdminProfile from '../AdminProfile/EditProfile'
import { Box, useTheme } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import { GET_REVIEWS } from 'gql/review/review.query'
import { generateFullName } from 'utils/utils'

const AllUserProfile: React.FC<{}> = ({ }) => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { fireSwal } = useSwal()
  const history = useHistory()
  const [isClickedOnEdit, setIsClickedOnEdit] = useState(false)
  const [isResetPasswordOpen, setResetPasswordOpen] = useState(false)
  const [educationListOpen, setEducationListOpen] = useState(false)
  const [experienceListOpen, setExperienceListOpen] = useState(false)


  const { data } = useQuery(GET_USER, {
    variables: { id },
  })

  let defaultFilter: any = {}

  if (data?.user?.companyId) {
    defaultFilter.companyId = {
      type: 'exact',
      value: data?.user?.companyId,
    }
  }

  const {
    updateUserStatus,
    updateUserStatusLoaing,
  } = useUpdateStatusUserService({
    role: data?.user?.role,
    defaultFilter,
  })

  const { deleteUser, deleteUserLoading } = useUserDeleteService({
    role: data?.user?.role,
    defaultFilter,
  })

  const { updateUserPassword } = useResendUserService({
    role: data?.user?.role,
    defaultFilter,
  })

  const [editUserDrawer, setEditUserDrawer] = useState<{
    role: string
    drawerName: string
  }>({} as any)

  const [profileData, setProfileData] = useState<IPersonalInformation>({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    companyId: {
      id: '',
      name: '',
    },
    createDate: new Date(),
    birthDate: new Date(),
    gender: 'prefer not to say',
    location: '',
    phone: '',
    jobTitle: '',
    biography: '',
    note: '',
    status: '',
    id: '',
    age: 0,
    phoneFields: null,
    phoneFieldValue: null,
    avatar: '',
    group: [],
  })

  useEffect(() => {
    if (data?.user) {
      const localdata = {
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        email: data.user.email,
        role: data.user.role,
        companyId: data.user.companyId,
        createDate: data.user.createDate,
        birthDate: data.user.birthDate,
        gender: data.user.gender,
        location: data.user.location,
        phone: data.user.mobileNumber,
        jobTitle: data.user.jobTitle,
        biography: data.user.biography,
        note: data.user.note,
        status: data.user.status,
        id: data.user.id,
        age: data.user.age,
        phoneFields: data.user.phoneFields,
        phoneFieldValue: data.user.phoneFields
          ? data.user.phoneFields.code
          : null,
        avatar: data.user.avatar,
        group: data.user.group
          ? data.user.group.map((g: any) => g.groupId)
          : [],
        course: data.user.courses,
      }
      setProfileData(localdata)
      let drawerName = ''
      if (data.user.role === 'SUPER_ADMIN') drawerName = 'editModerator'
      else if (data.user.role === 'ADMIN') drawerName = 'editAdmin'
      else if (data.user.role === 'COACH') drawerName = 'editCoach'
      else if (data.user.role === 'STUDENT') drawerName = 'editStudent'

      setEditUserDrawer({
        role: data.user.role,
        drawerName,
      })
    }
  }, [data])

  const handleEditClick = () => {
    DrawerEventEmitter.emit('openDrawer', editUserDrawer.drawerName, true, {
      id,
      role: editUserDrawer.role,
    })
  }

  const iscompany = data?.user?.companyId?.name

  const { updateAvatar, hasResponseReceived } = useUpdateProfileAvatar()

  const finishCrop = (
    action: any,
    crudFunction: (isFinished: boolean) => void,
    cropper: any,
  ) => {
    let cropdFile = null
    if (action === 'update') {
      cropdFile = cropper.current.getCroppedCanvas().toDataURL()
    }
    updateAvatar(cropdFile, id, action, crudFunction)
  }

  const confirmDelete = async (userId: string[]) => {
    if (data?.user?.role)
      deleteUser(userId, data.user.role, () => {
        if (data.user.role === 'SUPER_ADMIN') {
          history.push('/users/super-admins')
        } else history.push(`/users/${data.user.role.toLowerCase()}s`)
      })
  }

  const confirmChangeStatus = (userId: string, user: any) => {
    updateUserStatus([userId], user)
  }

  const handleDeleteClick = (user: any) => {
    let params: any = {}

    if (profileData.role === 'ADMIN' && user.group.length !== 0) {
      params = {
        title: t('popups.can_not_delete_admin'),
        onConfirm: () => {
          history.push('/groups')
        },
        confirmText: t('popups.go_to_groups'),
      }
    } else {
      params = {
        title: `${t('popups.delete_single')} ${profileData.role === 'SUPER_ADMIN'
          ? t('general.super_admin')
          : t(
            `general.${profileData.role &&
            profileData.role.toLocaleLowerCase()}`,
          )
          }?`,
        onConfirm: () => {
          confirmDelete([user.id])
        },
        confirmText: t('popups.confirm_delete'),
      }
    }
    fireSwal(params)
  }

  const confirmResendPassword = (id: string) => {
    updateUserPassword(id)
  }

  const handleResendPasswordClick = (userId: string) => {
    fireSwal({
      title: `${t('popups.resend_password')} ${profileData.role === 'SUPER_ADMIN'
        ? t('general.super_admin')
        : t(
          `general.${profileData?.role &&
          profileData.role.toLocaleLowerCase()}`,
        )
        }?`,
      onConfirm: () => {
        confirmResendPassword(userId)
      },
      confirmText: t('popups.confirm_resend_password'),
    })
  }

  const handleChangeStatusClick = (userId: string, status: string) => {
    if (status === 'ACTIVE' && profileData?.role) {
      fireSwal({
        title: `${t('popups.suspend_single')} ${profileData.role === 'SUPER_ADMIN'
          ? t('general.super_admin')
          : t(`general.${profileData.role.toLocaleLowerCase()}`)
          }?`,
        onConfirm: () => {
          confirmChangeStatus(userId, 'SUSPENDED')
        },
        confirmText: t('popups.confirm_suspend'),
      })
    } else {
      fireSwal({
        title: `${t('popups.activate_single')} ${profileData.role === 'SUPER_ADMIN'
          ? t('general.super_admin')
          : t(
            `general.${profileData.role &&
            profileData.role.toLocaleLowerCase()}`,
          )
          }?`,
        onConfirm: () => {
          confirmChangeStatus(userId, 'ACTIVE')
        },
        confirmText: t('popups.confirm_activate'),
      })
    }
  }

  const [state] = useUserValue()

  let field = ''

  switch (profileData.role) {
    case 'MODERATOR':
      field = 'Moderator'
      break
    case 'SUPER_ADMIN':
      field = 'SuperAdmin'
      break
    case 'STUDENT':
      field = 'Student'
      break
    case 'COACH':
      field = 'Coach'
      break
    case 'ADMIN':
      field = 'Admin'
      break
    default:
      break
  }

  const actions = actionConfig(
    handleEditClick,
    handleDeleteClick,
    handleResendPasswordClick,
    handleChangeStatusClick,
    state.userPermission,
    field,
    state.currentUser.role,
    t,
    setResetPasswordOpen,
    state.currentUser.id,
  )

  const review = useQuery(GET_REVIEWS, {
    variables: {
      ratedId: id,
    }
  })

  const theme = useTheme()



  return (
    <div>
      <>
        <UserInfoWrapper>
          <EditableAvatar
            img={profileData.avatar}
            finishCrop={finishCrop}
            hasResponseReceived={hasResponseReceived}
          />
          <UserInfoWithButtons>
            <UserShortInfo>
              <UserNameText theme={theme}>
                {strCutter(generateFullName(profileData), 80)}
              </UserNameText>
              <UserRoleText theme={theme}>
                {getRole(profileData.role || 'N/A', iscompany)}
              </UserRoleText>
              {profileData?.role === 'COACH' && (
                <Box display={'flex'} alignItems={'center'}>

                  <Text fs={'28px'} weight={600} color={theme.palette.text.primary} mr={'5px'}>
                    {review?.data?.getReviews?.averageRating || '0.0'}
                  </Text>
                  <Rating precision={0.1} value={review?.data?.getReviews?.averageRating || '0.0'} disabled />
                  <Text onClick={() => history.push(`${id}/review`)} fs={'16px'} color={theme.palette.text.primary} ml={'20px'}>
                    {review.data?.getReviews?.count} {t("general.review")}
                  </Text>
                </Box>
              )}
            </UserShortInfo>

          </UserInfoWithButtons>
        </UserInfoWrapper>
        {isResetPasswordOpen && (
          <ResetUsersPassword
            isModalOpen={isResetPasswordOpen}
            setModalOpen={setResetPasswordOpen}
            userId={id}
          />
        )}
        <PersonalInformation
          user={profileData}
          updateProfile={() => setIsClickedOnEdit(true)}
          setEducationListOpen={setEducationListOpen}
          setExperienceListOpen={setExperienceListOpen}
          t={t}

        />
        {isClickedOnEdit && (
          <EditAdminProfile
            setIsClickedOnEdit={setIsClickedOnEdit}
            initialValues={profileData}
          />
        )}

        {
          profileData?.role === 'STUDENT' &&
          profileData?.group && (<>
            <div style={{ margin: '50px 0px' }}>
              <UserCourses
                stat
                userId={profileData?.id}
                groups={profileData?.group}
                isDone={false}
                companyId={profileData?.companyId?.id || null}
              />
            </div>

            <div style={{ margin: '50px 0px' }}>
              <UserCourses
                stat
                userId={profileData?.id}
                isDone={true}
                groups={profileData?.group}
                companyId={profileData?.companyId?.id || null}
              />
            </div>
          </>
          )}

        {educationListOpen && (
          <EducationListDrawer
            open={educationListOpen}
            id={id}
            onClose={() => setEducationListOpen(false)}
          />
        )}
        {experienceListOpen && (
          <ExperienceListDrawer
            open={experienceListOpen}
            id={id}
            onClose={() => setExperienceListOpen(false)}
          />
        )}
      </>
    </div>
  )
}

export default AllUserProfile
