import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import Button from '@material-ui/core/Button'
import Checkbox from 'components/common/Checkbox'
import { generateFullName, formatDateAgo, getUserRole } from 'utils/utils'
import Drawer from 'components/common/Drawer'
import { RejectionDetailsDrawer } from 'pages/rejections'
import { mainColor } from 'components/common/Colors/CommonColors'
import { PlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'
import { object } from 'yup'

const NotificationList = (props: any) => {
  const [rejectionDrawerOpened, setRejectionDrawerOpened] = useState<boolean>(
    false,
  )
  const [notificationView, setNotificationView] = useState<boolean>(false)
  const [rejection, setRejection] = useState<any>(null)

  const renderTitle = (notification: any) => {
    if (notification.objectName === 'Rejection') {
      return notification.actionName === 'Post'
        ? `${notification.actionName} By`
        : `${notification.actionName}`
    } else {
      const owner = notification.owner
        ? notification.owner.companyId
          ? 'Company'
          : PlatformTitle
        : ''
      return `${owner} ${getUserRole(
        notification.owner ? notification.owner.role : '',
      )}`
    }
  }

  const handleRejectionViewClick = (rejection: any,) => {
    setRejectionDrawerOpened(true)

    setRejection(rejection)
  }

  useEffect(() => {
    if (props.id) {
      const filteredNotification = props.notificationList.filter(
        (notification: any) => {
          return notification.id == props.id
        },
      )

      if (filteredNotification[0]) {
        if (filteredNotification[0].objectName != "Rejection") {
          setNotificationView(true)
        }

        handleRejectionViewClick(filteredNotification[0])
      }

    }
  }, [props.id, props.notificationList])

  return (
    <NotificationContainer>
      <Drawer
        opened={rejectionDrawerOpened}
        toggleDrawer={() => setRejectionDrawerOpened(false)}
        totalWidth="560px"
      >
        <RejectionDetailsDrawer
          onClose={() => { setRejectionDrawerOpened(false); setNotificationView(false) }}
          data={rejection}
          notificationView={notificationView}
        />
      </Drawer>

      {props.notificationList.map((i: any, index: any) => (
        <NotificationItem
          key={index}
          bcColor={i.state === 'read' ? '#fff' : 'rgba(236,235,235,0.7)'}
        >
          <ListWrapper align="center">
            {i.objectName !== 'Rejection' && (
              <CheckboxWrapper>
                <Checkbox
                  onChange={() => props.selectItem(i.id)}
                  checked={props.selectedItem.includes(i.id)}
                />
              </CheckboxWrapper>
            )}

            <AvatarContainer>
              {i.objectName === 'Rejection' ? (
                <ReportProblemIcon />
              ) : (
                i.owner && <img src={i.owner ? i.owner.avatar : ''} alt="" />
              )}
            </AvatarContainer>

            <ContentWrapper>
              <div>
                <TitleContainer>
                  <UserRole>{renderTitle(i)}</UserRole>
                  <NameContainer>{generateFullName(i.owner)}</NameContainer>
                </TitleContainer>

                <EventContainer>
                  <span>{i.message}</span>
                  {i.objectName !== 'Rejection' && (
                    <NameContainer> {i.actionName} </NameContainer>
                  )}
                  {i.additionalText && (
                    <span style={{ marginLeft: '2px' }}>
                      {i.additionalText}
                    </span>
                  )}
                  {i.company && (
                    <NameContainer style={{ marginLeft: '2px' }}>
                      {i.company.name}
                    </NameContainer>
                  )}
                </EventContainer>
              </div>

              <div>
                {i.objectName === 'Rejection' && (
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      color: mainColor,
                      border: '1px solid #317BF4',
                    }}
                    onClick={() => handleRejectionViewClick(i)}
                  >
                    View
                  </Button>
                )}

                <DateContainer>
                  {formatDateAgo(
                    moment(parseInt(i.createDate)).format('DD MMM YYYY h:mm A'),
                  )}
                </DateContainer>
              </div>
            </ContentWrapper>
          </ListWrapper>
        </NotificationItem>
      ))}
    </NotificationContainer>
  )
}

export default NotificationList

const NotificationContainer = styled.ul`
  margin: 0;
  padding: 0;
`

const NotificationItem = styled.li<{ bcColor: string }>`
  box-sizing: border-box;
  padding: 0px 15px;
  background: ${({ bcColor }) => bcColor};
  transition: 0.2s;
  &:hover {
    background: #fff;
  }
`

const ListWrapper = styled.div<{ align?: string }>`
  display: flex;
  align-items: ${({ align }) => (align ? align : 'stretch')};
  border-bottom: 1px solid #d9dce0;
  box-sizing: border-box;
  padding: 17px 0px;
  position: relative;
`

const CheckboxWrapper = styled.div``

const ContentWrapper = styled.div`
  margin-left: 20px;
  margin-top: -2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
`

const AvatarContainer = styled.div`
  margin-left: 10px;
  img {
    border-radius: 50%;
    width: 37px;
    height: 37px;
  }
`
const NameContainer = styled.div`
  color: ${mainColor};
  font-size: 15px;
  margin-left: 5px;
  white-space: nowrap;
`
const EventContainer = styled.div`
  color: #333;
  font-size: 14px;
  opacity: 0.8;
  display: flex;
  align-items: center;
  span {
    white-space: nowrap;
  }
`
const DateContainer = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

const UserRole = styled.div`
  text-transform: capitalize;
`
