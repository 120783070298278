import React, { useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PER_PAGE, useFetchUsers } from 'hooks/users/useUsers'
import { useUserValue } from 'context/UserContext'
import { columnConfig } from '../GroupStudents/studentsGridconfig'
import IconButton from '@material-ui/core/IconButton'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import {
  CloseIcon,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Info,
} from 'components/DrawerLayout/styled-components'
import { Button } from 'components/common/Button'
import {
  FormButtons,
  FormContainer,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form'

import Grid from 'components/common/Grid'
import TablePagination from 'components/common/Pagination/TablePagination'
import TextInput from 'components/common/TextInput/TextInput'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import Search from 'components/common/Search'
import { useTheme } from '@material-ui/core'

const StudentListDrawer = (props: any) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [selectedItem, setSelectedItem] = useState<any>([])
  const [selectAll, setSelectAll] = useState<any>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [searchValue, setSearchValue] = useState<string>('')
  const studentsIds = props.selectedStudents.map((student: any) => student.id)
  let filter: any = {
    companyId: {
      type: 'exact',
      value: props.manualCompanyId
        ? props.manualCompanyId
        : state.selectedCompanyId,
    },

    _id: {
      type: 'arrayNotIn',
      value: studentsIds,
    },
  }

  const { users: usersData, loading, error, refetch }: any = useFetchUsers(
    'STUDENT',
    {
      ...filter,
    },
  )

  const selectItem = (id: never, event: boolean) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = usersData.data.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }
  const users = (usersData && usersData.data) || []

  const onFrmSubmit = () => {
    if (selectedItem.length <= 0) return
    const selectedData = users.filter(
      (i: any) =>
        selectedItem[currentPage].findIndex((val: any) => i.id === val) !== -1,
    )
    props.onFormSubmit(selectedData)
    setSelectedItem([])
    props.setSelectedStudents([])
    props.onCancel()
  }
  const handleSearchChange = (e: any) => {
    setSearchValue(e.currentTarget.value)
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    const formValue = {
      search: {
        type: 'search',
        value: searchValue,
        fields: ['firstName', 'lastName', 'email', 'phone'],
      },
    }
    let filterData = { ...filter }
    if (searchValue !== '') {
      filterData = { ...filter, ...formValue }
    } else {
      const { search, ...filters } = filter
      filterData = { ...filters }
    }

    refetch({
      role: 'STUDENT',
      filter: filterData,
      currentPage: 1,
      perPage,
    })
  }

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    refetch({
      role: 'STUDENT',
      filter: { ...filter },
      currentPage: newPage + 1,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    refetch({
      role: 'STUDENT',
      filter: { ...filter },
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
    setSelectedItem({})
  }

  const students = users.filter(
    (item: any) =>
      props.selectedStudents.findIndex((val: any) => item.id === val.id) === -1,
  )
  const config = columnConfig(selectItem, false, t)

  const theme = useTheme();

  return (
    <Container>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{t('actions.add_student')}</DrawerTitle>
        <CloseIcon
          onClick={props.onCancel}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <Toolbar styles={{ justifyContent: 'space-between' }}>
        <div />
        <ToolbarItem>
          <form onSubmit={handleSearchSubmit}>
            <Search
              inputProps={{
                value: searchValue,
                onChange: handleSearchChange,
              }}
              onClick={handleSearchChange}
            />
          </form>
        </ToolbarItem>
      </Toolbar>
      <DrawerContent
        headerHeight={168}
        style={{
          margin: '100px 20px',
        }}
      >
        <FormContainer>
          <FormGroupWrapper>
            <Grid
              data={users || []}
              config={config}
              selected={_.size(selectedItem[currentPage])}
              selectAllItem={selectAllItem}
              selectedItems={selectedItem[currentPage] || []}
              openInExternalTab={true}
            />
            {usersData && usersData.totalCount !== 0 && (
              <TablePagination
                currentPage={usersData ? usersData.currentPage : 0}
                rowsPerPage={perPage}
                count={usersData ? usersData.totalCount : 0}
                handleChangePage={handlePaginationClick}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={props.onCancel}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.add')}
                color="secondary"
                type="medium"
                isDisabled={
                  !!(
                    !selectedItem[currentPage] ||
                    (selectedItem[currentPage] &&
                      selectedItem[currentPage].length <= 0)
                  )
                }
                onClick={onFrmSubmit}
                background={'#317BF4'}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </Container>
  )
}

export default StudentListDrawer

const Container = styled.div`
  box-sizing: border-box;
  padding: 25px;
  height: 100%;
`
const Title = styled.div`
  font-size: 23px;
  font-weight: 500;
  padding-bottom: 80px;
`
