import React, { memo, useState, useRef, useEffect } from 'react'

import { ActionsDropDown, ActionsButton } from './styled_components'
import { Button } from '../Button'
import Icon from '../../../components/Icon'
import { mainColor } from '../Colors/CommonColors'
import { useTheme } from '@material-ui/core'

const DropdownButton = ({ title, buttons, backgroundColor = mainColor, textColor = "#fff", iconColor = "#fff" }) => {
  const [toggle, setToggle] = useState(false)
  const btnRef = useRef(null)
  const theme = useTheme();

  useEffect(() => {
    const handleClickOutside = event => {
      if (btnRef.current && !btnRef.current.contains(event.target)) {
        setToggle(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [toggle])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Button
        text={title}
        buttonStyles={{
          borderRadius: 16,
        }}
        width={174}
        onClick={() => {
          setToggle(!toggle)
        }}
        background={backgroundColor}
        textColor={textColor}
        icon={<Icon filename="dropdownIndicator" fill={iconColor}></Icon>}
        iconPosition="right"
        referance={btnRef}
      />
      <ActionsDropDown theme={theme} visible={toggle}>
        {buttons.map(button => (
          <ActionsButton
            onClick={() => {
              button.onClick()
              setToggle(false)
            }}
          >
            {button.title}
          </ActionsButton>
        ))}
      </ActionsDropDown>
    </div>
  )
}

export default memo(DropdownButton, (prevProps, nextProps) => {
  // if (prevProps.value !== nextProps.value) return false
  // if (!prevProps.list.equals(nextProps.list)) return false
  return false
})
