import { useEffect, useState } from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import BusinessIcon from '@material-ui/icons/Business'
import EmailIcon from '@material-ui/icons/Email'
import DomainIcon from '@material-ui/icons/Domain'
import EventIcon from '@material-ui/icons/Event'
import GroupIcon from '@material-ui/icons/Group'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox'
import PersonIcon from '@material-ui/icons/Person'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import PublicIcon from '@material-ui/icons/Public'
import { Button } from 'components/common/Button'
import { mainColor } from 'components/common/Colors/CommonColors'
import { EditableAvatar } from 'components/common/EditableAvatar/EditableAvatar'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { useUserValue } from 'context/UserContext'
import 'cropperjs/dist/cropper.css'
import { DrawerEventEmitter } from 'helpers/drawer'
import useDeactivateActivateCompany from 'hooks/companies/useDeactivateActivateCompany'
import useDeleteCompanyService from 'hooks/companies/useCompanyDeleteService'
import { useUpdateCompanyAvatar } from 'hooks/companies/useEditCompanyAvatarService'
import { useSwal } from 'hooks/useSwal'
import moment from 'moment'
import { actionConfig } from 'pages/companies/gridConfig'
import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import strCutter from 'utils/strCutter'
import {
  CompanyContent,
  Container,
  ContainerContent,
  ContainerHeader,
  ContenGrid,
  ContenGridItem,
  ContinueBtn,
  NoValueWrapper,
  Wrapper,
  PlatformServiceSwitcher,
  CompanyTitle,
  NoteContainer,
} from './styled-components'
import { CompanyInformationParams } from './types'
import CoverImage from 'components/common/CoverImage/CoverImage'
import { useUpdateCompanyCoverImage } from 'hooks/companies/useUpdateCompanyCoverImage'
import CompanyServiceToggle from './CompanyServiceToggle'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

const CompanyInformation = ({
  company,
  t,
  refetch,
}: CompanyInformationParams) => {
  const history = useHistory()
  const { fireSwal } = useSwal()
  const [state] = useUserValue()
  const {
    companyActivationDeactivationToogle,
    data,
  } = useDeactivateActivateCompany()
  const [isWenrollUser, setIsWenrollUser] = useState<boolean>()

  useEffect(() => {
    setIsWenrollUser(!state.currentUser.companyId?.id)
  }, [state.currentUser.companyId?.id])

  const { deleteCompany } = useDeleteCompanyService({
    filter: {},
  })

  const confirmDelete = async (ids: string[]) => {
    deleteCompany(ids, () => history.push('/companies'))
  }

  const handleEditClick = () => {
    DrawerEventEmitter.emit('openDrawer', 'companiesEdit', true, {
      id: company.id,
      data: () => { },
    })
  }

  const handleDeleteClick = () => {
    const params = {
      title: `${t('popups.are_you_sure')} ${t('actions.delete')} ${t(
        'popups.this',
      )} ${t('general.company')}`,
      onConfirm: () => {
        confirmDelete([company.id])
      },
      confirmText: t('popups.confirm_delete'),
    }
    fireSwal(params)
  }

  const handleChangeStatusClick = (companyId: string, status: string) => {
    if (status === 'ACTIVE' || status === 'DEACTIVATED') {
      fireSwal({
        title: `${status === 'ACTIVE'
          ? t('company_details.deactivate_company')
          : t('company_details.activate_company')
          }`,
        onConfirm: () => {
          companyActivationDeactivationToogle(companyId)
        },
        confirmText: t('general.yes'),
      })
    }
  }

  useEffect(() => {
    if (data?.updateCompanyStatus?.message === 'Success') {
      refetch()
    }
  }, [data])

  const actions = actionConfig(
    null,
    handleDeleteClick,
    handleChangeStatusClick,
    () => { },
    state.userPermission,
    t,
    isWenrollUser,
  )

  const { updateAvatar, hasResponseReceived } = useUpdateCompanyAvatar()
  const {
    updateCoverImage,
    hasResponseReceived_Cover,
  } = useUpdateCompanyCoverImage()

  const isCompanySuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !!state?.currentUser?.companyId?.id

  const isCompanyAdmin =
    state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  const isSuperAdmin = state?.currentUser?.role === 'SUPER_ADMIN'

  const isAdmin = state?.currentUser?.role === 'ADMIN'

  const finishCrop = (
    action: any,
    crudFunction: (isFinished: boolean) => void,
    cropper: any,
  ) => {
    let cropdFile = null
    if (action === 'update') {
      cropdFile = cropper.current.getCroppedCanvas().toDataURL()
      action = 'ADD'
    } else if (action === 'delete') {
      action = 'DELETE'
    }
    updateAvatar(company.id, action, cropdFile, crudFunction)
  }

  const finishCoverCrop = (
    action: any,
    crudFunction: (isFinished: boolean) => void,
    cropper: any,
  ) => {
    let cropdFile = null
    if (action === 'update') {
      cropdFile = cropper.current.getCroppedCanvas().toDataURL()
      action = 'ADD'
    } else if (action === 'delete') {
      action = 'DELETE'
    }
    updateCoverImage(company.id, action, cropdFile, crudFunction)
  }

  const theme = useTheme()


  return (
    <>
      <div style={{ height: '450px', width: '100%' }}>
        <CoverImage
          img={company?.coverImage ? company.coverImage : '/img/moduleAvatar.jpeg'}
          finishCrop={finishCoverCrop}
          hasResponseReceived={hasResponseReceived_Cover}
        />
        <Wrapper
          style={{
            position: 'absolute',
            marginLeft: '150px',
            marginTop: '-100px',
            width: 'calc(100% - 210px)',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Wrapper>
            <EditableAvatar
              img={company?.avatar}
              finishCrop={finishCrop}
              isRectangular={true}
              hasResponseReceived={hasResponseReceived}
            />
            <CompanyContent>
              {!isCompanySuperAdmin && !isCompanyAdmin && (
                <div>
                  <CompanyTitle theme={theme}>{strCutter(company?.name, 35)} </CompanyTitle>
                  <div style={{ fontSize: 14, color: '#080A0A54' }}>{company?.companyActivity ? (
                    strCutter(company?.companyActivity[0]?.label, 25)
                  ) : null}</div>
                </div>
              )}

              {!state.currentUser.companyId && !(isSuperAdmin || isAdmin) && (
                <Wrapper align="center">
                  <Wrapper align="center">
                    <PlatformServiceSwitcher>
                      <span style={{ color: '#b3b3b3' }}>
                        {t('general.disabled')}
                      </span>
                      <CompanyServiceToggle
                        id={company?.id}
                        platformService={company?.platformService}
                        name={company?.name}
                      />{' '}
                      <span className="enabled">{t('general.enabled')}</span>
                    </PlatformServiceSwitcher>
                  </Wrapper>
                </Wrapper>
              )}
            </CompanyContent>
          </Wrapper>

          <StyledWrapper>
            {company?.finished !== 7 && (
              <StyledButtonContainer>
                <SetupsContainer>{company?.finished + 1}/7</SetupsContainer>
                <span>Steps Done</span>
                <ContinueBtn
                  onClick={() => history.push(`/create-company/${company?.id}`)}
                >
                  {t('companies_layout.continue_setup')}
                </ContinueBtn>
              </StyledButtonContainer>
            )}{' '}
            {!isCompanySuperAdmin && !isCompanyAdmin && (
              <StyledActionsContainer>
                <GridActionMenu
                  actionConfig={actions}
                  row={company}
                  icon={
                    <Button
                      text={t('general.actions')}
                      size="small"
                      icon={<ArrowDropDownIcon />}
                      iconPosition="right"
                      background={mainColor}
                    />
                  }
                />
              </StyledActionsContainer>
            )}
          </StyledWrapper>
        </Wrapper>
      </div>

      {isCompanySuperAdmin || isCompanyAdmin ? (
        <Container>
          <ContainerContent>
            <ContenGrid
              style={{
                gridTemplateColumns: 'repeat(4, 1fr)',
              }}
            >
              <ContenGridItem theme={theme}>
                <IconContainer>
                  <Icon filename={'building'} color={theme.palette.text.primary} />
                </IconContainer>
                {company?.name}
              </ContenGridItem>
              <ContenGridItem theme={theme}>
                <IconContainer>
                  <Icon filename={'calendar'} color={theme.palette.text.primary} />
                </IconContainer>
                {moment(parseInt(company?.createDate)).format('DD.MM.YYYY')}
              </ContenGridItem>
              <ContenGridItem theme={theme}>
                <IconContainer>
                  <Icon filename={'profile'} color={theme.palette.text.primary} />
                </IconContainer>
                {company?.numberOfUsers || 0}
              </ContenGridItem>
              <ContenGridItem theme={theme}>
                <IconContainer>
                  <Icon filename={'groups'} color={theme.palette.text.primary} />
                </IconContainer>
                {company?.groupsCount || 0}
              </ContenGridItem>
            </ContenGrid>
          </ContainerContent>
        </Container>
      ) : (
        <Container>
          <ContainerHeader theme={theme}>
            {t('company_details.company_details')}
            <Icon
              style={{ marginLeft: '10px' }}
              filename={'edit'}
              cursor={'pointer'}
              fill={theme.palette.text.primary}
              onClick={() => handleEditClick()}
            />
          </ContainerHeader>
          <ContainerContent>
            <ContenGrid>
              <ContenGridItem theme={theme}>
                <IconContainer>
                  <Icon filename={'call'} color={theme.palette.text.primary} />
                </IconContainer>
                {company?.phoneFields &&
                  company?.phoneFields.code &&
                  company?.phone ? (
                  `${company?.phoneFields.code} ${company.phone}`
                ) : (
                  <NoValueWrapper>N/A</NoValueWrapper>
                )}
              </ContenGridItem>
              <ContenGridItem theme={theme}>
                <IconContainer>
                  <Icon filename={'message'} color={theme.palette.text.primary} />
                </IconContainer>
                {strCutter(company?.companyEmail, 30)}
              </ContenGridItem>
              <ContenGridItem theme={theme}>
                <IconContainer>
                  <Icon filename={'location'} color={theme.palette.text.primary} />
                </IconContainer>
                {strCutter(company?.city, 25) || (
                  <NoValueWrapper>N/A</NoValueWrapper>
                )}
              </ContenGridItem>
              <ContenGridItem theme={theme}>
                <IconContainer>
                  <Icon filename={'building'} color={theme.palette.text.primary} />
                </IconContainer>
                {company?.companyActivity ? (
                  strCutter(company?.companyActivity[0]?.label, 25)
                ) : (
                  <NoValueWrapper>N/A</NoValueWrapper>
                )}
              </ContenGridItem>
              <ContenGridItem theme={theme}>
                <IconContainer>
                  <Icon filename={'document'} color={theme.palette.text.primary} />
                </IconContainer>
                {strCutter(company?.keyContactName, 25)}
              </ContenGridItem>
              <ContenGridItem theme={theme}>
                <IconContainer>
                  <Icon filename={'calendar'} color={theme.palette.text.primary} />
                </IconContainer>
                {moment(parseInt(company?.createDate)).format('DD.MM.YYYY')}
              </ContenGridItem>
              {/* <ContenGridItem>
                <GroupIcon style={{ color: mainColor }} />
                <strong>{t('company_details.number_of_users')}:</strong>{' '}
                {company.numberOfUsers || 0}
              </ContenGridItem>
              <ContenGridItem>
                <MarkunreadMailboxIcon style={{ color: mainColor }} />
                <strong>{t('company_details.postal_code')}:</strong>{' '}
                {company.postalCode || <NoValueWrapper>N/A</NoValueWrapper>}
              </ContenGridItem>

              <ContenGridItem>
                <LocationOnIcon style={{ color: mainColor }} />
                <strong>{t('company_details.address')}:</strong>{' '}
                {strCutter(company.address, 25) || (
                  <NoValueWrapper>N/A</NoValueWrapper>
                )}
              </ContenGridItem>
              <ContenGridItem>
                <PublicIcon style={{ color: mainColor }} />
                <strong>{t('company_details.country')} :</strong>{' '}
                {strCutter(company.country, 25) || (
                  <NoValueWrapper>N/A</NoValueWrapper>
                )}
              </ContenGridItem> */}
            </ContenGrid>
          </ContainerContent>
        </Container>
      )}
      {!isCompanySuperAdmin && !isCompanyAdmin && company.note && (
        <NoteContainer style={{ marginTop: '30px' }}>
          <div className={'noteTitle'}>Note</div>
          <div className={'noteText'}>{company.note}</div>
        </NoteContainer>
      )}
    </>
  )
}
export default CompanyInformation

const StyledWrapper = styled.div`
  position: relative;
  height: 175px;
`

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 85px;
  span {
    // font-weight: 600;
    color: #fff;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 3px;
  }
`

const SetupsContainer = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
`

const StyledActionsContainer = styled.div`
  position: absolute;
  top: 120px;
  right: 30px;
  display: flex;
`

const CompanyInfoWrapper = styled.div`
  border: 2px solid #ededed;
  border-radius: 10px;
  margin-top: 100px;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 500px) {
    display: none;
  }
`

const CompanyInfoItem = styled.div`
  margin: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  strong {
    margin: 0 8px;
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: #080a0a0a;
  border-radius: 12px;

  svg {
    width: 17px;
    height: 17px;
    fill: #080a0ad6;
  }
`
