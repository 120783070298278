import React, { useState, useEffect } from 'react'
import { Box, Select, useTheme } from '@material-ui/core'
import { GlobalUserDefaultImage } from 'utils/utils'
import { GetCourseById } from '../types'
import * as S from './styled'
import * as Styled from '../Module/styled'
import Quizz from "../Module/quizz"
import Icon from 'components/Icon'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import SelectBtn from 'components/common/SelectBtn'
import DropdownButton from 'components/common/DropdownButton'
import { useUserValue } from 'context/UserContext'
import { useQuery } from '@apollo/react-hooks'
import { GET_COACH_BY_COURSEID } from 'gql/couch/couch.query'
import { Rating } from '@material-ui/lab'
import { Text } from '../styled'
import { Button } from 'components/common/Button'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import moment from 'moment'

export type AboutCourseProps = {
  course: GetCourseById,
  disabled?: boolean,
  isStat?: boolean,
  userStatistics?: boolean,
  portfolio?: any
}

const AboutCourse: React.FC<AboutCourseProps> = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const [state] = useUserValue();
  const [showQuizResults, setShowQuizResults] = useState(true)
  const [showPersonalAssignments, setShowPersonalAssignments] = useState(true)
  const [groupId, setGroupId] = useState<any>("")
  const params = useParams<{ id: string }>()

  const { data } = useQuery(GET_COACH_BY_COURSEID, {
    variables: {
      courseId: props.course.id
    }
  })
  const theme = useTheme();



  useEffect(() => {
    const duplicates = props?.course?.groups?.filter(value => state.currentUser.group?.some((el: any) => el.groupId === value.groupId));

    setGroupId(duplicates?.[0]?.groupId)
  }, [params.id])

  const companyUser = state.currentUser.role === 'STUDENT' && !state.currentUser.isRegistered;

  return (
    <S.Container>
      <S.Header>
        {
          props.course.isOwnCourse
            ?
            <S.CoachContainer onClick={() => history.push(`/profile/${data?.getCoachByCourseId[0].coachId}`)}>
              <S.CoachItem
                image={data?.getCoachByCourseId?.[0]?.avatar || GlobalUserDefaultImage}
              >
              </S.CoachItem>

              <S.CoachName>
                <span>{data?.getCoachByCourseId?.[0]?.name || ''}</span>
              </S.CoachName>
              <S.RatingBox>
                <S.ReviewNumber>
                  {data?.getCoachByCourseId?.[0]?.averageRating?.toFixed(1) || '0.0'}
                </S.ReviewNumber>
                <Rating
                  value={data?.getCoachByCourseId?.[0]?.averageRating || 0}
                  disabled
                />
              </S.RatingBox>

            </S.CoachContainer>

            :
            <S.CoachContainer>
              {props.course.coaches?.map((coache, index) => {

                return <S.CoachItem
                  key={coache.id}
                  onClick={() => history.push(`/profile/${coache.userId}`)}
                  image={coache.avatar || GlobalUserDefaultImage}
                  style={
                    props.course.coaches!.length <= index + 1
                      ? { margin: '0px' }
                      : { marginRight: '-24px' }
                  }
                />
              })}
              {(props.course.coaches?.length || 0) > 0 && (
                <S.CoachTitle>
                  <span>{props.course.coaches?.length || 0}</span>
                  <span>{t('general.coach')}</span>
                </S.CoachTitle>
              )}
            </S.CoachContainer>

        }
        {!props.disabled && !props.isStat && state.currentUser.role !== 'STUDENT' && (
          <DropdownButton
            title={t('general.myGroups')}
            backgroundColor={'#080A0A10'}
            textColor={'#080A0A'}
            iconColor={'#080A0A36'}
            buttons={props.course.groups && props.course.groups.map(g => ({
              title: g.name,
              onClick: () => history.push(`/group/${g.groupId}`, props.course)
            }))} />
        )}
        {
          companyUser && (
            <Link
              to={`/group/${groupId}`}
              style={{ fontWeight: "bold", width: "144px", fontSize: "13px", padding: "14px 22px", backgroundColor: "#080A0A0A", borderRadius: "16px" }}>
              {t('general.myGroup')} <ChevronRightIcon fontSize="small" style={{ color: "#080A0A5C" }}
              />
            </Link>
          )
        }
      </S.Header>
      <S.GeneralInfo>
        <Box display={'flex'} style={{ gap: '30px', marginBottom: '40px' }}>
          {props.course.numberOfStudents && (
            <S.GeneralItem>
              <Icon filename={'students'} />
              {`${props.course.numberOfStudents} ${props.course.numberOfStudents > 1 ? t('general.students') : t('general.student')}`}
            </S.GeneralItem>
          )}
          {props.course.level && (
            <S.GeneralItem>
              <Icon filename={'chart'} />
              {`${t(`courses_layout.${props.course.level}`)}`}
            </S.GeneralItem>
          )}

          <S.GeneralItem>
            <Icon filename={'testing'} />
            {props.course.certificateIncluded ? t('general.include_certificate') : t('general.not_include_certificate')}
          </S.GeneralItem>

        </Box>
        {(props.course.categories?.length || 0) > 0 && (
          <Box
            display={'flex'}
            alignItems={'center'}
            style={{ gap: '10px', marginBottom: '10px' }}
          >
            <span style={{ marginRight: '10px' }}>{t('general.category')}:</span>
            {props.course.categories?.map(category => (
              <S.CardItem theme={theme}>{category.name}</S.CardItem>
            ))}
          </Box>
        )}
        {(props.course.skills?.length || 0) > 0 && (
          <Box display={'flex'} alignItems={'center'} style={{ gap: '10px' }}>
            <span style={{ marginRight: '10px' }}>{t('general.skills')}:</span>
            {props.course.skills?.map(skill => (
              <S.CardItem theme={theme}>{skill.label}</S.CardItem>
            ))}
          </Box>
        )}
      </S.GeneralInfo>
      {props.course.description && (
        <S.Description style={{ marginTop: '30px' }}>
          <div>{t('general.review')}</div>
          <span>{props.course.description}</span>
        </S.Description>
      )}

      {props?.userStatistics &&
        <div style={{ margin: '30px 0px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h3>{props?.userStatistics && t('general.quizResults')}</h3>
            <div
              onClick={() => setShowQuizResults(prevState => !prevState)}
              style={{ cursor: 'pointer', background: '#080A0A0A', borderRadius: '12px', height: '36px', width: '36px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Icon
                filename='down_arrow'
                style={{
                  transition: '0.3s',
                  transform: `rotate(${showQuizResults ? 0 : 180}deg)`,
                }}
              />
            </div>
          </div>
          {/* {showQuizResults && props?.portfolio?.quizzes?.map((quiz: any) => (
            <div style={{
              padding: '20px 10px 0px 60px',
              border: '1px solid #080A0A0A',
              borderRadius: '16px',
              margin: '16px',
            }}>
              {console.log(props?.portfolio?.quizzes, " props?.portfolio?.quizzes")}
              <Text fs={'16px'} color="#080A0A" style={{ margin: '5px 0px', fontWeight: 'bold' }}>{"ტესტი 01"}</Text>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
              >
                <div style={{ display: 'flex' }}>
                  <Styled.SectionIcon>
                    <Icon filename='topic_quiz' />
                  </Styled.SectionIcon>
                  <Styled.SectionContent>
                    <Text fs={'12px'}>{quiz.name}</Text>
                    <Text fs={'12px'} color="#080A0A8A">{quiz.module.name}</Text>
                    <div className='content' style={{ margin: '4px 0' }}>
                      <Text fs={'12px'} color={'#080A0A8A'}>{quiz.questionsCount} {t('general.question')}</Text>
                      <div>
                        {quiz.quizParticipationType === "mandatory" && (
                          <Styled.Mandatory>
                            <Icon filename={'mandatory_warning'} style={{ marginRight: 5 }} />
                            <Text color={'#CC3131'} fs={13}>{t('general.mandatory')}</Text>
                          </Styled.Mandatory>
                        )}
                      </div>
                    </div>
                  </Styled.SectionContent>
                </div>
                <div>
                  {
                    !quiz.hasEverStarted ? <div style={{ display: 'flex', background: '#080A0A0A', borderRadius: '16px', padding: '10px' }}>
                      <Icon filename='danger' />
                      <span style={{ marginLeft: '5px' }}>
                        არ არის დაწყებული
                      </ span >
                    </div> :
                      <>
                        <p style={{ color: `${quiz.passRate > quiz.score ? '#CC3131' : '#269F5A'}`, fontSize: '24px' }}>{quiz.score}%</p>
                        <p style={{ color: `${quiz.passRate > quiz.score ? '#CC3131' : '#269F5A'}`, fontSize: '13px' }}>{quiz.passRate > quiz.score ? t('general.try_again') : t('general.passed')}</p>
                      </>
                  }

                </div>
              </div>
            </div>
          )
          )
          } */}

          {showQuizResults && props?.portfolio?.quizzes?.map((quiz: any) => (<Quizz quiz={quiz} />)
          )}
        </div>
      }

      {
        props?.userStatistics &&
        <div style={{ margin: '30px 0px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h3>{props?.userStatistics && t('general.personalAssignments')}</h3>
            <div
              onClick={() => setShowPersonalAssignments(prevState => !prevState)}
              style={{ cursor: 'pointer', background: '#080A0A0A', borderRadius: '12px', height: '36px', width: '36px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Icon
                filename='down_arrow'
                style={{
                  transition: '0.3s',
                  transform: `rotate(${showPersonalAssignments ? 0 : 180}deg)`,
                }}
              />
            </div>
          </div>

          {
            showPersonalAssignments && props?.portfolio?.skillTestVideos?.map((item: any) =>
            (<div>

              {
                item?.videos.map((personalAssignment: any) => (
                  <div
                    key={item?.module?.id}
                    style={{
                      padding: '20px 10px 20px 60px',
                      border: '1px solid #080A0A0A',
                      borderRadius: '16px',
                      margin: '16px',
                    }}>
                    <h3 style={{ padding: '20px 0px', fontWeight: 'bold' }}>{t('general.personal_assignment')}</h3>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                      <div style={{ display: 'flex' }}>
                        {
                          personalAssignment?.file?.type === 'pdf'
                            ?
                            <Styled.SectionIcon>
                              <Icon filename='pdf' width={15} height={15} />
                            </Styled.SectionIcon>
                            :
                            personalAssignment?.file?.type === 'mp3'
                              ?
                              <Styled.SectionIcon>
                                <Icon filename='voice' width={15} height={15} />
                              </Styled.SectionIcon>
                              : personalAssignment?.file?.type ?
                                <Styled.SectionIcon>
                                  <Icon filename='personalHW' />
                                </Styled.SectionIcon>
                                : null
                        }
                        <Styled.SectionContent>
                          <Text fs={'16px'} color={'#080A0A'}>{personalAssignment?.file?.name}</Text>
                          <p style={{ color: '#080A0A8A', fontSize: '12px', padding: '5px 0px' }}>{item?.module?.name}</p>
                          <div className='content'>
                            <Text fs={'12px'} color={'#49AF75'}>{personalAssignment?.status}</Text>
                            <div>
                              <Rating precision={0.1} value={personalAssignment?.averageRating || '0.0'} disabled />
                            </div>
                          </div>
                          <div>
                            <Text color="#080A0A8A" fs={16}>
                              {moment(parseInt(personalAssignment.createDate)).format('DD/MM/YYYY hh:mm')}
                            </Text>
                          </div>
                        </Styled.SectionContent>
                      </div>
                      <div>
                        <button style={{ background: '#080A0A0A', borderRadius: '16px', border: 'none', width: '84px', height: '47px', marginBottom: '5px' }}>{t('general.view')}</button>
                        <p style={{ color: '#080A0A8A' }}>{t('general.version')} {personalAssignment?.version}</p>
                      </div>
                    </div>
                  </div>
                ))
              }

            </div>
            )
            )
          }
        </div>
      }

    </S.Container >
  )
}

export default AboutCourse
