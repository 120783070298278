import React from 'react'

import backgroundImage from '../../assets/images/background-image.png';
import LandingNavbar from './LandingNavbar';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import Icon from 'components/Icon'
import { Button } from 'components/common/Button';


const RegisterSuccess = () => {
  const { t } = useTranslation()

  return (
    <>
      <LandingNavbar />
      <div className='w-full h-full flex flex-col items-center justify-center'>
        <div className='w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 text-white landing'>
          <div className=' bg-black/70 flex flex-col items-center justify-center rounded-xl'
            style={{
              padding: '20px 90px',
              width: '536px',
            }}
          >
            <div className='h-full w-full'>
              <h1 style={{ fontSize: '24px' }} className='w-full mt-5 flex items-center text-center justify-center'>
                {t('general.passwordSendToMail')}
              </h1>
              <div style={{ fontSize: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className='w-full flex mt-5 mb-4 text-sm font-light'>
                <Icon height='40px' width='50px' filename='payment_success' />
                <Link to={'/login'} className='cursor-pointer mt-10 font-[Gotham]'>
                  <Button buttonStyles={{ textTransform: 'uppercase' }} width={130} text={t('login.log_in')} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterSuccess

