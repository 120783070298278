import gql from 'graphql-tag'

export const GENERATE_VIDEO_UPLOAD_LINKS = gql`
  mutation($videos: [Video], $modelName: String) {
    generateVideoUploadLinks(videos: $videos, modelName: $modelName) {
      uploadLinks
      video {
        id
        name
        key
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
    }
  }
`

export const GENERATE_CUSTUM_VIDEO_UPLOAD_LINKS = gql`
  mutation($videos: [Video], $modelName: String) {
    generateCustumVideoUploadLinks(videos: $videos, modelName: $modelName) {
      uploadLinks
      video {
        id
        name
        key
        thumbnail
        links {
          url
          fileType
          converted
        }
      }
    }
  }
`

export const UPLOAD_IMAGE = gql`
  mutation($image: String, $path: String) {
    uploadImageLink(image: $image, path: $path) {
      link
    }
  }
`

export const UPLOAD_FILE = gql`
  mutation($file: Upload, $path: String) {
    uploadFileLink(file: $file, path: $path) {
      link
      fileId
    }
  }
`

export const UPLOAD_AUDIO = gql`
  mutation($file: Audio) {
    uploadAudio(file: $file) {
      link
      fileId
    }
  }
`
export const EXPORT_DATA = gql`
  mutation($action: String, $ids: [String]) {
    exportCsv(action: $action, ids: $ids) {
      link
    }
  }
`

export const EXTRACT_TEXT = gql`
  mutation($link: String) {
    extractText(link: $link) {
      text
    }
  }
`
