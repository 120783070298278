import React from 'react'
import { FlashCard } from '../types'
import strCutter from 'utils/strCutter'

import CheckBox from 'components/common/Checkbox'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'

import {
  Wrapper,
  FlashCardItemContainer,
  FlashCardItemContent,
  AudioIcon,
  FlashCardItemTitle,
  FlashCardItemImageBox,
  FlashCardItemAnswer,
} from '../styled-components'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import Icon from 'components/Icon'

interface Params {
  flashCard: FlashCard
  actions: any
  selectedItems: string[]
  selectItem: any
  handleAudioClick: any
  companyCourse?: boolean
}

const FlashCardItem = ({
  flashCard,
  actions,
  selectedItems,
  selectItem,
  handleAudioClick,
  companyCourse,
}: Params) => {
  const audioColor: 'primary' | 'disabled' =
    flashCard.audio && flashCard.audio.link ? 'primary' : 'disabled'

  return (
    <FlashCardItemContainer background={flashCard.color}>
      <Wrapper>
        {!companyCourse && (
          <CheckBox
            onChange={(e: React.SyntheticEvent<EventTarget>) =>
              selectItem(flashCard.id, e)
            }
            checked={selectedItems.includes(flashCard.id)}
          />
        )}
        <AudioIcon
          className={`${
            flashCard.audio && !flashCard.audio.link ? 'disabled' : ''
          }`}
        >
          <Icon
            filename={'volume'}
            width={22}
            height={18}
            onClick={() =>
              handleAudioClick(flashCard.audio && flashCard.audio.link)
            }
          />
        </AudioIcon>
        <GridActionMenu actionConfig={actions} row={flashCard} />
      </Wrapper>
      <FlashCardItemContent>
        {/* <FlashCardItemTitle>{flashCard.question}</FlashCardItemTitle> */}
        <FlashCardItemImageBox>
          <img src={flashCard.image} alt="Flashcard Image" />
        </FlashCardItemImageBox>
        <FlashCardItemAnswer>
          {strCutter(flashCard.answer, 250)}
        </FlashCardItemAnswer>
      </FlashCardItemContent>
    </FlashCardItemContainer>
  )
}

export default FlashCardItem
