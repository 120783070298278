import { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

import { Login } from 'interfaces/auth'
import { useUserValue } from 'context/UserContext'
import { clearToken, setRole, setToken, setUserId } from 'helpers/localStorage'

import { LOGIN } from 'gql/auth/login.query'

const useAuth = () => {
  const { t } = useTranslation()
  const [state, dispatch] = useUserValue()
  const [authenticate] = useMutation(LOGIN)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  // const { updateUserFirstLogin } = useUpdateUserFirstLoginService()

  const login = async (loginValues: Login) => {
    loginValues.deviceType = "WEB"

    try {
      setLoading(true)
      const { data, errors } = await authenticate({
        variables: { input: loginValues },
      })

      if (errors) {
        const errorMessage = errors[0].message
        setError(
          errorMessage === 'Invalid credentials'
            ? t('login.invalid_credentials')
            : errorMessage === 'SUSPENDED'
              ? t('login.user_is_suspanded')
              : errorMessage,
        )
        setTimeout(() => {
          setError('')
        }, 3000)
        return false
      }
      const { accessToken, user } = data.auth

      setRole(user.role)
      setUserId(user.id)
      setToken(accessToken)
      dispatch({
        type: 'LOGIN',
      })

      // if(user.isFirstLogin){
      //   if(user.requestPasswordChange){
      //     history.push("/change-password")
      //   }else{
      //     updateUserFirstLogin(user.id)
      //   }
      // }else{
      //   history.push("/")

      // }
    } catch (err) {
      console.log('Error from login request', err)
    } finally {
      setLoading(false)
    }
  }

  const logout = () => {
    dispatch({
      type: 'LOGOUT',
    })
    clearToken()
    window.location.href = '/login'
  }

  return {
    loading,
    login,
    logout,
    error,
  }
}

export default useAuth
