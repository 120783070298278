import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 56px;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid #080a0a14;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 30px;

  .MuiFormControlLabel-root {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .MuiButtonBase-root {
    color: #707070;
    &.Mui-checked {
      color: #5290f6;
    }
  }
  .Mui-checked {
    color: #5290f6 !important;
  }


  @media (max-width: 480px) {
    height: 100%;
    gap: 0px;
    padding-left: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Title = styled.div`
  min-width: 110px;
  height: 100%;
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: 8px 0px 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.text.primary};
`
