import gql from 'graphql-tag'

export const GET_SECTIONS = gql`
  query($filter: SectionFilterInput, $currentPage: Int, $perPage: Int, $fetchAll: Boolean, ) {
    getSections(filter: $filter, currentPage: $currentPage, perPage: $perPage, fetchAll: $fetchAll, ) {
      currentPage
      totalPages
      totalCount
      data {
        id
        status
        name
        createDate
        # forStartup
        courses {
          id
          name
          avatar {
            link
          }
        }
      }
    }
  }
`

export const DELETE_SECTIONS = gql`
  mutation($ids: [String!]!) {
    deleteSections(ids: $ids)
  }
`

export const DELETE_SECTION_COURSES = gql`
  mutation($id: String!, $input: SectionCoursesInput!) {
    deleteSectionCourses(id: $id, input: $input) {
      id
      status
      name
      courses {
        name
        id
      }
    }
  }
`

export const ADD_SECTION_COURSES = gql`
  mutation($id: String!, $input: SectionCoursesInput!) {
    addSectionCourses(id: $id, input: $input) {
      id
      status
      name
      courses {
        name
        id
      }
    }
  }
`

export const EDIT_SECTION = gql`
  mutation($id: String!, $input: SectionInput!) {
    editSection(id: $id, input: $input) {
      id
      status
      name
      courses {
        name
        id
      }
    }
  }
`


export const GET_SECTION_WITH_COURSES = gql`
query ($id: String!){
  getSectionWithCourses( id: $id){
    id
    name
    courses{
      id
      numberOfRating
      averageRating
      isOwnCourse
      certificateIncluded
      lastWatchedLesson {
        moduleIndex
        topicIndex
        lessonIndex
      }
      IQTests {
        id
        parentName
        quizType
        name
        IQTestType
        quizParticipationType
        passRate
        questionsCount
        score
        blocked
        tags {
          label
          id
        }
        timeType
        time
        randomization
      }
      name
      description
      editorText
      level
      # progress
      # completed
      coursePrivacy
      duration
      coachType
      price
      currency
      isBought
      originalId
      certificate {
        name
        fileType
        link
      }
      price
      currency
      avatar {
        name
        fileType
        link
      }
      state
      subtitle {
        name
        fileType
        link
      }
      skills {
        label
        id
      }
      coaches {
        firstName
        averageRating
        lastName
        email
        id
        userId
        avatar
      }
      groups {
        groupId
        name
      }
      numberOfGroups
      numberOfStudents
      numberOfModules
      numberOfLessons
      duration
      video {
        id
        title
        name
        thumbnail
            links {
              url
              fileType
              converted
            }
          }
           finished
          categories {
            id
            name
          }
          contentLocked
          certificateIncluded
          }
      }
    }
    `


export const CREATE_SECTION = gql`
  mutation($input: SectionInput!) {
    addSection(input: $input) {
      id
      status
      name
      courses {
        name
        id
      }
    }
  }

`

export const GET_PAGINATED_SECTION_COURSES = gql`
  query($id: String!, $currentPage: Int, $perPage: Int) {
    getSectionCoursesWithPagination(id: $id, currentPage: $currentPage, perPage: $perPage) {
      currentCoursePage,
      totalCoursePages,
      totalCourseCount,
      data {
        id
        status
        name
        courses {
          id
          name
        }
      }
    }
  }
`
