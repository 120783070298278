import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { useQuery } from '@apollo/react-hooks'
import { GET_MODULE } from 'gql/modules.query'
import useEditModuleService from 'hooks/modules/useEditModuleService'
import styled from 'styled-components'
import { SET_FORMDATA } from 'store/types'
import { ModuleSchema } from 'helpers/validationSchemas'
import { useTranslation } from 'react-i18next'

import {
  useUploadImage,
  useUploadFile,
  useGenerateUploadVideoLinks,
  useGenerateCustumUploadVideoLinks,
} from 'hooks/helpers/useHelpersService'
import { useData } from 'context/DataContext'
import { useAction } from 'store/actions'

import {
  FormContainer,
  FormGroup,
  FormGroupItem,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
  FormField,
} from 'components/common/Form'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import ImageCropper from 'components/common/ImageCropper'
import Modal from 'components/common/Modal'

import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from 'components/common/Checkbox'
import Uploader from 'components/common/FileUploader/Uploader'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import { useTheme } from '@material-ui/core'
import { triggerVideoConverter } from 'services/mediaEncoding'
import useSnackbarAlert from 'hooks/useSnackbar'

const fromData: any = {
  name: '',
  description: '',
  coverImage: null,
  certificateImage: null,
  attachment: null,
  video: null,
  subtitle: null,
  skillTestVideoEnabled: false,
}
const EditModuleDrawer = (props: any) => {
  const { t } = useTranslation()
  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()
  const [file, setFile] = useState<any>({})
  const [introVideo, setIntroVideo] = useState<any>('')
  const [showCertificate, setShowCertificate] = useState(false)
  const [cropperOpen, setCropperOpened] = useState(false)
  const [currentModule, setCurrentModule] = useState<any>('')

  const { data, loading: getModuleLoading, error } = useQuery(GET_MODULE, {
    variables: { moduleId: props.id },
  })
  const { editModule, loading } = useEditModuleService({
    moduleId: props.id,
  })
  const { uploadImage, imageLoading } = useUploadImage()
  const { generateCustumUploadVideoLinks } = useGenerateCustumUploadVideoLinks()
  const { uploadFile, fileLoading } = useUploadFile()
  const { setSnackbar } = useSnackbarAlert()


  const uploadVideoService = (url: string, videoKey: string) => {

    const formData = new FormData();

    formData.append("file", file);

    return axios.put(url, formData, {
      headers: {
        'Content-Type': "multipart/form-data",
      },
    }).then((res) => {
      const message = `Video conversion ${res.data}`
      const variant = 'success'

      setSnackbar({ message, variant })
    }).catch((err) => {
      const message = `${err}`
      const variant = 'error'
      console.log("err", err)
      setSnackbar({ message, variant })
    })
  }

  const handleFileChange = (e: any, field: string) => {
    const { files } = e.target
    const reader: any = new FileReader()
    reader.onload = () => {
      const img: any = new Image()
      img.src = reader.result
      setFile({ name: field, file: reader.result })
      if (field === 'certificateImage') {
        finishCrop('certificateImage', reader.result)
      }
    }
    reader.readAsDataURL(files[0])
    if (field !== 'certificateImage') {
      setCropperOpened(true)
    }
  }

  const onSuccess = () => {
    toggleDrawerConfirm(false, '')
    props.onClose()
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: fromData,
    validationSchema: ModuleSchema,
    onSubmit(values) {
      const data = { ...values }

      if (data.coverImage) delete data.coverImage.__typename

      if (data.certificateImage) delete data.certificateImage.__typename

      if (data.attachment) delete data.attachment.__typename

      if (data.video) {
        const { __typename, ...args } = data.video
        data.video = {
          ...args,
          links: args.links.map(({ __typename, ...n }: any) => n),
        }

        if (data.subtitle) delete data.subtitle.__typename
      }

      editModule(props.id, data, onSuccess)
    },
  })

  useEffect(() => {
    if (data && data.getModule) {
      let dataClone: any = {}
      for (let [key] of Object.entries(fromData)) {
        dataClone[key] = data.getModule[key]
      }
      setValues(dataClone)
      setCurrentModule(dataClone)

      if (data.getModule.certificateImage) {
        setShowCertificate(true)
      }
    }
  }, [data])

  const handleGenerateUploadLink = () => {
    const videos = [{ fileName: introVideo.name, type: introVideo.type }]
    generateCustumUploadVideoLinks(
      videos,
      'modules',
      uploadVideoService,
      (args: any) => {
        setFieldValue('video', args[0])
        setFieldValue('video.title', args[0].name)
      },
    )
  }

  const handleAttachmentChange = (e: any, field: string) => {
    if (e && !fileLoading) {
      const { files } = e.target
      const file = files[0]


      uploadFile(file, `module/${field}`, (link: string) => {

        setFieldValue(field, {
          name: file.name,
          link: link,
          fileType: file.name.split('.').pop(),
        })
      })
    }
  }

  const handleCloseDrawer = () => {
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editModuleDrawer',
        values,
        compareTo: currentModule,
      },
    })
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  useEffect(() => {
    if (introVideo) {
      handleGenerateUploadLink()
    }
  }, [introVideo])

  // const handleToggleCertificate = () => {
  //   setShowCertificate(!showCertificate)
  // }

  const finishCrop = (field: string, croppedFile: string) => {
    uploadImage(croppedFile, `module/${field}`, (link: string) =>
      setFieldValue(field, {
        name: Date.now().toString(),
        link: link,
        fileType: 'png',
      }),
    )
  }

  const theme = useTheme();

  return (
    <>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{t('modules_layout.edit_module')}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormGroup padding="10px">
              <FormGroupItem>
                <Input
                  error={errors.name ? true : false}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  label={`${t('form_fields.name')}*`}
                  size="small"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                />
              </FormGroupItem>

              <FormGroupItem>
                <Input
                  error={errors.description ? true : false}
                  errorMessage={errors.description ? errors.description : ''}
                  touched={touched.description}
                  label={t('form_fields.module_overview')}
                  size="small"
                  name="description"
                  type="text"
                  value={values.description}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows="4"
                />
              </FormGroupItem>

              <FormGroupItem>
                <StyledFromGroupContainer>
                  <CourseDetailsContainer>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Uploader
                        label={t('actions.upload_cover_image')}
                        accept="image/x-png,image/gif,image/jpeg"
                        onFileChange={(e: any) =>
                          handleFileChange(e, 'coverImage')
                        }
                        deleteFile={() => setFieldValue('coverImage', null)}
                        type="image"
                        inputValue={
                          values.coverImage ? values.coverImage.name : ''
                        }
                        onInputChange={(e: any) =>
                          setFieldValue('coverImage.name', e.target.value)
                        }
                        disabled={!values.coverImage}
                        uploadedFile={values.coverImage}
                        fileExtension={
                          (values.coverImage &&
                            `(${values.coverImage.link.split('.').pop()})`) ||
                          ''
                        }
                        loading={imageLoading === 'coverImage'}
                        id="coverImage"
                      />
                      {errors.coverImage && (
                        <span style={{ color: '#f44336' }}>
                          {errors.coverImage}
                        </span>
                      )}
                    </div>
                  </CourseDetailsContainer>
                  <CourseDetailsContainer>
                    <Uploader
                      label={t('actions.add_attachment')}
                      accept=".pdf"
                      onFileChange={(e: any) =>
                        handleAttachmentChange(e, 'attachment')
                      }
                      deleteFile={() => {
                        setFieldValue('attachment', null)
                      }}
                      type="file"
                      inputValue={
                        values.attachment ? values.attachment.name : ''
                      }
                      onInputChange={(e: any) =>
                        setFieldValue('attachment.name', e.target.value)
                      }
                      disabled={!values.attachment}
                      uploadedFile={values.attachment}
                      fileExtension={
                        values.attachment
                          ? `(${values.attachment.fileType})`
                          : ''
                      }
                      loading={fileLoading === 'attachment'}
                      id="attachment"
                    />
                  </CourseDetailsContainer>
                </StyledFromGroupContainer>
              </FormGroupItem>

              <FormField width="100%">
                <CustomSwitcher
                  onChange={(e: any) => {
                    setFieldValue('skillTestVideoEnabled', e?.target?.checked)
                  }}
                  label={t('general.personal_assignment')}
                  name="skillTestVideoEnabled"
                  value={values.skillTestVideoEnabled}
                />
              </FormField>

              <FormGroupItem>
                <StyledFromGroupContainer>
                  {/* <div style={{ height: '150px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showCertificate}
                            onChange={handleToggleCertificate}
                            name="certificateIncluded"
                            color="primary"
                          />
                        }
                        label={t('general.certificate_included')}
                      />
                    </div> */}

                  {showCertificate ? (
                    <CourseDetailsContainer>
                      <Uploader
                        label={t('actions.upload_certificate_photo')}
                        accept="image/x-png,image/gif,image/jpeg"
                        onFileChange={(e: any) =>
                          handleFileChange(e, 'certificateImage')
                        }
                        deleteFile={() =>
                          setFieldValue('certificateImage', null)
                        }
                        type="image"
                        inputValue={
                          values.certificateImage
                            ? values.certificateImage.name
                            : ''
                        }
                        onInputChange={(e: any) =>
                          setFieldValue('certificateImage.name', e.target.value)
                        }
                        disabled={!values.certificateImage}
                        uploadedFile={values.certificateImage}
                        fileExtension={
                          (values.certificateImage &&
                            `(${values.certificateImage.link
                              .split('.')
                              .pop()})`) ||
                          ''
                        }
                        loading={imageLoading === 'certificateImage'}
                        id="certificateImage"
                      />
                    </CourseDetailsContainer>
                  ) : null}
                </StyledFromGroupContainer>
              </FormGroupItem>
            </FormGroup>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />

              <Button
                text={t('actions.save')}
                type="medium"
                btnType="submit"
                background={'#317BF4'}
                buttonStyles={{
                  borderRadius: '8px',
                }}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>

      <Modal
        isOpened={cropperOpen}
        onClose={() => setCropperOpened(!cropperOpen)}
      >
        <ImageCropper
          file={file}
          hideCropper={() => setCropperOpened(false)}
          getCroppedFile={finishCrop}
        />
      </Modal>
    </>
  )
}

export default EditModuleDrawer

const StyledFromGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const CourseDetailsContainer = styled.div`
  width: 47%;
  box-sizing: border-box;
`
