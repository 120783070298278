import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { useData } from 'context/DataContext'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'
import {
  useUploadImage,
  useUploadFile,
  useGenerateUploadVideoLinks,
  useExtractTextService,
} from 'hooks/helpers/useHelpersService'
import useAddModuleService from 'hooks/modules/useAddModuleService'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'

import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
// import Checkbox from 'components/common/Checkbox'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'

import Loader from 'components/common/Loader'
import { DrawerEventEmitter } from 'helpers/drawer'
import { ModuleSchema } from 'helpers/validationSchemas'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import Uploader from 'components/common/FileUploader/Uploader'
import UploaderButton from 'components/common/FileUploader/UploaderButton'
import { mainColor } from 'components/common/Colors/CommonColors'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import styled from 'styled-components'
import Icon from 'components/Icon'
import { SSwitch } from 'pages/companies/CompanyDetails/CompanyServiceToggle'
import { useTheme } from '@material-ui/core'
import { triggerVideoConverter } from 'services/mediaEncoding'
import useSnackbarAlert from 'hooks/useSnackbar'

interface Props {
  title: string
  data: any
  drawerName?: string
  closeDrawer?: () => void
}

interface formDataInterface {
  name: string
  description: string
  courses: string[]
  coverImage: any
  attachment: any
  certificateImage: any
  subtitle: any
  video: any
  skillTestVideoEnabled: boolean
}

const formData: formDataInterface = {
  name: '',
  description: '',
  courses: [],
  coverImage: null,
  attachment: null,
  certificateImage: null,
  subtitle: null,
  video: null,
  skillTestVideoEnabled: false,
}

const AddModule = ({ title, data, drawerName }: Props) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [dataState, dispatchData] = useData()
  const courseId: any = data.courseId || ''

  const variables: any = data.courseId
    ? {
      filter: {
        name: { type: 'match', value: '' },
        course: { type: 'nestedArrayIn', value: courseId },
      },
    }
    : {}

  // const { data: course } = useQuery(GET_COURSE, { variables: { courseId } })
  const { addModule, newModule, addModuleLoading } = useAddModuleService({
    ...variables,
  })

  const { extractText, loading: extractTextLoading } = useExtractTextService()
  // const [extractText, { data: extractTextData, loading: extractTextLoading }] = useLazyQuery(EXTRACT_TEXT)

  // console.log('extractTextData', extractTextData)

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: ModuleSchema,
    onSubmit(values) {
      const courseData: any = { ...values }

      if (addModuleLoading) return

      courseData.courses =
        courseData.courses.map((course: any) => course.value) || []

      if (state.selectedCompanyId)
        courseData.companyId = state.selectedCompanyId

      if (!state.selectedCompanyId && data.manualCompanyId) {
        courseData.companyId = data.manualCompanyId
      }

      const { manualCompanyId, ...dataParams } = data

      addModule(courseData, dataParams)
    },
  })

  const [cropperOpen, setCropperOpened] = useState(false)
  const [file, setFile] = useState<any>('')
  const [certificateIncluded, setCertificateIncluded] = useState<boolean>(false)
  const [introVideo, setIntroVideo] = useState<any>('')
  const [imageType, setImageType] = useState('')
  const [progress, setProgress] = useState<number>(0)
  const { generateUploadVideoLinks } = useGenerateUploadVideoLinks()
  const { uploadImage, imageLoading } = useUploadImage()
  const { uploadFile, fileLoading } = useUploadFile()
  const { setSnackbar } = useSnackbarAlert()

  const uploadVideoService = (url: string, key: string) => {
    return axios.put(url, introVideo, {
      headers: {
        'Content-Type': introVideo.type,
      },
      onUploadProgress: ({ total, loaded }) => {
        setProgress((loaded / total) * 100)
        // if ((loaded / total) * 100 === 100) {
        //   setProgress(0)
        // }
      },

    }).then(() => triggerVideoConverter(key).then((res) => {
      const message = `Video conversion ${res.data}`
      const variant = 'success'

      setSnackbar({ message, variant })
    }).catch((err) => {
      const message = `${err}`
      const variant = 'error'

      setSnackbar({ message, variant })
    }))
  }

  const closeDrawer = () => {
    DrawerEventEmitter.emit('openDrawer', { drawerName }, false)
  }

  const cropperModalToggle = () => {
    setCropperOpened(!cropperOpen)
  }

  const handleCropSave = (field: string, croppedFile: string) => {
    uploadImage(croppedFile, `module/${field}`, (link: string) =>
      setFieldValue(field, {
        name: Date.now().toString(),
        link: link,
        fileType: 'png',
      }),
    )
  }

  const handleImageChange = (e: any, field: string) => {
    const { files, name } = e.target
    const reader: any = new FileReader()

    reader.onload = () => {
      const img: any = new Image()
      img.src = reader.result
      setFile({ name, file: reader.result })
      if (field === 'certificateImage') {
        handleCropSave('certificateImage', reader.result)
      }
    }
    reader.readAsDataURL(files[0])
    if (field !== 'certificateImage') {
      setCropperOpened(true)
    }
    setImageType(field)
  }

  const handleAttachmentChange = (e: any, field: string) => {
    const { files } = e.target
    const file = files[0]
    uploadFile(file, `module/${field}`, (link: string) => {
      setFieldValue(field, {
        name: file.name,
        link: link,
        fileType: file.name.split('.').pop(),
      })
    })
  }

  const deleteImage = (field: string) => {
    setFieldValue(field, '')
  }

  const handleGenerateUploadLink = () => {
    const videos = [{ fileName: introVideo.name, type: introVideo.type }]
    generateUploadVideoLinks(
      videos,
      'modules',
      uploadVideoService,
      (args: any) => {
        setFieldValue('video', args[0])
        setFieldValue('video.title', args[0].name)
        setProgress(0)
      },
    )
  }

  const handleCloseDrawer = () => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'addModule',
        values,
        compareTo: {},
      },
    })
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (newModule) {
      closeDrawer()
      dispatchData({ type: CLEAR_FORMDATA })
      data.onSuccess()
      // if (data.courseId)
      //   refetch({
      //     filter: {
      //       name: {
      //         type: 'match',
      //         value: '',
      //       },
      //       course: {
      //         type: 'nestedrrayIn',
      //         value: courseId,
      //       },
      //     },
      //   })
    }
  }, [newModule])

  const handleExtractText = (e: any) => {
    if (!e || !e.target) return
    const { files } = e.target
    const file = files[0]
    uploadFile(file, `uploads/extract`, (link: string) => {
      extractText(link, (text: string) => setFieldValue('description', text))
    })
  }

  useEffect(() => {
    if (introVideo) {
      handleGenerateUploadLink()
    }
  }, [introVideo])

  const disabledButton = !!(
    (progress > 0 && progress < 100) ||
    imageLoading ||
    addModuleLoading
  )

  const theme = useTheme()

  return (
    <>
      {addModuleLoading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{title}</DrawerTitle>

        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              {data.name && (
                <FormField>
                  <TextInput
                    label={t('general.course')}
                    size="medium"
                    name="course"
                    type="text"
                    value={data.name}
                    disabled
                  />
                </FormField>
              )}
              <FormField>
                <TextInput
                  error={errors.name ? true : false}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  label={`${t('form_fields.name')}*`}
                  size="medium"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                />
              </FormField>

              <FormField>
                <TextInput
                  error={errors.description ? true : false}
                  errorMessage={errors.description ? errors.description : ''}
                  touched={touched.description}
                  label={t('form_fields.module_overview')}
                  size="medium"
                  name="description"
                  type="text"
                  value={values.description}
                  onChange={handleChange}
                  fullWidth={true}
                  multiline
                  rows="4"
                  component={
                    <UploaderButton
                      onChange={handleExtractText}
                      label={t('general.upload')}
                      loading={fileLoading === 'extract' || extractTextLoading}
                    />
                  }
                />
              </FormField>
              <FormField width="100%">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Uploader
                    label={t('actions.upload_cover_image')}
                    accept="image/x-png,image/gif,image/jpeg"
                    onFileChange={(e: any) =>
                      handleImageChange(e, 'coverImage')
                    }
                    deleteFile={() => deleteImage('coverImage')}
                    type="image"
                    inputValue={values.coverImage ? values.coverImage.name : ''}
                    onInputChange={(e: any) =>
                      setFieldValue('coverImage.name', e.target.value)
                    }
                    disabled={!values.coverImage}
                    uploadedFile={values.coverImage}
                    fileExtension={
                      (values.coverImage &&
                        `(${values.coverImage.link.split('.').pop()})`) ||
                      ''
                    }
                    loading={imageLoading === 'coverImage'}
                    id="image"
                  />
                  {errors.coverImage && (
                    <span style={{ color: '#f44336' }}>
                      {errors.coverImage}
                    </span>
                  )}
                </div>
              </FormField>

              <FormField width="100%">
                <Uploader
                  label={t('actions.add_attachment')}
                  accept=".pdf"
                  onFileChange={(e: any) =>
                    handleAttachmentChange(e, 'attachment')
                  }
                  deleteFile={() => {
                    setFieldValue('attachment', null)
                  }}
                  type="file"
                  inputValue={values.attachment ? values.attachment.name : ''}
                  onInputChange={(e: any) =>
                    setFieldValue('attachment.name', e.target.value)
                  }
                  disabled={!values.attachment}
                  uploadedFile={values.attachment}
                  fileExtension={
                    values.attachment ? `(${values.attachment.fileType})` : ''
                  }
                  loading={fileLoading === 'attachment'}
                  id="attachment"
                />
              </FormField>

              <FormField
                width="100%"
                style={{
                  textAlign: 'left',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <div style={{ fontSize: '16px', color: '#080A0A8A' }}>
                  {t('general.personal_assignment')}
                </div>
                <SSwitch
                  checked={values.skillTestVideoEnabled}
                  onChange={(e: any) => {
                    setFieldValue('skillTestVideoEnabled', e?.target?.checked)
                  }}
                  name="platformService"
                  style={{ color: mainColor }}
                />
              </FormField>

              {/* <FormField width="48%">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={certificateIncluded}
                      onChange={() =>
                        setCertificateIncluded(!certificateIncluded)
                      }
                      name="certificateIncluded"
                      color="primary"
                    />
                  }
                  label={t('general.certificate_included')}
                />
              </FormField> */}

              <FormField width="48%">
                {certificateIncluded && (
                  <Uploader
                    label={t('actions.upload_certificate_photo')}
                    accept="image/x-png,image/gif,image/jpeg"
                    onFileChange={(e: any) =>
                      handleImageChange(e, 'certificateImage')
                    }
                    deleteFile={() => deleteImage('certificateImage')}
                    type="image"
                    inputValue={
                      values.certificateImage
                        ? values.certificateImage.name
                        : ''
                    }
                    onInputChange={(e: any) =>
                      setFieldValue('certificateImage.name', e.target.value)
                    }
                    disabled={!values.certificateImage}
                    uploadedFile={values.certificateImage}
                    fileExtension={
                      (values.certificateImage &&
                        `(${values.certificateImage.link.split('.').pop()})`) ||
                      ''
                    }
                    loading={imageLoading === 'certificateImage'}
                    id="certificateImage"
                  />
                )}
              </FormField>
              <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
                <ImageCropper
                  file={file}
                  hideCropper={cropperModalToggle}
                  getCroppedFile={(_: any, file: any) =>
                    handleCropSave(imageType, file)
                  }
                />
              </Modal>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              {/* <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              /> */}
              <Button
                text={t('actions.save')}
                type="medium"
                btnType="submit"
                isDisabled={disabledButton}
                background={'#317BF4'}
                buttonStyles={{
                  borderRadius: '8px',
                }}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddModule

const CloseIcon = styled(Icon)`
  position: absolute;
  top: 30px;
  right: 50px;
  cursor: 'pointer';
`

const Info = styled.div`
  position: absolute;
  bottom: -15px;
  left: 75px;
  width: 35px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 100%;
`
