import React from 'react'
import styled from 'styled-components'

import EditRoundedIcon from '@material-ui/icons/EditRounded'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'

export const actionConfig = (actionHandler: any, t: any) => {
  let actions = [
    {
      render: (item: any) => (
        <Action onClick={() => actionHandler('edit', item)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={() => actionHandler('delete', item)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
  ]

  return actions
}
const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`
