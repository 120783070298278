import React from 'react'
import styled from 'styled-components'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useTranslation } from 'react-i18next'
import { mainColor } from 'components/common/Colors/CommonColors'
import Icon from 'components/Icon'
import Checkbox from 'components/common/Checkbox'

interface Params {
  type: string
  onBoolChange?: any
  handleAddAnswerClick?: any
  boolAnswer: boolean
  choiceAnswers?: any
  multiAnswers?: any
  handleRemoveAnswerClick?: any
  handleAnswerChange?: any
  selectedChoiceAnswer?: any
  selectedMultiAnswers?: any
  handleSelect?: any
  fieldsValid: boolean | undefined
  selectedValid: boolean | undefined
}

const renderMulti = (
  handleAddAnswerClick: any,
  handleRemoveAnswerClick: any,
  multiAnswers: any,
  handleAnswerChange: any,
  handleSelect: any,
  selectedMultiAnswers: any,
  fieldsValid?: boolean | undefined,
  selectedValid?: boolean | undefined,
  t?: any,
) => {
  return (
    <>
      <FormLabel className="full">
        {' '}
        {t('create_question.add_and_select_correct_answer')}:
      </FormLabel>
      <FormRules>
        <FormRulesItem className={fieldsValid ? 'valid' : ''}>
          {t('create_question.please_add_and_fill_all_answers')}
        </FormRulesItem>
        <FormRulesItem className={selectedValid ? 'valid' : ''}>
          {t(
            'create_question.please_add_and_select_more_than_one_correct_answer',
          )}
        </FormRulesItem>
      </FormRules>
      <FormGroupWrapper>
        <FormGroup>
          {multiAnswers.map((multiAnswer: any, index: number) => (
            <FormGroupItem key={index}>
              <div className="form-item">
                <CheckboxWrapper>
                  <Checkbox
                    name={`multiple-${index}`}
                    checked={selectedMultiAnswers.includes(index)}
                    onChange={(e: React.SyntheticEvent<EventTarget>) =>
                      handleSelect(index, 'multiple')
                    }
                  />
                </CheckboxWrapper>
                <input
                  name={`multiple-${index}`}
                  value={multiAnswer.value}
                  onChange={e => handleAnswerChange(e, 'multiple', index)}
                  placeholder={t('general.probableAnswer')}
                />

                {index + 1 !== multiAnswers.length && (
                  <ActionContainer
                    onClick={() => handleRemoveAnswerClick('multiple', index)}
                  >
                    <Icon filename={'delete'} />
                  </ActionContainer>
                )}
              </div>
              {index + 1 === multiAnswers.length && (
                <AddButton
                  onClick={e => {
                    e.preventDefault()
                    handleAddAnswerClick('multiple')
                  }}
                >
                  {t('quiz_details.add_question')}
                </AddButton>
              )}
            </FormGroupItem>
          ))}
        </FormGroup>
      </FormGroupWrapper>
    </>
  )
}

const renderChoice = (
  handleAddAnswerClick: any,
  choiceAnswers: any,
  handleRemoveAnswerClick: any,
  handleAnswerChange: any,
  selectedChoiceAnswer?: any,
  handleSelect?: any,
  fieldsValid?: boolean | undefined,
  selectedValid?: boolean | undefined,
  t?: any,
) => (
  <>
    <FormLabel className="full">
      {t('create_question.add_and_select_correct_answer')}:
    </FormLabel>
    <FormRules>
      <FormRulesItem className={fieldsValid ? 'valid' : ''}>
        {t('create_question.please_add_and_fill_all_answers')}
      </FormRulesItem>
      <FormRulesItem className={selectedValid ? 'valid' : ''}>
        {t('create_question.please_select_one_correct_answer')}
      </FormRulesItem>
    </FormRules>
    <FormGroupWrapper>
      <FormGroup>
        {choiceAnswers.map((choiceAnswer: any, index: number) => (
          <FormGroupItem key={index}>
            <div className="form-item">
              <RadioWrapper>
                <Radio
                  name="single"
                  value={index}
                  checked={selectedChoiceAnswer == index}
                  onChange={e => handleSelect(e, 'single')}
                />
              </RadioWrapper>
              <input
                name={`single-${index}`}
                value={choiceAnswer.value}
                onChange={e => handleAnswerChange(e, 'single', index)}
                placeholder={t('general.probableAnswer')}
              />

              {index + 1 !== choiceAnswers.length && (
                <ActionContainer
                  onClick={() => handleRemoveAnswerClick('single', index)}
                >
                  <Icon filename={'delete'} />
                </ActionContainer>
              )}
            </div>
            {index + 1 === choiceAnswers.length && (
              <AddButton
                onClick={e => {
                  e.preventDefault()
                  handleAddAnswerClick('single')
                }}
              >
                {t('quiz_details.add_question')}
              </AddButton>
            )}
          </FormGroupItem>
        ))}
      </FormGroup>
    </FormGroupWrapper>
  </>
)

const renderBool = (onBoolChange: any, boolAnswer: boolean, t: any) => (
  <Container>
    <RadioGroup
      row
      aria-label="position"
      name="position"
      defaultValue="top"
      onChange={onBoolChange}
      value={`${boolAnswer}`}
    >
      <StyledFromGroupContainer>
        <StyledFormGroupWrapper className={'radiogroup_title'}>
          {t('create_question.tick_correct_answer')}
        </StyledFormGroupWrapper>
        <StyledFormGroupWrapper>
          <ItemWrapper>
            <FormControlLabel
              value="true"
              control={<Radio color="secondary" />}
              label={t('general.true')}
            />
          </ItemWrapper>
          <ItemWrapper>
            <FormControlLabel
              value="false"
              control={<Radio color="secondary" />}
              label={t('general.false')}
            />
          </ItemWrapper>
        </StyledFormGroupWrapper>
      </StyledFromGroupContainer>
    </RadioGroup>
  </Container>
)

const Container = styled.div`
  width: 100%;
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${mainColor};
  }
`

const QuestionItem = ({
  type,
  boolAnswer,
  choiceAnswers,
  multiAnswers,
  onBoolChange,
  handleAddAnswerClick,
  handleRemoveAnswerClick,
  handleAnswerChange,
  selectedChoiceAnswer,
  selectedMultiAnswers,
  handleSelect,
  fieldsValid,
  selectedValid,
}: Params) => {
  const { t } = useTranslation()

  React.useEffect(() => {
    switch (type) {
      case 'multiple':
        handleAddAnswerClick('multiple')
        break
      case 'single':
        handleAddAnswerClick('single')
    }
  }, [type])

  return (
    <FormControl>
      {type === 'boolean' && renderBool(onBoolChange, boolAnswer, t)}
      {type === 'single' &&
        renderChoice(
          handleAddAnswerClick,
          choiceAnswers,
          handleRemoveAnswerClick,
          handleAnswerChange,
          selectedChoiceAnswer,
          handleSelect,
          fieldsValid,
          selectedValid,
          t,
        )}
      {type === 'multiple' &&
        renderMulti(
          handleAddAnswerClick,
          handleRemoveAnswerClick,
          multiAnswers,
          handleAnswerChange,
          handleSelect,
          selectedMultiAnswers,
          fieldsValid,
          selectedValid,
          t,
        )}
    </FormControl>
  )
}

export default QuestionItem

const FormControl = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`

const AddButton = styled.button`
  background: #317bf4;
  border-radius: 8px;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 16px;
  padding: 18px 20px;
  min-width: max-content;
`

const FormLabel = styled.label`
  color: #080a0a8a;
  font-size: 14px;

  &.full {
    width: 100%;
  }
`

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 56px;
  border-right: 1px solid #080a0a14;
  .Mui-checked {
    svg {
      color: #5290f6;
    }
  }
  .MuiSvgIcon-root {
    color: #707070;
  }
`

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 56px;
  svg {
    fill: #cc3131;
  }
`

const FormRules = styled.ul`
  padding-left: 0;
  color: #080a0a8a;
  font-size: 14px;
`

const FormRulesItem = styled.li`
  &.valid {
    color: ${mainColor};
  }
`
const FormGroupWrapper = styled.div`
  padding: 20px 0px;
  display: flex;
  align-items: flex-start;
  width: 100%;

  svg {
    color: #d2364b;
    cursor: pointer;
  }
`

const FormGroup = styled.div`
  margin-top: 20px;
  width: 100%;
`

const FormGroupItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 50px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .form-item {
    border: 1px solid #080a0a14;
    border-radius: 8px;
    height: 56px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 30px;
  }

  span {
    padding: 0;
  }

  input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    color: #080a0a8a;
    font-size: 16px;
  }
`

const StyledFromGroupContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #080a0a14;
  border-radius: 8px;
`

const StyledFormGroupWrapper = styled.div`
  display: flex;
  gap: 23px;

  &.radiogroup_title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    background: #f5f5f5;
    border-radius: 8px 0px 0px 8px;
    padding: 0px 30px;
    min-width: max-content;
    margin-right: 20px;
    color: #080a0a8a;
  }
`

const ItemWrapper = styled.div`
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${mainColor};
  }
`

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 56px;
  border-right: 1px solid #080a0a14;
  .MuiButtonBase-root {
    background: transparent;
  }
  .MuiSvgIcon-root {
    color: transparent !important;
  }
`
