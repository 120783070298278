import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { DrawerEventEmitter } from 'helpers/drawer'
import {
  actionConfig,
  moduleActionsConfig,
  columnConfig,
} from './AddCourseModuleDrawer/gridConfig'
import Drawer from 'components/common/Drawer'
import { useUserValue } from 'context/UserContext'

import { GET_COURSE } from 'gql/course/course.query'
import { useSwal } from 'hooks/useSwal'
import { useFetchModules, PER_PAGE } from 'hooks/modules/useModules'
import { useDeleteCourseModule } from 'hooks/course/useEditCourse'
import DeleteIcon from '@material-ui/icons/Delete'
import SyncIcon from '@material-ui/icons/Sync'
import FilterListIcon from '@material-ui/icons/FilterList'
import FilterDrawer from 'pages/modules/FilterDrawer/FilterDrawer'
import Player from 'components/common/VideoPlayer/Player'
import { useTranslation } from 'react-i18next'

import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import TextInput from 'components/common/TextInput/TextInput'
import CourseDetailContent from 'components/courseDetail'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import IconButton from '@material-ui/core/IconButton'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ViewListIcon from '@material-ui/icons/ViewList'
import { GridSwitchButtons } from 'components/common/Cards/styled-components'
import { CardsLayout } from 'components/common/Cards'
import { Button } from 'components/common/Button'
import Modal from 'components/common/Modal'
import {
  ActionButton,
  AddButton,
  GridLayoutActions,
  GridLayoutForm,
  GridToolbar,
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import Breadcrumb from 'components/common/Breadcrumb'
import {
  TabNavigation,
  TabPanel,
  activeTabClassName,
} from 'components/common/Tabs/Tabs'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { QuizLayout } from 'pages/quizzes'
import IQQuizCard from 'pages/quizzes/IQQuizz/QuizCard'
import { mainColor } from 'components/common/Colors/CommonColors'
import Search from 'components/common/Search'
import Icon from 'components/Icon'
import _ from 'lodash'
import SelectBtn from 'components/common/SelectBtn'
import TablePagination from 'components/common/Pagination/TablePagination'
import Grid from 'components/common/Grid'
import { useTheme } from '@material-ui/core'

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid #EBEBEB',
  },
  btn: {
    color: '#000',
  },
}

interface RouterParams {
  match: MatchProps
}

interface MatchProps {
  path: string
  url: string
  isExact: boolean
  params: { id: string }
}

const filterOption: any = {
  name: '',
  duration: '',
  courses: [],
  coverImage: { label: 'All', id: 0, value: 'all' },
  introVideo: { label: 'All', id: 0, value: 'all' },
  attachment: { label: 'All', id: 0, value: 'all' },
  range: [0, 5000],
}

const CourseDetail = ({ match }: RouterParams) => {
  const currentLocation = useLocation()
  const { t } = useTranslation()
  const [value, setValue] = useState(0)
  const [state] = useUserValue()
  const history = useHistory()
  const wenrollExploreCourse = match.path === '/explore-wenroll-courses/:id'
  const courseId = match.params.id
  const {
    data: courseData,
    loading,
    error,
    refetch: refetchCourse,
  }: any = useQuery(GET_COURSE, {
    variables: { courseId },
  })

  const { modules, refetch }: any = useFetchModules(
    {
      name: { type: 'match', value: '' },
      course: { type: 'nestedArrayIn', value: courseId },
    },
    1,
    0,
  )

  // const { data: courseModules, refetch } = useQuery(GET_MODULES, {
  //   variables: {
  //     filter: {
  //       name: { type: 'match', value: '' },
  //       course: { type: 'nestedArrayIn', value: courseId },
  //     },
  //   },
  // })

  const {
    deleteCourseModule,
    deleteCourseModuleLoading,
  } = useDeleteCourseModule({
    filter: {
      name: { type: 'match', value: '' },
      course: { type: 'nestedArrayIn', value: courseId },
    },
  })

  const { fireSwal } = useSwal()

  const [filterData, setFilterData] = useState<any>(filterOption)
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filtered, setFiltered] = useState<boolean>(false)
  const [videoModal, setVideoModal] = useState({ modal: false, data: '' })
  const [gridStyle, setGridStyle] = useState('card')
  const [searchValue, setSearchValue] = useState('')
  const [selectedItem, setSelectedItem] = useState<any>([])
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [perPage] = useState<number>(4)
  const [btnState, setBtnState] = useState(0)
  const theme = useTheme()


  useEffect(() => {
    if (
      (state?.currentUser?.role && state.currentUser.role === 'STUDENT') ||
      wenrollExploreCourse
    ) {
      setGridStyle('list')
    }
  }, [state.currentUser.role, wenrollExploreCourse])

  const selectItem = (id: string, event: boolean) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
    }

    setSelectedItem(newSelected)
  }

  const selectAllItem = () => {
    // if (!selectAll) {
    //   // const newArr: string[] = data.usersByRole.map((n: any) => n.id)
    //   setSelectedItem(newArr)
    //   setSelectAll(true)
    //   return
    // }
    setSelectedItem([])
  }

  const handleResetClick = () => {
    refetch({
      filter: {
        name: { type: 'match', value: '' },
        course: { type: 'nestedArrayIn', value: courseId },
      },
      currentPage: 1,
      perPage,
    })
    closeFilterDrawer()
    setFilterData({})
    setFiltered(false)
    setSearchValue('')
    setFilterQuery({})
  }

  const openCreateModuleDrawer = (id: string, name: string, company: any) => {
    DrawerEventEmitter.emit('openDrawer', 'addModule', true, {
      data: {
        courseId: id,
        name,
        manualCompanyId: company ? company?.id : null,
        onSuccess: () =>
          refetch({
            filter: {
              name: { type: 'match', value: '' },
              course: { type: 'nestedArrayIn', value: courseId },
            },
            currentPage: 1,
            perPage: 0,
          }),
      },
    })
  }

  const openAddCourseModuleDrawer = (
    id: string,
    name: string,
    company: any,
  ) => {
    DrawerEventEmitter.emit('openDrawer', 'addCourseModule', true, {
      data: {
        courseId: id,
        name,
        manualCompanyId: company ? company?.id : null,
        refetchCourse: () => {
          refetchCourse()
          refetch({
            filter: {
              name: { type: 'match', value: '' },
              course: { type: 'nestedArrayIn', value: courseId },
            },
            currentPage: 1,
            perPage: 0,
          })
        },
      },
    })
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    const search = { type: 'match', value: searchValue }

    refetch({
      filter: {
        name: search,
        course: { type: 'nestedArrayIn', value: courseId },
      },
    })
  }

  const handleSearchChange = (e: any) => {
    setSearchValue(e.target.value)

    if (e.target.value === '') {
      refetch({
        filter: {
          name: { type: 'match', value: '' },
          course: { type: 'nestedArrayIn', value: courseId },
        },
      })
    }
  }

  const confirmDelete = (ids: string[]) => {
    deleteCourseModule(courseId, ids, () => {
      refetchCourse()
      refetch({
        filter: {
          name: { type: 'match', value: '' },
          course: { type: 'nestedArrayIn', value: courseId },
        },
      })
    })
    setSelectedItem([])
  }

  const handleDeleteClick = (field: any) => {
    fireSwal({
      title: t('popups.remove_module_from_course_single'),
      confirmText: t('popups.confirm_remove'),
      onConfirm: () => confirmDelete([field?.id]),
    })
  }

  const handleDeleteAllClick = () => {
    fireSwal({
      title: t('popups.remove_module_from_course_many'),
      confirmText: t('popups.confirm_remove'),
      onConfirm: () => confirmDelete(selectedItem),
    })
  }

  const handleViewClick = (id: string) => history.push(`/modules/${id}`)

  const handleEditClick = (id: string) =>
    DrawerEventEmitter.emit('openDrawer', 'editModuleDrawer', true, { id: id })

  const handleVideoModal = (item: any) => {
    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? item.introVideo[0].links[0].url : '',
    })
  }

  const closeFilterDrawer = () => {
    setFilterDrawerOpened(false)
  }

  const openPdf = (item: any) => {
    item.attachment && window.open(item.attachment, '_blank')
  }

  const actionHandler = (event: string, field: any, action: string) => {
    switch (event) {
      case 'view':
        handleViewClick(field)
        break
      case 'edit':
        handleEditClick(field)
        break
      case 'delete':
        handleDeleteClick(field)
        break
      case 'pdf':
        openPdf(field)
      default:
        return () => console.log('null')
    }
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  if (loading) return <div>Loading...</div>

  const course = courseData && courseData.getCourseById

  const moduleActions = moduleActionsConfig(
    openCreateModuleDrawer,
    openAddCourseModuleDrawer,
    t,
  )

  const breadCrumbData = [
    { label: t('general.courses'), link: '/courses' },
    {
      label: courseData && courseData.getCourseById?.name,
      link: '',
    },
  ]

  const isStudent = state.currentUser.role === 'STUDENT'
  const iqQuizzes = courseData?.getCourseById?.IQTests
  const initialIQQuiz =
    iqQuizzes && iqQuizzes.filter((quiz: any) => quiz?.IQTestType === 'initial')
  const finalIQQuiz =
    iqQuizzes && iqQuizzes.filter((quiz: any) => quiz?.IQTestType === 'final')

  const displayStartButton =
    currentLocation.pathname.slice(1, 15) !== 'explore-course'

  const isCompanySuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !!state?.currentUser?.companyId?.id

  const isCompanyAdmin =
    state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  const isSuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !state?.currentUser?.companyId?.id

  const isAdmin =
    state?.currentUser?.role === 'ADMIN' && !state?.currentUser?.companyId?.id

  const canModify =
    (((isCompanySuperAdmin || isCompanyAdmin) &&
      courseData?.getCourseById?.companyId?.id ===
      state?.currentUser?.companyId?.id) ||
      isSuperAdmin ||
      isAdmin) &&
    !isStudent &&
    !wenrollExploreCourse &&
    !(course.originalId && (isCompanySuperAdmin || isCompanyAdmin))

  const actions = actionConfig(
    actionHandler,
    state.userPermission,
    canModify,
    t,
  )

  const config = columnConfig(
    (id: string) => history.push(`/modules/${id}`),
    selectItem,
    t,
  )

  return (
    <div>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => { }}
        totalWidth="700px"
      >
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          setFilterQuery={setFilterQuery}
          perPage={perPage}
          refetchData={refetch}
          onClose={closeFilterDrawer}
          setFiltered={setFiltered}
          courseId={match.params.id}
          resetFilter={handleResetClick}
          t={t}
        />
      </Drawer>
      {courseData && (
        <CourseDetailContent
          course={course}
          refetch={refetch}
          refetchCourse={refetchCourse}
          t={t}
          canModify={canModify}
        />
      )}
      <TabContainer>
        <TabNavigation>
          <AppBar position="static" style={{
            background: theme.palette.background.default,
            boxShadow: 'none',
          }}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              <Tab
                label={
                  <span style={{ color: theme.palette.text.primary }}>{
                    isStudent ? t('course_details.content') : t('general.modules')
                  }</span>
                }
                {...activeTabClassName(0)}

              />
              {!isStudent && !wenrollExploreCourse && (
                <Tab
                  label={<span style={{ color: theme.palette.text.primary }}>{t('general.iq_test')}</span>}
                  {...activeTabClassName(1)}

                />
              )}
            </Tabs>
          </AppBar>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TabPanel value={value} index={0} width="95%">
              <>
                {/* <Toolbar>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {canModify && (
                      <GridActionMenu
                        actionConfig={moduleActions}
                        row={courseData.getCourseById}
                        icon={
                          <Button
                            text={t('general.module')}
                            size="small"
                            color="secondary"
                            icon={<ArrowDropDownIcon />}
                            iconPosition="right"
                            background={mainColor}
                          />
                        }
                      />
                    )}
                    {canModify && (
                      <GridMultipleActions
                        selectedItems={selectedItem.length}
                        actions={[
                          {
                            hide: course?.isBought,
                            id: 0,
                            color: 'secondary',
                            tooltipText: `${t('actions.delete')}`,
                            disabled: selectedItem.length < 1,
                            onClick: () => handleDeleteAllClick(),
                            component: <DeleteIcon fontSize="small" />,
                          },
                        ].filter((i: any) => !i.hide)}
                      />
                    )}
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MultipleAction>
                      <IconButton
                        disabled={filtered || searchValue ? false : true}
                        onClick={handleResetClick}
                      >
                        <SyncIcon fontSize="small" />
                      </IconButton>
                      <MultipleActionTooltip>
                        {t('actions.reset')}
                      </MultipleActionTooltip>
                    </MultipleAction>
                    <MultipleAction>
                      <IconButton onClick={() => setFilterDrawerOpened(true)}>
                        <FilterListIcon
                          fontSize="small"
                          style={{ color: mainColor }}
                        />
                      </IconButton>
                      <MultipleActionTooltip>
                        {t('actions.filters')}
                      </MultipleActionTooltip>
                    </MultipleAction>
                    <form
                      onSubmit={handleSearchSubmit}
                      style={{ marginRight: 10 }}
                    >
                      <TextInput
                        label={t('general.search_placeholder')}
                        type="text"
                        size="small"
                        value={searchValue}
                        onChange={handleSearchChange}
                        icon={
                          <IconButton type="submit">
                            <SearchRoundedIcon />
                          </IconButton>
                        }
                      />
                    </form>
                    {!isStudent && !wenrollExploreCourse && (
                      <GridSwitchButtons>
                        <button
                          className={`${gridStyle === 'card' ? 'active' : ''}`}
                          onClick={() => setGridStyle('card')}
                        >
                          <DashboardIcon />
                        </button>
                        <button
                          className={`${gridStyle === 'list' ? 'active' : ''}`}
                          onClick={() => setGridStyle('list')}
                        >
                          <ViewListIcon />
                        </button>
                      </GridSwitchButtons>
                    )}
                  </div>
                </Toolbar> */}
                <GridToolbar theme={theme}>
                  <GridLayoutActions>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px',
                      }}
                    >
                      {canModify && (
                        <SelectBtn
                          active={btnState}
                          onChange={(active) => setBtnState(active)}
                          options={[
                            {
                              label: t('modules_layout.create_module'),
                              value: 0,
                              onClick: () =>
                                openCreateModuleDrawer(
                                  courseData.getCourseById.id,
                                  courseData.getCourseById.name,
                                  courseData.getCourseById.companyId,
                                ),
                            },
                            {
                              label: `${t('course_details.add_module_to_the_course')}`,
                              value: 1,
                              onClick: () =>
                                openAddCourseModuleDrawer(
                                  courseData.getCourseById.id,
                                  courseData.getCourseById.name,
                                  courseData.getCourseById.companyId,
                                ),
                            },
                          ]}
                        />
                      )}
                      <div>
                        {selectedItem.length} {t('general.items_selected')}
                      </div>
                      {canModify && (
                        <ActionButton
                          disabled={selectedItem.length === 0}
                          onClick={() => handleDeleteAllClick()}
                          fill={'#CC3131'}
                          disabledFill={'#080A0AD6'}
                          theme={theme}
                        >
                          <Icon filename={'delete'} />
                        </ActionButton>
                      )}
                    </div>
                  </GridLayoutActions>
                  <GridLayoutForm>
                    {canModify && (
                      <>
                        <ActionButton
                          disabled={filtered || searchValue ? false : true}
                          onClick={handleResetClick}
                          theme={theme}
                        >
                          <SyncIcon fontSize="small" />
                        </ActionButton>
                        <MultipleAction theme={theme}>
                          <ActionButton
                            onClick={() => setFilterDrawerOpened(true)}
                            theme={theme}
                          >
                            <Icon filename={'filter'} fill={'#080A0AD6'} />
                          </ActionButton>
                          <MultipleActionTooltip>
                            {t('actions.filters')}
                          </MultipleActionTooltip>
                        </MultipleAction>
                      </>
                    )}
                    <Search
                      inputProps={{
                        value: searchValue,
                        onChange: handleSearchChange,
                      }}
                      onClick={handleSearchChange}
                    />
                  </GridLayoutForm>
                </GridToolbar>
                <div>
                  {/* {modules && (
                    <CardsLayout
                      canModify={canModify}
                      wenrollExploreCourse={wenrollExploreCourse}
                      gridStyle={gridStyle}
                      data={modules.data}
                      noRecordsText={t('general.no_modules_in_course')}
                      actions={actions}
                      handleVideoModal={handleVideoModal}
                      selectItem={selectItem}
                      selectedItem={selectedItem}
                      refetch={refetch}
                      isDraggable={filtered || searchValue ? false : true}
                      t={t}
                      isStudent={isStudent}
                      FirstItem={
                        initialIQQuiz &&
                        initialIQQuiz.length > 0 &&
                        isStudent ? (
                          <IQQuizCard
                            quiz={initialIQQuiz[0]}
                            courseId={courseId}
                            displayStartButton={displayStartButton}
                          />
                        ) : (
                          <></>
                        )
                      }
                      lastItem={
                        finalIQQuiz && finalIQQuiz.length > 0 && isStudent ? (
                          <IQQuizCard
                            quiz={finalIQQuiz[0]}
                            courseId={courseId}
                            displayStartButton={displayStartButton}
                          />
                        ) : (
                          <></>
                        )
                      }
                      permissions={state.userPermission}
                    />
                  )} */}
                  {modules && (
                    <Grid

                      config={config}
                      data={modules.data}
                      actionConfig={actions}

                      selectAllItem={selectAllItem}
                      selectedItems={selectedItem}

                    />
                  )}
                  {modules && modules.data && (
                    <TablePagination
                      currentPage={modules ? modules.currentPage : 0}
                      rowsPerPage={perPage}
                      count={modules ? modules.totalPages : 0}
                      handleChangePage={() => { }}
                      handleChangeRowsPerPage={() => { }}
                    />
                  )}
                </div>
              </>
            </TabPanel>
          </div>

          <TabPanel value={value} index={1}>
            <QuizLayout
              IQTest
              type="course"
              id={course && course?.id}
              companyCourse={
                (course &&
                  course?.companyId?.id ===
                  state?.currentUser?.companyId?.id) ||
                null
              }
              name={course?.name}
              canCreate={2}
              isDraggable
              canModify={canModify}
              header={'noHeader'}
            />
          </TabPanel>
        </TabNavigation>
      </TabContainer>
      <Modal isOpened={videoModal.modal} onClose={handleVideoModal}>
        <Player url={videoModal.data} />
      </Modal>
    </div >
  )
}

export default CourseDetail

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
`

export const Title = styled.h2`
  font-size: 22px;
  font-weight: 500;
`
export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`

const TabContainer = styled.div`
  /* border: 1px solid #EBEBEB; */
  border-top: none;
  .MuiTabs {
    &-flexContainer {
      justify-content: center;
    }
  }

  .MuiTab {
    &-wrapper {
      color: #080a0ad6;
      font-size: 20px;
      padding-bottom: 15px;
      padding-top: 40px;
    }
  }
`
