import styled from 'styled-components'
import { mainColor } from '../Colors/CommonColors'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px 0;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 60px;
  height: 100%;
`
export const ColorContainer = styled.div<{ color: string }>`
  cursor: pointer;
  width: 160px;
  height: 156px;
  background: ${({ color }) => color};
  position: relative;
  border: 1px solid transparent;
  transform: scale(0.87);
  transition: 0.3s ease;

  &:hover,
  &.selected {
    border: 2px solid ${mainColor};
    transform: scale(1);
  }

  &.disabled {
    pointer-events: none;
    background: gray;
  }
`
