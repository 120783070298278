import React, { useState, useRef } from 'react'
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css'
import originalMoment from 'moment'
import { Input } from 'components/common/TextInput'
import styled from 'styled-components'
import Icon from 'components/Icon'
import useOutsideClick from 'helpers/useOutsideClick'
import InputAdornment from '@material-ui/core/InputAdornment'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { Popover, useTheme } from '@material-ui/core'

const DateRangePickers = ({ values, placeholder, onChange }) => {
  const ref = useRef()
  // const [values, setValues] = useState({ start: null, end: null })
  const [showPicker, setShowPicker] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  useOutsideClick(ref, () => {
    if (showPicker) setShowPicker(false)
  })

  const theme = useTheme();

  // const onSelect = (value, states) => {
  //   setValues(value)
  //   props.onChange(value)
  // }

  const date = `${values.start ? originalMoment(values.start).format('DD.MM.YYYY') : ''
    }${values.start && values.end ? ' - ' : ''}${values.end ? originalMoment(values.end).format('DD.MM.YYYY') : ''
    }`

  return (
    <>
      <Container ref={ref} aria-describedby={id} theme={theme}>
        <Icon
          cursor={'pointer'}
          filename={'calendar'}
          fill={'#797A7A'}
          onClick={handleClick}
        />
        <Input
          label={placeholder}
          name="name"
          type="text"
          fullWidth
          value={date}

        // onFocus={() => setShowPicker(true)}
        />
      </Container>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{
          top: '15px',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PickerContainer>
          <DateRangePicker
            onSelect={onChange}
            value={values}
            singleDateRange={true}
          />
        </PickerContainer>
      </Popover>
    </>
  )
}

export default DateRangePickers

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  transition: 0.3s;
  height: 56px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.palette.text.primary};
  padding-left: 20px;

  .MuiInputBase-root {
    border: none;
    fieldset {
      border: none !important;
    }
  }

  label {
    background: #ffffff;
  }

  &:hover {
    border-color: #317bf4;
  }
`
const PickerContainer = styled.div`
  position: relative;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`
