import { useMutation } from '@apollo/react-hooks'
import { updateCache } from 'services/updateCache'
import { EDIT_LESSON, GET_LESSON_BY_ID } from 'gql/lesson/lesson.query'

const editLessonService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editLessonMutation, { loading }] = useMutation(EDIT_LESSON, {
    update(cache, { data: { editLesson: document } }) {
      updateCache({
        objectName: 'getLessonById',
        query: GET_LESSON_BY_ID,
        cache,
        document,
        action: 'update',
        params,
      })
    },
  })

  return {
    editLessonMutation,
    loading,
  }
}

export default editLessonService
