import config from '../config'

const axios = require("axios").default;

export const Api = (method: string, url: string, data: any): Promise<any> => {
  return axios({
    method,
    url: `${config.APIURL}/${url}`,
    data
  });
};
