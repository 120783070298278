import moment from 'moment'

export const GlobalUserDefaultImage = '/svg/profileAvatar.png'

export const generateFullName = (user: any) => {
  if (!user) return ''
  if (!user.firstName && !user.lastName) return user.email
  return `${user.firstName} ${user.lastName}`
}

export const formatDateAgo = (dateable: any) => {
  const str = moment(dateable).fromNow()

  if (str.includes('few')) {
    return '1m'
  }

  const split = str.split(' ')

  const numb = split[0] === 'a' || split[0] === 'an' ? 1 : split[0]

  let char = split[1][0]

  if (split[1].includes('month')) {
    char = 'M'
  }

  return `${numb}${char}`
}

export const getUserRole = (role: string) => {
  if (!role) return ''
  const text = role.toLowerCase().split('_')

  if (text && text.length > 1) {
    return `${text[0]} ${text[1]}`
  }

  return text[0]
}

export function secondsToHms(d: number) {
  d = Number(d)
  var h = Math.floor(d / 3600)
  var m = Math.floor((d % 3600) / 60)
  var s = Math.floor((d % 3600) % 60)

  var hDisplay = h > 0 ? h + 'სთ · ' : ''
  var mDisplay = m > 0 ? m + 'წთ · ' : ''
  var sDisplay = s > 0 ? s + 'წმ' : ''
  return hDisplay + mDisplay + sDisplay
}

export function timeConvert(duration: number) {
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
}


export function dateConvertToLocaleDate(receivedDate: number | string): string {
  return new Date(+receivedDate).toLocaleDateString('en-GB') +
    "  " + new Date(+receivedDate).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit', second: '2-digit' });
}