import { useMutation } from '@apollo/react-hooks'
import { updateCache } from 'services/updateCache'
import { GET_USERS, CHANGE_COACH_QUALIFICATION } from 'gql/users.query'

const changeCoachQualificationService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [changeCoachQualificationMutation, { loading, error }] = useMutation(
    CHANGE_COACH_QUALIFICATION,
    {
      update(cache, { data: { changeCoachQualification: document } }) {
        updateCache({
          objectName: 'usersByRole',
          query: GET_USERS,
          cache,
          document,
          action: 'update',
          params
        })
      }
    }
  )

  return {
    changeCoachQualificationMutation,
    loading,
    error
  }
}

export default changeCoachQualificationService
