import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { gql } from 'graphql.macro'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/react-hooks'
import { useUserValue } from 'context/UserContext'
import { formatDuration } from 'helpers/formatDuration'
import styled from 'styled-components'
import strCutter from 'utils/strCutter'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import GroupIcon from '@material-ui/icons/Group'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import { LinearProgress } from '@material-ui/core'
import {
  GridItem,
  GridItemContent,
  GridItemContentHeader,
  GridItemImage,
  GridItemLessons,
  GridItemTitle,
} from 'components/common/Cards/styled-components'
import Checkbox from 'components/common/Checkbox'
import { mainColor } from 'components/common/Colors/CommonColors'
import DndItem from 'components/common/Grid/DndGrid'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { CapitalPlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'
import Icon from 'components/Icon'
import { GetCourseById } from './StudentCourse/types'
import { DataContext, useData } from 'context/DataContext'
import { SET_CURRENT_COURSE } from 'store/types'
import { getRole } from 'helpers/localStorage'

interface Props {
  data: any
  test?: any
  isDraggable?: boolean
  gridStyle: string
  selectItem?: any
  selectedItem: string[]
  actions?: any
  actionConfigForPurchasedCourse?: any
  handleVideoModal: (item: any) => void
  fromWizard?: boolean
  redirectToDashboard?: boolean
  userId?: string
  redirectToExplore?: boolean
  openInExternalTab?: boolean
  exploreWenrollCoursesMode?: boolean
  stat?: boolean
  checkbox?: boolean
  addCourseButton?: boolean,
  coachCourses?: any[]
  isDone?: boolean
  section?: any
  sectionName?: string
}

const CHANGE_ORDER = gql`
  mutation($draggedId: String!, $droppedId: String!) {
    changeCoursesOrder(draggedId: $draggedId, droppedId: $droppedId)
  }
`

const CourseList = ({
  section,
  data,
  isDraggable = false,
  gridStyle,
  selectItem,
  selectedItem,
  test,
  actions,
  actionConfigForPurchasedCourse,
  handleVideoModal,
  fromWizard,
  redirectToDashboard,
  userId,
  redirectToExplore,
  openInExternalTab,
  exploreWenrollCoursesMode,
  stat,
  checkbox,
  addCourseButton,
  coachCourses,
  isDone,
  sectionName
}: Props) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [changeCoursesOrder, { loading }] = useMutation(CHANGE_ORDER)
  const history = useHistory()
  const currentLocation = useLocation()
  const [companyUser, setCompanyUser] = useState<boolean>(false)
  const [dataState, dispatch] = useData();

  useEffect(() => {
    setCompanyUser(!!state?.currentUser?.companyId?.id)
  }, [state.currentUser?.companyId?.id])

  const [cards, setCards] = useState(data)
  const moveCard = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )

  const RedirectToCourse = useCallback(
    (item, companyUser) => {
      let courseId = item.id ?? ''
      let openAsYourCourse = true

      if (companyUser) {
        if (
          (!!item?.companyId?.id && !item.isBought) ||
          (!!item?.originalId && item.isBought)
        ) {
          openAsYourCourse = true
        } else {
          openAsYourCourse = false
        }
      } else {

        openAsYourCourse = true
      }


      if (!item.isBought && item.originalId !== null) {

        courseId = item.originalId
      } else if (item.isBought) {
        courseId = item.id
      } else {
        courseId = item.id
      }
      if (openInExternalTab) {
        if (redirectToExplore) {
          window.open(`/explore-course/${courseId}`, '_blank')
        } else {

          redirectToDashboard && userId
            ? window.open(`/course/${courseId}/user/${userId}`, '_blank')
            : window.open(`/courses/${courseId}`, '_blank')
        }
      } else {

        if (redirectToExplore) {

          if (!state?.currentUser?.companyId?.id || addCourseButton) {
            history.push(`/courses/${courseId}`)
          } else {
            history.push(`/course/${courseId}/${stat ? `stat` : ''}`)
          }
        } else {

          if (state.currentUser.role === "COACH") {
            dispatch({
              type: SET_CURRENT_COURSE,
              payload: item,
            })

            if (history.location.pathname.includes('profile')) {
              history.push(`/course/${courseId}/${stat ? `stat` : ''}`)
            }

            if (userId && history.location.pathname.includes('profile')) {
              history.push(`/course/${courseId}/user/${userId}`)
              return;
            }

            redirectToDashboard &&
              userId &&
              history.push(`/course/${courseId}/${stat ? `stat/${userId}` : ''}`)
          }
          else {

            redirectToDashboard &&
              userId &&
              history.push(`/course/${courseId}/${stat ? 'stat' : ''}`)
          }

        }
      }
    },
    [redirectToDashboard, userId, openInExternalTab],
  )

  const handleChangeRequest = async (
    draggedId: string,
    droppedId: string | undefined,
  ) => {
    const { data } = await changeCoursesOrder({
      variables: { draggedId, droppedId },
    })
  }

  const getCourseState = (state: string) => {
    let courseState = state
    switch (state) {
      case 'published':
        courseState = t('courses_layout.published')
        break
      case 'unpublished':
        courseState = t('courses_layout.unpublished')
    }

    return courseState
  }

  const getCourseLevel = (level: string) => {
    let courseLevel = level
    switch (level) {
      case 'beginner':
        courseLevel = t('courses_layout.beginner')
        break
      case 'intermediate':
        courseLevel = t('courses_layout.intermediate')
        break
      case 'advanced':
        courseLevel = t('courses_layout.advanced')
    }

    return courseLevel
  }

  useEffect(() => {
    setCards(data)
  }, [data])

  const isStudent = state?.currentUser.role === 'STUDENT'

  const isPriceHidden = !state?.currentUser?.companyId?.id

  const renderContent = (item: any) => {
    const isOnProfile = currentLocation.pathname.indexOf('profile') > -1

    const hasConverted = item?.video && item?.video?.links?.[0]?.converted

    return (
      <StylesCardWrapper
        className="wrapper"
        key={item.id}
        onClick={() => {
          RedirectToCourse(item, companyUser)
        }}
      >
        {fromWizard && selectedItem.includes(item.id) && <StyledChecked />}
        {checkbox && (
          <CardActionBar switched={!!(gridStyle === 'card')}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  zIndex: 3,
                }}
              >
                {exploreWenrollCoursesMode ||
                  (state.userPermission.deleteGroup && !isOnProfile && (
                    <Checkbox
                      onChange={() => selectItem(item.id)}
                      checked={selectedItem.includes(item.id)}
                    />
                  ))}
              </div>
            </div>
          </CardActionBar>
        )}
        {actions && actions.length > 0 && (
          <div
            className="action_block"
            style={{
              position: 'absolute',
              top: '15px',
              right: '15px',
              zIndex: 100,
              height: 0,
            }}
          >
            <GridActionMenu
              actionConfig={
                !!item.originalId && state.currentUser.companyId?.id
                  ? actionConfigForPurchasedCourse
                  : actions
              }
              row={item}
            />
          </div>
        )}
        <GridItem>
          <GridItemImage>
            <img
              src={
                (item.avatar && item.avatar.link) ||
                'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSHw6OeiQMdYX_iXu8otmzMnlbD07xEWr87EMnJ5r4X9vYC-kNr&usqp=CAU'
              }
              alt="Course Image"
            />
          </GridItemImage>
          <SContentContainer>
            <SCourseName>{item.name}</SCourseName>
            <SCourseInfo>{secondsToHms(item.duration)}
              {(item.progress < 100 && state.currentUser.role === "STUDENT" && item.progress != null) && `- ${t('general.progress')} ${item.progress}%`}
            </SCourseInfo>

            {state?.currentUser?.role === 'STUDENT' && item?.progress != undefined && item?.progress != 100 ? (
              <SLinearProgress variant='determinate' value={item.progress} />
            ) : ""}
            {sectionName != "myCourse" && <SCoursePrice>

              <Icon
                filename={'wallet'}
                fill={'#D3D0CE'}
                width={16}
                height={15}
              />
              {item.price ? `${item.price} ${item.currency === "GBP" ? "£" : item.currency === "GEL" ? "₾" : item.currency === "USD" ? "$" : item.currency === "EUR" ? "€" : ""} ` : t('courses_layout.free')}
            </SCoursePrice>}

          </SContentContainer>
        </GridItem>
      </StylesCardWrapper>
    )
  }

  return (
    <>
      {cards?.map((item: any, index: number) => {

        if (isDone) {
          return item.completed ? <React.Fragment key={item.id}>{renderContent(item)}</React.Fragment> : null
        }
        if (item.completed && getRole() === 'STUDENT') {
          return null
        }
        return isDraggable ? (
          <DndItem
            key={item.id}
            id={item.id}
            index={index}
            moveCard={moveCard}
            cardIds={cards?.map((item: any) => item.id)}
            onEnd={handleChangeRequest}
          >
            {renderContent(item)}
          </DndItem>
        ) : (

          // (section && item.isOwnCourse == true) ? null :
          < React.Fragment key={item.id} > {renderContent(item)}</React.Fragment>
        )
      }

      )}
    </>
  )
}

export default CourseList

function secondsToHms(d: any) {
  d = Number(d)
  var h = Math.floor(d / 3600)
  var m = Math.floor((d % 3600) / 60)
  var s = Math.floor((d % 3600) % 60)

  var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : ''
  var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : ''
  var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : ''
  return hDisplay + mDisplay + sDisplay
}

const defs = (args: any) => {
  const styles: any = {}
  if (args.switched) {
    styles.top = '0px'
    styles.right = '0px'
    styles.gridTemplateColumns = '1fr 35px'
  } else {
    styles.bottom = '0px'
    styles.right = '0px'
    styles.justifyContent = 'end'
    styles.gridTemplateColumns = '35px 35px'
  }
  return styles
}

const CardActionBar = styled.div<{ switched: boolean }>`
  width: 100%;
  position: absolute;
  z-index: 99;
  box-sizing: border-box;
  padding: 6px;

  ${defs}
  .action_block {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`

const StylesCardWrapper = styled.div`
  width: 370px;
  cursor: pointer;
  position: relative;

  &:hover {
    .action_block {
      background: rgba(255, 255, 255, 0.59) !important;
    }
  }
`

const StyledChecked = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgb(0 0 0 / 23%);
`

const CourseStatusContainer = styled.div<{ status: boolean }>`
  font-size: 13px;
  color: ${({ status }) => (!status ? '#f50057' : mainColor)};
  text-transform: capitalize;
`
const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 13px;
  }
`

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 14px;
  }
  div {
    font-size: 11px;
  }
`

const ItemBlock = styled.div`
  text-transform: capitalize;
`
const StyledItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
`
const CategoriesWrapper = styled.div`
  display: inline-block;
`
const Category = styled.div`
  font-size: 13px;
  display: inline-block;
  margin-right: 5px;
  padding: 0px 3px;
  border: 1px solid darkgrey;
  border-radius: 5px;
`

const SContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 25px;
  padding-bottom: 30px;
  gap: 10px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.4009978991596639) 0%,
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 0.3981967787114846) 100%
  );
  @media (max-width: 600px){
    padding: 0 15px 25px 15px ;
  }
`

const SCourseName = styled.div`
  color: #dbdada;
  font-size: 16px;
`

const SCourseInfo = styled.div`
  color: #dbdada;
  font-size: 14px;
`

const SCoursePrice = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #d3d0ce;
  border-radius: 5px;
  padding: 0px 10px;
  color: #b0aeac;
  font-size: 20px;
  width: max-content;
  height: max-content;
`

const SLinearProgress = styled(LinearProgress)`
  &.MuiLinearProgress,
  .MuiLinearProgress {
    &-root {
      border-radius: 10px;
      background: #F5F6F8;
      opacity: 0.5;
      height: 6px;
    }
    &-bar {
      border-radius: 10px;
      background: #317bf4;
    }
  }
`
