import { useState } from 'react'
import addStudentsService from 'services/group/addStudentsService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useAddStudentsInGroup = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)
  const { addStudents } = addStudentsService(params)

  const addStudentsInGroup = async (
    groupId: string,
    groupName: string,
    ids: string[] = [],
    refetch: any,
    studentInGroupRefetch: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors } = await addStudents({
      variables: { groupId, groupName, ids },
    })

    if (errors && errors.length) {
      setLoading(false)
      message = t('messages.something_wrong')
      variant = 'error'
      return
    }

    message = `${
      ids.length > 1 ? t('general.students') : t('general.student')
    } ${t('messages.successfully')} ${t('general.added')}`
    variant = 'success'

    setSnackbar({ message, variant })
    setLoading(false)
    refetch()
    if (studentInGroupRefetch) {
      studentInGroupRefetch()
    }
  }

  return {
    loading,
    addStudentsInGroup,
  }
}

export default useAddStudentsInGroup
