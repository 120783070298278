import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Box from '@material-ui/core/Box'
import { Button } from 'components/common/Button'
import { CircularProgressBarWithLabel } from 'components/common/CircularProgressBar/CircularProgressBar'
import { Row } from 'components/common/DetailsLayout'
import Icon from 'components/Icon'
import { CHECK_COURSE_COMPLETION } from 'gql/course/course.query'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { borderRadius } from 'react-select/src/theme'
import { mainColor, successGreen, validationRed } from '../../../components/common/Colors/CommonColors'
import { GET_QUIZ_RESULT } from 'gql/quiz/quiz.query'

type QuizResultsType = {
  score: number
  passed: boolean
}

const QuizResults: React.FC<{
  quizResults: QuizResultsType
  resultsLoading?: boolean,
  restartQuiz: any,
  courseId: string,
  onFinish: () => void
  getQuizResult?: () => any
  setCountdown?: (countdown: boolean) => void


}> = ({ quizResults, restartQuiz, courseId, onFinish, resultsLoading, getQuizResult, setCountdown }) => {
  const { t } = useTranslation()

  const history = useHistory()

  const [checkCourseCompletion, { error }] = useMutation(CHECK_COURSE_COMPLETION)

  useEffect(() => {
    checkCourseCompletion({
      variables: {
        courseId: courseId
      }
    })
    setCountdown && setCountdown(false)
  }, [])

  const redirectToCoursePage = useCallback(() => {
    if (courseId) {

      history.push(`/course/${courseId}`)
      onFinish()
    }
  }, [courseId])



  return (
    <>
      <Box style={{
        margin: 'auto',
        width: '770px',
        height: '363px!important',
        padding: '20px 35px',
        borderRadius: '16px',
        boxShadow: '0px 10px 30px #00000010',
        overflowY: 'scroll',
        background: '#fff'
      }}>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingBottom: '32px',
          }}
        >
          {/* <p
            style={{
              fontSize: '26px',
              letterSpacing: '0px',
              color: '#414141',
            }}
          >
            {t('user_tabs.quiz_performance')}
          </p> */}
          {/* <CircularProgressBarWithLabel
            strokeWidth={3}
            progressStrokeWidth={8}
            size={100}
            progress={quizResults?.score || 0}
          /> */}
          <div style={{
            width: '120px',
            height: '100px',
            border: '1px solid #080A0A08',
            borderRadius: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 30
          }}>
            {
              resultsLoading ? null :
                <div style={{
                  fontSize: 24,
                  color: mainColor,

                }}>
                  <span style={{
                    fontSize: 32
                  }}>{quizResults?.score}/</span>100
                </div>
            }
          </div>
        </Row>
        {
          resultsLoading ? null : quizResults?.passed ?
            <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '28px',
                  letterSpacing: '0px',
                  color: successGreen,
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                {t("general.congratulation")}! <br /> {t('general.youPassedQuiz')}
              </p>
            </Row> : <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '28px',
                  letterSpacing: '0px',
                  color: validationRed,
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                {t("general.youFailedQuiz")}
              </p>
            </Row>
        }
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '32px',
            gap: '20px',
          }}
        >
          <Button
            onClick={() => {
              restartQuiz()
              setCountdown && setCountdown(true)
            }}
            text={t('general.tryAgain')}
            width={204}
            height={52}
            background={mainColor}
            textColor={'#fff'}
            buttonStyles={{
              borderRadius: '16px',
            }}
            icon={(
              <Icon filename={'quiz_retake'} />
            )}
          />
          <Button
            text={t('actions.close')}
            onClick={redirectToCoursePage}
            background={'#fff'}
            textColor={mainColor}
            width={100}
            height={36}
            outline={`1px solid ${mainColor}`}
          />
        </Row>
      </Box>
    </>
  )
}

export default QuizResults
