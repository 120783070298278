import React, { useState, useEffect } from 'react'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import styled from 'styled-components'
import { useUserValue } from 'context/UserContext'
import { useEditProfile } from 'hooks/users/useEditProfileService'
import { useTranslation } from 'react-i18next'
import { mainColor } from 'components/common/Colors/CommonColors'

const Settings = (props: any) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [value, setValue] = useState(false)
  const { editProfile } = useEditProfile(state.currentUser)

  const handleSwitchChange = (e: any) => {
    editProfile({
      settings: {
        askPassword: !value,
      },
    })
    setValue(!value)
  }
  const {
    currentUser: { settings },
  } = state

  useEffect(() => {
    if (settings) {
      setValue(settings.askPassword)
    }
  }, [settings])

  return (
    <Container>
      <StyledH1>{t('settings_layout.title')}</StyledH1>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={handleSwitchChange}
            value="checkedB"
            style={{ color: mainColor }}
          />
        }
        label={t('settings_layout.aks_new_user_for_password_change')}
      />
    </Container>
  )
}

export default Settings

const StyledH1 = styled.h1`
  margin-bottom: 10px;
`
const Container = styled.div`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${mainColor};
  }
`
