import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAction } from 'store/actions'
import _ from 'lodash'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

import Loader from 'components/common/Loader'
import CompaniesWizardPage1 from './CompaniesWizardPage1'
import CompaniesWizardPage2 from './CompaniesWizardPage2'
import Step2 from './Step2/Step2'
import { Button } from 'components/common/Button'
import Stepper from 'components/common/Stepper'
import { useFormik } from 'formik'

import useAddCompanyService from 'hooks/companies/useAddCompanyService'
import { CHECK_COMPANY_EMAIL } from 'gql/companies.query'
import { CompaniesWizardPage1Schema } from 'helpers/validationSchemas'
import { validationRed, red } from 'components/common/Colors/CommonColors'

import {
  DrawerHeaderSteps,
  DrawerStepsTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import { forIn } from 'lodash'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    stepper: {
      padding: '16px',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
)

function getSteps() {
  return ['Company Details', 'Purchased Courses', 'Invite Company Super Admins']
}

const formData = {
  name: '',
  companyEmail: '',
  phone: '',
  city: '',
  companyActivity: null,
  numberOfUsers: null,
  country: null,
  postalCode: '',
  address: '',
  platformService: false,
  keyContactName: '',
  note: '',
}

interface Props {
  closeDrawer?: () => void
  refetchCompany?: any
}

const steps = getSteps()

const CompaniesWizard = ({ closeDrawer = () => { }, refetchCompany }: any) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { toggleDrawerConfirm } = useAction()
  const [activeStep, setActiveStep] = useState<number>(0)
  const [formValues, setFormValues] = useState({})
  const { addCompany, data, loading } = useAddCompanyService({ filter: {} })
  const [checkEmail] = useMutation(CHECK_COMPANY_EMAIL)
  const [selectedCourses, setSelectedCourses] = useState<any>({})
  const [companyManagers, setCompanyManagers] = useState([])

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: CompaniesWizardPage1Schema,
    async onSubmit(values) {
      try {
        if (activeStep === 0) {
          const res = await checkEmail({
            variables: { email: values.companyEmail },
          })

          if (res.errors) {
            errors.companyEmail = res.errors[0].message
            return false
          }
        }

        if (activeStep < 2) {
          setActiveStep(activeStep + 1)
          return
        }

        const data: any = { ...values }

        if (data.companyActivity) {
          data.companyActivity = [
            {
              label: data.companyActivity.label,
            },
          ]
        }

        if (data.country) data.country = data.country.value

        data.managers = companyManagers || []

        let coursesId: string[] = []

        if (!_.isEmpty(selectedCourses)) {
          for (const key in selectedCourses) {
            coursesId = [...selectedCourses[key], ...coursesId]
          }
        }

        addCompany(data, coursesId, refetchCompany.refetchCompany)
      } catch (err) {
        console.log('Error from checkEmail request', err)
      }
    },
  })

  useEffect(() => {
    if (data) {
      toggleDrawerConfirm(false, '')
      closeDrawer()
    }
  }, [data])

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <CompaniesWizardPage1
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        )
      case 1:
        return (
          <Step2
            setSelectedCourses={setSelectedCourses}
            selectedCourses={selectedCourses}
          />
        )
      case 2:
        return (
          <CompaniesWizardPage2
            setCompanyManagers={setCompanyManagers}
            companyManagers={companyManagers}
          />
        )
      default:
        return <div>Error...!</div>
    }
  }

  return (
    <div style={{ height: '100%' }}>
      {loading && <Loader width="100%" height="100%" />}
      <DrawerHeaderSteps>
        <DrawerStepsTitle>
          {t('companies_layout.create_company')}
        </DrawerStepsTitle>
        <div className={classes.root}>
          <Stepper active={activeStep} steps={steps} />
        </div>
      </DrawerHeaderSteps>
      <DrawerContent headerHeight={168}>
        <FormContainer onSubmit={(e: any) => e.preventDefault()}>
          <FormGroupWrapper padding={10}>
            {getStepContent(activeStep)}
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons multiCols={true}>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={closeDrawer}
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text="Prev"
                type="small"
                color="secondary"
                isDisabled={activeStep === 0}
                onClick={() => setActiveStep(activeStep - 1)}
              />
              <Button
                text={activeStep === 2 ? 'Save' : 'Next'}
                type="small"
                color="secondary"
                onClick={handleSubmit}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </div>
  )
}

export default CompaniesWizard

const WizardsWrapper = styled.div`
  position: absolute;
  width: 100%;
  /* height: 100%; */
  top: 0;
  left: 0;

  &.hidden {
    visibility: hidden;
    pointer-events: none;
  }
`

const FooterButtons = styled.div``
