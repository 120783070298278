import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { FilterCompanySchema } from 'helpers/validationSchemas'
import { useTranslation } from 'react-i18next'

import {
  DrawerHeader,
  DrawerContent,
  DrawerTitle,
  CloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'

import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import { SelectField } from 'components/common/SelectField'
import {
  platformServiceOptions,
  statusOptions,
  filterOptions,
} from 'pages/companies/staticData'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import DateRangePicker from 'components/common/DateRangePicker/DateRangePicker'
import { useTheme } from '@material-ui/core'

const FilterCompanies = (props: any) => {
  const { t } = useTranslation()
  const [error, setError] = useState(false)

  const {
    handleSubmit,
    handleChange,
    values,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: filterOptions,
    validationSchema: FilterCompanySchema,
    onSubmit(values) {
      const formValues: any = {}

      if (values.name) {
        formValues.name = {
          type: 'search',
          value: values.name,
          fields: ["name"]
        }
      }

      if (values.platformService.value !== 'all') {
        formValues.platformService = {
          type: 'exact',
          value: values.platformService.value,
        }
      }

      if (values.status.value !== 'all') {
        formValues.status = {
          type: 'exact',
          value: values.status.value,
        }
      }

      if (!values.createDate[1] && values.createDate[0] !== null)
        values.createDate[1] = new Date()

      if (values.createDate[1] !== null || values.createDate[0] !== null) {
        formValues.createDate = {
          type: 'DATE_RANGE',
          start: new Date(values.createDate[0]),
        }
      }

      props.setCurrentPage(1)
      props.setFilterOptions(values)
      props.filter(formValues)
    },
  })

  useEffect(() => {
    if (props.filterOptions) {
      setValues(props.filterOptions)
    }
  }, [props.filterOptions])

  const theme = useTheme()

  return (
    <>
      {props.filterLoading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>
          {t('general.advanced_filters')} -{' '}
          {t('companies_layout.filter_companies')}
        </DrawerTitle>

        <CloseIcon
          onClick={() => props.onClose('reset')}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width="100%">
                <TextInput
                  label={t('form_fields.name')}
                  name="name"
                  size="medium"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                />
              </FormField>

              <FormField style={{ position: 'relative' }} width="48%">
                <Label>{t('companies_layout.platform_service')}</Label>
                <SelectField
                  label={t('companies_layout.platform_service')}
                  options={platformServiceOptions}
                  value={values.platformService}
                  onChange={(e: any) => setFieldValue('platformService', e)}
                />
              </FormField>

              <FormField style={{ position: 'relative' }} width="48%">
                <Label>{`${t('general.company')} ${t(
                  'general.status',
                )}`}</Label>
                <SelectField
                  label={`${t('general.company')} ${t('general.status')}`}
                  options={statusOptions}
                  value={values.status}
                  onChange={(e: any) => setFieldValue('status', e)}
                />
              </FormField>
            </FormFields>

            <FormField
              style={{ position: 'relative', width: '94%', margin: '0 auto' }}
            >
              <DateRangePicker
                placeholder={t('general.create_date')}
                values={values.createDate}
                onChange={(e: any) => {
                  setFieldValue('createDate', e)
                }}
              />
            </FormField>
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={() => props.onClose('reset')}
                background="#F5F5F5"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.filter')}
                type="medium"
                color="secondary"
                btnType="submit"
                background={'#317BF4'}
                isDisabled={!!error}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterCompanies

const Label = styled.div`
  position: absolute;
  top: -21px;
  font-size: 13px;
`
