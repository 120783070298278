import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import editLessonService from 'services/lessons/editLessonService'

const useEditLessonService = (params: any) => {
  const { t } = useTranslation()
  const { editLessonMutation, loading } = editLessonService(params)
  const { setSnackbar } = useSnackbarAlert()

  const editLesson = async (
    topicId: string,
    lessonId: string,
    input: any,
    fun: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await editLessonMutation({
      variables: {
        topicId,
        lessonId,
        input,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = t('messages.changes_saved')
      variant = 'success'
    }

    setSnackbar({ message, variant })
    fun()
  }

  return {
    loading,
    editLesson,
  }
}

export default useEditLessonService
