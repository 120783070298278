import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { useData } from 'context/DataContext'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'

import { useDeleteSectionCourses } from 'hooks/sections/useSections'
import useAddSectionService from 'hooks/sections/useAddSectionService'
import useAddSectionCourses from 'hooks/sections/useAddSectionCoursesService'
import useEditSection from 'hooks/sections/useEditSectionService'

import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import Drawer from 'components/common/Drawer'
import { useSwal } from 'hooks/useSwal'
import { SubscriptionSchema } from 'helpers/validationSchemas'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'

import Loader from 'components/common/Loader'

import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'

import styled from 'styled-components'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

import { CREATE_SUBSCRIPTION } from 'gql/subscriptions/subscription.query'
import { useHistory } from 'react-router'

import _ from 'lodash'

import { SelectField } from 'components/common/SelectField'

import { useMutation } from '@apollo/react-hooks'

enum SectionPopulatingActions {
  CREATE = 'createSection',
  EDIT = 'editSection',
}

interface SelectedCourse {
  id: string
  name: string
  savedInDb?: boolean
  avatar?: {
    link: string
    __typename?: string
  }
  __typename?: string
}

interface ISectionInfo {
  courses: SelectedCourse[]
  name: string
  id: string
}
enum Currency {
  GEL = 'GEL',
  USD = 'USD',
  EUR = 'EUR',
}

interface Props {
  // title: string
  // data: ISectionInfo
  // drawerName?: string
  // closeDrawer: () => void
  title: string
  data: {
    sectionInfo: ISectionInfo | null
    type: string
    onSuccess: () => void
  }
  drawerName?: string
  closeDrawer: () => void
}

interface formDataInterface {
  occurrenceNumber: any
  price: number
  currency: any
}

const PER_PAGE = 10

const SubscriptionDrawer = ({
  title,
  data,
  drawerName,
  closeDrawer,
}: Props) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [dataState, dispatchData] = useData()
  const theme = useTheme()
  const history = useHistory()
  const { fireSwal } = useSwal()

  // const courseId: any = data.courseId || ''

  const [filterData, setFilterData] = useState<any>({})
  const [searchText, setSearchText] = useState<string>('')
  const [filterIsOpen, setFilterIsOpen] = useState(false)

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)

  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})

  const [selectedCourses, setSelectedCourses] = useState<SelectedCourse[]>(
    data?.sectionInfo?.courses || [],
  )

  const formData: formDataInterface = {
    occurrenceNumber: null,
    price: 0,
    currency: null,
  }

  const skip: number = (currentPage - 1) * perPage
  const lastRenderedCourseIndex: number = (currentPage - 1) * perPage + perPage

  const gridData: SelectedCourse[] = selectedCourses.slice(
    skip,
    lastRenderedCourseIndex,
  )

  const { addSection, newSection, addSectionLoading } = useAddSectionService()
  const {
    deleteSectionCourses,
    deletedSectionCourses,
    deleteSectionCoursesLoading,
    setDeletedSectionCourses,
    setSnackbar,
  } = useDeleteSectionCourses()

  const {
    addSectionCourses,
    addSectionCoursesLoading,
    updatedSection,
  } = useAddSectionCourses()
  const { editSection, editSectionLoading, editedSection } = useEditSection()

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isValid,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: SubscriptionSchema,

    onSubmit(values) {

      createSubscriptionProduct({
        variables: {
          input: {
            currency: values.currency.value,
            occuarenceType: 'MONTH',
            price: values.price,
            occurrenceNumber: values.occurrenceNumber.value,
          },
        },
      })
        .then(x => {
          data.onSuccess()
        })
        .finally(() => {
          closeDrawer()
        })
    },
  })

  useEffect(() => {
    if (newSection || updatedSection || editedSection) {
      closeDrawer()
      dispatchData({ type: CLEAR_FORMDATA })
      data.onSuccess()
    }
  }, [newSection, updatedSection, editedSection])

  const resetFilter = () => { }

  const filterDrawer = () => {
    setFilterIsOpen(!filterIsOpen)
  }

  const onSearchSubmit = (e: React.FormEvent) => { }

  const handleUpdateSelectedCourses = (markedCoursesIds: string[]) => {
    const successMessage = t('messages.success')
    const successVariant = 'success'

    setSelectedCourses(previousSelectedCourses => {
      return previousSelectedCourses.filter(
        item => !markedCoursesIds.includes(item.id),
      )
    })
    setSelectedItem({})
    setSelectAll({})
    setSnackbar({ message: successMessage, variant: successVariant })
  }

  const onDeleteConfirm = (markedCoursesIds: string[]) => {
    if (data?.sectionInfo) {
      const savedCoursesIds = gridData
        .filter(
          (i: SelectedCourse) =>
            markedCoursesIds?.includes(i.id) && i.savedInDb,
        )
        .map((i: SelectedCourse) => i.id)

      if (savedCoursesIds.length > 0) {
        deleteSectionCourses(data.sectionInfo?.id, savedCoursesIds)
      } else {
        handleUpdateSelectedCourses(markedCoursesIds)
      }
    } else {
      handleUpdateSelectedCourses(markedCoursesIds)
    }
  }

  const selectItem = (id: string) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const isStudent = state.currentUser.role === 'STUDENT'

  const isSuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !state?.currentUser?.companyId?.id

  const canModify = isSuperAdmin && !isStudent

  const [createSubscriptionProduct, { loading, error }] = useMutation(
    CREATE_SUBSCRIPTION,
  )

  const options = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
  ]
  const handleSetSelectedCourses = (courses: SelectedCourse[]) =>
    setSelectedCourses(courses)

  return (
    <>
      {addSectionLoading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{title}</DrawerTitle>

        <CloseIcon
          // onClick={handleCloseDrawer}
          onClick={closeDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent headerHeight={500} margin={'10px'}>
        <FormContainer onSubmit={handleSubmit}>
          {/* <FormGroupWrapper> */}
          <div>
            <FormFields>
              <FormField>
                <SelectField
                  error={errors.occurrenceNumber ? true : false}
                  touched={touched.occurrenceNumber}
                  errorMessage={
                    errors.occurrenceNumber ? errors.occurrenceNumber : ''
                  }
                  placeholder={t('general.chooseOccurrence')}
                  name="occurrenceNumber"
                  options={[
                    { label: 1, value: 1 },
                    { label: 6, value: 6 },
                    { label: 12, value: 12 },
                    { label: 24, value: 24 },
                  ]}
                  value={values.occurrenceNumber}
                  onChange={(e: any) => {
                    setFieldValue('occurrenceNumber', e)
                  }}
                />
              </FormField>
              <FormField>
                <TextInput
                  error={errors.price ? true : false}
                  errorMessage={errors.price ? errors.price : ''}
                  touched={touched.price}
                  label={`${t('form_fields.price')}*`}
                  size="small"
                  name="price"
                  type="number"
                  min={1}
                  value={values.price}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <SelectField
                  error={errors.currency ? true : false}
                  touched={touched.occurrenceNumber}
                  errorMessage={errors.currency ? errors.currency : ''}
                  placeholder={t('general.chooseCurrency')}
                  name="currency"
                  options={[
                    { label: Currency.GEL, value: Currency.GEL },
                    { label: Currency.USD, value: Currency.USD },
                    { label: Currency.EUR, value: Currency.EUR },
                  ]}
                  value={values.currency}
                  onChange={(e: any) => {
                    setFieldValue('currency', e)
                  }}
                />
              </FormField>
            </FormFields>
          </div>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type="medium"
                btnType="submit"
                background={'#317BF4'}
                buttonStyles={{
                  borderRadius: '8px',
                }}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default SubscriptionDrawer

const CloseIcon = styled(Icon)`
  position: absolute;
  top: 30px;
  right: 50px;
  cursor: 'pointer';
`

const Info = styled.div`
  position: absolute;
  bottom: -15px;
  left: 75px;
  width: 35px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 100%;
`

const Container = styled.div`
  overflow: hidden;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  height: 100%;
`

const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #317bf4;
  padding: 18px 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    background: #3165f4;
  }
`

const GridStyledToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
`
