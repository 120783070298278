import { useMutation } from '@apollo/react-hooks'

import { GET_QUESTIONS, CREATE_QUESTION } from 'gql/questions.query'
import { updateCache } from 'services/updateCache'

const createQuestionService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [createQuestionMutation, { loading, error }] = useMutation(
    CREATE_QUESTION,
    {
      update(cache, { data: { createQuestion: document } }) {
        updateCache({
          objectName: 'questions',
          query: GET_QUESTIONS,
          cache,
          document,
          action: 'add',
          params,
        })
      },
    },
  )

  return {
    createQuestionMutation,
    loading,
    error,
  }
}

export default createQuestionService
