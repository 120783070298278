import { useState } from 'react'
import deleteTopicService from 'services/topics/deleteTopicService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

export const useDeleteTopic = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [deletedTopic, setDeletedTopic] = useState('')
  const [error, setError] = useState('')
  const { deleteTopicMutation, loading } = deleteTopicService(params)

  const deleteTopic = async (
    moduleId: string,
    topicIds: string[],
    onSuccess: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await deleteTopicMutation({
      variables: {
        moduleId,
        topicIds,
      },
    })


    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return false
      }
      variant = 'error'
    } else {
      onSuccess()
      setDeletedTopic(data?.deleteTopics)
      const isMultiple = topicIds.length > 1 ? true : false
      message = isMultiple
        ? `${t('general.topics')} ${t('messages.deleted')}`
        : `${t('general.topic')} ${t('messages.deleted')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteTopic,
    deleteTopicLoading: loading,
    deletedTopic: deletedTopic,
    error,
  }
}
