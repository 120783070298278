import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

interface Props {
  width?: number | string
  height?: number | string
  withBackground?: boolean
}

const Loader = ({
  width = '40px',
  height = '40px',
  withBackground = false,
}: Props) => {
  return (
    <LoaderWrapper className={`${withBackground ? 'withBackground' : ''}`}>
      <LoaderCircle>
        <CircularProgress style={{ width, height }} />
      </LoaderCircle>
    </LoaderWrapper>
  )
}

export default Loader

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.withBackground {
    background: rgba(255, 255, 255, 1);
  }
`

const LoaderCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
