import React from 'react'
import Checkbox from 'components/common/Checkbox/Checkbox'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import AvatarIcon from '../../../components/common/AvatarInput/profile.svg'
import {
  CheckBoxAndAvatarContainer,
  GroupItem,
  GroupTitle,
  StatusCircle,
  StatusWrapper,
} from 'pages/companyWizard/CompanyStudents/gridConfig'
import Tooltip from '@material-ui/core/Tooltip'
import { GroupsWrapper } from 'components/common/DetailsLayout'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import { NoValueWrapper } from 'pages/companies/CompanyDetails/styled-components'
import GroupIcon from '@material-ui/icons/Group'
import SchoolIcon from '@material-ui/icons/School'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import strCutter from 'utils/strCutter'
import { mainColor } from 'components/common/Colors/CommonColors'
import { CapitalPlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'

interface itemProps {
  id: string
  isChecked: boolean
  firstName: string
  lastName: string
  email: string
  phone: string
  avatar: any
  group: any
  courses: any
  status: any
  createDate: any
  companyId: any
}

export const columnConfig = (selectItem: any, t: any, onGroupClick?: any) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={item.isChecked}
            onChange={() => selectItem(item.id)}
          />
          <img src={item.avatar || AvatarIcon} alt="Avatar" />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('user_details.first_name'),
      render: (item: itemProps) => (
        <div style={{ cursor: 'pointer' }}>{item.firstName}</div>
      ),
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('user_details.last_name'),
      render: (item: itemProps) => <div>{item.lastName}</div>,
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: false,
      label: t('user_details.email'),
      render: (item: itemProps) => <div>{item.email}</div>,
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: t('user_details.phone'),
      render: (item: itemProps) => <div>{item.phone}</div>,
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item: itemProps) => (
        <StatusWrapper className={`${item.status.toLowerCase()}`}>
          <StatusCircle />
          {item.status}
        </StatusWrapper>
      ),
    },
    {
      id: 'group',
      numeric: false,
      disablePadding: false,
      label: `${t('general.groups')}`,
      render: (item: itemProps) => {
        const firstTwoGroups = item.group.slice(0, 2)
        const exGroups = item.group.slice(2, item.group.length)
        const groups = item.group
        // console.log('firstTwoGroups', firstTwoGroups)
        // console.log('exGroups', exGroups)
        return (
          <div>
            {firstTwoGroups.length === 0 && (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
            <GroupsWrapper>
              {firstTwoGroups.map(
                (group: any, index: number) =>
                  index < 3 && (
                    <GroupItem key={group.groupId}>
                      <IconButton onClick={() => onGroupClick(group.groupId)}>
                        <GroupIcon fontSize="small" />
                      </IconButton>
                      <GroupTitle className="group-item-title">
                        {group.name}
                      </GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exGroups.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exGroups.map((exGroup: any) => (
                        <div>{exGroup.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'courses',
      numeric: false,
      disablePadding: false,
      label: `${t('general.courses')}`,
      render: (item: itemProps) => {
        const courses = (item.courses && item.courses) || []
        const firstTwoCourses = courses.slice(0, 2)
        const exCourse = courses.slice(2, courses.length)
        return (
          <div>
            {firstTwoCourses.length === 0 && (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
            <GroupsWrapper>
              {courses.map(
                (course: any, index: number) =>
                  index < 3 && (
                    <GroupItem key={course.courseId}>
                      <IconButton onClick={() => onGroupClick(course.courseId)}>
                        <SchoolIcon fontSize="small" />
                      </IconButton>
                      <GroupTitle className="group-item-title">
                        {course.name}
                      </GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exCourse.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exCourse.map((exGroup: any) => (
                        <div>{exGroup.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: itemProps) => (
        <div>
          {item.companyId ? (
            strCutter(item.companyId.name, 25)
          ) : (
            <>
              {CapitalPlatformTitle}
              <StarsRoundedIcon
                style={{
                  color: mainColor,
                  fontSize: 10,
                  verticalAlign: 'super',
                }}
              />
            </>
          )}
        </div>
      ),
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: itemProps) => {
        return (
          <StatusWrapper>
            <StatusCircle />
            {item.createDate ? (
              moment(parseInt(item.createDate)).format('DD.MM.YYYY')
            ) : (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
          </StatusWrapper>
        )
      },
    },
  ]

  return grid
}

export const nonAdminColumnConfig = (selectItem: any, t: any, onGroupClick?: any) => {
  const grid = [
    {
      id: 'avatar',
      render: (item: itemProps) => (
        <CheckBoxAndAvatarContainer>
          <img src={item.avatar || AvatarIcon} alt="Avatar" />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('user_details.first_name'),
      render: (item: itemProps) => (
        <div style={{ cursor: 'pointer' }}>{item.firstName}</div>
      ),
    },
    {
      id: 'lastname',
      numeric: false,
      disablePadding: false,
      label: t('user_details.last_name'),
      render: (item: itemProps) => <div>{item.lastName}</div>,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('user_details.email'),
      render: (item: itemProps) => <div>{item.email}</div>,
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: t('user_details.phone'),
      render: (item: itemProps) => <div>{item.phone}</div>,
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item: itemProps) => (
        <StatusWrapper className={`${item.status.toLowerCase()}`}>
          <StatusCircle />
          {item.status}
        </StatusWrapper>
      ),
    }
  ]

  return grid
}

export const actionConfig = (handleClick: any, t: any) => {
  const action = [
    {
      render: (item: any) => (
        <Action
          onClick={() =>
            handleClick(
              item.id,
              'move',
              item.group.map((group: any) => group.groupId),
            )
          }
        >
          <DragIndicatorIcon />
          <Typography>{t('actions.move_to_another_group')}</Typography>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={() => handleClick(item.id, 'delete')}>
          <DeleteForeverRoundedIcon />
          <Typography>{t('actions.delete')}</Typography>
        </Action>
      ),
    },
  ]
  return action
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
`
