import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useFetchUsers } from 'hooks/users/useUsers'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import useUserDeleteService from 'hooks/users/useDeleteUserService'
import useUpdateStatusUserService from 'hooks/users/useUpdateStatusUserService'
import useChangeCoachQualification from 'hooks/users/useChangeCoachQualificationService'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import useResendUserService from 'hooks/users/useUpdateUserPasswordService'

import IconButton from '@material-ui/core/IconButton'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import DeleteIcon from '@material-ui/icons/Delete'
import BackspaceRoundedIcon from '@material-ui/icons/BackspaceRounded'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ArchiveIcon from '@material-ui/icons/Archive'

import { Wrapper } from '../styled-components'

import Drawer from 'components/common/Drawer'
import Grid from 'components/common/Grid'
import { userActionConfig, columnConfig } from './config'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import TablePagination from 'components/common/Pagination/TablePagination'
import { FilterStudents } from 'pages/students'
import { FilterCoaches } from 'pages/coaches'
import { FilterAdmins } from 'pages/admins'
import { mainColor } from 'components/common/Colors/CommonColors'
import { FilterModerators } from 'pages/moderators'
import ResetUsersPassword from 'pages/profile/AllUserProfile/ResetPassword'
import {
  GridToolbar,
  GridLayoutActions,
  AddButton,
  GridLayoutForm,
  ActionButton,
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import Search from 'components/common/Search'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

interface Params {
  variables: any
  coaches: boolean
  super_admin?: boolean
  currentTab: number
  assignCoaches?: boolean
  t?: any
}

const CompanyUsers = ({
  variables,
  coaches,
  super_admin,
  currentTab,
  assignCoaches,
  t,
}: Params) => {
  const { role, filter } = variables
  const defaultFilter = { ...filter }
  const history = useHistory()
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const { users, loading, refetch }: any = useFetchUsers(role, defaultFilter)

  const { deleteUser } = useUserDeleteService({
    role,
  })

  const {
    updateUserStatus,
    updateUserStatusLoaing,
  } = useUpdateStatusUserService({
    role,
  })

  const {
    changeCoachQualification,
    changeCoachQualificationLoading,
  } = useChangeCoachQualification({
    role: 'COACH',
    defaultFilter,
  })

  const { updateUserPassword } = useResendUserService({
    role,
  })

  const { exportData } = useExportDataService()

  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [filterQuery, setFilterQuery] = useState<any>(defaultFilter)
  const [filterOptions, setFilterOptions] = useState<any>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [resetVisible, setResetVisible] = useState(false)
  const [isResetPasswordOpen, setResetPasswordOpen] = useState(false)
  const [userId, setUserId] = useState('')
  const theme = useTheme();


  const selectItem = (id: never, event: boolean) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = users?.data.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const confirmDeleteMany = (ids: string[], role: string) => {
    deleteUser(ids, role, () =>
      refetch({
        role,

        filter: filterQuery,
        currentPage,
        perPage,
      }),
    )
    setSelectedItem([])
  }

  const confirmChangeStatusMany = (ids: string[], newStatus: string) => {
    updateUserStatus(
      ids.length > 0 ? ids : selectedItem[currentPage] || [],
      newStatus,
    )
    // setSelectedItem([])
  }

  const confirmChangeQualification = (ids: string[], isQualified: boolean) => {
    let qualify: boolean = isQualified ? false : true
    changeCoachQualification(ids, qualify)
  }

  const handleEditClick = (userId: string) => {
    let drawer = ''
    let drawerTitle = ''
    switch (role) {
      case 'ADMIN':
        drawer = 'editAdmin'
        drawerTitle = 'Edit Admin'
        break
      case 'SUPER_ADMIN':
        drawer = 'editAdmin'
        drawerTitle = 'Edit Super Admin'
        break
      case 'STUDENT':
        drawer = 'editStudent'
        drawerTitle = 'Edit Student'
        break
      case 'COACH':
        drawer = 'editCoach'
        drawerTitle = 'Edit Coach'
        break
      default:
        drawer = ''
    }
    DrawerEventEmitter.emit('openDrawer', drawer, true, {
      id: userId,
      role,
    })
  }

  const userRole = () => {
    let formattedRole = ''
    if (
      (selectAll?.['1'] &&
        !!selectedItem?.['1'] &&
        selectedItem?.['1'].length > 1) ||
      (!!selectedItem?.['1'] && selectedItem?.['1'].length > 1)
    ) {
      if (role === 'SUPER_ADMIN') {
        formattedRole = t('general.super_admins')
      } else if (role === 'ADMIN') {
        formattedRole = t('general.admins')
      } else if (role === 'COACH') {
        formattedRole = t('general.coaches')
      } else if (role === 'STUDENT') {
        formattedRole = t('general.students')
      } else formattedRole = role
    } else {
      if (role === 'SUPER_ADMIN') {
        formattedRole = t('general.super_admin')
      } else if (role === 'ADMIN') {
        formattedRole = t('general.admin')
      } else if (role === 'COACH') {
        formattedRole = t('user_profile.coach')
      } else if (role === 'STUDENT') {
        formattedRole = t('user_profile.student')
      } else formattedRole = role
    }
    return formattedRole
  }

  const handleDeleteAllClick = () => {
    const selectedUsers = selectedItem[currentPage].map(
      (selectedItem: string) => {
        const usersToDelete = users?.data.filter(
          (user: any) => user.id === selectedItem,
        )
        return usersToDelete[0]
      },
    )

    const selectedUsersWithGroup = selectedUsers.filter(
      (user: any) => user.group.length !== 0,
    )

    if (
      selectedItem[currentPage] &&
      selectedItem[currentPage].includes(state.currentUser.id)
    ) {
      fireSwal({
        title: t('popups.delete_yourself'),
        showConfirmButton: false,
      })
    } else {
      if (selectedUsersWithGroup.length !== 0 && role === 'ADMIN') {
        fireSwal({
          title:
            selectedItem['1'].length === 1
              ? t('popups.can_not_delete_admin_single')
              : t('popups.can_not_delete_admin_many'),
          onConfirm: () => {
            history.push('/groups')
          },
          confirmText: t('actions.remove_admin_from_group_confirm'),
        })
      } else {
        fireSwal({
          title: `${selectAll?.['1'] ||
            (!!selectedItem?.['1'] && selectedItem?.['1'].length > 0)
            ? t('actions.remove_selected_user')
            : t('actions.remove_user_from_actions')
            } ${userRole()}?`,
          onConfirm: () => confirmDeleteMany(selectedItem[currentPage], role),
          confirmText: t('popups.confirm_delete'),
        })
      }
    }
  }

  const handleDeleteClick = (user: any) => {
    let params: any = {}

    if (role === 'SUPER_ADMIN' && users?.data.length <= 1) {
      params = {
        title: `${selectAll?.['1'] ||
          (!!selectedItem?.['1'] && selectedItem?.['1'].length > 0)
          ? t('actions.remove_selected_user')
          : t('actions.remove_user_from_actions')
          } ${userRole()}?`,
        onConfirm: () => { },
        confirmText: t('actions.yes'),
      }

      fireSwal(params)

      return
    }

    if (role === 'ADMIN' && user.group.length !== 0) {
      params = {
        title: t('actions.remove_admin_from_group'),
        onConfirm: () => {
          history.push('/groups')
        },
        confirmText: t('actions.remove_admin_from_group_confirm'),
      }
    } else {
      params = {
        title: `${selectAll?.['1'] ||
          (!!selectedItem?.['1'] && selectedItem?.['1'].length > 0)
          ? t('actions.remove_selected_user')
          : t('actions.remove_user_from_actions')
          } ${userRole()}?`,
        onConfirm: () => {
          confirmDeleteMany([user.id], role)
        },
        confirmText: t('popups.confirm_delete'),
      }
    }

    fireSwal(params)
  }

  const handleChangeStatusAllClick = (ids: string[], action: string) => {
    let title: string = ''
    let newStatus: string = ''
    let confirmText: string = ''
    let showConfirmButton: boolean = true

    if (
      selectedItem[currentPage] &&
      selectedItem[currentPage].includes(state.currentUser.id)
    ) {
      title = `${t('popups.suspend_yourself')}`
      showConfirmButton = false
    } else {
      showConfirmButton = true
      if (action === 'suspend') {
        title = `${selectAll?.['1'] ||
          (!!selectedItem?.['1'] && selectedItem?.['1'].length > 0)
          ? t('actions.deactivate_different_roles_from_checkbox')
          : t('actions.deactivate_different_roles_from_action')
          } ${userRole()}?`
        newStatus = 'SUSPENDED'
        confirmText = t('general.yes')
      } else {
        title = `${selectAll?.['1'] ||
          (!!selectedItem?.['1'] && selectedItem?.['1'].length > 0)
          ? t('actions.activate_different_roles_from_checkbox')
          : t('actions.activate_different_roles_from_action')
          } ${userRole()}?`
        newStatus = 'ACTIVE'
        confirmText = t('general.yes')
      }
    }

    fireSwal({
      title,
      onConfirm: () => confirmChangeStatusMany(ids, newStatus),
      confirmText,
      showConfirmButton,
    })
  }

  const handleExportData = () => {
    if (selectedItem[currentPage].length > 0) {
      exportData(role, selectedItem[currentPage], (link: string) => {
        window.open(link, '_blank')
      })
    }
  }

  const handleChangeQualificationManyClick = (
    selectedItems: string[],
    isQualified: boolean,
  ) => {
    let title: string = ''
    let confirmText: string = ''

    if (isQualified) {
      title = `<span style="color:red">Are you sure you want to make this coaches Unqualified ?</span>`
      confirmText = 'Yes, Unqualify!'
    } else {
      title = `<span style="color: ${mainColor}">Are you sure you want to make this coaches Qualified ?</span>`
      confirmText = 'Yes, Qualify!'
    }

    fireSwal({
      title,
      onConfirm: () => confirmChangeQualification(selectedItems, isQualified),
      confirmText,
    })
  }

  const handleResendPasswordClick = (userId: string) => {
    fireSwal({
      title: t('popups.resend_password'),
      onConfirm: () => {
        updateUserPassword(userId)
      },
      confirmText: t('popups.confirm_resend_password'),
    })
  }

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    refetch({
      role,
      filter: filterQuery,
      currentPage: newPage + 1,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    refetch({
      role,
      filter: filterQuery,
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
    setSelectedItem({})
  }

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formValue = {
      search: {
        type: 'search',
        value: searchValue,
        fields: ['firstName', 'lastName', 'email', 'phone'],
      },
    }
    let formData = { ...filterQuery }
    if (searchValue !== '') {
      formData = { ...filterQuery, ...formValue }
    } else {
      const { search, ...filters } = filterQuery
      formData = { ...filters }
    }
    refetch({
      role,
      filter: formData,
      currentPage: 1,
      perPage,
    })
    setFilterQuery(formData)
    setResetVisible(true)
  }

  const handleSearchChange = (e: any) => {
    setSearchValue(e.currentTarget.value)
  }

  useEffect(() => {
    if (currentTab) {
      setSelectedItem({})
      setSelectAll({})
    }
  }, [currentTab])

  let field = ''

  switch (variables.role) {
    case 'MODERATOR':
      field = 'Moderator'
      break
    case 'STUDENT':
      field = 'Student'
      break
    case 'COACH':
      field = 'Coach'
      break
    case 'ADMIN':
      field = 'Admin'
      break
    case 'SUPER_ADMIN':
      field = 'super_admin'
      break
    default:
      break
  }

  const multiActions = () => {
    let actions: any = [
      {
        id: 0,
        color: 'secondary',
        tooltipText: t('actions.delete'),
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => handleDeleteAllClick(),
        component: <DeleteIcon fontSize="small" />,
      },
      {
        id: 1,
        color: 'secondary',
        tooltipText: t('actions.suspend'),
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => handleChangeStatusAllClick([], 'suspend'),
        component: <BackspaceRoundedIcon fontSize="small" />,
      },
      {
        id: 2,
        color: 'primary',
        tooltipText: t('actions.activate'),
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => handleChangeStatusAllClick([], 'activate'),
        component: <CheckCircleIcon fontSize="small" />,
      },
      {
        id: 10,
        color: 'primary',
        tooltipText: t('actions.export'),
        disabled: selectedItem[currentPage]
          ? selectedItem[currentPage].length < 1
          : true,
        onClick: () => handleExportData(),
        component: <ArchiveIcon fontSize="small" />,
      },
    ].filter((i: any) => !i.admin)

    return actions.filter((i: any) => !i.hide)
  }

  if (loading) return <div>Loading...</div>

  const onGroupClick = () => { }

  const openAddDrawer = () => {
    let drawer = ''
    let drawerTitle = ''
    switch (role) {
      case 'ADMIN':
        drawer = 'addAdmin'
        drawerTitle = 'Add Admin'
        break
      case 'SUPER_ADMIN':
        drawer = 'addAdmin'
        drawerTitle = `${t('actions.add')} ${t('general.super_admin')}`
        break
      case 'STUDENT':
        drawer = 'addStudent'
        drawerTitle = 'Add Student'
        break
      case 'COACH':
        drawer = 'addCoach'
        drawerTitle = 'Add Coach'
        break
      default:
        drawer = ''
    }
    DrawerEventEmitter.emit('openDrawer', drawer, true, {
      data: {
        title: drawerTitle,
        role,
        onClose: () => {
          DrawerEventEmitter.emit('openDrawer', drawer, false)
        },
        onSuccess: () => {
          refetch({
            role,
            filter: filterQuery,
            currentPage: 1,
            perPage,
          })
          setSelectedItem({})
          setSelectedItem({})
        },
      },
    })
  }
  const handleResetClick = () => {
    refetch({
      role,
      filter: defaultFilter,
      currentPage,
      perPage,
    })
    setSearchValue('')
    setFilterQuery(defaultFilter)
    setFilterOptions(null)
    setResetVisible(false)
  }

  const filterData = (filters: any) => {
    let formValue = {}
    if (searchValue !== '') {
      formValue = {
        search: {
          type: 'search',
          value: searchValue,
          fields: ['firstName', 'lastName', 'email', 'phone'],
        },
      }
    }
    refetch({
      role,
      filter: { ...filters, ...formValue, ...defaultFilter },
      currentPage: 1,
      perPage,
    })
    setResetVisible(true)

    if (users) {
      setFilterDrawerOpened(false)
      setFilterQuery({ ...filters, ...formValue, ...defaultFilter })
      setSelectedItem({})
    }
  }

  const renderFilterComponent = () => {
    if (role === 'STUDENT') {
      return (
        <FilterStudents
          role="STUDENT"
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filterData}
          filterLoading={loading}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          useCompanyId
        />
      )
    }

    if (role === 'COACH') {
      return (
        <FilterCoaches
          role="COACH"
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filterData}
          filterLoading={loading}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          useCompanyId
          forAssignCoaches={currentTab === 1 ? true : false}
        />
      )
    }

    if (role === 'SUPER_ADMIN') {
      return (
        <FilterModerators
          role={role}
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filterData}
          filterLoading={loading}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          hasSearchByCompany={false}
          useCompanyId
        />
      )
    }

    if (role === 'ADMIN') {
      return (
        <FilterAdmins
          role={role}
          closeDrawer={() => setFilterDrawerOpened(false)}
          filter={filterData}
          filterLoading={loading}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          useCompanyId
        />
      )
    }
  }

  const isSuperAdmin = state?.currentUser?.role === 'SUPER_ADMIN'
  const isAdmin = state?.currentUser?.role === 'ADMIN'

  const config = columnConfig(
    variables.role,
    selectItem,
    onGroupClick,
    t,
    assignCoaches,
    isSuperAdmin,
    isAdmin,
  )
  const actions = userActionConfig(
    handleEditClick,
    handleDeleteClick,
    handleResendPasswordClick,
    handleChangeStatusAllClick,
    handleChangeQualificationManyClick,
    state.userPermission,
    field,
    state.currentUser.role,
    t,
    setResetPasswordOpen,
    setUserId,
    state.currentUser.id,
  )

  const SuperAdmin = state?.currentUser?.role === 'SUPER_ADMIN'

  const Admin = state?.currentUser?.role === 'ADMIN'

  return (
    <div style={{ paddingTop: 20 }}>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={() => { }}
        totalWidth="600px"
      >
        {renderFilterComponent()}
      </Drawer>
      <div style={{ marginBottom: 20 }}>
        {/* <Wrapper justify="between" align="center">
          <Wrapper align="center">
            {(!SuperAdmin && !Admin && coaches) ||
              ((SuperAdmin || Admin) && super_admin && (
                <Button
                  text={`${t('actions.create')} ${t(`general.${field}`)}`}
                  color="secondary"
                  onClick={openAddDrawer}
                  icon={<AddCircleRoundedIcon />}
                  background={mainColor}
                />
              ))}

            {!assignCoaches && (
              <GridMultipleActions
                selectedItems={
                  selectedItem[currentPage]
                    ? selectedItem[currentPage].length
                    : 0
                }
                actions={multiActions()}
              />
            )}
          </Wrapper>
          <form onSubmit={handleSearchSubmit}>
            <TextInput
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              value={searchValue}
              onChange={handleSearchChange}
              disabled={loading}
              icon={
                <IconButton type="submit">
                  <SearchRoundedIcon />
                </IconButton>
              }
            />
          </form>
        </Wrapper> */}
        <GridToolbar>
          <GridLayoutActions>
            <div>
              {(SuperAdmin || Admin) && super_admin && (
                // state.userPermission.createSuperAdmin &&
                <AddButton onClick={openAddDrawer}>
                  <Icon filename={'add'} fill={'#ffffff'} />
                  {t('actions.create')} {t(`general.${field}`)}
                </AddButton>
              )}
            </div>
          </GridLayoutActions>
          <GridLayoutForm>
            {`${selectedItem?.[currentPage]?.length || 0} ${t(
              'general.items_selected',
            )}`}
            <ActionButton
              onClick={() => handleDeleteAllClick()}
              disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
              fill={'#CC3131'}
              disabledFill={'#080A0AD6'}
              theme={theme}
            >
              <Icon filename={'delete'} />
            </ActionButton>
            <Search
              inputProps={{
                value: searchValue,
                onChange: handleSearchChange,
              }}
              onClick={handleSearchSubmit}
            />
          </GridLayoutForm>
        </GridToolbar>
      </div>
      <Grid
        onFiltersClick={() => setFilterDrawerOpened(true)}
        resetVisible={resetVisible}
        resetFilters={handleResetClick}
        config={config}
        data={users?.data || []}
        actionConfig={assignCoaches ? null : actions}
        selected={_.size(selectedItem[currentPage])}
        selectAllItem={selectAllItem}
        selectedItems={selectedItem[currentPage] || []}
      />
      {users && (
        <TablePagination
          currentPage={users ? users.currentPage : 0}
          rowsPerPage={perPage}
          count={users ? users.totalCount : 0}
          handleChangePage={handlePaginationClick}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      {isResetPasswordOpen && (
        <ResetUsersPassword
          isModalOpen={isResetPasswordOpen}
          setModalOpen={setResetPasswordOpen}
          userId={userId}
        />
      )}
    </div>
  )
}

export default CompanyUsers
