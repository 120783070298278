import { useState } from 'react'
import editGroupService from 'services/group/editGroupService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useEditGroup = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)
  const { editGroupMutation, assignCoachMutation } = editGroupService()

  const editGroup = async (payload: any, id: any, refetch: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)
    const { errors, data } = await editGroupMutation({
      variables: { id, input: payload },
    })




    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
      setLoading(false)
    } else {
      message = `${t('general.group')} ${t('messages.edited')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    setLoading(false)
    refetch()
  }

  const assignCoach = async (
    groupId: string,
    coach: any,
    courseId: string,
    callBack?: any,
    t?: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }




    const { data, errors } = await assignCoachMutation({
      variables: {
        groupId,
        coach,
        courseId,
      },
    })




    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = t('messages.success')
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    loading,
    editGroup,
    assignCoach,
  }
}

export default useEditGroup
