import Icon from 'components/Icon'
import styled from 'styled-components'

export const DrawerContainer = styled.div``

export const DrawerHeaderSteps = styled.header`
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

export const DrawerHeader = styled.header`
  padding: 10px 30px;
  min-height: 166px;
  background: ${({ theme }) => theme.palette.background.default};
  border-bottom: 1px solid #ebebeb;
  position: sticky;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .close {
    position: absolute;
    top: 15px;

    right: 30px;
  }
`

export const DrawerTitle = styled.h1<{ marginLeft?: string }>`
  font-size: 20px;
  font-weight: 500;
  font-size: 32px;
  font-weight: 600;
  margin: auto;

  @media (max-width: 560px) {
    font-size: 20px;
    background: green;
    margin-left: ${props => props.marginLeft || 'auto'};
  }
  @media (max-width: 370px){
    font-size:18px;
  }
`

export const DrawerStepsTitle = styled.h1`
  font-size: 2em;
  font-weight: normal;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 10px;
`

export const DrawerContent = styled.div<{ headerHeight?: number, margin?: string, marginResponsive?: string, left?: string }>`
  position: relative;

  margin: 50px ${props => props.margin ? props.margin : '150px'};
  height: ${({ headerHeight }) =>
    headerHeight ? `calc(100% - ${headerHeight}px)` : `calc(100% - 68px)`};
    @media (max-width: 680px){
      margin: 50px 10%;
    }
  @media (max-width: 480px) {
    margin: 50px ${props => props.marginResponsive ? props.marginResponsive : '25px'};
    left: ${props => props.left || null};
  }
`

export const CloseIcon = styled(Icon)`
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  @media (max-width: 680px) {
    right: 2vw;
  }
  @media (max-width: 560px) {
    top: 40px;
  }

`

export const Info = styled.div`
  position: absolute;
  bottom: -15px;
  left: 75px;
  width: 35px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 100%;
`
