import React from 'react';
export const Sun = ({ color }) => {
  return (
    <svg id="Component_3_1" data-name="Component 3 – 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color}>
      <rect id="Rectangle_6" data-name="Rectangle 6" width="24" height="24" fill="none" />
      <g id="brightness_1_" data-name="brightness (1)" transform="translate(2 2)">
        <path id="Path_1842" data-name="Path 1842" d="M241.586,3.055a.586.586,0,0,0,.586-.586V.586a.586.586,0,0,0-1.172,0V2.469A.586.586,0,0,0,241.586,3.055Z" transform="translate(-231.586)" />
        <path id="Path_1843" data-name="Path 1843" d="M72.092,72.92a.586.586,0,0,0,.829-.829L71.59,70.76a.586.586,0,0,0-.829.829Z" transform="translate(-67.832 -67.831)" />
        <path id="Path_1844" data-name="Path 1844" d="M72.086,377.506l-1.331,1.331a.586.586,0,0,0,.829.829l1.331-1.331a.586.586,0,1,0-.829-.829Z" transform="translate(-67.826 -362.594)" />
        <path id="Path_1845" data-name="Path 1845" d="M241.586,433.8a.586.586,0,0,0-.586.586v1.883a.586.586,0,0,0,1.172,0v-1.883A.586.586,0,0,0,241.586,433.8Z" transform="translate(-231.586 -416.855)" />
        <path id="Path_1846" data-name="Path 1846" d="M3.055,241.586A.586.586,0,0,0,2.469,241H.586a.586.586,0,0,0,0,1.172H2.469A.586.586,0,0,0,3.055,241.586Z" transform="translate(0 -231.586)" />
        <path id="Path_1847" data-name="Path 1847" d="M133.512,128.533a4.979,4.979,0,1,0,4.979,4.979A4.985,4.985,0,0,0,133.512,128.533Z" transform="translate(-123.512 -123.512)" />
        <path id="Path_1848" data-name="Path 1848" d="M378.335,72.914l1.331-1.331a.586.586,0,0,0-.829-.829l-1.331,1.331a.586.586,0,0,0,.829.829Z" transform="translate(-362.595 -67.825)" />
        <path id="Path_1849" data-name="Path 1849" d="M378.329,377.5a.586.586,0,0,0-.829.829l1.331,1.331a.586.586,0,0,0,.829-.829Z" transform="translate(-362.589 -362.589)" />
        <path id="Path_1850" data-name="Path 1850" d="M436.269,241h-1.883a.586.586,0,1,0,0,1.172h1.883a.586.586,0,0,0,0-1.172Z" transform="translate(-416.855 -231.586)" />
      </g>
    </svg>

  )
}