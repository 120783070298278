import React, { useCallback, useEffect, useRef, useState } from 'react'
import Cropper from 'react-cropper'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import 'cropperjs/dist/cropper.css'
import { useTheme } from '@material-ui/core'

import { createStyles, makeStyles } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Cancel'

import { Button } from 'components/common/Button'
import { useUserValue } from 'context/UserContext'
import useOutsideClick from 'helpers/useOutsideClick'
import { CompanyImage } from 'pages/companies/CompanyDetails/styled-components'
import ProfileIcon from '../../../pages/profile/AdminProfile/icons/profile-picture.svg'
import DomainIcon from '../../../pages/companies/CompanyDetails/icons/domain.svg'
import { mainColor, validationRed, red } from '../Colors/CommonColors'
import Loader from '../Loader'
import { useSwal } from 'hooks/useSwal'
import Icon from 'components/Icon'
import { FakeUserImage } from '../DetailsLayout/styled-components'

const useStyles = makeStyles(theme =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    label: {
      color: theme.palette.text.primary
    }
  }),
)

const cropper: any = React.createRef() as any

export const EditableAvatar: React.FC<{
  finishCrop: (
    action: any,
    crudFunction: (isFinished: boolean) => void,
    cropper: any,
  ) => void
  img?: string
  isRectangular?: boolean
  hasResponseReceived?: boolean
}> = ({ finishCrop, img, isRectangular, hasResponseReceived }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [avatarMenu, setAvatarMenu] = useState(false)
  const [cropperOpen, setCropperOpen] = useState(false)
  const [file, setFile] = useState<any>(null)
  const ref: React.RefObject<HTMLDivElement> = useRef() as React.RefObject<
    HTMLDivElement
  >
  const { fireSwal } = useSwal()
  const [loading, setLoading] = useState(false)
  const [loadingForDelete, setLoadingForDelete] = useState(false)
  const [canEditProfileAvatar, setCanEditProfileAvatar] = useState<boolean>(
    false,
  )
  const [state] = useUserValue()
  const { id: userId } = useParams<{ id: string }>()

  useEffect(() => {
    loadingForDelete && setLoadingForDelete(!hasResponseReceived)
  }, [hasResponseReceived, loading, loadingForDelete])

  useOutsideClick(ref, () => {
    if (avatarMenu) setAvatarMenu(false)
  })

  const handleAvatarChange = (e: any) => {
    const { files } = e.target
    const reader = new FileReader()
    setLoading(false)

    reader.onload = () => {
      const img: HTMLImageElement = new Image() as HTMLImageElement
      if (typeof reader.result === 'string') {
        img.src = reader.result
        setFile(reader.result)
      }
      setCropperOpen(true)
      setAvatarMenu(false)
    }
    reader.readAsDataURL(files[0])
  }

  const cropperModalToggle = () => {
    setCropperOpen(!cropperOpen)
  }

  const crudFunction = (isFinished: boolean) => {
    setCropperOpen(false)
    setAvatarMenu(false)
  }

  const onSave = useCallback(() => {
    setLoading(true)
    finishCrop('update', crudFunction, cropper)
    setFile(null)
  }, [])

  const currentUserId = state?.currentUser?.id
  const currentUserEditPerrmision = state?.userPermission?.editStudent

  useEffect(() => {
    if (currentUserId === userId || currentUserEditPerrmision) {
      setCanEditProfileAvatar(true)
    }
  }, [currentUserId, userId, currentUserEditPerrmision])

  const handleDeleteClick = () => {
    const params = {
      title: t('popups.remove_image'),
      onConfirm: () => {
        setLoadingForDelete(true)
        finishCrop('delete', crudFunction, cropper)
        setFile(null)
      },
      confirmText: t('popups.confirm_delete'),
    }
    fireSwal(params)
  }
  const theme = useTheme();

  return (
    <>
      <AvatarSettingContainer ref={ref}>
        {isRectangular ? (
          <CompanyImage>
            <img src={img || '/svg/companyAvatar.svg'} alt="" />

            {canEditProfileAvatar && (
              <EditIconContainer
                isRectangular={isRectangular}
                onClick={() => setAvatarMenu(!avatarMenu)}
              >
                <Icon
                  filename={'edit'}
                  onClick={() => setAvatarMenu(!avatarMenu)}
                  width={17}
                  height={16}
                />
              </EditIconContainer>
            )}
          </CompanyImage>
        ) : (
          <StyledAvatarWrapper>
            <img src={img || '/svg/profileAvatar.png'} alt="" />
            {canEditProfileAvatar && (
              <EditIconContainer
                isRectangular={isRectangular}
                onClick={() => setAvatarMenu(!avatarMenu)}
              >
                <Icon
                  filename={'edit'}
                  onClick={() => setAvatarMenu(!avatarMenu)}
                  width={17}
                  height={16}
                />
              </EditIconContainer>
            )}
          </StyledAvatarWrapper>
        )}

        {avatarMenu && (
          <AvatarMenu >
            <AvatarMenuItem onClick={() => setAvatarMenu(true)}>
              <Icon
                style={{ fontSize: 18, color: '#989898', marginRight: '8px' }}
                filename={'uploadAvatar'}
              />
              <input
                type="file"
                name="file"
                id="file"
                onChange={handleAvatarChange}
                accept="image/*"
                multiple
              />
              <label style={{ color: "black" }} htmlFor="file">{t('actions.upload_avatar')}</label>

            </AvatarMenuItem>

            {(!!file || (!file && !!img)) && (
              <AvatarMenuItem onClick={handleDeleteClick}>
                <Icon
                  style={{ fontSize: 18, color: '#989898', marginRight: '8px' }}
                  filename={'delete'}
                />
                {t('actions.delete_avatar')}
              </AvatarMenuItem>
            )}
          </AvatarMenu>
        )}
      </AvatarSettingContainer>

      {/* {loadingForDelete && <Loader />} TODO: this is not working properly because cant update response */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={cropperOpen}
        onClose={cropperModalToggle}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={cropperOpen}>
          <ImageCropperContainer>
            {loading && <Loader />}
            <Cropper
              ref={cropper}
              src={file}
              style={{
                height: 320,
                width: 320,
                borderRadius: '16px',
                overflow: 'hidden',
              }}
              aspectRatio={16 / 16}
              guides={false}
            />
            <div className="actions">
              <Button
                onClick={cropperModalToggle}
                text={t('actions.cancel')}
                type="medium"
                background="#F5F5F5"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                //   variant="contained"
                color="secondary"
                onClick={onSave}
                background={'#317BF4'}
                text={t('actions.save')}
                type="medium"
              />
            </div>
          </ImageCropperContainer>
        </Fade>
      </Modal>
    </>
  )
}

const AvatarMenu = styled.div`
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 200px;
  box-sizing: border-box;
  padding: 5px 7px;
  background: #fff;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.22);
  border-radius: 3px;
  z-index: 1;
`

const AvatarSettingContainer = styled.div`
  position: relative;
`

const StyledAvatarWrapper = styled.div`
  overflow: hidden;
  width: 170px;
  height: 170px;
  border-radius: 16px;
`

const AvatarMenuItem = styled.div`
  padding: 5px 3px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  label {
    cursor: pointer;
  }
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    overflow: hidden;
  }
`

const ImageCropperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  padding: 90px 125px;
  border-radius: 16px;

  .actions {
    margin-top: 35px;
    display: flex;
    gap: 30px;
  }
`

const EditIconContainer = styled.div<{ isRectangular?: boolean }>`
  width: 34px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #ebebeb;

  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  position: absolute;
  bottom: ${({ isRectangular }) => (isRectangular ? '-5px' : '-12px')};
  right: ${({ isRectangular }) => (isRectangular ? '-7px' : '-12px')};
  cursor: pointer;
`
