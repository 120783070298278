import { useLazyQuery } from '@apollo/react-hooks'
import { FormControlLabel, useTheme } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DeleteIcon from '@material-ui/icons/Delete'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import SyncIcon from '@material-ui/icons/Sync'
import ViewListIcon from '@material-ui/icons/ViewList'
import { Button } from 'components/common/Button'
import { GridSwitchButtons } from 'components/common/Cards/styled-components'
import Checkbox from 'components/common/Checkbox'
import { mainColor } from 'components/common/Colors/CommonColors'
import Drawer from 'components/common/Drawer'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import {
  ActionButton,
  GridLayoutActions,
  GridLayoutForm,
  GridToolbar,
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import Pagination from 'components/common/Pagination'
import Search from 'components/common/Search'
import { Input } from 'components/common/TextInput'
import Icon from 'components/Icon'
import { useUserValue } from 'context/UserContext'
import { GET_QUIZ_BY_TYPE } from 'gql/quiz/quiz.query'
import { DrawerEventEmitter } from 'helpers/drawer'
import useDeleteQuizService from 'hooks/quizzes/useDeleteQuizService'
import { useSwal } from 'hooks/useSwal'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import FilterDrawer from './FilterDrawer/FilterDrawer'
import { actionConfig } from './gridConfig'
import QuizList from './QuizList/QuizList'

const QuizLayout = (props: any) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const history = useHistory()
  const { fireSwal } = useSwal()
  const [gridStyle, setGridStyle] = useState('card')
  const [searchText, setSearchText] = useState('')
  const [filterData, setFilterData] = useState<any>({
    filterOptions: null,
    values: null,
  })
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})
  const [perPage] = useState<number>(12)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const [fetchQuizByType, { data, refetch: refetchQuizByType }] = useLazyQuery(
    GET_QUIZ_BY_TYPE,
  )
  const { deleteQuiz } = useDeleteQuizService('s')

  const fromList = !!(props.type && props.id)

  const runQuery = () => {

    if (fromList) {
      fetchQuizByType({
        variables: {
          type: props.type.toLowerCase(),
          actionId: props.id,
          currentPage,
          perPage,
          filter: {
            ...filterData.filterOptions,
            IQTestType: {
              type: 'EXACT',
              value: props.IQTest ? 'initial' : null,
            },
          },
        },
      })
    } else {
      fetchQuizByType({
        variables: {
          type: '',
          actionId: '',
          currentPage,
          perPage,
          filter: { ...filterData.filterOptions },
        },
      })
      // filterData.query.name = { $regex: searchText, $options: 'i' }

      // fetchAllQuizzes({
      //   variables: {
      //     query: JSON.stringify(filterData.query),
      //     filter: {
      //       name: {
      //         type: 'search',
      //         value: searchText,
      //       },
      //     },
      //   },
      // })
    }
  }

  useEffect(() => {
    runQuery()
  }, [props.type, props.id, filterData, currentPage])

  const selectItem = (id: never, event: boolean) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const openAddQuizDrawer = () => {
    DrawerEventEmitter.emit('openDrawer', 'addQuiz', true, {
      type: props.type,
      id: props.id,
      data: {
        name: props.name,
        quizzes: quizList,
        companyId: props.companyId,
        refetch: () => (!!refetchQuizByType ? refetchQuizByType({}) : {}),
        IQTest: props.IQTest,
      },
    })
  }

  let quizList = data?.getQuizzesByType || []

  const deleteQuizzes = (ids: string[]) => {
    const title: string = props.IQTest
      ? ids.length > 1
        ? t('popups.delete_iq_test_many')
        : t('popups.delete_iq_test_single')
      : ids.length > 1
        ? t('popups.delete_quiz_many')
        : t('popups.delete_quiz_single')

    fireSwal({
      title,
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => deleteQuiz(ids, runQuery, props.type),
    })
  }

  const handlePaginationClick = (event: any, value: number) => {
    if (value !== currentPage) {
      refetchQuizByType({
        currentPage: value,
        perPage,
      })
      setCurrentPage(value)
    }
  }

  const onSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const searchValue = { type: 'MATCH', value: searchText }
    setCurrentPage(1)
    refetchQuizByType({
      filter: {
        name: searchValue,
      },
      currentPage: 1,
      perPage,
    })
  }

  const handleEditDrawer = (val: any) => {
    DrawerEventEmitter.emit('openDrawer', 'editQuiz', true, {
      type: props.type || '',
      id: props.id || '',
      data: { quizId: val.id, quizzes: quizList, IQTest: props.IQTest },
    })
  }

  const actionHandlers = (action: string, value: any) => {
    switch (action) {
      case 'view':
        return history.push(`/quizzes/${value.id}`)
      case 'edit':
        return handleEditDrawer(value)
      case 'delete':
        return deleteQuizzes([value.id])
      default:
        return () => console.log('1')
    }
  }

  useEffect(() => {
    if (props.fromQuestions) {
      openAddQuizDrawer()
    }

    return () => {
      props.setOpenCreateQuizDrawer && props.setOpenCreateQuizDrawer(false)
    }
  }, [props.fromQuestions])

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const questionList = quizList.length > 0 ? quizList : quizList.data
      const newArr: string[] = questionList.map((n: any) => n.id)
      setSelectedItem({ ...selectedItem, [currentPage]: newArr })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({ ...selectedItem, [currentPage]: [] })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const currentUser = state.currentUser
  const isStudent = currentUser.role === 'STUDENT'
  const isCompanySuperAdmin =
    currentUser?.role === 'SUPER_ADMIN' && !!currentUser?.companyId?.id

  const isCompanyAdmin =
    currentUser?.role === 'ADMIN' && !!currentUser?.companyId?.id

  const actions = actionConfig(
    actionHandlers,
    false,
    !(props.canModify && currentUser.companyId) && !isStudent,
    t,
    isCompanySuperAdmin,
    isCompanyAdmin,
  )
  const createIqTestBtnDisabled =
    props.IQTest && quizList.data && quizList.data.length > 0 ? true : false

  const theme = useTheme()

  return (
    <Container theme={theme} noHeader={props?.header === 'noHeader'}>
      {/* <Header className={'withBorder'}>
        <Wrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {props.canModify ? (
              <div style={{ display: 'flex' }}>
                {props.canModify && (
                  <Button
                    text={
                      props.IQTest
                        ? t('quizzes_layout.create_iq_test')
                        : t('quizzes_layout.create_quiz')
                    }
                    color="secondary"
                    onClick={openAddQuizDrawer}
                    isDisabled={
                      !props.canCreate ||
                      props.canCreate <= 0 ||
                      createIqTestBtnDisabled
                    }
                    icon={<AddCircleRoundedIcon />}
                    background={mainColor}
                  />
                )}
              </div>
            ) : (
              <Title>{t('quizzes_layout.title')}</Title>
            )}

            {!props.IQTest && (
              <GridMultipleActions
                selectedItems={
                  selectedItem[currentPage]
                    ? selectedItem[currentPage].length
                    : 0
                }
                actions={
                  (props.companyCourse && currentUser.companyId) || isStudent
                    ? []
                    : [
                        {
                          id: 0,
                          color: 'secondary',
                          tooltipText: `${t('actions.delete')}`,
                          disabled:
                            !selectedItem[currentPage] ||
                            selectedItem[currentPage].length < 1,
                          onClick: () => {
                            deleteQuizzes([...selectedItem[currentPage]])
                          },
                          component: <DeleteIcon fontSize="small" />,
                        },
                      ]
                }
                isNumberOfSelectedItemsHidden={!state.userPermission.editQuiz}
              />
            )}
          </div>

          <div style={{ display: 'flex', alignContent: 'center' }}>
            {!fromList && (
              <>
                <IconButton
                  disabled={!filterData.filterOptions && !searchText}
                  onClick={() => {
                    setFilterData({
                      filterOptions: null,
                      values: null,
                    })
                    setSearchText('')
                  }}
                >
                  <SyncIcon fontSize="small" />
                </IconButton>

                <IconButton onClick={() => setFilterDrawer(true)}>
                  <FilterListIcon
                    fontSize="default"
                    color="primary"
                    style={{ color: mainColor }}
                  />
                </IconButton>
              </>
            )}

            {!fromList && (
              <div style={{ marginRight: '20px' }}>
                <form onSubmit={onSearchSubmit}>
                  <Input
                    label={t('general.search_placeholder')}
                    type="text"
                    size="small"
                    value={searchText}
                    onChange={(e: any) => setSearchText(e.target.value)}
                    icon={
                      <IconButton onClick={onSearchSubmit}>
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                  />
                </form>
              </div>
            )}

            <GridSwitchButtons>
              <button
                className={`${gridStyle === 'card' ? 'active' : ''}`}
                onClick={() => setGridStyle('card')}
              >
                <DashboardIcon />
              </button>
              <button
                className={`${gridStyle === 'list' ? 'active' : ''}`}
                onClick={() => setGridStyle('list')}
              >
                <ViewListIcon />
              </button>
            </GridSwitchButtons>
          </div>
        </Wrapper>
      </Header> */}

      {/* {fromList && !isStudent && !isCompanySuperAdmin && !isCompanyAdmin && (
        <Text style={{ width: '100%', textAlign: 'right' }}>
          {props.IQTest
            ? t('quizzes_layout.iq_test_rule')
            : t('quizzes_layout.quiz_recommendation')}
        </Text>
      )} */}

      {props?.header !== 'noHeader' && (
        <GridToolbar
          style={{ borderBottom: '1px solid #EBEBEB', marginBottom: '40px' }}
          theme={theme}
        >
          <GridLayoutActions>
            <div>
              {props.canModify && (
                <AddButton onClick={openAddQuizDrawer}>
                  <Icon filename={'add'} fill={'#ffffff'} />
                  {t('quizzes_layout.create_quiz')}
                </AddButton>
              )}
            </div>
            {/* {!isCompanySuperAdmin && !isCompanyAdmin && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={(selectedItem?.[currentPage]?.length || 0) > 0}
                  onChange={selectAllItem}
                />
              }
              label={t('general.select_all')}
            />
          )} */}
          </GridLayoutActions>
          <GridLayoutForm>
            <ActionButton
              disabled={!filterData.filterOptions && !searchText}
              onClick={() => {
                setFilterData({
                  filterOptions: null,
                  values: null,
                })
                setSearchText('')
              }}
              theme={theme}
            >
              <SyncIcon fontSize="small" />
            </ActionButton>
            <MultipleAction theme={theme}>
              <ActionButton theme={theme} onClick={() => setFilterDrawer(true)}>
                <Icon filename={'filter'} fill={'#080A0AD6'} />
              </ActionButton>
              <MultipleActionTooltip>
                {t('actions.filters')}
              </MultipleActionTooltip>
            </MultipleAction>
            <Search
              inputProps={{
                value: searchText,
                onChange: e => setSearchText(e.target.value),
              }}
              onClick={onSearchSubmit}
            />
          </GridLayoutForm>
        </GridToolbar>
      )}
      <QuizListContainer>
        <div style={{ padding: '0px 10px' }}>
          <QuizHeader theme={theme}>
            <div className="title">{t('quizzes_layout.title').toUpperCase()}</div>
            <GridLayoutForm>
              {`${selectedItem?.[currentPage]?.length || 0} ${t(
                'general.items_selected',
              )}`}
              {currentUser?.role === 'SUPER_ADMIN' && (
                <>
                  <ActionButton
                    onClick={() =>
                      deleteQuizzes([...selectedItem[currentPage]])
                    }
                    disabled={(selectedItem?.[currentPage]?.length || 0) === 0}
                    fill={'#CC3131'}
                    disabledFill={'#080A0AD6'}
                    theme={theme}
                  >
                    <Icon filename={'delete'} />
                  </ActionButton>
                  <ActionButton theme={theme} fill={'#269F5A'} disabledFill={'#080A0AD6'}>
                    <Icon filename={'download'} />
                  </ActionButton>{' '}
                </>
              )}
            </GridLayoutForm>
          </QuizHeader>
          <QuizList
            IQTest={props.IQTest}
            data={quizList.data || []}
            actions={actions}
            gridStyle={gridStyle}
            selectedItems={selectedItem[currentPage] || []}
            selectItem={selectItem}
            isDraggable={props.isDraggable ? true : false}
            type={props.type}
            t={t}
            companyCourse={props.companyCourse}
          />
          {quizList && quizList.totalPages > 1 && (
            <div
              style={{
                marginTop: 20,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Pagination
                currentPage={quizList ? quizList.currentPage : 1}
                totalPages={quizList ? quizList.totalPages : 0}
                handleChange={handlePaginationClick}
              />
            </div>
          )}
        </div>
      </QuizListContainer>

      <Drawer opened={filterDrawer} toggleDrawer={() => { }} totalWidth="970px">
        <FilterDrawer
          onClose={() => setFilterDrawer(false)}
          setFilterData={setFilterData}
          filterData={filterData}
          setCurrentPage={setCurrentPage}
          t={t}
        />
      </Drawer>
    </Container>
  )
}

export default QuizLayout

const Container = styled.div<{ noHeader?: boolean }>`
  overflow: hidden;
  background:  ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  height: 100%;
  ${({ noHeader }) =>
    !noHeader &&
    css`
      border: 1px solid  ${({ theme }) => theme.palette.background.default};
    `}
`

const QuizListContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
`

const QuizHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 600;
    font-size: 24px;
  }
`

const Header = styled.header`
  margin-bottom: 20px;

  &.withBorder {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
  }

  button.makeStyles-button-247 {
    padding: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
`
const Text = styled.div`
  width: 100%;
  text-align: right;
  font-size: 14px;
  color: #ff3a52;
`

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #317bf4;
  padding: 18px 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 30px;

  &:hover {
    transition: 0.3s;
    background: #3165f4;
  }
`
