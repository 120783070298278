import styled from 'styled-components'
import Icon from 'components/Icon'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 370px;
  height: 72px;
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: 0px 0px 30px #0000001a;
  border-radius: 16px;
  padding: 8px 8px 8px 30px;
`

export const SInput = styled.input`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  border: none;
  background-color: transparent;
  outline: none;
`

export const SButton = styled.button`
  min-width: 111px;
  height: 56px;
  background: #317bf4;
  border-radius: 16px;
  opacity: 1;
  color: #ffffff;
  font-size: 14px;
  border: none;
  cursor: pointer;
  &:hover {
    transition: 0.3s;
    background: #317bf4;
  }
`

export const SIcon = styled(Icon)`
  min-width: 20px;
  min-height: 20px;
  margin-right: 8px;
`
