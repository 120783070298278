import { IconButton } from '@material-ui/core'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

`

export const Section = styled.section`
  display: flex;
  background: blue;
  flex-direction: column;
  gap: 20px;
  background: transparent;
  border: 1px solid #080a0a0a;
  border-radius: 16px;
  padding: 36px;
  margin-bottom: 20px;

  & > div:last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }

  .title {
    color: #080a0a;
    font-weight: 600;
    font-size: 16px;
  }
  @media (max-width: 640px){
    padding: 36px 10px;
  }
`

export const SectionItem = styled.div`
  display: flex;
  background: transparent;
  border-bottom: 1px solid #080a0a0a;
  padding: 20px 0;
  @media (max-width: 1280px){
    flex-wrap: wrap;
  }
`

export const SectionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border: 1px solid #080a0a12;
  border-radius: 100%;
  cursor: pointer;
`

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    align-items: center;
    gap: 14px;
    height: 28px;

  }
`

export const SectionAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  margin-left: auto;
`

export const SIconButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const ActionShowButton = styled.button`
  color: #080a0a;
  font-size: 13px;
  padding: 16px 24px;
  background: transparent;
  border-radius: 16px;
  cursor: pointer;
  font-weight: 600;
  border: none;
  outline: none;




`
export const HowerItem = styled.p`

`
export const StyledReactTooltip = styled<any>(ReactTooltip)`
border: 1px solid #EBEBEB !important;
&::after {
  display: none !important;
  /* border-right: 1px solid black !important; */
  /* border-left: 1px solid black !important; */
  /* border-top: 1px solid red !important; */
  /* border-bottom: 1px solid black !important; */
  /* transform: rotate(45deg); */
  /* position: absolute; */
  /* z-index: 111; */

}

`

export const Mandatory = styled.div`
  padding: 2px 8px;
  border: 2px solid #CC3131;
  color: #CC3131;
  font-size: 13px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
