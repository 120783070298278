import gql from 'graphql-tag'

export const GET_REVIEWS = gql`
  query($ratedId: String!) {
    getReviews(ratedId: $ratedId) {
      totalReviews
      courseName
      averageRating
      isRatedByMe
      canReviewCoach
      count
      data {
        id
        ratedId
        rate
        onModel
        description
        notesCount
        author {
          userId
          firstName
          lastName
          avatar
          role
          postsCount
          companyId
        }
      }
    }
}`


export const UPDATE_ASK_REVIEW = gql`
  mutation($courseId: String){
  askReview(askReview:false, courseId:$courseId)
}
`
