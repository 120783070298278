import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  CloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useUserValue } from 'context/UserContext'
import useEditGroup from 'hooks/group/useEditGroup'
import Drawer from 'components/common/Drawer'
import { AddAdmin } from 'pages/admins'
import { generateFullName } from 'utils/utils'
import { GET_GROUP_BY_ID } from 'gql/group/group.query'
import Stepper from 'components/common/Stepper'

import Step1 from './step1'
import Step2 from './Step2'
import styled, { css } from 'styled-components'
import { useTheme } from '@material-ui/core'

const initialData: any = {
  name: '',
  location: '',
  note: '',
  admin: null,
}

const EditGroupDrawer = (props: any) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [drawer, setDrawer] = useState({
    addAdmin: false,
    args: {},
  })
  const [step, setStep] = useState<number>(0)

  const { editGroup, loading } = useEditGroup()
  const { data, error, refetch } = useQuery(GET_GROUP_BY_ID, {
    variables: {
      id: props.id,
    },
  })

  const groupData = (data && data.getGroupById) || null

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialData,
    validationSchema: Yup.object({
      name: Yup.string().required('Name is Required'),
      admin: Yup.string()
        .required('Admin is Required')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      const data = { ...values }

      if (data.admin) {
        data.admin = data.admin.value
      }

      editGroup(data, groupData.id, () => {
        handleChangeStep(1)
        // props.refetch()
        // props.closeDrawer()
      })
    },
  })

  useEffect(() => {
    if (data && data.getGroupById) {


      setValues({
        name: data.getGroupById.name,
        location: data.getGroupById.location,
        note: data.getGroupById.note,
        admin: {
          label: generateFullName(data.getGroupById.admin),
          value: data?.getGroupById?.admin?.id,
        },
      })
    }
  }, [data])

  const handleChangeStep = (activeStep: number) => {
    setStep(activeStep)
  }

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            errors={errors}
            touched={touched}
            t={t}
            values={values}
            handleChange={handleChange}
            closeDrawer={props.closeDrawer}
            setFieldValue={setFieldValue}
            handleChangeStep={handleChangeStep}
            handleSubmit={handleSubmit}
            manualCompanyId={props.drawerData && props.drawerData.companyId}
          />
        )
      case 1:
        return (
          <Step2
            groupData={groupData || {}}
            companyId={props?.drawerData?.companyId || state.selectedCompanyId}
            handleChangeStep={handleChangeStep}
            t={t}
            closeDrawer={props.closeDrawer}
          />
        )
      default:
        return 'Error'
    }
  }

  const theme = useTheme();

  return (
    <>
      <div>
        <DrawerHeader theme={theme}>
          <DrawerTitle theme={theme}>{t('groups_layout.edit_group')}</DrawerTitle>
          <CloseIcon
            onClick={props.closeDrawer}
            filename={'close'}
            fill={'#707070'}
            stroke={'transparent'}
            width={36}
            height={36}
            cursor={'pointer'}
          />
          <Info />
        </DrawerHeader>

        <StepperContainer>
          <Stepper
            active={step}
            steps={[t('general.details'), t('general.students')]}
            setStep={setStep}
            ColorlibConnector={() => <></>}
            StepIconComponent={(props: any) => {
              const icons: { [index: string]: React.ReactElement } = {
                1: <>1</>,
                2: <>2</>,
              }

              return (
                <SWizardStepper active={props.active as boolean}>
                  {icons[String(props.icon)]}
                </SWizardStepper>
              )
            }}
          />
        </StepperContainer>
      </div>

      <DrawerContent
        style={{
          margin: '100px 20px',
        }}
        headerHeight={176}
      >
        {getStepContent(step)}
      </DrawerContent>

      <Drawer
        opened={drawer.addAdmin}
        toggleDrawer={() => setDrawer({ addAdmin: false, args: {} })}
        totalWidth="940px"
      >
        <AddAdmin
          title={t('admin_drawer_layout.create_admin')}
          role="ADMIN"
          drawerName="addAdmin"
          drawerData={drawer.args}
          onClose={() => setDrawer({ addAdmin: false, args: {} })}
        />
      </Drawer>
    </>
  )
}

export default EditGroupDrawer

const StepperContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiPaper-root {
    background: transparent !important;
  }
`
const SWizardStepper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 42px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #080a0a14;
  box-shadow: none;
  color: #707070;
  font-size: 20px;
  transition: 0.3s;
  border-radius: 100%;

  ${({ active }) =>
    active &&
    css`
      width: 54px;
      height: 54px;
      background: #317bf4;
      color: #ffffff;
      font-size: 28px;
      &::after {
        content: '';
        position: absolute;
        border: 1px solid #707070;
        border-collapse: separate;
        border-radius: 100%;
        width: 60px;
        height: 60px;
      }
    `}
`
