import { useQuery } from '@apollo/react-hooks'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Tooltip from '@material-ui/core/Tooltip'
import HelpIcon from '@material-ui/icons/Help'
import { mainColor } from 'components/common/Colors/CommonColors'
import { FormGroupItem } from 'components/common/Form'
import { Input } from 'components/common/TextInput'
import Icon from 'components/Icon'
import gql from 'graphql-tag'
import { IQTestTypes } from 'pages/quizzes/staticData'
import React, { useEffect } from 'react'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import styled from 'styled-components'

export const CREATE_QUIZ = gql`
  query {
    getAllTags {
      id
      label
    }
  }
`

const Step1 = (props: any) => {
  const { data } = useQuery(CREATE_QUIZ)

  const tagList =
    (data &&
      data.getAllTags.map((i: any) => ({ label: i.label, value: i.id }))) ||
    []

  let IQTestTypeValue = props.values.position

  if (props.addedIQTestType) {
    IQTestTypeValue =
      props.addedIQTestType === 'initial' ? IQTestTypes[1] : IQTestTypes[0]
  }

  useEffect(() => {
    if (props.addedIQTestType) {
      props.setFieldValue('IQTestType', IQTestTypeValue)
    }
  }, [props.addedIQTestType])

  const { t } = props

  return (
    <>
      <FormGroupItem>
        <Input
          error={!!props.errors.name}
          errorMessage={props.errors.name ? props.errors.name : ''}
          touched={props.touched.name}
          label={`${t('general.name')}*`}
          name="name"
          size="medium"
          type="text"
          fullWidth
          value={props.values.name}
          onChange={props.handleChange}
        />
      </FormGroupItem>

      {!props.IQTest && props.type === 'course' && (
        <FormGroupItem>
          <Label
            className={`${props.touched.IQTestType && props.errors.IQTestType ? 'error' : ''
              }`}
          >
            {t('quiz_details.quiz_type')}*
          </Label>
          <ReactSelect
            name="position"
            onChange={(e: any) => props.setFieldValue('position', e)}
            options={IQTestTypes}
            value={IQTestTypeValue}
            isDisabled={props.addedIQTestType ? true : false}
            className={
              props.touched.position && props.errors.position ? 'error' : ''
            }
          />
          <ErrorMessage>
            {props.touched.position &&
              props.errors.position &&
              props.errors.position}
          </ErrorMessage>
        </FormGroupItem>
      )}

      <FormGroupItem>
        <CreatableSelect
          label={`${t('form_fields.select')} ${t('general.group')}`}
          name="group"
          isMulti
          onChange={(e: any) => props.setFieldValue('tags', e)}
          options={tagList}
          value={props.values.tags}
          placeholder={t('general.tags')}
          classNamePrefix={'creatableselect'}
        />
      </FormGroupItem>

      <FormGroupItem>
        <Input
          error={!!props.errors.description}
          errorMessage={
            props.errors.description ? props.errors.description : ''
          }
          touched={props.touched.description}
          label={
            props.type === 'course'
              ? t('quiz_details.iq_test_overview')
              : t('quiz_details.quiz_overview')
          }
          name="description"
          size="medium"
          type="text"
          fullWidth
          multiline
          rows="4"
          value={props.values.description}
          onChange={props.handleChange}
        />
      </FormGroupItem>

      <FormGroupItem>
        <Input
          error={!!props.errors.time}
          errorMessage={props.errors.time ? props.errors.time : ''}
          touched={props.touched.time}
          label={t('quiz_details.time_in_minutes')}
          name="time"
          size="medium"
          type="number"
          fullWidth
          value={props.values.time}
          onChange={props.handleChange}
        />
      </FormGroupItem>

      <FormGroupItem>
        <RadioGroup
          name="timeType"
          value={props.values.timeType}
          onChange={props.handleChange}
        >
          <StyledFromGroupContainer>
            <StyledFormGroupWrapper className={'radiogroup_title'}>
              {t('quiz_details.time_type')}
              {props.values.time && '*'}
            </StyledFormGroupWrapper>

            <StyledFormGroupWrapper>
              <ItemWrapper>
                <FormControlLabel
                  value="estimated"
                  control={<Radio color="secondary" />}
                  label={t('general.estimated')}
                  disabled={!props.values.time}
                />
              </ItemWrapper>

              <ItemWrapper>
                <FormControlLabel
                  value="limited"
                  control={<Radio color="secondary" />}
                  label={t('general.limited')}
                  disabled={!props.values.time}
                />
              </ItemWrapper>

              <HelpButtonContainer>
                <Tooltip
                  title={
                    props.values.timeType &&
                    (props.values.timeType === 'estimated' ? (
                      <span style={{ fontSize: '14px' }}>
                        {t('general.estimated_helper')}
                      </span>
                    ) : (
                      <span style={{ fontSize: '14px' }}>
                        {t('general.limited_helper')}
                      </span>
                    ))
                  }
                  arrow
                >
                  <Icon filename={'info'}></Icon>
                </Tooltip>
              </HelpButtonContainer>
            </StyledFormGroupWrapper>
            {props.touched.timeType && props.errors.timeType && (
              <ErrorMessage>{props.errors.timeType}</ErrorMessage>
            )}
          </StyledFromGroupContainer>
        </RadioGroup>
      </FormGroupItem>

      <FormGroupItem>
        <RadioGroup
          name="quizParticipationType"
          value={props.values.quizParticipationType}
          onChange={props.handleChange}
        >
          <StyledFromGroupContainer>
            <StyledFormGroupWrapper className={'radiogroup_title'}>
              {props.type === 'course'
                ? `${t('general.iq_test')} ${t(
                  'quiz_details.participation',
                )} ${t('general.type')}*`
                : `${t('general.quiz')} ${t('quiz_details.participation')} ${t(
                  'general.type',
                )}*`}
            </StyledFormGroupWrapper>

            <StyledFormGroupWrapper>
              <ItemWrapper>
                <FormControlLabel
                  value="mandatory"
                  control={<Radio color="secondary" />}
                  label={t('general.mandatory')}
                  disabled={false}
                />
              </ItemWrapper>

              <ItemWrapper>
                <FormControlLabel
                  value="optional"
                  control={<Radio color="secondary" />}
                  label={t('general.optional')}
                  disabled={false}
                />
              </ItemWrapper>

              <HelpButtonContainer>
                <Tooltip
                  title={
                    props.values.quizParticipationType &&
                    (props.values.quizParticipationType === 'mandatory' ? (
                      <span style={{ fontSize: '14px' }}>
                        {t('general.mandatory_helper')}
                      </span>
                    ) : (
                      <span style={{ fontSize: '14px' }}>
                        {t('general.optional_helper')}
                      </span>
                    ))
                  }
                  arrow
                >
                  <Icon filename={'info'}></Icon>
                </Tooltip>
              </HelpButtonContainer>
            </StyledFormGroupWrapper>
          </StyledFromGroupContainer>
        </RadioGroup>
      </FormGroupItem>

      <FormGroupItem>
        <Input
          error={!!props.errors.passRate}
          errorMessage={props.errors.passRate ? props.errors.passRate : ''}
          touched={props.touched.passRate}
          label={`${t('quiz_details.pass_rate')} ( % )${props.values.quizParticipationType !== 'optional' ? '*' : ''
            }`}
          name="passRate"
          size="medium"
          type="number"
          fullWidth
          value={props.values.passRate}
          onChange={props.handleChange}
          disabled={props.values.quizParticipationType === 'optional'}
          onBlur={props.handleBlur}
        />
      </FormGroupItem>

      <FormGroupItem>
        <RadioGroup
          name="randomization"
          value={props.values.randomization}
          onChange={() =>
            props.setFieldValue('randomization', !props.values.randomization)
          }
        >
          <StyledFromGroupContainer>
            <StyledFormGroupWrapper className={'radiogroup_title'}>
              {t('quiz_details.randomize_question')}
            </StyledFormGroupWrapper>

            <StyledFormGroupWrapper>
              <ItemWrapper>
                <FormControlLabel
                  value={false}
                  control={<Radio color="secondary" />}
                  label={t('general.no')}
                // disabled={!props.values.randomization}
                />
              </ItemWrapper>

              <ItemWrapper>
                <FormControlLabel
                  value={true}
                  control={<Radio color="secondary" />}
                  label={t('general.yes')}
                // disabled={!props.values.randomization}
                />
              </ItemWrapper>

              <HelpButtonContainer>
                <Tooltip
                  title={
                    <span style={{ fontSize: '14px' }}>
                      {t('quiz_details.randomize_helper')}
                    </span>
                  }
                  arrow
                >
                  <Icon filename={'info'}></Icon>
                </Tooltip>
              </HelpButtonContainer>
            </StyledFormGroupWrapper>
            {props.touched.randomization && props.errors.randomization && (
              <ErrorMessage>{props.errors.randomization}</ErrorMessage>
            )}
          </StyledFromGroupContainer>
        </RadioGroup>
      </FormGroupItem>

      <FormGroupItem>
        <InputLabel>
          {t('quiz_details.this_module_contains')} {props.questionListSize}{' '}
          {t('quiz_details.randomize_rule')}
        </InputLabel>
        <Input
          error={!!props.errors.randomizationNumber}
          errorMessage={
            props.errors.randomizationNumber
              ? props.errors.randomizationNumber
              : ''
          }
          touched={props.touched.randomizationNumber}
          label={t('quiz_details.number_of_randomized_questions')}
          name="randomizationNumber"
          size="medium"
          type="number"
          fullWidth
          value={props.values.randomizationNumber}
          onChange={props.handleChange}
          disabled={!props.values.randomization}
          onBlur={props.handleBlur}
        />
      </FormGroupItem>
    </>
  )
}

export default Step1

const StyledFromGroupContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #080a0a14;
  border-radius: 8px;
`
const StyledFormGroupWrapper = styled.div`
  display: flex;
  gap: 23px;

  &.radiogroup_title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    background: #f5f5f5;
    border-radius: 8px 0px 0px 8px;
    padding: 0px 30px;
    min-width: max-content;
    margin-right: 20px;
    color: #080a0a8a;
  }
`

const ErrorMessage = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0;
  font-size: 12px;
  color: red;
`

const ItemWrapper = styled.div`
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${mainColor};
  }
`
const HelpButtonContainer = styled.div`
  position: absolute;
  top: -15px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 100%;
`

const Label = styled.label`
  &.error {
    color: red;
  }
`
const InputLabel = styled.div`
  padding-bottom: 10px;
`
