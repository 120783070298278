import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import importStudentsService from 'services/users/importStudentsService'

const useImportStudents = () => {
  const { t } = useTranslation()
  const { insertStudents } = importStudentsService()
  const { setSnackbar } = useSnackbarAlert()

  const importStudents = async (
    values: any,
    groupId: any,
    groupName: any,
    companyId: any,
    callBack?: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    const { errors, data } = await insertStudents({
      variables: {
        students: values,
        groupId,
        groupName,
        companyId,
      },
    })

    let usersData = null

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `${t('general.user')} ${t('messages.edited')}`
      variant = 'success'
      usersData = data.insertStudents
    }

    callBack(usersData)

    setSnackbar({ message, variant })
  }

  return {
    importStudents,
  }
}

export default useImportStudents
