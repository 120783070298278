
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import CustomDrawer from "components/common/Drawer/CustomDrawer";
import styled from 'styled-components'
import { useTheme } from "@material-ui/core";
import { useTranslation } from 'react-i18next'

import { FormField, FormFields, FormGroupItem, FormGroupWrapper } from "components/common/Form";
import TimeInput from "components/common/TimeInput/TimeInput";
import VideoCommentGrid, { IComment } from "components/common/VideoCommentGrid/VideoCommentGrid";

import { CloseIcon, DrawerContent, DrawerHeader, DrawerTitle, Info, } from "components/DrawerLayout/styled-components";
// import { CutomTextArea, SaveCommentButton, CancelEditButton } from "../styled-components";
import { JsxEmit } from "typescript";
import { CutomTextArea, SaveCommentButton } from "components/common/VideoPlayer/styled-components";
import { Text } from "../../styled";
import { module } from "../../types";
import Player from "components/common/VideoPlayer/Player";
import AudioPlayer from "components/common/AudioPlayer";
import { getUserId } from "helpers/localStorage";

import { useNote, NoteQuery } from 'hooks/notes/useNote';
import { useEditNote, EditNote } from "hooks/notes/useEditNote";

interface PlayerDrawerProps {
  opened: boolean,
  toggleDrawer?: () => void,
  totalWidth?: string,
  onClose: () => void,
  url: string,
  lesson: module.Lesson,
  courseId: string,
  model: 'LESSON' | 'SKILLTESTVIDEO',
  refetchGetUser: () => any
}


const StudentNotes: FunctionComponent<PlayerDrawerProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [time, setTime] = useState<number>(0);
  const [showFormGroup, setShowFormGroup] = useState<boolean>(false);
  const redirectRef = useRef();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [editedNote, setEditedNote] = useState<IComment | null>(null);

  const getNotesData: NoteQuery = {
    actionId: props.lesson.id,
    model: "LESSON",
    skip: 0,
    limit: 8
  }

  const {
    comments,
    loadingFetchingNotes,
    errorFetchingNotes,
    refetchGetNotes,
  } = useNote(getNotesData);


  const { editNoteFunction, editNoteLoading, editNoteError } = useEditNote(handleRefetchNotes);


  function clearFields() {
    setTitle('')
    setDescription('')
  }

  function handleRefetchNotes() {
    props.refetchGetUser();
    setEditedNote(null);
    refetchGetNotes();
    clearFields();
    setShowFormGroup(false);
  }

  function onNoteEditHandler(e: React.MouseEvent<HTMLElement>, id: string) {
    e.stopPropagation();
    const foundNote = comments.find(item => item.id === id);
    if (foundNote) {
      setShowFormGroup(true);
      setEditedNote(foundNote)
      setTitle(foundNote.title)
      setDescription(foundNote.description);
    }
  }

  const saveNoteClickHandler = async () => {
    const validTitle = title.trim();
    const validDescription = description.trim();

    if (validTitle && validDescription) {
      if (!editedNote) {
        return;
      } else {
        const updatedNoteData: EditNote = {
          noteId: editedNote.id,
          input: {
            description,
            moment: editedNote.seconds,
            title,
            thumbnail: props.lesson.video?.thumbnail
          },
        };
        await editNoteFunction({
          variables: updatedNoteData
        });
      }
    }
  };

  const handleCancelNoteEditing = () => {
    setEditedNote(null)
    clearFields();
    setShowFormGroup(false);
  };

  return (
    <CustomDrawer
      opened={props.opened}
      toggleDrawer={props.toggleDrawer}
      totalWidth={props.totalWidth}>
      <>
        <DrawerHeader theme={theme}>
          <DrawerTitle theme={theme}>
            {t('general.noteComment')}
          </DrawerTitle>
          <CloseIcon filename='close' onClick={props.onClose} />
          <Info />
        </DrawerHeader>
        <DrawerContent margin='100px'>
          {props.lesson?.video && <div style={{
            marginTop: '20px',
            marginBottom: '20px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Player
              url={props.url}
              currentTime={time}
              disableTimeSave={true}
              model="LESSON"
              lesson={props.lesson}
              courseId={props.courseId}
            />
          </div>}

          {props.lesson?.audio && <div style={{
            marginTop: '20px',
            marginBottom: '20px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // padding: "0 20%",

          }}>
            <AudioPlayer
              url={props.url}
              audioRedirect={{ time }}
            />
          </div>}
          {showFormGroup && editedNote && (
            <FormGroupWrapper>
              <FormGroupItem>
                <FormFields >
                  <FormField width={"100%"}>
                    <TimeInput label={t('general.time')} time={editedNote.seconds} value={title} onChange={(e) => setTitle(e.target.value)} />
                  </FormField>
                  <FormField width={"100%"}>
                    <CutomTextArea placeholder={t('form_fields.description')} value={description} onChange={(e) => setDescription(e.target.value)} />
                  </FormField>
                  <FormField width={"100%"}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {editedNote && (
                        <CancelEditButton onClick={handleCancelNoteEditing}>
                          {t('actions.cancel')}
                        </CancelEditButton>
                      )}
                      <SaveCommentButton disabled={editNoteLoading || loadingFetchingNotes} onClick={saveNoteClickHandler}>
                        {t('general.save')}
                      </SaveCommentButton>
                    </div>
                  </FormField>
                </FormFields>
              </FormGroupItem>
            </FormGroupWrapper>
          )}
          <br />
          <VideoCommentGrid
            setTime={setTime}
            comments={comments}
            onDelete={handleRefetchNotes}
            onEdit={onNoteEditHandler}
          />
        </DrawerContent>
      </>
    </CustomDrawer>
  );
}

export default StudentNotes;

const CancelEditButton = styled.button`
  width: 166px;
  height: 51px;
  background-color: #fff;
  color: #797A7A;
  border: none;
  outline: none;
  border-radius: 16px;
  margin-right: 10px;
  border: 1px solid #EBEBEB;
  cursor: pointer;
  font-weight: medium;
`
