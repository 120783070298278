import React, { FC, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useFormik } from "formik";
import { IconButton, useTheme, CircularProgress } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import axios from "axios";
import { Button } from "components/common/Button";
import DropdownButton from "components/common/DropdownButton";
import Uploader from "components/common/FileUploader/Uploader";
import { Input } from "components/common/TextInput";
import { CloseIcon, DrawerContent, DrawerHeader, DrawerTitle, Info } from "components/DrawerLayout/styled-components";
import Icon from "components/Icon";
import { useUserValue } from "context/UserContext";
import { ADD_REVIEW } from "gql/skillTest/skilltest.query";
import { useGenerateCustumUploadVideoLinks, useGenerateUploadVideoLinks, useUploadAudioService, useUploadFile } from "hooks/helpers/useHelpersService";
import styled from "styled-components";
import { Text } from '../../styled'
import { ISkillTest, ISkillTestReview } from "../personalhomework";
import { useTranslation } from 'react-i18next'
import { useParams } from "react-router-dom";

import AudioPlayer from 'components/common/AudioPlayer';
import SkillTestReviewCard from './SkillTestReviewCard/SkillTestReviewCard';
import useSnackbarAlert from "hooks/useSnackbar";
import ConvertedVideo from "../PersonalHomework/ConvertedVideo";

interface CoachPersonalProps {
  onModalClose: () => void;
  skillTests: ISkillTest[]
  courseName: string,
  moduleName: string,
  refetch: () => any
}

interface FormDataProps {
  rate: number,
  attachedFile: { name: string, link: string, fileType: string } | null,
  description: string
}

const formData: FormDataProps = {
  rate: 0,
  attachedFile: null,
  description: ''
};

const constants = {
  video:
    'video/x-msvideo|video/mpeg|video/ogg|video/mp4|video/x-flv|video/quicktime|video/x-msvideo|video/x-ms-wmv',
  audio: 'audio/mpeg|audio/mpeg|audio/aac|audio/mp4a-latm',
  file:
    'application/pdf|application/msword|application/plain|text/plain|image/png|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel',
};

const CoachPersonal: FC<CoachPersonalProps> = ({ onModalClose, skillTests, moduleName, courseName, refetch }) => {
  const [file, setFile] = useState<any>('')
  const [fileTypes, setFileTypes] = useState('')
  const [uploadVideoProgress, setUploadVideoProgress] = useState(0)
  const [isReviewed, setIsReviewed] = useState(false)
  const [state] = useUserValue()
  const { uploadFile, fileLoading } = useUploadFile()
  const params: any = useParams()
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const theme = useTheme();

  const [currentSkillTest, setCurrentSkillTest] = useState(skillTests.length - 1);
  const [isSkillVideoConverted, setIsSkillVideoConverted] = useState<true | null>(null);

  const chosenSkilltest = skillTests[currentSkillTest];

  const [addReview] = useMutation(ADD_REVIEW);
  const { generateCustumUploadVideoLinks } = useGenerateCustumUploadVideoLinks();
  const { uploadAudio } = useUploadAudioService();

  const reviewByCoachExists = !!chosenSkilltest?.reviews.find(r =>
    r.author.userId === state.currentUser.id && r.author.role === 'COACH') || null;

  const renderedReviews: ISkillTestReview[] = chosenSkilltest?.reviews.reduce((acc: ISkillTestReview[], inc) => {
    if (inc.author.role === 'COACH') {
      return [inc, ...acc];
    } else if (inc.author.role == 'STUDENT') {
      return [...acc, inc]
    } else {
      return [...acc];
    }
  }, []) || []

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik(
    {
      initialValues: formData,
      onSubmit: async (values) => {

        await addReview({
          variables: {
            ratedId: skillTests[currentSkillTest].id,
            model: 'skillTestVideo',
            input: {
              courseId: params.id,
              rate: values.rate,
              description: values.description,
              ...(values.attachedFile && {
                attachedFile: {
                  name: values.attachedFile?.name,
                  type: values.attachedFile?.fileType,
                  link: values.attachedFile?.link
                }
              })
            }
          }
        })

        refetch();
        // onModalClose()
      }
    }
  );


  const dateConvert = (timestamp: Date) => {
    const date = new Date(timestamp);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}  ${date.getHours()}:${date.getMinutes()}`
  }

  const onReviewDeleteHandler = () => {
    setIsReviewed(false);
  };

  const { generateUploadVideoLinks } = useGenerateUploadVideoLinks()

  const uploadVideoService = (url: string) => {

    const formData = new FormData();

    formData.append("file", file);

    return axios.put(url, formData, {
      headers: {
        'Content-Type': "multipart/form-data",
      },
      onUploadProgress: ({ total, loaded }) => {
        setUploadVideoProgress((loaded / total) * 100)
        if ((loaded / total) * 100 === 100) {
          setUploadVideoProgress(0)
        }
      },
    }).then((res) => {
      const message = `Video upload success, conversion ${res.data}`
      const variant = 'success'
      setSnackbar({ message, variant })
    }).catch((err) => {
      const message = `${err}`
      const variant = 'error'
      setSnackbar({ message, variant })
    })
  }

  const handleGenerateUploadLink = () => {
    uploadFile(
      file,
      'skillTest/attachment',
      (link: any) => {
        setFieldValue('attachedFile', {
          name: file.name,
          fileType: file.name.split('.').pop(),
          link,
        })
      },
    )
  };

  const generateUploadAudioLink = () => {
    const audioFile: any = { fileName: file.name, type: file.type }

    uploadAudio(
      audioFile,
      (arg: any) => uploadVideoService(arg),
      (link: string) => {
        setFieldValue('attachedFile', {
          name: file.name,
          fileType: file.name.split('.').pop(),
          link,
        })

        setUploadVideoProgress(0)
      },
    )
  };

  useEffect(() => {
    if (file && file.type && constants.audio.includes(file.type)) {
      setFileTypes('audio')
      generateUploadAudioLink();
    }

    if (file && file.type && constants.video.includes(file.type)) {
      setFileTypes('video')
      handleGenerateVideoUploadLink()
    }

    if (file && file.type && constants.file.includes(file.type)) {
      setFileTypes('contentDocument')
      handleGenerateUploadLink()
    }
  }, [file])

  useEffect(() => {
    const index = skillTests.findIndex(el => el.id === params.skillId)
    index > 1 && setCurrentSkillTest(index)

  }, [params.skillId])


  const handleGenerateVideoUploadLink = () => {
    const videos = [{ fileName: file.name, type: file.type }]

    generateCustumUploadVideoLinks(
      videos,
      'skill_test_video',
      (arg: any, key?: string) => uploadVideoService(arg),
      (links: any) => {
        setFieldValue('attachedFile', {
          name: file.name,
          fileType: file.name.split('.').pop(),
          link: links.video.links[2].url,
        })
      },
    )
  }

  const handleAttachmentChange = (e: any, field: string) => {
    const { files } = e.target
    const file = files[0]
    setFile(file)
  }

  return (
    <>
      <DrawerHeader theme={theme} style={{ display: 'flex', flexDirection: 'column' }}>
        <DrawerTitle theme={theme}>{t("general.personal_assignment")}</DrawerTitle>
        <DrawerSubtitle>
          {skillTests[currentSkillTest]?.sentForReview[1]?.firstName} {skillTests[currentSkillTest]?.sentForReview[1]?.lastName}
        </DrawerSubtitle>
        <CloseIcon
          onClick={onModalClose}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent margin="150px">
        <DrawerTitle style={{ fontSize: '28px', }}>
          {courseName}
        </DrawerTitle>
        <DrawerSubtitle style={{ marginTop: '10px', fontSize: '16px' }}>
          {moduleName}
        </DrawerSubtitle>
        <div style={{ width: '100%', height: '1px', background: '#EBEBEB' }} />
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
          <Text fs={'16px'} color={'#080A0A54'}>{dateConvert(skillTests[currentSkillTest].createDate)}</Text>
          <DropdownButton
            backgroundColor='transparent'
            textColor='#080A0A54'
            iconColor='#080A0A54'
            title={`${t('general.version')} ${skillTests[currentSkillTest].version}`}
            buttons={skillTests.map((skill, index) => {
              return {
                title: `${t('general.version')} ${skill.version}`,
                onClick: () => {
                  if (currentSkillTest !== index) {
                    setIsReviewed(false);
                    setCurrentSkillTest(index);
                    setIsSkillVideoConverted(null);
                    setFieldValue('rate', 0);
                    setFieldValue('attachedFile', null)
                    setFieldValue('description', '')
                  }
                }
              }
            })}
          />
        </div>
        <div style={{ width: '100%', height: '1px', background: '#EBEBEB' }} />
        <p style={{ marginTop: 10, color: "#080A0A54" }}>
          <b>{t('general.studentComment')}: </b><i>{skillTests[currentSkillTest].description}</i>
        </p>

        {chosenSkilltest?.file.type === "mp4" ? (
          <ConvertedVideo
            currentSkilltestIndex={currentSkillTest}
            chosenSkilltest={chosenSkilltest}
            onConvertionStatusChange={setIsSkillVideoConverted}
            isSkillVideoConverted={isSkillVideoConverted}
          />
        ) : ""}

        {skillTests[currentSkillTest].file && skillTests[currentSkillTest].file.type === "pdf" &&
          <div style={{
            marginTop: '20px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}>
            <IconButton style={{
              border: '1px solid #F1F1F1',
              borderRadius: 15,
            }}
              onClick={() => window.open(skillTests[currentSkillTest].file.link)}
            >
              <Icon filename='pdf-file' />
            </IconButton>
          </div>
        }

        {skillTests[currentSkillTest]?.file.type === "mp3" ?
          (
            <div className="mt-5 w-full flex justify-center">
              <AudioPlayer url={chosenSkilltest?.file.link} />
            </div>
          ) : ""}

        {chosenSkilltest && !isReviewed && !reviewByCoachExists && (
          <>
            <div className="mt-5">
              <Rating
                value={values.rate}
                precision={1}
                onChange={(e: any) => setFieldValue('rate', +e.target.value)}
              />
            </div>
            <div className="mt-5">
              <Input
                label={`${t('general.yourReview')}`}
                name="location"
                size="medium"
                type="text"
                fullWidth
                multiline
                rows={10}
                value={values.description}
                onChange={(e: any) => {
                  setFieldValue('description', e.target.value)
                }}
              />
            </div>
            <div style={{
              marginTop: '20px',
            }}>
              <Uploader
                label={`${t('actions.upload_file')}`}
                accept="video/mp4,video/x-m4v,video/*,.pdf,audio/mp3,audio/wav,audio/*"
                onFileChange={(e: any) =>
                  handleAttachmentChange(e, 'attachedFile')
                }
                deleteFile={() => {
                  setFieldValue('attachedFile', null)
                }}
                type={fileTypes}
                inputValue={() => {
                  return values.attachedFile ? values.attachedFile.name : ''
                }}
                onInputChange={(e: any) =>
                  setFieldValue('attachedFile.name', e.target.value)
                }
                disabled={!values.attachedFile}
                uploadedFile={values.attachedFile}
                fileExtension={
                  values.attachedFile ? file.type : ''
                }
                loading={
                  uploadVideoProgress > 0
                    ? uploadVideoProgress
                    : false
                }
                id="attachedFile"
              ></Uploader>
            </div>

            <div style={{
              marginTop: '20px',
              marginBottom: '20px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
              <Button
                text={t('general.send')}
                onClick={handleSubmit}
                size={'medium'}
                buttonStyles={{
                  borderRadius: '16px'
                }}
              />
            </div>
          </>
        )}

        <div style={{ height: '20px' }}></div>

        {renderedReviews.length > 0 ? (
          renderedReviews.map((review) => {
            const isMyReview = state.currentUser.id === review.author.userId;
            return (
              <SkillTestReviewCard
                key={review.id}
                // skillTestId={chosenSkilltest.id}
                profilePictureUrl=""
                isMyReview={isMyReview}
                refetch={refetch}
                review={review}
              />
            )
          })
        ) : ""}
      </DrawerContent>
    </>
  );
}

export default CoachPersonal;

const DrawerSubtitle = styled.div`
  font-size: 24px;
  color: #080A0A54;
  margin-bottom: 20px;
`
