import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Backdrop, makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { Grid } from 'components/common/Cards/styled-components'
import Drawer from 'components/common/Drawer'
import Player from 'components/common/VideoPlayer/Player'
import CourseList from 'pages/courses/CourseList'
import FilterDrawer from 'pages/courses/FilterDrawer/FilterDrawer'
import { PersonalInformationHeader } from '../AdminProfile/PersonalInformation'
import _ from 'lodash'
import { useFetchSuggestedCourses } from 'hooks/course/useSuggestedCourses'
import gql from 'graphql-tag'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { GET_SECTION_WITH_COURSES } from 'gql/sections/sections.query'


const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export const Section: React.FC<{
  userId?: string
  sectionName?: String
  sectionId?: string
  // groups: string[]
  section?: any
  redirectToCoursePage?: boolean
  isTitleHidden?: boolean
  companyId?: string | null
}> = ({
  userId, sectionId, redirectToCoursePage, isTitleHidden, companyId, sectionName
}) => {

    const { t } = useTranslation()
    const [filterIsOpen, setFilterIsOpen] = useState(false)
    const [filterData, setFilterData] = useState<any>({})
    const [filterQuery, setFilterQuery] = useState<any>({})
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(12);
    const [searchText, setSearchText] = useState<string>('');
    const [fetchCourses, setCourses] = useState<any[]>([])
    const [hideCourses, setHideCourses] = useState(false);
    const [hasMoreCourses, setHasMoreCourses] = useState(true);

    const observer = useRef<IntersectionObserver | null>(null);

    const initialVariables = {}




    const { data: courseData, error: errorT } = useQuery(GET_SECTION_WITH_COURSES, {
      variables: {
        id: sectionId
      },
      onCompleted: courseData => {

        setCourses(courseData.getSectionWithCourses.courses)
      }
    })



    useEffect(() => {
      return () => {
        if (observer.current) observer.current.disconnect();
      }
    }, [])


    const [isCoursesVisible, setCoursesVisible] = useState(true)

    const classes = useStyles()

    const [videoModal, setVideoModal] = useState<any>({
      modal: false,
      data: '',
      subtitle: '',
    })

    const handleVideoModal = (data: any) => {
      if (!data.video) return

      setVideoModal({
        ...videoModal,
        modal: !videoModal.modal,
        data: !videoModal.modal ? data.video.links[0].url : '',
        subtitle: !videoModal.modal ? data.subtitle : '',
      })
    }


    const filterFetchCourses = fetchCourses?.filter(item => {

      return item.isOwnCourse == false
    })

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

    if (filterFetchCourses.length === 0) {
      return null
    }
    console.log(fetchCourses, "fetchCourses")
    return (

      <div style={{ margin: "60px 0" }}>
        {!isTitleHidden && (
          <PersonalInformationHeader theme={theme}>
            <h1>
              {sectionName}
            </h1>
            {filterFetchCourses && filterFetchCourses?.length >= 4 &&
              <div
                style={{
                  fontSize: '13px',
                  marginLeft: 'auto',
                  fontWeight: 500,
                }}

                onClick={() => setHideCourses((oldState) => oldState ? false : true)}
              >
                {hideCourses ? t('general.show_all') : t('general.show_less')}
              </div>
            }
          </PersonalInformationHeader>
        )}

        {isCoursesVisible && hideCourses && (
          <Grid className="card" style={{ position: 'relative' }} cols={isSmallScreen ? 3 : 2}>
            {filterFetchCourses && (
              <>
                <CourseList
                  data={[filterFetchCourses[0], filterFetchCourses[1], filterFetchCourses[2]]}
                  gridStyle="card"
                  selectedItem={[]}
                  handleVideoModal={handleVideoModal}
                  selectItem={() => { }}
                  redirectToDashboard={!redirectToCoursePage}
                  userId={userId}
                />
              </>
            )}
          </Grid>
        )}

        {isCoursesVisible && !hideCourses && (
          <>

            <Grid className="card" style={{ position: 'relative' }} cols={isSmallScreen ? 3 : 2}>
              {filterFetchCourses && (
                <>
                  <CourseList
                    section
                    data={filterFetchCourses}
                    gridStyle="card"
                    selectedItem={[]}
                    handleVideoModal={handleVideoModal}
                    selectItem={() => { }}
                    redirectToDashboard={!redirectToCoursePage}
                    userId={userId}
                  />
                  <div />
                </>
              )}
            </Grid>
          </>
        )}


        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={videoModal.modal}
          onClose={() => {
            setVideoModal({
              ...videoModal,
              modal: false,
            })
          }}
          className={classes.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Player url={videoModal.data} subtitle={videoModal.subtitle} />
        </Modal>

      </div>
    )
  }
