import { useState } from 'react'
import {
  editCourseService,
  coursePublishedService,
  addCourseModulesService,
  deleteCourseModuleService,
  changeCourseStepService,
} from 'services/course/editCourseService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

interface useEditCourseParams {
  courseId: string
}

interface useAddCourseModulesParams {
  filter: any
}

export const usePublishedCourse = () => {
  const { t } = useTranslation()
  const { coursePublishedMutation } = coursePublishedService()
  const { setSnackbar } = useSnackbarAlert()

  const publishedCourse = async (courseId: string[], action: string, refetch?: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await coursePublishedMutation({
      variables: {
        courseId,
        action,
      },
    })




    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${action === 'published'
        ? t('actions.course_published_successfuly')
        : t('actions.course_unpublished_successfuly')
        }`
      variant = 'success'
      refetch()
    }

    setSnackbar({ message, variant })
  }

  return {
    publishedCourse,
  }
}

export const useEditCourse = (params: useEditCourseParams) => {

  const { t } = useTranslation()
  const [updatedCourse, setUpdatedCourse] = useState('')
  const { editCourseMutation, loading } = editCourseService(params)
  const { setSnackbar } = useSnackbarAlert()

  const editCourse = async (
    id: string,
    course: any,
    groupIds: string[] = [],
    callback?: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }


    const { errors, data } = await editCourseMutation({
      variables: {
        id,
        input: course,
        groupIds,
      },
    })

    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      setUpdatedCourse(data.editCourse)
      message = t('messages.changes_saved')
      variant = 'success'
      callback()
    }

    setSnackbar({ message, variant })
  }

  return {
    editCourse,
    editCourseLoading: loading,
    updatedCourse,
  }
}

export const useAddCourseModules = (params: useAddCourseModulesParams) => {
  const { t } = useTranslation()
  const { addCourseModulesMutation, loading } = addCourseModulesService(params)
  const { setSnackbar } = useSnackbarAlert()
  const [newModule, setNewModule] = useState('')

  const addCourseModules = async (
    courseData: { courseId: string; name: string },
    moduleIds: string[],
    onSuccess: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await addCourseModulesMutation({
      variables: {
        courseData,
        moduleIds,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      onSuccess()
      setNewModule(data.addModulesToCourse)
      message = t('messages.added_modules_to_courses')
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    addCourseModules,
    addCourseModulesLoading: loading,
    newModule,
  }
}

export const useDeleteCourseModule = (params: useAddCourseModulesParams) => {
  const { t } = useTranslation()
  const { deleteCourseModuleMutation, loading } = deleteCourseModuleService(
    params,
  )
  const { setSnackbar } = useSnackbarAlert()
  const [deletedModule, setDeletedModule] = useState('')

  const deleteCourseModule = async (
    courseId: string,
    moduleIds: string[],
    onSuccess: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await deleteCourseModuleMutation({
      variables: {
        courseId,
        moduleIds,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      onSuccess()
      setDeletedModule(data.deleteModuleInCourse)
      message = t('messages.remove_modules_from_course')
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteCourseModule,
    deleteCourseModuleLoading: loading,
    deletedModule,
  }
}

export const useChangeCourseStep = () => {
  const { changeCourseStepMutation } = changeCourseStepService()

  const changeCourseStep = async (
    courseId: string,
    step: number,
    callback: any,
  ) => {
    const { errors, data } = await changeCourseStepMutation({
      variables: { courseId, step },
    })

    if (errors && errors.length) {
    } else {
      callback()
    }
  }

  return {
    changeCourseStep,
  }
}
