import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import strCutter from 'utils/strCutter'
import { getFileType } from 'helpers/getFileType'

import Tooltip from '@material-ui/core/Tooltip'
import { FileContainer, FileTitle, FileIcon } from './styled-components'

interface FileParams {
  name: string
  fileType: string
  link: string
}

interface Params {
  icon?: React.ReactElement
  file: FileParams
  onClick: (link: string) => void
}

const CustomTooltip = withStyles(() => ({
  tooltip: {
    color: '#fff',
    fontSize: '14px',
  },
}))(Tooltip)

const FileWrapper = ({ icon, file, onClick }: Params) => {
  const type = file.fileType

  const fileType = getFileType(type)
  return (
    <FileContainer onClick={() => onClick(file.link)}>
      <FileIcon>
        {icon ? icon : <img src={`/${fileType}_icon.svg`} alt="File icon" />}
      </FileIcon>

      <CustomTooltip title={`${file.name}.${file.fileType}`}>
        <FileTitle>
          {strCutter(file.name, 20)}
          {file.name && file.name.length > 20 ? '' : '.'}
          {file.fileType}
        </FileTitle>
      </CustomTooltip>
    </FileContainer>
  )
}

export default FileWrapper
