import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import { mainColor } from 'components/common/Colors/CommonColors'

const ExpansionPanel = withStyles({
  root: {
    backgroundColor: 'transparent',
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },

    '& > a': {
      color: '#fff',
    },

    '& > a.active > div': {
      backgroundColor: '#317BF4 !important',
    },
  },
  expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    padding: props => (props.isOpened ? `16px 30px` : '16px 30px'),
    fontSize: '14px',
    backgroundColor: 'transparent',
    width: '100%',
    transition: 'color .3s ease',
    '&$expanded': {
      minHeight: 56,
    },
    '&:hover': {
      backgroundColor: '#317BF4',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expandIcon: {
    color: 'inherit',
  },
  expanded: {},
})(MuiExpansionPanelSummary)

const Typo = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: '16px',
    margin: props => (props.isOpened ? 'unset' : 'auto'),
  },
})(Typography)

const useStyles = makeStyles({
  root: {},
  closed: {
    visibility: 'hidden',
  },
})

const ExpansionItem = ({ item, index, isOpened, role }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  // const canView = item.canView ? item.canView.includes(role) : true

  return (
    <React.Fragment key={index}>
      {item.canView && (
        <ExpansionPanel className="expansionPanel">
          <NavLink to={item.path} activeClassName="active" exact>
            <ExpansionPanelSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              isOpened={isOpened}
            >
              <Typo isOpened={isOpened}>
                {item.icon}
                {isOpened && (
                  <span className={classes.root}>
                    {' '}
                    {t(`navigation.${item.title}`)}
                  </span>
                )}
              </Typo>
            </ExpansionPanelSummary>
          </NavLink>
        </ExpansionPanel>
      )}
    </React.Fragment>
  )
}

export default ExpansionItem
