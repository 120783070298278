import { useMutation } from "@apollo/react-hooks";
import { DELETE_NOTE } from "gql/notes/notes.query";

export type DeleteNote = {
  noteId: string
  onCompleted: () => void
};

export const useDeleteNote = ({ noteId, onCompleted }: DeleteNote) => {
  const [deleteNoteFunction, { loading, error }] = useMutation(DELETE_NOTE, {
    variables: { id: noteId },
    onCompleted: (data) => {
      if (data) {
        onCompleted();
      }
    }
  })

  return {
    deleteNoteFunction,
    deleteNoteLoading: loading,
    deleteNoteError: error,
  }
};