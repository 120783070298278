import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import createQuizService from 'services/quizzes/createQuizService'

const useCreateQuizService = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  const { createQuizMutation, loading } = createQuizService(params)

  const createQuiz = async (
    input: any,
    type: string,
    actionId: string,
    companyId: string | null,
    fun: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }



    const { data, errors } = await createQuizMutation({
      variables: {
        type,
        actionId,
        input,
        companyId,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message =
        type === 'course'
          ? `${t('general.iq_test')} ${'messages.saved'}`
          : `${t('general.quiz')} ${'messages.saved'}`
      variant = 'success'
      fun()
    }

    setSnackbar({ message, variant })
  }
  return {
    createQuiz,
    loading,
  }
}

export default useCreateQuizService
