import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

interface Props {
  label: string
  name?: string
  value: number[]
  onRangeChange: (newValue: number | number[], name: string) => void
  min?: number
  max?: number
}

const RangeSlider = ({
  label,
  value,
  onRangeChange,
  min = 0,
  max = 100,
  name = label,
}: Props) => {
  const classes = useStyles()

  const handleChange = (event: any, newValue: number | number[]) => {
    onRangeChange(newValue as number[], name)
  }
  return (
    <div className={classes.root}>
      <Typography id="range-slider" gutterBottom>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 30px',
            color: '#080A0A8A',
          }}
        >
          {label}
          <span
            style={{
              color: '#000000',
            }}
          >
            {value[0]}-{value[1]}
          </span>
        </div>
      </Typography>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        min={min}
        max={max}
        name={name}
      />
    </div>
  )
}

export default RangeSlider
