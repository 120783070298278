import React, { FormEvent, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useFormik } from 'formik'
import Alert from '@material-ui/lab/Alert'
import useAuth from 'hooks/useAuth'
import { EmailSchema, LoginSchema } from 'helpers/validationSchemas'
import AuthLayout from 'components/AuthLayout/'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import { useTranslation } from 'react-i18next'
import ChangeLanguage from 'components/common/ChangeLanguage/ChangeLanguage'
import { mainColor, validationRed } from 'components/common/Colors/CommonColors'
import '../../fonts/font.css'
import { getCurrentLanguage } from 'helpers/localStorage'
import backgroundImage from '../../assets/images/background-image.png';
import blindEye from '../../assets/images/password-eye.png';
import LandingNavbar from './LandingNavbar'
import platform from 'platform'
import { Api } from 'api'

interface InitialValue {
  email: string
  password: string
  remember: boolean
}

const initialValues: InitialValue = {
  email: '',
  password: '',
  remember: false,
}
const SendMail = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: EmailSchema,
    // eslint-disable-next-line no-shadow
    async onSubmit(values) {

      const data: any = { ...values }
      Api('post', 'users/forgotPassword', {
        email: data.email,
      })
        .then((res: any) => {

          history.push('/login')
          // message = `Successful`
          // variant = 'success'
          // setLoading(false)
          // setSnackbar({ message, variant })
          // setSuccess(true)
        })
        .catch((err: any) => {
          history.push('/login')
          // message = err.response.data.message
          // variant = 'error'
          // setLoading(false)
          // setSnackbar({ message, variant })
        })
    },
  })

  const handlePasswordMail = (e: any) => {
    e.preventDefault()

    handleSubmit()
  }

  const currentLng = getCurrentLanguage() || 'ka'

  const InputStyles = {

    fontSize: currentLng === 'en' ? '16px' : '14px',
    marginBottom: '20px',
    borderRadius: '12px',
    height: '60px',
  }

  return (

    <>
      <LandingNavbar />
      <div className='w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 z-1 landing'>
        <div className='w-full flex flex-col h-full align-center items-center text-center justify-center text-white'>
          <div
            className='bg-black/70 flex flex-col items-center justify-center rounded-xl w-full'
            style={{
              padding: '50px 45px',
              maxWidth: '536px',
            }}
          >
            <div className='h-full w-full m-30'>
              <div className='text-3xl w-full mt-5'>{t('login.forgot_password')}</div>
              <form className='flex flex-col w-full h-1/7 mt-10 items-center justify-center' >
                <input
                  type='text'
                  placeholder={t('form_fields.email')}
                  className='w-full h-14 px-4 rounded-2xl bg-[#333333] border-[#505050] placeholder-[#ffffff70]'
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />

                <span className='w-full mt-5' >
                  <div className='flex flex-nowrap rounded-2xl text-white cursor-pointer h-14 bg-[#007DFF]'>
                    <button className='w-full h-full flex items-center justify-center' type="button" onClick={handlePasswordMail}>{t('login.send_email')}</button>
                  </div>
                </span>

                <div style={{ fontSize: '16px' }} className='w-full flex mt-2 text-sm font-light'>
                  {t('login.remember_password')}
                  <span className='text-[#007DFF] ml-2 underline cursor-pointer'>
                    <Link to={'/login'} className='underline cursor-pointer'>
                      {t('login.log_in')}
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default SendMail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 650px;
  height: 476px;
`
export const LoginPopupTitle = styled.div<{ currentLng: string }>`
  font-size: ${({ currentLng }) => (currentLng === 'en' ? '32px' : '28px')};
  font-family: ${({ currentLng }) =>
    currentLng === 'en' ? 'Gotham Black' : 'Geo Roboto Mt Bold'};
  text-align: center;
`

const Content = styled.div`
  position: relative;
  margin: 20px 0 20px 0;
  width: 352px;
`

const ServerErorBox = styled.div`
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 100;

  &.show {
    opacity: 1;
  }

  .MuiAlert-root {
    padding: 0 12px;
  }
`

const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`

const TermsWrapper = styled.div<{ currentLng: string }>`
  font-size: ${({ currentLng }) => (currentLng === 'en' ? '16px' : '12px')};

  text-align: center;
  align-self: center;
`

const StyledLink = styled.div<{ currentLng: string }>`
  transition: 0.3s ease-out;
  text-align: left;
  font-size: ${({ currentLng }) => (currentLng === 'en' ? '16px' : '12px')};

  text-decoration: underline;
  &:hover {
    color: #72afd2;
  }
`

const LoginButton = styled.div`
  width: 100%;
  display: flex;
  .btn-wrapper {
    width: 100%;
  }
`
