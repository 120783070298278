import { Rating } from '@material-ui/lab'
import React, { FC } from 'react'
import Icon from 'components/Icon'
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton'
import { useDeleteReview } from '../../hooks/reviews/useDeleteReview';

interface Props {
  ProfilePicture: string
  Appraiser: string
  RatingNum: number
  Description: string
  ProfileUrl: string
  isReviewedByMe: boolean
  reviewId: string
  refetch: () => void
}

const ProfileReviewRatings: FC<Props> = ({ Appraiser, ProfilePicture, RatingNum, Description, ProfileUrl, isReviewedByMe, reviewId, refetch }) => {
  const { handleDeleteReview } = useDeleteReview(refetch);

  const handleDeleteConfirm = () => {
    if (!reviewId) {
      return
    }
    handleDeleteReview([reviewId]);
  }

  const history = useHistory();

  return (
    <div style={{ marginTop: '20px', position: 'relative', marginBottom: '20px', padding: '10px', display: 'flex', flexDirection: 'column', width: '80%', borderRadius: '16px', boxShadow: '0px 0px 30px 0px rgba(0,0,0,0.22)' }}>
      {isReviewedByMe && <IconButton onClick={handleDeleteConfirm} style={{ position: "absolute", top: "15px", right: "16.2px" }}><Icon filename="delete" fill="red" /></IconButton>}
      <div style={{ display: 'flex', flexDirection: 'column', margin: '10px 100px 10px 100px' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ marginRight: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {ProfilePicture ? <img src={ProfilePicture} alt="ProfilePicture" style={{ width: '75px', height: '75px', borderRadius: '16px' }} /> : <Icon filename={'noProfilePicture'} fill={'#FFD700'} width={'75px'} height={'75px'} />}
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingTop: '5px', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', }}>
              <HoverableElement onClick={() => window.location.pathname = `profile/${ProfileUrl}`}>
                {Appraiser}
              </HoverableElement>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Rating value={RatingNum} disabled />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <span style={{ display: 'flex', alignItems: 'center', marginLeft: '30px', }}><Icon filename='Calendar' style={{ color: '#080A0A84' }} /></span>
                  <p style={{ marginLeft: '10px', display: 'flex', alignItems: 'center', color: '#080A0A54', fontSize: '14px' }}> 10/12/2021 15:35</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p style={{ display: 'flex', flexDirection: 'row', margin: '10px 60px 0 100px', fontSize: '15px', color: '#080A0A86' }}>{Description}</p>
      </div>
    </div>
  )
}

export default ProfileReviewRatings

const HoverableElement = styled.div`
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #080A0A84;
  &:hover {
    color: #317BF4;
  }
  
`