import { ApolloClient } from 'apollo-client'
// import { HttpLink } from "apollo-link-http";
import { ApolloLink, split } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import { WebSocketLink } from "apollo-link-ws"
import { getMainDefinition } from "apollo-utilities"
// eslint-disable-next-line import/no-dynamic-require

import config from '../config/index.ts'
import { clearToken } from 'helpers/localStorage'

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message.statusCode === 401 && message.error === 'Unauthorized') {
        // * Logout user
        clearToken();
        window.location.href = "/login";
      }
      // eslint-disable-next-line no-console
      return console.log(
        `Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
    })
    // eslint-disable-next-line no-console
    console.log('graphQLErrors::', graphQLErrors)
  }
  // eslint-disable-next-line no-console
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Authorization: `Bearer ${localStorage.getItem('token')}` || undefined,
  },
}))

// console.log("GRAPHQL_URI", apiConfig.GRAPHQL_URI);

const uploadLink = createUploadLink({
  uri: config.GRAPHQL_URI,
})

const wsLink = new WebSocketLink({
  uri: config.WS_GRAPHQL_URI,
  options: {
    reconnect: true,
    // connectionParams: {
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem('token')}`,
    //   },
    // },
  },
})

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    )
  },
  wsLink,
  uploadLink,
)

const apolloLink = ApolloLink.from([errorLink, authLink, link])

const inMemoryCache = new InMemoryCache({})

export const client = new ApolloClient({
  link: apolloLink,
  cache: inMemoryCache,
  defaultOptions,
})
