import React, { useContext, useState } from 'react'
import { CssBaseline, ThemeProvider, createMuiTheme } from '@material-ui/core'
import { BrowserRouter, Switch } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'

import { DataProvider, useData } from './context/DataContext'
import { UserProvider } from './context/UserContext'
import { dataInitialState, dataReducer } from 'store/reducers/dataReducer'
import { userInitialState, userReducer } from 'store/reducers/userReducer'
import AuthRoutes from 'routes/AuthRoutes'
import './i18n/index.js'
import { mainColor, successGreen } from 'components/common/Colors/CommonColors'
import { TOGGLE_DARK_MODE } from 'store/types'
import { useAction } from 'store/actions'
import { GET_PORTFOLIO, GET_USER_Short_Info } from 'gql/users.query'
import { CustomThemeProvider } from 'ThemeContext'
import RedirectMobileDrawer from 'components/RedirectMobileDrawer/RedirectMobileDrawer'
import { useQuery } from '@apollo/react-hooks'
const useStyles = makeStyles(() => ({
  success: {
    backgroundColor: successGreen,
  },
  error: {
    backgroundColor: '#D2364B',
  },
}))

const App = () => {
  const classes = useStyles()

  return (
    <CustomThemeProvider>
      <DataProvider initialState={dataInitialState} reducer={dataReducer}>
        <UserProvider initialState={userInitialState} reducer={userReducer}>
          <BrowserRouter>
            <CssBaseline />
            <RedirectMobileDrawer />
            <Switch>
              <SnackbarProvider
                classes={{ variantSuccess: classes.success }}
                iconVariant={{
                  success: '',
                }}
              >
                <AuthRoutes />
              </SnackbarProvider>
            </Switch>
          </BrowserRouter>
        </UserProvider>
      </DataProvider>
    </CustomThemeProvider>
  )
}

export default App
