import styled from 'styled-components'

import { Box, Switch } from "@material-ui/core";


export const BoxContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin: 20px 0px;

  @media (max-width: 480px) {
    gap: 20px;
    grid-template-columns: 1fr;
  }
`