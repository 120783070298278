interface Config {
  APIURL: string
  GRAPHQL_URI: string
  WS_GRAPHQL_URI: string
  ENV: string
}

// eslint-disable-next-line import/no-mutable-exports
let config: Config = {
  APIURL: 'https://api.wenroll.com/',
  GRAPHQL_URI: 'https://api.wenroll.com/graphql',
  WS_GRAPHQL_URI: 'wss://api.wenroll.com/graphql',
  ENV: 'production',
}
switch (process.env.REACT_APP_STAGE) {

  case 'local':
    config = {
      APIURL: 'http://localhost:8001',
      GRAPHQL_URI: 'http://localhost:8001/graphql',
      WS_GRAPHQL_URI: 'wss://localhost:8001/graphql',
      ENV: 'testadmin',
    }
    break
  case 'development':
    config = {
      APIURL: 'https://apitest.wenroll.com',
      GRAPHQL_URI: 'https://apitest.wenroll.com/graphql',
      WS_GRAPHQL_URI: 'wss://apitest.wenroll.com/graphql',
      ENV: 'testadmin',
    }
    break
  case 'staging':
    config = {
      APIURL: 'https://apistage.wenroll.com/',
      GRAPHQL_URI: 'https://apistage.wenroll.com/graphql',
      WS_GRAPHQL_URI: 'wss://apistage.wenroll.com/graphql',
      ENV: 'stgadmin',
    }
    break
  case 'production':
    config = {
      APIURL: 'https://api.wenroll.com',
      GRAPHQL_URI: 'https://api.wenroll.com/graphql',
      WS_GRAPHQL_URI: 'wss://api.wenroll.com/graphql',
      ENV: 'admin',
    }
    break
  default:
    config = {
      APIURL: '',
      GRAPHQL_URI: '',
      WS_GRAPHQL_URI: '',
      ENV: ''
    };
    break
}

export default config
