import VideoPlayer from 'components/common/VideoPlayer/Player'
import Icon from 'components/Icon'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { lastWatchedIndex, module } from '../../types'
import Modal from 'components/common/Modal'
import { SIconButton } from '../styled'
import AudioPlayer from 'components/common/AudioPlayer'
import styled from 'styled-components'
import { LastWatchedContext } from '../StudentCourseContext'
import { useTrackCourse } from 'hooks/course/useTrackCourse'

export type LessionTypeIconProps = {
  getModule: any
  refetchDone: boolean
  setRefetchDone: any
  lesson: module.Lesson
  disabled?: boolean
  quiz?: boolean,
  pdfDuration: number
  onVideoProggress: (duration: number, lessonId?: string) => void
  allLesson: module.Lesson[]
  lessonIndex: number
  isLessonDisabled: boolean
  indexes: number[]
  courseId: string
  refetchGetUser: () => any
  userId: string
  moduleId: string
}

type DesiredLessonData = {
  id: string
  url: string
  lessonType: 'audio' | 'video' | 'pdf',
  pdfDuration: number
  progress: number,
  duration: number,
}

function getDesiredPropsFromLesson(inputObject: module.Lesson): DesiredLessonData {
  const outputObject: DesiredLessonData = {} as DesiredLessonData;
  const { duration, progress, audio, video, contentDocument, pdfLessonDuration, id } = inputObject;

  outputObject.duration = duration
  outputObject.progress = progress
  outputObject.pdfDuration = pdfLessonDuration;
  outputObject.id = id;

  if (audio) {
    outputObject.lessonType = 'audio'
    outputObject.url = audio.link
    audio.link ? outputObject.url = audio.link : outputObject.url = ""
  } else if (video) {
    outputObject.lessonType = 'video'
    video.links ? outputObject.url = video.links[0].url : outputObject.url = ""
  } else if (contentDocument) {
    outputObject.lessonType = 'pdf'
    outputObject.url = contentDocument.link
    contentDocument.link ? outputObject.url = contentDocument.link : outputObject.url = ""
  }
  return outputObject
}

const LessionTypeIcon: React.FC<LessionTypeIconProps> = props => {
  const [modalPlayer, setModalPlayer] = useState({ open: false, url: '' })
  const [currentLesson, setCurrentLesson] = useState(() => getDesiredPropsFromLesson(props.lesson));
  const [autoPlay, setAutoPlay] = useState(false)

  const currentLessonFullData: module.Lesson =
    props.allLesson.find((lesson: module.Lesson) => lesson.id === currentLesson.id) || props.lesson;

  const [videoPlayer, setVideoPlayer] = useState<VideoPlayer | null>();
  const audioPlayerRef = useRef<any>(null)
  const { lastWatched, setLastWatched } = useContext(LastWatchedContext)

  const { trackProgress, trackData } = useTrackCourse({ moduleId: props.moduleId, courseId: props.courseId, userId: props.userId });

  // when user clicks outside video | audio and closes a modal during watching or listening a lesson
  const handleLessonResetAndCloseModal = () => {
    setCurrentLesson(getDesiredPropsFromLesson(props.lesson))
    setAutoPlay(false)
    setModalPlayer({ ...modalPlayer, open: false })
  };

  const handleCloseModalAndRefetchModule = () => {
    props.getModule()
    handleLessonResetAndCloseModal()
    setLastWatched((state) => { return { ...state, show: false } })
  }


  const progressTracker = (duration: number) => {
    // console.log("WATCHED DURATION: ", duration)
    // setModalPlayer({ ...modalPlayer, open: false })

    trackProgress({
      variables: {
        courseId: props.courseId,
        lessonId: currentLesson.id,
        duration
      }
    }).then((res) => {

      // console.log("SUCCESSFULLY TRACKED A PROGRESS")
      const currentLessonIndex = props.allLesson.findIndex((item: module.Lesson) => item.id === currentLesson.id);
      const nextLesson = props.allLesson[currentLessonIndex + 1];

      if (nextLesson) {
        // console.log("WE HAVE A NEXT LESSON")
        const nextLsnState = getDesiredPropsFromLesson(nextLesson);
        if (nextLsnState.lessonType === 'pdf') {
          // close the modal
          handleCloseModalAndRefetchModule();
        } else if (nextLsnState.lessonType === "audio" || nextLsnState.lessonType === "video") {
          setCurrentLesson(nextLsnState);
        } else {
          // close the modal
          handleCloseModalAndRefetchModule()
        }
      } else {
        // close the modal
        handleCloseModalAndRefetchModule();
      }
    }).catch(() => {
      console.log("track progress error")
    })
  }

  useEffect(() => {
    // console.log("PREVIOUS LESSON CHECK EFFECT");
    const [topicIndex, lessonIndex] = props.indexes || []
    const { topicIndex: lastTopicIndex, lessonIndex: lastLessonIndex } = lastWatched || {}
    // setFirstClick(false)
    if (lastWatched.show && topicIndex === lastTopicIndex && lessonIndex === lastLessonIndex) {

      if (props.lesson.audio) {
        onAudioIconClick()
      }
      if (props.lesson.video) {
        onVideoIconClick()

      }
      if (props.lesson.contentDocument) {
        setTimeout(() => {
          onPdfIconClick()
          setLastWatched((state) => { return { ...state, show: false } })
        }, 2000)
      }
    }
    // may we need to write empty array here as a dependency
  }, [lastWatched.show])

  useEffect(() => {
    if (currentLesson.id === props.lesson.id && props.lesson.progress > currentLesson.progress) {
      setCurrentLesson((prevState) => {
        return { ...prevState, progress: props.lesson.progress }
      })
    }
  }, [props.lesson.progress])


  const onPdfIconClick = (e?: any) => {
    e?.preventDefault()
    if (!props.lesson?.contentDocument?.link || props.isLessonDisabled) return
    // we do not need currentLesson here, because pdf data will always come from props (continuing doesn't affect on pdf's ordering)
    props.onVideoProggress(props.pdfDuration, props.lesson.id)
    window.open(props.lesson.contentDocument.link, '_blank')
  };

  const onAudioIconClick = (e?: any) => {
    e?.preventDefault()
    if (!props.lesson?.audio?.link || props.isLessonDisabled) return
    setAutoPlay(true)
    setModalPlayer({
      open: true,
      url: props.lesson.audio.link,
    })
  };

  const onVideoIconClick = (e?: any) => {
    e?.preventDefault()
    if (!props.lesson?.video?.links || props.isLessonDisabled) return
    setAutoPlay(true);
    setModalPlayer({
      open: true,
      url: props.lesson.video.links[0].url,
      // type: "video"
    })
  };


  const handleAudioPlayerModalClose = (value: number) => {
    props.onVideoProggress(+value, currentLesson.id)
    handleLessonResetAndCloseModal();
  };

  const handleVideoModalClose = () => {
    if (videoPlayer && videoPlayer.state) {
      const s = Math.ceil(videoPlayer.state.currentTime)
      props.onVideoProggress(s, currentLesson.id)
    }
    handleLessonResetAndCloseModal();
  };

  let lessonProgressIndicator;

  if (props.lesson.progress === 100) {
    lessonProgressIndicator = <Icon filename={"complete"} width={22} height={20} />;
  } else {
    if (props.lesson.audio) {
      lessonProgressIndicator = <Icon filename={"voice"} width={10} height={12} fill={"#317BF4"} />
    } else if (props.lesson.contentDocument) {
      lessonProgressIndicator = <Icon filename={"pdf"} width={10} height={12} fill={'#000000'} />
    } else if (props.lesson.video) {
      lessonProgressIndicator = <Icon filename={"play"} width={7} height={8} fill={"#317BF4"} />
    }
  }

  if (currentLesson.lessonType === 'audio') {
    // const redirectTime = getDuration(props.lesson.progress, props.lesson.duration)
    const redirectTime = getDuration(currentLesson.progress, currentLesson.duration)

    return (
      <>
        <IconWrapper onClick={onAudioIconClick}>
          {lessonProgressIndicator}
        </IconWrapper>

        {modalPlayer.open ? (
          <AudioPlayer
            autoPlay={autoPlay}
            refetchGetUser={props.refetchGetUser}
            isModalOpened={modalPlayer.open}
            onModalClose={(e: any) => {
              handleAudioPlayerModalClose(e)
              setLastWatched((state) => { return { ...state, show: false } })
            }}
            lessonProgress={redirectTime}
            url={currentLesson.url}
            needsDrawer
            withModal
            lesson={currentLessonFullData}
            model="LESSON"
            courseId={props.courseId}
            onNextLessonSwitch={progressTracker}
          />
        ) : ""}
      </>
    )
  }

  if (currentLesson.lessonType === 'pdf') {
    return (
      <IconWrapper onClick={onPdfIconClick}>
        {lessonProgressIndicator}
      </IconWrapper>
    )
  }

  if ((currentLesson.lessonType === "video")) {
    // const redirectTime = getDuration(props.lesson.progress, props.lesson.duration)
    const redirectTime = getDuration(currentLesson.progress, currentLesson.duration)
    // console.log('REDIRECTTIME', redirectTime)
    return (
      <>
        <IconWrapper onClick={onVideoIconClick}>
          {lessonProgressIndicator}
        </IconWrapper>

        {
          modalPlayer.open ? (
            <Modal isOpened={modalPlayer.open} onClose={() => {
              handleVideoModalClose()
              setLastWatched((state) => { return { ...state, show: false } })
            }
            }>
              <VideoPlayer
                allLesson={props.allLesson}
                autoPlay={autoPlay}
                url={currentLesson.url}
                lessonProgress={redirectTime}
                model="LESSON"
                lesson={currentLessonFullData}
                courseId={props.courseId}
                refetchGetUser={props.refetchGetUser}
                ref={player => setVideoPlayer(player)}
                onNextLessonSwitch={progressTracker}
              />
            </Modal>
          ) : ""
        }
      </>
    )
  }

  if (props.quiz)
    return (
      <>
        <SIconButton
          disabled={props.isLessonDisabled}
          onClick={e => {
            e.preventDefault()

          }}
        >
          <Icon filename={'topic_quiz'} width={7} height={8} />
        </SIconButton>
      </>
    )
  return <></>
}

function getDuration(progress: number, duration: number) {
  let latestTime;
  if (progress === 100) {
    return 0;
  }
  if (progress && duration) {
    latestTime = duration * (progress / 100)
  }
  return latestTime
}
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export default LessionTypeIcon
