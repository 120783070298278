import { useQuery } from '@apollo/react-hooks'

import { GET_MODULES } from 'gql/modules.query'
import { GET_FLASHCARD, GET_FLASHCARDS } from 'gql/flashcards.query'

export const PER_PAGE = 12

export const useFetchModules = (
  filter: any,
  currentPage: number = 1,
  perPage: number = PER_PAGE,
  fetchAll?: boolean
) => {
  const { data, loading, error, refetch } = useQuery(GET_MODULES, {
    variables: { filter, currentPage, perPage, fetchAll },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    modules: data && data.allModules,
    loading,
    error: errorMessage,
    refetch,
  }
}

export const useFetchFlashCard = (id: string) => {
  const { data, loading, error, refetch } = useQuery(GET_FLASHCARD, {
    variables: { id },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    flashCard: data && data.flashCard,
    loading,
    error: errorMessage,
    refetch,
  }
}

export const useFetchFlashCards = (
  moduleId: string,
  skip: number = 0,
  limit: number = 0,
) => {
  const { data, loading, error, refetch } = useQuery(GET_FLASHCARDS, {
    variables: { moduleId, skip, limit },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    flashCards: data && data.flashCards,
    loading,
    error: errorMessage,
    refetch,
  }
}
