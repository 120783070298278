import { updateProfileAvatarService } from "services/companies/editCompanyAvatarService"
import { useTranslation } from "react-i18next"
import useSnackbarAlert from "hooks/useSnackbar"
import { snackbarProps } from "interfaces/snackbar"

export const useUpdateCompanyAvatar = () => {
    const { t } = useTranslation()
    const { updateCompanyAvatar } = updateProfileAvatarService()
    const { setSnackbar } = useSnackbarAlert()

    const updateAvatar = async (
      id: string,
      action: string,
      avatar: any,
      crudFunction: any,
    ) => {

      let { message, variant }: snackbarProps = {
        message: '',
        variant: undefined,
      }

      let hasResponseReceived = false
  
      const { errors } = await updateCompanyAvatar({
        variables: {
          id,
          action,
          avatar,
        },
      })
  
      if (errors) {
        hasResponseReceived = true;
        message = errors[0].message
        variant = 'error'
      } else {
        hasResponseReceived = true;
        if (action === 'ADD') {
          message = t('messages.changes_saved')
        } else {

          message = `${t('general.avatar')} ${t('messages.successfully')} ${t(
            'general.deleted',
          )}`
        }
        
        variant = 'success'
      }
  
      setSnackbar({ message, variant })

  
      crudFunction(hasResponseReceived)
    }


    return {
      updateAvatar,
      hasResponseReceived: false
    }
  }
  