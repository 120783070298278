import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'
import update from 'immutability-helper'

import { Toolbar, ToolbarActions } from './styled-components'
import { Button } from 'components/common/Button'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import { DrawerEventEmitter } from 'helpers/drawer'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import DeleteIcon from '@material-ui/icons/Delete'
import Modal from 'components/common/Modal'
import { filterTopics } from 'pages/topics/FilterDrawer'
import { useUserValue } from 'context/UserContext'

import IconButton from '@material-ui/core/IconButton'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import SyncIcon from '@material-ui/icons/Sync'
import FilterListIcon from '@material-ui/icons/FilterList'

import { useSwal } from 'hooks/useSwal'
import { useDeleteTopic } from 'hooks/topics/useDeleteTopic'
import { useChangeTopicsOrder } from 'hooks/topics/useEditTopic'
import Player from 'components/common/VideoPlayer/Player'
import TextInput from 'components/common/TextInput/TextInput'
import {
  ActionButton,
  GridLayoutActions,
  GridLayoutForm,
  GridToolbar,
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import AudioPlayer from 'components/common/AudioPlayer'

import {
  columnConfig,
  nestedColumConfig,
  actionConfig,
  nestedActions,
} from './gridConfig'

import NestedGrid from './NestedGrid'
import Drawer from 'components/common/Drawer'
import { FilterDrawer } from 'pages/topics'

import { useEditTopic } from 'hooks/topics/useEditTopic'
import useDeleteLessonService from 'hooks/lessons/useDeleteLessonService'
import { mainColor } from 'components/common/Colors/CommonColors'
import Icon from 'components/Icon'
import styled from 'styled-components'
import Grid from 'components/common/Grid'
import { useTheme } from '@material-ui/core'

const filterOptions = {
  name: '',
  numberOfLessons: [0, 0],
  duration: [0, 0],
  attachment: '',
  lessons: [],
}

const ModuleTopicsGrid = ({
  moduleId,
  data,
  refetch,
  loadingTopics,
  canModify,
  t,
}: any) => {
  const modalData: any = {
    modal: false,
    url: '',
    field: '',
    subtitle: null,
  }
  const { fireSwal } = useSwal()
  const history = useHistory()
  const [mediaModal, setMediaModal] = useState(modalData)
  const [state] = useUserValue()

  const { deleteTopic } = useDeleteTopic({ moduleId, filter: {} })
  const [searchValue, setSearchValue] = useState<string>('')
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filtered, setFiltered] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<any>(filterOptions)
  const [filteredData, setFilteredData] = useState<any>(null)
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const { deleteLesson } = useDeleteLessonService({
    moduleId,
  })
  const { changeTopicsOrder, changeTopicsOrderLoading } = useChangeTopicsOrder({
    moduleId,
    filter: {},
  })

  const { editTopic, newTopic, editTopicLoading } = useEditTopic({
    topicId: '',
  })
  // const topicList = data.filter((item: any) =>
  //   item.name.toLowerCase().includes(searchValue.toLowerCase()),
  // )

  const [cards, setCards] = useState([])
  const moveCard = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      // console.log('dragCard', dragCard)

      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )

  const selectItem = (id: never, event: boolean) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    setSelectedItem(newSelected)
  }

  const selectAllItem = () => {
    if (!selectAll) {
      const newArr: string[] = data.map((n: any) => n.id)
      setSelectedItem(newArr)
      setSelectAll(true)
      return
    }
    setSelectedItem([])
    setSelectAll(false)
  }

  const getTopicList = () => data?.map((i: any) => ({ name: i.name, id: i.id }))

  const handleDrawerOpen = (field: string) => {
    const loadTopicList: any = {}

    if (field === 'addLesson') {
      loadTopicList.topicList = getTopicList()
    }

    DrawerEventEmitter.emit('openDrawer', field, true, {
      id: moduleId,
      data: { id: '', ...loadTopicList, refetch: () => refetch() },
    })
  }

  const handleAddLessonClick = (e: any) => {
    DrawerEventEmitter.emit('openDrawer', 'addLesson', true, {
      id: moduleId,
      data: { name: e.name, id: e.id, refetch: () => refetch() },
    })
  }

  const confirmDelete = (ids: string[]) => {
    deleteTopic(moduleId, ids, refetch)
    setSelectedItem([])

    // refetchTopics({ moduleId, filter: {} })
  }

  const navigateToTopicDetails = (id: string) => {
    history.push(`/topics/${id}`)
  }

  const handleEditClick = (id: string) => {

    // DrawerEventEmitter.emit('openDrawer', 'editTopic', true, {
    //   id,
    // })
  }

  const handleDeleteClick = (ids: string[]) => {
    const isMultiple = ids.length > 1 ? true : false
    const mainText: string = isMultiple
      ? t('popups.delete_topic_many')
      : t('popups.delete_topic_single')
    fireSwal({
      title: `${mainText}`,
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => confirmDelete(ids),
    })
  }

  const openAttachment = (attachment: string) => {
    window.open(attachment, '_blank')
  }

  const handleLessonDelete = (lessonId: string) => {
    const params = {
      title: t('popups.delete_lesson_single'),
      onConfirm: () => deleteLesson([lessonId], () => refetch()),
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const onTopicEdit = (topicId: string, name: string, callBack: any) => {
    editTopic(topicId, { name }, callBack)
  }

  const config = columnConfig(
    state.userPermission,
    selectItem,
    navigateToTopicDetails,
    onTopicEdit,
    canModify,
    t,
    () => { }
  )
  const actions = actionConfig(
    handleAddLessonClick,
    navigateToTopicDetails,
    handleEditClick,
    handleDeleteClick,
    openAttachment,
    state.userPermission,
    canModify,
    t,
  )

  const handleOpenMediaModal = (data?: any, field?: string) => {
    if (mediaModal.modal) {
      setMediaModal(modalData)
      return
    }
    setMediaModal({
      modal: true,
      url: field === 'playAudio' ? data.audio.link : data.video.links[0].url,
      subtitle: field === 'playAudio' ? null : data.subtitle,
      field,
    })
  }

  const filter = (filterOptions: any) => {
    const filtered = filterTopics(filterOptions, cards)
    setFilteredData(filtered)
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    const filterOptions = {
      name: searchValue,
    }
    filter(filterOptions)
  }

  const handleSearchChange = (e: any) => {
    setSearchValue(e.target.value)
    if (e.target.value === '') setFilteredData(null)
  }

  const handleResetClick = () => {
    setFilteredData(null)
    setFiltered(false)
    setSearchValue('')
    setFilterData(filterOptions)
  }

  const nestedEvents = (val: any, field: string) => {
    switch (field) {
      case 'playVideo':
        return handleOpenMediaModal(val, field)
      case 'playAudio':
        return handleOpenMediaModal(val, field)
      case 'view':
        return history.push(`/lessons/${val.id}`)
      case 'edit':
        return DrawerEventEmitter.emit('openDrawer', 'editLesson', true, {
          id: moduleId,
          data: { id: val.id },
        })
      case 'delete':
        return handleLessonDelete(val)
      default:
        return () => console.log('1')
    }
  }

  const nestedConfig = nestedColumConfig(nestedEvents, t)
  const nestedAction = nestedActions(
    nestedEvents,
    state.userPermission,
    canModify,
    t,

  )

  useEffect(() => {
    if (data) {
      const topicList = data.filter((item: any) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase()),
      )
      setCards(topicList)
    }
  }, [data, searchValue])


  const theme = useTheme()

  return (
    <div>
      <Drawer opened={filterDrawerOpened} toggleDrawer={() => { }} width="600px">
        <FilterDrawer
          closeDrawer={() => setFilterDrawerOpened(false)}
          filterData={filterData}
          setFilterData={setFilterData}
          filter={filter}
          setFiltered={setFiltered}
          t={t}
        />
      </Drawer>
      {/* <Toolbar>
        <ToolbarActions>
          {state.userPermission.createLesson && canModify && (
            <Button
              text={t('lessons_layout.create_lesson')}
              size="small"
              color="secondary"
              onClick={() => handleDrawerOpen('addLesson')}
              icon={<AddCircleRoundedIcon />}
              background={mainColor}
            />
          )}
          {canModify && (
            <GridMultipleActions
              selectedItems={selectedItem.length}
              actions={[
                {
                  id: 0,
                  color: 'secondary',
                  hide: !state.userPermission.deleteTopic,
                  tooltipText: `${t('actions.delete')}`,
                  disabled: selectedItem.length < 1,
                  onClick: () => handleDeleteClick(selectedItem),
                  component: <DeleteIcon fontSize="small" />,
                },
              ].filter((i: any) => !i.hide)}
            />
          )}
        </ToolbarActions>
        <div style={{ display: 'flex' }}>
          <MultipleAction>
            <IconButton
              disabled={filtered ? false : true}
              onClick={handleResetClick}
            >
              <SyncIcon fontSize="small" />
            </IconButton>
            <MultipleActionTooltip>{t('actions.reset')}</MultipleActionTooltip>
          </MultipleAction>
          <MultipleAction>
            <IconButton onClick={() => setFilterDrawerOpened(true)}>
              <FilterListIcon fontSize="small" style={{ color: mainColor }} />
            </IconButton>
            <MultipleActionTooltip>
              {t('actions.filters')}
            </MultipleActionTooltip>
          </MultipleAction>
          <form onSubmit={handleSearchSubmit} style={{ marginLeft: 10 }}>
            <TextInput
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              value={searchValue}
              onChange={handleSearchChange}
              icon={
                <IconButton type="submit">
                  <SearchRoundedIcon />
                </IconButton>
              }
            />
          </form>
        </div>
      </Toolbar> */}
      <GridToolbar theme={theme}>
        <GridLayoutActions>
          <div>
            {state.userPermission.createLesson && canModify && (
              <AddButton onClick={() => handleDrawerOpen('addLesson')}>
                <Icon filename={'add'} fill={'#ffffff'} />
                {t('lessons_layout.create_lesson')}
              </AddButton>
            )}
          </div>
        </GridLayoutActions>
        <GridLayoutForm>
          {`${selectedItem.length} ${t('general.items_selected')}`}
          {state.userPermission.createLesson && canModify && (
            <ActionButton
              onClick={() => handleDeleteClick(selectedItem)}
              disabled={selectedItem.length === 0}
              fill={'#CC3131'}
              disabledFill={'#080A0AD6'}
              theme={theme}
            >
              <Icon filename={'delete'} />
            </ActionButton>
          )}
        </GridLayoutForm>
      </GridToolbar>
      <NestedGrid
        title={t('topics_layout.title')}
        config={config}
        navigateToTopicDetails={navigateToTopicDetails}
        data={filteredData ? filteredData : cards}
        actions={actions}
        nestedAction={canModify ? nestedAction : []}
        nestedConfig={nestedConfig}

        selectAll={selectAllItem}
        // selectAllItem={selectAllItem}
        selectedItems={selectedItem}
        loading={loadingTopics}
        changeTopicsOrder={changeTopicsOrder}
        moveCard={moveCard}
        canModify={canModify}
        t={t}

      />
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={videoModal.modal}
        onClose={playVideo}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <VideoPlayer url={videoModal.data} />
      </Modal> */}
      <Modal isOpened={mediaModal.modal} onClose={handleOpenMediaModal}>
        <div>
          {mediaModal.field === 'playAudio' && (
            <AudioPlayer url={mediaModal.url} />
          )}
          {mediaModal.field === 'playVideo' && (
            <Player url={mediaModal.url} subtitle={mediaModal.subtitle} />
          )}
        </div>
      </Modal>
    </div>
  )
}

export default ModuleTopicsGrid

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #317bf4;
  padding: 18px 30px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    background: #3165f4;
  }
`
