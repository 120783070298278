import { useMutation } from "@apollo/react-hooks"
import { updateCache } from "services/updateCache"

import {
  UPDATE_PROFILE_AVATAR,
  EDIT_USER_PROFILE,
  GET_CURRENT_USER,
  CHANGE_PASSWORD,
  UPDATE_USER_PASSWORD_BY_ID
} from "gql/users.query"
import { useTranslation } from "react-i18next"
import useSnackbarAlert from "hooks/useSnackbar"
import { snackbarProps } from "interfaces/snackbar"
import { useState } from "react"

export const updateProfileAvatarService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [updateProfileAvatar] = useMutation(UPDATE_PROFILE_AVATAR, {
    update(cache, { data: { updateProfileAvatar: document } }) {
      updateCache({
        objectName: "currentUser",
        query: GET_CURRENT_USER,
        cache,
        document,
        action: "update",
      })
    }
  })

  return {
    updateProfileAvatar
  }
}

export const editProfileService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editUserProfile] = useMutation(EDIT_USER_PROFILE, {
    update(cache, { data: { editUserProfile: document } }) {
      updateCache({
        objectName: "currentUser",
        query: GET_CURRENT_USER,
        cache,
        document,
        action: "update",
        params,
      })
    }
  })
  return {
    editUserProfile
  }
}

export const changePasswordService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    update(cache, { data: { changePassword: document } }) {
      updateCache({
        objectName: 'currentUser',
        query: GET_CURRENT_USER,
        cache,
        document,
        action: 'update',
      })
    }
  })

  return {
    changePassword
  }
}

const updateUserPasswordService =   () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [updatePassword] = useMutation(UPDATE_USER_PASSWORD_BY_ID)

  return {
    updatePassword
  }
}

export const useUpdateUserPasswordById = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)
  const { updatePassword } = updateUserPasswordService()

  const updateUserPassword = async (
   id: string,
   password: string
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors } = await updatePassword({
      variables: { id, password},
    })

    if (errors && errors.length) {
      setLoading(false)
      message = t('messages.something_wrong')
      variant = 'error'
      return
    }

    message = `${t("reset_password.success")}`
    variant = 'success'

    setSnackbar({ message, variant })
    setLoading(false)

  }

  return {
    loading,
    updateUserPassword,
  }
}