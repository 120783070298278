import { useState } from 'react'
import deleteModuleService from 'services/modules/deleteModuleService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

export const useDeleteModuleService = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [deleted, setDeleted] = useState([])
  const [error, setError] = useState('')
  const { deleteModuleMutation, loading } = deleteModuleService()

  const deleteModule = async (ids: string[]) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }
    const { data, errors } = await deleteModuleMutation({
      variables: {
        ids,
      },
    })

    if (errors && errors.length) {
      // message = errors[0].message
      // if (message === 'User already registered') {
      //   setError(message)
      //   return false
      // }
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      setDeleted(data.deleteModule)
      message = `${t('general.module')} ${t('messages.deleted')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteModule,
    addModuleLoading: loading,
    deletedModule: deleted,
    error,
  }
}
