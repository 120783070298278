import React, { useState, useRef, useEffect } from 'react'

import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import _ from 'lodash'

import { useLazyQuery } from '@apollo/react-hooks'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import Slider from '@material-ui/core/Slider'

import SelectAsync from 'components/common/Select/SelectAsync'
import ReactSelect from 'react-select'
import Typography from '@material-ui/core/Typography'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'

import {
  FormContainer,
  FormGroupWrapper,
  FormGroup,
  FormFooter,
  FormButtons,
  FormGroupItem,
} from 'components/common/Form'

import { existData, filterOption } from 'pages/modules/staticData'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import { useTheme } from '@material-ui/core'

const FilterDrawer = (props: any) => {
  const ref = useRef<any>(null)
  const [range, setRange] = useState([0, 500])
  const [filterData, setFilterData] = useState<any>(filterOption)

  const [loadData, { called, loading, data }] = useLazyQuery(GET_ALL_COURSE)

  useEffect(() => {
    if (!_.isEmpty(props.filterData)) {
      const { range, ...args } = props.filterData

      setFilterData(props.filterData)

      if (range) setRange(range)
    }
  }, [])

  const handleFieldChange = (field: string, action?: string) => {
    if (action && action === 'dropdown') {
      return (e: any) => {
        setFilterData({
          ...filterData,
          [field]: e,
        })
      }
    }

    return (e: any, val?: any) => {
      setFilterData({
        ...filterData,
        [field]: field === 'range' ? val : e.target.value,
      })
    }
  }

  const loadDataOptions = (e: any) =>
    setTimeout(() => {
      const value = e
      loadData({
        variables: {
          filter: {
            name: {
              type: 'match',
              value,
            },
            originalId: {
              type: 'exact',
              value: null,
            },
          },
        },
      })
    }, 300)

  const handleSelectChange = (e: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e)
    }
  }

  const onFormSubmit = (e: any) => {
    e.preventDefault()
    const query: any = {}

    const { name, courses, ...args } = filterData

    for (const key in args) {
      if (args[key].value && args[key].value !== 'all') {
        query[key] = args[key].value === 'no' ? null : { $ne: null }
      }
    }

    if (range[0] > 0 || range[1] < 500) {
      query.duration = { $gte: range[0] * 60, $lte: range[1] * 60 }
    }

    if (courses && courses.length > 0) {
      query['courses.courseId'] = { $in: courses.map((i: any) => i.value) }
    }

    props.setFilterQuery(query)
    props.refetchData(
      props.companyId
        ? {
          filter: {
            query: {
              type: 'query',
              value: JSON.stringify(query),
            },
            companyId: {
              type: 'exact',
              value: props.companyId,
            },
            course: {
              type: props.type || 'nestedArrayIn',
              value: props.courseId || null,
            },
            name: {
              type: 'search',
              value: filterData.name,
              fields: ["name"]
            },
          },
          currentPage: 1,
          perPage: props.perPage,
        }
        : {
          filter: {
            course: {
              type: props.type || 'nestedArrayIn',
              value: props.courseId || null,
            },
            name: {
              type: 'search',
              value: filterData.name,
              fields: ["name"]
            },
            query: {
              type: 'query',
              value: JSON.stringify(query),
            },
          },
          currentPage: 1,
          perPage: props.perPage,
        },
    )

    filterData.range = range

    props.setFilterData(filterData)
    props.setFiltered(true)
    props.onClose()
  }

  const { t } = props

  const theme = useTheme();

  return (
    <>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>
          {t('general.advanced_filters')} - {t('actions.to_filter')}{' '}
          {t('general.modules')}
        </DrawerTitle>
        <IconButton onClick={props.onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>

      <DrawerContent>
        <FormContainer onSubmit={onFormSubmit}>
          <FormGroupWrapper>
            <FormGroup>
              <FormGroupItem>
                <Input
                  label={t('form_fields.name')}
                  name="name"
                  size="medium"
                  type="text"
                  fullWidth
                  value={filterData.name}
                  onChange={handleFieldChange('name')}
                />
              </FormGroupItem>

              {!props.courseId && (
                <FormGroupItem>
                  <SelectAsync
                    isMulti
                    onInputChange={handleSelectChange}
                    onChange={handleFieldChange('courses', 'dropdown')}
                    data={data?.getAllCourse?.data || []}
                    value={filterData.courses || []}
                    label={t('general.course')}
                    loading={loading}
                  />
                </FormGroupItem>
              )}

              <FormGroupItem>
                <FormItemContainer>
                  <Label>{t('general.attachment')}</Label>
                  <ReactSelect
                    onChange={handleFieldChange('attachment', 'dropdown')}
                    options={existData}
                    value={filterData.attachment}
                  />
                </FormItemContainer>
              </FormGroupItem>

              <FormGroupItem>
                <Typography id="range-slider" gutterBottom>
                  {t('general.duration')}
                </Typography>
                <Slider
                  value={range}
                  onChange={(e: any, i: any) => setRange(i)}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  min={0}
                  max={500}
                // getAriaValueText={valuetext}
                />
              </FormGroupItem>
            </FormGroup>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={props.resetFilter}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.filter')}
                type="medium"
                color="secondary"
                btnType="submit"
                background={mainColor}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterDrawer

const Container = styled.div`
  box-sizing: border-box;
  padding: 15px;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 70px;
  padding-top: 100px;
  position: relative;
`

const FormHeader = styled.header`
  padding: 10px 20px;
  width: 100%;
  background: rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`

const FormTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`

const FormItemContainer = styled.div`
  position: relative;
`

const Label = styled.div`
  position: absolute;
  background: #fff;
  color: rgba(0, 0, 0, 0.54);
  z-index: 1;
  line-height: 1;
  top: -7px;
  font-size: 13px;
  left: 10px;
`
