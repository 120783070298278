import roleObj from './rolesObject'

export const vPermission = ['STUDENT', 'COACH']

const roles: any = {
  super_admin: 'SUPER_ADMIN',
  moderator: 'MODERATOR',
  admin: 'ADMIN',
}

const permissions: any = {
  //company
  createCompany: 'Create company',
  editCompany: 'Edit company',
  deleteCompany: 'Delete company',
  viewCompanyPage: 'View company page',
  switchToCompanyMode: 'Switch to company mode',

  //superAdmin
  createSuperAdmin: 'Create Super Admin',
  editSuperAdmin: 'Edit Super Admin',
  deleteSuperAdmin: 'Delete Super Admin',
  viewSuperAdminPage: 'View Super Admin page',
  suspendSuperAdmin: 'Suspend Super Admin',
  activateSuperAdmin: 'Activate Super Admin',
  resendUserPasswordSuperAdmin: 'Resend Super Admin Password',
  resetPassword: 'Reset Password', //this is for admins and super admins

  //group
  createGroup: 'Create group',
  editGroup: 'Edit group',
  deleteGroup: 'Delete group',
  viewGroup: 'View group',

  //admins
  createAdmin: 'Create admin',
  editAdmin: 'Edit admin',
  deleteAdmin: 'Delete admin',
  viewAdmin: 'View admin',
  activateAdmin: 'Activate admin',
  suspendAdmin: 'Suspend admin',
  resendUserPasswordAdmin: 'Resend Admin Password',

  //coach
  viewCoach: 'View coach',
  createCoach: 'Create coach',
  editCoach: 'Edit coach',
  deleteCoach: 'Delete coach',
  resendUserPasswordCoach: 'Resend Coach Password',
  activateCoach: 'Activate coach',
  suspendCoach: 'Suspend coach',

  //course
  createCourse: 'Create course',
  editCourse: 'Edit course',
  deleteCourse: 'Delete course',
  viewCourse: 'View course',
  publishCourse: 'Publish course',
  unPublishCourse: 'Un publish course',

  //module
  createModule: 'Create module',
  editModule: 'Edit module',
  deleteModule: 'Delete module',
  viewModule: 'View module',

  //student
  createStudent: 'Create student',
  editStudent: 'Edit student',
  deleteStudent: 'Delete student',
  viewStudent: 'View student',
  resendUserPasswordStudent: 'Resend Student Password',
  activateStudent: 'Activate student',
  suspendStudent: 'Suspend student',

  //topic
  createTopic: 'Create topic',
  editTopic: 'Edit topic',
  deleteTopic: 'Delete topic',
  viewTopic: 'View topic',

  //lesson
  createLesson: 'Create lesson',
  editLesson: 'Edit lesson',
  deleteLesson: 'Delete lesson',
  viewLesson: 'View lesson',

  viewQuestion: 'View question',
  viewQuiz: 'View quiz',
  editQuiz: 'Edit quiz',

  viewSettingPage: 'View settings page',
  viewDashboardPage: 'View dashboard page',

  //categories
  createNewCategory: 'Create New Category',

  //course section
  createNewSection: 'Create New Course Section'
}

const checkPermission = (permission: string, user: any, mode: string) => {
  const { role } = user

  if ([roles.super_admin, roles.admin].includes(role) && !user.companyId) {
    return true
  }

  const obj = roleObj(user)

  const filtered = obj.filter((i: any) => i.role === role)

  const currPermissions: any =
    filtered.length > 0 ? filtered[0].permissions : []

  const result = currPermissions.filter((i: any) => i.name === permission)

  if (result.length <= 0) {
    return false
  }

  return result[0][mode].can
}

export const setUserPermission = (user: any, mode: string) => {
  const response: any = {}

  for (const key in permissions) {
    response[key] = checkPermission(permissions[key], user, mode)
  }
  return response
}
