import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Modal from '@material-ui/core/Modal'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from 'components/common/Button'
import Checkbox from 'components/common/Checkbox'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import Drawer from 'components/common/Drawer'
import Uploader from 'components/common/FileUploader/Uploader'
import { SSwitch } from 'pages/companies/CompanyDetails/CompanyServiceToggle'
import UploaderButton from 'components/common/FileUploader/UploaderButton'
import {
  FormButtons,
  FormFooter,
  FormGroup,
  FormGroupItem,
  FormGroupWrapper,
} from 'components/common/Form'
import Select from 'components/common/Select'
import { AsyncSelect, SelectField } from 'components/common/SelectField'
import SelectCategories from 'components/common/SelectField/SelectCategories'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import { Input } from 'components/common/TextInput'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import 'cropperjs/dist/cropper.css'
import { GET_ALL_SKILLS } from 'gql/skilss.query'
import { GET_USERS } from 'gql/users.query'
import {
  useExtractTextService,
  useUploadFile,
} from 'hooks/helpers/useHelpersService'
import { AddCoach } from 'pages/coaches'
import { courseLevels, currencyList } from 'pages/courses/staticData'
import React, { useEffect, useRef, useState } from 'react'
import Cropper from 'react-cropper'
import CreatableSelect from 'react-select/creatable'
import { SET_CREATED_USER } from 'store/types'
import styled from 'styled-components'

const useStyles = makeStyles(theme =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    fab: {
      margin: theme.spacing(2),
    },
  }),
)

const cropper: any = React.createRef()

const Step1 = (props: any) => {
  const [state] = useUserValue()
  const ref: any = useRef()
  const [dataState, dispatch] = useData()
  const classes = useStyles()
  const [file, setFile] = useState<any>('')
  const [imageType, setImageType] = useState('')
  const [cropperOpen, setCropperOpen] = useState(false)
  const [drawer, setDrawer] = useState({
    addCoach: false,
    data: {},
  })
  const [fetchCoach, { data: coachData, loading: coachLoading }] = useLazyQuery(
    GET_USERS,
  )
  const { extractText, loading: extractTextLoading } = useExtractTextService()
  const { uploadFile, fileLoading } = useUploadFile()

  useEffect(() => {
    if (
      dataState.createdObject.obj &&
      dataState.createdObject.type === 'coach'
    ) {
      const coach = props.values.coaches || []
      props.setFieldValue('coaches', [
        ...coach,
        {
          value: dataState.createdObject.obj.id,
          label: generateFullName(dataState.createdObject.obj),
        },
      ])

      dispatch({
        type: SET_CREATED_USER,
        payload: { obj: null, type: '' },
      })
    }
  }, [dataState.createdObject.obj])

  const { role } = state.currentUser

  const rolePermission =
    (role === 'SUPER_ADMIN' || role === 'MODERATOR') && !state.selectedCompanyId

  useEffect(() => {
    if (rolePermission) {
      props.setFieldValue('coursePrivacy', 'public')
    }
  }, [])

  // useEffect(() => {
  //   if (state.selectedCompanyId) {
  //     props.setFieldValue('coaches', null)
  //     const query: any = {}

  //     if (state.selectedCompanyId && props.values.coachType) {
  //       if (props.values.coachType === 'external') {
  //         query.isQualified = {
  //           type: 'bool',
  //           value: 'true',
  //         }
  //       }

  //       fetchCoach({
  //         variables: {
  //           role: 'COACH',
  //           companyId:
  //             props.values.coachType !== 'external'
  //               ? state.selectedCompanyId
  //               : null,
  //           filter: { ...query },
  //         },
  //       })
  //     }
  //   }
  // }, [props.values.coachType])

  const { data: skillsData } = useQuery(GET_ALL_SKILLS)

  const cropperModalToggle = () => {
    setCropperOpen(!cropperOpen)
  }

  const finishCrop = () => {
    let cropdFile = null
    cropdFile = cropper.current.getCroppedCanvas().toDataURL()

    props.handleImageChange(
      cropdFile,
      imageType,
      file.match(/[^:/]\w+(?=;|,)/)[0],
    )
    setFile('')
    setCropperOpen(false)
  }

  const handleImageChange = (e: any, field: string) => {
    const { files } = e.target
    const reader: any = new FileReader()
    reader.onload = () => {
      const img: any = new Image()
      img.src = reader.result
      setFile(reader.result)
      if (field === 'certificate') {
        props.handleImageChange(
          reader.result,
          'certificate',
          reader.result.match(/[^:/]\w+(?=;|,)/)[0],
        )
      }
    }
    reader.readAsDataURL(files[0])
    if (field !== 'certificate') {
      setCropperOpen(true)
    }
    setImageType(field)
  }

  const deleteImage = (field: string) => {
    props.handleImageChange(null, field)
  }

  const skillList =
    skillsData &&
    skillsData.getAllSkills.map((i: any) => ({
      label: i.label,
      value: i.label,
    }))

  const generateFullName = (e: any) => {
    if (!e.firstName && !e.lastName) return e.email
    return `${e.firstName} ${e.lastName}`
  }

  const handleDrawerOpen = (event?: string) => {
    if (!event) {
      return setDrawer({ addCoach: false, data: {} })
    }
    setDrawer({
      ...drawer,
      addCoach: true,
      data: {
        onSuccess: () => { },
        companyId: props?.company?.companyId || null,
        fromCourse: true,
      },
    })
  }

  const handleExtractText = (e: any) => {
    if (!e || !e.target) return
    const { files } = e.target
    const file = files[0]
    uploadFile(file, `uploads/extract`, (link: string) => {
      extractText(link, (text: string) =>
        props.setFieldValue('description', text),
      )
    })
  }

  const loadDataOptions = (e: any) =>
    setTimeout(() => {
      const value = e
      fetchCoach({
        variables: {
          role: 'COACH',
          filter: {
            search: {
              type: 'search',
              value,
              fields: ['firstName', 'lastName'],
            },
            companyId: {
              type: 'exact',
              value: state.selectedCompanyId,
            },
          },
        },
      })
    }, 200)

  const handleSelectChange = (e: string) => {
    clearTimeout(ref.current)
    if (e) {
      ref.current = loadDataOptions(e)
    }
  }

  const { t } = props

  return (
    <>
      <Container>
        <FormGroupWrapper padding={10}>
          <FormGroup>
            <FormGroupItem>
              <Input
                error={!!props.errors.name}
                errorMessage={props.errors.name ? props.errors.name : ''}
                touched={props.touched.name}
                label={`${t('form_fields.name')}*`}
                name="name"
                size="medium"
                type="text"
                fullWidth
                value={props.values.name}
                onChange={props.handleChange}
              />
            </FormGroupItem>
            <FormGroupItem>
              <Input
                error={!!props.errors.description}
                errorMessage={
                  props.errors.description ? props.errors.description : ''
                }
                touched={props.touched.description}
                label={t('form_fields.course_overview')}
                name="description"
                size="medium"
                type="text"
                fullWidth
                multiline
                rows="4"
                value={props.values.description}
                onChange={props.handleChange}
                component={
                  <UploaderButton
                    onChange={handleExtractText}
                    label={t('general.upload')}
                    loading={fileLoading === 'extract' || extractTextLoading}
                    id="description"
                  />
                }
              />
            </FormGroupItem>
            <FormGroupItem>
              <SelectCategories
                setFieldValue={props.setFieldValue}
                categories={props.values.categories}
                error={!!props.errors.categories}
                errorMessage={props.errors.categories || ''}
                touched={props.touched.categories}
              />
            </FormGroupItem>
            <FormGroupItem>
              <SelectField
                error={!!props.errors.level}
                errorMessage={props.errors.level ? props.errors.level : ''}
                touched={props.touched.level}
                placeholder={t('course_details.level')}
                options={courseLevels}
                value={props.values.level}
                onChange={(e: any) => props.setFieldValue('level', e)}
                isClearable
              />
            </FormGroupItem>

            <FormGroupItem
              style={{ display: 'flex', gap: '15px', alignItems: 'center' }}
            >
              <div style={{ width: '80%' }}>
                <AsyncSelect
                  isMulti
                  onInputChange={handleSelectChange}
                  onChange={(e: any) => props.setFieldValue('coaches', e)}
                  data={(coachData && coachData.usersByRole.data) || []}
                  value={props.values.coaches}
                  label={t('form_fields.type_to_search_coaches')}
                  loading={coachLoading}
                  labelDataName={generateFullName}
                  valueDataKey="id"
                />
              </div>

              <CreateCoacheContainer>
                <Button
                  text={t('actions.create')}
                  type="medium"
                  color="primary"
                  background={mainColor}
                  onClick={() => handleDrawerOpen('addCoach')}
                />
              </CreateCoacheContainer>
            </FormGroupItem>
            <FormGroupItem>
              <CreatableSelect
                isMulti
                onChange={(e: any) => {
                  props.setFieldValue('skills', e)
                }}
                options={
                  (skillList &&
                    skillList.sort((a: any, b: any) => {
                      const textA = a.label.toUpperCase()
                      const textB = b.label.toUpperCase()
                      return textA < textB ? -1 : textA > textB ? 1 : 0
                    })) ||
                  []
                }
                value={props.values.skills}
                placeholder={t('general.skills')}
                classNamePrefix={'creatableselect'}
              />
            </FormGroupItem>
            <FormGroupItem>
              <StyledFromGroupContainer>
                <CourseDetailsContainer>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Uploader
                      label={t('actions.upload_cover_image')}
                      accept="image/x-png,image/gif,image/jpeg"
                      onFileChange={(e: any) => handleImageChange(e, 'avatar')}
                      deleteFile={() => deleteImage('avatar')}
                      type="image"
                      inputValue={
                        props.values.avatar ? props.values.avatar.name : ''
                      }
                      onInputChange={(e: any) =>
                        props.setFieldValue('avatar.name', e.target.value)
                      }
                      disabled={!props.values.avatar}
                      uploadedFile={props.values.avatar}
                      fileExtension={
                        (props.values.avatar &&
                          `(${props.values.avatar.link.split('.').pop()})`) ||
                        ''
                      }
                      loading={props.imageUploading === 'avatar'}
                      id="avatar"
                    />
                    {props.errors.avatar && props.touched.avatar && (
                      <span style={{ color: '#f44336' }}>
                        {props.errors.avatar}
                      </span>
                    )}
                  </div>
                </CourseDetailsContainer>
                <CourseDetailsContainer>
                  <Uploader
                    label={t('actions.upload_intro_video')}
                    accept="video/mp4,video/x-m4v,video/*"
                    onFileChange={props.handleVideoChange}
                    deleteFile={() => props.handleVideoChange()}
                    type="video"
                    inputValue={
                      props.values.video ? props.values.video.title : ''
                    }
                    onInputChange={(e: any) =>
                      props.setFieldValue('video.title', e.target.value)
                    }
                    disabled={!props.values.video}
                    uploadedFile={props.values.video}
                    fileExtension={
                      (props.values.video &&
                        `(${props.values.video.links[2].type})`) ||
                      ''
                    }
                    loading={
                      props.videoUploadProgress > 0
                        ? props.videoUploadProgress
                        : false
                    }
                    id="video"
                  />
                </CourseDetailsContainer>
              </StyledFromGroupContainer>
            </FormGroupItem>
            <FormGroupItem
              width="100%"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
              }}
            >
              <SecondaryText>{t('general.course_content')}</SecondaryText>
              <SSwitch
                onChange={(e: any) => {
                  props.setFieldValue('contentLocked', e?.target?.checked)
                }}
                name="contentLocked"
                value={props.values.contentLocked}
              />
            </FormGroupItem>
            <FormGroupItem
              width="100%"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
              }}
            >
              <SecondaryText>{t('general.certificate_included')}</SecondaryText>
              <SSwitch
                checked={props.values.certificateIncluded}
                onChange={props.handleChange}
                name="certificateIncluded"
              />
            </FormGroupItem>
            {/* {props.values.certificateIncluded ? (
              <FormGroupItem>
                <CourseDetailsContainer>
                  <Uploader
                    label={t('actions.upload_certificate_photo')}
                    accept="image/x-png,image/gif,image/jpeg"
                    onFileChange={(e: any) =>
                      handleImageChange(e, 'certificate')
                    }
                    deleteFile={() => deleteImage('certificate')}
                    type="image"
                    inputValue={
                      props.values.certificate
                        ? props.values.certificate.name
                        : ''
                    }
                    onInputChange={(e: any) =>
                      props.setFieldValue('certificate.name', e.target.value)
                    }
                    disabled={!props.values.certificate}
                    uploadedFile={props.values.certificate}
                    fileExtension={
                      (props.values.certificate &&
                        `(${props.values.certificate.link
                          .split('.')
                          .pop()})`) ||
                      ''
                    }
                    loading={props.imageUploading === 'certificate'}
                    id="certificate"
                  />
                </CourseDetailsContainer>
              </FormGroupItem>
            ) : null} */}
            {props.values.coursePrivacy === 'public' && (
              <FormGroupItem>
                <PriceContainer>
                  <StyledTextContainer>
                    {t('form_fields.price_description')}
                  </StyledTextContainer>
                  <InputFieldContainer>
                    <FieldItemContainer width="170px">
                      <Input
                        error={!!props.errors.price}
                        errorMessage={
                          props.errors.price ? props.errors.price : ''
                        }
                        touched={props.touched.price}
                        label={t('form_fields.price')}
                        name="price"
                        size="medium"
                        type="number"
                        fullWidth
                        value={props.values.price}
                        onChange={props.handleChange}
                      />
                    </FieldItemContainer>
                    <FieldItemContainer width="170px">
                      <SelectField
                        error={!!props.errors.currency}
                        // errorMessage={
                        //   props.errors.currency ? props.errors.currency : ''
                        // }
                        touched={props.touched.currency}
                        label={t('form_fields.currency')}
                        name="currency"
                        items={currencyList}
                        value={props.values.currency}
                        onChange={props.handleChange}
                      />
                    </FieldItemContainer>
                  </InputFieldContainer>
                </PriceContainer>
              </FormGroupItem>
            )}
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={cropperOpen}
              onClose={cropperModalToggle}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={cropperOpen}>
                <ImageCropperContainer>
                  <Cropper
                    ref={cropper}
                    src={file}
                    style={{ height: 500, width: 600 }}
                    // aspectRatio={16 / 9}
                    guides={false}
                  />
                  <div className="actions">
                    <Button
                      text={t('actions.cancel')}
                      type="medium"
                      background="#C7C7C7"
                      textColor={validationRed}
                      outline={`1px solid ${red}`}
                      onClick={cropperModalToggle}
                    />
                    <Button
                      text={t('actions.save')}
                      type="medium"
                      color="secondary"
                      onClick={() => finishCrop()}
                      background={mainColor}
                    />
                  </div>
                </ImageCropperContainer>
              </Fade>
            </Modal>
            <Drawer
              opened={drawer.addCoach}
              toggleDrawer={() => { }}
              totalWidth="600px"
            >
              <AddCoach
                title={t('actions.create_coach')}
                role="COACH"
                drawerName="addCoach"
                drawerData={drawer.data}
                onClose={() => handleDrawerOpen()}
              />
            </Drawer>
          </FormGroup>
        </FormGroupWrapper>
      </Container>
      <FormFooter>
        <FormButtons multiCols={true}>
          <Button
            text={t('actions.cancel')}
            type="medium"
            onClick={props.handleCloseDrawer}
            background="#F5F5F5"
            textColor={validationRed}
            outline={`1px solid ${red}`}
            buttonStyles={{
              borderRadius: '8px',
            }}
          />

          <Button
            text={t('actions.save_as_draft')}
            type="medium"
            color="secondary"
            onClick={() => props.handleButtonClick('draft')}
            background="#ffffff"
            textColor="#080A0A8A"
            outline="1px solid #080A0A14"
            buttonStyles={{
              borderRadius: '8px',
            }}
          />
          <Button
            text={t('actions.next')}
            type="medium"
            color="secondary"
            onClick={() => props.handleButtonClick('next')}
            background={'#317BF4'}
            buttonStyles={{
              borderRadius: '8px',
            }}
          />
        </FormButtons>
      </FormFooter>
    </>
  )
}

export default Step1

const Container = styled.div`
  width: 70%;
  margin: auto;
  margin-bottom: 50px;
`

const SecondaryText = styled.div`
  color: #080a0a8a;
  font-size: 16px;
`

const StyledFromGroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const CourseDetailsContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`

const ImageCropperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .actions {
    /* width: 500px; */
    /* background: #fff; */
    display: flex;
    button {
      margin: 7px 5px;
    }
  }
`

const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledTextContainer = styled.div`
  width: 280px;
  font-size: 16px;
  color: #080a0a8a;
`

const InputFieldContainer = styled.div`
  display: flex;
  gap: 10px;
`

const FieldItemContainer = styled.div<{ width: any }>`
  margin-left: 5px;
  margin-right: 5px;
  width: ${({ width }) => width};
`

const CreateCoacheContainer = styled.div`
  min-width: 170px;

  .btn-wrapper {
    width: 100%;
    button {
      width: 100%;
      border-radius: 8px !important;
    }
  }
`
