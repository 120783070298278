import React from 'react'
import { default as MUITablePagination } from '@material-ui/core/TablePagination'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core'

interface TablePaginationProps {
  currentPage: number
  rowsPerPage: number
  count: number
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

const TablePagination = ({
  currentPage,
  rowsPerPage,
  count,
  handleChangePage,
  handleChangeRowsPerPage,
}: TablePaginationProps) => {
  const { t } = useTranslation()


  const theme = useTheme();

  return (
    <div
      style={{
        background: '#fff',
      }}
    >
      <MUITablePagination
        component="div"
        count={count}
        page={currentPage - 1}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={`${t('grid.rows_per_page')}:`}
        rowsPerPageOptions={[10, 12, 25, 50, 100]}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        style={{
          background: theme.palette.background.default,
        }}
      />
    </div>
  )
}

export default TablePagination
