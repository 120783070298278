import React, { useState } from 'react'
import * as S from './styled'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core'


export type SearchProps = {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const Search: React.FC<SearchProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <S.Container theme={theme}>
      <S.SIcon filename={'search'} fill={theme.palette.text.primary} />
      <S.SInput
        theme={theme}
        name={'search'}
        placeholder={t('general.search_placeholder')}
        {...props.inputProps}
        onKeyPress={(e) => {
          if (e.key === 'Enter')
            if (props.onEnter)
              props.onEnter(e)
        }}
      />
      <S.SButton onClick={props.onClick}>
        {t('general.search_placeholder')}
      </S.SButton>
    </S.Container>
  )
}

export default Search
