import { useState } from 'react'
import createCourseService from 'services/course/createCourseService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useCreateCourse = (params: any) => {
  const { t } = useTranslation()
  const { createCourse } = createCourseService(params)
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)
  const useCreateCourseService = async (
    input: any,
    // videos: any,
    groupIds: string[],
    callback: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors, data } = await createCourse({
      variables: {
        input,
        groupIds,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.course')} ${t('messages.added')}`
      variant = 'success'
      callback(data)
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    useCreateCourseService,
    createCourseLoading: loading,
  }
}

export default useCreateCourse
