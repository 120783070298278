import React, { useState, useEffect, memo, useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { makeStyles } from '@material-ui/core/styles'
import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import MainRoutes from 'routes/MainRoutes'
import i18n from '../i18n';
import { getCurrentLanguage, setCurrentLanguage } from '../helpers/localStorage';

import { useUserValue } from 'context/UserContext'
import { GET_CURRENT_USER } from 'gql/users.query'
import {
  SET_CURRENT_COMPANY,
  FETCH_CURRENT_USER,
  SET_SELECTED_COMPANY_ID,
  CURRENT_USER_PERMISSION,
} from 'store/types'
import { useQuery } from '@apollo/react-hooks'
import DrawerLayout from 'components/DrawerLayout'
import { useLocation, useHistory } from 'react-router-dom'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import FirstLogin from 'components/FirstLogin'

import { setUserPermission, vPermission } from 'utils/permission'
import TransactionsPage from './payment/transactions'
import PurchaseCourse from 'components/purchaseCourse/PurchaseCourse'

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    height: '100px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    width: 'calc(100% - 370px)',
    background: theme.palette.background.paper,
    height: '100vh',
    overflowY: 'scroll',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  pageContainer: {
    position: 'relative',
    padding: '30px',
    overflowY: 'scroll',
    ['@media (max-width:780px)']: {
      padding: 0,
    }
  },
}))





const Main = (props: any) => {
  const [open, setOpen] = useState(true)
  const [state, dispatch] = useUserValue()
  const [filterQuery, setFilterQuery] = useState<any>({})

  const { data, loading, error } = useQuery(GET_CURRENT_USER)
  console.log(data, "data user")
  console.log(state.currentUser, "currentUser")
  const classes = useStyles()
  const [displayChangePasswordModel, setDisplayChangePasswordModel] = useState<
    boolean
  >(false)
  const location = useLocation()
  const history = useHistory()


  useEffect(() => {

    if (location.pathname === "/ka") {

      i18n.changeLanguage("ka")
      setCurrentLanguage("ka")
      history.push("/")

    } else if (location.pathname === "/en") {
      i18n.changeLanguage("en")
      setCurrentLanguage("en")
      history.push("/")
    }
  }, [])

  useEffect(() => {

    data?.currentUser?.requestPasswordChange && setDisplayChangePasswordModel(data?.currentUser?.requestPasswordChange)
  }, [data?.currentUser?.requestPasswordChange])

  useEffect(() => {
    if (data && data.currentUser) {
      if (data.currentUser.companyId) {
        dispatch({
          type: SET_SELECTED_COMPANY_ID,
          payload: data.currentUser.companyId.id,
        })
        // refetch({ id: data.currentUser.companyId.id })
        dispatch({
          type: SET_CURRENT_COMPANY,
          payload: data.currentUser.companyId,
        })
      } else {
        // localStorage.clear()
        // history.push('/login')
      }
      dispatch({
        type: FETCH_CURRENT_USER,
        payload: data.currentUser,
      })

      dispatch({
        type: CURRENT_USER_PERMISSION,
        payload: setUserPermission(
          data.currentUser,
          data.currentUser.companyId ? 'companyUser' : 'user',
        ),
      })
    }
  }, [data])

  useEffect(() => {
    if (error) {
      localStorage.clear()
      window.location.href = '/login'
    }
  }, [error])



  const firstLoginModal = () => { }

  if (loading) return <div>Loading...</div>

  if (error) return <div>error...</div>
  if (data.currentUser.role !== "STUDENT") {
    return (
      <div style={{ display: 'flex' }}>
        {data && <Header currentUser={data.currentUser} open={open} setFilterQuery={setFilterQuery} />}
        {data &&
          data.currentUser.role !== 'STUDENT' &&
          data.currentUser.role !== 'COACH' && (
            <Sidebar
              currentUser={data.currentUser}
              open={open}
              setSideBarOpen={setOpen}
            />
          )}
        <main className={classes.content}>
          <DndProvider backend={HTML5Backend}>
            <div className={classes.toolbar} />
            <div
              // style={{ padding: '30px', position: 'relative' }}
              className={`${classes.pageContainer} ${vPermission.includes(
                data?.currentUser?.role,
              ) && 'container-md'}`}
            >
              <MainRoutes />
            </div>
            {/* {data && !data.currentUser.companyId && (
              <CompanyModeNotification
                className={`${state.selectedCompanyId ? 'visible' : ''}`}
              >
                <CompanyModeTitle>You are in company mode</CompanyModeTitle>
                <article>
                  Here you can see and modify selected company data
                </article>
              </CompanyModeNotification>
            )} */}
          </DndProvider>
        </main>
        <DrawerLayout />

        {data && data.currentUser && (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            // open={!!(data.currentUser.firstLogin === true)}
            open={displayChangePasswordModel}
            onClose={firstLoginModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={displayChangePasswordModel}>
              <FirstLogin setDisplayChangePasswordModel={setDisplayChangePasswordModel} />
            </Fade>
          </Modal>
        )}
      </div>
    )
  } else {
    return (
      <>
        {data && <Header currentUser={data.currentUser} open={open} setFilterQuery={setFilterQuery} />}
        {data &&
          data.currentUser.role !== 'STUDENT' &&
          data.currentUser.role !== 'COACH' && (
            <Sidebar
              currentUser={data.currentUser}
              open={open}
              setSideBarOpen={setOpen}
            />
          )}

        <PurchaseCourse userId={state.currentUser.id} subscription={state.currentUser?.subscription} />
      </>

    )
  }

}

export default memo(Main)
