import { useState } from 'react';
import { useQuery } from "@apollo/react-hooks";
import { IComment } from 'components/common/VideoCommentGrid/VideoCommentGrid';
import { GET_NOTES } from "gql/notes/notes.query";
import { timeConvert } from 'utils/utils'

export type NoteQuery = {
  actionId: string
  model: "LESSON" | "SKILLTESTVIDEO"
  skip: number
  limit: number
}

export const useNote = ({ actionId, model, limit, skip }: NoteQuery) => {
  const [comments, setComments] = useState<IComment[]>([]);

  const { loading, error, refetch, fetchMore } = useQuery(GET_NOTES, {
    variables: {
      actionId, limit, model, skip
    },
    onCompleted: (data) => {
      if (data) {
        const userComments: IComment[] = [];
        data.getNotes.data.forEach((note: any) => {
          const { id, title, description, moment } = note;
          const restructuredNote = {
            id,
            title,
            description,
            seconds: moment,
            time: timeConvert(moment)
          }
          userComments.push(restructuredNote);
        })
        setComments(userComments);
      }
    }
  })

  return {
    comments,
    loadingFetchingNotes: loading,
    errorFetchingNotes: error,
    refetchGetNotes: refetch,
    fetchMore,
  }
};