import styled from 'styled-components'

export const ImageCropperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .actions {
    /* width: 500px; */
    /* background: #fff; */
    display: flex;
    > div {
      margin: 7px;
    }
  }
`
