import React from 'react'
import Checkbox from 'components/common/Checkbox'

interface itemProps {
  id: string
  isChecked: boolean
  firstName: string
  lastName: string
  email: string
  phone: string
  message: string
  error: boolean
}

// eslint-disable-next-line import/prefer-default-export
export const columnConfig = (selectItem: any, isimported: boolean, t: any) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <Checkbox
          checked={item.isChecked}
          onChange={() => selectItem(item.id)}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('user_details.first_name'),
      render: (item: itemProps) => (
        <div style={{ cursor: 'pointer' }}>{item.firstName}</div>
      ),
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('user_details.last_name'),
      render: (item: itemProps) => <div>{item.lastName}</div>,
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: false,
      label: t('user_details.email'),
      render: (item: itemProps) => <div>{item.email}</div>,
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: t('user_details.phone'),
      render: (item: itemProps) => <div>{item.phone}</div>,
    },
  ]

  if (isimported) {
    grid.push({
      id: 'error',
      numeric: false,
      disablePadding: false,
      label: 'error',
      render: (item: itemProps) => (
        <div style={{ color: item.error ? 'red' : 'rgba(0, 0, 0, 0.27)' }}>
          {item.message || 'N/A'}
        </div>
      ),
    })
  }

  return grid
}