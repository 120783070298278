import React from 'react'
import styled from 'styled-components'
import { mainColor } from '../Colors/CommonColors'

export const Grid = props => (
  <StyledGrid {...props}>{props.children}</StyledGrid>
)

export const Container = styled.div`
  margin-top: 20px;
`
export const test = styled.div``

export const GridSwitchButtons = styled.div`
  display: flex;
  align-items: center;

  button {
    cursor: pointer;
    background: #eee;
    outline: none;
    border: none;
    box-shadow: none;
    padding: 5px;
    transition: background 0.2s ease, color 0.2s ease;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:hover,
    &.active {
      background: ${mainColor};
      color: #fff;
    }
  }
`

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  .wrapper {
    width: 100%;
    height: 100%;
    box-shadow: 0 10px 20px -15px rgba(0, 0, 0, 0.5);
    background: #fff;
    border-radius: 16px;
    overflow: hidden;
  }

  &.card {
    ${({ cols }) => `grid-template-columns: repeat(${cols ? cols : 4}, 1fr);`}
    a > div {
      flex-direction: column;
    }

    .wrapper > div {
      flex-direction: column;
    }
  }

  &.list {
    grid-template-columns: 1fr;
    a > div {
      flex-direction: row;
    }
    .wrapper > div {
      flex-direction: row;
    }

    figure {
      width: 200px;
      max-height: 130px;
    }

    section {
      width: calc(100% - 200px);
      padding: 10px 20px;
      height: calc(100% - 50px);
    }
  }
  @media (max-width: 600px){

  }
`
export const GridItem = styled.div`
  width: 100%;
  height: 212px;
  display: flex;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.2);
    }

    h3 {
      color: ${mainColor};
    }
  }
`
export const GridItemImage = styled.figure`
  width: 100%;
  height: 212px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    transition: transform 0.3s ease;
  }
`
export const GridItemContent = styled.section`
  position: relative;
  z-index: 100;
  font-size: 16px;
  color: #333;
  padding: 10px;
`
export const GridItemContentHeader = styled.header`
  display: flex;
  justify-content: space-between;
`
export const GridItemTitle = styled.h3`
  font-size: 16px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: bold;
  transition: color 0.3s ease;
`
export const GridItemDate = styled.span`
  font-size: 12px;
  font-style: italic;
`

export const GridItemLessons = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;

  > div {
    font-size: 11px;
    position: relative;
    padding-left: 12px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.2);
    }

    :not(:last-child) {
      margin-right: 10px;
    }
  }
`
