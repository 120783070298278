import React, { useState, useEffect } from 'react'
import useAddGroup from 'hooks/group/useAddGroup'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import { useFormik } from 'formik'
import { Button } from 'components/common/Button'
import { useUserValue } from 'context/UserContext'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { checkValues } from '../checkGroupsValues'
import { useSwal } from 'hooks/useSwal'

import Stepper from 'components/common/Stepper'
import Step1 from './Step1'
import Step2 from './Step2'

import styled, { css } from 'styled-components'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'

const formData = {
  name: '',
  note: '',
  location: '',
  admin: null,
}

const AddGroupDrawer = (props: any) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const [tab, setTab] = useState(0)
  const { loading, addGroup } = useAddGroup({
    filter: {
      company: {
        type: 'exact',
        value: props.companyId && props.companyId,
      },
    },
  })
  const [students, setStudents] = useState<any>([])

  const handleChangeTab = (e: number) => {
    setTab(e)
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: Yup.object({
      name: Yup.string().required('Name is Required'),
      admin: Yup.string()
        .required('Admin is Required')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      if (tab === 0) {
        setTab(1)
        return
      }

      if (loading) return

      const data = { ...values }

      if (data.admin) {
        data.admin = data.admin.value
      }

      data.courses = []

      const studentsEmail = students.map((i: any) => i.email)
      if (props.companyId) data.company = props.companyId

      if (state.selectedCompanyId) {
        data.company = props.companyId
      }

      addGroup(data, studentsEmail, props.refetch)
    },
  })

  const handleSetStudents = (items: any) => {
    setStudents([...items, ...students])
  }

  const handleCloseDrawer = () => {
    const isEmpty = checkValues(values)

    if (isEmpty) {
      props.onCancel()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        onConfirm: () => {
          props.onCancel()
        },
        onClose: () => { },
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }

  useEffect(() => {
    if (props.closeDrawerClicked) {
      props.setFormData(values)
    }
  }, [props.closeDrawerClicked])

  return (
    <Container>
      <Header>
        <Title>{t('groups_layout.create_group')}</Title>

        <StepperContainer>
          <Stepper
            active={tab}
            steps={[t('general.details'), t('general.students')]}
            setStep={setTab}
            ColorlibConnector={() => <></>}
            StepIconComponent={(props: any) => {
              const icons: { [index: string]: React.ReactElement } = {
                1: <>1</>,
                2: <>2</>,
              }

              return (
                <SWizardStepper active={props.active as boolean}>
                  {icons[String(props.icon)]}
                </SWizardStepper>
              )
            }}
          />
        </StepperContainer>
      </Header>
      <div style={{ position: 'relative', height: 'calc(100% - 168px)' }}>
        <FormContainer onSubmit={(e: any) => e.preventDefault()}>
          <FormGroupWrapper>
            {tab === 0 && (
              <Step1
                handleChange={handleChange}
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                companyId={props.companyId}
                t={t}
              />
            )}

            {tab === 1 && (
              <Step2
                students={students}
                handleSetStudents={handleSetStudents}
                companyId={props.companyId}
              />
            )}
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons multiCols={true}>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />

              {tab !== 0 && (
                <Button
                  text={t('actions.previous')}
                  type="medium"
                  color="secondary"
                  onClick={() => handleChangeTab(0)}
                  background="#E0E1E2"
                  textColor="#414141"
                />
              )}

              <Button
                text={tab === 1 ? t('actions.save') : t('actions.next')}
                color="secondary"
                type="medium"
                onClick={handleSubmit}
                isDisabled={loading}
                background={'#317BF4'}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </div>
    </Container>
  )
}

export default AddGroupDrawer

const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
`
const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: 2;
`

const Title = styled.div`
  font-size: 2em;
  font-weight: normal;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 10px;
`

const StepperContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiPaper-root {
    background: transparent !important;
  }
`
const SWizardStepper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 42px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #080a0a14;
  box-shadow: none;
  color: #707070;
  font-size: 20px;
  transition: 0.3s;
  border-radius: 100%;

  ${({ active }) =>
    active &&
    css`
      width: 54px;
      height: 54px;
      background: #317bf4;
      color: #ffffff;
      font-size: 28px;
      &::after {
        content: '';
        position: absolute;
        border: 1px solid #707070;
        border-collapse: separate;
        border-radius: 100%;
        width: 60px;
        height: 60px;
      }
    `}
`
