import { useMutation } from "@apollo/react-hooks"


import { UPLOAD_FILE_STREAM } from "gql/group/group.query"

const uploadFileService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [singleUploadStream] = useMutation(UPLOAD_FILE_STREAM)

  return {
    singleUploadStream
  }
}

export default uploadFileService
