import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`
export const Wrapper = styled.div<{ width: string; height?: string }>`
  width: ${({ width }) => width};
  background: ${({ theme }) => theme.palette.background.default};
  ${({ height }) => (height ? `height: ${height}` : undefined)};
`
export const FooterWrapper = styled.div`
  width: 100%;
  margin: auto;
  padding: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    border-radius: 8px !important;
  }
`
