import React from 'react'
import styled from 'styled-components'
import strCutter from 'utils/strCutter'

import CheckBox from '@material-ui/core/Checkbox'
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'

interface Audio {
  fileType: string
  link: string
  name: string
}

interface Params {
  backgroundColor: string
  question: string
  image: string
  audio: Audio
  answer: string
  t: any
}

const Preview = ({
  backgroundColor,
  question,
  image,
  audio,
  answer,
  t,
}: Params) => {
  const audioColor: 'primary' | 'disabled' = audio ? 'primary' : 'disabled'
  return (
    <Container>
      <PreviewBox background={backgroundColor}>
        <Wrapper>
          <CheckBox disabled />
          <MoreVertRoundedIcon color="disabled" />
        </Wrapper>
        <Content>
          <AudioIcon>
            <VolumeUpIcon color={audioColor} />
          </AudioIcon>
          <Title>{strCutter(question, 50) || t('general.question')}</Title>
          <ImageBox className={`${image ? '' : 'noImage'}`}>
            {image ? <img src={image} alt="" /> : 'Cover Image'}
          </ImageBox>
          <Answer>
            {strCutter(answer, 120) || t('question_details.answer')}
          </Answer>
        </Content>
      </PreviewBox>
    </Container>
  )
}

export default Preview

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
`
const PreviewBox = styled.div<{ background: string }>`
  width: 350px;
  height: 380px;
  padding: 10px;
  border-radius: 20px;
  background: ${({ background }) =>
    background ? background : 'rgba(6, 198, 143, .3)'};
`
const Content = styled.section`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const AudioIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: '#707070';
  text-transform: uppercase;
  max-width: 300px;
  text-align: center;
`
const ImageBox = styled.figure`
  margin: 20px 0;
  width: 130px;
  height: 130px;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #707070;

  img {
    width: 130px;
    height: 130px;
  }

  &.noImage {
    border: 1px solid #707070;
  }
`
const Answer = styled.article`
  text-align: left;
  width: 80%;
`
