import { useMutation } from '@apollo/react-hooks'

import { DELETE_LESSON } from 'gql/lesson/lesson.query'

const deleteLessonService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteLessonMutation, { loading }] = useMutation(DELETE_LESSON)
  //   {
  //   update(cache, { data: { deleteLessons: document } }) {
  //     updateCache({
  //       objectName: 'topic',
  //       query: GET_TOPIC,
  //       cache,
  //       document,
  //       action: 'update',
  //       params,
  //     })
  //   },
  // })

  return {
    deleteLessonMutation,
    loading,
  }
}

export default deleteLessonService
