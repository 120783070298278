import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'

const SelectAsync = (props: any) => {
  const newData =
    props?.data &&
    props.data.length > 0 &&
    props.data.map((i: any) => ({ label: i.name, value: i.id }))

  return (
    <Container error={props.error && props.touched}>
      <Select
        isLoading={props.loading}
        isMulti={props.isMulti}
        options={newData || []}
        value={props.value}
        onChange={props.onChange}
        onInputChange={props.onInputChange}
        placeholder={props.label}
        isDisabled={props.disabled}
        isClearable={props.isClearable}
      />

      {props.touched && props.error && props.errorMessage && (
        <ErrorMessage>{props.errorMessage}</ErrorMessage>
      )}
    </Container>
  )
}

export default SelectAsync

const Container = styled.div<{ error: boolean }>`
  width: 100%;
  position: relative;
  .css-yk16xz-control {
    border-color: ${({ error }) => (error ? '#f44336' : 'rgba(0, 0, 0, 0.23)')};
    outline: none;
    &:hover {
      border-color: ${({ error }) =>
        error ? '#f44336' : 'rgba(0, 0, 0, 0.23)'};
    }
  }
  .css-1wa3eu0-placeholder {
    color: ${({ error }) => (error ? '#f44336' : 'rgba(0, 0, 0, 0.54) ')};
  }
`

const ErrorMessage = styled.div`
  position: absolute;
  color: #f44336;
  bottom: -20px;
  left: 6px;
  font-size: 0.75rem;
`
