import { useQuery } from '@apollo/react-hooks'

import { GET_USERS } from 'gql/users.query'

export const PER_PAGE = 10

export const useFetchUsers = (
  role: string,
  filter: any = {},
  fetchAll?: any,
  currentPage: number = 1,
  perPage: number = PER_PAGE,


) => {
  const { data, loading, error, refetch } = useQuery(GET_USERS, {
    variables: {

      role,
      filter: { ...filter },
      fetchAll,
      currentPage,
      perPage,
    },
    fetchPolicy: 'no-cache',


  },)

  const errorMessage = error && error?.message.split(':')[1]

  return {
    users: data && data.usersByRole,
    loading,
    error: errorMessage,
    refetch,
  }
}
