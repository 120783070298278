import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/common/Button'
import { useHistory } from 'react-router-dom'
import { mainColor } from 'components/common/Colors/CommonColors'

const ChangePassword = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <>
      {/* <Title>{t('reset_password.title')}</Title>
      <SubText>{t('reset_password.success')}</SubText> */}
      sccs
      {/* <Button
        text={t('login.back_to_sign_in')}
        onClick={() => history.push('/login')}
        background={mainColor}
      /> */}
    </>
  )
}

const Title = styled.div`
  font: normal normal bold 30px/50px FiraGO;
  letter-spacing: 0px;
  color: ${mainColor};
  text-align: center;
  width: max-content;
  margin-left: -28px;
`
const SubText = styled.div`
  font: normal normal normal 16px/28px FiraGO;
  letter-spacing: 0px;
  color: #414141;
  text-align: center;
  margin: 40px 0;
`
export default ChangePassword
