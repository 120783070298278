import { CircularProgress, LinearProgress, useTheme, useMediaQuery } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { activeTabClassName, TabNavigation } from 'components/common/Tabs/Tabs'
import { ToolbarItem } from 'components/common/Toolbar'
import Icon from 'components/Icon'
import { useUserValue } from 'context/UserContext'
import { IPersonalInformation, PersonalInformationHeader } from 'pages/profile/AdminProfile/PersonalInformation'
import { UserCourses } from 'pages/profile/AllUserProfile/UserCourses'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetchSuggestedCourses } from 'hooks/course/useSuggestedCourses'
import styled from 'styled-components'
import { SelectField } from 'components/common/SelectField'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { GET_CATEGOTIES, GET_USED_CATEGORIES } from 'gql/categories/categories.query'
import { ExploreCourses } from './exploreCourses'
import { UserExploreCourses } from 'pages/profile/AllUserProfile/UserExploreCourses'
import { Section } from 'pages/profile/AllUserProfile/Section'

import { Grid } from 'components/common/Cards/styled-components'
import CourseList from '../CourseList'
import { GET_COURSES_BY_COACH } from 'gql/course/course.query'
import { useFetchCourses } from 'hooks/course/useCourses'
import { Redirect } from 'react-router-dom'

import gql from 'graphql-tag'
import { GET_SECTIONS } from 'gql/sections/sections.query'
import { profile } from 'console'
import { useSearchCourses } from 'hooks/course/useSearchCourses'



// import { GET_COURSES_BY_COACH } from 'gql/course/course.query'

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

const CourseLayoutForStudent = () => {
  const { t } = useTranslation()

  const [state] = useUserValue()

  const [value, setValue] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [filterData, setFilterData] = useState<any>({})
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage] = useState<number>(12)
  const [coachCourse, setCoachCourse] = useState<any[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([])
  const [coachSearch, setCoachSearch] = useState<any[]>([]);
  const [section, setSection] = useState<any[]>([])
  const groups =
    state?.currentUser?.group &&
    state.currentUser.group.map((g: any) => g.groupId)
  const userId = state.currentUser.id


  const { data: categoriesData } = useQuery(GET_USED_CATEGORIES)



  const categoriesList =
    categoriesData &&
    categoriesData.getUsedCategories.map((category: any) => ({
      label: category.name,
      value: category.id,
      id: category.id,
    }))

  const generateQuery = () => {

    const where: any = {
      query: { value: {}, type: 'query' },
    }

    where.query = {
      type: 'query',
      value: {
        ...where.query.value,
      },
    }
    return where
  }

  // const query = generateQuery()

  // query.query = {
  //   type: 'query',
  //   value: JSON.stringify({ ...query.query.value, ...filterQuery }),
  // }

  // if (searchText) {
  //   query.name = {
  //     type: 'match',
  //     value: searchText,
  //   }
  // }

  const {
    firstName,
    lastName,
    email,
    avatar,
    id,
    role,
    gender,
    birthDate,
    location,
    jobTitle,
    companyId,
    phone,
    createDate,
    biography,
    note,
    status,
    age,
    phoneFields,
    isCompanyAdmin,
    isSuperAdmin,
    group,
  } = state.currentUser

  const [profileData, setProfileData] = useState<IPersonalInformation>({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    companyId: {
      id: '',
      name: '',
    },
    createDate: new Date(),
    birthDate: new Date(),
    gender: 'prefer not to say',
    location: '',
    phone: '',
    jobTitle: '',
    biography: '',
    note: '',
    status: '',
    id: '',
    age: 0,
    phoneFields: null,
    isCompanyAdmin: false,
    isSuperAdmin: false,
    phoneFieldValue: null,
    avatar: '',
    group: [],
  })

  React.useEffect(() => {
    const data = {
      firstName,
      lastName,
      email,
      role,
      companyId,
      createDate,
      birthDate,
      gender,
      location,
      phone,
      jobTitle,
      biography,
      note,
      status,
      id,
      age,
      phoneFields,
      isCompanyAdmin,
      isSuperAdmin,
      phoneFieldValue: phoneFields ? phoneFields.code : null,
      avatar,
      group: group ? group.map((g: any) => g.groupId) : [],
    }
    setProfileData(data)
  }, [
    firstName,
    lastName,
    email,
    role,
    companyId,
    createDate,
    birthDate,
    gender,
    location,
    phone,
    jobTitle,
    biography,
    note,
    status,
    id,
    age,
    phoneFields,
    isCompanyAdmin,
    isSuperAdmin,
    avatar,
    group,
  ])



  const query = generateQuery()

  query.query = {
    type: 'query',
    value: JSON.stringify({ ...query.query.value, ...filterQuery }),
  }

  useEffect(() => {
    if (profileData.role === "STUDENT" && searchText) {
      query.name = {
        type: 'match',
        value: searchText,
      }
      const test = new RegExp(searchText)

      setFilterQuery({
        $or: [
          { name: { $regex: searchText, $options: 'i' } },
          {
            "categories.name": {
              $regex: searchText
            }
          }, { "skills.label": { $regex: searchText, $options: 'i' } },
          {
            "coaches.firstName": { $regex: searchText, $options: "i" },
          }, {
            "coaches.lastName": { $regex: searchText, $options: "i" },
          }]
      })
    }


    if (profileData.role === "COACH" && searchText) {
      let searchArr: any = []
      coachCourse.forEach((item, index) => {
        (item.name.toLowerCase().includes(searchText.toLowerCase()) && searchArr.push(item))

        item.categories.forEach((test: { name: { toLowerCase: () => (string | number)[] } }) => {
          test.name.toLowerCase().includes(searchText.toLowerCase()) && searchArr.push(item)
        })
      })
      searchArr = [...new Set(searchArr)];
      setCoachSearch(searchArr)
    }
  }, [searchText])



  // useEffect(() => {
  //   if (filterData) {
  //     query.name = {
  //       type: 'match',
  //       value: filterData?.categories?.label,
  //     }
  //   }

  //   setFilterQuery({ "categories.name": { $in: [filterData?.categories?.label] } })
  // }, [filterData])

  let includePurchasedCourses = true
  const {
    courses,
    loading,
    refetch,
    error,
    fetchMore,
  }: any = useSearchCourses(query,)

  //useFetchSuggestedCourses(query, currentPage, perPage, true)
  // useSearchCourses(query,)



  const handleFieldChange = (field: string) => {
    return (e: any) => {
      let item = field === 'name' ? e.target.value : e
      setFilterData({
        ...filterData,
        [field]: item,
      })
    }
  }

  const [getCourse, { data }] = useLazyQuery(GET_COURSES_BY_COACH, {
    onCompleted: (data) => setCoachCourse(data.getCombinedCoursesForCoach.data),
  })

  useEffect(() => {
    if (state.currentUser.role === "COACH") {
      getCourse({
        variables: {
          currentPage: 1,
          perPage: 100000
        }
      });
    }
  }, [])


  const { data: sections, error: errorT } = useQuery(GET_SECTIONS, {
    variables: {
      currentPage: 1,
    },
    onCompleted: sections => {
      setSection(sections?.getSections?.data)
    }
  })
  const theme = useTheme();




  if (profileData.role) {
    return (
      <div style={{ minHeight: "400px" }}>
        {profileData.role === 'STUDENT' && localStorage.getItem("courseId") && <Redirect to={`/course/${localStorage.getItem("courseId")}`} />}
        {profileData.role === 'COACH' && localStorage.getItem("courseId") && localStorage.removeItem("courseId")}

        <Header role={profileData.role}>{(searchText.length > 0 ||
          filterData?.categories?.value?.length > 0 ||
          loading) && (
            <div className="secondary-title">
              {loading &&
                <LinearProgress />
              }
              {/* {loading ? (
                <LinearProgress />
              ) : (
                `${t('general.searched_all')} ${profileData.role === "COACH" ? coachSearch?.length : courses?.data?.length} ${t('general.course')}`
              )} */}
            </div>
          )}
          <FilterContainer>
            <SearchContainer theme={theme}>
              <Icon filename={'search'} fill={theme.palette.text.primary} />
              <input
                type="text"
                value={searchText}
                placeholder={t('general.search_placeholder')}
                onChange={e => setSearchText(e.target.value)}
              />
            </SearchContainer>
            {/* <SelectCategoryWrapper>
              <SelectField
                isClearable={true}
                label="categories"
                name="categories"
                options={categoriesList}
                value={filterData.categories}
                onChange={handleFieldChange('categories')}
                placeholder={t('form_fields.select')}
              />
            </SelectCategoryWrapper>
            <SearchButton>{t('general.search')}</SearchButton> */}
          </FilterContainer>

          {profileData?.role === "COACH" ? <h1 className="title" style={{ color: theme.palette.text.primary, marginTop: "50px", fontSize: "24px" }}>
            {t("general.my_courses")}
          </h1> : null}
        </Header>
        {searchText === '' &&
          !filterData.categories &&
          profileData?.role &&
          profileData.role === 'STUDENT' &&
          profileData?.group && (<>
            <div style={{ margin: '50px 0px' }}>

              <UserCourses userId={profileData?.id} groups={profileData?.group} isDone={false} />
            </div>
          </>
          )}
        {/* ტესტ */}
        {searchText === '' &&
          !filterData.categories &&
          profileData?.role &&
          profileData.role === 'STUDENT' &&
          profileData?.group && (
            <div style={{ margin: '50px 0px' }}>
              <UserExploreCourses
                userId={profileData?.id}
                groups={profileData?.group}
              />
              {section?.length > 0 && section.map(item =>
                item.courses.length > 0 ?
                  <Section sectionId={item.id} userId={profileData?.id} sectionName={item.name} section />
                  : null
              )}
            </div>
          )}
        {searchText === '' &&
          !filterData.categories &&
          profileData?.role &&
          profileData.role === 'STUDENT' &&
          profileData?.group && (<>
            <div style={{ margin: '50px 0px' }}>
              <UserCourses userId={profileData?.id} groups={profileData?.group} isDone={true} />
            </div>
          </>
          )}
        <div style={{ marginTop: profileData?.role === 'COACH' ? "0" : "50px" }}>

          {(searchText != '' || filterData.categories) && value === 1 &&
            courses?.map((course: any) => {



              return course.courses.length > 0 ? < div style={{ marginBottom: "60px" }
              }>
                < PersonalInformationHeader theme={theme} >
                  {/* <h1>{course?.name}</h1> */}

                  {course?.name === "my courses" && <h1> {t("general.my_courses")}</h1>}
                  {course?.name === "finished courses" && <h1>  {t('general.courses_done')}</h1>}
                  {course?.name === "discover courses" && <h1> {t('general.discover_courses')}</h1>}
                  {(course?.name !== "discover courses" && course?.name !== "my courses" && course?.name !== "discover courses") && <h1>{courses?.name} </h1>}
                </PersonalInformationHeader>
                <ExploreCourses
                  groups={groups}
                  courses={profileData.role === "STUDENT" ? course.courses : { currentPage: 0, data: coachSearch, totalCount: coachSearch.length }}
                  loading={loading}
                  refetch={refetch}
                  error={error}
                  userId={profileData?.id}
                  fetchMore={fetchMore}
                />
              </div> : null
            }


            )}
        </div >
        {
          searchText === '' &&
          !filterData.categories &&
          profileData?.role
          && profileData?.role === 'COACH'
          && profileData?.group
          && coachCourse
          && (
            <>
              <Grid className="card" style={{ position: 'relative' }} cols={3}>
                {coachCourse && (
                  <CourseList
                    data={coachCourse}
                    gridStyle="card"
                    selectedItem={[]}
                    selectItem={() => { }}
                    userId={profileData.id}
                    stat={false}
                    handleVideoModal={() => { }}
                    redirectToDashboard={true}
                    coachCourses={coachCourse}
                  />
                )}
              </Grid>
            </>
          )
        }
      </div >
    )
  } else {
    return null
  }
}

export default CourseLayoutForStudent

const Title = styled.div`
  font-size: 23px;
  font-weight: 500;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: ${props => props?.role === "COACH" && '50px 0px 20px 0'};

  .title {
    color: #080a0ad6;
    font-size: 36px;
    font-weight: 600;
  }

  .secondary-title {
    color: #080a0a8a;
    font-size: 16px;
  }
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  /* margin-top: 30px; */
  width: 100%;
`

const SelectCategoryWrapper = styled.div`
  max-width: 237px;
  width: 100%;
  height: 56px;
  .react-select__control {
    border-color: #080a0a29;
  }
`

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 357px;
  width: 100%;
  height: 56px;
  background: ${({ theme }) => theme.palette.background.default};
  border: 1px solid #080A0A29;
  border-radius: 8px;
  padding: 0px 17px;
  gap: 10px;

  input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
  }
`

const SearchButton = styled.button`
  background: #317bf4;
  border-radius: 16px;
  color: #ffffff;
  height:  56px;
  font-size: 14px;
  padding: 20px 35px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledCoursesCount = styled.p`
  color: #080A0A8A;
`
