import { useMutation, useQuery } from '@apollo/react-hooks';

import axios from 'axios';
import { Button } from 'components/common/Button';
import Uploader from 'components/common/FileUploader/Uploader';
import Select from 'components/common/Select';
import { SelectField } from 'components/common/SelectField';
import { Input } from 'components/common/TextInput';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { CloseIcon, DrawerContent, DrawerHeader, DrawerTitle, Info } from 'components/DrawerLayout/styled-components';
import { useUserValue } from 'context/UserContext';
import { useFormik } from 'formik';
import { GET_COURSE } from 'gql/course/course.query';
import { ADD_SKILL_TEST_VIDEO } from 'gql/skillTest/skilltest.query';
import { useCourseByUserId } from 'hooks/course/useCourseByUserId';
import { useGenerateCustumUploadVideoLinks, useGenerateUploadVideoLinks, useUploadAudioService, useUploadFile, useUploadImage } from 'hooks/helpers/useHelpersService';
import { useFetchUsers } from 'hooks/users/useUsers';
import useUsersService from 'hooks/useUsersService';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from '../../styled'
import { module } from '../../types';

import { GET_COACH_BY_COURSEID } from '../../../../../gql/couch/couch.query';
import { useTranslation } from 'react-i18next'
import useSnackbarAlert from 'hooks/useSnackbar';


interface StudentPersonalProps {
  onModalClose: () => void;
  module: module.root;
  groupId: string;
}

interface FormDataProps {
  coach: { label: string, value: string } | null,
  attachedFile: { id: string, name: string, fileType: string, link: string } | null,
  describe: string,
}

const formData: FormDataProps = {
  coach: null,
  attachedFile: null,
  describe: '',
}

const constants = {
  video:
    'video/x-msvideo|video/mpeg|video/ogg|video/mp4|video/x-flv|video/quicktime|video/x-msvideo|video/x-ms-wmv',
  audio: 'audio/mpeg|audio/mpeg|audio/aac|audio/mp4a-latm',
  file:
    'application/pdf|application/msword|application/plain|text/plain|image/png|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel',
}

const StudentPersonal: FC<StudentPersonalProps> = ({ onModalClose, module, groupId }) => {
  const [state] = useUserValue();
  const params: { id: string } = useParams();

  const { t } = useTranslation();
  const theme = useTheme()

  const { uploadFile, fileLoading } = useUploadFile();
  const { uploadImage, imageLoading } = useUploadImage()
  const { uploadAudio } = useUploadAudioService()
  const { generateCustumUploadVideoLinks } = useGenerateCustumUploadVideoLinks()
  const [file, setFile] = useState<any>('');
  const { setSnackbar } = useSnackbarAlert()

  const [uploadVideoProgress, setUploadVideoProgress] = useState(0);

  const [students, setStudents] = useState<any[]>([]);
  const [selectedStudentsIds, setSelectedStudentsIds] = useState<any[]>([]);

  const [coaches, setCoaches] = useState<any[]>();
  const [fileTypes, setFileTypes] = useState('')

  const [addSkillTestVideo] = useMutation(ADD_SKILL_TEST_VIDEO);

  const { users } = useFetchUsers("STUDENT", {
    group: {
      type: 'nestedArrayIn',
      value: groupId,
      nestedField: 'groupId'
    }
  });


  // const { courses, loading } = useCourseByUserId({}, 1, 1, state.currentUser.id);
  const { data: coachData, loading: coachDataLoading } = useQuery(GET_COACH_BY_COURSEID, {
    variables: { courseId: params.id }
  });

  useEffect(() => {
    const tmp: any[] = []
    if (users?.data) {
      users?.data.forEach((user: any) => {
        if (user.id !== state.currentUser.id)
          tmp.push(user)
      })
      setStudents(tmp)
    }
  }, [users])

  // useEffect(() => {
  //   if (file && file.type) {
  //     handleGenerateUploadLink()
  //   }
  // }, [file])

  useEffect(() => {
    if (file && file.type && constants.audio.includes(file.type)) {
      setFileTypes('audio')
      // handleGenerateUploadLink()
      generateUploadAudioLink();
    }

    if (file && file.type && constants.video.includes(file.type)) {
      setFileTypes('video')
      handleGenerateVideoUploadLink()
    }

    if (file && file.type && constants.file.includes(file.type)) {
      setFileTypes('contentDocument')
      handleGenerateUploadLink()
    }
  }, [file])


  useEffect(() => {
    if (coachData.getCoachByCourseId
    ) {
      const { coachId, name } = coachData.getCoachByCourseId[0];
      setFieldValue('coach', { value: coachId, label: name });
    }
  }, [coachData])

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik(
    {
      initialValues: formData,
      onSubmit: async (values) => {
        await addSkillTestVideo({
          variables: {
            moduleId: module.id,
            courseId: params.id,
            input: {
              courseId: params.id,
              description: values.describe,
              file: {
                name: values.attachedFile?.name,
                type: values.attachedFile?.fileType,
                link: values.attachedFile?.link
              },
              coaches: [`${values.coach?.value}`],
              students: [state.currentUser.id, ...selectedStudentsIds]
            }
          }
        })
        onModalClose()
      }
    }
  );

  const uploadVideoService = (url: string) => {

    const formData = new FormData();

    formData.append("file", file);

    return axios.put(url, formData, {
      headers: {
        'Content-Type': "multipart/form-data",
      },
      onUploadProgress: ({ total, loaded }) => {
        setUploadVideoProgress((loaded / total) * 100)
        if ((loaded / total) * 100 === 100) {
          setUploadVideoProgress(0)
        }
      },
    }).then((res) => {
      const message = `Video upload success, conversion ${res.data}`
      const variant = 'success'

      setSnackbar({ message, variant })
    }).catch((err) => {
      const message = `${err}`
      const variant = 'error'
      console.log("err", err)
      setSnackbar({ message, variant })
    })
  }

  const handleGenerateUploadLink = () => {
    uploadFile(
      file,
      'skillTest/attachment',
      (link: any) => {
        setFieldValue('attachedFile', {
          name: file.name,
          fileType: file.name.split('.').pop(),
          link,
        })
      },
    )
  };

  const handleGenerateVideoUploadLink = () => {
    const videos = [{ fileName: file.name, type: file.type }]

    generateCustumUploadVideoLinks(
      videos,
      'skill_test_video',
      (arg: any, key?: string) => uploadVideoService(arg),
      (links: any) => {
        setFieldValue('attachedFile', {
          name: file.name,
          fileType: file.name.split('.').pop(),
          link: links.video.links[2].url,
        })
      },
    )
  };

  const generateUploadAudioLink = () => {
    const audioFile: any = { fileName: file.name, type: file.type }

    uploadAudio(
      audioFile,
      (arg: any) => uploadVideoService(arg),
      (link: string) => {
        setFieldValue('attachedFile', {
          name: file.name,
          fileType: file.name.split('.').pop(),
          link,
        })

        setUploadVideoProgress(0)
      },
    )
  };

  const handleAttachmentChange = (e: any, field: string) => {
    const { files } = e.target
    const file = files[0]
    setFile(file)
  };

  const handleStudentsChange = (e: [{ label: string, value: string }] | null) => {
    if (e && e.length > 0) {
      const studentIds = e.map((studentData) => studentData.value);
      setSelectedStudentsIds(studentIds);
    } else {
      setSelectedStudentsIds([])
    }
  };

  const coachSelectPlaceholder = values.coach?.label ? values.coach.label : t('general.chooseCoach');
  const accessibleStudents = students?.length > 0 && selectedStudentsIds.length < 3 ? students : [];

  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <DrawerHeader theme={theme} style={{ display: 'flex', flexDirection: 'column' }}>
        <DrawerTitle theme={theme} >{t("general.personal_assignment")} </DrawerTitle>
        <DrawerSubtitle theme={theme}>{t('general.transformationCourse')}</DrawerSubtitle>
        <CloseIcon
          onClick={onModalClose}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={isSmallScreen ? 36 : 26}
          height={isSmallScreen ? 36 : 26}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent margin="100px">
        <SelectField
          placeholder={coachSelectPlaceholder}
          isDisabled
        />
        <div style={{
          marginTop: '20px',
        }}>
          <SelectField
            placeholder={t('general.chooseStudents')}
            options={accessibleStudents.map((user: any) => {
              return {
                label: `${user.firstName} ${user.lastName}`,
                value: user.id
              }
            })
            }
            onChange={handleStudentsChange}
            isMulti
          />
        </div>
        <div style={{
          marginTop: '20px',
        }}>
          <Uploader
            label={`${t('actions.upload_file')}`}
            accept="video/mp4,video/x-m4v,video/*,.pdf,audio/mp3,audio/wav,audio/*"
            onFileChange={(e: any) =>
              handleAttachmentChange(e, 'attachedVideo')
            }
            deleteFile={() => {
              setFieldValue('attachedFile', null)

            }}
            type={fileTypes}
            inputValue={() => {
              return values.attachedFile ? values.attachedFile.name : ''
            }}
            onInputChange={(e: any) =>
              setFieldValue('attachedVideo.name', e.target.value)
            }
            disabled={!values.attachedFile}
            uploadedFile={values.attachedFile}
            fileExtension={
              values.attachedFile ? file.type : ''
            }
            loading={
              uploadVideoProgress > 0
                ? uploadVideoProgress
                : false
            }
            id="attachedVideo"
          ></Uploader>
        </div>

        <div style={{
          marginTop: '20px',
        }}>
          <Input
            label={`${t('question_details.reason')}`}
            name="location"
            size="medium"
            type="text"
            fullWidth
            multiline
            rows={10}
            value={values.describe}
            onChange={(e: any) => {
              setFieldValue('describe', e.target.value);
            }}
          />
        </div>

        <div style={{
          marginTop: '20px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <Button
            text={t('general.send')}
            onClick={handleSubmit}
            isDisabled={values.coach?.value ? false : true}
            size={'medium'}
            buttonStyles={{
              borderRadius: '16px'
            }}
          />
        </div>
        <div style={{ height: '20px' }}></div>
      </DrawerContent>
    </>
  );
}

export default StudentPersonal;

const DrawerSubtitle = styled.div`
  font-size: 24px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 20px;
  @media (max-width: 370px){
    font-size: 20px;
  }
`
