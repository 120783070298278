import { useQuery } from '@apollo/react-hooks'
import { Api } from 'api'
import AuthLayout from 'components/AuthLayout'
import { Button } from 'components/common/Button'
import { mainColor, validationRed, successGreen } from 'components/common/Colors/CommonColors'
import { PasswordInput } from 'components/common/TextInput/PasswordInput'
import { useFormik } from 'formik'
import { CHECK_RESET_PASSWORD_TOKEN } from 'gql/users.query'
import { getCurrentLanguage } from 'helpers/localStorage'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { isValidPassword } from 'pages/profile/AdminProfile/ChangePassword'
import { PasswordWithValidations } from 'pages/profile/AdminProfile/PasswordWithValidations'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import LandingNavbar from './LandingNavbar'
import backgroundImage from '../../assets/images/background-image.png';

import * as Yup from 'yup'

import '../../fonts/font.css'

const ForgotPassword = ({ match }: any) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [success, setSuccess] = useState(false)
  const { setSnackbar } = useSnackbarAlert()
  const token = match.params.token
  const { data, error } = useQuery(CHECK_RESET_PASSWORD_TOKEN, {
    variables: { token },
  })
  if (error) {
    let errorMessage: string = error.message.split('GraphQL error: ')[1]
    setSnackbar({
      message: errorMessage,
      variant: 'error',
    })
  }
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required('New Password is Required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
        .required('Password confirm is required'),
    }),
    onSubmit: (values: any) => {
      const data = { ...values }
      setLoading(true)

      data.token = match.params.token

      let { message, variant, anchorOrigin }: snackbarProps = {
        message: '',
        variant: undefined,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      }

      Api('post', 'users/resetPassword', data)
        .then((res: any) => {
          message = t('reset_password.password_changed_successfully')
          variant = 'success'
          setLoading(false)
          setSnackbar({
            message,
            variant,
            anchorOrigin,
          })
          setSuccess(true)
          history.push('/login') //replaced with success component
        })
        .catch((err: any) => {
          message = err.response.data.message
          variant = 'error'
          setLoading(false)
          setSnackbar({ message, variant, anchorOrigin })
          history.push('/login')
        })
    },
  })

  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  const onChangeConfirmPassword = useCallback(
    (e: any) => {
      handleChange(e)
      if (values.confirmPassword !== e.target.value) {
        setConfirmPasswordError(t('reset_password.passwords_do_not_match'))
      } else setConfirmPasswordError('')
    },
    [values.confirmPassword, confirmPasswordError],
  )

  useEffect(() => {
    if (
      values.newPassword !== values.confirmPassword &&
      values.confirmPassword &&
      values.confirmPassword.length > 0
    ) {
      setConfirmPasswordError(t('reset_password.passwords_do_not_match'))
    } else setConfirmPasswordError('')
  }, [values.newPassword, values.confirmPassword])

  const onSave = () => {
    const isValid = isValidPassword(values.newPassword)
    if (isValid && values.confirmPassword === values.newPassword) {
      handleSubmit()
    }
  }

  const currentLng = getCurrentLanguage() || 'ka'

  return (
    <>
      <LandingNavbar token={token} />
      <div className='w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 z-1 landing'>
        <div className='w-full flex flex-col h-full align-center items-center text-center justify-center text-white'>
          <div
            className='bg-black/70 flex flex-col items-center justify-center rounded-xl w-full'
            style={{
              padding: '50px 45px',
              maxWidth: '536px',
            }}
          >
            <div className='h-full w-full m-30 flex justify-center align-center'>
              <Container className='flex justify-center align-center'>
                <ContentWrapper>
                  {error && history.push('/login')}
                  <Title currentLng={currentLng}>
                    {t('reset_password.choose_new_password')}
                  </Title>

                  <Content>
                    <form onSubmit={(e: any) => e.preventDefault()}>
                      <PasswordWithValidations
                        handleChange={handleChange}
                        language="en"
                        eyeColor='#ffffff'
                        currentLng={currentLng}
                        labelFirstColor='#a8a8a8'
                        labelSecondColor='#ffffff'
                        textColor={'#ffffff'}
                      />

                      <PasswordInput
                        touched={touched.confirmPassword}
                        placeholder={t('form_fields.confirm_password')}
                        name="confirmPassword"
                        size="small"
                        eyeColor='#ffffff'
                        textColor='#ffffff'
                        onChange={onChangeConfirmPassword}
                        value={values.confirmPassword}
                        error={confirmPasswordError.length > 1}
                        inputStyle={{
                          borderRadius: '12px',
                          background: '#333333',
                          height: '60px',
                          fontSize: currentLng === 'en' ? '16px' : '14px',

                        }}
                      />
                      <div
                        style={{
                          padding: '20px 0px',
                          color: validationRed,
                          fontSize: currentLng === 'en' ? '14px' : '12px',

                        }}
                      >
                        {confirmPasswordError}
                      </div>

                      <LoginButton>
                        <Button
                          btnType="submit"
                          text={t('reset_password.choose_new_password')}
                          loading={loading}
                          size="large"
                          background={mainColor}
                          onClick={onSave}
                          buttonStyles={{
                            borderRadius: '12px',
                            height: '60px',
                            fontSize: currentLng === 'en' ? '16px' : '14px',

                          }}
                        />
                      </LoginButton>
                    </form>
                  </Content>
                </ContentWrapper>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword


const LoginButton = styled.div`
  width: 100%;
  display: flex;
  .btn-wrapper {
    width: 100%;
}
`

const Title = styled.div<{ currentLng: string }>`
  font-size: ${({ currentLng }) => (currentLng === 'en' ? '32px' : '28px')};

`

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 650px;
  height: 559px;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 352px;
`

export const LoginPopupSubtitle = styled.div<{ currentLng: string }>`
  text-align: center;
  font-size: ${({ currentLng }) => (currentLng === 'en' ? '16px' : '14px')};

  letter-spacing: 0px;
  color: ${successGreen};
  margin: 30px 0px 40px 0;
`

const Content = styled.div`
  margin: 20px 0 20px 0;
  display:flex;
  align-items: center;
  justify-content: center;
`
