import { gql } from 'graphql.macro'

export const GET_TOPICS = gql`
  query($moduleId: String, $filter: TopicFilter) {
    getTopics(moduleId: $moduleId, filter: $filter) {
      id
      name
      description
      attachment {
        name
        link
        fileType
      }
      lessons {
        id
        name
        subtitle {
          name
          link
          fileType
        }
        video {
          name
          thumbnail
          duration
          links {
            url
            fileType
            converted
          }
        }
      }
    }
  }
`

export const GET_TOPIC = gql`
  query($topicId: String!) {
    topic(topicId: $topicId) {
      id
      name
      description
      editorText
      companyId
      attachment {
        name
        link
        fileType
      }
      lessons {
        name
        id
        video {
          id
          name
          thumbnail
          duration
          links {
            url
            fileType
            converted
          }
        }
        attachment {
          name
          link
          fileType
        }
        subtitle {
          name
          link
          fileType
        }
        description
        transcriptFile {
          name
          link
          fileType
        }
        duration
        audio {
          name
          duration
          link
          fileType
        }
        contentDocument {
          name
          fileType
          link
        }
      }
      numberOfModuleQuestions
      currentModule {
        moduleId
        name
      }
    }
  }
`

export const ADD_TOPIC = gql`
  mutation($moduleId: String, $input: TopicInput) {
    addTopic(moduleId: $moduleId, input: $input) {
      id
      name
      description
      editorText
      attachment {
        name
        link
        fileType
      }
      # topics {
      #   id
      #   name
      #   description
      #   attachment {
      #     name
      #     fileType
      #     link
      #     size
      #   }
      #   lessons {
      #     name
      #     id
      #     video {
      #       name
      #       thumbnail
      #       duration
      #       links {
      #         url
      #         fileType
      #         converted
      #       }
      #     }
      #     attachment {
      #       name
      #       fileType
      #       link
      #     }
      #     description
      #     transcript
      #     duration
      #     audio {
      #       name
      #       duration
      #       link
      #       fileType
      #     }
      #     contentDocument {
      #       name
      #       fileType
      #       link
      #     }
      #   }
      # }
      createdTopic {
        id
        name
      }
    }
  }
`

export const EDIT_TOPIC = gql`
  mutation($topicId: String!, $input: TopicInput) {
    editTopic(topicId: $topicId, input: $input) {
      id
      name
      description
      editorText
      attachment {
        name
        link
        fileType
      }
      topics {
        id
        name
        description
        attachment {
          name
          fileType
          link
          size
        }
        lessons {
          name
          id
          # video {
          #   name
          #   thumbnail
          #   duration
          #   links {
          #     url
          #     fileType
          #     converted
          #   }
          # }
          attachment {
            name
            fileType
            link
          }
          description
          transcript
          duration
          audio {
            name
            duration
            link
            fileType
          }
          contentDocument {
            name
            fileType
            link
          }
        }
      }
    }
  }
`

export const DELETE_TOPIC = gql`
  mutation($moduleId: String!, $topicIds: [String]) {
    deleteTopics(moduleId: $moduleId, topicIds: $topicIds) {
      id
      name
      description
      editorText
      attachment {
        name
        link
        fileType
      }
      topics {
        id
        name
        description
        attachment {
          name
          fileType
          link
        }
        lessons {
          name
          id
          video {
            name
            thumbnail
            duration
            links {
              url
              fileType
              converted
            }
          }
          attachment {
            name
            fileType
            link
          }
          description
          transcript
          duration
          audio {
            name
            duration
            link
            fileType
          }
          contentDocument {
            name
            fileType
            link
          }
        }
      }
      # lessons {
      #   id
      #   name
      #   videos {
      #     name
      #     thumbnail
      #     duration
      #     links {
      #       url
      #       type
      #       converted
      #     }
      #   }
      # }
    }
  }
`
export const CHANGE_TOPICS_ORDER = gql`
  mutation($draggedId: String!, $droppedId: String!) {
    changeTopicsOrder(draggedId: $draggedId, droppedId: $droppedId) {
      id
      name
      description
      editorText
      attachment {
        name
        fileType
        link
      }
      topics {
        id
        name
        description
        attachment {
          name
          fileType
          link
          size
        }
        lessons {
          name
          id
          video {
            name
            thumbnail
            duration
            links {
              url
              fileType
              converted
            }
          }
          attachment {
            name
            fileType
            link
          }
          description
          transcript
          duration
          audio {
            name
            duration
            link
            fileType
          }
          contentDocument {
            name
            fileType
            link
          }
        }
      }
    }
  }
`
