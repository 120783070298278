import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useAddRemoveCourseFromCompany } from 'hooks/companies/useCompanySettings'
import { PER_PAGE, useFetchCourses } from 'hooks/course/useCourses'
import useCompaniesService from 'hooks/useCompaniesService'
import IconButton from '@material-ui/core/IconButton'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import ViewListIcon from '@material-ui/icons/ViewList'
import { Button } from 'components/common/Button'
import {
  Grid,
  GridSwitchButtons,
} from 'components/common/Cards/styled-components'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import Loader from 'components/common/Loader'
import Pagination from 'components/common/Pagination'
import { Input } from 'components/common/TextInput'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import CourseList from 'pages/courses/CourseList'
import { Container, FooterWrapper, Wrapper } from '../styled-components'
import { mainColor } from 'components/common/Colors/CommonColors'
import Search from 'components/common/Search'
import { useTheme } from '@material-ui/core'

const toolbarStyle = {
  justifyContent: 'space-between',
}

const PurchasedCourses = (props: any) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [gridStyle, setGridStyle] = useState<'list' | 'card'>('card')
  const [selectAll, setSelectAll] = useState<any>({})
  const [perPage] = useState<number>(PER_PAGE)
  const [searchText, setSearchText] = useState('')
  const [searchTextClick, setSearchTextClick] = useState('')
  const { setCompanyState } = useCompaniesService()
  const history = useHistory()

  const { companyData } = props

  const query: any = {
    state: {
      type: 'exact',
      value: 'published',
    },
    companyId: {
      type: 'exact',
      value: null,
    },
  }
  const [filterOptions, setFilterOptions] = useState<any>(query)

  const { addRemoveCourseFromCompany } = useAddRemoveCourseFromCompany()
  const { courses, loading, refetch, error, fetchMore } = useFetchCourses(
    filterOptions,
    currentPage,
    perPage,
  )

  const { selectedCourses } = companyData

  useEffect(() => {
    setSelectedItem({
      ...selectedItem,
      [currentPage]: selectedCourses,
    })
  }, [selectedCourses])

  const selectItem = (id: string) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectedItemsOnPage = selectedItem[currentPage] || []

  const handleSubmit = () => {
    addRemoveCourseFromCompany(
      companyData.companyData.id,
      selectedItemsOnPage,
      () => {
        props.setData({
          ...companyData,
          selectedCourses: selectedItemsOnPage,
        })
        props.changeStep(3)
        setCompanyState(companyData.companyData.id, 3)
      },
    )
  }

  const fetchAll = false
  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    let filters = {
      ...filterOptions,
      name: { type: 'match', value: searchText },
    }
    refetch({
      filter: filters,
      currentPage: 1,
      perPage,
      fetchAll
    })
    setFilterOptions(filters)
    setCurrentPage(1)
  }

  const handlePaginationClick = (event: any, value: number) => {
    if (value !== currentPage) {
      refetch({
        filter: {
          ...filterOptions,
        },
        currentPage: value,
        perPage, fetchAll
      })
      setCurrentPage(value)
    }
  }

  const courseList = (courses && courses.data) || []

  const buttonDisabled = selectedItem['1'] ? selectedItem['1'].length > 0 : true

  const theme = useTheme()

  return (
    <>
      <Container>
        <Wrapper theme={theme} width="98%">
          <ContentWrapper>
            <Toolbar styles={toolbarStyle}>
              <ToolbarItemWrapper>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Title theme={theme}>{props.t('courses_layout.title')}</Title>
                </div>

                <div className="wrapper">
                  <GridMultipleActions
                    selectedItems={selectedItemsOnPage.length}
                    actions={[]}
                  />
                  <ToolbarItem>
                    <Search
                      inputProps={{
                        value: searchText,
                        onChange: e => setSearchText(e.target.value),
                      }}
                      onClick={e => handleSearchSubmit(e)}
                      onEnter={e => handleSearchSubmit(e)}
                    />
                  </ToolbarItem>
                </div>
              </ToolbarItemWrapper>
            </Toolbar>

            <br />

            <Grid
              className={gridStyle}
              style={{ position: 'relative' }}
              cols={3}
            >
              {error && error}
              {loading && <Loader withBackground />}
              {courses && (
                <CourseList
                  data={courseList}
                  // isDraggable={!searchText && !filtered ? true : false}
                  gridStyle={gridStyle}
                  selectItem={selectItem}
                  selectedItem={selectedItem[currentPage] || []}
                  actions={[]}
                  handleVideoModal={() => { }}
                  fromWizard
                  openInExternalTab={true}
                  checkbox={true}
                />
              )}
            </Grid>
            {courses && courses.totalPages > 1 && (
              <div
                style={{
                  marginTop: 20,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Pagination
                  currentPage={courses ? courses.currentPage : 1}
                  totalPages={courses ? courses.totalPages : 0}
                  handleChange={handlePaginationClick}
                />
              </div>
            )}
          </ContentWrapper>
        </Wrapper>
      </Container>

      <FooterWrapper>
        <ButtonWrapper>
          <Button
            text={props.t('actions.save_as_draft')}
            type="medium"
            onClick={() => {
              addRemoveCourseFromCompany(
                companyData.companyData.id,
                selectedItemsOnPage,
                () => {
                  props.setData({
                    ...companyData,
                    selectedCourses: selectedItemsOnPage,
                  })
                  setCompanyState(companyData.companyData.id, 1, '', () =>
                    history.push('/Companies'),
                  )
                },
              )
            }}
            background="#ffffff"
            textColor="#080A0A8A"
            outline="1px solid #080A0A14"
          />

          <Button
            text={props.t('actions.save_and_next')}
            type="medium"
            color="secondary"
            background={'#317BF4'}
            isDisabled={!buttonDisabled}
            onClick={handleSubmit}
          />
        </ButtonWrapper>
      </FooterWrapper>
    </>
  )
}

export default PurchasedCourses

const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 15px 20px;
`
const ToolbarItemWrapper = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 20px;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
`
const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    border-radius: 8px !important;
  }
`
const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
`
