import { useMutation, useQuery } from '@apollo/react-hooks'
import { Paper, useTheme } from '@material-ui/core'
import TablePagination from 'components/common/Pagination/TablePagination'

import Grid from 'components/common/Grid'
import { GET_TRANSACTIONS } from 'gql/transaction/transaction.query'
import React, { useEffect, useState } from 'react'
import { columnConfig } from './gridConfig'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'components/common/Grid/styled-components'
import { useUserValue } from 'context/UserContext'
import Icon from 'components/Icon'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import { useFetchTransactions, PER_PAGE } from 'hooks/transactions/useFetchTransactions'
import Loader from 'components/common/Loader/Loader'

const TransactionsPage = () => {
  const [state, dispatch] = useUserValue()
  const [selectedItem, setSelectedItem] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [selectAll, setSelectAll] = useState([])
  const [perPage, setPerPage] = useState(PER_PAGE)
  const [page, setPage] = useState(1)
  const [fetchAll, setFetchAll] = useState(false)
  const [fetchAllPagination, setFetchAllPagination] = useState([0, 10])

  const {
    transactions: transactionsData,
    loading,
    refetch,
    error,
    fetchMore,
  } = useFetchTransactions(currentPage, perPage,)

  const {
    transactions: allTransactionsData, loading: allTransactionDataLoading } = useFetchTransactions(currentPage, perPage, true)
  const [transactions, setTransactions] = useState([])
  const { t } = useTranslation()




  const selectItem = id => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = () => {

    if (!selectAll[currentPage]) {
      const newArr = allTransactionsData.data.map(n => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const theme = useTheme()
  let config = columnConfig(
    // state.currentUser.role,
    // handleClick,
    selectItem,
    theme,
    t,
  )

  const handleDeleteCompany = () => { }

  const handlePaginationClick = (
    event,
    newPage
  ) => {
    if ((page - newPage === 1 || page - newPage === 0) && fetchAll) {
      setFetchAllPagination(prevState => [prevState[0] + 10, prevState[1] + 10])
      setPage(page + 1)
      return
    } else if (page > newPage && fetchAll) {
      setFetchAllPagination(prevState => [prevState[0] - 10, prevState[1] - 10])
      setPage(page - 1)
      return
    }
    refetch({

      currentPage: newPage + 1,
      perPage,
    })
    setCurrentPage(newPage + 1)

    // setFetchAllPagination(prevState => [prevState[0] + 10, prevState[1] + 10])
    // setPage(page + 1)
  }

  const handleChangeRowsPerPage = (
    event
  ) => {
    refetch({


      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
    setSelectedItem({})
  }
  const { exportData } = useExportDataService()

  const handleExportData = () => {
    if (selectedItem[currentPage] && selectedItem[currentPage].length > 0) {
      exportData('TRANSACTION', selectedItem[currentPage], link => {
        window.open(link, '_blank')
      })
    } else {
      exportData('TRANSACTION', [], link => {
        window.open(link, '_blank')
      })
    }
  }

  if (loading) {
    return <div style={{ marginTop: "100px" }}>
      <Loader />
    </div>
  }
  return (
    <Paper
      style={{
        padding: '20px',
      }}
    >

      {!loading && transactionsData?.data && (
        <Grid
          title={'ტრანზაქციები'}
          config={config}
          data={transactionsData?.data}
          actionConfig={[]}
          transactions={true}
          selectAllItem={selectAllItem}
          selectedItems={selectedItem[currentPage] || []}
          actions={() => [
            {
              id: 0,
              color: 'secondary',
              tooltipText: `${t('actions.export')}`,
              hide: !state.userPermission.deleteGroup,
              // disabled: selectedItem[currentPage]
              //   ? selectedItem[currentPage].length < 1
              //   : true,
              onClick: () => handleExportData(),
              component: (
                <ActionButton


                  fill={'#269F5A'}

                  theme={theme}
                >
                  <Icon filename={'download'} />
                </ActionButton>
              ),
            },
          ]}
        />
      )}
      {allTransactionDataLoading && <div style={{
        position: 'absolute',
        zIndex: 1100,
        top: '255px',
        left: '47px',
        backgroundColor: "white",
        width: "135px",
        height: "50px"
      }}>

      </div>}
      {transactionsData && transactionsData?.data && (
        <TablePagination
          currentPage={transactionsData && !fetchAll ? transactionsData?.currentPage : allTransactionsData && fetchAll ? page : 0}
          // currentPage={companies && !fetchAll ? companies.currentPage : allComapanies && fetchAll ? page : 0}
          // currentPage={page}
          // currentPage={1}
          rowsPerPage={perPage}
          count={transactionsData ? transactionsData?.totalCount : 0}
          handleChangePage={handlePaginationClick}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  )
}

export default TransactionsPage
