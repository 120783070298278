import { useMutation } from "@apollo/react-hooks"
import { updateCache } from "services/updateCache"
import { GET_USERS, UPDATE_USER_STATUS } from "gql/users.query"

const updateUserStatusService = (params: any) => {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [updateUserStatusMutation, { loading, error }] = useMutation(UPDATE_USER_STATUS, {
    update(cache, { data: { updateUser: document } }) {
      updateCache({
        objectName: "usersByRole",
        query: GET_USERS,
        cache,
        document,
        action: "update",
        params
      })
    }
  })

  return {
    updateUserStatusMutation,
    loading,
    error
  }
}

export default updateUserStatusService
