import { useQuery } from '@apollo/react-hooks'
import { useUserValue } from 'context/UserContext'
import { GET_COURSE } from 'gql/course/course.query'
import Content from 'pages/courses/StudentCourse/Content'
import Header from 'pages/courses/StudentCourse/Header'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import CourseDetailInformation from './CourseDetailInformation'
import UserTabs from './UserTabs'

const UserStatistics: React.FC<{}> = ({ }) => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState<number>(0)
  const { courseId } = useParams<{ courseId: string }>()
  const {
    data: courseData,
    loading,
    error,
    refetch: refetchCourse,
  }: any = useQuery(GET_COURSE, {
    variables: { courseId },
  })

  const course = courseData && courseData.getCourseById

  const [canViewStatistics, setCanViewStatistics] = useState<boolean>(true)
  const [state] = useUserValue()
  const { userId } = useParams<{ userId: string }>()

  useEffect(() => {
    if (
      state?.currentUser?.id === userId ||
      state?.userPermission?.viewStudent
    ) {
      setCanViewStatistics(true)
    }
  }, [state.currentUser.id, userId, state.currentUser.role])

  return (
    <div>
      <div>
        {courseData && <Header course={course} />}
        {canViewStatistics && course && (
          <div>
            {/* <Title>{t('user_tabs.statistics')}</Title>
            <UserTabs
              courseInfo={course}
              currentTab={currentTab}
              handleTabChange={setCurrentTab}
            /> */}

            <Content course={course} isStat={true} userId={userId} />
          </div>
        )}
      </div>
    </div>
  )
}

export default UserStatistics

const Title = styled.div`
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: -10px;
`
