import React, { useState, useEffect, useCallback } from 'react'
import update from 'immutability-helper'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { DrawerEventEmitter } from 'helpers/drawer'
import { columnConfig, actionConfig } from './gridConfig'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_LESSONS, CHANGE_LESSONS_ORDER } from 'gql/lesson/lesson.query'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'

import { useSwal } from 'hooks/useSwal'

import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import { Button } from 'components/common/Button'

import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { Input } from 'components/common/TextInput'
import Grid from 'components/common/Grid'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import IconButton from '@material-ui/core/IconButton'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import DeleteIcon from '@material-ui/icons/Delete'

import useDeleteLessonService from 'hooks/lessons/useDeleteLessonService'
import Modal from 'components/common/Modal'
import AudioPlayer from 'components/common/AudioPlayer'
import Player from 'components/common/VideoPlayer/Player'
import FilterDrawer from './FilterDrawer/FilterDrawer'
import Drawer from 'components/common/Drawer'
import { mainColor } from 'components/common/Colors/CommonColors'
import { ActionButton } from 'components/common/Grid/styled-components'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

const modal = { modal: false, url: '', event: '', subtitle: '' }

const LessonLayout = ({
  topic,
  moduleId,
  refetch,
  lessons,
  isDraggable,
  canModify,
}: any) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { fireSwal } = useSwal()
  const [state] = useUserValue()

  const [mediaModal, setMediaModal] = useState(modal)
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [filterData, setFilterData] = useState<any>({})
  const [searchText, setSearchText] = useState('')

  const { deleteLesson } = useDeleteLessonService({
    topicId: topic.id,
  })
  const { data, refetch: refetchLessons, ...args }: any = useQuery(
    GET_LESSONS,
    {
      variables: {
        topicId: topic.id,
      },
    },
  )

  const [changeOrder, { loading }] = useMutation(CHANGE_LESSONS_ORDER)

  const [cards, setCards] = useState([])
  // const moveCard = useCallback(
  //   async (dragIndex: number, hoverIndex: number) => {
  //     const dragCard = cards[dragIndex]
  //     setCards(
  //       update(cards, {
  //         $splice: [
  //           [dragIndex, 1],
  //           [hoverIndex, 0, dragCard],
  //         ],
  //       }),
  //     )
  //   },
  //   [cards],
  // )

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const newOrder = [...cards];
    const [removed] = newOrder.splice(dragIndex, 1);
    newOrder.splice(hoverIndex, 0, removed);
    setCards(newOrder);
  }

  const selectItem = (id: never, event: boolean) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    setSelectedItem(newSelected)
  }

  const selectAllItem = () => {
    if (!selectAll) {
      const newArr: string[] = data.getLessons.map((n: any) => n.id)
      setSelectedItem(newArr)
      setSelectAll(true)
      return
    }
    setSelectedItem([])
    setSelectAll(false)
  }

  const handleChangeRequest = async (draggedId: string, droppedId: string) => {
    const { data } = await changeOrder({ variables: { draggedId, droppedId } })
    if (data) {
      refetch()
    }
  }

  const handleClick = (item: any) => history.push(`/lessons/${item.id}`)

  const handleEditClick = (id: string, event?: string) => {
    if (event && event === 'edit') {
      DrawerEventEmitter.emit('openDrawer', 'editLesson', true, {
        id: moduleId,
        data: { fromTopic: true, id, topic: { ...topic } },
      })
    }
  }

  const createLessonDrawer = () => {
    DrawerEventEmitter.emit('openDrawer', 'addLesson', true, {
      id: moduleId,
      data: { name: topic.name, id: topic.id },
    })
  }

  const handleDeleteClick = (ids: string[]) => {
    let title =
      ids.length === 1
        ? t('popups.delete_lesson_single')
        : t('popups.delete_lesson_many')

    const params = {
      title,
      onConfirm: () => {
        setSelectedItem([])
        deleteLesson(ids, () => refetch())
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const handleOpenMediaModal = (data: any, event: string) => {
    if (mediaModal.modal) {
      setMediaModal(modal)
      return
    }

    setMediaModal({
      modal: true,
      url: event === 'audio' ? data.audio.link : data.video.links[0].url,
      subtitle: event === 'audio' ? '' : data.subtitle,
      event,
    })
  }

  const searchFilter = (i: any, filed: any) =>
    filed ? i.name.toLowerCase().includes(filed ? filed.toLowerCase() : '') : i

  const filterList = (data: any) => {
    const filtered = data.filter((i: any) => {
      let item: any = { ...i }

      const { name, range, ...args } = filterData

      for (const key in args) {
        if (args[key] === true && i[key]) {
          item = { ...i }
        } else if (args[key] === false && !i[key]) {
          item = { ...i }
        } else {
          return null
        }
      }

      if (range) {
        if (range[0] < i.duration / 60 && range[1] > i.duration / 60) {
          return (item = { ...i })
        } else {
          return null
        }
      }

      const searchItem = searchFilter(item, name)

      return searchItem
    })

    return filtered
  }

  const handleFiltersClick = () => {
    setFilterDrawer(true)
  }

  useEffect(() => {
    if (lessons) {
      const items = filterList(lessons)
      setCards(items)
    }
  }, [lessons, filterData])

  const config = columnConfig(
    state.userPermission,
    selectItem,
    handleClick,
    handleOpenMediaModal,
    t,
  )
  const actions = actionConfig(
    handleEditClick,
    handleDeleteClick,
    'list',
    state.userPermission,
    canModify,
    t,
  )

  const theme = useTheme()

  return (
    <>
      {/* <Toolbar styles={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <ToolbarItem>
            {canModify && (
              <Button
                text={t('lessons_layout.create_lesson')}
                color="secondary"
                onClick={createLessonDrawer}
                icon={<AddCircleRoundedIcon />}
                background={mainColor}
              />
            )}
          </ToolbarItem>

          <ToolbarItem>
            {canModify && (
              <GridMultipleActions
                selectedItems={selectedItem.length}
                actions={
                  canModify
                    ? [
                        {
                          id: 0,
                          color: 'secondary',
                          hide: !state.userPermission.createLesson,
                          tooltipText: t('actions.delete'),
                          disabled: selectedItem.length < 1,
                          onClick: () => handleDeleteClick(selectedItem),
                          component: <DeleteIcon fontSize="small" />,
                        },
                      ].filter((i: any) => !i.hide)
                    : []
                }
                isNumberOfSelectedItemsHidden={
                  !state.userPermission.deleteLesson &&
                  !state.userPermission.editLesson
                }
              />
            )}
          </ToolbarItem>
        </div>

        <ToolbarItem>
          <Input
            label={t('general.search_placeholder')}
            type="text"
            size="small"
            value={searchText}
            onChange={(e: any) => setSearchText(e.target.value)}
            icon={
              <IconButton>
                <SearchRoundedIcon />
              </IconButton>
            }
          />
        </ToolbarItem>
      </Toolbar> */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '30px',
        }}
      >
        <div
          style={{
            color: '#080A0AD6',
            fontWeight: 600,
            fontSize: '24px',
          }}
        >
          {t('lessons_layout.title')}
        </div>
        <ToolbarItem>
          {canModify && (
            <GridMultipleActions
              selectedItems={selectedItem.length}
              actions={
                canModify
                  ? [
                    {
                      id: 0,
                      color: 'secondary',
                      hide: !state.userPermission.createLesson,
                      tooltipText: t('actions.delete'),
                      disabled: selectedItem.length < 1,
                      onClick: () => handleDeleteClick(selectedItem),
                      component: (
                        <ActionButton
                          disabled={selectedItem.length === 0}
                          fill={'#CC3131'}
                          disabledFill={'#080A0AD6'}
                          theme={theme}
                        >

                          <Icon filename={'delete'} />
                        </ActionButton>
                      ),
                    },
                  ].filter((i: any) => !i.hide)
                  : []
              }
              isNumberOfSelectedItemsHidden={
                !state.userPermission.deleteLesson &&
                !state.userPermission.editLesson
              }
            />
          )}
        </ToolbarItem>
      </div>

      <Grid
        // title={t('lessons_layout.title')}
        resetVisible={!!(Object.keys(filterData).length > 0)}
        onFiltersClick={handleFiltersClick}
        resetFilters={() => setFilterData({})}
        isLessons={true}
        data={cards.filter((i: any) => searchFilter(i, searchText))}
        config={config}
        actionConfig={actions}
        selected={_.size(selectedItem)}
        selectAllItem={selectAllItem}
        selectedItems={selectedItem}
        isDraggable={
          isDraggable && _.isEmpty(filterData) && !searchText ? true : false
        }
        changeOrder={handleChangeRequest}
        // loading={loading}
        moveCard={moveCard}
      />

      <Modal isOpened={mediaModal.modal} onClose={handleOpenMediaModal}>
        <div>
          {mediaModal.event === 'audio' && <AudioPlayer url={mediaModal.url} />}
          {mediaModal.event === 'video' && (
            <Player url={mediaModal.url} subtitle={mediaModal.subtitle} />
          )}
        </div>
      </Modal>

      <Drawer opened={filterDrawer} toggleDrawer={() => { }} totalWidth="700px">
        <FilterDrawer
          onClose={() => setFilterDrawer(false)}
          setFilterData={setFilterData}
          filterData={filterData}
          t={t}
        />
      </Drawer>
    </>
  )
}

export default LessonLayout
