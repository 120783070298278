import React from 'react'
import createLinkPlugin from 'draft-js-anchor-plugin'
import createLinkifyPlugin from 'draft-js-linkify-plugin'

export const linkPlugin = createLinkPlugin({
  placeholder: 'https://…',
  linkTarget: '_blank',
  theme: {
    input: 'anchor_input',
    inputInvalid: 'anchor_input--invalid',
  },
})

export const linkifyPlugin = createLinkifyPlugin({
  component: props => {
    return (
      <a href={props.href} target="_blank">
        {props.children}
      </a>
    )
  },
})
