import React, { useState } from 'react'
import styled from 'styled-components'
import Grid from 'components/common/Grid'
import { columnConfig } from 'pages/group/ImportStudents/gridConfig'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'
import { Button } from 'components/common/Button'
import { SelectField } from 'components/common/SelectField'
import { useTranslation } from 'react-i18next'

import useUploadFileStream from 'hooks/group/useUploadFileStream'
import useImportStudents from 'hooks/users/useImportStudents'
import { mainColor } from 'components/common/Colors/CommonColors'
import Icon from 'components/Icon'

const ImportStudents = ({
  groupList,
  companyId,
  refetch,
  setTabValue,
}: any) => {
  const { t } = useTranslation()
  const [selectedStudents, setSelectedStudents] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [imported, setImported] = useState(false)
  const [importedFromFile, setImportedFromFile] = useState([])
  const [selectGroups, setSelectGroups] = useState<any>(null)

  const { uploadFileStream } = useUploadFileStream()
  const { importStudents } = useImportStudents()

  const studentList: any = []

  const selectAllItem = () => {
    if (!selectAll) {
      const newArr: string[] = studentList.map((n: any) => n.id)
      setSelectedStudents(newArr)
      setSelectAll(true)
      return
    }
    setSelectedStudents([])
    setSelectAll(false)
  }

  const handleUploadFile = (file: any) => {
    if (file) {
      uploadFileStream(file, (data: any) => {
        // console.log('file::', data)
        // setImportedFromFile(data)
        const userList =
          data && data.map(({ __typename, id, ...args }: any) => ({ ...args }))

        importStudents(
          userList,
          selectGroups.value,
          selectGroups.label,
          companyId,
          (data?: any) => {
            setImportedFromFile(data)
            setImported(true)
            refetch()
          },
        )
      })
    }
  }

  const handleFileChange = (e: any) => {
    const files: any = Array.from(e.target.files)
    handleUploadFile(files[0])
    setImported(false)
  }

  const config = columnConfig(() => {}, imported, t)
  const groups = groupList.map((i: any) => ({
    label: i.name,
    value: i.id,
    companyId,
  }))

  return (
    <div>
      <SelectField
        // error={!!errors.admin}
        // errorMessage={errors.admin}
        // touched={touched.admin}
        placeholder={`${t('groups_layout.select_groups')}*`}
        options={groups}
        value={selectGroups}
        onChange={(e: any) => setSelectGroups(e)}
        isClearable
      />

      <Toolbar styles={{ justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <ButtonContainer>
            <ToolbarItem style={{ width: '100%' }} className={'secondary'}>
              <Button
                text={t('actions.download_template')}
                type="medium"
                icon={
                  <Icon
                    filename={'download'}
                    style={{ fill: '#5290F6', marginRight: '20px' }}
                  />
                }
                onClick={() =>
                  (window.location.href =
                    'https://ib-assets-public.s3.eu-west-2.amazonaws.com/Template.xlsx')
                }
              />
            </ToolbarItem>

            <ToolbarItem style={{ width: '100%' }}>
              <Button
                text={t('actions.upload_file')}
                type="medium"
                icon={
                  <Icon
                    filename={'download'}
                    style={{
                      fill: '#ffffff',
                      marginRight: '20px',
                      transform: 'rotate(180deg)',
                    }}
                  />
                }
                isDisabled={!selectGroups}
                component={
                  <StyledInput
                    type="file"
                    onChange={handleFileChange}
                    value=""
                  />
                }
                background={mainColor}
              />
            </ToolbarItem>
          </ButtonContainer>
        </div>
      </Toolbar>

      {imported && importedFromFile.find((i: any) => i.error) && (
        <div
          style={{ fontSize: '13px', color: '#ff3a52', padding: '0px 0 20px' }}
        >
          {t('messages.wrong_info')}
        </div>
      )}

      {imported && importedFromFile.find((i: any) => !i.error) && (
        <div
          style={{
            marginTop: '15px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            text={t('general.review')}
            type="small"
            onClick={() => setTabValue(2)}
          />
        </div>
      )}
    </div>
  )
}

export default ImportStudents

const StyledInput = styled.input`
  cursor: pointer;
  height: 100%;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;

  .btn-wrapper {
    width: 100%;
  }

  .MuiButton {
    &-root {
      width: 100%;
      height: 56px;
      color: #ffffff;
      border-radius: 8px !important;

      &:disabled {
        color: #ffffff;
        background: #a8c7fa !important;
      }
    }
  }

  .secondary {
    .MuiButton {
      &-root {
        width: 100%;
        height: 56px;
        color: #5290f6;
        border-radius: 8px !important;
        background: transparent !important;
      }
    }
  }
`
