import React from 'react'
import { useSwal } from 'hooks/useSwal'
import { useUserValue } from 'context/UserContext'

import useChangeCoachQualification from 'hooks/users/useChangeCoachQualificationService'

import UsersLayout from 'components/UsersLayout'
import { mainColor } from 'components/common/Colors/CommonColors'

const CoachesLayout = () => {
  const [state] = useUserValue()
  const {
    changeCoachQualification,
    changeCoachQualificationLoading,
  } = useChangeCoachQualification({
    role: 'COACH',
    companyId: state.selectedCompanyId,
    filter: {},
  })
  const { fireSwal } = useSwal()

  const confirmChangeQualification = (ids: string[], isQualified: boolean) => {
    let qualify: boolean = isQualified ? false : true
    changeCoachQualification(ids, qualify)
  }

  const handleChangeQualificationClick = (id: string, isQualified: boolean) => {
    let title: string = ''
    let confirmText: string = ''

    if (isQualified) {
      title = `<span style="color:red">Are you sure you want to make this coach Unqualified ?</span>`
      confirmText = 'Yes, Unqualify!'
    } else {
      title = `<span style="color: ${mainColor}">Are you sure you want to make this coach Qualified ?</span>`
      confirmText = 'Yes, Qualify!'
    }

    fireSwal({
      title,
      onConfirm: () => confirmChangeQualification([id], isQualified),
      confirmText,
    })
  }

  const handleChangeQualificationManyClick = (
    selectedItems: string[],
    isQualified: boolean,
  ) => {
    let title: string = ''
    let confirmText: string = ''

    if (isQualified) {
      title = `<span style="color:red">Are you sure you want to make this coaches Unqualified ?</span>`
      confirmText = 'Yes, Unqualify!'
    } else {
      title = `<span style="color: ${mainColor}">Are you sure you want to make this coaches Qualified ?</span>`
      confirmText = 'Yes, Qualify!'
    }

    fireSwal({
      title,
      onConfirm: () => confirmChangeQualification(selectedItems, isQualified),
      confirmText,
    })
  }

  return (
    <div>
      <UsersLayout
        role="COACH"
        addText="Create Coach"
        addUserDrawer="addCoach"
        editUserDrawer="editCoach"
        changeQualification={handleChangeQualificationClick}
        changeCoachQualificationLoading={changeCoachQualificationLoading}
        handleChangeQualificationManyClick={handleChangeQualificationManyClick}
      />
    </div>
  )
}

export default CoachesLayout
