import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import './CustomButton.scss'
import { mainColor } from '../Colors/CommonColors'

interface Props {
  text: string
  type?: string
  btnType?: 'button' | 'submit' | 'reset' | undefined
  color?: 'default' | 'inherit' | 'primary' | 'secondary' | undefined
  icon?: React.ReactElement | ''
  iconPosition?: string
  size?: 'small' | 'medium' | 'large' | undefined
  loading?: boolean
  isDisabled?: boolean
  onClick?: () => void
  width?: number
  height?: number
  component?: any
  background?: string
  textColor?: string
  textSize?: string
  outline?: string
  buttonStyles?: any
  referance?: any
}

const CustomButton = ({
  text,
  type = 'default',
  color = 'default',
  btnType = 'button',
  icon = '',
  iconPosition = 'left',
  size = 'medium',
  loading = false,
  isDisabled = false,
  onClick = () => { },
  width,
  height,
  component,
  background = mainColor,
  textColor,
  textSize,
  outline,
  buttonStyles,

  referance,
}: Props) => {
  return (
    <div className={`btn-wrapper btn--${type}`} ref={referance}>
      <Button
        onClick={() => onClick()}
        startIcon={iconPosition === 'left' ? icon : ''}
        endIcon={iconPosition === 'right' ? icon : ''}
        color={color}
        size={size}
        type={btnType}
        disabled={isDisabled ? true : false}
        style={{
          background,
          color: textColor,
          fontSize: textSize,
          border: outline,
          width,
          height,
          ...buttonStyles,
        }}

      >
        {component}
        {text}
        {loading && (
          <LoaderContent>
            <CircularProgress />
          </LoaderContent>
        )}
      </Button>
    </div>
  )
}

export default CustomButton

const LoaderContent = styled.div`
  & > div {
    width: 12px !important;
    height: 12px !important;
    margin-left: 0px !important;
    color: #fff;
  }
`
