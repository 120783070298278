import React from 'react'
import {
  FormGroupItem,
  InputGroup,
  FormGroupWrapper,
  FormContainer,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import { Button } from 'components/common/Button'

import GroupStudents from '../GroupStudents/GroupStudents'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'

const Step2 = ({
  groupData,
  companyId,
  t,
  closeDrawer,
  handleChangeStep,
}: any) => {
  return (
    <FormContainer>
      <FormGroupWrapper>
        <FormGroupItem>
          <GroupStudents
            groupId={groupData.id}
            groupName={groupData.name}
            groupStatus={groupData.status}
            companyId={companyId}
          />
        </FormGroupItem>
      </FormGroupWrapper>

      <FormFooter>
        <FormButtons multiCols={true}>
          <Button
            text={t('actions.cancel')}
            type="medium"
            onClick={closeDrawer}
            background="#E0E1E2"
            textColor={validationRed}
            outline={`1px solid ${red}`}
          />

          <Button
            text={t('actions.previous')}
            color="secondary"
            type="medium"
            background="#E0E1E2"
            textColor="#414141"
            onClick={() => handleChangeStep(0)}
          />

          <Button
            text={t('actions.save')}
            color="secondary"
            type="medium"
            background={'#317BF4'}
            onClick={closeDrawer}
          />
        </FormButtons>
      </FormFooter>
    </FormContainer>
  )
}

export default Step2
