import gql from 'graphql-tag'

export const CREATE_USER = gql`
  mutation($input: SignUpInput) {
    createUser(input: $input) {
      status
    }
  }
`
export const LOGOUT = gql`
  query logout {
    logOut {
      userId
      deviceId
      deviceType
    }
  }
`
