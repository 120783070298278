import { useState } from 'react'
import {
  editQuestionService,
  changeQuestionsOrderService,
} from 'services/questions/editQuestionService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

export const useEditQuestion = (params: any) => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const { editQuestionMutation, loading } = editQuestionService(params)

  const editQuestion = async (
    moduleId: string,
    questionId: string,
    input: any,
    successCallback: () => void,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await editQuestionMutation({
      variables: {
        moduleId,
        questionId,
        input,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`

        setError(message)
        return false
      }
      variant = 'error'
    } else {
      successCallback()
      message = t('messages.changes_saved')
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    editQuestion,
    editQuestionLoading: loading,
    error,
  }
}

export const useChangeQuestionsOrder = (params: any) => {
  const [error, setError] = useState('')

  const { changeQuestionsOrderMutation, loading } = changeQuestionsOrderService(
    params,
  )

  const changeQuestionsOrder = async (
    draggedId: String,
    position: Number,
    refetch: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await changeQuestionsOrderMutation({
      variables: {
        draggedId,
        position,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        setError(message)
        return false
      }
      variant = 'error'
    } else {
      // refetch()
      message = 'The changes have successfully been saved.'
      variant = 'success'
    }
  }

  return {
    changeQuestionsOrder,
    changeQuestionsOrderLoading: loading,
    error,
  }
}
