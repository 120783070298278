import styled from 'styled-components'


export const ActionsDropDown = styled.div`
  width: 270px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 1px solid ${({ theme }) => theme.palette.background.paper};
  border-radius: 8px;
  position: absolute;
  display: ${props => props.visible ? "flex" : "none"};
  flex-direction: column;
  align-items: center;
  top: 70px;
  padding: 10px;
  z-index: 10;

  &::before, &::after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &::after {
    top: -30px;
    border-color:  transparent  transparent ${({ theme }) => theme.palette.background.paper} transparent;
    border-width: 15px;
  }

  &::before  {
    top: -31px;
    border-color: transparent transparent ${({ theme }) => theme.palette.background.paper} transparent;
    border-width: 15px;
  }

`

export const ActionsButton = styled.button`
    padding: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    cursor: pointer;
    font-size: 16px;


    &:hover {
      background-color: rgba(112,112,112, 0.05);
      border-radius: 5px;
      color: #317BF4;
    }
`
