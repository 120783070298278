import { useMutation } from '@apollo/react-hooks'

import { GET_COMPANIES, CHANGE_COMPANY_STATUS } from 'gql/companies.query'
import { updateCache } from 'services/updateCache'

const changeCompanyStatusService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [changeCompanyStatusMutation, { loading, error }] = useMutation(
    CHANGE_COMPANY_STATUS,
    {
      update(cache, { data: { changeCompanyStatus: document } }) {
        updateCache({
          objectName: 'companies',
          query: GET_COMPANIES,
          cache,
          document,
          action: 'update',
        })
      },
    },
  )

  return {
    changeCompanyStatusMutation,
    loading,
    error,
  }
}

export default changeCompanyStatusService
