import gql from 'graphql-tag'

export const ADD_GROUP = gql`
  mutation($input: GroupInput, $students: [String]) {
    addGroup(input: $input, students: $students) {
      name
      note
      location
      id
      status
      numberOfStudents
      admin {
        firstName
        lastName
        email
        id
      }
      courses {
        courseId
        name
        coach {
          name
          coachId
        }
      }
      coach {
        name
        coachId
      }
      company {
        name
        id
      }
    }
  }
`
export const EDIT_GROUP = gql`
  mutation($id: String!, $input: GroupInput) {
    editGroup(id: $id, input: $input) {
      name
      note
      location
      id
      numberOfStudents
      admin {
        firstName
        lastName
        email
        id
      }
    }
  }
`

export const GET_GROUP = gql`
  query($filter: GroupFilter, $currentPage: Int, $perPage: Int,  $fetchAll: Boolean ) {
    getAllGroups(
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
      fetchAll: $fetchAll
    ) {
      currentPage
      totalCount
      data {
        name
        note
        isDefault
        location
        id
        numberOfStudents
        status
        createDate
        admin {
          firstName
          lastName
          email
          id
        }
        courses {
          courseId
          name
          coach {
            name
            coachId
          }
        }
        coach {
          name
          coachId
        }
        company {
          name
          id
        }
      }
    }
  }
`

export const GET_GROUPS = gql`
  query($filter: GroupFilter) {
    getAllGroups(filter: $filter) {
      data {
        name
        id
      }
    }
  }
`

export const GET_GROUP_BY_ID = gql`
  query($id: String) {
    getGroupById(id: $id) {
      name
      note
      location
      id
      numberOfStudents
      status
      company {
        id
      }
      admin {
        firstName
        lastName
        email
        id
      }
      coach {
        name
        coachId,
        avatar,
        id
      }
    }
  }
`

export const ADD_STUDENTS_IN_GROUP = gql`
  mutation($groupId: String, $groupName: String, $ids: [String]) {
    addStudentsInGroup(groupId: $groupId, groupName: $groupName, ids: $ids) {
      name
      note
      location
      id
      numberOfStudents
      admin {
        firstName
        lastName
        email
        id
      }
    }
  }
`
export const DELETE_STUDENTS_FROM_GROUP = gql`
  mutation($groupId: String, $ids: [String]) {
    deleteStudentsFromGroup(groupId: $groupId, ids: $ids) {
      name
      note
      location
      id
      numberOfStudents
      admin {
        firstName
        lastName
        email
        id
      }
    }
  }
`

// export const UPLOAD_FILE = gql`
//   mutation SingleUpload($file: Upload!) {
//     singleUpload(file: $file) {
//       name
//       lastModified
//       lastModifiedDate
//       webkitRelativePath
//       type
//       size
//     }
//   }
// `

export const UPLOAD_FILE_STREAM = gql`
  mutation($file: Upload) {
    singleUploadStream(file: $file) {
      firstName
      lastName
      email
      phone
      id
      biography
      note
      gender
    }
  }
`

export const DELETE_GROUP = gql`
  mutation($id: [String]) {
    deleteGroup(id: $id) {
      message
    }
  }
`

export const ASSIGN_COACH = gql`
  mutation($groupId: String, $coach: GroupsCoach, $courseId: String) {
    assignCoach(groupId: $groupId, coach: $coach, courseId: $courseId) {
      name
      id
      admin {
        firstName
        lastName
        email
        id
      }
      courses {
        courseId
        name
        coach {
          name
          coachId
        }
      }
      coach {
        name
        coachId
      }
    }
  }
`

export const ADD_REMOVE_GROUP_FROM_COURSE = gql`
  mutation($courseId: String, $groupIds: [String]) {
    asyncAddRemoveGroupFromCourse(courseId: $courseId, groupIds: $groupIds) {
      id
    }
  }
`
