import DatePicker from 'components/common/DatePicker/DatePicker'
import { FormField, FormFields } from 'components/common/Form'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import React, { useEffect, useState } from 'react'
import { IPersonalInformation } from './PersonalInformation'
import { useTranslation } from 'react-i18next'

import { genderOptions } from 'components/UsersLayout/staticData'
import { useLocation } from 'react-router-dom'
import GenderPicker from 'components/common/GenderPicker'
interface IFormValues {
  firstName?: string | undefined
  lastName?: string | undefined
  email?: string | undefined
  phone?: string | undefined
  gender?: string | undefined
  birthDate?: Date | undefined | null
  jobTitle?: string | undefined
  location?: string | undefined
  biography?: string | undefined
  note?: string | undefined
  phoneFields?: any
  phoneFieldValue?: string | null | undefined
  avatar?: any
}

interface IErrors {
  firstName?: string | undefined
  lastName?: string | undefined
  email?: string | undefined
  phone?: string | undefined
  gender?: string | undefined
  birthDate?: any
  phoneFields?: any
}

interface IFormTouched {
  firstName?: boolean | undefined
  lastName?: boolean | undefined
  phone?: boolean | undefined
  birthDate?: boolean | undefined
  phoneFields?: any
}

interface IEditProfileFieldsProps {
  values: IFormValues
  errors: IErrors
  generalError: string
  touched: IFormTouched
  handleChange: (eventOrTextValue: string | React.FormEvent<any>) => void
  setFieldValue: any
  setValues: (values: IPersonalInformation) => any
  isStudent?: boolean
}

export const EditProfileFields: React.FC<IEditProfileFieldsProps> = ({
  values,
  errors,
  generalError,
  touched,
  handleChange,
  setFieldValue,
  setValues,
  isStudent,
}) => {
  const { t } = useTranslation()

  const [currentGender, setCurrentGender] = useState({})
  const onDateChange = (date: Date | null) => {
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
      setValues(values as any)
    }
  }

  useEffect(() => {
    const val = genderOptions.find((x: any) => x.value === values.gender)
    val && setCurrentGender(val)
  }, [])

  const onGenderChange = (e: any) => {
    setCurrentGender(e)
    values.gender = e
    setValues(values as any)
  }

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.label
      : null
  }, [values.phoneFields])

  const currentLocation = useLocation()
  const isOnProfilePage = currentLocation.pathname.indexOf('profile') > -1

  return (
    <div>
      <FormFields>
        <FormField width="48%" responsiveWidth='100%' enableReinitialize>
          <TextInput
            error={errors.firstName ? true : false}
            errorMessage={errors.firstName ? errors.firstName : ''}
            touched={touched.firstName}
            label={`${t('user_details.first_name')}*`}
            size="medium"
            name="firstName"
            type="text"
            value={values.firstName}
            onChange={handleChange}
          />
        </FormField>
        <FormField width="48%" responsiveWidth='100%'>
          <TextInput
            error={errors.lastName ? true : false}
            errorMessage={errors.lastName ? errors.lastName : ''}
            touched={touched.lastName}
            label={`${t('user_details.last_name')}*`}
            size="medium"
            name="lastName"
            type="text"
            value={values.lastName}
            onChange={handleChange}
          />
        </FormField>
        <FormField width="100%">
          <GenderPicker checked={values.gender} onChange={onGenderChange} />
        </FormField>
        <FormField width="48%" responsiveWidth='100%'>
          <DatePicker
            label={t('user_details.birthday')}
            value={values.birthDate}
            onChange={onDateChange}
            error={errors.birthDate}
            errorMessage={
              errors.birthDate &&
              `${t('user_details.birthday')} ${t(`validations.required`)}`
            }
            touched={touched.birthDate}
          />
        </FormField>

        <FormField width="48%" responsiveWidth='100%'>
          <PhoneCountryCode
            label={t('user_details.phone')}
            selectLabel={t('form_fields.dial_code')}
            selectValue={values.phoneFields}
            selectChange={(e: any) => {
              setFieldValue('phoneFields', e)
            }}
            inputLabel={t('user_details.phone')}
            inputValue={values.phone}
            inputChange={handleChange}
            errorMessage={errors.phoneFields || errors.phone}
            error={errors.phoneFields || errors.phone}
            touched={touched.phoneFields || touched.phone}
          />
        </FormField>
        <FormField width="100%">
          <TextInput
            label={t('form_fields.location')}
            size="medium"
            name="location"
            type="text"
            value={values.location}
            onChange={handleChange}
          />
        </FormField>
        <FormField width="48%" responsiveWidth='100%'>
          <TextInput
            label={`${t('user_details.email')}*`}
            type="text"
            value={values.email}
            disabled
            size="medium"
          />
        </FormField>

        <FormField width="48%" responsiveWidth='100%'>
          <TextInput
            label={t('form_fields.job_title')}
            size="medium"
            name="jobTitle"
            type="text"
            value={values.jobTitle}
            onChange={handleChange}
          />
        </FormField>

        {!isStudent && (
          <FormField width="100%">
            <TextInput
              label={t('user_details.note')}
              size="medium"
              name="note"
              type="text"
              value={values.note}
              onChange={handleChange}
              multiline
              rows="3"
            />
          </FormField>
        )}
      </FormFields>
    </div>
  )
}
