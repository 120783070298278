import { useTheme } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { AvatarInput } from 'components/common/AvatarInput/AvatarInput'
import { Button } from 'components/common/Button'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'
import DatePicker from 'components/common/DatePicker'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import GenderPicker from 'components/common/GenderPicker'
import Loader from 'components/common/Loader'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'
import { SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import {
  CloseIcon,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Info,
} from 'components/DrawerLayout/styled-components'
import { genderOptions } from 'components/UsersLayout/staticData'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { useFormik } from 'formik'
import { DrawerEventEmitter } from 'helpers/drawer'
import { UserSchema } from 'helpers/validationSchemas'
import useAddUserServie from 'hooks/users/useAddUserService'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CLEAR_FORMDATA, SET_FORMDATA } from 'store/types'

interface Props {
  title: string
  role: string
  drawerName?: string
  closeDrawer?: () => void
  drawerData?: any
}

const AddModerator = ({ title, role, drawerName, drawerData }: Props) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [dataState, dispatch] = useData()
  // const { data, loading } = useQuery(GET_GROUP, {
  //   variables: {
  //     filter: {
  //       company: {
  //         type: 'exact',
  //         value: state.selectedCompanyId,
  //       },
  //     },
  //   },
  // })
  const { addUser, user, error, addUserLoading } = useAddUserServie({
    role,
    companyId: state.selectedCompanyId,
    filter: {},
  })
  const [generalError, setGeneralError] = useState('')

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      jobTitle: '',
      location: '',
      gender: '',
      phoneFields: null as any,
      phoneFieldValue: null,
      birthDate: null as any,
      biography: '',
      note: '',
      avatar: '',
    },
    validationSchema: UserSchema,

    onSubmit(values) {
      if (addUserLoading) return

      const formValues: any = {
        ...values,
        requestPasswordChange: state.currentUser.settings.askPassword,
      }
      formValues.role = role

      delete formValues.phoneFieldValue

      if (formValues.phoneFields && formValues.phoneFields.value) {
        formValues.phoneFields = {
          dialCode: formValues.phoneFields.value,
          code: formValues.phoneFields.label,
        }
      } else {
        formValues.phoneFields = null
      }

      formValues.gender = formValues.gender ? formValues.gender : null

      addUser(formValues, role, state.selectedCompanyId, onSuccess)
    },
  })

  const closeDrawer = () =>
    DrawerEventEmitter.emit('openDrawer', { drawerName }, false)

  const handleCloseDrawer = () => {
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'addModerator',
        values,
        compareTo: {},
      },
    })
  }

  const onSuccess = () => {
    closeDrawer()
    dispatch({ type: CLEAR_FORMDATA })
    drawerData.onSuccess()
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    setGeneralError('')
  }, [values.email])

  useEffect(() => {
    if (error) {
      setGeneralError(error)
    }
  }, [error])

  // useEffect(() => {
  //   if (data) {
  //     const allGroups = [...data.getAllGroups]
  //     allGroups.forEach(item => {
  //       item.value = item.name
  //       item.label = item.name
  //     })
  //     setGroups(allGroups)
  //   }
  // }, [data])

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  const [currentDate, setCurrentDate] = useState<Date | null>(null)

  const onDateChange = (date: Date | null) => {
    // setCurrentDate(date)
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  useEffect(() => {
    if (values.birthDate) {
      setCurrentDate(
        new Date(
          typeof (values.birthDate === 'string')
            ? values.birthDate
            : +values.birthDate,
        ),
      )
    }
  }, [values.birthDate])


  const theme = useTheme()

  return (
    <>
      {addUserLoading && <Loader />}
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>{title}</DrawerTitle>

        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              {/* <FormField >
                <PhoneCountryCode />
              </FormField > */}

              <FormField>
                <AvatarInput
                  setFieldValue={setFieldValue}
                  initialAvatar={values.avatar}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  label={`${t('user_details.first_name')}*`}
                  size="medium"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  label={`${t('user_details.last_name')}*`}
                  size="medium"
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </FormField>

              <FormField width="48%">
                <TextInput
                  error={errors.email || generalError ? true : false}
                  errorMessage={
                    errors.email || generalError
                      ? errors.email || generalError
                      : ''
                  }
                  touched={touched.email}
                  label={`${t('user_details.email')}*`}
                  size="medium"
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <PhoneCountryCode
                  label={`${t('user_details.phone')}`}
                  selectLabel={t('form_fields.dial_code')}
                  selectValue={null}
                  selectChange={(e: any) => setFieldValue('phoneFields', e)}
                  inputLabel="Number"
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={
                    errors.phone || errors.phoneFields || generalError
                      ? true
                      : false
                  }
                  errorMessage={
                    errors.phone || errors.phoneFields || generalError
                      ? errors.phone || errors.phoneFields || generalError
                      : ''
                  }
                  touched={touched.phone || touched.phoneFields}
                />
              </FormField>

              <FormField width="100%">
                <GenderPicker
                  checked={(values.gender as unknown) as string}
                  onChange={e => setFieldValue('gender', e)}
                />
              </FormField>

              <FormField width="48%">
                <TextInput
                  label={t('form_fields.job_title')}
                  size="medium"
                  name="jobTitle"
                  type="text"
                  value={values.jobTitle}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  label={t('form_fields.location')}
                  size="medium"
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                />
              </FormField>
              <FormField width="100%">
                <DatePicker
                  label={`${t('user_details.birthday')}`}
                  name="birthDate"
                  value={currentDate}
                  onChange={onDateChange}
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  label={t('user_details.biography')}
                  size="medium"
                  name="biography"
                  type="text"
                  value={values.biography}
                  onChange={handleChange}
                  multiline
                  rows="3"
                />
              </FormField>
              <FormField width="100%">
                <TextInput
                  label={t('user_details.note')}
                  size="medium"
                  name="note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  multiline
                  rows="3"
                />
              </FormField>
              {/* <FormControlLabel
                control={
                  <Switch
                    checked={requestPasswordChange}
                    onChange={() => setChecked(!requestPasswordChange)}
                    value={requestPasswordChange}
                    color="primary"
                  />
                }
                label="Ask for a password change"
              /> */}
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="medium"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor={validationRed}
                outline={`1px solid ${red}`}
              />
              <Button
                text={t('actions.save')}
                type="medium"
                color="secondary"
                btnType="submit"
                background={mainColor}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddModerator
