import { useState } from "react";
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  GET_SECTIONS,
  DELETE_SECTIONS,
  CREATE_SECTION,
  DELETE_SECTION_COURSES,
} from "gql/sections/sections.query";

import { GET_ALL_COURSE } from "gql/course/course.query";

export const PER_PAGE = 10;

export const useFetchSections = (filter: any, currentPage: number = 1, perPage: number = 10, fetchAll?: boolean,) => {
  const { data, loading, error, refetch } = useQuery(GET_SECTIONS, {
    variables: {
      fetchAll, filter, currentPage, perPage,
    },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    sections: data && data.getSections,
    loading,
    error: errorMessage,
    refetch,
  }
};

export const useFetchNonSectionCourses = (selectedCoursesIds: string[], currentPage: number = 1, perPage: number = 10) => {
  const value = {
    // $or: [
    //   { originalId: { $exists: false } },
    //   { originalId: { $eq: null } },
    // ],
    isPublished: { $eq: true },
    status: { $eq: 'ACTIVE' },
    state: { $eq: 'published' },
    ...(selectedCoursesIds.length > 0 ? { _id: { $nin: [...selectedCoursesIds] } } : "")
  };

  // const query = {
  //   originalId: {
  //     type: 'query',
  //     value: JSON.stringify(value)
  //   },

  // };


  const query = {

    query: { type: "eq", value: "" }, companyId: { type: "exact", value: null }

  };

  const { data, loading, error, refetch, fetchMore } = useQuery(GET_ALL_COURSE, {
    variables: { filter: query, currentPage, perPage: 100 }
  });

  const errorMessage = error ? error?.message.split(':')[1] : ''

  const desiredDataFromCourses = data?.getAllCourse?.data?.map((c: any) => {
    return {
      id: c.id,
      name: c.name,
      imgLink: c.avatar?.link
    }
  }) || []

  const desiredData = {
    data: desiredDataFromCourses,
    courseCount: data?.getAllCourse?.courseCount,
    currentPage: data?.getAllCourse?.currentPage
  };

  // console.log("desired desiredData", desiredData)

  return {
    // courses: data && data.getAllCourse,
    courses: data ? desiredData : null,
    loading,
    error: errorMessage,
    refetch,
    fetchMore,
  }
}

export const useDeleteSections = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [deleted, setDeleted] = useState(false);
  const [error, setError] = useState('')

  const [deleteSectionsMutation, { loading }] = useMutation(DELETE_SECTIONS);

  const deleteSections = async (ids: string[]) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await deleteSectionsMutation({
      variables: {
        ids,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      // console.log("desired deletion response", data.deleteSections);
      setDeleted(data.deleteSections)
      message = `${t('general.section')} ${t('messages.deleted')}`
      variant = 'success'
    }
    setSnackbar({ message, variant })
  }

  return {
    deleteSections,
    addSectionLoading: loading,
    deletedSections: deleted,
    setDeleted,
    error,
  }
}

export const useDeleteSectionCourses = () => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  const [deletedSectionCourses, setDeletedSectionCourses] = useState<any>(false);
  const [error, setError] = useState('')

  const [deleteSectionCoursessMutation, { loading }] = useMutation(DELETE_SECTION_COURSES);

  const deleteSectionCourses = async (id: string, courses: string[], deletingSingleCourseFromDots?: boolean) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await deleteSectionCoursessMutation({
      variables: {
        id,
        input: {
          courses,
        }
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
      setSnackbar({ message, variant })

    } else {
      setDeletedSectionCourses({
        ...data.deleteSectionCourses,
        ...(deletingSingleCourseFromDots ? { deletingFromDots: true, deletedCourseId: courses[0] } : "")
      })
    }
  }

  return {
    deleteSectionCourses,
    deleteSectionCoursesLoading: loading,
    deletedSectionCourses,
    setDeletedSectionCourses,
    setSnackbar,
    error,
  }
}
