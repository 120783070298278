import React from 'react';
import { getCurrentLanguage } from 'helpers/localStorage';

const NavButton = ({ text, fontSize, border, bold, backgroundColor, component, onClick }) => {
  const currentLanguage = getCurrentLanguage();

  const classNames = `flex flex-nowrap sm:px-8 px-1 md:mx-2 xl:mx-2 mx-1 ${border && 'border-2'} rounded-full text-white cursor-pointer h-12 ${backgroundColor}`
  const headingClassnames = `w-full h-full flex items-center justify-center ${currentLanguage === 'ka' && 'georgianFonts capitalLetters'}`;

  return (
    <div
      onClick={onClick}
      style={{ paddingTop: currentLanguage === 'ka' && 5 }}
      className={classNames}>
      {component
        ? component
        : <h6 className={headingClassnames}>{text}</h6>}
    </div>
  );
};

export default NavButton;
