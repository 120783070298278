import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useMutation } from '@apollo/react-hooks'

import { CompaniesWizardPage2Schema } from 'helpers/validationSchemas'
import { CHECK_USER_EMAIL } from 'gql/users.query'

import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
} from 'components/common/Form/Form'

import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'

interface Manager {
  email: string
  firstName: string
  lastName: string
  tableData?: any
}

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  status: '',
}

const CompaniesWizardPage2 = ({ setCompanyManagers, companyManagers }: any) => {
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: CompaniesWizardPage2Schema,
    onSubmit(values) {
      addManager(values)
    },
  })

  const [managers, setManagers] = useState<Manager[]>([])
  const [generalError, setGeneralError] = useState<any>('')
  const [checkUserEmail] = useMutation(CHECK_USER_EMAIL)

  useEffect(() => {
    setManagers(companyManagers)
  }, [companyManagers])

  const checkEmail = async (email: string) => {
    try {
      const { errors } = await checkUserEmail({
        variables: { email },
      })

      if (errors && errors.length !== 0) {
        return errors[0].message
      }
    } catch (err) {
      console.log('error from check user email', err)
    }

    return ''
  }

  const addManager = async (newManager: Manager) => {
    touched.email = true
    if (!values.email) {
      setGeneralError('Email is required')
      return false
    }

    const exists = managers.find(manager => manager.email === newManager.email)
    if (exists) {
      setGeneralError('Manager already invited')
    } else {
      const error = await checkEmail(newManager.email)
      if (error) {
        setGeneralError(error)
      } else {
        const updatedManagers = [newManager, ...managers]
        setManagers(updatedManagers)
        setCompanyManagers(updatedManagers)
        touched.email = false
        resetForm()
      }
    }
  }

  // const handleCloseDrawer = () => {
  //   fireSwal({
  //     title:
  //       t('popups.close_popup'),
  //     text: t('popups.sure'),
  //     confirmText: 'Yes, Cancel!',
  //     cancelText: 'No',
  //     onConfirm: () => closeDrawer(),
  //   })
  // }

  useEffect(() => {
    if (generalError) setGeneralError('')
  }, [values.email, generalError])

  return (
    <FormContainer onSubmit={handleSubmit}>
      <FormGroupWrapper>
        <FormFields>
          <FormField>
            <TextInput
              error={errors.email || generalError ? true : false}
              errorMessage={
                errors.email || generalError ? errors.email || generalError : ''
              }
              touched={touched.email}
              label="Email"
              name="email"
              size="small"
              type="text"
              value={values.email}
              onChange={handleChange}
              fullWidth={true}
            />
          </FormField>
          <FormField>
            <TextInput
              error={errors.firstName ? true : false}
              errorMessage={errors.firstName ? errors.firstName : ''}
              touched={touched.firstName}
              label="Firstname (optional)"
              name="firstName"
              size="small"
              type="text"
              value={values.firstName}
              onChange={handleChange}
              fullWidth={true}
            />
          </FormField>
          <FormField>
            <TextInput
              error={errors.lastName ? true : false}
              errorMessage={errors.lastName ? errors.lastName : ''}
              touched={touched.lastName}
              label="Lastname (optional)"
              name="lastName"
              size="small"
              type="text"
              value={values.lastName}
              onChange={handleChange}
              fullWidth={true}
            />
          </FormField>
          <div style={{ maxWidth: 120 }}>
            <Button text="Add to List" type="medium" btnType="submit" />
          </div>
        </FormFields>
        {/* <DynamicGrid
          title="Manage invited users"
          data={managers}
          initialValues={initialValues}
          validationSchema={CompaniesWizardManagersSchema}
          editable={false}
          updateRow={updateManager}
          deleteRow={deleteManager}
          checkEmail={checkEmail}
        /> */}
      </FormGroupWrapper>
    </FormContainer>
  )
}

export default CompaniesWizardPage2
