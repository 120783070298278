import React, { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { IconButton, useTheme, useMediaQuery, CircularProgress } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { Button } from 'components/common/Button';
import DropdownButton from 'components/common/DropdownButton';
import Uploader from 'components/common/FileUploader/Uploader';
import Select from 'components/common/Select';
import { SelectField } from 'components/common/SelectField';
import { Input } from 'components/common/TextInput';
import VideoPlayer from 'components/common/VideoPlayer';
import Player from 'components/common/VideoPlayer/Player';
import { CloseIcon, DrawerContent, DrawerHeader, DrawerTitle, Info } from 'components/DrawerLayout/styled-components';
import Icon from 'components/Icon';
import { useUserValue } from 'context/UserContext';
import { useFormik } from 'formik';
import { GET_COURSE } from 'gql/course/course.query';
import { ADD_SKILL_TEST_VIDEO } from 'gql/skillTest/skilltest.query';
import { useCourseByUserId } from 'hooks/course/useCourseByUserId';
import { useGenerateUploadVideoLinks, useUploadFile } from 'hooks/helpers/useHelpersService';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from '../../styled'
import { module } from '../../types';
import { ISkillTest, ISkillTestReview } from '../personalhomework';
import { useTranslation } from 'react-i18next'

import AudioPlayer from 'components/common/AudioPlayer';
import SkillTestReviewCard from './SkillTestReviewCard/SkillTestReviewCard';
import ConvertedVideo from '../PersonalHomework/ConvertedVideo';

interface StudentInfoPersonalProps {
  onModalClose: () => void;
  skillTests: ISkillTest[]
  module?: module.root
  course?: module.Cours,
  moduleName: string,
  courseName: string,
  refetch: () => void,
}

interface FormDataProps {
  coach: { label: string, value: string } | null,
  attachedFile: { name: string, link: string, fileType: string } | null,
  attachedVideo: { id: string, title: string } | null,
  describe: string,
}

const formData: FormDataProps = {
  coach: null,
  attachedFile: null,
  attachedVideo: null,
  describe: '',
}

const StudentInfoPersonal: FC<StudentInfoPersonalProps> = ({ refetch, onModalClose, skillTests, moduleName, courseName }) => {
  const [state] = useUserValue();
  const { t } = useTranslation();
  const theme = useTheme();
  const [currentSkillTestIndex, setCurrentSkillTestIndex] = useState(skillTests.length - 1);
  const [isSkillVideoConverted, setIsSkillVideoConverted] = useState<true | null>(null);

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik(
    {
      initialValues: formData,
      onSubmit: async (values) => {

      }
    }
  );

  const dateConvert = (timestamp: Date) => {
    const date = new Date(timestamp);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}  ${date.getHours()}:${date.getMinutes()}`
  }

  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const currentSkillTest = skillTests[currentSkillTestIndex];

  // take into consideration groupmates' view perspective on rendered reviews: shall their review be the first in the list?
  const renderedReviews: ISkillTestReview[] = currentSkillTest?.reviews.reduce((acc: ISkillTestReview[], inc) => {
    if (inc.author.role === 'COACH') {
      return [inc, ...acc];
    } else if (inc.author.role == 'STUDENT') {
      return [...acc, inc]
    } else {
      return [...acc];
    }
  }, []) || []

  return (
    <>
      <DrawerHeader theme={theme} style={{ display: 'flex', flexDirection: 'column' }}>
        <DrawerTitle theme={theme}>{t("general.personal_assignment")} </DrawerTitle>
        <DrawerSubtitle>{skillTests[0]?.sentForReview[1]?.firstName} {skillTests[0]?.sentForReview[1]?.lastName}</DrawerSubtitle>
        <CloseIcon
          onClick={onModalClose}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={20}
          height={20}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent margin="100px">

        <DrawerTitle style={{
          fontSize: '28px',
        }}>{courseName}</DrawerTitle>
        <DrawerSubtitle style={{
          marginTop: '10px',
          fontSize: '16px'
        }}>
          {moduleName}
        </DrawerSubtitle>
        <div style={{ width: '100%', height: '1px', background: '#EBEBEB' }} />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '15px',
          }}
        >
          <Text fs={'16px'} color={'#080A0A54'}>{dateConvert(skillTests[currentSkillTestIndex].createDate)}</Text>
          <DropdownButton
            backgroundColor='transparent'
            textColor='#080A0A54'
            iconColor='#080A0A54'
            title={`${t('general.version')} ${skillTests[currentSkillTestIndex].version}`}
            buttons={skillTests.map((skill, index) => {
              return {
                title: `${t('general.version')} ${skill.version}`,
                onClick: () => {
                  if (currentSkillTestIndex !== index) {
                    setIsSkillVideoConverted(null);
                    setCurrentSkillTestIndex(index);
                  }
                }
              }
            })}
          />
        </div>
        {currentSkillTest?.file.type === "mp4" ? (
          <ConvertedVideo
            isSkillVideoConverted={isSkillVideoConverted}
            onConvertionStatusChange={setIsSkillVideoConverted}
            currentSkilltestIndex={currentSkillTestIndex}
            chosenSkilltest={currentSkillTest}
          />
        ) : ""}
        {skillTests[currentSkillTestIndex].file && skillTests[currentSkillTestIndex].file.type === "pdf" &&
          <div style={{
            margin: '20px 0px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}>
            <IconButton style={{
              border: '1px solid #F1F1F1',
              borderRadius: 15,
            }}
              onClick={() => window.open(skillTests[currentSkillTestIndex].file.link)}
            >
              <Icon filename='pdf-file' />
            </IconButton>
          </div>
        }

        {
          skillTests[currentSkillTestIndex].file && skillTests[currentSkillTestIndex].file.type === "mp3" &&
          <div style={{
            margin: '20px 0px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}>

            <AudioPlayer url={currentSkillTest?.file.link} />

            {/* It could be necessary again: */}

            {/* {
              !audioModalIsOpened && (
                <IconButton
                  style={{ border: '1px solid #F1F1F1', borderRadius: 15 }}
                  onClick={() => setAudioModalIsOpened(true)}
                >
                  <Icon style={{ width: 42, height: 44 }} filename='volume' />
                </IconButton>
              )
            } */}

            {/* {
              audioModalIsOpened && (
                <AudioPlayer
                  isModalOpened={audioModalIsOpened}
                  onModalClose={() => setAudioModalIsOpened(false)}
                  url={desiredSkillTest?.file.link}
                />
              )
            } */}

          </div>
        }

        <div style={{ width: '100%', height: '1px', background: '#EBEBEB' }} />

        {/* {skillTests[currentSkillTest]?.sentForReview[1]?.userId !== state.currentUser.id && (<div style={{
          marginTop: '20px',
        }}>
          <Rating defaultValue={0} precision={1} />
        </div>)} */}

        {/* {skillTests[currentSkillTest]?.sentForReview[1]?.userId !== state.currentUser.id && (<div style={{
          marginTop: '20px',
        }}>
          <Input
            label={`${t('question_details.reason')}`}
            name="location"
            size="medium"
            type="text"
            fullWidth
            multiline
            rows={10}
            value={values.describe}
            onChange={(e: any) => {
              setFieldValue('describe', e.target.value);
            }}
          />
        </div>)} */}

        {/* {skillTests[currentSkillTest]?.sentForReview[1]?.userId !== state.currentUser.id && (<div style={{
          marginTop: '20px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <Button
            text={t('general.send')}
            onClick={handleSubmit}
            size={'medium'}
            buttonStyles={{
              borderRadius: '16px'
            }}
          />
        </div>)}
        <div style={{ height: '20px' }}></div> */}

        {renderedReviews.length > 0 ?
          renderedReviews.map((review) => {
            const isMyReview = state.currentUser.id === review.author.userId;
            return (
              <SkillTestReviewCard
                key={review.id}
                // skillTestId={currentSkillTest.id}
                profilePictureUrl=""
                isMyReview={isMyReview}
                refetch={refetch}
                review={review}
              />
            )
          }) : ""
        }
      </DrawerContent>
    </>
  );
}

export default StudentInfoPersonal;

const DrawerSubtitle = styled.div`
  font-size: 24px;
  color: #080A0A54;
  margin-bottom: 20px;

`
