import React, { useState } from 'react'
import { useFetchCourses, PER_PAGE } from 'hooks/course/useCourses'

import styled from 'styled-components'
import { FormFields, FormField, Label } from 'components/common/Form/Form'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import { generateFullName } from 'utils/utils'
import { SelectField } from 'components/common/SelectField'
import { mainColor } from 'components/common/Colors/CommonColors'

const CreateGroup = ({
  handleSubmit,
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
  adminList,
  companyId,
  addGroupLoading,
  t,
}: any) => {
  const [currentPage] = useState<number>(1)
  const [perPage] = useState<number>(PER_PAGE)

  const query: any = {
    companyId: {
      type: 'exact',
      value: companyId,
    },
    isBought: {
      type: 'bool',
      value: 'true',
    },
  }

  const { courses, loading, refetch, error, fetchMore } = useFetchCourses(
    query,
    currentPage,
    perPage,
  )

  const courseList = (courses && courses.data) || []

  const admins = adminList.map((i: any) => ({
    label: generateFullName(i),
    value: i.id,
  }))

  const courseData = courseList.map((i: any) => ({
    label: i.name,
    value: i.id,
    state: i.state,
  }))

  return (
    <Container>
      <FormFields>
        <FormField>
          <TextInput
            error={!!errors.name}
            errorMessage={errors.name}
            touched={touched.name}
            label={`${t('form_fields.name')}*`}
            name="name"
            size="medium"
            type="text"
            value={values.name}
            onChange={handleChange}
          />
        </FormField>

        <FormField>
          <TextInput
            label={t('form_fields.location')}
            name="location"
            size="medium"
            type="text"
            value={values.location}
            onChange={handleChange}
          />
        </FormField>

        <FormField style={{ position: 'relative' }}>
          {values.admin && <Label>{`${t('general.admin')}*`}</Label>}
          <SelectField
            error={!!errors.admin}
            errorMessage={errors.admin}
            touched={touched.admin}
            placeholder={`${t('general.admin')}*`}
            name="admin"
            options={admins}
            value={values.admin}
            onChange={(e: any) => setFieldValue('admin', e)}
            isClearable
          />
        </FormField>

        <FormField style={{ position: 'relative' }}>
          {values.courses && values.courses.length > 0 && (
            <Label>{t('general.courses')}*</Label>
          )}
          <SelectField
            error={!!errors.courses}
            errorMessage={errors.courses}
            touched={touched.courses}
            placeholder={`${t('general.courses')}*`}
            name="courses"
            options={courseData}
            value={values.courses}
            onChange={(e: any) => setFieldValue('courses', e)}
            isClearable
            isMulti
          />
        </FormField>

        <FormField>
          <TextInput
            label={t('form_fields.note')}
            size="medium"
            name="note"
            type="text"
            multiline
            rows="3"
            value={values.note}
            onChange={handleChange}
            fullWidth={true}
          />
        </FormField>

        <FormField>
          <ButtonContainer>
            <ButtonItem style={{ marginRight: '10px' }}>
              <Button
                text={t('company_wizard_layout.save_and_create_another')}
                type="medium"
                onClick={() => handleSubmit('reset')}
                isDisabled={addGroupLoading}
                background="#ffffff"
                textColor="#080A0A8A"
                outline="1px solid #080A0A14"
              />
            </ButtonItem>

            <ButtonItem>
              <Button
                text={t('company_wizard_layout.save_and_review')}
                type="medium"
                onClick={() => handleSubmit('review')}
                isDisabled={addGroupLoading}
                color="secondary"
                background={'#317BF4'}
              />
            </ButtonItem>
          </ButtonContainer>
        </FormField>
      </FormFields>
    </Container>
  )
}

export default CreateGroup

const Container = styled.div`
  box-sizing: border-box;
  padding: 0px 30px;
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 30px;
`

const ButtonItem = styled.div`
  width: 100%;
  div {
    width: 100%;

    button {
      border-radius: 8px !important;
    }
  }
`
