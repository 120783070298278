import { gql } from 'graphql.macro'

export const SET_NOTIFICATION_VIEW_STATUS = gql`
  mutation($ids: [String]) {
    setNotificationViewStatus(ids: $ids) {
      message
    }
  }
`
export const GET_NOTIFICATIONS = gql`
  query($skip: Int!, $count: Int!) {
    getNotifications(skip: $skip, count: $count) {
      totalCount
      hasMore

      data {
        message
        id
        courseId
        actionType
        view
        actionName
        actionId

        # actionUser
        objectName
        state
        createDate
        additionalText
        company {
          name,
          id
        }
        owner {
          id
          firstName
          lastName
          email
          avatar
          role
          companyId
        }
      }
    }
  }
`
export const GET_NOTIFICATION_COUNT = gql`
  query {
    getNotificationCount {
      numberOfNotifications
    }
  }
`
export const NOTIFICATION_LISTENER = gql`
  subscription {
    registerNotification {
      message
    }
  }
`
export const READ_NOTIFICATIONS = gql`
  mutation($ids: [String], $action: String) {
    readNotification(ids: $ids, action: $action) {
      message
    }
  }
`
export const DELETE_NOTIFICATIONS = gql`
  mutation($ids: [String], $action: String) {
    deleteNotifications(ids: $ids, action: $action) {
      message
    }
  }
`
