import useSnackbarAlert from 'hooks/useSnackbar';
import { snackbarProps } from 'interfaces/snackbar';
import { useTranslation } from 'react-i18next';
import retakeQuizService from 'services/quizzes/retakeQuizService';


const useRetakeQuizService = () => {
  const { setSnackbar } = useSnackbarAlert()
  const {t} = useTranslation()

  const { retakeQuizMutation, loading } = retakeQuizService()

  const retakeQuiz = async (
    userId: string,
    courseId: string,
    quizId: string
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await retakeQuizMutation({
      variables: {
        userId,
        courseId,
        quizId
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = t('quiz_details.retake_quiz')
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }
  return {
    retakeQuiz,
    loading,
  }
}

export default useRetakeQuizService