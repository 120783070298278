import styled from 'styled-components'

export const CommentMenu = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;


  &:hover {
    background-color: #317BF4;
    border-radius: 4px;
  }

  &:hover:before {
    display: block;
  }

  &:hover:after {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::after{
    content: '${props => props.title ? props.title : ''}';
    display: none;
    width: 183px;
    padding: 9px 15px 6px;
    background-color: #317BF4;
    position: absolute;
    font-weight: 700;
    top: -56px;
    left: -82px;
    font-size: 12px;
    border-radius: 8px;
    color: #fff;
  }

  &::before {
    content: '';
    display: none;
    position: absolute;
    top: -25px;
    left: -2px;

    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #317BF4;
  }
`

export const CutomTextArea = styled.textarea`
  resize: none;
  width: 100%;
  height: 130px;
  border: 1px solid #080A0A08;
  outline: none;
  padding: 20px;
  border-radius: 8px;
 `

export const SaveCommentButton = styled.button`
  width: 166px;
  height: 51px;
  background-color: #317BF4;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 16px;
  cursor: pointer;
`