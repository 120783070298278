import useOutsideClick from 'helpers/useOutsideClick'
import React, { useRef, useState } from 'react'
import strCutter from 'utils/strCutter'
import MoreIcon from '@material-ui/icons/More'
import styled from 'styled-components'
import { StyledItem } from '../CourseDetailContent'
import { useTheme } from '@material-ui/core'

const RenderCompany: React.FC<{
  data: any
  handleCompanyOpen: (id: string) => void
}> = ({ data, handleCompanyOpen }) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef()

  useOutsideClick(ref, () => {
    if (isOpen) setIsOpen(false)
  })

  const companyList = data || []
  const theme = useTheme()
  return (
    <CompanyContainer>
      {companyList.slice(0, 5).map((i: any) => (
        <StyledItem theme={theme} onClick={() => handleCompanyOpen(i.id)}>
          {strCutter(i.name, 17)},
        </StyledItem>
      ))}

      {companyList.length > 2 && (
        <StyledButtonWrapper
          ref={ref}
          onClick={(e: any) => {
            setIsOpen(!isOpen)
          }}
        >
          <MoreIcon
            fontSize="small"
            color="primary"
            style={{ marginTop: '3px' }}
          />
          {isOpen && (
            <StyledMoreContainer onClick={(e: any) => e.stopPropagation()}>
              {companyList.slice(5, companyList.length).map((i: any) => (
                <StyledItem theme={theme} onClick={() => handleCompanyOpen(i.id)}>
                  {strCutter(i.name, 25)},
                </StyledItem>
              ))}
            </StyledMoreContainer>
          )}
        </StyledButtonWrapper>
      )}
    </CompanyContainer>
  )
}

export default RenderCompany

const CompanyContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
const StyledButtonWrapper = styled.div<{ ref: any }>`
  margin-left: 5px;
  position: relative;
  cursor: pointer;
`

const StyledMoreContainer = styled.div`
  position: absolute;
  bottom: 0px;
  transform: translateY(100%);
  background: #fff;
  width: 200px;
  z-index: 9;
  padding: 7px;
  border-radius: 3px;
`
