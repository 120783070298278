import React from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from 'components/common/TextInput'
import CancelIcon from '@material-ui/icons/Cancel'
import CircularProgress from '@material-ui/core/CircularProgress'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import styled from 'styled-components'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import HeadsetIcon from '@material-ui/icons/Headset'
import { mainColor } from '../Colors/CommonColors'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/core'

const Loading = (props: any) => {
  const { type, loading } = props
  if (['video', 'audio'].includes(type)) {
    return (
      <div style={{ marginLeft: '19px', marginTop: '2px' }}>
        {Math.round(loading)} %
      </div>
    )
  }

  return (
    <div style={{ marginLeft: '19px', marginTop: '2px' }}>
      <CircularProgress style={{ width: '32px', height: '32px' }} />
    </div>
  )
}

const Uploader = (props: any) => {
  const theme = useTheme()

  const { t } = useTranslation()
  const filePreview = () => {
    switch (props.type) {
      case 'image':
        return (
          <ImagePlaceholder>
            <img src={props.uploadedFile.link} alt="" />
          </ImagePlaceholder>
        )
      case 'video':
        return (
          <div style={{ marginLeft: '23px', marginTop: '3px' }}>
            <OndemandVideoIcon color="secondary" />
          </div>
        )
      case 'audio':
        return (
          <div style={{ marginLeft: '23px', marginTop: '3px' }}>
            <HeadsetIcon color="secondary" />
          </div>
        )
      default:
        return (
          <div style={{ marginLeft: '23px', marginTop: '3px' }}>
            <InsertDriveFileIcon color="secondary" />
          </div>
        )
    }
  }

  return (
    <Container theme={theme}>
      <ItemContainer>
        {!props.uploadedFile && !props.loading && (
          <InputFileContainer>
            <UploadInput
              type="file"
              name={props.name}
              id={props.id}
              onChange={props.onFileChange}
              multiple={props.multiple}
              accept={props.accept}
            />
            <InputLabel htmlFor={props.id}>
              <Icon
                filename={'upload'}
                // fill={'#080A0A85'}
                fill={theme.palette.text.secondary}
                width={21}
                height={21}
              />
            </InputLabel>
          </InputFileContainer>
        )}

        {props.loading && <Loading type={props.type} loading={props.loading} />}

        {props.uploadedFile && filePreview()}
      </ItemContainer>

      <ItemContainer>

        <div style={{ fontSize: '16px', color: theme.palette.text.secondary }}>
          {props.label}
        </div>
      </ItemContainer>

      {props.uploadedFile && (
        <CancelButton onClick={props.deleteFile}>
          <CancelIcon style={{ color: mainColor }} />
        </CancelButton>
      )}
    </Container>
  )
}

export default Uploader

const Container = styled.div`
  width: 100%;
  height: 90px;
  border: 3px dashed #080a0a1f;
  border-radius: 8px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  position: relative;
  gap: 30px;

`

const ItemContainer = styled.div``

const CancelButton = styled.div`
  position: absolute;
  right: -12px;
  top: -12px;
  cursor: pointer;
`

const InputFileContainer = styled.div`
  position: relative;
  /* input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    overflow: hidden;
  }

  &label {
    width: 85%;
    height: 100%;
    cursor: pointer;
  } */
`

const UploadInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  overflow: hidden;
`

const Label = styled.div`
  position: absolute;
  left: 11px;
  top: -9px;
  background: #fff;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
`

const ImagePlaceholder = styled.div`
  img {
    position: relative;
    width: 34px;
    height: 33px;
    margin-left: 18px;
    top: 2px;
  }
`

const InputLabel = styled.label`
  width: 50px;
  height: 50px;
  background: #080a0a0a;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

`
