import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import { GET_COUCH_RATING } from 'gql/couch/couch.query'

import {
  Row,
  DetailsContentCoachWrapper,
  DetailsContentCoachInfo,
  Column,
  DetailsContentCoachFullName,
  DetailsContentReviewList,
} from 'components/common/DetailsLayout'
import Rating from 'components/common/Rating'
import { FakeUserImage } from 'components/common/DetailsLayout/styled-components'

interface CouchItemI {
  avatar?: string
  email: string
  firstName: string
  id: string
  lastName: string
  userId: string
  coachType?: string
}

interface CouchI {
  coach: CouchItemI
}

const CoachesItem = ({
  coach: { avatar, firstName, id, lastName, userId, coachType },
}: CouchI) => {
  const history = useHistory()
  const { data, loading } = useQuery(GET_COUCH_RATING, {
    variables: { ratedId: userId },
  })

  if (loading) {
    return <div>loading</div>
  }

  const coachRating = data?.getReviews

  return (
    <DetailsContentCoachWrapper key={id}>
      <Row>
        {avatar ? (
          <img
            src={avatar}
            style={{
              width: 48,
              height: 48,
              cursor: 'pointer',
              borderRadius: '16ox',
            }}
            onClick={() => history.push(`/profile/${userId}`)}
            alt=""
          />
        ) : (
          <FakeUserImage onClick={() => history.push(`/profile/${userId}`)} />
        )}
        <DetailsContentCoachInfo>
          <Column>
            <DetailsContentCoachFullName>
              {firstName} {lastName}
            </DetailsContentCoachFullName>
            <span>{coachType}</span>
            <div style={{ display: 'flex' }}>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <Rating
                  isControled={false}
                  value={coachRating?.averageRating}
                  size="small"
                />
                <DetailsContentReviewList>
                  <a href="#">{coachRating?.totalReviews} Reviews </a>
                </DetailsContentReviewList>
              </div>
            </div>
          </Column>
        </DetailsContentCoachInfo>
      </Row>
    </DetailsContentCoachWrapper>
  )
}

export default CoachesItem
