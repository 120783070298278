import gql from 'graphql-tag'

export const GET_ALL_SKILLS = gql`
  query {
    getAllSkills {
      label
      id
    }
  }
`
export const GET_ALL_INDUSTRY = gql`
  query {
    getAllIndustry {
      label
      id
    }
  }
`