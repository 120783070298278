import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { mainColor } from './../Colors/CommonColors'

export interface ICircularProgressProps {
  progress: number
  size?: number
  strokeWidth?: number
  progressStrokeWidth?: number
  endCircleRadius?: number
  endCircleStrokeWidth?: number
  radius?: number
  animateTiming?: number
  strokeColor?: string
  innerFontSize?: number
  firstColor?: string
  secondColor?: string
}

export const CircularProgressBarWithLabel: React.FC<ICircularProgressProps> = props => {
  const [isProgressShown, setIsProgressShown] = useState(
    !props.animateTiming ? true : false,
  )
  useEffect(() => {
    if (!props.animateTiming) return
    let isCancelled = false
    setTimeout(() => {
      if (isCancelled) return
      setIsProgressShown(true)
    }, 0)
    return () => {
      isCancelled = true
    }
  }, [props.animateTiming])
  const strokeWidth = props.strokeWidth || 3
  const backgroundStrokeWidth = props.progressStrokeWidth || 8
  const width = props.size || 100
  const height = props.size || 100
  const radius = props.radius || width * 0.4 - strokeWidth / 2

  const circleLength = 2 * radius * Math.PI
  const progressLength = circleLength * (1 - props.progress / 100)

  return (
    <ProgressContainer>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={{ transform: 'rotate(-90deg)' }}
      >
        <defs>
          <linearGradient id="linear1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={props.firstColor || mainColor} />
            <stop offset="100%" stopColor={props.secondColor || mainColor} />
          </linearGradient>
        </defs>
        <circle
          cx={width / 2}
          cy={height / 2}
          r={radius}
          fill="none"
          stroke={props.strokeColor || 'transparent'}
          strokeWidth={strokeWidth}
        />
        <circle
          cx={width / 2}
          cy={height / 2}
          r={radius}
          fill="none"
          stroke={'url(#linear1)'}
          strokeWidth={backgroundStrokeWidth}
          strokeDasharray={circleLength}
          strokeDashoffset={progressLength}
          // strokeLinecap="round"
          style={{
            transition: props.animateTiming
              ? props.animateTiming / 1000 + 's'
              : '0s',
            strokeDashoffset: !isProgressShown
              ? circleLength + 'px'
              : progressLength + 'px',
          }}
        />
      </svg>
      <InnerBody
        style={{
          width: 2 * radius - strokeWidth,
          height: 2 * radius - strokeWidth,
          top: height / 2 + strokeWidth / 2 - radius,
          left: width / 2 + strokeWidth / 2 - radius,
          fontSize: props.innerFontSize || 18,
        }}
      >
        {props.children || `${props.progress}%`}
      </InnerBody>
    </ProgressContainer>
  )
}

const ProgressContainer = styled.div`
  position: relative;
  display: inline-block;
`
const InnerBody = styled.div`
  display: flex;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: bold;
  font-size: 18px;
  color: ${mainColor};
`
