import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Rating } from '@material-ui/lab'
import { GET_REVIEWS } from 'gql/review/review.query'
import React, { FC, useState } from 'react'
import styled from 'styled-components'
import ProfileReviewRatings from './ProfileReviewRatings'
import { useFetchUsers } from 'hooks/users/useUsers'
import { Button } from 'components/common/Button'
import Icon from 'components/Icon'
import Modal from 'components/common/Modal'
import Drawer from 'components/common/Drawer'
import Review from 'pages/companies/CourseReview/Components/Review'
import { GlobalUserDefaultImage } from 'utils/utils'
import { useTranslation } from 'react-i18next'
import { useUserValue } from 'context/UserContext'

interface Props {

}

const ProfileReview: FC<Props> = () => {
  const [state] = useUserValue();
  const [isModalOpened, setIsModalOpened] = useState(false);

  const params: any = useParams()
  const { data, refetch } = useQuery(GET_REVIEWS, {
    variables: {
      ratedId: params.id,
    }
  })

  const { users } = useFetchUsers('COACH', {
    _id: { type: 'exact', value: params.id }
  })

  const { t } = useTranslation()

  const handleModalClose = () => {
    setIsModalOpened(false);
  };


  return (
    <>
      <Header>
        <HeadWrapper>
          <img src={users?.data[0]?.avatar || GlobalUserDefaultImage} alt="profilepicture" style={{ width: '170px', height: '170px', marginRight: '10px', borderRadius: '16px' }} />
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', marginLeft: '20px' }}>
            <Title>{`${users?.data[0]?.firstName} ${users?.data[0]?.lastName}`}
              <p style={{ color: '#080A0A36', fontSize: '14px', display: 'flex', alignItems: 'flex-start', margin: '5px 0 0 0' }}>{users?.data[0]?.role}</p>
            </Title>
            <Title style={{ marginRight: '5px' }}>{data?.getReviews.averageRating}
              {<Rating precision={0.1} value={data?.getReviews?.averageRating || '0.0'} disabled />}
              <p style={{ color: '#080A0A36', fontSize: '14px', display: 'flex', alignItems: 'flex-start', margin: '0' }}>{data?.getReviews.count} {t('general.review')} {'>'}</p>
            </Title>
          </div>
        </HeadWrapper>
      </Header>
      <Wrapper>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: data?.getReviews?.canReviewCoach && !data.getReviews.isRatedByMe ? 'space-between' : 'center', width: '50%' }}>
          <SmallerTitle>{t("general.reviews")}</SmallerTitle>
          {
            data?.getReviews?.canReviewCoach && !data.getReviews.isRatedByMe && (
              <Button
                buttonStyles={{ padding: '20px 40px', borderRadius: '16px' }}
                text={t('general.write_review')}
                icon={<Icon filename={'edit'} fill="#fff" />}
                onClick={() => setIsModalOpened(true)}
              />
            )
          }
        </div>
        {data?.getReviews?.data?.map((item: any, index: number) => {
          return (
            <ProfileReviewRatings
              key={index}
              ProfileUrl={item?.author?.userId}
              Appraiser={`${item?.author?.firstName || ''} ${item?.author?.lastName || ''}`}
              ProfilePicture={item?.author?.avatar}
              Description={item?.description}
              RatingNum={item?.rate}
              isReviewedByMe={state.currentUser?.id === item?.author?.userId}
              reviewId={item?.id}
              refetch={refetch}
            />
          )
        })}
      </Wrapper>
      <Modal isOpened={isModalOpened} onClose={handleModalClose}>
        <Drawer
          opened={isModalOpened}
          toggleDrawer={handleModalClose}
          totalWidth="970px">
          <Review
            refetch={refetch}
            isRatedByMe={data?.getReviews?.isRatedByMe}
            model='user'
            drawerTitle={t('general.coach_review')}
            onModalClose={handleModalClose}
            courseName={`${users?.data[0]?.firstName} ${users?.data[0]?.lastName}`}
            ratedId={params?.id}
          />
        </Drawer>
      </Modal>
    </>
  )
}

export default ProfileReview

const HeadWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
`

const SmallerTitle = styled.div`
  font-size: 24px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 50px 0px;
  height: 170px;

  .secondary-title {
    color: #080a0a8a;
    font-size: 16px;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
