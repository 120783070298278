import i18n from 'i18n/index'

export let IQTestTypes: any = []
export let filterDefault: any = {}
export let filterOptions: any = {}
export let formData: any = {}

function translate() {
  formData = {
    name: '',
    description: '',
    timeType: '',
    time: '',
    quizParticipationType: 'mandatory',
    passRate: '',
    feedback: false,
    tags: [],
    position: {
      label: i18n.t('quiz_details.final'),
      value: 'final',
    },
    randomization: false,
    randomizationNumber: '',
  }
  IQTestTypes = [
    {
      label: i18n.t('quiz_details.initial'),
      value: 'initial',
    },
    {
      label: i18n.t('quiz_details.final'),
      value: 'final',
    },
  ]

  filterDefault = {
    quizType: [
      { label: i18n.t('general.all'), id: 1, value: 'all' },
      { label: i18n.t('general.course'), id: 2, value: 'course' },
      { label: i18n.t('general.module'), id: 3, value: 'module' },
      { label: i18n.t('general.lesson'), id: 3, value: 'lesson' },
      { label: i18n.t('general.topic'), id: 3, value: 'topic' },
    ],
    quizParticipationType: [
      { label: i18n.t('general.all'), id: 1, value: 'all' },
      { label: i18n.t('general.mandatory'), id: 2, value: 'mandatory' },
      { label: i18n.t('general.optional'), id: 3, value: 'optional' },
    ],
    timeType: [
      { label: i18n.t('general.all'), id: 1, value: 'all' },
      { label: i18n.t('general.estimated'), id: 2, value: 'estimated' },
      { label: i18n.t('general.limited'), id: 3, value: 'limited' },
    ],
    feedback: [
      { label: i18n.t('general.all'), id: 1, value: 'all' },
      { label: i18n.t('general.on'), id: 2, value: true },
      { label: i18n.t('general.off'), id: 3, value: false },
    ],
  }

  filterOptions = {
    name: '',
    tags: null,
    quizType: { label: i18n.t('general.all'), id: 1, value: 'all' },
    quizParticipationType: {
      label: i18n.t('general.all'),
      id: 1,
      value: 'all',
    },
    timeType: { label: i18n.t('general.all'), id: 1, value: 'all' },
    feedback: { label: i18n.t('general.all'), id: 1, value: 'all' },
  }
}

translate()

i18n.on('languageChanged init', () => {
  translate()
})
