import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'

import { GET_ALL_INDUSTRY } from 'gql/skilss.query'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { FormFields, FormField } from 'components/common/Form/Form'
import TextInput from 'components/common/TextInput/TextInput'
import ReactSelect from 'react-select'
import countries from 'countries'
import Tooltip from '@material-ui/core/Tooltip'
import HelpIcon from '@material-ui/icons/Help'
import CreatableSelect from 'react-select/creatable'

const CompaniesWizardPage1 = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
}: any) => {
  const { data } = useQuery(GET_ALL_INDUSTRY)

  const activityList =
    (data &&
      data.getAllIndustry.map((i: any) => ({ label: i.label, value: i.id }))) ||
    []

  return (
    <FormFields>
      <FormField width="48%">
        <TextInput
          error={errors.name ? true : false}
          errorMessage={errors.name ? errors.name : ''}
          touched={touched.name}
          label="Company Title*"
          name="name"
          size="small"
          type="text"
          value={values.name}
          onChange={handleChange}
          // onBlur={handleBlur}
        />
      </FormField>

      <FormField width="48%">
        <TextInput
          error={errors.keyContactName ? true : false}
          errorMessage={errors.keyContactName ? errors.keyContactName : ''}
          touched={touched.keyContactName}
          label="Key Contact Name*"
          name="keyContactName"
          size="small"
          type="text"
          value={values.keyContactName}
          onChange={handleChange}
          // onBlur={handleBlur}
        />
      </FormField>

      <FormField width="48%">
        <TextInput
          error={errors.companyEmail ? true : false}
          errorMessage={errors.companyEmail ? errors.companyEmail : ''}
          touched={touched.companyEmail}
          label="Key Contact E-mail*"
          name="companyEmail"
          size="small"
          type="text"
          value={values.companyEmail}
          onChange={handleChange}
          // onBlur={handleBlur}
        />
      </FormField>

      <FormField width="48%">
        <TextInput
          error={errors.phone ? true : false}
          errorMessage={errors.phone ? errors.phone : ''}
          touched={touched.phone}
          label="Key Contact Phone Number*"
          name="phone"
          size="small"
          type="text"
          value={values.phone}
          onChange={handleChange}
          // onBlur={handleBlur}
        />
      </FormField>

      <FormField width="48%">
        <CreatableSelect
          // isMulti
          onChange={(e: any) => setFieldValue('companyActivity', e)}
          options={activityList}
          value={values.companyActivity}
          placeholder="Industry*"
        />
      </FormField>
      <FormField width="48%">
        <TextInput
          error={errors.numberOfUsers ? true : false}
          errorMessage={errors.numberOfUsers ? errors.numberOfUsers : ''}
          touched={touched.numberOfUsers}
          label="Number of users"
          name="numberOfUsers"
          size="small"
          type="text"
          value={values.numberOfUsers}
          onChange={handleChange}
          // onBlur={handleBlur}
        />
      </FormField>
      <FormField width="48%">
        <ReactSelect
          placeholder="Country"
          name="country"
          options={countries}
          value={values.country}
          onChange={(e: any) => setFieldValue('country', e)}
          isClearable
        />
      </FormField>
      <FormField width="48%">
        <TextInput
          error={errors.city ? true : false}
          errorMessage={errors.city ? errors.city : ''}
          touched={touched.city}
          label="City"
          name="city"
          size="small"
          type="text"
          value={values.city}
          onChange={handleChange}
          // onBlur={handleBlur}
        />
      </FormField>
      <FormField width="48%">
        <TextInput
          label="Postal code"
          name="postalCode"
          size="small"
          type="text"
          value={values.postalCode}
          onChange={handleChange}
        />
      </FormField>
      <FormField width="48%">
        <TextInput
          error={errors.address ? true : false}
          errorMessage={errors.address ? errors.address : ''}
          touched={touched.address}
          label="Address"
          name="address"
          size="small"
          type="text"
          fullWidth={true}
          value={values.address}
          onChange={handleChange}
        />
      </FormField>

      <FormField>
        <TextInput
          error={errors.note ? true : false}
          errorMessage={errors.note ? errors.note : ''}
          touched={touched.note}
          label="Note"
          name="note"
          size="small"
          type="text"
          fullWidth
          multiline
          rows="3"
          value={values.note}
          onChange={handleChange}
        />
      </FormField>

      <FormField width="30%">
        <ItemWrapper>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.platformService}
                onChange={handleChange}
                name="platformService"
                color="primary"
              />
            }
            label="Platform Service"
          />

          <Tooltip
            title={
              <span style={{ fontSize: '14px' }}>
                If this checkbox is ticked, Company can create their own
                Courses. If it is not ticked, Company can only view the
                purchased Courses and the ones in the Marketplace and purchase
                them. They can add their Groups, Students, Admins and Coaches in
                these Courses anyway.
              </span>
            }
            arrow
          >
            <HelpIcon color="primary" />
          </Tooltip>
          {/* <HelpButtonContainer>
          </HelpButtonContainer> */}
        </ItemWrapper>
      </FormField>
    </FormFields>
  )
}

export default CompaniesWizardPage1

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
