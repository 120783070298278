import React, { useRef } from 'react'
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'
import { XYCoord } from 'dnd-core'
import itemTypes, { DndItemProps, DragItem } from './types'
import TableRow from '@material-ui/core/TableRow'

const DndItem: React.FunctionComponent<DndItemProps> = ({
  id,
  index,
  moveCard,
  cardIds = [],
  isTable = false,
  isNested = false,
  className = '',
  children,
  onEnd = () => { },
}) => {
  const ref = useRef<any>(null)
  const [, drop] = useDrop({
    accept: itemTypes.CARD,
    hover(item: any, monitor: DropTargetMonitor) {
      const dragItem = item as DragItem;

      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current!.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      // moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
      item.hoverIndex = dragIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: itemTypes.CARD,  // <-- Moved to the top level
    item: { id, index },
    previewOptions: {},
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: async (item: any) => {


      const diff: number = item.hoverIndex - item.index

      let keyIndex: number | null = null



      if (Math.abs(diff) === 1) {

        keyIndex = (diff > 0) ? item.index : item.index;

      } else if (diff > 0) {

        keyIndex = item.index + 1;
      } else if (diff < 0) {

        keyIndex = item.index - 1;
      }

      // if (Math.abs(diff) === 1) {
      //   keyIndex = item.hoverIndex
      // }
      // else if (diff > 0) {
      //   keyIndex = item.index + 1
      // } else if (diff < 0) {
      //   keyIndex = item.index - 1
      // }


      const droppedId =
        cardIds.find((i: string, ind: number) => ind === keyIndex) || item.id

      if (droppedId !== item.id) {

        isNested
          ? onEnd(item.id, droppedId)
          : onEnd(item.id, droppedId || item.id)
      }
    },
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return isTable ? (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={id}
      ref={ref}
      style={{ opacity }}
      className={className}
    >
      {children}
    </TableRow>
  ) : (
    <div style={{ opacity }} key={id} ref={ref}>
      {children}
    </div>
  )
}

export default DndItem
