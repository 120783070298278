import React, { useEffect, useRef } from 'react'
import Hls from 'hls.js'
import styled from 'styled-components'

const VideoPlayer = (props: any) => {
  const player = useRef<any>()
  const hlsRef = useRef<any>()

  useEffect(() => {
    const video = player.current
    if (Hls.isSupported()) {
      hlsRef.current = new Hls()
      hlsRef.current.attachMedia(video)
      hlsRef.current.on(Hls.Events.MEDIA_ATTACHED, () => {
        hlsRef.current.loadSource(props.url)
        // hlsRef.current.loadLevel = level
        hlsRef.current.on(
          Hls.Events.MANIFEST_PARSED,
          (event: any, data: any) => {
            hlsRef.current.autoLevelEnabled = true
          },
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.url])

  return (
    <Container>
      <StyledVideo
        // ref={player}
        autoPlay={false}
        controls
        src={props.url}
      // playing
      // controls
      // autoLevelEnabled
      // pip
      // light
      />
    </Container>
  )
}

export default VideoPlayer

const Container = styled.div`
  /* background: #fff; */
`

const StyledVideo = styled.video<{ src: any; autoPlay: any }>`
  width: 100%;
  height: 380px;
  outline: none;
`
