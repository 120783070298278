import editModuleService from 'services/modules/editModuleService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useEditModuleService = (params: any) => {
  const { t } = useTranslation()
  const { editModuleMutation, loading } = editModuleService(params)
  const { setSnackbar } = useSnackbarAlert()

  const editModule = async (moduleId: string, input: any, action?: any) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await editModuleMutation({
      variables: {
        moduleId,
        input,
      },
    })

    if (errors && errors.length) {
      // message = errors[0].message
      message = t('messages.something_wrong')
      variant = 'error'
    } else {
      message = t('messages.changes_saved')
      variant = 'success'
    }

    setSnackbar({ message, variant })
    if (action) action()
  }

  return {
    editModule,
    loading,
  }
}

export default useEditModuleService
