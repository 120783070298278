import { Button } from "components/common/Button";
import Icon from "components/Icon";
import React, { FC, useState } from "react";
import * as S from './styled'
import { Text } from '../styled'
import { useQuery } from "@apollo/react-hooks";
import { GET_QUIZ_RESULT } from "gql/quiz/quiz.query";
import { useUserValue } from "context/UserContext";
import { CircularProgress } from '@material-ui/core'
import { red, successGreen } from "components/common/Colors/CommonColors";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next'


interface QuizzProps {
  quiz: any,
  courseId?: string,
  onQuizSelect?: (id: string) => void
  isStat?: boolean,
  userId?: string,
  isOwn?: boolean
}

const Quizz: FC<QuizzProps> = ({ quiz, onQuizSelect, courseId, isStat, userId, isOwn }) => {

  const [user, setUser] = useUserValue();
  const [quizResult, setQuizResult] = useState<{ passed: boolean, score: number, passRate: number, hasEverStarted: boolean }>();
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const { t } = useTranslation()


  useQuery(GET_QUIZ_RESULT, {
    variables: {
      quizId: quiz.id,
      courseId,
      userId: isStat ? userId : user.currentUser.id
    },
    onCompleted: data => setQuizResult(data.getQuizResult),
    onError: error => setIsError(true)

  })


  // if (!quizResult) return <CircularProgress />
  return (
    <S.SectionItem style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex' }}>
        <S.SectionIcon>
          <Icon filename='topic_quiz' />
        </S.SectionIcon>
        <S.SectionContent>
          <Text fs={12}>{quiz.name}</Text>
          <div className='content' style={{ margin: '4px 0' }}>
            <Text fs={'12px'} color={'#080A0A8A'}>{quiz.questionsCount} {t('general.question')}</Text>
            <div>
              {quiz.quizParticipationType === "mandatory" && (
                <S.Mandatory>
                  <Icon filename={'mandatory_warning'} style={{ marginRight: 5 }} />
                  <Text color={'#CC3131'} fs={13}>{t('general.mandatory')}</Text>
                </S.Mandatory>
              )}
            </div>
          </div>
        </S.SectionContent>
      </div>
      {quizResult && <div style={{
        display: 'flex',
        alignItems: 'center',
        width: "100%",
        justifyContent: (quiz.quizParticipationType == "mandatory" && quizResult.hasEverStarted) || ((quiz.quizParticipationType == "optional") && quizResult.hasEverStarted) && !isStat ? "space-between" : "right"
      }}>

        {quizResult.hasEverStarted && quizResult.passed &&
          (
            <>
              <div style={{
                textAlign: 'center',
                margin: '0 15px',
              }}>
                <Text
                  fs={24}
                  weight={700}
                  color={successGreen}
                >{quizResult.score}%</Text>
                <Text
                  fs={13}
                  color={successGreen}
                >{t('general.passed')}</Text>
              </div>
            </>
          )
        }
        {quizResult.hasEverStarted && !quizResult.passed &&
          <div style={{
            textAlign: 'center',
            margin: '0 15px',
          }}>
            <Text
              fs={24}
              weight={700}
              color={red}
            >{quizResult.score}%</Text>
            <Text
              fs={13}
              color={red}
            >{t('general.try_again')}</Text>
          </div>}

        {!isStat && !quizResult.hasEverStarted ? (<Button text={t('general.start')}
          textColor='#080A0A'
          background='#080A0A08'
          btnType='button'
          height={47}
          size={'large'}
          buttonStyles={{
            padding: 20,
            borderRadius: '16px',
          }}
          onClick={() => onQuizSelect && onQuizSelect(quiz.id)}
        />) :
          (quiz.quizParticipationType == "mandatory" && quizResult.hasEverStarted) || ((quiz.quizParticipationType == "optional") && quizResult.hasEverStarted) && !isStat ?
            (<Button text={t('general.tryAgain')}
              textColor='#fff'
              background='#317BF4'
              height={47}
              size={'large'}
              icon={
                <Icon filename={'quiz_retake'} />
              }
              buttonStyles={{
                padding: 20,
                borderRadius: '16px',
              }}
              onClick={() => onQuizSelect && onQuizSelect(quiz.id)}
            />)
            : null
        }

        {!quizResult.passed && user.currentUser.role !== 'STUDENT' && history.location.pathname.includes('user') && (
          <Button text={t('general.isNotPassed')}
            textColor='#080A0A'
            background='#080A0A08'
            btnType='button'
            height={47}
            size={'large'}
            buttonStyles={{
              padding: 20,
              borderRadius: '16px',
            }}
            icon={
              <Icon filename={'danger'} />
            }
            isDisabled={true}
            onClick={() => onQuizSelect && onQuizSelect(quiz.id)}
          />
        )}
      </div>}
    </S.SectionItem >
  );
}

export default Quizz;
