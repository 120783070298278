import React, { useState } from 'react'
import styled from 'styled-components'
import { SelectField } from 'components/common/SelectField'
import { Button } from 'components/common/Button'
import { filterOptions } from './staticData'
import DateRangePicker from 'components/common/DateRangePicker/DateRangePicker'
import { mainColor } from 'components/common/Colors/CommonColors'
import { Select, MenuItem } from '@material-ui/core'
import Icon from 'components/Icon'
import { useTheme } from '@material-ui/styles'

const FilterBar = (props: any) => {
  const { t } = props
  const [companyFilter, setCompanyFilter] = useState({
    value: 'all',
    label: t('general.all'),
  })

  const theme = useTheme();

  const [dateRange, setDateRange] = useState({ start: null, end: null })

  const handleDateSelect = (value: any, states: any) => {
    setDateRange(value)
  }

  return (
    <Container>
      <TitleContainer theme={theme}>{t('actions.filter')}</TitleContainer>

      <div style={{ width: '100%', maxWidth: '370px' }}>
        <DateRangePicker
          placeholder={t('general.date')}
          values={dateRange}
          onChange={handleDateSelect}
        />
      </div>

      <SelectContainer>
        {/* <SelectField
          options={filterOptions}
          value={companyFilter}
          onChange={(e: any) => setCompanyFilter(e)}
          placeholder={t('general.all')}
        /> */}
        <SSelect
          theme={theme}
          value={companyFilter.value}
          IconComponent={props => (
            <div {...props} style={{ right: '30px' }}>
              <Icon filename={'down_arrow'} />
            </div>
          )}
          onChange={(e: any) =>
            setCompanyFilter({
              value: e.target.value,
              label: e.target.name,
            })
          }
        >
          {filterOptions.map((option: any) => (
            <MenuItem value={option.value}>{option.label}</MenuItem>
          ))}
        </SSelect>
      </SelectContainer>

      <SSubbmit
        onClick={() => {
          props.setCompanyFilter(companyFilter)
          props.handleDateSelect(dateRange)
        }}
      >
        {t('actions.apply')}
      </SSubbmit>
    </Container>
  )
}

export default FilterBar

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  .MuiFormControl-marginNormal {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
`

const TitleContainer = styled.div`
  box-sizing: border-box;
  font-size: 32px;
  font-weight: medium;
  color: ${props => props.theme.palette.text.primary};
  text-transform: uppercase;
`

const SelectContainer = styled.div`
  width: 100%;
  max-width: 370px;
  box-sizing: border-box;
`

const SSelect = styled(Select)`
  width: 100%;
  height: 56px;
  border: 1px solid ${props => props.theme.palette.text.primary};
  border-radius: 8px;
  padding: 0px 30px;

  .MuiSelect-root {
    background: transparent;
  }

  &::before {
    content: '';
    display: none;
  }
`

const SSubbmit = styled.button`
  font-size: 16px;
  font-weight: medium;
  min-width: 170px;
  padding: 20px;
  color: #ffffff;
  border: none;
  background: #317bf4;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #314ef4;
  }
`
