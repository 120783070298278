import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SelectField } from 'components/common/SelectField'
import useEditGroup from 'hooks/group/useEditGroup'
import { useQuery } from '@apollo/react-hooks'
import { GET_GROUP } from 'gql/group/group.query'
import {
  FormGroupWrapper,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import { Button } from 'components/common/Button'
import { useTranslation } from 'react-i18next'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'

interface Props {
  values: any
  selectedGroup: any
  courseId?: string
  handleDeleteCourse?: any
  handleButtonClick?: any
  handleChangeStep?: any
}

const AssignComponent = ({ item, coaches, assignCoach, courseId }: any) => {
  const { t } = useTranslation()
  const [coach, setCoach] = useState<any>(null)
  useEffect(() => {
    const course = item.courses.find((i: any) => i.courseId === courseId)
    if (
      course.coach &&
      coaches.find((i: any) => i.value === course.coach.coachId)
    ) {
      setCoach({ label: course.coach.name, value: course.coach.coachId })
    } else {
      // assignCoach(item.id, null, courseId, () => {}, t)
    }
  }, [coaches, courseId, item.courses])
  return (
    <StyledItem>
      <StyledTitle>{item.name}</StyledTitle>
      <ItemContainer>
        <SelectField
          placeholder={t('general.coach')}
          options={coaches}
          isClearable
          // error={errors.gender && touched.gender ? true : false}
          // errorMessage={errors.gender ? errors.gender : ''}
          // touched={touched.gender}
          value={coach}
          onChange={(e: any) => {
            setCoach(e)
            assignCoach(
              item.id,
              e
                ? {
                  name: e.label,
                  coachId: e.value,
                  avatar: null,
                }
                : null,
              courseId,
              () => { },
              t,
            )
          }}
        />
      </ItemContainer>
    </StyledItem>
  )
}

const AssignCoach = (props: Props) => {
  const { t } = useTranslation()
  const { assignCoach } = useEditGroup()

  const { data } = useQuery(GET_GROUP, {
    variables: {
      filter: {
        query: {
          type: 'query',
          value: JSON.stringify({
            'courses.courseId': { $in: [props.courseId] },
          }),
        },
      },
      perPage: 0,
    },
  })

  const groups = (data && data.getAllGroups.data) || []

  // console.log('groups::', groups)
  // const groups = props.selectedGroup || []
  const coaches = props.values.coaches || []

  return (
    <>
      <FormGroupWrapper padding={10}>
        <StyledItemContainer>
          {/* {_.uniqBy(groups, (obj: any) => obj.groupId).map((i: any) => ( */}
          {groups.map((i: any) => (
            <AssignComponent
              item={i}
              coaches={coaches}
              assignCoach={assignCoach}
              courseId={props.courseId}
            />
          ))}
        </StyledItemContainer>
      </FormGroupWrapper>

      <FormFooter>
        <FormButtons multiCols={true}>
          <Button
            text={t('actions.cancel')}
            type="small"
            onClick={props.handleDeleteCourse}
            background="#E0E1E2"
            textColor={validationRed}
            outline={`1px solid ${red}`}
          />

          <Button
            text={t('actions.previous')}
            type="small"
            color="secondary"
            onClick={() => props.handleChangeStep(1)}
            background="#E0E1E2"
            textColor="#414141"
          />

          {props.values.finished === 2 && (
            <Button
              text={t('actions.save_as_draft')}
              type="small"
              color="secondary"
              onClick={() => props.handleButtonClick('draft')}
              textColor={mainColor}
              background="#f5f5f5"
              outline="1px solid #317BF4"
            />
          )}

          <Button
            text={
              props.values.finished === 3
                ? t('actions.save')
                : t('actions.finish')
            }
            type="small"
            color="secondary"
            onClick={() => props.handleButtonClick('next')}
            background={mainColor}
          />
        </FormButtons>
      </FormFooter>
    </>
  )
}

export default AssignCoach

const StyledItemContainer = styled.ul`
  margin-top: 20px;
  padding: 0;
`
const StyledItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 70px;
  margin-top: 20px;
`

const StyledTitle = styled.div``

const ItemContainer = styled.div`
  width: 210px;
`
