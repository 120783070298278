import { useMutation } from "@apollo/react-hooks"

import { DELETE_STUDENTS_FROM_GROUP } from "gql/group/group.query"

const deleteStudentsService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteStudents] = useMutation(DELETE_STUDENTS_FROM_GROUP)

  return {
    deleteStudents
  }
}

export default deleteStudentsService
