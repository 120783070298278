
import React, { FunctionComponent } from "react";
import { useDeleteNote, DeleteNote } from "hooks/notes/useDeleteNote";
import styled from 'styled-components'
import CIcon from "components/Icon";

type DeleteNoteProps = {
  noteId: string
  onCompleted: () => void
}

const StyledButton = styled.button`
  background: 'transparent',
  border: '1px solid #080A0A04',
  outline: 'none',
  padding: '15px',
  borderRadius: '8px',
  cursor: 'pointer'
`

const DeleteNoteButton: FunctionComponent<DeleteNoteProps> = ({ noteId, onCompleted }) => {
  const deleteNoteData: DeleteNote = {
    noteId,
    onCompleted
  };

  const { deleteNoteFunction, deleteNoteError, deleteNoteLoading } = useDeleteNote(deleteNoteData);

  const handleDeleteClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    deleteNoteFunction();
  };

  return (
    <StyledButton onClick={handleDeleteClick}>
      <CIcon filename="delete" fill="#CC3131" />
    </StyledButton>
  )
};

export default DeleteNoteButton;
