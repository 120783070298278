import React from 'react'
import strCutter from 'utils/strCutter'
import Checkbox from 'components/common/Checkbox'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import styled from 'styled-components'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import { generateFullName } from 'utils/utils'
import { GroupsWrapper } from 'components/common/DetailsLayout'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { NoValueWrapper } from 'pages/companies/CompanyDetails/styled-components'
import {
  StatusWrapper,
  StatusCircle,
  GroupItem,
  GroupTitle,
} from 'pages/companyWizard/CompanyStudents/gridConfig'
import SchoolIcon from '@material-ui/icons/School'
import { mainColor } from 'components/common/Colors/CommonColors'
import { CapitalPlatformTitle } from 'components/common/PlatformTitle/PlatformTitle'

interface itemProps {
  name: string
  description: string
  location: string
  isChecked?: boolean
  id: string
  last?: string
  numberOfStudents?: number | string
  admin: any
  created: string
  status: any
  companyId: any
  courses: any
  company: any
}

export const columnConfig = (
  handelClick: any,
  selectItem: any,
  t: any,
  onGroupClick?: any,
) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <Checkbox
          checked={item.isChecked}
          onChange={() => selectItem(item.id)}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('general.name'),
      render: (item: itemProps) => (
        <div onClick={() => handelClick(item.id)} style={{ cursor: 'pointer' }}>
          <Tooltip
            title={
              <>
                <div>{item.name}</div>
              </>
            }
          >
            <span
              style={{
                display: 'inline-block',
                cursor: 'pointer',
                marginLeft: 5,
              }}
            >
              {strCutter(item.name, 5)}
            </span>
          </Tooltip>{' '}
        </div>
      ),
    },
    {
      id: 'admin',
      numeric: true,
      disablePadding: false,
      label: t('general.admin'),
      render: (item: itemProps) => {
        return <div>{strCutter(generateFullName(item.admin), 30)}</div>
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item: itemProps) => (
        <StatusWrapper className={`${item.status?.toLowerCase()}`}>
          <StatusCircle />
          {item.status}
        </StatusWrapper>
      ),
    },
    {
      id: 'numberOfStudents',
      numeric: true,
      disablePadding: false,
      label: t('general.students'),
      render: (item: itemProps) => <div>{item.numberOfStudents || 0}</div>,
    },
    {
      id: 'courses',
      numeric: false,
      disablePadding: false,
      label: `${t('general.courses')}`,
      render: (item: itemProps) => {
        const courses = (item.courses && item.courses) || []
        const firstTwoCourses = courses.slice(0, 2)
        const exCourse = courses.slice(2, courses.length)
        return (
          <div>
            {firstTwoCourses.length === 0 && (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
            <GroupsWrapper>
              {courses.map(
                (course: any, index: number) =>
                  index < 3 && (
                    <GroupItem key={course.courseId}>
                      <IconButton onClick={() => onGroupClick(course.courseId)}>
                        <SchoolIcon fontSize="small" />
                      </IconButton>
                      <GroupTitle className="group-item-title">
                        {course.name}
                      </GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exCourse.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exCourse.map((exGroup: any) => (
                        <div>{exGroup.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: itemProps) => (
        <div>
          {item.company && item.company.name ? (
            strCutter(item.company.name, 25)
          ) : (
            <>
              {CapitalPlatformTitle}
              <StarsRoundedIcon
                style={{
                  color: mainColor,
                  fontSize: 10,
                  verticalAlign: 'super',
                }}
              />
            </>
          )}
        </div>
      ),
    },
    {
      id: 'created',
      numeric: true,
      disablePadding: false,
      label: t('general.create_date'),
      render: (item: itemProps) => (
        <div>{moment(item.created).format('DD.MM.YYYY')}</div>
      ),
    },
  ]

  return grid
}

export const actionConfig = (handleClick: any, t: any) => {
  const action = [
    {
      render: (item: any) => (
        <Action onClick={() => handleClick(item.id, 'edit')}>
          <EditRoundedIcon />
          <Typography>{t('actions.edit')}</Typography>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={() => handleClick([item.id], 'delete')}>
          <DeleteForeverRoundedIcon />
          <Typography>{t('actions.delete')}</Typography>
        </Action>
      ),
    },
  ]
  return action
}

export const groupActionConfig = (handleClick: any, t: any) => {
  let action = [
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('createGroup')}>
          <AddCircleRoundedIcon />
          <span>{t('actions.create_group')}</span>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('addGroup')}>
          <PlaylistAddIcon />
          <span>{t('actions.add_groups_to_course')}</span>
        </Action>
      ),
    },
  ]

  return action
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
`
