import React, { useContext, useEffect, useState } from 'react'
import * as S from './styled'
import { TabPanel } from 'components/common/Tabs/Tabs'
import Icon from 'components/Icon'
import { Button } from 'components/common/Button'
import { UPDATE_ASK_REVIEW } from "gql/review/review.query"

import { AllModules, GetCourseById, ModulesData, lastWatchedIndex } from './types'
import { useHistory, useLocation } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { useUserValue } from 'context/UserContext'
import { GET_MODULES } from 'gql/modules.query'
import { Box, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core'
import { GET_REVIEWS } from 'gql/review/review.query'
import AboutCourse from './AboutCourse'
import Module from './Module'
import WriteQuizz from 'pages/quizzes/IQQuizz/WriteQuizz'
import { GET_USER } from 'gql/users.query'
import { GET_PROGRESS_BY_USER_ID } from 'gql/progress/progress.query'
import { GET_SKILL_TEST_VIDEO_BY_MODULE_ID } from "gql/skillTest/skilltest.query"
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { LastWatchedContext } from './Module/StudentCourseContext'
import Modal from 'components/common/Modal'
import { Rating } from '@material-ui/lab'


const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    value: index,
  }
}

export type ContentProps = {
  course: GetCourseById
  disabled?: boolean
  isStat?: boolean
  userId?: string
  refetchCourseStatiscs?: () => any
}

const Content: React.FC<ContentProps> = props => {
  const history = useHistory()
  const [value, setValue] = React.useState(0)
  const [modules, setModules] = React.useState<ModulesData[] | null>(null)
  const [selectedQuizzId, setSelectedQuizzId] = useState<string>("")
  const [isQuizzSelected, setIsQuizzSelected] = useState(false)
  const [ownCourse, setOwnCourse] = useState(false)

  const params: any = useParams()
  // const [filteredCourse, setFilteredCourse] = useState([])
  const [updateAskReview, { data: review }] = useMutation(UPDATE_ASK_REVIEW)


  const [state] = useUserValue();
  const { t } = useTranslation()
  const location: { state?: { moduleId?: string }, pathname: string } = useLocation();

  useQuery<{ allModules: AllModules }>(GET_MODULES, {
    variables: {
      filter: {
        name: { type: 'match', value: '' },
        course: { type: 'nestedArrayIn', value: props.course.id },
      },
    },
    onCompleted: data => setModules(data.allModules.data),
  })

  useEffect(() => {
    state.currentUser.courses.some((course: any) => course.courseId == params.id) ? setOwnCourse(true) : setOwnCourse(false)
  }, [params])


  const { data: userData, refetch: refetchGetUser } = useQuery(GET_USER, {
    variables: {
      id: props.userId,

    },
    fetchPolicy: "network-only"
  });

  const { data: progress, refetch: refetchUserProgress } = useQuery(GET_PROGRESS_BY_USER_ID, {
    variables: {
      userId: props.userId,
    }
  });



  const [getUserProgress, { data: userProgress }] = useLazyQuery(GET_PROGRESS_BY_USER_ID, {
    variables: {
      userId: props.userId,
    },

  })



  const [getSkillTestVideosByModuleId, { data: skillTest }] = useLazyQuery(GET_SKILL_TEST_VIDEO_BY_MODULE_ID, {
    onCompleted: skillTest => {
      skillTest.getSkillTestVideosByModuleId.forEach((element: any, index: any) => {
        const index1 = element.skillTestVideos.findIndex((el: any) => el.id == params.skillId)

        if (index1 > 0) {

          setValue(index + 1)
          a11yProps(index + 1)
        } else {
          setValue(0)
        }
      })
    },
  })

  useEffect(() => {
    if (params.assignment === "assignment") {
      modules?.map(module => {
        getSkillTestVideosByModuleId({
          variables: {
            moduleId: module.id
          }
        })
      })
    }
  }, [params.skillId, modules])

  useEffect(() => {
    if (props.isStat) {
      // getUser()

      refetchGetUser();
      getUserProgress()

    }
  }, [])
  const { lastWatched, setLastWatched } = useContext(LastWatchedContext)


  useEffect(() => {
    if (!props.isStat && props.userId) {
      getUserProgress();
    }
  }, [])

  useEffect(() => {
    if (lastWatched?.show == true) {
      const { moduleIndex, topicIndex, lessonIndex } = lastWatched;
      setValue(moduleIndex + 1);
    }
  }, [lastWatched])


  useEffect(() => {
    if (modules && location?.state?.moduleId) {
      const desiredModuleIndex = modules.findIndex((module: ModulesData) => module.id === location?.state?.moduleId);
      if (desiredModuleIndex >= 0) {
        setValue(desiredModuleIndex + 1)
      }
    }
  }, [modules])

  const currentUser = userData?.user;
  const userTotalNotes = userData?.user.notes;

  let currentGroupId = '';
  const { role, group } = state?.currentUser;

  if (role === 'COACH') currentGroupId = props.course.groups?.[0]?.groupId || '';

  if (role === 'STUDENT' && group && props.course?.groups) {
    for (const gr of group) {
      const desiredGroupId = props.course.groups.find(g => g.groupId.toString() === gr.groupId.toString()) || null;
      if (desiredGroupId) {
        currentGroupId = gr.groupId.toString();
        break;
      }
    }
  }
  const { data } = useQuery(GET_REVIEWS, {
    variables: {
      ratedId: params.id ? params.id : params.courseId,
    },
  })
  const courseProgress = userProgress?.getProgressByUserId?.courses.find((item: any) => item.courseId === props.course.id);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));


  const filteredCourse = progress?.getProgressByUserId?.courses.filter((item: any) => {
    return item.courseId === params.id
  })



  useEffect(() => {
    if (filteredCourse?.length > 0 && filteredCourse[0].askReview && !location?.pathname.endsWith("/review")) {

      history.push("review")
    }
  }, [filteredCourse])

  if (!modules) return <CircularProgress />


  return !isQuizzSelected ? (
    <>

      {
        props.isStat && <S.Text style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          margin: '15px 0'
        }}
          fs={'1.5rem'}
          weight={'bold'}
        >{currentUser.firstName} {currentUser.lastName} {t("general.statistics")}</S.Text>
      }
      <S.ContentContainer>

        <S.STabs
          orientation={isSmallScreen ? 'vertical' : 'horizontal'}
          variant="scrollable"
          value={value}
          onChange={(e, value) => setValue(value)}
          aria-label="Vertical tabs example"
          style={{ borderRight: 1, borderColor: 'divider' }}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          <S.STab label={<span style={{ color: theme.palette.text.primary }} >{t("general.about_course")}</span>} {...a11yProps(0)} />
          {props.course.IQTests?.[0]?.id && (
            <S.QuizButton
              disabled={props.disabled}
              onClick={() =>
                history.push(
                  `/course/${props.course.id}/quizzdetails/${props.course.IQTests?.[0].id}`,
                )
              }
            >
              <Icon filename={'profile'} />
              {t("general.general_course_test")}
            </S.QuizButton>
          )}

          {props.isStat && modules?.map((module, index) => (
            <div key={module.id}
              onClick={() => setValue(index + 1)} {...a11yProps(index + 1)}
            >
              <S.STab
                label={<span style={{ color: theme.palette.text.primary }}>{t('general.module')} {index + 1}</span>}
              />
              <div style={{
                width: `${courseProgress.modules[index].progress}%`,
                height: '2px',
                background: '#317BF4'
              }} />
            </div>
          ))}

          {!props.isStat && courseProgress &&
            modules?.map((module, index) => {
              return (
                <div key={module.id} onClick={() => setValue(index + 1)} {...a11yProps(index + 1)}>
                  <S.STab label={<span style={{ color: theme.palette.text.primary }}>{t("general.module")} {index + 1}</span>} />
                  <div style={{
                    width: `${courseProgress.modules[index]?.progress}%`,
                    height: '2px',
                    background: '#317BF4'
                  }} />
                </div>
              )
            })}

          {!props.isStat && !courseProgress &&
            modules.map((module, index) => (
              <S.STab
                key={module.id}
                label={<span style={{ color: theme.palette.text.primary }}>{t("general.module")} {index + 1}</span>}
                {...a11yProps(index + 1)}
              />
            ))
          }

          {props.course.IQTests?.[1]?.id && (
            <S.QuizButton
              disabled={props.disabled}
              onClick={() =>
                history.push(
                  `/course/${props.course.id}/quizzdetails/${props.course.IQTests?.[1].id}`,
                )
              }
            >
              <Icon filename={'profile'} />
              {t("general.general_course_test")}
            </S.QuizButton>
          )}
        </S.STabs>
        <TabPanel value={value} index={0}>
          <AboutCourse course={props.course} disabled={props.disabled} isStat={props.isStat} />
        </TabPanel>
        {modules?.map((module, index) => (
          <TabPanel key={module.id} value={value} index={index + 1}>
            <Module
              disabled={props.disabled}
              isOwnCourse={props.course.isOwnCourse}
              courseId={props.course.originalId || props.course.id}
              originalId={props.course.originalId || props.course.id}
              moduleId={module.id}
              onQuizzSelect={(id: string) => {
                setSelectedQuizzId(id)
                setIsQuizzSelected(true);
              }}
              courseName={props.course.name}
              groupId={currentGroupId}
              isStat={props.isStat}
              moduleIndex={index}
              modules={modules}
              refetchCourseStatiscs={props.refetchCourseStatiscs}
              refetchGetUser={refetchGetUser}
              userTotalNotes={userTotalNotes}
            />
          </TabPanel>
        ))}
      </S.ContentContainer>
    </>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <WriteQuizz
        quizId={selectedQuizzId}
        courseId={props.course.id}
        originalId={props.course.originalId}
        onQuizzFinish={() => {
          setIsQuizzSelected(false)
        }}
      />
    </div>

  )
}

export default Content
