import React, { memo, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import FilterListIcon from '@material-ui/icons/FilterList'
import SyncIcon from '@material-ui/icons/Sync'
import Checkbox from 'components/common/Checkbox'
import DndItem from 'components/common/Grid/DndGrid/DndItem'
import {
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'
import Loader from 'components/common/Loader'
import GridActionMenu from './GridActionMenu/GridActionMenu'
import { TabNavigation } from '../Tabs/Tabs'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import NestedGrid from 'pages/modules/ModuleDetail/ModuleTopicsGrid/NestedGrid'
import strCutter from 'utils/strCutter'

type Order = 'asc' | 'desc'
interface Data {
  default: null
  title: number
  description: number
}

function descendingComparator<T extends Record<string, any>>(a: T, b: T, orderBy: keyof T) {
  if (orderBy === 'createdDate') {
    const val1: any = a["createDate"]
    const val2: any = b["createDate"]
    const dateA = new Date(val1);
    const dateB = new Date(val2);

    if (dateB < dateA) {
      return -1
    }
    if (dateB > dateA) {
      return 1
    }
  } else if (orderBy === 'paymentCount') {
    const val1: any = a.subscription?.paymentCount || 0
    const val2: any = b.subscription?.paymentCount || 0
    if (val2 < val1) {
      return -1
    }
    if (val2 > val1) {
      return 1
    }
  } else if (orderBy === 'subscription') {
    const val1: any = a.subscription?.productId?.price || 0
    const val2: any = b.subscription?.productId?.price || 0
    if (val2 < val1) {
      return -1
    }
    if (val2 > val1) {
      return 1
    }
  } else if (orderBy === 'courses') {
    if (b[orderBy] < a[orderBy]) {

      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
  } else {
    if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {

      return -1
    }
    if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
      return 1
    }
  }
  return 0
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {

  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}



function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {

  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number])
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis?.map(el => el[0])
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  numSelected: number
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void
  onSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
  order: Order
  orderBy: string
  rowCount: number
  config: any
  selectAllItem: any
  actionMenu?: boolean
  allSelected?: boolean
  disabledSelect?: boolean
  setFetchAll?: any
  fetchAll?: any
  transactions?: boolean

}

function EnhancedTableHead(props: EnhancedTableProps) {

  const { t } = useTranslation()
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    allSelected,
    disabledSelect,
    fetchAll
  } = props
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>,
  ) => {
    // alert("Asd")
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {props.config?.map((headCell: any) => {
          // if (headCell.id === 'isChecked') {

          //   return (
          //     <TableCell padding="none" key={headCell.id}>
          //       <div
          //         style={{
          //           visibility: disabledSelect ? 'hidden' : 'visible',
          //           display: 'flex',
          //           alignItems: 'center',
          //           gap: '10px',
          //         }}
          //       >
          //         <Checkbox
          //           checked={fetchAll}
          //           disabled={rowCount === 0}
          //           onChange={() => {
          //             props.setFetchAll && props.setFetchAll((prevState: any) => !prevState);
          //             props.selectAllItem(!(rowCount === numSelected));
          //           }
          //           }
          //         />
          //         <span style={{ fontWeight: 600 }}>{t('general.all')}</span>

          //       </div>
          //     </TableCell>
          //   )
          // }
          return (
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              padding={'none'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                style={{ whiteSpace: 'nowrap', fontWeight: 600 }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        })}

        {props.actionMenu ? (
          <TableCell
            component="th"
            scope="row"
            padding="none"
            align="center"
            style={{
              fontWeight: 600,
            }}
          >
            <div>{t('grid.action')}</div>
          </TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  )
}



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(0),
      boxShadow: 'none',
    },
    table: {
      minWidth: 750,
      position: 'relative',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    cursor: {
      cursor: 'pointer',
    },
  }),
)

const Grid = (props: any) => {
  const { t } = useTranslation()
  const theme = useTheme();
  const classes = useStyles()
  const history = useHistory()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('default')
  const [selected, setSelected] = React.useState<string[]>([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)


  const [tabval, setTabval] = useState(props.tabValue)

  // const [cards, setCards] = useState(props.data)
  // const moveCard = useCallback(
  //   async (dragIndex: number, hoverIndex: number) => {
  //     const dragCard = cards[dragIndex]
  //     setCards(
  //       update(cards, {
  //         $splice: [
  //           [dragIndex, 1],
  //           [hoverIndex, 0, dragCard],
  //         ],
  //       }),
  //     )

  //     update(cards, {
  //       $splice: [
  //         [dragIndex, 1],
  //         [hoverIndex, 0, dragCard],
  //       ],
  //     })
  //   },
  //   [cards],
  // )

  // useEffect(() => {
  //   if (props.data) {
  //     console.log('props.data', props.data)
  //     setCards(props.data)
  //   }
  // }, [props.data])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc'

    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = props.data?.map((n: any) => n.title)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id: any) =>
    props.selectedItems && props.selectedItems.indexOf(id) !== -1

  const redirectToProfile = (id: string): void => {
    if (props.openInExternalTab) {
      window.open(`/profile/${id}`, '_blank')
    } else {
      history.push(`/profile/${id}`)
    }
  }


  const redirectToCompany = (name: string, id: string, status: string) => {
    status === 'ACTIVE' && history.push(`/companies/${id}`)
    status === 'DRAFT' && history.push(`/create-company/${id}`)
  }

  const renderRow = (item: any) => {
    const isItemSelected = isSelected(item.id)

    item.isChecked = isItemSelected

    item.name = strCutter(item.name, 45)

    return (
      <>
        {props.config?.map((i: any, index: number) => (
          <>
            <TableCell
              className={classes.cursor}
              component="th"
              scope="row"
              padding="none"
              key={index}
              style={
                props.width && props.indexOfSpecificWidth === index
                  ? { width: props.width }
                  : {}
              }
              onClick={() => {
                if (item.__typename === "Company")
                  redirectToCompany(item.name, item.id, item.status);

                return item.role
                  ? redirectToProfile(item.id)
                  : props?.redirectToGroupeDetails
                    ? props.redirectToGroupeDetails(item)
                    : {}
              }
              }
            >
              {i.render(item)}
            </TableCell>
          </>
        ))}
      </>
    )
  }

  const renderBody = (data: any, actionConfig: any, error: any) => {


    return props.isDraggable ? (
      <>
        {data?.map((row: any, index: number) => {
          const styles: any = {}
          if (error) {
            styles.background = row[error.error]
              ? error.errorColor
              : error.successColor
          }
          return (
            <>
              <DndItem
                id={row.id}
                key={row.id}
                index={index}
                moveCard={props.moveCard}
                onEnd={props.changeOrder}
                cardIds={data?.map((card: any) => card.id)}
                isTable
                isNested
                className={`row ${props.selectedItems.includes(row.id) ? 'Mui-selected' : ''
                  }`}
              >

                {renderRow(row)}
                {actionConfig && actionConfig?.length > 0 && (
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    align="center"
                  >
                    <GridActionMenu actionConfig={actionConfig} row={row} />
                  </TableCell>
                )}

              </DndItem>
            </>
          )
        })}
      </>
    ) : (
      <>
        {stableSort(data, getComparator(order, orderBy))?.map((row: any) => {
          const styles: any = {}
          if (error) {
            styles.background = row[error.error]
              ? error.errorColor
              : error.successColor
          }
          return (
            <>
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                style={styles}
                className={`row ${props.selectedItems.includes(row.id) ? 'Mui-selected' : ''
                  }`}
              >
                {renderRow(row)}

                {actionConfig && actionConfig?.length > 0 && (
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    align="center"
                  >
                    <GridActionMenu actionConfig={actionConfig} row={row} />
                  </TableCell>
                )}
              </TableRow>
            </>
          )
        })}
      </>
    )
  }

  console.log(props?.selectedItems?.length, "props?.selectedItems?.length")
  return (
    <div className={classes.root}>


      <Paper className={classes.paper}>

        <TableWrapper theme={theme}>
          <TableContainer>
            {(props.title || props.actions) && (
              <>
                <TableHeader>
                  {props.title && <TableTitle theme={theme}>{props.title}</TableTitle>}
                  {props.actions && (
                    <GridMultipleActions
                      selectedItems={props?.selectedItems?.length}
                      actions={props.actions?.()}
                    />
                  )}


                </TableHeader>
                {props.tabGrid && (
                  <TabWrapper>
                    <TabNavigation withBorder={true} transparent>
                      <Tabs value={props.tabValue} onChange={(e, value) => {
                        props.tabValueChange(e, value)
                      }}>
                        {props.tabs?.map((tab: any) => (
                          <Tab label={tab.label} value={tab.value} />
                        ))}
                      </Tabs>
                    </TabNavigation>
                  </TabWrapper>
                )}
              </>
            )}

            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
            >

              <EnhancedTableHead
                fetchAll={props.fetchAll}
                setFetchAll={props.setFetchAll}
                classes={classes}
                numSelected={props.selected}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={props.data?.length}
                config={props.config}
                selectAllItem={props.selectAllItem}
                actionMenu={
                  !(!props.actionConfig || props.actionConfig?.length <= 0)
                }
                allSelected={props.allSelected}
                disabledSelect={props.disabledSelect}
                transactions={props.transactions}
              />
              <TableBody>
                {props?.loading && <Loader withBackground />}

                {renderBody(props.data, props.actionConfig, props.error)}
              </TableBody>
            </Table>
            {props?.data?.length === 0 && (
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  padding: '20px 0',
                }}
              >
                {t('general.no_records')}
              </div>
            )}
          </TableContainer>
        </TableWrapper>
        {props?.data?.length > 10 && props.paging ? (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={props.data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            style={{
              background: theme.palette.background.paper,
            }}
          />
        ) : null}
      </Paper>
    </div>
  )
}

export default memo(Grid, (prev, next) => {
  if (!_.isEqual(prev.data, next.data)) return false
  if (prev.selectAllItem !== next.selectAllItem) return false
  if (prev.selected !== next.selected) return false

  return true
})

const TableHeader = styled.div`
  display: flex;
  padding: 0 10px 0 0;
  align-items: center;
  justify-content: space-between;
  padding-top: 60px;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
`

const TableTitle = styled.h2`
  padding: 10px;
  color: ${(props: any) => props.theme.palette.text.primary};

  font-weight: 500;
  font-size: 24px;
`

const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  border-bottom: 1px solid #EBEBEB;

  .MuiButtonBase-root {
    font-size: 20px;
    // font-family: 'Helvetica Neue LT GEO W82';
  }

  .MuiButtonBase-root
  .Mui-selected {
    font-weight: bolder;
  }

`

const TableWrapper = styled.div`
  width: 100%;
  .row:last-child {
    .group-item-title {
      top: -35px;
      bottom: unset;
    }
  }

  .MuiTableRow-root.Mui-selected,
  .MuiTableRow-root.Mui-selected:hover {
    background-color: rgb(38 245 0 / 8%) !important;
  }

  .MuiTableHead-root {
    height: 70px;
  }

  .MuiTableBody-root {
    tr {
      th {
        div {
          font-weight: 500;
        }
      }
    }
  }

  .MuiTableCell-root {
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  }
`
