import React from 'react'
import styled from 'styled-components'

import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'

export const actionConfig = (
  currentModule?: any,
  actionHandler?: any,
  canModify?: boolean,
  t?: any,
) => {
  let action = [
    {
      hide: false,
      render: (item: any) => (
        <Action onClick={() => actionHandler('view', item)}>
          <ViewCompactIcon />
          <span>{t('actions.view')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('edit', item)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('duplicate', item)}>
          <FileCopyIcon />
          <span>{t('actions.edit_duplicate')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('delete', item)}>
          <DeleteForeverRoundedIcon />
          <span>
            {currentModule
              ? t('actions.remove_from_module')
              : t('actions.delete')}
          </span>
        </Action>
      ),
    },
  ]

  return action.filter((i: any) => !i.hide)
}

export const questionActionsConfig = (
  handleCreateQuestionClick: (moduleId: string, name: string) => void,
  handleLinkQuestionToModuleClick: (moduleId: string, name: string) => void,
  canModify: boolean,
  t?: any,
) => {
  let action = [
    {
      hide: !canModify,
      render: (item: any) => (
        <Action
          onClick={() => handleCreateQuestionClick(item.moduleId, item.name)}
        >
          <AddCircleRoundedIcon />
          <span>{t('questions_layout.create_question')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify,
      render: (item: any) => (
        <Action
          onClick={() =>
            handleLinkQuestionToModuleClick(item.moduleId, item.name)
          }
        >
          <PlaylistAddIcon />
          <span>{t('questions_layout.link_question_to_module')}</span>
        </Action>
      ),
    },
  ]

  return action
}

export const quizQuestionActionsConfig = (
  actionHandler?: any,
  canModify?: boolean,
  t?: any,
) => {
  let action = [
    {
      hide: false,
      render: (item: any) => (
        <Action onClick={() => actionHandler('view', item)}>
          <ViewCompactIcon />
          <span>{t('actions.view')}</span>
        </Action>
      ),
    },
    {
      hide: canModify,
      render: (item: any) => (
        <Action onClick={() => actionHandler('deleteQuestion', item)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.remove_question_from_quiz')}</span>
        </Action>
      ),
    },
  ].filter((i: any) => !i.hide)

  return action
}

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`
