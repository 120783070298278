import React from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from '@material-ui/core/IconButton'
import { useTheme } from '@material-ui/core'

import {
  SelectedItemsWrapper,
  Wrapper,
  MultipleAction,
  MultipleActionTooltip,
} from '../styled-components'

interface Actions {
  id: number
  disabled: boolean
  color?: string
  onClick: () => void
  component: React.ReactElement
  tooltipText?: string
}

interface Props {
  selectedItems: number
  actions: Actions[]
  isNumberOfSelectedItemsHidden?: Boolean
}

const GridMultipleActions = ({
  selectedItems,
  actions,
  isNumberOfSelectedItemsHidden,
}: Props) => {

  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!isNumberOfSelectedItemsHidden && (
        <SelectedItemsWrapper
          className={`${selectedItems < 1 ? 'disabled' : ''}`}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', fontSize: '16px' }}
          >
            <strong> {selectedItems}</strong>
            <p style={{ marginLeft: 6 }}>{t('general.items_selected')}</p>
          </div>
        </SelectedItemsWrapper>
      )}

      <Wrapper>
        {actions.map(
          ({ id, disabled, color, onClick, component, tooltipText }: any) => (
            <MultipleAction
              theme={theme}
              key={id}
              className={`${disabled ? 'disabled' : ''}`}

            >

              <IconButton color={color}
                // disabled={tooltipText != "ექსპორტი" && disabled}
                onClick={onClick}>
                {component}
              </IconButton>
              <MultipleActionTooltip>{tooltipText}</MultipleActionTooltip>
            </MultipleAction>
          ),
        )}
      </Wrapper>
    </div>
  )
}

export default GridMultipleActions
