import gql from 'graphql-tag'

export const GET_COMPANIES = gql`
  query($currentPage: Int, $perPage: Int, $filter: CompanyFilter,  $fetchAll: Boolean) {
    companies(currentPage: $currentPage, perPage: $perPage, filter: $filter,  fetchAll: $fetchAll) {
      currentPage
      totalPages
      totalCount
      data {
        id
        name
        companyEmail
        address
        phone
        companyActivity {
          label
          id
        }
        keyContactName
        status
        createDate
        internalCoursesCount
        platformCoursesCount
        superAdminsCount
        groupAdminsCount
        studentsCount
        internalCoachesCount
        assignedCoachesCount
        groupsCount
        avatar
        platformService
        color
        finished
        coverImage
      }
    }
  }
`

export const GET_COMPANY = gql`
  query($id: String) {
    company(id: $id) {
      id
      name
      companyEmail
      address
      phone
      companyActivity {
        label
        id
      }
      status
      keyContactName
      platformService
      note
      country
      # managers {
      #   id
      #   firstName
      #   lastName
      #   email
      #   status
      # }
      avatar
      coverImage
      postalCode
      city
      numberOfUsers
      color
      createDate
      internalCoursesCount
      platformCoursesCount
      superAdminsCount
      groupAdminsCount
      groupsCount
      studentsCount
      internalCoachesCount
      assignedCoachesCount
      groupsCount
      finished
      phoneFields {
        code
        dialCode
      }
    }
  }
`

export const DELETE_COMPANY = gql`
  mutation($ids: [String!]) {
    deleteCompany(ids: $ids) {
      id
      companyEmail
    }
  }
`

export const CHECK_COMPANY_EMAIL = gql`
  mutation($email: String!) {
    checkEmail(email: $email)
  }
`

export const ADD_COMPANY = gql`
  mutation($input: CompanyInput, $coursesIds: [String]) {
    addCompany(input: $input, coursesIds: $coursesIds) {
      id
      name
      companyEmail
      address
      phone
      companyActivity {
        label
        id
      }
      status
      keyContactName
      platformService
      note
      country
      # managers {
      #   id
      #   firstName
      #   lastName
      #   email
      #   status
      # }
      avatar
      postalCode
      city
      numberOfUsers
      phoneFields {
        code
        dialCode
      }
    }
  }
`

export const UPDATE_COMPANY = gql`
  mutation($id: String!, $input: CompanyInput) {
    updateCompany(id: $id, input: $input) {
      id
      name
      companyEmail
      address
      phone
      companyActivity {
        label
        id
      }
      status
      keyContactName
      platformService
      note
      country
      # managers {
      #   id
      #   firstName
      #   lastName
      #   email
      #   status
      # }
      avatar
      postalCode
      city
      numberOfUsers
      phoneFields {
        code
        dialCode
      }
    }
  }
`

export const CHANGE_COMPANY_STATUS = gql`
  mutation($ids: [String]!, $action: String!) {
    changeCompanyStatus(ids: $ids, action: $action) {
      id
      name
      companyEmail
      address
      phone
      # companyActivity {
      #   label
      #   id
      # }
      status
    }
  }
`

export const CHANGE_COMPANY_PLATFORM_SEVICE = gql`
  mutation($id: String!, $platformService: Boolean!) {
    changeCompanyPlatformService(id: $id, platformService: $platformService) {
      id
      name
      companyEmail
      address
      phone
      companyActivity {
        label
        id
      }
      status
      keyContactName
      platformService
      note
      country
      avatar
      postalCode
      city
      numberOfUsers
      color
      createDate
    }
  }
`

export const ADD_REMOVE_COURSE_FROM_COMPANY = gql`
  mutation($companyId: String!, $coursesIds: [String]) {
    addRemoveCourseFromCompany(companyId: $companyId, coursesIds: $coursesIds) {
      message
    }
  }
`
export const ADD_MANAGER = gql`
  mutation($input: UserInput, $companyId: String) {
    addManager(input: $input, companyId: $companyId) {
      id
    }
  }
`
export const EDIT_MANAGER = gql`
  mutation($companyId: String, $userId: String, $input: UserInput) {
    editManager(companyId: $companyId, userId: $userId, input: $input) {
      id
    }
  }
`

export const GET_COMPANIES_BY_PURCHASED_COURSE = gql`
  query($courseId: String!) {
    getCompaniesByPurchasedCourses(courseId: $courseId) {
      id
      name
    }
  }
`
export const SET_COMPANY_STATE = gql`
  mutation($companyId: String, $state: Int) {
    setCompanyState(companyId: $companyId, state: $state) {
      id
      name
    }
  }
`

export const UPDATE_COMPANY_AVATAR = gql`
  mutation($id: String!, $action: ProfileAction, $avatar: String) {
    updateCompanyAvatar(id: $id, action: $action, avatar: $avatar) {
      id
      avatar
    }
  }
`

export const UPDATE_COMPANY_COVER_IMAGE = gql`
  mutation($id: String!, $action: ProfileAction, $coverImage: String) {
    updateCompanyCoverImage(id: $id, action: $action, coverImage: $coverImage) {
      id
      coverImage
    }
  }
`

export const TOOGLE_COMPANY_STATUS = gql`
  mutation($companyId: String!) {
    updateCompanyStatus(companyId: $companyId) {
      message
    }
  }
`
