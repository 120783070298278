import { useMutation } from "@apollo/react-hooks"
import { DELETE_COURSE } from 'gql/course/course.query'

const deleteCourseService = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteCourseMutation] = useMutation(DELETE_COURSE)

  return {
    deleteCourseMutation
  }
}

export default deleteCourseService