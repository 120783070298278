import VideoPlayer from 'components/common/VideoPlayer/Player'
import Icon from 'components/Icon'
import React from 'react'
import { module } from '../../types'
import Modal from 'components/common/Modal'
import { ActionShowButton, HowerItem, StyledReactTooltip } from '../styled'
import AudioPlayer from 'components/common/AudioPlayer'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export type LessionTypeActionProps = {
  lesson: module.Lesson
  icon?: string
}

const LessionTypeAction: React.FC<LessionTypeActionProps> = props => {
  const [modalPlayer, setModalPlayer] = React.useState({ open: false, url: '' })

  const { t } = useTranslation()

  if (props.lesson.audio) {
    if (props.icon == "lock") {
      return <ActionShowButton>
        <Icon filename={props.icon || "lock"} width={13} height={16} fill={'#797A7A'} />
      </ActionShowButton>
    }
    return (
      <>
        <HowerItem data-tip={t('general.openLesson')}>
          <ActionShowButton>
            {props.icon != "lock" ? <StyledReactTooltip type='light' effect='solid' /> : null}
            <Icon filename={props.icon || "lock"} width={13} height={16} fill={'#797A7A'} />
          </ActionShowButton>
        </HowerItem>
        <Modal
          isOpened={modalPlayer.open}
          onClose={() => setModalPlayer({ ...modalPlayer, open: false })}
        >
          <AudioPlayer url={modalPlayer.url} />
        </Modal>
      </>
    )
  }

  if (props.lesson.contentDocument) {
    if (props.icon == "lock") {
      return <ActionShowButton>
        <Icon filename={props.icon || "lock"} width={13} height={16} fill={'#797A7A'} />
      </ActionShowButton>
    }
    return (
      <HowerItem data-tip={t('general.openLesson')}  >
        <ActionShowButton>
          {props.icon != "lock" ? <StyledReactTooltip type='light' effect='solid' /> : null}
          <Icon filename={props.icon || "lock"} width={13} height={16} fill={'#797A7A'} />
        </ActionShowButton>
      </HowerItem>
    )
  }
  if (props.lesson.video) {
    if (props.icon == "lock") {
      return <ActionShowButton>
        <Icon filename={props.icon || "lock"} width={13} height={16} fill={'#797A7A'} />
      </ActionShowButton>
    }
    return (
      <HowerItem data-tip={t('general.openLesson')}  >
        <ActionShowButton>
          <StyledReactTooltip type='light' effect='solid' />

          <Icon filename={props.icon || "lock"} width={13} height={16} fill={'#797A7A'} />
        </ActionShowButton>
      </HowerItem>
    )
  }
  return <></>
}

export default LessionTypeAction
