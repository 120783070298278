import React, { useState, useRef, memo } from 'react'
import useOutsideClick from 'helpers/useOutsideClick'

import {
  DropDownItem,
  Container,
  DropDownValue,
  StyledIcon,
  StyledLabel,
  DropDownContainer,
} from './styled-components'

const stopPropagation = e => e.stopPropagation()

const renderItems = (
  item,
  handelSelect,
  dataKey,
  index,
  toggleMenu,
  value,
  secondDataKey,
) => (
  <DropDownItem
    key={index}
    onClick={() => {
      handelSelect(item)
      toggleMenu()
    }}
    active={!!((item[dataKey] || item[secondDataKey] || item.email) === value)}
  >
    {item && (item[dataKey] || item[secondDataKey] || item.email)}
  </DropDownItem>
)

const DropDownField = props => {
  const ref = useRef()
  const [isVisible, setShowMenu] = useState(false)

  const {
    totalWidth,
    value,
    label,
    list,
    dataKey,
    secondDataKey,
    handelSelect,
    small,
    defaultData,
  } = props

  const toggleMenu = () => {
    setShowMenu(!isVisible)
  }

  useOutsideClick(ref, () => {
    if (isVisible) setShowMenu(false)
  })

  const dropdownOptions = list.map((item, index) =>
    renderItems(
      item,
      handelSelect,
      dataKey,
      index,
      toggleMenu,
      value,
      secondDataKey,
    ),
  )

  return (
    <Container
      ref={ref}
      totalWidth={totalWidth}
      onClick={toggleMenu}
      small={small}
      isVisible={isVisible}
    >
      <DropDownValue totalWidth={totalWidth} small={small}>
        {value || defaultData}
      </DropDownValue>

      <StyledIcon />

      {label ? (
        <StyledLabel active={!!(value || defaultData)} isVisible={isVisible}>
          {label}
        </StyledLabel>
      ) : null}

      {isVisible ? (
        <DropDownContainer onClick={stopPropagation}>
          {defaultData ? (
            <DropDownItem
              onClick={() => {
                handelSelect(defaultData)
                toggleMenu()
              }}
            >
              {defaultData}
            </DropDownItem>
          ) : null}

          {list && list.length > 0 ? (
            dropdownOptions
          ) : (
            <DropDownItem onClick={toggleMenu}>No Item</DropDownItem>
          )}
        </DropDownContainer>
      ) : null}
    </Container>
  )
}

export default memo(DropDownField, (prevProps, nextProps) => {
  // if (prevProps.value !== nextProps.value) return false
  // if (!prevProps.list.equals(nextProps.list)) return false
  return false
})
