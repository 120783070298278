import React, { useState, useRef, useEffect } from 'react'
import PlayerDrawer from '../VideoPlayer/PlayerDrawer/PlayerDrawer';
import Modal from '../Modal'
import Icon from '../../Icon'

import { CommentMenu } from './audioStyledComponents';
import styles from "./AudioPlayer.module.css";


const PauseIcon = props => (
  <svg width="10" height="12" viewBox="0 0 47.607 47.607" {...props}>
    <path
      fill="currentColor"
      d="M17.991 40.976a6.631 6.631 0 01-13.262 0V6.631a6.631 6.631 0 0113.262 0v34.345zM42.877 40.976a6.631 6.631 0 01-13.262 0V6.631a6.631 6.631 0 0113.262 0v34.345z"
    />
  </svg>
);

const AudioPlayer = (props) => {

  const {
    isModalOpened,
    onModalClose,
    url,
    needsDrawer,
    withModal,
    needsBlackBackground = true,
    model,
    courseId,
    lesson,
    refetchGetUser
  } = props;

  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  // references
  const audioPlayer = useRef();   // reference  for audio component
  const progressBar = useRef();   // reference for progress bar
  const animationRef = useRef();  // reference for the animation
  const volumeBar = useRef();  // reference  for volume

  const [commentDrawerState, setCommentDrawerState] = useState(false);

  useEffect(() => {
    if (props?.audioRedirect?.time > 0) {
      audioPlayer.current.currentTime = props.audioRedirect.time
      audioPlayer.current.play()
      animationRef.current = requestAnimationFrame(whilePlaying)
      setIsPlaying(true)
      whilePlaying()
    }
  }, [props?.audioRedirect?.time])


  useEffect(() => {
    if (props.lessonProgress) {
      setCurrentTime(props.lessonProgress)
    }
    return () => {
      if (!withModal) {
        audioPlayer.current.pause();
        cancelAnimationFrame(animationRef.current);
      }
    }
  }, [])

  useEffect(() => {
    // console.log("AUTOPLAY EFFECT")
    if (props.autoPlay && audioPlayer?.current && !isPlaying) {
      togglePlayPause()
    }
  }, [audioPlayer?.current])


  const handlePlayNextLesson = () => {
    // console.log("AUDIO HAS JUST ENDED")
    props.onNextLessonSwitch(Number(duration));
  }

  const handleSetupDuration = () => {
    setIsPlaying(false);
    const seconds = Math.floor(audioPlayer.current.duration);
    setDuration(seconds);
    progressBar.current.max = seconds;

    audioPlayer.current.currentTime = currentTime;

    progressBar.current.value = currentTime;
    progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / duration * 100}%`)
  }

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  }

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying)
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }


  // useEffect(() => {
  //   const prevValue = isPlaying;
  //   setIsPlaying(!prevValue);
  //   if (props.forceAudio && !prevValue) {
  //     setTimeout(() => {
  //       audioPlayer.current.play();
  //       animationRef.current = requestAnimationFrame(whilePlaying)
  //     }, 5000)

  //   }
  // }, [props.forceAudio])


  // useEffect(() => {
  //   setTimeout(() => {
  //     (!props.firstClick && props.autoPlay) && togglePlayPause()
  //   }, 1000)
  // }, [props.autoPlay])

  const whilePlaying = () => {

    try {
      progressBar.current.value = audioPlayer.current.currentTime || 0;
      changePlayerCurrentTime();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } catch (e) {
    }
  }


  // useEffect(() => {

  //   animationRef.current = requestAnimationFrame(whilePlaying)

  // }, [props.autoPlay])

  // if (props.autoPlay) {
  //   setCurrentTime(progressBar.current.value);
  // }

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  }

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / duration * 100}%`)
    setCurrentTime(progressBar.current.value);
  }


  const changeVolume = () => {
    audioPlayer.current.volume = +volumeBar.current.value;
  };

  const handleCloseModal = () => {
    audioPlayer.current.pause();
    cancelAnimationFrame(animationRef.current);
    onModalClose(+currentTime);
  }

  const handleDrawerOpen = () => {
    setCommentDrawerState(true);
    if (isPlaying) {
      togglePlayPause();
    }
  };

  const handleCloseDrawer = () => {

    // audioPlayer.current.play();
    setCommentDrawerState(false);
    // togglePlayPause()
  }


  // Elements and components:

  const playerDrawer = (
    <PlayerDrawer
      opened={commentDrawerState}
      toggleDrawer={handleCloseDrawer}
      onClose={handleCloseDrawer}
      totalWidth={'900px'}
      time={currentTime}
      url={url}
      courseId={courseId}
      model={model}
      lesson={lesson}
      refetchGetUser={refetchGetUser}
    />
  )

  const volumeElement = (
    <div className={styles.volumeBarParent}>
      <Icon filename='volume' fill={"#c4c4c4"} />
      <input
        type="range"
        className={`${styles.progressBar} ${styles.volumeProgressBar}`}
        ref={volumeBar}
        defaultValue="0.5"
        max="1"
        min="0"
        step="any"
        onChange={changeVolume}
      />
    </div>
  )


  const audioPlayerElement = (
    <div className={styles.audioPlayer}>
      <audio
        // autoPlay={props.autoPlay}
        onEnded={handlePlayNextLesson}
        ref={audioPlayer}
        onLoadedMetadata={handleSetupDuration}
        src={url}
        preload="metadata">
      </audio>
      <button onClick={togglePlayPause} className={styles.playPause}>
        {isPlaying ? <PauseIcon className={styles.play} /> : <Icon className={styles.play} filename="play" />}
      </button>
      <div className={styles.currentTime}>{calculateTime(currentTime) || "00:00"}</div>
      <div className={styles.progressBarParent}>
        <input type="range" className={styles.progressBar} defaultValue="0" ref={progressBar} onChange={changeRange} />
      </div>
      <div className={styles.duration}>{(duration && !isNaN(duration)) && calculateTime(duration)}</div>
      {needsDrawer ? (
        <CommentMenu
          order={7}
          title={'კომენტარის ჩანიშვნა'}
          onClick={handleDrawerOpen}>
          <Icon filename='addComment' fill='#fff'></Icon>
        </CommentMenu>
      ) : ""}
      {volumeElement}
    </div>
  )

  const content = needsBlackBackground
    ? (
      <div className={styles.audioPlayerContainer}>
        {needsDrawer && playerDrawer}
        {audioPlayerElement}
      </div>
    ) : audioPlayerElement

  const renderedPlayer = withModal ? (
    <Modal
      isOpened={isModalOpened}
      onClose={handleCloseModal}>
      {content}
    </Modal>

  ) : content;


  return renderedPlayer;
}

export default AudioPlayer;
