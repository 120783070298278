import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { mainColor } from 'components/common/Colors/CommonColors'

interface Props {
  title: string
  text?: string
  input?: any
  inputConfirmValue?: string
  inputPlaceholder?: string
  inputValidationMessage?: string
  type?: string

  //   icon: "success" | "error" | "warning" | "info" | "question" | undefined;
  onConfirm?: any
  onClose?: any
  confirmText?: string
  cancelText?: string
  width?: string | number
  showConfirmButton?: boolean
  html?: any
  willOpen?: () => void
}

// eslint-disable-next-line import/prefer-default-export
export const useSwal = (eventListeners?: any) => {
  const { t } = useTranslation()
  const fireSwal = ({
    title,
    text,
    input,
    inputConfirmValue,
    inputValidationMessage,
    onConfirm = {},
    onClose = {},

    inputPlaceholder,
    confirmText,
    cancelText = t('actions.cancel'),
    width = '30%',
    showConfirmButton = true,
    html,
    willOpen
  }: Props) => {
    setTimeout(() => {
      if (willOpen) {
        willOpen();
      }
    }, 50);
    Swal.fire({

      title,
      text,
      input,
      inputAttributes: {
        onblur: 'this.focus()',
      },
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: mainColor,
      cancelButtonColor: '#E0E1E2',
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      showConfirmButton,
      onClose: onClose,
      inputPlaceholder,
      width: width,
      html,

      inputValidator: value => {
        return new Promise(resolve => {
          if (value === inputConfirmValue) {
            resolve()
          } else {
            resolve(inputValidationMessage)
          }
        })
      },
    }).then(result => {
      if (result.value) {
        onConfirm()
      }
    })
  }

  return {
    fireSwal,
  }
}
