import { generateCustumUploadVideoLinksService } from './../../services/helperService/helperService';
import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import {
  generateUploadVideoLinksService,
  uploadImageService,
  uploadFileService,
  uploadAudioService,
  exportDataService,
  extractTextService,
} from 'services/helperService/helperService'
import { useTranslation } from 'react-i18next'

export const useGenerateUploadVideoLinks = () => {
  const { t } = useTranslation()
  const { generateUploadVideoLinksMutation } = generateUploadVideoLinksService()
  const { setSnackbar } = useSnackbarAlert()

  const generateUploadVideoLinks = async (
    videos: any,
    modelName: string,
    uploadVideoService: any,
    setVideoLinks: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await generateUploadVideoLinksMutation({
      variables: {
        videos: videos,
        modelName,
      },
    })
    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'

      setSnackbar({ message, variant })
    } else {
      const promises = data.generateVideoUploadLinks.uploadLinks.map(
        (url: string) => uploadVideoService(url, data.generateVideoUploadLinks.video.key),
      )

      await Promise.all(promises)

      setVideoLinks(data.generateVideoUploadLinks)
    }
  }

  return {
    generateUploadVideoLinks,
  }
}

export const useGenerateCustumUploadVideoLinks = () => {
  const { t } = useTranslation()
  const { generateCustumUploadVideoLinksMutation } = generateCustumUploadVideoLinksService()
  const { setSnackbar } = useSnackbarAlert()

  const generateCustumUploadVideoLinks = async (
    videos: any,
    modelName: string,
    uploadVideoService: any,
    setVideoLinks: any,
  ) => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await generateCustumUploadVideoLinksMutation({
      variables: {
        videos: videos,
        modelName,
      },
    })
    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'

      setSnackbar({ message, variant })
    } else {
      const promises = data.generateCustumVideoUploadLinks.uploadLinks.map(
        (url: string) => uploadVideoService(url, data.generateCustumVideoUploadLinks.video.key),
      )

      await Promise.all(promises)

      setVideoLinks(data.generateCustumVideoUploadLinks)
    }
  }

  return {
    generateCustumUploadVideoLinks,
  }
}

export const useUploadImage = () => {
  const { uploadImageMutation } = uploadImageService()
  const [imageLoading, setImageLoading] = useState('')

  const uploadImage = async (
    image: string,
    path: string,
    setImageLink: any,
  ) => {
    const loadingType = path.split('/')[1]
    setImageLoading(loadingType)
    const { data, errors } = await uploadImageMutation({
      variables: { image, path },
    })

    if (errors && errors.length) {
      return
    }
    setImageLoading('')
    setImageLink(data.uploadImageLink.link)
  }

  return {
    uploadImage,
    imageLoading,
  }
}

export const useUploadFile = () => {
  const { uploadFileMutation } = uploadFileService()
  const [fileLoading, setFileLoading] = useState('')

  const uploadFile = async (file: any, path: string, setFileLink: any) => {
    const loadingType = path.split('/')[1]
    setFileLoading(loadingType);

    const { data, errors } = await uploadFileMutation({
      variables: { file, path },
    })

    if (errors && errors.length) {
      console.log("No data, it is an error", errors, errors.length);
      return
    }

    setFileLoading('')
    setFileLink(data.uploadFileLink.link, data.uploadFileLink.fileId)
  }

  return {
    uploadFile,
    fileLoading,
  }
}

export const useUploadAudioService = () => {
  const { uploadAudioMutation, loading } = uploadAudioService()

  const uploadAudio = async (values: any, uploadApi: any, call: any) => {
    const { data, errors } = await uploadAudioMutation({
      variables: {
        file: values,
      },
    })

    if (errors && errors.length) {
      return
    }

    const promises = [uploadApi(data.uploadAudio.link)]

    await Promise.all(promises)

    call(data.uploadAudio.fileId)
  }

  return {
    uploadAudio,
    audioLoading: loading,
  }
}

export const useExportDataService = () => {
  const { exportDataMutation, loading } = exportDataService()

  const exportData = async (action: string, ids: string[], callback: any) => {

    const { data, errors } = await exportDataMutation({
      variables: {
        action,
        ids,
      },
    })

    if (errors && errors.length) {
      return
    }

    callback(data && data.exportCsv.link)
  }

  return {
    loading,
    exportData,
  }
}

export const useExtractTextService = () => {
  const { extractTextMutation, loading } = extractTextService()

  const extractText = async (link: string, callback: any) => {
    const { data, errors } = await extractTextMutation({
      variables: {
        link,
      },
    })

    if (errors && errors.length) {
      return
    }

    callback(data && data.extractText.text)
  }

  return {
    loading,
    extractText,
  }
}
