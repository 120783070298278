import React, { useContext, useEffect } from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { mainColor } from "components/common/Colors/CommonColors";
import { lowerCase } from "lodash";

const CustomThemeContext = React.createContext();

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    secondary: {
      main: '#1c426e',
      dark: '#2A2E32',
    }
  }
})

const lightTheme = createMuiTheme({
  overrides: {
    typography: {
      root: {
        textTransform: 'none'
      }
    }
  },

  typography: {
    fontFamily: 'Helvetica',
    textTransform: "none",
    button: {
      textTransform: "none"
    }
  },

  palette: {
    primary: {
      main: mainColor,

    },
    secondary: {
      main: '#bcd1f2',
      dark: '#223554',
    },
    type: "light",
  },
})




export function CustomThemeProvider({ children }) {
  const [dark, setDark] = React.useState(false);
  const [first, setFirst] = React.useState(true);

  function toggleTheme(mode) {
    setDark(mode);
  }

  useEffect(() => {
    if (first) {
      if (JSON.parse(localStorage.getItem("dark")) === true) {
        setDark(true)
      }
      else {
        setDark(false)
      }
      setFirst(false)

      return;
    }
    else {
      localStorage.setItem("dark", dark);
    }
  }, [dark])


  const theme = React.useMemo(
    () => {
      if (dark === true) {
        return darkTheme;
      }
      return lightTheme;
    },
    [dark],
  );

  return (
    <CustomThemeContext.Provider value={toggleTheme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CustomThemeContext.Provider>
  );
}

export function useToggleTheme() {
  const context = useContext(CustomThemeContext);
  return context;
}
