import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLazyQuery } from '@apollo/react-hooks'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import PublicIcon from '@material-ui/icons/Public'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded'
import VpnLockIcon from '@material-ui/icons/VpnLock'
import { Button } from 'components/common/Button'
import { mainColor } from 'components/common/Colors/CommonColors'
import {
  ButtonsWrapper,
  DetailsContentDescription,
  DetailsContentHeader,
  DetailsContentHeaderSection,
  DetailsContentLeft,
  DetailsContentRight,
  DetailsTitle,
  Row,
} from 'components/common/DetailsLayout'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import Loader from 'components/common/Loader'
import Modal from 'components/common/Modal'
import TextEditor from 'components/common/TextEditor/TextEditor.jsx'
import Thumbnail from 'components/common/Thumbnail/Thumbnail'
import Player from 'components/common/VideoPlayer/Player'
import { useUserValue } from 'context/UserContext'
import { GET_COMPANIES_BY_PURCHASED_COURSE } from 'gql/companies.query'
import { DrawerEventEmitter } from 'helpers/drawer'
import useDeleteCourse from 'hooks/course/useDeleteCourse'
import { useEditCourse, usePublishedCourse } from 'hooks/course/useEditCourse'
import { useSwal } from 'hooks/useSwal'
import styled from 'styled-components'
import { actionConfig } from './actionConfig'
import Categories from './CourseDetails/Categories'
import RenderCoaches from './CourseDetails/CoachesList'
import RenderCompany from './CourseDetails/Company'
import CourseShortInfo from './CourseDetails/ShortInfo'
import Skills from './CourseDetails/Skills'
import RenderVideo from './CourseDetails/Videos'
import {
  CapitalPlatformTitle,
  PlatformTitle,
} from 'components/common/PlatformTitle/PlatformTitle'
import { ActionButton } from 'components/common/Grid/styled-components'
import Icon from 'components/Icon'
import { formatDuration } from 'helpers/formatDuration'
import { useTheme } from '@material-ui/core'

export interface CourseParams {
  id: string
  name: string
  description: string
  editorText: string
  level: string
  coursePrivacy: string
  coachType: string
  video: any
  coaches: any // for time
  certificateIncluded: Boolean
  certificate: {
    name: string
    link: string
    fileType: string
  }
  avatar: {
    name: string
    link: string
    fileType: string
  }
  skills: [
    {
      id: string
      label: string
    },
  ]
  state: string
  groups: any
  numberOfGroups: number
  numberOfStudents: number
  duration: number
  subtitle: any
  isBought: boolean
  companyId: any
  finished: number
  categories?: {
    id: string
    name: string
  }[]
}

interface Props {
  course: CourseParams
  refetch: () => void
  refetchCourse: () => void
  t: any
  canModify?: boolean
}

const CourseDetailContent = ({
  course,
  refetch,
  refetchCourse,
  t,
  canModify,
}: Props) => {
  const history = useHistory()
  const [state] = useUserValue()

  const [
    fetchCompanies,
    { data: companyData, loading: companiesLoading },
  ] = useLazyQuery(GET_COMPANIES_BY_PURCHASED_COURSE)

  const { editCourse, editCourseLoading } = useEditCourse({
    courseId: course.id,
  })
  const { deleteCourse } = useDeleteCourse()
  const { publishedCourse } = usePublishedCourse()
  const { fireSwal } = useSwal()
  const [editMode, setEditMode] = useState(false)
  const [videoModal, setVideoModal] = useState(false)

  useEffect(() => {


    if (course && course.id && !course.isBought && !course.companyId) {
      fetchCompanies({
        variables: {
          courseId: course.id,
        },
      })
    }
  }, [course])

  const editCourseDescription = (editorText: any, plainText: string) => {
    const newCourse: any = {}
    newCourse.name = course.name
    newCourse.coursePrivacy = course.coursePrivacy
    newCourse.coaches = course.coaches.map((coach: any) => coach.value)
    newCourse.editorText = editorText
    newCourse.description = plainText



    editCourse(course.id, newCourse)
    setEditMode(false)
  }

  const confirmPublishClick = (courseId: string[], action: string) => {
    publishedCourse(courseId, action)
    refetch()
  }

  const handlePublishClick = (courseId: string, action: string) => {
    let title: string = ''
    title =
      action === 'published'
        ? t('popups.publish_course')
        : t('popups.unpublish_course')
    fireSwal({
      title: title,
      onConfirm: () => confirmPublishClick([courseId], action),
      confirmText: `${t('general.yes')}, ${action === 'published' ? t('actions.publish') : t('actions.unpublish')
        }`,
    })
  }

  const handleEditClick = (id: string) =>
    DrawerEventEmitter.emit('openDrawer', 'editCourse', true, { id: id })

  const handleDeleteClick = (id: string) => {
    fireSwal({
      title: 'Are you sure you want to delete this course ?',
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => {
        deleteCourse([id], refetchCourse)
        history.push('/courses')
      },
    })
  }

  const handlePublishUnpublishClick = (courseId: string) => {
    let title: string = ''
    let confirmText: string = ''

    if (course.state !== 'unpublished') {
      title = `<span style="color:red">${t('popups.unpublish_course')}</span>`
      confirmText = t('popups.confirm_unpublish')
    } else {
      title = `<span style="color: ${mainColor}">${t(
        'popups.publish_course',
      )}</span>`
      confirmText = t('popups.confirm_publish')
    }


    fireSwal({
      title,
      onConfirm: () => publishedCourse([courseId], course.state === "unpublished" ? "published" : "unpublished"),
      confirmText,
    })
  }

  const handleCompanyOpen = (id: string) => history.push(`/companies/${id}`)

  const isBoughtCourse =
    !!course.companyId && !!state.currentUser.companyId && !!course.isBought

  const actions = actionConfig(
    handleEditClick,
    handleDeleteClick,
    t,
    isBoughtCourse,
  )
  const video = course.video
  const companies =
    (companyData && companyData.getCompaniesByPurchasedCourses) || []
  const hasConverted = video && video.links[0].converted

  const isStudent = state.currentUser.role === 'STUDENT'

  const CourseLevel = (level: string): string => {
    let courseLevel = ''
    if (level === 'beginner') {
      courseLevel = t('courses_layout.beginner')
    } else if (level === 'intermediate') {
      courseLevel = t('courses_layout.intermediate')
    } else if (level === 'advanced') {
      courseLevel = t('courses_layout.advanced')
    }
    return courseLevel
  }

  const theme = useTheme()

  return (
    <Container theme={theme}>
      <CourseContentWrapper>
        <DetailsContentLeft>
          <div style={{ padding: '30px' }}>
            <DetailsContentHeader>
              <img
                style={{
                  width: '470px',
                  height: '269px',
                  borderRadius: '16px',
                  marginRight: '30px',
                }}
                src={course.avatar && course.avatar.link}
                alt=""
              />

              {canModify && (
                <div
                  style={{
                    position: 'absolute',
                    top: '60px',
                    right: '60px',
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  <div>
                    <div style={{ display: 'flex', margin: '20px' }}>
                      <ActionButton style={{ marginRight: '20px' }}
                        fill={'#CC3131'}
                        onClick={() => handleDeleteClick(course.id)}
                        theme={theme}
                      >
                        <Icon filename={'delete'} />
                      </ActionButton>
                      <ActionButton theme={theme} onClick={() => handleEditClick(course.id)}>
                        <Icon filename={'edit'} />
                      </ActionButton>
                    </div>
                    <button style={{
                      fontSize: '16px',
                      margin: '5px 15px',
                      padding: '20px',
                      border: 'none',
                      borderRadius: '16px',
                      outline: 'none',
                      cursor: 'pointer',
                      backgroundColor: course.state === "published" ? '#F5F5F5' : '#317BF4',
                      color: course.state === "published" ? '#2F3131' : 'white'
                    }} onClick={() => handlePublishUnpublishClick(course.id)}>
                      {course.state === "published" ? t('actions.cancel') : t('actions.publish')}
                    </button>
                  </div>
                </div>
              )}

              <DetailsContentHeaderSection>
                <DetailsTitle style={{ color: theme.palette.text.primary, fontSize: '22px' }}>
                  {course.name}
                </DetailsTitle>
                <StyledItemContainer>
                  {!isStudent && (
                    <StyledItemWrapper>
                      {course.companyId && !course.isBought ? (
                        <StyledItem theme={theme}>{course.companyId.name}</StyledItem>
                      ) : !course.companyId ? (
                        <CreatedBy style={{ textDecoration: 'none' }}>
                          {CapitalPlatformTitle}
                          <StarsRoundedIcon
                            style={{
                              color: mainColor,
                              fontSize: 10,
                              verticalAlign: 'super',
                            }}
                          />
                        </CreatedBy>
                      ) : (
                        <StyledItem theme={theme}>{PlatformTitle}</StyledItem>
                      )}
                    </StyledItemWrapper>
                  )}

                  <CourseContentDetails>
                    {canModify && (
                      <CourseContentDetail theme={theme}>
                        <strong>{t('course_details.purchased_by')}:</strong>
                        {companiesLoading ? (
                          'Loading...'
                        ) : (
                          <RenderCompany
                            data={companies}
                            handleCompanyOpen={handleCompanyOpen}
                          />
                        )}
                      </CourseContentDetail>
                    )}
                    <CourseContentDetail theme={theme} style={{ marginTop: '10px' }}>
                      <span>{t('course_details.course_details')}</span>
                    </CourseContentDetail>
                    <CourseContentDetail theme={theme}>
                      <strong>{t('course_details.level')}:</strong>
                      <span>{CourseLevel(course.level)}</span>
                    </CourseContentDetail>
                    <CourseContentDetail theme={theme}>
                      <strong>{t('course_details.duration')}:</strong>
                      <span>
                        {course.duration && formatDuration(course.duration)}
                      </span>
                    </CourseContentDetail>
                    <CourseContentDetail theme={theme}>
                      <strong>{t('general.groups')}:</strong>
                      <span>{course.groups.length}</span>
                    </CourseContentDetail>
                    <CourseContentDetail theme={theme}>
                      <strong>{t('course_details.certificate')}:</strong>
                      <span>
                        {course.certificate
                          ? t('general.certificate_included')
                          : t('general.certificate_not_included')}
                      </span>
                    </CourseContentDetail>
                  </CourseContentDetails>
                </StyledItemContainer>
                {/* <article>{course.description}</article> */}
              </DetailsContentHeaderSection>
            </DetailsContentHeader>
            <DetailsContentDescription>
              {editCourseLoading && <Loader withBackground={true} />}
              <TextEditor
                text={course.editorText}
                isVisible={editMode}
                hasControls={true}
                editorTitle={t('course_details.course_overview')}
                closeEditMode={() => setEditMode(false)}
                openEditMode={() => setEditMode(true)}
                onSave={editCourseDescription}
                canEdit={canModify}

              />
            </DetailsContentDescription>
          </div>
          {course?.categories && <Categories categories={course.categories} />}
          {course.skills.length > 0 && <Skills skills={course.skills} />}
          <RenderCoaches coaches={course?.coaches} />
        </DetailsContentLeft>
        {/* <DetailsContentRight className={!video ? 'noVideo' : ''}>
          {canModify && (
            <ButtonsWrapper>
              {course.finished === 3 && state.userPermission.publishCourse && (
                <Button
                  text={
                    course.state === 'unpublished'
                      ? t('actions.publish')
                      : t('actions.unpublish')
                  }
                  icon={
                    course.state === 'unpublished' ? (
                      <PublicIcon />
                    ) : (
                      <VpnLockIcon />
                    )
                  }
                  size="small"
                  onClick={() =>
                    handlePublishClick(
                      course.id,
                      course.state === 'published'
                        ? 'unpublished'
                        : 'published',
                    )
                  }
                  background={mainColor}
                />
              )}

              <GridActionMenu
                actionConfig={actions}
                row={course}
                icon={
                  <Button
                    text={t('general.actions')}
                    size="small"
                    onClick={() => {}}
                    icon={<ArrowDropDownIcon />}
                    iconPosition="right"
                    background={mainColor}
                  />
                }
              />
            </ButtonsWrapper>
          )}
          {video && (
            <RenderVideo
              video={video}
              hasConverted={hasConverted}
              setVideoModal={setVideoModal}
            />
          )}

          <CourseShortInfo course={course} />
        </DetailsContentRight> */}
      </CourseContentWrapper>
      <Modal isOpened={videoModal} onClose={() => setVideoModal(false)}>
        <Player url={video && video.links[0].url} subtitle={course.subtitle} />
      </Modal>
    </Container>
  )
}

export default CourseDetailContent

const Container = styled.div`
  background: ${({ theme }) => theme.palette.background.default};
  /* border: 1px solid #ebebeb; */
  border-bottom: none;
`

const CourseContentWrapper = styled.div`
  display: flex;
  padding: 0px;
`

const StyledItemContainer = styled.div``
const StyledItemWrapper = styled.div`
  span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    white-space: nowrap;
  }
  display: flex;
  align-items: center;
`

export const StyledItem = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text.primary};
  cursor: pointer;
  margin-left: 5px;
  text-decoration: underline;
`

const CreatedBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #080a0a8a;
  cursor: pointer;
  height: 36px;
  padding: 9px;
  text-decoration: underline;
  background: #ffffff;
  border: 1px solid #080a0a14;
  border-radius: 8px;
  margin: 10px 0px;
`

export const CourseContentDetails = styled.ul`
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const CourseContentDetail = styled.li`
  display: flex;
  gap: 8px;
  margin: 2px 0px;
  strong {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 16px;
  }
  span {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 16px;
  }
`
