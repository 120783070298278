import gql from 'graphql-tag'


export const ADD_SKILL_TEST_VIDEO = gql`
  mutation($moduleId: String!, $courseId: String!, $input: SkillTestVideoInput) {
    addSkillTestVideo(moduleId: $moduleId, courseId: $courseId, input: $input) {
      id
      file {
        name
        link
      },
      status,
       courseId,
      description,
      version,
      coaches,
      students,
      sentForReview {
        id,
        userId,
        firstName,
        lastName,
        role,
        rate
      },
      reviews {
        id,
        ratedId,
        onModel,
        rate,
        description,
        author {
          userId,
          firstName,
          lastName,
          avatar,
          role,
          postsCount,
          companyId
        },
        notesCount
      },
      averageRating,
      course {
        id,
        name
      },
      module {
        id,
        name
      },
    }
  }
`

export const GET_SKILL_TEST_VIDEO = gql`
  query($courseId: String!, $userId: String!) {
    getSkillTestVideos(courseId: $courseId, userId: $userId) {
      id,
      status
      file {
        name,
        link,
        type
      },
      description,
      reviews {
        id,
        ratedId,
        createDate,
        rate,
        description,
        attachedFile {
          name,
          type,
          link
        },
        author {
          userId,
          firstName,
          lastName,
          role,
        }
      }
      sentForReview {
          id,
          userId,
          firstName,
          lastName,
          role,
          rate
      }
      averageRating,
      version
      createDate
      module {
        id
        name
      }
      course {
        id
        name
      }
    }
  }

`

export const GET_SKILL_TEST_VIDEO_BY_MODULE_ID = gql`
  query($moduleId: String!) {
    getSkillTestVideosByModuleId(moduleId: $moduleId) {
      createdBy,
    skillTestVideos {
      id,
      file {
        link
        name,
        type
      }
      status,
      description,
      version,
      createDate,
      coaches {
        firstName,
        lastName,
        role
      }
      students {
        firstName,
        lastName
        role
      },
			sentForReview {
        id,
        userId,
        firstName,
        lastName,
        role,
        rate
      }
      reviews {
        id
        ratedId
        onModel
        rate,
        # attachedAudio,
        attachedFile {
          name,
          link,
          type
        },
        createDate,
        description,
        author {
          userId,
          firstName
          lastName,
          avatar,
          role,
          postsCount,
          companyId
        },
        notesCount
      }
      averageRating,
      course {
        id,
        name
      },
      module {
        id,
        name
      }
    }
    }
  }
`

export const GET_SKILL_TEST_VIDEO_TO_RATE = gql`
  query($id: String!) {
    getSkillTestVideoToRate(id: $id) {
      id,
      video {
        name
        title,
        thumbnail,
        duration,
        id,
        links {
          url,
          fileType,
          converted
        }
      }
      status,
      description,
      version,
      createdBy {
        id,
        name,
        avatar
      },
      hasReviewed
      reviews {
        id,
        ratedId
        onModel,
        rate,
        description,
        # attachedAudio,
        attachedFile {
          name,
          link,
          type
        },
        author {
          userId,
          firstName
          lastName,
          avatar,
          role,
          postsCount,
          companyId
        }
        notesCount
      }
      averageRating
    }
  }
`

export const ADD_REVIEW = gql`
  mutation addReview($ratedId: String!, $model: ReviewModel!, $input: ReviewInput, $isAnonymous: Boolean) {
  addReview(ratedId: $ratedId, model: $model, input: $input, isAnonymous: $isAnonymous) {
    id,
    ratedId,
    courseId,
    onModel,
    rate,
    description,
    author {
      userId,
      firstName,
      lastName
      avatar,
      role,
      postsCount,
      companyId
    }
    notesCount
  }
}
`

export const ADD_COURSE_REVIEW = gql`
  mutation addReview($ratedId: String!, $model: ReviewModel! $input: ReviewInput, $isAnonymous: Boolean) {
  addReview(ratedId: $ratedId, model: $model, input: $input, isAnonymous: $isAnonymous) {
    id,
    rate,
    description,
  }
}
`


export const ADD_COACH_REVIEW = gql`
    mutation addReview($ratedId: String!, $input: ReviewInput, $isAnonymous: Boolean) {
    addReview(ratedId: $ratedId, model: user, input: $input, isAnonymous: $isAnonymous) {
      id,
      rate,
      description,
  }
}
`

export const EDIT_REVIEW = gql`
    mutation editReview($id: String!, $input: ReviewInput, $isAnonymous: Boolean) {
    editReview(id: $id, input: $input, isAnonymous: $isAnonymous) {
      id,
      rate,
      description,
      author {
        firstName
        lastName
      }
  }
}
`

export const DELETE_REVIEWS = gql`
  mutation deleteReviews($ids: [String!]) {
    deleteReviews(ids: $ids)
  }
`
