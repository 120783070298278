export const filterTopics = (filterOptions: any, topics: any) => {
  const filteredTopics = topics.filter((topic: any) => {
    if (filterOptions.name && !topic.name.includes(filterOptions.name)) {
      return false
    }

    if (filterOptions.attachment) {
      if (filterOptions.attachment === 'true' && topic.attachment === null) {
        return false
      }

      if (filterOptions.attachment === 'false' && topic.attachment !== null) {
        return false
      }
    }

    if (filterOptions.numberOfLessons !== '0-0') {
      const from = parseInt(filterOptions.numberOfLessons.split('-')[0])
      const to = parseInt(filterOptions.numberOfLessons.split('-')[1])

      if (topic.lessons.length < from || topic.lessons.length > to) {
        return false
      }
    }

    return topic
  })

  return filteredTopics
}
