import gql from 'graphql-tag'

export const GET_NOTES = gql`
 query ($actionId: String!, $model: NoteModelType!, $skip:Int!, $limit: Int!) {
  getNotes(
    actionId: $actionId
    model: $model
    skip: $skip
    limit: $limit
  ) {
    totalCount
    hasMore
    data {
      id
      title
      description
      moment
    }
  }
}
`

export const CREATE_NOTE = gql`
 mutation ($actionId: String!, $model: NoteModelType!, $courseId: String!, $newNote: NoteInput!) {
  addNote(
    actionId: $actionId
    model: $model
    courseId: $courseId
    input: $newNote
  ) {
      title
      description
      moment
      thumbnail
      createDate
      id
  }
}
`

export const EDIT_NOTE = gql`
 mutation ($noteId: String!, $input: NoteInput!) {
  editNote(
    noteId: $noteId
    input: $input
  ) {
      title
      description
      moment
      thumbnail
      createDate
      id
  }
}
`

export const DELETE_NOTE = gql`
 mutation ($id: String!) {
  deleteNote(
    id: $id
  )
}
`