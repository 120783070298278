import { useMutation } from '@apollo/react-hooks'

import { GET_FLASHCARDS, ADD_FLASHCARD } from 'gql/flashcards.query'
import { updateCache } from 'services/updateCache'

const addFlashCardService = (params: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addFlashCardMutation, { loading, error }] = useMutation(
    ADD_FLASHCARD,
    {
      update(cache, { data: { addFlashCard: document } }) {
        updateCache({
          objectName: 'flashCards',
          query: GET_FLASHCARDS,
          cache,
          document,
          action: 'addToLast',
          params,
        })
      },
    },
  )

  return {
    addFlashCardMutation,
    loading,
    error,
  }
}

export default addFlashCardService
