import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

import { GET_MODULE } from 'gql/modules.query'
import ModuleDetailContent from './ModuleDetailContent/ModuleDetailContent'
import ModuleTopicsGrid from './ModuleTopicsGrid'
import QuestionsLayout from 'pages/questions/QuestionsLayout'
import FlashCardsLayout from '../FlashCards'
import { useUserValue } from 'context/UserContext'
import {
  TabNavigation,
  TabPanel,
  activeTabClassName,
} from 'components/common/Tabs/Tabs'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { QuizLayout } from 'pages/quizzes'
import Breadcrumb from 'components/common/Breadcrumb'
import styled from 'styled-components'
import { useTheme } from '@material-ui/core'

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid #EBEBEB',
  },
  btn: {
    color: '#000',
  },
}

interface RouterParams {
  match: MatchProps
}

interface MatchProps {
  path: string
  url: string
  isExact: boolean
  params: { id: string }
}

const ModuleDetail = ({ match }: RouterParams) => {
  const { t } = useTranslation()
  const moduleId = match.params.id
  const [value, setValue] = useState(0)
  const [state, dispatch] = useUserValue()
  const [openCreateQuizDrawer, setOpenCreateQuizDrawer] = useState<boolean>(
    false,
  )
  const { data, loading, refetch } = useQuery(GET_MODULE, {
    variables: { moduleId },
  })

  const theme = useTheme()


  useEffect(() => {
    if (state.refetch) {
      dispatch({ type: 'REMOVE_REFETCH' })
    }
  }, [state])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  if (loading) return <div>Loading...</div>

  const moduleData = (data && data.getModule) || {}
  const currentModule = moduleData && {
    moduleId: moduleData.id,
    name: moduleData.name,
  }

  const breadCrumbData = [
    { label: t('general.modules'), link: '/modules' },
    { label: currentModule && currentModule.name, link: '' },
  ]

  const isStudent = state.currentUser.role === 'STUDENT'

  const isCompanySuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !!state?.currentUser?.companyId?.id

  const isCompanyAdmin =
    state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  const isSuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !state?.currentUser?.companyId?.id

  const isAdmin =
    state?.currentUser?.role === 'ADMIN' && !state?.currentUser?.companyId?.id

  const canModify =
    (((isCompanySuperAdmin || isCompanyAdmin) &&
      moduleData?.companyId === state?.currentUser?.companyId?.id) ||
      isSuperAdmin ||
      isAdmin) &&
    !isStudent


  return (
    <Container theme={theme}>
      {moduleData && (
        <>
          <ModuleDetailContent
            moduleItem={moduleData}
            refetch={refetch}
            topicsLength={0}
            t={t}
            canModify={canModify}
          />
          <TabContainer theme={theme}>
            <TabNavigation>
              <AppBar position="static" style={{
                background: theme.palette.background.default,
                boxShadow: 'none',
              }}>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    label={`${t('general.topics')} ${t('general.and')} ${t(
                      'general.lessons',
                    )}`}
                    {...activeTabClassName(0)}
                    style={styles.btn}
                  />
                  {!isStudent && (
                    <Tab
                      label={t('general.questions')}
                      {...activeTabClassName(1)}
                      style={styles.btn}
                    />
                  )}
                  {!isStudent && (
                    <Tab
                      label={t('general.quizzes')}
                      {...activeTabClassName(2)}
                      style={styles.btn}
                    />
                  )}
                  {!isStudent && (
                    <Tab
                      label={t('general.flash_cards')}
                      {...activeTabClassName(2)}
                      style={styles.btn}
                    />
                  )}
                </Tabs>
              </AppBar>

              <TabPanel value={value} index={0}>
                <ModuleTopicsGrid
                  data={moduleData.topics}
                  refetch={refetch}
                  moduleId={moduleData.id}
                  canModify={canModify}
                  t={t}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <QuestionsLayout
                  topics={moduleData.topics}
                  fromModule={true}
                  currentModule={currentModule}
                  isDraggable
                  refetchModule={refetch}
                  manualCompanyId={moduleData.companyId}
                  canModify={canModify}
                  handleTabChange={handleTabChange}
                  setOpenCreateQuizDrawer={setOpenCreateQuizDrawer}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <QuizLayout
                  type="module"
                  id={moduleData.id}
                  canCreate={moduleData.numberOfQuestions}
                  name={moduleData.name}
                  isDraggable
                  companyId={moduleData.companyId}
                  canModify={canModify}
                  fromQuestions={openCreateQuizDrawer}
                  setOpenCreateQuizDrawer={setOpenCreateQuizDrawer}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <FlashCardsLayout
                  module={moduleData}
                  companyCourse={!canModify}
                />
              </TabPanel>
            </TabNavigation>
          </TabContainer>
        </>
      )}
    </Container>
  )
}

export default ModuleDetail

const Container = styled.div`
  overflow: hidden;
  background: ${({ theme }) => theme.palette.background.default};
  /* border: 1px solid #ebebeb; */
  border-radius: 8px;
  height: 100%;
`

const TabContainer = styled.div`
  .MuiTabs {
    &-flexContainer {
      justify-content: center;
    }
  }

  .MuiTab {
    &-wrapper {
      color: ${({ theme }) => theme.palette.text.primary};
      font-size: 20px;
    }
  }
`
