import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useAction } from 'store/actions'
import { useFormik } from 'formik'
import axios from 'axios'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import IconButton from '@material-ui/core/IconButton'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'

import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
  FormGroupItem,
  InputGroup,
} from 'components/common/Form'
import { Button } from 'components/common/Button'
import { Input } from 'components/common/TextInput'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  CloseIcon,
  Info,
} from 'components/DrawerLayout/styled-components'
import useCreateLessonService from 'hooks/lessons/useCreateLessonService'
import {
  useGenerateUploadVideoLinks,
  useUploadImage,
  useUploadFile,
  useUploadAudioService,
  useExtractTextService,
  useGenerateCustumUploadVideoLinks,
} from 'hooks/helpers/useHelpersService'
import Uploader from 'components/common/FileUploader/Uploader'
import UploaderButton from 'components/common/FileUploader/UploaderButton'
import CreatableSelectWithError from 'components/common/CreatableSelect/CreatableSelect'
import { useData } from 'context/DataContext'
import { SET_FORMDATA } from 'store/types'
import { useAddTopic } from 'hooks/topics/useAddTopic'
import { LessonSchema } from 'helpers/validationSchemas'
import { mainColor } from 'components/common/Colors/CommonColors'
import { SelectField } from 'components/common/SelectField'
import { useTheme } from '@material-ui/core'
import { triggerVideoConverter } from 'services/mediaEncoding'
import useSnackbarAlert from 'hooks/useSnackbar'

const formData: any = {
  name: '',
  description: '',
  transcript: '',
  pdfDuration: 1,
  topic: null,
  video: null,
  attachment: null,
  audio: null,
  contentDocument: null,
  subtitle: null,
  thumbnail: null,
}

const constants = {
  video:
    'video/x-msvideo|video/mpeg|video/ogg|video/mp4|video/x-flv|video/quicktime|video/x-msvideo|video/x-ms-wmv',
  audio: 'audio/mpeg|audio/mpeg|audio/aac|audio/mp4a-latm',
  file:
    'application/pdf|application/msword|application/plain|text/plain|image/png|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel',
}

const AddLessonDrawer = (props: any) => {
  const { t } = useTranslation()
  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()
  const [file, setFile] = useState<any>('')
  const [videoDuration, setVideoDuration] = useState(0)
  const [uploadVideoProgress, setUploadVideoProgress] = useState(0)
  const [attachment, setAttachment] = useState(null)
  const [subtitles, setSubtitles] = useState(null)
  const [transcript, setTranscript] = useState(null)
  const [topicId, setTopicId] = useState<any>(null)
  const { setSnackbar } = useSnackbarAlert()

  const { createLesson, loading: createLessonLoading } = useCreateLessonService(
    {
      topicId: topicId || props.data.id,
    },
  )
  const [fileTypes, setFileTypes] = useState('video')

  const { addTopic, newTopic, addTopicLoading } = useAddTopic({
    moduleId: props.id,
    filter: {},
  })

  const { generateCustumUploadVideoLinks } = useGenerateCustumUploadVideoLinks()
  const { uploadFile, fileLoading } = useUploadFile()
  const { uploadImage, imageLoading } = useUploadImage()
  const { uploadAudio } = useUploadAudioService()

  const uploadVideoService = (url: string, key?: string) => {
    const formData = new FormData()

    formData.append('file', file)

    return axios
      .put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: ({ total, loaded }) => {
          setUploadVideoProgress((loaded / total) * 100)
        },
      })
      .then(res => {
        const message = `Video upload success, conversion ${res.data}`
        const variant = 'success'

        setSnackbar({ message, variant })
      })
      .catch(err => {
        const message = `${err}`
        const variant = 'error'
        console.log('err', err)
        setSnackbar({ message, variant })
      })
  }

  // console.log("uploadFile", uploadFile);

  const { extractText, loading: extractTextLoading } = useExtractTextService()

  const onSuccess = () => {
    props.onClose()
    toggleDrawerConfirm(false, '')
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    validationSchema: Yup.object(LessonSchema),
    onSubmit: async (values: any) => {
      const data = { ...values }

      if (createLessonLoading) return

      if (data.topic) {
        data.topic = data.topic.value
      }

      if (fileTypes === 'contentDocument' || videoDuration) {
        data.duration =
          fileTypes === 'contentDocument'
            ? Number(data.pdfDuration)
            : videoDuration
      }
      delete data.pdfDuration

      if (data.video) {
        data.video = {
          id: data.video.id,
          title: data.video.title,
        }
      }

      if (data.thumbnail) {
        data.thumbnail = data.thumbnail.link
      }

      createLesson(data, onSuccess, props.data.refetch)
    },
  })

  useEffect(() => {
    setTopicId(values.topic ? values.topic.value : null)
  }, [values.topic])

  const handleGenerateUploadLink = () => {
    const videos = [{ fileName: file.name, type: file.type }]

    generateCustumUploadVideoLinks(
      videos,
      'lesson',
      (arg: any, key?: string) => uploadVideoService(arg, key),
      (args: any) => {
        setFieldValue('video', args.video)
        setFieldValue('video.title', args.video.name)
        setUploadVideoProgress(0)
      },
    )
  }

  const generateUploadAudioLink = () => {
    const audioFile: any = { fileName: file.name, type: file.type }

    uploadAudio(
      audioFile,
      (arg: any) => uploadVideoService(arg),
      (link: string) => {
        setFieldValue('audio', {
          name: file.name,
          fileType: file.name.split('.').pop(),
          link,
        })

        setUploadVideoProgress(0)
      },
    )
  }

  const handleCloseDrawer = () => {
    let formValues: any = { ...values }
    if (props.data.id) {
      const { topic, ...formData } = values
      formValues = formData
    }
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'addLesson',
        values: formValues,
        compareTo: {},
      },
    })
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  useEffect(() => {
    if (file && file.type && constants.audio.includes(file.type)) {
      generateUploadAudioLink()
      setFileTypes('audio')
    }

    if (file && file.type && constants.video.includes(file.type)) {
      handleGenerateUploadLink()
      setFileTypes('video')
    }

    if (file && file.type && constants.file.includes(file.type)) {
      uploadFile(file, 'lesson/content', (link: string, fileId: string) =>
        setFieldValue('contentDocument', {
          name: file.name,
          fileType: file.name.split('.').slice(-1)[0],
          link,
        }),
      )
      setFileTypes('contentDocument')
    }
  }, [file])

  const handleContentChange = (e: any) => {
    const file = e.target.files[0]

    if (constants.file.includes(file.type)) {
      setFile(file)
      return
    }

    setUploadVideoProgress(1)
    setFile(file)
  }

  const handleFileChange = (e: any, filed: string) => {
    const { files } = e.target
    const file = files[0]

    const fileType = file.name.split('.').slice(-1)[0]

    uploadFile(file, `lesson/${filed}`, (link: string) => {
      setFieldValue(filed, {
        name: file.name,
        fileType,
        link,
      })
    })
    if (filed === 'attachment') {
      setAttachment(file)
    }

    if (filed === 'transcript') {
      setTranscript(file)
    }

    if (filed === 'subtitles') {
      setSubtitles(file)
    }
  }

  const handleImageChange = (e: any, field: string) => {
    const { files } = e.target
    const file = files[0]
    const reader: any = new FileReader()
    reader.onload = () => {
      const img: any = new Image()
      img.src = reader.result
      uploadImage(reader.result, `lesson/${field}`, (link: string) =>
        setFieldValue(field, {
          name: file.name,
          link,
          fileType: file.name.split('.').slice(-1)[0],
        }),
      )
    }
    reader.readAsDataURL(files[0])
  }

  const deleteContentFile = () => {
    setFile('')
    setUploadVideoProgress(0)
    setFieldValue('video', null)
    setFieldValue('audio', null)
    setFieldValue('contentDocument', null)
    setFileTypes('video')
    setFieldValue('subtitle', null)
  }

  const fromTopic = !!(props.data && props.data.name)

  useEffect(() => {
    if (fromTopic) {
      setFieldValue('topic', { value: props.data.id, label: props.data.name })
    }
  }, [])

  const handleExtractText = (e: any, field: string) => {
    if (!e || !e.target) return
    const { files } = e.target
    const file = files[0]
    uploadFile(file, `uploads/extract`, (link: string) => {
      extractText(link, (text: string) => setFieldValue(field, text))
    })
  }

  const handleTopicChange = (e: any) => {
    if (e && !e.__isNew__) {
      setFieldValue('topic', e)
    }


    if (e && e.__isNew__) {
      addTopic(props.id, { name: e.value }, (topic: any) => {
        setFieldValue('topic', {
          value: topic.id,
          label: topic.name,
        })
        props.data.topicList = [...props.data.topicList, topic]
      })
    }
  }

  const submitDisabled = !(
    (values.video ||
      values.audio ||
      values.contentDocument ||
      createLessonLoading) &&
    !fileLoading
  )

  const fileExtension =
    fileTypes === 'video'
      ? (values.video && `(${values.video.links[2]?.type})`) || ''
      : (values[fileTypes] && `(${values[fileTypes]?.fileType})`) || ''

  const topicList =
    (props.data.topicList &&
      props.data.topicList.map((i: any) => ({ value: i.id, label: i.name }))) ||
    []

  const theme = useTheme()

  return (
    <>
      <DrawerHeader theme={theme}>
        <DrawerTitle theme={theme}>
          {t('lessons_layout.create_lesson')}
        </DrawerTitle>

        <CloseIcon
          onClick={handleCloseDrawer}
          filename={'close'}
          fill={'#707070'}
          stroke={'transparent'}
          width={36}
          height={36}
          cursor={'pointer'}
        />
        <Info />
      </DrawerHeader>
      <DrawerContent>
        <FormContainer>
          <FormGroupWrapper>
            <FormGroupItem>
              <CreatableSelectWithError
                placeholder={`${t('general.topic')}*`}
                options={topicList}
                errorMessage={errors.topic ? errors.topic : ''}
                touched={touched.topic}
                value={values.topic}
                onChange={handleTopicChange}
                isLoading={addTopicLoading}
                isDisabled={!!props.data.id}
              />
            </FormGroupItem>

            <FormGroupItem>
              <Input
                error={!!errors.name}
                errorMessage={errors.name ? errors.name : ''}
                touched={touched.name}
                label={`${t('general.name')}*`}
                name="name"
                size="medium"
                type="text"
                fullWidth
                value={values.name}
                onChange={handleChange}
              />
            </FormGroupItem>

            <FormGroupItem>
              <Input
                error={!!errors.description}
                errorMessage={errors.description ? errors.description : ''}
                touched={touched.description}
                label={t('lesson_details.lesson_overview')}
                name="description"
                size="medium"
                type="text"
                fullWidth
                multiline
                rows="4"
                value={values.description}
                onChange={handleChange}
                component={
                  <UploaderButton
                    onChange={(e: any) => handleExtractText(e, 'description')}
                    label={t('general.upload')}
                    loading={fileLoading === 'extract' || extractTextLoading}
                    id="description"
                    accept=".pdf"
                  />
                }
              />
            </FormGroupItem>

            <FormGroupItem>
              <Input
                error={!!errors.transcript}
                errorMessage={errors.transcript ? errors.transcript : ''}
                touched={touched.transcript}
                label={t('form_fields.transcript')}
                name="transcript"
                size="medium"
                type="text"
                fullWidth
                multiline
                rows="4"
                value={values.transcript}
                onChange={handleChange}
                component={
                  <UploaderButton
                    onChange={(e: any) => handleExtractText(e, 'transcript')}
                    label={t('general.upload')}
                    loading={fileLoading === 'extract' || extractTextLoading}
                    id="transcript"
                    accept=".pdf"
                  />
                }
              />
            </FormGroupItem>

            <FormGroupItem>
              <StyledFromGroupContainer>
                <Uploader
                  label={`${t('actions.upload_content')}*`}
                  onFileChange={handleContentChange}
                  deleteFile={deleteContentFile}
                  type={fileTypes}
                  inputValue={
                    fileTypes === 'video'
                      ? (values[fileTypes] && values[fileTypes].title) || ''
                      : (values[fileTypes] && values[fileTypes].name) || ''
                  }
                  onInputChange={(e: any) =>
                    setFieldValue(
                      fileTypes === 'video'
                        ? 'video.title'
                        : `${fileTypes}.name`,
                      e.target.value,
                    )
                  }
                  disabled={!values[fileTypes]}
                  uploadedFile={values[fileTypes]}
                  fileExtension={fileExtension}
                  loading={
                    ['video', 'audio'].includes(fileTypes)
                      ? uploadVideoProgress > 0
                        ? uploadVideoProgress
                        : false
                      : fileLoading === 'content'
                  }
                  id="content"
                  accept="audio/*,video/*,.pdf"
                />
                {/* <CourseDetailsContainer>
                </CourseDetailsContainer> */}

                {/* <CourseDetailsContainer>
                  <Uploader
                    label={t('actions.add_transcript')}
                    // accept="video/*"
                    onFileChange={(e: any) =>
                      handleFileChange(e, 'transcriptFile')
                    }
                    deleteFile={() => {
                      setFieldValue('transcriptFile', null)
                      setTranscript(null)
                    }}
                    type="file"
                    inputValue={
                      (values.transcriptFile && values.transcriptFile.name) ||
                      ''
                    }
                    onInputChange={(e: any) =>
                      setFieldValue('transcriptFile.name', e.target.value)
                    }
                    disabled={!values.transcriptFile}
                    uploadedFile={values.transcriptFile}
                    fileExtension={
                      (values.transcriptFile &&
                        values.transcriptFile.fileType) ||
                      ''
                    }
                    loading={fileLoading === 'transcriptFile'}
                    id="transcriptFile"
                  />
                </CourseDetailsContainer> */}
              </StyledFromGroupContainer>
            </FormGroupItem>
            {fileTypes === 'contentDocument' && (
              <FormGroupItem style={{ width: '100%' }}>
                <Input
                  error={!!errors.pdfDuration}
                  errorMessage={errors.pdfDuration ? errors.pdfDuration : ''}
                  touched={touched.pdfDuration}
                  onChange={handleChange}
                  name="pdfDuration"
                  value={values.pdfDuration}
                  label={t('general.required_time_to_read_pdf')}
                  size="medium"
                  type="number"
                  fullWidth
                />
              </FormGroupItem>
            )}

            <FormGroupItem>
              <InputGroup>
                {values.video && (
                  <Uploader
                    label={t('actions.add_thumbnail')}
                    onFileChange={(e: any) => handleImageChange(e, 'thumbnail')}
                    deleteFile={() => {
                      setFieldValue('thumbnail', null)
                    }}
                    type="image"
                    inputValue={
                      (values.thumbnail && values.thumbnail.name) || ''
                    }
                    onInputChange={(e: any) =>
                      setFieldValue('thumbnail', e.target.value)
                    }
                    disabled={!values.thumbnail}
                    uploadedFile={values.thumbnail}
                    fileExtension={
                      (values.thumbnail && values.thumbnail.fileType) || ''
                    }
                    loading={imageLoading}
                    id="thumbnail"
                    accept="image/*"
                  />
                )}
              </InputGroup>
            </FormGroupItem>
            <FormGroupItem>
              <Uploader
                label={t('actions.add_attachment')}
                onFileChange={(e: any) => handleFileChange(e, 'attachment')}
                deleteFile={() => {
                  setFieldValue('attachment', null)
                  setAttachment(null)
                }}
                type="file"
                inputValue={(values.attachment && values.attachment.name) || ''}
                onInputChange={(e: any) => console.log('on pdf input change')}
                disabled={!values.attachment}
                uploadedFile={values.attachment}
                fileExtension={
                  (values.attachment && values.attachment.fileType) || ''
                }
                loading={fileLoading === 'attachment'}
                id="attachment"
                accept=".pdf"
              />
            </FormGroupItem>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type="medium"
                color="secondary"
                onClick={handleSubmit}
                isDisabled={submitDisabled}
                background={'#317BF4'}
                buttonStyles={{
                  borderRadius: '8px',
                }}
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default AddLessonDrawer

const StyledFromGroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
