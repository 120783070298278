import React, { useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import logo from '../../assets/logo.png';
import symbol from '../../assets/simbol.svg';
import LightSwitch from './light-switch/light-switch';
import NavButton from './nav-button';
import i18n from '../../i18n';
import { getCurrentLanguage, setCurrentLanguage } from '../../helpers/localStorage';
import LangSelect from './lang-select';
import { useTranslation } from 'react-i18next'
import { useHistory, } from 'react-router-dom';

const LandingNavbar = (props) => {



  const { t } = useTranslation()


  const languages = [
    { title: 'Eng', code: 'en' },
    { title: 'Geo', code: 'ka' },
  ];

  const location = window.location.pathname;

  let history = useHistory()

  const hostname = window.location.hostname;
  const link = hostname === 'stage.wenroll.com' ? 'https://stgadmin.wenroll.com/' : hostname === 'www.wenroll.com' ? 'https://admin.wenroll.com/' : hostname === 'test.wenroll.com' ? 'https://testadmin.wenroll.com/' : hostname === 'localhost' ? 'login'
    : '/'
    ;   // const hideElement =
  //   location === '/login' || location === '/' || location === '/signup' || location === '/reset'
  //     ? 'xl:hidden'
  //     : 'xl:block';

  const hideElement =
    location === '/login/ka' || location === '/login/en' || location === '/login' || location === '/signup' || location === '/signup/ka' || location === '/signup/en' || location === '/forgot-password' || location === '/success' || location === `/reset-password/${props.token}`
      ? 'xl:hidden'
      : 'xl:block';

  const currentLang = languages.find(lang => lang.code === getCurrentLanguage()) || languages[1];
  useEffect(() => {

  }, [])

  return (
    <div className='flex flex-row w-full align-center justify-between mt-5 hover:cursor-pointer absolute top-0 z-10'>
      <div className='flex flex-row ml-14 cursor-pointer items-start' >
        <a href='/' className='w-full h-full'>
          <img src={logo} alt='logo' className='w-0 h-0 sm:w-[245px] sm:h-[50px] mr-2 ' />
          <img src={symbol} alt='logo' className='h-[50px] sm:h-0 sm:w-0  mr-2 ' />
        </a>
      </div>
      <div className='flex flex-row mr-4 h-full align-center justify-center'>
        <LightSwitch />
        <span className={`hidden ${hideElement}`} onClick={() => {

        }} >

          <a href="https://www.wenroll.com/business" _>
            <NavButton border={true} text={t("general.forBusiness")}

            />
          </a>

        </span>
        <LangSelect
          languages={languages}
          defaultValue={currentLang}
          onChange={(lang) => {
            i18n.changeLanguage(lang.code)
            setCurrentLanguage(lang.code)
            history.push(`${lang.code}`)
          }}
        />


        <a href={link} className={`hidden ${hideElement}`}>
          <NavButton text={t('login.log_in')} backgroundColor='bg-[#007DFF] hover:bg-[#008DFF]' />
        </a>
      </div>
    </div>
  );
};

export default LandingNavbar;
