import React from 'react'
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from 'draft-js-buttons'

import { ToolbarControls } from './styled-components'

import HeadlinesPicker from './HeadlinesPicker/HeadlinesPicker'
import { linkPlugin } from './Plugins'

const HeadlinesButton = props => {
  const onClick = () => {
    props.onOverrideContent(HeadlinesPicker)
  }

  return (
    <div>
      <button onClick={onClick}>H</button>
    </div>
  )
}

const Controls = ({ externalProps }) => {

  return (
    <ToolbarControls>
      <BoldButton {...externalProps} />
      <ItalicButton {...externalProps} />
      <UnderlineButton {...externalProps} />
      <linkPlugin.LinkButton {...externalProps} />
      {/* <CodeButton {...externalProps} /> */}
      <HeadlinesButton {...externalProps} />
      <UnorderedListButton {...externalProps} />
      <OrderedListButton {...externalProps} />
      <BlockquoteButton  {...externalProps} />
    </ToolbarControls>
  )
}

export default Controls
