import React, { FunctionComponent } from "react";
import DeleteNoteButton from "./DeleteNoteButton";
import CIcon from "components/Icon";
import './VideoCommentGrid.scss'

export interface IComment {
  id: string,
  title: string,
  description: string,
  time: string,
  seconds: number,
  onEdit?: () => void,
  onDelete?: () => void
}

interface VideoCommentGridProps {
  comments: IComment[],
  onDelete: () => void,
  onEdit: (e: React.MouseEvent<HTMLElement>, id: string) => void,
  setTime?: (time: number) => void,
}

const VideoCommentGrid: FunctionComponent<VideoCommentGridProps> = (props) => {
  return (
    <>
      {props.comments.map((item, index) => (
        <div key={item.id} className="comment-wrapper" style={{ cursor: "pointer" }} onClick={() => {
          props.setTime?.(item.seconds + Math.random() || 0)
        }}>
          <div className="actions">
            <div className="time">{item.time}</div>
            <div className="actions-btn">
              <button style={{
                background: 'transparent',
                border: '1px solid #080A0A04',
                outline: 'none',
                padding: '15px',
                borderRadius: '8px',
                marginRight: '10px',
                cursor: 'pointer'
              }} onClick={(e) => props.onEdit(e, item.id)}>
                <CIcon filename="edit" fill="#49AF75" />
              </button>
              <DeleteNoteButton noteId={item.id} onCompleted={props.onDelete} />
            </div>
          </div>
          <div className="comment-title">{item.title}</div>
          <div className="comment-description">{item.description}</div>
        </div>
      ))}
    </>
  );
}

export default VideoCommentGrid;