import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { gql } from 'graphql.macro'
import strCutter from 'utils/strCutter'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DashboardIcon from '@material-ui/icons/Dashboard'
import EditIcon from '@material-ui/icons/Edit'
import HeadsetIcon from '@material-ui/icons/Headset'
import ImageIcon from '@material-ui/icons/Image'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import { Grid } from 'components/common/Cards/styled-components'
import CheckBox from 'components/common/Checkbox'
import DndItem from 'components/common/Grid/DndGrid/DndItem'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import {
  Card,
  Content,
  Edited,
  FilesWrapper,
  FileWrapper,
  Footer,
  Header,
  ModuleItem,
  ModuleItemWrapper,
  Modules,
  Question,
  TagItem,
  Tags,
  Type,
  Wrapper,
} from '../styled-components'
import { mainColor } from 'components/common/Colors/CommonColors'
import { useUserValue } from 'context/UserContext'

interface Params {
  gridStyle: string
  cols: number
  data?: any
  actions?: any
  currentModuleId?: string
  selectItem?: any
  selectedItems?: any
  linkDrawer?: boolean
  questionIds?: string[]
  isDraggable?: boolean
  moveCard?: any
  fetchQuestions?: any
  questions?: string[]
  cards?: any
  handleChangeRequest?: any
  t?: any
  openInExternalTab?: boolean
  canModify?: boolean
}

const CHANGE_ORDER = gql`
  mutation($draggedId: String!, $position: Int!) {
    changeQuestionsOrder(draggedId: $draggedId, position: $position)
  }
`

const QuestionsGrid = ({
  gridStyle,
  cols,
  data,
  actions,
  currentModuleId,
  selectItem,
  selectedItems,
  linkDrawer = false,
  questionIds,
  isDraggable = false,
  fetchQuestions,
  questions = [],
  cards,
  moveCard,
  handleChangeRequest,
  t,
  openInExternalTab,
  canModify,
}: Params) => {
  const history = useHistory()
  const [state] = useUserValue()

  const redirectToQuestionDetails = (quistionId: string): void => {
    if (openInExternalTab) {
      window.open(`/questions/${quistionId}`)
    } else {
      history.push(`/questions/${quistionId}`)
    }
  }

  const isCompanySuperAdmin =
    state?.currentUser?.role === 'SUPER_ADMIN' &&
    !!state?.currentUser?.companyId?.id

  const isCompanyAdmin =
    state?.currentUser?.role === 'ADMIN' && !!state?.currentUser?.companyId?.id

  return (
    <Grid className={gridStyle} cols={cols}>
      {data.map((item: any, index: number) => {
        const moduleIds = item.modules.map(
          (moduleItem: any) => moduleItem.moduleId,
        )
        const visibleModulesQuantity = gridStyle === 'card' ? 2 : 10
        const visibleModules = item.modules.slice(0, visibleModulesQuantity)
        const exModules = item.modules.slice(
          visibleModulesQuantity,
          item.modules.length,
        )

        return isDraggable ? (
          <DndItem
            key={item.id}
            id={item.id}
            index={index}
            moveCard={moveCard}
            cardIds={data.map((card: any) => card.id)}
            onEnd={handleChangeRequest}
            isNested
          >
            <Card onClick={() => redirectToQuestionDetails(item.id)}>
              <Header>
                <Wrapper>
                  <Wrapper>
                    {canModify && (
                      <CheckBox
                        onChange={() => selectItem(item.id)}
                        checked={
                          selectedItems.includes(item.id) ||
                          (questionIds && questionIds.includes(item.id)) ||
                          (linkDrawer && moduleIds.includes(currentModuleId))
                        }
                        disabled={
                          (questionIds && questionIds.includes(item.id)) ||
                          (linkDrawer && moduleIds.includes(currentModuleId))
                        }
                      />
                    )}
                    {/* <Link to={`${`/questions/${item.id}`}`}>
                      <Type style={{ fontSize: '12px' }}>
                        {t(`question_details.${item.type.value}`)}
                      </Type>
                    </Link> */}
                  </Wrapper>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {item.edited && (
                      <Edited>
                        <EditIcon fontSize="small" />
                        edited
                      </Edited>
                    )}
                    {actions && (
                      <GridActionMenu actionConfig={actions} row={item} />
                    )}
                  </div>
                </Wrapper>
                {/* <Tags>
                  {item.tags.map((tag: any) => (
                    <TagItem>#{tag.label}</TagItem>
                  ))}

                  {item.tags.length === 0 && <div>{t('general.no_tags')}</div>}
                </Tags> */}
              </Header>
              <Content>
                <div>
                  <Question>{strCutter(item.question, 130)}</Question>
                  {/* <FilesWrapper>
                    {item.documents.length > 0 && (
                      <FileWrapper>
                        <InsertDriveFileIcon style={{ color: mainColor }} />
                        {item.documents.length}
                      </FileWrapper>
                    )}
                    {item.videos.length > 0 && (
                      <FileWrapper>
                        <PlayCircleOutlineIcon style={{ color: mainColor }} />
                        {item.videos.length}
                      </FileWrapper>
                    )}
                    {item.images.length > 0 && (
                      <FileWrapper>
                        <ImageIcon style={{ color: mainColor }} />
                        {item.images.length}
                      </FileWrapper>
                    )}
                    {item.audios.length > 0 && (
                      <FileWrapper>
                        <HeadsetIcon style={{ color: mainColor }} />
                        {item.audios.length}
                      </FileWrapper>
                    )}
                  </FilesWrapper> */}
                </div>
              </Content>
              <Footer>{t('general.questionWithOneCorrectAnswer')}</Footer>
            </Card>
          </DndItem>
        ) : (
          <Card
            key={item.id}
            onClick={() => redirectToQuestionDetails(item.id)}
          >
            <Header>
              <Wrapper>
                <Wrapper>
                  <CheckBox
                    onChange={() => selectItem(item.id)}
                    checked={
                      selectedItems.includes(item.id) ||
                      (questionIds && questionIds.includes(item.id)) ||
                      (linkDrawer && moduleIds.includes(currentModuleId))
                    }
                    disabled={
                      (questionIds && questionIds.includes(item.id)) ||
                      (linkDrawer && moduleIds.includes(currentModuleId))
                    }
                  />
                </Wrapper>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {item.edited && (
                    <Edited>
                      <EditIcon fontSize="small" />
                      {t('general.edited')}
                    </Edited>
                  )}
                  {actions && (
                    <GridActionMenu actionConfig={actions} row={item} />
                  )}
                </div>
              </Wrapper>
              {/* <Tags>
                {item.tags.map((tag: any) => (
                  <TagItem>#{tag.label}</TagItem>
                ))}

                {item.tags.length === 0 && <div>{t('general.no_tags')}</div>}
              </Tags> */}
            </Header>
            <Content>
              <div>
                <Question>{strCutter(item.question, 130)}</Question>
                <FilesWrapper>
                  {item.documents && item.documents.length > 0 && (
                    <FileWrapper>
                      <InsertDriveFileIcon style={{ color: mainColor }} />
                      {item.documents.length}
                    </FileWrapper>
                  )}
                  {item.videos && item.videos.length > 0 && (
                    <FileWrapper>
                      <PlayCircleOutlineIcon style={{ color: mainColor }} />
                      {item.videos.length}
                    </FileWrapper>
                  )}
                  {item.images.length > 0 && (
                    <FileWrapper>
                      <ImageIcon style={{ color: mainColor }} />
                      {item.images.length}
                    </FileWrapper>
                  )}
                  {item.audios.length > 0 && (
                    <FileWrapper>
                      <HeadsetIcon style={{ color: mainColor }} />
                      {item.audios.length}
                    </FileWrapper>
                  )}
                </FilesWrapper>
              </div>
            </Content>
            <div
              style={{
                width: '100%',
                padding: '15px 0',
                background: '#E6EFFD',
                textAlign: 'center',
              }}
            >
              <Link to={`${`/questions/${item.id}`}`}>
                <Type style={{ fontSize: '14px', color: '#080A0A8A' }}>
                  {t(`question_details.${item.type.value}`)}
                </Type>
              </Link>
            </div>
            <Footer>
              <Modules
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                {item.modules.length > 0 ? (
                  visibleModules.map((moduleItem: any) => (
                    <div
                      style={{
                        fontSize: '16px',
                        color: '#5290F6',
                        paddingBottom: '10px',
                      }}
                    >
                      {strCutter(moduleItem.name, 30)}
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      fontSize: '16px',
                      color: '#5290F6',
                    }}
                  >
                    {t('general.no_assigned_modules')}
                  </div>
                )}
                {exModules.length > 0 && (
                  <Tooltip
                    title={
                      <>
                        {exModules.map((exModule: any) => (
                          <div>{exModule.name}</div>
                        ))}
                      </>
                    }
                  >
                    <MoreHorizIcon fontSize="small" />
                  </Tooltip>
                )}
                {/* </div> */}
              </Modules>
            </Footer>
          </Card>
        )
      })}

      {data.length === 0 && <div>{t('general.no_questions')}</div>}
    </Grid>
  )
}

export default QuestionsGrid
