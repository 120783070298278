import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/common/Button'
import Modal from 'components/common/Modal'
import { PasswordInput } from 'components/common/TextInput/PasswordInput'
import { useUpdateUserPasswordById } from 'services/users/editProfileService'
import styled from 'styled-components'
import { mainColor, validationRed, red } from 'components/common/Colors/CommonColors'

const ResetUsersPassword: React.FC<{
  isModalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  userId: string
}> = ({ isModalOpen, setModalOpen, userId }) => {
  const { t } = useTranslation()
  const [newPassword, setNewPassword] = useState('')
  const handleCloseDrawer = () => setModalOpen(false)
  const { loading, updateUserPassword } = useUpdateUserPasswordById()
  const onSave = useCallback(() => {
    updateUserPassword(userId, newPassword)
    handleCloseDrawer()
  }, [userId, newPassword])

  return (
    <Modal isOpened={isModalOpen} onClose={handleCloseDrawer}>
      <Wrapper>
        <h2>{t('reset_password.set_new_password')}</h2>
        <PasswordInput
          label={t('reset_password.set_new_password')}
          name="newpassword"
          size="small"
          onChange={(e: any) => setNewPassword(e.target.value)}
          value={newPassword}
        />
        <ButtonsContainer>
          <Button
            text={t('actions.cancel')}
            type="small"
            onClick={handleCloseDrawer}
            background="#E0E1E2"
            textColor={validationRed}
            outline={`1px solid ${red}`}
          />
          <Button
            text={t('actions.save')}
            type="small"
            color="secondary"
            background={mainColor}
            isDisabled={newPassword.length < 1}
            onClick={onSave}
          />
        </ButtonsContainer>
      </Wrapper>
    </Modal>
  )
}

export default ResetUsersPassword

const Wrapper = styled.div`
  background: white;
  width: 30%;
  height: 30vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  width: 400px;
  height: 200px;
  padding: 20px 30px;
  box-shadow: 5px 5px 10px #00000029;
  border-radius: 10px;
  > h2 {
    width: 100%;
    text-align: left;
  }
`
const ButtonsContainer = styled.div`
  display: flex;
  width: 146px;
  align-items: center;
  justify-content: space-between;
`
