import React from 'react'
import Checkbox from 'components/common/Checkbox'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Tooltip from '@material-ui/core/Tooltip'
import styled from 'styled-components'
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList'

interface itemProps {
  id: string
  isChecked: boolean
  firstName: string
  lastName: string
  email: string
  phone: string
  phoneFields: {
    dialCode: string
    code: string
  }
  message: string[]
  error: boolean
  gender: string
  biography: string
  note: string
}

// eslint-disable-next-line import/prefer-default-export
export const columnConfig = (selectItem: any, isImported: boolean, t?: any) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <Checkbox
          checked={item.isChecked}
          onChange={() => selectItem(item.id)}
          disabled={isImported}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: `${t('user_details.first_name')}*`,
      render: (item: itemProps) => (
        <div style={{ cursor: 'pointer' }}>{item.firstName}</div>
      ),
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.last_name')}*`,
      render: (item: itemProps) => <div>{item.lastName}</div>,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.email')}*`,
      render: (item: itemProps) => <div>{item.email}</div>,
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: t('user_details.phone'),
      render: (item: itemProps) => {
        return (
          <div>
            {item.phoneFields && `(${item.phoneFields.code})`}
            {item.phone}
          </div>
        )
      },
    },
    {
      id: 'gender',
      numeric: false,
      disablePadding: false,
      label: t('user_details.gender'),
      render: (item: itemProps) => <div>{item.gender}</div>,
    },
    {
      id: 'biography',
      numeric: false,
      disablePadding: false,
      label: t('user_details.biography'),
      render: (item: itemProps) => (
        <Tooltip
          title={<span style={{ fontSize: '12px' }}>{item.biography}</span>}
        >
          <FeaturedPlayListIcon />
        </Tooltip>
      ),
    },
    {
      id: 'note',
      numeric: false,
      disablePadding: false,
      label: t('user_details.note'),
      render: (item: itemProps) => (
        <Tooltip title={<span style={{ fontSize: '12px' }}>{item.note}</span>}>
          <FeaturedPlayListIcon />
        </Tooltip>
      ),
    },
  ]

  if (isImported) {
    grid.push({
      id: 'error',
      numeric: false,
      disablePadding: false,
      label: 'Error',
      render: (item: itemProps) => {
        const messages = (item && item.message) || []

        if (!item.error) return <></>

        return (
          <ErrorContainer error={item.error}>
            <Tooltip
              title={
                <List>
                  {messages.map((i: string) => (
                    <ListItem>* {i}</ListItem>
                  ))}
                </List>
              }
            >
              <ErrorOutlineIcon color="secondary" />
            </Tooltip>
          </ErrorContainer>
        )
      },
    })
  }

  return grid
}

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: circle;
`
const ListItem = styled.li`
  font-size: 12px;
`
const ErrorContainer = styled.div<{ error: boolean }>`
  cursor: pointer;
  color: ${({ error }) => (error ? 'red' : 'rgba(0, 0, 0, 0.27)')};
`
