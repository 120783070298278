import React from 'react'
import styled from 'styled-components'

import ExpansionNavMenu from './ExpansionNavMenu.jsx'
import ExpansionItem from './ExpansionItem'

import { getNavigation } from 'routerConfig'
import { checkRole } from 'helpers/checkRole'
import { useUserValue } from 'context/UserContext'

const NavigationBar = ({ isOpened, currentUser }) => {
  const [state] = useUserValue()

  const role = checkRole(currentUser.role, currentUser.companyId)
  const routerData = getNavigation(state.userPermission, state.currentUser)

  return (
    <Container isOpened={isOpened}>
      {routerData.map((item, index) =>
        item.children ? (
          <ExpansionNavMenu
            item={item}
            index={index}
            key={index}
            isOpened={isOpened}
            role={role}
          />
        ) : (
          <ExpansionItem
            item={item}
            index={index}
            key={index}
            isOpened={isOpened}
            role={role}
          />
        ),
      )}
    </Container>
  )
}

export default NavigationBar

const Container = styled.div`
  width: ${({ isOpened }) => (isOpened ? `unset` : `100%`)};
`
