import { Button, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Icon from '../../components/Icon';
import Axios from 'axios';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { GET_TRANSACTION } from 'gql/transaction/transaction.query';
import { setCourseId } from "helpers/localStorage";

const PaymentPage = () => {

  const history = useHistory();
  const params = useParams();
  const [paymentData, setPaymentData] = useState()
  const [payment, setPayment] = useState("in_progress")
  const { stopPolling } = useQuery(GET_TRANSACTION, {
    variables: {
      shopOrderId: params.orderId
    },
    onCompleted: data => {
      const { getTransaction: transaction } = data ?? {};
      const status = data?.getTransaction?.status ? data?.getTransaction?.status : "error"

      let courseId = data?.getTransaction?.courseId || ""

      setPaymentData(courseId)
      setCourseId(courseId)
      setPayment(status)
    },
    pollInterval: 1000,

  });
  // let payment = data?.getTransaction?.status || "in_progress"
  // let courseId = data?.getTransaction?.courseId || ""
  if (payment === "success" || payment === "error") {
    stopPolling()
  }

  useEffect(() => {
    return () => {
      stopPolling()
    }
  }, [])
  const HandelRedirect = () => {
    if (payment === 'success') {

      history.push(`/course/${paymentData}`)
    } else {
      history.push('/')
    }

  }

  return (
    <PaymentContainer>
      <LoadingContainer>
        {payment === 'progress' && <Icon width={'64px'} height={'64px'} filename='progress_dots' />}
        {payment === 'success' && <Icon width={'64px'} height={'64px'} filename='payment_success' />}
        {payment === 'error' && <Icon width={'64px'} height={'64px'} filename='payment_fail' fill={'red'} />}
      </LoadingContainer>
      <Typography>
        {payment === 'progress' && 'მიმდინარეობს გადახდა...'}
        {payment === 'success' && 'გილოცავ! შენ წარმატებით შეიძინე კურსი.'}
        {payment === 'error' && 'გადახდის შეცდომა'}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        size='large'
        style={{
          marginTop: '20px',
        }}
        onClick={HandelRedirect}
      >{payment === "success" ? "კურსზე გადასვლა" : "დახურვა"}</Button>
    </PaymentContainer>
  );
}

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 20px;

  border-radius: 100%;
  background-color: rgb(0 0 0 / 10%);
`

const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 30px;
  padding: 20px;
`

export default PaymentPage;
