import React from 'react'
import strCutter from 'utils/strCutter'
import Checkbox from 'components/common/Checkbox'
import Typography from '@material-ui/core/Typography'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import styled from 'styled-components'

import EditRoundedIcon from '@material-ui/icons/EditRounded'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import GetAppIcon from '@material-ui/icons/GetApp'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import IconButton from '@material-ui/core/IconButton'
import GroupIcon from '@material-ui/icons/Group'
import Tooltip from '@material-ui/core/Tooltip'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import PublicIcon from '@material-ui/icons/Public'
import VpnLockIcon from '@material-ui/icons/VpnLock'
import { CheckBoxAndAvatarContainer } from 'pages/companyWizard/CompanyStudents/gridConfig'
interface itemProps {
  name: string
  videos: any
  state: string
  isChecked?: boolean
  id: string
  level?: string
  coursePrivacy: string
  coachType: any
  coach: any
  duration: string
  studentsEnrolled: string
  rating: string
  numberOfGroups: string
  numberOfStudents: string
  price: number | null
  avatar: any
}

export const columnConfig = (handelClick: any, selectItem: any, t?: any) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: itemProps) => (
        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={item.isChecked}
            onChange={() => selectItem(item.id)}
          />
          <img
            onClick={() => handelClick(item.id)}
            src={item.avatar.link || ''}
            alt="Avatar"
          />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'courseName',
      numeric: false,
      disablePadding: true,
      center: true,
      label: `${t('general.courseName')}`,
      render: (item: itemProps) => (
        <div onClick={() => handelClick(item.id)} style={{ cursor: 'pointer' }}>
          {item.name}
        </div>
      ),
    },
  ]

  return grid
}

export const actionConfig = (
  handleClick: any,
  permission: any,
  currentUser: any,
  companyMode?: any,
  activeTab?: any,
  t?: any,
  isSuperAdmin?: boolean,
  isAdmin?: boolean,
) => {
  const viewCourse = {
    // !permission.viewCourse, //TODO: fix in case of student
    render: (item: any) => (
      <Action
        onClick={() => {
          handleClick('viewCourse', item.id)
        }}
      >
        <ViewCompactIcon />
        <Typography>{t('actions.view')}</Typography>
      </Action>
    ),
  }

  const mediaActions = [
    {
      render: (item: any) => {
        return (
          <Action onClick={() => handleClick('palyIntro', item)}>
            <PlayCircleFilledIcon
              color={item.video ? 'secondary' : 'disabled'}
            />
            <Typography>{t('actions.play_intro')}</Typography>
          </Action>
        )
      },
    },
  ]

  const editCourse = {
    hide: !companyMode,
    render: (item: any) => (
      <Action onClick={() => handleClick('editCourse', item.id)}>
        <EditRoundedIcon />
        <Typography>{t('actions.edit')}</Typography>
      </Action>
    ),
  }

  const deleteCourse = {
    hide: !companyMode,
    render: (item: any) => (
      <Action onClick={() => handleClick('deleteCourse', item.id)}>
        <DeleteForeverRoundedIcon />
        <Typography>{t('actions.delete')}</Typography>
      </Action>
    ),
  }

  const remove = {
    hide: !companyMode,
    render: (item: any) => (
      <Action onClick={() => handleClick('deleteCourse', item.id)}>
        <DeleteForeverRoundedIcon />
        <Typography>{t('actions.delete')}</Typography>
      </Action>
    ),
  }

  let publishUnpublish = {
    hide: !companyMode,
    render: (item: any) => {
      return (
        <Action
          disabled={item.finished !== 3}
          onClick={() => {
            if (item.finished === 3) {
              handleClick(
                'publishedUnPublished',
                item.id,
                item.state === 'unpublished' ? 'published' : 'unpublished',
              )
            }
          }}
        >
          {item.state === 'unpublished' ? <PublicIcon /> : <VpnLockIcon />}
          {/* <PublicIcon /> */}
          <Typography>
            {item.state === 'unpublished'
              ? t('actions.publish')
              : t('actions.unpublish')}
          </Typography>
          {/* <Typography>Publish / Unpublish</Typography> */}
        </Action>
      )
    },
  }

  const menage = {
    render: (item: any) => (
      <Action onClick={() => handleClick('editCourse', item.id)}>
        <EditRoundedIcon />
        <Typography>{t('actions.edit')}</Typography>
      </Action>
    ),
  }

  if ((isSuperAdmin || isAdmin) && companyMode && activeTab === 0) {
    return [
      viewCourse,
      menage,
      publishUnpublish,
      remove,
      ...mediaActions,
    ].filter((i: any) => !i.hide)
  }

  if (companyMode && activeTab === 0) {
    return [viewCourse, menage, remove, ...mediaActions].filter(
      (i: any) => !i.hide,
    )
  }

  if (companyMode && activeTab === 1) {
    return [
      viewCourse,
      editCourse,
      publishUnpublish,
      deleteCourse,
      ...mediaActions,
    ].filter((i: any) => !i.hide)
  }

  return [
    viewCourse,
    editCourse,
    publishUnpublish,
    deleteCourse,
    ...mediaActions,
  ].filter((i: any) => !i.hide)

  // if (currentUser.companyId && !currentUser.companyId.platformService) {
  //   action.push({
  //     render: (item: any) => (
  //       <Action onClick={() => handleClick('editCourse', item.id)}>
  //         <EditRoundedIcon />
  //         <Typography>Menage Groups</Typography>
  //       </Action>
  //     ),
  //   })
  // }

  //
}

export const purchasedCourseActionConfig = (
  handleClick: any,
  currentUser: any,
  t?: any,
) => {
  const viewCourse = {
    render: (item: any) => (
      <Action
        onClick={() =>
          handleClick(
            'viewCourse',
            item.isBought && !currentUser.companyId ? item.originalId : item.id,
          )
        }
      >
        <ViewCompactIcon />
        <Typography>{t('actions.view')}</Typography>
      </Action>
    ),
  }

  const playIntro = {
    render: (item: any) => {
      return (
        <Action onClick={() => handleClick('palyIntro', item)}>
          <PlayCircleFilledIcon color={item.video ? 'secondary' : 'disabled'} />
          <Typography>{t('actions.play_intro')}</Typography>
        </Action>
      )
    },
  }

  const remove = {
    render: (item: any) => (
      <Action onClick={() => handleClick('deleteCourse', item.id)}>
        <DeleteForeverRoundedIcon />
        <Typography>{t('actions.delete')}</Typography>
      </Action>
    ),
  }

  return [viewCourse, playIntro, remove]
}

const Action = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  opacity: ${({ disabled }: any) => (disabled ? '0.5' : '1')};
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`
const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 0 20px 0 0;
  text-transform: capitalize;
`

// const Action = styled.div`
//   display: flex;
//   align-items: center;
//   cursor: pointer;
//   padding: 6px 10px;
//   transition: 0.3s ease;
//   border-radius: 5px;
//   &:hover {
//     background: rgba(0, 0, 0, 0.1);
//   }
//   svg {
//     font-size: 1rem;
//     margin-right: 6px;
//   }
// `

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  width: max-content;
  height: max-content;
  padding: 5px 10px;
  padding-bottom: 2px;
  border-radius: 4px;

  &.active {
    color: #269f5a;
    background: #269f5a1f;
  }

  &.suspended {
    color: #cc3131;
    background: #cc31311f;
  }

  &.pending {
    color: #ffa502;
    background: #ffa5021f;
  }

  &.draft {
    color: rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.151);
  }
`

const StatusCircle = styled.div`
  margin-right: 5px;
  margin-top: -3px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
`

const CountContainer = styled.div`
  width: 36px;
  height: 36px;
  background: #080a0a0a;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #303131;
`
